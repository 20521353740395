import React from 'react';
import PropTypes from 'prop-types';

function CheckCircleIcon(props) {
    const { color = '#FF2825' } = props;
    return (
        <>
            <svg width="23" height="23" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M8.5 14.875C12.0208 14.875 14.875 12.0208 14.875 8.5C14.875 4.97918 12.0208 2.125 8.5 2.125C4.97918 2.125 2.125 4.97918 2.125 8.5C2.125 12.0208 4.97918 14.875 8.5 14.875Z"
                    stroke={color}
                    strokeWidth="0.916667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.375 8.50016L7.79167 9.91683L10.625 7.0835"
                    stroke={color}
                    strokeWidth="0.916667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
}

CheckCircleIcon.propTypes = {
    color: PropTypes.string,
};

export default CheckCircleIcon;
