import React from 'react';
import { Redirect } from 'react-router-dom';

// ** New Routes v1

// Authentication
import LoginPage from 'pages/Auth/Login-Username';
import ForgetPasswordPage from 'pages/Auth/Forget-Password';
import ResetPasswordPage from 'pages/Auth/Reset-Password';
import VerifyUserPage from 'pages/Auth/Verify-User';
import SwitchProfilePage from 'pages/Auth/Select-Profile';
import VerifyRiderPage from 'pages/Auth/Verify-Rider';
import TemporaryPasswordResetPage from 'pages/Auth/Temporary-Password-Reset';

// User
import UserRegistration from 'pages/Modules/User-Management/modules/User-Form';
import UserProfile from 'pages/UserProfile';

// Rider
import RiderRegistration from 'pages/Modules/Rider-Management/modules/Rider-Form';

// Main
import HomePage from 'pages/Modules/Home';
import UserProfileManagement from 'pages/Modules/User-Management/modules/User-List';
import RiderProfileManagement from 'pages/Modules/Rider-Management/modules/Rider-List';
import OrderProcessingDashboardPage from 'pages/Modules/Order-Processing';
import DeliveryViewPage from 'pages/Modules/Delivery-Management/modules/Delivery-Form';
import DeliveryManagementPage from 'pages/Modules/Delivery-Management/modules/Delivery-List';
import OrderPage from 'pages/Modules/Order-Processing/Order';
import PickingTaskListPage from 'pages/Modules/Order-Processing/PickingTask/List/index';
import PickingTaskFormPage from 'pages/Modules/Order-Processing/PickingTask/Form/index';
import MerchantManagementPage from 'pages/Modules/Merchant-Management/modules/Merchant-List';
import MerchantFormPage from 'pages/Modules/Merchant-Management/modules/Merchant-Form';
import ProcessedOrdersPage from 'pages/Modules/Order-Processing/Processed-Orders';
import MerchantLocationListPage from 'pages/Modules/Merchant-Management/modules/Merchant-Locations/modules/Location-List';
import Packaging from 'pages/Modules/Order-Processing/Packaging';
import MerchantLocationFormPage from 'pages/Modules/Merchant-Management/modules/Merchant-Locations/modules/Location-Form';
import ShippingLabelViewPage from 'pages/Modules/Delivery-Management/modules/Shipping-Labels/label-view';
import AdvancedRiderTrackingPage from 'pages/Modules/Rider-Management/modules/Advanced-Rider-Tracking';
import DeliveryImportPage from 'pages/Imports/Delivery-Imports';
import BillingImportPage from 'pages/Imports/Payment-Uploads';
import RiderCommissionImportPage from 'pages/Imports/Rider-Commission-Uploads';
import ExternalSystemConnections from 'pages/Modules/Settings/Delivery-Settings/External-System-Connections';
import ExcelCsvConfigurationPage from 'pages/Modules/Settings/Delivery-Settings/Excel-Csv-Config';
import PendingDeliveryDataViewPage from 'pages/PendingData/Delivery';
import DeletedRidersManagementPage from 'pages/Modules/Rider-Management/modules/Deleted-Riders';
import DeletedMerchantLocationsPage from 'pages/Modules/Merchant-Management/modules/Merchant-Locations/modules/Deleted-Merchant-Locations';
import DeletedDeliveriesPage from 'pages/Modules/Delivery-Management/modules/Deleted-Deliveries';
import DeletedMerchantsPage from 'pages/Modules/Merchant-Management/modules/Deleted-Merchants';
import WarehouseSiteManagementPage from 'pages/Modules/Warehouse/Warehouse-Sites';
import WarehouseSiteFormPage from 'pages/Modules/Warehouse/Warehouse-Sites/Site-Form';
import WarehouseZonePage from 'pages/Modules/Warehouse/Warehouse-Sites/Site-Form/Site-Location-Form';
import BinLocationFormPage from 'pages/Modules/Warehouse/Warehouse-Sites/Site-Form/Site-Location-Form/Bin-Location-Form';
import ProductMasterDataManagementPage from 'pages/Modules/Warehouse/Product-Master-Data';
import ProductMasterDataFormPage from 'pages/Modules/Warehouse/Product-Master-Data/Product-Form';
import StocksReceivingManagementPage from 'pages/Modules/Warehouse/Stocks-Receiving';
import StocksReceivingFormPage from 'pages/Modules/Warehouse/Stocks-Receiving/Form';
import UnAssignedDeliveryManagementPage from 'pages/Modules/Delivery-Management/modules/Un-Assigned-Deliveries';
import NotificationsView from 'pages/Notification';
import StockAdjustmentPage from 'pages/Modules/Warehouse/Stock-Adjustments';
import StockAdjustmentFormPage from 'pages/Modules/Warehouse/Stock-Adjustments/Form';
import StocksReceivingDeletedListPage from 'pages/Modules/Warehouse/Stocks-Receiving/Deleted-List';
import InventoryReportViewPage from 'pages/Modules/Warehouse/Inventory-Report';
import DeliveryReport from 'pages/Modules/Delivery-Management/modules/Delivery-Report';
import LabelPrintListPage from 'pages/Modules/Label-Print/Shipping-Label/List-View';
import BatchPrintPage from 'pages/Modules/Label-Print/Shipping-Label/Batch-Print';
import PrinterSettingsPage from 'pages/Modules/Label-Print/Shipping-Label/Printer-Settings';
import ArchivedBatchPrint from 'pages/Modules/Label-Print/Archived-Batch-Prints/List-View';
import BatchDeliveryAssignmentPage from 'pages/Modules/Delivery-Management/modules/Batch-Delivery-Assignment';
import RolePermission from 'pages/Modules/User-Management/modules/RolePermission';
import RoleForm from 'pages/Modules/User-Management/modules/RolePermission/RoleForm';
import DeletedUsers from 'pages/Modules/User-Management/modules/Deleted-Users';
import BillingSettingFormPage from 'pages/Modules/Merchant-Management/modules/Merchant-Form/modules/Billing-Form';
import RiderCommissionFormPage from 'pages/Modules/Rider-Management/modules/Rider-Form/modules/RiderCommission-Form';
import BillingRecordFormPage from 'pages/Modules/Payments/Billing/BillingRecordForm';
import RiderCommissionRecordFormPage from 'pages/Modules/Payments/RiderCommisions/RiderCommissionRecord';
import ShippingLabelPrintPage from 'pages/Modules/Delivery-Management/modules/Shipping-Label-Print';
import BillingManagementPage from 'pages/Modules/Payments/Billing';
import EarningSummaryPage from 'pages/Modules/Payments/EarningSummary';
import RiderCommissionPage from 'pages/Modules/Payments/RiderCommisions';

// Developer
import ApiSettingsPartnerList from 'pages/Modules/Developer/Api-Service/Partner-List';
import ClientDashboard from 'pages/Modules/Developer/Api-Service/Dashboard/Client';
import ApiPortalPage from 'pages/Modules/Developer/Api-Portal';
import ManageApiKeyPage from 'pages/Modules/Developer/Api-Keys/Manage';
import GenerateApiKeyPage from 'pages/Modules/Developer/Api-Keys/Create';
import CreateWebhookPage from 'pages/Modules/Developer/Webhooks/Create';
import ManageWebhookPage from 'pages/Modules/Developer/Webhooks/Manage';

// ------------------------------------------------------

// ** Temp Routes
// TEST PURPOSE
import TestDataGrid from 'pages/Static/Test-Data-Grid';

// Open Routes
import DeliveryTrackingPage from 'pages/Modules/Delivery-Management/modules/Delivery-Tracking';
import Logout from 'pages/Logout';
import TermsConditionScootixApplicationV1 from 'pages/Static/TermsConditions/ScootixApplication-v1.0';
import TermsConditionScootixApplicationV2 from 'pages/Static/TermsConditions/ScootixApplication-v2.0';
import UnderMaintenancePage from 'pages/Static/UnderMaintenance';

import SandboxAccountListViewPage from 'pages/Modules/Developer/Sandbox-Account/modules/Account-List';
import SandboxAccountFormViewPage from 'pages/Modules/Developer/Sandbox-Account/modules/Account-Form';
import RoutePlaningPage from 'pages/Modules/Delivery-Management/modules/Route-Planning/modules/Form-View';
import RoutePlaningEntries from 'pages/Modules/Delivery-Management/modules/Route-Planning/modules/List-View';
import RoutePlaningEntryView from 'pages/Modules/Delivery-Management/modules/Route-Planning/modules/RoutePlanningEntry-View';
import PATHS from './paths';

const authProtectedRoutes = [
    // Home
    { path: PATHS.HOME.DEFAULT, component: HomePage },

    // User
    { path: PATHS.USER_PROFILE_MANAGEMENT.DEFAULT, component: UserProfileManagement },
    { path: PATHS.USER_PROFILE_MANAGEMENT.FORM, component: UserRegistration },
    { path: PATHS.DELETED_USERS.DEFAULT, component: DeletedUsers },

    // Rider
    { path: PATHS.RIDER_PROFILE_MANAGEMENT.LIST_VIEW, component: RiderProfileManagement },
    { path: PATHS.RIDER_PROFILE_MANAGEMENT.DELETED_RIDERS, component: DeletedRidersManagementPage },
    { path: PATHS.RIDER_PROFILE_MANAGEMENT.FORM_VIEW, component: RiderRegistration },

    // Orders
    { path: PATHS.ORDER_PROCESSING.DEFAULT, component: OrderProcessingDashboardPage },
    { path: PATHS.ORDER_PROCESSING.ORDER_FORM, component: OrderPage },
    { path: PATHS.ORDER_PROCESSING.PROCESSED_ORDERS, component: ProcessedOrdersPage },
    { path: PATHS.PICKING_TASK.FORM, component: PickingTaskFormPage },
    { path: PATHS.PICKING_TASK.DEFAULT, component: PickingTaskListPage },
    { path: PATHS.PACKAGING.FORM, component: Packaging },
    { path: PATHS.ORDER_PROCESSING.PACKING, component: OrderProcessingDashboardPage },

    // Merchant
    { path: PATHS.MERCHANT.DEFAULT, component: MerchantManagementPage },
    { path: PATHS.MERCHANT.MERCHANT_FORM, component: MerchantFormPage },
    { path: PATHS.MERCHANT.MERCHANT_DELETED, component: DeletedMerchantsPage },
    { path: PATHS.MERCHANT.MERCHANT_LOCATION_LIST, component: MerchantLocationListPage },
    { path: PATHS.MERCHANT.MERCHANT_LOCATION_FORM, component: MerchantLocationFormPage },
    { path: PATHS.MERCHANT.MERCHANT_LOCATION_DELETED, component: DeletedMerchantLocationsPage },

    // Role Permissions
    { path: PATHS.ROLE_PERMISSION.DEFAULT, component: RolePermission },
    { path: PATHS.ROLE_PERMISSION.NEW_ROLE, component: RoleForm },

    // Delivery
    { path: PATHS.DELIVERY.DEFAULT, component: DeliveryManagementPage },
    { path: PATHS.DELIVERY.COMPLETED, component: DeliveryManagementPage },
    { path: PATHS.DELIVERY.DELETED, component: DeletedDeliveriesPage },
    { path: PATHS.DELIVERY.FORM, component: DeliveryViewPage },
    { path: PATHS.PENDING_DATA.DELIVERY_VIEW, component: PendingDeliveryDataViewPage },
    { path: PATHS.DELIVERY.UNASSIGNED, component: UnAssignedDeliveryManagementPage },
    { path: PATHS.DELIVERY.REPORT, component: DeliveryReport },
    { path: `/${PATHS.DELIVERY.BATCH_ASSIGNMENT}`, component: BatchDeliveryAssignmentPage, exact: true },
    { path: `/${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_FORM}`, component: RoutePlaningPage, exact: true },
    { path: `/${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_ENTRIES}`, component: RoutePlaningEntries, exact: true },
    {
        path: `/${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_ENTRY_VIEW}`,
        component: RoutePlaningEntryView,
        exact: true,
    },

    // Profile
    { path: PATHS.USER_PROFILE.DEFAULT, component: UserProfile },

    // Shipping Label
    { path: PATHS.SHIPPING_LABEL.VIEW, component: ShippingLabelViewPage },

    // Rider Tracking
    { path: PATHS.ADVANCED_RIDER_TRACKING.DEFAULT, component: AdvancedRiderTrackingPage },

    // Imports
    { path: PATHS.IMPORTS.DELIVERY_IMPORTS.DEFAULT, component: DeliveryImportPage },
    { path: PATHS.IMPORTS.BILLING_IMPORTS.DEFAULT, component: BillingImportPage },
    { path: PATHS.IMPORTS.RIDER_COMMISSION_IMPORTS.DEFAULT, component: RiderCommissionImportPage },

    // Delivery Settings
    { path: PATHS.SETTINGS.DELIVERY.EXTERNAL_SYSTEM_CONNECTIONS, component: ExternalSystemConnections },
    { path: PATHS.SETTINGS.DELIVERY.EXCEL_CSV_IMPORT, component: ExcelCsvConfigurationPage },

    // Warehouse
    { path: PATHS.WAREHOUSE_SITE.DEFAULT, component: WarehouseSiteManagementPage },
    { path: PATHS.WAREHOUSE_SITE.FORM_VIEW, component: WarehouseSiteFormPage },
    { path: PATHS.WAREHOUSE_SITE.LOCATION_FORM, component: WarehouseZonePage },
    { path: PATHS.WAREHOUSE_SITE.LOCATION_BIN_FORM, component: BinLocationFormPage },

    { path: PATHS.PRODUCT_MASTER_DATA.DEFAULT, component: ProductMasterDataManagementPage },
    { path: PATHS.PRODUCT_MASTER_DATA.FORM_VIEW, component: ProductMasterDataFormPage },

    { path: PATHS.STOCKS_RECEIVING.DEFAULT, component: StocksReceivingManagementPage }, // Stock Receiving Default ( List )
    { path: PATHS.STOCKS_RECEIVING.FORM_VIEW, component: StocksReceivingFormPage }, // Stock Receiving Form
    { path: PATHS.STOCKS_RECEIVING.DELETED_LIST, component: StocksReceivingDeletedListPage }, // Stock Receiving Deleted ( List )

    // Stocks Adjustment
    { path: PATHS.STOCK_ADJUSTMENT.DEFAULT, component: StockAdjustmentPage }, // Stock Adjustment
    { path: PATHS.STOCK_ADJUSTMENT.FORM, component: StockAdjustmentFormPage }, // Stock Adjustment Form

    // Billing
    { path: PATHS.PAYMENTS.BILLING_LIST, component: BillingManagementPage },
    { path: PATHS.PAYMENTS.BILLING_FORM, component: BillingSettingFormPage },

    // Rider Commissions
    { path: PATHS.PAYMENTS.RIDER_COMMISSIONS_LIST, component: RiderCommissionPage },
    { path: PATHS.PAYMENTS.RIDER_COMMISSIONS_FORM, component: RiderCommissionFormPage },

    // BillingRecords
    { path: PATHS.PAYMENTS.BILLING_RECORD, component: BillingRecordFormPage },
    { path: PATHS.PAYMENTS.RIDER_COMMISSION_RECORD, component: RiderCommissionRecordFormPage },

    // Earning Summary
    { path: PATHS.PAYMENTS.EARNING_SUMMARY, component: EarningSummaryPage },

    // Notifications
    { path: PATHS.NOTIFICATION.DEFAULT, component: NotificationsView },

    // Reports
    { path: PATHS.INVENTORY_REPORT.DEFAULT, component: InventoryReportViewPage },

    // Label Print
    { path: `/${PATHS.LABEL_PRINT.SHIPPING_LABEL.LIST_VIEW}`, component: LabelPrintListPage, exact: true },
    { path: `/${PATHS.LABEL_PRINT.SHIPPING_LABEL.BATCH_PRINT_VIEW}`, component: BatchPrintPage },
    { path: PATHS.LABEL_PRINT.PRINTER_SETTINGS.DEFAULT, component: PrinterSettingsPage },
    { path: `/${PATHS.LABEL_PRINT.ARCHIVED_BATCH_PRINTS.DEFAULT}`, component: ArchivedBatchPrint, exact: true },

    // Temp
    { path: '/test-page', component: TestDataGrid },

    // Developer
    { path: `/${PATHS.DEVELOPER.API_SERVICE}`, component: ApiSettingsPartnerList, exact: true },
    { path: `/${PATHS.DEVELOPER.API_PORTAL}`, component: ApiPortalPage, exact: true },
    { path: `/${PATHS.DEVELOPER.API_KEY_MANAGE}`, component: ManageApiKeyPage, exact: true },
    { path: `/${PATHS.DEVELOPER.API_KEY_CREATE}`, component: GenerateApiKeyPage, exact: true },
    { path: `/${PATHS.DEVELOPER.API_DASHBOARD}`, component: ClientDashboard, exact: true },
    { path: `/${PATHS.DEVELOPER.WEBHOOK_CREATE}`, component: CreateWebhookPage, exact: true },
    { path: `/${PATHS.DEVELOPER.WEBHOOK_MANAGE}`, component: ManageWebhookPage, exact: true },
    {
        path: `/${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.LIST}`,
        component: SandboxAccountListViewPage,
        exact: true,
    },
    {
        path: `/${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.FORM}`,
        component: SandboxAccountFormViewPage,
    },

    // this route should be at the end of all other routes
    { path: '/', exact: true, component: () => <Redirect to="/home" /> },
];

const publicRoutes = [
    { path: '/logout', component: Logout },
    { path: '/login', component: LoginPage },
    { path: '/forgot-password', component: ForgetPasswordPage },
    { path: '/temporary-password-reset', component: TemporaryPasswordResetPage },
    { path: '/reset-password', component: ResetPasswordPage },
    { path: '/verify', component: VerifyUserPage },
    { path: '/verify-rider', component: VerifyRiderPage },
    { path: '/under-maintenance', component: UnderMaintenancePage },
    { path: PATHS.SWITCH_PROFILE.DEFAULT, component: SwitchProfilePage },
    { path: '/tracking', component: DeliveryTrackingPage },
    { path: PATHS.SHIPPING_LABEL.PRINT_VIEW, component: ShippingLabelPrintPage },
    { path: PATHS.TERMS_AND_CONDITIONS.SCOOTIX_APPLICATION['V1.0'], component: TermsConditionScootixApplicationV1 },
    { path: PATHS.TERMS_AND_CONDITIONS.SCOOTIX_APPLICATION['V2.0'], component: TermsConditionScootixApplicationV2 },
];

export { authProtectedRoutes, publicRoutes };
