import * as React from 'react';
import PropTypes from 'prop-types';

function Settings(props) {
    const { color, width, height } = props;
    return (
        <svg
            width={width || 24}
            height={height || 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.1854 4.67675C11.6469 2.77442 14.3531 2.77442 14.8146 4.67675C14.8838 4.96253 15.0196 5.22793 15.2108 5.45133C15.402 5.67474 15.6432 5.84984 15.9149 5.96239C16.1865 6.07494 16.4809 6.12176 16.7741 6.09902C17.0673 6.07629 17.3509 5.98466 17.602 5.83158C19.2736 4.81325 21.1878 6.72642 20.1695 8.39908C20.0166 8.65004 19.9252 8.93353 19.9025 9.2265C19.8798 9.51948 19.9266 9.81367 20.039 10.0852C20.1514 10.3567 20.3263 10.5978 20.5495 10.789C20.7726 10.9802 21.0377 11.116 21.3233 11.1854C23.2256 11.6469 23.2256 14.3531 21.3233 14.8146C21.0375 14.8838 20.7721 15.0196 20.5487 15.2108C20.3253 15.402 20.1502 15.6432 20.0376 15.9149C19.9251 16.1865 19.8782 16.4809 19.901 16.7741C19.9237 17.0673 20.0153 17.3509 20.1684 17.602C21.1868 19.2736 19.2736 21.1878 17.6009 20.1695C17.35 20.0166 17.0665 19.9252 16.7735 19.9025C16.4805 19.8798 16.1863 19.9266 15.9148 20.039C15.6433 20.1514 15.4022 20.3263 15.211 20.5495C15.0198 20.7726 14.884 21.0377 14.8146 21.3233C14.3531 23.2256 11.6469 23.2256 11.1854 21.3233C11.1162 21.0375 10.9804 20.7721 10.7892 20.5487C10.598 20.3253 10.3568 20.1502 10.0851 20.0376C9.81347 19.9251 9.51907 19.8782 9.2259 19.901C8.93273 19.9237 8.64907 20.0153 8.398 20.1684C6.72642 21.1868 4.81217 19.2736 5.8305 17.6009C5.98336 17.35 6.07485 17.0665 6.09752 16.7735C6.1202 16.4805 6.07343 16.1863 5.96101 15.9148C5.84859 15.6433 5.6737 15.4022 5.45055 15.211C5.22739 15.0198 4.96228 14.884 4.67675 14.8146C2.77442 14.3531 2.77442 11.6469 4.67675 11.1854C4.96253 11.1162 5.22793 10.9804 5.45133 10.7892C5.67474 10.598 5.84984 10.3568 5.96239 10.0851C6.07494 9.81347 6.12176 9.51907 6.09902 9.2259C6.07629 8.93273 5.98466 8.64907 5.83158 8.398C4.81325 6.72642 6.72642 4.81217 8.39908 5.8305C9.48242 6.48917 10.8864 5.90633 11.1854 4.67675Z"
                stroke={color || '#FF9300'}
                strokeWidth="1.83333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13 16.25C14.7949 16.25 16.25 14.7949 16.25 13C16.25 11.2051 14.7949 9.75 13 9.75C11.2051 9.75 9.75 11.2051 9.75 13C9.75 14.7949 11.2051 16.25 13 16.25Z"
                stroke={color || '#FF9300'}
                strokeWidth="1.83333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

Settings.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};
export default Settings;
