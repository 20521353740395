/* eslint-disable array-callback-return */
export const validateItems = (allStocks) => {
    let isValid = true;
    let message = null;

    let isErrorMet = false;

    allStocks.map((val) => {
        const {
            productMasterDataDescription,
            skuCode,
            manufactureDate,
            expiryDate,
            unitOfMeasure,
            warehouseZoneName,
            binLocationName,
            adjustingQuantity,
            methodOfAdjustment,
        } = val;

        if (isErrorMet) {
            return;
        }

        if (val.productMasterData && val.productMasterData.takeawayMethod) {
            const { takeawayMethod } = val.productMasterData;
            if (takeawayMethod === 'FEFO') {
                if (!manufactureDate) {
                    isValid = false;
                    isErrorMet = true;
                    message = 'Fill Manufacture Date';
                    return;
                }
                if (!expiryDate) {
                    isValid = false;
                    isErrorMet = true;
                    message = 'Fill Expiry Date';
                    return;
                }
            }
        }

        if (
            !productMasterDataDescription ||
            !skuCode ||
            !unitOfMeasure ||
            !adjustingQuantity ||
            !warehouseZoneName ||
            !binLocationName ||
            !methodOfAdjustment
        ) {
            isValid = false;
        }
    });

    return { message, isValid };
};
