/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalBody,
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody,
    Spinner,
    ModalHeader,
    Row,
    Col,
    FormGroup,
    Label,
} from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

// #region imports
import { Select } from 'components';
import { RIDER_ICON_IMAGE } from 'definitions/assets';
import { centerElementContainerStyles } from 'styles';

// #region utils
import { __DEV__, __TEST__ } from 'utils/checks';
import { allKmRadiusOptions } from 'constants/db/client-setting';
import { MERCHANT_SETTING_DEFAULT } from 'constants/db/merchant-setting';
import { getVisitingObject } from 'utils/checkAuth';
import { calcCrow } from 'utils/functions';
import { getDefaultValueForSelect, isObject, reactSelectCustomStyles } from 'utils/helpers';
import { filterRidersBasedByAssigningDeliveryConditions } from 'utils/conditions/delivery';

// #region services
import { getAllRidersCoordinatesByClient } from 'services/rider.service';
import { getMerchantSettingByMerchantIdService } from 'services/merchant-setting.service';

function SelectRiderModal(props) {
    const {
        pickupLocationMeta,
        toggleModal,
        handleReturnSelectedRider,
        showRiderSelectionModal,
        handleReturnDefaultRiders,
        riderId,
        deliveryData,
        handleReturnActiveRiders,
    } = props;

    // loading
    const [loading, setLoading] = useState(0);

    const [merchantSetting, setMerchantSetting] = useState({});

    // riders
    const [allRangedRiders, setAllRangedRiders] = useState([]);
    const [allRiders, setAllRiders] = useState();

    // goe
    const [currentGeo, setCurrentGeo] = useState(null);

    const [selectedRider, setSelectedRider] = useState(null);

    const [customMapLocation, setCustomMapLocation] = useState(null);

    const [selectedKmRadius, setSelectedKmRadius] = useState(null);

    useEffect(() => {
        if (deliveryData.merchantId) {
            loadMerchantSettings(deliveryData.merchantId);
        }
    }, [deliveryData.merchantId]);

    useEffect(() => {
        deliveryData.merchantId && loadRidersByClient();
        const interval = setInterval(() => {
            // let isAllRidersLoaded = false;
            if (allRiders) {
                // isAllRidersLoaded = true;
            }
            deliveryData.merchantId && loadRidersByClient(false);
        }, 15000);
        return () => clearInterval(interval);
    }, [deliveryData.merchantId]);

    /**
     * If Modal Not Opened Will FIlter Users By Pickup Location
     * If Modal Opened and Custom Location Not set Then Filter By Current User GEO
     * If Modal Opened and Custom Location set then filter By Custom Location
     */
    useEffect(() => {
        if (allRiders && merchantSetting.delivery && currentGeo) {
            if (showRiderSelectionModal) {
                if (customMapLocation) {
                    filterRangedRidersByCustomLocation();
                } else {
                    filterRangedUsersByCurrentUserGeo();
                }
            } else if (!riderId) {
                filterRangedRidersByPickupLocation();
            }
        }
    }, [merchantSetting.delivery, allRiders, currentGeo, pickupLocationMeta]);

    // effect to get geo data
    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position) {
                    setCurrentGeo({
                        coordinates: {
                            lng: position.coords.longitude,
                            lat: position.coords.latitude,
                        },
                    });
                }
            });
        } else {
            console.log('GEO Not Available');
        }
    }, []);

    const loadMerchantSettings = async (id) => {
        setLoading((prevState) => prevState + 1);
        const { visitingClient } = getVisitingObject();
        if (visitingClient) {
            try {
                const { data } = await getMerchantSettingByMerchantIdService(id);
                if (data && data.settings) {
                    setMerchantSetting(data.settings);
                } else {
                    setMerchantSetting(MERCHANT_SETTING_DEFAULT);
                }
            } catch (e) {
                console.log(e);
            }
        }
        setLoading((prevState) => prevState - 1);
    };

    const loadRidersByClient = async (isLoading) => {
        if (isLoading) {
            setLoading((prevState) => prevState + 1);
        }
        const { visitingClient } = getVisitingObject();
        if (visitingClient) {
            try {
                const { data } = await getAllRidersCoordinatesByClient(visitingClient._id);
                if (data && data.docs) {
                    const docs = mapDocs(data.docs);
                    const filteredRiders = filterRidersBasedByAssigningDeliveryConditions(docs, deliveryData);
                    setAllRiders(filteredRiders);
                    handleReturnActiveRiders(filteredRiders);
                } else {
                    setAllRiders([]);
                }
            } catch (e) {
                console.log(e);
            }
        }
        if (isLoading) {
            setLoading((prevState) => prevState - 1);
        }
    };

    const mapDocs = (docs) => {
        let allDocs = [];
        allDocs = docs.map((x) => ({
            ...x,
            merchant: x.merchantId && isObject(x.merchantId) ? x.merchantId : null,
            merchantId: x.merchantId && isObject(x.merchantId) ? x.merchantId._id : x.merchantId,
        }));
        return allDocs;
    };

    const filterRangedUsersByCurrentUserGeo = () => {
        if (showRiderSelectionModal) {
            if (merchantSetting.delivery && allRiders) {
                const selectedRadius = selectedKmRadius || 10;
                const kmRadiusInMeters = selectedRadius * 1000;
                const filteredRiders = [];
                allRiders.map((rider) => {
                    if (!selectedKmRadius) {
                        filteredRiders.push(rider);
                        return rider;
                    }

                    if (rider.currentCoordinates) {
                        const calculatedDistance = calcCrow(currentGeo.coordinates, {
                            lat: rider.currentCoordinates.latitude,
                            lng: rider.currentCoordinates.longitude,
                        });
                        if (calculatedDistance < kmRadiusInMeters) {
                            filteredRiders.push(rider);
                        }
                    }
                    return rider;
                });
                (__TEST__ || __DEV__) && console.log('Current GEO :', currentGeo.coordinates);
                handleReturnDefaultRiders(allRiders, selectedKmRadius);
                setAllRangedRiders(filteredRiders);
            }
        }
    };

    const filterRangedRidersByPickupLocation = () => {
        if (merchantSetting.delivery && allRiders && pickupLocationMeta) {
            // console.log('FILTERED BY PICKUP AND PICKUP FOUND');
            const selectedRadius = selectedKmRadius || 10;
            const kmRadiusInMeters = selectedRadius * 1000;
            const filteredRiders = [];
            allRiders.map((rider) => {
                if (!selectedKmRadius) {
                    filteredRiders.push(rider);
                    return rider;
                }
                if (rider.currentCoordinates) {
                    const calculatedDistance = calcCrow(pickupLocationMeta.coordinates, {
                        lat: rider.currentCoordinates.latitude,
                        lng: rider.currentCoordinates.longitude,
                    });
                    if (calculatedDistance < kmRadiusInMeters) {
                        filteredRiders.push(rider);
                    }
                }
                return rider;
            });
            handleReturnDefaultRiders(allRiders, selectedKmRadius);
            setAllRangedRiders(filteredRiders);
            return;
        }
        // console.log('FILTERED BY PICKUP BUT NO PICKUP FOUND');
        setAllRangedRiders([]);
        handleReturnDefaultRiders(allRiders || [], selectedKmRadius);
    };

    const filterRangedRidersByCustomLocation = () => {
        if (customMapLocation && merchantSetting.delivery && allRiders) {
            const selectedRadius = selectedKmRadius || 10;
            const kmRadiusInMeters = selectedRadius * 1000;
            const filteredRiders = [];
            allRiders.map((rider) => {
                if (!selectedKmRadius) {
                    filteredRiders.push(rider);
                    return rider;
                }
                if (rider.currentCoordinates) {
                    const calculatedDistance = calcCrow(customMapLocation.coordinates, {
                        lat: rider.currentCoordinates.latitude,
                        lng: rider.currentCoordinates.longitude,
                    });
                    if (calculatedDistance < kmRadiusInMeters) {
                        filteredRiders.push(rider);
                    }
                }
                return rider;
            });
            setAllRangedRiders(filteredRiders);
        }
    };

    const RiderMarker = ({ _id, fullName, mobileNo, ...otherProps }) => (
        <div>
            <img
                id={`marker${_id}`}
                src={RIDER_ICON_IMAGE}
                height={25}
                width={25}
                alt=""
                onClick={() => setSelectedRider({ _id, ...otherProps })}
            />
            <UncontrolledPopover
                className="custom-popover"
                trigger="hover"
                target={`marker${_id}`}
                placement="right"
                style={{ zIndex: 1000000 }}
            >
                <PopoverHeader className="custom-popover">{fullName || ''}</PopoverHeader>
                <PopoverBody>{mobileNo || ''}</PopoverBody>
            </UncontrolledPopover>
        </div>
    );

    RiderMarker.propTypes = {
        _id: PropTypes.string,
        fullName: PropTypes.string,
        mobileNo: PropTypes.string,
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        if (id === 'kmRadius') {
            setSelectedKmRadius(event.value);
        }
    };

    const returnDataToPrevComp = () => {
        handleReturnSelectedRider(selectedRider, allRangedRiders, '');
    };

    return (
        <>
            <div className="track-map-modal-container">
                <Modal
                    size="xl"
                    isOpen={showRiderSelectionModal}
                    center
                    toggle={toggleModal}
                    className="modal-dialog modal-dialog-centered"
                >
                    <ModalHeader>Select Rider </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="4" sm="4" xs="4">
                                <FormGroup>
                                    <Label htmlFor="name">KM Radius</Label>
                                    <Select
                                        value={getDefaultValueForSelect(selectedKmRadius)}
                                        options={allKmRadiusOptions.map((x) => ({
                                            label: x.label,
                                            value: x.value,
                                        }))}
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => handleSelectChange(event, 'kmRadius')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {customMapLocation && (
                            <Row style={{ ...centerElementContainerStyles }}>
                                <motion.div
                                    style={{ fontSize: 15 }}
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => filterRangedRidersByCustomLocation()}
                                >
                                    <i className="fas fa-search"></i>
                                    {'  '} <span style={{ marginLeft: 5 }}>Scan This Area</span>
                                </motion.div>
                            </Row>
                        )}
                        <div
                            style={
                                loading
                                    ? { height: '70vh', width: '100%', ...centerElementContainerStyles }
                                    : { height: '70vh', width: '100%' }
                            }
                        >
                            {!loading && currentGeo && currentGeo.coordinates ? (
                                <GoogleMapReact
                                    defaultCenter={{
                                        lat: currentGeo.coordinates.lat,
                                        lng: currentGeo.coordinates.lng,
                                    }}
                                    defaultZoom={18}
                                    draggable
                                    onDragEnd={(data) => {
                                        if (data.center) {
                                            setCustomMapLocation({
                                                coordinates: { lat: data.center.lat(), lng: data.center.lng() },
                                            });
                                        }
                                    }}
                                >
                                    {allRangedRiders
                                        .filter((x) => x?.currentCoordinates?.latitude)
                                        .map((marker, i) => (
                                            <RiderMarker
                                                key={marker._id}
                                                lat={marker.currentCoordinates.latitude}
                                                lng={marker.currentCoordinates.longitude}
                                                _id={marker._id}
                                                {...marker}
                                            />
                                        ))}
                                </GoogleMapReact>
                            ) : (
                                <div>
                                    <Spinner size="50" />
                                </div>
                            )}
                        </div>

                        {selectedRider && (
                            <>
                                <br />
                                <Row style={{ ...centerElementContainerStyles }}>
                                    <motion.div
                                        style={{ fontSize: 15 }}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-btn-radius mb-3 pr-3 pl-3 shadow-lg nw-md"
                                        onClick={() => {
                                            if (handleReturnSelectedRider) {
                                                returnDataToPrevComp();
                                                toggleModal();
                                            }
                                        }}
                                    >
                                        {/* <i className="fas fa-search"></i> */}
                                        {'  '} <span style={{ marginLeft: 5 }}>Select Rider</span>
                                    </motion.div>
                                </Row>
                            </>
                        )}
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
}
SelectRiderModal.propTypes = {
    toggleModal: PropTypes.func,
    handleReturnSelectedRider: PropTypes.func,
    handleReturnDefaultRiders: PropTypes.func,
    showRiderSelectionModal: PropTypes.bool,
    riderId: PropTypes.string,
    pickupLocationMeta: PropTypes.object,
    deliveryData: PropTypes.object,
    handleReturnActiveRiders: PropTypes.func,
};

export default SelectRiderModal;
