import React from 'react';
import PropTypes from 'prop-types';

// #region assets | components
import { Bike, Delivered, PickUp, RightArrowColored, Tick } from 'components/Icons';

export default function DeliveryProgressBar(props) {
    const { options = {} } = props;
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                flexDirection: 'row',
                padding: 10,
                paddingTop: 5,
            }}
        >
            <div
                style={{
                    padding: 10,
                    backgroundColor: options.isPickedUp ? 'orange' : '#FFF9EA',
                    borderRadius: 30,
                    border: '1px solid orange',
                }}
            >
                <PickUp color={options.isPickedUp ? null : 'orange'} height={20} width={20} />
            </div>
            <RightArrowColored color={options.isPickedUp ? null : '#E2DBDB'} className="ml-2 mr-2" />
            <div
                style={{
                    padding: 10,
                    backgroundColor: options.isDeliveryStarted ? 'orange' : '#FFF9EA',
                    borderRadius: 30,
                    border: '1px solid orange',
                }}
            >
                <Bike color={options.isDeliveryStarted ? '#FFFFFF' : 'orange'} height={20} width={20} />
            </div>
            <RightArrowColored color={options.isDeliveryStarted ? null : '#E2DBDB'} className="ml-2 mr-2" />
            <div
                style={{
                    padding: 10,
                    backgroundColor: options.isDelivered ? 'orange' : '#FFF9EA',
                    borderRadius: 30,
                    border: '1px solid orange',
                }}
            >
                <Tick color={options.isDelivered ? null : 'orange'} height={20} width={20} />
            </div>
            <RightArrowColored color={options.isDelivered ? null : '#E2DBDB'} className="ml-2 mr-2" />
            <div
                style={{
                    padding: 10,
                    backgroundColor: options.isCompleted ? 'orange' : '#FFF9EA',
                    borderRadius: 30,
                    border: '1px solid orange',
                }}
            >
                <Delivered color={options.isCompleted ? null : 'orange'} height={20} width={20} />
            </div>
        </div>
    );
}

DeliveryProgressBar.propTypes = {
    options: PropTypes.any,
};
