export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'DELIVERY ORDER NUMBER',
    },
    {
        key: 'merchantOrderNo',
        value: 'MERCHANT ORDER NO.',
    },
    {
        key: 'reason',
        value: 'REASON',
    },
];

export const defaultModelData = {
    totalDocs: 0,
    totalPages: 0,
    limit: 2,
    page: 1,
    headers: defaultTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};
