/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

// #region assets | components
import { CustomToast, Select, TableCustom } from 'components';
import SearchIcon from 'assets/images/developer/search.svg';
import excelExport from 'assets/images/developer/exportfile.svg';
import moment from 'moment';

// #region services
import { getWebhooksList } from 'services/partner-api.service';
import { getAllDeliveryIdsService } from 'services/deliveries.service';

// # region utils
import { apiErrorHandlerV2, getDateValue, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';

// #region other imports
import PATHS from 'routes/paths';

// #endregion imports
import { getVisitingObject, hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import ExportModal from 'components/Modals/Export-Dashboard';
import _ from 'lodash';
import {
    exportHeadersWebhookEventTable,
    partnerApiWebhookEvents,
    partnerWebhookEventStatusCodeValues,
    statusDropDownValues,
    tableDefaultFilters,
    webhookEventTableHeaders,
    webhooksTableHeaders,
} from './constants';
import { mapDocs, mapWebhookEventsTableDocs } from './utils';
import CustomizableButton from './components/CustomizableButton';
import { getPartnerApiWebhookEventService } from './services';
import { getDatePeriod } from '../../Api-Service/Dashboard/helpers';
import WebhookEventData from '../../Api-Service/Partner-List/components/webhookEventDataModel';

const currentDate = moment();

const initialFormData = {
    fromDateTime: currentDate.startOf('day').toISOString(),
    toDateTime: currentDate.endOf('day').toISOString(),
};
function WebhookTab(props) {
    const { merchantId, merchantName, location } = props;

    const { visitingClient } = getVisitingObject();

    if (visitingClient) {
        initialFormData.clientId = visitingClient._id;
    }

    const history = useHistory();

    /** Whether the webhooks list is being fetched */
    const [webhooksDataLoading, setWebhooksDataLoading] = useState(false);

    const [formData] = useState(initialFormData);
    const [tableFilterOptions, setTableFilterOptions] = useState(initialFormData);
    const [allDeliveryIds, setAllDeliveryIds] = useState([]);

    const [showExportModalForTable, setShowExportModalForTable] = useState(false);

    const [modalOptions, setModalOptions] = useState({ modal: null, data: {} });

    /** Data for the webhooks table */
    const [webhooksList, setWebhooksList] = useState({
        matchedCount: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: webhooksTableHeaders,
        docs: [],
        status: ['active'],
    });

    /** Loaded data */
    const [webhookEventData, setWebhookEventData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: webhookEventTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });
    /** Table search parameters */
    const [searchParams] = useState(null);
    /** Whether mobile UI */
    // const [isMobileUi] = useState(false);

    const [loading, setLoading] = useState(0);

    /** Trigger the fetching of webhooks list */
    useEffect(() => {
        loadWebhooksList();
        if (merchantId) {
            const { fromDateTime, toDateTime, clientId } = formData;
            loadWebhookEventTableTableData({
                merchantId,
                clientId,
                fromDateTime,
                toDateTime,
            });
        }
        loadAllDeliveryIds();
    }, [merchantId, location.search]);

    /** Handle table search and trigger fetching data again */
    const handleFilter = (field, filterText) => {
        let modifiedFilterText = null;
        if (filterText && filterText !== '') {
            modifiedFilterText = filterText.replaceAll(' ', '$2');
        } else if (!filterText || filterText === '') {
            modifiedFilterText = null;
        }

        loadWebhooksList(undefined, modifiedFilterText);
    };

    /** Call the service and fetch the list of webhooks from the API */
    const loadWebhooksList = async (status, search) => {
        setWebhooksDataLoading(true);

        if (!merchantId) return;

        if (!location.search.includes('tab=webhook')) {
            setWebhooksDataLoading(false);
            return;
        }

        try {
            const { data } = await getWebhooksList(merchantId, status || webhooksList.status, search);
            const { payload } = data;
            if (data && payload) {
                const mappedDocs = mapDocs(payload.docs);
                setWebhooksList((prev) => ({ ...prev, ...payload, docs: mappedDocs }));
            } else {
                toast.custom((t) => <CustomToast text="Failed to load webhooks list" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setWebhooksDataLoading(false);
    };

    const loadWebhookEventTableTableData = (properties, showLoading = true) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getPartnerApiWebhookEventService(properties)
            .then((res) => {
                const { data } = res;
                if (data) {
                    const docs = mapWebhookEventsTableDocs(data || []);
                    setWebhookEventData({ ...webhookEventData, ...data.payload, docs });
                }
            })
            .catch((e) => {
                console.log(e);
                const { message } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    /**
     * Load All Delivery Ids Based on Status
     */
    const loadAllDeliveryIds = async (query = {}, isLoading) => {
        // if (isLoading) {
        //     setCustomLoading((prevState) => ({ ...prevState, deliveryId: false }));
        // }
        try {
            const { data } = await getAllDeliveryIdsService(query);
            if (data && Array.isArray(data)) {
                const mappedData = data.map((x) => ({ ...x, value: x._id }));
                setAllDeliveryIds(mappedData);
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        }
        // if (isLoading) {
        //     setCustomLoading((prevState) => ({ ...prevState, deliveryId: false }));
        // }
    };

    function handleFilterRequest(properties = {}) {
        const { limit, page } = properties;
        if (merchantId) {
            const { clientId, merchantOrderNo, fromDateTime, toDateTime, deliverySequenceId } = tableFilterOptions;
            let { responseStatus, statusCode, eventType } = tableFilterOptions;
            if (responseStatus === 'all') {
                responseStatus = null;
            }
            if (statusCode === 'all') {
                statusCode = null;
            }
            if (eventType === 'all') {
                eventType = null;
            }
            const cleanedProperties = _.pickBy({
                merchantId,
                clientId,
                responseStatus,
                statusCode,
                limit,
                page,
                eventType,
                fromDateTime,
                toDateTime,
                deliverySequenceId,
                merchantOrderNo,
            });
            loadWebhookEventTableTableData(cleanedProperties);
        }
    }
    /** Navigate to the Create Webhook page */
    const handleNewWebhookButton = () => {
        history.push({
            pathname: `/${PATHS.DEVELOPER.WEBHOOK_CREATE}`,
            state: { merchantId, merchantName },
        });
    };

    const handleSelectChange = (event, id) => {
        if (event?.value) {
            if (id === 'responseStatus') {
                setTableFilterOptions({
                    ...tableFilterOptions,
                    [id]: event.value,
                });
                return;
            }
            setTableFilterOptions({
                ...tableFilterOptions,
                [id]: event.value,
            });
        } else {
            setTableFilterOptions({
                ...tableFilterOptions,
                [id]: null,
            });
        }
    };

    const displayModal = (data) => {
        const { meta } = data;

        if (!meta) {
            return;
        }

        // show webhook event data modal
        setModalOptions({
            modal: 'webhookEvent',
            data: {
                name: data?.webhookName,
                data: data?.meta,
            },
        });
    };

    /**
     * Change the modalOptions state so modals can be shown or hidden.
     * See comments on modalOptions state.
     * @param {string} modalName - name of the modal to show - false value hides modal
     */
    const handleTransactionModalToggle = (modalName) => {
        if (modalName) {
            setModalOptions((prev) => ({ ...prev, modal: modalName }));
        } else {
            setModalOptions((prev) => ({ ...prev, modal: null }));
        }
    };
    const handlePageChange = (pageNo) => {
        handleFilterRequest({ ...webhookEventData, ...tableFilterOptions, page: pageNo });
    };

    const handleDateChange = (event) => {
        const { id, value } = event.target;
        setTableFilterOptions({
            ...tableFilterOptions,
            [id]: value ? new Date(value).toISOString() : null,
        });
    };

    function calculateFromDateValidation() {
        if (formData.fromDateTime === formData.toDateTime) {
            return true;
        }

        if (moment(formData.fromDateTime).isAfter(formData.toDateTime)) {
            return true;
        }

        return false;
    }

    /** Change status according to the checkbox in the table header, and trigger fetching data */
    const handleCheckChange = (checkbox, value) => {
        let newStatus;
        if (value === true) {
            if (webhooksList.status.includes(checkbox)) return;
            newStatus = [...webhooksList.status, checkbox];
            setWebhooksList((prev) => ({ ...prev, status: newStatus }));
        } else {
            newStatus = webhooksList.status.filter((status) => status !== checkbox);
            setWebhooksList((prev) => ({ ...prev, status: newStatus }));
        }
        loadWebhooksList(newStatus);
    };

    /** Navigate to the Manage webhook page */
    const handleManageButton = (data) => {
        history.push({
            pathname: `/${PATHS.DEVELOPER.WEBHOOK_MANAGE}`,
            state: { merchantId, merchantName, webhookData: data },
        });
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Card className="shadow default-card">
                            <CardHeader
                                className="border-bottom pl-5"
                                style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}
                            >
                                <Row>
                                    <Col>Manage Webhook</Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <TableCustom
                                    tableData={webhooksList}
                                    loading={webhooksDataLoading}
                                    onFilter={handleFilter}
                                    customActionHandlers={{ handleManageButton }}
                                    searchProp={searchParams}
                                    removeAction
                                    isFixedHeight
                                    showApiWebhookAction
                                    tableHeaderType="MANAGE_WEBHOOKS"
                                    webhooksTableHeaderProps={{
                                        handleNewWebhookButton,
                                        handleCheckChange,
                                        checked: webhooksList.status,
                                    }}
                                    showPagination={false}
                                    filters={[
                                        {
                                            key: 'name',
                                            label: 'Name',
                                        },
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="shadow default-card">
                            <CardHeader
                                className="border-bottom pl-5"
                                style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}
                            >
                                <Row>
                                    <Col>
                                        Webhook Events
                                        {webhooksDataLoading && <Spinner size="sm" className="ml-4" />}
                                    </Col>
                                    <Col xs="auto">
                                        {hasAccess(PERMISSIONS.PARTNER_API_WEBHOOK, ['Export']) && (
                                            <CustomizableButton
                                                buttonTitle="Export"
                                                icon={excelExport}
                                                customStyle={{ marginRight: 10 }}
                                                handleOnClick={() =>
                                                    setShowExportModalForTable(!showExportModalForTable)
                                                }
                                            />
                                        )}
                                    </Col>
                                    {showExportModalForTable && (
                                        <ExportModal
                                            data={{
                                                records: webhookEventData.docs,
                                                columnNames: exportHeadersWebhookEventTable,
                                            }}
                                            toggleModal={() => setShowExportModalForTable(!showExportModalForTable)}
                                            header="ScootiX API Service"
                                            description="Webhooks Events"
                                            fileName={`ScootiX_Webhook_Events_${moment().format('YYYYMMDDHHmmss')}`}
                                            excelTabName="Webhooks Events"
                                            period={getDatePeriod(formData.fromDateTime, formData.toDateTime)}
                                            merchantName={merchantName}
                                        />
                                    )}
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {/** Filter Component */}
                                <Row className="pl-4 pr-4 mt-2" style={{ alignItems: 'center', marginBottom: -10 }}>
                                    <Col style={{ width: '12.5%', minWidth: 100 }}>
                                        {/**
                                         * Dropdowns for the inputs
                                         */}
                                        <FormGroup className="mt-3">
                                            <Label htmlFor="status">Filter by Status</Label>
                                            <Select
                                                value={getDefaultValueForSelect(
                                                    tableFilterOptions.responseStatus,
                                                    statusDropDownValues,
                                                    'value'
                                                )}
                                                options={statusDropDownValues}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'responseStatus')}
                                                isClearable
                                                isLoading={loading}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col style={{ width: '12.5%', minWidth: 100 }}>
                                        <FormGroup className="mt-3">
                                            <Label htmlFor="statusCode">Filter by Status Code</Label>
                                            <Select
                                                value={getDefaultValueForSelect(
                                                    tableFilterOptions.statusCode,
                                                    [
                                                        { label: 'All', value: 'all' },
                                                        ...partnerWebhookEventStatusCodeValues,
                                                    ],
                                                    'value'
                                                )}
                                                options={[
                                                    { label: 'All', value: 'all' },
                                                    ...partnerWebhookEventStatusCodeValues,
                                                ]}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'statusCode')}
                                                required
                                                isClearable
                                                isLoading={loading}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col style={{ width: '12.5%', minWidth: 100 }}>
                                        <FormGroup className="mt-3">
                                            <Label htmlFor="eventType">Event Type</Label>
                                            <Select
                                                value={getDefaultValueForSelect(
                                                    tableFilterOptions.eventType,
                                                    [{ label: 'All', value: 'all' }, ...partnerApiWebhookEvents],
                                                    'value'
                                                )}
                                                options={[{ label: 'All', value: 'all' }, ...partnerApiWebhookEvents]}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'eventType')}
                                                required
                                                isClearable
                                                isLoading={loading}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col style={{ width: '12.5%', minWidth: 100 }}>
                                        <FormGroup className="mt-3">
                                            <Label htmlFor="deliveryId">Delivery ID</Label>
                                            <Select
                                                value={getDefaultValueForSelect(
                                                    tableFilterOptions.deliverySequenceId,
                                                    allDeliveryIds.map((val) => ({
                                                        ...val,
                                                        value: val.referenceNumber,
                                                        label: val.referenceNumber,
                                                    })),
                                                    'value'
                                                )}
                                                options={allDeliveryIds.map((val) => ({
                                                    ...val,
                                                    value: val.referenceNumber,
                                                    label: val.referenceNumber,
                                                }))}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'deliverySequenceId')}
                                                required
                                                isClearable
                                                // isLoading={customLoading.deliveryId}
                                                maxMenuHeight={150}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col style={{ width: '12.5%', minWidth: 100 }}>
                                        <FormGroup className="mt-3">
                                            <Label htmlFor="merchantOrderId">Merchant Order ID</Label>
                                            <Select
                                                value={getDefaultValueForSelect(
                                                    tableFilterOptions.merchantOrderNo,
                                                    allDeliveryIds.map((val) => ({
                                                        ...val,
                                                        value: val.merchantOrderNo,
                                                        label: val.merchantOrderNo,
                                                    })),
                                                    'value'
                                                )}
                                                options={allDeliveryIds.map((val) => ({
                                                    ...val,
                                                    value: val.merchantOrderNo,
                                                    label: val.merchantOrderNo,
                                                }))}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'merchantOrderNo')}
                                                required
                                                isClearable
                                                // isLoading={customLoading.deliveryId}
                                                maxMenuHeight={150}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col style={{ width: '12.5%', minWidth: 100 }}>
                                        <FormGroup className="mt-3">
                                            <Label htmlFor="fromDate">From Date and Time</Label>
                                            <Input
                                                onChange={handleDateChange}
                                                type="datetime-local"
                                                defaultValue={getDateValue(new Date(), 'datetime-local')}
                                                className="form-control"
                                                value={getDateValue(tableFilterOptions.fromDateTime, 'datetime-local')}
                                                id="fromDateTime"
                                                invalid={calculateFromDateValidation()}
                                                max={getDateValue(
                                                    moment(tableFilterOptions.toDateTime),
                                                    'datetime-local'
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col style={{ width: '12.5%', minWidth: 100 }}>
                                        <FormGroup className="mt-3">
                                            <Label htmlFor="toDate">To Date and Time</Label>
                                            <Input
                                                onChange={handleDateChange}
                                                type="datetime-local"
                                                defaultValue={getDateValue(new Date(), 'datetime-local')}
                                                className="form-control"
                                                value={getDateValue(tableFilterOptions.toDateTime, 'datetime-local')}
                                                id="toDateTime"
                                                max={getDateValue(moment(), 'datetime-local')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {/** Filter Button */}
                                    <Col style={{ width: '12.5%', marginTop: 25, minWidth: 100 }}>
                                        <CustomizableButton
                                            icon={SearchIcon}
                                            buttonTitle="Filter Request"
                                            handleOnClick={() => handleFilterRequest()}
                                            // isMobileUi={isMobileUi}
                                        />
                                    </Col>
                                </Row>
                                {/** Custom Table */}
                                <div>
                                    <Row>
                                        <TableCustom
                                            tableData={webhookEventData}
                                            onPageChange={handlePageChange}
                                            loading={loading}
                                            // showEdit={hasAccess(PERMISSIONS.PARTNER_API_WEBHOOK, ['Edit'])}
                                            showDelete={false}
                                            showView={false}
                                            removeId
                                            showApiWebhookAction={hasAccess(PERMISSIONS.PARTNER_API_WEBHOOK, ['Edit'])}
                                            isFullScreenShow={false}
                                            showStickyView
                                            filters={tableDefaultFilters}
                                            isFilter={false}
                                            isSearch={false}
                                            removeAction
                                            isFixedHeight
                                            customActionHandlers={{ displayModal }}
                                        />
                                        <WebhookEventData
                                            show={modalOptions.modal === 'webhookEvent'}
                                            handleToggle={handleTransactionModalToggle}
                                            name={modalOptions.data.name}
                                            data={modalOptions.data.data}
                                        />
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

WebhookTab.propTypes = {
    merchantId: PropTypes.string.isRequired,
    merchantName: PropTypes.string.isRequired,
    location: PropTypes.any,
};

export default WebhookTab;
