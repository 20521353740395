/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-self-assign */
// This function takes in latitude and longitude of two locations
// and returns the distance between them as the crow flies (in meters)
export function calcCrow(coords1, coords2) {
    // var R = 6.371; // km
    const R = 6371000;
    const dLat = toRad(coords2.lat - coords1.lat);
    const dLon = toRad(coords2.lng - coords1.lng);
    const lat1 = toRad(coords1.lat);
    const lat2 = toRad(coords2.lat);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
}

// Converts numeric degrees to radians
function toRad(Value) {
    return (Value * Math.PI) / 180;
}

export const getAddressObject = (address_components, addressLabel) => {
    const ShouldBeComponent = {
        street_number: ['street_number'],
        postal_code: ['postal_code'],
        street: ['street_address', 'route'],
        province: [
            'administrative_area_level_1',
            'administrative_area_level_2',
            'administrative_area_level_3',
            'administrative_area_level_4',
            'administrative_area_level_5',
        ],
        city: [
            'locality',
            'sublocality',
            'sublocality_level_1',
            'sublocality_level_2',
            'sublocality_level_3',
            'sublocality_level_4',
        ],
        country: ['country'],
    };

    const address = {
        street_number: '',
        postal_code: '',
        street: '',
        province: '',
        city: '',
        country: '',
    };

    address_components.forEach((component) => {
        for (const shouldBe in ShouldBeComponent) {
            if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                if (shouldBe === 'country') {
                    address[shouldBe] = component.short_name;
                } else {
                    address[shouldBe] = component.long_name;
                }
            }
        }
    });
    // Fix the shape to match our schema
    address.address = addressLabel || `${address.street_number} ${address.street}`;
    address.addressSimple = `${address.street_number} ${address.street}`;
    // delete address.street_number;
    // delete address.street;
    if (address.province) {
        address.province = address.province;
    }
    if (address.country === 'US') {
        address.state = address.province;

        // delete address.province;
    }
    if (address.country === 'MY') {
        address.state = address.province;
        // delete address.province;
    }
    return address;
};
