/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

// #region assets | components
import { Breadcrumbs, CustomToast } from 'components';
import CheckCircleIcon from 'components/Icons/Check-Circle';
import CrossCircleIcon from 'components/Icons/CrossCircle';

// #region services
import { createWebhook } from 'services/partner-api.service';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #region other imports
import PATHS from 'routes/paths';

// #endregion imports
import { addToValues, authenticationTypes, emptyFormValues } from '../constants';

function CreateWebhookPage(props) {
    const { location } = props;
    const state = location?.state;

    const history = useHistory();

    /** State for storing entered form data */
    const [formData, setFormData] = useState(emptyFormValues);
    /** Whether the submit operation is in progress  */
    const [inProgress, setInProgress] = useState(false);

    if (!state) {
        history.push({
            pathname: `/${PATHS.DEVELOPER.API_PORTAL}`,
        });
        return <div />;
    }

    const { merchantId, merchantName } = state;

    const breadcrumbItems = [
        { title: 'Scootix', link: PATHS.HOME.DEFAULT },
        { title: 'API Portal', link: `/${PATHS.DEVELOPER.API_PORTAL}` },
        { title: merchantName, link: '#' },
        { title: 'Webhook', link: '#' },
        { title: 'Create Webhook', link: '#' },
    ];

    /** Call the relevant service to create a Webhook */
    const handleSubmit = async () => {
        setInProgress(true);

        const reqBody = { ...formData, merchantId };

        try {
            const response = await createWebhook(reqBody);
            const { data } = response;
            const { payload } = data;
            if (data && payload) {
                toast.custom((t) => <CustomToast text="Webhook created successfully!" t={t} type="success" />, {
                    position: 'top-right',
                });
                history.push({
                    pathname: `/${PATHS.DEVELOPER.WEBHOOK_MANAGE}`,
                    state: { ...state, webhookData: payload },
                });
            } else {
                toast.custom((t) => <CustomToast text="Failed to create webhook" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setInProgress(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    /** Navigate back to the Webhooks page when form is cancelled */
    const handleCancel = () => {
        history.push({
            pathname: `/${PATHS.DEVELOPER.API_PORTAL}`,
            state,
            search: '?tab=webhook',
        });
    };

    /** Handle changing of dropdown inputs */
    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    /**
     * Navigate to the documentation site.
     */
    const handleDocsLinkClick = () => {
        window.open('https://docs.scootix.com.my', '_blank');
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                <Row>
                    <Col>
                        <Card className="shadow default-card">
                            <CardHeader className="pl-5">
                                <Row>
                                    <Col xs="auto" style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}>
                                        New Webhook
                                    </Col>
                                    <Col className="text-muted ml-5">
                                        <span>Need Help? </span>
                                        <span
                                            role="button"
                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                            onClick={handleDocsLinkClick}
                                        >
                                            Check out the docs
                                        </span>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="px-5">
                                <AvForm
                                    className="needs-vaildation default-form"
                                    noValidate
                                    onValidSubmit={handleSubmit}
                                >
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label htmlFor="name" className="text-muted">
                                                    Name
                                                </Label>
                                                <AvField
                                                    name="name"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                    validate={{ required: { value: true } }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label htmlFor="postUrl" className="text-muted">
                                                    Post URL
                                                </Label>
                                                <AvField
                                                    name="postUrl"
                                                    type="text"
                                                    errorMessage="Please add valid URL"
                                                    className="form-control"
                                                    pattern="^(http(s)?:\/\/)"
                                                    value={formData.postUrl}
                                                    onChange={handleInputChange}
                                                    validate={{ required: { value: true } }}
                                                    autoComplete="edit-postUrl" // disable auto complete
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label htmlFor="eventGroup" className="text-muted">
                                                    Event Group
                                                </Label>
                                                <AvField name="eventGroup" type="select" onChange={handleSelectChange}>
                                                    <option value="deliveryUpdates">Delivery Updates</option>
                                                </AvField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label htmlFor="authenticationType" className="text-muted">
                                                    Authentication Type
                                                </Label>
                                                <AvField
                                                    name="authenticationType"
                                                    type="select"
                                                    onChange={handleSelectChange}
                                                >
                                                    {authenticationTypes.map((authType) => (
                                                        <option value={authType.value}>{authType.name}</option>
                                                    ))}
                                                </AvField>
                                            </FormGroup>
                                        </Col>
                                        {formData.authenticationType === 'apiKey' && (
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label htmlFor="systemAuthKey" className="text-muted">
                                                        Your System Authorization Key
                                                    </Label>
                                                    <AvField
                                                        name="systemAuthKey"
                                                        type="text"
                                                        className="form-control"
                                                        value={formData.systemAuthKey}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}
                                        {(formData.authenticationType === 'apiKey' ||
                                            formData.authenticationType === 'bearer') && (
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label htmlFor="systemAuthValue" className="text-muted">
                                                        Your System Authorization Value
                                                    </Label>
                                                    <AvField
                                                        name="systemAuthValue"
                                                        type="text"
                                                        className="form-control"
                                                        value={formData.systemAuthValue}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label htmlFor="addTo" className="text-muted">
                                                    Add to
                                                </Label>
                                                <AvField name="addTo" type="select" onChange={handleSelectChange}>
                                                    {addToValues.map((val) => (
                                                        <option value={val.value}>{val.name}</option>
                                                    ))}
                                                </AvField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Alert color="warning" style={{ borderRadius: 8 }}>
                                                Please enter your password to update the webhook.
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <AvField
                                                    name="password"
                                                    type="password"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={formData.password}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="auto">
                                            <Button
                                                className="scootix-btn-radius"
                                                type="submit"
                                                disabled={inProgress}
                                                style={{ borderRadius: 8 }}
                                            >
                                                {inProgress ? (
                                                    <Spinner size="sm" color="warning" className="spinner-white mx-1" />
                                                ) : (
                                                    <CheckCircleIcon color="#FFF" />
                                                )}
                                                <span className="mr-3">&nbsp;Create</span>
                                            </Button>
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                className="form-delete-btn"
                                                style={{ backgroundColor: '#FD4A4A', borderRadius: 8 }}
                                                onClick={handleCancel}
                                            >
                                                <CrossCircleIcon color="#FFF" />
                                                &nbsp;Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

CreateWebhookPage.propTypes = {
    location: PropTypes.any,
};

const HotCreateWebhookPage = hot(CreateWebhookPage);

export default connect(null, {})(HotCreateWebhookPage);
