/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { v4 as uuid } from 'uuid';

function TableFilter(props) {
    const {
        showFilters,
        setShowFilters,
        filters,
        onFilter,
        getFilterTitle,
        isManualSearch,
        setSearch,
        search,
        setIsUp, // set column wise sorting
        setDateFilter,
    } = props;
    return (
        <div className="table-filter">
            <Dropdown
                isOpen={showFilters}
                toggle={() => {
                    setShowFilters(!showFilters);
                    setIsUp({});
                }}
                className="d-inline-block dropdown-table-filter"
            >
                <DropdownToggle tag="button" className="btn header-item waves-effect" id="table-filter-dropdown-toggle">
                    <div className="filter-btn" onClick={() => setShowFilters(!showFilters)}>
                        <i className="fas fa-filter fa-sm "></i>
                        <span style={{ fontSize: 17 }}>{getFilterTitle(search.searchBy)}</span>
                    </div>
                </DropdownToggle>
                <DropdownMenu right className="table-filter-dropdown-menu">
                    {filters.map((x) => (
                        <DropdownItem
                            key={uuid()}
                            onClick={() => {
                                if (!isManualSearch) {
                                    setSearch({
                                        ...search,
                                        searchBy: x.key,
                                        type: x.type,
                                    });
                                }
                                if (onFilter) {
                                    onFilter(x.key, search.searchText);
                                    // if filter type is a dateFilter set as true
                                    if (x.type === 'dateFilter') {
                                        setDateFilter(true);
                                    }
                                }
                            }}
                        >
                            {/* <i className="ri-user-line align-middle mr-1"></i> */}
                            <i className="mdi mdi-circle mr-2 fa-sm" />
                            {x.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

TableFilter.propTypes = {
    setShowFilters: PropTypes.func,
    showFilters: PropTypes.bool,
    filters: PropTypes.array,
    isManualSearch: PropTypes.bool,
    setSearch: PropTypes.func,
    search: PropTypes.object,
    onFilter: PropTypes.func,
    getFilterTitle: PropTypes.func,
    setIsUp: PropTypes.func,
    setDateFilter: PropTypes.func,
};

export default TableFilter;
