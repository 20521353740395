/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Alert, Card, CardBody, Col, Container, FormGroup, Label, Row, Spinner, Input } from 'reactstrap';
import { AvForm, AvCheckbox, AvCheckboxGroup } from 'availity-reactstrap-validation';

import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';

// #region assets | components
import { BackdropLoader, Breadcrumbs, ClientAlerts, CustomToast, Select } from 'components';
import { SubTitle } from 'styles';

// #region imports
import PATHS from 'routes/paths';

// #region services
import { getAllWarehouseSitesService } from 'services/warehouse-site.service';
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';
import { getAllProductMasterDataByMerchantService } from 'services/product-master-data.service';

// #region utils
import { getVisitingObject } from 'utils/checkAuth';
import { apiErrorHandler, getDateValue, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { DEBUG_MODE } from 'utils/checks';

// #endregion imports
import InventoryReportComponent from './components/InventoryReport';
import CycleCountReportComponent from './components/CycleCountReport';
import InventoryTransactionLogComponent from './components/InventoryTransactionLog';
import StocksReceivingReportComponent from './components/StocksReceivingReport';
import StocksIssuingReportComponent from './components/StocksIssuingReport';
import StocksAdjustmentReportComponent from './components/StocksAdjustmentReport';
import {
    getCycleCountReport,
    getInventoryReport,
    getStockAdjustmentReport,
    getStockIssuingReport,
    getStockReceivingReport,
    getTransactionLogReport,
} from './services';
import { validateForm, validateReportOptions } from './utils';
import { reportTypes } from './constants';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Inventory Reports', link: '#' },
];

let initialFormValues = {
    reportType: null,
};

function InventoryReportPage() {
    const _isMounted = useRef(true);

    const { visitingClient, visitingMerchant } = getVisitingObject();

    if (visitingClient) {
        initialFormValues = {
            ...initialFormValues,
            clientId: visitingClient._id,
            clientName: visitingClient.name,
        };
    }

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    // ** States

    // component
    const [backdropMessage, setBackdropMessage] = useState(null);
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [disableEntirePage] = useState(false);
    const [alertMessage] = useState(null);
    const [formAlertMessage, setFormAlertMessage] = useState(null);

    // form
    const [formData, setFormData] = useState(initialFormValues);
    const [formLoading, setFormLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [reportData, setReportData] = useState(null);

    // other
    const [warehouseSites, setWarehouseSites] = useState([]);
    const [merchants, setMerchants] = useState([]);
    const [allProductMasterData, setAllProductMasterData] = useState([]);

    useEffect(() => {
        loadWarehouseSites();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        formData.merchantId && loadAllProductMasterDataByMerchant(formData.merchantId);
    }, [formData.merchantId]);

    // Merchant Loaders
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                if (_isMounted.current) {
                    const { data } = res;
                    if (data.docs) {
                        setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                    }
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                if (_isMounted.current) {
                    const { data } = res;
                    if (data.docs) {
                        setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                    }
                }
            });
        }
    }, []);

    const loadWarehouseSites = async () => {
        try {
            const { data } = await getAllWarehouseSitesService();
            if (_isMounted.current && data && data.docs) {
                setWarehouseSites(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.siteName}`,
                        key: x._id,
                    }))
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadAllProductMasterDataByMerchant = async (merchantId) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getAllProductMasterDataByMerchantService(merchantId);
            if (_isMounted.current && data && data.docs && Array.isArray(data.docs)) {
                setAllProductMasterData(data.docs.map((x) => ({ ...x, label: x.productDescription, value: x._id })));
            }
            if (data && (data.docs || []).length === 0) {
                toast.custom(
                    (t) => <CustomToast text="Product Master Data Not Found For Merchant" t={t} type="warning" />,
                    {
                        position: 'top-right',
                    }
                );
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    // -------------  HANDLERS ---------

    const handleSubmit = async (event, errors) => {
        event.persist();

        if (formLoading) {
            return;
        }

        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }

        const { isFormOptionsValid, optionsMessage } = validateReportOptions(formData);

        if (!isFormOptionsValid) {
            setFormAlertMessage(optionsMessage);
            return;
        }
        setFormAlertMessage(null);

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);
        setBackdropLoading((x) => x + 1);
        setBackdropMessage('Fetching Details');

        try {
            // eslint-disable-next-line no-unused-vars
            let isResponse = null;

            if (formData.reportType === 'Inventory Report') {
                const { data } = await getInventoryReport(formData);
                if (data) {
                    isResponse = true;
                    setReportData(data);
                }
            }
            if (formData.reportType === 'Cycle Count Report') {
                const { data } = await getCycleCountReport(formData);
                if (data) {
                    isResponse = true;
                    setReportData(data);
                }
            }
            if (formData.reportType === 'Inventory Transaction Log') {
                const { data } = await getTransactionLogReport(formData);
                if (data) {
                    isResponse = true;
                    setReportData(data);
                }
            }
            if (formData.reportType === 'Stocks Receiving') {
                const { data } = await getStockReceivingReport(formData);
                if (data) {
                    isResponse = true;
                    setReportData(data);
                }
            }
            if (formData.reportType === 'Stocks Issuing') {
                const { data } = await getStockIssuingReport(formData);
                if (data) {
                    isResponse = true;
                    setReportData(data);
                }
            }
            if (formData.reportType === 'Stocks Adjustments') {
                const { data } = await getStockAdjustmentReport(formData);
                if (data) {
                    isResponse = true;
                    setReportData(data);
                }
            }

            toast.custom((t) => <CustomToast text="Successfully Fetched Details" t={t} type="success" />, {
                position: 'top-right',
            });
            setIsFormSaved(true);
        } catch (e) {
            const errorMessage = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setBackdropMessage(null);
        setBackdropLoading((x) => x - 1);
        setFormLoading(false);
        setSubmitted(false);
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);
        setReportData(null);
        if (event) {
            if (id === 'merchantId') {
                setFormData({
                    ...formData,
                    productMasterDataId: null,
                    [id]: event ? event.value : null,
                });
                return;
            }

            setFormData({
                ...formData,
                [id]: event ? event.value : null,
            });
        } else {
            setFormData({
                ...formData,
                [id]: null,
            });
        }
    };

    const handleCheckChange = (event) => {
        setIsFormSaved(false);
        const { id, checked } = event.target;
        setFormData({
            ...formData,
            [id]: checked,
        });
    };

    // Selects Handlers
    const getMerchantSelect = (_id) => {
        // eslint-disable-next-line no-console
        DEBUG_MODE() && console.log('select-func');
        if (_id) {
            const relevantObj = merchants.find((x) => x._id === _id);
            if (relevantObj) {
                return { label: relevantObj.name, value: relevantObj._id };
            }
        }
        return null;
    };

    const getWarehouseSiteSelect = (_id) => {
        // eslint-disable-next-line no-console
        DEBUG_MODE() && console.log('select-func');
        if (_id) {
            const relevantObj = warehouseSites.find((x) => x._id === _id);
            if (relevantObj) {
                return {
                    label: `${relevantObj.siteName}`,
                    value: relevantObj._id,
                };
            }
        }
        return null;
    };

    const getProductMasterDataSelect = (_id) => {
        // eslint-disable-next-line no-console
        DEBUG_MODE() && console.log('select-func');
        if (_id) {
            const relevantObj = allProductMasterData.find((x) => x._id === _id);
            if (relevantObj) {
                return {
                    label: `${relevantObj.productDescription}`,
                    value: relevantObj._id,
                };
            }
        }
        return null;
    };

    // ** Memos
    const selectedMerchantId = useMemo(() => getMerchantSelect(formData.merchantId), [formData.merchantId, merchants]);
    const selectedWarehouseSiteId = useMemo(
        () => getWarehouseSiteSelect(formData.warehouseSiteId),
        [formData.warehouseSiteId, warehouseSites]
    );
    const selectedProductMasterDataId = useMemo(
        () => getProductMasterDataSelect(formData.productMasterDataId),
        [formData.productMasterDataId, allProductMasterData]
    );
    const selectedReportType = useMemo(() => getDefaultValueForSelect(formData.reportType), [formData.reportType]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <ClientAlerts />
                    {alertMessage && (
                        <Alert color="danger" className="">
                            {alertMessage}
                        </Alert>
                    )}
                    <Card
                        className="shadow-lg"
                        style={
                            !visitingClient || disableEntirePage
                                ? { pointerEvents: 'none', opacity: '0.6' }
                                : { borderRadius: 20 }
                        }
                    >
                        <CardBody>
                            <div
                                style={{
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'col' }}>
                                    <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}>
                                        SELECT FILTERS FOR YOUR REPORT
                                    </SubTitle>
                                </div>
                            </div>
                            <hr />
                            {formAlertMessage && (
                                <Alert color="danger" className="">
                                    {formAlertMessage}
                                </Alert>
                            )}
                            <AvForm
                                autoComplete="off"
                                className="needs-validation default-form"
                                noValidate
                                onSubmit={handleSubmit}
                                id="productMasterDataForm"
                            >
                                <input type="hidden" value="something" />
                                <fieldset disabled={formLoading}>
                                    <div style={{ display: 'flex', flexDirection: 'col' }}>
                                        <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 0 }}>
                                            Filters
                                        </SubTitle>
                                    </div>
                                    <Row className="mt-3">
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="reportType">Report Type</Label>
                                                <Select
                                                    value={selectedReportType}
                                                    options={reportTypes}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'reportType')}
                                                    submitted={submitted}
                                                    validated={validated}
                                                    required
                                                    isClearable
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="warehouseSiteId">Warehouse</Label>
                                                <Select
                                                    value={selectedWarehouseSiteId}
                                                    options={warehouseSites.map((x) => ({
                                                        label: x.label,
                                                        value: x.key,
                                                    }))}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'warehouseSiteId')}
                                                    submitted={submitted}
                                                    validated={validated}
                                                    required
                                                    isClearable
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="merchantId">Merchant</Label>
                                                <Select
                                                    value={selectedMerchantId}
                                                    isClearable
                                                    options={merchants}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                    submitted={submitted}
                                                    validated={validated}
                                                    required={formData.reportType === 'Inventory Transaction Log'}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="productMasterDataId">Product Name</Label>
                                                <Select
                                                    value={selectedProductMasterDataId}
                                                    options={allProductMasterData}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) =>
                                                        handleSelectChange(event, 'productMasterDataId')
                                                    }
                                                    submitted={submitted}
                                                    validated={validated}
                                                    isClearable
                                                    required={formData.reportType === 'Inventory Transaction Log'}
                                                />
                                            </FormGroup>
                                        </Col>
                                        {formData.reportType === 'Inventory Report' && (
                                            <Col md="4" style={{ marginTop: 38 }}>
                                                <AvCheckboxGroup name="isZeroStocks" className="form-check-group">
                                                    <AvCheckbox
                                                        label="Zero Stocks"
                                                        checked={formData.isZeroStocks}
                                                        id="isZeroStocks"
                                                        onChange={handleCheckChange}
                                                    />
                                                </AvCheckboxGroup>
                                            </Col>
                                        )}
                                    </Row>

                                    {formData.reportType !== 'Inventory Report' &&
                                        formData.reportType !== 'Cycle Count Report' && (
                                            <>
                                                <div style={{ display: 'flex', flexDirection: 'col' }}>
                                                    <SubTitle
                                                        style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 0 }}
                                                    >
                                                        Period
                                                    </SubTitle>
                                                </div>
                                                <Row className="mt-3">
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="fromDate">From Date</Label>
                                                            <Input
                                                                onChange={handleInputChange}
                                                                className="form-control"
                                                                type="date"
                                                                value={getDateValue(formData.fromDate, 'date')}
                                                                defaultValue={getDateValue(new Date(), 'date')}
                                                                validate={{ required: { value: true } }}
                                                                id="fromDate"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="toDate">To Date</Label>
                                                            <Input
                                                                onChange={handleInputChange}
                                                                className="form-control"
                                                                type="date"
                                                                value={getDateValue(formData.toDate, 'date')}
                                                                defaultValue={getDateValue(new Date(), 'date')}
                                                                validate={{ required: { value: true } }}
                                                                id="toDate"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}

                                    <Row style={{ justifyContent: 'flex-start' }}>
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                            type="submit"
                                            disabled={formLoading}
                                        >
                                            {formLoading ? (
                                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                            ) : (
                                                <>
                                                    <i className="fas fa-save left-icon"></i>
                                                    <span className="h6">Search</span>
                                                </>
                                            )}
                                        </motion.button>
                                    </Row>
                                </fieldset>
                            </AvForm>
                        </CardBody>
                    </Card>
                    <br />

                    {reportData && formData.reportType === 'Inventory Report' && (
                        <InventoryReportComponent reportData={reportData} reportSettings={formData} />
                    )}
                    {reportData && formData.reportType === 'Cycle Count Report' && (
                        <CycleCountReportComponent reportData={reportData} reportSettings={formData} />
                    )}
                    {reportData && formData.reportType === 'Inventory Transaction Log' && (
                        <InventoryTransactionLogComponent reportData={reportData} reportSettings={formData} />
                    )}
                    {reportData && formData.reportType === 'Stocks Receiving' && (
                        <StocksReceivingReportComponent reportData={reportData} reportSettings={formData} />
                    )}
                    {reportData && formData.reportType === 'Stocks Issuing' && (
                        <StocksIssuingReportComponent reportData={reportData} reportSettings={formData} />
                    )}
                    {reportData && formData.reportType === 'Stocks Adjustments' && (
                        <StocksAdjustmentReportComponent reportData={reportData} reportSettings={formData} />
                    )}
                </Container>
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.8} message={backdropMessage} />
        </>
    );
}

InventoryReportPage.propTypes = {};

const mapStateToProps = (state) => ({
    ...state,
});

InventoryReportPage.propTypes = {};

const HotInventoryReportPage = hot(InventoryReportPage);

export default connect(mapStateToProps, {})(HotInventoryReportPage);
