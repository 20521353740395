export const batchPrintTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Delivery Id',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
        style: ['increase-width'],
        customHeaderStyles: {
            minWidth: 250,
        },
    },
    {
        key: 'orderReferenceNumber',
        value: 'Order Number',
    },
    {
        key: 'merchantOrderNo',
        value: 'Merchant Order Number',
    },
    {
        key: 'pickupLocation',
        value: 'Pickup Location',
    },
    {
        key: 'deliveryAddress',
        value: 'Delivery Location',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
    {
        key: 'deliveryReadyTime',
        value: 'Delivery Ready Time',
        type: 'dateTime',
    },
];

export const deliveryListInitialValues = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: batchPrintTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};
