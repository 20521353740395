/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { DangerApiConnectedHeader, UnderMaintenanceHeader } from 'components';

import { AUTH_USER, USER_INITIAL_WINDOW_HREF } from 'constants/local-storage.constants';

import { encryptedLocalStorage } from 'utils/local-storage';
import { getCurrentPageUrl } from 'utils/helpers';

const AppRoute = ({ component: Component, layout: Layout, isAuthProtected, socket, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuthProtected && !encryptedLocalStorage.getItem(AUTH_USER)) {
                    encryptedLocalStorage.setItem(USER_INITIAL_WINDOW_HREF, getCurrentPageUrl());
                    console.log('window.location.href', getCurrentPageUrl());
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
                }

                return (
                    <>
                        {!isAuthProtected && <UnderMaintenanceHeader isAuthProtected={isAuthProtected} />}
                        <DangerApiConnectedHeader />
                        <Layout socket={socket}>
                            <Component socket={socket} {...props} />
                        </Layout>
                    </>
                );
            }}
        />
    );
};

const HotAppRoute = hot(AppRoute);

export default HotAppRoute;
