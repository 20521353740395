/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';

// #region assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { BackdropLoader, CustomToast, ScootixModal, TableCustom } from 'components';
import StockAdjustmentTransactionSettingsModal from 'components/Modals/Stock-Adjustment-Transaction-Settings';
import PATHS from 'routes/paths';

// #region utils
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import { apiErrorHandler, isObject } from 'utils/helpers';

// #region services
import { getStocksAdjustmentService } from 'services/stocks-adjustment.service';

// #endregion imports
import { defaultTableHeaders } from './constants';
import { deleteStocksAdjustmentService } from './services';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Stocks Adjustment', link: '#' },
];

/**
 * Stocks Adjustment Management Page
 * @param {*} props
 * @returns {React.Component}
 */
function StockAdjustmentListPage(props) {
    const history = useHistory();

    const _isMounted = useRef(true);

    // component state
    const [loading, setLoading] = useState(0);
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [showTransactionSettings, setShowTransactionSettings] = useState(false);

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    const [searchBy] = useState({ searchBy: '', searchText: null });

    // db - data
    const [stocksAdjustmentListData, setStocksAdjustmentListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    // !FIX CLEANUP
    useEffect(() => {
        loadStocksAdjustmentListData(
            true,
            stocksAdjustmentListData.all,
            1,
            stocksAdjustmentListData.limit,
            stocksAdjustmentListData.filter,
            stocksAdjustmentListData.sort,
            stocksAdjustmentListData.order,
            searchBy
        );
        return () => {
            _isMounted.current = false;
        };
    }, [props.location]);

    const loadStocksAdjustmentListData = (showLoading = true, all, page, limit, filter, sort, order, searchByParam) => {
        if (showLoading && _isMounted.current) {
            setLoading((prevState) => prevState + 1);
        }
        getStocksAdjustmentService(all, page, limit, filter, sort, order, searchByParam, 'merchantId,warehouseSiteId')
            .then((res) => {
                if (_isMounted.current && res) {
                    const { data } = res;
                    if (data && data.docs) {
                        const docs = mapDocs(data.docs);
                        setStocksAdjustmentListData({
                            ...stocksAdjustmentListData,
                            ...data,
                            docs,
                        });
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading && _isMounted.current) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const mapDocs = (docs) => {
        let allDocs = [];
        allDocs = docs.map((x) => ({
            ...x,
            merchantPictureUrl: x.merchantId && isObject(x.merchantId) ? x.merchantId.merchantPictureUrl : null,
            merchant: x.merchantId && isObject(x.merchantId) ? x.merchantId : null,
            merchantId: x.merchantId && isObject(x.merchantId) ? x.merchantId._id : x.merchantId,
            merchantName: x.merchantId && isObject(x.merchantId) ? x.merchantId.name : null,
            warehouseSite: x.warehouseSiteId && isObject(x.warehouseSiteId) ? x.warehouseSiteId : null,
            warehouseSiteId:
                x.warehouseSiteId && isObject(x.warehouseSiteId) ? x.warehouseSiteId._id : x.warehouseSiteId,
            warehouseSiteName: x.warehouseSiteId && isObject(x.warehouseSiteId) ? x.warehouseSiteId.siteName : null,
        }));
        return allDocs;
    };

    const handleOnClickNewStocksAdjustment = () => {
        history.push({
            pathname: `${PATHS.STOCK_ADJUSTMENT.FORM}`,
            search: `?type=new`,
        });
    };

    const handleOnClickTransactionSettings = () => {
        setShowTransactionSettings(true);
    };

    const handlePageChange = (pageNo) => {
        loadStocksAdjustmentListData(
            true,
            stocksAdjustmentListData.all,
            pageNo,
            stocksAdjustmentListData.limit,
            stocksAdjustmentListData.filter,
            stocksAdjustmentListData.sort,
            stocksAdjustmentListData.order,
            searchBy
        );
    };

    const handleFilter = (field, filterText) => {
        loadStocksAdjustmentListData(
            false,
            stocksAdjustmentListData.all,
            1,
            stocksAdjustmentListData.limit,
            stocksAdjustmentListData.filter,
            stocksAdjustmentListData.sort,
            stocksAdjustmentListData.order,
            { searchBy: field || 'name', searchText: filterText }
        );
    };

    const handleEditRow = (e, row) => {
        history.push({
            pathname: `${PATHS.STOCK_ADJUSTMENT.FORM}`,
            search: `?type=edit`,
            state: { stocksAdjustmentParentData: row },
        });
    };

    const handleOnDelete = (e, row) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: row,
            message: 'Are you sure you want to delete Stocks Adjustment?',
            onConfirm: (data) => handleDeleteStocksAdjustment(data),
        });
    };

    const handleDeleteStocksAdjustment = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteStocksAdjustmentService(data._id);
            loadStocksAdjustmentListData(
                false,
                stocksAdjustmentListData.all,
                1,
                stocksAdjustmentListData.limit,
                stocksAdjustmentListData.filter,
                stocksAdjustmentListData.sort,
                stocksAdjustmentListData.order,
                searchBy
            );
            toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        toggleScootixModal();
        setBackdropLoading((prevState) => prevState - 1);
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <>
                        <Row className="d-flex row ml-2 justify-content-between">
                            {hasAccess(PERMISSIONS.STOCK_ADJUSTMENT, ['Add']) && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => handleOnClickNewStocksAdjustment()}
                                >
                                    <i className="fas fa-warehouse left-icon mr-2" />
                                    {'  '} <div style={{ marginTop: 1, marginLeft: 3 }}>New Stocks Adjustment</div>
                                </motion.div>
                            )}
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                onClick={() => handleOnClickTransactionSettings()}
                            >
                                <i className="fas fa-cogs left-icon mr-2" />
                                {'  '} <div style={{ marginTop: 1, marginLeft: 3 }}>Transaction Settings</div>
                            </motion.div>
                        </Row>
                    </>
                    <br />

                    <Row>
                        <TableCustom
                            id="stocksAdjustment-table-id"
                            tableData={stocksAdjustmentListData}
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            handleDelete={handleOnDelete}
                            loading={loading}
                            isFullScreenShow
                            handleEdit={handleEditRow}
                            showView={false}
                            showEdit={hasAccess(PERMISSIONS.STOCK_ADJUSTMENT, ['Edit'])}
                            showDelete={hasAccess(PERMISSIONS.STOCK_ADJUSTMENT, ['Delete'])}
                            removeId={false}
                            isExport
                            type="stocksAdjustment"
                            exportModalHeader="Export Stocks Adjustment Data"
                            filters={[]}
                        />
                    </Row>
                </Container>
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.7} />

            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
            {showTransactionSettings && (
                <StockAdjustmentTransactionSettingsModal
                    toggleModal={() => setShowTransactionSettings(!showTransactionSettings)}
                />
            )}
        </>
    );
}

StockAdjustmentListPage.propTypes = {
    location: PropTypes.any,
    socket: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotStockAdjustmentListPage = hot(StockAdjustmentListPage);

export default connect(mapStateToProps, {})(HotStockAdjustmentListPage);
