export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Delivery ID',
        sortingEnabled: true,
    },
    {
        key: 'deliveryType',
        value: 'Delivery Type',
        sortingEnabled: true,
    },
    {
        key: 'orderReferenceNumber',
        value: 'Order Number',
    },
    {
        key: 'merchantOrderNo',
        value: 'Merchant Order Number',
        sortingEnabled: true,
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
        sortingEnabled: true,
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
        style: ['increase-width'],
        customHeaderStyles: {
            minWidth: 250,
        },
        sortingEnabled: true,
    },
    {
        key: 'deliveryReadyTime',
        value: 'Ready Time',
        type: 'dateTime',
        sortingEnabled: true,
    },
    {
        key: 'pickupLocation',
        value: 'From',
        style: ['increase-width', 'long-text'],
    },
    {
        key: 'recipientNameFormatted',
        value: 'Recipient',
        style: ['increase-width', 'long-text'],
    },
    {
        key: 'riderDetails',
        value: 'Rider',
    },
    {
        key: 'recipientMobileNumber',
        value: 'Recipient Mobile No',
    },
    {
        key: 'formattedStatus',
        value: 'Current Status',
        type: 'deliveryStatus',
    },
    {
        key: 'pickedUpAt',
        value: 'Picked-Up At',
        type: 'dateTime',
    },
    {
        key: 'completedAt',
        value: 'Completed At',
        type: 'dateTime',
        sortingEnabled: true,
    },
];

export const deliveryFilters = [
    {
        label: 'Delivery ID',
        key: 'referenceNumber',
    },
    {
        label: 'Order Number',
        key: 'orderReferenceNumber',
    },
    {
        label: 'Merchant Order No',
        key: 'merchantOrderNo',
    },
    {
        label: 'Merchant',
        key: 'merchantName',
    },
    {
        label: 'Recipient Mobile No',
        key: 'recipientMobileNumber',
    },
    {
        label: 'Rider',
        key: 'riderName',
    },
    {
        label: 'Rider ID',
        key: 'riderReference',
    },
    {
        label: 'Rider Mobile No',
        key: 'riderMobileNumber',
    },
    {
        label: 'Delivery Date',
        key: 'deliveryDate',
        type: 'dateFilter',
    },
];

export const defaultDeliveryListView = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: defaultTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};

export const defaultScootixModalState = {
    show: false,
    title: null,
    message: 'Are you sure?',
    confirmMessage: 'Accept',
    cancelMessage: 'Cancel',
    onConfirm: null,
    onCancel: null,
    data: null,
};
