/* eslint-disable no-unused-expressions */
/* eslint-disable no-useless-concat */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState, memo } from 'react';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as qs from 'query-string';
import { motion } from 'framer-motion';
import { compose } from 'redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import moment from 'moment';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { BackdropLoader, CustomToast, ScootixModal, TableCustom } from 'components';

// #region imports
import PATHS from 'routes/paths';
import { PERMISSIONS } from 'utils/constants';

// #region services
import { getDeliveriesService } from 'services/deliveries.service';

// #region utils
import { apiErrorHandler, apiErrorHandlerV2, isObject } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';

// #endregion imports
import reducer from './reducer';
import saga from './saga';
import { defaultDeliveryListView, deliveryFilters } from '../../constants';
import { deleteDeliveryPermanentService, restoreDeliveryService } from '../../services';
import {
    PERMANENT_DELETE_DELIVERY_END,
    PERMANENT_DELETE_DELIVERY_START,
    RESTORE_DELIVERY_END,
    RESTORE_DELIVERY_START,
} from './actionTypes';
import { defaultTableHeaders } from './constants';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Deliveries', link: PATHS.MERCHANT.DEFAULT },
    { title: 'Deleted Deliveries', link: '#' },
];

const key = 'DeletedDeliveries';

function DeletedDeliveriesPage(props) {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    const { location } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    // component state
    const [loading, setLoading] = useState(0);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [deliveryListData, setDeliveryListData] = useState({
        ...defaultDeliveryListView,
        headers: defaultTableHeaders,
    });

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    const [urlParamsSearch, setUrlParamsSearch] = useState(null);
    const [searchParams, setSearchParams] = useState(null);

    useEffect(() => {
        if (props.DeletedDeliveries && props.DeletedDeliveries?.restoreDeliveryData) {
            handleOnRestore(props.DeletedDeliveries?.restoreDeliveryData);
        }
        if (props.DeletedDeliveries && props.DeletedDeliveries?.permanentDeleteDeliveryData) {
            handleOnDeletePermanent(props.DeletedDeliveries?.permanentDeleteDeliveryData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.DeletedDeliveries]);

    useEffect(() => {
        const urlSearch = qs.parse(location.search);
        if (urlSearch && urlSearch.status) {
            const { status, page, searchBy, searchText, filterDate } = urlSearch;
            setUrlParamsSearch({ status, page, searchBy, searchText, filterDate });
            setSearchParams({ searchBy, searchText, filterDate });
        } else {
            replaceUrlParamsHandler({ status: 'isDeleted', page: '1' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    useEffect(() => {
        if (urlParamsSearch) {
            loadDeliveriesListData({
                showLoading: true,
                ...deliveryListData,
                searchBy: {
                    searchBy: urlParamsSearch?.searchBy,
                    searchText: urlParamsSearch?.searchText,
                    filterDate: urlParamsSearch?.filterDate,
                },
                type: urlParamsSearch.type,
                status: urlParamsSearch.status,
                page: urlParamsSearch.page,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParamsSearch]);

    const replaceUrlParamsHandler = (
        params = { status: null, page: '1', searchBy: null, searchText: null, filterDate: null }
    ) => {
        try {
            history.replace({
                search: `${
                    params.page || urlParamsSearch.page ? `page=${params.page || urlParamsSearch.page || '1'}&` : ''
                }${
                    params.status || urlParamsSearch.status
                        ? `status=${params.status || urlParamsSearch.status || 'isDeleted'}&`
                        : ''
                }${params?.searchBy ? `searchBy=${params.searchBy}&` : ''}${
                    params?.searchText ? `searchText=${params.searchText}&` : ''
                }${params?.filterDate ? `filterDate=${params.filterDate}&` : ''}`,
            });
        } catch (e) {
            console.log(e);
        }
    };

    const mapDocs = (docs) => {
        let allDocs = [];
        allDocs = docs.map((x) => ({
            ...x,
            recipientName: `${x.recipientName || 'No Recipient Given'}, ${x.deliveryAddress || ''}`,
            riderDetails: x.riderReference ? `${x.riderName} ${x.riderReference}` : `Rider Not Assigned`,
            merchant: x.merchantId && isObject(x.merchantId) ? x.merchantId : null,
            merchantPictureUrl: x.merchantId && isObject(x.merchantId) ? x.merchantId.merchantPictureUrl : null,
            merchantId: x.merchantId && isObject(x.merchantId) ? x.merchantId._id : x.merchantId,
            deliveryDate:
                moment(x.deliveryDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')
                    ? `Today`
                    : `${moment(x.deliveryDate).format('YYYY-MM-DD')}`,
        }));
        return allDocs;
    };

    const loadDeliveriesListData = (args) => {
        if (args.showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getDeliveriesService({ ...args, populate: 'riderId,merchantId' })
            .then((res) => {
                const { data } = res;
                if (data && data.docs) {
                    const docs = mapDocs(data.docs);
                    setDeliveryListData({
                        ...deliveryListData,
                        ...data,
                        docs,
                    });
                }
            })
            .catch((e) => {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            })
            .finally(() => {
                if (args.showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const reloadComponent = () => {
        loadDeliveriesListData({
            showLoading: true,
            ...deliveryListData,
            page: urlParamsSearch.page,
            searchBy: {
                searchBy: urlParamsSearch?.searchBy,
                searchText: urlParamsSearch?.searchText,
            },
            type: urlParamsSearch.type,
            status: urlParamsSearch.status,
        });
    };

    const handlePageChange = (pageNo) => {
        replaceUrlParamsHandler({
            page: pageNo,
            searchBy: searchParams?.searchBy,
            searchText: searchParams?.searchText,
            filterDate: searchParams?.filterDate,
        });
    };

    const handleFilter = (field, filterText) => {
        let modifiedFilterText = null;
        if (filterText && filterText !== '') {
            modifiedFilterText = filterText.replaceAll(' ', '$2');
        } else {
            modifiedFilterText = null;
        }
        replaceUrlParamsHandler({ searchBy: field || 'referenceNumber', searchText: modifiedFilterText, page: '1' });
    };

    // handling date filtering and updating URL parameters
    const handleFilterDate = (field, filterDate) => {
        let modifiedFilterDate = null;
        if (filterDate && filterDate !== '') {
            modifiedFilterDate = filterDate;
        } else {
            modifiedFilterDate = null;
        }
        replaceUrlParamsHandler({ searchBy: field, filterDate: modifiedFilterDate, page: '1' });
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: !scootixModalState.show, data: null });
    };

    const closeScootixModal = () => {
        setScootixModalState({ show: false, data: null });
        props.DeletedDeliveries?.restoreDeliveryData && dispatch({ type: RESTORE_DELIVERY_END });
        props.DeletedDeliveries?.permanentDeleteDeliveryData && dispatch({ type: PERMANENT_DELETE_DELIVERY_END });
    };

    const handleOnRestore = (delivery) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: { _id: delivery._id },
            message: `Are you sure you want to restore Delivery ${delivery.referenceNumber || ''} ?`,
            onConfirm: (data) => handleRestore(data),
        });
    };

    const handleRestore = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            if (data && data._id) {
                await restoreDeliveryService(data._id);
                history.push({
                    search: ``,
                });
                reloadComponent();
                toast.custom((t) => <CustomToast text="Successfully Restored" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((prevState) => prevState - 1);
        closeScootixModal();
    };

    const handleOnDeletePermanent = (delivery) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: { _id: delivery._id },
            message: `Are you sure you want to Permanently delete Delivery ${delivery.referenceNumber || ''} ?`,
            onConfirm: (data) => handleDeletePermanent(data),
        });
    };

    const handleDeletePermanent = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            if (data && data._id) {
                await deleteDeliveryPermanentService(data._id);
                history.push({
                    search: ``,
                });
                reloadComponent();
                toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((prevState) => prevState - 1);
        closeScootixModal();
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <br />
                    <Row className="m-2" style={{ justifyContent: 'space-between' }}>
                        <div />
                        <div>
                            <motion.button
                                id="reload-comp-btn"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-form-btn circle shadow-lg nw-md pr-2 pl-3 pointer m3-3 mr-3"
                                onClick={() => {
                                    replaceUrlParamsHandler({
                                        status: 'unAssigned',
                                        page: '1',
                                        searchBy: null,
                                        searchText: null,
                                    });
                                }}
                            >
                                <>Reset Filters</>
                            </motion.button>
                            <motion.button
                                id="reload-comp-btn"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-form-btn circle shadow-lg nw-md pr-2 pl-3 pointer"
                                onClick={() => {
                                    reloadComponent();
                                }}
                            >
                                <>
                                    <i className="fas fa-redo-alt left-icon"></i>
                                </>
                            </motion.button>
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <TableCustom
                            tableData={deliveryListData}
                            onFilter={handleFilter}
                            onPageChange={handlePageChange}
                            showDelete={false}
                            showEdit={false}
                            showView={false}
                            removeAction
                            showPermanentDelete={hasAccess(PERMISSIONS.DELETED_MERCHANT, ['Permanent Delete'])}
                            showRestore={hasAccess(PERMISSIONS.DELETED_MERCHANT, ['Restore'])}
                            filters={deliveryFilters}
                            showStickyView
                            loading={loading}
                            type="delivery"
                            exportModalHeader="Export Deleted Delivery Data"
                            onRestore={(data) => dispatch({ type: RESTORE_DELIVERY_START, payload: data })}
                            onPermanentDelete={(data) =>
                                dispatch({ type: PERMANENT_DELETE_DELIVERY_START, payload: data })
                            }
                            removeId={false}
                            searchProp={searchParams}
                            isManualSearch
                            isDeliveryDate
                            onDateFilter={handleFilterDate}
                        />
                    </Row>
                </Container>
            </div>
            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
            <BackdropLoader show={backdropLoading} opacity={0.7} />
        </>
    );
}

DeletedDeliveriesPage.propTypes = {
    location: PropTypes.any,
    DeletedDeliveries: PropTypes.object,
};

const mapStateToProps = (state) => ({
    ...state,
});

export function mapDispatchToProps(dispatch) {
    return {};
}

const HotDeletedDeliveriesPage = hot(DeletedDeliveriesPage);

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(HotDeletedDeliveriesPage);
