/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

// #region components | assets
import { CrossCircle } from 'components/Icons';
import { DANGER_RED, THEME_COLOR_2 } from 'theme';

// #endregion imports
import {
    AddMoreDeliveriesModule,
    CancelRoutePlanningEntryModule,
    ChangeTheRiderModule,
    DetachDeliveryModule,
    SelectDeliveriesModule,
    SelectDeliveryTypeModule,
    SelectMerchantModule,
    UpdateDeliveryDateModule,
} from './Modules';

function RoutePlanningModal(props) {
    const {
        toggleModal,
        show,
        type,
        formData,
        setSelectedDeliveryTypes,
        selectedDeliveryTypes,
        allMerchantIds,
        selectedMerchants,
        setSelectedMerchants,
        allUnPlannedDeliveryList,
        selectedUnPlannedDeliveryList,
        setSelectedUnPlannedDeliveryList,
        currentMerchantId,
        deliveryDate,
        routePlanningId,
        fetchRoutePlanningData,
        fetchRelevantDeliveryData,
        riderDetails,
        deliveryArray,
        routePlanData,
        reloads,
        detachDeliveryId,
        location,
        loadTransactions,
    } = props;

    const [modalBackgroundColor, setModalBackgroundColor] = useState(THEME_COLOR_2);

    const commonProps = {
        toggleModal,
    };

    /**
     * Decides the modal header color based on type of the modal
     */
    useEffect(() => {
        if (type === 'detachDelivery' || type === 'cancelRoutePlanningEntry') {
            setModalBackgroundColor(DANGER_RED);
        } else {
            setModalBackgroundColor(THEME_COLOR_2);
        }
    }, [type]);

    /** Generate modal body based on modal type */
    const generateBody = () => {
        if (type === 'addDeliveries') {
            return (
                <SelectDeliveriesModule
                    {...commonProps}
                    allUnPlannedDeliveryList={allUnPlannedDeliveryList}
                    selectedUnPlannedDeliveryList={selectedUnPlannedDeliveryList}
                    setSelectedUnPlannedDeliveryList={setSelectedUnPlannedDeliveryList}
                />
            );
        }
        if (type === 'addMoreDeliveries') {
            return (
                <AddMoreDeliveriesModule
                    {...commonProps}
                    currentMerchantId={currentMerchantId}
                    routePlanData={routePlanData}
                    reloads={reloads}
                    selectedUnPlannedDeliveryList={selectedUnPlannedDeliveryList}
                    setSelectedUnPlannedDeliveryList={setSelectedUnPlannedDeliveryList}
                />
            );
        }
        if (type === 'selectDeliveryType') {
            return (
                <SelectDeliveryTypeModule
                    {...commonProps}
                    formData={formData}
                    setSelectedDeliveryTypes={setSelectedDeliveryTypes}
                    selectedDeliveryTypes={selectedDeliveryTypes}
                />
            );
        }
        if (type === 'selectMerchant') {
            return (
                <SelectMerchantModule
                    {...commonProps}
                    allMerchantIds={allMerchantIds}
                    selectedMerchants={selectedMerchants}
                    setSelectedMerchants={setSelectedMerchants}
                />
            );
        }
        if (type === 'changeTheRider') {
            return (
                <ChangeTheRiderModule
                    {...commonProps}
                    routePlanningId={routePlanningId}
                    riderDetails={riderDetails}
                    deliveryArray={deliveryArray}
                    fetchRoutePlanningData={fetchRoutePlanningData}
                    fetchRelevantDeliveryData={fetchRelevantDeliveryData}
                    loadTransactions={loadTransactions}
                />
            );
        }

        if (type === 'detachDelivery') {
            // check whether the selected delivery to detach is moved beyond the pickedUp status
            const isUnassignRiderPossible = deliveryArray.docs.some(
                (delivery) => delivery._id === detachDeliveryId && !delivery.isPickedUp
            );

            return (
                <DetachDeliveryModule
                    {...commonProps}
                    routePlanningId={routePlanningId}
                    detachDeliveryId={detachDeliveryId}
                    isUnassignRiderPossible={isUnassignRiderPossible}
                    fetchRoutePlanningData={fetchRoutePlanningData}
                    fetchRelevantDeliveryData={fetchRelevantDeliveryData}
                    loadTransactions={loadTransactions}
                />
            );
        }

        if (type === 'updateDeliveryDate') {
            return (
                <UpdateDeliveryDateModule
                    {...commonProps}
                    routePlanningId={routePlanningId}
                    deliveryDate={deliveryDate}
                    fetchRoutePlanningData={fetchRoutePlanningData}
                    fetchRelevantDeliveryData={fetchRelevantDeliveryData}
                    loadTransactions={loadTransactions}
                />
            );
        }

        if (type === 'cancelRoutePlanningEntry') {
            return (
                <CancelRoutePlanningEntryModule
                    {...commonProps}
                    routePlanningId={routePlanningId}
                    fetchRoutePlanningData={fetchRoutePlanningData}
                    fetchRelevantDeliveryData={fetchRelevantDeliveryData}
                    location={location}
                    loadTransactions={loadTransactions}
                />
            );
        }
        return <></>;
    };

    /**
     * Generates the title for the modal based on the modal type and route planning data.
     *
     * @function
     * @returns {string} The title for the modal.
     */
    const generateModalTitle = () => {
        const title = {
            addDeliveries: 'Add Deliveries for the Route Planning',
            addMoreDeliveries: 'Add Deliveries for the Route Planning',
            selectDeliveryType: 'Select Delivery Types',
            selectMerchant: 'Select Merchant',
            changeTheRider: 'Change the Rider',
            detachDelivery: 'Detach Delivery From Route Planning ID',
            updateDeliveryDate: 'Update the Delivery Date',
            cancelRoutePlanningEntry: 'Cancel Route Planning Entry',
        };

        if (type === 'detachDelivery') {
            const { sequenceId } = routePlanData;
            return `${title[type]} ${sequenceId}`;
        }
        return title[type];
    };

    return (
        <>
            {type && (
                <Modal
                    isOpen={show}
                    className="modal-dialog modal-dialog-centered"
                    style={
                        type === 'addDeliveries' || type === 'addMoreDeliveries'
                            ? { maxWidth: '80%', width: '100%', margin: 'auto' }
                            : { width: '100%', margin: 'auto' }
                    }
                    toggle={toggleModal}
                >
                    <ModalHeader
                        toggle={toggleModal}
                        style={{
                            backgroundColor: modalBackgroundColor,
                        }}
                        close={
                            <button type="button" className="close" onClick={toggleModal}>
                                <CrossCircle color="#FFF" />
                            </button>
                        }
                    >
                        <span className="font-weight-normal text-white">{generateModalTitle()}</span>
                    </ModalHeader>
                    <ModalBody className="p-0">{generateBody()}</ModalBody>
                </Modal>
            )}
        </>
    );
}

RoutePlanningModal.propTypes = {
    toggleModal: PropTypes.func,
    show: PropTypes.bool,
    type: PropTypes.any,
    formData: PropTypes.any,
    setSelectedDeliveryTypes: PropTypes.func,
    selectedDeliveryTypes: PropTypes.any,
    allMerchantIds: PropTypes.any,
    selectedMerchants: PropTypes.any,
    setSelectedMerchants: PropTypes.func,
    allUnPlannedDeliveryList: PropTypes.any,
    selectedUnPlannedDeliveryList: PropTypes.any,
    setSelectedUnPlannedDeliveryList: PropTypes.func,
    currentMerchantId: PropTypes.string,
    deliveryDate: PropTypes.any,
    routePlanningId: PropTypes.string,
    fetchRoutePlanningData: PropTypes.func,
    fetchRelevantDeliveryData: PropTypes.func,
    riderDetails: PropTypes.any,
    deliveryArray: PropTypes.object,
    routePlanData: PropTypes.object,
    reloads: PropTypes.func,
    detachDeliveryId: PropTypes.string,
    location: PropTypes.any,
    loadTransactions: PropTypes.func,
};

export default RoutePlanningModal;
