/**
 * CLIENT SETTING FIXED DATA
 * DUPLICATE IN CLIENT SERVICE IF CHANGED HERE CHANGE THERE
 */
export const CLIENT_SETTING_DEFAULT = {
    riderSettings: {
        delivery: {
            selfUnAssignmentTimeout: 10, // Mobile Rider Delivery Un Assignment Time Out ( From My Delivery Orders Screen)
        },
    },
    orderProcessing: {
        isEnabled: true,
        lastEnabledAt: null,
        lastEnabledBy: null,
        lastDisabledBy: null,
        lastDisabledAt: null,
    },
    stocksReceiving: {
        isEnabled: true,
        lastEnabledAt: null,
        lastEnabledBy: null,
        lastDisabledBy: null,
        lastDisabledAt: null,
    },
};

// USED IN RIDER SEARCH MODAL
export const allKmRadiusOptions = [
    {
        label: 'All',
        value: null,
    },
    {
        label: '10 Km',
        value: 10,
    },
    {
        label: '50 Km',
        value: 50,
    },
    {
        label: '100 Km',
        value: 100,
    },
    {
        label: '150 Km',
        value: 150,
    },
    {
        label: '200 Km',
        value: 200,
    },
];
