/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Container, Row, Spinner } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';
import CheckCircleIcon from 'components/Icons/Check-Circle';

/** This modals prompts the user for the password, in order to show the Secret Key */
function ViewSecretKey(props) {
    const { show, handleToggle, handleSubmit } = props;

    /** State for showing whether the password check is in progress */
    const [loading, setLoading] = useState(false);
    /** State for storing entered form data */
    const [formData, setFormData] = useState({ password: '' });

    const handleModalSubmit = async () => {
        setLoading(true);
        const response = await handleSubmit(formData);
        if (!('error' in response)) {
            handleModalToggle();
        }
        setLoading(false);
    };

    const handleModalToggle = () => {
        resetForm();
        handleToggle();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const resetForm = () => {
        setFormData({ password: '' });
        setLoading(false);
    };

    return (
        <DeveloperModal header="View Secret Key" show={show} handleToggle={handleModalToggle}>
            <Container>
                <AvForm className="needs-validation default-form" noValidate onValidSubmit={handleModalSubmit}>
                    <Row>
                        <Col>
                            <Alert color="warning" style={{ borderRadius: 8 }}>
                                Please enter your password to view the secret key.
                            </Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AvField
                                name="password"
                                type="password"
                                value={formData.password}
                                className="form-control"
                                errorMessage="Required"
                                onChange={handleInputChange}
                                validate={{ required: { value: true } }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button disabled={loading} style={{ borderRadius: 10 }} className="scootix-btn-radius">
                                {loading ? (
                                    <Spinner size="sm" color="warning" className="spinner-white mx-1" />
                                ) : (
                                    <CheckCircleIcon color="#FFF" />
                                )}
                                &nbsp;DISPLAY
                            </Button>
                        </Col>
                    </Row>
                </AvForm>
            </Container>
        </DeveloperModal>
    );
}

ViewSecretKey.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default ViewSecretKey;
