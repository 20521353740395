/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React from 'react';
import Form from './components/Form';
function BatchPrintTempPage() {
    return <Form />;
}

BatchPrintTempPage.propTypes = {};

const HotBatchPrintTempPage = hot(BatchPrintTempPage);

export default HotBatchPrintTempPage;
