import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Label, Spinner, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';

// #region services
import { BackdropLoader, CustomToast } from 'components';
import { getModelByTypeAndReferenceIdService } from 'services/model.service';
import { apiErrorHandlerV2 } from 'utils/helpers';

function SelectDeliveryImportModel(props) {
    const { handleReturnData, toggleModal } = props;

    const [backdropLoading, setBackdropLoading] = useState(0);

    const [formData, setFormData] = useState({});

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!formData.referenceId) {
            toast.custom((t) => <CustomToast text="Enter Model ID" t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setBackdropLoading((prevState) => prevState + 1);

        try {
            const { data } = await getModelByTypeAndReferenceIdService('DELIVERY_IMPORT_MODEL', formData.referenceId);
            if (data) {
                handleReturnData(data);
            } else {
                toast.custom((t) => <CustomToast text="Model Not Found" t={t} type="warning" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    return (
        <>
            <Modal size="lg" isOpen toggle={toggleModal} center className="modal-dialog modal-dialog-centered">
                <ModalHeader toggle={toggleModal}>Search For Model</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md="3">
                            <Label className="mt-2" htmlFor="referenceId">
                                Model ID
                            </Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="referenceId"
                                    placeholder="Model Id"
                                    type="text"
                                    errorMessage="Enter ID"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="referenceId"
                                    value={formData.referenceId}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row style={{ justifyContent: 'flex-end' }}>
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                            onClick={handleSubmit}
                            disabled={backdropLoading > 0}
                        >
                            {backdropLoading > 0 ? (
                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                            ) : (
                                <>
                                    <i className="fas fa-save left-icon"></i>
                                    <span className="h6">Search</span>
                                </>
                            )}
                        </motion.button>
                    </Row>
                </ModalBody>
            </Modal>

            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

SelectDeliveryImportModel.propTypes = {
    toggleModal: PropTypes.func,
    handleReturnData: PropTypes.func,
};

export default SelectDeliveryImportModel;
