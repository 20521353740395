/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import classnames from 'classnames';
import { motion } from 'framer-motion';

// #region components | assets
import paths from 'routes/paths';
import { DangerApiConnectedHeader } from 'components';
import { LinkedWithRoutePlan, OnDemandDeliveryBell } from 'components/Icons';
import UnderMaintenanceHeader from 'components/Alerts/UnderMaintenanceHeader';

// #region utils
import { getUser } from 'utils/checkAuth';

// #region imports
import { APP_LOGO_WIDE_IMAGE, APP_LOGO_SM_IMAGE } from 'definitions/assets';
import { USER_PROFILE_PIC } from 'constants/local-storage.constants';
import { DELIVERY_TYPES } from 'constants/constants';

// #region services
import { getAttachmentByKey } from 'services/attachment.service';
import { getApiServiceStatus } from 'services/partner-api.service';

// Import menuDropdown
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import ClientMerchantX from '../CommonForBoth/TopbarDropdown/client-merchant-x';

// Redux Store
import { toggleRightSidebar } from '../../store/actions';

// Import logo Images
import logoSmDark from '../../assets/images/logo-sm-dark.png';
import logoDark from '../../assets/images/logo-dark.png';

// #endregion imports
import { getHeaderTitle } from './util';
import './styles/header.css';
import ClientMerchantXMobile from './component/ClientMerchantXMobile';
import {
    renderPartnerApiApiPortalStatus,
    renderPartnerApiSandboxAccountRequestStatus,
    renderRoutePlanningEntryStatus,
} from './component/StatusChip';

function Header(props) {
    const reduxSelector = useSelector((state) => state);

    const [headingText, setHeadingText] = useState({});

    const [width, setWidth] = useState(window.innerWidth);
    const [isMobileUi, setIsMobileUi] = useState(false);

    const [partnerApiServiceStatusOptions, setPartnerApiServiceStatusOptions] = useState(null);

    const userData = getUser();

    // image
    const [images, setImages] = useState({
        profilePictureUrl: null,
    });

    const _isMounted = useRef(true);

    // logic to load profile image once
    // avoid unnecessary api call
    // using session storage
    useEffect(() => {
        if (!window.sessionStorage.getItem(USER_PROFILE_PIC)) {
            if (userData.profilePictureUrl) {
                getAttachmentByKey(userData.profilePictureUrl).then((res) => {
                    if (_isMounted.current) {
                        setImages({ ...images, profilePictureUrl: res });
                        window.sessionStorage.setItem(USER_PROFILE_PIC, res);
                    }
                });
            }
        } else {
            const profileImage = window.sessionStorage.getItem(USER_PROFILE_PIC);
            if (profileImage) {
                setImages({ ...images, profilePictureUrl: profileImage });
            }
        }

        return () => {
            _isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (width <= 768) {
            setIsMobileUi(true);
        } else {
            setIsMobileUi(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    useEffect(() => {
        if (props.location) {
            // let headerTypeValue = null;
            const value = getHeaderTitle(props.location.pathname, props.location.search || null, props.location);
            if (value) {
                setHeadingText(value);
            } else {
                const { Page } = reduxSelector;
                if (Page && Page.textObject) {
                    const { heading = '', text = '', type, subtitle = '', status, ...other } = Page.textObject;
                    setHeadingText({ heading, text, subtitle, status, ...other });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location, reduxSelector.Page]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (headingText?.key) {
            renderHeaderApiLogic(headingText.key);
        }
    }, [headingText?.key]);

    async function renderHeaderApiLogic(key) {
        if (key === 'partner-api-service') {
            try {
                const { data } = await getApiServiceStatus();
                if (data?.payload?.service) {
                    const { status } = data.payload.service;
                    setPartnerApiServiceStatusOptions({
                        status,
                    });
                    return;
                }
                setPartnerApiServiceStatusOptions({
                    status: false,
                });
            } catch (e) {
                console.error(e);
                setPartnerApiServiceStatusOptions({
                    status: false,
                });
            }
        }
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    /**
     * Toggle sidebar
     */
    const toggleMenu = () => {
        props.toggleMenuCallback();
    };

    // !TODO: add docs
    const getStatus = (status) => {
        if (status === 'deleted') {
            return 'Deleted Delivery';
        }
        if (status === 'dispatched') {
            return 'Dispatch Ready';
        }

        if (status === 'pickUpStarted' || status === 'riderAssigned') {
            return 'Rider Assigned';
        }

        if (status === 'pickedUp') {
            return 'Dispatched';
        }

        if (status === 'deliveryStarted') {
            return 'Delivery Started';
        }

        if (status === 'proofOfDeliveryReceived') {
            return 'POD Received';
        }

        if (status === 'completed') {
            return 'Completed';
        }

        if (status === 'delivered') {
            return 'Delivered';
        }
        if (status === 'READY_TO_PRINT') {
            return 'Ready to Print';
        }
        if (status === 'DRAFT') {
            return 'Draft';
        }
        if (status === 'IN_PROGRESS') {
            return 'In Progress';
        }
        return 'In Progress';
    };

    // !TODO: add docs
    const toggleFullscreen = () => {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        }
    };
    // !TODO: add docs
    const renderHeaderText = () => {
        const headerKey = headingText?.key;

        // !TODO: add docs
        const renderOnDemandDeliveryStatus = () => {
            const toListValues = ['delivery_form', 'billing_record_commission_form', 'rider_commission_form'];
            if (
                headingText.type &&
                toListValues.some((v) => headingText.type === v) &&
                headingText?.deliveryType === DELIVERY_TYPES.ON_DEMAND_DELIVERY
            ) {
                return (
                    <h5 className="on-demand-delivery-status-container d-flex row">
                        <OnDemandDeliveryBell />
                        {'  '} <div style={{ marginTop: 1, marginLeft: 3 }}>{headingText.deliveryType || ''}</div>{' '}
                    </h5>
                );
            }
            return <div />;
        };

        // !TODO: add docs
        function renderCustomHeader() {
            if (headerKey) {
                const { status, data } = headingText;

                // PARTNER API - SANDBOX ACCOUNT REQUEST STATUS
                if (headerKey === 'partner-api-sandbox-account-form' && status) {
                    return renderPartnerApiSandboxAccountRequestStatus(status);
                }
                if (headerKey === 'partner-api-portal' && data) {
                    return renderPartnerApiApiPortalStatus(data);
                }
                if (headerKey === 'route-planning-entry-view' && status) {
                    return renderRoutePlanningEntryStatus(status);
                }
            }
            return <></>;
        }

        /**
         * Handles click event of route planning status label. Redirect user to linked route plan
         */
        const handleRoutePlanningLabelClick = () => {
            window.open(
                `/${paths.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_ENTRY_VIEW}?type=edit&id=${headingText.routePlanningId}`,
                '_blank'
            );
        };

        return (
            <>
                <div className="custom-header">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h5 className="header-heading mr-2">{headingText.heading || ''}</h5>
                        {headerKey && renderCustomHeader()}
                        {headingText.status && !headingText.key && (
                            <h5 className="header-status">{getStatus(headingText.status) || ''} </h5>
                        )}
                        {headingText.stockAdjustmentStatus && (
                            <h5 className="header-status">{headingText.stockAdjustmentStatus || ''} </h5>
                        )}
                        {headingText.routePlanningLabel && <h5>{headingText.routePlanningLabel}</h5>}
                        <div className="on-demand-delivery-status-desktop">{renderOnDemandDeliveryStatus()}</div>
                        {headingText.routePlanningId && (
                            <motion.div
                                className="route-plan-linked-status-container route-plan-linked-status-desktop mb-2"
                                onClick={handleRoutePlanningLabelClick}
                            >
                                <div>Route Planning Entry</div>
                                <div
                                    className="font-weight-bolder ml-1"
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    {` ${headingText.routePlanningSequenceId || ''}`}
                                    <LinkedWithRoutePlan color="#2f2e2ead" width={16} height={16} />
                                </div>
                            </motion.div>
                        )}
                    </div>

                    <div className="sub-text">
                        <div className="row">
                            <div className="on-demand-delivery-status-mobile">{renderOnDemandDeliveryStatus()}</div>
                            {headingText.routePlanningId && (
                                <motion.div
                                    className="route-plan-linked-status-container route-plan-linked-status-laptop-lg"
                                    onClick={handleRoutePlanningLabelClick}
                                >
                                    <div>Route Planning Entry</div>
                                    <div
                                        className="font-weight-bolder ml-1 mr-2"
                                        style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                        {` ${headingText.routePlanningSequenceId || ''}`}
                                        <LinkedWithRoutePlan color="#2f2e2ead" width={14} height={14} />
                                    </div>
                                </motion.div>
                            )}
                        </div>

                        <h6 className="header-text">{headingText.text || ''} </h6>
                    </div>
                    <div className="sub-text">
                        <h6 className="header-subtitle">{headingText.subtitle || ''} </h6>
                    </div>
                </div>
            </>
        );
    };

    /**
     * Render Additional Labels
     * @returns
     */
    function renderLabel() {
        if (partnerApiServiceStatusOptions) {
            const { status } = partnerApiServiceStatusOptions;
            return (
                <div
                    className={classnames('partner-api-service__header', {
                        'service-active': status,
                    })}
                >
                    {status ? 'SERVICE ACTIVE' : 'SERVICE INACTIVE'}
                </div>
            );
        }

        return <></>;
    }

    return (
        <React.Fragment>
            <header id="page-topbar">
                <DangerApiConnectedHeader />
                <UnderMaintenanceHeader />
                <ClientMerchantXMobile />
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/home" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logoSmDark} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoDark} alt="" height="20" />
                                </span>
                            </Link>

                            <Link to="/home" className="logo logo-light">
                                <span className="logo-sm" style={{ marginLeft: -18 }}>
                                    <img src={APP_LOGO_SM_IMAGE} alt="" height="22" />
                                </span>
                                <span className="logo-lg" style={{ marginLeft: 20 }}>
                                    <img src={APP_LOGO_WIDE_IMAGE} alt="" height="40" width="100" />
                                </span>
                            </Link>
                        </div>

                        <Button
                            size="sm"
                            color="none"
                            type="button"
                            onClick={toggleMenu}
                            className="px-3 font-size-24 header-item waves-effect"
                            id="vertical-menu-btn"
                            style={{ alignSelf: 'center' }}
                        >
                            <i className="ri-menu-2-line align-middle"></i>
                        </Button>
                        {renderHeaderText()}

                        {partnerApiServiceStatusOptions && (
                            <div className="header-additional-labels__container">{renderLabel()}</div>
                        )}
                        {/* <MegaMenu /> */}
                    </div>

                    <div className="d-flex">
                        {/* <div className="dropdown d-inline-block d-lg-none ml-2">
                            <button
                                type="button"
                                onClick={() => {
                                    setIsSearch(!isSearch);
                                }}
                                className="btn header-item noti-icon waves-effect"
                                id="page-header-search-dropdown"
                            >
                                <i className="ri-search-line"></i>
                            </button>
                            <div
                                className={
                                    isSearch === true
                                        ? 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show'
                                        : 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0'
                                }
                                aria-labelledby="page-header-search-dropdown"
                            >
                                <Form className="p-3">
                                    <FormGroup className="m-0">
                                        <InputGroup>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder={props.t('Search')}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <Button color="primary" type="submit">
                                                    <i className="ri-search-line"></i>
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Form>
                            </div>
                        </div> */}

                        {/* <SearchBox {...props} /> */}

                        {/* <LanguageDropdown /> */}

                        {/* <Dropdown
                                isOpen={isSocialPf}
                                toggle={() => setState({ isSocialPf: !isSocialPf })}
                                className="d-none d-lg-inline-block ml-1"
                            >
                                <DropdownToggle tag="button" className="btn header-item noti-icon waves-effect">
                                    <i className="ri-apps-2-line"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg" right>
                                    <div className="px-lg-2">
                                        <Row className="no-gutters">
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={github} alt="Github" />
                                                    <span>{props.t('GitHub')}</span>
                                                </Link>
                                            </Col>
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={bitbucket} alt="bitbucket" />
                                                    <span>{props.t('Bitbucket')}</span>
                                                </Link>
                                            </Col>
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={dribbble} alt="dribbble" />
                                                    <span>{props.t('Dribbble')}</span>
                                                </Link>
                                            </Col>
                                        </Row>

                                        <Row className="no-gutters">
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={dropbox} alt="dropbox" />
                                                    <span>{props.t('Dropbox')}</span>
                                                </Link>
                                            </Col>
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={mail_chimp} alt="mail_chimp" />
                                                    <span>{props.t('Mail Chimp')}</span>
                                                </Link>
                                            </Col>
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={slack} alt="slack" />
                                                    <span>{props.t('Slack')}</span>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </DropdownMenu>
                            </Dropdown> */}

                        <div className="dropdown d-none d-lg-inline-block ml-1">
                            <Button
                                color="none"
                                type="button"
                                className="header-item noti-icon waves-effect"
                                onClick={toggleFullscreen}
                            >
                                <i className="ri-fullscreen-line"></i>
                            </Button>
                        </div>
                        <div className="custom-header-desktop">
                            <div className="row">
                                <ClientMerchantX />
                            </div>
                        </div>

                        {!isMobileUi && <NotificationDropdown />}

                        <ProfileMenu
                            setImages={setImages}
                            profilePictureUrl={images.profilePictureUrl}
                            userData={userData}
                            images={images}
                        />

                        {/* <div className="dropdown d-inline-block">
                            <Button
                                color="none"
                                onClick={toggleRightBar}
                                type="button"
                                className="header-item noti-icon right-bar-toggle waves-effect"
                            >
                                <i className="ri-settings-2-line"></i>
                            </Button>
                        </div> */}
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

Header.propTypes = {
    location: PropTypes.any,
    toggleMenuCallback: PropTypes.func,
    // toggleRightSidebar: PropTypes.func,
};

export default connect(mapStateToProps, { toggleRightSidebar })(withNamespaces()(Header));
