import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, ModalHeader, Input } from 'reactstrap';
import { motion } from 'framer-motion';

// #region imports
import { centerElementContainerStyles, centerElementInnerStyles } from 'styles';
import { getDateValue, reactSelectCustomStyles } from 'utils/helpers';
import { Select } from 'components';

function ModalSelect(props) {
    const {
        toggleModal,
        show = false,
        title = null,
        message = 'Are you sure?',
        onConfirm = null,
        confirmMessage = 'Ok',
        options = [],
        type = null,
        dataType = 'text',
        selectedRowValue = null,
    } = props;

    const [selectedValue, setSelectedValue] = useState(null);

    const getSelectValue = (_id) => {
        if (_id) {
            if (type === 'productMasterData') {
                const relevantObj = options.find((x) => x.value === _id);
                if (relevantObj) {
                    return { label: relevantObj.label, value: relevantObj.value };
                }
                return null;
            }
            if (type === 'unitOfMeasure') {
                const relevantObj = options.find((x) => x.value === _id);
                if (relevantObj) {
                    return { label: relevantObj.label, value: relevantObj.value };
                }
                return null;
            }
            if (type === 'warehouseZone') {
                const relevantObj = options.find((x) => x.value === _id);
                if (relevantObj) {
                    return { label: relevantObj.label, value: relevantObj.value };
                }
                return null;
            }
        }
        return null;
    };

    const handleInputChange = (event) => {
        const { value } = event.target;
        setSelectedValue(value);
    };

    return (
        <>
            <div className="custom-scootix-modal">
                {show && (
                    <Modal
                        size="lg"
                        isOpen
                        center
                        toggle={toggleModal}
                        style={{ borderRadius: 15 }}
                        className="modal-dialog modal-dialog-centered scootix-confirmation-modal"
                    >
                        <ModalHeader
                            className="scootix-bg-radiant"
                            style={{
                                marginTop: -1,
                                marginRight: -1,
                                marginLeft: -1,
                                color: '#FFF',
                                borderTopRightRadius: 15,
                                borderTopLeftRadius: 15,
                            }}
                        >
                            <h4
                                style={{
                                    color: '#FFF',
                                }}
                            >
                                {' '}
                                {title || 'Confirmation'}{' '}
                            </h4>
                        </ModalHeader>
                        <ModalBody
                            style={{
                                flexDirection: 'column',
                                borderRadius: 15,
                                backgroundColor: '#FAFAFA',
                                ...centerElementContainerStyles,
                            }}
                            className="p-3"
                        >
                            <br />
                            <div>
                                <h5>{message}</h5>
                            </div>
                            <br />
                            <Col md={6}>
                                {dataType === 'select' && (
                                    <Select
                                        value={getSelectValue(selectedValue || selectedRowValue)}
                                        options={options}
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => setSelectedValue(event.value)}
                                        required
                                    />
                                )}
                                {dataType === 'datetime' && (
                                    <Input
                                        onChange={handleInputChange}
                                        className="form-control"
                                        type="datetime-local"
                                        value={getDateValue(selectedValue || selectedRowValue, 'datetime-local')}
                                    />
                                )}
                                {dataType === 'date' && (
                                    <Input
                                        onChange={handleInputChange}
                                        className="form-control"
                                        type="date"
                                        value={getDateValue(selectedValue || selectedRowValue, 'date')}
                                    />
                                )}
                            </Col>

                            <br />
                            <div className="row">
                                <motion.div
                                    className="scootix-bg-radiant ml-2 btn"
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    style={{
                                        minWidth: 120,
                                        padding: 17,
                                        paddingRight: 24,
                                        paddingLeft: 24,
                                        borderRadius: 20,
                                        textAlign: 'center',
                                        ...centerElementInnerStyles,
                                    }}
                                    onClick={() => onConfirm && onConfirm(selectedValue)}
                                >
                                    <span className="h6" style={{ color: '#FFF', fontWeight: 'bold', fontSize: 18 }}>
                                        {confirmMessage}
                                    </span>
                                </motion.div>
                            </div>
                        </ModalBody>
                    </Modal>
                )}
            </div>
        </>
    );
}

ModalSelect.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    toggleModal: PropTypes.func,
    onCancel: PropTypes.func,
    confirmMessage: PropTypes.string,
    cancelMessage: PropTypes.string,
    data: PropTypes.object,
    options: PropTypes.any,
    type: PropTypes.string,
    selectedRowValue: PropTypes.any,
    dataType: PropTypes.string,
};

export default ModalSelect;
