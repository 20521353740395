import {
    LABEL_PRINT_SHIPPING_LABEL_GENERATE_QUICK_PRINT,
    LABEL_PRINT_SHIPPING_LABEL_GET_DELIVERY_IDS,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Generate Quick Print
 * @param {object} data
 * @returns {Promise}
 */
export const generateQuickPrintService = (data) => {
    try {
        const url = UrlBuilder().setUrl(LABEL_PRINT_SHIPPING_LABEL_GENERATE_QUICK_PRINT).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Shipping Label Delivery Ids
 * @param {object} data
 * @returns {Promise}
 */
export const getShippingLabelDeliveryIds = (query = {}) => {
    try {
        const url = UrlBuilder().setUrl(LABEL_PRINT_SHIPPING_LABEL_GET_DELIVERY_IDS).setQueryParams(query).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
