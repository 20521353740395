/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import toast from 'react-hot-toast';
import * as qs from 'query-string';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import _ from 'lodash';

// #region assets | components
import { Breadcrumbs, ClientAlerts, CustomToast, TableCustom } from 'components';
import PlusCircleIcon from 'components/Icons/PlusCircle';

// #region imports
import PATHS from 'routes/paths';
import { TABLE_HEADER_TYPES } from 'constants/table';

// #region services
import {
    getPartnerApiSandboxAccountPendingActions,
    getSandboxAccountTableService,
} from 'services/partnerApi/sandboxAccount.service';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #endregion imports
import { sandboxAccountInitialTableData } from './constant';
import { mapDocs } from './utils';

function SandboxAccountListViewPage(props) {
    const { location } = props;
    const history = useHistory();

    const _isMounted = useRef(true);

    const breadcrumbItems = [
        { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
        { title: 'Developer', link: `/${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.LIST}` },
    ];

    /** Data for the table */
    const [sandboxTableData, setSandboxTableData] = useState(sandboxAccountInitialTableData);

    const [tableDataLoading, setTableDataLoading] = useState(false);

    const [totalPendingRequests, setTotalPendingRequests] = useState(0);

    /** URL search parameters */
    const [urlParamsSearch, setUrlParamsSearch] = useState({});
    /** Table search parameters */
    const [searchParams, setSearchParams] = useState(null);

    const defaultSearchParams = {
        page: searchParams?.page,
        searchText: searchParams?.searchText,
        limit: searchParams?.limit,
        requestStatus: searchParams?.requestStatus,
    };

    // component cleanup
    useEffect(
        () => () => {
            _isMounted.current = false;
        },
        []
    );

    useEffect(() => {
        loadPendingActionRequests();
    }, []);

    /** Triggers the loadApiKeyData function if the URL search params (in urlParamsSearch state) have changed */
    useEffect(() => {
        if (urlParamsSearch.page) {
            loadSandboxAccountTableData(
                urlParamsSearch.page,
                urlParamsSearch.limit,
                sandboxTableData.sort,
                sandboxTableData.order,
                {
                    searchBy: 'multi',
                    searchText: urlParamsSearch?.searchText,
                },
                urlParamsSearch.requestStatus || 'pending'
            );
        }
    }, [urlParamsSearch]);

    /** Reads the URL and updates the urlParamsSearch state, which triggers another useEffect to fetch data */
    useEffect(() => {
        const urlSearch = qs.parse(location.search);
        if (urlSearch && (urlSearch.searchText || urlSearch.page || urlSearch.requestStatus)) {
            const { page, searchBy, searchText, limit, requestStatus } = urlSearch;
            setUrlParamsSearch({ page, searchBy, searchText, limit, requestStatus });
            setSearchParams({ searchBy, searchText });
        } else {
            replaceUrlParamsHandler({ page: '1', limit: 10 });
        }
    }, [location.search]);

    /**
     * Replaces the URL params when user types in the search box
     * @param {object} params - should contain page (string), limit (string), searchBy (string), searchText (string)
     */
    const replaceUrlParamsHandler = (
        params = { page: '1', limit: '10', searchBy: null, searchText: null, requestStatus: null }
    ) => {
        try {
            history.replace({
                search: `?${
                    params.page || urlParamsSearch.page ? `page=${params.page || urlParamsSearch.page || '1'}&` : ''
                }${
                    params.limit || urlParamsSearch.limit
                        ? `limit=${params.limit || urlParamsSearch.limit || '10'}&`
                        : ''
                }${params?.searchBy ? `searchBy=${params.searchBy}&` : ''}${
                    params?.searchText ? `searchText=${params.searchText}&` : ''
                }${params?.requestStatus ? `requestStatus=${params.requestStatus}&` : ''}`,
                state: location.state,
            });
        } catch (e) {
            console.error(e);
            toast.custom(
                (t) => <CustomToast text="Failed to update URL to match search parameters" t={t} type="error" />,
                { position: 'top-right' }
            );
        }
    };

    const loadPendingActionRequests = async (tab) => {
        try {
            const { data } = await getPartnerApiSandboxAccountPendingActions(tab);
            if (data?.payload) {
                setTotalPendingRequests(data.payload);
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        }
    };

    /**
     * Calls the relevant service to get API key list
     * @param {number} page - page number
     * @param {number} limit - number of entries to paginate by
     * @param {string} sort - field to sort by
     * @param {number} order - order to sort by: -1 or 1
     * @param {object} searchByProp - search prop - contains searchBy and searchText fields
     */
    const loadSandboxAccountTableData = async (page, limit, sort, order, searchByProp, requestStatus) => {
        setTableDataLoading(false);

        try {
            const { data } = await getSandboxAccountTableService(page, limit, sort, order, searchByProp, requestStatus);
            const { payload } = data;
            if (data && payload) {
                const mappedDocs = mapDocs(payload.docs);
                setSandboxTableData({ ...sandboxTableData, ...payload, docs: mappedDocs });
            } else {
                toast.custom((t) => <CustomToast text="Failed to load data" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setTableDataLoading(false);
    };

    /** Opens the merchant profile page in a new tab */
    const handleViewMerchantProfile = (row) => {
        if (!row || !row.merchantId) return;
        window.open(`${PATHS.MERCHANT.MERCHANT_FORM}?type=edit&id=${row.merchantId}`, '_blank');
    };

    const handleClickNewSandboxAccount = () => {
        history.push({
            pathname: `/${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.FORM}`,
            search: `?type=new`,
        });
    };

    const navigateToManagePage = (data) => {
        if (!data._id) {
            return;
        }
        history.push({
            pathname: `/${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.FORM}`,
            search: `?type=edit`,
            state: { sandboxData: data },
        });
    };

    const handleFilter = (field, filterText) => {
        let modifiedFilterText = null;
        if (filterText && filterText !== '') {
            modifiedFilterText = filterText.replaceAll(' ', '$2');
        } else if (!filterText || filterText === '') {
            modifiedFilterText = null;
        }

        replaceUrlParamsHandler({
            ...defaultSearchParams,
            searchBy: field || 'multi',
            searchText: modifiedFilterText,
            page: '1',
        });
    };

    /** Handles page change and adds those parameters to the URL */
    const handlePageChange = (pageNo) => {
        replaceUrlParamsHandler({
            ...defaultSearchParams,
            page: pageNo,
        });
    };

    function handleCheckHeader(event, statusValue) {
        const { checked, name } = event.target;
        if (name === 'requestStatus') {
            let currentStatus = sandboxTableData.requestStatus;
            if (checked) {
                currentStatus.push(statusValue);
            } else {
                currentStatus = currentStatus.filter((e) => e !== statusValue); // will return ['A', 'C']
            }
            setSandboxTableData({ ...sandboxTableData, requestStatus: _.union(currentStatus) });
        }
    }

    const handleHeaderFilter = () => {
        const { limit, page, sort, order, requestStatus } = sandboxTableData;
        loadSandboxAccountTableData(
            page,
            limit,
            sort,
            order,
            {
                searchBy: 'all',
                searchText: urlParamsSearch?.searchText,
            },
            requestStatus
        );
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <br />
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <ClientAlerts />
                    <Row>
                        <Col>
                            <Card className="shadow default-card">
                                <CardHeader
                                    className="pl-3"
                                    style={{
                                        borderTopRightRadius: 20,
                                        borderTopLeftRadius: 20,
                                        border: 1,
                                        borderColor: '#E7E4E4',
                                    }}
                                >
                                    <Row className="flex align-items-center align-content-center">
                                        <Col xs="auto" style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}>
                                            Sandbox Account Requests
                                        </Col>
                                        <div
                                            className="flex justify-content-center p-2 ml-2"
                                            style={{
                                                backgroundColor: '#A39D90',
                                                borderRadius: '15px',
                                                color: '#FFF',
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            {totalPendingRequests > 0 ? (
                                                <>
                                                    Action Pending for {totalPendingRequests}{' '}
                                                    {totalPendingRequests > 1 ? 'Requests' : 'Request'}
                                                </>
                                            ) : (
                                                <>No New Requests</>
                                            )}
                                        </div>

                                        <Col xs="auto" className="ml-auto pr-0">
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-btn-radius"
                                                style={{ borderRadius: 10 }}
                                                onClick={() => handleClickNewSandboxAccount()}
                                            >
                                                <PlusCircleIcon />
                                                &nbsp;Create a Sandbox Account
                                            </motion.div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className="px-3">
                                    <TableCustom
                                        tableData={sandboxTableData}
                                        loading={tableDataLoading}
                                        onFilter={handleFilter}
                                        onPageChange={handlePageChange}
                                        onViewMerchantProfile={handleViewMerchantProfile}
                                        showViewMerchantProfileButton
                                        customActionHandlers={{ navigateToManagePage }}
                                        searchProp={searchParams}
                                        removeAction
                                        tableHeaderType={TABLE_HEADER_TYPES.PARTNER_API.SANDBOX_ACCOUNT_LIST_VIEW}
                                        customHeaderProps={{
                                            requestStatus: sandboxTableData.requestStatus,
                                            handleCheck: handleCheckHeader,
                                            handleHeaderFilter,
                                        }}
                                        filters={[
                                            {
                                                key: 'multi',
                                                label: 'Any',
                                            },
                                        ]}
                                        isCardContainer={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

SandboxAccountListViewPage.propTypes = {
    location: PropTypes.any,
};

const HotSandboxAccountListViewPage = hot(SandboxAccountListViewPage);

export default HotSandboxAccountListViewPage;
