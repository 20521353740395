import { isObject } from 'utils/helpers';

export const mapDocs = (docs) => {
    let allDocs = [];
    allDocs = docs.map((x) => ({
        ...x,
        merchant: x.merchantId && isObject(x.merchantId) ? x.merchantId : null,
        merchantName: x.merchantId && isObject(x.merchantId) ? x.merchantId.name : null,
        merchantPictureUrl: x.merchantId && isObject(x.merchantId) ? x.merchantId.merchantPictureUrl : null,
        merchantId: x.merchantId && isObject(x.merchantId) ? x.merchantId._id : x.merchantId,
    }));
    return allDocs;
};
