import React, { useState } from 'react';
import { Card, CardBody, DropdownItem, Row, Spinner } from 'reactstrap';
import moment from 'moment';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

// #region components | assets
import { CustomToast } from 'components';

function LabelPrintShippingLabel(props) {
    const { selectedNotification } = props;

    const [downloadBtnLoading, setDownloadBtnLoading] = useState(false);

    const handleDownloadAttachment = () => {
        setDownloadBtnLoading(true);
        try {
            const fileUrl = selectedNotification?.meta?.s3FileUrl;
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                toast.custom((t) => <CustomToast text="Successfully Downloaded" t={t} type="success" />, {
                    position: 'top-right',
                    duration: 5000,
                });
            } else {
                toast.custom((t) => <CustomToast text="File Not Found" t={t} type="error" />, {
                    position: 'top-right',
                    duration: 5000,
                });
            }
        } catch (e) {
            console.log(e);
        }
        setDownloadBtnLoading(false);
    };

    function renderCardData() {
        return (
            <>
                <Card style={{ borderRadius: 10, minHeight: '70vh' }}>
                    <CardBody
                        style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div>
                            <DropdownItem divider />
                            <div style={{}}>
                                <div>
                                    <div style={{ color: '#969292' }}>
                                        {moment(selectedNotification.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                                    </div>

                                    <div className="mt-2">
                                        <h4 style={{ fontSize: 25 }}>{selectedNotification.title || ''}</h4>
                                    </div>
                                    <DropdownItem divider />
                                    <div className="mt-3">
                                        <p style={{ fontSize: 17 }}>{selectedNotification.description || ''}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Row style={{ justifyContent: 'flex-start' }}>
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                    disabled={downloadBtnLoading}
                                    style={{ borderRadius: 20 }}
                                    onClick={() => handleDownloadAttachment()}
                                >
                                    {downloadBtnLoading ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>
                                            <i className="fa fa-download mr-1" style={{ color: '#FFF' }} />
                                            <span className="h6">Download</span>
                                        </>
                                    )}
                                </motion.button>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </>
        );
    }

    return <>{renderCardData()}</>;
}

LabelPrintShippingLabel.propTypes = {
    selectedNotification: PropTypes.any,
};

export default LabelPrintShippingLabel;
