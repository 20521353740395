import { PERMISSIONS } from 'utils/constants';

export const transactionsLogTableHeaders = [
    {
        key: 'sequence',
        value: 'TRANSACTION ID',
    },
    {
        key: 'reference',
        value: 'TRANSACTION REFERENCE',
    },
    {
        key: 'name',
        value: 'TRANSACTION',
    },
    {
        key: 'createdByUserFullName',
        value: 'USER',
    },
    {
        key: 'createdAt',
        value: 'TRANSACTION DATE AND TIME',
        type: 'dateTime',
    },
    {
        key: 'action',
        value: 'DETAILS',
        type: 'customActionButton',
        params: {
            buttonLabel: 'View',
            customActionHandlerName: 'displayTransactionModal',
            permissionType: PERMISSIONS.PARTNER_API_API_SERVICE,
            permissions: ['View'],
        },
    },
];

export const transactionInitialValues = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: transactionsLogTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};
