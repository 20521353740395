import { USER_ROLES } from 'constants/user/user.constant';
import { hasRole } from 'utils/checkAuth';

export function buildPathNamesForSideBar(path) {
    let currentPathname = path;

    const { clientAdmin, clientUser, merchantAdmin, merchantUser } = USER_ROLES;

    const isMerchantUsers = hasRole([merchantAdmin, merchantUser]);
    // eslint-disable-next-line no-unused-vars
    const isClientUsers = hasRole([clientAdmin, clientUser]);

    // developer-api-portal
    if (path.includes('/developer/api-portal') && !isMerchantUsers) {
        currentPathname = currentPathname.replace('/api-portal', '/api-service/list');
    }
    if (path.includes('api-key/create')) {
        currentPathname = currentPathname.replace('/api-key/create', '/api-service/list');
    }
    if (path.includes('api-key/manage')) {
        currentPathname = currentPathname.replace('/api-key/manage', '/api-service/list');
    }

    // payments
    if (path.includes('imports/billing')) {
        currentPathname = currentPathname.replace('/imports/billing', '/billing/list');
    }
    if (path.includes('imports/rider-commissions')) {
        currentPathname = currentPathname.replace('/imports/rider-commissions', '/rider-commissions/list');
    }

    return currentPathname;
}
