/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody, ModalHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import Reactotron from 'reactotron-react-js';
import { useSelector, useDispatch } from 'react-redux';

// #region imports
import { centerElementContainerStyles } from 'styles';
import {
    getUser,
    getVisitingObject,
    removeVisitingClientAndMerchant,
    setVisitingClient,
    removeVisitingMerchant,
} from 'utils/checkAuth';
import { getAllClientsService } from 'services/client.service';
import { getMerchantsByClientIdService } from 'services/merchant.service';
import { __DEV__ } from 'utils/checks';
import {
    SET_CLIENT_MERCHANT_X_CLIENTS_LOADED,
    SET_CLIENT_MERCHANT_X_MERCHANTS_LOADED,
} from 'store/reducers/global/actionTypes';
import {
    getClientMerchantXClients,
    getClientMerchantXMerchants,
    storeClientMerchantXClients,
    storeClientMerchantXMerchants,
} from 'utils/local-storage';

function ClientSelectorModal() {
    const dispatch = useDispatch();

    const [visitingClientData, setVisitingClientData] = useState({});

    const [selectedClient, setSelectedClient] = useState({});

    const [userRole, setUserRole] = useState('user'); // user, clientAdmin, superAdmin, merchantAdmin

    const [showClientDropDown, setClientDropDownShow] = useState(false);
    const [showMerchantDropDown, setMerchantDropDownShow] = useState(false);

    const [allClients, setAllClients] = useState([
        {
            name: 'OPERATION CENTER',
        },
    ]);
    const [allMerchants, setAllMerchants] = useState([
        {
            name: 'ALL',
        },
    ]);

    const _isMounted = useRef(true);

    const reduxState = useSelector((state) => state);

    useEffect(() => {
        const { visitingClient } = getVisitingObject();
        if (visitingClient) {
            setVisitingClientData(visitingClient);
            setSelectedClient(visitingClient);
        }
        const data = getUser();
        if (data) {
            setUserRole(data.role);
        }

        return () => {
            _isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (selectedClient._id && !reduxState.Global.clientMerchantXMerchantsLoaded) {
            __DEV__ && Reactotron.warn('*Client-Merchant-X : Merchant Request*');
            getMerchantsByClientIdService(selectedClient._id)
                .then((res) => {
                    if (_isMounted.current) {
                        dispatch({ type: SET_CLIENT_MERCHANT_X_MERCHANTS_LOADED, payload: true });
                        const { data } = res;
                        if (data) {
                            setAllMerchants([...allMerchants, ...data.docs]);
                            storeClientMerchantXMerchants([...allMerchants, ...data.docs]);
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else if (selectedClient._id) {
            const existingLocalStorageObject = getClientMerchantXMerchants();
            if (existingLocalStorageObject && Array.isArray(existingLocalStorageObject)) {
                setAllMerchants(existingLocalStorageObject);
            }
        }
    }, [selectedClient._id, reduxState.Global.clientMerchantXMerchantsLoaded]);

    useEffect(() => {
        if (!reduxState.Global.clientMerchantXClientsLoaded) {
            __DEV__ && Reactotron.warn('*Client-Merchant-X : Client Request*');
            getAllClientsService()
                .then((res) => {
                    if (_isMounted.current) {
                        dispatch({ type: SET_CLIENT_MERCHANT_X_CLIENTS_LOADED, payload: true });
                        const { data } = res;
                        if (data) {
                            setAllClients([...allClients, ...data.docs]);
                            storeClientMerchantXClients([...allClients, ...data.docs]);
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            const existingLocalStorageObject = getClientMerchantXClients();
            if (existingLocalStorageObject && Array.isArray(existingLocalStorageObject)) {
                setAllClients(existingLocalStorageObject);
            }
        }
    }, [reduxState.Global.clientMerchantXClientsLoaded]);

    const toggleClientToggle = () => {
        setClientDropDownShow(!showClientDropDown);
    };

    // eslint-disable-next-line no-unused-vars
    const toggleMerchantToggle = () => {
        setMerchantDropDownShow(!showMerchantDropDown);
    };

    return (
        <>
            <div className="custom-scootix-modal">
                <Modal
                    size="md"
                    isOpen
                    center
                    style={{ borderRadius: 15 }}
                    className="modal-dialog modal-dialog-centered scootix-confirmation-modal"
                >
                    <ModalHeader
                        className="scootix-bg-radiant"
                        style={{
                            marginTop: -1,
                            marginRight: -1,
                            marginLeft: -1,
                            color: '#FFF',
                            borderTopRightRadius: 15,
                            borderTopLeftRadius: 15,
                        }}
                    >
                        <h4
                            style={{
                                color: '#FFF',
                            }}
                        >
                            {' '}
                            Select Client
                        </h4>
                    </ModalHeader>
                    <ModalBody
                        style={{
                            flexDirection: 'column',
                            borderRadius: 15,
                            backgroundColor: '#FAFAFA',
                            ...centerElementContainerStyles,
                        }}
                        className="p-3"
                    >
                        <br />
                        <div className="row flex">
                            <Dropdown
                                disabled={userRole !== 'superAdmin'}
                                isOpen={showClientDropDown}
                                toggle={toggleClientToggle}
                                className="d-inline-block"
                            >
                                <DropdownToggle
                                    style={{ marginRight: -20, backgroundColor: 'transparent' }}
                                    tag="button"
                                    className="btn header-item waves-effect"
                                    id="page-header"
                                >
                                    {visitingClientData && (
                                        <span
                                            className="x-client-name-modal"
                                            style={{
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                            }}
                                        >
                                            {' '}
                                            {visitingClientData.name || 'Client'}
                                        </span>
                                    )}
                                </DropdownToggle>
                                <DropdownMenu left="true">
                                    {allClients.map((x) => (
                                        <DropdownItem
                                            key={uuid()}
                                            onClick={() => {
                                                if (x.name === 'OPERATION CENTER') {
                                                    removeVisitingClientAndMerchant();
                                                    window.location.reload();
                                                } else {
                                                    removeVisitingMerchant();
                                                    setVisitingClient(x);
                                                    window.location.reload();
                                                }
                                            }}
                                            href="#"
                                        >
                                            {x.name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
}

ClientSelectorModal.propTypes = {};

export default ClientSelectorModal;
