import * as React from 'react';
import PropTypes from 'prop-types';

function WarningIcon(props) {
    const { color = '#FAA523', width = '46', height = '46' } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6026_393)">
                <path
                    d="M22.9805 3.77344C24.9413 3.77344 26.7564 4.77202 27.7952 6.37819L27.9926 6.70785L41.601 30.187L41.7198 30.4285C42.0598 31.2693 42.196 32.1785 42.1175 33.082C42.0389 33.9855 41.7478 34.8576 41.2678 35.6271C40.7878 36.3966 40.1327 37.0416 39.3558 37.5095C38.5789 37.9775 37.7024 38.255 36.7978 38.3194L36.4164 38.3348H9.58304L9.48913 38.329L9.27446 38.3329C8.42078 38.2889 7.58763 38.0552 6.83566 37.6487C6.08369 37.2422 5.43181 36.6732 4.92746 35.983L4.71854 35.6764C4.26208 34.9592 3.97031 34.1497 3.86426 33.3062C3.7582 32.4628 3.84049 31.6063 4.10521 30.7984L4.24321 30.4266L4.35821 30.187L17.9512 6.74044C18.4484 5.84157 19.1772 5.0923 20.062 4.57052C20.9468 4.04874 21.9553 3.77351 22.9825 3.77344H22.9805ZM23.0189 26.8348L22.7755 26.8482C22.3096 26.9036 21.8803 27.1279 21.5688 27.4787C21.2572 27.8295 21.0852 28.2823 21.0852 28.7514C21.0852 29.2206 21.2572 29.6734 21.5688 30.0242C21.8803 30.3749 22.3096 30.5993 22.7755 30.6547L22.9997 30.6681L23.2431 30.6547C23.709 30.5993 24.1383 30.3749 24.4498 30.0242C24.7614 29.6734 24.9334 29.2206 24.9334 28.7514C24.9334 28.2823 24.7614 27.8295 24.4498 27.4787C24.1383 27.1279 23.709 26.9036 23.2431 26.8482L23.0189 26.8348ZM22.9997 15.3348C22.5303 15.3348 22.0771 15.5072 21.7263 15.8191C21.3755 16.1311 21.1514 16.561 21.0965 17.0272L21.083 17.2514V21.0848L21.0965 21.309C21.1519 21.7749 21.3762 22.2042 21.727 22.5157C22.0777 22.8272 22.5306 22.9993 22.9997 22.9993C23.4688 22.9993 23.9217 22.8272 24.2724 22.5157C24.6232 22.2042 24.8475 21.7749 24.903 21.309L24.9164 21.0848V17.2514L24.903 17.0272C24.848 16.561 24.6239 16.1311 24.2731 15.8191C23.9223 15.5072 23.4692 15.3348 22.9997 15.3348Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_6026_393">
                    <rect width="46" height="46" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

WarningIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default WarningIcon;
