import * as React from 'react';
import PropTypes from 'prop-types';

function SelectAllUnplannedDeliveryIcon(props) {
    const { color = '#FAA123', width = 17, height = 17, url = '#' } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath={url}>
                <path
                    d="M8.49996 15.5827C12.412 15.5827 15.5833 12.4114 15.5833 8.49935C15.5833 4.58733 12.412 1.41602 8.49996 1.41602C4.58794 1.41602 1.41663 4.58733 1.41663 8.49935C1.41663 12.4114 4.58794 15.5827 8.49996 15.5827Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.5 11.3333V8.5"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.5 5.66602H8.50875"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5641_371">
                    <rect width={width} height={height} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
SelectAllUnplannedDeliveryIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    url: PropTypes.string,
};

export default SelectAllUnplannedDeliveryIcon;
