import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import MerchantSettingImportType from './modules/MerchantSettingImportType';
import BatchDeliveryRiderAssignStatus from './modules/BatchDeliveryRiderAssignStatus';
import RoutePlanningRiderAssignStatus from './modules/RoutePlanningRiderAssignStatus';

function InfoModal(props) {
    const { toggleModal, show = false, ref, type, title = '', body, size = 'md', data } = props;

    const generateBody = () => {
        if (body) {
            return <>{body}</>;
        }
        if (type === 'merchantSettingImportType') {
            return (
                <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                    <MerchantSettingImportType />
                </div>
            );
        }
        if (type === 'batchDeliveryRiderAssignStatus') {
            return <BatchDeliveryRiderAssignStatus data={data} toggleModal={toggleModal} />;
        }
        if (type === 'routePlanningRiderAssignStatus') {
            return <RoutePlanningRiderAssignStatus data={data} toggleModal={toggleModal} />;
        }
        return <></>;
    };

    return (
        <>
            <Modal
                size={size}
                isOpen={show}
                // isOpen={show}
                // toggle={toggleModal}
                center
                className="modal-dialog modal-dialog-centered"
                ref={ref}
            >
                {title && <ModalHeader toggle={toggleModal}>{title}</ModalHeader>}
                <ModalBody className="p-0">{generateBody()}</ModalBody>
            </Modal>
        </>
    );
}

InfoModal.propTypes = {
    toggleModal: PropTypes.func,
    show: PropTypes.bool,
    ref: PropTypes.any,
    type: PropTypes.any,
    title: PropTypes.any,
    body: PropTypes.any,
    size: PropTypes.any,
    data: PropTypes.any,
};

export default InfoModal;
