import { formatter, isObject } from 'utils/helpers';

/**
 * Builds the merchant name based on the provided deliveries.
 * If the merchant names are different, it returns "Multiple".
 * Otherwise, it returns the merchant name from the first delivery.
 * @param {Array} deliveries - The array of delivery objects.
 * @returns {string} - The built merchant name.
 */
export const buildMerchantName = (deliveries) => {
    const firstMerchantName = deliveries[0]?.merchantName;
    const hasDifferentMerchantName = deliveries.some((delivery) => delivery.merchantName !== firstMerchantName);
    const merchantName = hasDifferentMerchantName ? 'Multiple' : firstMerchantName || '';
    return merchantName;
};

/**
 * Generate Summary Of Rider Commission and rider commission paginated table data
 * @param {object} data - Billing record data
 * @param setRiderCommissionMeta - State that holds the rider commission summary
 * @returns Table data and sets the riderCommissionMeta state
 */
export const mapDocs = (data, setRiderCommissionMeta) => {
    const {
        docs,
        totalRiderCommission: totalAmount = 0,
        totalRiderPaidAmount: totalPaidAmount = 0,
        totalDeliveryCount: totalDeliveries = 0,
    } = data;
    let allDocs = [];
    const totalUnpaidAmount = totalAmount - totalPaidAmount;

    allDocs = docs.map((x) => {
        const riderBalanceAmount = x.riderCommission - x.riderPaidAmount;
        const vehicleType = x.riderId?.vehicleType;
        let merchantName = x.merchantId.name;

        let { referenceNumber, merchantOrderNo, pickupLocation, deliveryAddress, recipientName } = x;
        const riderPaymentStatus = riderBalanceAmount === 0 ? 'Paid' : 'Unpaid';
        // if Multi-Stop billing record(has more than one deliveries)
        if (x.deliveries && x.deliveries?.length > 1) {
            pickupLocation = 'Multiple';
            deliveryAddress = 'Multiple';
            recipientName = 'Multiple';

            // get merchant name or Multiple depending on merchants of the deliveries
            merchantName = buildMerchantName(x.deliveries);

            // get all delivery reference numbers as comma separated string
            referenceNumber = x.deliveries?.map((delivery) => delivery.referenceNumber).join(', ');
            // get all merchant order numbers as comma separated string
            merchantOrderNo = x.deliveries?.map((delivery) => delivery.merchantOrderNo).join(', ');
        }

        return {
            ...x,
            merchantName,
            vehicleType,
            referenceNumber,
            merchantOrderNo,
            pickupLocation,
            deliveryAddress,
            recipientName,
            riderBalanceAmount,
            riderPaymentStatus,
            riderId: x?.riderId?._id,
            riderName:
                isObject(x.riderId) && x.riderId?.referenceNumber ? `${x.riderId.fullName || ''}` : `Rider Not Found`,
            riderReferenceNumber:
                isObject(x.riderId) && x.riderId?.referenceNumber
                    ? `${x.riderId.referenceNumber || ''}`
                    : `Rider Not Found`,
            formattedDistance: `${x.distance} KM`,
            formattedAmount: `MYR ${formatter.format(x.riderCommission.toFixed(2))}`,
            deliveryType: x.deliveryId && isObject(x.deliveryId) ? x.deliveryId.deliveryType : null,
        };
    });

    setRiderCommissionMeta({
        totalDeliveries,
        totalAmount,
        totalPaidAmount,
        totalUnpaidAmount,
    });

    return allDocs;
};

/**
 * Determines the payment status for a rider based on the rider's balance amount.
 * @returns {string} The payment status: 'Paid' if the balance amount is 0, 'Unpaid' otherwise.
 */
export const getRiderPaymentStatus = (riderBalanceAmount) => {
    if (riderBalanceAmount === 0) {
        return 'Paid';
    }
    return 'Unpaid';
};
