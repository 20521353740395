import { createAttachmentService, updateAttachmentService } from 'services/attachment.service';
import { CREATE_PRODUCT_MASTER_DATA, UPDATE_PRODUCT_MASTER_DATA, DELETE_PRODUCT_MASTER_DATA } from 'utils/apiEndpoints';
import { buildFormDataSingle, buildNewAttachment } from 'utils/helpers';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Create Product Master Data Service
 * @param {object} data
 * @returns {Promise}
 */
export const createProductMasterDataService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_PRODUCT_MASTER_DATA).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Product Master Data Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateProductMasterDataService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_PRODUCT_MASTER_DATA).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Product Master Data Service
 * @param {string} id
 * @returns {Promise}
 */
export const deleteProductMasterDataService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_PRODUCT_MASTER_DATA).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 *
 * @param {object} formData
 * @param {object} attachmentsObj  Attachment Object with new changes
 * @param {object} initialAttachmentDb Attachment Object With Only DB Changes
 * !! attachmentObj and initialAttachmentDb should have same keys
 * @returns
 */
export const updateProductMasterDataImagesService = async (formData, attachmentsObj, initialAttachmentDb) => {
    try {
        const payload = { ...formData, attachmentObj: formData.attachmentObj || attachmentsObj };

        // delete irrelevant data
        try {
        } catch (e) {
            console.log(e);
        }
        // Attachments Handling
        if (attachmentsObj && initialAttachmentDb) {
            const attachmentPromises = Object.keys(attachmentsObj).map(
                (k) =>
                    new Promise(async (res, rej) => {
                        const attachment = attachmentsObj[k];
                        const initialAttachment = initialAttachmentDb[k];
                        if (!attachment || !initialAttachment) {
                            console.log('Attachment Key Not Found on State');
                            return rej();
                        }
                        // Attachment Should Update Or Create ?
                        // Based on two conditions last updated and not saved DB
                        // Either One
                        if (attachment.lastUpdated !== initialAttachment.lastUpdated || !attachment.isDbSaved) {
                            const imageData = buildFormDataSingle(attachment.data);
                            if (initialAttachment.data && initialAttachment.isDbSaved) {
                                try {
                                    const { data } = await updateAttachmentService(initialAttachment.data, imageData);
                                    if (data && data.key) {
                                        payload.attachmentObj[k] = buildNewAttachment(data.key);
                                    }
                                } catch (e) {
                                    console.log(e);
                                }
                            } else {
                                try {
                                    const { data } = await createAttachmentService(imageData);
                                    if (data && data.key) {
                                        payload.attachmentObj[k] = buildNewAttachment(data.key);
                                    }
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        }
                        return res(true);
                    })
            );

            await Promise.all(attachmentPromises);
        }
        const url = UrlBuilder().setUrl(UPDATE_PRODUCT_MASTER_DATA).setPathParams({ id: payload._id }).build();
        return instance.patch(url, payload);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
