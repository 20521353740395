/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, DropdownItem, Label, Spinner, Modal, ModalBody, ModalHeader, Input } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';
import { useDispatch, connect } from 'react-redux';
import { motion } from 'framer-motion';
import _ from 'lodash';

// #region components
import { BackdropLoader, ClientAlerts, CustomToast } from 'components';

// #region imports
import { SET_CLIENT_SETTING } from 'store/reducers/global/actionTypes';
import { apiErrorHandler, mapUserObj } from 'utils/helpers';

// #region utils
import { getUser, getVisitingObject } from 'utils/checkAuth';

// #region services
import { getClientActiveSettingByClientIdService, updateClientSettingService } from 'services/client-setting.service';

function StockAdjustmentTransactionSettingsModal(props) {
    const { toggleModal } = props;

    const dispatch = useDispatch();

    const _isMounted = useRef(true);

    const [backdropLoading, setBackdropLoading] = useState(0);

    // eslint-disable-next-line no-unused-vars
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        loadActiveClientSetting();

        return () => {
            _isMounted.current = false;
        };
    }, []);

    const loadActiveClientSetting = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        const { visitingClient } = getVisitingObject();
        if (visitingClient) {
            try {
                const { data } = await getClientActiveSettingByClientIdService(visitingClient._id);
                setFormData({ ...formData, ...data });
            } catch (e) {
                console.log(e);
            }
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        setBackdropLoading((prevState) => prevState + 1);
        // eslint-disable-next-line no-unused-vars
        let isSuccess = false;

        try {
            const { data } = await updateClientSettingService(formData._id, formData);

            if (_isMounted.current && data && data.settings) {
                dispatch({ type: SET_CLIENT_SETTING, payload: data.settings });
                isSuccess = true;
                setFormData({ ...formData, ...data });
                toast.custom((t) => <CustomToast text="Updated Settings" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setBackdropLoading((prevState) => prevState - 1);
        setSubmitted(false);
    };

    const handleCheckChange = (val, id) => {
        let payload = _.set(formData, id, val);
        const userData = getUser();

        if (id === 'settings.orderProcessing.isEnabled' && val) {
            payload = _.set(formData, 'settings.orderProcessing.lastEnabledAt', new Date());
            payload = _.set(formData, 'settings.orderProcessing.lastEnabledBy', mapUserObj(userData));
        }

        if (id === 'settings.orderProcessing.isEnabled' && !val) {
            payload = _.set(formData, 'settings.orderProcessing.lastDisabledAt', new Date());
            payload = _.set(formData, 'settings.orderProcessing.lastDisabledBy', mapUserObj(userData));
        }

        if (id === 'settings.stocksReceiving.isEnabled' && val) {
            payload = _.set(formData, 'settings.stocksReceiving.lastEnabledAt', new Date());
            payload = _.set(formData, 'settings.stocksReceiving.lastEnabledBy', mapUserObj(userData));
        }

        if (id === 'settings.stocksReceiving.isEnabled' && !val) {
            payload = _.set(formData, 'settings.stocksReceiving.lastDisabledAt', new Date());
            payload = _.set(formData, 'settings.stocksReceiving.lastDisabledBy', mapUserObj(userData));
        }
        setFormData({ ...formData, ...payload });
    };

    return (
        <>
            <Modal size="lg" isOpen toggle={toggleModal} center className="modal-dialog modal-dialog-centered">
                <ModalHeader toggle={toggleModal}>Stocks Adjustment Transaction Settings</ModalHeader>

                <ClientAlerts />

                {formData.settings && (
                    <ModalBody>
                        <AvForm
                            className="needs-validation default-form"
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                            id="stockAdjustmentsTransactionSettings"
                        >
                            {formData.settings.orderProcessing && (
                                <Row>
                                    <Col md={4}>
                                        <h5 style={{ color: '#3C405B' }} className="">
                                            Order Processing Module
                                        </h5>
                                        <span color="#5d5d5a">Settings for Order Processing Module </span>
                                    </Col>
                                    <Col md={6}>
                                        <Row className="pl-3">
                                            <div className="custom-control custom-switch" dir="ltr">
                                                <Input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="orderProcessingIsEnabled"
                                                    defaultChecked
                                                    onChange={() =>
                                                        handleCheckChange(
                                                            !formData.settings.orderProcessing.isEnabled,
                                                            'settings.orderProcessing.isEnabled'
                                                        )
                                                    }
                                                    checked={formData.settings.orderProcessing.isEnabled}
                                                />
                                                <Label
                                                    className="custom-control-label"
                                                    htmlFor="orderProcessingIsEnabled"
                                                >
                                                    Toggle Enable Module
                                                </Label>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            )}

                            <br />
                            <DropdownItem divider className="mt-4" />
                            <br />

                            {formData.settings.stocksReceiving && (
                                <Row>
                                    <Col md={4}>
                                        <h5 style={{ color: '#3C405B' }} className="">
                                            Stocks Receiving Module
                                        </h5>
                                        <span color="#5d5d5a">Settings for Stocks Receiving Module </span>
                                    </Col>
                                    <Col md={6}>
                                        <Row className="pl-3">
                                            <div className="custom-control custom-switch" dir="ltr">
                                                <Input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="stocksReceivingIsEnabled"
                                                    defaultChecked
                                                    onChange={() =>
                                                        handleCheckChange(
                                                            !formData.settings.stocksReceiving.isEnabled,
                                                            'settings.stocksReceiving.isEnabled'
                                                        )
                                                    }
                                                    checked={formData.settings.stocksReceiving.isEnabled}
                                                />
                                                <Label
                                                    className="custom-control-label"
                                                    htmlFor="stocksReceivingIsEnabled"
                                                >
                                                    Toggle Enable Module
                                                </Label>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            )}

                            <Row style={{ justifyContent: 'flex-end' }}>
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                    type="submit"
                                    disabled={backdropLoading > 0}
                                >
                                    {backdropLoading > 0 ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>
                                            <i className="fas fa-save left-icon"></i>
                                            <span className="h6">Update</span>
                                        </>
                                    )}
                                </motion.button>
                            </Row>
                        </AvForm>
                    </ModalBody>
                )}
            </Modal>

            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

StockAdjustmentTransactionSettingsModal.propTypes = {
    toggleModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
    layout: state.Layout,
});

export default connect(mapStateToProps, null)(StockAdjustmentTransactionSettingsModal);
