export const getMappedData = (data, uniqueRiders) => {
    let filteredDataByRider = [];
    const finalRecords = [];

    // eslint-disable-next-line array-callback-return
    uniqueRiders.map((rider) => {
        filteredDataByRider = data.filter((d) => d.riderUniqueId === rider);
        const flatOutReportData = filteredDataByRider.reduce((r, c) => Object.assign(r, c), {});

        finalRecords.push(flatOutReportData);
    });

    return finalRecords;
};
