/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useState, Suspense, useEffect, useRef } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import io from 'socket.io-client';
import LogRocket from 'logrocket';

import { getSocketProps, SOCKET_SERVICE_ENDPOINT } from 'utils/socket-io';
import { __PROD__, __TEST__ } from 'utils/checks';

// Components
import BackdropLoader from 'components/BackdropLoader';
import Error404 from 'pages/Utility/Error404';
import LoadingScreen from 'components/LoadingScreen';
import { CustomToast } from 'components';

// #region imports
import { SET_SOCKET, SET_CLIENT_SETTING } from 'store/reducers/global/actionTypes';
import { CLIENT_MERCHANT_X_CLIENTS, CLIENT_MERCHANT_X_MERCHANTS } from 'constants/local-storage.constants';
import { SOCKET_EVENTS } from 'constants/socket';

// #region utils
import { encryptedLocalStorage } from 'utils/local-storage';
import { backToLogin, getUser, getVisitingObject } from 'utils/checkAuth';
import { apiErrorHandlerV2 } from 'utils/helpers';

// #region services
import { getClientActiveSettingByClientIdService } from 'services/client-setting.service';
import { verifyAuthenticated } from 'services/user.service';

// Import Routes
import { authProtectedRoutes, publicRoutes } from './routes';
import AppRoute from './routes/route';

// layouts
import VerticalLayout from './components/VerticalLayout';
import HorizontalLayout from './components/HorizontalLayout';
import NonAuthLayout from './components/NonAuthLayout';

// Import css
import './styles/theme.scss';
import './styles/index.css';
import './styles/modules/header.css';

import CONFIG from './app-config';

function App(props) {
    const reduxSelector = useSelector((state) => state);

    const dispatch = useDispatch();

    const _isMounted = useRef(true);

    const [socket, setSocket] = useState(null);

    // State of loading Screen
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const { subVersion, isForceReloadNewVersion } = CONFIG;

        const currentCodeVersion = subVersion; // Replace with your current code version
        const previousCodeVersion = localStorage.getItem('app_version'); // Retrieve previous code version from local storage

        if (previousCodeVersion !== currentCodeVersion) {
            // If previous code version doesn't match current code version, store current code version in local storage
            localStorage.setItem('app_version', currentCodeVersion);
            if (isForceReloadNewVersion) {
                window.location.reload(true);
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener('beforeunload', handlePageReloadClean);
        return () => {
            window.removeEventListener('beforeunload', handlePageReloadClean);
            // removeSocketSession();
        };
    }, []);

    useEffect(() => {
        loadActiveClientSetting();
        // if user is logged in verify user first
        const userData = getUser();
        if (userData) {
            // If user is logged in then verify user Auth
            verifyUserAuthenticated();
        } else {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        const userData = getUser();
        if (userData) {
            let isLogEnabled = false;

            if (userData.role && userData.role === 'clientUser') {
                if (__TEST__) LogRocket.init('jvpq7x/scootix-console-test');
                if (__PROD__) LogRocket.init('jvpq7x/scootix-console-prod');
                isLogEnabled = true;
            }
            if (userData.role && userData.role === 'clientAdmin') {
                if (__TEST__) LogRocket.init('jvpq7x/scootix-console-test');
                if (__PROD__) LogRocket.init('jvpq7x/scootix-console-prod');
                isLogEnabled = true;
            }
            if (isLogEnabled) {
                LogRocket.identify(userData._id, {
                    name: userData.fullName || '',
                    email: userData.email,
                });
            }
        }
        if (userData && !socket) {
            const newSocket = io(SOCKET_SERVICE_ENDPOINT, getSocketProps());

            if (newSocket && _isMounted.current) {
                dispatch({ type: SET_SOCKET, payload: newSocket });
            }
            newSocket.on('invalid_access_token', () => {
                newSocket.disconnect();
                console.log('invalid_access_token');
            });
            setSocket(newSocket);
            return () => newSocket.close();
        }

        return () => {
            _isMounted.current = false;
        };
    }, [setSocket, reduxSelector.Login.loading]);

    // user Logout
    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENTS.USER_LOGOUT.SELF_ACCOUNT_DELETION, (res) => {
                if (res?.key === 'logout_user') {
                    backToLogin('');
                }
            });
        }
    }, [socket]);

    const handlePageReloadClean = () => {
        encryptedLocalStorage.removeItem(CLIENT_MERCHANT_X_CLIENTS);
        encryptedLocalStorage.removeItem(CLIENT_MERCHANT_X_MERCHANTS);
    };

    /**
     * Check whether if user authenticated or not
     * If it is not authenticated showing loading screen and redirect to login screen
     */
    const verifyUserAuthenticated = async () => {
        try {
            const { data } = await verifyAuthenticated();
            if (!data) {
                backToLogin('');
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setIsLoading(false);
    };

    const loadActiveClientSetting = async () => {
        const visitingObject = getVisitingObject();

        if (visitingObject) {
            const { visitingClient } = visitingObject;
            if (visitingClient) {
                try {
                    const { data } = await getClientActiveSettingByClientIdService(visitingClient._id);
                    if (_isMounted.current && data && data.settings) {
                        dispatch({ type: SET_CLIENT_SETTING, payload: data.settings });
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }
    };

    /**
     * Returns the layout
     */
    const getLayout = () => {
        let layoutCls = VerticalLayout;
        switch (props.layout.layoutType) {
            case 'horizontal':
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    const Layout = getLayout();

    return (
        <React.Fragment>
            <Suspense fallback={BackdropLoader}>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                                currentProps={props}
                                socket={socket}
                            />
                        ))}

                        {/* Loading Screen */}
                        {isLoading ? (
                            <LoadingScreen />
                        ) : (
                            authProtectedRoutes.map((route, idx) => (
                                <AppRoute
                                    path={route.path}
                                    layout={Layout}
                                    component={route.component}
                                    key={idx}
                                    isAuthProtected
                                    exact
                                    socket={socket}
                                />
                            ))
                        )}

                        <AppRoute
                            path="*"
                            layout={NonAuthLayout}
                            component={Error404}
                            key={4322}
                            isAuthProtected={false}
                            currentProps={props}
                        />
                    </Switch>
                    <Toaster position="bottom-right" reverseOrder={false} />
                </Router>
            </Suspense>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    layout: state.Layout,
});

const HotApp = hot(App);

export default connect(mapStateToProps, null)(HotApp);
