export const THEME_COLOR = '#f58421';
export const THEME_COLOR_1 = '#f58421';
export const THEME_COLOR_2 = '#f68521';

export const FONT_PRIMARY_BLACK = '#464B50';
export const FONT_PRIMARY_BLACK_TWO = '#5f6266';
export const FONT_PRIMARY_GRAY = '#606467';
export const FONT_PRIMARY_WHITE = '#F6F6F6';

export const DANGER_RED = '#FD4A4A';

export const TOAST_STYLES = {
    SUCCESS: {
        style: {
            // border: '1px solid #713200',
            padding: '16px',
            color: '#000',
            backgroundColor: '#fff',
        },
        // iconTheme: {
        //     primary: '#713200',
        //     secondary: '#FFFAEE',
        // },
    },
    ERROR: {
        style: {
            // border: '1px solid #713200',
            padding: '16px',
            color: '#000',
            backgroundColor: '#fff',
        },
        // iconTheme: {
        //     primary: '#713200',
        //     secondary: '#FFFAEE',
        // },
    },
};

export const CUSTOM_TOAST = {
    messageStyle: {
        padding: '16px',
        color: '#000',
        backgroundColor: '#f8ebfc',
        fontSize: 15,
    },
};
