import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Label, Spinner, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';

// #region services
import { profileChangeNewPasswordService } from 'services/profile.service';
import { getApiErrorMessage } from 'constants/api';
import { BackdropLoader } from 'components';
import { riderProfileChangeNewPasswordService } from 'services/rider.service';

function ChangePasswordModalNew(props) {
    const { userData = null, toggleModal, isRider = false } = props;

    const [backdropLoading, setBackdropLoading] = useState(0);

    const [formData, setFormData] = useState({ id: userData._id });
    // eslint-disable-next-line no-unused-vars
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();
        if (formData.newPassword !== formData.confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }
        setBackdropLoading((prevState) => prevState + 1);
        let isSuccess = false;
        try {
            if (isRider) {
                await riderProfileChangeNewPasswordService(formData);
            } else {
                await profileChangeNewPasswordService(formData);
            }
            toast.success(`Successfully Changed Password`);
            isSuccess = true;
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors) {
                const message = getApiErrorMessage(e.data.errors.msg);
                toast.error(message);
            } else {
                toast.error('Something went wrong');
            }
        }
        setBackdropLoading((prevState) => prevState - 1);
        setSubmitted(false);
        if (isSuccess) {
            toggleModal();
        }
    };

    return (
        <>
            <Modal size="lg" isOpen toggle={toggleModal} center className="modal-dialog modal-dialog-centered">
                <ModalHeader toggle={toggleModal}>Change Password</ModalHeader>
                <ModalBody>
                    <AvForm
                        className="needs-validation default-form"
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                        id="userRegistrationForm"
                    >
                        <Row>
                            <Col md="3">
                                <Label className="mt-2" htmlFor="newPassword">
                                    New Password
                                </Label>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <AvField
                                        name="newPassword"
                                        placeholder="New Password"
                                        type="password"
                                        errorMessage="Enter Password"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        id="newPassword"
                                        value={formData.newPassword}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <Label className="mt-2" htmlFor="confirmPassword">
                                    Renter Password
                                </Label>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <AvField
                                        name="confirmPassword"
                                        placeholder="Renter Password"
                                        type="password"
                                        errorMessage="Enter Password"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        id="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ justifyContent: 'flex-end' }}>
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                type="submit"
                                disabled={backdropLoading > 0}
                            >
                                {backdropLoading > 0 ? (
                                    <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                ) : (
                                    <>
                                        <i className="fas fa-save left-icon"></i>
                                        <span className="h6">Change Password</span>
                                    </>
                                )}
                            </motion.button>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>

            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

ChangePasswordModalNew.propTypes = {
    toggleModal: PropTypes.func,
    userData: PropTypes.object,
    isRider: PropTypes.any,
};

export default ChangePasswordModalNew;
