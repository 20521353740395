/* eslint-disable camelcase */
export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { country, addressMeta, address } = form;
    if (!country || !addressMeta || !address) {
        return { isFormValid, message };
    }

    isFormValid = true;
    return { isFormValid, message };
};

// eslint-disable-next-line consistent-return
export const buildLocationData = (id, idMeta, data) => {
    if (id === 'address') {
        if (data) {
            const { city = '', address = '', state = null, postal_code = '' } = data;
            return {
                city,
                state,
                [id]: `${address ? `${address}` : ''}`,
                [idMeta]: data,
                postalCode: postal_code || '',
            };
        }
        return {
            [id]: null,
            [idMeta]: null,
            city: '',
            state: '',
            postalCode: '',
        };
    }
};
