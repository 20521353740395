/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row, NavLink, NavItem, Nav } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import * as qs from 'query-string';

// #region imports
import Breadcrumbs from 'components/Common/Breadcrumb';
import { StatCard, TableCustom } from 'components';
import PATHS from 'routes/paths';
import { getOrdersService, getAllOrdersMetaService } from 'services/order.service';

// #endregion imports
import { defaultProcessedOrdersTableHeaders } from '../constants';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Order Processing', link: PATHS.ORDER_PROCESSING.DEFAULT },
    { title: 'Processed Orders', link: PATHS.ORDER_PROCESSING.PROCESSED_ORDERS },
];

const IS_MOCK_DATA = false;

/**
 * Processed Orders Page
 * @param {*} props
 * @returns {React.Component}
 */
function ProcessedOrdersPage(props) {
    const history = useHistory();

    // component state
    const [loading, setLoading] = useState(0);

    const [activeTab, setActiveTab] = useState('1');
    const [tabStatus, setTabStatus] = useState('dispatched');

    const [orderMeta, setOrderMeta] = useState({
        dispatchedOrders: 0,
        completedOrders: 0,
    });
    const [ordersListData, setOrdersListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultProcessedOrdersTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    const [searchBy] = useState({ searchBy: '', searchText: null });

    useEffect(() => {
        if (IS_MOCK_DATA) {
            // setOrdersList*Data({ ...ordersListData, docs: ordersListMockData });
        }
    }, []);

    useEffect(() => {
        // eslint-disable-next-line react/prop-types
        const urlSearch = qs.parse(props.location.search);
        if (urlSearch && urlSearch.tab) {
            setActiveTab(urlSearch.tab);
            setTabStatus(urlSearch.tab === '1' ? 'dispatched' : 'completed');
        } else {
            setTabStatus('dispatched');
        }
    }, []);

    useEffect(() => {
        loadOrdersListData(
            true,
            ordersListData.all,
            1,
            ordersListData.limit,
            ordersListData.filter,
            ordersListData.sort,
            ordersListData.order,
            searchBy,
            tabStatus
        );
    }, [tabStatus]);

    useEffect(() => {
        getAllOrdersMetaService().then((res) => {
            const { data } = res;
            if (data) {
                setOrderMeta({ ...orderMeta, ...data });
            }
        });
    }, [tabStatus]);

    const handlePageChange = (pageNo) => {
        loadOrdersListData(
            true,
            ordersListData.all,
            pageNo,
            ordersListData.limit,
            ordersListData.filter,
            ordersListData.sort,
            ordersListData.order,
            searchBy,
            tabStatus
        );
    };

    const handleFilter = (field, filterText) => {
        loadOrdersListData(
            false,
            ordersListData.all,
            1,
            ordersListData.limit,
            ordersListData.filter,
            ordersListData.sort,
            ordersListData.order,
            { searchBy: field || 'reference', searchText: filterText },
            tabStatus
        );
    };

    // eslint-disable-next-line no-shadow
    const loadOrdersListData = (showLoading = true, all, page, limit, filter, sort, order, searchBy, status) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getOrdersService(all, page, limit, filter, sort, order, searchBy, null, status)
            .then((res) => {
                const { data } = res;
                setOrdersListData({
                    ...ordersListData,
                    ...data,
                });
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const handleEditRow = (e, row) => {
        history.push({
            pathname: `${PATHS.ORDER_PROCESSING.ORDER_FORM}`,
            search: `?type=edit`,
            state: {
                orderParentData: row,
                prevCompState: { activeTab, pageUrl: PATHS.ORDER_PROCESSING.PROCESSED_ORDERS },
            },
        });
    };

    return (
        <>
            <div className="page-content order-processing-dashboard">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />

                    <Row>
                        <Nav tabs className="nav-tabs-custom order-processing">
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '1',
                                    })}
                                    onClick={() => {
                                        setActiveTab('1');
                                        setTabStatus('dispatched');
                                    }}
                                >
                                    <span className="d-none d-sm-block h5">
                                        Dispatched ( {orderMeta.dispatchedOrders} )
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '2',
                                    })}
                                    onClick={() => {
                                        setActiveTab('2');
                                        setTabStatus('completed');
                                    }}
                                >
                                    <span className="d-none d-sm-block h5">
                                        Completed ( {orderMeta.completedOrders} )
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Row>
                    <br />
                    <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div>
                            {/* {activeTab === '1' && hasAccess(PERMISSIONS.DISPATCH_READY, ['View']) && ( */}
                            {activeTab === '1' && (
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="w3-animate-opacity"
                                >
                                    <StatCard
                                        title="DISPATCHED"
                                        value={`${orderMeta.dispatchedOrders} ORDERS`}
                                        icon="fas fa-paper-plane"
                                        tooltipTitle="Orders Dispatched"
                                        tooltipPlacement="right"
                                        iconSize="fa-2x"
                                    />
                                </motion.div>
                            )}
                            {/* {activeTab === '2' && hasAccess(PERMISSIONS.COMPLETED_ORDERS, ['View']) && ( */}
                            {activeTab === '2' && (
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="w3-animate-opacity"
                                >
                                    <StatCard
                                        title="COMPLETED"
                                        value={`${orderMeta.completedOrders} ORDERS`}
                                        icon="fas fa-check-square"
                                        tooltipTitle="Completed Orders"
                                        tooltipPlacement="right"
                                        iconSize="fa-2x"
                                    />
                                </motion.div>
                            )}
                        </div>

                        <div></div>
                    </Row>
                    <Row>
                        <TableCustom
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            tableData={ordersListData}
                            loading={loading}
                            handleEdit={handleEditRow}
                        />
                    </Row>
                </Container>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

const HotProcessedOrdersPage = hot(ProcessedOrdersPage);

export default connect(mapStateToProps, {})(HotProcessedOrdersPage);
