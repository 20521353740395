import { NewWindow, PlusSquare, RemoveIcon } from 'components/Icons';
import React from 'react';
import Avatar from 'react-avatar';
import { Popup } from 'react-leaflet';
import { Col, Row } from 'reactstrap';

import { CustomToolTipPropTypes } from '../constants';
import './styles/index.scss';

/**
 * This component is responsible for showing custom tooltip for markers
 * Though this component named as a tooltip for searching and understanding easiness,
 * Technically in what is used here is popup in leaflet not the exact tooltip due to technical constraints
 * @returns Custom tooltip
 */
function CustomTooltip(props) {
    const {
        mappedOrderArray,
        isPickup,
        setSelectedOrders,
        setDeselectedOrders,
        showRemove = false, // this property use to show the discard button instead on add button of the tooltip
        isActionDisable = false, // this property use to avoid displaying the add and remove button of the tooltip
    } = props;

    // This function is responsible for showing delivery details in a new tab window
    const handleOpenInNewWindowClick = (orderId) => {
        // TODO: Following url should be corrected
        window.open(`/delivery/form?type=edit&id=${orderId}`, '_blank');
    };

    // Handling when clicking on plus button in the tooltip
    const handleAddClick = (order) => {
        setSelectedOrders((prevState) => {
            // Check if there is already an order with the same properties as the new order
            if (prevState.some((item) => item.id === order.id)) {
                return prevState; // If there is, return the previous state without adding the new order
            }
            return [...prevState, order]; // If there isn't, add the new order to the previous state and return it
        });
    };

    // Handling when clicking on remove button in the tooltip
    const handleRemoveClick = (order) => {
        setDeselectedOrders((prevState) => {
            // Check if there is already an order with the same properties as the new order
            if (prevState.some((item) => item.id === order.id)) {
                return prevState; // If there is, return the previous state without adding the new order
            }
            return [...prevState, order]; // If there isn't, add the new order to the previous state and return it
        });

        setSelectedOrders((prevState) => {
            const updatedValues = prevState.filter((obj) => obj.id !== order.id);
            return updatedValues;
        });
    };

    return (
        <Popup closeButton={false}>
            <div className="tooltip-wrapper">
                {mappedOrderArray.map((order, index) => (
                    <div key={order._id} className="marker-on-map">
                        <Row className="justify-content-between ml-2 mr-2 ">
                            <span
                                className={`${
                                    isPickup
                                        ? 'marker-on-map__custom-marker-pickup'
                                        : 'marker-on-map__custom-marker-deliver'
                                }`}
                            >
                                <h5 className="text-center">{order.id}</h5>
                            </span>
                            <button
                                type="submit"
                                className="tooltip-wrapper__new-window__btn"
                                onClick={() => handleOpenInNewWindowClick(order._id)}
                            >
                                <NewWindow />
                            </button>
                        </Row>
                        <Row className="ml-2 mr-2">
                            <Col md={4} className="d-flex align-items-center">
                                <Avatar
                                    name={order.merchantName || ''}
                                    src={order.merchantPictureUrl || null}
                                    size="50"
                                    round="50px"
                                    colors={['#1e6262', '#581b98', '#364f6b']}
                                />
                            </Col>
                            <Col md={8} className="d-flex-column">
                                <h6 className="header6-color-white">Delivery ID</h6>
                                <h6 className="header6-color-white">{order.referenceNumber}</h6>
                                <h6 className="header6-color-white">Merchant Order ID</h6>
                                <h6 className="header6-color-white">{order.merchantOrderNo}</h6>
                            </Col>
                        </Row>
                        {!isActionDisable && (
                            <Row className="d-flex justify-content-end ml-2 mr-2">
                                {showRemove ? (
                                    <button
                                        type="submit"
                                        className="tooltip-wrapper__add__btn"
                                        onClick={() => handleRemoveClick(order)}
                                    >
                                        <RemoveIcon />
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        className="tooltip-wrapper__add__btn"
                                        onClick={() => handleAddClick(order)}
                                    >
                                        <PlusSquare />
                                    </button>
                                )}
                            </Row>
                        )}
                        {index !== mappedOrderArray.length - 1 && <hr className="bg-color-white" />}
                    </div>
                ))}
            </div>
        </Popup>
    );
}

CustomTooltip.propTypes = CustomToolTipPropTypes;
export default CustomTooltip;
