/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';

// #region components | assets
import { CustomToast } from 'components';

// #region imports
import { PRINT_METHODS, PRINT_TYPES } from 'constants/constants';
import { SYSTEM_DATA_TYPES, BATCH_PRINT } from 'constants/enums';
import { batchPrintSizes } from 'constants/options';

// #region services
import { getAllSystemDataByTypeModuleAndHeadService } from 'services/system-data.service';
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';

// #region utils
import { apiErrorHandlerV2, getDefaultValueForSelect } from 'utils/helpers';
import { getVisitingObject, getUser } from 'utils/checkAuth';
import { getPrinterSettingsByTypeModuleUser } from 'services/printer-settings.service';

// #endregion components
import { validateForm } from '../utils';
import { generateQuickPrintService, getShippingLabelDeliveryIds } from '../services';
import FormBody from './FormBody';

let initialFormValues = { noOfCopies: 1 };
const initialQuickFiltersValues = { isNotAssigned: false, isPickupPending: false };

export default function Form() {
    const _isMounted = useRef(true);

    const userData = getUser();

    const { visitingClient, visitingMerchant } = getVisitingObject();

    // Append Merchant Details
    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
        };
    }

    // form
    const [formLoading, setFormLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);

    // component
    const [deliveryIdLoading, setDeliveryIdLoading] = useState(false);
    const [printType] = useState(PRINT_TYPES.MULTIPLE);
    const [printMethod] = useState(PRINT_METHODS.QUICK_PRINT);
    const [quickFilters, setQuickFilters] = useState(initialQuickFiltersValues);

    // backend-data
    const [allDeliveryIds, setAllDeliveryIds] = useState([]);
    const [allPrinters, setAllPrinters] = useState([]);
    const [merchants, setMerchants] = useState([]);

    // data
    const [formData, setFormData] = useState(initialFormValues);
    const [selectedDeliveryIds, setSelectedDeliveryIds] = useState([]);

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        return () => {
            _isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        loadSystemDataPrinters();
    }, []);

    useEffect(() => {
        loadAllDeliveryIds(quickFilters);
    }, [quickFilters]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    useEffect(() => {
        loadPrinterSettings();
    }, []);

    /**
     * Load All Delivery Ids Based on Status
     */
    const loadAllDeliveryIds = async (query = {}) => {
        setDeliveryIdLoading(true);
        try {
            const { data } = await getShippingLabelDeliveryIds(query);
            if (data?.docs && Array.isArray(data.docs)) {
                const mappedData = data.docs.map((x) => ({ ...x, value: x._id }));
                setAllDeliveryIds(mappedData);
                if (quickFilters.isNotAssigned || quickFilters.isPickupPending) {
                    setSelectedDeliveryIds(mappedData);
                } else {
                    setSelectedDeliveryIds([]);
                }
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        }
        setDeliveryIdLoading(false);
    };

    const loadSystemDataPrinters = async () => {
        try {
            const { data } = await getAllSystemDataByTypeModuleAndHeadService(
                SYSTEM_DATA_TYPES.PRINTERS,
                SYSTEM_DATA_TYPES.LABEL_PRINT.SHIPPING_LABEL
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                setAllPrinters(data.map((x) => ({ label: x.name, value: x.name })));
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        }
    };

    const loadPrinterSettings = async () => {
        try {
            const { data } = await getPrinterSettingsByTypeModuleUser(
                BATCH_PRINT.PRINTER_SETTINGS.TYPES,
                BATCH_PRINT.PRINTER_SETTINGS.MODULE,
                userData._id
            );
            if (_isMounted.current && data?.settings) {
                setFormData((prevState) => ({ ...prevState, ...data.settings }));
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        }
    };

    const resetForm = () => {
        setFormData({
            noOfCopies: 1,
            printer: formData.printer,
            printSize: formData.printSize,
            merchantId: formData.merchantId,
        });
        setQuickFilters(initialQuickFiltersValues);
        setSelectedDeliveryIds([]);
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const { isFormValid, message } = validateForm({ ...formData, printType, selectedDeliveryIds });

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }
        setFormLoading(true);

        try {
            const payload = {
                ...formData,
                printType,
                printMethod,
            };

            if (printType === PRINT_TYPES.SINGLE) {
                payload.selectedDeliveryIds = [{ _id: formData.deliveryId }];
            }

            if (printType === PRINT_TYPES.MULTIPLE) {
                payload.selectedDeliveryIds = selectedDeliveryIds;
            }

            const { data } = await generateQuickPrintService(payload);

            if (data && data?.status === 'ok') {
                toast.custom(
                    (t) => (
                        <CustomToast
                            text="Please Wait You will be Notified Once Print Document Is Ready"
                            t={t}
                            type="wait"
                        />
                    ),
                    {
                        position: 'top-right',
                        duration: 6000,
                    }
                );
                resetForm();
            } else {
                toast.custom((t) => <CustomToast text="Print Failed, Try Again" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message: error } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={error} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading(false);
        setSubmitted(false);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleCheckChangeFilter = (event) => {
        const { id, checked } = event.target;
        if (printType === PRINT_TYPES.SINGLE) {
            setQuickFilters({ ...quickFilters, [id]: checked });
        }
        if (printType === PRINT_TYPES.MULTIPLE) {
            setQuickFilters({ ...quickFilters, [id]: checked });
        }
    };

    const handleSelectChange = (event, id) => {
        if (printType === PRINT_TYPES.SINGLE) {
            if (event) {
                if (id === 'merchantOrderNo') {
                    const relevantObj = allDeliveryIds.find((x) => x._id === event.value);
                    setFormData({
                        ...formData,
                        [id]: relevantObj.merchantOrderNo,
                        deliveryId: relevantObj._id,
                        deliveryReferenceNumber: relevantObj.referenceNumber,
                    });
                    return;
                }
                if (id === 'deliveryReferenceNumber') {
                    const relevantObj = allDeliveryIds.find((x) => x._id === event.value);
                    setFormData({
                        ...formData,
                        [id]: relevantObj.referenceNumber,
                        deliveryId: relevantObj._id,
                        merchantOrderNo: relevantObj.merchantOrderNo,
                    });
                    return;
                }
                if (id === 'printer') {
                    setFormData({
                        ...formData,
                        [id]: event ? event.value : null,
                        printSize: null,
                    });
                    return;
                }
                setFormData({
                    ...formData,
                    [id]: event ? event.value : null,
                });
            } else if (id === 'deliveryReferenceNumber' || id === 'merchantOrderNo') {
                setFormData({
                    ...formData,
                    deliveryId: null,
                    merchantOrderNo: null,
                    deliveryReferenceNumber: null,
                });
            } else {
                setFormData({
                    ...formData,
                    [id]: null,
                });
            }
        }
        if (printType === PRINT_TYPES.MULTIPLE) {
            if (event) {
                if (id === 'deliveryReferenceNumber' || id === 'merchantOrderNo') {
                    setSelectedDeliveryIds(event);
                }
                if (id === 'printer') {
                    setFormData({
                        ...formData,
                        [id]: event ? event.value : null,
                        printSize: null,
                    });
                    return;
                }
                setFormData({
                    ...formData,
                    [id]: event ? event.value : null,
                });
            } else {
                if (id === 'deliveryReferenceNumber' || id === 'merchantOrderNo') {
                    setSelectedDeliveryIds([]);
                }
                setFormData({
                    ...formData,
                    [id]: null,
                });
            }
        }
    };

    // Select Handlers
    const getMerchantSelect = (_id) => {
        const relevantObj = merchants.find((x) => x._id === _id);
        if (relevantObj) {
            return { label: relevantObj.name, value: relevantObj._id };
        }
        return null;
    };

    // Selects Memos Handlers
    const getSelectDeliveryId = (value) => {
        if (value) {
            const relevantObj = allDeliveryIds.find((x) => x._id === value);
            if (relevantObj) {
                return { label: relevantObj.referenceNumber, value: relevantObj._id };
            }
        }
        return null;
    };

    const getSelectMerchantOrderNumber = (value) => {
        if (value) {
            const relevantObj = allDeliveryIds.find((x) => x._id === value);
            if (relevantObj) {
                return { label: relevantObj.merchantOrderNo, value: relevantObj._id };
            }
        }
        return null;
    };

    const getSelectPrintSize = (value) => {
        if (value) {
            const relevantObj = batchPrintSizes.find((x) => x.key === value);
            if (relevantObj) {
                return { label: relevantObj.label, value: relevantObj.key };
            }
        }
        return null;
    };

    // ** Memos

    const selectedDeliveryReferenceNumber = useMemo(
        () => getSelectDeliveryId(formData.deliveryId),
        [formData.deliveryReferenceNumber]
    );

    const selectedMerchantOrderNo = useMemo(
        () => getSelectMerchantOrderNumber(formData.deliveryId),
        [formData.merchantOrderNo]
    );

    const selectedPrinter = useMemo(() => getDefaultValueForSelect(formData.printer), [formData.printer]);

    const selectedPrintSize = useMemo(() => getSelectPrintSize(formData.printSize), [formData.printSize]);

    const selectedMerchantId = useMemo(() => getMerchantSelect(formData.merchantId), [formData.merchantId, merchants]);

    const formBodyProps = {
        formData,
        allDeliveryIds,
        handleSubmit,
        formLoading,
        handleCheckChangeFilter,
        printType,
        selectedDeliveryIds,
        handleSelectChange,
        submitted,
        validated,
        deliveryIdLoading,
        allPrinters,
        handleInputChange,
        selectedMerchantId,
        merchants,
        visitingMerchant,
        selectedDeliveryReferenceNumber,
        selectedMerchantOrderNo,
        selectedPrinter,
        selectedPrintSize,
    };

    return (
        <>
            <FormBody {...formBodyProps} />
        </>
    );
}

Form.propTypes = {};
