/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Import Logo
import bg from 'assets/images/maintenance-bg.png';

function PageDisabled(props) {
    const { pageName = 'Page', message } = props;

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="mdi mdi-home-variant h2"></i>
                </Link>
            </div>

            <div className="my-5 pt-sm-5">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="text-center">
                                <Row className="justify-content-center">
                                    <Col sm={4}>
                                        <div className="maintenance-img">
                                            <img src={bg} alt="" className="img-fluid mx-auto d-block" />
                                        </div>
                                    </Col>
                                </Row>
                                <h2 className="mt-5">{pageName || 'Page'} is Disabled</h2>

                                {message && <h4>{message}</h4>}
                                <p>Please check back in sometime.</p>

                                {/* <Row>
                                    <Col md={4}>
                                        <div className="mt-4 maintenance-box">
                                            <div className="p-3">
                                                <div className="avatar-sm mx-auto">
                                                    <span className="avatar-title bg-soft-primary rounded-circle">
                                                        <i className="mdi mdi-access-point-network font-size-24 text-primary"></i>
                                                    </span>
                                                </div>

                                                <h5 className="font-size-15 text-uppercase mt-4">
                                                    Why is the Page Disabled?
                                                </h5>
                                                <p className="text-muted mb-0">
                                                    There are many variations of passages of Lorem Ipsum available, but
                                                    the majority have suffered alteration.
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mt-4 maintenance-box">
                                            <div className="p-3">
                                                <div className="avatar-sm mx-auto">
                                                    <span className="avatar-title bg-soft-primary rounded-circle">
                                                        <i className="mdi mdi-clock-outline font-size-24 text-primary"></i>
                                                    </span>
                                                </div>
                                                <h5 className="font-size-15 text-uppercase mt-4">
                                                    What is the Downtime?
                                                </h5>
                                                <p className="text-muted mb-0">
                                                    Contrary to popular belief, Lorem Ipsum is not simply random text.
                                                    It has roots in a piece of classical.
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mt-4 maintenance-box">
                                            <div className="p-3">
                                                <div className="avatar-sm mx-auto">
                                                    <span className="avatar-title bg-soft-primary rounded-circle">
                                                        <i className="mdi mdi-email-outline font-size-24 text-primary"></i>
                                                    </span>
                                                </div>
                                                <h5 className="font-size-15 text-uppercase mt-4">
                                                    Do you need Support?
                                                </h5>
                                                <p className="text-muted mb-0">
                                                    If you are going to use a passage of Lorem Ipsum, you need to be
                                                    sure there isn't anything embar..
                                                    <Link to="#" className="text-decoration-underline">
                                                        no-reply@domain.com
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
                <br />
            </div>
        </React.Fragment>
    );
}

PageDisabled.propTypes = {
    pageName: PropTypes.string,
    message: PropTypes.string,
};

export default PageDisabled;
