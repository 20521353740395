import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Button, Card, CardBody, CardHeader, Col, Form, Input, Row, Spinner } from 'reactstrap';
import toast from 'react-hot-toast';
import './styles/selectedDelivery-modal-styles.scss';

// #region assets|components
import { CustomToast, ScootixModal, TableCustom } from 'components';
import { RoutePlanModalCloseIcon } from 'components/Icons';
import CrossCircleIcon from 'components/Icons/CrossCircle';
import CheckCircleIcon from 'components/Icons/Check-Circle';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #endregion components
import AssignedRiderModal from './AssignRider-Modal';

// #endregion constants
import { selectedDeliveryInitialTableValues } from '../constants';

/**
 * This Modal relate to the selected delivery sliding modal of route planing section
 * once selected delivery button click, this modal should open
 * @param {object} props
 */
function SelectedDeliveriesSliderModal(props) {
    const { setShow, show, selectedOrders, setSelectedOrders, setDeselectedOrders } = props;

    const [formLoading, setFormLoading] = useState(false);

    /** state - input search text */
    const [searchText, setSearchText] = useState('');

    /** state - input search text */
    const [tableData, setTableData] = useState({ ...selectedDeliveryInitialTableValues, docs: selectedOrders });

    //* * scootix confirmation modal */
    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        confirmMessage: 'Yes',
        cancelMessage: 'No',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    /** state - modal showing state for rider assigned modal */
    const [openRiderAssignModal, setOpenRiderAssignModal] = useState({
        show: false,
        data: null,
    });

    /** Table data should be updated every time selectedOrders get changed */
    useEffect(() => {
        setTableData({ ...selectedDeliveryInitialTableValues, docs: selectedOrders });
    }, [selectedOrders]);

    /** callback setShow function for closing the modal */
    const onchangeClose = () => {
        setShow(false);
    };

    /**
     * Handles search functionality
     * @param {*} event - Text box change event
     */
    const handleSearch = (event) => {
        const text = event.target.value;
        if (text === '') {
            setTableData({ ...selectedDeliveryInitialTableValues, docs: selectedOrders });
            setSearchText('');
            return;
        }
        try {
            setSearchText(text.toLowerCase());
            // filtered data array of delivery objects list
            const filteredTableDataArray = tableData.docs.filter(
                (item) =>
                    item.merchantOrderNo.toLowerCase().indexOf(text) !== -1 ||
                    item.referenceNumber.toLowerCase().indexOf(text) !== -1
            );
            // create final table data by adding filtered values
            const filteredData = {
                ...selectedDeliveryInitialTableValues,
                docs: filteredTableDataArray,
            };
            setTableData(filteredData);
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    /** Open a model window when clicking a remove all button */
    const handleRemoveAll = () => {
        if (selectedOrders.length > 0) {
            setFormLoading(true);
            setScootixModalState({
                ...scootixModalState,
                show: true,
                message: `Do you want to remove all deliveries from the selected list?`,
                onConfirm: (data) => handleDeleteAllSelectedDeliveries(data),
            });
            setFormLoading(false);
        }
    };

    /** On delete Function for each row in the table */
    const handleOnDelete = (e, row) => {
        setDeselectedOrders([row]);
    };

    /** Assign selected deliveries to the riders */
    const handleAssignToRiders = () => {
        if (selectedOrders.length > 0) {
            toggleRiderAssignedModal();
        }
    };

    /** Opens the scootix confirmation modal */
    const toggleScootixModal = () => {
        setScootixModalState({ show: !scootixModalState.show, data: null });
    };

    /** Close the scootix confirmation modal */
    const closeScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    /** Opens the rider assign modal */
    const toggleRiderAssignedModal = () => {
        setOpenRiderAssignModal({
            show: !openRiderAssignModal.show,
            data: selectedOrders,
        });
    };

    /** once user click remove all, should remove all the data from the selected order array */
    const handleDeleteAllSelectedDeliveries = () => {
        setDeselectedOrders([...selectedOrders]);
        setSelectedOrders([]);
        closeScootixModal();
    };

    const assignRiderModalCommonProps = {
        openRiderAssignModal,
        toggleRiderAssignedModal,
    };

    return (
        <div>
            {show && (
                <motion.div
                    className="route-planning__grid__selected-delivery__modal"
                    animate={{
                        x: 0,
                        transition: {
                            duration: 0.5,
                        },
                        scale: 1,
                        rotate: 0,
                    }}
                    initial={{ x: 200 }}
                    variants={{
                        opacity: 1,
                        x: 0,
                    }}
                >
                    <Card>
                        <CardHeader className="selected-delivery__modal__card-header">
                            <div className="selected-delivery__modal__card-header__title">Selected Deliveries</div>
                            <div className="close-btn" onClick={onchangeClose} role="button">
                                <CrossCircleIcon color="#FFF" />
                            </div>
                        </CardHeader>
                        <CardBody className="selected-delivery__modal__card-body">
                            <Col className="p-0">
                                <Form className="app-search p-0">
                                    <div className="position-relative">
                                        <Input
                                            type="text"
                                            value={searchText}
                                            className="form-control"
                                            placeholder="Enter the Delivery ID or Merchant ID to Search"
                                            onChange={handleSearch}
                                        />
                                        <span className="ri-search-line search-icon"></span>
                                    </div>
                                </Form>
                            </Col>
                            <div className="modal-body__table">
                                {selectedOrders.length > 0 && (
                                    <TableCustom
                                        tableData={tableData}
                                        isSearch={false}
                                        isFixedHeight
                                        isFilter={false}
                                        showPagination={false}
                                        showEdit={false}
                                        showView={false}
                                        showDelete
                                        handleDelete={handleOnDelete}
                                        allowDelete
                                        showCustomDiscardActionIcon
                                    />
                                )}
                                {selectedOrders.length === 0 && (
                                    <div className="modal-body__text mt-3 mb-3">
                                        You have not selected any deliveries !!
                                    </div>
                                )}
                            </div>
                            <Row>
                                <Col xs="auto">
                                    <Button className="remove__btn form-delete-btn" onClick={handleRemoveAll}>
                                        <CrossCircleIcon color="#FFF" />
                                        &nbsp;Remove All
                                    </Button>
                                </Col>
                                <Col xs="auto" className="ml-n3">
                                    <Button
                                        className="scootix-btn-radius secondary-btn "
                                        style={{ borderRadius: 10 }}
                                        onClick={onchangeClose}
                                    >
                                        <RoutePlanModalCloseIcon color="#FFF" />
                                        &nbsp;CLOSE
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col sm={8}>
                                    <motion.div
                                        className="scootix-btn-radius secondary-btn"
                                        style={{ borderRadius: 10 }}
                                        onClick={handleAssignToRiders}
                                    >
                                        {formLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <CheckCircleIcon color="#FFF" />
                                        )}
                                        <Row className="pl-3">&nbsp;CONTINUE TO ASSIGN RIDERS</Row>
                                    </motion.div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </motion.div>
            )}
            {/* Scootix Modal */}
            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
            {/* Rider assign Modal */}
            {openRiderAssignModal?.data && <AssignedRiderModal {...assignRiderModalCommonProps} />}
        </div>
    );
}

SelectedDeliveriesSliderModal.propTypes = {
    setShow: PropTypes.func,
    selectedOrders: PropTypes.array,
    setDeselectedOrders: PropTypes.func,
    show: PropTypes.bool,
    setSelectedOrders: PropTypes.func,
    slider: PropTypes.any,
};

export default SelectedDeliveriesSliderModal;
