/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Container, CardBody, Card, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter, Link, useHistory } from 'react-router-dom';

// import images
import { APP_LOGO_WIDE_IMAGE } from 'definitions/assets';
import paths from 'routes/paths';
import Hoc from 'components/Hoc';
import toast from 'react-hot-toast';

function SelectProfilePage(props) {
    const history = useHistory();

    const [loading] = useState(false);
    const [allProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);

    const handleSubmit = () => {
        if (!selectedProfile) {
            toast.error('Select Profile to proceed');
            return;
        }
        history.push(paths.HOME.DEFAULT);
    };

    useEffect(() => {
        document.body.classList.add('auth-body-bg');
        return () => {
            document.body.classList.remove('auth-body-bg');
        };
    }, []);

    return (
        <Hoc {...props}>
            <div className="home-btn d-none d-sm-block">
                <Link to={paths.AUTH.LOGIN}>
                    <i className="mdi mdi-home-variant h2 "></i>
                </Link>
            </div>

            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={7}>
                            <div className="authentication-bg">
                                {/* <div className="bg-overlay"></div> */}
                                <img src={APP_LOGO_WIDE_IMAGE} alt="icon" />
                                <h3 className="font-size-18 mt-0">Delivery Management Console</h3>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="auth-body w-100">
                                    <Row className="justify-content-center row-container">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center" style={{ marginTop: -50 }}>
                                                    <h5 className="text-muted font-weight-bold">
                                                        Select Profile To Continue
                                                    </h5>
                                                </div>
                                                <br />
                                                <div style={{ maxHeight: 400, overflowY: 'scroll', paddingRight: 10 }}>
                                                    {allProfiles.map((x) => (
                                                        <div>
                                                            <Card
                                                                className={`auth-card-custom-${
                                                                    selectedProfile
                                                                        ? x.username === selectedProfile.username
                                                                            ? 'selected'
                                                                            : 'not-selected'
                                                                        : 'not-selected'
                                                                }`}
                                                                onClick={() => setSelectedProfile(x)}
                                                            >
                                                                <CardBody>
                                                                    <div>
                                                                        <div>
                                                                            <span style={{ fontWeight: 'bold' }}>
                                                                                {' '}
                                                                                Username{' '}
                                                                            </span>
                                                                            {x.username}
                                                                        </div>

                                                                        <div>
                                                                            <span style={{ fontWeight: 'bold' }}>
                                                                                {' '}
                                                                                Client{' '}
                                                                            </span>
                                                                            {x.client.name}
                                                                        </div>
                                                                        <div>
                                                                            <span style={{ fontWeight: 'bold' }}>
                                                                                {' '}
                                                                                Merchant{' '}
                                                                            </span>
                                                                            {x.merchant.name}
                                                                        </div>
                                                                        <div>
                                                                            <span style={{ fontWeight: 'bold' }}>
                                                                                {' '}
                                                                                Email{' '}
                                                                            </span>
                                                                            {x.email}
                                                                        </div>
                                                                        <div>
                                                                            <span style={{ fontWeight: 'bold' }}>
                                                                                {' '}
                                                                                Last Accessed{' '}
                                                                            </span>
                                                                            {moment(x.lastLogin).format(
                                                                                'YYYY-MM-DD hh:mm A'
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="p-2 mt-4">
                                                    <div className="mt-4 text-center">
                                                        <Button
                                                            disabled={loading}
                                                            color="primary"
                                                            className="auth-login-btn nw-md"
                                                            onClick={handleSubmit}
                                                        >
                                                            {loading ? (
                                                                <Spinner className="mr-2" color="secondary" size="sm" />
                                                            ) : (
                                                                'Continue'
                                                            )}
                                                        </Button>
                                                    </div>
                                                    <div className="mt-4 text-center">
                                                        <Button
                                                            disabled={loading}
                                                            color="primary"
                                                            className="auth-login-btn-back-to-login"
                                                            onClick={() => {
                                                                history.push(paths.AUTH.LOGIN);
                                                            }}
                                                        >
                                                            Back to Login
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="mt-5 text-center">
                                                    <p>© 2023 ScootiX. Developed By TASA Global Solutions Sdn Bhd</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Hoc>
    );
}

SelectProfilePage.propTypes = {};

const mapStateToProps = (state) => {
    const { loginError, loading } = state.Login;
    return { loginError, loading };
};

export default withRouter(connect(mapStateToProps, {})(SelectProfilePage));
