import React from 'react';

function AddDeliveryAttempt() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <circle
                cx="7"
                cy="17"
                r="2"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            ></circle>
            <circle
                cx="17"
                cy="17"
                r="2"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            ></circle>
            <path
                fill="#fff"
                d="M5 17.75a.75.75 0 000-1.5v1.5zM3 17h-.75c0 .414.336.75.75.75V17zm.75-4a.75.75 0 00-1.5 0h1.5zM2 4.25a.75.75 0 000 1.5v-1.5zM13 5h.75a.75.75 0 00-.75-.75V5zm-.75 12a.75.75 0 001.5 0h-1.5zM9 16.25a.75.75 0 000 1.5v-1.5zm6 1.5a.75.75 0 000-1.5v1.5zm4-1.5a.75.75 0 000 1.5v-1.5zm2 .75v.75a.75.75 0 00.75-.75H21zm0-6h.75a.75.75 0 00-.75-.75V11zm-8-.75a.75.75 0 000 1.5v-1.5zm0-5a.75.75 0 000 1.5v-1.5zM18 6l.643-.386A.75.75 0 0018 5.25V6zm2.357 5.386a.75.75 0 101.286-.772l-1.286.772zM5 16.25H3v1.5h2v-1.5zM3.75 17v-4h-1.5v4h1.5zM2 5.75h11v-1.5H2v1.5zM12.25 5v12h1.5V5h-1.5zM9 17.75h6v-1.5H9v1.5zm10 0h2v-1.5h-2v1.5zm2.75-.75v-6h-1.5v6h1.5zM21 10.25h-8v1.5h8v-1.5zm-8-3.5h5v-1.5h-5v1.5zm4.357-.364l3 5 1.286-.772-3-5-1.286.772z"
            ></path>
            <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3 9h4"></path>
        </svg>
    );
}

export default AddDeliveryAttempt;
