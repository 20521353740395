import React, { useState } from 'react';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import { motion } from 'framer-motion';
import { Alert, Button, Col, Container, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import '../../styles/index.scss';

// #region assets | components
import { ScootixModal, Select } from 'components';
import { Search as SearchIcon, SelectAllUnplannedDeliveryIcon, StartOverIcon, PlusCircle } from 'components/Icons';
import CustomDateInput from 'components/CustomDateInput/CustomDateInput';
import WarningModal from 'components/Modals/Warning-Modal';
import ConfirmationModal from 'components/Modals/Route-Planning/Modules/Confirmation';

// #region utils
import { getDateValue, reactSelectCustomStyles } from 'utils/helpers';

// #endregion constants
import RoutePlanningModal from 'components/Modals/Route-Planning';
import { RoutePlanningFormBodyPropTypes } from '../../../../constants';
import { deliveryTypeOptions, vehicleTypeOptions } from './constants';

/**
 * This modal contains the form body jsx of the route planning module
 * @param {RoutePlanningFormBodyPropTypes} props
 */

function RoutePlanningFormBody(props) {
    const {
        handleSubmit,
        formData,
        setFormData,
        selectedVehicleType,
        handleSelectChange,
        handleCheckChange,
        handleDateChange,
        selectedPickupCity,
        selectedDeliveryCity,
        selectedDeliveryTypes,
        allMerchantIds,
        selectedMerchants,
        allMerchantLocations,
        handleDropdownClick,
        selectedMerchantLocations,
        formLoading,
        submitted,
        validated,
        setSelectedDeliveryTypes,
        setSelectedMerchants,
        allUnPlannedDeliveryList,
        selectedUnPlannedDeliveryList = [],
        setSelectedUnPlannedDeliveryList,
        customLoading,
        showWarningModal,
        toggleWarningModal,
        scootixModalState,
        toggleScootixModal,
        isEditable,
        handleFormReset,
        toggleStartOverConfirmationModal,
        showConfirmationModal,
        resetForm,
        stateList,
        selectedPickupState,
        selectedDeliveryState,
        deliveryCityList,
        pickupCityList,
    } = props;

    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState(''); // modal type(Add deliveries, Select delivery and Select merchant modals)

    /**
     * Handles the modal type and their visibility
     *
     * @param {string} id - Modal type(Add Deliveries, Select Delivery or Select Merchant modal)
     * @void
     */
    const toggleModal = (id) => {
        setShowModal(!showModal);
        setType(id);
    };

    return (
        <>
            <Container fluid>
                <AvForm
                    className="route-planning__form needs-validation default-form"
                    onValidSubmit={handleSubmit}
                    id="routePlanningForm"
                    autoComplete="off"
                >
                    <fieldset disabled={isEditable}>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label htmlFor="vehicleType">Select a Vehicle Type</Label>
                                    <Select
                                        value={selectedVehicleType}
                                        options={vehicleTypeOptions}
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => handleSelectChange(event, 'vehicleType')}
                                        submitted={submitted}
                                        isDisabled={selectedUnPlannedDeliveryList.length > 0}
                                        required
                                        validated={validated}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="form-group">
                                    <div className="form-check mb-3">
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={handleCheckChange}
                                            checked={formData.isPickupReadyOnly}
                                            id="isPickupReadyOnly"
                                        />
                                        <Label className="form-check-label" htmlFor="pickupDeliveriesOnly">
                                            Display Only Pick Up Ready Deliveries
                                        </Label>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr className="divider mt-n3" />
                        <p>Optional Filters</p>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label htmlFor="deliveryDate">Delivery Date</Label>
                                    <CustomDateInput
                                        onChange={handleDateChange}
                                        type="date"
                                        className="form-control"
                                        value={getDateValue(formData.deliveryDate, 'date')}
                                        id="deliveryDate"
                                        isClearable
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label htmlFor="deliveryReadyDate">Delivery Ready Date</Label>
                                    <CustomDateInput
                                        onChange={handleDateChange}
                                        type="date"
                                        className="form-control"
                                        value={getDateValue(formData.deliveryReadyTime, 'date')}
                                        id="deliveryReadyTime"
                                        isClearable
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label htmlFor="pickupState">Pickup State</Label>
                                    <Select
                                        value={selectedPickupState}
                                        options={stateList}
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => handleSelectChange(event, 'pickupState')}
                                        submitted={submitted}
                                        isClearable
                                        isLoading={customLoading.citiesLoading}
                                        validated={validated}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label htmlFor="pickupCity">Pickup City</Label>
                                    <Select
                                        value={selectedPickupCity}
                                        options={pickupCityList}
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => handleSelectChange(event, 'pickupCity')}
                                        submitted={submitted}
                                        isClearable
                                        isLoading={customLoading.citiesLoading}
                                        validated={validated}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label htmlFor="deliveryState">Delivery State</Label>
                                    <Select
                                        value={selectedDeliveryState}
                                        options={stateList}
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => handleSelectChange(event, 'deliveryState')}
                                        submitted={submitted}
                                        isClearable
                                        isLoading={customLoading.citiesLoading}
                                        validated={validated}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label htmlFor="deliveryCity">Delivery City</Label>
                                    <Select
                                        value={selectedDeliveryCity}
                                        options={deliveryCityList}
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => handleSelectChange(event, 'deliveryCity')}
                                        submitted={submitted}
                                        isClearable
                                        isLoading={customLoading.citiesLoading}
                                        validated={validated}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr className="divider mt-n1" />
                        <Row>
                            <Col xs="auto">Delivery Types</Col>
                            {/* <Col xs="auto" className="ml-auto pr-0">
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="route-planning__btn scootix-btn-radius"
                                    onClick={() => {
                                        toggleModal('selectDeliveryType');
                                    }}
                                >
                                    <PlusCircle />
                                    &nbsp;Select
                                </motion.div>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="form-group">
                                    <div className="form-check mb-3">
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={handleCheckChange}
                                            checked={formData.isSelectAllDeliveryTypes}
                                            id="isSelectAllDeliveryTypes"
                                        />
                                        <Label className="form-check-label" htmlFor="isSelectAllDeliveryTypes">
                                            Select All Delivery Types
                                        </Label>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mt-n4">
                            <Col>
                                <FormGroup>
                                    <Select
                                        value={selectedDeliveryTypes.map((val) => ({
                                            ...val,
                                            label: val.value,
                                        }))}
                                        options={deliveryTypeOptions}
                                        isMulti
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => {
                                            handleSelectChange(event, 'deliveryTypes');
                                        }}
                                        submitted={submitted}
                                        validated={validated}
                                        isClearable
                                        maxMenuHeight={150}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr className="divider mt-n1" />
                        <Row>
                            <Col xs="auto">Merchants</Col>
                            {/* <Col xs="auto" className="ml-auto pr-0">
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="route-planning__btn scootix-btn-radius"
                                    onClick={() => {
                                        toggleModal('selectMerchant');
                                    }}
                                >
                                    <PlusCircle />
                                    &nbsp;Select
                                </motion.div>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="form-group">
                                    <div className="form-check mb-3">
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={handleCheckChange}
                                            checked={formData.isSelectAllMerchants}
                                            id="isSelectAllMerchants"
                                        />
                                        <Label className="form-check-label" htmlFor="isSelectAllMerchants">
                                            Select All Merchants
                                        </Label>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mt-n4">
                            <Col>
                                <FormGroup>
                                    <Select
                                        value={selectedMerchants}
                                        options={allMerchantIds}
                                        isMulti
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => handleSelectChange(event, 'merchants')}
                                        submitted={submitted}
                                        validated={validated}
                                        isClearable
                                        isLoading={customLoading.merchantsLoading}
                                        maxMenuHeight={150}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label htmlFor="merchantLocation">Merchant Location</Label>
                                <FormGroup>
                                    <Select
                                        value={selectedMerchantLocations}
                                        options={allMerchantLocations}
                                        isMulti
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => handleSelectChange(event, 'merchantLocations')}
                                        onMenuOpen={handleDropdownClick}
                                        submitted={submitted}
                                        isClearable
                                        isLoading={customLoading.merchantsLoading}
                                        maxMenuHeight={150}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr className="mt-n1" />
                        <Row>
                            <Col xs="auto">Deliveries</Col>
                            <Col xs="auto" className="ml-auto pr-0">
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="route-planning__btn scootix-btn-radius"
                                    onClick={() => {
                                        toggleModal('addDeliveries');
                                    }}
                                >
                                    <PlusCircle />
                                    &nbsp;Select
                                </motion.div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <FormGroup className="form-group">
                                    <div className="form-check mb-3">
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={handleCheckChange}
                                            checked={formData.isSelectAllUnplannedDeliveries}
                                            id="isSelectAllUnplannedDeliveries"
                                        />
                                        <Label className="form-check-label" htmlFor="isSelectAllUnplannedDeliveries">
                                            Select All Unplanned Deliveries
                                        </Label>
                                        <div className="tooltip-container">
                                            &nbsp;
                                            <SelectAllUnplannedDeliveryIcon />
                                            <span className="tooltip-text">
                                                Deliveries that are in the status of Dispatch Ready and do not have a
                                                Rider assigned, except for those that are Deleted or Cancelled.
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mt-n4">
                            <Col>
                                <FormGroup>
                                    <Select
                                        value={selectedUnPlannedDeliveryList}
                                        options={allUnPlannedDeliveryList}
                                        isMulti
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => handleSelectChange(event, 'unplannedDelivery')}
                                        submitted={submitted}
                                        validated={validated}
                                        isDisabled={formData.isSelectAllUnplannedDeliveries}
                                        isLoading={customLoading.deliveriesLoading}
                                        maxMenuHeight={150}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {allUnPlannedDeliveryList.length === 0 && (
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <Alert color="danger">No Deliveries Were Found !</Alert>
                                </Col>
                            </Row>
                        )}
                        {/* <Row>
                            <Col xs="auto">
                                <motion.div
                                    className="remove__btn form-delete-btn"
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={handleRemoveAll}
                                >
                                    <CrossCircleIcon color="#FFF" />
                                    &nbsp;Remove All
                                </motion.div>
                            </Col>
                        </Row> */}
                        <hr className="divider" />
                        <Row>
                            <Col>
                                <FormGroup className="form-group">
                                    <div className="form-check mb-3">
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={handleCheckChange}
                                            checked={formData.isDisplayPickupLocations}
                                            id="isDisplayPickupLocations"
                                        />
                                        <Label className="form-check-label" htmlFor="isDisplayPickupLocations">
                                            Display Pickup Locations
                                        </Label>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mt-n4">
                            <Col>
                                <FormGroup className="form-group">
                                    <div className="form-check mb-3">
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={handleCheckChange}
                                            checked={formData.isDisplayDeliveryLocations}
                                            id="isDisplayDeliveryLocations"
                                        />
                                        <Label className="form-check-label" htmlFor="isDisplayDeliveryLocations">
                                            Display Delivery Locations
                                        </Label>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </fieldset>
                    <Row>
                        <Col xs="auto mt-n2 mb-2">
                            {isEditable ? (
                                <Button
                                    className="route-planning__btn scootix-btn-radius"
                                    disabled={formLoading}
                                    onClick={handleFormReset}
                                >
                                    <StartOverIcon />
                                    &nbsp;Start Over
                                </Button>
                            ) : (
                                <Button
                                    type="submit"
                                    className="route-planning__btn scootix-btn-radius"
                                    disabled={formLoading}
                                >
                                    {formLoading ? (
                                        <Spinner size="sm" color="warning" className="spinner-white mx-1" />
                                    ) : (
                                        <SearchIcon />
                                    )}
                                    &nbsp;Generate Deliveries Grid
                                </Button>
                            )}
                        </Col>
                    </Row>
                </AvForm>
                {/* Warning modal */}
                <WarningModal
                    show={showWarningModal}
                    toggleModal={toggleWarningModal}
                    title="Edit Filters"
                    text="You have made your selections in the Deliveries list. Altering the filters will not affect these selections, and the deliveries list may still contain deliveries that do not match the updated criteria."
                />
                {/* start over confirmation modal */}
                <ConfirmationModal
                    show={showConfirmationModal}
                    toggleModal={toggleStartOverConfirmationModal}
                    onConfirm={resetForm}
                    isStartOver
                    title="Do you want to regenerate the Delivery Grid?"
                    text="Regenerating the delivery grid will result in the loss of the current results of the grid. Do you wish to continue?"
                />
                {/* Scootix Modal */}
                <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
                {/* Route Planning modal */}
                {showModal && (
                    <RoutePlanningModal
                        toggleModal={toggleModal}
                        setShowModal={setShowModal}
                        type={type}
                        show={showModal}
                        formData={formData}
                        setFormData={setFormData}
                        handleSelectChange={handleSelectChange}
                        handleCheckChange={handleCheckChange}
                        setSelectedDeliveryTypes={setSelectedDeliveryTypes}
                        selectedDeliveryTypes={selectedDeliveryTypes}
                        allMerchantIds={allMerchantIds}
                        selectedMerchants={selectedMerchants}
                        setSelectedMerchants={setSelectedMerchants}
                        allUnPlannedDeliveryList={allUnPlannedDeliveryList}
                        selectedUnPlannedDeliveryList={selectedUnPlannedDeliveryList}
                        setSelectedUnPlannedDeliveryList={setSelectedUnPlannedDeliveryList}
                    />
                )}
            </Container>
        </>
    );
}

RoutePlanningFormBody.propTypes = RoutePlanningFormBodyPropTypes;
export default RoutePlanningFormBody;
