import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

function FullScreen(props) {
    const { handleFullScreen, isFullScreen, setIsFullScreen } = props;
    return (
        <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="top-row btn"
            onClick={() => {
                if (isFullScreen) {
                    handleFullScreen.exit();
                } else {
                    handleFullScreen.enter();
                }
                setIsFullScreen(!isFullScreen);
            }}
            style={{ cursor: 'pointer' }}
        >
            {isFullScreen && <i className="fas fa-compress-arrows-alt fa-2x" />}
            {!isFullScreen && <i className="fas fa-expand-arrows-alt fa-2x" />}
        </motion.div>
    );
}

FullScreen.propTypes = {
    handleFullScreen: PropTypes.object,
    isFullScreen: PropTypes.bool,
    setIsFullScreen: PropTypes.func,
};

export default FullScreen;
