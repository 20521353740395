import React from 'react';
import { CustomToast } from 'components';
import toast from 'react-hot-toast';

// #region constant
import { VEHICLE_TYPES } from 'constants/constants';

/**  if there is a merchant in the list who has isLimitDeliveriesToMerchantRider true, we cant add any other merchant to the list and vise versa
 */
export const selectMerchantsHelper = (event, selectedMerchants, setSelectedMerchants) => {
    /**  check existing selected list has a true isLimitDeliveriesToMerchantRider  */
    const hasMerchantLimitRiders = selectedMerchants.some((obj) => obj.isLimitDeliveriesToMerchantRider === true);

    /**  check current selected merchant has a true isLimitDeliveriesToMerchantRider  */
    const isMerchantLimitRiders = event[event.length - 1].isLimitDeliveriesToMerchantRider;

    if (!hasMerchantLimitRiders && isMerchantLimitRiders && event.length === 1) {
        setSelectedMerchants(event);
    } else if (hasMerchantLimitRiders) {
        toast.custom(
            (t) => (
                <CustomToast
                    text="You have already selected a merchant with 'Limit Deliveries to Merchant Riders' option"
                    t={t}
                    type="warning"
                />
            ),
            {
                position: 'top-right',
            }
        );
    } else if (!hasMerchantLimitRiders && !isMerchantLimitRiders) {
        setSelectedMerchants(event);
    } else if (!hasMerchantLimitRiders && isMerchantLimitRiders && event.length !== 1) {
        toast.custom(
            (t) => (
                <CustomToast
                    text="You have selected a merchant with 'Limit Deliveries to Merchant Riders' option. Please deselect the other merchants and add this merchant again to continue"
                    t={t}
                    type="warning"
                />
            ),
            {
                position: 'top-right',
            }
        );
    }
};

/**  building query data for get unplanned deliveries */
export const buildDeliverySearchData = (
    formData,
    selectedDeliveryTypes,
    selectedMerchants,
    selectedMerchantLocations
) => {
    /** filters using to get all unplanned deliveries */
    const {
        vehicleType = VEHICLE_TYPES.MOTOR_BIKE,
        isPickupReadyOnly,
        pickupCity,
        deliveryCity,
        deliveryDate,
        deliveryReadyTime,
    } = formData;
    /** list of delivery types */
    const deliveryTypes = selectedDeliveryTypes?.map((obj) => obj.value);
    /** list of merchant ids */
    const merchants = selectedMerchants?.map((obj) => obj.value);
    /** list of merchant locations */
    const merchantLocations = selectedMerchantLocations?.map((obj) => obj.value);

    const buildData = {
        vehicleType,
        isPickupReadyOnly,
        pickupCity,
        deliveryCity,
        deliveryDate,
        deliveryReadyTime,
        deliveryTypes,
        merchants,
        merchantLocations,
    };

    return buildData;
};

/** Validate the relevant properties wether are there before going to submit the form  */
export const validateRoutePlanningForm = (
    formData,
    selectedUnPlannedDeliveryList,
    selectedMerchants,
    allUnPlannedDeliveryList
) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { isDisplayDeliveryLocations, isDisplayPickupLocations, isSelectAllUnplannedDeliveries } = formData;

    if (!isDisplayDeliveryLocations && !isDisplayPickupLocations) {
        return {
            isFormValid,
            message:
                "You must select either one or both options from 'Display Pickup Locations' and 'Display Delivery Locations' to generate the map",
        };
    }
    if (selectedMerchants.length === 0) {
        return {
            isFormValid,
            message: 'Please Select at least a merchant before proceeded further',
        };
    }
    if (selectedUnPlannedDeliveryList.length === 0 && !isSelectAllUnplannedDeliveries) {
        return {
            isFormValid,
            message: 'Please Select at at least a delivery to continue',
        };
    }
    if (allUnPlannedDeliveryList.length === 0) {
        return {
            isFormValid,
            message: 'No Deliveries to Generate the Map',
        };
    }
    isFormValid = true;
    return { isFormValid, message };
};
