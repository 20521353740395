import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

import { GET_RIDERS_BY_FILTERS } from 'utils/apiEndpoints';

export const getRiderDataByFilters = ({
    page = 1,
    limit = 1,
    sort = 'createdAt',
    order = -1,
    isActive,
    isLimitedToMerchant,
    merchantId,
    isOnline,
    isOffline,
    search,
    vehicleType,
    all = 0,
    ignoreIsLimited = 0,
}) => {
    const buildQuery = {
        page,
        limit,
        sort: sort || 'createdAt',
        order,
        all,
    };

    if (search) {
        buildQuery.searchBy = ['fullName', 'mobileNo', 'referenceNumber'];
        buildQuery.searchText = search;
    }

    if (isActive) buildQuery.isActive = isActive;
    if (isOnline) buildQuery.isOnline = isOnline;
    if (isOffline) buildQuery.isOffline = isOffline;
    if (isLimitedToMerchant) buildQuery.isLimitedToMerchant = isLimitedToMerchant;
    if (merchantId) buildQuery.merchantId = merchantId;
    if (vehicleType) buildQuery.vehicleType = vehicleType;
    if (ignoreIsLimited) buildQuery.ignoreIsLimited = ignoreIsLimited;

    const url = UrlBuilder().setUrl(GET_RIDERS_BY_FILTERS).setQueryParams(buildQuery).build();
    return instance.get(url);
};
