import PATHS from 'routes/paths';

export const breadcrumbItemsArchivePrints = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Label Printing', link: '#' },
    { title: 'Archive Prints', link: '#' },
];

export const defaultTableHeaders = [
    {
        key: 'sequenceId',
        value: 'Print Id',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
    },
    {
        key: 'name',
        value: 'Print Name',
    },
    {
        key: 'createdAt',
        value: 'Created Date and Time',
        type: 'dateTime',
    },
    {
        key: 'createdByUserFullName',
        value: 'Created By',
    },
    {
        key: 'status',
        value: 'Current Status',
    },
];

export const filters = [
    {
        label: 'Print Id',
        key: 'sequenceId',
    },
    {
        label: 'Merchant',
        key: 'merchantName',
    },
    {
        label: 'Print Name',
        key: 'name',
    },
];
