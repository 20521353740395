import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

// #endregion modules
import DeliveryForm from './modules/DeliveryForm';
import BatchPrintResourceGenerated from './modules/BatchPrintResourceGenerated';

function DocumentUpdatedModal(props) {
    const { toggleModal, show = true, ref, type, title = '', body, size = 'md', data } = props;

    const generateBody = () => {
        if (body) {
            return <>{body}</>;
        }
        if (type === 'deliveryForm') {
            return <DeliveryForm data={data} toggleModal={toggleModal} />;
        }
        if (type === 'batchPrintResourceGenerated') {
            return <BatchPrintResourceGenerated data={data} toggleModal={toggleModal} />;
        }
        return <></>;
    };

    return (
        <>
            <Modal
                size={size}
                isOpen={show}
                // isOpen={show}
                // toggle={toggleModal}
                center
                className="modal-dialog modal-dialog-centered"
                ref={ref}
            >
                {title && <ModalHeader toggle={toggleModal}>{title}</ModalHeader>}
                <ModalBody className="p-0">{generateBody()}</ModalBody>
            </Modal>
        </>
    );
}

DocumentUpdatedModal.propTypes = {
    toggleModal: PropTypes.func,
    show: PropTypes.bool,
    ref: PropTypes.any,
    type: PropTypes.any,
    title: PropTypes.any,
    body: PropTypes.any,
    size: PropTypes.any,
    data: PropTypes.any,
};

export default DocumentUpdatedModal;
