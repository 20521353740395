/* eslint-disable indent */
import {
    GET_SHIPPING_LABELS,
    GET_ONE_SHIPPING_LABEL,
    GET_ALL_SHIPPING_LABELS,
    GET_ALL_SHIPPING_LABELS_BY_DELIVERY_ID,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Shipping Labels Service
 * @returns {object}
 */
export const getShippingLabelsService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_SHIPPING_LABELS}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${populate ? `&${populate}&` : ''}${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Shipping Label By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getShippingLabelByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ONE_SHIPPING_LABEL).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Shipping Label
 * @returns {Promise}
 */
export const getAllShippingLabelService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_SHIPPING_LABELS).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Shipping Label By Delivery Id
 * @returns {Promise}
 */
export const getAllShippingLabelByDeliveryIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_SHIPPING_LABELS_BY_DELIVERY_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
