import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, DropdownItem, Row, FormGroup, Label, Input } from 'reactstrap';
import { motion } from 'framer-motion';

import { reactSelectCustomStyles } from 'utils/helpers';
import { InfoModal, Select } from 'components';
import { DELIVERY_IMPORT_TYPES } from 'constants/db/merchant-setting';
import SelectDeliveryImportModel from '../components/Select-Delivery-Import-Model';

function DeliveryImportSettingsSubModule(props) {
    const { handleSelectChange, settings, handleCheckChange } = props;

    const [infoModalData, setInfoModalData] = useState({
        title: 'Import Type / Model',
        body: '',
        show: false,
        type: 'merchantSettingImportType',
        size: 'md',
    });

    const [showModelSelect, setShowModelSelect] = useState(false);

    const handleOpenInfo = (type) => {
        if (type === 'importType') {
            showInfoModal('Import Type / Model', null, 'merchantSettingImportType');
        }
    };

    const showInfoModal = (title, body, type) => {
        setInfoModalData({ ...infoModalData, show: true, title, body, type });
    };

    const toggleInfoModal = () => setInfoModalData({ ...infoModalData, show: false });

    const handleReturnDataModel = (data) => {
        if (data && data.value) {
            const relevantModel = DELIVERY_IMPORT_TYPES.find((x) => x.value === data.value);
            if (relevantModel) {
                handleSelectChange(relevantModel, 'settings.deliveryImport.type');
            }
            setShowModelSelect(false);
        }
    };

    // Selects Memos Handlers
    const getImportTypeSelect = (value) => {
        if (value) {
            const relevantObj = DELIVERY_IMPORT_TYPES.find((x) => x.value === value);
            if (relevantObj) {
                return { label: relevantObj.label, value: relevantObj._id };
            }
        }
        return null;
    };

    // ** Memos

    const selectedImportModelType = useMemo(
        () => getImportTypeSelect(settings.deliveryImport.type),
        [settings.deliveryImport.type]
    );

    return (
        <>
            <div>
                <h3 style={{ fontWeight: 'bolder' }} className="">
                    Delivery Import Settings
                </h3>
                <span color="#5d5d5a">Manage Merchant Delivery Import Settings Here</span>
            </div>
            <DropdownItem divider className="mt-4" />
            <br />

            <Row>
                <Col md={4}>
                    <h5 style={{ color: '#3C405B' }} className="">
                        Excel / CSV Imports
                    </h5>
                    <div style={{ marginTop: 20 }}>
                        <span color="#5d5d5a">Import Type / Model</span>
                    </div>
                </Col>
                <Col md={6}>
                    <Row className="pl-3">
                        <div className="custom-control custom-switch" dir="ltr">
                            <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="riderSelfAssignmentIsEnabled"
                                onChange={() =>
                                    handleCheckChange(
                                        !settings.deliveryImport.isEnabled,
                                        'settings.deliveryImport.isEnabled'
                                    )
                                }
                                checked={settings.deliveryImport.isEnabled}
                            />
                            <Label className="custom-control-label" htmlFor="riderSelfAssignmentIsEnabled">
                                {settings.deliveryImport.isEnabled ? 'Disable' : 'Enable'} Function
                            </Label>
                        </div>
                    </Row>
                    <br />
                    <Row style={{ alignItems: 'center' }}>
                        <Col md="8">
                            <Row>
                                <Col md="10" sm="10" xs="10">
                                    <FormGroup>
                                        <Row className="justify-content-between pl-2 pr-2">
                                            <Label htmlFor="pickupLocationType" className="ml-1">
                                                Type
                                            </Label>
                                            <i
                                                className="fa fa-info-circle pointer"
                                                aria-hidden="true"
                                                onClick={() => handleOpenInfo('importType')}
                                            />
                                        </Row>
                                        <Select
                                            placeholder="Model Type"
                                            value={selectedImportModelType}
                                            options={DELIVERY_IMPORT_TYPES}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) =>
                                                handleSelectChange(event, 'settings.deliveryImport.type')
                                            }
                                            isDisabled
                                            required={settings.deliveryImport.isEnabled}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                {settings.deliveryImport.isEnabled && (
                                    <Col md="2" sm="2" xs="2">
                                        <motion.div
                                            style={{ width: 35, height: 35, paddingRight: 27 }}
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-btn-circle shadow-lg nw-md ml-3 delivery-import-setting-model-search"
                                            onClick={() => setShowModelSelect(!showModelSelect)}
                                        >
                                            <div className="delivery-import-setting-model-search-icon">
                                                <i className="fas fa-search fa-lg" style={{ marginRight: 5 }} />
                                            </div>
                                        </motion.div>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <Row>
                <Col md={4} className="mt-3">
                    <span color="#5d5d5a">Locations for Return Order</span>
                </Col>
                <Col md={6}>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="pickupLocationType" className="ml-1">
                                    Pick Up Location
                                </Label>
                                <Select
                                    value={selectedReturnOrderPickupLocationType}
                                    options={deliveryImportLocationTypes.map((val) => ({
                                        label: val.value,
                                        value: val.key,
                                    }))}
                                    styles={reactSelectCustomStyles}
                                    onChange={(event) =>
                                        handleSelectChange(
                                            event,
                                            'settings.deliveryImport.returnOrder.pickupLocationType'
                                        )
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="deliveryAddressType" className="ml-1">
                                    Delivery Address Location
                                </Label>
                                <Select
                                    value={selectedReturnOrderDeliveryAddressType}
                                    options={deliveryImportLocationTypes.map((val) => ({
                                        label: val.value,
                                        value: val.key,
                                    }))}
                                    styles={reactSelectCustomStyles}
                                    onChange={(event) =>
                                        handleSelectChange(
                                            event,
                                            'settings.deliveryImport.returnOrder.deliveryAddressType'
                                        )
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row> */}
            <br />
            <DropdownItem divider className="mt-4" />
            <br />

            <InfoModal toggleModal={toggleInfoModal} {...infoModalData} />

            {showModelSelect && (
                <SelectDeliveryImportModel
                    handleReturnData={handleReturnDataModel}
                    toggleModal={() => setShowModelSelect(!showModelSelect)}
                />
            )}
        </>
    );
}

DeliveryImportSettingsSubModule.propTypes = {
    settings: PropTypes.object,
    handleInputChange: PropTypes.func,
    handleSelectChange: PropTypes.func,
    handleCheckChange: PropTypes.func,
    location: PropTypes.any,
};

export default DeliveryImportSettingsSubModule;
