/* eslint-disable camelcase */
import {
    CREATE_MERCHANT_lOCATION,
    DELETE_MERCHANT_lOCATION,
    UPDATE_MERCHANT_lOCATION,
    DELETE_MERCHANT_lOCATION_PERMANENT,
    RESTORE_MERCHANT_lOCATION,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const createMerchantLocationService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_MERCHANT_lOCATION).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const updateMerchantLocationService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_MERCHANT_lOCATION).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const deleteMerchantLocationPermanentService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_MERCHANT_lOCATION_PERMANENT).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Restore Merchant Location Service
 * @returns {Promise}
 */
export const restoreMerchantLocationService = (id) => {
    try {
        const url = UrlBuilder().setUrl(RESTORE_MERCHANT_lOCATION).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const deleteMerchantLocationService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_MERCHANT_lOCATION).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
