/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col, Alert, Button, Container, FormGroup, Label, Spinner } from 'reactstrap';
import toast from 'react-hot-toast';
import Lottie from 'react-lottie';

// Redux
import { connect } from 'react-redux';
import Hoc from 'components/Hoc';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { withRouter, Link } from 'react-router-dom';

import { removeStorageItems } from 'utils/checkAuth';

// #region components | assets
import ScootixLogoLottie from 'assets/animations/scootix-logo-animated.json';

// #region imports
import { checkLogin, apiError } from 'store/actions';
import CONFIG from 'app-config';

// action
import { forgetUser } from '../../../store/actions';

// import images
import { temporaryPasswordResetService } from './services';

import PackageJson from '../../../../package.json';

function TemporaryPasswordResetPage(props) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordDoNotMatch, setPasswordDoNotMatch] = useState(null);
    const [loading, setLoading] = useState(false);

    const [userId, setUserId] = useState(null);

    useEffect(() => {
        removeStorageItems();
        /** Gets the current page path(/temporary-password-reset/<userId>)
         *  extract the userId from the path
         */
        const currentPagePathname = props.location.pathname;

        if (currentPagePathname) {
            let formatUserId = currentPagePathname.replace('temporary-password-reset', '');
            formatUserId = formatUserId.replace(/\/+/g, '');
            setUserId(formatUserId);
        }
    }, []);

    const lottieOptions = (lottieName) => ({
        loop: false,
        autoplay: true,
        animationData: lottieName || '',
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    });

    // handleValidSubmit
    const handleSubmit = async (event, values) => {
        event.preventDefault();
        // eslint-disable-next-line no-shadow
        const { password, confirmPassword } = values;

        if (password !== confirmPassword) {
            setPasswordDoNotMatch('Password do not match');
            toast.error('Password do not match');
            return;
        }
        setPasswordDoNotMatch(null);
        setLoading(true);
        try {
            const res = await temporaryPasswordResetService({ password, confirmPassword }, userId);
            setPassword('');
            setConfirmPassword('');
            const { status, userMessage, username } = res.data;
            const payload = { username, password };

            if (status === 409) {
                toast.error(userMessage);
            } else {
                setTimeout(() => {
                    props.checkLogin(payload, props.history);
                }, 500);
                toast.success('Password reset successfully.');
            }
            // directs to login if userNotApproved
            setTimeout(() => {
                window.location.href = '/';
            }, 500);
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };

    return (
        <Hoc {...props}>
            <div className="home-btn d-none d-sm-block">
                <Link to="/">
                    <i className="mdi mdi-home-variant h2 text-white"></i>
                </Link>
            </div>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <Lottie
                                    className="page-center-lottie"
                                    options={lottieOptions(ScootixLogoLottie)}
                                    height={90}
                                    width={230}
                                    speed={1}
                                />
                                <h3 className="font-size-18 mt-1">Delivery Management Console</h3>
                                <>
                                    {PackageJson ? (
                                        <h4 className="font-size-18" style={{ marginTop: -1 }}>
                                            {PackageJson.version || ''} - {CONFIG.subVersion}
                                        </h4>
                                    ) : (
                                        ''
                                    )}
                                </>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="auth-body w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        {/* <Link to="/" className="logo">
                                                            <img src={logodark} height="20" alt="logo" />
                                                        </Link> */}
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">
                                                        Update your temporary password
                                                    </h4>
                                                    <p className="text-muted">
                                                        Please update your temporary password with a new password to
                                                        login to your ScootiX account. This is a mandatory step to login
                                                        to ScootiX.
                                                    </p>
                                                </div>

                                                <div className="p-2 mt-5">
                                                    {passwordDoNotMatch && passwordDoNotMatch ? (
                                                        <Alert color="danger" className="mb-4">
                                                            {passwordDoNotMatch}
                                                        </Alert>
                                                    ) : null}
                                                    {props.message ? (
                                                        <Alert color="success" className="mb-4">
                                                            {props.message}
                                                        </Alert>
                                                    ) : null}
                                                    <AvForm
                                                        id="forget-pwd-form"
                                                        className="form-horizontal"
                                                        onValidSubmit={handleSubmit}
                                                    >
                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-mail-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="password">Password</Label>
                                                            <AvField
                                                                name="password"
                                                                value={password}
                                                                type="password"
                                                                validate={{ required: true }}
                                                                className="form-control"
                                                                id="password"
                                                                placeholder="Enter password"
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-mail-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="confirmPassword">Confirm Password</Label>
                                                            <AvField
                                                                name="confirmPassword"
                                                                value={confirmPassword}
                                                                type="password"
                                                                validate={{ required: true }}
                                                                className="form-control"
                                                                id="confirmPassword"
                                                                placeholder="Re enter password"
                                                            />
                                                        </FormGroup>

                                                        <div className="mt-4 text-center">
                                                            <Button
                                                                color="primary"
                                                                className="auth-login-btn nw-md"
                                                                type="submit"
                                                            >
                                                                {loading ? (
                                                                    <Spinner
                                                                        className="mr-2"
                                                                        color="secondary"
                                                                        size="sm"
                                                                    />
                                                                ) : (
                                                                    'Reset'
                                                                )}
                                                            </Button>
                                                        </div>
                                                    </AvForm>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p>
                                                        Don't have an account ?{' '}
                                                        <Link to="/login" className="font-weight-medium text-primary">
                                                            {' '}
                                                            Log in{' '}
                                                        </Link>{' '}
                                                    </p>
                                                    <p>© 2023 ScootiX. Developed By TASA Global Solutions Sdn Bhd</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Hoc>
    );
}

const mapStateToProps = (state) => {
    const { message, forgetError, loading } = state.Forget;
    return { message, forgetError, loading };
};

export default withRouter(connect(mapStateToProps, { checkLogin, apiError, forgetUser })(TemporaryPasswordResetPage));
