import * as React from 'react';

function PickUp(props) {
    const { width, height, color } = props;
    return (
        <svg
            width={width || 30}
            height={height || 30}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M19 9l-2 9c-.093.573-.345 1.087-.71 1.453-.366.365-.822.559-1.29.547H9c-.468.012-.924-.182-1.29-.547-.365-.366-.617-.88-.71-1.453L5 9h14zM7 9a5 5 0 1110 0"
                stroke={color || '#fff'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default PickUp;
