/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { motion } from 'framer-motion';
import moment from 'moment';

// #region imports
import { SubTitle } from 'styles';
import { isObject } from 'utils/helpers';
import { INVENTORY_LOG_TYPES } from 'constants/enums';
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import { defaultTableHeaders } from './constants';

function InventoryTransactionLogComponent(props) {
    const { reportData } = props;

    const { ExportCSVButton } = CSVExport;

    const [allRows, setAllRows] = useState([]);

    useEffect(() => {
        if (reportData && reportData.docs && Array.isArray(reportData.docs)) {
            const mappedReportData = [];
            reportData.docs.map((val) => {
                const log = val;

                const inventoryLog = {
                    title: '',
                    merchantName: '',
                    unitOfMeasure: '',
                    reference: '',
                    additionalReferences: '',
                    transactionDateTime: '',
                    user: '',
                    in: 0,
                    out: 0,
                    balanceQuantity: 0,
                };

                inventoryLog.title = log.title;

                // Build Reference Field Data
                if (log.type === INVENTORY_LOG_TYPES.PICKING_COMPLETED) {
                    if (log.orderId && isObject(log.orderId)) {
                        inventoryLog.reference = log.orderId.referenceNumber || '';
                    }
                }
                if (log.type === INVENTORY_LOG_TYPES.STOCKS_RECEIVED) {
                    if (log.stocksReceivingId && isObject(log.stocksReceivingId)) {
                        inventoryLog.reference = log.stocksReceivingId.referenceNumber || '';
                    }
                }
                if (log.type === INVENTORY_LOG_TYPES.STOCKS_ADJUSTMENT) {
                    if (log.stocksAdjustmentId && isObject(log.stocksAdjustmentId)) {
                        inventoryLog.reference = log.stocksAdjustmentId.referenceNumber || '';
                    }
                }

                // Build Additional References Field Data
                if (log.type === INVENTORY_LOG_TYPES.PICKING_COMPLETED) {
                    if (log.orderId && isObject(log.orderId)) {
                        inventoryLog.additionalReferences = log.orderId.merchantOrderNo || '';
                    }
                }
                if (log.type === INVENTORY_LOG_TYPES.STOCKS_RECEIVED) {
                    if (log.stocksReceivingId && isObject(log.stocksReceivingId)) {
                        inventoryLog.additionalReferences = log.stocksReceivingId.deliveryNoteReference || '';
                    }
                }
                if (log.type === INVENTORY_LOG_TYPES.STOCKS_ADJUSTMENT) {
                    if (log.stocksAdjustmentId && isObject(log.stocksAdjustmentId)) {
                        inventoryLog.additionalReferences = log.stocksAdjustmentId.note || '';
                    }
                }

                // Transaction Date And Time
                if (log.type === INVENTORY_LOG_TYPES.PICKING_COMPLETED) {
                    if (log.orderItemId && isObject(log.orderItemId)) {
                        inventoryLog.transactionDateTime = log.orderItemId.pickingCompletedAt
                            ? moment(log.orderItemId.pickingCompletedAt).format('YYYY-MM-DD - hh:mm:a')
                            : '';
                    }
                }
                if (log.type === INVENTORY_LOG_TYPES.STOCKS_RECEIVED) {
                    if (log.stocksReceivingId && isObject(log.stocksReceivingId)) {
                        inventoryLog.transactionDateTime = log.stocksReceivingId.confirmedAt
                            ? moment(log.stocksReceivingId.confirmedAt).format('YYYY-MM-DD - hh:mm:a')
                            : '';
                    }
                }
                if (log.type === INVENTORY_LOG_TYPES.STOCKS_ADJUSTMENT) {
                    if (log.stocksAdjustmentId && isObject(log.stocksAdjustmentId)) {
                        inventoryLog.transactionDateTime = log.stocksAdjustmentId.confirmedAt
                            ? moment(log.stocksReceivingId.confirmedAt).format('YYYY-MM-DD - hh:mm:a')
                            : '';
                    }
                }

                // User
                if (log.type === INVENTORY_LOG_TYPES.PICKING_COMPLETED) {
                    if (log.orderItemId && isObject(log.orderItemId) && isObject(log.orderItemId.pickedBy)) {
                        inventoryLog.user = log.orderItemId.pickedBy
                            ? `${log.orderItemId.pickedBy.firstName || ''} ${log.orderItemId.pickedBy.lastName || ''}`
                            : '';
                    }
                }
                if (log.type === INVENTORY_LOG_TYPES.STOCKS_RECEIVED) {
                    if (
                        log.stocksReceivingId &&
                        isObject(log.stocksReceivingId) &&
                        isObject(log.stocksReceivingId.confirmedBy)
                    ) {
                        inventoryLog.user = log.stocksReceivingId.confirmedBy
                            ? `${log.stocksReceivingId.confirmedBy.firstName || ''} ${
                                  log.stocksReceivingId.confirmedBy.lastName || ''
                              }`
                            : '';
                    }
                }
                if (log.type === INVENTORY_LOG_TYPES.STOCKS_ADJUSTMENT) {
                    if (
                        log.stocksAdjustmentId &&
                        isObject(log.stocksAdjustmentId) &&
                        isObject(log.stocksAdjustmentId.confirmedBy)
                    ) {
                        inventoryLog.user = log.stocksAdjustmentId.confirmedBy
                            ? `${log.stocksAdjustmentId.confirmedBy.firstName || ''} ${
                                  log.stocksAdjustmentId.confirmedBy.lastName || ''
                              }`
                            : '';
                    }
                }

                // IN Field
                if (
                    log.type === INVENTORY_LOG_TYPES.STOCKS_ADJUSTMENT ||
                    log.type === INVENTORY_LOG_TYPES.STOCKS_RECEIVED
                ) {
                    if (log.type === INVENTORY_LOG_TYPES.STOCKS_ADJUSTMENT && log.methodOfAdjustment === 'Addition') {
                        inventoryLog.in = log.meta.adjustedQuantity || '';
                    }
                    if (log.type === INVENTORY_LOG_TYPES.STOCKS_RECEIVED) {
                        inventoryLog.in = log.meta.adjustedQuantity || '';
                    }
                }

                // OUT Field
                if (
                    log.type === INVENTORY_LOG_TYPES.STOCKS_ADJUSTMENT ||
                    log.type === INVENTORY_LOG_TYPES.PICKING_COMPLETED
                ) {
                    if (log.meta && isObject(log.meta)) {
                        if (
                            log.type === INVENTORY_LOG_TYPES.STOCKS_ADJUSTMENT &&
                            log.methodOfAdjustment === 'Deduction'
                        ) {
                            inventoryLog.out = log.meta.adjustedQuantity || '';
                        }
                        if (log.type === INVENTORY_LOG_TYPES.PICKING_COMPLETED) {
                            inventoryLog.out = log.meta.adjustedQuantity || '';
                        }
                    }
                }

                if (log.productMasterDataId && isObject(log.productMasterDataId)) {
                    inventoryLog.unitOfMeasure = log.productMasterDataId.unitOfMeasure || '';
                }

                if (log.meta && isObject(log.meta)) {
                    inventoryLog.balanceQuantity = log.meta.remainingQuantity || '';
                }

                mappedReportData.push(inventoryLog);

                return val;
            });

            if (reportData.docs.length > 0) {
                const inventoryLogOpeningBalance = {
                    title: 'Opening Balance',
                    merchantName: '-',
                    unitOfMeasure: '-',
                    reference: '-',
                    additionalReferences: '-',
                    transactionDateTime: '-',
                    user: '-',
                    in: '-',
                    out: '-',
                    balanceQuantity: reportData.docs[0].meta ? reportData.docs[0].meta.initialQuantity : 0,
                };
                mappedReportData.unshift(inventoryLogOpeningBalance);
            }

            setAllRows(mappedReportData);
        }
    }, [reportData]);

    return (
        <>
            <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                <CardBody>
                    <div
                        style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'col' }}>
                            <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}>
                                Inventory Transaction Log Report
                            </SubTitle>
                        </div>
                    </div>
                    <hr />
                    <ToolkitProvider
                        keyField="_id"
                        data={allRows}
                        columns={defaultTableHeaders}
                        exportCSV={{
                            fileName: 'Inventory Transaction Log Report.csv',
                            // separator: '|',
                            // ignoreHeader: true,
                            // noAutoBOM: false,
                            // blobType: 'text/csv;charset=ansi',
                        }}
                    >
                        {(tableProps) => (
                            <div>
                                {allRows.length > 0 && (
                                    <Row className="d-flex justify-content-end">
                                        {hasAccess(PERMISSIONS.INVENTORY_REPORT, ['Export']) && (
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 pt-0 pb-0"
                                            >
                                                <ExportCSVButton {...tableProps.csvProps}>
                                                    {' '}
                                                    <span style={{ color: '#FFF' }}> Export</span>
                                                </ExportCSVButton>
                                            </motion.button>
                                        )}
                                    </Row>
                                )}

                                <hr />
                                <BootstrapTable {...tableProps.baseProps} />
                            </div>
                        )}
                    </ToolkitProvider>
                </CardBody>
            </Card>
        </>
    );
}

InventoryTransactionLogComponent.propTypes = {
    reportData: PropTypes.any,
};

export default InventoryTransactionLogComponent;
