import { CREATE_ORDER_ITEMS } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Save OrderItems Batch Service
 * @param {object} data
 * @returns {Promise}
 */
export const saveOrderItemsBatchService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_ORDER_ITEMS).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
