import { EncryptStorage } from 'encrypt-storage';

import { CLIENT_MERCHANT_X_CLIENTS, CLIENT_MERCHANT_X_MERCHANTS } from 'constants/local-storage.constants';
import { __DEV__ } from './checks';

export const ENCRYPTED_HASH = 'k6GAdP*UkDF*%$$Q2r7muFaSv#nUOgysHO@$qSjz1gz&sxtwpbJ0*CQrGxg';

export const encryptedLocalStorage = new EncryptStorage(ENCRYPTED_HASH, {
    prefix: '@web1',
    storageType: 'localStorage',
});

export const storeNotification = (data) => {
    const payload = { ...data, isRead: false };
    let existingNotifications = localStorage.getItem('scootix-notifications');

    // Existing Notifications
    if (existingNotifications) {
        existingNotifications = JSON.parse(existingNotifications);

        // Check if Notification Already Exists
        let checkIfNotificationExists = null;

        if (payload._id) {
            checkIfNotificationExists = existingNotifications.find((x) => x && x._id === data._id);
        }

        // If Notification Does Not Exist Push
        if (!checkIfNotificationExists) {
            existingNotifications.push(payload);
        }

        localStorage.removeItem('scootix-notifications');
        localStorage.setItem('scootix-notifications', JSON.stringify(existingNotifications));
    } else {
        existingNotifications = [];
        existingNotifications.push(payload);
        localStorage.setItem('scootix-notifications', JSON.stringify(existingNotifications));
    }

    // Filters
    existingNotifications = existingNotifications.filter((x) => x && !x.isRead);

    return existingNotifications;
};

export const getNotifications = () => {
    let existingNotifications = localStorage.getItem('scootix-notifications');

    if (existingNotifications) {
        existingNotifications = JSON.parse(existingNotifications);
        existingNotifications = existingNotifications.filter((x) => x && !x.isRead);
    } else {
        existingNotifications = [];
    }

    return existingNotifications;
};

export const readNotification = (_id) => {
    let existingNotifications = localStorage.getItem('scootix-notifications');

    if (existingNotifications) {
        existingNotifications = JSON.parse(existingNotifications);
    } else {
        existingNotifications = [];
    }

    const filteredNotifications = existingNotifications.filter((x) => x && x._id !== _id);
    const relevantNotification = existingNotifications.find((x) => x && x._id === _id);
    if (relevantNotification) {
        relevantNotification.isRead = true;
    }

    localStorage.setItem('scootix-notifications', JSON.stringify([...filteredNotifications, relevantNotification]));
};

export const storeSocketSession = (data) => {
    sessionStorage.setItem('scootix-socket-session', JSON.stringify(data));
};

export const removeSocketSession = () => {
    if (!__DEV__) {
        sessionStorage.removeItem('scootix-socket-session');
        console.log('removed socket session');
    }
};

export const getSocketStorageSession = () => {
    try {
        const data = sessionStorage.getItem('scootix-socket-session');
        if (data) {
            return JSON.parse(data);
        }
        return null;
    } catch (e) {
        return {};
    }
};

export const getClientMerchantXClients = () => {
    try {
        const data = encryptedLocalStorage.getItem(CLIENT_MERCHANT_X_CLIENTS);
        if (data) {
            return data;
        }
        return null;
    } catch (e) {
        return {};
    }
};

export const storeClientMerchantXClients = (data) => {
    encryptedLocalStorage.setItem(CLIENT_MERCHANT_X_CLIENTS, JSON.stringify(data));
};

export const getClientMerchantXMerchants = () => {
    try {
        const data = encryptedLocalStorage.getItem(CLIENT_MERCHANT_X_MERCHANTS);
        if (data) {
            return data;
        }
        return null;
    } catch (e) {
        return {};
    }
};

export const storeClientMerchantXMerchants = (data) => {
    encryptedLocalStorage.setItem(CLIENT_MERCHANT_X_MERCHANTS, JSON.stringify(data));
};
