/* eslint-disable indent */
/* eslint-disable camelcase */
import {
    GET_STOCKS_RECEIVING_ITEM,
    GET_STOCKS_RECEIVING_ITEM_BY_ID,
    GET_ALL_STOCKS_RECEIVING_ITEM,
    GET_STOCKS_RECEIVING_ITEM_DELETED,
    GET_STOCKS_RECEIVING_ITEM_BY_STOCK_RECEIVING_ID,
    GET_ALL_STOCKS_RECEIVING_ITEM_BY_STOCK_RECEIVING_ID,
    UPDATE_STOCKS_RECEIVING_ITEM,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Stocks Receiving Item Service
 * @returns {object}
 */
export const getStocksReceivingItemService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_STOCKS_RECEIVING_ITEM}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Stocks Receiving Item By Receiving Service
 * @returns {object}
 */
export const getStocksReceivingItemByStockReceivingIdService = function api(
    stockReceivingId,
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_STOCKS_RECEIVING_ITEM_BY_STOCK_RECEIVING_ID}?page=${page}&${
            all ? `all=true&` : ''
        }limit=${limit}&${filter ? `filter=${filter}&` : ''}${sort ? `sort=${sort}&` : ''}${
            order ? `order=${order}&` : `order=-1`
        }${populate ? `&populate=${populate}&` : ''}${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).setPathParams({ id: stockReceivingId }).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Stocks Receiving Item Deleted Service
 * @returns {object}
 */
export const getStocksReceivingItemDeletedService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_STOCKS_RECEIVING_ITEM_DELETED}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Stocks Receiving Item By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getStocksReceivingItemByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_STOCKS_RECEIVING_ITEM_BY_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Stocks ReceivingItem
 * @returns {Promise}
 */
export const getAllStocksReceivingItemService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_STOCKS_RECEIVING_ITEM).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Stocks Receiving Item By Stock Receiving Id
 * @returns {Promise}
 */
export const getAllStocksReceivingItemByStockReceivingIdService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_STOCKS_RECEIVING_ITEM_BY_STOCK_RECEIVING_ID).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Stocks Receiving Item Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateStocksReceivingItemService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_STOCKS_RECEIVING_ITEM).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
