import PropTypes from 'prop-types';

/**
 * Map Constants
 * TODO! Change the url with an API key to Grab Maps
 */
export const GOOGLE_MAPS_URL = `https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&apikey=${process.env.REACT_APP_MAPS_API_KEY}`;
export const ATTRIBUTION = '&copy; Google Maps - Scootix'; // text bottom right corner

// PropTypes
export const FormHeaderPropTypes = {};
export const RoutePlanningPagePropTypes = {};
export const RoutePlanningFormPropTypes = {
    mapGridDeliveries: PropTypes.func,
    pickupDeliverySetting: PropTypes.func,
};

export const RoutePlanningMapGridPropTypes = {
    isPickupShowing: PropTypes.bool,
    isDeliveryShowing: PropTypes.bool,
    deliveries: PropTypes.array,
};

export const RoutePlanningFormBodyPropTypes = {
    formData: PropTypes.object,
    selectedDeliveryTypes: PropTypes.array,
    allMerchantIds: PropTypes.array,
    allMerchantLocations: PropTypes.array,
    allUnPlannedDeliveryList: PropTypes.array,
    selectedMerchants: PropTypes.array,
    selectedMerchantLocations: PropTypes.array,
    selectedUnPlannedDeliveryList: PropTypes.array,
    formLoading: PropTypes.bool,
    customLoading: PropTypes.object,
    submitted: PropTypes.bool,
    validated: PropTypes.bool,
    isEditable: PropTypes.bool,
    handleSubmit: PropTypes.func,
    handleSelectChange: PropTypes.func,
    handleCheckChange: PropTypes.func,
    handleDateChange: PropTypes.func,
    selectedVehicleType: PropTypes.object,
    selectedPickupCity: PropTypes.object,
    selectedDeliveryCity: PropTypes.object,
    handleDropdownClick: PropTypes.func,
    handleRemoveAll: PropTypes.func,
    showWarningModal: PropTypes.bool,
    toggleWarningModal: PropTypes.func,
    scootixModalState: PropTypes.object,
    toggleScootixModal: PropTypes.func,
    resetForm: PropTypes.func,
    toggleStartOverConfirmationModal: PropTypes.func,
    showConfirmationModal: PropTypes.bool,
    stateList: PropTypes.array,
    selectedPickupState: PropTypes.array,
    selectedDeliveryState: PropTypes.array,
    pickupCityList: PropTypes.array,
    deliveryCityList: PropTypes.array,
};

export const CustomMarkerPropTypes = {
    mappedOrderArray: PropTypes.array,
    isPickup: PropTypes.bool,
    setSelectedOrders: PropTypes.func,
    setDeselectedOrders: PropTypes.func,
};

export const CustomToolTipPropTypes = {
    mappedOrderArray: PropTypes.array,
    isPickup: PropTypes.bool,
    setSelectedOrders: PropTypes.func,
    setDeselectedOrders: PropTypes.func,
};

export const RiderMarkerPropTypes = {
    rider: PropTypes.object,
    setSelectedRider: PropTypes.func,
    setRidersOnMap: PropTypes.func,
    hasCurrentTask: PropTypes.bool,
    isTooltipOpen: PropTypes.bool,
};

export const RiderToolTipPropTypes = {
    rider: PropTypes.object,
    setSelectedRider: PropTypes.func,
};

export const RoutePlanningListViewPropTypes = {
    formData: PropTypes.object,
    tableFilterOptions: PropTypes.object,
    merchants: PropTypes.array,
    riderListData: PropTypes.array,
    selectedMerchants: PropTypes.array,
    routeListTableData: PropTypes.array,
    riderLoading: PropTypes.bool,
    merchantLoading: PropTypes.bool,
    routingRequestLoading: PropTypes.bool,
    routePlanningListLoading: PropTypes.bool,
    handleSubmit: PropTypes.func,
    handleSelectChange: PropTypes.func,
    handleCheckChange: PropTypes.func,
    handleDateChange: PropTypes.func,
    handleBackPress: PropTypes.func,
    getMerchantSelect: PropTypes.func,
    getRiderSelect: PropTypes.func,
    getRoutePlanningIdSelect: PropTypes.func,
    loadOptions: PropTypes.func,
    navigateToViewRoutePlan: PropTypes.func,
};
