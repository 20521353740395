/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { connect, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import toast from 'react-hot-toast';

// #region components | assets
import NotificationItem from 'components/Notificaiton/item';
import { CustomToast, DocumentUpdatedModal } from 'components';

// #region imports
import { SOCKET_EVENTS } from 'constants/socket';
import { THEME_COLOR_1 } from 'theme';

// #region utils
import {
    getNotifications,
    readNotification,
    removeSocketSession,
    storeNotification,
    storeSocketSession,
} from 'utils/local-storage';
import { getVisitingObject } from 'utils/checkAuth';
import paths from 'routes/paths';

function NotificationDropdown(props) {
    const reduxState = useSelector((state) => state);

    const history = useHistory();
    const location = useLocation();

    const [menuShow, setMenuShow] = useState(false);
    const [socketClient, setSocketClient] = useState(null);
    const [allNotifications, setAllNotifications] = useState([]);

    const [documentUpdatedData, setDocumentUpdatedData] = useState({ show: false, type: null });

    useEffect(() => {
        if (reduxState?.Global) {
            const { socket } = reduxState.Global;
            if (socket) {
                setSocketClient(socket);
            }
        }
    }, [reduxState.Global]);

    useEffect(() => {
        if (socketClient) {
            const { visitingClient, visitingMerchant } = getVisitingObject();
            socketClient.on(SOCKET_EVENTS.CONNECTED, (res) => {
                const { status, session } = res;
                if (session) {
                    storeSocketSession(session);
                }
                if (status && status === 'ok') {
                    socketClient.emit(SOCKET_EVENTS.JOIN_ROOM, {
                        doc: {
                            visitingClientId: visitingClient ? visitingClient._id : null,
                            visitingMerchantId: visitingMerchant ? visitingMerchant._id : null,
                        },
                    });
                }
            });
            socketClient.on('disconnect', () => {
                removeSocketSession();
            });
        }
    }, [socketClient]);

    useEffect(() => {
        if (socketClient) {
            const { visitingClient, visitingMerchant } = getVisitingObject();
            socketClient.on(SOCKET_EVENTS.CLIENT_MERCHANT_USERS_NOTIFY, (res) => {
                if (visitingClient && visitingMerchant) {
                    handleStoreNotification(res);
                }
            });
            socketClient.on(SOCKET_EVENTS.CLIENT_USERS_NOTIFY, (res) => {
                handleStoreNotification(res);
            });
            socketClient.on(SOCKET_EVENTS.USER_NOTIFICATION, (res) => {
                handleStoreNotification(res);
                watchDocumentModel(res);
            });
        }
    }, [socketClient]);

    useEffect(() => {
        const timer1 = setTimeout(() => {
            const storageNotifications = getNotifications();
            if (storageNotifications.length > 5) {
                toast.custom((t) => <CustomToast text="You have Unread Notifications" t={t} type="info" />, {
                    position: 'top-right',
                });
            }
        }, 600 * 1000);
        return () => {
            clearTimeout(timer1);
        };
    }, []);

    useEffect(() => {
        loadNotifications();
    }, []);

    const handleStoreNotification = (res) => {
        if (res && res.doc) {
            const { doc } = res;
            let storageNotifications = storeNotification(doc);
            storageNotifications = storageNotifications.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setAllNotifications(storageNotifications);
        }
    };

    const watchDocumentModel = (res) => {
        if (res.doc && location?.pathname === `/${paths.LABEL_PRINT.SHIPPING_LABEL.BATCH_PRINT_VIEW}`) {
            const { title } = res.doc;
            if (title === 'Shipping Label Batch Print Task Completed Successfully') {
                setDocumentUpdatedData({
                    ...documentUpdatedData,
                    type: 'batchPrintResourceGenerated',
                    show: true,
                    data: res.doc,
                });
            }
        }
    };

    const toggle = () => {
        setMenuShow((prevState) => !prevState);
    };

    const handleReadNotification = (_id) => {
        readNotification(_id);
        loadNotifications(true);
    };

    const loadNotifications = (isRead = false) => {
        let allNotificationsStorage = getNotifications();
        allNotificationsStorage = allNotificationsStorage.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAllNotifications(allNotificationsStorage);

        // if (!isRead && !isUserWarned) {
        //     if (allNotificationsStorage.length > 5) {
        //         toast.custom((t) => <CustomToast text="You have Unread Notifications" t={t} type="info" />, {
        //             position: 'top-right',
        //         });
        //         setIsUserWarned(true);
        //     }
        // }
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={menuShow} toggle={toggle} className="d-inline-block">
                <DropdownToggle
                    tag="button"
                    className="btn header-item noti-icon waves-effect"
                    id="page-header-notifications-dropdown"
                >
                    <div className="row" style={{ width: 35 }}>
                        <i className="ri-notification-3-line"></i>
                        {allNotifications.length > 0 && (
                            <>
                                <div
                                    style={{
                                        backgroundColor: THEME_COLOR_1,
                                        borderRadius: 100,
                                        zIndex: 50,
                                        height: 20,
                                        width: 20,
                                        marginLeft: -10,
                                        marginTop: -3,
                                        color: '#FFF',
                                        fontSize: 11,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {allNotifications.length}
                                </div>
                            </>
                        )}
                    </div>
                </DropdownToggle>
                <DropdownMenu
                    right
                    className="dropdown-menu-lg p-0"
                    aria-labelledby="page-header-notifications-dropdown"
                    style={{ minWidth: 400 }}
                >
                    <div className="p-3">
                        <Row className="align-items-center">
                            <Col>
                                <h6 className="m-0"> {props.t('Notifications')} </h6>
                            </Col>
                            {/* <div className="col-auto">
                                <Link to="#" className="small">
                                    {' '}
                                    {props.t('View All')}
                                </Link>
                            </div> */}
                        </Row>
                    </div>
                    <SimpleBar style={{ maxHeight: '230px' }}>
                        {allNotifications.map((val) => (
                            <NotificationItem
                                key={uuid()}
                                {...val}
                                handleReadNotification={handleReadNotification}
                                history={history}
                            />
                        ))}
                    </SimpleBar>
                    <div className="p-2 border-top">
                        <Link to="/notifications" className="btn btn-sm btn-link font-size-14 btn-block text-center">
                            <i className="mdi mdi-arrow-right-circle mr-1"></i>
                            {props.t(' View More')}
                        </Link>
                    </div>
                </DropdownMenu>
            </Dropdown>

            {documentUpdatedData.show && <DocumentUpdatedModal {...documentUpdatedData} />}
        </React.Fragment>
    );
}

const mapStateToProps = () => ({});

NotificationDropdown.propTypes = {
    t: PropTypes.any,
};

export default connect(mapStateToProps)(withNamespaces()(NotificationDropdown));
