export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Reference',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
    {
        key: 'stocksFrom',
        value: 'Stocks From',
    },
    {
        key: 'deliveryNoteReference',
        value: 'Delivery Note Reference',
    },
    {
        key: 'additionalDetails',
        value: 'Additional Details',
    },
    {
        key: 'warehouseSiteName',
        value: 'Warehouse',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
    {
        key: 'updatedAt',
        value: 'Updated At',
        type: 'dateTime',
    },
];

export const defaultSearchFilters = [
    {
        label: 'Reference',
        key: 'referenceNumber',
    },
    {
        label: 'Stocks From',
        key: 'stocksFrom',
    },
    {
        label: 'Delivery Note Reference',
        key: 'deliveryNoteReference',
    },
    {
        key: 'skuCode',
        label: 'SKU Code',
    },
    {
        key: 'unitOfMeasure',
        label: 'Unit Of Measure',
    },
    {
        key: 'minimumStockLevel',
        label: 'Minimum stock level',
    },
    {
        key: 'warehouseSiteName',
        label: 'Warehouse',
    },
];
