/* eslint-disable indent */
import {
    GET_BIN_LOCATIONS,
    GET_ALL_BIN_LOCATIONS,
    GET_BIN_LOCATIONS_BY_ZONE_ID,
    GET_TRANSACTIONS_BY_BIN_LOCATION_ID,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Bin Locations Service
 * @returns {object}
 */
export const getBinLocationsService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_BIN_LOCATIONS}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Bin Locations Service
 * @returns {object}
 */
export const getBinLocationsByZoneIdService = function api(
    zoneId,
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_BIN_LOCATIONS_BY_ZONE_ID}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).setPathParams({ id: zoneId, zoneId }).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get All Bin Locations
 * @returns {Promise}
 */
export const getAllBinLocationsService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_BIN_LOCATIONS).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * This build the url for getting bin location transactions by bin location id
 * @param {string} id - bin location id
 * @returns url
 */
export const getTransactionsByBinLocationId = (id) => {
    const url = UrlBuilder().setUrl(GET_TRANSACTIONS_BY_BIN_LOCATION_ID).setPathParams({ id }).build();
    return instance.get(url);
};
