/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { clone } from 'lodash';
import { v4 as uuid } from 'uuid';

// #region assets | components
import { TableCustom } from 'components';

// #endregion imports
import { deliveryListInitialValues } from './constants';
import { LOADED_DELIVERIES, UPDATE_SELECTED_DELIVERIES } from '../../actionTypes';

/**
 * List Table Container
 * @returns {React.Component}
 */
function ListTable() {
    const batchDeliveryReducer = useSelector((state) => state?.BatchDeliveryAssignment);

    const dispatch = useDispatch();

    const [deliveryListData, setDeliveryListData] = useState(deliveryListInitialValues);

    useEffect(() => {
        if (batchDeliveryReducer?.loadedDeliveries.docs) {
            setDeliveryListData({ ...deliveryListData, docs: batchDeliveryReducer.loadedDeliveries.docs });
        }
    }, [batchDeliveryReducer?.loadedDeliveries.id]);

    /**
     * Handle Search Filter
     * @param {*} searchBy
     * @param {*} value
     */
    const handleFilter = (searchBy, value) => {
        if (batchDeliveryReducer?.loadedDeliveries && value && value !== '') {
            const { docs } = batchDeliveryReducer?.loadedDeliveries;
            const filteredDocs = docs.filter(
                (obj) => obj.referenceNumber.toLowerCase().indexOf(value.toLowerCase()) !== -1
            );
            setDeliveryListData((prevState) => ({ ...prevState, docs: filteredDocs }));
            dispatch({ type: LOADED_DELIVERIES, payload: { id: uuid(), docs: filteredDocs } });
        } else if (batchDeliveryReducer?.loadedDeliveries) {
            const { docs } = batchDeliveryReducer?.loadedDeliveries;
            setDeliveryListData((prevState) => ({ ...prevState, docs }));
        }
    };

    /**
     * Handle On Select
     * @param {object} record
     */
    const handleOnSelect = (record, checked) => {
        if (!batchDeliveryReducer?.loadedDeliveries || !record || !deliveryListData.docs) {
            return;
        }
        const { docs } = deliveryListData;
        // If Checked then add to selected and remove from table
        if (checked) {
            const deliveryRecords = clone(docs);
            const itemIndex = deliveryRecords.findIndex((x) => x._id === record._id);
            deliveryRecords[itemIndex] = { ...record, isSelected: true };
            setDeliveryListData((prevState) => ({ ...prevState, docs: deliveryRecords }));
            dispatch({
                type: UPDATE_SELECTED_DELIVERIES,
                payload: { id: uuid(), docs: [...(batchDeliveryReducer?.selectedDeliveries?.docs || []), record] },
            });
        }
        // If Unchecked add to table and remove from selected
        else {
            const deliveryRecords = clone(docs);
            const itemIndex = deliveryRecords.findIndex((x) => x._id === record._id);
            deliveryRecords[itemIndex] = { ...record, isSelected: false };
            setDeliveryListData((prevState) => ({ ...prevState, docs: deliveryRecords }));
            const filterOutFromSelected = batchDeliveryReducer?.selectedDeliveries?.docs.filter(
                (x) => x._id !== record._id
            );
            dispatch({ type: UPDATE_SELECTED_DELIVERIES, payload: { id: uuid(), docs: filterOutFromSelected } });
        }
    };

    /**
     * Handle On Select All
     * @param {array} allRecords
     */
    const handleOnSelectAll = (allRecords, checked) => {
        if (batchDeliveryReducer?.loadedDeliveries && allRecords && deliveryListData.docs) {
            const { docs } = deliveryListData;
            setDeliveryListData((prevState) => ({
                ...prevState,
                docs: docs.map((x) => ({ ...x, isSelected: !!checked })),
            }));
            dispatch({
                type: UPDATE_SELECTED_DELIVERIES,
                payload: { id: uuid(), docs },
            });
        }
    };

    return (
        <Row>
            <TableCustom
                tableData={deliveryListData}
                showPagination={false}
                tableHeaderType="DELIVERY_BATCH_ASSIGNMENT_DELIVERIES"
                removeAction
                isSelectable
                removeId={false}
                onFilter={handleFilter}
                loading={batchDeliveryReducer?.isLoading}
                onSelectAll={handleOnSelectAll}
                onSelect={handleOnSelect}
                isFixedHeight
                tableId="batch-delivery-assignment-delivery-table"
                isResponsive={false}
                cardStyleProps={{ marginBottom: 0, borderRadius: 0 }}
                containerId="batch-delivery-assignment-delivery-table"
            />
        </Row>
    );
}

ListTable.propTypes = {};

export default ListTable;
