import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, Col } from 'reactstrap';
import { motion } from 'framer-motion';

// #region imports
import { centerElementContainerStyles, centerElementInnerStyles } from 'styles';
import { TableCustom } from 'components';

function DeleteMerchantModal(props) {
    const {
        toggleModal,
        show = false,
        title = null,
        onConfirm = null,
        onCancel = null,
        confirmMessage = 'Confirm',
        cancelMessage = 'Cancel',
        merchantUsers = [],
    } = props;

    const [formData, setFormData] = useState({ confirmation: null });

    const [merchantsListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: [
            {
                key: 'fullName',
                value: 'Name',
            },
            {
                key: 'username',
                value: 'Username',
            },
        ],
        docs: merchantUsers,
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    return (
        <>
            <div className="custom-scootix-modal">
                {show && (
                    <Modal
                        size="lg"
                        isOpen
                        center
                        toggle={toggleModal}
                        style={{ borderRadius: 15 }}
                        className="modal-dialog modal-dialog-centered scootix-confirmation-modal"
                    >
                        <ModalHeader
                            className="scootix-bg-radiant"
                            style={{
                                marginTop: -1,
                                marginRight: -1,
                                marginLeft: -1,
                                color: '#FFF',
                                borderTopRightRadius: 15,
                                borderTopLeftRadius: 15,
                            }}
                        >
                            <h4
                                style={{
                                    color: '#FFF',
                                }}
                            >
                                {' '}
                                {title || 'Delete Merchant Profile'}{' '}
                            </h4>
                        </ModalHeader>
                        <ModalBody
                            style={{
                                flexDirection: 'column',
                                borderRadius: 15,
                                backgroundColor: '#FAFAFA',
                                ...centerElementContainerStyles,
                            }}
                            className="p-3"
                        >
                            <br />
                            <div>
                                <h5>
                                    <i className="fas fa-exclamation-triangle mr-3" style={{ color: '#ff304f' }} />
                                    Below Users will be disabled once merchant profile is deleted
                                </h5>
                            </div>

                            <div
                                style={{
                                    maxHeight: 300,
                                    overflowY: 'scroll',
                                    width: 600,
                                    minHeight: 50,
                                    // backgroundColor: 'red',
                                    padding: 10,
                                }}
                            >
                                {/* {merchantUsers &&
                                    merchantUsers.map((x, i) => (
                                        <div>
                                            {' '}
                                            {i + 1} {x.fullName || ''} - {x.username || ''} - {x.mobileNo || ''}
                                        </div>
                                    ))} */}

                                <TableCustom
                                    showSearch={false}
                                    tableData={merchantsListData}
                                    showEdit={false}
                                    showDelete={false}
                                    showView={false}
                                    isFilter={false}
                                    showPagination={false}
                                    isSearch={false}
                                    removeAction
                                />
                            </div>
                            <br />
                            <h6>
                                {' '}
                                To confirm deletion, type <i> confirm </i>in the text input field.
                            </h6>
                            <Col md={6}>
                                <input
                                    name="confirmation"
                                    type="text"
                                    className="form-control mt-2"
                                    placeholder="confirm"
                                    id="confirmation"
                                    onChange={handleInputChange}
                                    value={formData.confirmation}
                                />
                            </Col>
                            <br />
                            <div className="row" style={{ ...centerElementContainerStyles }}>
                                <motion.button
                                    className="scootix-bg-radiant ml-2 btn"
                                    whileHover={{ scale: formData.confirmation === 'confirm' ? 1.1 : 1 }}
                                    whileTap={{ scale: formData.confirmation === 'confirm' ? 0.9 : 1 }}
                                    style={{
                                        minWidth: 120,
                                        padding: 17,
                                        paddingRight: 24,
                                        paddingLeft: 24,
                                        borderRadius: 20,
                                        textAlign: 'center',
                                        ...centerElementInnerStyles,
                                    }}
                                    onClick={() => onConfirm && onConfirm()}
                                    disabled={formData.confirmation !== 'confirm'}
                                >
                                    <span className="h6" style={{ color: '#FFF', fontWeight: 'bold', fontSize: 18 }}>
                                        {confirmMessage}
                                    </span>
                                </motion.button>
                                <motion.div
                                    className="btn"
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    style={{
                                        backgroundColor: '#FFF9EA',
                                        minWidth: 120,
                                        padding: 17,
                                        paddingRight: 24,
                                        paddingLeft: 24,
                                        borderRadius: 20,
                                        marginLeft: 10,
                                        border: '1px solid #FDBA24',
                                        textAlign: 'center',
                                        ...centerElementInnerStyles,
                                    }}
                                    onClick={() => {
                                        if (onCancel) {
                                            onCancel();
                                        } else if (toggleModal) {
                                            toggleModal();
                                        }
                                    }}
                                >
                                    <span className="h6" style={{ color: '#FDBA24', fontWeight: 'bold', fontSize: 18 }}>
                                        {cancelMessage}
                                    </span>
                                </motion.div>
                            </div>
                        </ModalBody>
                    </Modal>
                )}
            </div>
        </>
    );
}

DeleteMerchantModal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    onConfirm: PropTypes.func,
    toggleModal: PropTypes.func,
    onCancel: PropTypes.func,
    confirmMessage: PropTypes.string,
    cancelMessage: PropTypes.string,
    merchantUsers: PropTypes.array,
};

export default DeleteMerchantModal;
