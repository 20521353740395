// 13 Events
export const SOCKET_EVENTS = {
    CONNECTED: 'connected',
    USER_NOTIFICATION: 'user_notification',
    JOIN_ROOM: 'join_room',
    CLIENT_USERS_NOTIFY: 'client_users_notify',
    CLIENT_MERCHANT_USERS_NOTIFY: 'client_merchant_users_notify',
    RIDERS_NOTIFY_MESSAGE: 'riders_notify_message',
    DELIVERY_ASSIGNED: 'delivery_assigned',
    RIDER_UPDATE_LOCATION_DUPLE: 'rider_update_location_duple',
    DELIVERY_LOCATION_CHANGE: 'delivery_location_change',
    ACTIVE_SESSION: 'active_session',
    CLIENT_SERVER_LISTEN_RIDERS_LOCATION: 'client_server_listen_riders_location',
    SERVER_CLIENT_RIDER_LOCATION_MAP: 'server_client_riders_location_map',
    CLIENT_SERVER_LISTEN_SINGLE_RIDER_LOCATION: 'client_server_listen_single_rider_location',
    SERVER_CLIENT_SINGLE_RIDER_LOCATION_MAP: 'server_client_single_rider_location_map',
    DOCUMENT_WATCHER_DELIVERY: 'document_watcher_delivery',
    AUTOMATIC_SESSION_LOGOUT: 'automatic_session_logout',
    USER_LOGOUT: {
        SELF_ACCOUNT_DELETION: 'user_logout_self_account_deletion',
    },
};
