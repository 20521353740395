import * as React from 'react';
import PropTypes from 'prop-types';

function LinkedWithRoutePlan(props) {
    const { width, height, color = '#fff' } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 16 16">
            <g
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                clipPath="url(#clip0_6443_376)"
            >
                <path d="M8 4H4a1.333 1.333 0 00-1.333 1.333V12A1.333 1.333 0 004 13.333h6.667A1.333 1.333 0 0012 12V8M7.333 8.667l6-6M10 2.667h3.333V6"></path>
            </g>
            <defs>
                <clipPath id="clip0_6443_376">
                    <path fill={color} d="M0 0H16V16H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

LinkedWithRoutePlan.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};

export default LinkedWithRoutePlan;
