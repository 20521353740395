import React from 'react';
import { Alert } from 'reactstrap';
import { getVisitingObject } from 'utils/checkAuth';

function MerchantAlerts() {
    const { visitingMerchant } = getVisitingObject();

    return (
        <>
            {!visitingMerchant && (
                <Alert color="danger" className="">
                    MERCHANT NOT SELECTED
                </Alert>
            )}
        </>
    );
}

MerchantAlerts.propTypes = {};

export default MerchantAlerts;
