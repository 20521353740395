import React from 'react';
import Avatar from 'react-avatar';

export const defaultTableHeaders = [
    {
        text: 'Merchant Name',
        dataField: 'merchantNamePicture',
        sort: 'asc',
        editable: false,
        formatter(text) {
            const splitText = text.split('||');
            const merchantName = splitText[0];
            const merchantImage = splitText[1] || null;
            return (
                <div
                    className="d-flex row"
                    style={{
                        alignContent: 'center',
                        alignItems: 'center',
                        verticalAlign: 'middle',
                        height: 50,
                        marginLeft: 5,
                    }}
                >
                    <Avatar
                        name={merchantName || ''}
                        src={merchantImage || null}
                        size="25"
                        round="50px"
                        colors={['#1e6262', '#581b98', '#364f6b']}
                    />{' '}
                    <div className="ml-3">{merchantName}</div>
                </div>
            );
        },
        headerStyle: (column, colIndex) => ({
            minWidth: '200px',
            textAlign: 'center',
            backgroundColor: '#303a52',
            color: '#FFF',
        }),
    },
    {
        text: 'Stocks From',
        dataField: 'stocksFrom',
        sort: 'asc',
        width: 100,
        editable: false,
        headerStyle: (column, colIndex) => ({
            minWidth: '150px',
            textAlign: 'center',
            backgroundColor: '#303a52',
            color: '#FFF',
        }),
    },
    {
        text: 'Delivery Note Reference',
        dataField: 'deliveryNoteReference',
        sort: 'asc',
        editable: false,
        headerStyle: (column, colIndex) => ({
            minWidth: '150px',
            textAlign: 'center',
            backgroundColor: '#303a52',
            color: '#FFF',
        }),
    },
    {
        text: 'Additional Details',
        dataField: 'additionalDetails',
        sort: 'asc',
        editable: false,
        headerStyle: (column, colIndex) => ({
            minWidth: '150px',
            textAlign: 'center',
            backgroundColor: '#303a52',
            color: '#FFF',
        }),
    },
    {
        text: 'Warehouse',
        dataField: 'warehouseSiteName',
        sort: 'asc',
        editable: false,
        headerStyle: (column, colIndex) => ({
            minWidth: '180px',
            textAlign: 'center',
            backgroundColor: '#303a52',
            color: '#FFF',
        }),
    },
    {
        text: 'Stocks Received date and time ( Confirmed Date and Time)',
        dataField: 'stocksReceivedDateTime',
        sort: 'asc',
        editable: false,
        // headerStyle: {},
        headerStyle: (column, colIndex) => ({
            minWidth: '180px',
            textAlign: 'center',
            backgroundColor: '#303a52',
            color: '#FFF',
        }),
    },
    {
        text: 'Stocks Receiving Reference',
        dataField: 'stocksReceivingReference',
        sort: 'asc',
        editable: false,
        headerStyle: (column, colIndex) => ({
            minWidth: '150px',
            textAlign: 'center',
            backgroundColor: '#303a52',
            color: '#FFF',
        }),
    },
    {
        text: 'Product Description',
        dataField: 'productDescription',
        sort: 'asc',
        editable: false,
        headerStyle: (column, colIndex) => ({
            minWidth: '150px',
            textAlign: 'center',
            backgroundColor: '#303a52',
            color: '#FFF',
        }),
    },
    {
        text: 'UPC',
        dataField: 'upc',
        sort: 'asc',
        editable: false,
        headerStyle: (column, colIndex) => ({
            minWidth: '100px',
            textAlign: 'center',
            backgroundColor: '#303a52',
            color: '#FFF',
        }),
    },
    {
        text: 'ScootiX Product Code',
        dataField: 'productMasterDataReferenceNumber',
        sort: 'asc',
        editable: false,
        headerStyle: (column, colIndex) => ({
            minWidth: '150px',
            textAlign: 'center',
            backgroundColor: '#303a52',
            color: '#FFF',
        }),
    },
    {
        text: 'SKU Code',
        dataField: 'skuCode',
        sort: 'asc',
        editable: false,
        headerStyle: (column, colIndex) => ({
            minWidth: '100px',
            textAlign: 'center',
            backgroundColor: '#303a52',
            color: '#FFF',
        }),
    },
    {
        text: 'Unit',
        dataField: 'unitOfMeasure',
        sort: 'asc',
        editable: false,
        headerStyle: (column, colIndex) => ({
            minWidth: '100px',
            textAlign: 'center',
            backgroundColor: '#303a52',
            color: '#FFF',
        }),
    },
    {
        text: 'Quantity',
        dataField: 'quantity',
        sort: 'asc',
        editable: false,
        headerStyle: (column, colIndex) => ({
            minWidth: '100px',
            textAlign: 'center',
            backgroundColor: '#303a52',
            color: '#FFF',
        }),
    },
];
