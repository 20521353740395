/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { hot } from 'react-hot-loader/root';
import { Container, Row } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import PropTypes from 'prop-types';
import './styles.css';

// #region assets | components
import { BackdropLoader, ClientAlerts, CustomToast } from 'components';
import Breadcrumbs from 'components/Common/Breadcrumb';

// #region services
import { getAllSystemDataByTypeModuleAndHeadService } from 'services/system-data.service';
import { getPrinterSettingsByTypeModuleUser } from 'services/printer-settings.service';
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';

// #region other imports
import { SYSTEM_DATA_TYPES, BATCH_PRINT } from 'constants/enums';
import paths from 'routes/paths';

// #region utils
import { apiErrorHandlerV2, getDateValue } from 'utils/helpers';
import { getVisitingObject, getUser } from 'utils/checkAuth';
import { __DEV__ } from 'utils/checks';

// #endregion imports
import reducer from './reducer';
import { breadcrumbItemsBatchPrinting } from '../constant';
import { createBatchPrint, getPrintRecordById, updateBatchPrintRecord } from './services';
import { validateBatchPrintData } from './utils';
import Header from '../containers/HeaderContainer/Header';
import InitialForm from './containers/InitialForm/Form';
import DeliveryFilters from './containers/DeliveryFilters';
import SelectDeliveryList from './containers/SelectDeliveryList';
import SelectedDeliveryList from './containers/SelectedDeliveryList';
import FooterBatchPrintContainer from './containers/FooterBatchPrintContainer';
import { UPDATE_BATCH_PRINT_DELIVERY_DATA, UPDATE_SELECTED_DELIVERIES } from './actionTypes';
import { batchPrintFormDeliveryListInitialValues } from './constant';

let initialFormValues = { noOfCopies: 1, isUseDateTime: true, name: getDateValue(new Date(), 'datetime') };
const key = 'BatchPrintForm';
function BatchPrintPage(props) {
    const { BatchPrintForm } = props;

    useInjectReducer({ key, reducer });

    const _isMounted = useRef(true);
    const dispatch = useDispatch();
    const history = useHistory();

    const userData = getUser();
    const { visitingClient, visitingMerchant } = getVisitingObject();

    // Append Merchant Details
    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
        };
    }

    // Getting URL parameters
    const queryParams = new URLSearchParams(window.location.search);
    const deliveryId = queryParams.get('id');

    // Data
    const [allPrinters, setAllPrinters] = useState([]);
    const [merchants, setMerchants] = useState([]);
    const [formData, setFormData] = useState(initialFormValues);

    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [editingMode, setEditingMode] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);

    useEffect(
        () => () => {
            _isMounted.current = false;
            // ! CHANGE
            // if (!__DEV__)
            dispatch({
                type: UPDATE_SELECTED_DELIVERIES,
                payload: [],
            });
        },
        []
    );

    useEffect(() => {
        loadSystemDataPrinters();
        loadPrinterSettings();
        if (deliveryId) {
            loadPrintRecordById(deliveryId);
        }

        // ! TESTING PURPOSE ONLY
        if (__DEV__) {
            // setFormData(mockData);
            // setEditingMode(true);
        }
    }, [deliveryId, formData.isFinalized]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    /**
     * Load Print Records
     *
     */
    const loadPrintRecordById = async (id) => {
        !__DEV__ && setBackdropLoading((prev) => prev + 1);
        if (id) {
            try {
                const { data } = await getPrintRecordById(id);
                if (_isMounted.current && data) {
                    const mergedData = { ...formData, ...data };
                    setFormData((prevState) => ({ ...prevState, ...data }));

                    history.replace({
                        pathname: `/${paths.LABEL_PRINT.SHIPPING_LABEL.BATCH_PRINT_VIEW}`,
                        search: `?id=${data._id}`,
                        state: {
                            batchPrintParentData: mergedData,
                        },
                    });
                    // Append Selected Deliveries to Reducer
                    if (data.isFinalized && data.documentsArray && data.status === 'READY_TO_PRINT') {
                        const isDocumentsPopulated = data.documentsArray.some((x) => x.referenceNumber);
                        isDocumentsPopulated &&
                            dispatch({
                                type: UPDATE_BATCH_PRINT_DELIVERY_DATA,
                                payload: {
                                    ...batchPrintFormDeliveryListInitialValues,
                                    docs: data.documentsArray,
                                    updatedAt: new Date(),
                                },
                            });
                    } else if (!data.isFinalized && data.documentsArray && data.status === 'DRAFT') {
                        const isDocumentsPopulated = data.documentsArray.some((x) => x.referenceNumber);
                        isDocumentsPopulated &&
                            dispatch({
                                type: UPDATE_SELECTED_DELIVERIES,
                                payload: data.documentsArray,
                            });
                    }
                    setEditingMode(true);
                } else {
                    toast.custom((t) => <CustomToast text="Batch Print Details Not Found" t={t} type="error" />, {
                        position: 'top-right',
                    });
                    history.goBack();
                }
            } catch (e) {
                apiErrorHandlerV2(e);
                history.goBack();
            }
        }
        !__DEV__ && setBackdropLoading((prev) => prev - 1);
    };

    const loadSystemDataPrinters = async () => {
        try {
            const { data } = await getAllSystemDataByTypeModuleAndHeadService(
                SYSTEM_DATA_TYPES.PRINTERS,
                SYSTEM_DATA_TYPES.LABEL_PRINT.SHIPPING_LABEL
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                setAllPrinters(data.map((x) => ({ label: x.name, value: x.name })));
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        }
    };

    /**
     * Load Default Printer Settings
     *
     */
    const loadPrinterSettings = async () => {
        setFormLoading(true);
        try {
            const { data } = await getPrinterSettingsByTypeModuleUser(
                BATCH_PRINT.PRINTER_SETTINGS.TYPES,
                BATCH_PRINT.PRINTER_SETTINGS.MODULE,
                userData._id
            );
            if (_isMounted.current && data?.settings) {
                setFormData((prevState) => ({ ...prevState, ...data.settings }));
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        }
        setFormLoading(false);
    };

    const handleSelectChange = (event, id) => {
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (event && Array.isArray(errors) && errors?.length > 0) {
            setValidated(true);
            return;
        }
        event && event.preventDefault();

        const isFormValid = validateBatchPrintData(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text="Fill all fields" t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);

        // API Response Data
        let apiResponseData = null;

        try {
            if (editingMode) {
                const payload = formData; // Update Payload

                // Append Selected Deliveries
                if (BatchPrintForm?.selectedDeliveries) {
                    payload.selectedDeliveryIds = BatchPrintForm.selectedDeliveries;
                }

                const { data } = await updateBatchPrintRecord(payload);
                if (data) {
                    apiResponseData = data;
                    setIsUpdated(true);
                }
            } else {
                const { data } = await createBatchPrint({
                    module: BATCH_PRINT.PRINTER_SETTINGS.MODULE,
                    type: BATCH_PRINT.PRINTER_SETTINGS.TYPES,
                    printer: formData.printer,
                    printSize: formData.printSize,
                    noOfCopies: formData.noOfCopies,
                    name: formData.name,
                    merchantId: formData.merchantId,
                    clientId: formData.clientId,
                });
                if (data) {
                    apiResponseData = data;
                    // Manipulate the Header module with status
                }
            }

            // Update History State
            if (apiResponseData) {
                history.replace({
                    pathname: `/${paths.LABEL_PRINT.SHIPPING_LABEL.BATCH_PRINT_VIEW}`,
                    search: `?id=${apiResponseData._id}`,
                    state: {
                        batchPrintParentData: apiResponseData,
                    },
                });
            }

            setEditingMode(true);
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'Updated' : 'Created'} Batch Print`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
        } catch (e) {
            const { message: error } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={error} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        // If API Response is Available Update State
        if (apiResponseData) {
            setFormData({ ...formData, ...apiResponseData });
        }

        setFormLoading(false);
        setSubmitted(false);
    };

    const formProps = {
        allPrinters,
        formData,
        setFormLoading,
        handleSelectChange,
        merchants,
        visitingMerchant,
        setFormData,
        formLoading,
        submitted,
        validated,
        handleSubmit,
        editingMode,
        setBackdropLoading,
    };

    const selectedItemPrintProps = {
        editingMode,
        formData,
        handleSubmit,
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItemsBatchPrinting} />
                    <Row className="justify-content-end">
                        <Header buttonIconRight={<i className="fas fa-chevron-left" />} />
                    </Row>
                    <Row>
                        <ClientAlerts />
                    </Row>
                    <InitialForm {...formProps} />
                    {editingMode ? (
                        <>
                            {formData.isFinalized && (
                                <>
                                    <br />
                                </>
                            )}
                            {!formData.isFinalized && <DeliveryFilters initialFormData={formData} />}
                            <SelectDeliveryList formData={formData} />
                            {!formData.isFinalized && <SelectedDeliveryList {...selectedItemPrintProps} />}
                            <FooterBatchPrintContainer
                                handleSubmit={handleSubmit}
                                formData={formData}
                                setFormData={setFormData}
                                setFormLoading={setFormLoading}
                                formLoading={formLoading}
                                isUpdated={isUpdated}
                                selectedDeliveries={BatchPrintForm?.selectedDeliveries}
                            />
                        </>
                    ) : null}
                    <BackdropLoader show={backdropLoading} opacity={0.8} />
                </Container>
            </div>
        </>
    );
}

BatchPrintPage.propTypes = {
    BatchPrintForm: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotBatchPrintPage = hot(BatchPrintPage);

export default connect(mapStateToProps, {})(HotBatchPrintPage);
