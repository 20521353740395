/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { AvForm } from 'availity-reactstrap-validation';
import { Col, FormGroup, Label, Row, Spinner } from 'reactstrap';
import toast from 'react-hot-toast';
import moment from 'moment';

// #region assets | components
import { CheckCircle, CrossCircle } from 'components/Icons';
import CustomDateInput from 'components/CustomDateInput/CustomDateInput';
import CustomToast from 'components/Toast/Custom';

// #region helpers
import { apiErrorHandlerV2, getDateValue } from 'utils/helpers';
import { updateDeliveryDateByRoutePlanningEntryId } from 'services/routePlanning/routePlanning.service';

// #endregion imports

/**
 * Update the delivery date modal
 *
 * @param {func} toggleModal - Toggles the modal
 * @returns {JSX.Element} A React component that displays the delivery types modal.
 */
function UpdateDeliveryDateModule(props) {
    const {
        toggleModal,
        deliveryDate,
        routePlanningId,
        fetchRoutePlanningData,
        fetchRelevantDeliveryData,
        loadTransactions,
    } = props;

    // New Delivery date state
    const [newDeliveryDate, setNewDeliveryDate] = useState(null);
    // form
    const [formLoading, setFormLoading] = useState(false);

    /**
     * Handles the "UPDATE THE DELIVERY DATE" button click
     * @returns {void}
     */
    const handleSubmit = async () => {
        // Convert date strings to moment dates
        const newDate = moment(newDeliveryDate);
        const today = moment().format('YYYY-MM-DD');

        setFormLoading(true);

        // whether the update request success
        let isSuccess = false;

        // Checking the new selected is below date or not with the current route planning delivery date
        if (newDate.isAfter(today) || newDate.isSame(today)) {
            try {
                const body = {
                    deliveryDate: newDeliveryDate,
                };

                const response = await updateDeliveryDateByRoutePlanningEntryId(routePlanningId, body);
                //  if the cancellation process is success
                if (response.status === 200) {
                    isSuccess = true;
                    toast.custom(
                        (t) => (
                            <CustomToast
                                text="Successfully Updated Delivery Date of the Route Planning Entry"
                                t={t}
                                type="success"
                            />
                        ),
                        {
                            position: 'top-right',
                        }
                    );
                }
            } catch (e) {
                const { message } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } else if (newDate.isBefore(today)) {
            const errorMessage = 'Delivery date should be the current date or a future date';
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });
        } else {
            const errorMessage = 'Please select a valid delivery date to continue!';
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (isSuccess) {
            toggleModal();
            // fetch updated route planning entry data
            fetchRoutePlanningData(routePlanningId);
            // fetch updated route planning entry delivery data
            fetchRelevantDeliveryData(routePlanningId);
            // fetch updated route planning entry Transactions data
            loadTransactions(routePlanningId);
        }
        setFormLoading(false);
    };

    /** Handle delivery date change */
    const handleDateChange = (event) => {
        const { value } = event.target;
        if (event) {
            setNewDeliveryDate(value);
        } else {
            setNewDeliveryDate(null);
        }
    };

    return (
        <>
            <div className="font-size-14 w-100 p-0">
                <div className="modal__form-table">
                    <AvForm onSubmit={handleSubmit}>
                        <fieldset>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label htmlFor="deliveryDate">Current Delivery Date</Label>
                                        <CustomDateInput
                                            type="date"
                                            className="form-control"
                                            value={getDateValue(deliveryDate, 'date')}
                                            id="deliveryDate"
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label htmlFor="newDeliveryDate">Select a New Delivery Date</Label>
                                        <CustomDateInput
                                            onChange={handleDateChange}
                                            type="date"
                                            className="form-control"
                                            value={getDateValue(newDeliveryDate, 'date')}
                                            id="newDeliveryDate"
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="auto" className="mb-2 mb-xs-0">
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-btn-radius secondary-btn shadow-lg "
                                        style={{ borderRadius: 10 }}
                                        type="submit"
                                        disabled={formLoading}
                                    >
                                        {formLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <CheckCircle color="#fff" />
                                                &nbsp;UPDATE THE DELIVERY DATE
                                            </>
                                        )}
                                    </motion.button>
                                </Col>
                                <Col xs="auto">
                                    <motion.button
                                        className="remove__btn form-delete-btn shadow-lg"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        style={{ borderRadius: 10 }}
                                        onClick={toggleModal}
                                    >
                                        <CrossCircle color="#FFF" />
                                        &nbsp;CANCEL
                                    </motion.button>
                                </Col>
                            </Row>
                        </fieldset>
                    </AvForm>
                </div>
            </div>
        </>
    );
}

UpdateDeliveryDateModule.propTypes = {
    toggleModal: PropTypes.func,
    deliveryDate: PropTypes.any,
    routePlanningId: PropTypes.string,
    fetchRelevantDeliveryData: PropTypes.func,
    fetchRoutePlanningData: PropTypes.func,
    loadTransactions: PropTypes.func,
};

export default UpdateDeliveryDateModule;
