export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const PERMISSIONS = {
    MERCHANTS: 'MERCHANTS',
    APPROVE_MERCHANTS: 'APPROVE_MERCHANTS',
    API_MANAGEMENT_MERCHANTS: 'API_MANAGEMENT_MERCHANTS',
    USER_ACCOUNT: 'USER_ACCOUNT',
    DELETED_USERS: 'DELETED_USERS',
    APPROVE_USER_ACCOUNT: 'APPROVE_USER_ACCOUNT',
    ROLE_PERMISSION: 'ROLE_PERMISSION',
    ASSIGN_ROLE_PERMISSION: 'ASSIGN_ROLE_PERMISSION',
    RIDER_ACCOUNT: 'RIDER_ACCOUNT',
    APPROVE_RIDER: 'APPROVE_RIDER',
    ORDER: 'ORDER',
    DELIVERY_EXCEL_UPLOAD: 'DELIVERY_EXCEL_UPLOAD',
    MANAGE_DELIVERY_EXCEL_UPLOAD: 'MANAGE_DELIVERY_EXCEL_UPLOAD',
    PICKING: 'PICKING',
    PACKING: 'PACKING',
    DISPATCH_READY: 'DISPATCH_READY',
    COMPLETED_ORDERS: 'COMPLETED_ORDERS',
    DELIVERY: 'DELIVERY',
    SHIPPING_LABELS: 'SHIPPING_LABELS',
    EXTERNAL_SYSTEM_CONNECTION: 'EXTERNAL_SYSTEM_CONNECTION',
    MERCHANT_LOCATION: 'MERCHANT_LOCATION',
    DELETED_MERCHANT_LOCATION: 'DELETED_MERCHANT_LOCATION',
    DELETED_MERCHANT: 'DELETED_MERCHANT',
    DELETED_RIDER: 'DELETED_RIDER',
    DELETED_DELIVERY: 'DELETED_DELIVERY',
    STOCK_ADJUSTMENT: 'STOCK_ADJUSTMENT',
    APPROVE_STOCK_ADJUSTMENT: 'APPROVE_STOCK_ADJUSTMENT',
    BILLING: 'BILLING',
    RIDER_COMMISSION: 'RIDER_COMMISSION',
    BILLING_RECORDS: 'BILLING_RECORDS',
    WAREHOUSE: 'WAREHOUSE',
    PRODUCT_MASTER_DATA: 'PRODUCT_MASTER_DATA',
    STOCKS_RECEIVING: 'STOCKS_RECEIVING',
    INVENTORY_REPORT: 'INVENTORY_REPORT',
    LABEL_PRINT_SHIPPING_LABEL: 'LABEL_PRINT_SHIPPING_LABEL',
    DELIVERY_BATCH_ASSIGNMENT: 'DELIVERY_BATCH_ASSIGNMENT',
    TRANSACTION_COMPLETE_DELIVERY: 'TRANSACTION_COMPLETE_DELIVERY',
    TRANSACTION_DELIVERY_UPDATE_VEHICLE_TYPE: 'TRANSACTION_DELIVERY_UPDATE_VEHICLE_TYPE',
    TRANSACTION_DELIVERY_UPDATE_DELIVERY_LOCATION: 'TRANSACTION_DELIVERY_UPDATE_DELIVERY_LOCATION',
    TRANSACTION_DELIVERY_UPDATE_PICKUP_LOCATION: 'TRANSACTION_DELIVERY_UPDATE_PICKUP_LOCATION',
    TRANSACTION_DELIVERY_UPDATE_DELIVERY_TYPE: 'TRANSACTION_DELIVERY_UPDATE_DELIVERY_TYPE',
    TRANSACTION_ADD_DELIVERY_ATTEMPT: 'TRANSACTION_ADD_DELIVERY_ATTEMPT',
    TRANSACTION_UNASSIGN_DELIVERY: 'TRANSACTION_UNASSIGN_DELIVERY',
    TRANSACTION_CANCEL_DELIVERY: 'TRANSACTION_CANCEL_DELIVERY',
    DELIVERY_ATTACHMENT_NOTES: 'DELIVERY_ATTACHMENT_NOTES',
    PARTNER_API_CLIENT_API_DASHBOARD: 'PARTNER_API_CLIENT_API_DASHBOARD',
    PARTNER_API_API_SERVICE: 'PARTNER_API_API_SERVICE',
    PARTNER_API_SANDBOX_ACCOUNTS: 'PARTNER_API_SANDBOX_ACCOUNTS',
    PARTNER_API_REQUEST_API_ACCESS: 'PARTNER_API_REQUEST_API_ACCESS',
    PARTNER_API_REQUEST_API_DASHBOARD_ACCESS: 'PARTNER_API_REQUEST_API_DASHBOARD_ACCESS',
    PARTNER_API_MERCHANT_DASHBOARD: 'PARTNER_API_MERCHANT_DASHBOARD',
    PARTNER_API_BASIC_SETTINGS: 'PARTNER_API_BASIC_SETTINGS',
    PARTNER_API_API_KEYS: 'PARTNER_API_API_KEYS',
    PARTNER_API_ADVANCED_SETTINGS: 'PARTNER_API_ADVANCED_SETTINGS',
    PARTNER_API_WEBHOOK: 'PARTNER_API_WEBHOOK',
    DEVELOPER_CONTACT: 'DEVELOPER_CONTACT',
    CONNECTED_APPS: 'CONNECTED_APPS',
    // Relevant to the User / Rider Self Deletion Request
    RESTORE_USER_ACCOUNT: 'RESTORE_USER_ACCOUNT',
    DELETE_USER_ACCOUNT: 'DELETE_USER_ACCOUNT',
    RESTORE_RIDER_ACCOUNT: 'RESTORE_RIDER_ACCOUNT',
    DELETE_RIDER_ACCOUNT: 'DELETE_RIDER_ACCOUNT',
    ROUTE_PLANNING: 'ROUTE_PLANNING',
    // merchant automatic delivery date settings
    AUTOMATIC_DELIVERY_DATE_SETTINGS: 'AUTOMATIC_DELIVERY_DATE_SETTINGS',
    MULTI_STOP_BILLING_SETTINGS: 'MULTI_STOP_BILLING_SETTINGS',
};

export const PERMISSION_TYPES = {
    add: 'Add',
    view: 'View',
    edit: 'Edit',
    delete: 'Delete',
    all: 'All',
    restore: 'Restore',
    onGoingDeliveries: 'OnGoingDeliveries',
    completedDeliveries: 'CompletedDeliveries',
    onGoing: 'OnGoing',
    completed: 'Completed',
    complete: 'Complete',
    export: 'Export',
    enableAndDisable: 'EnableAndDisable',
    reject: 'Reject',
    request: 'Request',
    detachDelivery: 'Detach',
    cancel: 'Cancel',
};

export const convertDeliveryStatusToNormalText = (value, data) => {
    if (data?.isDeleted) {
        return 'Deleted Delivery';
    }

    switch (value) {
        case 'dispatched':
            return 'Dispatched';
        case 'dispatchReady':
            return 'Dispatch Ready';
        case 'completed':
            return 'Completed';
        case 'pickUpStarted':
            return 'Pick Up Started';
        case 'pickedUp':
            return 'Picked Up';
        case 'deliveryStarted':
            return 'Delivery Started';
        case 'delivered':
            return 'Delivered';
        case 'proofOfDeliveryReceived':
            return 'Proof Of Delivery Received';
        case 'riderAssigned':
            return 'Rider Assigned';
        case 'delivering':
            return 'Delivery In Progress';
        default:
            break;
    }
    return '';
};

export const excelColumns = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
];
