/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import Reactotron from 'reactotron-react-js';
import { connect, useSelector, useDispatch } from 'react-redux';

// #region constants
import { USER_ROLES } from 'constants/user/user.constant';

// #region services
import { getAllClientsService } from 'services/client.service';
import { getMerchantsByClientIdService } from 'services/merchant.service';

// #region imports

import { __DEV__ } from 'utils/checks';
import {
    SET_CLIENT_MERCHANT_X_CLIENTS_LOADED,
    SET_CLIENT_MERCHANT_X_MERCHANTS_LOADED,
} from 'store/reducers/global/actionTypes';

// #region utils
import {
    getVisitingObject,
    removeVisitingClientAndMerchant,
    setVisitingClient,
    setVisitingMerchant,
    removeVisitingMerchant,
    hasRole,
} from 'utils/checkAuth';
import {
    getClientMerchantXClients,
    getClientMerchantXMerchants,
    storeClientMerchantXClients,
    storeClientMerchantXMerchants,
} from 'utils/local-storage';

function ClientMerchantX() {
    const dispatch = useDispatch();

    // eslint-disable-next-line no-unused-vars
    const { clientAdmin, clientUser, merchantAdmin, merchantUser, superAdmin } = USER_ROLES;

    const [visitingClientData, setVisitingClientData] = useState({});
    const [visitingMerchantData, setVisitingMerchantData] = useState({});

    const [selectedClient, setSelectedClient] = useState({});

    const [showClientDropDown, setClientDropDownShow] = useState(false);
    const [showMerchantDropDown, setMerchantDropDownShow] = useState(false);

    const [allClients, setAllClients] = useState([
        {
            name: 'ALL',
        },
    ]);
    const [allMerchants, setAllMerchants] = useState([
        {
            name: 'ALL',
        },
    ]);

    const _isMounted = useRef(true);

    const reduxState = useSelector((state) => state);

    useEffect(() => {
        const { visitingMerchant, visitingClient } = getVisitingObject();
        if (visitingClient) {
            setVisitingClientData(visitingClient);
            setSelectedClient(visitingClient);
        }
        if (visitingMerchant) {
            setVisitingMerchantData(visitingMerchant);
        }

        return () => {
            _isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (selectedClient._id && !reduxState.Global.clientMerchantXMerchantsLoaded) {
            __DEV__ && Reactotron.warn('*Client-Merchant-X : Merchant Request*');
            getMerchantsByClientIdService(selectedClient._id)
                .then((res) => {
                    if (_isMounted.current) {
                        dispatch({ type: SET_CLIENT_MERCHANT_X_MERCHANTS_LOADED, payload: true });
                        const { data } = res;
                        if (data) {
                            const records = data.docs.sort((a, b) => (a.name > b.name ? 1 : -1));
                            setAllMerchants([...allMerchants, ...records]);
                            storeClientMerchantXMerchants([...allMerchants, ...records]);
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else if (selectedClient._id) {
            const existingLocalStorageObject = getClientMerchantXMerchants();
            if (existingLocalStorageObject && Array.isArray(existingLocalStorageObject)) {
                setAllMerchants(existingLocalStorageObject);
            }
        }
    }, [selectedClient._id, reduxState.Global.clientMerchantXMerchantsLoaded]);

    useEffect(() => {
        if (!reduxState.Global.clientMerchantXClientsLoaded) {
            __DEV__ && Reactotron.warn('*Client-Merchant-X : Client Request*');
            getAllClientsService()
                .then((res) => {
                    if (_isMounted.current) {
                        dispatch({ type: SET_CLIENT_MERCHANT_X_CLIENTS_LOADED, payload: true });
                        const { data } = res;
                        if (data) {
                            const records = data.docs.sort((a, b) => (a.name > b.name ? 1 : -1));
                            setAllClients([...allClients, ...records]);
                            storeClientMerchantXClients([...allClients, ...records]);
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            const existingLocalStorageObject = getClientMerchantXClients();
            if (existingLocalStorageObject && Array.isArray(existingLocalStorageObject)) {
                setAllClients(existingLocalStorageObject);
            }
        }
    }, [reduxState.Global.clientMerchantXClientsLoaded]);

    const toggleClientToggle = () => {
        setClientDropDownShow(!showClientDropDown);
    };

    const toggleMerchantToggle = () => {
        setMerchantDropDownShow(!showMerchantDropDown);
    };
    /**
     *
     * Fillter the search items of clients
     * @param {data}
     * @param {searchTerm}
     *
     * @returns {result}
     */
    const filterContent = (data, searchTerm) => {
        // eslint-disable-next-line no-shadow
        const result = data.docs.filter((allClients) => allClients.name.toLowerCase().includes(searchTerm));
        setAllClients(result);
    };
    /**
     *
     * Get all data of dropdown menu of clients
     * @param {event}
     *
     */

    const handleSearchAll = (event) => {
        const searchTerm = event.currentTarget.value;
        __DEV__ && Reactotron.warn('*Client-Merchant-X : Client Request*');
        getAllClientsService()
            .then((res) => {
                if (_isMounted.current) {
                    dispatch({ type: SET_CLIENT_MERCHANT_X_CLIENTS_LOADED, payload: true });
                    const { data } = res;
                    if (data) {
                        filterContent(data, searchTerm.toLowerCase());
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
    /**
     *
     * Fillter the search items of Merchants
     * @param {data}
     * @param {searchTerm}
     *
     * @returns {result}
     */
    const filterContentMerchant = (data, searchTerm) => {
        // eslint-disable-next-line no-shadow
        const result = data.docs.filter((allMerchants) => allMerchants.name.toLowerCase().includes(searchTerm));
        setAllMerchants(result);
    };
    /**
     *
     * Get all data of dropdown menu of merchants
     * @param {event}
     *
     */
    const handleSearchAllMerchant = (event) => {
        const searchTerm = event.currentTarget.value;
        __DEV__ && Reactotron.warn('*Client-Merchant-X : Merchant Request*');
        getMerchantsByClientIdService(selectedClient._id)
            .then((res) => {
                if (_isMounted.current) {
                    dispatch({ type: SET_CLIENT_MERCHANT_X_MERCHANTS_LOADED, payload: true });
                    const { data } = res;
                    if (data) {
                        filterContentMerchant(data, searchTerm.toLowerCase());
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <>
            <Dropdown
                disabled={!hasRole([superAdmin])}
                isOpen={showClientDropDown}
                toggle={toggleClientToggle}
                className="d-inline-block user-dropdown mr-0"
            >
                <DropdownToggle
                    style={{ marginRight: -20, backgroundColor: 'transparent' }}
                    tag="button"
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                >
                    {visitingClientData && (
                        <span
                            className="scootix-btn-radius mb-3 shadow-lg nw-md"
                            style={{ borderRadius: '10px', color: 'white', minWidth: 80 }}
                        >
                            {' '}
                            {visitingClientData.name || 'Client'}{' '}
                            {hasRole([superAdmin]) && (
                                <span>
                                    <svg
                                        height="20"
                                        width="20"
                                        viewBox="0 0 20 20"
                                        aria-hidden="true"
                                        focusable="false"
                                        className="css-6q0nyr-Svg"
                                        fill="#fff"
                                        style={{ marginBottom: '2px', marginLeft: '7px' }}
                                    >
                                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                                    </svg>
                                </span>
                            )}
                        </span>
                    )}
                </DropdownToggle>
                <DropdownMenu left="true" className="dropdown-menu-custom">
                    <input type="text" placeholder="Search.." id="myInput" onChange={handleSearchAll} />
                    {allClients.map((x, i) => (
                        <DropdownItem
                            key={uuid()}
                            onClick={() => {
                                if (x.name === 'ALL') {
                                    removeVisitingClientAndMerchant();
                                    window.location.reload();
                                } else {
                                    removeVisitingMerchant();
                                    setVisitingClient(x);
                                    window.location.reload();
                                }
                            }}
                            href="#"
                        >
                            {x.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
            <Dropdown
                disabled={hasRole([merchantAdmin, merchantUser])}
                isOpen={showMerchantDropDown}
                toggle={toggleMerchantToggle}
                className="d-inline-block user-dropdown ml-0"
            >
                <DropdownToggle
                    style={{ backgroundColor: 'transparent' }}
                    tag="button"
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                >
                    {visitingMerchantData && (
                        <span
                            className="scootix-btn-radius mb-3 shadow-lg nw-md"
                            style={{ borderRadius: '10px', color: 'white', minWidth: 80 }}
                        >
                            {visitingMerchantData.name || 'ALL'}
                            {!hasRole([merchantAdmin, merchantUser]) && (
                                <span>
                                    <svg
                                        height="20"
                                        width="20"
                                        viewBox="0 0 20 20"
                                        aria-hidden="true"
                                        focusable="false"
                                        className="css-6q0nyr-Svg"
                                        fill="#fff"
                                        style={{ marginBottom: '2px', marginLeft: '7px' }}
                                    >
                                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                                    </svg>
                                </span>
                            )}
                        </span>
                    )}
                </DropdownToggle>
                <DropdownMenu left="true" className="dropdown-menu-custom">
                    <input type="text" placeholder="Search.." id="myInput" onChange={handleSearchAllMerchant} />
                    {allMerchants.map((x, i) => (
                        <DropdownItem
                            key={uuid()}
                            onClick={() => {
                                if (x.name === 'NONE' || x.name === 'ALL') {
                                    removeVisitingMerchant();
                                    window.location.reload();
                                } else {
                                    setVisitingMerchant(x);
                                    window.location.reload();
                                }
                            }}
                        >
                            {x.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
            {/* )} */}
        </>
    );
}

ClientMerchantX.propTypes = {};

const mapStateToProps = (state) => ({
    ...state,
});

export default connect(mapStateToProps, {})(ClientMerchantX);
