import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS, PERMISSION_TYPES } from 'utils/constants';

const { view } = PERMISSION_TYPES;

// If the user has access to one of below tabs he can access portal
export const hasAccessApiPortal = () =>
    hasAccess(PERMISSIONS.PARTNER_API_MERCHANT_DASHBOARD, [view]) ||
    hasAccess(PERMISSIONS.PARTNER_API_BASIC_SETTINGS, [view]) ||
    hasAccess(PERMISSIONS.PARTNER_API_ADVANCED_SETTINGS, [view]) ||
    hasAccess(PERMISSIONS.DEVELOPER_CONTACT, [view]) ||
    hasAccess(PERMISSIONS.CONNECTED_APPS, [view]) ||
    hasAccess(PERMISSIONS.PARTNER_API_WEBHOOK, [view]);

// TODO: add docs and change each tabKey to constant change other places used too
export function hasAccessTabs(tabs = []) {
    // modify tabs array based on the role permission
    return tabs.filter((obj) => {
        if (!hasAccess(PERMISSIONS.PARTNER_API_MERCHANT_DASHBOARD, [view]) && obj.tabKey === 'dashboard') {
            return false;
        }
        if (!hasAccess(PERMISSIONS.PARTNER_API_BASIC_SETTINGS, [view]) && obj.tabKey === 'basicSettings') {
            return false;
        }
        if (!hasAccess(PERMISSIONS.PARTNER_API_ADVANCED_SETTINGS, [view]) && obj.tabKey === 'advancedSettings') {
            return false;
        }
        if (!hasAccess(PERMISSIONS.PARTNER_API_WEBHOOK, [view]) && obj.tabKey === 'webhook') {
            return false;
        }
        if (!hasAccess(PERMISSIONS.DEVELOPER_CONTACT, [view]) && obj.tabKey === 'developerContact') {
            return false;
        }
        if (!hasAccess(PERMISSIONS.CONNECTED_APPS, [view]) && obj.tabKey === 'connectedApps') {
            return false;
        }
        return true;
    });
}
