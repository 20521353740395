import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import './styles/index.scss';

// #region assets | components
import { CheckCircleSolid, HourGlass } from 'components/Icons';

/**
 * A React component that displays delivery counts(number of deliveries and pending deliveries)
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.deliveryCountDetails - Delivery count details
 * @returns {JSX.Element} - The rendered component.
 */
function DeliveryCountCard(props) {
    const { deliveryCountDetails } = props;

    return (
        <React.Fragment>
            <Card className="shadow default-card route-planning-entry__card">
                <CardBody>
                    <Row>
                        <Col>
                            <h5 className="mb-2" style={{ fontWeight: 'bold' }}>
                                Deliveries
                            </h5>

                            <Row>
                                <Col xs={1}>
                                    <CheckCircleSolid />
                                </Col>
                                <Col>
                                    <h5 className="text-muted mb-0 ml-4" style={{ display: 'inline-block' }}>
                                        {deliveryCountDetails.deliveryCount} Completed
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={1}>
                                    <HourGlass />
                                </Col>
                                <Col>
                                    <h5 className="text-muted mb-0 ml-4" style={{ display: 'inline-block' }}>
                                        {deliveryCountDetails.pendingDeliveryCount} Pending
                                    </h5>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

DeliveryCountCard.propTypes = {
    deliveryCountDetails: PropTypes.any,
};

export default DeliveryCountCard;
