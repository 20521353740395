/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Spinner, Label, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as qs from 'query-string';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// #region imports
import Breadcrumbs from 'components/Common/Breadcrumb';
import PATHS from 'routes/paths';
import { getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { ClientAlerts, CustomToast, Select } from 'components';
import { getVisitingObject, IS_MERCHANT_VIEW } from 'utils/checkAuth';
import { getMerchantsByClientIdService } from 'services/merchant.service';
import { getApiErrorMessage } from 'constants/api';
import { createRole } from '../services';
import { validateForm } from './utils';

// #endregion imports
// import { PackagingOrders } from './constants';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Order List', link: PATHS.ORDER_PROCESSING.DEFAULT },
];

let initialFormValues = {
    taskStartDate: new Date(),
    merchantId: null,
    merchantName: null,
};

function RoleFormPage(props) {
    const { visitingClient, visitingMerchant } = getVisitingObject();

    const history = useHistory();

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);

    // Form State
    // eslint-disable-next-line no-unused-vars
    const [editingMode, setEditingMode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);

    const [formKey, setFormKey] = useState(1); // Used  Reset Form

    const [merchants, setMerchants] = useState([]);

    useEffect(() => {
        const formType = qs.parse(props.location.search);
        if (formType && formType.type && formType.type === 'new') {
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Role', link: '#' }]);
        }
    }, [props.location]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);
        const payload = formData;

        try {
            await createRole(payload);
            toast.custom((t) => <CustomToast text="Successfully added a new Role" t={t} type="success" />, {
                position: 'top-right',
            });
            setFormData({ roleName: '' });
            resetForm();
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors) {
                const error = getApiErrorMessage(e.data.errors.msg);
                toast.custom((t) => <CustomToast text={error} t={t} type="error" />, {
                    position: 'top-right',
                });
            } else {
                toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        }

        setFormLoading(false);
        setValidated(true);
        setSubmitted(false);
    };

    // Reset Form
    const resetForm = () => {
        try {
            document.getElementById('roleNameForm').reset();
            setFormData(initialFormValues);
            setFormKey((prevState) => prevState + 1);
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);

        if (id === 'userType') {
            if (!IS_MERCHANT_VIEW()) {
                setFormData({
                    ...formData,
                    merchantName: '',
                    merchantId: '',
                    [id]: event ? event.value : null,
                });
            } else {
                setFormData({
                    ...formData,
                    [id]: event ? event.value : null,
                });
            }
            return;
        }

        if (id === 'merchantId') {
            const relevantMerchant = merchants.find((x) => x._id === event.value);
            if (relevantMerchant) {
                setFormData({
                    ...formData,
                    merchantName: relevantMerchant.name || '',
                    [id]: event ? event.value : null,
                });
            }
            return;
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Role" breadcrumbItems={breadcrumbItems} />
                    <Row style={{ justifyContent: 'flex-end', alignItems: 'center', margin: 2 }}>
                        <div
                            role="button"
                            tabIndex={0}
                            className="scootix-btn-radius p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                            onClick={() => {
                                history.push({
                                    pathname: `${PATHS.ROLE_PERMISSION.DEFAULT}`,
                                });
                            }}
                            onKeyDown={() => {
                                history.push({
                                    pathname: `${PATHS.ROLE_PERMISSION.DEFAULT}`,
                                });
                            }}
                        >
                            <i className="ri-arrow-go-back-line left-icon"></i>
                            Back to Roles
                        </div>
                    </Row>
                    <>
                        <ClientAlerts />
                    </>
                    <Card>
                        <CardBody>
                            <AvForm className="needs-validation" onSubmit={handleSubmit} id="roleNameForm">
                                <Row>
                                    <Col md="3">
                                        <FormGroup>
                                            <Label htmlFor="userType">User Type</Label>
                                            <Select
                                                value={getDefaultValueForSelect(formData.userType)}
                                                options={[
                                                    { label: 'Client', value: 'Client' },
                                                    { label: 'Merchant', value: 'Merchant' },
                                                ]}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'userType')}
                                                submitted={submitted}
                                                required
                                                validated={validated}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {formData.userType === 'Merchant' && (
                                    <Row>
                                        <Col md="3">
                                            <FormGroup>
                                                <Label htmlFor="merchantId">Merchant</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(
                                                        getMerchantSelect(formData.merchantId)
                                                    )}
                                                    options={merchants}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                    isDisabled={IS_MERCHANT_VIEW()}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col md="3">
                                        <FormGroup>
                                            <Label htmlFor="country">Role Name</Label>
                                            <AvField
                                                name="roleName"
                                                placeholder="Name"
                                                type="text"
                                                errorMessage="Required"
                                                className="form-control"
                                                value={formData.roleName}
                                                validate={{ required: { value: true } }}
                                                id="roleName"
                                                onChange={handleInputChange}
                                                key={String(formKey)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {visitingClient && (
                                    <Row style={{ justifyContent: 'flex-start' }}>
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                            type="submit"
                                            disabled={formLoading}
                                        >
                                            {formLoading ? (
                                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                            ) : (
                                                <>
                                                    <i className="fas fa-save left-icon"></i>
                                                    <span className="h6">Save</span>
                                                </>
                                            )}
                                        </motion.button>
                                        <motion.div
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                            onClick={() => {
                                                history.push({
                                                    pathname: PATHS.ROLE_PERMISSION.DEFAULT,
                                                });
                                            }}
                                        >
                                            <i className="far fa-times-circle mr-2 fa-lg" />
                                            Cancel
                                        </motion.div>
                                    </Row>
                                )}
                            </AvForm>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

const HotPackagingPage = hot(RoleFormPage);

export default connect(mapStateToProps, {})(HotPackagingPage);
