import React from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown, DropdownToggle, Label } from 'reactstrap';

// #region assets | components
import { Settings } from 'components/Icons';

// #endregion imports
import TableFilter from './TableHeaderComponents/TableFilter';
import Search from './TableHeaderComponents/TableSearch';
import TableDate from './TableHeaderComponents/TableDate';

function ShippingLabelTableHeader(props) {
    const {
        setShowFilters,
        showFilters,
        filters,
        getFilterTitle,
        onFilter,
        isManualSearch,
        setSearch,
        search,
        isSetting,
    } = props;
    return (
        <div className="table-top-row">
            <div className="top-row">
                {isSetting && (
                    <div className="table-settings">
                        <Dropdown className="d-inline-block dropdown-table-settings">
                            <DropdownToggle
                                tag="button"
                                className="btn header-item waves-effect"
                                id="table-settings-dropdown-toggle"
                            >
                                <div className="settings-btn">
                                    <Settings />
                                </div>
                            </DropdownToggle>
                        </Dropdown>
                    </div>
                )}
                <TableFilter
                    setShowFilters={setShowFilters}
                    showFilters={showFilters}
                    filters={filters}
                    getFilterTitle={getFilterTitle}
                    onFilter={onFilter}
                    isManualSearch={isManualSearch}
                    setSearch={setSearch}
                    search={search}
                />
                <div className="table__custom__search-shipping">
                    <Search
                        onFilter={onFilter}
                        isManualSearch={isManualSearch}
                        setSearch={setSearch}
                        search={search}
                        searchPlaceholder="Search by Label Print ID, Batch Print Name Merchant"
                    />
                </div>

                <div>
                    <Form className="table__custom__lable-shipping d-lg-block">
                        <div className="position-relative">
                            <Label htmlFor="fromDate">Created Date</Label>
                        </div>
                    </Form>
                </div>
                <div>
                    <TableDate setSearch={setSearch} search={search} onFilter={onFilter} />
                </div>
            </div>
        </div>
    );
}

ShippingLabelTableHeader.propTypes = {
    setShowFilters: PropTypes.func,
    showFilters: PropTypes.bool,
    isManualSearch: PropTypes.bool,
    filters: PropTypes.array,
    onFilter: PropTypes.func,
    setSearch: PropTypes.func,
    search: PropTypes.object,
    getFilterTitle: PropTypes.func,
    isSetting: PropTypes.bool,
};

export default ShippingLabelTableHeader;
