export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { riderId } = form;

    if (!riderId) {
        return { isFormValid, message };
    }

    isFormValid = true;
    return { isFormValid, message };
};
