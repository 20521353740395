/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

// #region assets | components
import { CustomToast, InfoModal, ScootixModal } from 'components';

// #region other imports
import { VEHICLE_TYPES } from 'constants/constants';
import { defaultScootixModalState } from 'constants/options';
// import { statusReportOne } from 'mock/delivery/batch-delivery-assignment';

// #regions services
import { getRiderListData } from 'services/rider.service';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #endregion imports
// import { failOnly } from 'mock/delivery/batch-delivery-assignment';
import FormBody from './FormBody';
import { validateForm } from './utils';
import { assignBatchDeliveriesToRiderService } from './services';
import { buildInitialFormValues } from '../../constants';
import { UPDATE_SELECTED_DELIVERIES } from '../../actionTypes';

/**
 * Initial Form
 * @param {*} props
 * @returns {React.FunctionComponent}
 */
function RiderAssignForm(props) {
    const { setFormData, formData, loadDeliveryListData } = props;
    const batchDeliveryReducer = useSelector((state) => state?.BatchDeliveryAssignment);

    const _isMounted = useRef(true);
    const dispatch = useDispatch();

    // component state
    const [scootixModalState, setScootixModalState] = useState(defaultScootixModalState);

    //  backend data
    const [riderListData, setRiderListData] = useState({ docs: [] });
    // State for storing filtered rider data
    const [riderFilteredListData, setRiderFilteredListData] = useState({ docs: [] });
    const [infoModalData, setInfoModalData] = useState({
        title: null,
        body: '',
        // show: false,
        type: 'batchDeliveryRiderAssignStatus',
        size: 'lg',
        show: false,
        data: null,
    });

    // form state
    const [validated, setValidated] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);

    // unmount component
    useEffect(
        () => () => {
            _isMounted.current = false;
        },
        []
    );

    useEffect(() => {
        loadRiderListData({ ...formData, showLoading: true });
    }, [formData.vehicleType, formData.riderStatus]);

    // Trigger filtered rider list when select the deliveries
    useEffect(() => {
        ridersForSelectedDeliveries();
    }, [batchDeliveryReducer?.selectedDeliveries]);

    // Get riders for selected deliveries
    // Check if riders are limited to a merchant and filter the relevant riders
    const ridersForSelectedDeliveries = () => {
        // Check if the batchDeliveryReducer or its selectedDeliveries property is not defined or null
        if (!batchDeliveryReducer || !batchDeliveryReducer.selectedDeliveries) {
            return;
        }
        const { selectedDeliveries } = batchDeliveryReducer;
        // Get the merchantId from selectedDeliveries
        const selectDeliveries = selectedDeliveries?.docs.map((x) => ({ merchantId: x.merchantId })) || [];

        // Check rider is limited to merchant and filter riders who has same merchantId with deliveries
        const selectRider = riderListData?.docs?.filter((rider) => {
            if (rider.isLimitedToMerchant) {
                const isSameMerchant = selectDeliveries?.every((delivery) => {
                    return rider.merchantId === delivery.merchantId;
                });
                return isSameMerchant;
            }
            return true;
        });
        setRiderFilteredListData({ ...riderListData, docs: selectRider });
    };

    /**
     * Load Delivery List Data
     * @param {*} args
     */
    const loadRiderListData = (args) => {
        if (args.showLoading) {
            setLoading(true);
        }
        getRiderListData({
            ...args,
            limit: 1000,
            vehicleType: formData.vehicleType || VEHICLE_TYPES.MOTOR_BIKE,
            status: formData.riderStatus,
        })
            .then((res) => {
                const { data } = res;
                delete data.sort;
                setRiderListData({ ...data });
                setRiderFilteredListData({ ...data });
            })
            .catch((e) => {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            })
            .finally(() => {
                if (args.showLoading) {
                    setLoading(false);
                }
            });
    };

    // Reset Form
    const resetForm = () => {
        document.getElementById('riderAssignForm').reset();
        const formInitialValues = buildInitialFormValues();
        setFormData(formInitialValues);
        const newArgs = { ...formInitialValues, showLoading: true };
        loadDeliveryListData(newArgs);
    };

    /**
     * Handle Form Submit
     * @param {*} event
     * @param {*} errors
     * @returns
     */
    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);

            return;
        }
        event.persist();

        const { isFormValid, message } = validateForm(formData);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }
        if (batchDeliveryReducer?.selectedDeliveries?.docs.length === 0) {
            toast.custom((t) => <CustomToast text="Select At Least One Delivery To Process" t={t} type="warning" />, {
                position: 'top-right',
            });
            stopFormFromSubmitting();
            return;
        }

        if (batchDeliveryReducer?.selectedDeliveries?.docs.length > 0) {
            setScootixModalState({
                ...scootixModalState,
                show: true,
                message: 'Are you sure you want to Assign?',
                onConfirm: () => handleAssignRider(),
            });
        }
    };

    /**
     * Handle Assign Rider
     */
    const handleAssignRider = async () => {
        setFormLoading(true);
        try {
            const payload = {
                filters: formData,
                selectedDeliveries: batchDeliveryReducer?.selectedDeliveries?.docs || [],
            };

            const { data } = await assignBatchDeliveriesToRiderService(payload, formData.riderId);
            if (data) {
                if (data.statusReport) {
                    // logDataToCopy(data.statusReport);
                    setInfoModalData({ ...infoModalData, show: true, data: data.statusReport });
                }
                dispatch({
                    type: UPDATE_SELECTED_DELIVERIES,
                    payload: { id: uuid(), docs: [] },
                });
            }

            toast.custom((t) => <CustomToast text="Assign Batch Delivery Operation Completed" t={t} type="success" />, {
                position: 'top-right',
            });

            resetForm();
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        stopFormFromSubmitting();
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setFormData({
            ...formData,
            [id]: event?.value || null,
        });
    };

    function stopFormFromSubmitting() {
        setFormLoading(false);
        setValidated(false);
        setSubmitted(false);
        toggleScootixModal();
    }

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    // Form Selectors
    const getRiderSelect = (_id) => {
        if (_id) {
            const relevantObj = riderListData.docs.find((x) => x._id === _id);
            if (relevantObj) {
                return { label: relevantObj.fullName, value: relevantObj._id };
            }
        }
        return null;
    };

    const toggleInfoModal = () => setInfoModalData({ ...infoModalData, show: false });

    const commonProps = {
        formData,
        setFormData,
        riderFilteredListData,
        getRiderSelect,
        validated,
        submitted,
        handleSelectChange,
        loadRiderListData,
        loading,
        handleSubmit,
        formLoading,
        setScootixModalState,
        scootixModalState,
    };

    return (
        <>
            <FormBody {...commonProps} />
            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} loading={formLoading} />
            <InfoModal toggleModal={toggleInfoModal} {...infoModalData} />
        </>
    );
}

RiderAssignForm.propTypes = {
    setFormData: PropTypes.func,
    formData: PropTypes.object,
    handleSearch: PropTypes.func,
    filters: PropTypes.object,
    setFilters: PropTypes.func,
    loadDeliveryListData: PropTypes.func,
};

export default RiderAssignForm;
