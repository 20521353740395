/* eslint-disable indent */
import {
    GET_USERS,
    GET_USER_BY_ID,
    GET_USERS_ALL,
    DELETE_USER,
    TOGGLE_DISABLE_USER,
    GET_USERS_META,
    UPDATE_USER,
    RESEND_REGISTRATION_VERIFICATION,
    GET_USERS_BY_QUERY,
    RESEND_REGISTRATION_VERIFICATION_URL,
    RESTORE_USER_SELF_ACCOUNT_DELETION_ACCESS,
    AUTH_VERIFY_PASSWORD,
    AUTH_VERIFY_AUTHENTICATED,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Users Service
 * @returns {object}
 */
export const getUsersService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate,
    status
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_USERS}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }${status ? `status=${status}` : `status=new`}`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Users By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getUserByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_USER_BY_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Users
 * @returns {Promise}
 */
export const getAllUsersService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_USERS_ALL).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Users Meta
 * @returns {Promise}
 */
export const getAllUsersMetaService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_USERS_META).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const updateUserService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_USER).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const toggleDisableUserService = (id) => {
    try {
        const url = UrlBuilder().setUrl(TOGGLE_DISABLE_USER).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Delete User
 * @param id
 */
export const deleteUserService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_USER).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const resendUserRegistrationVerificationCodeService = (data) => {
    try {
        const url = UrlBuilder().setUrl(RESEND_REGISTRATION_VERIFICATION).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const resendUserRegistrationVerificationUrlService = (data) => {
    try {
        const url = UrlBuilder().setUrl(RESEND_REGISTRATION_VERIFICATION_URL).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getUsersByQueryService = (data) => {
    try {
        const url = UrlBuilder().setUrl(GET_USERS_BY_QUERY).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

// Send userId and password to restore user account
export const restoreUserAccountService = (data) => {
    const url = UrlBuilder().setUrl(RESTORE_USER_SELF_ACCOUNT_DELETION_ACCESS).setPathParams({ id: data._id }).build();
    return instance.patch(url, data);
};

// Send userId and password to verify user account password
export const getUserVerifiedPassword = (data, id) => {
    const url = UrlBuilder().setUrl(AUTH_VERIFY_PASSWORD).setPathParams({ userId: id }).build();
    return instance.post(url, data);
};

/**
 * Verify User Authenticated
 * @returns {Promise}
 */
export const verifyAuthenticated = () => {
    const url = UrlBuilder().setUrl(AUTH_VERIFY_AUTHENTICATED).build();
    return instance.get(url);
};
