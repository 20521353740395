/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { centerElementContainerStyles } from 'styles';
import { Input } from 'reactstrap';

// #endregion imports
import FullScreen from './TableHeaderComponents/FullScreen';
import Search from './TableHeaderComponents/TableSearch';
import TableFilter from './TableHeaderComponents/TableFilter';
import TableExport from './TableHeaderComponents/TableExport';
import TableStickyView from './TableHeaderComponents/TableStickyView';

function Default(props) {
    const {
        setShowFilters,
        showFilters,
        isManualSearch,
        filters,
        onFilter,
        isFilter,
        isExport,
        showStickyView,
        tableData,
        isFullScreenShow,
        isSearch,
        onDateFilter,
        getFilterTitle,
        setSearch,
        search,
        toggleExportModal,
        setEnableStickyView,
        enableStickyView,
        isFullScreen,
        setIsFullScreen,
        handleFullScreen,
        setIsUp, // set column wise sorting
        dateFilter,
        setDateFilter,
    } = props;
    return (
        <>
            <div className="table-top-row">
                <div className="top-row">
                    {isFilter && (
                        <TableFilter
                            setShowFilters={setShowFilters}
                            showFilters={showFilters}
                            filters={filters}
                            getFilterTitle={getFilterTitle}
                            onFilter={onFilter}
                            isManualSearch={isManualSearch}
                            setSearch={setSearch}
                            search={search}
                            setIsUp={setIsUp}
                            setDateFilter={setDateFilter}
                        />
                    )}
                    {isSearch && (
                        <Search
                            onFilter={onFilter}
                            isManualSearch={isManualSearch}
                            setSearch={setSearch}
                            search={search}
                            searchPlaceholder="Search"
                            setIsUp={setIsUp}
                        />
                    )}
                    {/* Delivery date */}
                    <div style={{ marginLeft: '40px' }}>
                        {dateFilter && (
                            <Input
                                onChange={(e) => onDateFilter(search.searchBy, e.target.value)}
                                type="date"
                                className="form-control"
                                value={search.filterDate}
                                id="deliveryDate"
                            />
                        )}
                    </div>
                </div>
                <div className="d-flex row" style={centerElementContainerStyles}>
                    {isExport && <TableExport toggleExportModal={toggleExportModal} />}
                    {showStickyView && tableData.docs.length > 5 && (
                        <TableStickyView
                            enableStickyView={enableStickyView}
                            setEnableStickyView={setEnableStickyView}
                        />
                    )}
                    {isFullScreenShow && (
                        <FullScreen
                            isFullScreen={isFullScreen}
                            setIsFullScreen={setIsFullScreen}
                            handleFullScreen={handleFullScreen}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

Default.propTypes = {
    setShowFilters: PropTypes.func,
    showFilters: PropTypes.bool,
    isManualSearch: PropTypes.bool,
    filters: PropTypes.array,
    onFilter: PropTypes.func,
    isFilter: PropTypes.bool,
    isExport: PropTypes.bool,
    tableData: PropTypes.object,
    showStickyView: PropTypes.bool,
    setIsFullScreen: PropTypes.func,
    setEnableStickyView: PropTypes.func,
    isFullScreenShow: PropTypes.bool,
    isSearch: PropTypes.bool,
    setSearch: PropTypes.func,
    search: PropTypes.object,
    getFilterTitle: PropTypes.func,
    toggleExportModal: PropTypes.func,
    isFullScreen: PropTypes.bool,
    enableStickyView: PropTypes.bool,
    handleFullScreen: PropTypes.object,
    setIsUp: PropTypes.func,
    onDateFilter: PropTypes.func,
    dateFilter: PropTypes.bool,
    setDateFilter: PropTypes.func,
};

export default Default;
