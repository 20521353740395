/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Media, Spinner, Collapse } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import AvCheckboxGroup from 'availity-reactstrap-validation/lib/AvCheckboxGroup';
import AvCheckbox from 'availity-reactstrap-validation/lib/AvCheckbox';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import * as qs from 'query-string';
import toast from 'react-hot-toast';
import GoogleMapReact from 'google-map-react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import { isObject } from 'lodash';

// #region components
import {
    PlacesAutocompleteModal,
    Select,
    BackdropLoader,
    RiderProfileModal,
    PlacesAutocompleteField,
    CustomToast,
    ClientAlerts,
    AlertModal,
    ScootixModal,
    FormStatusBtn,
    DocumentUpdatedModal,
} from 'components';
import DeliveryProgressBar from 'components/DeliveryProgressbar';
import Breadcrumbs from 'components/Common/Breadcrumb';
import TrackDeliveryModal from 'components/Modals/Track-Delivery';
import RiderSelectionModal from 'components/Modals/Select-Rider';
import AttachmentPreviewModal from 'components/Modals/Image-Preview';
import { LinkedWithRoutePlan } from 'components/Icons';
import { RECIPIENT_DELIVERY_ADDRESS_ICON_IMAGE, MERCHANT_LOCATION_ICON_IMAGE } from 'definitions/assets';

// #region imports
import PATHS from 'routes/paths';
import { THEME_COLOR } from 'theme';
import { DELIVERY_TYPES, VEHICLE_TYPES } from 'constants/constants';

// #region hooks
import useDeliveryWatcher from 'hooks/deliveryWatcher';

// #region utils
import { PERMISSIONS } from 'utils/constants';
import { getVisitingObject, getUser, IS_SUPER_ADMIN, hasAccess } from 'utils/checkAuth';
import {
    cleanMobileNo,
    getDateValue,
    getDefaultValueForSelect,
    reactSelectCustomStyles,
    apiErrorHandler,
    mapUserObj,
    apiErrorHandlerV2,
    checkIfMerchantOrClientChanged,
} from 'utils/helpers';
import { filterRidersBasedByAssigningDeliveryConditions } from 'utils/conditions/delivery';
import { DEBUG_MODE, __BLOCKED_FOR_MERCHANTS__, __DEV__ } from 'utils/checks';
import { breakDownDistanceMatrixData } from 'utils/maps';

// #region services
import { getCustomerByMobileNo } from 'services/customer.service';
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';
import { getAllOrdersByStatusService } from 'services/order.service';
import { getAllRidersService, getRiderByIdService } from 'services/rider.service';
import { getDeliveryProgressTransactionsService } from 'services/transaction.service';
import { getAllMerchantLocationByMerchantId } from 'services/merchant-location.service';
import { getAttachmentByKey } from 'services/attachment.service';
import {
    getAllDeliveriesAttachmentsService,
    getDeliveryByIdService,
    postLinkBillingRecordWithDeliveryRecord,
} from 'services/deliveries.service';
import { getDistanceMatrixMeta } from 'services/external-maps.services';

// #endregion imports
import { createDeliveryService, deleteDelivery, updateDeliveryService, updatePendingDelivery } from '../../services';
import { buildLocationData, emptyFormData, emptyOrderData, renderExistingPendingData, validateForm } from './utils';
import MapDeliveryDocument from './components/MapDeliveryDocument';
import './style/index.css';
import DeliveryInformation from './containers/deliveryInformation/index';
import DeliveryProgressComponent from './containers/DeliveryProgress';
import PodComponent from './components/Pod';
import AdvanceOptionsComponent from './components/AdvanceOptions';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Delivery', link: PATHS.DELIVERY.DEFAULT },
];

let initialFormValues = {
    isDispatched: true,
    isDeliveryReadyDateTime: true,
    deliveryReadyTime: new Date(),
};

if (__DEV__) {
    // initialFormValues = { ...initialFormValues, ...deliveryMockData };
}

function DeliveryFormPage(props) {
    const { socket } = props;

    const { visitingClient, visitingMerchant } = getVisitingObject();

    const history = useHistory();
    const _isMounted = useRef(true);

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [modalLoading, setModalLoading] = useState(false);

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [initialDeliveryData, setInitialDeliveryData] = useState(initialFormValues);
    const [formData, setFormData] = useState(initialFormValues);
    const [merchants, setMerchants] = useState([]);

    const [clientRiders, setClientRiders] = useState([]);

    const [riderData, setRiderData] = useState({});

    // Orders Based On Status
    const [dispatchReadyOrders, setDispatchReadyOrders] = useState([]);
    const [dispatchedOrders, setDispatchedOrders] = useState([]);
    const [completedOrders, setCompletedOrders] = useState([]);
    const [showRiderCallModal, setShowRiderCallModal] = useState(false);
    const [deliveryProgressHistory, setDeliveryProgressHistory] = useState([]);
    const [deliveryAttachmentHistory, setDeliveryAttachmentHistory] = useState([]);

    const [isViewMode, setIsViewMode] = useState(false);

    const [allRidersWithoutFilter, setAllRidersWithoutFilter] = useState([]); // All The Riders Without Filter Applied

    const [orders, setOrders] = useState([]);
    const [riders, setRiders] = useState([]);
    const [showPickupLocation, setShowPickupLocation] = useState(false);
    const [showDeliveryLocation, setShowDeliveryLocation] = useState(false);
    const [podAttachment, setPodAttachment] = useState(null);
    const [showPreview, setShowPreview] = useState(false);

    const [showRiderProfileModal, setShowRiderProfileModal] = useState(false);
    const [showLiveLocationModal, setShowLiveLocationModal] = useState(false);
    const [showRiderSelectionModal, setShowRiderSelectionModal] = useState(false);

    const [ridersSearchedRange, setRidersSearchedRange] = useState(null);
    const [allMerchantLocations, setAllMerchantLocations] = useState([]);

    // Pending Delivery States
    const [isPendingDelivery, setIsPendingDelivery] = useState(false);
    const [pendingDataId, setPendingDataId] = useState(null);
    const [showExistingPendingData, setShowExistingPendingData] = useState(true);
    const [existingPendingData, setExistingPendingData] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState({ state: false, data: null });

    const [showSaveBtn, setShowSaveBtn] = useState(false);
    const [image, setImage] = useState({
        riderProfilePictureUrl: null,
    });

    // Custom Hooks
    const { isDataConflict } = useDeliveryWatcher(formData._id);

    useEffect(
        () => () => {
            _isMounted.current = false;
        },
        []
    );

    useEffect(() => {
        if (!props.location) {
            history.push(PATHS.DELIVERY.DEFAULT);
            return;
        }

        const urlSearch = qs.parse(props.location.search);
        if (urlSearch && urlSearch.type && urlSearch.type === 'new') {
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Delivery', link: '#' }]);
        }
        if (urlSearch && urlSearch.type && urlSearch.type === 'edit') {
            handleUpdateComponentEditMode();
        }
        if (urlSearch && urlSearch.type && urlSearch.type === 'delivery-pending') {
            if (!props.location.state) {
                history.push(PATHS.DELIVERY.DEFAULT);
                return;
            }
            const { deliveryParentData } = props.location.state;
            const { pendingdataid } = urlSearch;
            setPendingDataId(pendingdataid);
            setIsPendingDelivery(true);
            setFormData({ ...formData, ...deliveryParentData });
            setInitialDeliveryData({ ...initialDeliveryData, ...deliveryParentData });
            setExistingPendingData({ ...formData, ...deliveryParentData });
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Pending Delivery', link: '#' }]);
        }
        if (urlSearch && urlSearch.type && urlSearch.type === 'deleted-view') {
            !props.location.state && history.push(PATHS.DELIVERY.DEFAULT);
            const { deliveryParentData } = props.location.state;
            setFormData({ ...formData, ...deliveryParentData });
            setInitialDeliveryData({ ...initialDeliveryData, ...deliveryParentData });
            setIsViewMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'View Delivery', link: '#' }]);
        }
    }, [props.location.search]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    useEffect(() => {
        if (editingMode) {
            if (hasAccess(PERMISSIONS.DELIVERY, ['Edit'])) {
                setShowSaveBtn(true);
            }
        } else if (hasAccess(PERMISSIONS.DELIVERY, ['Add'])) {
            setShowSaveBtn(true);
        }
    }, [editingMode]);

    useEffect(() => {
        if (formData.podUrl) {
            getAttachmentByKey(formData.podUrl).then((res) => {
                setPodAttachment(res);
            });
        }
    }, [formData.podUrl]);

    // Orders Side Effects
    useEffect(() => {
        if (!editingMode) {
            loadAllOrdersByStatusDispatchReady();
            loadAllOrdersByStatusCompleted();
        }
        if (editingMode) {
            loadAllOrdersByStatusDispatched();
        }
    }, [editingMode]);

    useEffect(() => {
        if (formData.riderId && isObject(formData.riderId) && formData?.riderId?._id) {
            setRiderData({ ...riderData, ...formData.riderId });
            loadRiderProfileImage(formData.riderId.riderProfilePictureUrl);
        } else if (formData.riderId && typeof formData.riderId === 'string') {
            loadRiderDataById(formData.riderId);
        }
    }, [formData.riderId]);

    useEffect(() => {
        if (formData.vehicleType) {
            const filteredRiders = filterRidersBasedByAssigningDeliveryConditions(allRidersWithoutFilter, formData);
            setRiders(filteredRiders);
        }
    }, [formData.vehicleType]);

    // Side Effect to load transactions of delivery progress
    // works on an interval basis
    useEffect(() => {
        formData._id && loadDeliveryAttachments();
        formData._id && loadDeliveryProgressTransactions();
        const interval = setInterval(() => {
            formData._id && !__DEV__ && loadDeliveryProgressTransactions();
        }, 20000);
        return () => clearInterval(interval);
    }, [formData._id]);

    useEffect(() => {
        if (formData.merchantId) {
            const relevantMerchant = merchants.find((x) => x._id === formData.merchantId);
            if (relevantMerchant) {
                const merchantSetting = relevantMerchant.merchantSettingId
                    ? isObject(relevantMerchant.merchantSettingId)
                        ? relevantMerchant.merchantSettingId.settings
                        : null
                    : null;
                let merchantSettingObject = null;
                if (merchantSetting) {
                    const { delivery, deliveryImport, schedular } = merchantSetting;
                    merchantSettingObject = { delivery, deliveryImport, schedular };
                }
                setFormData((prevState) => ({
                    ...prevState,
                    merchantSetting: merchantSettingObject,
                }));
            }

            // loading merchant locations
            loadMerchantLocations(formData.merchantId);
        }
    }, [formData.merchantId]);

    useEffect(() => {
        if (editingMode) {
            if (riders.length === 0) {
                if (formData.riderName && formData.riderId) {
                    setRiders([
                        {
                            _id: formData.riderId,
                            fullName: formData.riderName,
                            referenceNumber: formData.riderReference,
                            mobileNo: formData.riderMobileNumber,
                        },
                    ]);
                }
            }
        }
    }, [formData.riderId, editingMode]);

    useEffect(() => {
        if (formData.source === 'System Order' && formData.deliveryType === 'New Order') {
            if (formData.orderId) {
                setOrders(dispatchedOrders);
            } else {
                const filteredOrdersByVehicle = filterOrdersByVehicle(dispatchReadyOrders);
                setOrders(filteredOrdersByVehicle);
            }
        } else if (formData.source === 'System Order' && formData.deliveryType === 'Return Order') {
            const filteredOrdersByVehicle = filterOrdersByVehicle(completedOrders);
            setOrders(filteredOrdersByVehicle);
        }
    }, [
        formData.vehicleType,
        formData.source,
        formData.deliveryType,
        editingMode,
        dispatchReadyOrders,
        completedOrders,
        dispatchedOrders,
    ]);

    function handleUpdateComponentEditMode() {
        try {
            const urlSearch = qs.parse(props.location.search);
            const { id } = urlSearch;
            if (!id && !props.location.state) {
                history.push(PATHS.DELIVERY.DEFAULT);
                return;
            }

            if (props.location.state) {
                const { deliveryParentData } = props.location.state;
                checkIfMerchantOrClientChanged(deliveryParentData);
            }

            if (id) {
                loadDeliveryById(id);
            }

            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Delivery', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    }

    const loadDeliveryById = async (id) => {
        setBackdropLoading((x) => x + 1);
        try {
            const { data } = await getDeliveryByIdService(id);
            if (data) {
                setFormData((prevState) => ({ ...prevState, ...data }));
                setInitialDeliveryData({ ...initialDeliveryData, ...data });
                history.replace({
                    pathname: `${PATHS.DELIVERY.FORM}`,
                    search: `?type=edit&id=${data._id}`,
                    state: {
                        deliveryParentData: data,
                    },
                });
            } else {
                toast.custom((t) => <CustomToast text="Delivery Details Not Found" t={t} type="error" />, {
                    position: 'top-right',
                });
                history.goBack();
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((x) => x - 1);
    };

    const loadRiderDataById = async (id) => {
        try {
            const { data } = await getRiderByIdService(id);
            if (data) {
                setRiderData({ ...riderData, ...data });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    const loadDeliveryProgressTransactions = () => {
        if (formData._id) {
            getDeliveryProgressTransactionsService(formData._id).then((res) => {
                if (res) {
                    const { data } = res;
                    data.docs && setDeliveryProgressHistory(data.docs);
                }
            });
        }
    };

    // loading delivery attachment data
    const loadDeliveryAttachments = async () => {
        if (formData._id) {
            try {
                const { data } = await getAllDeliveriesAttachmentsService(formData._id);
                if (data) {
                    setDeliveryAttachmentHistory(data);
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    const loadMerchantLocations = async (id) => {
        try {
            const { data } = await getAllMerchantLocationByMerchantId(id);
            if (data && data.docs) {
                setAllMerchantLocations(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.merchantLocationName || ''} - ${x.location || ''}`,
                    }))
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadAllOrdersByStatusDispatched = async () => {
        try {
            const { data } = await getAllOrdersByStatusService('dispatched');
            if (data.docs) {
                setDispatchedOrders(
                    data.docs
                        .map((x) => ({ ...x, label: x.referenceNumber, value: x._id }))
                        .filter((x) => x.riderId === null)
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleLinkBillingRecord = async () => {
        setBackdropLoading((x) => x + 1);
        // if (!formData.riderId) {
        //     toast.custom((t) => <CustomToast text="Rider Not Linked To Delivery" t={t} type="error" />, {
        //         position: 'top-right',
        //     });
        //     return;
        // }
        try {
            const payload = {
                _id: formData._id,
                referenceNumber: formData.referenceNumber,
                merchantOrderNo: formData.merchantOrderNo,
            };

            const { data } = await postLinkBillingRecordWithDeliveryRecord(payload);
            if (data?._id) {
                setFormData((prevState) => ({ ...prevState, billingRecordId: data._id }));
                toast.custom((t) => <CustomToast text="Linked Billing Record" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((x) => x - 1);
        loadDeliveryProgressTransactions();
    };

    const refreshAdditionalInfo = async () => {
        if (!formData.pickupLocationMeta || !formData.deliveryAddressMeta) {
            return;
        }

        const { coordinates: pickupCoordinates } = formData.pickupLocationMeta;
        const { coordinates: deliveryCoordinates } = formData.deliveryAddressMeta;

        if (!pickupCoordinates || !deliveryCoordinates) {
            toast.custom((t) => <CustomToast text="Something Went Wrong" t={t} type="error" />, {
                position: 'top-right',
            });
            return;
        }

        const payload = {
            mode: 'car',
            origins: `${pickupCoordinates.lat} , ${pickupCoordinates.lng}`,
            destinations: `${deliveryCoordinates.lat} , ${deliveryCoordinates.lng}`,
        };

        setBackdropLoading((prev) => prev + 1);
        try {
            const { data } = await getDistanceMatrixMeta(payload);
            if (data) {
                const meta = breakDownDistanceMatrixData(data);
                const deliveryPayload = {
                    _id: formData._id,
                    distanceMatrixMeta: meta,
                    distance: meta.distance || null,
                };
                setFormData({ ...formData, ...deliveryPayload });
                await updateDeliveryService(deliveryPayload);
                toast.custom((t) => <CustomToast text="Additional Info Updated" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((prev) => prev - 1);
    };

    const loadAllOrdersByStatusDispatchReady = async () => {
        try {
            const { data } = await getAllOrdersByStatusService('dispatchReady');
            if (data.docs) {
                setDispatchReadyOrders(data.docs.map((x) => ({ ...x, label: x.referenceNumber, value: x._id })));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadAllOrdersByStatusCompleted = async () => {
        try {
            const { data } = await getAllOrdersByStatusService('completed');
            if (data.docs) {
                setCompletedOrders(data.docs.map((x) => ({ ...x, label: x.referenceNumber, value: x._id })));
            }
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Load Profile Image
     * @param {string} riderProfilePictureUrl
     */
    const loadRiderProfileImage = (riderProfilePictureUrl) => {
        getAttachmentByKey(riderProfilePictureUrl)
            .then((res) => {
                if (res) {
                    setImage((prevState) => ({ ...prevState, riderProfilePictureUrl: res }));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const loadCustomerByPhoneNumber = async () => {
        if (formData.isDeliveryStarted) {
            return;
        }

        if (
            formData.recipientMobileNumber &&
            (cleanMobileNo(formData.recipientMobileNumber).length === 11 ||
                cleanMobileNo(formData.recipientMobileNumber).length === 12)
        ) {
            setBackdropLoading((prevState) => prevState + 1);
            try {
                const { data } = await getCustomerByMobileNo(formData.recipientMobileNumber);
                if (data) {
                    setFormData({
                        ...formData,
                        customerId: data._id,
                        recipientEmail: data.email,
                        recipientName: data.name,
                        deliveryAddress: data.address || formData.deliveryAddress || null,
                        deliveryAddressMeta: data.addressMeta || formData.deliveryAddressMeta || null,
                        city: data?.addressMeta?.city,
                        postalCode: data?.addressMeta?.postalCode,
                        state: data?.addressMeta?.state,
                    });
                    toast.custom((t) => <CustomToast text="Updated Customer Details" t={t} type="success" />, {
                        position: 'top-right',
                    });
                } else {
                    toast.custom((t) => <CustomToast text="Customer Details not found" t={t} type="warning" />, {
                        position: 'top-right',
                    });
                }
            } catch (e) {
                console.log(e);
            }
            setBackdropLoading((prevState) => prevState - 1);
        }
    };

    const filterOrdersByVehicle = (newOrders) => {
        let filteredOrders = newOrders;
        if (formData.vehicleType && !formData.riderId) {
            filteredOrders = newOrders.filter((x) => x.vehicleType === formData.vehicleType);
        }
        return filteredOrders;
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleDateChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value ? new Date(value) : null,
        });
    };

    useEffect(() => {
        loadRiders();
    }, []);

    const loadRiders = async () => {
        setModalLoading((prevState) => prevState + 1);
        try {
            if (IS_SUPER_ADMIN()) {
                const { data } = await getAllRidersService();
                if (data.docs) {
                    setClientRiders(data.docs);
                }
            }
        } catch (e) {
            console.log(e);
        }
        setModalLoading((prevState) => prevState - 1);
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);
        if (event) {
            if (id === 'source') {
                const emptiedData = emptyFormData(formData, {
                    ...initialFormValues,
                    merchantId: formData.merchantId,
                    merchantName: formData.merchantName,
                    merchantSetting: formData.merchantSetting,
                    vehicleType: formData.vehicleType,
                    deliveryReadyTime: formData.deliveryReadyTime,
                    isDeliveryReadyDateTime: formData.isDeliveryReadyDateTime,
                });
                setFormData({
                    [id]: event ? event.value : null,
                    ...emptiedData,
                });
                return;
            }
            if (id === 'merchantId') {
                const relevantMerchant = merchants.find((x) => x._id === event.value);
                if (relevantMerchant) {
                    setFormData({
                        ...formData,
                        merchantName: relevantMerchant.name || '',
                        [id]: event ? event.value : null,
                    });
                }
                return;
            }
            if (id === 'vehicleType') {
                setFormData({
                    ...formData,
                    orderId: null,
                    [id]: event ? event.value : null,
                    orderReferenceNumber: null,
                });
                return;
            }
            if (id === 'riderId') {
                const relevantRider = riders.find((x) => x._id === event.value);
                if (relevantRider) {
                    setFormData({
                        ...formData,
                        isAcceptedByRider: false,
                        riderName: relevantRider.fullName || '',
                        riderReference: relevantRider.referenceNumber || '',
                        riderMobileNumber: relevantRider.mobileNo || '',
                        [id]: event ? event.value : null,
                    });
                }
                return;
            }

            // If Order Selected Append Required Order Details to Delivery
            if (id === 'orderId') {
                const relevantOrder = orders.find((x) => x._id === event.value);
                if (relevantOrder) {
                    const {
                        isChill,
                        isFragile,
                        isFrozen,
                        recipientMobileNumber,
                        recipientEmail,
                        recipientName,
                        city,
                        state,
                        postalCode,
                        pickupLocation,
                        pickupLocationMeta,
                        customerId,
                        isFemaleDriver,
                        isHalal,
                        isHighValue,
                        deliveryAddress,
                        deliveryAddressMeta,
                        deliveryState,
                        deliveryCity,
                        deliveryPostalCode,
                        merchantOrderNo,
                        vehicleType,
                    } = relevantOrder;
                    setFormData({
                        ...formData,
                        orderReferenceNumber: relevantOrder.referenceNumber || '',
                        [id]: event ? event.value : null,
                        isChill,
                        isFragile,
                        isFrozen,
                        recipientEmail,
                        recipientName,
                        pickupLocation,
                        isFemaleDriver,
                        isHalal,
                        isHighValue,
                        pickupLocationMeta,
                        pickupLocationCity: city,
                        pickupLocationState: state,
                        pickupLocationPostalCode: postalCode,
                        recipientMobileNumber,
                        customerId,
                        deliveryAddress,
                        merchantOrderNo,
                        deliveryAddressMeta,
                        state: deliveryState,
                        city: deliveryCity,
                        postalCode: deliveryPostalCode,
                        vehicleType,
                    });
                }
                return;
            }
            setFormData({
                ...formData,
                [id]: event ? event.value : null,
            });
        } else {
            if (id === 'riderId') {
                setFormData({
                    ...formData,
                    isAcceptedByRider: false,
                    riderName: null,
                    riderReference: null,
                    riderMobileNumber: null,
                    [id]: null,
                });
                setRiderData({});
                return;
            }
            if (id === 'orderId') {
                const emptiedData = emptyOrderData();
                setFormData({
                    ...formData,
                    [id]: null,
                    ...emptiedData,
                });
                return;
            }
            if (id === 'merchantId') {
                setFormData({
                    ...formData,
                    [id]: null,
                    merchantSetting: null,
                });
                return;
            }

            setFormData({
                ...formData,
                [id]: null,
            });
        }
    };

    function appendAdditionalData(data) {
        let payload = data;

        const userData = getUser();

        if (!userData) {
            return;
        }

        if (editingMode) {
            if (!initialDeliveryData.riderId && data.riderId) {
                payload = {
                    ...payload,
                    assignedById: userData._id,
                    assignedByName: userData.fullName,
                    assignedBy: mapUserObj(userData),
                    riderAssignedAt: new Date(),
                };
            }
        }
        return payload;
    }

    // Update Form Data with data from child components
    const handleUpdateFormData = (data = {}) => {
        setFormData((prevState) => ({ ...prevState, ...data }));
    };

    const handleSubmit = async (event, errors) => {
        if (formLoading) {
            return;
        }

        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.persist();

        const { isFormValid, message } = validateForm(formData);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);

        const payload = appendAdditionalData(formData);

        // delete unnecessary payload data
        delete payload.pickupLocationMeta.geometry;
        delete payload.deliveryAddressMeta.geometry;

        let updatedPayloadData;

        if (payload.recipientMobileNumber) {
            payload.recipientMobileNumber = cleanMobileNo(payload.recipientMobileNumber);
        }

        // Pending Delivery ( Pending Imported Delivery Module's Delivery )
        if (isPendingDelivery) {
            try {
                await updatePendingDelivery(pendingDataId, formData.uuid, formData);
                setEditingMode(true);
                toast.custom((t) => <CustomToast text="Successfully updated delivery" t={t} type="success" />, {
                    position: 'top-right',
                });
                setIsFormSaved(true);
            } catch (e) {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } else {
            try {
                if (editingMode) {
                    const { data } = await updateDeliveryService(payload);
                    if (data) {
                        setFormData({ ...payload, ...data });
                        updatedPayloadData = { ...payload, ...data };
                        setInitialDeliveryData(updatedPayloadData);
                    }
                } else {
                    const { data } = await createDeliveryService(payload);
                    if (data) {
                        // await sendDeliveryProgressMsg(data);
                        setFormData({ ...payload, ...data });
                        updatedPayloadData = { ...payload, ...data };
                        setInitialDeliveryData(updatedPayloadData);
                    }
                }
                setEditingMode(true);
                toast.custom(
                    (t) => (
                        <CustomToast
                            text={`Successfully ${editingMode ? 'updated' : 'created'} Delivery`}
                            t={t}
                            type="success"
                        />
                    ),
                    {
                        position: 'top-right',
                    }
                );
                setIsFormSaved(true);
            } catch (e) {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        }

        if (updatedPayloadData) {
            updateHistoryWithData(updatedPayloadData);
        }

        loadDeliveryProgressTransactions();
        setFormLoading(false);
        setSubmitted(false);
    };

    function updateRiderData(riderUpdatedData) {
        if (riderUpdatedData) {
            setRiderData({ ...riderData, ...riderUpdatedData });
        }
    }

    /**
     * Updates Browser History
     * @param {*} payload
     */
    function updateHistoryWithData(payload) {
        try {
            if (payload?._id) {
                history.replace({
                    pathname: `${PATHS.DELIVERY.FORM}`,
                    search: `?type=edit&id=${payload._id}`,
                    state: {
                        deliveryParentData: payload,
                    },
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleDeleteDelivery = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteDelivery(data._id);
            toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                position: 'top-right',
            });
            history.push({
                pathname: `${PATHS.DELIVERY.DEFAULT}`,
            });
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setShowDeleteModal({ state: false, data: null });
        setBackdropLoading((prevState) => prevState - 1);
    };

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    const getSelectedOrder = (_id) => {
        const relevantOrder = orders.find((x) => x._id === _id);
        if (relevantOrder) {
            return relevantOrder.label;
        }
        return null;
    };

    const handleCheckChange = (event) => {
        setIsFormSaved(false);
        const { id, checked } = event.target;

        // Business Logics
        if (id === 'isDeliveryReadyDateTime') {
            setFormData({
                ...formData,
                [id]: checked,
                deliveryReadyTime: checked ? new Date() : null,
            });
            return;
        }

        setFormData({
            ...formData,
            [id]: checked,
        });
    };

    const returnLocationData = (id, idMeta, data) => {
        let builtData = buildLocationData(id, idMeta, data);
        if (formData?.merchantLocationId) {
            builtData = {
                ...builtData,
                merchantLocationId: formData?.merchantLocationId,
            };
        }
        setFormData({ ...formData, ...builtData });
    };

    const PickupLocationMarker = () => (
        <div>
            <img id="pickup-location-marker" src={MERCHANT_LOCATION_ICON_IMAGE} height={35} width={35} alt="" />
        </div>
    );

    const DeliveryAddressMarker = () => (
        <div>
            <img
                id="delivery-location-marker"
                src={RECIPIENT_DELIVERY_ADDRESS_ICON_IMAGE}
                height={35}
                width={35}
                alt=""
            />
        </div>
    );

    const handleReturnSelectedRider = (selectedRider, allRangedRiders, range) => {
        setRidersSearchedRange(range);
        setAllRidersWithoutFilter(allRangedRiders);
        const filteredRiders = filterRidersBasedByAssigningDeliveryConditions(allRangedRiders, formData);
        setRiders(filteredRiders);
        setFormData({
            ...formData,
            riderId: selectedRider._id,
            riderName: selectedRider.fullName || '',
            riderReference: selectedRider.referenceNumber || '',
            riderMobileNumber: selectedRider.mobileNo || '',
        });
    };

    const handleReturnDefaultRiders = (allRangedRiders, range) => {
        setAllRidersWithoutFilter(allRangedRiders);
        setRidersSearchedRange(range);
        const filteredRiders = filterRidersBasedByAssigningDeliveryConditions(allRangedRiders, formData);
        setRiders(filteredRiders);
    };

    /**
     * Handle Return Active Riders
     * @param {array} activeRiders
     */
    const handleReturnActiveRiders = (activeRiders) => {
        setAllRidersWithoutFilter(activeRiders);
        const filteredRiders = filterRidersBasedByAssigningDeliveryConditions(activeRiders, formData);
        setRiders(filteredRiders);
    };

    const toggleRiderProfileModal = () => {
        setShowRiderProfileModal(!showRiderProfileModal);
    };

    const toggleTrackModal = () => {
        setShowLiveLocationModal(!showLiveLocationModal);
    };

    const handleOnDelete = (e, row) => {
        setShowDeleteModal({ state: true, data: row });
    };

    const toggleSearchRiderModal = () => {
        setShowRiderSelectionModal(!showRiderSelectionModal);
    };

    const getRiderSelect = (_id) => {
        // eslint-disable-next-line no-console
        DEBUG_MODE() && console.log('select-func');
        if (_id) {
            const relevantObj = riders.find((x) => x._id === _id);
            if (relevantObj) {
                return { label: relevantObj.fullName, value: relevantObj._id };
            }
        }
        return null;
    };

    // Riders relevant to the vehicle type
    const relevantRidersList = (vehicleType) => {
        const relevantObj = riders.filter((x) => x.vehicleType === vehicleType);
        const relevantRider = relevantObj.map((rider) => {
            if (rider) {
                return { label: rider.fullName || '', value: rider._id };
            }
            return null;
        });
        return relevantRider;
    };

    // ** Memos

    const selectedRider = useMemo(() => getRiderSelect(formData.riderId), [formData.riderId, riders]);

    const mapDocumentData = useMemo(() => () => <MapDeliveryDocument deliveryData={formData} />, [formData._id]);

    // Map Utilities
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    {!editingMode && <Breadcrumbs breadcrumbItems={breadcrumbItems} />}

                    <Row style={{ display: 'flex', justifyContent: isViewMode ? 'flex-end' : 'space-between' }}>
                        {editingMode && (
                            <div>
                                <DeliveryProgressBar options={formData} />
                            </div>
                        )}
                        <Row style={{ justifyContent: isViewMode ? 'flex-end' : 'space-between' }}>
                            <div>
                                {!editingMode && (
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md ml-3"
                                        onClick={() => {
                                            history.push({
                                                pathname: `${PATHS.DELIVERY.DEFAULT}`,
                                            });
                                        }}
                                    >
                                        <i className="far fa-times-circle mr-2 fa-lg" />
                                        <span>Cancel</span>
                                    </motion.div>
                                )}
                            </div>

                            {editingMode && hasAccess(PERMISSIONS.SHIPPING_LABELS, ['View']) && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius m-2 mb-3 pr-3 pl-3 shadow-lg nw-md mr-3"
                                    id="delivery-shipping-label-btn"
                                    onClick={() =>
                                        history.push({
                                            pathname: `${PATHS.SHIPPING_LABEL.VIEW}`,
                                            search: `?id=${formData.referenceNumber}`,
                                            state: { deliveryParentData: { ...formData } },
                                        })
                                    }
                                >
                                    <i className="fas fa-qrcode"></i> <span className="h6">Shipping Labels</span>
                                </motion.div>
                            )}
                            <div className="row d-flex">
                                {isPendingDelivery && pendingDataId && (
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                        onClick={() =>
                                            history.push({
                                                pathname: `${PATHS.PENDING_DATA.DELIVERY_VIEW}`,
                                                search: `?id=${pendingDataId}`,
                                            })
                                        }
                                    >
                                        <i className="fas fa-chevron-left"></i>{' '}
                                        <span className="h6">Back to Delivery Approval</span>
                                    </motion.div>
                                )}

                                {(editingMode || isViewMode) && (
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        id="delivery-form-back-btn"
                                        className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md mr-4"
                                        onClick={() => {
                                            const formType = qs.parse(props.location.search);
                                            if (formType.type === 'deleted-view') {
                                                history.push({
                                                    pathname: `${PATHS.DELIVERY.DELETED}`,
                                                });
                                            } else {
                                                history.push({
                                                    pathname: `${PATHS.DELIVERY.DEFAULT}`,
                                                });
                                            }
                                        }}
                                    >
                                        <i className="fas fa-arrow-left mr-2 fa-lg" />
                                        Back to Deliveries
                                    </motion.div>
                                )}
                            </div>
                        </Row>
                    </Row>
                    <Row>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10 }}>
                            <FormStatusBtn isFormSaved={isFormSaved} />
                        </div>
                    </Row>

                    {isPendingDelivery && existingPendingData && (
                        <Card style={{ borderRadius: 10 }}>
                            <CardBody>
                                <Row className="d-flex row justify-content-between p-2">
                                    <h3 style={{ fontWeight: 'bold' }}>Existing Data</h3>
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        style={{ cursor: 'pointer' }}
                                        className=" nw-md mr-2"
                                        onClick={() => {
                                            setShowExistingPendingData(!showExistingPendingData);
                                        }}
                                    >
                                        <i
                                            id="excel-config-icon"
                                            className={`${
                                                showExistingPendingData ? 'fas fa-chevron-down' : 'fas fa-chevron-up'
                                            } fa-2x`}
                                            style={{ color: THEME_COLOR }}
                                        />
                                    </motion.div>
                                </Row>
                                <Collapse isOpen={!showExistingPendingData}>
                                    {renderExistingPendingData(formData, existingPendingData)}
                                </Collapse>
                            </CardBody>
                        </Card>
                    )}

                    <ClientAlerts />

                    <Row>
                        <Col md={12} xl={editingMode ? 6 : 12}>
                            <Card className="shadow-lg default-card">
                                <CardBody>
                                    <AvForm
                                        className="needs-validation custom-form default-form"
                                        onSubmit={handleSubmit}
                                    >
                                        <fieldset disabled={formData.isCancelled || formData.isCompleted}>
                                            {!__BLOCKED_FOR_MERCHANTS__ && !visitingMerchant && (
                                                <Row>
                                                    <Col md={editingMode ? 6 : 4}>
                                                        <Label htmlFor="merchantId">Merchant Name</Label>
                                                        <Select
                                                            value={getDefaultValueForSelect(
                                                                getMerchantSelect(formData.merchantId)
                                                            )}
                                                            options={merchants}
                                                            styles={reactSelectCustomStyles}
                                                            onChange={(event) =>
                                                                handleSelectChange(event, 'merchantId')
                                                            }
                                                            submitted={submitted}
                                                            required
                                                            isDisabled={
                                                                __BLOCKED_FOR_MERCHANTS__ ||
                                                                isViewMode ||
                                                                formData.isPickUpStarted ||
                                                                !!visitingMerchant
                                                            }
                                                            validated={validated}
                                                            id="merchantId"
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row>
                                                <Col md={editingMode ? 6 : 4} className="mt-2">
                                                    <Label htmlFor="vehicleType">Vehicle Type</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.vehicleType)}
                                                        options={[
                                                            { label: 'Motorbike', value: VEHICLE_TYPES.MOTOR_BIKE },
                                                            { label: 'Car', value: VEHICLE_TYPES.CAR },
                                                            { label: 'Van - 7FT', value: VEHICLE_TYPES.VAN_SEVEN_FEET },
                                                            { label: 'Truck', value: VEHICLE_TYPES.TRUCK },
                                                        ]}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'vehicleType')}
                                                        submitted={submitted}
                                                        required
                                                        isDisabled={formData.riderId || formData.isPickUpStarted}
                                                        validated={validated}
                                                        id="vehicleType"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <Label htmlFor="name" className="mt-3">
                                                        Delivery Type
                                                    </Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.deliveryType)}
                                                        options={[
                                                            {
                                                                label: DELIVERY_TYPES.NEW_ORDER,
                                                                value: DELIVERY_TYPES.NEW_ORDER,
                                                            },
                                                            {
                                                                label: DELIVERY_TYPES.RETURN_ORDER,
                                                                value: DELIVERY_TYPES.RETURN_ORDER,
                                                            },
                                                            {
                                                                label: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
                                                                value: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
                                                            },
                                                        ]}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'deliveryType')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                        isDisabled={editingMode || isViewMode}
                                                        id="deliveryType"
                                                    />
                                                </Col>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <Label htmlFor="source" className="mt-3">
                                                        Source
                                                    </Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.source)}
                                                        options={[
                                                            { label: 'System Order', value: 'System Order' },
                                                            { label: 'Manual Order', value: 'Manual Order' },
                                                        ]}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'source')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                        isDisabled={editingMode || isViewMode}
                                                        id="source"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup className="mt-3">
                                                        <Col md={12}>
                                                            <Row className="justify-content-between">
                                                                <Label htmlFor="deliveryReadyTime">
                                                                    Delivery Ready Time
                                                                </Label>
                                                                <Row className="mb-1">
                                                                    <div className="row delivery-form__div__delivery-ready-time-check">
                                                                        <Input
                                                                            onChange={handleCheckChange}
                                                                            className="delivery-form__check__delivery-ready-time"
                                                                            type="checkbox"
                                                                            checked={formData.isDeliveryReadyDateTime}
                                                                            defaultChecked={
                                                                                formData.isDeliveryReadyDateTime
                                                                            }
                                                                            id="isDeliveryReadyDateTime"
                                                                            disabled={formData.isPickedUp}
                                                                        />
                                                                        <Label htmlFor="customCheck2">Ready</Label>
                                                                    </div>
                                                                </Row>
                                                            </Row>
                                                        </Col>
                                                        <Input
                                                            onChange={handleDateChange}
                                                            type="datetime-local"
                                                            defaultValue={getDateValue(new Date(), 'datetime-local')}
                                                            className="form-control"
                                                            value={getDateValue(
                                                                formData.deliveryReadyTime,
                                                                'datetime-local'
                                                            )}
                                                            disabled={formData.isPickedUp || isViewMode}
                                                            id="deliveryReadyTime"
                                                            // required={!formData.isDeliveryReadyDateTime}
                                                            // valid={!!formData.isDeliveryReadyDateTime}
                                                            // invalid={
                                                            //     !formData.isDeliveryReadyDateTime &&
                                                            //     !formData.deliveryReadyTime
                                                            // }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Row>
                                                            <div className="form-check form-check-right mb-2 ml-3 mt-3">
                                                                <Input
                                                                    onChange={handleCheckChange}
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={formData.isDeliveryTimeSlot}
                                                                    defaultChecked={formData.isDeliveryTimeSlot}
                                                                    id="isDeliveryTimeSlot"
                                                                    disabled={formData.isPickUpStarted || isViewMode}
                                                                />
                                                                <Label
                                                                    htmlFor="customCheck2"
                                                                    className="form-check-label"
                                                                >
                                                                    Delivery Time Slot
                                                                </Label>
                                                            </div>
                                                        </Row>
                                                        <Input
                                                            onChange={handleInputChange}
                                                            className="form-control"
                                                            type="datetime-local"
                                                            value={getDateValue(
                                                                formData.deliveryTimeSlot,
                                                                'datetime-local'
                                                            )}
                                                            disabled={
                                                                !formData.isDeliveryTimeSlot ||
                                                                formData.isPickUpStarted ||
                                                                isViewMode
                                                            }
                                                            validate={{ required: { value: true } }}
                                                            id="deliveryTimeSlot"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Col md={12}>
                                                            <Row className="justify-content-between">
                                                                <Label htmlFor="deliveryDate">Delivery Date</Label>
                                                            </Row>
                                                        </Col>
                                                        <Input
                                                            onChange={handleDateChange}
                                                            type="date"
                                                            className="form-control"
                                                            value={getDateValue(formData.deliveryDate, 'date')}
                                                            min={new Date().toISOString().split('T')[0]}
                                                            disabled={formData.isPickedUp || isViewMode}
                                                            id="deliveryDate"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    {formData.source === 'Manual Order' ? (
                                                        <>
                                                            <Label htmlFor="merchantOrderNo">
                                                                Merchant Order Number
                                                            </Label>
                                                            <AvField
                                                                name="merchantOrderNo"
                                                                placeholder="Merchant Order No"
                                                                type="text"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{ required: { value: true } }}
                                                                id="merchantOrderNo"
                                                                onChange={handleInputChange}
                                                                disabled={isViewMode}
                                                                value={formData.merchantOrderNo}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            {(formData.isPickedUp || formData.isPickUpStarted) &&
                                                            editingMode ? (
                                                                <>
                                                                    <Label htmlFor="orderId">
                                                                        Scootix Order Number
                                                                    </Label>
                                                                    <AvField
                                                                        name="orderReferenceNumber"
                                                                        placeholder="Scootix Order Number"
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="orderReferenceNumber"
                                                                        disabled
                                                                        value={formData.orderReferenceNumber}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Label htmlFor="orderId">
                                                                        Scootix Order Number
                                                                    </Label>
                                                                    <Select
                                                                        value={getDefaultValueForSelect(
                                                                            getSelectedOrder(formData.orderId)
                                                                        )}
                                                                        options={orders}
                                                                        styles={reactSelectCustomStyles}
                                                                        onChange={(event) =>
                                                                            handleSelectChange(event, 'orderId')
                                                                        }
                                                                        submitted={submitted}
                                                                        required={formData.source === 'System Order'}
                                                                        isDisabled={isViewMode}
                                                                        validated={validated}
                                                                        isClearable
                                                                        id="orderId"
                                                                    />
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </Col>
                                                {formData.source === 'System Order' && (
                                                    <Col md={editingMode ? 6 : 4}>
                                                        <Label htmlFor="merchantOrderNo">Merchant Order Number</Label>
                                                        <AvField
                                                            name="merchantOrderNo"
                                                            placeholder="Merchant Order No"
                                                            type="text"
                                                            className="form-control"
                                                            id="merchantOrderNo"
                                                            onChange={handleInputChange}
                                                            disabled
                                                            value={formData.merchantOrderNo}
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row style={{ alignItems: 'center' }}>
                                                <Col md="6">
                                                    <Row>
                                                        <Col md="10" sm="10" xs="10">
                                                            <FormGroup className="mt-3">
                                                                <Label htmlFor="pickupLocation">Pickup Location</Label>
                                                                <PlacesAutocompleteField
                                                                    addressData={formData.pickupLocationMeta || null}
                                                                    returnLocationData={returnLocationData}
                                                                    id="pickupLocation"
                                                                    idMeta="pickupLocationMeta"
                                                                    isRequired
                                                                    submitted={submitted}
                                                                    validated={validated}
                                                                    isDisabled={
                                                                        (formData.isAcceptedByRider ||
                                                                            formData.isPickUpStarted ||
                                                                            isViewMode) &&
                                                                        !__DEV__
                                                                    }
                                                                    isDiscardPreviousValues
                                                                    predefinedValues={
                                                                        formData.merchantId &&
                                                                        (formData.deliveryType ===
                                                                            DELIVERY_TYPES.NEW_ORDER ||
                                                                            formData.deliveryType ===
                                                                                DELIVERY_TYPES.ON_DEMAND_DELIVERY)
                                                                            ? allMerchantLocations
                                                                            : null
                                                                    }
                                                                />
                                                                {showPickupLocation && !isViewMode && (
                                                                    <PlacesAutocompleteModal
                                                                        show={showPickupLocation}
                                                                        isModal
                                                                        addressData={
                                                                            formData.pickupLocationMeta || null
                                                                        }
                                                                        returnLocationData={returnLocationData}
                                                                        toggle={() =>
                                                                            setShowPickupLocation(!showPickupLocation)
                                                                        }
                                                                        id="pickupLocation"
                                                                        idMeta="pickupLocationMeta"
                                                                        isRequired
                                                                    />
                                                                )}
                                                            </FormGroup>
                                                        </Col>
                                                        {!formData.isAcceptedByRider && !formData.isPickedUp && (
                                                            <Col md="2" sm="2" xs="2">
                                                                <motion.div
                                                                    style={{ width: 35, height: 35, paddingRight: 27 }}
                                                                    whileHover={{ scale: 1.1 }}
                                                                    whileTap={{ scale: 0.9 }}
                                                                    className="scootix-btn-circle shadow-lg nw-md ml-3 delivery-map-pin-container-two form-field-icon-delivery"
                                                                    onClick={() =>
                                                                        setShowPickupLocation(!showPickupLocation)
                                                                    }
                                                                >
                                                                    <div className="map-pin-holder">
                                                                        <i
                                                                            className="ri-map-pin-line fa-lg"
                                                                            style={{ marginRight: 5 }}
                                                                        />
                                                                    </div>
                                                                </motion.div>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row style={{ alignItems: 'center' }}>
                                                <Col md={editingMode ? 4 : 3}>
                                                    <FormGroup>
                                                        <Label htmlFor="pickupLocationPostalCode">
                                                            Pickup Postal Code
                                                        </Label>
                                                        <AvField
                                                            name="pickupLocationPostalCode"
                                                            placeholder="Pickup Postal Code"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="pickupLocationPostalCode"
                                                            value={formData.pickupLocationPostalCode}
                                                            type="Number"
                                                            minLength={5}
                                                            maxLength={5}
                                                            disabled={isViewMode}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3">
                                                    <FormGroup>
                                                        <Label htmlFor="pickupLocationCity">Pickup City</Label>
                                                        <AvField
                                                            name="pickupLocationCity"
                                                            placeholder="Pickup City"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            // validate={{ required: { value: true } }}
                                                            id="pickupLocationCity"
                                                            value={formData.pickupLocationCity}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3">
                                                    <FormGroup>
                                                        <Label htmlFor="pickupLocationState">Pickup State</Label>
                                                        <AvField
                                                            name="pickupLocationState"
                                                            placeholder="Pickup State"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            // validate={{ required: { value: true } }}
                                                            id="pickupLocationState"
                                                            value={formData.pickupLocationState}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ alignItems: 'center' }}>
                                                <Col md={editingMode ? 4 : 3}>
                                                    <FormGroup>
                                                        <Label htmlFor="pickupContactPersonName">
                                                            Pickup Point Contact Person
                                                        </Label>
                                                        <AvField
                                                            name="pickupContactPersonName"
                                                            placeholder="Pickup Point Contact Person"
                                                            className="form-control"
                                                            id="pickupContactPersonName"
                                                            value={formData.pickupContactPersonName}
                                                            type="text"
                                                            disabled={isViewMode}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3">
                                                    <FormGroup>
                                                        <Label htmlFor="pickupContactPersonContactNo">
                                                            Contact Number
                                                        </Label>
                                                        <AvField
                                                            name="pickupContactPersonContactNo"
                                                            placeholder="Contact Number"
                                                            type="text"
                                                            className="form-control"
                                                            id="pickupContactPersonContactNo"
                                                            value={formData.pickupContactPersonContactNo}
                                                            disabled={isViewMode}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <Row>
                                                        <Col md="10" sm="10" xs="10">
                                                            <FormGroup>
                                                                <Label htmlFor="recipientMobileNumber">
                                                                    Recipient Mobile Number
                                                                </Label>
                                                                <AvField
                                                                    name="recipientMobileNumber"
                                                                    placeholder="Mobile No"
                                                                    className="form-control"
                                                                    id="recipientMobileNumber"
                                                                    value={formData.recipientMobileNumber}
                                                                    mask="+60 99-999 99999"
                                                                    maskChar=""
                                                                    disabled={isViewMode || formData.isDeliveryStarted}
                                                                    onChange={handleInputChange}
                                                                    tag={[Input, InputMask]}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="2" sm="2" xs="2">
                                                            <motion.div
                                                                style={{ width: 35, height: 35 }}
                                                                whileHover={{ scale: 1.1 }}
                                                                whileTap={{ scale: 0.9 }}
                                                                className="scootix-btn-circle shadow-lg nw-md ml-3 delivery-contact-refresh-container form-field-icon-delivery"
                                                                onClick={() => loadCustomerByPhoneNumber()}
                                                            >
                                                                <div className="map-pin">
                                                                    <i className="fas fa-sync-alt fa-lg" />
                                                                </div>
                                                            </motion.div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="recipientName">Recipient Name</Label>
                                                        <AvField
                                                            name="recipientName"
                                                            placeholder="Name"
                                                            type="text"
                                                            className="form-control"
                                                            id="recipientName"
                                                            disabled={isViewMode}
                                                            onChange={handleInputChange}
                                                            value={formData.recipientName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="recipientEmail">Recipient E-Mail</Label>
                                                        <AvField
                                                            name="recipientEmail"
                                                            placeholder="E-Mail"
                                                            type="email"
                                                            className="form-control"
                                                            id="recipientEmail"
                                                            onChange={handleInputChange}
                                                            disabled={isViewMode}
                                                            value={formData.recipientEmail}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 8 : 6}>
                                                    <Row>
                                                        <Col md="10" sm="10" xs="10">
                                                            <FormGroup>
                                                                <Label htmlFor="deliveryAddress">
                                                                    Delivery Address
                                                                </Label>
                                                                <PlacesAutocompleteField
                                                                    addressData={formData.deliveryAddressMeta || null}
                                                                    returnLocationData={returnLocationData}
                                                                    id="deliveryAddress"
                                                                    idMeta="deliveryAddressMeta"
                                                                    isRequired
                                                                    submitted={submitted}
                                                                    validated={validated}
                                                                    isDisabled={
                                                                        formData.isDeliveryStarted ||
                                                                        formData.isDelivered ||
                                                                        isViewMode
                                                                    }
                                                                    isDiscardPreviousValues
                                                                    predefinedValues={
                                                                        formData.deliveryType === 'Return Order'
                                                                            ? allMerchantLocations
                                                                            : null
                                                                    }
                                                                />
                                                                {showDeliveryLocation && !isViewMode && (
                                                                    <PlacesAutocompleteModal
                                                                        show={showDeliveryLocation}
                                                                        isModal
                                                                        addressData={
                                                                            formData.deliveryAddressMeta || null
                                                                        }
                                                                        returnLocationData={returnLocationData}
                                                                        toggle={() =>
                                                                            setShowDeliveryLocation(
                                                                                !showDeliveryLocation
                                                                            )
                                                                        }
                                                                        id="deliveryAddress"
                                                                        idMeta="deliveryAddressMeta"
                                                                    />
                                                                )}
                                                            </FormGroup>
                                                        </Col>
                                                        {!formData.isAcceptedByRider && !formData.isDelivered && (
                                                            <Col md="2" sm="2" xs="2">
                                                                <motion.div
                                                                    style={{ width: 35, height: 35 }}
                                                                    whileHover={{ scale: 1.1 }}
                                                                    whileTap={{ scale: 0.9 }}
                                                                    className="scootix-btn-circle shadow-lg nw-md ml-3 delivery-map-pin-container form-field-icon-delivery"
                                                                    onClick={() =>
                                                                        setShowDeliveryLocation(!showDeliveryLocation)
                                                                    }
                                                                >
                                                                    <div className="map-pin">
                                                                        <i className="ri-map-pin-line fa-lg" />
                                                                    </div>
                                                                </motion.div>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row style={{ alignItems: 'center' }}>
                                                <Col md={editingMode ? 4 : 3}>
                                                    <FormGroup>
                                                        <Label htmlFor="postalCode">Postal Code</Label>
                                                        <AvField
                                                            name="postalCode"
                                                            placeholder="Postal Code"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="postalCode"
                                                            value={formData.postalCode}
                                                            type="Number"
                                                            disabled={isViewMode}
                                                            minLength={5}
                                                            maxLength={5}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3">
                                                    <FormGroup>
                                                        <Label htmlFor="city">City</Label>
                                                        <AvField
                                                            name="city"
                                                            placeholder="City"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            // validate={{ required: { value: true } }}
                                                            id="city"
                                                            value={formData.city}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3">
                                                    <FormGroup>
                                                        <Label htmlFor="state">State</Label>
                                                        <AvField
                                                            name="state"
                                                            placeholder="State"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            // validate={{ required: { value: true } }}
                                                            id="state"
                                                            value={formData.state}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={editingMode ? 6 : 3}>
                                                    <Label htmlFor="specialCare">Special Care</Label>
                                                    <AvCheckboxGroup name="specialCare" className="form-check-group">
                                                        <AvCheckbox
                                                            label="Fragile"
                                                            checked={formData.isFragile}
                                                            id="isFragile"
                                                            onChange={handleCheckChange}
                                                        />
                                                        <AvCheckbox
                                                            label="Frozen"
                                                            checked={formData.isFrozen}
                                                            id="isFrozen"
                                                            onChange={handleCheckChange}
                                                        />
                                                        <AvCheckbox
                                                            label="High Value"
                                                            checked={formData.isHighValue}
                                                            id="isHighValue"
                                                            onChange={handleCheckChange}
                                                        />
                                                        <AvCheckbox
                                                            label="Chill"
                                                            checked={formData.isChill}
                                                            id="isChill"
                                                            onChange={handleCheckChange}
                                                        />
                                                    </AvCheckboxGroup>
                                                </Col>
                                                <Col md={editingMode ? 6 : 3}>
                                                    <Label htmlFor="specialConditions">Special Conditions</Label>
                                                    <AvCheckboxGroup
                                                        name="specialConditions"
                                                        className="form-check-group"
                                                    >
                                                        <AvCheckbox
                                                            label="Halal"
                                                            checked={formData.isHalal}
                                                            id="isHalal"
                                                            onChange={handleCheckChange}
                                                        />
                                                        <AvCheckbox
                                                            label="Deliver by Female Rider"
                                                            checked={formData.isFemaleDriver}
                                                            id="isFemaleDriver"
                                                            onChange={handleCheckChange}
                                                        />
                                                    </AvCheckboxGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="deliveryNotes">Delivery Notes</Label>
                                                        <AvField
                                                            name="deliveryNotes"
                                                            placeholder="Notes"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            id="deliveryNotes"
                                                            disabled={isViewMode}
                                                            onChange={handleInputChange}
                                                            value={formData.deliveryNotes}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                {formData.merchantId && (
                                                    <Col md={editingMode ? 6 : 4}>
                                                        <Row>
                                                            <Col md="10" sm="10" xs="10">
                                                                <FormGroup>
                                                                    <Label htmlFor="name">
                                                                        Assign a Rider / Driver{' '}
                                                                        {ridersSearchedRange
                                                                            ? `( ${ridersSearchedRange} KM Radius )`
                                                                            : ''}
                                                                    </Label>

                                                                    {formData.routePlanningId ||
                                                                    formData.isAcceptedByRider ||
                                                                    formData.isDeliveryStarted ||
                                                                    formData.isPickUpStarted ? (
                                                                        <AvField
                                                                            name="riderName"
                                                                            placeholder="Rider Name"
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="riderName"
                                                                            disabled
                                                                            value={riderData.fullName}
                                                                        />
                                                                    ) : (
                                                                        <Select
                                                                            value={selectedRider}
                                                                            options={relevantRidersList(
                                                                                formData.vehicleType
                                                                            )}
                                                                            styles={reactSelectCustomStyles}
                                                                            onChange={(event) =>
                                                                                handleSelectChange(event, 'riderId')
                                                                            }
                                                                            isClearable
                                                                            submitted={submitted}
                                                                            validated={validated}
                                                                            isDisabled={
                                                                                formData.isAcceptedByRider || isViewMode
                                                                            }
                                                                            id="riderId"
                                                                        />
                                                                    )}
                                                                </FormGroup>
                                                                {/* linked with route plan icon */}
                                                                {formData.routePlanningId && (
                                                                    <motion.div
                                                                        className="scootix-form-btn shadow-lg p-1 rounded"
                                                                        style={{ width: 230, height: 35 }}
                                                                        onClick={() => {
                                                                            window.open(
                                                                                `/${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_ENTRY_VIEW}?type=edit&id=${formData.routePlanningId}`,
                                                                                '_blank'
                                                                            );
                                                                        }}
                                                                    >
                                                                        <div className="d-flex align-items-center d-flex justify-content-around">
                                                                            <span className="h6 pt-1 mb-0">
                                                                                LINKED WITH A ROUTE PLAN
                                                                            </span>
                                                                            <LinkedWithRoutePlan
                                                                                width={16}
                                                                                height={16}
                                                                            />
                                                                        </div>
                                                                    </motion.div>
                                                                )}
                                                            </Col>
                                                            {!formData.isAcceptedByRider && !formData.isPickUpStarted && (
                                                                <Col md="2" sm="2" xs="2">
                                                                    <motion.div
                                                                        style={{ width: 35, height: 35 }}
                                                                        whileHover={{ scale: 1.1 }}
                                                                        whileTap={{ scale: 0.9 }}
                                                                        className="scootix-btn-circle shadow-lg nw-md ml-3 delivery-search-pin-container form-field-icon-delivery"
                                                                        onClick={() => toggleSearchRiderModal()}
                                                                    >
                                                                        <div className="map-pin ">
                                                                            <i className="fas fa-search fa-lg" />
                                                                        </div>
                                                                    </motion.div>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </Col>
                                                )}
                                                <Col md={editingMode ? 6 : 4}></Col>
                                            </Row>
                                            <Row style={{ justifyContent: 'flex-start' }}>
                                                {showSaveBtn && !isViewMode && (
                                                    <motion.button
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                        type="submit"
                                                        disabled={formLoading}
                                                    >
                                                        {formLoading ? (
                                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                        ) : (
                                                            <>
                                                                <i className="fas fa-save left-icon"></i>
                                                                <span className="h6">
                                                                    {editingMode ? 'Update' : 'Save'}
                                                                </span>
                                                            </>
                                                        )}
                                                    </motion.button>
                                                )}
                                                {formData.status === 'dispatched' &&
                                                    !formData.isDeleted &&
                                                    editingMode &&
                                                    hasAccess(PERMISSIONS.DELIVERY, ['Delete']) && (
                                                        <motion.button
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.9 }}
                                                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                            onClick={(e) => !formLoading && handleOnDelete(e, formData)}
                                                            disabled={formLoading}
                                                            type="button"
                                                        >
                                                            {formLoading ? (
                                                                <Spinner
                                                                    className="mr-4 ml-4 0"
                                                                    color="info"
                                                                    size="sm"
                                                                />
                                                            ) : (
                                                                <>
                                                                    <i className="mdi mdi-trash-can left-icon"></i>
                                                                    <span className="h6">Delete</span>
                                                                </>
                                                            )}
                                                        </motion.button>
                                                    )}
                                                {!isViewMode && (
                                                    <motion.div
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                                        onClick={() => {
                                                            if (isPendingDelivery) {
                                                                history.push({
                                                                    pathname: `${PATHS.PENDING_DATA.DELIVERY_VIEW}`,
                                                                    search: `?id=${pendingDataId}`,
                                                                });
                                                            } else {
                                                                history.push({
                                                                    pathname: PATHS.DELIVERY.DEFAULT,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <i className="far fa-times-circle mr-2 fa-lg" />
                                                        <span>Cancel</span>
                                                    </motion.div>
                                                )}
                                            </Row>
                                        </fieldset>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                        {editingMode && (
                            <Col md={6}>
                                {riderData._id && (
                                    <Card className="shadow-lg default-card">
                                        <CardBody>
                                            <Row
                                                style={{
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                <h4>Rider</h4>
                                                <Row
                                                    style={{
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <motion.div
                                                        style={{ width: 35, height: 35, marginRight: 8 }}
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        className="scootix-btn-circle shadow-lg nw-md ml-3 form-field-icon-delivery"
                                                        onClick={() => setShowRiderCallModal(!showRiderCallModal)}
                                                    >
                                                        <div className="map-pin">
                                                            <i className="fas fa-phone fa-lg" />
                                                        </div>
                                                    </motion.div>
                                                    <motion.button
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        onClick={toggleRiderProfileModal}
                                                        className="scootix-form-btn shadow-lg nw-md pr-4 pl-4"
                                                        type="submit"
                                                        disabled={formLoading}
                                                    >
                                                        <>
                                                            <i className="fas fa-save left-icon"></i>
                                                            <span className="h6">View Profile</span>
                                                        </>
                                                    </motion.button>
                                                </Row>
                                            </Row>
                                            <hr />
                                            <div className="media">
                                                <Avatar
                                                    className="image-upload-avatar"
                                                    name={riderData.fullName}
                                                    size="70"
                                                    round="50px"
                                                    src={image.riderProfilePictureUrl}
                                                />
                                                <Media body className="chat-user-box overflow-hidden ml-2">
                                                    <p className="user-title m-0">{riderData.fullName || ''}</p>
                                                    <p className="user-title m-0">{riderData.mobileNo || ''}</p>
                                                    <p
                                                        className="text-muted text-truncate"
                                                        style={{
                                                            backgroundColor: '#FFEDB7',
                                                            padding: 5,
                                                            minWidth: 80,
                                                            maxWidth: 150,
                                                            borderRadius: 12,
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {(riderData.currentTasks || []).length > 0 ? 'Busy' : 'Idle'}
                                                    </p>
                                                </Media>
                                            </div>
                                        </CardBody>
                                    </Card>
                                )}
                                <Card className="shadow-lg default-card">
                                    <CardBody>
                                        <Row
                                            style={{
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: 0,
                                                margin: 0,
                                            }}
                                        >
                                            <h4>Location</h4>
                                            {formData.isPickUpStarted && !formData.isDelivered && (
                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="scootix-form-btn shadow-lg nw-md pr-4 pl-4"
                                                    type="submit"
                                                    onClick={toggleTrackModal}
                                                    disabled={formLoading}
                                                >
                                                    <>
                                                        <i className="fas fa-save left-icon"></i>
                                                        <span className="h6">Trace Live Location</span>
                                                    </>
                                                </motion.button>
                                            )}
                                        </Row>
                                        <hr />
                                        <div style={{ height: '30vh', width: '100%' }}>
                                            {formData.pickupLocationMeta?.coordinates?.lat &&
                                                formData.pickupLocationMeta?.coordinates?.lng && (
                                                    <GoogleMapReact
                                                        yesIWantToUseGoogleMapApiInternals
                                                        // onGoogleApiLoaded={({ map, maps }) => {
                                                        //     setMapData({ map, maps, mapLoaded: true });
                                                        // }}
                                                        defaultCenter={{
                                                            lat: formData.pickupLocationMeta.coordinates.lat,
                                                            lng: formData.pickupLocationMeta.coordinates.lng,
                                                        }}
                                                        defaultZoom={15}
                                                    >
                                                        {/* {mapData.mapLoaded && (
                                                            <Polyline
                                                                map={mapData.map}
                                                                maps={mapData.maps}
                                                                origin={formData.pickupLocationMeta.coordinates}
                                                                destination={formData.deliveryAddressMeta.coordinates}
                                                            />
                                                        )} */}
                                                        {formData.pickupLocationMeta.coordinates.lat &&
                                                            formData.pickupLocationMeta.coordinates.lng && (
                                                                <PickupLocationMarker
                                                                    lat={formData.pickupLocationMeta.coordinates.lat}
                                                                    lng={formData.pickupLocationMeta.coordinates.lng}
                                                                    text="Pickup Location"
                                                                />
                                                            )}
                                                        {formData.deliveryAddressMeta?.coordinates?.lat &&
                                                            formData.deliveryAddressMeta?.coordinates?.lng && (
                                                                <DeliveryAddressMarker
                                                                    lat={formData.deliveryAddressMeta.coordinates.lat}
                                                                    lng={formData.deliveryAddressMeta.coordinates.lng}
                                                                    text="Delivery Address"
                                                                />
                                                            )}
                                                    </GoogleMapReact>
                                                )}
                                        </div>
                                        <div className="container">
                                            <h6 className="delivery-view-map-locations-title font-weight-bold mt-3">
                                                Pick Up Location
                                            </h6>
                                            <Row
                                                style={{
                                                    alignItems: 'center',
                                                    marginLeft: -15,
                                                    marginBottom: 20,
                                                    marginTop: 10,
                                                }}
                                            >
                                                <Col md={1} xs={1} className="delivery-view-map-locations-pin">
                                                    {/* <i className="ri-map-pin-line text-warning fa-2x" /> */}
                                                    <img
                                                        id="pickup-location-marker"
                                                        src={MERCHANT_LOCATION_ICON_IMAGE}
                                                        height={25}
                                                        width={25}
                                                        alt=""
                                                    />
                                                </Col>
                                                <Col md={11} xs={11}>
                                                    <span
                                                        className="h6 m-0 delivery-form-pickup-location-desc"
                                                        style={{ marginLeft: -30 }}
                                                    >
                                                        {formData.pickupLocation && formData.pickupLocationMeta
                                                            ? formData.pickupLocation
                                                            : ''}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <h6 className="delivery-view-map-locations-title font-weight-bold mt-2">
                                                Delivery Location
                                            </h6>
                                            <Row
                                                style={{
                                                    alignItems: 'center',
                                                    marginLeft: -15,
                                                    marginBottom: 15,
                                                    marginTop: 10,
                                                }}
                                            >
                                                <Col md={1} xs={1} className="delivery-view-map-locations-pin">
                                                    {/* <i className="ri-map-pin-line text-warning fa-2x" /> */}
                                                    <img
                                                        id="delivery-location-marker-desc"
                                                        src={RECIPIENT_DELIVERY_ADDRESS_ICON_IMAGE}
                                                        height={25}
                                                        width={25}
                                                        alt=""
                                                    />
                                                </Col>
                                                <Col md={11} xs={11}>
                                                    <span className="h6 m-0 delivery-form-delivery-address-desc">
                                                        {formData.deliveryAddress && formData.deliveryAddressMeta
                                                            ? formData.deliveryAddress
                                                            : ''}
                                                    </span>
                                                </Col>
                                            </Row>
                                            {formData.pickupLocationMeta && formData.deliveryAddressMeta && (
                                                <>
                                                    <Row style={{ justifyContent: 'space-between', marginBottom: 10 }}>
                                                        <h6 className="delivery-view-map-locations-title font-weight-bold mt-3 ml-2">
                                                            Additional Info
                                                        </h6>
                                                        {!formData.isPickedUp && (
                                                            <motion.div
                                                                style={{ width: 35, height: 35 }}
                                                                whileHover={{ scale: 1.1 }}
                                                                whileTap={{ scale: 0.9 }}
                                                                className="scootix-btn-circle-np shadow-lg nw-md delivery-additional-info-icon-container"
                                                                onClick={() => refreshAdditionalInfo()}
                                                            >
                                                                <i className="fas fa-sync-alt fa-lg delivery-additional-info-icon" />
                                                            </motion.div>
                                                        )}
                                                    </Row>
                                                    <Row
                                                        style={{
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Col md={4} xs={6}>
                                                            Total Distance :
                                                            <span className="h6 ml-1 mt-2">
                                                                {formData?.distanceMatrixMeta?.baseDistance
                                                                    ? formData?.distanceMatrixMeta?.baseDistance?.text
                                                                    : 'Unknown'}
                                                            </span>
                                                        </Col>
                                                        <Col md={4} xs={6}>
                                                            Avg. Time :{' '}
                                                            <span className="h6 ml-1 mt-2">
                                                                {formData?.distanceMatrixMeta?.baseDuration
                                                                    ? formData?.distanceMatrixMeta?.baseDuration?.text
                                                                    : 'Unknown'}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                                {deliveryProgressHistory.length > 0 && (
                                    <DeliveryProgressComponent deliveryProgressHistory={deliveryProgressHistory} />
                                )}
                                {editingMode && podAttachment && <PodComponent setShowPreview={setShowPreview} />}
                            </Col>
                        )}
                    </Row>
                    <br />
                    {/** DELIVERY ADVANCED SECTION */}
                    {editingMode && IS_SUPER_ADMIN() && (
                        <AdvanceOptionsComponent
                            formData={formData}
                            handleLinkBillingRecord={handleLinkBillingRecord}
                            mapDocumentData={mapDocumentData}
                        />
                    )}

                    {formData._id && (
                        <DeliveryInformation
                            merchantOrder={formData.merchantOrderNo}
                            deliveryId={formData._id}
                            riderId={formData.riderId}
                            createBillingRecord={handleLinkBillingRecord}
                            deliveryTransactions={deliveryProgressHistory}
                            deliveryAttachments={deliveryAttachmentHistory}
                            data={formData}
                            loadTransactions={loadDeliveryProgressTransactions}
                            loadAttachments={loadDeliveryAttachments}
                            setDeliveryFormData={handleUpdateFormData}
                            setIsViewMode={setIsViewMode}
                            updateHistoryWithData={updateHistoryWithData}
                            updateRiderData={updateRiderData}
                        />
                    )}

                    <br />
                </Container>
            </div>
            {editingMode && showRiderProfileModal && formData.riderId && (
                <RiderProfileModal riderData={riderData} toggleModal={toggleRiderProfileModal} />
            )}
            {editingMode && showLiveLocationModal && formData.riderId && socket && riderData._id && (
                <TrackDeliveryModal
                    deliveryId={formData._id}
                    riderData={riderData}
                    pickupLocationMeta={formData.pickupLocationMeta}
                    deliveryAddressMeta={formData.deliveryAddressMeta}
                    toggleModal={toggleTrackModal}
                    clientRiders={clientRiders}
                    socket={socket}
                />
            )}
            <AttachmentPreviewModal
                image={podAttachment}
                show={showPreview}
                type="default"
                toggleModal={() => {
                    setShowPreview(false);
                }}
            />
            <AlertModal
                show={showDeleteModal.state}
                onConfirm={(data) => handleDeleteDelivery(data)}
                data={showDeleteModal.data}
                onHide={() =>
                    setShowDeleteModal({
                        state: false,
                        data: null,
                    })
                }
            />
            <ScootixModal
                show={showRiderCallModal}
                title="Rider Mobile Number"
                toggleModal={() => setShowRiderCallModal(false)}
                phoneNo={riderData.mobileNo}
                message="Click on the number given below to call the assigned rider"
                removeBtns
                modalLoading={modalLoading}
            />
            {formData.merchantId && (
                <RiderSelectionModal
                    toggleModal={toggleSearchRiderModal}
                    socket={socket}
                    riderId={formData.riderId}
                    pickupLocationMeta={formData.pickupLocationMeta}
                    showRiderSelectionModal={showRiderSelectionModal}
                    handleReturnSelectedRider={handleReturnSelectedRider}
                    handleReturnDefaultRiders={handleReturnDefaultRiders}
                    deliveryData={formData}
                    handleReturnActiveRiders={handleReturnActiveRiders}
                />
            )}

            {isDataConflict && <DocumentUpdatedModal type="deliveryForm" />}

            <BackdropLoader show={backdropLoading} opacity={0.8} />

            {/* <DeliveryTransactionModal toggleModal={toggleTransactionModal} {...transactionModalData} /> */}
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

DeliveryFormPage.propTypes = {
    location: PropTypes.any,
    socket: PropTypes.any,
};

const HotDeliveryFormPage = hot(DeliveryFormPage);

export default connect(mapStateToProps, {})(HotDeliveryFormPage);
