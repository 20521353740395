import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Label } from 'reactstrap';
import { motion } from 'framer-motion';

// #region components | assets
import { Search as SearchIcon } from 'components/Icons';

// #endregion imports
import SearchInput from '../TableHeaderComponents/TableSearch';

function PartnerApiSandboxAccountListViewHeader(props) {
    const { isManualSearch, onFilter, isSearch, setSearch, search, customHeaderProps } = props;

    const { requestStatus, handleCheck: handleCheckProp, handleHeaderFilter } = customHeaderProps;

    const handleCheck = (event, statusValue) => {
        handleCheckProp(event, statusValue);
    };

    return (
        <div className="table-top-row align-items-center justify-content-start">
            {isSearch && (
                <Col xs="auto" className="pl-0">
                    <SearchInput
                        onFilter={onFilter}
                        isManualSearch={isManualSearch}
                        setSearch={setSearch}
                        search={search}
                        searchPlaceholder="Type Merchant ID or Name to search"
                    />
                </Col>
            )}

            <Col xs="auto" className="ml-5">
                <Input
                    type="checkbox"
                    name="requestStatus"
                    checked={requestStatus.includes('pending')}
                    onChange={(event) => handleCheck(event, 'pending')}
                />
                <Label htmlFor="requestStatus">Action Pending</Label>
            </Col>
            <Col xs="auto" className="ml-5">
                <Input
                    type="checkbox"
                    name="requestStatus"
                    checked={requestStatus.includes('completed')}
                    onChange={(event) => handleCheck(event, 'completed')}
                />
                <Label htmlFor="requestStatus">Completed Requests</Label>
            </Col>
            <Col xs="auto" className="ml-5">
                <Input
                    type="checkbox"
                    name="requestStatus"
                    checked={requestStatus.includes('rejected')}
                    onChange={(event) => handleCheck(event, 'rejected')}
                />
                <Label htmlFor="requestStatus">Rejected Requests</Label>
            </Col>
            <Col xs="auto" className="ml-5">
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="scootix-btn-radius"
                    style={{ borderRadius: 10 }}
                    onClick={() => handleHeaderFilter()}
                >
                    <SearchIcon />
                    &nbsp;Filter Requests
                </motion.div>
            </Col>
        </div>
    );
}

PartnerApiSandboxAccountListViewHeader.propTypes = {
    isManualSearch: PropTypes.bool,
    onFilter: PropTypes.func,
    isSearch: PropTypes.bool,
    setSearch: PropTypes.func,
    search: PropTypes.object,
    customHeaderProps: PropTypes.object,
};

export default PartnerApiSandboxAccountListViewHeader;
