/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, CardBody, CardHeader, Col, Modal, Row } from 'reactstrap';

// #region components
import CheckCircleIcon from 'components/Icons/Check-Circle';
import CrossCircleIcon from 'components/Icons/CrossCircle';
import { THEME_COLOR_2 } from 'theme';
import { WarningIcon } from 'components/Icons';

/** This is a general modals which prompts the warning for and showing passed header text and warning text */
function ConfirmationModal(props) {
    const { show, toggleModal, title, text, onConfirm, isStartOver = false } = props;

    const handleModalToggle = () => {
        toggleModal();
    };

    return (
        <Modal className="modal-dialog modal-dialog-centered" isOpen={show}>
            <CardHeader
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: THEME_COLOR_2,
                    borderTopLeftRadius: '7px',
                    borderTopRightRadius: '7px',
                }}
            >
                <div className="d-flex text-white">{title}</div>
                <div className="ml-2 cursor-pointer" onClick={handleModalToggle} role="button">
                    <CrossCircleIcon color="#FFF" />
                </div>
            </CardHeader>
            <CardBody>
                {isStartOver ? (
                    <Row>
                        <Col>
                            <Alert
                                color="warning"
                                style={{
                                    color: 'black',
                                    borderRadius: 8,
                                    fontSize: '120%',
                                    fontWeight: 'bolder',
                                    borderWidth: '1px',
                                    border: 'solid',
                                    borderColor: '#FF9300',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Row>
                                    <Col sm={2}>
                                        <div>
                                            <WarningIcon color="#FD4A4A" />
                                        </div>
                                    </Col>
                                    <Col sm={10}>{text}</Col>
                                </Row>
                            </Alert>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col>
                            <Alert
                                color="warning"
                                style={{
                                    color: 'black',
                                    borderRadius: 8,
                                }}
                            >
                                <Row>
                                    <Col sm={10}>{text}</Col>
                                </Row>
                            </Alert>
                        </Col>
                    </Row>
                )}

                <Row className="justify-content-center">
                    <Button
                        style={{ borderRadius: 10, marginRight: 20 }}
                        className="scootix-btn-radius"
                        onClick={() => {
                            if (onConfirm) {
                                onConfirm();
                            }
                        }}
                    >
                        <CheckCircleIcon color="#FFF" />
                        &nbsp;{isStartOver ? 'CONTINUE' : 'CONFIRM'}
                    </Button>
                    <Button
                        style={{ backgroundColor: '#FD4A4A', borderRadius: 10 }}
                        className="form-delete-btn"
                        onClick={handleModalToggle}
                    >
                        <CrossCircleIcon color="#FFF" />
                        &nbsp;CANCEL
                    </Button>
                </Row>
            </CardBody>
        </Modal>
    );
}
ConfirmationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    isStartOver: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default ConfirmationModal;
