import React from 'react';
import toast from 'react-hot-toast';

// #region imports
import { CustomToast } from 'components';
import { createSystemDataService } from 'services/system-data.service';
import { apiErrorHandler } from 'utils/helpers';
import { SYSTEM_DATA_TYPES } from 'constants/enums';

export const handleCheckChange = (event, setIsFormSaved, setFormData, formData) => {
    setIsFormSaved(false);
    const { id, checked } = event.target;

    if (id === 'isChill') {
        if (formData.isFrozen) {
            setFormData({
                ...formData,
                isFrozen: false,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: checked,
            });
        }

        return;
    }

    if (id === 'isFrozen') {
        if (formData.isChill) {
            setFormData({
                ...formData,
                isChill: false,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: checked,
            });
        }

        return;
    }

    setFormData({
        ...formData,
        [id]: checked,
    });
};

/**
 * Handle Select Change
 */
export const handleSelectChange = (event, id, setIsFormSaved, merchants, setFormData, formData) => {
    setIsFormSaved(false);
    if (event) {
        if (id === 'merchantId' && event) {
            const relevantMerchant = merchants.find((x) => x._id === event.value);
            if (relevantMerchant) {
                setFormData({
                    ...formData,
                    merchantName: relevantMerchant.name || '',
                    [id]: event ? event.value : null,
                });
            }
            return;
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    } else {
        if (id === 'merchantId') {
            setFormData({
                ...formData,
                merchantName: null,
                [id]: null,
            });
            return;
        }
        setFormData({
            ...formData,
            [id]: null,
        });
    }
};

export const getProductDescriptionSelect = (value, array) => {
    if (value) {
        const relevantObj = array.find((x) => x.value === value);
        if (relevantObj) {
            return relevantObj.name;
        }
    }
    return null;
};

/**
 * Handle Create Change
 */
export const handleCreateSelectChange = async (
    record,
    id,
    setIsFormSaved,
    setCustomLoading,
    setStocksFrom,
    stocksFrom,
    setFormData,
    formData
) => {
    setIsFormSaved(false);
    if (record) {
        if (id === 'stocksFrom') {
            setCustomLoading((prev) => ({ ...prev, stocksFrom: true }));
            try {
                const { data } = await createSystemDataService({
                    type: SYSTEM_DATA_TYPES.STOCKS_RECEIVING.TYPES.STOCKS_FROM,
                    module: SYSTEM_DATA_TYPES.STOCKS_RECEIVING.MODULE,
                    name: record,
                });

                if (data) {
                    setStocksFrom([...stocksFrom, { ...data, label: data.name, value: data.name }]);
                    setFormData({
                        ...formData,
                        [id]: record,
                    });
                }
            } catch (e) {
                const message = apiErrorHandler(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            setCustomLoading((prev) => ({ ...prev, stocksFrom: false }));
            return;
        }

        setFormData({
            ...formData,
            [id]: record,
        });
    }
};
