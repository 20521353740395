/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */

import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    FormText,
    Spinner,
    UncontrolledPopover,
    PopoverHeader,
} from 'reactstrap';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';

// #region components
import { BackdropLoader, Select, TableCustom } from 'components';
import ImportExampleModal from 'components/Modals/Import-Example';
import CustomToast from 'components/Toast/Custom';
import CustomDateInput from 'components/CustomDateInput/CustomDateInput';

// #region imports
import { THEME_COLOR, TOAST_STYLES } from 'theme';
import paths from 'routes/paths';

// #region services
import { uploadDeliveryImportXlCsvAttachment } from 'services/attachment.service';
import {
    getAllMerchantsService,
    getMerchantsByClientIdService,
    getMerchantLocationsByVisitingMerchantIdService,
} from 'services/merchant.service';
import { getImportHistoryService } from 'services/import-history.service';
import { getAllMerchantLocationByMerchantId } from 'services/merchant-location.service';
import { getExcelCsvConfigByMerchantIdService } from 'services/excel-csv-config.service';
import { getMerchantSettingByMerchantIdService } from 'services/merchant-setting.service';

// #region utils
import {
    apiErrorHandlerV2,
    fileListToArray,
    getDefaultValueForSelect,
    reactSelectCustomStyles,
    getDateValue,
} from 'utils/helpers';
import { getVisitingObject, hasAccess, IS_MERCHANT_VIEW, IS_SUPER_ADMIN } from 'utils/checkAuth';
import { deliveryExcelCsvImportConfig } from 'utils/db/excel-csv/delivery-excel-csv';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import { validateForm } from './utils';
import { defaultTableHeaders, tableDefaultFilters } from './constants';
import { downloadExcelImportFile } from './services';

let initialFormValues = {};

function DeliveryImportPage() {
    const history = useHistory();

    const { visitingClient, visitingMerchant } = getVisitingObject();

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    const formRef = useRef(null);

    // form
    const [formData, setFormData] = useState(initialFormValues);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);

    const [merchants, setMerchants] = useState([]);
    const [allMerchantLocations, setAllMerchantLocations] = useState([]);

    // loading
    const [loading, setLoading] = useState(0);
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [formLoading, setFormLoading] = useState(false);
    const [allowToImport, setAllowToImport] = useState(false);

    const [formKey, setFormKey] = useState(1); // Used  Reset Form

    const [importHistoryData, setImportHistoryData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });
    const [searchBy] = useState({ searchBy: '', searchText: null });

    const [showDeliveryExampleModal, setShowDeliveryExampleModal] = useState(false);
    const [excelDeliveryConfig, setExcelDeliveryConfig] = useState();
    const [showDeliveryConfigNotFoundWarning, setShowDeliveryConfigNotFoundWarning] = useState(false);

    const [showExcelConfigIcon, setShowExcelConfigIcon] = useState(false);

    const checkIfLastUploadedFileHadIssues = (record) => {
        if (record && record.errorRecordsLength > 0) {
            const currentTime = moment();
            const { createdAt } = record;
            const createdAtWithConditionTime = moment(createdAt).add('5', 'minutes');
            if (currentTime.isBefore(createdAtWithConditionTime)) {
                toast.custom((t) => <CustomToast text="Looks like last uploaded excel had issues" t={t} />, {
                    position: 'top-right',
                });
            }
        }
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (showExcelConfigIcon) {
            const timer1 = setTimeout(() => setShowExcelConfigIcon(false), 3 * 1000);
            return () => {
                clearTimeout(timer1);
            };
        }
    }, [showExcelConfigIcon]);

    useEffect(() => {
        loadImportHistoryData(
            true,
            importHistoryData.all,
            1,
            importHistoryData.limit,
            importHistoryData.filter,
            importHistoryData.sort,
            importHistoryData.order,
            searchBy
        );
    }, []);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    useEffect(() => {
        if (visitingMerchant && visitingMerchant._id) {
            const visitingMerchantId = visitingMerchant._id;
            getMerchantLocationsByVisitingMerchantId(visitingMerchantId);
        }
    }, []);

    // Merchant Location Handlers of visiting merchant
    const getMerchantLocationsByVisitingMerchantId = async (visitingMerchantId) => {
        await getMerchantLocationsByVisitingMerchantIdService(visitingMerchantId).then((res) => {
            const { data } = res;
            if (data.docs) {
                setAllMerchantLocations(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.merchantLocationName || ''} - ${x.location || ''}`,
                        key: x._id,
                    }))
                );
            }
        });
    };

    useEffect(() => {
        if (formData.merchantId) {
            loadExcelCsvConfig(formData.merchantId);
            loadMerchantSettings(formData.merchantId);
            loadAllMerchantLocationsByMerchantId(formData.merchantId);
        }
    }, [formData.merchantId]);

    // load excel csv configuration
    const loadExcelCsvConfig = async (id) => {
        setLoading((prevState) => prevState + 1);
        try {
            const { data } = await getExcelCsvConfigByMerchantIdService(id, 'DELIVERY_IMPORT');
            if (data) {
                setShowDeliveryConfigNotFoundWarning(false);
                setExcelDeliveryConfig({ ...excelDeliveryConfig, ...data });
                setAllowToImport(true);
                setTimeout(() => {
                    setShowExcelConfigIcon(true);
                }, 1000);
            } else {
                setShowDeliveryConfigNotFoundWarning(true);
                setExcelDeliveryConfig({ ...excelDeliveryConfig, ...deliveryExcelCsvImportConfig });
                setAllowToImport(false);
            }
        } catch (e) {
            console.log(e);
        }
        setLoading((prevState) => prevState - 1);
    };

    // load merchant settings
    const loadMerchantSettings = async (id) => {
        try {
            const { data } = await getMerchantSettingByMerchantIdService(id);
            if (data && data.settings) {
                // setMerchantSetting(data.settings);
                if (data.settings?.deliveryImport?.isEnabled) {
                    setAllowToImport(true);
                } else {
                    setAllowToImport(false);
                    toast.custom((t) => <CustomToast text="Enable Delivery Import Setting!" t={t} type="warning" />, {
                        position: 'top-right',
                    });
                }
            } else {
                // setMerchantSetting({});
                setAllowToImport(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // Merchant locations for selected form data
    const loadAllMerchantLocationsByMerchantId = async (id) => {
        try {
            const { data } = await getAllMerchantLocationByMerchantId(id);
            if (data && data.docs) {
                setAllMerchantLocations(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.merchantLocationName || ''} - ${x.location || ''}`,
                        key: x._id,
                    }))
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handlePageChange = (pageNo) => {
        loadImportHistoryData(
            true,
            importHistoryData.all,
            pageNo,
            importHistoryData.limit,
            importHistoryData.filter,
            importHistoryData.sort,
            importHistoryData.order,
            searchBy
        );
    };

    const handleFilter = (field, filterText) => {
        loadImportHistoryData(
            false,
            importHistoryData.all,
            1,
            importHistoryData.limit,
            importHistoryData.filter,
            importHistoryData.sort,
            importHistoryData.order,
            { searchBy: field || 'reference', searchText: filterText }
        );
    };

    const loadImportHistoryData = (showLoading = true, all, page, limit, filter, sort, order, searchByProp) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getImportHistoryService(all, page, limit, filter, sort, order, searchByProp, null, 'DELIVERY_IMPORT_EXCEL_CSV')
            .then((res) => {
                const { data } = res;
                setImportHistoryData({
                    ...importHistoryData,
                    ...data,
                });
                if (data.docs && data.docs.length > 0) {
                    checkIfLastUploadedFileHadIssues(data.docs[0]);
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const handleSubmit = async (event, errors, values) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const isFormValid = validateForm(formData);

        if (!isFormValid) {
            toast.error('Fill all fields', TOAST_STYLES.ERROR);
            return;
        }

        const {
            merchantLocationReference,
            merchantLocation,
            merchantLocationMeta,
            merchantLocationId,
            merchantId,
            fileType,
            merchantName,
            deliveryDate,
        } = formData;
        const meta = {
            merchantLocation,
            merchantLocationMeta,
            merchantLocationId,
            merchantId,
            fileType,
            merchantName,
            merchantLocationReference,
            deliveryDate,
        };
        // Meta
        formData.meta = meta;

        // Attachment Meta
        const { name, lastModifiedDate, size } = formData.attachment;
        formData.meta.attachmentMeta = { name, lastModifiedDate, size };

        const attachmentData = new FormData();
        attachmentData.append('file', formData.attachment);
        formData.uploadData = attachmentData;
        setFormLoading(true);
        try {
            await uploadDeliveryImportXlCsvAttachment({ ...formData });
            toast.custom((t) => <CustomToast text="Successfully Uploaded" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        } finally {
            setFormLoading(false);
        }
        setFormLoading(false);
        resetForm();
        setSubmitted(false);
        setValidated(false);
        loadImportHistoryData();
    };

    // Reset Form
    const resetForm = () => {
        document.getElementById('deliveryImportForm').reset();
        setFormData(initialFormValues);
        setFormKey((prevState) => prevState + 1);
    };

    const handleChange = (event) => {
        event.preventDefault();

        if (event.target.id === 'attachment') {
            const files = fileListToArray(event.target.files);
            const uploadedFile = files[0];
            let fileType = null;
            let fileTypeName = null;

            if (uploadedFile.name.includes('xlsx')) {
                fileType = 'xlsx';
                fileTypeName = 'Excel';
            }
            if (uploadedFile.name.includes('csv')) {
                fileType = 'csv';
                fileTypeName = 'Csv';
            }
            if (!fileType) {
                toast.error('Invalid File Format', TOAST_STYLES.ERROR);
                setFormData({
                    ...formData,
                    fileType: null,
                    fileTypeName: '',
                    attachment: null,
                });
                return;
            }
            setFormData({
                ...formData,
                fileType,
                fileTypeName,
                attachment: files[0],
            });
        } else {
            setFormData({
                ...formData,
                [event.target.id]: event.target.value,
            });
        }
    };

    const handleDownloadRow = async (row) => {
        if (!row?.fileName) {
            toast.custom((t) => <CustomToast text="File Not Found, Contact Admin" t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await downloadExcelImportFile(row.fileName);
            if (data) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', row.importedFileName);
                document.body.appendChild(link);
                link.click();

                toast.custom((t) => <CustomToast text="Successfully Downloaded File" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setBackdropLoading((prevState) => prevState - 1);
    };

    const getMerchantLocationSelect = (_id) => {
        const relevantMerchantLocation = allMerchantLocations.find((x) => x._id === _id);
        if (relevantMerchantLocation) {
            return `${relevantMerchantLocation.merchantLocationName || ''} - ${
                relevantMerchantLocation.location || ''
            }`;
        }
        return null;
    };

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    const handleDateChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value ? new Date(value) : null,
        });
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        if (event) {
            if (id === 'merchantId') {
                const relevantMerchant = merchants.find((x) => x._id === event.value);
                if (relevantMerchant) {
                    setFormData({
                        ...formData,
                        merchantName: relevantMerchant.name || '',
                        [id]: event ? event.value : null,
                        merchantLocation: null,
                        merchantLocationId: null,
                        merchantLocationMeta: null,
                        merchantLocationReference: null,
                    });
                }
                return;
            }
            if (id === 'merchantLocationId') {
                const relevantMerchantLocation = allMerchantLocations.find((x) => x._id === event.value);
                if (relevantMerchantLocation) {
                    setFormData({
                        ...formData,
                        merchantLocation: relevantMerchantLocation.location,
                        merchantLocationMeta: relevantMerchantLocation.locationMeta,
                        merchantLocationReference: relevantMerchantLocation.referenceNumber,
                        [id]: event ? event.value : null,
                    });
                }
                return;
            }
            setFormData({
                ...formData,
                [id]: event ? event.value : null,
            });
        } else if (id === 'merchantId') {
            setFormData({
                ...formData,
                merchantLocation: null,
                merchantLocationId: null,
                merchantLocationMeta: null,
                merchantLocationReference: null,
                [id]: null,
            });
        } else {
            if (id === 'merchantLocationId') {
                setFormData({
                    ...formData,
                    merchantLocation: null,
                    merchantLocationMeta: null,
                    merchantLocationReference: null,
                    [id]: null,
                });
                return;
            }
            setFormData({
                ...formData,
                [id]: null,
            });
        }
    };

    const toggleShowDeliveryExampleModal = () => {
        setShowDeliveryExampleModal(!showDeliveryExampleModal);
    };

    return (
        <>
            <div className="page-content">
                <AvForm
                    noValidate
                    id="deliveryImportForm"
                    className="needs-validation"
                    validated={validated}
                    onSubmit={handleSubmit}
                    ref={formRef}
                >
                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div></div>
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius m-2 mb-3 pr-3 pl-3 shadow-lg nw-md mr-4 pr-4 pl-4"
                            onClick={() =>
                                history.push({
                                    pathname: `${paths.DELIVERY.DEFAULT}`,
                                })
                            }
                        >
                            <i className="fas fa-chevron-left" /> <span className="h6">Back</span>
                        </motion.div>
                    </Row>
                    <br />
                    {showDeliveryConfigNotFoundWarning && (
                        <>
                            <Alert
                                color="danger"
                                className="ml-4 mr-4 d-flex align-items-center"
                                style={{ justifyContent: 'space-between', flexDirection: 'row' }}
                            >
                                <div>
                                    Excel / CSV File Configuration not found, Please configure setup before continuing
                                </div>
                            </Alert>
                            <br />
                        </>
                    )}
                    {!showDeliveryConfigNotFoundWarning && excelDeliveryConfig && (
                        <>
                            <Alert
                                color="success"
                                className="ml-4 mr-4 d-flex align-items-center"
                                style={{ justifyContent: 'space-between', flexDirection: 'row' }}
                            >
                                <div style={{ fontSize: 15 }}> Excel import Configuration found</div>
                                {excelDeliveryConfig && (
                                    <>
                                        <motion.div
                                            id="excel-config-icon-container"
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            style={{ cursor: 'pointer' }}
                                            className="shadow-lg nw-md mr-2"
                                            onClick={() => toggleShowDeliveryExampleModal()}
                                        >
                                            <i
                                                id="excel-config-icon"
                                                className="fas fa-table fa-lg"
                                                style={{ color: THEME_COLOR }}
                                            />
                                        </motion.div>
                                        <UncontrolledPopover
                                            trigger="hover"
                                            isOpen={!showDeliveryExampleModal && showExcelConfigIcon}
                                            target="excel-config-icon"
                                            placement="bottom"
                                        >
                                            <PopoverHeader>View Configuration Example</PopoverHeader>
                                            {/* <PopoverBody> View Configuration Example</PopoverBody> */}
                                        </UncontrolledPopover>
                                    </>
                                )}
                            </Alert>
                            <br />
                        </>
                    )}
                    <Card style={{ borderRadius: 15, margin: 10, marginTop: -10 }}>
                        <CardBody style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                            <div
                                style={{
                                    backgroundColor: '#F9F7F7',
                                    borderTopRightRadius: 15,
                                    borderTopLeftRadius: 15,
                                    minHeight: 20,
                                }}
                            >
                                <div
                                    style={{
                                        padding: 23,
                                        color: '#807F7D',
                                        fontSize: 19,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Import Deliveries By Excel / CSV
                                </div>
                            </div>
                            <br />

                            <Row style={{ padding: 10, marginLeft: 5 }}>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="fileTypeName">File Type</Label>
                                        <AvField
                                            name="fileTypeName"
                                            type="text"
                                            errorMessage="Required"
                                            className="form-control"
                                            id="fileTypeName"
                                            disabled
                                            // placeholder={formData.default}
                                            value={formData.fileTypeName}
                                            // onChange={handleInputChange}
                                            validate={{ required: { value: true } }}
                                            key={String(formKey)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="merchantId">Merchant</Label>
                                        <Select
                                            value={getDefaultValueForSelect(getMerchantSelect(formData.merchantId))}
                                            options={merchants}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => {
                                                handleSelectChange(event, 'merchantId');
                                            }}
                                            submitted={submitted}
                                            required
                                            validated={validated}
                                            isDisabled={IS_MERCHANT_VIEW()}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="merchantLocationId">Merchant Locations</Label>
                                        <Select
                                            value={getDefaultValueForSelect(
                                                getMerchantLocationSelect(formData.merchantLocationId)
                                            )}
                                            options={allMerchantLocations.map((x) => ({
                                                label: x.label,
                                                value: x.key,
                                            }))}
                                            id="merchantLocationId"
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'merchantLocationId')}
                                            submitted={submitted}
                                            validated={validated}
                                            isClearable
                                            isDisabled={!formData.merchantId}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="deliveryDate">Delivery Date</Label>
                                        <CustomDateInput
                                            onChange={handleDateChange}
                                            type="date"
                                            className="form-control"
                                            value={getDateValue(formData.deliveryDate, 'date')}
                                            min={new Date().toISOString().split('T')[0]}
                                            id="deliveryDate"
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label for="attachment">File</Label>
                                        <Input type="file" name="attachment" id="attachment" onChange={handleChange} />
                                        <FormText color="muted">Excel or CSV</FormText>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ justifyContent: 'flex-end' }}>
                                {hasAccess(PERMISSIONS.DELIVERY_EXCEL_UPLOAD, ['Upload']) && allowToImport && (
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 mr-5"
                                        type="submit"
                                        disabled={formLoading}
                                    >
                                        {formLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <i className="fas fa-upload left-icon"></i>
                                                <span className="h6">Upload</span>
                                            </>
                                        )}
                                    </motion.button>
                                )}
                            </Row>
                        </CardBody>
                    </Card>

                    <br />
                    <Row>
                        <div
                            style={{
                                minHeight: 20,
                            }}
                        >
                            <div
                                style={{
                                    padding: 23,
                                    color: '#807F7D',
                                    fontSize: 19,
                                    fontWeight: 'bolder',
                                }}
                            >
                                Import History
                            </div>
                        </div>
                        <br />
                        <TableCustom
                            removeAction
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            tableData={importHistoryData}
                            loading={loading}
                            isFullScreenShow
                            showManageDeliveryImportExcelCsv={hasAccess(PERMISSIONS.MANAGE_DELIVERY_EXCEL_UPLOAD, [
                                'View',
                            ])}
                            showDownload={IS_SUPER_ADMIN()}
                            filters={tableDefaultFilters}
                            removeId={false}
                            onDownload={handleDownloadRow}
                        />
                    </Row>
                </AvForm>
            </div>

            {showDeliveryExampleModal && deliveryExcelCsvImportConfig && (
                <ImportExampleModal
                    toggleModal={toggleShowDeliveryExampleModal}
                    header="Delivery Import Example"
                    importConfiguration={excelDeliveryConfig}
                    text="Below format will be used when processing file"
                />
            )}

            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

DeliveryImportPage.propTypes = {};

const HotDeliveryImportPage = hot(DeliveryImportPage);

export default connect(mapStateToProps, {})(HotDeliveryImportPage);
