/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { AvForm } from 'availity-reactstrap-validation';
import { Card, CardBody, Container, Row, FormGroup, Label, Input } from 'reactstrap';

// #region assets | components
import { BackdropLoader, CustomToast } from 'components';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #endRegions imports
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import CustomizableButton from '../../../Api-Service/Dashboard/components/CustomizableButton';
import updateIcon from '../../../../../../assets/images/developer/updateIcon.svg';
import { updateRequestSettingsService } from '../../services';

const RequestSettings = () => {
    const [formData, setFormData] = useState({});

    const [formLoading, setFormLoading] = useState(false);

    const [loaded, setLoaded] = useState(false);

    const apiPartnerData = useSelector((state) => state.apiPartnerData);

    useEffect(() => {
        if (apiPartnerData?.merchantRecordData) {
            setLoaded(true);
            setFormData((prev) => ({
                ...prev,
                isCreateQuotation: apiPartnerData?.merchantRecordData?.payload?.isCreateQuotation || false,
                isIncludeShippingLabel: apiPartnerData?.merchantRecordData?.payload?.isIncludeShippingLabel || false,
            }));
        } else {
            setLoaded(false);
        }
    }, [apiPartnerData?.merchantRecordData]);

    const handleSubmit = async (event) => {
        event.persist();
        setFormLoading(true);
        const payload = {
            merchantId: apiPartnerData?.merchantRecordData?.payload?.merchantId,
            isCreateQuotation: formData.isCreateQuotation,
            isIncludeShippingLabel: formData.isIncludeShippingLabel,
        };
        try {
            const { data } = await updateRequestSettingsService(payload);
            if (data) {
                toast.custom(
                    (t) => <CustomToast text="Successfully Updated the Request settings" t={t} type="success" />,
                    {
                        position: 'top-right',
                    }
                );
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setFormLoading(false);
    };

    const handleCheckChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.checked,
        });
    };

    return (
        <Container fluid>
            <Card style={{ borderRadius: 15, marginTop: 10 }}>
                {loaded ? (
                    <CardBody style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                        <div
                            style={{
                                backgroundColor: '#E7E4E4',
                                borderTopRightRadius: 15,
                                borderTopLeftRadius: 15,
                                minHeight: 20,
                                padding: 15,
                                fontFamily: 'inter',
                                alignItems: 'center',
                            }}
                        >
                            <div className="font-weight-bold font-size-16 ml-3" style={{ color: '#3A3937' }}>
                                Request Settings
                            </div>
                        </div>
                        <AvForm
                            className="needs-validation default-form"
                            onValidSubmit={handleSubmit}
                            id="AdvancedSettingsForm"
                            autoComplete="off"
                        >
                            <Row
                                style={{
                                    marginTop: 10,
                                    marginLeft: 30,
                                    marginRight: 30,
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div className="font-weight-bold font-size-14" style={{ color: '#807F7D' }}>
                                    Delivery Creation
                                </div>
                                <hr style={{ border: '1px solid #807F7D', width: '100%' }}></hr>
                                <fieldset disabled={formLoading} style={{ width: '100%' }}>
                                    <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="form-check">
                                            <Input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={handleCheckChange}
                                                checked={formData.isCreateQuotation || false}
                                                defaultChecked={formData.isCreateQuotation}
                                                id="isCreateQuotation"
                                            />
                                            <Label className="form-check-label">
                                                Create Quotation with Delivery Creation
                                            </Label>
                                        </div>
                                    </FormGroup>
                                    <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="form-check">
                                            <Input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={handleCheckChange}
                                                checked={formData.isIncludeShippingLabel || false}
                                                defaultChecked={formData.isIncludeShippingLabel}
                                                id="isIncludeShippingLabel"
                                            />
                                            <Label className="form-check-label">
                                                Include Shipping Label for the Response
                                            </Label>
                                        </div>
                                    </FormGroup>
                                </fieldset>
                            </Row>
                            <Row>
                                {hasAccess(PERMISSIONS.PARTNER_API_ADVANCED_SETTINGS, ['Edit']) && (
                                    <CustomizableButton
                                        buttonTitle="Update"
                                        icon={updateIcon}
                                        iconStyle={{ width: 24, height: 24 }}
                                        customStyle={{ marginLeft: 40 }}
                                        loading={formLoading}
                                    />
                                )}
                            </Row>
                        </AvForm>
                    </CardBody>
                ) : (
                    <BackdropLoader show opacity={0.8} />
                )}
            </Card>
        </Container>
    );
};

export default RequestSettings;
