import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, FormGroup, Label, Button } from 'reactstrap';

function PodComponent(props) {
    const { setShowPreview } = props;
    return (
        <>
            <Card className="shadow-lg default-card">
                <CardBody>
                    <div className="container">
                        <Row>
                            <Col md={6}>
                                <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Label htmlFor="proofOfDelivery">Proof of Delivery</Label>
                                    <Button
                                        color="black"
                                        style={{
                                            width: 160,
                                            height: 30,
                                            backgroundColor: '#F1F5F7',
                                            borderColor: 'lightgrey',
                                        }}
                                        onClick={() => setShowPreview(true)}
                                    >
                                        View File
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

PodComponent.propTypes = {
    setShowPreview: PropTypes.func,
};

export default PodComponent;
