import { v4 as uuid } from 'uuid';

export const userProfileManagementListData = [
    {
        id: uuid(),
        userId: 33441,
        name: 'Hans Sibert',
        email: 'hans@scootix.com',
        merchant: 'AEON',
        designation: 'Customer Service Manager',
        contactNo: '+60 12-33-0580',
        createdAt: new Date(),
    },
];

export const exampleUser = {
    fullName: 'Arsadsa Ahgaded',
    isAdmin: false,
    role: 'clientUser',
    permissions: [],
    profilePictureUrl: null,
    designation: 'dsad',
    nic: '222222-22-2222',
    contactNo: '60222222222',
    socsoNo: '222222222222',
    address: 'dsa',
    department: 'adsada',
    verified: false,
    isApproved: false,
    isBlocked: false,
    isDisabled: false,
    isActive: false,
    isDeleted: false,
    isOnline: false,
    countryCode: 60,
    status: 'new',
    loginAttempts: 0,
    deletedAt: null,
    merchantName: 'AOEN',
    clientName: 'TASA',
    roleId: null,
    email: 'arshad.azaad32@gmail.com',
    username: 'adsadsada',
    firstName: 'Arsadsa',
    lastName: 'Ahgaded',
    merchantLocation: 'SCM Studio Pvt Ltd, R. A. De Mel Mawatha, Colombo, Sri Lanka, ,Colombo',
    merchantLocationMeta: {
        street_number: '',
        postal_code: '00300',
        street: 'R. A. De Mel Mawatha',
        province: 'Western Province',
        city: 'Colombo',
        country: 'LK',
        address: 'SCM Studio Pvt Ltd, R. A. De Mel Mawatha, Colombo, Sri Lanka',
        addressSimple: 'R. A. De Mel Mawatha',
        place_id: 'ChIJ3QJ0cl1Z4joRsRncKaemfio',
        geometry: {
            location: { lat: 6.9078675, lng: 79.85302820000001 },
            location_type: 'ROOFTOP',
            viewport: {
                south: 6.906500919708498,
                west: 79.85162546970851,
                north: 6.909198880291503,
                east: 79.85432343029152,
            },
        },
        coordinates: { lat: 6.9078675, lng: 79.85302820000001 },
        searchedValue: { label: 'SCM Studio Pvt Ltd, R. A. De Mel Mawatha, Colombo, Sri Lanka' },
        inputAddress: 'scm studio',
        type: 'MERCHANT_LOCATION',
    },
    city: 'dsadsa',
    country: 'United Arab Emirates',
    password: '12345',
};
