/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Input, Label, Spinner } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// #region components
import { Select, CustomToast } from 'components';
import toast from 'react-hot-toast';
import CreatableSelectAdvanced from 'components/CreateableSelect';

// #regions services
import { getRiderListData } from 'services/rider.service';
import { cancelDeliveryService } from 'pages/Modules/Delivery-Management/services';
import { createSystemDataService, getAllSystemDataByTypeAndModuleService } from 'services/system-data.service';

// #region other imports
import { THEME_COLOR_2 } from 'theme';

// #endregion imports
import { apiErrorHandlerV2, getDefaultValueForSelect } from 'utils/helpers';
import { SYSTEM_DATA_TYPES } from 'constants/enums';
import { cancelDeliveryValidateForm } from '../utils';
import './style.css';

function CancelDelivery(props) {
    const {
        data: deliveryData,
        setShowModal,
        loadTransactions,
        setDeliveryFormData,
        updateHistoryWithData,
        createBillingRecord,
    } = props;

    const _isMounted = useRef(true);

    const { merchantOrderNo, referenceNumber, _id, vehicleType } = deliveryData;

    const initialFormValues = {
        reason: 'Other',
        description: '',
        isBillable: false,
        isCreateReturnOrder: false,
        isReturnOrderBillable: false,
        confirmation: '',
        riderId: deliveryData.riderId,
    };

    const [reasons, setReasons] = useState([]);

    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);

    const [customLoading, setCustomLoading] = useState({ reason: false });
    const [formLoading, setFormLoading] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);
    const [riderListData, setRiderListData] = useState({ docs: [] });

    // load delivery cancel reasons to dropdown
    useEffect(() => {
        loadDeliveryCancelReasons();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    const handleCreateSelectChange = async (record, id) => {
        if (record) {
            if (id === 'reason') {
                setCustomLoading((prev) => ({ ...prev, reason: true }));
                try {
                    const { data } = await createSystemDataService({
                        type: SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION_REASONS.DELIVERY_CANCEL_REASON,
                        module: SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION,
                        name: record,
                    });
                    if (data) {
                        setReasons([...reasons, { label: data.name, value: data.name }]);
                        setFormData({
                            ...formData,
                            [id]: record,
                        });
                    }
                } catch (e) {
                    const { message: exception } = apiErrorHandlerV2(e);
                    toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                        position: 'top-right',
                    });
                }
            }
        }

        setCustomLoading((prev) => ({ ...prev, reason: false }));
    };

    const handleSelectChange = (event, id) => {
        if (id === 'confirmation' && event) {
            setFormData({
                ...formData,
                [id]: event ? event.target.value : null,
            });
            return;
        }
        if (id === 'description' && event) {
            setFormData({
                ...formData,
                [id]: event ? event.target.value : null,
            });
            return;
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleSubmit = async (event, errors = []) => {
        let updatedPayloadData;
        const { isFormValid, message } = cancelDeliveryValidateForm(formData);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        const payload = { ...formData, deliveryId: _id };
        if (formLoading) {
            return;
        }

        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.persist();
        setFormLoading(true);

        let isSuccess = false;

        try {
            const { data } = await cancelDeliveryService(payload);
            if (data) {
                setFormData({ ...payload, ...data });
                updatedPayloadData = { ...deliveryData, ...data };
                setDeliveryFormData({ ...deliveryData, ...data });
                isSuccess = true;
                toast.custom((t) => <CustomToast text="Successfully Canceled the delivery" t={t} type="success" />, {
                    position: 'top-right',
                });
                if (formData.isBillable) {
                    await createBillingRecord();
                }
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (updatedPayloadData) {
            updateHistoryWithData({ ...deliveryData, ...updatedPayloadData });
        }
        setFormLoading(false);
        setValidated(false);
        setSubmitted(false);
        loadTransactions();

        if (isSuccess) {
            setShowModal(false);
        }
    };

    /**
     * Load delivery Cancel Reasons to the Dropdown
     */
    const loadDeliveryCancelReasons = async () => {
        try {
            const { data } = await getAllSystemDataByTypeAndModuleService(
                SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION_REASONS.DELIVERY_CANCEL_REASON,
                SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                setReasons(data.map((x) => ({ label: x.name, value: x.name })));
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    useEffect(() => {
        loadRiderListData({ ...formData, showLoading: true });
    }, [formData.vehicleType]);

    /**
     * Load Delivery List Data
     * @param {*} args
     */
    const loadRiderListData = (args) => {
        getRiderListData({
            ...args,
            limit: 100,
        }).then((res) => {
            const { data } = res;
            delete data.sort;
            setRiderListData({ ...data });
        });
    };

    const relevantRidersList = (vehicle) => {
        const ridersList = riderListData.docs.filter((x) => x.vehicleType === vehicle);

        const relevantRider = ridersList.map((riders) => {
            if (riders) {
                return { label: riders.fullName || '', value: riders._id };
            }
            return null;
        });
        return relevantRider;
    };

    const selectedReasons = useMemo(() => getDefaultValueForSelect(formData.reason), [formData.reason]);
    return (
        <div className="font-size-14 w-100 p-0">
            <div
                className="font-size-16 text-white"
                style={{ backgroundColor: THEME_COLOR_2, padding: 15, borderRadius: '5px 5px 0 0' }}
            >
                Cancel the Delivery - {merchantOrderNo} | {referenceNumber}
            </div>
            <div className="modal__form-table">
                <AvForm onSubmit={handleSubmit}>
                    <div className="modal_form-item">
                        <Label htmlFor="reason">Reason</Label>
                        <CreatableSelectAdvanced
                            options={reasons}
                            isClearable
                            value={selectedReasons}
                            required
                            id="reason"
                            isLoading={customLoading.reason}
                            onChange={(event) => handleSelectChange(event, 'reason')}
                            onCreate={(event) => handleCreateSelectChange(event, 'reason')}
                            submitted={submitted}
                            validated={validated}
                        />
                    </div>

                    <div className="modal_form-item">
                        <Label htmlFor="Description">Description</Label>
                        <div>
                            <Input
                                style={{ borderRadius: 2, padding: 5, width: '100%', borderColor: 'none' }}
                                type="textarea"
                                name="text"
                                rows="3"
                                cols=""
                                id="description"
                                value={formData.description}
                                onChange={(event) => handleSelectChange(event, 'description')}
                            />
                        </div>
                    </div>

                    <div className="modal_form-item">
                        <Label htmlFor="billable">Billable</Label>
                        <Select
                            value={getDefaultValueForSelect(formData.isBillable === false ? 'No' : 'Yes')}
                            options={[
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ]}
                            id="isBillable"
                            required
                            onChange={(event) => handleSelectChange(event, 'isBillable')}
                            submitted={submitted}
                            validated={validated}
                        />
                    </div>

                    <div className="modal_form-item">
                        <Label>Create a Return Order</Label>
                        <Select
                            value={getDefaultValueForSelect(formData.isCreateReturnOrder === false ? 'No' : 'Yes')}
                            options={[
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ]}
                            id="isCreateReturnOrder"
                            required
                            onChange={(event) => handleSelectChange(event, 'isCreateReturnOrder')}
                            submitted={submitted}
                            validated={validated}
                        />
                    </div>

                    {formData.isCreateReturnOrder && (
                        <div className="modal_form-item">
                            <Label>Is Return Order Billable</Label>
                            <Select
                                value={getDefaultValueForSelect(formData.isReturnOrderBillable ? 'Yes' : 'No')}
                                options={[
                                    { label: 'Yes', value: true },
                                    { label: 'No', value: false },
                                ]}
                                id="isReturnOrderBillable"
                                required
                                onChange={(event) => handleSelectChange(event, 'isReturnOrderBillable')}
                                submitted={submitted}
                                validated={validated}
                            />
                        </div>
                    )}

                    {formData.isCreateReturnOrder && (
                        <div className="modal_form-item">
                            <Label>Assign a rider</Label>
                            <Select
                                defaultValue={[{ label: deliveryData.riderName || '', value: deliveryData.riderId }]}
                                options={relevantRidersList(vehicleType)}
                                id="riderId"
                                isClearable
                                required
                                onChange={(event) => handleSelectChange(event, 'riderId')}
                                submitted={submitted}
                                validated={validated}
                            />
                        </div>
                    )}

                    <div style={{ width: '100%', padding: '10px', background: '#fffbf0', marginBottom: '15px' }}>
                        Type &apos;confirm&apos; to confirm the transaction and click confirm.
                    </div>

                    <div className="modal_form-item">
                        <AvField
                            value={formData.confirmation}
                            name="confirmation"
                            onChange={(event) => handleSelectChange(event, 'confirmation')}
                            errorMessage="Confirmation Required"
                            submitted={submitted}
                            required
                            id="confirmation"
                            validated={validated}
                            autoComplete="off"
                        />
                    </div>

                    <div style={{ fontSize: 14 }} className="mb-6 flex row">
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius secondary-btn pt-1 pb-1 m-2 mb-3 pr-5 pl-5 shadow-lg "
                            style={{ borderRadius: 10 }}
                            type="submit"
                            disabled={formLoading}
                        >
                            {formLoading ? <Spinner className="mr-4 ml-4 0" color="info" size="sm" /> : <>CONFIRM</>}
                        </motion.button>
                    </div>
                </AvForm>
            </div>
        </div>
    );
}

CancelDelivery.propTypes = {
    loadTransactions: PropTypes.func,
    data: PropTypes.any,
    setDeliveryFormData: PropTypes.func,
    setShowModal: PropTypes.func,
    updateHistoryWithData: PropTypes.func,
    createBillingRecord: PropTypes.func,
};

export default CancelDelivery;
