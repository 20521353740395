// @flow
import { ADD_HEADER_TEXT } from './actionTypes';

const INIT_STATE = {
    textObject: null,
};

const Page = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_HEADER_TEXT:
            return {
                ...state,
                textObject: { ...action.payload },
            };
        default:
            return state;
    }
};

export default Page;
