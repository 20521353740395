import { ceil } from 'lodash';

export const pieChartInitialConfig = {
    series: [0, 0],
    options: {
        chart: {
            width: '100%',
            type: 'pie',
        },
        colors: ['#17CF67', '#F72A5A'],
        legend: {
            position: 'bottom',
        },
        labels: ['Success Requests - 0', 'Requests with Errors - 0'],
        dataLabels: {
            style: {
                fontWeight: 'normal',
                fontFamily: 'Sans-serif',
                fontSize: 14,
            },
        },

        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: '100%',
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    },
};

export const hourlyRequestOverviewChartInitialConfig = {
    options: {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        legend: {
            show: true,
            textStyle: {
                fontSize: 10,
            },
        },
        grid: {
            top: '25%',
            bottom: '20%',
            left: '3%',
            right: '4%',
            containLabel: true,
        },
        yAxis: {
            type: 'value',
            minInterval: 1,
        },
        xAxis: {
            type: 'category',
            data: [
                '01:00',
                '02:00',
                '03:00',
                '04:00',
                '05:00',
                '06:00',
                '07:00',
                '08:00',
                '09:00',
                '10:00',
                '11:00',
                '12:00',
                '13:00',
                '14:00',
                '15:00',
                '16:00',
                '17:00',
                '18:00',
                '19:00',
                '20:00',
                '21:00',
                '22:00',
                '23:00',
                '24:00',
            ],
            nameGap: 30,
            scale: true,
        },
        dataZoom: [
            {
                type: 'inside',
                start: 85,
                end: 100,
            },
            {
                type: 'slider',
                height: 20,
            },
        ],
        series: [
            {
                name: 'Number of Success Requests',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
                name: 'Number of Error Requests',
                type: 'bar',
                itemStyle: { color: '#FF5733' },
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
        ],
    },
};

export const apiUsageDataOverViewChartInitialConfigs = {
    series: [
        {
            data: [0, 0, 0, 0, 0, 0, 0, 0],
        },
    ],
    options: {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '20%',
                distributed: true,
                horizontal: true,
            },
        },
        colors: [
            // this array contains different color code for each data
            '#33b2df',
            '#546E7A',
            '#d4526e',
            '#13d8aa',
            '#A5978B',
            '#2b908f',
            '#f9a3a4',
            '#90ee7e',
            '#f48024',
        ],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 1,
            colors: ['#fff'],
        },
        legend: {
            show: false,
        },
        xaxis: {
            categories: [
                'Quotation',
                'Delivery Creation',
                'Cancel Delivery',
                'Update Delivery',
                'Shipping Label',
                'Delivery Charges',
                'Delivery Tracking',
                'Delivery Ready Status',
                'Get Delivery',
            ],
            labels: {
                style: {
                    fontSize: '12px',
                },
                // eslint-disable-next-line consistent-return
                formatter(val) {
                    // TODO: fix warning above and below
                    // @champika
                    // eslint-disable-next-line eqeqeq
                    if (val == ceil(val)) {
                        return val;
                    }
                },
            },
        },
    },
};
