import React from 'react';
import PropTypes from 'prop-types';

function OnDemandDeliveryBell(props) {
    const { width = 15, height = 15 } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.66669 7.33333V5.5C3.66669 5.01377 3.85984 4.54745 4.20366 4.20364C4.54747 3.85982 5.01379 3.66667 5.50002 3.66667C5.98625 3.66667 6.45257 3.85982 6.79638 4.20364C7.1402 4.54745 7.33335 5.01377 7.33335 5.5V7.33333"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.375 5.5H1.83333M5.5 1.375V1.83333M9.16667 5.5H9.625M2.56667 2.56667L2.8875 2.8875M8.43333 2.56667L8.1125 2.8875"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.79167 7.33333H3.20833C2.9552 7.33333 2.75 7.53854 2.75 7.79167V8.70833C2.75 8.96146 2.9552 9.16667 3.20833 9.16667H7.79167C8.0448 9.16667 8.25 8.96146 8.25 8.70833V7.79167C8.25 7.53854 8.0448 7.33333 7.79167 7.33333Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

OnDemandDeliveryBell.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

export default OnDemandDeliveryBell;
