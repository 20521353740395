/* eslint-disable react-hooks/exhaustive-deps */

import React, { useMemo } from 'react';
import { Row, Col, Label } from 'reactstrap';
import { motion } from 'framer-motion';

import PropTypes from 'prop-types';

// #region imports
import { Select } from 'components';
import { Search } from 'components/Icons';

// #region utils
import { getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';

// #endregion imports
import { DELIVERY_TYPE_OPTIONS, VEHICLE_TYPE_OPTIONS } from '../../constants';

function FormBody(props) {
    const { handleSearch, formData, merchants, submitted, validated, handleSelectChange, getMerchantSelect } = props;

    // ** Memos
    const selectedMerchantId = useMemo(() => getMerchantSelect(formData.merchantId), [formData.merchantId, merchants]);

    return (
        <>
            <div className="delivery-batch-assign__initial-container">
                <Row>
                    <Col md={4}>
                        <Label htmlFor="merchantId">Merchant</Label>
                        <Select
                            value={selectedMerchantId}
                            options={merchants}
                            isClearable
                            styles={reactSelectCustomStyles}
                            onChange={(event) => handleSelectChange(event, 'merchantId')}
                            submitted={submitted}
                            required
                            validated={validated}
                        />
                    </Col>
                    <Col md={3}>
                        <Label htmlFor="name">Delivery Type</Label>
                        <Select
                            value={getDefaultValueForSelect(formData.deliveryType)}
                            options={DELIVERY_TYPE_OPTIONS}
                            isClearable
                            styles={reactSelectCustomStyles}
                            onChange={(event) => handleSelectChange(event, 'deliveryType')}
                            submitted={submitted}
                            required
                            validated={validated}
                        />
                    </Col>
                    <Col md={3}>
                        <Label htmlFor="vehicleType">Vehicle Type</Label>
                        <Select
                            value={getDefaultValueForSelect(formData.vehicleType)}
                            options={VEHICLE_TYPE_OPTIONS}
                            styles={reactSelectCustomStyles}
                            onChange={(event) => handleSelectChange(event, 'vehicleType')}
                            submitted={submitted}
                            required
                            isDisabled={formData.riderId || formData.isPickUpStarted}
                            validated={validated}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.8, opacity: 0 }}
                        transition={{ type: 'spring', duration: 1.5 }}
                        className="scootix-btn-radius p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md ml-3"
                        style={{ borderRadius: 15 }}
                        onClick={() => handleSearch()}
                    >
                        <Search /> <div className="ml-1">Search</div>
                    </motion.div>
                </Row>
            </div>
        </>
    );
}

FormBody.propTypes = {
    formData: PropTypes.object,
    merchants: PropTypes.array,
    submitted: PropTypes.bool,
    validated: PropTypes.bool,
    handleSelectChange: PropTypes.func,
    getMerchantSelect: PropTypes.func,
    handleSearch: PropTypes.func,
};

export default FormBody;
