/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';

function TableStickyView(props) {
    const {
        tableData,
        isRowDisabled,
        handleEdit,
        showEdit,
        handleOpenToolBox,
        applyFormats,
        history,
        tablePermissions,
        removeId = false,
        isTableBorder,
        handlerProps,
        otherProps,
    } = props;

    const getMinWidth = (rowKey) => {
        let minWidth = 150;
        if (rowKey === 'merchantName') {
            minWidth = 200;
        }
        return minWidth;
    };

    return (
        <div>
            <div style={{ width: '100%', height: '500px' }}>
                <StickyTable leftStickyColumnCount={2}>
                    <Row>
                        {!removeId && (
                            <Cell
                                style={{
                                    width: 35,
                                    textAlign: 'center',
                                    minWidth: 50,
                                    verticalAlign: 'center',
                                }}
                            >
                                #
                            </Cell>
                        )}
                        {tableData.headers.map((header) => (
                            // <Cell>{header.value}</Cell>
                            <Cell
                                className="tableHeader"
                                key={uuid()}
                                style={{
                                    borderLeft: isTableBorder ? '1px solid #d9d9d9' : '',
                                    borderRight: isTableBorder ? '1px solid #d9d9d9' : '',
                                    minWidth: header.style
                                        ? header.style.includes('increase-width')
                                            ? 300
                                            : 170
                                        : 170,
                                    maxWidth: 600,
                                    height: 40,
                                    // position: 'sticky',
                                    top: 0,
                                    backgroundColor: '#F9F7F7',
                                    borderBottom: '1px solid #d9d9d9',
                                    paddingBottom: 10,
                                    // flexDirection: 'row',
                                    // display: 'flex',
                                    // justifyContent: 'space-between',
                                }}
                            >
                                <div
                                    className="th-row"
                                    style={{
                                        display: 'flex',
                                        minWidth: 'min-content',
                                        maxWidth: 500,
                                        width: 'max-content',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>{header.value}</div>
                                </div>
                            </Cell>
                        ))}
                    </Row>
                    {tableData.docs.map((row, i) => (
                        <Row
                            style={{
                                cursor: 'pointer',
                                height: 10,
                                ...(row.isLatest ? '#fdefd6' : ''),
                            }}
                            key={`row_${i}`}
                            fixed="left"
                            className={isRowDisabled(row)}
                        >
                            {!removeId && (
                                <Cell
                                    key={`col_start_${i}`}
                                    style={{
                                        width: 35,
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        verticalAlign: 'middle',
                                    }}
                                >
                                    {tableData.page
                                        ? tableData.page > 1
                                            ? i + (tableData.page - 1) * tableData.limit + 1
                                            : i + 1
                                        : i + 1}
                                </Cell>
                            )}
                            {tableData.headers.map((header, k) => (
                                <Cell
                                    style={{
                                        // display: 'flex',
                                        // flexDirection: 'col',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        textAlign: 'left',
                                        verticalAlign: 'middle',
                                        minWidth: getMinWidth(header.key),
                                    }}
                                    key={`row_${k}`}
                                    onClick={(e) => {
                                        if (handleEdit && showEdit) {
                                            handleEdit(e, row);
                                        } else {
                                            handleOpenToolBox(`row_${k}${header}${i}`);
                                        }
                                    }}
                                >
                                    {applyFormats(
                                        row[header.key],
                                        header,
                                        row,
                                        history,
                                        tablePermissions,
                                        handlerProps,
                                        otherProps
                                    )}
                                </Cell>
                            ))}
                        </Row>
                    ))}
                </StickyTable>
            </div>
        </div>
    );
}

TableStickyView.propTypes = {
    tableData: PropTypes.object,
    handleEdit: PropTypes.func,
    handlerProps: PropTypes.object,
    otherProps: PropTypes.object,
    isRowDisabled: PropTypes.bool,
    showEdit: PropTypes.bool,
    removeId: PropTypes.bool,
    applyFormats: PropTypes.func,
    handleOpenToolBox: PropTypes.func,
    history: PropTypes.any,
    tablePermissions: PropTypes.object,
    isTableBorder: PropTypes.bool,
};

export default TableStickyView;
