import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

/**
 * This Custom Date Input component implemented for showing date inputs with clear button option
 * We can use this component where you have used reactstrap Input
 */
const CustomDateInput = (props) => {
    const { onChange, type, defaultValue, className, value, id, invalid, max, isClearable, isRequired, disabled, min } =
        props;

    const [fieldValue, setFieldValue] = useState(value || '');

    useEffect(() => {
        setFieldValue(value);
    }, [value]);

    const handleClear = () => {
        setFieldValue('');
        onChange({ target: { id, value: '' } });
    };

    return (
        <div className="custom-date-wrapper">
            <Input
                onChange={onChange}
                type={type}
                defaultValue={defaultValue}
                className={className}
                value={fieldValue}
                id={id}
                invalid={invalid}
                max={max}
                disabled={disabled}
                min={min}
                validate={{ required: { value: isRequired } }}
            />
            {isClearable && value && (
                <button
                    type="button"
                    className="close custom-date-input"
                    aria-label="Close"
                    onClick={() => handleClear()}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            )}
        </div>
    );
};

// Component Prop Types
CustomDateInput.propTypes = {
    onChange: PropTypes.func,
    type: PropTypes.string,
    defaultValue: PropTypes.any,
    className: PropTypes.string,
    value: PropTypes.any,
    id: PropTypes.any,
    invalid: PropTypes.any,
    isClearable: PropTypes.bool,
    max: PropTypes.any,
    min: PropTypes.any,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default CustomDateInput;
