import { GET_ALL_VEHICLES, GET_ALL_VEHICLES_BY_TYPE } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get All Vehicles
 * @returns {Promise}
 */
export const getAllVehiclesService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_VEHICLES).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Vehicles By Type
 * @returns {Promise}
 */
export const getVehiclesByTypeService = (type) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_VEHICLES_BY_TYPE).setPathParams({ type }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
