/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { CardBody, Col, Modal, Row } from 'reactstrap';
import Lottie from 'react-lottie';
import './styles/loadingModal.scss';

// #region assets | components
import GenerateMapLottie from 'assets/animations/route-plan-loading.json';

const generateMapLottieOptions = (lottieName) => ({
    loop: true,
    autoplay: true,
    animationData: lottieName || '',
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
});

/**
 * A modal used in showing the animation when generating the map
 * @param {object} props
 */
function GenerateMapLoadingModal(props) {
    const { loading } = props;
    return (
        <Modal className="loading-modal modal-dialog modal-dialog-centered" isOpen={loading}>
            <CardBody style={{ borderRadius: '20px', borderStyle: 'solid', borderColor: '#F9A322' }}>
                <Row>
                    <Col sm={3}>
                        <Lottie
                            className="page-center-lottie"
                            options={generateMapLottieOptions(GenerateMapLottie)}
                            height={100}
                            width={100}
                            speed={0.8}
                        />
                    </Col>
                    <Col sm={9}>
                        <h3 style={{ whiteSpace: 'nowrap', fontSize: '1.4rem', marginTop: '10%' }}>
                            Hold tight.. Generating the Map..
                        </h3>
                    </Col>
                </Row>
            </CardBody>
        </Modal>
    );
}

GenerateMapLoadingModal.propTypes = {
    loading: PropTypes.bool,
};

export default GenerateMapLoadingModal;
