import * as React from 'react';
import PropTypes from 'prop-types';

function DeleteAccount(props) {
    const { width, height } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 22}
            height={height || 22}
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12 15.75v-1.5a3 3 0 00-3-3H3.75a3 3 0 00-3 3v1.5M6.375 8.25a3 3 0 100-6 3 3 0 000 6zM13.5 6l3.75 3.75M17.25 6L13.5 9.75"
            ></path>
        </svg>
    );
}

DeleteAccount.propTypes = {
    // color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default DeleteAccount;
