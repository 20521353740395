/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Label, Media, Modal, ModalBody, ModalHeader, Card, CardBody } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Avatar from 'react-avatar';

// #region services
import { BackdropLoader } from 'components';

function RiderProfileModal(props) {
    const { riderData = {}, toggleModal } = props;

    const [backdropLoading] = useState(0);

    const [formData, setFormData] = useState({ ...riderData });
    const [validated] = useState(false);

    useEffect(() => {
        if (riderData) {
            setFormData({ ...formData, ...riderData });
        }
    }, [riderData]);

    return (
        <>
            <Modal size="xl" isOpen toggle={toggleModal} center className="modal-dialog modal-dialog-centered">
                <ModalHeader toggle={toggleModal}>Rider Profile</ModalHeader>
                <ModalBody>
                    <Card className="shadow default-card">
                        <CardBody>
                            <AvForm
                                className="needs-validation"
                                noValidate
                                validated={validated}
                                id="userRegistrationForm"
                            >
                                <Row>
                                    <Avatar name={riderData.fullName} size="50" round="50px" className="mt-1" />
                                    <Media body className="chat-user-box overflow-hidden ml-2">
                                        <p className="user-title m-0">{riderData.fullName || ''}</p>
                                        <p className="user-title m-0">{riderData.mobileNo || ''}</p>
                                        <p
                                            className="text-muted text-truncate"
                                            style={{
                                                backgroundColor: '#FFEDB7',
                                                padding: 5,
                                                minWidth: 80,
                                                maxWidth: 150,
                                                borderRadius: 12,
                                                textAlign: 'center',
                                            }}
                                        >
                                            {(riderData.currentTasks || []).length > 0 ? 'Busy' : 'Idle'}
                                        </p>
                                    </Media>
                                </Row>

                                <Row className="mt-4">
                                    <Col md="4">
                                        <Label className="mt-2" htmlFor="firstName">
                                            First Name
                                        </Label>
                                        <FormGroup>
                                            <AvField
                                                name="firstName"
                                                placeholder="First Name"
                                                type="text"
                                                errorMessage="required"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="firstName"
                                                value={formData.firstName}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <Label className="mt-2" htmlFor="lastName">
                                            Last Name
                                        </Label>
                                        <FormGroup>
                                            <AvField
                                                name="lastName"
                                                placeholder="Last Name"
                                                type="text"
                                                errorMessage="required"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="lastName"
                                                value={formData.lastName}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <Label className="mt-2" htmlFor="email">
                                            Email
                                        </Label>
                                        <FormGroup>
                                            <AvField
                                                name="email"
                                                placeholder="Email"
                                                type="text"
                                                errorMessage="required"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="email"
                                                value={formData.email}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <Label className="mt-2" htmlFor="vehicleType">
                                            Vehicle Type
                                        </Label>
                                        <FormGroup>
                                            <AvField
                                                name="vehicleType"
                                                placeholder="Vehicle Type"
                                                type="text"
                                                errorMessage="required"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="vehicleType"
                                                value={formData.vehicleType}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>

            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

RiderProfileModal.propTypes = {
    toggleModal: PropTypes.func,
    riderData: PropTypes.object,
};

export default RiderProfileModal;
