export const defaultTableHeaders = [
    {
        key: 'name',
        value: 'Name',
    },
    {
        key: 'country',
        value: 'Country',
    },
    {
        key: 'address',
        value: 'Address',
    },
    {
        key: 'primaryContactNumber',
        value: 'Contact Number',
    },
    {
        key: 'businessRegistrationNo',
        value: 'Business Registration No',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
    {
        key: 'deletedAt',
        value: 'Deleted At',
        type: 'dateTime',
    },
    {
        key: '_id',
        value: 'Restore',
        type: 'restoreButton',
    },
    {
        key: '_id',
        value: 'Permanent Delete',
        type: 'permanentDeleteButton',
    },
    { key: 'deletedBy', value: 'Deleted By', type: 'userObject' },
];
