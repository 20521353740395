import { TRANSACTION_REFERENCE } from 'constants/enums';

export const isActionButtonVisible = (data) => {
    if (!data) return false;

    const {
        CREATE_WEBHOOK,
        ENABLE_WEBHOOK,
        DISABLE_DISABLED,
        UPDATE_WEBHOOK,
        CLIENT_API_KEY_UPDATED,
        AUTO_DISABLE_WEBHOOK,
    } = TRANSACTION_REFERENCE.PARTNER_API_WEBHOOK;

    const buttonVisibleReferences = [
        CREATE_WEBHOOK,
        ENABLE_WEBHOOK,
        DISABLE_DISABLED,
        UPDATE_WEBHOOK,
        CLIENT_API_KEY_UPDATED,
        AUTO_DISABLE_WEBHOOK,
    ];
    if (buttonVisibleReferences.includes(data.reference)) {
        return true;
    }

    return false;
};
