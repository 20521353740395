/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Row } from 'reactstrap';
import './styles.css';

// #region component | assets
import { CustomToast } from 'components';

// #region other imports
import { batchPrintSizes } from 'constants/options';

// #region utils
import { apiErrorHandlerV2, getDateValue, getDefaultValueForSelect } from 'utils/helpers';

// #endregion imports
import FormBody from './FormBody';

function InitialForm(props) {
    const { formData, merchants, setFormData, setBackdropLoading } = props;

    /**
     * Handle Start Print Process
     */
    const handleStartPrintProcess = async () => {
        try {
            // window.open(formData.task?.resourceUrl, 'PRINT', 'height=400,width=600');
            // const newWindow = window.open(formData.task?.resourceUrl, '_blank', 'noopener,noreferrer');
            // https://scootix-staging-open.s3.ap-southeast-1.amazonaws.com/console_batch_print/628aa454f8333300167e67a2/BP00109.pdf
            // iframe.src =
            //     'https://scootix-staging-open.s3.ap-southeast-1.amazonaws.com/console_batch_print/628aa454f8333300167e67a2/BP00109.pdf';
            // const pdfWindow = window.open();
            // pdfWindow.location.href = formData.task?.resourceUrl;
        } catch (e) {
            const { message: error } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={error} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    /**
     * Handle Download File
     */
    const handleDownloadFile = () => {
        setBackdropLoading((prev) => prev + 1);
        try {
            const fileUrl = formData.task?.resourceUrl;
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                toast.custom((t) => <CustomToast text="Successfully Downloaded" t={t} type="success" />, {
                    position: 'top-right',
                    duration: 5000,
                });
            } else {
                toast.custom((t) => <CustomToast text="File Not Found" t={t} type="error" />, {
                    position: 'top-right',
                    duration: 5000,
                });
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prev) => prev - 1);
    };

    const getSelectPrintSize = (value) => {
        if (value) {
            const relevantObj = batchPrintSizes.find((x) => x.key === value);
            if (relevantObj) {
                return { label: relevantObj.label, value: relevantObj.key };
            }
        }
        return null;
    };

    // Select Handlers
    const getMerchantSelect = (_id) => {
        const relevantObj = merchants.find((x) => x._id === _id);
        if (relevantObj) {
            return { label: relevantObj.name, value: relevantObj._id };
        }
        return null;
    };

    const selectedPrinter = useMemo(() => getDefaultValueForSelect(formData.printer), [formData.printer]);
    const selectedPrintSize = useMemo(() => getSelectPrintSize(formData.printSize), [formData.printSize]);

    const selectedMerchantId = useMemo(() => getMerchantSelect(formData.merchantId), [formData.merchantId, merchants]);

    const handleCheckChange = (event) => {
        const { id, checked, value } = event.target;

        if (id === 'isUseDateTime') {
            setFormData({
                ...formData,
                [id]: checked,
                name: checked ? getDateValue(new Date(), 'datetime') : '',
            });
        }
        if (id === 'batchPrintName') {
            setFormData({
                ...formData,
                name: value,
            });
        }
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleSelectChange = (event, id) => {
        if (id === 'printer') {
            setFormData({
                ...formData,
                [id]: event ? event.value : null,
                printSize: null,
            });
            return;
        }
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const formBodyProps = {
        ...props,
        selectedPrinter,
        handleInputChange,
        handleCheckChange,
        selectedMerchantId,
        selectedPrintSize,
        handleStartPrintProcess,
        handleDownloadFile,
        handleSelectChange,
    };

    return (
        <Row style={{ marginLeft: 1, marginRight: 1 }}>
            <FormBody {...formBodyProps} />
        </Row>
    );
}

InitialForm.propTypes = {
    formData: PropTypes.object,
    merchants: PropTypes.object,
    setFormData: PropTypes.func,
    setBackdropLoading: PropTypes.func,
    setFormLoading: PropTypes.func,
};

export default InitialForm;
