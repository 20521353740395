import React, { useState } from 'react';
import { Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ToolTipDefault from 'components/Tooltip';
import PropTypes from 'prop-types';

function StatCard(props) {
    const {
        title = '',
        showMenu = false,
        value = '',
        heading = '',
        icon,
        tooltipTitle = '',
        tooltipPlacement = 'right',
        iconSize = 'fa-3x',
        customClassName = '',
        iconProps,
        cardStyle = {},
        active,
    } = props;

    const [menuState, setMenuState] = useState(false);

    const cardId = `${title.toLowerCase().replace(/ /g, '')}-stat-card`;

    return (
        <React.Fragment>
            <Card
                className={`stat-card-container shadow ${customClassName}`}
                id={cardId}
                style={{ ...cardStyle, backgroundColor: active ? '#ffeecc' : cardStyle.backgroundColor || '#FFF' }}
            >
                <CardBody>
                    {showMenu && (
                        <Dropdown className="float-right" isOpen={menuState} toggle={() => setMenuState(!menuState)}>
                            <DropdownToggle tag="i" className="arrow-none card-drop">
                                <i className="mdi mdi-dots-vertical" />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem href="">Extend</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    )}
                    {heading && <h4 className="card-title mb-4">{heading}</h4>}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <i className={`home-card-stat-icon ${icon} ${iconSize} `} {...iconProps} />
                        </div>
                        <div className="ml-3 mr-1">
                            <h5 className="text-truncate mb-2 font-weight-bold">{value}</h5>
                            <h5 className="text-muted font-weight-bold">{title}</h5>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <ToolTipDefault targetId={cardId} text={tooltipTitle} position={tooltipPlacement} />
        </React.Fragment>
    );
}

StatCard.propTypes = {
    title: PropTypes.string,
    heading: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    tooltipTitle: PropTypes.string,
    iconSize: PropTypes.string,
    showMenu: PropTypes.bool,
    customClassName: PropTypes.string,
    iconProps: PropTypes.any,
    cardStyle: PropTypes.any,
    active: PropTypes.bool,
};

export default StatCard;
