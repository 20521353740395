import React from 'react';
import { motion } from 'framer-motion';
import { Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

// #region components | assets
import { Printer, Settings } from 'components/Icons';

// #region other imports
import PATHS from 'routes/paths';

export default function Header() {
    const history = useHistory();
    const handleOnClickPrinterSettings = () => {
        history.push({
            pathname: `${PATHS.LABEL_PRINT.PRINTER_SETTINGS.DEFAULT}`,
        });
    };
    const handleOnClickNewBatchPrint = () => {
        history.push({
            pathname: `/${PATHS.LABEL_PRINT.SHIPPING_LABEL.LIST_VIEW}`,
        });
    };

    return (
        <Row className="justify-content-between">
            <div className="header-button-left">
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="scootix-btn-radius m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                    onClick={() => handleOnClickNewBatchPrint()}
                >
                    <Printer style={{ marginRight: 5 }} />
                    Print Labels
                </motion.div>
            </div>
            <div className="header-button-right">
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="right scootix-btn-radius m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                    onClick={() => handleOnClickPrinterSettings()}
                >
                    <Settings color="#fff" style={{ marginRight: 5 }} />
                    Printer Settings
                </motion.div>
            </div>
        </Row>
    );
}
