/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

/** Library Imports */
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import ReactECharts from 'echarts-for-react';
import toast from 'react-hot-toast';
import { Card, CardBody, Col, Container, Row, FormGroup, Label, Input, Spinner } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import { hot } from 'react-hot-loader/root';

// #region components | assets
import { TableCustom, Select, CustomToast } from 'components';
import ExportModal from 'components/Modals/Export-Dashboard';

// #region services
import {
    getDashboardPartnerApiLatestUsageStat,
    getDashboardPartnerApiUsageStats,
    getDashboardPartnerApiUsageDataOverview,
    getDashboardPartnerApiUsageDataOverviewTime,
    getDashboardPartnerUsageTableData,
    getDashboardSuccessErrorCountMerchantVise,
} from 'services/partnerApi/partnerApi.dashboard.service';
import { getAllDeliveryIdsService } from 'services/deliveries.service';
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';

// #region utils
import { apiErrorHandlerV2, getDateValue, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { getVisitingObject, hasAccess, IS_MERCHANT_VIEW } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import AccessDenied from 'pages/Utility/AccessDenied';
import CustomizableButton from '../components/CustomizableButton';
import GraphCard from '../components/GraphCard';
import AnalyticsCard from '../components/AnalyticsCard';
import ApiRequestModal from '../components/ApiRequestModal';
import sync from '../../../../../../assets/images/developer/sync.svg';
import file from '../../../../../../assets/images/developer/file.svg';
import successSvg from '../../../../../../assets/images/developer/success.svg';
import error from '../../../../../../assets/images/developer/bug.svg';
import excelExport from '../../../../../../assets/images/developer/exportfile.svg';
import searchIcon from '../../../../../../assets/images/developer/search.svg';
import {
    tableDefaultFilters,
    statusDropDownValues,
    partnerApiStatusCodeValues,
    partnerApiSActionPathsDropDownValues,
    tableDataInitialInClientDashboardTable,
    exportHeadersClientTable,
} from '../constants';
import {
    hourlyRequestOverviewChartInitialConfig,
    apiUsageDataOverViewChartInitialConfigs,
    pieChartInitialConfig,
} from '../utils/chartConfigs';
import {
    getErrorPercentage,
    getMonthName,
    getSuccessPercentage,
    mapApiUsageOverviewTableDocs,
    mapDataSeriesForApiUsageOverview,
    mapDataSeriesForApiUsageOverviewTimely,
} from '../utils';
import CustomPeriodSection from '../components/CustomPeriodSection';
import { chartDataMapToExportableFormat, getDatePeriod } from '../helpers';

const initialFormData = {
    fromDateTime: moment().startOf('day').toISOString(),
    toDateTime: moment().toISOString(),
    clientId: null,
    merchantId: null,
};

// !TODO: ADD DOCS, REFACTOR AND OPTIMIZE CODE
/**
 * This is the ClientDashboard component and this components is getting the merchant ID property
 * @returns the Client Dashboard
 */
const ClientDashboard = () => {
    const { visitingClient, visitingMerchant } = getVisitingObject();

    if (visitingMerchant) {
        initialFormData.merchantId = visitingMerchant._id;
    }
    if (visitingClient) {
        initialFormData.clientId = visitingClient._id;
        initialFormData.country = visitingClient?.country || 'Malaysia';
    }

    const [formData, setFormData] = useState(initialFormData);
    const [tableFilterOptions, setTableFilterOptions] = useState(initialFormData);

    const [apiUsageStats, setApiUsageStats] = useState({
        success: {
            requestCount: 0,
        },
        fail: {
            requestCount: 0,
        },
        total: {
            requestCount: 0,
        },
        latestRequest: {
            latestRequestDate: null,
        },
    });
    const [apiUsageDataOverView, setApiUsageDataOverview] = useState({ status: 'success' });
    const [successErrorMerchantVise, setSuccessErrorMerchantVise] = useState([]);

    const [showExportModalForTable, setShowExportModalForTable] = useState(false);
    const [showExportModalForPieChart, setShowExportModalForPieChart] = useState(false);
    const [showExportModalForTimelyGraph, setShowExportModalForTimelyGraph] = useState(false);
    const [showExportModalForOverviewGraph, setShowExportModalForOverviewGraph] = useState(false);

    const [timelyGraphType, setTimelyGraphType] = useState('Hourly');

    const [customLoading, setCustomLoading] = useState({
        latestRequest: false,
        apiUsageStats: false,
        apiUsageDataOverview: false,
        apiUsageDataOverviewTime: false,
        deliveryId: false,
        pieChartData: false,
    });

    const [allDeliveryIds, setAllDeliveryIds] = useState([]);
    const [merchants, setMerchants] = useState([]);

    /**
     * Setting initial configs
     */
    const [successAndErrorPieChart, setSuccessAndErrorPieChart] = useState(pieChartInitialConfig);
    const [apiUsageDataOverViewChart, setApiUsageDataOverviewChart] = useState(apiUsageDataOverViewChartInitialConfigs);
    const [apiUsageDataTimelyOverViewChart, setApiUsageDataTimelyOverViewChart] = useState(
        hourlyRequestOverviewChartInitialConfig
    );
    const [apiUsageOverviewTableData, setApiUsageOverviewTableData] = useState(tableDataInitialInClientDashboardTable);

    /**
     * Setting loading and modal status
     */
    const [loading, setLoading] = useState(0);
    const [modalOptions, setModalOptions] = useState({ modal: null, data: {} });

    // !TODO: ADD DOCS
    useEffect(() => {
        if (formData.clientId) {
            const { fromDateTime, toDateTime, clientId } = formData;
            getApiUsageStats({ clientId, fromDateTime, toDateTime });
            getApiUsageDataOverview({ clientId, fromDateTime, toDateTime });
            getApiUsageLatestStat({ clientId });
            loadApiUsageOverviewTableData({ clientId, fromDateTime, toDateTime });
            loadAllDeliveryIds();
            getPieChartDataMerchantVise({ clientId, fromDateTime, toDateTime });
        }
    }, [formData.clientId]);

    // Separated this from above useEffect due to this is rendering when timelyGraphType changing
    useEffect(() => {
        if (formData.clientId) {
            const { fromDateTime, toDateTime, clientId } = formData;
            getApiUsageDataOverviewTime({ clientId, fromDateTime, toDateTime });
        }
    }, [formData.clientId, timelyGraphType]);

    // !TODO: ADD DOCS
    useEffect(() => {
        if (!customLoading.apiUsageStats && apiUsageStats) {
            const { success, fail } = apiUsageStats;
            setSuccessAndErrorPieChart({
                ...successAndErrorPieChart,
                series: [success.requestCount, fail.requestCount],
                options: {
                    ...successAndErrorPieChart.options,
                    labels: [
                        `Success Requests - ${success.requestCount}`,
                        `Requests with Errors - ${fail.requestCount}`,
                    ],
                },
            });
        }
    }, [customLoading.apiUsageStats, apiUsageStats]);

    // !TODO: ADD DOCS
    useEffect(() => {
        if (!customLoading.apiUsageDataOverview && apiUsageDataOverView) {
            const { successRequestOverview = {}, failRequestOverview = {}, status } = apiUsageDataOverView;
            setApiUsageDataOverviewChart((state) => ({
                ...state,
                series: [
                    {
                        data: mapDataSeriesForApiUsageOverview(status, { successRequestOverview, failRequestOverview }),
                    },
                ],
            }));
        }
    }, [customLoading.apiUsageDataOverview, apiUsageDataOverView]);

    // This is date calculation when setting custom date (Whether to show hourly, daily, monthly overview)
    useEffect(() => {
        // Convert date strings to calculatable date objects
        const date1 = moment(formData.fromDateTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
        const date2 = moment(formData.toDateTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ');

        // Get hour difference in two dates
        const hourDiff = date2.diff(date1, 'hours');

        // If duration is with 24 hours, hourly request overview should be displayed
        if (hourDiff < 24) {
            const selectedHours = [];
            while (date1 <= date2) {
                let hour = date1.format('H');
                hour = hour < 10 ? `0${hour}` : hour;
                selectedHours.push(`${hour}:00`);
                date1.add(1, 'hours');
            }
            setTimelyGraphType('Hourly');
            setApiUsageDataTimelyOverViewChart((prevState) => ({
                ...prevState,
                options: { ...prevState.options, xAxis: { data: selectedHours } },
            }));

            // If the duration between month, daily request overview should be displayed
        } else if (hourDiff > 24 && hourDiff < 720) {
            // Get dates with in the selected date range
            const selectedDates = [];
            while (date1 <= date2) {
                selectedDates.push(date1.format('D'));
                date1.add(1, 'days');
            }
            setTimelyGraphType('Daily');
            setApiUsageDataTimelyOverViewChart((prevState) => ({
                ...prevState,
                options: { ...prevState.options, xAxis: { data: selectedDates } },
            }));

            // If the duration is more than month, monthly request overview should be displayed
        } else if (hourDiff > 720) {
            // Get months with in the selected date range
            const selectedMonths = [];
            while (date1 <= date2) {
                const monthName = getMonthName(Number(date1.format('M')));
                selectedMonths.push(monthName);
                date1.add(1, 'months');
            }
            setTimelyGraphType('Monthly');
            setApiUsageDataTimelyOverViewChart((prevState) => ({
                ...prevState,
                options: { ...prevState.options, xAxis: { data: selectedMonths } },
            }));
        }
    }, [formData.fromDateTime, formData.toDateTime]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    function handleFilterRequest(properties = {}) {
        const { limit, page } = properties;
        if (formData.clientId) {
            const { clientId, referenceNumber, merchantOrderNo, fromDateTime, toDateTime } = tableFilterOptions;
            let { status, statusCode, actionPath, merchantId } = tableFilterOptions;
            let deliveryId = null;
            if (status === 'all') {
                status = null;
            }
            if (statusCode === 'all') {
                statusCode = null;
            }
            if (actionPath === 'all') {
                actionPath = null;
            }
            // referenceNumber or merchantOrderNo stored delivery object id
            if (referenceNumber || merchantOrderNo) {
                deliveryId = referenceNumber || merchantOrderNo;
            }
            if (merchantId === 'all') {
                merchantId = null;
            }
            const cleanedProperties = _.pickBy({
                clientId,
                status,
                statusCode,
                limit,
                page,
                actionPath,
                deliveryId,
                fromDateTime,
                toDateTime,
                merchantId,
            });
            loadApiUsageOverviewTableData(cleanedProperties);
        }
    }

    // !TODO: ADD DOCS
    const getApiUsageStats = async (params, isLoading = true) => {
        if (!params.clientId) {
            toast.custom((t) => <CustomToast text="Refresh Again" t={t} type="error" />, {
                position: 'top-right',
            });
            return;
        }

        if (isLoading) {
            setCustomLoading((prevState) => ({ ...prevState, apiUsageStats: true }));
        }

        try {
            const { data } = await getDashboardPartnerApiUsageStats(params);
            if (data?.payload?.statisticalData) {
                const { statisticalData = {} } = data.payload;
                const { success = null, fail = null, pending = null } = statisticalData;
                const cleanedData = _.pickBy(statisticalData);
                let totalRequests = 0;
                if (success) {
                    totalRequests += success?.requestCount || 0;
                }
                if (fail) {
                    totalRequests += fail?.requestCount || 0;
                }
                if (pending) {
                    totalRequests += pending?.requestCount || 0;
                }
                setApiUsageStats((prevState) => ({
                    ...prevState,
                    ...cleanedData,
                    success: { requestCount: success?.requestCount || 0 },
                    fail: { requestCount: fail?.requestCount || 0 },
                    total: { requestCount: totalRequests },
                }));
            }
        } catch (err) {
            const { message: exception } = apiErrorHandlerV2(err);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (isLoading) {
            setCustomLoading((prevState) => ({ ...prevState, apiUsageStats: false }));
        }
    };

    // !TODO: ADD DOCS
    const getApiUsageLatestStat = async (params, isLoading = true) => {
        if (!params.clientId) {
            toast.custom((t) => <CustomToast text="Refresh Again" t={t} type="error" />, {
                position: 'top-right',
            });
            return;
        }

        if (isLoading) {
            setCustomLoading((prevState) => ({ ...prevState, latestRequest: true }));
        }

        try {
            const { data } = await getDashboardPartnerApiLatestUsageStat(params);
            if (data?.payload) {
                setApiUsageStats((prevState) => ({
                    ...prevState,
                    latestRequest: {
                        ...data.payload,
                        latestRequestDate: moment(data.payload?.latestRequestDate || new Date()).format(
                            'YYYY-MM-DD hh:mm A'
                        ),
                    },
                }));
            }
        } catch (err) {
            const { message: exception } = apiErrorHandlerV2(err);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (isLoading) {
            setCustomLoading((prevState) => ({ ...prevState, latestRequest: false }));
        }
    };

    // !TODO: ADD DOCS
    const getApiUsageDataOverview = async (params, isLoading = true) => {
        if (!params.clientId) {
            toast.custom((t) => <CustomToast text="Refresh Again" t={t} type="error" />, {
                position: 'top-right',
            });
            return;
        }

        if (isLoading) {
            setCustomLoading((prevState) => ({ ...prevState, apiUsageDataOverview: true }));
        }

        try {
            const { data } = await getDashboardPartnerApiUsageDataOverview(params);
            if (data?.payload) {
                setApiUsageDataOverview({ ...apiUsageDataOverView, ...data.payload });
            }
        } catch (err) {
            const { message: exception } = apiErrorHandlerV2(err);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (isLoading) {
            setCustomLoading((prevState) => ({ ...prevState, apiUsageDataOverview: false }));
        }
    };

    // !TODO: ADD DOCS
    const getApiUsageDataOverviewTime = async (params, isLoading = true) => {
        const { clientId, fromDateTime, toDateTime } = params;
        if (!clientId) {
            toast.custom((t) => <CustomToast text="Refresh Again" t={t} type="error" />, {
                position: 'top-right',
            });
            return;
        }

        if (isLoading) {
            setCustomLoading((prevState) => ({ ...prevState, apiUsageDataOverviewTime: true }));
        }

        try {
            const { data } = await getDashboardPartnerApiUsageDataOverviewTime(params);
            if (data?.payload) {
                const { success, fail } = data.payload;

                let successData = [];
                let failData = [];

                if (timelyGraphType === 'Hourly') {
                    if (success) {
                        const { hourly } = success;
                        if (hourly && Array.isArray(hourly) && hourly[0]) {
                            let hourlyData = hourly[0]?.result;
                            if (hourlyData) {
                                hourlyData = hourlyData.sort((x) => x.hour - x.hour);
                                successData = hourlyData;
                            }
                        }
                    }
                    if (fail) {
                        const { hourly } = fail;
                        if (hourly && Array.isArray(hourly) && hourly[0]) {
                            let hourlyData = hourly[0]?.result;
                            if (hourlyData) {
                                hourlyData = hourlyData.sort((x) => x.hour - x.hour);
                                failData = hourlyData;
                            }
                        }
                    }
                } else if (timelyGraphType === 'Daily') {
                    if (success) {
                        const { daily } = success;
                        if (daily && Array.isArray(daily) && daily[0]) {
                            let dailyData = daily[0]?.result;
                            if (dailyData) {
                                dailyData = dailyData.sort((x) => x.day - x.day);
                                successData = dailyData;
                            }
                        }
                    }
                    if (fail) {
                        const { daily } = fail;
                        if (daily && Array.isArray(daily) && daily[0]) {
                            let dailyData = daily[0]?.result;
                            if (dailyData) {
                                dailyData = dailyData.sort((x) => x.day - x.day);
                                failData = dailyData;
                            }
                        }
                    }
                } else if (timelyGraphType === 'Monthly') {
                    if (success) {
                        const { monthly } = success;
                        if (monthly && Array.isArray(monthly) && monthly[0]) {
                            let monthlyData = monthly[0]?.result;
                            if (monthlyData) {
                                monthlyData = monthlyData.sort((x) => x.month - x.month);
                                successData = monthlyData;
                            }
                        }
                    }
                    if (fail) {
                        const { monthly } = fail;
                        if (monthly && Array.isArray(monthly) && monthly[0]) {
                            let monthlyData = monthly[0]?.result;
                            if (monthlyData) {
                                monthlyData = monthlyData.sort((x) => x.month - x.month);
                                failData = monthlyData;
                            }
                        }
                    }
                }
                const { successSeriesArray, failSeriesArray } = mapDataSeriesForApiUsageOverviewTimely({
                    fromDateTime,
                    toDateTime,
                    successData,
                    failData,
                    timelyGraphType,
                });
                const { options } = apiUsageDataTimelyOverViewChart;
                const successSeries = options.series[0];
                const failSeries = options.series[1];

                successSeries.data = successSeriesArray;
                failSeries.data = failSeriesArray;
                setApiUsageDataTimelyOverViewChart((prevState) => ({
                    ...prevState,
                    options: { ...prevState.options, series: [successSeries, failSeries] },
                }));
            }
        } catch (err) {
            const { message: exception } = apiErrorHandlerV2(err);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (isLoading) {
            setCustomLoading((prevState) => ({ ...prevState, apiUsageDataOverviewTime: false }));
        }
    };

    /**
     * Load All Delivery Ids Based on Status
     */
    const loadAllDeliveryIds = async (query = {}, isLoading) => {
        if (isLoading) {
            setCustomLoading((prevState) => ({ ...prevState, deliveryId: false }));
        }
        try {
            const { data } = await getAllDeliveryIdsService(query);
            if (data && Array.isArray(data)) {
                const mappedData = data.map((x) => ({ ...x, value: x._id }));
                setAllDeliveryIds(mappedData);
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        }
        if (isLoading) {
            setCustomLoading((prevState) => ({ ...prevState, deliveryId: false }));
        }
    };

    /**
     * This function is responsible for getting success and error counts with merchants
     * @param {Object} params - clientId, fromDateTime and toDateTime should be there
     * @param {Boolean} isLoading
     * @returns Success and error counts according to merchant
     */
    const getPieChartDataMerchantVise = async (params, isLoading = true) => {
        if (!params.clientId) {
            toast.custom((t) => <CustomToast text="Refresh Again" t={t} type="error" />, {
                position: 'top-right',
            });
            return;
        }

        if (isLoading) {
            setCustomLoading((prevState) => ({ ...prevState, pieChartData: true }));
        }

        try {
            const { data } = await getDashboardSuccessErrorCountMerchantVise(params);
            setSuccessErrorMerchantVise(data.payload);
        } catch (err) {
            const { message: exception } = apiErrorHandlerV2(err);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (isLoading) {
            setCustomLoading((prevState) => ({ ...prevState, pieChartData: false }));
        }
    };

    const loadApiUsageOverviewTableData = (properties, showLoading = true) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getDashboardPartnerUsageTableData(properties)
            .then((res) => {
                const { data } = res;
                if (data?.payload) {
                    const docs = mapApiUsageOverviewTableDocs(data.payload.docs || []);
                    setApiUsageOverviewTableData({ ...apiUsageOverviewTableData, ...data.payload, docs });
                    // console.log('docs[0]', docs[0]);
                    // setModalOptions({
                    //     modal: 'request',
                    //     data: docs[0],
                    // });
                }
            })
            .catch((e) => {
                console.log(e);
                const { message } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const handleGenerateReport = async (updatedFormData) => {
        const { fromDateTime, toDateTime, clientId, merchantId } = updatedFormData;
        setFormData({ ...formData, fromDateTime, toDateTime, merchantId });
        getApiUsageStats({ fromDateTime, toDateTime, clientId, merchantId });
        getApiUsageDataOverview({ fromDateTime, toDateTime, clientId, merchantId });
        getApiUsageLatestStat({ clientId, merchantId });
        getApiUsageDataOverviewTime({ fromDateTime, toDateTime, clientId, merchantId });
        getPieChartDataMerchantVise({ fromDateTime, toDateTime, clientId, merchantId });
    };

    const handlePageChange = (pageNo) => {
        handleFilterRequest({ ...apiUsageOverviewTableData, ...tableFilterOptions, page: pageNo });
    };

    const handleOnClickApiUsageOverviewStatus = (status) => {
        setApiUsageDataOverview({ ...apiUsageDataOverView, status });
    };

    const displayRequestData = (data) => {
        setModalOptions({
            modal: 'request',
            data,
        });
    };

    /**
     * Change the modalOptions state so modals can be shown or hidden.
     * See comments on modalOptions state.
     * @param {string} modalName - name of the modal to show - false value hides modal
     */
    const handleModalToggle = (modalName) => {
        if (modalName) {
            setModalOptions((prev) => ({ ...prev, modal: modalName }));
        } else {
            setModalOptions((prev) => ({ ...prev, modal: null }));
        }
    };

    const handleSelectChange = (event, id) => {
        if (event?.value) {
            if (id === 'status') {
                setTableFilterOptions({
                    ...tableFilterOptions,
                    [id]: event.value,
                });
                return;
            }
            setTableFilterOptions({
                ...tableFilterOptions,
                [id]: event.value,
            });
        } else {
            setTableFilterOptions({
                ...tableFilterOptions,
                [id]: null,
            });
        }
    };

    const handleDateChange = (event) => {
        const { id, value } = event.target;
        setTableFilterOptions({
            ...tableFilterOptions,
            [id]: value ? new Date(value).toISOString() : null,
        });
    };

    function calculateFromDateValidation() {
        if (formData.fromDateTime === formData.toDateTime) {
            return true;
        }

        if (moment(formData.fromDateTime).isAfter(formData.toDateTime)) {
            return true;
        }

        return false;
    }

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    if (!(hasAccess(PERMISSIONS.PARTNER_API_CLIENT_API_DASHBOARD, ['View']) && !IS_MERCHANT_VIEW())) {
        return <AccessDenied pageName="API Dashboard" />;
    }

    return (
        <div className="page-content">
            <Container fluid>
                <CustomPeriodSection
                    handleGenerateReport={handleGenerateReport}
                    initialFormData={formData}
                    isShowCancelButton={false}
                    isClientDashboard
                />
                <Row className="flex">
                    {/** Analytics Cards */}
                    <Col xl={9} lg={12} md={12} sm={12}>
                        <Row>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <AnalyticsCard
                                    title="Last Requested At"
                                    value={apiUsageStats?.latestRequest?.latestRequestDate || ''}
                                    icon={sync}
                                    tooltipPlacement="bottom"
                                    tooltipTitle="Last Received"
                                    cardStyle={{
                                        height: 120,
                                        borderRadius: 10,
                                    }}
                                    loading={customLoading?.latestRequest}
                                />
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <AnalyticsCard
                                    title="Total Requests"
                                    value={apiUsageStats?.total?.requestCount || 0}
                                    icon={file}
                                    tooltipPlacement="bottom"
                                    tooltipTitle="Total Requests"
                                    cardStyle={{
                                        height: 120,
                                        borderRadius: 10,
                                    }}
                                    loading={customLoading?.apiUsageStats}
                                />
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <AnalyticsCard
                                    title="Success Requests"
                                    value={apiUsageStats?.success?.requestCount || 0}
                                    icon={successSvg}
                                    tooltipPlacement="bottom"
                                    tooltipTitle="Success Requests"
                                    percentage={getSuccessPercentage(
                                        apiUsageStats?.success?.requestCount,
                                        apiUsageStats?.total?.requestCount
                                    )}
                                    cardStyle={{
                                        height: 120,
                                        borderRadius: 10,
                                    }}
                                    loading={customLoading?.apiUsageStats}
                                />
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <AnalyticsCard
                                    title="Requests with Errors"
                                    value={apiUsageStats?.fail?.requestCount || 0}
                                    icon={error}
                                    tooltipPlacement="bottom"
                                    tooltipTitle="Errors"
                                    percentage={getErrorPercentage(
                                        apiUsageStats?.fail?.requestCount,
                                        apiUsageStats?.total?.requestCount
                                    )}
                                    cardStyle={{
                                        height: 120,
                                        borderRadius: 10,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 5 }}>
                            <Col md={5} sm={12}>
                                {/** Pie Chart */}
                                <GraphCard
                                    title="Success vs Error Requests"
                                    // isExportInBottom
                                    customClassName="pie-chart"
                                    hasPermission={
                                        hasAccess(PERMISSIONS.PARTNER_API_CLIENT_API_DASHBOARD, ['Export']) &&
                                        !IS_MERCHANT_VIEW()
                                    }
                                    cardStyle={{
                                        borderRadius: 10,
                                        minHeight: 400,
                                    }}
                                    handleExportOnclick={() => {
                                        setShowExportModalForPieChart(!showExportModalForPieChart);
                                    }}
                                >
                                    <Chart
                                        options={successAndErrorPieChart.options}
                                        series={successAndErrorPieChart.series}
                                        type="pie"
                                        width="100%"
                                    />
                                </GraphCard>
                                {showExportModalForPieChart && (
                                    <ExportModal
                                        data={{
                                            records: chartDataMapToExportableFormat(
                                                null,
                                                successErrorMerchantVise,
                                                'pieChartClient'
                                            ),
                                            columnNames: chartDataMapToExportableFormat(
                                                [
                                                    'Merchant',
                                                    'Number Of Success Requests',
                                                    'Number Of Error Requests',
                                                    'Success Percentage',
                                                    'Error Percentage',
                                                ],
                                                null,
                                                'columns'
                                            ),
                                        }}
                                        toggleModal={() => setShowExportModalForPieChart(!showExportModalForPieChart)}
                                        header="ScootiX API Service"
                                        description="Success Vs Error API Request Analysis"
                                        excelTabName="Success vs Error Requests"
                                        fileName={`ScootiX_API_SuccessVsError_Requests_${moment().format(
                                            'YYYYMMDDHHmmss'
                                        )}`}
                                        period={getDatePeriod(formData.fromDateTime, formData.toDateTime)}
                                        merchantName={getMerchantSelect(formData.merchantId)}
                                    />
                                )}
                            </Col>
                            <Col md={7} sm={12}>
                                {/** Horizontal Chart */}
                                <GraphCard
                                    cardStyle={{ borderRadius: 10, minHeight: 400 }}
                                    handleChipClick={handleOnClickApiUsageOverviewStatus}
                                    handleExportOnclick={() => {
                                        setShowExportModalForOverviewGraph(!showExportModalForOverviewGraph);
                                    }}
                                    hasPermission={
                                        hasAccess(PERMISSIONS.PARTNER_API_CLIENT_API_DASHBOARD, ['Export']) &&
                                        !IS_MERCHANT_VIEW()
                                    }
                                >
                                    <Chart
                                        options={apiUsageDataOverViewChart.options}
                                        series={apiUsageDataOverViewChart.series}
                                        type="bar"
                                        height="80%"
                                    />
                                </GraphCard>
                                {showExportModalForOverviewGraph && (
                                    <ExportModal
                                        data={{
                                            records: chartDataMapToExportableFormat(
                                                apiUsageDataOverViewChart.options.xaxis.categories,
                                                apiUsageDataOverViewChart.series[0].data,
                                                'overviewChart'
                                            ),
                                            columnNames: chartDataMapToExportableFormat(
                                                ['Request Type', 'Number Of Requests'],
                                                null,
                                                'columns'
                                            ),
                                        }}
                                        toggleModal={() =>
                                            setShowExportModalForOverviewGraph(!showExportModalForOverviewGraph)
                                        }
                                        header="ScootiX API Service"
                                        description={
                                            apiUsageDataOverView.status === 'success'
                                                ? 'Success Request Overview'
                                                : 'Error Request Overview'
                                        }
                                        excelTabName={
                                            apiUsageDataOverView.status === 'success'
                                                ? 'Success API Request Overview'
                                                : 'Error API Request Overview'
                                        }
                                        fileName={
                                            apiUsageDataOverView.status === 'success'
                                                ? `ScootiX_Success_API_Requests_Overview_${moment().format(
                                                      'YYYYMMDDHHmmss'
                                                  )}`
                                                : `ScootiX_Error_API_Requests_Overview_${moment().format(
                                                      'YYYYMMDDHHmmss'
                                                  )}`
                                        }
                                        period={getDatePeriod(formData.fromDateTime, formData.toDateTime)}
                                        merchantName={getMerchantSelect(formData.merchantId)}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>

                    {/** Columnar Chart */}
                    <Col xl={3} lg={12} md={12} sm={12}>
                        <GraphCard
                            title={`${timelyGraphType} Requests Overview`}
                            cardStyle={{
                                borderRadius: 10,
                                height: '100%',
                                width: '100%',
                                minHeight: '400px',
                                paddingLeft: 10,
                                maxHeight: '98.5%',
                            }}
                            handleExportOnclick={() => setShowExportModalForTimelyGraph(!showExportModalForTimelyGraph)}
                            hasPermission={
                                hasAccess(PERMISSIONS.PARTNER_API_CLIENT_API_DASHBOARD, ['Export']) &&
                                !IS_MERCHANT_VIEW()
                            }
                        >
                            {/**
                             * Here we are using React Wrapper for Apache Echarts library
                             */}
                            {customLoading.apiUsageDataOverviewTime ? (
                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                            ) : (
                                <ReactECharts
                                    option={apiUsageDataTimelyOverViewChart.options}
                                    style={{ height: '85%', width: '90%', justifyContent: 'center' }}
                                />
                            )}
                        </GraphCard>
                        {showExportModalForTimelyGraph && (
                            <ExportModal
                                data={{
                                    records: chartDataMapToExportableFormat(
                                        apiUsageDataTimelyOverViewChart.options.xAxis.data,
                                        apiUsageDataTimelyOverViewChart.options.series,
                                        'timelyChart',
                                        timelyGraphType
                                    ),
                                    columnNames: chartDataMapToExportableFormat(
                                        [timelyGraphType, 'Number Of Success Requests', 'Number Of Error Requests'],
                                        null,
                                        'columns'
                                    ),
                                }}
                                toggleModal={() => setShowExportModalForTimelyGraph(!showExportModalForTimelyGraph)}
                                header="ScootiX API Service"
                                description={`${timelyGraphType} Request Overview`}
                                excelTabName={`${timelyGraphType} Request Overview`}
                                fileName={`ScootiX_${timelyGraphType}_API_${moment().format('YYYYMMDDHHmmss')}`}
                                period={getDatePeriod(formData.fromDateTime, formData.toDateTime)}
                                merchantName={getMerchantSelect(formData.merchantId)}
                            />
                        )}
                    </Col>
                </Row>
                {/** Table Row */}
                <Row>
                    <Card style={{ borderRadius: 17, margin: 10, width: '100%' }}>
                        <CardBody style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0, width: '100%' }}>
                            <div
                                style={{
                                    backgroundColor: '#F9F7F7',
                                    borderTopRightRadius: 15,
                                    borderTopLeftRadius: 15,
                                    minHeight: 20,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div
                                    style={{
                                        padding: 23,
                                        color: '#807F7D',
                                        fontSize: 19,
                                        fontWeight: 'bold',
                                        fontFamily: 'Inter',
                                    }}
                                >
                                    API Requests Overview
                                </div>
                                {hasAccess(PERMISSIONS.PARTNER_API_CLIENT_API_DASHBOARD, ['Export']) && (
                                    <CustomizableButton
                                        buttonTitle="Export"
                                        icon={excelExport}
                                        customStyle={{ marginRight: 10 }}
                                        handleOnClick={() => setShowExportModalForTable(!showExportModalForTable)}
                                    />
                                )}
                                {showExportModalForTable && (
                                    <ExportModal
                                        data={{
                                            records: apiUsageOverviewTableData.docs,
                                            columnNames: exportHeadersClientTable,
                                        }}
                                        toggleModal={() => setShowExportModalForTable(!showExportModalForTable)}
                                        header="ScootiX API Service"
                                        description="API Requests"
                                        excelTabName="API Requests"
                                        fileName={`ScootiX_API_Requests_${moment().format('YYYYMMDDHHmmss')}`}
                                        period={getDatePeriod(formData.fromDateTime, formData.toDateTime)}
                                        merchantName={getMerchantSelect(formData.merchantId)}
                                    />
                                )}
                            </div>
                            {/** Filter Component */}
                            <Row className="pl-4 pr-4 mt-4">
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="status">Status</Label>
                                        <Select
                                            value={
                                                getDefaultValueForSelect(
                                                    tableFilterOptions.status,
                                                    statusDropDownValues,
                                                    'value'
                                                ) || {
                                                    label: 'All',
                                                    value: 'all',
                                                }
                                            }
                                            options={statusDropDownValues}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'status')}
                                            required
                                            isLoading={loading}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="statusCode">Status Code</Label>
                                        <Select
                                            value={
                                                getDefaultValueForSelect(
                                                    tableFilterOptions.statusCode,
                                                    [...partnerApiStatusCodeValues],
                                                    'value'
                                                ) || {
                                                    label: 'All',
                                                    value: 'all',
                                                }
                                            }
                                            options={[{ label: 'All', value: 'all' }, ...partnerApiStatusCodeValues]}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'statusCode')}
                                            required
                                            isLoading={loading}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="actionPath">Request Type</Label>
                                        <Select
                                            value={
                                                getDefaultValueForSelect(
                                                    tableFilterOptions.actionPath,
                                                    [...partnerApiSActionPathsDropDownValues],
                                                    'value'
                                                ) || {
                                                    label: 'All',
                                                    value: 'all',
                                                }
                                            }
                                            options={[
                                                { label: 'All', value: 'all' },
                                                ...partnerApiSActionPathsDropDownValues,
                                            ]}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'actionPath')}
                                            required
                                            isLoading={loading}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="merchantId">Merchant</Label>
                                        <Select
                                            value={
                                                getDefaultValueForSelect(
                                                    getMerchantSelect(tableFilterOptions.merchantId),
                                                    'value'
                                                ) || {
                                                    label: 'All',
                                                    value: 'All',
                                                }
                                            }
                                            options={merchants}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'merchantId')}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="referenceNumber">Delivery ID</Label>
                                        <Select
                                            value={
                                                getDefaultValueForSelect(
                                                    tableFilterOptions.referenceNumber,
                                                    allDeliveryIds.map((val) => ({
                                                        ...val,
                                                        value: val._id,
                                                        label: val.referenceNumber,
                                                    })),
                                                    'value'
                                                ) || {
                                                    label: 'All',
                                                    value: 'all',
                                                }
                                            }
                                            options={allDeliveryIds.map((val) => ({
                                                ...val,
                                                value: val._id,
                                                label: val.referenceNumber,
                                            }))}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'referenceNumber')}
                                            required
                                            isClearable
                                            isLoading={customLoading.deliveryId}
                                            maxMenuHeight={150}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="merchantOrderNo">Merchant Order Number</Label>
                                        <Select
                                            value={
                                                getDefaultValueForSelect(
                                                    tableFilterOptions.merchantOrderNo,
                                                    allDeliveryIds.map((val) => ({
                                                        ...val,
                                                        value: val._id,
                                                        label: val.merchantOrderNo,
                                                    })),
                                                    'value'
                                                ) || {
                                                    label: 'All',
                                                    value: 'all',
                                                }
                                            }
                                            options={allDeliveryIds.map((val) => ({
                                                ...val,
                                                value: val._id,
                                                label: val.merchantOrderNo,
                                            }))}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'merchantOrderNo')}
                                            required
                                            isClearable
                                            isLoading={customLoading.deliveryId}
                                            maxMenuHeight={150}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="fromDateTime">From Date and Time</Label>
                                        <Input
                                            onChange={handleDateChange}
                                            type="datetime-local"
                                            defaultValue={getDateValue(moment().startOf('day'), 'datetime-local')}
                                            className="form-control"
                                            value={getDateValue(tableFilterOptions.fromDateTime, 'datetime-local')}
                                            id="fromDateTime"
                                            invalid={calculateFromDateValidation()}
                                            max={getDateValue(moment(tableFilterOptions.toDateTime), 'datetime-local')}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="toDateTime">To Date and Time</Label>
                                        <Input
                                            onChange={handleDateChange}
                                            type="datetime-local"
                                            defaultValue={getDateValue(moment(), 'datetime-local')}
                                            className="form-control"
                                            value={getDateValue(tableFilterOptions.toDateTime, 'datetime-local')}
                                            id="toDateTime"
                                            max={getDateValue(moment(), 'datetime-local')}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                {/** Filter Button */}
                                <Col style={{ width: '12.5%', marginTop: 25, minWidth: 100 }}>
                                    <CustomizableButton
                                        icon={searchIcon}
                                        buttonTitle="Filter Request"
                                        handleOnClick={() => handleFilterRequest()}
                                    />
                                </Col>
                            </Row>
                            {/** Custom Table */}
                            <div>
                                <Row>
                                    <TableCustom
                                        tableData={apiUsageOverviewTableData}
                                        onPageChange={handlePageChange}
                                        loading={loading}
                                        showDelete={false}
                                        showView={false}
                                        removeId
                                        isFullScreenShow={false}
                                        filters={tableDefaultFilters}
                                        isFilter={false}
                                        isSearch={false}
                                        removeAction
                                        isFixedHeight
                                        showApiCustomAction={hasAccess(PERMISSIONS.PARTNER_API_CLIENT_API_DASHBOARD, [
                                            'View',
                                        ])}
                                        customActionHandlers={{ displayRequestData }}
                                    />
                                    <ApiRequestModal
                                        show={modalOptions.modal === 'request'}
                                        handleToggle={handleModalToggle}
                                        data={modalOptions?.data || {}}
                                    />
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
        </div>
    );
};

ClientDashboard.propTypes = {};

const HotClientDashboard = hot(ClientDashboard);

export default HotClientDashboard;
