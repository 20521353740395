/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Spinner, Label, Container, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as qs from 'query-string';
import toast from 'react-hot-toast';
import BootstrapTable from 'react-bootstrap-table-next';
import { motion } from 'framer-motion';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';
import PropTypes from 'prop-types';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { isObject } from 'lodash';
import { v4 as uuid } from 'uuid';

// #region imports
import Breadcrumbs from 'components/Common/Breadcrumb';
import PATHS from 'routes/paths';
import { BackdropLoader, AlertModal, Select, TooltipDefault, CustomToast } from 'components';

// #region utils
import {
    apiErrorHandler,
    capitalizeFirstLetter,
    getDateValue,
    getDefaultValueForSelect,
    reactSelectCustomStyles,
    mapUserObj,
} from 'utils/helpers';
import { getVisitingObject, getUser } from 'utils/checkAuth';
import { getRelevantBinLocationsBasedOnStocks } from 'utils/conditions/picking-task';
import { DEV_ENV, __BLOCKED_FOR_MERCHANTS__ } from 'utils/checks';

// #region services
import { createPicking, getPickingByIdService, updatePickingTaskService } from 'services/pickingtask.service';
import { getAllOrdersByStatusService, updateOrderService2 } from 'services/order.service';
import { getAllUsableStocks } from 'services/stocks';
import { getMerchantsByClientIdService } from 'services/merchant.service';
import { completeOrderItemPickingService, undoCompleteOrderItemPickingService } from 'services/order-items.service';

// #endregion imports
import { centerElementInnerStyles, SubTitle } from 'styles';
import {
    deletePickingTaskService,
    markOrdersAsCompletedInPickingTaskService,
    removeOrderFromPickingTask,
} from '../services';

import { pickingTaskOrderItemsColumns, pickingTaskOrders } from './constants';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Order List', link: PATHS.ORDER_PROCESSING.DEFAULT },
];

let initialFormValues = {
    taskStartDate: new Date(),
    merchantId: null,
    merchantName: null,
};

function PickingTaskFormPage(props) {
    const { visitingClient, visitingMerchant } = getVisitingObject();
    const history = useHistory();
    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [customOrderColumns, setCustomOrderColumns] = useState(pickingTaskOrders.columns);
    const [customOrderItemsColumns] = useState(pickingTaskOrderItemsColumns);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [tableKey, setTableKey] = useState(uuid());
    const [subTableKey, setSubTableKey] = useState(uuid());

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);

    const [merchants, setMerchants] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [allOrderItems, setAllOrderItems] = useState([]);

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [showMarkAsCompleted, setShowMarkAsCompleted] = useState(false);

    const [allUsableStocks, setAllUsableStocks] = useState([]);

    const [filteredOrders, setFilteredOrders] = useState(null);
    const [filterOptions, setFilterOptions] = useState({
        merchantId: null,
        orderReferenceNumber: null,
    });

    useEffect(() => {
        loadAllUsableStocks();
    }, []);

    useEffect(() => {
        if (!props.location) {
            history.goBack();
            return;
        }
        const formType = qs.parse(props.location.search);
        if (formType && formType.type && formType.type === 'new') {
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Task', link: '#' }]);
            loadAllOrders();
            return;
        }
        const { taskParentData } = props.location.state;

        if (formType && formType.type && formType.type === 'edit') {
            if (!taskParentData || !props.location.state) {
                history.goBack();
                return;
            }

            setFormData({ ...formData, ...taskParentData });
            setSelectedOrders(taskParentData.orders.map((x) => x._id));
            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Task', link: '#' }]);
        }
        if (formType && formType.type && formType.type === 'view') {
            if (!taskParentData || !props.location.state) {
                history.goBack();
                return;
            }
            setFormData({ ...formData, ...taskParentData });
            setIsViewMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'View Task', link: '#' }]);
        }
    }, [props.location]);

    useEffect(() => {
        if (editingMode) {
            setCustomOrderColumns([...customOrderColumns, renderColumnButtons]);
        }
    }, [editingMode]);

    useEffect(() => {
        if (allOrders && allOrders.length) {
            handleAutoCompletePickingTask();
        }
    }, [allOrders, editingMode, formData.allOrders]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    useEffect(() => {
        if (formData._id) {
            loadPickingTaskById(formData._id, true);
        }
    }, [formData._id]);

    const loadAllUsableStocks = async () => {
        try {
            const { data } = await getAllUsableStocks();
            if (data && Array.isArray(data)) {
                setAllUsableStocks(
                    data.map((x) => ({
                        ...x,
                        label: x.binLocationReferenceNumber,
                        value: x.binLocationReferenceNumber,
                    }))
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (allOrders.length > 0) {
            mapOrderItems();
        }
    }, [allUsableStocks, allOrders]);

    useEffect(() => {
        let filteredItems = allOrders;
        let doFilter = false;
        if (filterOptions.merchantId) {
            filteredItems = allOrders.filter(
                (x) => x.merchantId.toLowerCase().indexOf(filterOptions.merchantId.toLowerCase()) !== -1
            );
            doFilter = true;
        }

        if (filterOptions.orderReferenceNumber) {
            filteredItems = filteredItems.filter(
                (x) => x.referenceNumber.toLowerCase().indexOf(filterOptions.orderReferenceNumber.toLowerCase()) !== -1
            );
            doFilter = true;
        }
        doFilter && setFilteredOrders(filteredItems);
    }, [filterOptions.merchantId, filterOptions.orderReferenceNumber]);

    const handleAutoCompletePickingTask = async () => {
        let allowShowButton = true;
        const isAnyOrdersInPicking = allOrders.some((x) => x.status === 'picking');

        allOrders.map((x) => {
            if (x.orderItems) {
                const isAnyOrderItemsPickingNotCompleted = x.orderItems.some((y) => !y.isPickingCompleted);
                if (isAnyOrderItemsPickingNotCompleted) {
                    allowShowButton = false;
                }
            }
            return x;
        });

        if (formData._id && formData.status === 'completed' && isAnyOrdersInPicking) {
            setBackdropLoading((prevState) => prevState + 1);
            try {
                const { data } = await updatePickingTaskService({ ...formData, status: 'pending' });
                if (data) {
                    setFormData((prevState) => ({ ...prevState, status: 'pending' }));
                }
                setShowMarkAsCompleted(false);
            } catch (e) {
                console.log(e);
            }
            setBackdropLoading((prevState) => prevState - 1);
        }

        if (allowShowButton && isAnyOrdersInPicking) {
            setShowMarkAsCompleted(true);
            return;
        }

        if (!allowShowButton && isAnyOrdersInPicking) {
            setShowMarkAsCompleted(false);
            return;
        }

        if (formData._id && formData.status === 'pending' && allowShowButton && !isAnyOrdersInPicking) {
            setBackdropLoading((prevState) => prevState + 1);
            try {
                const { data } = await updatePickingTaskService({ ...formData, status: 'completed' });
                if (data) {
                    setFormData((prevState) => ({ ...prevState, status: 'completed' }));
                }
                toast.custom((t) => <CustomToast text="Successfully Completed Picking Task" t={t} type="success" />, {
                    position: 'top-right',
                });
                setShowMarkAsCompleted(false);
            } catch (e) {
                console.log(e);
            }
            setBackdropLoading((prevState) => prevState - 1);
        }
    };

    const loadPickingTaskById = async (_id, isLoad) => {
        if (isLoad && !DEV_ENV()) {
            setBackdropLoading((prevState) => prevState + 1);
        }

        try {
            const { data } = await getPickingByIdService(_id);
            if (data) {
                setFormData({ ...formData, ...data });
                setAllOrders(mapDocs(data.orders));
            }
        } catch (e) {
            console.log(e);
        }

        if (isLoad && !DEV_ENV()) {
            setBackdropLoading((prevState) => prevState - 1);
        }
    };

    const mapDocs = (docs) => {
        let allDocs = [];
        allDocs = docs.map((x) => ({
            ...x,
            merchant: x.merchantId && isObject(x.merchantId) ? x.merchantId : null,
            merchantId: x.merchantId && isObject(x.merchantId) ? x.merchantId._id : x.merchantId,
            merchantName: x.merchantId && isObject(x.merchantId) ? x.merchantId.name : null,
            placedDateTimeFormatted: moment(x.placedDateTime).format('DD--MM-YY hh:mm A'),
        }));
        return allDocs;
    };

    const mapOrderItems = () => {
        const allOrderItemsProp = [];
        allOrders.map((order) => {
            order.orderItems.map((val, i) => {
                let item = val;

                if (!item.isPickingCompleted) {
                    const {
                        selectedBinLocationsArray,
                        selectedBinLocationsString,
                        isStocksAvailable,
                        selectedStocks,
                        orderItemStockStatus,
                        remainingQty,
                    } = getRelevantBinLocationsBasedOnStocks(allUsableStocks, val);
                    item = {
                        ...item,
                        selectedBinLocationsString,
                        isStocksAvailable,
                        selectedStocks,
                        selectedBinLocationsArray,
                        orderItemStockStatus,
                        remainingQty,
                    };
                } else {
                    const { remainingQty } = getRelevantBinLocationsBasedOnStocks(allUsableStocks, val);
                    item = {
                        ...item,
                        selectedBinLocationsString: '',
                        isStocksAvailable: true,
                        remainingQty,
                    };
                }

                // Picking Status
                item = {
                    ...item,
                    pickingStatus: item.isPickingCompleted ? 'Completed' : 'Not Completed',
                };

                allOrderItemsProp.push(item);
                return item;
            });
            return order;
        });
        setAllOrderItems(allOrderItemsProp);
    };

    const loadAllOrders = () => {
        setBackdropLoading((prevState) => prevState + 1);
        getAllOrdersByStatusService('new')
            .then((res) => {
                const { data } = res;
                if (data.docs && Array.isArray(data.docs)) {
                    setAllOrders(mapDocs(data.docs));
                }
            })
            .finally(() => {
                setBackdropLoading((prevState) => prevState - 1);
            });
    };

    // Expand Order Items Row
    const expandRowOrderItems = {
        renderer: (row) => {
            const allItems = allOrderItems.filter((x) => x.orderId === row._id);
            return (
                <BootstrapTable
                    keyField="_id"
                    key={subTableKey}
                    data={
                        //     allItems.map((x) => ({
                        //     ...x,
                        //     binLocationReferenceNumber: x.binLocationReferenceNumber
                        //         ? x.binLocationReferenceNumber
                        //         : isObject(x.binLocationId)
                        //         ? x.binLocationId.binLocationReferenceNumber
                        //         : '',
                        // }))
                        allItems
                    }
                    columns={[
                        ...customOrderItemsColumns,
                        {
                            dataField: 'selectedBinLocationsString',
                            text: 'Selected Bin Locations',
                            // editor: {
                            //     type: Type.SELECT,
                            //     options: allUsableStocks,
                            // },
                            sort: 'asc',
                            width: 200,
                            editable: false,
                        },
                        {
                            text: 'Stock Status',
                            dataField: 'orderItemStockStatus',
                            sort: 'asc',
                            width: 200,
                        },
                        {
                            text: 'Batch',
                            dataField: 'batch',
                            sort: 'asc',
                            width: 200,
                        },
                        {
                            text: 'Serial',
                            dataField: 'serial',
                            sort: 'asc',
                            width: 200,
                        },
                        {
                            text: 'Picking Status',
                            dataField: 'pickingStatus',
                            sort: 'asc',
                            width: 200,
                        },
                        editingMode && renderOrderItemsColumnButtons,
                    ]}
                    cellEdit={cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: handleChange,
                        editable: false,
                    })}
                />
            );
        },
        showExpandColumn: true,
    };

    function handleChange(oldValue, newValue, row, column) {
        if (newValue && column) {
            const { dataField } = column;
            if (dataField === 'binLocationReferenceNumber') {
                const selectedRow = allOrderItems.find((x) => x._id === row._id);
                const relevantObj = allUsableStocks.find((x) => x.binLocationReferenceNumber === newValue);
                if (selectedRow && relevantObj) {
                    selectedRow[dataField] = newValue;
                    selectedRow.binLocationReferenceNumber = newValue;
                    const filteredNewItems = allOrderItems.filter((x) => x._id !== row._id);
                    setAllOrderItems([...filteredNewItems, selectedRow]);
                }
                return;
            }
            const selectedRow = allOrderItems.find((x) => x._id === row._id);
            if (selectedRow) {
                selectedRow[dataField] = newValue;
            }
            const filteredNewItems = allOrderItems.filter((x) => x._id !== row._id);
            setAllOrderItems([...filteredNewItems, selectedRow]);
        }
    }

    const renderColumnButtons = {
        dataField: 'edit',
        text: '',
        sort: false,
        formatter: renderCompleteOrder,
        headerAttrs: { width: 50 },
        attrs: { width: 50, className: 'picking-task-order-edit-column' },
        style: centerElementInnerStyles,
    };

    const renderOrderItemsColumnButtons = {
        dataField: 'isPickingCompleted',
        text: '',
        sort: false,
        formatter: renderCompleteOrderItem,
        headerAttrs: { width: 50 },
        attrs: { width: 50, className: 'picking-task-order-edit-column' },
        style: centerElementInnerStyles,
        editable: false,
    };

    function renderCompleteOrder(cell, row) {
        const isAnyOrderItemsPickingCompleted = row.orderItems.some((x) => x.isPickingCompleted);
        const isAnyOrderItemsPickingNotCompleted = row.orderItems.some((x) => !x.isPickingCompleted);
        const isAllOrderItemsPacked = row.orderItems.every((x) => x.isPackingCompleted);
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <>
                    {row.status === 'picking' && !isAnyOrderItemsPickingCompleted && (
                        <>
                            <motion.div
                                whileHover={{ scale: 1.25 }}
                                whileTap={{ scale: 0.9 }}
                                className="w3-animate-opacity ml-3 pointer"
                                onClick={() => row.status === 'picking' && handleRemoveOrder(row, cell)}
                            >
                                <i
                                    id="remove-order"
                                    className="far fa-trash-alt fa-lg"
                                    style={{ color: row.status === 'picking' ? '#FF2825' : 'grey' }}
                                />
                            </motion.div>
                            <TooltipDefault targetId="remove-order" text="Remove Order" />
                        </>
                    )}
                    {row.status === 'picking' && !isAnyOrderItemsPickingNotCompleted && (
                        <>
                            <motion.div
                                whileHover={{ scale: 1.25 }}
                                whileTap={{ scale: 0.9 }}
                                className="w3-animate-opacity ml-3 pointer"
                                onClick={() => row.status === 'picking' && handleCompleteOrder(row, cell)}
                            >
                                <i
                                    id="complete-icon"
                                    className="far fa-check-circle fa-lg"
                                    aria-disabled
                                    style={{ color: row.status === 'picking' ? '#FFBF00' : 'grey' }}
                                />
                            </motion.div>
                            <TooltipDefault targetId="complete-icon" text="Completed Order" />
                        </>
                    )}
                    {row.status === 'packaging' && !isAllOrderItemsPacked && (
                        <>
                            <motion.div
                                whileHover={{ scale: 1.25 }}
                                whileTap={{ scale: 0.9 }}
                                className="w3-animate-opacity ml-3 pointer"
                                onClick={() => handleUndoCompleteOrder(row, cell)}
                            >
                                <i
                                    id="complete-icon"
                                    className="fas fa-undo fa-lg"
                                    aria-disabled
                                    style={{ color: 'grey' }}
                                />
                            </motion.div>
                            <TooltipDefault targetId="complete-icon" text="Undo Complete Order" />
                        </>
                    )}
                </>
            </div>
        );
    }

    function renderCompleteOrderItem(cell, row) {
        const relevantOrder = allOrders.find((x) => x._id === row.orderId);
        let showExtraButtons = true;
        if (relevantOrder) {
            if (relevantOrder.status === 'packaging') {
                showExtraButtons = false;
            }
        }

        if (row.isPackingCompleted) {
            showExtraButtons = false;
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <>
                    {(!row.isPickingCompleted || row.isPickingCompleted === 'false') &&
                        row.orderItemStockStatus === 'Available' &&
                        showExtraButtons && (
                            <>
                                <motion.div
                                    whileHover={{ scale: 1.25 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="w3-animate-opacity ml-3 pointer"
                                    onClick={() => handleCompleteOrderItem(row, cell)}
                                >
                                    <i
                                        id="complete-icon"
                                        className="far fa-check-circle fa-lg"
                                        aria-disabled
                                        style={{ color: 'grey' }}
                                    />
                                </motion.div>
                                <TooltipDefault targetId="complete-icon" text="Complete Picking Order Item" />
                            </>
                        )}
                    {!(!row.isPickingCompleted || row.isPickingCompleted === 'false') && showExtraButtons && (
                        <>
                            <motion.div
                                whileHover={{ scale: 1.25 }}
                                whileTap={{ scale: 0.9 }}
                                className="w3-animate-opacity ml-3 pointer"
                                onClick={() => handleUndoCompleteOrderItem(row, cell)}
                            >
                                <i
                                    id="undo-icon"
                                    className="fas fa-undo fa-lg"
                                    aria-disabled
                                    style={{ color: 'grey' }}
                                />
                            </motion.div>
                            <TooltipDefault targetId="undo-icon" text="Undo Complete Picking Order Item" />
                        </>
                    )}
                </>
            </div>
        );
    }

    const handleMarkAsCompleted = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await markOrdersAsCompletedInPickingTaskService({ allOrders });
            await loadPickingTaskById(formData._id, false);
            toast.custom((t) => <CustomToast text="Successfully Completed Picking Task" t={t} type="success" />, {
                position: 'top-right',
            });
            setShowMarkAsCompleted(false);
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleRemoveOrder = async (row) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await removeOrderFromPickingTask(formData._id, row._id);
            await loadPickingTaskById(formData._id, false);
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleCompleteOrder = async (row) => {
        setBackdropLoading((prevState) => prevState + 1);
        const userData = getUser();
        try {
            await updateOrderService2(row._id, {
                ...row,
                status: 'packaging',
                isPackaging: true,
                pickingCompletedAt: new Date(),
                pickedBy: mapUserObj(userData),
            });
            if (formData._id) {
                await loadPickingTaskById(formData._id, false);
                toast.custom((t) => <CustomToast text="Completed Picking Order" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            console.log(e);
        }
        setTableKey(uuid());
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleUndoCompleteOrder = async (row) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await updateOrderService2(row._id, {
                ...row,
                status: 'picking',
                isPicking: true,
                pickingCompletedAt: null,
                pickedBy: null,
                isPackaging: false,
            });
            if (formData._id) {
                await loadPickingTaskById(formData._id, false);
                toast.custom((t) => <CustomToast text="Undo Completed Picking Order" t={t} type="info" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
        setTableKey(uuid());
    };

    const handleCompleteOrderItem = async (row) => {
        setBackdropLoading((prevState) => prevState + 1);
        const userData = getUser();
        try {
            const relevantOrderItem = allOrderItems.find((x) => x._id === row._id);

            if (relevantOrderItem) {
                await completeOrderItemPickingService({
                    ...relevantOrderItem,
                    isPickingCompleted: true,
                    pickingCompletedAt: new Date(),
                    pickedBy: mapUserObj(userData),
                    selectedBinLocationsArray: row.selectedBinLocationsArray || [],
                    selectedBinLocationsString: row.selectedBinLocationsString || '',
                });
                if (formData._id) {
                    await loadPickingTaskById(formData._id, false);
                    toast.custom((t) => <CustomToast text="Completed Picking Order Item" t={t} type="success" />, {
                        position: 'top-right',
                    });
                }
            }
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setSubTableKey(uuid());
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleUndoCompleteOrderItem = async (row) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const relevantOrderItem = allOrderItems.find((x) => x._id === row._id);
            if (relevantOrderItem) {
                await undoCompleteOrderItemPickingService({
                    ...relevantOrderItem,
                    isPickingCompleted: false,
                    pickingCompletedAt: null,
                    pickedBy: null,
                    selectedBinLocationsArray: [],
                    selectedBinLocationsString: '',
                });
                await loadAllUsableStocks();
                if (formData._id) {
                    await loadPickingTaskById(formData._id, false);
                    toast.custom((t) => <CustomToast text="Completed Undo Picking Order Item" t={t} type="info" />, {
                        position: 'top-right',
                    });
                }
            }
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setSubTableKey(uuid());
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleDeletePickingTask = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deletePickingTaskService(formData._id);
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
        history.push({
            pathname: `${PATHS.PICKING_TASK.DEFAULT}`,
        });
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        if (selectedOrders.length < 1) {
            toast.custom((t) => <CustomToast text="Select At least one order" t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }
        setFormLoading(true);
        const payload = { ...formData, orders: !editingMode ? selectedOrders : allOrders };

        let updatedPayloadData = null;

        try {
            if (editingMode) {
                const { data } = await updatePickingTaskService({
                    ...payload,
                    status: showMarkAsCompleted ? 'pending' : 'completed',
                });
                loadPickingTaskById(formData._id, false);
                if (data) {
                    setFormData({
                        ...formData,
                        ...data,
                    });
                    updatedPayloadData = data;
                }
            } else {
                const { data } = await createPicking(payload);
                if (data) {
                    setFormData({
                        ...formData,
                        ...data,
                    });
                    loadPickingTaskById(data._id, false);
                    updatedPayloadData = data;
                }
            }
            setEditingMode(true);
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} Picking Task`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setIsFormSaved(true);
        } catch (e) {
            console.log(e);
            toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                position: 'top-right',
            });
        }

        try {
            if (updatedPayloadData) {
                history.replace({
                    pathname: `${PATHS.PICKING_TASK.FORM}`,
                    search: `?type=edit`,
                    state: {
                        taskParentData: updatedPayloadData,
                    },
                });
            }
        } catch (e) {
            console.log(e);
        }

        setFormLoading(false);
        setSubmitted(false);
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);

        if (id === 'merchantId') {
            const relevantMerchant = merchants.find((x) => x._id === event.value);
            const ordersByMerchant = allOrders.filter((x) => x.merchantId === event.value);
            setFilteredOrders(ordersByMerchant);
            if (relevantMerchant) {
                setFormData({
                    ...formData,
                    merchantName: relevantMerchant.name || '',
                    [id]: event ? event.value : null,
                });
            }
            return;
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    // Get filtered Order numbers
    const getOrderNoSelect = (_id) => {
        const relevantOrderNo = filteredOrders.find((x) => x.referenceNumber === _id);
        if (relevantOrderNo) {
            return relevantOrderNo.referenceNumber;
        }
        return null;
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });

        // if (id === 'merchantOrderNo') {
        // const filteredItems = allOrders.filter(
        //     (order) => order.referenceNumber.includes(value) || order.referenceNumber.includes(value)
        // );

        //     setFilteredOrders(filteredItems);
        // }
    };

    const handleOnSelectRow = (row, isSelect) => {
        if (isSelect) {
            setSelectedOrders([...selectedOrders, row._id]);
        } else {
            setSelectedOrders(selectedOrders.filter((x) => x !== row._id));
        }
    };

    const handleOnSelectAllRow = (isSelect, rows) => {
        const ids = rows.map((r) => r._id);
        if (isSelect) {
            setSelectedOrders(ids);
        } else {
            setSelectedOrders([]);
        }
    };

    const handleFilterChange = (event, fieldId) => {
        if (fieldId && fieldId === 'merchantId') {
            setFilterOptions({
                ...filterOptions,
                [fieldId]: event ? event.value : null,
            });
            return;
        }
        if (fieldId && fieldId === 'orderReferenceNumber') {
            setFilterOptions({
                ...filterOptions,
                [fieldId]: event ? event.value : null,
            });
            return;
        }
        const { id, value } = event.target;
        setFilterOptions({
            ...filterOptions,
            [id]: value,
        });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs breadcrumbItems={breadcrumbItems} />
                    <Row style={{ justifyContent: 'space-between', alignItems: 'center', margin: 2 }}>
                        <div>
                            {!editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md pointer"
                                    onClick={() => {
                                        history.push({
                                            pathname: `${PATHS.PICKING_TASK.DEFAULT}`,
                                        });
                                    }}
                                >
                                    <i className="far fa-times-circle mr-2 fa-lg" />
                                    Cancel
                                </motion.div>
                            )}
                        </div>
                        {editingMode && (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md pointer"
                                onClick={() => {
                                    history.push({
                                        pathname: `${PATHS.PICKING_TASK.DEFAULT}`,
                                    });
                                }}
                            >
                                <i className="fas fa-arrow-left mr-2 fa-lg" />
                                Back to Picking
                            </motion.div>
                        )}
                    </Row>
                    <Card>
                        <CardBody>
                            <Row className="d-flex justify-content-between ml-3 mr-3 ml-n2 mb-3">
                                <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 2 }}>
                                    PICKING TASK{' '}
                                </SubTitle>
                            </Row>
                            <AvForm
                                className="needs-validation"
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                                id="pickingTaskFormId"
                                // onValidSubmit={handleSubmit}
                            >
                                <Row>
                                    {editingMode && (
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="referenceNumber">Reference</Label>
                                                <AvField
                                                    name="referenceNumber"
                                                    placeholder="Reference"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    onChange={handleInputChange}
                                                    value={formData.referenceNumber}
                                                    id="referenceNumber"
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    )}
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="merchantId">Select A Merchant</Label>
                                            <Select
                                                value={getDefaultValueForSelect(getMerchantSelect(formData.merchantId))}
                                                options={merchants}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                submitted={submitted}
                                                required
                                                validated={validated}
                                                isDisabled={__BLOCKED_FOR_MERCHANTS__}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="pickerName">Picker Name</Label>
                                            <AvField
                                                name="pickerName"
                                                placeholder="Picker Name"
                                                type="text"
                                                errorMessage="Required"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                onChange={handleInputChange}
                                                value={formData.pickerName}
                                                id="pickerName"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="taskStartDate">Start the task on</Label>
                                            <Input
                                                onChange={handleInputChange}
                                                className="form-control"
                                                type="datetime-local"
                                                value={getDateValue(formData.taskStartDate, 'datetime-local')}
                                                defaultValue={getDateValue(new Date(), 'datetime-local')}
                                                validate={{ required: { value: true } }}
                                                id="taskStartDate"
                                            />
                                        </FormGroup>
                                    </Col>
                                    {(editingMode || isViewMode) && (
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="status-id">Status</Label>
                                                <AvField
                                                    name="status"
                                                    placeholder="Status"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    value={
                                                        formData.status ? capitalizeFirstLetter(formData.status) : ''
                                                    }
                                                    disabled
                                                    id="status-id"
                                                />
                                            </FormGroup>
                                        </Col>
                                    )}
                                </Row>
                                {formData.merchantId && (
                                    <Row>
                                        <Col xs={12}>
                                            <Card>
                                                <CardBody>
                                                    <p className="card-title-desc">
                                                        Select at least one order from the list.
                                                    </p>
                                                    {!editingMode && (
                                                        <Row>
                                                            <Col md={3}>
                                                                <Label htmlFor="merchant">Search by Merchant</Label>
                                                                <Select
                                                                    value={getDefaultValueForSelect(
                                                                        getMerchantSelect(filterOptions.merchantId)
                                                                    )}
                                                                    options={merchants}
                                                                    styles={reactSelectCustomStyles}
                                                                    onChange={(event) =>
                                                                        handleFilterChange(event, 'merchantId')
                                                                    }
                                                                    isClearable
                                                                />
                                                            </Col>
                                                            <Col md={3}>
                                                                <Label htmlFor="orderReferenceNumber">
                                                                    Search by Order No
                                                                </Label>
                                                                <Select
                                                                    value={getDefaultValueForSelect(
                                                                        getOrderNoSelect(
                                                                            filterOptions.orderReferenceNumber
                                                                        )
                                                                    )}
                                                                    options={filteredOrders.map((x) => ({
                                                                        label: x.referenceNumber || '',
                                                                        value: x.referenceNumber,
                                                                    }))}
                                                                    styles={reactSelectCustomStyles}
                                                                    onChange={(event) =>
                                                                        handleFilterChange(
                                                                            event,
                                                                            'orderReferenceNumber'
                                                                        )
                                                                    }
                                                                    isClearable
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {!editingMode && (
                                                        <BootstrapTable
                                                            keyField="_id"
                                                            data={filteredOrders || allOrders}
                                                            columns={customOrderColumns}
                                                            selectRow={{
                                                                mode: 'checkbox',
                                                                clickToSelect: false,
                                                                selected: selectedOrders,
                                                                onSelect: handleOnSelectRow,
                                                                onSelectAll: handleOnSelectAllRow,
                                                            }}
                                                            expandRow={expandRowOrderItems}
                                                        />
                                                    )}
                                                    {editingMode && (
                                                        <BootstrapTable
                                                            keyField="_id"
                                                            data={allOrders}
                                                            columns={customOrderColumns}
                                                            expandRow={expandRowOrderItems}
                                                            key={tableKey}
                                                        />
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            </AvForm>
                            <Row>
                                <motion.button
                                    form="pickingTaskFormId"
                                    formTarget="pickingTaskFormId"
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 pointer"
                                    type="submit"
                                    disabled={formLoading}
                                >
                                    {formLoading ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>
                                            {/* {hasAccess(PERMISSIONS.PICKING, ['View', 'Edit']) && ( */}
                                            <>
                                                <i className="fas fa-save left-icon"></i>
                                                <span className="h6">{editingMode ? 'Update' : 'Save'}</span>
                                            </>
                                            {/* )} */}
                                        </>
                                    )}
                                </motion.button>
                                {showMarkAsCompleted && editingMode && allOrderItems.length > 0 && (
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 pointer"
                                        onClick={() => handleMarkAsCompleted()}
                                    >
                                        <i className="fas fa-save left-icon"></i>
                                        <span className="h6">Mark as Completed</span>
                                    </motion.button>
                                )}
                                {/* {editingMode && hasAccess(PERMISSIONS.PICKING, ['View', 'Edit', 'Delete']) && ( */}
                                {editingMode && (
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-btn-radius secondary-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 pointer"
                                        onClick={() => setShowDeleteModal(true)}
                                    >
                                        <i className="fas fa-minus-circle left-icon"></i>
                                        <span className="h6">Delete</span>
                                    </motion.button>
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.8} />
            <AlertModal
                show={showDeleteModal}
                onConfirm={handleDeletePickingTask}
                data={formData}
                onHide={() => setShowDeleteModal(false)}
            />
        </>
    );
}

PickingTaskFormPage.propTypes = {
    location: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotPickingTaskPage = hot(PickingTaskFormPage);

export default connect(mapStateToProps, {})(HotPickingTaskPage);
