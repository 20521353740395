// #region utils
import { getVisitingObject } from 'utils/checkAuth';

// #region other imports
import { DELIVERY_TYPES, VEHICLE_TYPES } from 'constants/constants';

export const DELIVERY_TYPE_OPTIONS = [
    {
        label: DELIVERY_TYPES.NEW_ORDER,
        value: DELIVERY_TYPES.NEW_ORDER,
    },
    {
        label: DELIVERY_TYPES.RETURN_ORDER,
        value: DELIVERY_TYPES.RETURN_ORDER,
    },
    {
        label: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
        value: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
    },
];

export const VEHICLE_TYPE_OPTIONS = [
    { label: 'Motorbike', value: VEHICLE_TYPES.MOTOR_BIKE },
    { label: 'Car', value: VEHICLE_TYPES.CAR },
    { label: 'Van - 7FT', value: VEHICLE_TYPES.VAN_SEVEN_FEET },
    { label: 'Truck', value: VEHICLE_TYPES.TRUCK },
];

export const RIDER_STATUS_OPTIONS = [
    { label: 'All', value: 'All' },
    { label: 'Online ', value: 'Online' },
];

/**
 * Build Initial Form Values
 * @returns {object}
 */
export const buildInitialFormValues = () => {
    let initialForm = {};

    const { visitingClient, visitingMerchant } = getVisitingObject();

    if (visitingClient) {
        initialForm = {
            ...initialForm,
            clientId: visitingClient._id,
        };
    }

    if (visitingMerchant) {
        initialForm = {
            ...initialForm,
            merchantId: visitingMerchant._id,
        };
    }

    initialForm.vehicleType = VEHICLE_TYPES.MOTOR_BIKE;
    initialForm.riderStatus = RIDER_STATUS_OPTIONS[0].value;

    return initialForm;
};
