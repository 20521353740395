import React from 'react';

export default function MerchantSettingImportType() {
    return (
        <>
            <div className="font-size-18">
                Once an Import Type is Selected the Excel Import will work according to the relevant business Model
            </div>
        </>
    );
}
