import moment from 'moment';

/**
 * This function is responsible for mapping data which came from the backend to the table list view
 */
export const mapRoutePlanningEntryTableDocs = (docs) => {
    let allDocs = [];
    const manyMerchants = 'Various';
    allDocs = docs.map((doc) => ({
        ...doc,
        merchantReferenceNumber: doc.merchants.length > 1 ? manyMerchants : doc.merchants[0]?.referenceNumber,
        merchantName: doc.merchants.length > 1 ? manyMerchants : doc.merchants[0]?.name,
        riderName: `${doc.rider?.fullName}\n${doc.rider?.referenceNumber}`,
        deliveryDate: moment(doc.deliveryDate).format('DD-MM-YYYY'),
        plannedAt: moment(doc.createdAt).format('DD-MM-YYYY HH:mm'),
        plannedBy: doc.createdByUserFullName,
    }));
    return allDocs;
};
