// @flow
import { INPUT_SOCKET_RIDERS } from './actionTypes';

const INIT_STATE = {
    allSocketRiders: [],
};

const RiderManagement = (state = INIT_STATE, action) => {
    switch (action.type) {
        case INPUT_SOCKET_RIDERS:
            return {
                ...state,
                allSocketRiders: action.payload,
            };
        default:
            return state;
    }
};

export default RiderManagement;
