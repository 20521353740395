/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from 'react';
import { Container, Spinner } from 'reactstrap';
import Lottie from 'react-lottie';
import { motion } from 'framer-motion';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';

// #region components | assets
import { centerElementContainerStyles } from 'styles';
import SearchingRedLottie from 'assets/animations/10690-searching.json';

// #region utils
import { __DEV__ } from 'utils/checks';

// #endregion imports
import { mapLottieOptionsLoading } from '../../constants';
import RiderMarker from './rider-marker';

export default function App(props) {
    const {
        parentLoading = 0,
        allRidersByClient = [],
        setSelectedRider,
        selectedRider,
        allRidersByClientFixed,
    } = props;

    const _isMounted = useRef(true);

    const { isLoaded } = useLoadScript({
        // ! FIXME
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY || 'AIzaSyCGQIG6NR8l05WBRqgrBO2MX7XYy-9_a1Q',
        id: 'google-map-script',
    });

    const [loading, setLoading] = useState(1);

    const [initialLoading, setInitialLoading] = useState(true);

    const [selectedRiderCoordinates, setSelectedRiderCoordinates] = useState(null);

    // goe
    const [currentGeo, setCurrentGeo] = useState(null);
    const [customCenter, setCustomCenter] = useState(null);

    const [mapRef, setMapRef] = React.useState(null);

    useEffect(() => {
        const timeout = setTimeout(
            () => {
                if (_isMounted.current) {
                    setLoading(0);
                }
            },
            __DEV__ ? 1500 : 3000
        );
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        if (selectedRider && _isMounted.current) {
            if (selectedRider.selectedType === 'card' && selectedRider.rider && selectedRider.rider.coordinates) {
                setSelectedRiderCoordinates(selectedRider.rider.coordinates);
            }
        }
    }, [selectedRider]);

    // effect to get geo data
    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position && _isMounted.current) {
                    setCurrentGeo({
                        coordinates: {
                            lng: position.coords.longitude,
                            lat: position.coords.latitude,
                        },
                    });
                }
            });
            if (_isMounted.current) {
                setInitialLoading(false);
            }
        } else {
            console.log('GEO Not Available');
        }
        return () => {
            _isMounted.current = false;
        };
    }, []);

    const handleOnLoad = (map) => {
        setMapRef(map);
    };
    const handleCenterChanged = () => {
        if (mapRef) {
            const newCenter = mapRef.getCenter();
            try {
                setSelectedRiderCoordinates(null);
                setCustomCenter({
                    lng: newCenter.lng(),
                    lat: newCenter.lat(),
                });
                // console.log('newCenter', newCenter);
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        <Container fluid className="p-0">
            <div
                style={
                    initialLoading
                        ? { height: '80vh', width: '100%', ...centerElementContainerStyles }
                        : { height: '80vh', width: '100%', ...centerElementContainerStyles }
                }
            >
                {!initialLoading && currentGeo && currentGeo.coordinates && isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        zoom={18}
                        center={
                            selectedRiderCoordinates
                                ? { lat: selectedRiderCoordinates.latitude, lng: selectedRiderCoordinates.longitude }
                                : customCenter ||
                                  (currentGeo?.coordinates
                                      ? {
                                            lat: currentGeo.coordinates.lat,
                                            lng: currentGeo.coordinates.lng,
                                        }
                                      : null)
                        }
                        onLoad={handleOnLoad}
                        onDragEnd={handleCenterChanged}
                    >
                        {parentLoading > 0 ||
                            parentLoading === true ||
                            (loading > 0 || loading === true ? (
                                <motion.div
                                    style={{ width: 20, marginRight: 50 }}
                                    className="advanced-tracking-map-lottie"
                                >
                                    <Lottie
                                        lat={0}
                                        lng={0}
                                        options={mapLottieOptionsLoading(SearchingRedLottie)}
                                        height={230}
                                        width={230}
                                        speed={0.4}
                                    />
                                </motion.div>
                            ) : (
                                allRidersByClient.map((rider) => (
                                    <RiderMarker
                                        lat={rider.coordinates.latitude}
                                        lng={rider.coordinates.longitude}
                                        {...rider}
                                        riderData={rider}
                                        setSelectedRider={setSelectedRider}
                                        selectedRider={selectedRider}
                                        key={rider.riderId || rider._id}
                                        riderExtendedData={allRidersByClientFixed.find(
                                            (x) => x._id === (rider.riderId || rider._id)
                                        )}
                                    />
                                ))
                            ))}
                    </GoogleMap>
                ) : (
                    <div>
                        <Spinner size="50" />
                    </div>
                )}
            </div>
        </Container>
    );
}
