import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Container, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

// #region assets | components
import { CheckCircle } from 'components/Icons';
import ConfirmationModal from 'components/Modals/Developer/ConfirmationModal';

/**
 * Restore user Modal
 * @returns {RestoreUserModel} - Showing Restore user confirmation component for user
 */
export default function RestoreUserModel(props) {
    const { show, onclose, handleRestoreUserAccount, userData } = props;

    // Get the username from formData
    const { username } = userData;

    const [formData, setFormData] = useState(userData);

    // set the Password
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    return (
        <ConfirmationModal header={`Restore the user access | ${username}`} show={show} onclose={onclose}>
            <Container>
                <AvForm className="needs-validation default-form">
                    <Row>
                        <Col>
                            <Alert color="warning" style={{ borderRadius: 8 }}>
                                Please enter your password to restore the user access.
                            </Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AvField
                                name="password"
                                type="password"
                                className="form-control"
                                errorMessage="Required"
                                id="password"
                                validate={{ required: { value: true } }}
                                value={formData.password}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                style={{ backgroundColor: '#FD4A4A', borderRadius: 8 }}
                                className="scootix-btn-radius"
                                onClick={() => handleRestoreUserAccount(formData)}
                            >
                                <CheckCircle color="#FFF" />
                                &nbsp;RESTORE
                            </Button>
                        </Col>
                    </Row>
                </AvForm>
            </Container>
        </ConfirmationModal>
    );
}

RestoreUserModel.propTypes = {
    show: PropTypes.bool,
    onclose: PropTypes.func,
    handleRestoreUserAccount: PropTypes.func,
    userData: PropTypes.object,
};
