import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Input } from 'reactstrap';

// #region utils
import { getDateValue } from 'utils/helpers';
import { initialFormValues } from '../../../utils';

function TableDate(props) {
    const { onFilter } = props;

    const [formData, setFormData] = useState(initialFormValues);

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
        if (onFilter) {
            onFilter('createdAt', event.target.value);
        }
    };

    return (
        <Form className="table__custom__date-shipping d-lg-block">
            <div className="position-relative">
                <FormGroup>
                    <Input
                        onChange={handleInputChange}
                        type="date"
                        className="form-control"
                        value={getDateValue(formData.fromDate, 'date-local')}
                        validate={{ required: { value: true } }}
                        id="fromDate"
                    />
                </FormGroup>
            </div>
        </Form>
    );
}
TableDate.propTypes = {
    onFilter: PropTypes.func,
};
export default TableDate;
