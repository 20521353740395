import React, { useEffect, useState } from 'react';
import { Card, CardBody, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
// import { motion } from 'framer-motion';
import moment from 'moment';
import PropTypes from 'prop-types';

// #region imports
import { NOTIFICATION_VIEW_TYPES } from 'constants/enums';

// #region utils
import { readNotification } from 'utils/local-storage';

// #endregion imports
import LabelPrintShippingLabel from './modules/LabelPrintShippingLabel';

function TabSubView(props) {
    const { selectedNotification, setBackdropLoading } = props;

    const commonProps = {
        setBackdropLoading,
        selectedNotification,
    };

    const [notificationViewType, setNotificationViewType] = useState('DEFAULT');

    useEffect(() => {
        if (selectedNotification && selectedNotification._id) {
            readNotification(selectedNotification._id);
            if (selectedNotification.viewType) {
                setNotificationViewType(selectedNotification.viewType);
            } else if (selectedNotification?.meta?.viewType) {
                setNotificationViewType(selectedNotification.meta.viewType);
            }
        }
    }, [selectedNotification]);

    function renderCardData() {
        if (notificationViewType === NOTIFICATION_VIEW_TYPES.LABEL_PRINTING_SHIPPING_LABEL_BATCH_PRINT) {
            return <LabelPrintShippingLabel {...commonProps} />;
        }

        return (
            <>
                <Card style={{ borderRadius: 10, minHeight: '70vh' }}>
                    <CardBody>
                        <div>
                            <DropdownItem divider />

                            <div style={{ color: '#969292' }}>
                                {moment(selectedNotification.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                            </div>

                            <div className="mt-2">
                                <h4 style={{ fontSize: 25 }}>{selectedNotification.title || ''}</h4>{' '}
                            </div>
                            <DropdownItem divider />
                            <div className="mt-3">
                                <p style={{ fontSize: 17 }}>{selectedNotification.description || ''}</p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </>
        );
    }

    return <>{renderCardData()} </>;
}

TabSubView.propTypes = {
    selectedNotification: PropTypes.any,
    setBackdropLoading: PropTypes.func,
};

const mapStateToProps = (state) => ({
    ...state,
});

export default connect(mapStateToProps, {})(TabSubView);
