import APP_LOGO_WIDE from 'assets/images/app/ScootixLogo.png';
import SEARCH_NO_RESULTS from 'assets/images/dev/search-no-results.png';
import RIDER_ICON from 'assets/images/app/Rider-ICON.png';
import APP_LOGO_SM from 'assets/images/app/logo-sm.png';
import IMAGE_UPLOAD_AVATAR from 'assets/images/product/upload-image-avatar.png';
import RIDER_ICON_UPDATED from 'assets/images/icons/rider_icon.png';
import RECIPIENT_DELIVERY_ADDRESS_ICON from 'assets/images/icons/Recipient_Delivery_address.png';
import MERCHANT_LOCATION_ICON from 'assets/images/icons/merchant_location.png';
import TASA_LOGO from 'assets/images/app/tasa-logo.png';
import SCOOTIX_DARK_LOGO from 'assets/images/app/scootix-dark-logo.png';
import WARNING_ICON from 'assets/images/icons/warning.png';
import DEVELOPMENT_PLAN_ICON from 'assets/images/icons/development-plan.png';
import SCOOTER_ICON from 'assets/images/icons/Scooter@3x.png';
import CAR_ICON from 'assets/images/icons/Car@3x.png';
import VAN_ICON from 'assets/images/icons/Van@3x.png';
import TRUCK_ICON from 'assets/images/icons/Lorry@3x.png';

export const APP_LOGO_WIDE_IMAGE = APP_LOGO_WIDE;
export const SEARCH_NO_RESULTS_IMAGE = SEARCH_NO_RESULTS;
export const RIDER_ICON_IMAGE = RIDER_ICON;
export const APP_LOGO_SM_IMAGE = APP_LOGO_SM;
export const IMAGE_UPLOAD_AVATAR_IMAGE = IMAGE_UPLOAD_AVATAR;
export const RIDER_ICON_UPDATED_IMAGE = RIDER_ICON_UPDATED;
export const RECIPIENT_DELIVERY_ADDRESS_ICON_IMAGE = RECIPIENT_DELIVERY_ADDRESS_ICON;
export const MERCHANT_LOCATION_ICON_IMAGE = MERCHANT_LOCATION_ICON;
export const TASA_LOGO_IMAGE = TASA_LOGO;
export const SCOOTIX_DARK_LOGO_IMAGE = SCOOTIX_DARK_LOGO;
export const WARNING_ICON_IMAGE = WARNING_ICON;
export const DEVELOPMENT_PLAN_ICON_IMAGE = DEVELOPMENT_PLAN_ICON;
export const SCOOTER_ICON_IMAGE = SCOOTER_ICON;
export const CAR_ICON_IMAGE = CAR_ICON;
export const VAN_ICON_IMAGE = VAN_ICON;
export const TRUCK_ICON_IMAGE = TRUCK_ICON;
