import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';

// #region assets | components
import { Select } from 'components';

// #region utils
import { getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';

function PartnerListTableHeader(props) {
    const { handleFilter, handleStatusChange, search } = props;

    /** Which status is selected from the dropdown */
    const [selectedStatus, setSelectedStatus] = useState('');
    const [searchText, setSearchText] = useState('');

    /** Invoke the callback function with the value in the search box */
    const onFilter = (e) => {
        handleFilter(e.target.value);
        // !FIXME: setSearchText should not be there for below `searchText &&` it should take from search.searchText
        setSearchText(e.target.value);
    };

    const handleSelectChange = (event, id) => {
        if (event) {
            const { value } = event;
            if (id === 'status') {
                setSelectedStatus(value);
                handleStatusChange(value);
            }
        } else if (id === 'status') {
            setSelectedStatus('');
            handleStatusChange(null);
        }
    };

    const optionsValues = [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
    ];

    return (
        <div className="table-top-row align-items-center justify-content-start">
            <Col xs="auto" className="pl-0" style={{ minWidth: '30%' }}>
                <Form className="table__custom__search-default d-lg-block">
                    <div className="position-relative">
                        <DebounceInput
                            type="text"
                            className="form-control"
                            placeHolder="Type Merchant ID or Name to search"
                            onChange={onFilter}
                            debounceTimeout={500}
                            value={search.searchText ? search.searchText.replaceAll('$2', ' ') : searchText || ''}
                            style={{
                                backgroundColor: '#FCFCFC',
                                border: '1px solid #F1F1F1',
                                borderRadius: 14,
                            }}
                        />
                    </div>
                    <span className="ri-search-line ml-3 search-icon" />
                    {searchText && (
                        <span
                            className="fa fa-times close-icon"
                            onClick={() => {
                                handleFilter('');
                                setSearchText('');
                            }}
                            role="button"
                        />
                    )}
                </Form>
            </Col>
            <Col xs="auto" style={{ minWidth: '30%' }}>
                <Select
                    value={getDefaultValueForSelect(selectedStatus, optionsValues, 'value')}
                    isClearable
                    options={optionsValues}
                    styles={reactSelectCustomStyles}
                    onChange={(event) => handleSelectChange(event, 'status')}
                />
            </Col>
        </div>
    );
}

PartnerListTableHeader.propTypes = {
    search: PropTypes.object,
    handleFilter: PropTypes.func,
    handleStatusChange: PropTypes.func,
};

export default PartnerListTableHeader;
