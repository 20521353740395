import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Label, Row, Spinner } from 'reactstrap';

// #region assets | components
import { RemoveSelected, RemoveAll } from 'components/Icons';

// #region other imports
import { centerElementContainerStyles } from 'styles';

// #endregion imports
import Search from './TableHeaderComponents/TableSearch';

function SelectedItemPrintTableHeader(props) {
    const { isManualSearch, onFilter, formLoading, isSearch, setSearch, search, headerHandlers } = props;

    return (
        <>
            <Row style={{ marginBottom: 30, marginLeft: 0, fontWeight: 600, fontSize: 14 }}>
                Selected Deliveries for the Print
            </Row>
            <div className="table-top-row">
                <div className="top-row">
                    <Label>Delivery ID</Label>
                    {isSearch && (
                        <Search
                            onFilter={onFilter}
                            isManualSearch={isManualSearch}
                            setSearch={setSearch}
                            search={search}
                            searchPlaceholder="Search"
                        />
                    )}
                </div>
                <div className="d-flex row" style={centerElementContainerStyles}>
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                        onClick={() => headerHandlers.onRemoveSelected()}
                        disabled={formLoading}
                        style={{ borderRadius: 30, backgroundColor: '#FD4A4A' }}
                    >
                        {formLoading ? (
                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                        ) : (
                            <>
                                <RemoveSelected />
                                <span className="h6">Remove Selected</span>
                            </>
                        )}
                    </motion.button>
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                        onClick={() => headerHandlers.onRemoveAllSelected()}
                        disabled={formLoading}
                        style={{ borderRadius: 30, backgroundColor: '#FD4A4A' }}
                    >
                        {formLoading ? (
                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                        ) : (
                            <>
                                <RemoveAll />
                                <span className="h6">Remove All</span>
                            </>
                        )}
                    </motion.button>
                </div>
            </div>
        </>
    );
}

SelectedItemPrintTableHeader.propTypes = {
    isManualSearch: PropTypes.bool,
    onFilter: PropTypes.func,
    formLoading: PropTypes.bool,
    isSearch: PropTypes.bool,
    setSearch: PropTypes.func,
    search: PropTypes.object,
    headerHandlers: PropTypes.object,
};

export default SelectedItemPrintTableHeader;
