/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable vars-on-top */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import PropTypes from 'prop-types';

// #region components
import { Select } from 'components';

// #region imports
import { getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { getAddressObject } from 'utils/functions';
import { getVisitingObject } from 'utils/checkAuth';

/**
 * Google Places Auto Complete Field
 * @param {*} props
 * @returns
 */
function PlacesAutocompleteField(props) {
    const {
        id,
        idMeta,
        returnLocationData,
        addressData = null,
        submitted = false,
        validated = false,
        minLengthAutocomplete = 5,
        isDisabled = false,
        isLoading,
        isRequired = false,
        isClearable = false,
        predefinedValues = [],
        isDiscardPreviousValues = false, // if this value is true, it discard the previous values from the state
    } = props;

    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);

    const [isOptionsLoading, setIsOptionsLoading] = useState(false);

    useEffect(() => {
        if (predefinedValues && predefinedValues.length > 0) {
            loadPredefinedValues();
        } else if (!predefinedValues || predefinedValues === null) {
            resetPredefinedValues();
        }
    }, [predefinedValues]);

    useEffect(() => {
        if (addressData && addressData.place_id) {
            const relevantObject = suggestions.find((x) => x.place_id === addressData.place_id);
            if (!relevantObject) {
                setSuggestions([
                    ...suggestions,
                    {
                        ...addressData,
                        label: addressData.searchedValue ? addressData.searchedValue.label : '',
                        description: addressData.searchedValue ? addressData.searchedValue.label : '',
                        place_id: addressData.place_id,
                        value: addressData.place_id,
                    },
                ]);
            }
            setSelectedSuggestion(addressData.place_id);
        } else {
            setSuggestions([]);
        }
    }, [addressData]);

    const loadPredefinedValues = () => {
        if (predefinedValues) {
            const allSuggestions = [];
            predefinedValues.map((val) => {
                // const isValExist = suggestions.find((x) => x.place_id === val.locationMeta.place_id);
                // if (!isValExist) {
                allSuggestions.push({
                    ...val.locationMeta,
                    description: val.locationMeta.searchedValue ? val.locationMeta.searchedValue.label : '',
                    merchantLocationId: val.merchantLocationName && val._id,
                    place_id: val.locationMeta.place_id,
                    value: val.locationMeta.place_id,
                    label: val.label
                        ? val.label
                        : val.locationMeta.searchedValue
                        ? val.locationMeta.searchedValue.label
                        : '',
                });
                // }
                return val;
            });

            // if isDiscardPreviousValues is true, do not add previous values to the state again
            if (isDiscardPreviousValues) {
                setSuggestions([...allSuggestions]);
            } else {
                setSuggestions((prevState) => [...prevState, ...allSuggestions]);
            }
        }
    };

    const resetPredefinedValues = () => {
        setSuggestions([]);
    };

    const googleAutocomplete = async (text) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
            if (!text) {
                return reject('Need valid text input');
            }

            // for use in things like GatsbyJS where the html is generated first
            if (typeof window === 'undefined') {
                return reject('Need valid window object');
            }

            // Getting visitingClient purpose of here is to get the countryIsoCode of the current client
            // And Pass it to the componentRestrictions to avoid searching unnecessary locations rather than client's country
            const { visitingClient } = getVisitingObject();

            try {
                new window.google.maps.places.AutocompleteService({}).getPlacePredictions(
                    {
                        input: text,
                        componentRestrictions: { country: visitingClient?.countryIsoCode || 'my' },
                    },
                    resolve
                );
            } catch (e) {
                reject(e);
            }
        });

    const handleChangeInput = (text) => {
        if (text.length >= minLengthAutocomplete) {
            setInputValue(text);
            if (text) {
                setIsOptionsLoading(true);
                googleAutocomplete(text)
                    .then((res) => {
                        if (Array.isArray(res) && res.length > 0) {
                            setSuggestions(res);
                        }
                    })
                    .finally(() => {
                        setIsOptionsLoading(false);
                    });
            }
        } else if (text.length === 1) {
            loadPredefinedValues();
        }
    };

    const handleSelectChange = async (event) => {
        if (event) {
            const { value = null, label } = event;
            if (value) {
                setSelectedSuggestion(value);
                const selectedPlace = suggestions.find((x) => x.place_id === value);
                const geocodeObj = await geocodeByPlaceId(value);
                let addressObject = geocodeObj && getAddressObject(geocodeObj[0].address_components, label || null);
                if (returnLocationData && addressObject) {
                    addressObject = {
                        ...addressObject,
                        place_id: geocodeObj[0].place_id || null,
                        coordinates: geocodeObj[0]
                            ? geocodeObj[0].geometry.location
                                ? {
                                      lat: geocodeObj[0].geometry.location.lat(),
                                      lng: geocodeObj[0].geometry.location.lng(),
                                  }
                                : { lat: 59.95, lng: 30.33 }
                            : { lat: 59.95, lng: 30.33 },
                        searchedValue: { label: label || '' },
                        inputAddress: inputValue,
                        merchantLocationId: selectedPlace.merchantLocationId,
                    };
                    console.log('addressObject', addressObject);
                    returnLocationData(id, idMeta, addressObject);
                }
            }
        } else {
            setSelectedSuggestion(null);
            returnLocationData(id, idMeta, null);
        }
    };

    const getPlaceValue = (place_id) => {
        if (place_id) {
            const relevantPlace = suggestions.find((x) => x.place_id === place_id);
            if (relevantPlace) {
                return relevantPlace.description || relevantPlace.label;
            }
        }
        return null;
    };

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    return (
        <>
            <div>
                <Select
                    value={getDefaultValueForSelect(getPlaceValue(selectedSuggestion))}
                    isClearable={isClearable}
                    options={[
                        {
                            label: 'Merchant Locations',
                            options: suggestions
                                .filter((x) => x.type && x.type === 'MERCHANT_LOCATION')
                                .map((x) => ({
                                    label: x.label || x.description,
                                    value: x.value || x.place_id,
                                })),
                        },
                        {
                            label: 'Map',
                            options: suggestions
                                .filter((x) => !x.type || x.type !== 'MERCHANT_LOCATION')
                                .map((x) => ({
                                    label: x.label || x.description,
                                    value: x.value || x.place_id,
                                })),
                        },
                    ]}
                    formatGroupLabel={formatGroupLabel}
                    styles={reactSelectCustomStyles}
                    onChange={(event) => handleSelectChange(event, 'merchantId')}
                    onChangeInput={handleChangeInput}
                    submitted={submitted}
                    required={isRequired}
                    validated={validated}
                    isLoading={isOptionsLoading || isLoading}
                    isDisabled={isDisabled}
                />
            </div>
        </>
    );
}

PlacesAutocompleteField.propTypes = {
    returnLocationData: PropTypes.func,
    id: PropTypes.string,
    idMeta: PropTypes.string,
    addressData: PropTypes.any,
    minLengthAutocomplete: PropTypes.number,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    validated: PropTypes.bool,
    submitted: PropTypes.bool,
    isClearable: PropTypes.bool,
    predefinedValues: PropTypes.any,
    isDiscardPreviousValues: PropTypes.bool,
};

export default PlacesAutocompleteField;
