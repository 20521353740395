/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Row, Col, Card, CardBody, FormGroup, Spinner, Label, Container, Input } from 'reactstrap';
import * as qs from 'query-string';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

// #region imports
import PATHS from 'routes/paths';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {
    ClientAlerts,
    CustomToast,
    MerchantAlerts,
    PlacesAutocompleteField,
    PlacesAutocompleteModal,
    Select,
} from 'components';
import { getVisitingObject, hasAccess, IS_MERCHANT_VIEW } from 'utils/checkAuth';

// #region services
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';
import { getAllCountries } from 'services/countries.service';
import { getApiErrorMessage } from 'constants/api';

// #region utils
import { PERMISSIONS } from 'utils/constants';
import { getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';

// #endregion imports
import { createMerchantLocationService, updateMerchantLocationService } from '../../services';
import { validateFormMerchantLocation } from '../../utils';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Merchant Management', link: '/merchant/list' },
];

function MerchantLocationFormPage(props) {
    const history = useHistory();

    const merchantFormRef = useRef(null);

    const { visitingClient, visitingMerchant } = getVisitingObject();

    let initialFormValues = {
        country: visitingClient?.country || 'Malaysia',
    };

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
            role: 'merchantUser',
        };
    }

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);

    const [showPickupLocation, setShowPickupLocation] = useState(false);

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState(initialFormValues);

    const [merchants, setMerchants] = useState([]);

    const [showSaveBtn, setShowSaveBtn] = useState(false);

    useEffect(() => {
        if (editingMode) {
            if (hasAccess(PERMISSIONS.MERCHANT_LOCATION, ['Edit'])) {
                setShowSaveBtn(true);
            }
        } else if (hasAccess(PERMISSIONS.MERCHANT_LOCATION, ['Add'])) {
            setShowSaveBtn(true);
        }
    }, [editingMode]);

    useEffect(() => {
        const formType = qs.parse(props.location.search);
        if (formType && formType.type && formType.type === 'new') {
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Merchant', link: '#' }]);
        }
        if (formType && formType.type && formType.type === 'view') {
            !props.location.state && history.push(PATHS.MERCHANT.MERCHANT_LOCATION_LIST);
            const { merchantLocationParentData } = props.location.state;
            setFormData({ ...formData, ...merchantLocationParentData });
            setIsViewMode(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'View Merchant', link: '#' }]);
        }
        if (formType && formType.type && formType.type === 'edit') {
            !props.location.state && history.push(PATHS.MERCHANT.MERCHANT_LOCATION_LIST);
            const { merchantLocationParentData } = props.location.state;
            setFormData({ ...formData, ...merchantLocationParentData });
            setEditingMode(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Merchant', link: '#' }]);
        }
    }, [props.location]);

    useEffect(() => {
        getAllCountriesService();
    }, []);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        if (id === 'merchantId') {
            const relevantMerchant = merchants.find((x) => x._id === event.value);
            if (relevantMerchant) {
                setFormData({
                    ...formData,
                    merchantName: relevantMerchant.name || '',
                    [id]: event ? event.value : null,
                });
            }
            return;
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const getAllCountriesService = async () => {
        try {
            const data = await getAllCountries();
            setCountries(data);
        } catch (e) {
            console.log(e);
        }
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const isFormValid = validateFormMerchantLocation(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text="Fill all fields" t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);
        try {
            let responseData;

            if (editingMode) {
                const { data } = await updateMerchantLocationService({
                    ...formData,
                    locationMeta: { ...formData.locationMeta, type: 'MERCHANT_LOCATION' },
                });
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            } else {
                const { data } = await createMerchantLocationService({
                    ...formData,
                    locationMeta: { ...formData.locationMeta, type: 'MERCHANT_LOCATION' },
                });
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            }
            setEditingMode(true);
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} Merchant Location`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );

            try {
                // Replace URL so relevant changes can be done
                if (responseData) {
                    history.replace({
                        pathname: `${PATHS.MERCHANT.MERCHANT_LOCATION_FORM}`,
                        search: `?type=edit&id=${responseData._id}`,
                        state: {
                            merchantLocationParentData: responseData,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }
        } catch (e) {
            console.log(e);
            if (e && e.data) {
                if (e && e.data && e.data.errors) {
                    const message = getApiErrorMessage(e.data.errors.msg);
                    toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                        position: 'top-right',
                    });
                }
            } else {
                toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        }
        setFormLoading(false);
        setSubmitted(false);
    };

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    const returnLocationData = (id, idMeta, data) => {
        if (data) {
            const { city = '', address = '', state = null, postal_code = '' } = data;
            setFormData({
                ...formData,
                city,
                state,
                [id]: `${address ? `${address}` : ''}`,
                [idMeta]: data,
                postalCode: postal_code || '',
            });
        } else {
            setFormData({
                ...formData,
                [id]: null,
                [idMeta]: null,
                city: '',
                state: '',
            });
        }
    };

    const togglePlacesModal = () => {
        setShowPickupLocation(!showPickupLocation);
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        // title={editingMode ? 'Update Merchant' : 'Create New Merchant'}
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Row style={{ justifyContent: 'space-between' }}>
                        <div>
                            {!editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                    onClick={() => {
                                        history.push({
                                            pathname: PATHS.MERCHANT.MERCHANT_LOCATION_LIST,
                                        });
                                    }}
                                >
                                    <i className="far fa-times-circle mr-2 fa-lg" />
                                    Cancel
                                </motion.div>
                            )}
                        </div>
                        {editingMode && (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                onClick={() => {
                                    history.push({
                                        pathname: PATHS.MERCHANT.MERCHANT_LOCATION_LIST,
                                    });
                                }}
                            >
                                <i className="fas fa-arrow-left mr-2 fa-lg" />
                                Back to Merchant Locations
                            </motion.div>
                        )}
                    </Row>
                    {!editingMode && (
                        <>
                            <ClientAlerts />
                            <MerchantAlerts />
                        </>
                    )}
                    <AvForm className="needs-validation default-form" onValidSubmit={handleSubmit}>
                        <fieldset disabled={isViewMode || formLoading}>
                            <div ref={merchantFormRef}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Enter Merchant Location Details</h4>
                                        <Row>
                                            {editingMode && (
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="referenceNumber">Id</Label>
                                                        <AvField
                                                            name="referenceNumber"
                                                            placeholder="Name"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="referenceNumber"
                                                            onChange={handleInputChange}
                                                            disabled
                                                            value={formData.referenceNumber}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            )}
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="merchantId">Merchant</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(
                                                            getMerchantSelect(formData.merchantId)
                                                        )}
                                                        options={merchants}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                        isDisabled={editingMode || IS_MERCHANT_VIEW()}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="merchantLocationName">Merchant Location Name</Label>
                                                    <AvField
                                                        name="merchantLocationName"
                                                        placeholder="Name"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="merchantLocationName"
                                                        onChange={handleInputChange}
                                                        value={formData.merchantLocationName}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <Row>
                                                    <Col md="10" sm="10" xs="10">
                                                        <FormGroup>
                                                            <Label htmlFor="location">Location</Label>
                                                            <PlacesAutocompleteField
                                                                addressData={formData.locationMeta || null}
                                                                returnLocationData={returnLocationData}
                                                                id="location"
                                                                idMeta="locationMeta"
                                                                isRequired
                                                                submitted={submitted}
                                                                validated={validated}
                                                            />
                                                            {showPickupLocation && !isViewMode && (
                                                                <PlacesAutocompleteModal
                                                                    show={showPickupLocation}
                                                                    addressData={formData.pickupLocationMeta || null}
                                                                    returnLocationData={returnLocationData}
                                                                    toggle={togglePlacesModal}
                                                                    id="location"
                                                                    idMeta="locationMeta"
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="2" sm="2" xs="2">
                                                        <motion.div
                                                            style={{ width: 35, height: 35 }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.9 }}
                                                            className="scootix-btn-circle shadow-lg nw-md ml-3 delivery-map-pin-container"
                                                            onClick={togglePlacesModal}
                                                        >
                                                            <div className="map-pin">
                                                                <i className="ri-map-pin-line fa-lg" />
                                                            </div>
                                                        </motion.div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="postalCode">Postal Code</Label>
                                                    <AvField
                                                        name="postalCode"
                                                        placeholder="Postal Code"
                                                        type="Number"
                                                        minLength={5}
                                                        maxLength={5}
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="postalCode"
                                                        onChange={handleInputChange}
                                                        value={formData.postalCode}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="city">City</Label>
                                                    <AvField
                                                        name="city"
                                                        placeholder="City"
                                                        type="text"
                                                        errorMessage="Please provide a valid city."
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="city"
                                                        value={formData.city}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="state">State</Label>
                                                    <AvField
                                                        name="state"
                                                        placeholder="State"
                                                        type="text"
                                                        errorMessage="Please provide a valid state."
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="state"
                                                        value={formData.state}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="country">Country</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.country)}
                                                        options={countries.map((country) => ({
                                                            label: country.details.name,
                                                            value: country.details.name,
                                                        }))}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'country')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="contactPerson">Contact Person</Label>
                                                    <AvField
                                                        name="contactPerson"
                                                        placeholder="Contact Person"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="contactPerson"
                                                        onChange={handleInputChange}
                                                        value={formData.contactPerson}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="contactEmail">Contact Email Address</Label>
                                                    <AvField
                                                        name="contactEmail"
                                                        placeholder="Contact Email Address"
                                                        type="email"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="contactEmail"
                                                        onChange={handleInputChange}
                                                        value={formData.contactEmail}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="contactNumber">Contact Number</Label>
                                                    <AvField
                                                        name="contactNumber"
                                                        placeholder="Contact Number"
                                                        // type="text"
                                                        mask="+60 99-999 99999"
                                                        maskChar=""
                                                        tag={[Input, InputMask]}
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="contactNumber"
                                                        onChange={handleInputChange}
                                                        value={formData.contactNumber}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="secondaryContactPerson">
                                                        Secondary Contact Person
                                                    </Label>
                                                    <AvField
                                                        name="secondaryContactPerson"
                                                        placeholder="Secondary Contact Person"
                                                        type="text"
                                                        // errorMessage="Required"
                                                        className="form-control"
                                                        // validate={{ required: { value: true } }}
                                                        id="secondaryContactPerson"
                                                        onChange={handleInputChange}
                                                        value={formData.secondaryContactPerson}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="secondaryContactEmail">
                                                        Secondary Contact Email Address
                                                    </Label>
                                                    <AvField
                                                        name="secondaryContactEmail"
                                                        placeholder="Contact Email Address"
                                                        type="email"
                                                        className="form-control"
                                                        id="secondaryContactEmail"
                                                        onChange={handleInputChange}
                                                        value={formData.secondaryContactEmail}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="secondaryContactNumber">
                                                        Secondary Contact Number
                                                    </Label>
                                                    <AvField
                                                        name="secondaryContactNumber"
                                                        placeholder="Secondary Contact Number"
                                                        // type="text"
                                                        mask="+60 99-999 99999"
                                                        maskChar=""
                                                        tag={[Input, InputMask]}
                                                        className="form-control"
                                                        id="secondaryContactNumber"
                                                        onChange={handleInputChange}
                                                        value={formData.secondaryContactNumber}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row style={{ justifyContent: 'flex-end' }}>
                                            {showSaveBtn && (
                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                    type="submit"
                                                    disabled={formLoading}
                                                >
                                                    {formLoading ? (
                                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                    ) : (
                                                        <>
                                                            <i className="fas fa-save left-icon"></i>
                                                            <span className="h6">
                                                                {editingMode ? 'Update' : 'Save'}
                                                            </span>
                                                        </>
                                                    )}
                                                </motion.button>
                                            )}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </fieldset>
                    </AvForm>
                    <br />
                </Container>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

MerchantLocationFormPage.propTypes = {
    location: PropTypes.any,
};

const HotMerchantLocationFormPage = hot(MerchantLocationFormPage);

export default connect(mapStateToProps, {})(HotMerchantLocationFormPage);
