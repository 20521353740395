import {
    GET_ALL_ROLES_BY_USER_TYPE,
    GET_ALL_ROLES_BY_MERCHANT_ID,
    GET_ALL_ROLES_BY_CLIENT_ID,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get All Roles By User Type
 * @returns {Promise}
 */
export const getAllRolesByUserType = (type) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_ROLES_BY_USER_TYPE).setPathParams({ type }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Roles By Merchant ID Service
 * @returns {Promise}
 */
export const getAllRolesByMerchantIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_ROLES_BY_MERCHANT_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Roles By Client ID Service
 * @returns {Promise}
 */
export const getAllRolesByClientIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_ROLES_BY_CLIENT_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
