import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { motion } from 'framer-motion';
import * as qs from 'query-string';
import { isObject } from 'lodash';
import toast from 'react-hot-toast';

// #region assets | components
import Breadcrumbs from 'components/Common/Breadcrumb';
import { StatCard, TableCustom, BackdropLoader, CustomToast, ClientAlerts } from 'components';

// #region imports
import PATHS from 'routes/paths';

// #region utils
import { hasAccess, IS_MERCHANT_VIEW } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import { apiErrorHandlerV2 } from 'utils/helpers';

// #region services
import { getAllRidersByStatus } from 'services/rider.service';
import { getAllDeliveriesMetaService, getDeliveriesService } from 'services/deliveries.service';

// #endregion imports
import { defaultTableHeaders, tableDefaultFilters } from './constants';
import { getStatus } from './utils';
import './home.scss';

const breadcrumbItems = [
    { title: 'ScootiX', link: '#' },
    { title: 'Home', link: '#' },
];

/**
 * Home Page
 * @param {*} props
 * @returns {React.FunctionComponent}
 */
function HomePage(props) {
    const { location } = props;

    const history = useHistory();

    const currentMonth = new Date();

    // component state
    const [backdropLoading] = useState(0);
    const [loading, setLoading] = useState(0);

    // URL state
    const [urlParamsSearch, setUrlParamsSearch] = useState({
        status: 'inProgress', // enum: ['inProgress','onGoing','dispatched','pickUpStarted','pickedUp', 'deliveryStarted','delivered','proofOfDeliveryReceived','completed'],
        page: '1', // all or enum: ['general', 'imported'],
    });
    const [searchParams, setSearchParams] = useState(null);

    const [deliveryListData, setDeliveryListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    const [meta, setMeta] = useState({
        ridersActive: 0,
        dispatchedDeliveries: 0,
        ongoingDeliveries: 0,
        completedDeliveriesCurrentMonth: 0,
        pickupStartedDeliveries: 0,
        pickedUpDeliveries: 0,
        deliveryStartedDeliveries: 0,
        deliveredDeliveries: 0,
        proofOfDeliveryReceivedDeliveries: 0,
        deliveriesCompletedToday: 0,
        deliveriesInProgress: 0,
    });

    useEffect(() => {
        if (urlParamsSearch) {
            const { searchBy, searchText, type, page, sort, order, status, filterDate } = urlParamsSearch;

            loadDeliveriesListData({
                showLoading: true,
                ...deliveryListData,
                searchBy: { searchBy, searchText, filterDate },
                type,
                status,
                page,
                sort,
                order,
            });
        }
        loadAllDeliveriesMeta();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParamsSearch]);

    useEffect(() => {
        const urlSearch = qs.parse(location.search);
        if (urlSearch && urlSearch.status) {
            const { status, page, searchBy, searchText, sort, order, filterDate } = urlSearch;
            setUrlParamsSearch({ status, page, searchBy, searchText, sort, order, filterDate });
            setSearchParams({ searchBy, searchText, sort, order, filterDate });
        } else {
            replaceUrlParamsHandler({ status: 'inProgress', page: '1' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    const reloadComponent = () => {
        loadDeliveriesListData({
            showLoading: true,
            ...deliveryListData,
            page: urlParamsSearch.page,
            searchBy: {
                searchBy: urlParamsSearch?.searchBy,
                searchText: urlParamsSearch?.searchText,
            },
            type: urlParamsSearch.type,
            status: urlParamsSearch.status,
        });
        loadAllDeliveriesMeta();
    };

    useEffect(() => {
        loadRidersByStatus('active');
    }, []);

    // Changes in params handler
    const replaceUrlParamsHandler = (
        params = {
            type: null,
            status: null,
            page: '1',
            searchBy: null,
            searchText: null,
            sort: null,
            order: null,
            filterDate: null,
        }
    ) => {
        try {
            history.replace({
                search: `${
                    params.page || urlParamsSearch.page ? `page=${params.page || urlParamsSearch.page || '1'}&` : ''
                }${
                    params.status || urlParamsSearch.status
                        ? `status=${params.status || urlParamsSearch.status || 'inProgress'}&`
                        : ''
                }${params?.searchBy ? `searchBy=${params.searchBy}&` : ''}${
                    params?.searchText ? `searchText=${params.searchText}&` : ''
                }${params?.filterDate ? `filterDate=${params.filterDate}&` : ''}${
                    params?.limit ? `limit=${params.limit}&` : ''
                }${params?.sort ? `sort=${params.sort}&` : ''}${params?.order ? `order=${params.order}` : ''}`,
            });
        } catch (e) {
            console.log(e);
        }
    };

    const loadRidersByStatus = async (status) => {
        try {
            const { data } = await getAllRidersByStatus(status);
            if (data && data.docs) {
                setMeta((prevState) => ({ ...prevState, ridersActive: data.docs.length }));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadAllDeliveriesMeta = async () => {
        try {
            const { data } = await getAllDeliveriesMetaService('all');
            if (data) {
                setMeta((prevState) => ({ ...prevState, ...data }));
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    const loadDeliveriesListData = (args) => {
        if (args.showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getDeliveriesService({ ...args, populate: 'riderId,merchantId' })
            .then((res) => {
                const { data } = res;
                if (data && data.docs) {
                    const docs = mapDocs(data.docs);
                    setDeliveryListData({
                        ...deliveryListData,
                        ...data,
                        docs,
                    });
                }
            })
            .catch((e) => {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            })
            .finally(() => {
                if (args.showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const handlePageChange = (pageNo) => {
        replaceUrlParamsHandler({
            page: pageNo,
            searchBy: searchParams?.searchBy,
            searchText: searchParams?.searchText,
            filterDate: searchParams?.filterDate,
        });
    };

    const handleFilter = (field, filterText) => {
        let modifiedFilterText = null;
        if (filterText && filterText !== '') {
            modifiedFilterText = filterText.replaceAll(' ', '$2');
        } else {
            modifiedFilterText = null;
        }
        replaceUrlParamsHandler({ searchBy: field || 'referenceNumber', searchText: modifiedFilterText, page: '1' });
    };

    // handling date filtering and updating URL parameters.
    const handleFilterDate = (field, filterDate) => {
        let modifiedFilterDate = null;
        if (filterDate && filterDate !== '') {
            modifiedFilterDate = filterDate;
        } else {
            modifiedFilterDate = null;
        }
        replaceUrlParamsHandler({ searchBy: field, filterDate: modifiedFilterDate, page: '1' });
    };

    const handleEditRow = (e, row) => {
        history.push({
            pathname: `${PATHS.DELIVERY.FORM}`,
            search: `?type=edit&id=${row._id}`,
            state: { deliveryParentData: row },
        });
    };

    const mapDocs = (docs) => {
        let allDocs = [];
        allDocs = docs.map((x) => ({
            ...x,
            recipientName: `${x.recipientName || 'No Recipient Given'}, ${x.deliveryAddress || ''}`,
            formattedStatus: getStatus(x.status, x.riderId?._id),
            recipientNameFormatted: `${x.recipientName || 'No Recipient Given'}, ${x.deliveryAddress || ''}`,
            riderDetails: x.riderReference ? `${x.riderName} ${x.riderReference}` : `Rider Not Assigned`,
            merchant: x.merchantId && isObject(x.merchantId) ? x.merchantId : null,
            merchantName: x.merchantId && isObject(x.merchantId) ? x.merchantId.name : null,
            merchantPictureUrl: x.merchantId && isObject(x.merchantId) ? x.merchantId.merchantPictureUrl : null,
            merchantId: x.merchantId && isObject(x.merchantId) ? x.merchantId._id : x.merchantId,
            formattedRating: x.deliveryRating ? `${x.deliveryRating}/5` : 'Not Rated',
            orderReferenceNumber: x.orderReferenceNumber || '',
            deliveryDate:
                moment(x.deliveryDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')
                    ? `Today`
                    : `${moment(x.deliveryDate).format('YYYY-MM-DD')}`,
        }));
        return allDocs;
    };

    /**
     * Based on the passed column set url query params for column wise sorting
     * @param {boolean} isDescending - If true sorting is descending order else ascending order
     * @param {string} column - Which column need to be used
     */
    const handleColumnWiseSorting = (isDescending, column) => {
        replaceUrlParamsHandler({
            sort: column,
            order: isDescending ? -1 : 1,
        });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <ClientAlerts />
                    <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            className="w3-animate-opacity pointer"
                            onClick={() => {
                                replaceUrlParamsHandler({ status: 'inProgress', page: '1' });
                            }}
                        >
                            <StatCard
                                title="IN PROGRESS"
                                value={`${meta.deliveriesInProgress} DELIVERIES`}
                                icon="mdi mdi-clock-outline"
                                tooltipTitle="In Progress deliveries count ( Deliveries that are not yet completed )"
                                tooltipPlacement="bottom"
                                active={urlParamsSearch?.status === 'inProgress'}
                                loading={loading}
                            />
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            className="w3-animate-opacity pointer"
                            onClick={() => {
                                replaceUrlParamsHandler({ status: 'completedCurrentMonth', page: '1' });
                            }}
                        >
                            <StatCard
                                title={`IN ${moment(currentMonth).format('MMMM').toUpperCase()}`}
                                value={`${meta.completedDeliveriesCurrentMonth} DELIVERIES COMPLETED`}
                                tooltipTitle="Total deliveries completed this month"
                                tooltipPlacement="bottom"
                                icon="fas fa-check-circle"
                                active={urlParamsSearch?.status === 'completedCurrentMonth'}
                            />
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            className="w3-animate-opacity pointer"
                            onClick={() => {
                                replaceUrlParamsHandler({ status: 'completedToday', page: '1' });
                            }}
                        >
                            <StatCard
                                title="COMPLETED TODAY"
                                value={`${meta.deliveriesCompletedToday} DELIVERIES`}
                                tooltipTitle="Total deliveries completed today"
                                tooltipPlacement="bottom"
                                icon="fas fa-calendar-week"
                                active={urlParamsSearch?.status === 'completedToday'}
                            />
                        </motion.div>

                        {!IS_MERCHANT_VIEW() && (
                            <motion.div
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.9 }}
                                className="w3-animate-opacity pointer"
                                onClick={() => {
                                    history.push({
                                        pathname: `${PATHS.RIDER_PROFILE_MANAGEMENT.LIST_VIEW}`,
                                        search: 'tab=1',
                                    });
                                }}
                            >
                                <StatCard
                                    title="ACTIVE"
                                    value={`${meta.ridersActive} RIDERS`}
                                    tooltipTitle="Active rider count"
                                    tooltipPlacement="bottom"
                                    icon="fas fa-biking"
                                    // active={deliveryCondition?.status === 'completedToday'}
                                />
                            </motion.div>
                        )}
                    </Row>
                    <br />
                    <Row style={{ justifyContent: 'space-between' }} className="ml-3 mr-3">
                        <div>
                            <i className="fas fa-circle" style={{ color: '#08d404' }} />
                            <span className="ml-2 font-weight-bold">DELIVERIES IN PROGRESS</span>
                        </div>
                        <div className="row">
                            {hasAccess(PERMISSIONS.ORDER, ['View']) && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-circle shadow-lg nw-md"
                                    onClick={() => {
                                        history.push({
                                            pathname: PATHS.ORDER_PROCESSING.DEFAULT,
                                        });
                                    }}
                                >
                                    <i className="fas fa-cube fa-lg p-1" />
                                </motion.div>
                            )}
                            <div className="ml-3">
                                <motion.button
                                    id="reload-comp-btn"
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-form-btn circle shadow-lg nw-md pr-2 pl-3 pointer"
                                    onClick={() => {
                                        reloadComponent();
                                    }}
                                >
                                    <>
                                        <i className="fas fa-redo-alt left-icon"></i>
                                    </>
                                </motion.button>
                            </div>
                        </div>
                    </Row>
                    <br />

                    <Row>
                        <TableCustom
                            tableData={deliveryListData}
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            loading={loading}
                            handleEdit={handleEditRow}
                            showEdit={hasAccess(PERMISSIONS.DELIVERY, ['Edit'])}
                            showDelete={false}
                            showView={false}
                            removeId={false}
                            isFullScreenShow
                            showStickyView
                            searchProp={searchParams}
                            filters={tableDefaultFilters}
                            isManualSearch
                            onDateFilter={handleFilterDate}
                            onSort={handleColumnWiseSorting}
                        />
                    </Row>
                </Container>
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.4} />
        </>
    );
}

HomePage.propTypes = {
    location: PropTypes.any,
};

const HotHomePage = hot(HomePage);

export default HotHomePage;
