export const defaultTableHeaders = [
    {
        key: 'name',
        value: 'Name',
    },
    {
        key: 'username',
        value: 'Username',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
    {
        key: 'email',
        value: 'E-Mail',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
    {
        key: 'deletedAt',
        value: 'Deleted At',
        type: 'dateTime',
    },
    {
        key: '_id',
        value: 'Restore',
        type: 'restoreButton',
    },
    {
        key: '_id',
        value: 'Permanent Delete',
        type: 'permanentDeleteButton',
    },
    { key: 'deletedBy', value: 'Deleted By', type: 'userObject' },
];
