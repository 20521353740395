/* eslint-disable react-hooks/exhaustive-deps */

/**
 * Batch Delivery Assignment Page
 * https://scmstudio.atlassian.net/browse/SDMC-183?atlOrigin=eyJpIjoiN2I1Y2VhOWQ1OWI4NGFlNWFlZThkMDU0NzYxNDRmYzUiLCJwIjoiaiJ9
 * https://scmstudio.atlassian.net/l/cp/tsPVu1RA
 */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useRef, useState } from 'react';
import { Container, CardBody, Card } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';

// #region assets | components
import { CustomToast } from 'components';

// #region other imports
import { VEHICLE_TYPES } from 'constants/constants';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #region services
import { getDeliveryListData } from 'services/deliveries.service';

// #endregion containers
import InitialForm from './containers/InitialForm';
import { buildInitialFormValues } from './constants';

// #endregion import
import styles from './styles';
import './styles/index.css';
import reducer from './reducer';
import saga from './saga';
import { LOADED_DELIVERIES, LOADER_START, LOADER_STOP, UPDATE_SELECTED_DELIVERIES } from './actionTypes';
import ListTable from './containers/ListTable';
import RiderAssignForm from './containers/RiderAssign';

// This Key Will be the Reducer Key
const key = 'BatchDeliveryAssignment';

/**
 * Batch Delivery Assignment
 * @param {*} props
 * @returns {React.FunctionComponent}
 */
function BatchDeliveryAssignmentPage() {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    const _isMounted = useRef(true);

    const dispatch = useDispatch();

    // form states
    const [formData, setFormData] = useState(buildInitialFormValues());

    // component unmount
    useEffect(
        () => () => {
            _isMounted.current = false;
        },
        []
    );

    useEffect(() => {
        dispatch({ type: LOADED_DELIVERIES, payload: { id: uuid(), docs: [] } });
        dispatch({
            type: UPDATE_SELECTED_DELIVERIES,
            payload: { id: uuid(), docs: [] },
        });
        loadDeliveryListData({ vehicleType: VEHICLE_TYPES.MOTOR_BIKE, showLoading: true });
    }, []);

    /**
     * Load Delivery List Data
     * @param {*} args
     */
    const loadDeliveryListData = (args) => {
        if (args.showLoading) {
            dispatch({ type: LOADER_START });
        }
        getDeliveryListData({ ...args, limit: 1000, select: 'deliveryReadyTime', status: 'dispatched' })
            .then((res) => {
                const { data } = res;
                delete data.sort;
                if (_isMounted.current) {
                    dispatch({ type: LOADED_DELIVERIES, payload: { id: uuid(), docs: data.docs } });
                }
            })
            .catch((e) => {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            })
            .finally(() => {
                if (args.showLoading) {
                    dispatch({ type: LOADER_STOP });
                }
            });
    };

    // Common Props to be passed to container
    const commonContainerProps = {
        formData,
        setFormData,
        loadDeliveryListData,
    };

    /** JSX */
    return (
        <>
            <div className="page-content mb-5">
                <br />
                <Container fluid>
                    <Card style={styles.cardContainer} className="shadow-lg">
                        <CardBody className="delivery-batch-assign__form-container">
                            <InitialForm {...commonContainerProps} />
                            <ListTable {...commonContainerProps} />
                            <RiderAssignForm {...commonContainerProps} />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    );
}

BatchDeliveryAssignmentPage.propTypes = {};

const mapStateToProps = (state) => ({
    ...state,
});

const HotBatchDeliveryAssignmentPage = hot(BatchDeliveryAssignmentPage);

export default connect(mapStateToProps, {})(HotBatchDeliveryAssignmentPage);
