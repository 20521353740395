import React from 'react';
import { Row, Card, CardBody, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import moment from 'moment';

import PropTypes from 'prop-types';
import paths from 'routes/paths';

function ListSubView(props) {
    const { allNotifications = [], selectedNotification = null, history } = props;

    // const handleChangeSearch = (e) => {
    //     const { value } = e.target;
    //     const filteredVals = allRidersByClient.filter(
    //         (x) => x.fullName.toLowerCase().indexOf(value.toLowerCase()) !== -1
    //     );
    //     if (value && value !== '') {
    //         setAllRidersToDisplay(filteredVals);
    //     } else {
    //         setAllRidersToDisplay(null);
    //     }
    // };

    return (
        <>
            <Card
                style={{
                    borderRadius: 10,
                    backgroundColor: '#F4F6F8',
                    minHeight: '70vh',
                    maxHeight: '60vh',
                    overflowY: 'scroll',
                }}
                className="shadow-sm"
            >
                <CardBody className="p-1">
                    <DropdownItem divider />
                    <Row style={{ justifyContent: 'flex-end', marginRight: 10 }}>
                        <div>{allNotifications.length} Notifications</div>
                    </Row>

                    {allNotifications.map((val) => (
                        <motion.div
                            className="pointer"
                            key={val._id}
                            onClick={() => {
                                if (history) {
                                    history.replace({
                                        pathname: `${paths.NOTIFICATION.DEFAULT}`,
                                        search: `?id=${val._id}`,
                                        state: {
                                            notification: { _id: val._id },
                                        },
                                    });
                                }
                            }}
                        >
                            <Card
                                style={{
                                    backgroundColor:
                                        selectedNotification && selectedNotification._id === val._id
                                            ? '#FFF'
                                            : '#F4F6F8',
                                    width: '100%',
                                    borderBottom: '1px solid #ebebeb',
                                    marginTop: -20,
                                }}
                                className="shadow-none"
                            >
                                <CardBody>
                                    <Row
                                        style={{
                                            justifyContent: 'space-between',
                                            flexDirection: 'row',
                                            maxHeight: 120,
                                        }}
                                    >
                                        <div style={{ maxWidth: '100%' }}>
                                            <h5>
                                                {val?.title?.length > 40 ? (
                                                    <>{`${val.title.substring(0, 40)}...`}</>
                                                ) : (
                                                    <>{val.title || ''}</>
                                                )}
                                            </h5>
                                            <div className="ml-1">
                                                {val.description.length > 50 ? (
                                                    <div style={{ marginLeft: -3 }}>
                                                        {`${val.description.substring(0, 50)}...`}
                                                    </div>
                                                ) : (
                                                    <p>{val.description}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'right', fontWeight: 'bolder', marginTop: 5 }}>
                                            <div>{moment(val.createdAt).fromNow()}</div>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </motion.div>
                    ))}
                </CardBody>
            </Card>
        </>
    );
}

ListSubView.propTypes = {
    allNotifications: PropTypes.array,
    selectedNotification: PropTypes.any,
    history: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

export default connect(mapStateToProps, {})(ListSubView);
