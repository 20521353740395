/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';

/**
 * Modal for enabling the API key
 */
function WebhookTransactionData(props) {
    const { show, handleToggle, transactionId, name, eventGroup, postUrl, previousPostUrl, previousWebhookName } =
        props;

    return (
        <DeveloperModal header={`Transaction data for ${transactionId}`} show={show} handleToggle={handleToggle}>
            <Container>
                <Row className="mb-2">
                    <Col>
                        <Label htmlFor="name">Name</Label>
                        <Input name="name" className="form-control" value={name} readOnly />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Label htmlFor="previousWebhookName">Previous Webhook Name</Label>
                        <Input
                            name="previousWebhookName"
                            type="textarea"
                            className="form-control"
                            style={{ borderRadius: 15, backgroundColor: '#F5F4F4', border: '#E7E4E4 1px solid' }}
                            value={previousWebhookName}
                            readOnly
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Label htmlFor="eventGroup">Event Group</Label>
                        <Input
                            name="eventGroup"
                            type="textarea"
                            className="form-control"
                            style={{ borderRadius: 15, backgroundColor: '#F5F4F4', border: '#E7E4E4 1px solid' }}
                            value={eventGroup}
                            readOnly
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Label htmlFor="postUrl">URL</Label>
                        <Input
                            name="postUrl"
                            type="textarea"
                            className="form-control"
                            style={{ borderRadius: 15, backgroundColor: '#F5F4F4', border: '#E7E4E4 1px solid' }}
                            value={postUrl}
                            readOnly
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Label htmlFor="previousPostUrl">Previous Webhook Url</Label>
                        <Input
                            name="previousPostUrl"
                            type="textarea"
                            className="form-control"
                            style={{ borderRadius: 15, backgroundColor: '#F5F4F4', border: '#E7E4E4 1px solid' }}
                            value={previousPostUrl}
                            readOnly
                        />
                    </Col>
                </Row>
            </Container>
        </DeveloperModal>
    );
}

WebhookTransactionData.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    transactionId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    eventGroup: PropTypes.string,
    postUrl: PropTypes.string,
    previousPostUrl: PropTypes.string,
    previousWebhookName: PropTypes.string,
};

export default WebhookTransactionData;
