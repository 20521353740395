import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { motion } from 'framer-motion';
import moment from 'moment';

// #region assets | components
import Restore from 'components/Icons/Restore';
import DeleteAccount from 'components/Icons/DeleteAccount';
import Warning from 'components/Icons/Warning';

// #region utils
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

/**
 * Delete and restore account alert for User
 * @returns {UserAlert} - Showing Alert component for User
 */
export default function UserAlert(props) {
    const { toggleRestoreModal, toggleDeleteModal, requestedAt, requestedVia } = props;

    return (
        <>
            <div className="alert alert-warning mb-0 border border-warning p-4 rounded" role="alert">
                <div className="d-flex">
                    <Warning color="#fd4a4a" />
                    <p className="h5 pl-3">This user has requested to delete the User Account</p>
                </div>
                <div style={{ marginLeft: '60px' }}>
                    <div className="d-flex">
                        <p className="mr-4">{`Requested At : ${moment(requestedAt).format('DD-MM-YYYY hh:mm A')}`}</p>
                        <p>Requested Via : {requestedVia}</p>
                    </div>
                    <div className="d-flex">
                        {hasAccess(PERMISSIONS.RESTORE_USER_ACCOUNT, ['Edit']) && (
                            <motion.button
                                className="scootix-btn-radius secondary-btn pt-2 pb-2 mr-3 mb-3 pr-4 pl-4 shadow-lg justify-content-between"
                                style={{ borderRadius: 10 }}
                                type="submit"
                                onClick={toggleRestoreModal}
                            >
                                <Restore />
                                &nbsp;&nbsp;RESTORE THE ACCOUNT
                            </motion.button>
                        )}

                        {hasAccess(PERMISSIONS.DELETE_USER_ACCOUNT, ['Delete']) && (
                            <Button
                                style={{ backgroundColor: '#FD4A4A', borderRadius: 8 }}
                                className="form-delete-btn pt-2 pb-2 mb-3 pr-4 pl-4 shadow-lg"
                                type="submit"
                                onClick={toggleDeleteModal}
                            >
                                <DeleteAccount />
                                &nbsp;&nbsp;DELETE THE ACCOUNT
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

UserAlert.propTypes = {
    toggleRestoreModal: PropTypes.func,
    toggleDeleteModal: PropTypes.func,
    requestedAt: PropTypes.string,
    requestedVia: PropTypes.string,
};
