import * as React from 'react';
import PropTypes from 'prop-types';

function FailExclamation(props) {
    const { color = '#FD4A4A', width = '108', height = '108' } = props;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 108 108"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_2951_205)">
                <path
                    d="M54 94.5C76.3675 94.5 94.5 76.3675 94.5 54C94.5 31.6325 76.3675 13.5 54 13.5C31.6325 13.5 13.5 31.6325 13.5 54C13.5 76.3675 31.6325 94.5 54 94.5Z"
                    stroke={color}
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M54 36V54" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M54 72H54.045" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2951_205">
                    <rect width="108" height="108" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
FailExclamation.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default FailExclamation;
