import * as React from 'react';
import PropTypes from 'prop-types';

function SuccessCheck(props) {
    const { color = '#0F891C' } = props;
    return (
        <svg width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_2932_1509)">
                <path
                    d="M54 94.5C76.3675 94.5 94.5 76.3675 94.5 54C94.5 31.6325 76.3675 13.5 54 13.5C31.6325 13.5 13.5 31.6325 13.5 54C13.5 76.3675 31.6325 94.5 54 94.5Z"
                    stroke={color}
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M40.5 54L49.5 63L67.5 45"
                    stroke={color}
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2932_1509">
                    <rect width="108" height="108" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
SuccessCheck.propTypes = {
    color: PropTypes.string,
};

export default SuccessCheck;
