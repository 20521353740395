import { MAP_CENTER_COORDINATES_COL, MAP_CENTER_COORDINATES_KL } from 'constants/constants';
import { getVisitingObject } from './checkAuth';

export const breakDownDistanceMatrixData = (data) => {
    const modifiedData = {};
    if (data.rows && data.rows.length > 0) {
        const baseDistanceData = data.rows[0];
        if (baseDistanceData.elements && baseDistanceData.elements.length > 0) {
            const baseDistanceElementData = baseDistanceData.elements[0];
            if (baseDistanceElementData.status && baseDistanceElementData.status === 'OK') {
                modifiedData.baseDistance = baseDistanceElementData.distance;
                modifiedData.baseDuration = baseDistanceElementData.duration;
                const distanceText = modifiedData?.baseDistance?.text;
                if (distanceText) {
                    const [text] = distanceText.split(' ');
                    modifiedData.distance = parseFloat(text);
                }
            }
        }
    }
    return modifiedData;
};

/**
 * Returns the center coordinates of the map based on the country of the visiting client.
 * @returns {Array<number>} The center coordinates of the map as an array of [latitude, longitude].
 *                          Returns null if the visiting client's country is not supported.
 */
export function getMapCenterCoordinatesByClient() {
    const { visitingClient } = getVisitingObject();
    if (visitingClient?.countryIsoCode === 'my') {
        return MAP_CENTER_COORDINATES_KL;
    }
    if (visitingClient?.countryIsoCode === 'lk') {
        return MAP_CENTER_COORDINATES_COL;
    }
    return null;
}
