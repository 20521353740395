import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

// #region assets | components
import CrossCircle from 'components/Icons/CrossCircle';

/**
 * A modal used for self deletion.
 * Content of the modal should be passed as children.
 * @param {object} props
 */
function ConfirmationModal(props) {
    const { show, handleToggle, header, children, mode = 'enable', onclose } = props;

    return (
        <div className="custom-scootix-modal">
            <Modal
                size="md"
                isOpen={show}
                backdrop
                toggle={handleToggle}
                style={{ borderRadius: 15 }}
                className="modal-dialog modal-dialog-centered scootix-confirmation-modal"
            >
                {mode === 'disable' ? (
                    <ModalHeader
                        style={{
                            backgroundColor: '#FD4A4A',
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15,
                        }}
                    >
                        <div style={{ display: 'flex', width: '31rem', justifyContent: 'space-between' }}>
                            <span style={{ color: '#FFF' }}>{header}</span>
                            <div style={{ cursor: 'pointer' }} onClick={() => onclose(!show)}>
                                <CrossCircle />
                            </div>
                        </div>
                    </ModalHeader>
                ) : (
                    <ModalHeader
                        className="scootix-bg-radiant"
                        style={{
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15,
                        }}
                    >
                        <div style={{ display: 'flex', width: '31rem', justifyContent: 'space-between' }}>
                            <span style={{ color: '#FFF' }}>{header}</span>
                            <div style={{ cursor: 'pointer' }} onClick={() => onclose(!show)}>
                                <CrossCircle />
                            </div>
                        </div>
                    </ModalHeader>
                )}
                <ModalBody>{children}</ModalBody>
            </Modal>
        </div>
    );
}

ConfirmationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    /** Text for the header */
    header: PropTypes.string,
    children: PropTypes.node,
    /** Either 'enable' (default) or 'disable', which is needed to set the styles. */
    mode: PropTypes.string,
    onclose: PropTypes.func,
};

export default ConfirmationModal;
