// #region imports
import { AUTH_CLIENT, AUTH_MERCHANT, USER } from 'constants/local-storage.constants';
import { EncryptStorage } from 'encrypt-storage';

export const ENCRYPTED_HASH = 'k6GAdP*UkDF*%$$Q2r7muFaSv#nUOgysHO@$qSjz1gz&sxtwpbJ0*CQrGxg';

const encryptedLocalStorage = new EncryptStorage(ENCRYPTED_HASH, {
    prefix: '@web1',
    storageType: 'localStorage',
});

const getVisitingObject = () => {
    try {
        let visitingMerchant = null;
        let visitingClient = null;

        const merchant = encryptedLocalStorage.getItem(AUTH_MERCHANT);
        const client = encryptedLocalStorage.getItem(AUTH_CLIENT);
        if (merchant) {
            visitingMerchant = merchant;
        }
        if (client) {
            visitingClient = client;
        }
        return {
            visitingMerchant,
            visitingClient,
        };
    } catch (e) {
        console.log(e);
    }
    return {};
};

const getUser = () => {
    try {
        const user = encryptedLocalStorage.getItem(USER);
        return user || {};
    } catch (e) {
        return {};
    }
};

export const DEBUG_MODE = () => {
    if (process.env.NODE_ENV === 'debug' || process.env.REACT_APP_ENV === 'debug' || window.env === 'debug') {
        return true;
    }
    return false;
};

export const TEST_ENV = () => {
    if (process.env.NODE_ENV === 'test' || process.env.REACT_APP_ENV === 'test' || window.env === 'test') {
        return true;
    }
    return false;
};

export const DEV_ENV = () => {
    if (
        process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_ENV === 'development' ||
        window.env === 'development'
    ) {
        return true;
    }
    return false;
};

export const PROD_ENV = () => {
    if (
        process.env.NODE_ENV === 'production' ||
        process.env.REACT_APP_ENV === 'production' ||
        window.env === 'production'
    ) {
        return true;
    }
    return false;
};

export const STAGING_ENV = () => {
    if (process.env.NODE_ENV === 'staging' || process.env.REACT_APP_ENV === 'staging' || window.env === 'staging') {
        return true;
    }
    return false;
};

export const IS_CLIENT_MODE = () => {
    try {
        const data = getVisitingObject();
        if (data?.visitingClient) {
            return true;
        }
    } catch (e) {
        console.error(e);
    }
    return false;
};

const BLOCKED_FOR_MERCHANTS = () => {
    const { role } = getUser();
    if (role && (role === 'merchantUser' || role === 'merchantAdmin')) {
        return true;
    }
    return false;
};

export const __TEST__ = TEST_ENV();
export const __PROD__ = PROD_ENV();
export const __DEV__ = DEV_ENV();
export const __CLIENT_VIEW__ = IS_CLIENT_MODE();
export const __BLOCKED_FOR_MERCHANTS__ = BLOCKED_FOR_MERCHANTS();
