export const defaultTableHeaders = [
    {
        key: 'sequenceId',
        value: 'Billing Reference',
    },
    {
        key: 'referenceNumber',
        value: 'Delivery ID',
    },
    {
        key: 'deliveryType',
        value: 'Delivery Type',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
    {
        key: 'type',
        value: 'Billing Type',
        type: 'allCapsToHumanReadable',
    },
    {
        key: 'orderReferenceNumber',
        value: 'Order Number',
    },
    {
        key: 'merchantOrderNo',
        value: 'Merchant Order Number',
    },
    {
        key: 'pickupLocation',
        value: 'Pickup Address',
    },
    {
        key: 'deliveryAddress',
        value: 'Delivery Address',
    },
    {
        key: 'recipientName',
        value: 'Recipient Name',
    },
    {
        key: 'vehicleType',
        value: 'Vehicle Type',
    },
    {
        key: 'pickedUpAt',
        value: 'Pickup Date and Time',
        type: 'dateTime',
    },
    {
        key: 'deliveredAt',
        value: 'Delivery Date and Time',
        type: 'dateTime',
    },
    {
        key: 'formattedDistance',
        value: 'Distance',
    },
    {
        key: 'billingMethod',
        value: 'Billing Method',
    },
    {
        key: 'formattedDeliveryFee',
        value: 'Delivery Fee',
    },
    {
        key: 'merchantPaymentStatus',
        value: 'Merchant Payment Status',
    },
    {
        key: 'merchantPaymentReceivedDate',
        value: 'Merchant Payment Date',
        type: 'dateTime',
    },
    {
        key: 'merchantPaymentReference',
        value: 'Merchant Payment Reference',
    },
];

// table values for deliveries
export const defaultTableHeadersForDeliveries = [
    {
        key: 'referenceNumber',
        value: 'Delivery ID',
    },
    {
        key: 'merchantOrderNo',
        value: 'Merchant Order Number',
    },
    {
        key: 'deliveryType',
        value: 'Delivery Type',
    },
    {
        key: 'pickupLocation',
        value: 'From',
    },
    {
        key: 'recipientNameFormatted',
        value: 'Recipient',
    },
    {
        key: 'recipientName',
        value: 'Recipient Name',
    },
    {
        key: 'pickedUpAt',
        value: 'Pickup Date and Time',
        type: 'dateTime',
    },
    {
        key: 'deliveredAt',
        value: 'Delivery Date and Time',
        type: 'dateTime',
    },
    {
        key: 'view',
        value: 'View',
        type: 'viewDeliveryNavigation',
    },
];

export const initialDeliveryData = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: defaultTableHeadersForDeliveries,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};
