/**
 * MERCHANT SETTING FIXED DATA
 * DUPLICATE IN CLIENT SERVICE IF CHANGED HERE CHANGE THERE
 */
export const MERCHANT_SETTING_DEFAULT = {
    // External System Connections For Delivery
    externalSystemConnectionsForDelivery: {
        isActive: true,
    },
    delivery: {
        // (MOBILE USE & SCHEDULAR USE)
        riderSelfAssignment: {
            isEnabled: true, // Is Rider Self Assignment Enabled
            slabOneRadius: 10, // Slab One KM Radius
            slabOneTimeInterval: 1, // Slab One Search Time For Delivery
            slabTwoRadius: 50, // Slab Two KM Radius
            slabTwoTimeInterval: 10, // Slab Two Search Time For Delivery
            slabThreeRadius: 100, // Slab Three KM Radius
            slabThreeTimeInterval: 15, // Slab Three Search Time For Delivery
        },
    },
    schedular: {
        // @link https://scmstudio.atlassian.net/wiki/spaces/SCOOTIX/pages/58621955/Deliveries
        delivery: {
            // After All Time Slabs are completed if auto assignment is toggled delivery will fall under the category of this
            autoAssignment: {
                isEnabled: true, // Is Rider Self Assignment Enabled
                timeout: 15, // Timeout For Auto Assignment Only Within This Range The Auto Assignment Will Happen
            },
        },
    },
    deliveryImport: {
        // newOrder: {
        //     pickupLocationType: DELIVERY_IMPORT_LOCATION_TYPES.MERCHANT_LOCATION_ID,
        //     deliveryAddressType: DELIVERY_IMPORT_LOCATION_TYPES.PLACE_ID,
        // },
        // returnOrder: {
        //     pickupLocationType: DELIVERY_IMPORT_LOCATION_TYPES.PLACE_ID,
        //     deliveryAddressType: DELIVERY_IMPORT_LOCATION_TYPES.MERCHANT_LOCATION_ID,
        // },
        type: null, // ['DoctorOnCallModel']
    },
};

export const DELIVERY_IMPORT_TYPES = [
    {
        label: 'DoctorOnCall Model',
        value: 'DoctorOnCallModel',
    },
];

export const DELIVERY_IMPORT_TYPE_NAMES = {
    DOCTOR_ON_CALL: 'DoctorOnCallModel',
};
