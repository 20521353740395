import moment from 'moment';

/* eslint-disable camelcase */
export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { merchantId, warehouseSiteId } = form;
    if (!merchantId || !warehouseSiteId) {
        return { isFormValid, message };
    }

    isFormValid = true;
    return { isFormValid, message };
};

// eslint-disable-next-line consistent-return
export const buildLocationData = (id, idMeta, data) => {
    if (id === 'address') {
        if (data) {
            const { city = '', address = '', state = null, postal_code = '' } = data;
            return {
                city,
                state,
                [id]: `${address ? `${address}` : ''}`,
                [idMeta]: data,
                postalCode: postal_code || '',
            };
        }
        return {
            [id]: null,
            [idMeta]: null,
            city: '',
            state: '',
            postalCode: '',
        };
    }
};

/**
 * This function is responsible for mapping data which came from the backend to the table list view
 */
export const mapTransactionTableDocs = (docs) => {
    let allDocs = [];
    allDocs = docs.map((doc) => ({
        ...doc,
        createdAt: moment(doc.createdAt).format('DD-MM-YYYY HH:mm a'),
        createdByUserFullName: `${doc.createdByUserFullName} - ${doc.createdByUser.username}`,
        transactionName: doc.name,
        details: doc.meta,
    }));
    return allDocs;
};
