import React from 'react';
import PropTypes from 'prop-types';

// #region assets | components
import { Select } from 'components';
import { Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { motion } from 'framer-motion';

// #region helpers
import { reactSelectCustomStyles, getDefaultValueForSelect, getDateValue } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import { newDeliveryDays, nextDeliveryDays } from '../constants';

function DeliveryDateConfigurationOptions(props) {
    const {
        deliveryDateSettings,
        handleSubmit,
        setDeliveryDateSettings,
        formLoading,
        validated,
        submitted,
        hasEditPermission,
        formData,
    } = props;

    /** handle delivery date and next delivery date fields */
    const handleSelectChange = (event, id) => {
        if (id === 'newDeliveryDays') {
            setDeliveryDateSettings({
                ...deliveryDateSettings,
                newOrder: {
                    ...deliveryDateSettings?.newOrder,
                    deliveryDate: event ? event.value : null,
                },
            });
        }
        if (id === 'newNextDeliveryDate') {
            setDeliveryDateSettings({
                ...deliveryDateSettings,
                newOrder: {
                    ...deliveryDateSettings?.newOrder,
                    nextDeliveryDate: event ? event.value : null,
                },
            });
        }
        if (id === 'onDemandDeliveryDate') {
            setDeliveryDateSettings({
                ...deliveryDateSettings,
                onDemandOrder: {
                    ...deliveryDateSettings?.onDemandOrder,
                    deliveryDate: event ? event.value : null,
                },
            });
        }
        if (id === 'onDemandNextDeliveryDate') {
            setDeliveryDateSettings({
                ...deliveryDateSettings,
                onDemandOrder: {
                    ...deliveryDateSettings?.onDemandOrder,
                    nextDeliveryDate: event ? event.value : null,
                },
            });
        }
        if (id === 'returnDeliveryDate') {
            setDeliveryDateSettings({
                ...deliveryDateSettings,
                returnOrder: {
                    ...deliveryDateSettings?.returnOrder,
                    deliveryDate: event ? event.value : null,
                },
            });
        }
        if (id === 'returnNextDeliveryDate') {
            setDeliveryDateSettings({
                ...deliveryDateSettings,
                returnOrder: {
                    ...deliveryDateSettings?.returnOrder,
                    nextDeliveryDate: event ? event.value : null,
                },
            });
        }
    };

    /** handle delivery cut off time field */
    const handleTimeChange = (event, id) => {
        if (id === 'newDeliveryCutOffTime') {
            setDeliveryDateSettings({
                ...deliveryDateSettings,
                newOrder: {
                    ...deliveryDateSettings?.newOrder,
                    deliveryCutOffTime: event.target.value,
                },
            });
        }
        if (id === 'onDemandDeliveryCutOffTime') {
            setDeliveryDateSettings({
                ...deliveryDateSettings,
                onDemandOrder: {
                    ...deliveryDateSettings?.onDemandOrder,
                    deliveryCutOffTime: event.target.value,
                },
            });
        }
        if (id === 'returnDeliveryCutOffTime') {
            setDeliveryDateSettings({
                ...deliveryDateSettings,
                returnOrder: {
                    ...deliveryDateSettings?.returnOrder,
                    deliveryCutOffTime: event.target.value,
                },
            });
        }
    };

    /** handle accept deliveries checkboxes */
    const handleCheckChange = (event, id) => {
        if (id === 'newAcceptDeliveries') {
            setDeliveryDateSettings({
                ...deliveryDateSettings,
                newOrder: {
                    ...deliveryDateSettings?.newOrder,
                    acceptDeliveries: event.target.checked,
                },
            });
        }
        if (id === 'onDemandAcceptDeliveries') {
            setDeliveryDateSettings({
                ...deliveryDateSettings,
                onDemandOrder: {
                    ...deliveryDateSettings?.onDemandOrder,
                    acceptDeliveries: event.target.checked,
                },
            });
        }
        if (id === 'returnAcceptDeliveries') {
            setDeliveryDateSettings({
                ...deliveryDateSettings,
                returnOrder: {
                    ...deliveryDateSettings?.returnOrder,
                    acceptDeliveries: event.target.checked,
                },
            });
        }
    };

    /** set default labels of Delivery Date related to values */
    // eslint-disable-next-line consistent-return
    const getDeliveryDateLabel = (value, id) => {
        if (id === 'newDeliveryDays' || id === 'onDemandDeliveryDate' || id === 'returnDeliveryDate') {
            const obj = newDeliveryDays.find((d) => d.value === value);
            return obj?.label;
        }
    };

    /** set default labels of Next Delivery Date related to values */
    // eslint-disable-next-line consistent-return
    const getNextDeliveryDateLabel = (value, id) => {
        if (id === 'newNextDeliveryDate' || id === 'onDemandNextDeliveryDate' || id === 'returnNextDeliveryDate') {
            const obj = nextDeliveryDays.find((d) => d.value === value);
            return obj?.label;
        }
    };

    const generateAutomaticDeliveryDateUpdateData = () => {
        const { automaticDeliveryDateSettingsUpdated, updatedAt } = formData;
        const updatedBy = automaticDeliveryDateSettingsUpdated?.by?.fullName || 'System';

        const settingUpdatedAt = getDateValue(automaticDeliveryDateSettingsUpdated?.at || updatedAt, 'datetime');
        return `Updated By ${updatedBy} on ${settingUpdatedAt}`;
    };

    return (
        <div className="configurationOptions__container mt-5">
            <AvForm
                autoComplete="off"
                className="needs-validation default-form"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                id="deliveryDatesConfiguration"
            >
                {/* New Delivery */}
                <Row className="configurationOptions__row align-items-center mb-4">
                    <Col>
                        <h6>New Delivery</h6>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Input
                                className="accept-deliveries-check-input"
                                type="checkbox"
                                onChange={(event) => handleCheckChange(event, 'newAcceptDeliveries')}
                                checked={deliveryDateSettings?.newOrder?.acceptDeliveries}
                                defaultChecked={deliveryDateSettings?.newOrder?.acceptDeliveries}
                                id="newAcceptDeliveries"
                                submitted={submitted}
                                validated={validated}
                                required
                                disabled={!hasEditPermission}
                            />
                            <Label className="form-check-label" htmlFor="defaultCheck1">
                                Accept Deliveries
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="toDate">Delivery Date</Label>
                            <br />
                            <span style={{ fontSize: '10px' }}>When Created Before the Cut-Off</span>
                            <Select
                                value={getDefaultValueForSelect(
                                    getDeliveryDateLabel(
                                        deliveryDateSettings?.newOrder?.deliveryDate,
                                        'newDeliveryDays'
                                    )
                                )}
                                options={newDeliveryDays}
                                styles={reactSelectCustomStyles}
                                onChange={(event) => handleSelectChange(event, 'newDeliveryDays')}
                                id="newDeliveryDays"
                                submitted={submitted}
                                validated={validated}
                                required
                                isDisabled={!hasEditPermission}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="toDate">Delivery Cut-Off Time </Label>
                            <br />
                            <span style={{ fontSize: '10px' }}>In 24 Hrs Format</span>
                            <Input
                                type="time"
                                step="1"
                                className="form-control"
                                value={deliveryDateSettings?.newOrder?.deliveryCutOffTime}
                                onChange={(event) => handleTimeChange(event, 'newDeliveryCutOffTime')}
                                id="newDeliveryCutOffTime"
                                submitted={submitted}
                                validated={validated}
                                required
                                disabled={!hasEditPermission}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="toDate">Next Delivery Date</Label>
                            <br />
                            <span style={{ fontSize: '10px' }}>When Created After the Cut-Off</span>
                            <Select
                                value={getDefaultValueForSelect(
                                    getNextDeliveryDateLabel(
                                        deliveryDateSettings?.newOrder?.nextDeliveryDate,
                                        'newNextDeliveryDate'
                                    )
                                )}
                                options={nextDeliveryDays}
                                styles={reactSelectCustomStyles}
                                onChange={(event) => handleSelectChange(event, 'newNextDeliveryDate')}
                                id="newNextDeliveryDate"
                                submitted={submitted}
                                validated={validated}
                                required
                                isDisabled={!hasEditPermission}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr />

                {/* On-Demand Delivery */}
                <Row className="configurationOptions__row align-items-center mb-4">
                    <Col>
                        <h6>On-Demand Delivery</h6>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                onChange={(event) => handleCheckChange(event, 'onDemandAcceptDeliveries')}
                                checked={deliveryDateSettings?.onDemandOrder?.acceptDeliveries}
                                defaultChecked={deliveryDateSettings?.onDemandOrder?.acceptDeliveries}
                                id="onDemandAcceptDeliveries"
                                submitted={submitted}
                                validated={validated}
                                required
                                disabled={!hasEditPermission}
                            />
                            <Label className="form-check-label" htmlFor="defaultCheck1">
                                Accept Deliveries
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="toDate">Delivery Date</Label>
                            <br />
                            <span style={{ fontSize: '10px' }}>When Created Before the Cut-Off</span>
                            <Select
                                value={getDefaultValueForSelect(
                                    getDeliveryDateLabel(
                                        deliveryDateSettings?.onDemandOrder?.deliveryDate,
                                        'onDemandDeliveryDate'
                                    )
                                )}
                                options={newDeliveryDays}
                                styles={reactSelectCustomStyles}
                                onChange={(event) => handleSelectChange(event, 'onDemandDeliveryDate')}
                                id="onDemandDeliveryDate"
                                submitted={submitted}
                                validated={validated}
                                required
                                isDisabled={!hasEditPermission}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="toDate">Delivery Cut-Off Time </Label>
                            <br />
                            <span style={{ fontSize: '10px' }}>In 24 Hrs Format</span>
                            <Input
                                type="time"
                                step="1"
                                className="form-control"
                                value={deliveryDateSettings?.onDemandOrder?.deliveryCutOffTime}
                                onChange={(event) => handleTimeChange(event, 'onDemandDeliveryCutOffTime')}
                                id="onDemandDeliveryCutOffTime"
                                submitted={submitted}
                                validated={validated}
                                required
                                disabled={!hasEditPermission}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="toDate">Next Delivery Date</Label>
                            <br />
                            <span style={{ fontSize: '10px' }}>When Created After the Cut-Off</span>
                            <Select
                                value={getDefaultValueForSelect(
                                    getNextDeliveryDateLabel(
                                        deliveryDateSettings?.onDemandOrder?.nextDeliveryDate,
                                        'onDemandNextDeliveryDate'
                                    )
                                )}
                                options={nextDeliveryDays}
                                styles={reactSelectCustomStyles}
                                onChange={(event) => handleSelectChange(event, 'onDemandNextDeliveryDate')}
                                id="onDemandNextDeliveryDate"
                                submitted={submitted}
                                validated={validated}
                                required
                                isDisabled={!hasEditPermission}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr />

                {/* Return Delivery */}
                <Row className="configurationOptions__row align-items-center mb-4">
                    <Col>
                        <h6>Return Delivery</h6>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                onChange={(event) => handleCheckChange(event, 'returnAcceptDeliveries')}
                                checked={deliveryDateSettings?.returnOrder?.acceptDeliveries}
                                defaultChecked={deliveryDateSettings?.returnOrder?.acceptDeliveries}
                                id="returnAcceptDeliveries"
                                submitted={submitted}
                                validated={validated}
                                required
                                disabled={!hasEditPermission}
                            />
                            <Label className="form-check-label" htmlFor="defaultCheck1">
                                Accept Deliveries
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="toDate">Delivery Date</Label>
                            <br />
                            <span style={{ fontSize: '10px' }}>When Created Before the Cut-Off</span>
                            <Select
                                value={getDefaultValueForSelect(
                                    getDeliveryDateLabel(
                                        deliveryDateSettings?.returnOrder?.deliveryDate,
                                        'returnDeliveryDate'
                                    )
                                )}
                                options={newDeliveryDays}
                                styles={reactSelectCustomStyles}
                                onChange={(event) => handleSelectChange(event, 'returnDeliveryDate')}
                                id="returnDeliveryDate"
                                submitted={submitted}
                                validated={validated}
                                required
                                isDisabled={!hasEditPermission}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="toDate">Delivery Cut-Off Time</Label>
                            <br />
                            <span style={{ fontSize: '10px' }}>In 24 Hrs Format</span>
                            <Input
                                type="time"
                                step="1"
                                className="form-control"
                                value={deliveryDateSettings?.returnOrder?.deliveryCutOffTime}
                                onChange={(event) => handleTimeChange(event, 'returnDeliveryCutOffTime')}
                                id="returnDeliveryCutOffTime"
                                submitted={submitted}
                                validated={validated}
                                required
                                disabled={!hasEditPermission}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="toDate">Next Delivery Date</Label>
                            <br />
                            <span style={{ fontSize: '10px' }}>When Created After the Cut-Off</span>
                            <Select
                                value={getDefaultValueForSelect(
                                    getNextDeliveryDateLabel(
                                        deliveryDateSettings?.returnOrder?.nextDeliveryDate,
                                        'returnNextDeliveryDate'
                                    )
                                )}
                                options={nextDeliveryDays}
                                styles={reactSelectCustomStyles}
                                onChange={(event) => handleSelectChange(event, 'returnNextDeliveryDate')}
                                id="returnNextDeliveryDate"
                                submitted={submitted}
                                validated={validated}
                                required
                                isDisabled={!hasEditPermission}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr />

                {hasAccess(PERMISSIONS.AUTOMATIC_DELIVERY_DATE_SETTINGS, ['Edit']) && (
                    <Row className="d-flex justify-content-between" style={{ justifyContent: 'flex-start' }}>
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                            type="submit"
                            submitted={submitted}
                            validated={validated}
                            disabled={formLoading}
                        >
                            {formLoading ? (
                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                            ) : (
                                <>
                                    <i className="fas fa-save left-icon"></i>
                                    <span className="h6">Update</span>
                                </>
                            )}
                        </motion.button>

                        <span className="p-4">{generateAutomaticDeliveryDateUpdateData()}</span>
                    </Row>
                )}
            </AvForm>
        </div>
    );
}

DeliveryDateConfigurationOptions.propTypes = {
    deliveryDateSettings: PropTypes.object,
    handleSubmit: PropTypes.func,
    setDeliveryDateSettings: PropTypes.func,
    formLoading: PropTypes.bool,
    validated: PropTypes.bool,
    submitted: PropTypes.bool,
    hasEditPermission: PropTypes.bool,
    formData: PropTypes.object,
};

export default DeliveryDateConfigurationOptions;
