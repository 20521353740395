/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

export const filterRidersBasedByAssigningDeliveryConditions = (riders, deliveryData) => {
    let allRiders = riders;
    const filteredDocs = [];
    if (!deliveryData || !deliveryData.merchantId) {
        return riders;
    }
    // Limited To Merchant Validation Filtration
    if (deliveryData.merchant && deliveryData.merchant.isLimitDeliveriesToMerchantRider) {
        // Filter Riders By Merchant
        allRiders = riders.filter((x) => x.merchantId && x.merchantId === deliveryData.merchantId);
    }

    // eslint-disable-next-line array-callback-return
    allRiders.map((rider) => {
        // Limited to Rider Validation
        // if Merchant is Limited to Rider display only merchant riders
        if (deliveryData.merchant && deliveryData.merchant.isLimitDeliveriesToMerchantRider) {
            if (!rider.merchantId) {
                return;
            }
            // If delivery merchant and rider merchant doesn't match return
            if (rider.merchantId !== deliveryData.merchantId) {
                return;
            }
        }

        // If Rider is Limited to Merchant Check if Rider Merchant Id and delivery merchant Id same
        if (rider.isLimitedToMerchant && rider.merchantId) {
            // If delivery merchant and rider merchant doesn't match return
            if (rider.merchantId !== deliveryData.merchantId) {
                return;
            }
        }
        if (deliveryData.vehicleType) {
            if (rider.vehicleType !== deliveryData.vehicleType) {
                return;
            }
        }

        if (deliveryData.isFemaleDriver && rider.gender !== 'Female') {
            return;
        }

        if (deliveryData.isHalal && rider.isMuslim !== deliveryData.isHalal) {
            return;
        }

        filteredDocs.push(rider);
    });

    return filteredDocs;
};
