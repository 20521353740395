/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

function BackToTop() {
    const [isTop, setIsTop] = useState(false);
    const [isBottom, setIsBottom] = useState(true);

    useEffect(() => {
        function watchScroll(event) {
            setIsTop(window.scrollY > 300);
            setIsBottom(window.scrollY < 300);
        }

        window.addEventListener('scroll', watchScroll);
        return () => {
            window.removeEventListener('scroll', watchScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const scrollToBottom = () => {
        window.scrollTo({
            top: 100000,
            behavior: 'smooth',
        });
    };

    const backToTopBool = isTop ? 'active' : '';
    const backToBottomBool = isBottom ? 'active' : '';

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={`back-to-top ${backToTopBool} pointer`} id="backToTop" onClick={() => scrollToTop()}>
                    <i className="fa fa-arrow-up fa-sm" />
                </div>
                <div
                    className={`back-to-bottom ${backToBottomBool} pointer`}
                    id="backToTop"
                    onClick={() => scrollToBottom()}
                >
                    <i className="fa fa-arrow-down fa-sm" />
                </div>
            </div>
        </>
    );
}

export default BackToTop;
