/* eslint-disable indent */
import {
    GET_IMPORT_HISTORY,
    GET_ONE_IMPORT_HISTORY,
    GET_ALL_IMPORT_HISTORY,
    GET_ONE_IMPORT_HISTORY_BY_PENDING_DATA_ID,
    DELETE_REVERT_PENDING_DATA_DELIVERY_JOB,
    GET_APPROVE_PENDING_DATA_DELIVERY_JOB,
    RETRY_DELIVERY_IMPORT_JOB,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Import History Service
 * @returns {object}
 */
export const getImportHistoryService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate,
    type
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_IMPORT_HISTORY}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${populate ? `&${populate}&` : ''}${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }${type ? `&type=${type}` : ``}`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Import History By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getImportHistoryByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ONE_IMPORT_HISTORY).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Import History
 * @returns {Promise}
 */
export const getAllImportHistoryService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_IMPORT_HISTORY).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Import History By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getImportHistoryByPendingDataIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ONE_IMPORT_HISTORY_BY_PENDING_DATA_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Revert Pending Data Delivery Job
 * @param {string} id
 * @returns {Promise}
 */

export const revertPendingDataDeliveryJobService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_REVERT_PENDING_DATA_DELIVERY_JOB).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Approve Pending Data Delivery Job
 * @param {string} id
 * @returns {Promise}
 */

export const approvePendingDataDeliveryJobService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_APPROVE_PENDING_DATA_DELIVERY_JOB).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Retry Delivery Import Job
 * @param {string} id
 * @returns {Promise}
 */

export const retryDeliveryImportJobService = (data) => {
    try {
        const url = UrlBuilder().setUrl(RETRY_DELIVERY_IMPORT_JOB).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
