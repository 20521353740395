import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';

// #region component | assets
import { TableCustom } from 'components';

export default function DeliveryCompletedTable(props) {
    const { deliverySummaryData, handleViewRow, formLoading } = props;

    return (
        <>
            <div style={{ borderRadius: 15, margin: 10 }}>
                <div style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                    <div
                        style={{
                            backgroundColor: '#F9F7F7',
                            borderTopRightRadius: 15,
                            borderTopLeftRadius: 15,
                            minHeight: 20,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                padding: 23,
                                color: '#807F7D',
                                fontSize: 19,
                                fontWeight: 'bold',
                            }}
                        >
                            Deliveries Based on Delivery Completion Date
                        </div>
                    </div>
                    <div>
                        <Row>
                            <TableCustom
                                tableData={deliverySummaryData}
                                isLazyLoading={true}
                                handleView={handleViewRow}
                                loading={formLoading}
                                isExport
                                type="deliverySummary"
                                exportModalHeader="Export Delivery Summary"
                                removeAction
                                removeId={false}
                                isFilter={false}
                                isSearch={false}
                            />
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}

DeliveryCompletedTable.propTypes = {
    deliverySummaryData: PropTypes.object,
    handleViewRow: PropTypes.func,
    formLoading: PropTypes.bool,
};
