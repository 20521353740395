/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import toast from 'react-hot-toast';

// #region assets | components
import { TableCustom, CustomToast, ScootixModal, BackdropLoader } from 'components';
import AttachmentPreviewModal from 'components/Modals/Image-Preview';
import TextPreviewModal from 'components/Modals/Texts-Previews';

// #regions utils
import {
    attachmentArchiveTableHeaders,
    deliveryAttachmentsInitialValues,
} from 'pages/Modules/Delivery-Management/modules/Delivery-Form/constants';
import { apiErrorHandler } from 'utils/helpers';

// #region services
import { updateDeliveryAttachmentService } from 'services/deliveries.service';
function AttachmentArchiveModel(props) {
    const { deliveryAttachments, loadAttachments } = props;

    // Initial Payload for Restore
    const payload = { isArchived: false };

    // Attachment Data
    const [attachment, setAttachment] = useState();
    const [noteAttachment, setNoteAttachment] = useState();

    // Models
    const [showPreview, setShowPreview] = useState(false);
    const [noteShowPreview, setNoteShowPreview] = useState(false);
    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });
    const [backdropLoading, setBackdropLoading] = useState(0);

    const toggleAttachmentModal = () => {
        setShowPreview(!showPreview);
    };

    const toggleNoteAttachmentModal = () => {
        setNoteShowPreview(!noteShowPreview);
    };

    // Filter and map Data to the archive attachment table
    const mapDeletedAttachmentAndNotes = (record) => {
        let allDocs = [];
        allDocs = record
            .filter((x) => x.isArchived === true)
            .map((x) => ({
                ...x,
                id: x._id,
                attachment: x.attachment?.presignedUrl,
                key: x.attachment?.key,
                mimetype: x.attachment?.mimetype,
                isVisibleToRider: x?.isVisibleToRider,
                user: x.createdByRider.length > 0 ? x?.createdByRider[0]?.fullName : x?.createdByUser[0]?.fullName,
                userName:
                    x.createdByRider.length > 0 ? x?.createdByRider[0]?.referenceNumber : x?.createdByUser[0]?.username,
                userType: x.createdByRider.length > 0 ? 'Rider' : 'Console',
                createdAt: moment(x.createdAt).format('DD-MM-YYYY hh:mm A'),
                updatedAt: moment(x.updatedAt).format('DD-MM-YYYY hh:mm A'),
            }));
        return allDocs;
    };

    // handle view attachment btn function
    const handleViewAttachment = (row) => {
        const { category, mimetype, note } = row;
        if (row.attachment && category === 'Attachment') {
            if (mimetype === 'application/pdf' || mimetype === 'message/rfc822') {
                handleDownloadRow(row);
            } else {
                setAttachment(row.attachment);
                toggleAttachmentModal();
            }
        }
        if (!row.attachment && category === 'Note') {
            setNoteAttachment(note);
            toggleNoteAttachmentModal();
        }
    };

    const handleOnRestore = (row) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: { id: row.id },
            message: `Are you sure you want to restore this Delivery Attachment ?`,
            onConfirm: (data) => handleRestore(data),
        });
    };

    const handleDownloadRow = async (row) => {
        if (!row?.attachment) {
            toast.custom((t) => <CustomToast text="File Not Found, Contact Admin" t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setBackdropLoading((prevState) => prevState + 1);

        try {
            if (row?.attachment) {
                const link = document.createElement('a');
                link.href = row?.attachment;
                window.open(link.href);
                toast.custom((t) => <CustomToast text="Successfully Downloaded File" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            console.log(e);
        }

        setBackdropLoading((prevState) => prevState - 1);
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: !scootixModalState.show, data: null });
    };

    const closeScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    // Handle Restore
    const handleRestore = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            if (data.id) {
                await updateDeliveryAttachmentService(data.id, payload);
                toast.custom((t) => <CustomToast text="Successfully Restored" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((prevState) => prevState - 1);
        closeScootixModal();
        loadAttachments();
    };

    return (
        <>
            <div>
                <div
                    className="font-size-16 text-white"
                    style={{ backgroundColor: '#FD4A4A', padding: 15, borderRadius: '5px 5px 0 0' }}
                >
                    Deleted Attachments
                </div>
                <TableCustom
                    tableData={{
                        ...deliveryAttachmentsInitialValues,
                        docs: mapDeletedAttachmentAndNotes(deliveryAttachments),
                        headers: attachmentArchiveTableHeaders,
                    }}
                    showPagination={false}
                    showAttachmentView
                    onAttachmentView={handleViewAttachment}
                    isFilter={false}
                    isSearch={false}
                    removeAction
                    showRestore
                    onRestore={handleOnRestore}
                />
                <AttachmentPreviewModal
                    toggleModal={toggleAttachmentModal}
                    image={attachment}
                    show={showPreview}
                    type="default"
                />
                <TextPreviewModal
                    toggleModal={toggleNoteAttachmentModal}
                    text={noteAttachment}
                    show={noteShowPreview}
                    type="default"
                />
                <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
                <BackdropLoader show={backdropLoading} opacity={0.7} />
            </div>
        </>
    );
}

AttachmentArchiveModel.propTypes = {
    deliveryAttachments: PropTypes.any,
    loadAttachments: PropTypes.func,
};

export default AttachmentArchiveModel;
