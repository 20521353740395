/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { MapContainer, Polyline, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet-gesture-handling';
import '../styles/routePlanEntryView.scss';

// #region constants
import { MAP_CENTER_COORDINATES_KL } from 'constants/constants';

// #endregion components
import CustomMarker from '../../../components/CustomMarker';
import RiderMarker from '../../../components/RiderMarker';

// #endregion constants
import { ATTRIBUTION, GOOGLE_MAPS_URL } from '../../../constants';
import { buildRiderCoordinates } from '../../../utils';

/**
 * This modal contains the  map grid of the route planning entry view
 * @param {RoutePlanningMapGridPropTypes} props
 */
function RoutePlanEntryViewMapGrid(props) {
    const {
        mapDeliveries,
        rider = [],
        formData,
        deliveryList,
        deliveryRoutePolyline,
        isRoutePlanStarted,
        isRoutePlanCompleted,
    } = props;

    /** this is the state representing the boundaries of the markers in the map */
    const [bound, setBound] = useState([]);

    /** once deliveries are changed set the center or the boundaries of the map dynamically */
    useEffect(() => {
        if (deliveryList?.length > 0) {
            // create a array of coordinates of the deliveries
            const pickupMarkerCoordinates = deliveryList.map((order) => [
                order.pickupLocationMeta.coordinates.lat,
                order.pickupLocationMeta.coordinates.lng,
            ]);
            const deliveryMarkerCoordinates = deliveryList.map((order) => [
                order.deliveryAddressMeta.coordinates.lat,
                order.deliveryAddressMeta.coordinates.lng,
            ]);
            const coordinateArray = pickupMarkerCoordinates.concat(deliveryMarkerCoordinates);
            // if user select the rider in rider assign modal, this suppose to all delivery locations and rider location in one window
            if (rider) {
                const { riderCurrentCoordinate } = buildRiderCoordinates(rider, isRoutePlanStarted);
                coordinateArray.push(riderCurrentCoordinate);
            }
            const bounds = L.latLngBounds(coordinateArray);
            setBound(bounds);
        }
    }, [deliveryList]);

    // this GestureHandling to enables the zoom facility with using the cntrl + scroll
    useEffect(() => {
        L.Map.addInitHook('addHandler', 'gestureHandling', L.GestureHandling);
    }, []);

    /** MapContainer configurations are immutable after initial loading
     * this MapView function act as a child component that will change the map
     */
    function MapView({ coordinates }) {
        const map = useMap();
        if (coordinates?._northEast && coordinates?._southWest) {
            map.fitBounds(coordinates);
        }

        return null;
    }

    return (
        <>
            <div className="route-plan__entry-view__map-grid">
                {/* Main map component */}
                <MapContainer
                    center={MAP_CENTER_COORDINATES_KL}
                    zoom={10}
                    className="route-plan__entry-view__map-grid__map-container"
                    scrollWheelZoom={false}
                    gestureHandling
                >
                    {mapDeliveries?.pickupMappedObjectArray.map((arrayElement) => (
                        <CustomMarker
                            key={arrayElement[0]._id}
                            mappedOrderArray={arrayElement}
                            isPickup
                            isActionDisable
                        />
                    ))}
                    {mapDeliveries?.deliveryMappedObjectArray.map((arrayElement) => (
                        <CustomMarker
                            key={arrayElement[0]._id}
                            mappedOrderArray={arrayElement}
                            isPickup={false}
                            isActionDisable
                        />
                    ))}
                    {!isRoutePlanCompleted && (
                        <RiderMarker
                            formData={formData}
                            rider={rider}
                            isActionDisable
                            isRoutePlanStarted={isRoutePlanStarted}
                        />
                    )}
                    <TileLayer attribution={ATTRIBUTION} url={GOOGLE_MAPS_URL} />
                    {/* polyline for optimized delivery route */}
                    <Polyline positions={deliveryRoutePolyline} weight={3} />
                    {/* child map component  */}
                    <MapView coordinates={bound} />
                </MapContainer>
            </div>
        </>
    );
}

RoutePlanEntryViewMapGrid.propTypes = {
    mapDeliveries: PropTypes.any,
    deliveryList: PropTypes.array,
    rider: PropTypes.object,
    formData: PropTypes.object,
    deliveryRoutePolyline: PropTypes.any,
    isRoutePlanStarted: PropTypes.any,
    isRoutePlanCompleted: PropTypes.bool,
};
export default RoutePlanEntryViewMapGrid;
