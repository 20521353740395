import { CREATE_STOCKS_ADJUSTMENT_ITEM } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Save Stocks Adjustment Items Batch Service
 * @param {object} data
 * @returns {Promise}
 */
export const saveStocksAdjustmentItemBatchService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_STOCKS_ADJUSTMENT_ITEM).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
