import { GET_ALL_BANKS } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get All Banks
 * @returns {Promise}
 */
export const getAllBanksService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_BANKS).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
