import { v4 as uuid } from 'uuid';

import { LOADED_DELIVERIES, LOADER_START, LOADER_STOP, UPDATE_SELECTED_DELIVERIES } from './actionTypes';

const INIT_STATE = {
    isLoading: false,
    loadedDeliveries: {
        id: uuid(),
        docs: [],
    },
    selectedDeliveries: {
        id: uuid(),
        docs: [],
    },
};

const BatchDeliveryAssignmentReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOADER_START:
            return {
                ...state,
                isLoading: true,
            };
        case LOADER_STOP:
            return {
                ...state,
                isLoading: false,
            };
        case LOADED_DELIVERIES:
            return {
                ...state,
                loadedDeliveries: action.payload,
            };
        case UPDATE_SELECTED_DELIVERIES:
            return {
                ...state,
                selectedDeliveries: action.payload,
            };
        default:
            return state;
    }
};

export default BatchDeliveryAssignmentReducer;
