/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spinner, FormGroup, DropdownItem, Alert } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { motion } from 'framer-motion';
import moment from 'moment';

// #region components | assets
import CheckCircleIcon from 'components/Icons/Check-Circle';

// #region other imports

// #region utils

function MerchantRequestFormContainer(props) {
    const {
        handleUpdateCreateSandboxAccount,
        handleRejectSandboxAccount,
        formLoading = {},
        formData,
        handleInputChange,
    } = props;

    return (
        <AvForm className="needs-validation default-form">
            <fieldset>
                <Row className="mt-4 px-3 py-3">
                    <Col md={2} className="">
                        <div
                            className="font-size-16"
                            style={{ color: '#807F7D', fontWeight: 'bolder', fontFamily: 'sans-serif' }}
                        >
                            Requested By
                        </div>
                        <div className="mt-1" style={{ color: '#807F7D' }}>
                            {formData.requestedByUserFullName || ''}
                        </div>
                        <div className="font-size-12">{formData.requestedByUserUsername || ''}</div>
                    </Col>
                    <Col md={2} className="">
                        <div
                            className="font-size-16"
                            style={{ color: '#807F7D', fontWeight: 'bolder', fontFamily: 'sans-serif' }}
                        >
                            Requested At
                        </div>
                        <div className="mt-1" style={{ color: '#807F7D' }}>
                            {formData.requestedAt
                                ? `${moment(formData.requestedAt).format('DD-MM-YYYY')} AT ${moment(
                                      formData.requestedAt
                                  ).format('hh:mm A')}`
                                : ''}
                        </div>
                    </Col>
                    <Col md={2} className="">
                        <div
                            className="font-size-16"
                            style={{ color: '#807F7D', fontWeight: 'bolder', fontFamily: 'sans-serif' }}
                        >
                            Developer Name
                        </div>
                        <div className="mt-1" style={{ color: '#807F7D' }}>
                            {formData.developerName || ''}
                        </div>
                    </Col>
                    <Col md={2} className="">
                        <div
                            className="font-size-16"
                            style={{ color: '#807F7D', fontWeight: 'bolder', fontFamily: 'sans-serif' }}
                        >
                            Developer Contact Number
                        </div>
                        <div className="mt-1" style={{ color: '#807F7D' }}>
                            {formData.developerContactNumber || ''}
                        </div>
                    </Col>
                    <Col md={2} className="">
                        <div
                            className="font-size-16"
                            style={{ color: '#807F7D', fontWeight: 'bolder', fontFamily: 'sans-serif' }}
                        >
                            Developer Email
                        </div>
                        <div className="mt-1" style={{ color: '#807F7D' }}>
                            {formData.developerEmail || ''}
                        </div>
                    </Col>
                    <Col md={2} className="">
                        <div
                            className="font-size-16"
                            style={{ color: '#807F7D', fontWeight: 'bolder', fontFamily: 'sans-serif' }}
                        >
                            Developer WhatsApp No
                        </div>
                        <div className="mt-1" style={{ color: '#807F7D' }}>
                            {formData.developerWhatsappNumber || ''}
                        </div>
                    </Col>
                </Row>
                <div className="d-flex justify-content-center">
                    <DropdownItem divider style={{ border: '1px solid #807F7D', width: '95%' }} />
                </div>
                <br />
                <div style={{ color: '#807F7D' }} className="mb-2 px-3">
                    Add a Custom Message
                </div>
                <Col>
                    <Alert
                        color="warning"
                        className="p-4"
                        style={{ border: '1px solid #F1F1F1', backgroundColor: '#FFFAED' }}
                    >
                        This will be send to the developer with the sandbox account credentials.
                    </Alert>
                </Col>
                <Row className="px-3">
                    <Col md={7}>
                        <FormGroup>
                            <AvField
                                name="customMessage"
                                type="textarea"
                                helpMessage="Maximum 2000 Words"
                                className="form-control"
                                value={formData.customMessage || ''}
                                onChange={handleInputChange}
                                rows={5}
                                maxLength={2000}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <br />
                <Row style={{ justifyContent: 'flex-start' }} className="px-3">
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.99 }}
                        className="scootix-form-btn shadow-lg align-content-center"
                        style={{
                            borderRadius: 10,
                            display: formData.sandboxAccountMerchantId ? 'none' : 'inline-block',
                        }}
                        disabled={formLoading.createSandboxAccount}
                        onClick={() => handleUpdateCreateSandboxAccount()}
                    >
                        {formLoading.createSandboxAccount ? (
                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                        ) : (
                            <>
                                <span className="h6">
                                    <CheckCircleIcon color="#FFF" />
                                    &nbsp;Create a Sand Box Account
                                </span>
                            </>
                        )}
                    </motion.button>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.99 }}
                        className="scootix-form-btn shadow-lg align-content-center ml-3"
                        style={{ borderRadius: 10, background: '#FD4A4A' }}
                        disabled={formLoading.rejectRequest}
                        onClick={() => handleRejectSandboxAccount()}
                    >
                        {formLoading.rejectRequest ? (
                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                        ) : (
                            <>
                                <span className="h6">
                                    <CheckCircleIcon color="#FFF" />
                                    &nbsp;Reject the Request
                                </span>
                            </>
                        )}
                    </motion.button>
                </Row>
            </fieldset>
        </AvForm>
    );
}

MerchantRequestFormContainer.propTypes = {
    handleUpdateCreateSandboxAccount: PropTypes.func,
    handleRejectSandboxAccount: PropTypes.func,
    formLoading: PropTypes.bool,
    formData: PropTypes.object,
    handleInputChange: PropTypes.func,
};

export default MerchantRequestFormContainer;
