/* eslint-disable react/no-unescaped-entities */
/* eslint-disable indent */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Alert,
    Label,
    Container,
    Spinner,
    Button,
    Input,
    ButtonGroup,
} from 'reactstrap';
import * as qs from 'query-string';
import toast from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';
import Avatar from 'react-avatar';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';

// #region assets | components
import PATHS from 'routes/paths';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {
    Select,
    ClientAlerts,
    BackdropLoader,
    TooltipDefault,
    AlertModal,
    ImageUploadModal,
    ChangePasswordModalNew,
    CustomToast,
    ScootixModal,
    PlacesAutocompleteModal,
    PlacesAutocompleteField,
    TableCustom,
} from 'components';
import AttachmentPreviewModal from 'components/Modals/Image-Preview';

// #region imports
import { THEME_COLOR, THEME_COLOR_1 } from 'theme';
import { centerElementInnerStyles, SubTitle } from 'styles';

// #region utils
import { getUser, getVisitingObject, hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import {
    apiErrorHandlerV2,
    buildFormDataForMultipleNew,
    cleanMask,
    cleanMobileNo,
    cleanObject,
    getDefaultValueForSelect,
    reactSelectCustomStyles,
    validateAttachments,
} from 'utils/helpers';

// #region services
import { getAllCountries } from 'services/countries.service';
import { getAllVehiclesService, getVehiclesByTypeService } from 'services/vehicle.service';
import {
    createAttachmentService,
    createMultiAttachmentService,
    getAttachmentByKey,
    updateAttachmentService,
} from 'services/attachment.service';
import { getAllBanksService } from 'services/bank.service';
import { getAllMerchantLocationByMerchantId } from 'services/merchant-location.service';
import { getRiderCommissionSettingsByRiderIdService } from 'services/rider-commission-setting.service';
import { getRiderModifiedTransactionsService } from 'services/transaction.service';
import {
    getMerchantsByClientIdService,
    getAllMerchantsService,
    getMerchantLocationsByVisitingMerchantIdService,
} from 'services/merchant.service';
import {
    deleteRiderService,
    getRiderByIdService,
    restoreRiderAccountService,
    toggleDisableRiderService,
    updateRiderProfileService,
} from 'services/rider.service';
import { getUserVerifiedPassword } from 'services/user.service';
import { TRANSACTION_REFERENCE } from 'constants/enums';

// #endregion imports
import { approveRiderService, createRiderService, sendVerificationCode, updateRiderService } from './services';
import { buildLocationData, validateForm } from './utils';
import { riderCommissionSettingEmbeddedHeaders, transactionTableHeaderForRider } from '../../constants';
import RiderAlert from '../../components/RiderAlert';
import RestoreRiderModal from '../../components/RestoreRiderModal';
import DeleteRiderModel from '../../components/DeleteRiderModel';
import TransactionRiderModel from '../../components/TransactionRiderModel';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Rider / Driver Management', link: '/rider-profile-management' },
];

// This is the initial format for Attachments same in default state in DB
const initialAttachmentState = {
    nicFrontImageUrl: { data: null, lastUpdated: null, isDbSaved: false },
    nicBackImageUrl: { data: null, lastUpdated: null, isDbSaved: false },
    driversLicenseSideOneImageUrl: { data: null, lastUpdated: null, isDbSaved: false },
    driversLicenseSideTwoImageUrl: { data: null, lastUpdated: null, isDbSaved: false },
    roadTaxLicenseImageUrl: { data: null, lastUpdated: null, isDbSaved: false },
    vehicleImageUrl: { data: null, lastUpdated: null, isDbSaved: false },
};

function RiderRegistration(props) {
    const history = useHistory();

    const { visitingClient, visitingMerchant } = getVisitingObject();

    let initialFormValues = { country: visitingClient?.country || 'Malaysia', vehicleName: 'Other' };

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    if (visitingClient) {
        initialFormValues = {
            ...initialFormValues,
            clientId: visitingClient._id,
        };
    }

    // prev comp
    const [prevCompState, setPrevCompState] = useState({});

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);
    const [riderCommissionSettingLoading, setRiderCommissionSettingLoading] = useState(0);
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [autoCompleteModal, setAutoCompleteModal] = useState({ type: null, show: false });

    const [riderCommissionSettingListData, setRiderCommissionSettingListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: riderCommissionSettingEmbeddedHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    const [alertMessage, setAlertMessage] = useState(null);

    const [showDeleteModal, setShowDeleteModal] = useState({ state: false, data: null });
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [imageUploadModalState, setImageUploadModalState] = useState({
        show: false,
        type: null,
        data: null,
        header: null,
        title: null,
        subTitle: null,
    });
    const [attachmentPreviewModalState, setAttachmentPreviewModalState] = useState({
        show: false,
        multiple: false,
        imageUrl: null,
    });

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);
    const [merchants, setMerchants] = useState([]);

    const [showSaveBtn, setShowSaveBtn] = useState(false);

    const [vehicles, setVehicles] = useState([]);
    const [banks, setBanks] = useState([]);
    const [countries, setCountries] = useState([]);

    // Transaction data modal
    const [modalOptions, setModalOptions] = useState({ modal: null, data: {} });

    // transaction rider details
    const [allRiderTransactions, setAllRiderTransactions] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: transactionTableHeaderForRider,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    // attachments
    const [initialAttachmentDb, setInitialAttachmentDb] = useState(initialAttachmentState); // If Editing Mode This will be replaced with Value from DB
    const [attachmentsObj, setAttachmentsObj] = useState(initialAttachmentState); // This state handles attachments

    // images ( all Images with presigned URL are stored here )
    const [images, setImages] = useState({
        profilePictureUrl: null,
        nicBackImageUrl: null,
        nicFrontImageUrl: null,
    });

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    const [allMerchantLocations, setAllMerchantLocations] = useState([]);

    // Restore and Deletion
    const [showRestoreRiderModal, setShowRestoreRiderModal] = useState(false);
    const [showDeleteRiderModal, setShowDeleteRiderModal] = useState(false);

    useEffect(() => {
        if (editingMode) {
            if (hasAccess(PERMISSIONS.RIDER_ACCOUNT, ['Edit'])) {
                setShowSaveBtn(true);
            }
        } else if (hasAccess(PERMISSIONS.RIDER_ACCOUNT, ['Add'])) {
            setShowSaveBtn(true);
        }
    }, [editingMode]);

    useEffect(() => {
        if (formData.attachmentObj) {
            const cleanedDbObject = cleanObject(formData.attachmentObj);
            setAttachmentsObj({ ...attachmentsObj, ...cleanedDbObject });
        }
    }, [formData.attachmentObj]);

    useEffect(() => {
        if (formData.profilePictureUrl) {
            loadProfileImage(formData.profilePictureUrl);
        }
    }, [formData.profilePictureUrl]);

    useEffect(() => {
        loadAllVehicles();
        loadAllBanks();
    }, []);

    useEffect(() => {
        getAllCountriesService();
    }, []);

    useEffect(() => {
        if (!props.location) {
            history.push(PATHS.USER_PROFILE_MANAGEMENT.DEFAULT);
            return;
        }
        const formType = qs.parse(props.location.search);
        if (formType && formType.type && formType.type === 'new') {
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Rider', link: '#' }]);
        }
        if (formType && formType.type && formType.type === 'edit') {
            handleUpdateComponentEditMode();
        }
        if (formType && formType.type && formType.type === 'view') {
            handleUpdateComponentViewMode();
        }
        const { prevCompState = null } = props.location.state || {};
        if (prevCompState) {
            setPrevCompState(prevCompState);
        }
    }, []);

    useEffect(() => {
        const timer1 = setTimeout(() => setAlertMessage(null), 5000);
        return () => {
            clearTimeout(timer1);
        };
    }, [alertMessage]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    useEffect(() => {
        formData._id && loadRiderModifiedTransactions();
        if (formData._id) {
            getRiderCommissionSettings(
                true,
                formData._id,
                riderCommissionSettingListData.all,
                riderCommissionSettingListData.page,
                riderCommissionSettingListData.limit,
                riderCommissionSettingListData.filter,
                riderCommissionSettingListData.sort,
                riderCommissionSettingListData.order
            );
        }
    }, [formData._id]);

    useEffect(() => {
        if (visitingMerchant && visitingMerchant._id) {
            const visitingMerchantId = visitingMerchant._id;
            getMerchantLocationsByVisitingMerchantId(visitingMerchantId);
        }
    }, []);

    useEffect(() => {
        if (formData.merchantId) {
            loadAllMerchantLocationsByMerchantId(formData.merchantId);
        }
    }, [formData.merchantId]);

    // load all vehicles
    const loadAllVehicles = async () => {
        try {
            const { data } = await getAllVehiclesService();
            if (data && data.docs) {
                setVehicles(data.docs);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // Merchant Location Handlers of visiting merchant
    const getMerchantLocationsByVisitingMerchantId = async (visitingMerchantId) => {
        await getMerchantLocationsByVisitingMerchantIdService(visitingMerchantId).then((res) => {
            const { data } = res;
            if (data.docs) {
                setAllMerchantLocations(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.merchantLocationName || ''} - ${x.location || ''}`,
                        key: x._id,
                    }))
                );
            }
        });
    };

    // Merchant locations for selected form data
    const loadAllMerchantLocationsByMerchantId = async (id) => {
        try {
            const { data } = await getAllMerchantLocationByMerchantId(id);
            if (data && data.docs) {
                setAllMerchantLocations(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.merchantLocationName || ''} - ${x.location || ''}`,
                        key: x._id,
                    }))
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    // const loadAllMerchantLocations = async (id) => {
    //     try {
    //         const { data } = await getAllMerchantLocationByMerchantId(id);
    //         if (data && data.docs) {
    //             setAllMerchantLocations(
    //                 data.docs.map((x) => ({
    //                     ...x,
    //                     label: `${x.merchantLocationName || ''} - ${x.location || ''}`,
    //                     key: x._id,
    //                 }))
    //             );
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const getAllCountriesService = async () => {
        const data = await getAllCountries();
        setCountries(data);
    };

    const loadAllBanks = async () => {
        try {
            const { data } = await getAllBanksService();
            if (data && data.docs) {
                setBanks(data.docs);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadAllVehiclesByType = async (type, isLoading = false) => {
        if (isLoading) {
            setBackdropLoading((prevState) => prevState + 1);
        }
        try {
            const { data } = await getVehiclesByTypeService(type);
            if (data && data.docs) {
                setVehicles(data.docs);
            }
        } catch (e) {
            console.log(e);
        }
        if (isLoading) {
            setBackdropLoading((prevState) => prevState - 1);
        }
    };

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    const loadRiderModifiedTransactions = () => {
        if (formData._id) {
            getRiderModifiedTransactionsService(formData._id).then((res) => {
                if (res) {
                    const { data } = res;
                    data.docs &&
                        setAllRiderTransactions({
                            ...allRiderTransactions,
                            ...data,
                            docs: data.docs.map((doc) => ({
                                ...doc,
                                riderName: doc?.rider?.firstName,
                                transactionMadeByUserUsername: doc?.createdByUser?.username,
                            })),
                        });
                }
            });
        }
    };

    const loadRiderById = async (id) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getRiderByIdService(id);
            setFormData((prevState) => ({ ...formData, ...prevState, ...data }));
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    // Update Address Bar URL
    // PATH | QUERY | STATE
    const handleUpdateUrl = (prevState, riderData) => {
        // Handle Remove PrevComponent
        try {
            history.replace({
                pathname: `${PATHS.RIDER_PROFILE_MANAGEMENT.FORM_VIEW}`,
                search: `?type=edit&id=${riderData._id}`,
                state: {
                    riderParentData: riderData,
                    prevCompState: { ...prevState, refreshUser: true },
                },
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpdateComponentEditMode = () => {
        try {
            !props.location.state && history.push(PATHS.RIDER_PROFILE_MANAGEMENT.LIST_VIEW);
            const { riderParentData } = props.location.state;
            setFormData({ ...formData, ...riderParentData });
            setInitialAttachmentDb({ ...initialAttachmentDb, ...riderParentData.attachmentObj });

            const { prevCompState = null } = props.location.state || {};
            // Update URL Based on Condition
            handleUpdateUrl(prevCompState, riderParentData);
            if (prevCompState && prevCompState.refreshUser) {
                // This code is executed only during page reload
                loadRiderById(riderParentData._id);
            }

            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Rider', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpdateComponentViewMode = () => {
        try {
            !props.location.state && history.push(PATHS.RIDER_PROFILE_MANAGEMENT.LIST_VIEW);
            const { riderParentData } = props.location.state;
            setFormData({ ...formData, ...riderParentData });
            setInitialAttachmentDb({ ...initialAttachmentDb, ...riderParentData.attachmentObj });
            setIsViewMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'View Rider', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    const getRiderCommissionSettings = (
        showLoading = true,
        id,
        all,
        page,
        limit,
        filter,
        sort,
        order,
        searchByParam
    ) => {
        if (showLoading) {
            setRiderCommissionSettingLoading((prevState) => prevState + 1);
        }
        getRiderCommissionSettingsByRiderIdService(id, all, page, limit, filter, sort, order, searchByParam, 'riderId')
            .then((res) => {
                const { data } = res;
                if (data && data.docs) {
                    setRiderCommissionSettingListData({
                        ...riderCommissionSettingListData,
                        ...data,
                        docs: data.docs.map((doc) => ({
                            ...doc,
                            riderName: doc.riderId.fullName,
                        })),
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setRiderCommissionSettingLoading((prevState) => prevState - 1);
                }
            });
    };

    const handleEditRiderCommissionSetting = (e, row) => {
        history.push({
            pathname: `${PATHS.PAYMENTS.RIDER_COMMISSIONS_FORM}`,
            search: `?type=edit`,
            state: { riderCommissionSettingParentData: row },
        });
    };

    const handleNewRiderCommissionSetting = () => {
        history.push({
            pathname: `${PATHS.PAYMENTS.RIDER_COMMISSIONS_FORM}`,
            search: `?type=new`,
            state: { riderParentData: formData },
        });
    };

    const handleApproveRider = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await approveRiderService(formData._id);
            const { data } = await getRiderByIdService(formData._id);
            if (data) {
                setFormData({ ...formData, ...data });
            }
            toast.custom((t) => <CustomToast text="Successfully approved Rider" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            console.log(e);
        }
        loadRiderModifiedTransactions();
        setScootixModalState({
            ...scootixModalState,
            show: false,
        });
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleDeleteRider = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteRiderService(formData._id);
            toast.custom((t) => <CustomToast text="Successfully deleted Rider" t={t} type="success" />, {
                position: 'top-right',
            });
            setBackdropLoading((prevState) => prevState - 1);
            history.push({
                pathname: PATHS.RIDER_PROFILE_MANAGEMENT.LIST_VIEW,
                search: `?tab=${prevCompState.activeTab || '1'}`,
            });
            return;
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors && e.data.errors.riderMessage) {
                toast.custom((t) => <CustomToast text={e.data.errors.riderMessage} t={t} type="warning" />, {
                    position: 'top-right',
                });
            } else {
                toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="warning" />, {
                    position: 'top-right',
                });
            }
            setBackdropLoading((prevState) => prevState - 1);
        }
        setShowDeleteModal({ state: false, data: null });
    };

    const handleResendVerificationCode = async () => {
        await sendVerificationCode(formData._id, 'EMAIL', formData);
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        // event.preventDefault();
        event.persist();
        if (!editingMode && formData.password !== formData.confirmPassword) {
            toast.custom((t) => <CustomToast text="Passwords do not match" t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        const isAttachmentsValid = validateAttachments(Object.values(attachmentsObj).flat());

        if (!isAttachmentsValid) {
            toast.custom((t) => <CustomToast text="Upload Required Images" t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);

        formData.mobileNo = cleanMobileNo(formData.mobileNo);
        formData.emergencyContactNumber = cleanMobileNo(formData.emergencyContactNumber);
        formData.nic = cleanMask(formData.nic);

        try {
            let updatedPayloadData;

            const payload = {
                ...formData,
                fullName: `${formData.firstName} ${formData.lastName}`,
            };
            if (editingMode) {
                // Update Rider  ( Attachment Handling Occurs Within Service )
                const { data } = await updateRiderService(payload, attachmentsObj, initialAttachmentDb);
                if (data) {
                    setFormData({ ...formData, ...data });
                    setInitialAttachmentDb(data.attachmentObj);
                    updatedPayloadData = { ...formData, ...data };
                }
                try {
                    if (updatedPayloadData) {
                        history.replace({
                            pathname: `${PATHS.RIDER_PROFILE_MANAGEMENT.FORM_VIEW}`,
                            search: `?type=edit`,
                            state: {
                                riderParentData: updatedPayloadData,
                                prevCompState,
                            },
                        });
                    }
                } catch (e) {
                    console.log(e);
                }
            } else {
                const initialCreateData = await createRiderService({
                    ...payload,
                });

                // During Creation Build FormData
                const allAttachmentFormData = buildFormDataForMultipleNew(Object.values(attachmentsObj).flat());
                // Create Multiple Attachments
                const responseAttachment = await createMultiAttachmentService(allAttachmentFormData);

                // Attach attachment keys and update
                const { data } = await updateRiderService({
                    ...initialCreateData.data,
                    attachmentObj: {
                        nicFrontImageUrl: {
                            data: responseAttachment.data[0].key || null,
                            isDbSaved: true,
                            lastUpdated: new Date(),
                        },
                        nicBackImageUrl: {
                            data: responseAttachment.data[1].key || null,
                            isDbSaved: true,
                            lastUpdated: new Date(),
                        },
                        driversLicenseSideOneImageUrl: {
                            data: responseAttachment.data[2].key || null,
                            lastUpdated: new Date(),
                            isDbSaved: true,
                        },
                        driversLicenseSideTwoImageUrl: {
                            data: responseAttachment.data[3].key || null,
                            lastUpdated: new Date(),
                            isDbSaved: true,
                        },
                        roadTaxLicenseImageUrl: {
                            data: responseAttachment.data[4].key || null,
                            lastUpdated: new Date(),
                            isDbSaved: true,
                        },
                        vehicleImageUrl: {
                            data: responseAttachment.data[5].key || null,
                            lastUpdated: new Date(),
                            isDbSaved: true,
                        },
                    },
                });

                if (data) {
                    setFormData({ ...formData, ...data });
                    setInitialAttachmentDb(data.attachmentObj);
                    updatedPayloadData = { ...formData, ...data };
                }
                setEditingMode(true);
                try {
                    if (updatedPayloadData) {
                        history.replace({
                            pathname: `${PATHS.RIDER_PROFILE_MANAGEMENT.FORM_VIEW}`,
                            search: `?type=edit`,
                            state: {
                                riderParentData: updatedPayloadData,
                                prevCompState,
                            },
                        });
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            if (!editingMode) {
                setAlertMessage('Check Email Inbox to verify rider');
            }
            setEditingMode(true);
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} Rider`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setIsFormSaved(true);
            loadRiderModifiedTransactions();
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors) {
                const { message } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            } else {
                toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="warning" />, {
                    position: 'top-right',
                });
            }
        }
        setFormLoading(false);
        setSubmitted(false);
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;

        if (id === 'nic') {
            setFormData({
                ...formData,
                [id]: value,
                socsoNo: value.replace(/-/g, ''),
            });
            return;
        }

        if (id === 'vehicleNo') {
            setFormData({
                ...formData,
                [id]: value.toUpperCase(),
            });
            return;
        }

        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const toggleRiderDisable = async (isDisabled) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            if ((formData.currentTasks || []).length > 0 && isDisabled === false) {
                toast.custom(
                    (t) => (
                        <CustomToast
                            text="Unable to Inactivate Rider, their are pending deliveries for this Rider "
                            t={t}
                            type="warning"
                        />
                    ),
                    {
                        position: 'top-right',
                    }
                );
                setBackdropLoading((prevState) => prevState - 1);
                return;
            }

            const { data } = await toggleDisableRiderService(formData._id);
            if (data) {
                setFormData({ ...formData, isDisabled: !isDisabled });
            }

            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Rider Successfully ${isDisabled ? 'Enabled' : 'Disabled'} `}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
        } catch (e) {
            console.log(e);
        }
        loadRiderModifiedTransactions();
        setBackdropLoading((prevState) => prevState - 1);
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);

        if (event) {
            if (id === 'merchantId') {
                const relevantMerchant = merchants.find((x) => x._id === event.value);
                if (relevantMerchant) {
                    setFormData({
                        ...formData,
                        merchantName: relevantMerchant.name || '',
                        [id]: event ? event.value : null,
                    });
                }
                loadAllMerchantLocationsByMerchantId(event.value);
                return;
            }

            if (id === 'merchantLocationId') {
                const relevantMerchantLocation = allMerchantLocations.find((x) => x._id === event.value);
                if (relevantMerchantLocation) {
                    setFormData({
                        ...formData,
                        merchantLocation: relevantMerchantLocation.location,
                        // merchantLocationMeta: relevantMerchantLocation.locationMeta,
                        merchantLocationReference: relevantMerchantLocation.referenceNumber,
                        [id]: event ? event.value : null,
                    });
                }
                return;
            }

            if (id === 'vehicleType') {
                setFormData({
                    ...formData,
                    vehicleName: null,
                    otherVehicle: null,
                    vehicleNo: '',
                    [id]: event ? event.value : null,
                });
                loadAllVehiclesByType(event.value, true);
                return;
            }

            setFormData({
                ...formData,
                [id]: event ? event.value : null,
            });
            return;
        }
        if (id === 'merchantId') {
            setFormData({
                ...formData,
                merchantName: null,
                [id]: null,
                merchantLocationId: null,
                merchantLocationReference: null,
                merchantLocation: null,
            });
            return;
        }
        if (id === 'merchantLocationId') {
            setFormData({
                ...formData,
                [id]: null,
                merchantLocationReference: null,
                merchantLocation: null,
            });
            return;
        }

        setFormData({
            [id]: null,
        });
    };

    const handleCheckChange = (event) => {
        setIsFormSaved(false);

        setFormData({
            ...formData,
            [event.target.id]: event.target.checked,
        });
    };

    const toggleImageUploadModal = () => {
        setImageUploadModalState({
            show: false,
            data: null,
            type: null,
        });
    };

    /**
     * Load Profile Image
     * @param {string} profilePictureUrl
     */
    const loadProfileImage = (profilePictureUrl) => {
        getAttachmentByKey(profilePictureUrl)
            .then((res) => {
                if (res) {
                    setImages((prevState) => ({ ...prevState, profilePictureUrl: res }));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const updateProfilePicture = async (files) => {
        const image = files[0];
        const imageData = new FormData();
        imageData.append('file', image);
        setImageUploadModalState((prevState) => ({
            ...prevState,
            loading: true,
        }));
        let isSuccess = false;
        try {
            if (formData.profilePictureUrl) {
                const { data } = await updateAttachmentService(formData.profilePictureUrl, imageData);
                if (data && data.key) {
                    const response = await updateRiderProfileService(formData._id, { profilePictureUrl: data.key });
                    if (response.data) {
                        setFormData({ ...formData, ...response.data });
                        loadProfileImage(response.data.profilePictureUrl);
                        isSuccess = true;
                    }
                }
            } else {
                const { data } = await createAttachmentService(imageData);
                if (data && data.key) {
                    const response = await updateRiderProfileService(formData._id, { profilePictureUrl: data.key });
                    if (response.data) {
                        setFormData({ ...formData, ...response.data });
                        loadProfileImage(response.data.profilePictureUrl);
                        isSuccess = true;
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }

        if (isSuccess) {
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${formData.profilePictureUrl ? 'updated' : 'saved'} profile picture `}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
        }
        setImageUploadModalState((prevState) => ({
            ...prevState,
            loading: false,
            show: false,
        }));
    };

    const getMerchantLocationSelect = (_id) => {
        const relevantMerchantLocation = allMerchantLocations.find((x) => x._id === _id);
        if (relevantMerchantLocation) {
            return `${relevantMerchantLocation.merchantLocationName || ''} - ${
                relevantMerchantLocation.location || ''
            }`;
        }
        return null;
    };

    const showImageUpload = (header, type, title) => {
        setImageUploadModalState({
            show: true,
            data: null,
            header,
            type,
            title,
            onUpload: async (files) => {
                if (type === 'profilePictureUrl') {
                    await updateProfilePicture(files);
                    setImageUploadModalState((prevState) => ({
                        ...prevState,
                        loading: false,
                        show: false,
                    }));
                } else {
                    setAttachmentsObj({
                        ...attachmentsObj,
                        [type]: {
                            data: files[0],
                            lastUpdated: new Date(),
                            isDbSaved: false,
                            name: files[0].name || '',
                        },
                    });
                    setImageUploadModalState((prevState) => ({
                        ...prevState,
                        loading: false,
                        show: false,
                    }));
                }
            },
        });
    };

    const handleOnDelete = (e) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            message: 'Are you sure you want to proceed?',
            onConfirm: () => handleDeleteRider(),
        });
    };

    const toggleResetPasswordModal = () => {
        setShowResetPasswordModal(!showResetPasswordModal);
    };

    const handleOnApprove = (e) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            message: 'Are you sure you want to proceed?',
            onConfirm: () => handleApproveRider(),
        });
    };

    const returnLocationData = (id, idMeta, data) => {
        const builtData = buildLocationData(id, idMeta, data);
        setFormData({ ...formData, ...builtData });
    };

    // when click restore the Account button popup restore modal
    const toggleRestoreModal = () => {
        setShowRestoreRiderModal(!showRestoreRiderModal);
    };

    // when click delete button popup delete modal
    const toggleDeleteModal = () => {
        setShowDeleteRiderModal(!showDeleteRiderModal);
    };

    // Handle Restore Rider account for requested for deletion users
    const handleRestoreRiderAccount = async (formData) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            // get Logged in user id
            const userData = getUser();
            // verify logged in user password
            await getUserVerifiedPassword(formData, userData._id);
            // restore rider data
            const { data } = await restoreRiderAccountService(formData);
            if (data) {
                setFormData({ ...formData, ...data });
                // close the restore modal
                setShowRestoreRiderModal(!showRestoreRiderModal);
                toast.custom((t) => <CustomToast text="Successfully Restored Account" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    // Handle Delete User for requested for deletion users
    const handleDeleteRiderAccount = async (formData) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            // get Logged in user id
            const userData = getUser();
            // verify logged in user password
            await getUserVerifiedPassword(formData, userData._id);
            // Delete rider data
            const { data } = await deleteRiderService(formData._id);
            if (data) {
                setFormData({ ...formData, ...data });
                // close the restore modal
                setShowDeleteRiderModal(!showDeleteRiderModal);
                toast.custom((t) => <CustomToast text="Successfully Deleted Account" t={t} type="success" />, {
                    position: 'top-right',
                });
                setTimeout(() => {
                    history.push({
                        pathname: PATHS.RIDER_PROFILE_MANAGEMENT.LIST_VIEW,
                        search: `?tab=${prevCompState.activeTab || '1'}`,
                    });
                }, 500);
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    // toggle the transaction model
    const handleTransactionModalToggle = (modalName) => {
        if (modalName) {
            setModalOptions((prev) => ({ ...prev, modal: modalName }));
        } else {
            setModalOptions((prev) => ({ ...prev, modal: null }));
        }
    };

    // show transaction data modal
    const displayModal = (data) => {
        const {
            RIDER_SIGN_UP,
            RIDER_REGISTRATION,
            RIDER_VERIFIED,
            RIDER_APPROVED,
            NEW_RIDER_COMMISSION_SETTINGS_ADDED,
            RIDER_ACCOUNT_RESTORED_FROM_DELETED_RIDERS,
            RIDER_ACCOUNT_RESTORED,
            RIDER_COMMISSION_SETTINGS_UPDATED,
            RIDER_ACCOUNT_DELETED,
            RIDER_ACCOUNT_PERMANENTLY_DELETED,
            RIDER_PASSWORD_RESET_ADMIN,
            RIDER_PROFILE_UPDATED_CONSOLE,
            RIDER_PROFILE_ENABLED,
            RIDER_PROFILE_DISABLED,
        } = TRANSACTION_REFERENCE.RIDER_MANAGEMENT;

        // show transaction data modal
        if (data.reference === RIDER_SIGN_UP || RIDER_VERIFIED) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    transactionReference: data.reference,
                    name: `Rider name: ${data?.meta?.fullName}`,
                    profileId: `Rider Profile ID: ${data?.createdByUser?.referenceNumber}`,
                },
            });
        }
        if (data.reference === RIDER_REGISTRATION) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    transactionReference: data.reference,
                    name: `Created By: ${data?.createdByUser?.fullName}`,
                },
            });
        }
        if (data.reference === RIDER_APPROVED) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    transactionReference: data.reference,
                    name: `User’s name (Approved By): ${data?.createdByUser?.fullName}`,
                },
            });
        }
        if (
            data.reference === RIDER_ACCOUNT_RESTORED_FROM_DELETED_RIDERS ||
            RIDER_ACCOUNT_RESTORED ||
            RIDER_ACCOUNT_DELETED ||
            RIDER_ACCOUNT_PERMANENTLY_DELETED ||
            RIDER_PASSWORD_RESET_ADMIN ||
            RIDER_PROFILE_ENABLED ||
            RIDER_PROFILE_DISABLED
        ) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    transactionReference: data.reference,
                    name: `User’s name (Updater): ${data?.createdByUser?.fullName}`,
                },
            });
        }
        if (data.reference === NEW_RIDER_COMMISSION_SETTINGS_ADDED) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    transactionReference: data.reference,
                    name: `User’s name (Updater): ${data?.createdByUser?.fullName}`,
                    newSettings: {
                        deliveryType: data?.riderCommissionSettingsData?.deliveryType,
                        currency: data?.riderCommissionSettingsData?.currency,
                        riderCommissionMethod: data?.riderCommissionSettingsData?.riderCommissionMethod,
                        fixedRiderCommissionRate: data?.riderCommissionSettingsData?.fixedRiderCommissionRate,
                        riderCommissionPercentage: data?.riderCommissionSettingsData?.riderCommissionPercentage,
                        baseMileage: data?.riderCommissionSettingsData?.baseMileage,
                        baseMileageRate: data?.riderCommissionSettingsData?.baseMileageRate,
                        additionalMileageSlab1: data?.riderCommissionSettingsData?.additionalMileageSlab1,
                        additionalMileageSlab1Limit: data?.riderCommissionSettingsData?.additionalMileageSlab1Limit,
                        ratePerAdditionalMileageSlab1: data?.riderCommissionSettingsData?.ratePerAdditionalMileageSlab1,
                        additionalMileageSlab2: data?.riderCommissionSettingsData?.additionalMileageSlab2,
                        ratePerAdditionalMileage2: data?.riderCommissionSettingsData?.ratePerAdditionalMileage2,
                    },
                },
            });
        }
        if (data.reference === RIDER_COMMISSION_SETTINGS_UPDATED) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    transactionReference: data.reference,
                    name: `User’s name (Updater): ${data?.createdByUser?.fullName}`,
                    newSettings: {
                        deliveryType: data?.riderCommissionSettingsData?.deliveryType,
                        currency: data?.riderCommissionSettingsData?.currency,
                        riderCommissionMethod: data?.riderCommissionSettingsData?.riderCommissionMethod,
                        fixedRiderCommissionRate: data?.riderCommissionSettingsData?.fixedRiderCommissionRate,
                        riderCommissionPercentage: data?.riderCommissionSettingsData?.riderCommissionPercentage,
                        baseMileage: data?.riderCommissionSettingsData?.baseMileage,
                        baseMileageRate: data?.riderCommissionSettingsData?.baseMileageRate,
                        additionalMileageSlab1: data?.riderCommissionSettingsData?.additionalMileageSlab1,
                        additionalMileageSlab1Limit: data?.riderCommissionSettingsData?.additionalMileageSlab1Limit,
                        ratePerAdditionalMileageSlab1: data?.riderCommissionSettingsData?.ratePerAdditionalMileageSlab1,
                        additionalMileageSlab2: data?.riderCommissionSettingsData?.additionalMileageSlab2,
                        ratePerAdditionalMileage2: data?.riderCommissionSettingsData?.ratePerAdditionalMileage2,
                    },
                    previousSettings: {
                        deliveryType: data?.meta?.previousData?.deliveryType,
                        currency: data?.meta?.previousData?.currency,
                        riderCommissionMethod: data?.meta?.previousData?.riderCommissionMethod,
                        fixedRiderCommissionRate: data?.meta?.previousData?.fixedRiderCommissionRate,
                        riderCommissionPercentage: data?.meta?.previousData?.riderCommissionPercentage,
                        baseMileage: data?.previousData?.meta?.baseMileage,
                        baseMileageRate: data?.meta?.previousData?.baseMileageRate,
                        additionalMileageSlab1: data?.meta?.previousData?.additionalMileageSlab1,
                        additionalMileageSlab1Limit: data?.meta?.previousData?.additionalMileageSlab1Limit,
                        ratePerAdditionalMileageSlab1: data?.meta?.previousData?.ratePerAdditionalMileageSlab1,
                        additionalMileageSlab2: data?.meta?.previousData?.additionalMileageSlab2,
                        ratePerAdditionalMileage2: data?.meta?.previousData?.ratePerAdditionalMileage2,
                    },
                },
            });
        }
        if (data.reference === RIDER_PROFILE_UPDATED_CONSOLE) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    transactionReference: data.reference,
                    name: `User’s name (Updater): ${data?.createdByUser?.fullName}`,
                    newUserDetails: {
                        firstName: data?.rider?.firstName,
                        lastName: data?.rider?.lastName,
                        gender: data?.rider?.gender,
                        address: data?.rider?.address,
                        department: data?.rider?.department,
                        country: data?.rider?.country,
                        email: data?.rider?.email,
                        mobileNo: data?.rider?.mobileNo,
                        officeNo: data?.rider?.officeNo,
                        city: data?.rider?.city,
                        postalCode: data?.rider?.postalCode,
                        socsoNo: data?.rider?.socsoNo,
                        vehicleNo: data?.rider?.vehicleNo,
                        vehicleName: data?.rider?.vehicleName,
                        vehicleType: data?.rider?.vehicleType,
                        bankAccountNo: data?.rider?.bankAccountNo,
                        bank: data?.rider?.bank,
                        emergencyContactNumber: data?.rider?.emergencyContactNumber,
                    },
                    previousData: {
                        firstName: data?.meta?.previousData?.firstName,
                        lastName: data?.meta?.previousData?.lastName,
                        gender: data?.meta?.previousData?.gender,
                        address: data?.meta?.previousData?.address,
                        department: data?.meta?.previousData?.department,
                        country: data?.meta?.previousData?.country,
                        email: data?.meta?.previousData?.email,
                        mobileNo: data?.meta?.previousData?.mobileNo,
                        officeNo: data?.meta?.previousData?.officeNo,
                        city: data?.meta?.previousData?.city,
                        postalCode: data?.meta?.previousData?.postalCode,
                        socsoNo: data?.meta?.previousData?.socsoNo,
                        vehicleNo: data?.meta?.previousData?.vehicleNo,
                        vehicleName: data?.meta?.previousData?.vehicleName,
                        vehicleType: data?.meta?.previousData?.vehicleType,
                        bankAccountNo: data?.meta?.previousData?.bankAccountNo,
                        bank: data?.meta?.previousData?.bank,
                        emergencyContactNumber: data?.meta?.previousData?.emergencyContactNumber,
                    },
                },
            });
        }
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs breadcrumbItems={breadcrumbItems} />
                    <Row style={{ justifyContent: 'space-between' }}>
                        {!editingMode && (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                onClick={() => {
                                    history.push({
                                        pathname: PATHS.RIDER_PROFILE_MANAGEMENT.LIST_VIEW,
                                        search: `?tab=${prevCompState.activeTab || '1'}`,
                                    });
                                }}
                            >
                                <i className="far fa-times-circle mr-2 fa-lg" />
                                Cancel
                            </motion.div>
                        )}
                        <div>
                            <Col xs={12} md={12}>
                                {formData._id && (
                                    <>
                                        <div>
                                            <Row style={{ marginLeft: 10, justifyContent: 'center' }}>
                                                <Avatar
                                                    className="image-upload-avatar"
                                                    onClick={() =>
                                                        showImageUpload(
                                                            'Upload a Profile Picture',
                                                            'profilePictureUrl',
                                                            'Profile Picture',
                                                            'image/jpeg, image/png'
                                                        )
                                                    }
                                                    name={formData.fullName}
                                                    size="70"
                                                    round="50px"
                                                    src={images.profilePictureUrl}
                                                />

                                                <Col>
                                                    <h5 style={{ fontWeight: 'bold', marginBottom: 3 }}>
                                                        {formData.fullName}
                                                    </h5>
                                                    <span>{formData.email}</span>
                                                    <p>
                                                        {`Created at ${moment(formData.createdAt).format(
                                                            'DD-MM-YYYY hh:mm A'
                                                        )}`}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                )}
                            </Col>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'col' }}>
                            {editingMode && hasAccess(PERMISSIONS.RIDER_ACCOUNT, ['Edit']) && (
                                <>
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-btn-radius p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                        onClick={() => toggleResetPasswordModal()}
                                    >
                                        <i className="fas fa-key mr-2 fa-lg" />
                                        Change Password
                                    </motion.div>
                                </>
                            )}
                            {editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => {
                                        history.push({
                                            pathname: PATHS.RIDER_PROFILE_MANAGEMENT.LIST_VIEW,
                                            search: `?tab=${prevCompState.activeTab || '1'}`,
                                        });
                                    }}
                                >
                                    <i className="fas fa-arrow-left mr-2 fa-lg" />
                                    Back to Riders
                                </motion.div>
                            )}
                        </div>
                    </Row>

                    {/* Alert component */}
                    <Col className="mt-2 mb-4">
                        <>
                            {formData.isRequestAccountDeletion && (
                                <RiderAlert
                                    toggleRestoreModal={toggleRestoreModal}
                                    toggleDeleteModal={toggleDeleteModal}
                                    requestedAt={formData.requestAccountDeletionAt}
                                    requestedVia={formData.requestAccountDeletionVia}
                                />
                            )}
                        </>
                    </Col>

                    {editingMode && formData.address && !formData.addressMeta && (
                        <>
                            <br />
                            <Alert color="warning">
                                ( Optional ) Due to Rider Self Registration Rider Google Address not available, you can
                                select rider google address below
                            </Alert>
                            <br />
                        </>
                    )}
                    <Card>
                        <CardBody>
                            {!editingMode && <ClientAlerts />}
                            {/* <MerchantAlerts /> */}
                            {!formData.verified && formData._id && <Alert color="warning">Rider not verified!</Alert>}
                            {alertMessage && <Alert>{alertMessage}</Alert>}
                            <h4 className="card-title">Enter rider details</h4>
                            <AvForm
                                autoComplete="off"
                                className="needs-validation default-form"
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                                id="riderRegistrationForm"
                            >
                                <fieldset disabled={formLoading || isViewMode}>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="email">E-Mail Address</Label>
                                                <AvField
                                                    name="email"
                                                    placeholder="E-mail"
                                                    type="email"
                                                    errorMessage="Enter a valid email address"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    disabled={formData.isApproved}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="mobileNo">Mobile Number</Label>
                                                <AvField
                                                    name="mobileNo"
                                                    placeholder="Mobile No"
                                                    mask="+60 99-999 99999"
                                                    maskChar=""
                                                    tag={[Input, InputMask]}
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="mobileNo"
                                                    value={formData.mobileNo}
                                                    onChange={handleInputChange}
                                                    disabled={formData.isApproved}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="firstName">First name</Label>
                                                <AvField
                                                    name="firstName"
                                                    placeholder="First name"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="firstName"
                                                    value={formData.firstName}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="lastName">Last name</Label>
                                                <AvField
                                                    name="lastName"
                                                    placeholder="Last name"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="lastName"
                                                    value={formData.lastName}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="gender">Gender</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(formData.gender)}
                                                    options={[
                                                        { label: 'Male', value: 'Male' },
                                                        { label: 'Female', value: 'Female' },
                                                    ]}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'gender')}
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="nic">NRIC Number (NRIC)</Label>
                                                <InputMask
                                                    mask="999999-99-9999"
                                                    value={formData.nic}
                                                    className="form-control"
                                                    name="nic"
                                                    errorMessage="Please provide a valid NRIC."
                                                    onChange={handleInputChange}
                                                    required
                                                    validate={{ required: { value: true } }}
                                                    id="nic"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Label htmlFor="nic">
                                                    Upload NRIC (FRONT){' '}
                                                    {attachmentsObj.nicFrontImageUrl.data &&
                                                        attachmentsObj.nicFrontImageUrl.data.name && (
                                                            <div>{attachmentsObj.nicFrontImageUrl.data.name}</div>
                                                        )}
                                                </Label>
                                                <ButtonGroup>
                                                    {attachmentsObj.nicFrontImageUrl.data && (
                                                        <Button
                                                            id="view-nric-front-btn"
                                                            color="warning"
                                                            onClick={() =>
                                                                setAttachmentPreviewModalState({
                                                                    type: 'default',
                                                                    show: true,
                                                                    header: `NRIC Number (Front)  ${
                                                                        formData.nic ? `- ${formData.nic}` : ''
                                                                    }`,
                                                                    imageUrl: attachmentsObj.nicFrontImageUrl.data,
                                                                    isDbSaved:
                                                                        attachmentsObj.nicFrontImageUrl.isDbSaved,
                                                                })
                                                            }
                                                        >
                                                            View File
                                                        </Button>
                                                    )}

                                                    <Button
                                                        color="warning"
                                                        onClick={() => {
                                                            showImageUpload(
                                                                'Upload NRIC Number (Front)',
                                                                'nicFrontImageUrl',
                                                                'NRIC Number (Front)'
                                                            );
                                                        }}
                                                        id="upload-nric-front-btn"
                                                    >
                                                        {attachmentsObj.nicFrontImageUrl.data ? 'Update' : 'Upload'}
                                                    </Button>
                                                </ButtonGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Label htmlFor="nic">
                                                    Upload NRIC (BACK)
                                                    {attachmentsObj.nicBackImageUrl.data &&
                                                        attachmentsObj.nicBackImageUrl.data.name && (
                                                            <div>{attachmentsObj.nicBackImageUrl.data.name}</div>
                                                        )}
                                                </Label>
                                                <ButtonGroup>
                                                    {attachmentsObj.nicBackImageUrl.data && (
                                                        <Button
                                                            color="warning"
                                                            onClick={() =>
                                                                setAttachmentPreviewModalState({
                                                                    type: 'default',
                                                                    show: true,
                                                                    header: `NRIC Number  (Back)   ${
                                                                        formData.nic ? `- ${formData.nic}` : ''
                                                                    }`,
                                                                    imageUrl: attachmentsObj.nicBackImageUrl.data,
                                                                    isDbSaved: attachmentsObj.nicBackImageUrl.isDbSaved,
                                                                })
                                                            }
                                                            id="view-nric-back-btn"
                                                        >
                                                            View File
                                                        </Button>
                                                    )}

                                                    <Button
                                                        color="warning"
                                                        onClick={() => {
                                                            showImageUpload(
                                                                'Upload NRIC Number (Back)',
                                                                'nicBackImageUrl',
                                                                'NRIC Number (Back)'
                                                            );
                                                        }}
                                                        id="upload-nric-back-btn"
                                                    >
                                                        {attachmentsObj.nicBackImageUrl.data ? 'Update' : 'Upload'}
                                                    </Button>
                                                </ButtonGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="socsoNo">SOCSO Number</Label>
                                                <AvField
                                                    name="socsoNo"
                                                    placeholder="SOCSO"
                                                    type="text"
                                                    errorMessage="Please provide a valid SOCSO."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="socsoNo"
                                                    disabled
                                                    value={formData.socsoNo}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="epf">EPF Number</Label>
                                                <AvField
                                                    name="epf"
                                                    placeholder="EPF Number"
                                                    type="number"
                                                    minLength={9}
                                                    maxLength={9}
                                                    errorMessage="Please provide a valid EPF Number."
                                                    className="form-control"
                                                    id="epf"
                                                    value={formData.epf}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {editingMode && (
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="address">Address</Label>
                                                    <AvField
                                                        name="address"
                                                        placeholder="Address"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="address"
                                                        value={formData.address}
                                                        disabled={
                                                            editingMode && formData.address && !formData.addressMeta
                                                        }
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}
                                        <Col md="4">
                                            <Row>
                                                <Col md="10" sm="10" xs="10">
                                                    <FormGroup>
                                                        <Label htmlFor="address">Address</Label>
                                                        <PlacesAutocompleteField
                                                            addressData={formData.addressMeta || null}
                                                            returnLocationData={returnLocationData}
                                                            id="address"
                                                            idMeta="addressMeta"
                                                            isRequired
                                                            submitted={submitted}
                                                            validated={validated}
                                                            isClearable
                                                        />
                                                        {autoCompleteModal.type === 'address' &&
                                                            autoCompleteModal.show &&
                                                            !isViewMode && (
                                                                <PlacesAutocompleteModal
                                                                    show={autoCompleteModal.show}
                                                                    isModal
                                                                    addressData={formData.addressMeta || null}
                                                                    returnLocationData={returnLocationData}
                                                                    toggle={() =>
                                                                        setAutoCompleteModal({
                                                                            show: !autoCompleteModal.show,
                                                                            type: 'address',
                                                                        })
                                                                    }
                                                                    id="address"
                                                                    idMeta="addressMeta"
                                                                />
                                                            )}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="2" sm="2" xs="2">
                                                    <motion.div
                                                        style={{ width: 35, height: 35 }}
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        className="scootix-btn-circle shadow-lg nw-md ml-3 order-map-pin-container"
                                                        onClick={() =>
                                                            setAutoCompleteModal({
                                                                show: !autoCompleteModal.show,
                                                                type: 'address',
                                                            })
                                                        }
                                                    >
                                                        <div className="map-pin">
                                                            <i className="ri-map-pin-line fa-lg" />
                                                        </div>
                                                    </motion.div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="postalCode">Postal Code</Label>
                                                <AvField
                                                    name="postalCode"
                                                    placeholder="Postal Code"
                                                    type="Number"
                                                    minLength={5}
                                                    maxLength={5}
                                                    errorMessage="Please provide a valid Postal Code."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="postalCode"
                                                    value={formData.postalCode}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="city">City</Label>
                                                <AvField
                                                    name="city"
                                                    placeholder="City"
                                                    type="text"
                                                    errorMessage="Please provide a valid city."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="city"
                                                    value={formData.city}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="state">State</Label>
                                                <AvField
                                                    name="state"
                                                    placeholder="State"
                                                    type="text"
                                                    errorMessage="Please provide a valid state."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="state"
                                                    value={formData.state}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="country">Country</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(formData.country)}
                                                    options={countries.map((country) => ({
                                                        label: country.details.name,
                                                        value: country.details.name,
                                                    }))}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'country')}
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="emergencyContactName">Emergency Contact Name</Label>
                                                <AvField
                                                    name="emergencyContactName"
                                                    placeholder="Emergency Contact Name"
                                                    type="text"
                                                    errorMessage="Please Enter"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="emergencyContactName"
                                                    value={formData.emergencyContactName}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="emergencyContactNumber">Emergency Contact Number</Label>
                                                <AvField
                                                    name="emergencyContactNumber"
                                                    placeholder="Emergency Contact Number"
                                                    // type="text"
                                                    mask="+60 99-999 99999"
                                                    maskChar=""
                                                    tag={[Input, InputMask]}
                                                    errorMessage="Please Enter"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="emergencyContactNumber"
                                                    value={formData.emergencyContactNumber}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="3">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Label htmlFor="nic">
                                                    Upload Driver's License (FRONT)
                                                    {attachmentsObj.driversLicenseSideOneImageUrl.data &&
                                                        attachmentsObj.driversLicenseSideOneImageUrl.data.name && (
                                                            <div>
                                                                {attachmentsObj.driversLicenseSideOneImageUrl.data.name}
                                                            </div>
                                                        )}
                                                </Label>
                                                <ButtonGroup>
                                                    {attachmentsObj.driversLicenseSideOneImageUrl.data && (
                                                        <Button
                                                            color="warning"
                                                            onClick={() =>
                                                                setAttachmentPreviewModalState({
                                                                    type: 'default',
                                                                    show: true,
                                                                    header: `Driver's License (Front)`,
                                                                    imageUrl:
                                                                        attachmentsObj.driversLicenseSideOneImageUrl
                                                                            .data,
                                                                    isDbSaved:
                                                                        attachmentsObj.driversLicenseSideOneImageUrl
                                                                            .isDbSaved,
                                                                })
                                                            }
                                                        >
                                                            View File
                                                        </Button>
                                                    )}

                                                    <Button
                                                        color="warning"
                                                        onClick={() => {
                                                            showImageUpload(
                                                                `Upload Driver's License (Front)`,
                                                                'driversLicenseSideOneImageUrl',
                                                                `Driver's License (Front)`
                                                            );
                                                        }}
                                                    >
                                                        {attachmentsObj.driversLicenseSideOneImageUrl.data
                                                            ? 'Update'
                                                            : 'Upload'}
                                                    </Button>
                                                </ButtonGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Label htmlFor="nic">
                                                    Upload Driver's License (BACK)
                                                    {attachmentsObj.driversLicenseSideTwoImageUrl.data &&
                                                        attachmentsObj.driversLicenseSideTwoImageUrl.data.name && (
                                                            <div>
                                                                {attachmentsObj.driversLicenseSideTwoImageUrl.data.name}
                                                            </div>
                                                        )}
                                                </Label>
                                                <ButtonGroup>
                                                    {attachmentsObj.driversLicenseSideTwoImageUrl.data && (
                                                        <Button
                                                            color="warning"
                                                            onClick={() =>
                                                                setAttachmentPreviewModalState({
                                                                    type: 'default',
                                                                    show: true,
                                                                    header: `Driver's License (Back)`,
                                                                    imageUrl:
                                                                        attachmentsObj.driversLicenseSideTwoImageUrl
                                                                            .data,
                                                                    isDbSaved:
                                                                        attachmentsObj.driversLicenseSideTwoImageUrl
                                                                            .isDbSaved,
                                                                })
                                                            }
                                                        >
                                                            View File
                                                        </Button>
                                                    )}

                                                    <Button
                                                        color="warning"
                                                        onClick={() => {
                                                            showImageUpload(
                                                                `Upload Upload Driver's License (Back)`,
                                                                'driversLicenseSideTwoImageUrl',
                                                                `Upload Driver's License (Back)`
                                                            );
                                                        }}
                                                    >
                                                        {attachmentsObj.driversLicenseSideTwoImageUrl.data
                                                            ? 'Update'
                                                            : 'Upload'}
                                                    </Button>
                                                </ButtonGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="bank">Bank</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(formData.bank)}
                                                    options={banks.map((bank) => ({
                                                        label: bank.name,
                                                        value: bank.name,
                                                    }))}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'bank')}
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="bankAccountNo">Bank Account Number</Label>
                                                <AvField
                                                    name="bankAccountNo"
                                                    placeholder="Bank Account No"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={formData.bankAccountNo}
                                                    onChange={handleInputChange}
                                                    id="bankAccountNo"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="vehicleType">Select Your Vehicle type</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(formData.vehicleType)}
                                                    options={[
                                                        { label: 'Motorbike', value: 'Motorbike' },
                                                        { label: 'Car', value: 'Car' },
                                                        { label: 'Truck', value: 'Truck' },
                                                        { label: 'Van - 7FT', value: 'Van7FT' },
                                                    ]}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'vehicleType')}
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="vehicleName">Select Your Vehicle</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(formData.vehicleName)}
                                                    options={vehicles
                                                        .map((vehicle) => ({
                                                            label: vehicle.name,
                                                            value: vehicle.name,
                                                        }))
                                                        .concat({ label: 'Other', value: 'Other' })}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'vehicleName')}
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                />
                                            </FormGroup>
                                        </Col>
                                        {formData.vehicleName === 'Other' && (
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="otherVehicle">Vehicle Model</Label>
                                                    <AvField
                                                        name="otherVehicle"
                                                        placeholder="Other Vehicle Name"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        value={formData.otherVehicle}
                                                        onChange={handleInputChange}
                                                        id="otherVehicle"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="vehicleNo">Vehicle Number</Label>
                                                <AvField
                                                    name="vehicleNo"
                                                    placeholder="Vehicle No"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{
                                                        required: { value: true },
                                                        pattern: {
                                                            value: /^[A-Z0-9]*(?:List)?$/,
                                                            errorMessage: 'Invalid Vehicle Number',
                                                        },
                                                    }}
                                                    value={formData.vehicleNo}
                                                    onChange={handleInputChange}
                                                    id="vehicleNo"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="3">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Label htmlFor="nic">
                                                    Upload Road Tax
                                                    {attachmentsObj.roadTaxLicenseImageUrl.data &&
                                                        attachmentsObj.roadTaxLicenseImageUrl.data.name && (
                                                            <div>{attachmentsObj.roadTaxLicenseImageUrl.data.name}</div>
                                                        )}
                                                </Label>
                                                <ButtonGroup>
                                                    {attachmentsObj.roadTaxLicenseImageUrl.data && (
                                                        <Button
                                                            color="warning"
                                                            onClick={() =>
                                                                setAttachmentPreviewModalState({
                                                                    type: 'default',
                                                                    show: true,
                                                                    header: `Road TAX`,
                                                                    imageUrl:
                                                                        attachmentsObj.roadTaxLicenseImageUrl.data,
                                                                    isDbSaved:
                                                                        attachmentsObj.roadTaxLicenseImageUrl.isDbSaved,
                                                                })
                                                            }
                                                        >
                                                            View File
                                                        </Button>
                                                    )}

                                                    <Button
                                                        color="warning"
                                                        onClick={() => {
                                                            showImageUpload(
                                                                'Upload Road TAX',
                                                                'roadTaxLicenseImageUrl',
                                                                'Road TAX'
                                                            );
                                                        }}
                                                    >
                                                        {attachmentsObj.roadTaxLicenseImageUrl.data
                                                            ? 'Update'
                                                            : 'Upload'}
                                                    </Button>
                                                </ButtonGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Label htmlFor="nic">
                                                    Upload Photo of Vehicle
                                                    {attachmentsObj.vehicleImageUrl.data &&
                                                        attachmentsObj.vehicleImageUrl.data.name && (
                                                            <div>{attachmentsObj.vehicleImageUrl.data.name}</div>
                                                        )}
                                                </Label>
                                                <ButtonGroup>
                                                    {attachmentsObj.vehicleImageUrl.data && (
                                                        <Button
                                                            color="warning"
                                                            onClick={() =>
                                                                setAttachmentPreviewModalState({
                                                                    type: 'default',
                                                                    show: true,
                                                                    header: `Vehicle Image`,
                                                                    imageUrl: attachmentsObj.vehicleImageUrl.data,
                                                                    isDbSaved: attachmentsObj.vehicleImageUrl.isDbSaved,
                                                                })
                                                            }
                                                        >
                                                            View File
                                                        </Button>
                                                    )}

                                                    <Button
                                                        color="warning"
                                                        onClick={() => {
                                                            showImageUpload(
                                                                'Upload Vehicle Image',
                                                                'vehicleImageUrl',
                                                                'Vehicle Image'
                                                            );
                                                        }}
                                                    >
                                                        {attachmentsObj.vehicleImageUrl.data ? 'Update' : 'Upload'}
                                                    </Button>
                                                </ButtonGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="merchantId">Merchant (Optional)</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(
                                                        getMerchantSelect(formData.merchantId)
                                                    )}
                                                    isClearable
                                                    options={merchants}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                    submitted={submitted}
                                                    required={formData.isLimitedToMerchant}
                                                    validated={validated}
                                                    // isDisabled={role === 'merchantAdmin' || editingMode}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Label htmlFor="">&nbsp;</Label>
                                                <div className="form-check mb-3">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        onChange={handleCheckChange}
                                                        checked={formData.isLimitedToMerchant}
                                                        defaultChecked={formData.isLimitedToMerchant}
                                                        id="isLimitedToMerchant"
                                                    />
                                                    <Label className="form-check-label" htmlFor="defaultCheck1">
                                                        Limit to Merchant
                                                    </Label>
                                                </div>
                                            </FormGroup>
                                        </Col>

                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="merchantLocationId">Merchant Locations</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(
                                                        getMerchantLocationSelect(formData.merchantLocationId)
                                                    )}
                                                    options={allMerchantLocations.map((x) => ({
                                                        label: x.label,
                                                        value: x.key,
                                                    }))}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) =>
                                                        handleSelectChange(event, 'merchantLocationId')
                                                    }
                                                    submitted={submitted}
                                                    validated={validated}
                                                    required={formData.isLimitedToMerchantLocation}
                                                    isClearable
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Label htmlFor="">&nbsp;</Label>
                                                <div className="form-check mb-3">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        onChange={handleCheckChange}
                                                        checked={formData.isLimitedToMerchantLocation}
                                                        defaultChecked={formData.isLimitedToMerchantLocation}
                                                        id="isLimitedToMerchantLocation"
                                                    />
                                                    <Label className="form-check-label" htmlFor="defaultCheck1">
                                                        Limit to a Merchant Location
                                                    </Label>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Label htmlFor="">&nbsp;</Label>
                                                <div className="form-check mb-3">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        onChange={handleCheckChange}
                                                        checked={formData.isMuslim}
                                                        defaultChecked={formData.isMuslim}
                                                        id="isMuslim"
                                                    />
                                                    <Label className="form-check-label" htmlFor="defaultCheck1">
                                                        Halal Deliveries
                                                    </Label>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {!editingMode && (
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="password">Password</Label>
                                                    <AvField
                                                        name="password"
                                                        placeholder="Password"
                                                        type="password"
                                                        minLength={6}
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="password"
                                                        value={formData.password}
                                                        onChange={handleInputChange}
                                                        autoComplete="new-password"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="confirmPassword">Confirm Password</Label>
                                                    <AvField
                                                        name="confirmPassword"
                                                        placeholder="Password"
                                                        type="password"
                                                        minLength={6}
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="confirmPassword"
                                                        value={formData.confirmPassword}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}

                                    <Row style={{ justifyContent: 'flex-start' }}>
                                        {!isViewMode && visitingClient && showSaveBtn && (
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                type="submit"
                                                disabled={formLoading}
                                            >
                                                {formLoading ? (
                                                    <Spinner className="mr-4 ml-4 0" color="info" />
                                                ) : (
                                                    <>
                                                        <i className="fas fa-save left-icon"></i>
                                                        <span className="h6">
                                                            {editingMode ? 'Update' : 'Create Rider'}
                                                        </span>
                                                    </>
                                                )}
                                            </motion.button>
                                        )}
                                        {!formData.verified && editingMode && (
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                onClick={() => handleResendVerificationCode()}
                                                disabled={formLoading}
                                            >
                                                {formLoading ? (
                                                    <Spinner className="mr-4 ml-4 0" color="info" />
                                                ) : (
                                                    <>
                                                        <i className="fas fa-save left-icon"></i>
                                                        <span className="h6">Resend Verification Code</span>
                                                    </>
                                                )}
                                            </motion.button>
                                        )}
                                    </Row>
                                </fieldset>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Container>

                <br />

                {editingMode && hasAccess(PERMISSIONS.APPROVE_RIDER, ['View']) && (
                    <Container fluid>
                        <Card>
                            <CardBody>
                                <div
                                    style={{
                                        justifyContent: 'space-between',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'col',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div>RIDER ACCOUNT STATUS</div>
                                        <div
                                            className="ml-3 pr-3 pl-3 pt-1 pb-1"
                                            style={{
                                                textTransform: 'capitalize',
                                                borderRadius: 20,
                                                backgroundColor: !formData.isDisabled ? '#81EE6F' : '#FFCBD4',
                                            }}
                                        >
                                            {formData.isDisabled ? 'INACTIVE' : 'ACTIVE'}
                                        </div>
                                    </div>
                                    <div>
                                        {!formData.isRequestAccountDeletion && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'col',
                                                    ...centerElementInnerStyles,
                                                }}
                                            >
                                                {formData.isApproved && (
                                                    <div className="mr-3 mt-2" id="disable-switch">
                                                        <Switch
                                                            uncheckedIcon={<div />}
                                                            checkedIcon={<div />}
                                                            onColor="#FAA123"
                                                            offColor="#808080"
                                                            onChange={() => toggleRiderDisable(formData.isDisabled)}
                                                            checked={!formData.isDisabled}
                                                        />
                                                        <TooltipDefault
                                                            text={`${formData.isDisabled ? 'Enable' : 'Disable'} Rider`}
                                                            targetId="disable-switch"
                                                        />
                                                    </div>
                                                )}

                                                <div>
                                                    {hasAccess(PERMISSIONS.RIDER_ACCOUNT, ['Delete']) && (
                                                        <motion.div
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.9 }}
                                                            className="scootix-btn-radius secondary-btn pr-3 pl-3 shadow-lg nw-md"
                                                            style={{ height: 35 }}
                                                            onClick={() => handleOnDelete()}
                                                        >
                                                            <i className="fas fa-minus-circle left-icon fa-lg"></i>
                                                            Delete Rider
                                                        </motion.div>
                                                    )}
                                                </div>
                                                {!formData.isApproved && formData.verified && (
                                                    <div>
                                                        {hasAccess(PERMISSIONS.APPROVE_RIDER, ['Approve']) && (
                                                            <motion.div
                                                                whileHover={{ scale: 1.1 }}
                                                                whileTap={{ scale: 0.9 }}
                                                                className={`scootix-btn-radius pr-3 ml-1 pl-3 shadow-lg nw-md ${
                                                                    formData.isApproved ? 'disabled-div ' : ''
                                                                }`}
                                                                style={{ height: 35 }}
                                                                onClick={() => handleOnApprove()}
                                                            >
                                                                <i className="fas fa-eye left-icon fa-lg"></i>
                                                                Approve Rider
                                                            </motion.div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div style={{ color: THEME_COLOR_1 }}>
                                        <i className="fas fa-history" style={{ color: THEME_COLOR }} /> Transaction Log
                                    </div>

                                    <TableCustom
                                        id="warehouse-site-location-id"
                                        tableData={allRiderTransactions}
                                        removeAction
                                        showPagination={false}
                                        showView={false}
                                        showEdit={false}
                                        removeId={false}
                                        filters={[]}
                                        isSearch={false}
                                        isFilter={false}
                                        showTransactionDataRecords
                                        customActionHandlers={{ displayModal }}
                                        isFixedHeight
                                    />
                                    {/* {allRiderTransactions &&
                                        allRiderTransactions.map((x) => (
                                            <>
                                                <div className="m-2">
                                                    {' '}
                                                    {moment(x.createdAt).format('YYYY-MM-DD')} at{' '}
                                                    {moment(x.createdAt).format('HH.mm A')} - {x.description}
                                                </div>
                                            </>
                                        ))} */}
                                </div>
                            </CardBody>
                        </Card>

                        <br />
                        {editingMode && (
                            <>
                                <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                                    <CardBody>
                                        <Row className="justify-content-between">
                                            <SubTitle
                                                style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}
                                                className="ml-3 mt-2"
                                            >
                                                RIDER COMMISSION SETTING
                                            </SubTitle>
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                                onClick={() => handleNewRiderCommissionSetting()}
                                            >
                                                <i className="fas fa-warehouse left-icon mr-2" />
                                                <div style={{ marginTop: 1, marginLeft: 3 }}>
                                                    New Rider Commission Setting
                                                </div>
                                            </motion.div>
                                        </Row>
                                        <hr />
                                        <div style={{ marginTop: -15 }}>
                                            <TableCustom
                                                id="warehouse-site-location-id"
                                                tableData={riderCommissionSettingListData}
                                                loading={riderCommissionSettingLoading}
                                                isFullScreenShow
                                                handleEdit={handleEditRiderCommissionSetting}
                                                showView={false}
                                                showEdit={hasAccess(PERMISSIONS.DELIVERY, ['Edit'])}
                                                removeId={false}
                                                filters={[]}
                                                isSearch={false}
                                                isFilter={false}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </>
                        )}
                    </Container>
                )}
            </div>
            <BackdropLoader show={backdropLoading} opacity={0.8} />
            <AlertModal
                show={showDeleteModal.state}
                onConfirm={handleDeleteRider}
                data={showDeleteModal.data}
                onHide={() =>
                    setShowDeleteModal({
                        state: false,
                        data: null,
                    })
                }
            />
            {showResetPasswordModal && formData._id && (
                <ChangePasswordModalNew userData={formData} toggleModal={toggleResetPasswordModal} isRider />
            )}
            {imageUploadModalState.show && (
                <ImageUploadModal toggleModal={toggleImageUploadModal} {...imageUploadModalState} />
            )}
            {attachmentPreviewModalState.show && (
                <AttachmentPreviewModal
                    image={attachmentPreviewModalState.imageUrl}
                    show={attachmentPreviewModalState.show}
                    type={attachmentPreviewModalState.type}
                    header={attachmentPreviewModalState.header}
                    toggleModal={() => {
                        setAttachmentPreviewModalState({ show: false, multiple: false });
                    }}
                    {...attachmentPreviewModalState}
                    isFormAttachmentModal
                />
            )}
            <BackdropLoader show={backdropLoading} opacity={0.8} />
            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />

            {/* Restore Modal */}
            {showRestoreRiderModal && (
                <RestoreRiderModal
                    show={showRestoreRiderModal}
                    onclose={setShowRestoreRiderModal}
                    handleRestoreRiderAccount={handleRestoreRiderAccount}
                    userData={formData}
                />
            )}

            {/* Delete Modal */}
            {showDeleteRiderModal && (
                <DeleteRiderModel
                    show={showDeleteRiderModal}
                    onclose={setShowDeleteRiderModal}
                    handleDeleteRiderAccount={handleDeleteRiderAccount}
                    userData={formData}
                />
            )}

            {/* Transaction Modal */}
            <TransactionRiderModel
                show={modalOptions.modal === 'transaction'}
                handleToggle={handleTransactionModalToggle}
                transactionId={modalOptions.data.transactionId}
                name={modalOptions.data.name}
                profileId={modalOptions.data.profileId}
                transactionReference={modalOptions.data.transactionReference}
                newSettings={modalOptions.data.settings}
                previousSettings={modalOptions.data.previousSettings}
                newUserDetails={modalOptions.data.newUserDetails}
                previousData={modalOptions.data.previousData}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

const HotRiderRegistrationPage = hot(RiderRegistration);

export default connect(mapStateToProps, {})(HotRiderRegistrationPage);
