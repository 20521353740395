/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

function TextPreviewModal(props) {
    const { toggleModal, show, type, text, header = 'Note Preview' } = props;

    return (
        <>
            <Modal size="xl" isOpen={show} toggle={toggleModal} center className="modal-dialog modal-dialog-centered">
                <ModalHeader toggle={toggleModal}>{header}</ModalHeader>
                <ModalBody>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                        {type === 'default' && <>{text}</>}
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

TextPreviewModal.propTypes = {
    toggleModal: PropTypes.func,
    show: PropTypes.bool,
    type: PropTypes.string,
    header: PropTypes.any,
    text: PropTypes.any,
};

export default TextPreviewModal;
