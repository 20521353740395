import React from 'react';
import { TableCustom } from 'components';
import PropTypes from 'prop-types';

// #region helpers
import { transactionMappedData } from 'helpers/transactions';

import { __BLOCKED_FOR_MERCHANTS__ } from 'utils/checks';
import {
    transactionTableHeadersForAll,
    transactionTableHeadersForMerchants,
    transactionInitialValues,
} from '../../constants';

function TransactionTable(props) {
    const { deliveryTransactions = [], data } = props;
    const mapDeliveryTransactions = (records) => {
        let allDocs = [];
        allDocs = records.map((transaction) => ({
            ...transaction,
            methodFormatted: transactionMappedData(transaction.method, { ...transaction, deliveryData: data })
                .formattedMethod,
            consoleUserFormatted: transactionMappedData(transaction.method, { ...transaction, deliveryData: data })
                .consoleUserFormatted,
            driverFormatted: transactionMappedData(transaction.method, { ...transaction, deliveryData: data })
                .driverFormatted,
            remarks: transactionMappedData(transaction.method, { ...transaction, deliveryData: data }).remarks,
        }));
        return allDocs;
    };

    return (
        <TableCustom
            tableData={{
                ...transactionInitialValues,
                docs: mapDeliveryTransactions(deliveryTransactions),
                headers: __BLOCKED_FOR_MERCHANTS__
                    ? transactionTableHeadersForMerchants
                    : transactionTableHeadersForAll,
            }}
            showPagination={false}
            removeAction
            isFilter={false}
            isSearch={false}
            isFixedHeight
            isCardContainer={false}
            isOuterTableBorder
        />
    );
}

TransactionTable.propTypes = {
    deliveryTransactions: PropTypes.any,
    data: PropTypes.object,
};

export default TransactionTable;
