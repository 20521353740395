/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import paths from 'routes/paths';
import { connect } from 'react-redux';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';

// #region imports
import Hoc from 'components/Hoc';
import { getApiErrorMessage } from 'constants/api';

import { BackdropLoader, CustomToast } from 'components';

// action
import { forgetUser } from '../../../store/actions';

// import images
import { verifyUserService } from './services';

function VerifyUserPage(props) {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);

    const history = useHistory();

    useEffect(() => {
        const currentPagePathname = props.location.pathname;
        if (currentPagePathname) {
            let formatToken = currentPagePathname.replace('verify', '');
            formatToken = formatToken.replace(/\/+/g, '');
            setToken(formatToken);
        }
    }, []);

    useEffect(() => {
        if (token) {
            handleVerifyToken();
        }
    }, [token]);

    // handle Verify Token
    const handleVerifyToken = async () => {
        setLoading(true);
        try {
            await verifyUserService({ id: token });
            setTimeout(() => {
                setTimeout(() => {
                    history.push({
                        pathname: `/login`,
                        // search: '?type=new',
                        state: { prevCompState: { SCREEN: paths.AUTH.VERIFY_USER } },
                    });
                }, 2000);
            }, 2000);
            setLoading(false);
            toast.custom((t) => <CustomToast text="User Verified successfully" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors && e.data.errors.msg) {
                const message = getApiErrorMessage(e.data.errors.msg, e.status || null);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            } else {
                toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        }
        setLoading(false);
    };

    return (
        <Hoc {...props}>
            <div className="home-btn d-none d-sm-block">
                <Link to="/">
                    <i className="mdi mdi-home-variant h2 text-white"></i>
                </Link>
            </div>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="auth-body w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        {/* <Link to="/" className="logo">
                                                            <img src={logodark} height="20" alt="logo" />
                                                        </Link> */}
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">Verify User</h4>
                                                </div>
                                                <br />
                                                <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="scootix-btn-radius is-click m-2 mb-3 shadow-lg nw-md pr-5 pl-5"
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: paths.AUTH.LOGIN,
                                                        });
                                                    }}
                                                >
                                                    <span> Go to Login</span>
                                                </motion.div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <div className="bg-overlay"></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <BackdropLoader show={loading} opacity={0.8} />
            </div>
        </Hoc>
    );
}

VerifyUserPage.propTypes = {
    location: PropTypes.any,
};

const mapStatetoProps = (state) => {
    const { message, forgetError, loading } = state.Forget;
    return { message, forgetError, loading };
};

export default withRouter(connect(mapStatetoProps, { forgetUser })(VerifyUserPage));
