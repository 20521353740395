export const batchPrintTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Delivery Id',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
    },
    {
        key: 'orderReferenceNumber',
        value: 'Order Number',
    },
    {
        key: 'merchantOrderNo',
        value: 'Merchant Order Number',
    },
    {
        key: 'pickupLocation',
        value: 'Pickup Location',
    },
    {
        key: 'deliveryAddress',
        value: 'Delivery Location',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
];

export const batchPrintData = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: batchPrintTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};

export const batchPrintFormDeliveryListInitialValues = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: batchPrintTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};
