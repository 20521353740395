import { DELIVERY_TYPES } from 'constants/constants';

export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Delivery ID',
    },
    {
        key: 'deliveryType',
        value: 'Delivery Type',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
    {
        key: 'orderReferenceNumber',
        value: 'Order Number',
    },
    {
        key: 'merchantOrderNo',
        value: 'Merchant Order Number',
    },
    {
        key: 'pickupLocation',
        value: 'From',
        style: ['increase-width'],
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
    {
        key: 'pickedUpAt',
        value: 'Picked Up At',
        type: 'dateTime',
    },
    {
        key: 'completedAt',
        value: 'Delivery Completed At',
        type: 'dateTime',
    },
    {
        key: 'distance',
        value: 'Distance',
    },
    {
        key: 'recipientName',
        value: 'Recipient',
    },
    {
        key: 'riderName',
        value: 'Rider',
    },
    {
        key: 'formattedStatus',
        value: 'Current Status',
        type: 'deliveryStatus',
    },
];

export const defaultSummaryTableHeaders = [
    {
        key: 'date',
        value: 'Period',
    },
    {
        key: 'totalNewDeliveries',
        value: 'New Deliveries',
    },
    {
        key: 'totalPendingDeliveries',
        value: 'Pending Deliveries',
    },
    {
        key: 'totalCompletedDeliveries',
        value: 'Completed Deliveries',
    },
    {
        key: 'totalDeliveries',
        value: 'Total Deliveries',
    },
];

export const defaultSummaryCompletedTableHeaders = [
    {
        key: 'date',
        value: 'Period',
    },
    {
        key: 'totalCompletedDeliveries',
        value: 'Completed Deliveries',
    },
];

export const REPORT_OPTIONS = [
    { label: 'Delivery Progress Report', value: 'created' },
    { label: 'Completed Deliveries', value: 'completed' },
];

export const DELIVERY_TYPE_OPTIONS = [
    { label: 'All', value: 'All' },
    { label: 'New Deliveries', value: DELIVERY_TYPES.NEW_ORDER },
    { label: 'Return Deliveries', value: DELIVERY_TYPES.RETURN_ORDER },
    { label: 'On-Demand Deliveries', value: DELIVERY_TYPES.ON_DEMAND_DELIVERY },
];

export const initialDeliverySummaryData = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: defaultSummaryTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};

export const initialDeliveryReportData = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: defaultTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};

export const deliveryTypesBasedColumns = [
    {
        key: 'newOrderCount',
        value: `New Deliveries`,
    },
    {
        key: 'returnOrderCount',
        value: `Return Deliveries`,
    },
    {
        key: 'onDemandOrderCount',
        value: `On-Demand Deliveries`,
    },
];
