import React from 'react';
import PropTypes from 'prop-types';

/**
 * A dropdown menu that allows the user to select the number of results to display per page.
 * @param {Object} props - The component props.
 * @param {number} props.limit - The current limit value (i.e. number of results per page).
 * @param {function} props.handleResultsPerPageChange - A callback function that is triggered when the user changes the results per page.
 * @returns {JSX.Element} - The JSX markup for the component.
 */
const ResultsPerPage = (props) => {
    const { limit, handleResultsPerPageChange } = props;

    const resultsPerPage = [10, 20, 50];

    /**
     * Callback function that is triggered when the user changes the results per page.
     * @param {Object} event - The event object.
     */
    const onResultPerPageChange = (event) => {
        handleResultsPerPageChange(event.target.value);
    };

    return (
        <div className="results-per-page-container">
            Results per page
            <select className="results-per-page ml-3" onChange={onResultPerPageChange} value={limit}>
                {resultsPerPage.map((count) => (
                    <option key={count} value={count}>
                        {count}
                    </option>
                ))}
            </select>
        </div>
    );
};

ResultsPerPage.propTypes = {
    limit: PropTypes.number,
    handleResultsPerPageChange: PropTypes.func,
};

export default ResultsPerPage;
