/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Label, Spinner } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';

// #region assets | components
import { Select, CustomToast } from 'components';

// #region other imports
import { THEME_COLOR_2 } from 'theme';
import { newDeliveryAttemptService } from 'pages/Modules/Delivery-Management/services';

// #region services
import { getRiderListData } from 'services/rider.service';

// #region utils
import { apiErrorHandlerV2, getDefaultValueForSelect } from 'utils/helpers';

// #endregion imports
import { confirmValidateForm } from '../utils';

function AddDeliveryAttempt(props) {
    const { data: deliveryData, setShowModal, loadTransactions, setDeliveryFormData, updateHistoryWithData } = props;

    const { merchantOrderNo, referenceNumber, _id, vehicleType } = deliveryData;

    const initialFormValues = {
        isBillable: true,
        isAssignRider: false,
        riderId: '',
        confirmation: '',
    };

    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);

    const [riderListData, setRiderListData] = useState({ docs: [] });

    const handleSelectChange = (event, id) => {
        if (id === 'confirmation' && event) {
            setFormData({
                ...formData,
                [id]: event ? event.target.value : null,
            });
            return;
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleSubmit = async (event, errors = []) => {
        let updatedPayloadData;

        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);

            return;
        }
        event.persist();

        const { isFormValid, message } = confirmValidateForm(formData);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        const payload = { ...formData, deliveryId: _id };
        setFormLoading(true);
        let isSuccess = false;

        try {
            const { data } = await newDeliveryAttemptService(payload);
            if (data) {
                setFormData({ ...payload, ...data });
                updatedPayloadData = { ...deliveryData, ...data };
                setDeliveryFormData({ ...deliveryData, ...data });
                isSuccess = true;
                toast.custom((t) => <CustomToast text="New Delivery Attempt Created" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (updatedPayloadData) {
            updateHistoryWithData({ ...deliveryData, ...updatedPayloadData });
        }

        loadTransactions();
        setFormLoading(false);
        setValidated(false);
        setSubmitted(false);

        if (isSuccess) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        loadRiderListData({ ...formData, showLoading: true });
    }, [formData.vehicleType]);

    /**
     * Load Delivery List Data
     * @param {*} args
     */
    const loadRiderListData = (args) => {
        getRiderListData({
            ...args,
            limit: 100,
        }).then((res) => {
            const { data } = res;
            delete data.sort;
            setRiderListData({ ...data });
        });
    };

    const relevantRidersList = (vehicle) => {
        const ridersList = riderListData.docs.filter((x) => x.vehicleType === vehicle);

        const relevantRider = ridersList.map((riders) => {
            if (riders) {
                return { label: riders.fullName || '', value: riders._id };
            }
            return null;
        });
        return relevantRider;
    };

    return (
        <div className="font-size-14 w-100 p-0">
            <div
                className="font-size-16 text-white"
                style={{ backgroundColor: THEME_COLOR_2, padding: 15, borderRadius: '5px 5px 0 0' }}
            >
                New Delivery Attempt - {merchantOrderNo} | {referenceNumber}{' '}
            </div>
            <div className="modal__form-table">
                <AvForm onSubmit={handleSubmit}>
                    <div className="modal_form-item">
                        <Label htmlFor="Assign a Rider/ Driver">Assign a Rider/ Driver</Label>
                        <Select
                            value={getDefaultValueForSelect(formData.isAssignRider === false ? 'No' : 'Yes')}
                            options={[
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ]}
                            id="isAssignRider"
                            required
                            onChange={(event) => handleSelectChange(event, 'isAssignRider')}
                            submitted={submitted}
                            validated={validated}
                        />
                    </div>

                    <div className="modal_form-item">
                        <Label htmlFor="billable">Billable</Label>
                        <Select
                            value={getDefaultValueForSelect(formData.isBillable === false ? 'No' : 'Yes')}
                            options={[
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ]}
                            id="isBillable"
                            required
                            onChange={(event) => handleSelectChange(event, 'isBillable')}
                            submitted={submitted}
                            validated={validated}
                        />
                    </div>

                    {formData.isAssignRider && (
                        <div className="modal_form-item">
                            <Label>Assign a rider</Label>
                            <Select
                                defaultValue={[{ label: deliveryData.riderName || '', value: deliveryData.riderId }]}
                                options={relevantRidersList(vehicleType)}
                                id="riderId"
                                isClearable
                                onChange={(event) => handleSelectChange(event, 'riderId')}
                                submitted={submitted}
                                validated={validated}
                            />
                        </div>
                    )}

                    <div style={{ width: '100%', padding: '10px', background: '#fffbf0', marginBottom: '15px' }}>
                        Type &apos;confirm&apos; to confirm the transaction and click confirm.
                    </div>

                    <div className="modal_form-item">
                        <AvField
                            value={formData.confirmation}
                            name="confirmation"
                            onChange={(event) => handleSelectChange(event, 'confirmation')}
                            errorMessage="Confirmation Required"
                            submitted={submitted}
                            required
                            id="confirmation"
                            validated={validated}
                            autoComplete="off"
                        />
                    </div>

                    <div style={{ fontSize: 14 }} className="mb-6">
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius secondary-btn pt-1 pb-1 m-2 mb-3 pr-5 pl-5 shadow-lg "
                            style={{ borderRadius: 10 }}
                            type="submit"
                            disabled={formLoading}
                        >
                            {formLoading ? <Spinner className="mr-4 ml-4 0" color="info" size="sm" /> : <>CONFIRM</>}
                        </motion.button>
                    </div>
                </AvForm>
            </div>
        </div>
    );
}

AddDeliveryAttempt.propTypes = {
    setShowModal: PropTypes.func,
    loadTransactions: PropTypes.func,
    setDeliveryFormData: PropTypes.func,
    data: PropTypes.any,
    updateHistoryWithData: PropTypes.func,
};

export default AddDeliveryAttempt;
