import React from 'react';
import { motion } from 'framer-motion';
import { Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

// #region components | assets
import { DocumentTwo, Settings } from 'components/Icons';

// #region utils
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #region other imports
import PATHS from 'routes/paths';

export default function Header() {
    const history = useHistory();
    const handleOnClickPrinterSettings = () => {
        history.push({
            pathname: `${PATHS.LABEL_PRINT.PRINTER_SETTINGS.DEFAULT}`,
        });
    };

    const handleOnClickNewBatchPrint = () => {
        history.push({
            pathname: `/${PATHS.LABEL_PRINT.SHIPPING_LABEL.BATCH_PRINT_VIEW}`,
        });
    };

    return (
        <Row className="justify-content-between">
            {hasAccess(PERMISSIONS.LABEL_PRINT_SHIPPING_LABEL, ['Print']) && (
                <div className="header-button-left">
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="scootix-btn-radius m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                        onClick={() => handleOnClickNewBatchPrint()}
                    >
                        <DocumentTwo style={{ marginRight: 5 }} />
                        <div>New Batch Print</div>
                    </motion.div>
                </div>
            )}
            <div className="header-button-right">
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="right scootix-btn-radius m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                    onClick={() => handleOnClickPrinterSettings()}
                >
                    <Settings color="#fff" style={{ marginRight: 5 }} /> <div>Printer Settings</div>
                </motion.div>
            </div>
        </Row>
    );
}
