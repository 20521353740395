import * as React from 'react';
import PropTypes from 'prop-types';

function RemoveAll(props) {
    const { color, width, height } = props;
    return (
        <svg
            width={width || 24}
            height={height || 24}
            viewBox="0 2 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M4 7H20" stroke="#FFF8F8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 11V17" stroke="#FFF8F8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 11V17" stroke="#FFF8F8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7"
                stroke={color || '#fff'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                stroke={color || '#fff'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
RemoveAll.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default RemoveAll;
