import { CAR_ICON_IMAGE, SCOOTER_ICON_IMAGE, TRUCK_ICON_IMAGE, VAN_ICON_IMAGE } from 'definitions/assets';

/* eslint-disable no-useless-escape */
export const MALAYSIA_PHONE_LENGTH = 14;

// static coordinate for Kuala Lumpur
export const MAP_CENTER_COORDINATES_KL = [3.140853, 101.693207];

// static coordinate for Colombo
export const MAP_CENTER_COORDINATES_COL = [6.927079, 79.861244];

// TASA Head Office Coordinates
export const TASA_HEAD_OFFICE_COORDINATE = [2.9259734339745505, 101.65454853856532];

export const TEL_NUM_REGEX = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;

export const MALAYSIA_STATES = [
    'Johor',
    'Kedah',
    'Kelantan',
    'Melaka',
    'Negeri Sembilan',
    'Pahang',
    'Perak',
    'Perlis',
    'Pulau Pinang',
    'Sarawak',
    'Selangor',
    'Terengganu',
    'Kuala Lumpur',
    'Labuan',
    'Sabah',
    'Putrajaya',
];

export const MALAYSIA_STATE_CITIES = {
    Johor: [
        'Johor Bahru',
        'Tebrau',
        'Pasir Gudang',
        'Bukit Indah',
        'Skudai',
        'Kluang',
        'Batu Pahat',
        'Muar',
        'Ulu Tiram',
        'Senai',
        'Segamat',
        'Kulai',
        'Kota Tinggi',
        'Pontian Kechil',
        'Tangkak',
        'Bukit Bakri',
        'Yong Peng',
        'Pekan Nenas',
        'Labis',
        'Mersing',
        'Simpang Renggam',
        'Parit Raja',
        'Kelapa Sawit',
        'Buloh Kasap',
        'Chaah',
    ],
    Kedah: [
        'Sungai Petani',
        'Alor Setar',
        'Kulim',
        'Jitra / Kubang Pasu',
        'Baling',
        'Pendang',
        'Langkawi',
        'Yan',
        'Sik',
        'Kuala Nerang',
        'Pokok Sena',
        'Bandar Baharu',
    ],
    Kelantan: [
        'Kota Bharu',
        'Pangkal Kalong',
        'Tanah Merah',
        'Peringat',
        'Wakaf Baru',
        'Kadok',
        'Pasir Mas',
        'Gua Musang',
        'Kuala Krai',
        'Tumpat',
    ],
    Melaka: [
        'Bandaraya Melaka',
        'Bukit Baru',
        'Ayer Keroh',
        'Klebang',
        'Masjid Tanah',
        'Sungai Udang',
        'Batu Berendam',
        'Alor Gajah',
        'Bukit Rambai',
        'Ayer Molek',
        'Bemban',
        'Kuala Sungai Baru',
        'Pulau Sebang',
    ],
    'Negeri Sembilan': ['Seremban', 'Port Dickson', 'Nilai', 'Bahau', 'Tampin', 'Kuala Pilah'],
    Pahang: [
        'Kuantan',
        'Temerloh',
        'Bentong',
        'Mentakab',
        'Raub',
        'Jerantut',
        'Pekan',
        'Kuala Lipis',
        'Bandar Jengka',
        'Bukit Tinggi',
    ],
    Perak: [
        'Ipoh',
        'Taiping',
        'Sitiawan',
        'Simpang Empat',
        'Teluk Intan',
        'Batu Gajah',
        'Lumut',
        'Kampung Koh',
        'Kuala Kangsar',
        'Sungai Siput Utara',
        'Tapah',
        'Bidor',
        'Parit Buntar',
        'Ayer Tawar',
        'Bagan Serai',
        'Tanjung Malim',
        'Lawan Kuda Baharu',
        'Pantai Remis',
        'Kampar',
    ],
    Perlis: ['Kangar', 'Kuala Perlis'],
    'Pulau Pinang': [
        'Bukit Mertajam',
        'Georgetown',
        'Sungai Ara',
        'Gelugor',
        'Ayer Itam',
        'Butterworth',
        'Perai',
        'Nibong Tebal',
        'Permatang Kucing',
        'Tanjung Tokong',
        'Kepala Batas',
        'Tanjung Bungah',
        'Juru',
    ],
    Sabah: [
        'Kota Kinabalu',
        'Sandakan',
        'Tawau',
        'Lahad Datu',
        'Keningau',
        'Putatan',
        'Donggongon',
        'Semporna',
        'Kudat',
        'Kunak',
        'Papar',
        'Ranau',
        'Beaufort',
        'Kinarut',
        'Kota Belud',
    ],
    Sarawak: [
        'Kuching',
        'Miri',
        'Sibu',
        'Bintulu',
        'Limbang',
        'Sarikei',
        'Sri Aman',
        'Kapit',
        'Batu Delapan Bazaar',
        'Kota Samarahan',
    ],
    Selangor: [
        'Subang Jaya',
        'Klang',
        'Ampang Jaya',
        'Shah Alam',
        'Petaling Jaya',
        'Cheras',
        'Kajang',
        'Selayang Baru',
        'Rawang',
        'Taman Greenwood',
        'Semenyih',
        'Banting',
        'Balakong',
        'Gombak Setia',
        'Kuala Selangor',
        'Serendah',
        'Bukit Beruntung',
        'Pengkalan Kundang',
        'Jenjarom',
        'Sungai Besar',
        'Batu Arang',
        'Tanjung Sepat',
        'Kuang',
        'Kuala Kubu Baharu',
        'Batang Berjuntai',
        'Bandar Baru Salak Tinggi',
        'Sekinchan',
        'Sabak',
        'Tanjung Karang',
        'Beranang',
        'Sungai Pelek',
    ],
    Terengganu: ['Kuala Terengganu', 'Chukai', 'Dungun', 'Kerteh', 'Kuala Berang', 'Marang', 'Paka', 'Jerteh'],
    'Wilayah Persekutuan': ['Kuala Lumpur', 'Labuan', 'Putrajaya'],
};

export const currencyCodes = [
    'AED',
    'AFN',
    'ALL',
    'AMD',
    'ANG',
    'AOA',
    'ARS',
    'AUD',
    'AWG',
    'AZN',
    'BAM',
    'BBD',
    'BDT',
    'BGN',
    'BHD',
    'BIF',
    'BMD',
    'BND',
    'BOB',
    'BOV',
    'BRL',
    'BSD',
    'BTN',
    'BWP',
    'BYR',
    'BZD',
    'CAD',
    'CDF',
    'CHE',
    'CHF',
    'CHW',
    'CLF',
    'CLP',
    'CNY',
    'COP',
    'COU',
    'CRC',
    'CUC',
    'CUP',
    'CVE',
    'CZK',
    'DJF',
    'DKK',
    'DOP',
    'DZD',
    'EGP',
    'ERN',
    'ETB',
    'EUR',
    'FJD',
    'FKP',
    'GBP',
    'GEL',
    'GHS',
    'GIP',
    'GMD',
    'GNF',
    'GTQ',
    'GYD',
    'HKD',
    'HNL',
    'HRK',
    'HTG',
    'HUF',
    'IDR',
    'ILS',
    'INR',
    'IQD',
    'IRR',
    'ISK',
    'JMD',
    'JOD',
    'JPY',
    'KES',
    'KGS',
    'KHR',
    'KMF',
    'KPW',
    'KRW',
    'KWD',
    'KYD',
    'KZT',
    'LAK',
    'LBP',
    'LKR',
    'LRD',
    'LSL',
    'LTL',
    'LVL',
    'LYD',
    'MAD',
    'MDL',
    'MGA',
    'MKD',
    'MMK',
    'MNT',
    'MOP',
    'MRO',
    'MUR',
    'MVR',
    'MWK',
    'MXN',
    'MXV',
    'MYR',
    'MZN',
    'NAD',
    'NGN',
    'NIO',
    'NOK',
    'NPR',
    'NZD',
    'OMR',
    'PAB',
    'PEN',
    'PGK',
    'PHP',
    'PKR',
    'PLN',
    'PYG',
    'QAR',
    'RON',
    'RSD',
    'RUB',
    'RWF',
    'SAR',
    'SBD',
    'SCR',
    'SDG',
    'SEK',
    'SGD',
    'SHP',
    'SLL',
    'SOS',
    'SRD',
    'SSP',
    'STD',
    'SYP',
    'SZL',
    'THB',
    'TJS',
    'TMT',
    'TND',
    'TOP',
    'TRY',
    'TTD',
    'TWD',
    'TZS',
    'UAH',
    'UGX',
    'USD',
    'USN',
    'USS',
    'UYI',
    'UYU',
    'UZS',
    'VEF',
    'VND',
    'VUV',
    'WST',
    'XAF',
    'XAG',
    'XAU',
    'XBA',
    'XBB',
    'XBC',
    'XBD',
    'XCD',
    'XDR',
    'XFU',
    'XOF',
    'XPD',
    'XPF',
    'XPT',
    'XTS',
    'XXX',
    'YER',
    'ZAR',
    'ZMW',
];

export const VEHICLE_TYPES = {
    MOTOR_BIKE: 'Motorbike',
    CAR: 'Car',
    VAN_SEVEN_FEET: 'Van7FT',
    TRUCK: 'Truck',
};

export const VEHICLE_IMAGES = {
    Motorbike: SCOOTER_ICON_IMAGE,
    Car: CAR_ICON_IMAGE,
    Van7FT: VAN_ICON_IMAGE,
    Truck: TRUCK_ICON_IMAGE,
};

export const DELIVERY_IMPORT_LOCATION_TYPES = {
    PLACE_ID: 'Place ID',
    MERCHANT_LOCATION_ID: 'Merchant Location ID',
};

export const DELIVERY_TYPES = {
    NEW_ORDER: 'New Order',
    RETURN_ORDER: 'Return Order',
    ON_DEMAND_DELIVERY: 'On-Demand Delivery',
};

export const PRINT_METHODS = {
    QUICK_PRINT: 'QUICK_PRINT',
    BATCH_PRINT: 'BATCH_PRINT',
};

export const PRINT_TYPES = {
    SINGLE: 'SINGLE',
    MULTIPLE: 'MULTIPLE',
};

export const BILLING_METHODS = {
    FIXED_PER_DELIVERY: 'Fixed Per Delivery',
    MILEAGE_BASED_FEE: 'Mileage-Based Fee',
    PERCENTAGE_OF_DELIVERY_FEE: 'Percentage of Delivery Fee',
};

export const TASKS = {
    UPDATE_THE_PICKUP_LOCATION: 'Update the Pickup Location',
    UPDATE_THE_DELIVERY_LOCATION: 'Update the Delivery Location',
    UPDATE_THE_VEHICLE_TYPE: 'Update the Vehicle Type',
};

export const RIDER_STATUS_TYPES = {
    ONLINE: 'online',
    OFFLINE: 'offline',
};
