/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Modal, ModalBody, ModalHeader, Alert } from 'reactstrap';

function ImportExampleModal(props) {
    const { importConfiguration = {}, toggleModal, header = '', text = '' } = props;

    const [alertWidth, setAlertWidth] = useState();

    const ref = useRef(null);

    useEffect(() => {
        if (ref.current && ref.current.offsetWidth) {
            setAlertWidth(ref.current.offsetWidth);
        }
    }, [ref.current]);

    return (
        <>
            <Modal size="xl" isOpen toggle={toggleModal} center className="modal-dialog modal-dialog-centered">
                <ModalHeader toggle={toggleModal}>{header}</ModalHeader>
                <ModalBody style={{ overflowY: 'scroll', minHeight: 230 }}>
                    <Alert
                        color="info"
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            width: alertWidth ? alertWidth - 15 : '100%',
                        }}
                    >
                        <i className="fas fa-info-circle mr-3 mt-2 fa-2x" />
                        <div className="mt-2" style={{ fontSize: 15, fontWeight: 'bold' }}>
                            {' '}
                            {text}{' '}
                        </div>
                    </Alert>
                    <br />
                    <div>
                        <Table>
                            <thead>
                                <tr ref={ref}>
                                    {Object.keys(importConfiguration.fieldNames).map((key) => (
                                        <th
                                            style={{
                                                border: '1px solid black',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    // minWidth: 150,
                                                    minWidth: 'min-content',
                                                    maxWidth: 400,
                                                    width: 'max-content',
                                                    textAlign: 'center',
                                                    // border: '1px solid black',
                                                }}
                                            >
                                                {importConfiguration.fieldNames[key].value}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                        </Table>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

ImportExampleModal.propTypes = {
    toggleModal: PropTypes.func,
    importConfiguration: PropTypes.object,
    header: PropTypes.string,
    text: PropTypes.string,
};

export default ImportExampleModal;
