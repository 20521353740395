import React from 'react';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';

// #region utils
import { reactSelectCustomStyles } from 'utils/helpers';

/**
 * Handles React Select in lazy loading Mode
 * When scroll down it loads more data
 * @param {*} props
 */
const ReactSelectAsyncPaginate = (props) => {
    const { required = false, isClearable = false, value, onChange, loadOptions } = props;

    // JSX
    return (
        <div>
            <AsyncPaginate
                {...props}
                value={value}
                loadOptions={loadOptions}
                onChange={onChange}
                style={reactSelectCustomStyles}
                isClearable={isClearable}
                required={required}
                /** Fixed Configs */
                defaultOptions
                cacheOptions={false}
                additional={{
                    page: 1,
                }}
                debounceTimeout={500}
                isSearchable
            />
        </div>
    );
};

// Component Prop Types
ReactSelectAsyncPaginate.propTypes = {
    onChange: PropTypes.func,
    required: PropTypes.bool,
    isClearable: PropTypes.bool,
    value: PropTypes.any,
    loadOptions: PropTypes.func,
};

export default ReactSelectAsyncPaginate;
