/* eslint-disable consistent-return */

import React from 'react';
import moment from 'moment';
import Avatar from 'react-avatar';
import { motion } from 'framer-motion';
import { Input } from 'reactstrap';

// #region components
import ExternalLinkIcon from 'components/Icons/ExternalLink';

// #region imports
import paths from 'routes/paths';
import { PARTNER_API_SANDBOX_ACCOUNT } from 'enums/partnerApi/partnerApi.enum';

// #region utils
import { formatter, truncateString } from 'utils/helpers';
import { convertDeliveryStatusToNormalText } from 'utils/constants';
import { hasAccess } from 'utils/checkAuth';

export const applyFormats = (column, header, row, history, tablePermissions = {}, handlers) => {
    let value = column;

    if (header.style && Array.isArray(header.style) && header.style.includes('long-text')) {
        if (value && Array.isArray(value) && value.length > 30) {
            value = truncateString(value, 50);
        }
    }

    if (header.type === 'date') {
        return value ? moment(value).format('DD-MM-YYYY') : '';
    }
    if (header.type === 'time') {
        return value ? moment(value).format('hh:mm a') : '';
    }

    if (header.type === 'currency') {
        return value ? formatter.format(value) : '';
    }

    if (header.type === 'dateTime') {
        return value ? moment(value).format('DD-MM-YYYY hh:mm A') : '';
    }

    if (header.type === 'UPPERCASE') {
        if (value) {
            return value.toUpperCase();
        }
        return '';
    }

    if (header.type === 'billingSettingVehicleType') {
        if (value) {
            return (
                <>
                    {value}
                    {renderBillingSettingActive(row)}
                </>
            );
        }
        return '';
    }

    if (header.type === 'pickingTaskStatus') {
        if (value === 'pending') {
            return <div className="picking-status-table-pending">{value.toUpperCase()}</div>;
        }
        if (value === 'completed') {
            return <div className="picking-status-table-completed">{value.toUpperCase()}</div>;
        }
        return <div>{value ? value.toUpperCase() : ''}</div>;
    }

    if (header.isAvatar) {
        if (!header.isImage) {
            return (
                <div>
                    <Avatar name={value} size="25" round="50px" colors={['#1e6262', '#581b98', '#364f6b']} />
                    {value}
                </div>
            );
        }
        if (header.isImage && row[header.imageProp]) {
            return renderAvatarImage(row[header.imageProp], value);
        }
    }

    if (header.type === 'viewDeliveryNavigation') {
        return (
            <div className="d-flex align-items-center">
                {value}
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handlers.onViewDelivery(row)}
                >
                    <ExternalLinkIcon color="#f58120" />
                </motion.div>
            </div>
        );
    }

    if (header.type === 'userObject') {
        const user = value;
        return `${user ? user.fullName || `${user.firstName || ''} ${user.lastName || ''}` || '' : ''}`;
    }

    if (header.type === 'deliveryManagePendingData') {
        return renderDeliveryManagePendingData(value, history, tablePermissions, row);
    }

    if (header.type === 'restoreButton') {
        return restoreButton(row, tablePermissions, handlers);
    }

    if (header.type === 'permanentDeleteButton') {
        return permanentDeleteButton(row, tablePermissions, handlers);
    }

    if (header.type === 'deliveryStatus') {
        return renderDeliveryStatus(value, row);
    }

    if (header.type === 'downloadButton') {
        return downloadButton(row, tablePermissions, handlers);
    }
    if (header.type === 'attachmentViewBtn') {
        return AttachmentViewButton(row, tablePermissions, handlers);
    }
    if (header.type === 'attachmentRestoreBtn') {
        return AttachmentRestoreButton(row, tablePermissions, handlers);
    }

    if (header.type === 'viewMerchantButton') {
        return viewMerchantButton(row, tablePermissions, handlers);
    }

    if (header.type === 'statusLabel') {
        return statusLabel(row, header.key, header.params || {});
    }

    if (header.type === 'quickActionButton') {
        return quickActionButton(row, handlers, tablePermissions);
    }

    if (header.type === 'customActionButton') {
        return customActionButton(row, handlers, tablePermissions, header.params || {});
    }

    if (header.type === 'apiStatusLabel') {
        return apiStatusLabel(row, header.key, header.params || {});
    }

    if (header.type === 'requestStatusLabel') {
        return requestStatusLabel(row, header.key, header.params || {});
    }

    if (header.type === 'routePlanningStatusLabel') {
        return routePlanningStatusLabel(row, header.key, header.params || {});
    }

    // This is used when we need to add route planning sequence number according to the planning (White number with orange background)
    if (header.type === 'planSequenceNo') {
        return routePlanNumberLabel(row, header.key, header.params || {});
    }

    if (header.type === 'routePlanningEntryViewStatusLabel') {
        return routePlanningEntryViewStatusLabel(row, header.key, header.params || {});
    }

    if (header.type === 'allCapsToHumanReadable') {
        return allCapsToHumanReadable(row, header.key, header.params || {});
    }

    return value;
};

const renderDeliveryManagePendingData = (id, history, tablePermissions, row) => {
    const data = row;

    // PENDING RECORDS wont be able to be managed
    if (data.status === 'PENDING') {
        return <div></div>;
    }

    /**
     * Only when `pendingDataId` is present and table permission is given this btn will be displayed
     * below `id` refers to `pendingDataId`
     */
    if (id && tablePermissions.showManageDeliveryImportExcelCsv) {
        return (
            <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 mr-5"
                onClick={() => {
                    if (history) {
                        history.push({
                            pathname: `${paths.PENDING_DATA.DELIVERY_VIEW}`,
                            search: `?id=${id}`,
                        });
                    }
                }}
            >
                Manage
            </motion.button>
        );
    }
    return <div />;
};

const restoreButton = (row, tablePermissions, handlers) => {
    if (row && tablePermissions.showRestore) {
        return (
            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={{ minWidth: 140 }}
                className="table-restore-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 btn"
                onClick={() => {
                    if (handlers?.onRestore) {
                        handlers.onRestore(row);
                    }
                }}
            >
                <i className="fas fa-trash-restore left-icon fa-lg mr-3 mt-1" />
                Restore
            </motion.div>
        );
    }
    return <div />;
};

const permanentDeleteButton = (row, tablePermissions, handlers) => {
    if (row && tablePermissions.showPermanentDelete) {
        return (
            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={{ minWidth: 220, backgroundColor: 'background: #3A3937' }}
                className="form-delete-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 btn"
                onClick={() => {
                    if (handlers?.onPermanentDelete) {
                        handlers.onPermanentDelete(row);
                    }
                }}
            >
                <i className="fas fa-trash left-icon fa-lg mr-3 mt-1" />
                Permanent Delete
            </motion.div>
        );
    }
    return <div />;
};

const AttachmentViewButton = (row, tablePermissions, handlers) => {
    if (row && tablePermissions.showAttachmentView) {
        return (
            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={{ minWidth: 220 }}
                className="form-delete-btn m-1 mb-1 shadow-lg nw-md pr-4 pl-4 btn"
                onClick={() => {
                    if (handlers?.onAttachmentView) {
                        handlers.onAttachmentView(row);
                    }
                }}
            >
                View
            </motion.div>
        );
    }
    return <div />;
};

const AttachmentRestoreButton = (row, tablePermissions, handlers) => {
    if (row && tablePermissions.showAttachmentRestore) {
        return (
            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={{ minWidth: 220 }}
                className="form-delete-btn m-1 mb-1 shadow-lg nw-md pr-4 pl-4 btn"
                onClick={() => {
                    if (handlers?.onAttachmentRestore) {
                        handlers.onAttachmentRestore(row);
                    }
                }}
            >
                Restore
            </motion.div>
        );
    }
    return <div />;
};

const renderDeliveryStatus = (value, row) => {
    if (value) {
        return (
            <div
                className="p-3"
                style={{
                    backgroundColor: '#EDFFFD',
                    minWidth: 'min-content',
                    maxWidth: 200,
                    width: 'max-content',
                    borderRadius: 15,
                    fontWeight: 'bold',
                }}
            >
                {convertDeliveryStatusToNormalText(value, row)}
            </div>
        );
    }
    return <div />;
};

const renderAvatarImage = (imageUrl, name) => (
    <div className="d-flex row" style={{ alignContent: 'center', alignItems: 'center' }}>
        <Avatar
            name={name || ''}
            src={imageUrl || null}
            size="25"
            round="50px"
            colors={['#1e6262', '#581b98', '#364f6b']}
        />{' '}
        <div className="ml-3">{name}</div>
    </div>
);

const renderBillingSettingActive = (data) => {
    let isActive = false;

    const { validFrom, validTo } = data;

    const currentDate = moment;

    if (currentDate().isBefore(moment(validTo)) && currentDate().isAfter(moment(validFrom))) {
        isActive = true;
    }

    if (isActive) {
        return (
            <div
                className="d-flex row"
                style={{
                    alignContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#17b978',
                    width: 100,
                    borderRadius: 20,
                    justifyContent: 'center',
                }}
            >
                <div style={{ textAlign: 'center', color: '#FFF', fontWeight: 'bold' }}>Active</div>
            </div>
        );
    }
    return <div />;
};

const downloadButton = (row, tablePermissions, handlers) => {
    if (row && tablePermissions.showDownload) {
        return (
            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={{ minWidth: 220 }}
                className="form-download-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 btn"
                onClick={() => {
                    if (handlers?.onDownload) {
                        handlers.onDownload(row);
                    }
                }}
            >
                <i className="fas fa-download left-icon fa-lg mr-3 mt-1" />
                Download
            </motion.div>
        );
    }
    return <div />;
};

/**
 * Render Check Box
 * @param {*} row
 * @param {*} handlers
 * @returns
 */
export const renderCheck = (data, handleOnSelect, styles = {}) => {
    if (!data) return <div />;
    const checkedRecords = Array.isArray(data) && data.filter((x) => x.isSelected);
    const isChecked = !Array.isArray(data) ? data.isSelected : checkedRecords.length === data.length;
    return (
        <Input
            type="checkbox"
            onChange={(e) => handleOnSelect(data, e.target.checked)}
            id={Array.isArray(data) ? 'select-add' : data._id}
            style={{ ...styles, width: 15, height: 15, position: 'relative' }}
            checked={isChecked || false}
            value={isChecked || false}
            className="table-check"
        />
    );
};

const viewMerchantButton = (row, tablePermissions, handlers) => {
    // by default set this permission 'true'
    const { showViewMerchantProfileButton = true } = tablePermissions;
    if (row && showViewMerchantProfileButton) {
        return (
            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="scootix-btn-radius d-flex justify-content-center align-content-center align-items-center"
                style={{ borderRadius: 5 }}
                onClick={() => handlers.onViewMerchantProfile(row)}
            >
                <div style={{ marginBottom: 5, marginRight: 5 }}>
                    <ExternalLinkIcon />
                </div>
                {'  '}
                <span>VIEW MERCHANT</span>
            </motion.div>
        );
    }
    return <div />;
};

const statusLabel = (row, key, params) => {
    const { shape = 'pill', activeLabel = 'Active', inactiveLabel = 'Inactive' } = params;
    if (row) {
        return (
            <motion.div
                className="d-flex row shadow-lg"
                style={{
                    alignContent: 'center',
                    alignItems: 'center',
                    backgroundColor: row[key] === 'active' ? '#26C60C' : '#A39D90',
                    width: 110,
                    borderRadius: shape === 'pill' ? 20 : 5,
                    justifyContent: 'center',
                    padding: 5,
                }}
            >
                <div style={{ textAlign: 'center', color: '#FFF', fontWeight: 'bold' }}>
                    {row[key] === 'active' ? activeLabel : inactiveLabel}
                </div>
            </motion.div>
        );
    }
    return <div />;
};

// TODO: FIX ME MAKE sure this is not used for others and make better
const quickActionButton = (row, handlers, tablePermissions) => {
    if (row && tablePermissions.showQuickAction) {
        return (
            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                role="button"
                className="d-flex row shadow-lg"
                onClick={() => handlers.onQuickAction(row)}
                style={{
                    alignContent: 'center',
                    alignItems: 'center',
                    backgroundColor: row.serviceStatusClient ? '#FD4A4A' : '#26C60C',
                    width: 100,
                    borderRadius: 20,
                    justifyContent: 'center',
                    padding: 5,
                }}
            >
                <div style={{ textAlign: 'center', color: '#FFF', fontWeight: 'bold' }}>
                    {row.serviceStatusClient ? 'Disable' : 'Enable'}
                </div>
            </motion.div>
        );
    }

    return <div />;
};

/**
 * The params.displayCondition property is a callback function.
 * The custom action button can be shown conditionally by using this callback.
 * If displayCondition(row) returns false, the button will not be shown.
 *
 * The params.customActionHandlerName is the name of the handler that will run
 * when this custom button is clicked. The function with this name should be passed
 * to the table component via the customActionHandlers prop.
 */
const customActionButton = (row, handlers, tablePermissions, params) => {
    const {
        buttonLabel = 'Manage',
        displayCondition,
        customActionHandlerName,
        permissionType,
        permissions,
        btnBgColor = '#3A3937',
    } = params;

    if (!row) return <div />;
    if (displayCondition && !displayCondition(row)) return <div />;

    const clickHandler = customActionHandlerName && handlers ? handlers[customActionHandlerName] : undefined;

    // whether can access button
    let userHasAccess = false;

    // based on original role permission
    if (permissionType && permissions && Array.isArray(permissions)) {
        userHasAccess = hasAccess(permissionType, permissions);
    } else if (
        tablePermissions.showApiCustomAction ||
        tablePermissions.showApiBasicSettingsAction ||
        tablePermissions.showApiWebhookAction ||
        tablePermissions.showTransactionDataRecords
    ) {
        userHasAccess = true;
    }

    if (row && userHasAccess) {
        return (
            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                role="button"
                className="d-flex row shadow-lg"
                onClick={clickHandler ? () => clickHandler(row) : undefined}
                style={{
                    alignContent: 'center',
                    alignItems: 'center',
                    backgroundColor: btnBgColor,
                    width: 100,
                    borderRadius: 20,
                    justifyContent: 'center',
                    padding: 5,
                }}
            >
                <div style={{ textAlign: 'center', color: '#FFF', fontWeight: 'bold' }}>{buttonLabel}</div>
            </motion.div>
        );
    }
};

/**
 * apiStatusLabel is the label that related to the API requests' status
 * @param {*} row - This is the current row
 * @param {*} key - This is the current header key
 * @param {*} params - This is label customization and we can apply button shape here
 * @returns the apiStatusLabel
 */
const apiStatusLabel = (row, key, params) => {
    const { shape = 'pill' } = params;
    if (row) {
        return (
            <div
                className="d-flex row shadow-lg"
                style={{
                    alignContent: 'center',
                    alignItems: 'center',
                    backgroundColor: row[key].toLowerCase() === 'success' ? '#26C60C' : '#FF2825',
                    width: 100,
                    borderRadius: shape === 'pill' ? 20 : 5,
                    justifyContent: 'center',
                    padding: 5,
                }}
            >
                <div style={{ textAlign: 'center', color: '#FFF', fontWeight: 'bold' }}>{row[key]}</div>
            </div>
        );
    }
    return <div />;
};

const requestStatusLabel = (row, key, params) => {
    const { shape = 'pill' } = params;
    const { REQUEST_STATUS } = PARTNER_API_SANDBOX_ACCOUNT;

    const { requestStatus } = row;

    let backgroundColor = '#A39D90';
    let value = 'Action Pending';

    const { COMPLETED, PENDING, REJECTED } = REQUEST_STATUS;
    if (requestStatus === PENDING) {
        value = 'Action Pending';
    }
    if (requestStatus === REJECTED) {
        backgroundColor = '#FF2825';
        value = 'Rejected';
    }
    if (requestStatus === COMPLETED) {
        backgroundColor = '#26C60C';
        value = 'Completed';
    }

    if (row) {
        return (
            <motion.div
                className="d-flex row shadow-lg"
                style={{
                    alignContent: 'center',
                    alignItems: 'center',
                    backgroundColor,
                    width: 120,
                    borderRadius: shape === 'pill' ? 20 : 5,
                    justifyContent: 'center',
                    padding: 4,
                }}
            >
                <div style={{ textAlign: 'center', color: '#FFF', fontWeight: 'bold' }}>{value}</div>
            </motion.div>
        );
    }
    return <div />;
};

const routePlanningStatusLabel = (row, key, params) => {
    const { shape = 'pill' } = params;
    const { isCancelled, isCompleted } = row;

    let backgroundColor = '#A39D90';
    let value = 'Pending';

    if (isCancelled) {
        backgroundColor = '#FF2825';
        value = 'Cancelled';
    }
    if (isCompleted) {
        backgroundColor = '#26C60C';
        value = 'Completed';
    }

    if (row) {
        return (
            <motion.div
                className="d-flex row shadow-lg"
                style={{
                    alignContent: 'center',
                    alignItems: 'center',
                    backgroundColor,
                    width: 120,
                    borderRadius: shape === 'pill' ? 20 : 5,
                    justifyContent: 'center',
                    padding: 4,
                }}
            >
                <div style={{ textAlign: 'center', color: '#FFF', fontWeight: 'bold' }}>{value}</div>
            </motion.div>
        );
    }
    return <div />;
};

const routePlanningEntryViewStatusLabel = (row, key, params) => {
    const { shape = 'pill' } = params;
    const { isPickedUp, isPickUpStarted, isCompleted, routeType } = row;

    let backgroundColor = '#A39D90';
    let value = 'Pending';

    if (routeType === 'Pickup' && isPickUpStarted && !isPickedUp) {
        backgroundColor = '#FDBA24';
        value = 'Pickup Started';
    }
    if (routeType === 'Delivery' && isPickedUp && !isCompleted) {
        backgroundColor = '#FDBA24';
        value = 'Delivering';
    }
    if ((routeType === 'Delivery' && isCompleted) || (routeType === 'Pickup' && isPickedUp)) {
        backgroundColor = '#0F891C';
        value = 'Completed';
    }

    if (row) {
        return (
            <motion.div
                className="d-flex row shadow-lg"
                style={{
                    alignContent: 'center',
                    alignItems: 'center',
                    backgroundColor,
                    width: 120,
                    borderRadius: shape === 'pill' ? 20 : 5,
                    justifyContent: 'center',
                    padding: 4,
                }}
            >
                <div style={{ textAlign: 'center', color: '#FFF', fontWeight: 'bold' }}>{value}</div>
            </motion.div>
        );
    }
    return <div />;
};

/**
 *  Plan numbering label in a single route planning (delivery pickup or delivery order sequence number)
 */
const routePlanNumberLabel = (row, params) => {
    const { shape = 'round' } = params;
    const { plan } = row;
    const backgroundColor = '#FF9300';

    if (row) {
        return (
            <motion.div
                className="d-flex row shadow-lg"
                style={{
                    alignContent: 'center',
                    alignItems: 'center',
                    backgroundColor,
                    width: 30,
                    borderRadius: shape === 'round' ? 15 : 5,
                    justifyContent: 'center',
                    padding: 4,
                    marginLeft: 2,
                }}
            >
                <div style={{ textAlign: 'center', color: 'white', fontWeight: 'bold' }}>{plan}</div>
            </motion.div>
        );
    }
    return <div />;
};

/**
 * Converts snake cased all caps string to human readable format
 * Ex: `FOO_BAR` into `Foo Bar`
 * @param {object} row - Data row
 * @param {string} key - Column name
 * @returns human readable string
 */
const allCapsToHumanReadable = (row, key) => {
    if (!row || !key || !(key in row)) {
        return '';
    }

    const text = row[key];

    if (text == null) {
        return '';
    }

    return text
        .split('_')
        .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
        .join(' ');
};
