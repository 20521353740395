import { getSessionStorageItem, removeSessionStorageItem, storeSessionStorageItem } from 'utils/session-storage';

export const buildUrlSearchParams = (key, newParams = []) => {
    let urlSearchParams = '?';

    const updatedParams = [];
    try {
        let existingParams = getSessionStorageItem(key);

        if (existingParams && !Array.isArray(existingParams)) {
            removeSessionStorageItem(key);
            existingParams = [];
        }

        if (!existingParams) {
            existingParams = [];
        }

        newParams.map((val) => {
            const isExist = existingParams.find((x) => x.key === val.key);
            if (isExist) {
                updatedParams.push(val);
            }
            return val;
        });

        newParams.map((val) => {
            const isExist = existingParams.find((x) => x.key === val.key);
            if (!isExist) {
                updatedParams.push(val);
            }
            return val;
        });

        existingParams.map((val) => {
            const isExist = newParams.find((x) => x.key === val.key);
            if (!isExist) {
                updatedParams.push(val);
            }
            return val;
        });

        // console.log('updatedParams', updatedParams);
        storeSessionStorageItem(key, updatedParams);

        updatedParams.map((val, index) => {
            urlSearchParams = urlSearchParams.concat(`${index > 0 ? '&' : ''}${val.key}=${val.value}`);
            return val;
        });
    } catch (e) {
        console.log(e);
        urlSearchParams = urlSearchParams.concat(`${newParams[0].key}=${newParams[0].value}`);
    }
    // console.log('urlSearchParams', urlSearchParams);

    return urlSearchParams;
};

export const transformQsSearchToArray = (qsParseObject) => {
    const updatedArray = [];
    try {
        Object.keys(qsParseObject).forEach((key) => {
            updatedArray.push({ key, value: qsParseObject[key] });
        });
    } catch (e) {
        console.log(e);
    }
    return updatedArray;
};

export const removeParamKeyFromUrlSearchParams = (key, paramKey) => {
    let updatedParams = [];
    try {
        let existingParams = getSessionStorageItem(key);

        if (existingParams && !Array.isArray(existingParams)) {
            removeSessionStorageItem(key);
            existingParams = [];
        }

        if (!existingParams) {
            existingParams = [];
        }
        updatedParams = existingParams.filter((x) => x.key !== paramKey);
        storeSessionStorageItem(key, updatedParams);
    } catch (e) {
        console.log(e);
    }
};
