import { DELIVERY_IMPORT_LOCATION_TYPES } from './constants';

export const deliveryImportLocationTypes = [
    {
        key: DELIVERY_IMPORT_LOCATION_TYPES.MERCHANT_LOCATION_ID,
        value: DELIVERY_IMPORT_LOCATION_TYPES.MERCHANT_LOCATION_ID,
    },
    {
        key: DELIVERY_IMPORT_LOCATION_TYPES.PLACE_ID,
        value: DELIVERY_IMPORT_LOCATION_TYPES.PLACE_ID,
    },
];

export const batchPrintSizes = [
    {
        key: 'a4_210x297mm',
        height: 297,
        width: 210,
        pageType: 'A4',
        default: true,
        unit: 'mm',
        label: 'A4 ( 210 x 297 mm)',
        printerType: 'General Printer',
    },
    {
        key: 'a6_105x148mm',
        height: 148,
        width: 105,
        pageType: 'A6',
        unit: 'mm',
        label: 'A6 (105 x 148 mm)',
        printerType: 'General Printer',
    },
    {
        key: 'a6_100x150mm',
        height: 150,
        width: 100,
        pageType: 'A6',
        unit: 'mm',
        label: 'A6 (100 x 150 mm)',
        printerType: 'General Printer',
    },
    {
        key: 'a5_148x210mm',
        height: 297,
        width: 210,
        pageType: 'A5',
        unit: 'mm',
        label: 'A5 (148 x 210 mm)',
        printerType: 'General Printer',
    },
    {
        key: 'brother_ql_800_60.96x88.9mm',
        height: 88.9,
        width: 60.96,
        pageType: 'A6',
        unit: 'mm',
        label: 'Brother QL-800 (60.96 x 88.9 mm)',
        printerType: 'Label Printer',
    },
];

export const defaultScootixModalState = {
    show: false,
    title: null,
    message: 'Are you sure?',
    confirmMessage: 'Accept',
    cancelMessage: 'Cancel',
    onConfirm: null,
    onCancel: null,
    data: null,
};
