/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { motion } from 'framer-motion';
import moment from 'moment';
import toast from 'react-hot-toast';

// #region components
import { CustomToast, Select } from 'components';

// #region services
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';

// #region utils
import { getDefaultValueForSelect, reactSelectCustomStyles, getDateValue } from 'utils/helpers';
import { getVisitingObject } from 'utils/checkAuth';

// #endregion imports
import CustomizableButton from './CustomizableButton';
import tickIcon from '../../../../../../assets/images/developer/FrametickButton.svg';
import cancelIcon from '../../../../../../assets/images/developer/Framecancel.svg';

let initialFormValues = {
    country: 'Malaysia',
};
function CustomPeriodSection(props) {
    const {
        title = '',
        handleGenerateReport,
        handleClose,
        initialFormData = {},
        isShowCancelButton = false,
        isClientDashboard = false,
    } = props;

    const cardId = `${title.toLowerCase().replace(/ /g, '')}-stat-card`;

    // Form
    // eslint-disable-next-line no-unused-vars
    const [submitted, setSubmitted] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [validated, setValidated] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [formData, setFormData] = useState(initialFormData);

    const [merchants, setMerchants] = useState([]);

    const { visitingClient, visitingMerchant } = getVisitingObject();

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
            role: 'merchantUser',
        };
    }

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    const handleSubmit = async (event, errors = []) => {
        setSubmitted(true);
        event.persist();

        if (errors.length > 0) {
            setValidated(true);
            return;
        }

        const invalidFromDate = calculateFromDateValidation();
        if (invalidFromDate) {
            toast.custom((t) => <CustomToast text="Please select a valid date range" t={t} type="error" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);

        await handleGenerateReport(formData);

        setValidated(false);
        setSubmitted(false);
        setFormLoading(false);
        // handleClose();
    };

    const handleDateChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value ? new Date(value).toISOString() : null,
        });
    };

    function calculateFromDateValidation() {
        const monthDifference = moment(formData.toDateTime).diff(moment(formData.fromDateTime), 'months', true);

        if (monthDifference >= 6) {
            return true;
        }

        if (formData.fromDateTime === formData.toDateTime) {
            return true;
        }

        if (moment(formData.fromDateTime).isAfter(formData.toDateTime)) {
            return true;
        }

        return false;
    }

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        if (event) {
            if (id === 'merchantId') {
                const relevantMerchant = merchants.find((x) => x._id === event.value);
                if (relevantMerchant) {
                    setFormData({
                        ...formData,
                        merchantName: relevantMerchant.name || '',
                        [id]: event ? event.value : null,
                    });
                    return;
                }
            }

            setFormData({
                ...formData,
                [id]: event ? event.value : null,
            });
        } else {
            setFormData({
                ...formData,
                [id]: null,
            });
        }
    };

    return (
        <div>
            <AvForm onSubmit={handleSubmit}>
                <Card className="shadow" id={cardId} style={{ borderRadius: 10, backgroundColor: '#FFF' }}>
                    <CardBody>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'left',
                            }}
                        >
                            <div className="ml-4">
                                <h6
                                    className="text-truncate mb-2 font-weight-bold"
                                    style={{ color: '#807F7D', fontSize: '16px' }}
                                >
                                    Generate Overview Report for a Custom Period
                                </h6>
                            </div>
                            <Row className="ml-1">
                                {isShowCancelButton ? (
                                    <>
                                        <Col className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                            <hr className="ml-2 mr-4" />
                                        </Col>
                                        <motion.div
                                            whileHover={{ scale: 1.04 }}
                                            whileTap={{ scale: 0.95 }}
                                            className="d-flex justify-content-center cursor-pointer"
                                            style={{
                                                width: 43,
                                                height: 43,
                                                borderRadius: 26.5,
                                                backgroundColor: '#FD4A4A',
                                                marginTop: -10,
                                                padding: 10,
                                            }}
                                            onClick={() => handleClose()}
                                        >
                                            <img src={cancelIcon} alt="cancel" />
                                        </motion.div>
                                    </>
                                ) : (
                                    <Col className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <hr className="ml-2 mr-4" />
                                    </Col>
                                )}
                            </Row>
                        </div>
                        <Row className="pl-4 pr-4" style={{ alignItems: 'center' }}>
                            {isClientDashboard && (
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="merchantId">Merchant</Label>
                                        <Select
                                            value={
                                                getDefaultValueForSelect(getMerchantSelect(formData.merchantId)) || {
                                                    label: 'All',
                                                    value: 'All',
                                                }
                                            }
                                            options={merchants}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'merchantId')}
                                            submitted={submitted}
                                            validated={validated}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                            )}
                            <Col md="3">
                                <FormGroup>
                                    <Label htmlFor="fromDateTime">From Date and Time</Label>
                                    <Input
                                        onChange={handleDateChange}
                                        type="datetime-local"
                                        defaultValue={getDateValue(new Date(), 'datetime-local')}
                                        className="form-control"
                                        value={getDateValue(formData.fromDateTime, 'datetime-local')}
                                        id="fromDateTime"
                                        invalid={calculateFromDateValidation()}
                                        max={getDateValue(moment(formData.toDateTime), 'datetime-local')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Label htmlFor="toDateTime">To Date and Time</Label>
                                    <Input
                                        onChange={handleDateChange}
                                        type="datetime-local"
                                        defaultValue={getDateValue(new Date(), 'datetime-local')}
                                        className="form-control"
                                        value={getDateValue(formData.toDateTime, 'datetime-local')}
                                        id="toDateTime"
                                        // required={!formData.isDeliveryReadyDateTime}
                                        // valid={!!formData.isDeliveryReadyDateTime}
                                        // invalid={
                                        //     !formData.isDeliveryReadyDateTime &&
                                        //     !formData.toDateTime
                                        // }
                                        max={getDateValue(moment(), 'datetime-local')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col className="col-lg-2 col-md-2 col-sm-2 col-xs-2 ml-3 mt-2">
                                <CustomizableButton
                                    icon={tickIcon}
                                    buttonTitle="Generate Report"
                                    loading={formLoading}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </AvForm>
        </div>
    );
}

CustomPeriodSection.propTypes = {
    title: PropTypes.string,
    handleGenerateReport: PropTypes.func,
    initialFormData: PropTypes.object,
    handleClose: PropTypes.func,
    isShowCancelButton: PropTypes.bool,
    isClientDashboard: PropTypes.bool,
};

export default CustomPeriodSection;
