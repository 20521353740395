export const pickingTaskOrders = {
    columns: [
        {
            text: 'Order Reference',
            dataField: 'referenceNumber',
            sort: 'asc',
            width: 150,
            editable: false,
        },
        // {
        //     text: 'Merchant Name',
        //     dataField: 'merchantName',
        //     sort: 'asc',
        //     width: 150,
        //     editable: false,
        // },

        {
            text: 'Recipient',
            dataField: 'recipientName',
            sort: 'asc',
            width: 100,
            editable: false,
        },
        {
            text: 'Location',
            dataField: 'pickupLocation',
            sort: 'asc',
            width: 200,
            editable: false,
        },
        {
            text: 'Order Date',
            dataField: 'placedDateTimeFormatted',
            sort: 'asc',
            width: 150,
            editable: false,
        },
    ],
};

export const pickingTaskOrderItemsColumns = [
    {
        text: 'Product Description',
        dataField: 'productMasterDataDescription',
        sort: 'asc',
        editable: false,
        width: 150,
    },
    {
        text: 'SKU Code',
        dataField: 'skuCode',
        sort: 'asc',
        editable: false,
        width: 150,
    },
    // ...(DEV_ENV() && {
    //     text: 'Total Quantity',
    //     dataField: 'totalQuantity',
    //     sort: 'asc',
    //     editable: false,
    //     width: 100,
    // }),
    {
        text: 'Quantity',
        dataField: 'quantity',
        sort: 'asc',
        editable: false,
        width: 100,
    },
    {
        text: 'Remaining Qty',
        dataField: 'remainingQty',
        sort: 'asc',
        editable: false,
        width: 100,
    },
    {
        text: 'Unit Of Measure',
        dataField: 'unitOfMeasure',
        sort: 'asc',
        editable: false,
        width: 100,
    },
];
