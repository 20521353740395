export const defaultTableHeaders = [
    {
        key: 'fileImportId',
        value: 'File Import ID',
    },
    {
        key: 'importedFileName',
        value: 'File Name',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
    },
    {
        key: 'merchantLocation',
        value: 'Merchant Location',
    },
    {
        key: 'createdAt',
        value: 'Imported Date and Time',
        type: 'dateTime',
    },
    {
        key: 'createdBy',
        value: 'Imported By User',
        type: 'userObject',
    },
    {
        key: 'successRecordsLength',
        value: 'Records Success',
    },
    {
        key: 'errorRecordsLength',
        value: 'Records Fail',
    },
    {
        key: 'status',
        value: 'Status',
    },
    {
        key: 'pendingDataId',
        value: 'Manage',
        type: 'deliveryManagePendingData',
    },
    {
        key: 'pendingDataId',
        value: 'Download',
        type: 'downloadButton',
        customHeaderStyles: {
            justifyContent: 'center',
            display: 'flex',
        },
    },
];
export const tableDefaultFilters = [
    {
        label: 'File Import Id',
        key: 'fileImportId',
    },
    {
        label: 'File Name',
        key: 'importedFileName',
    },
    {
        label: 'Merchant',
        key: 'merchantName',
    },
    {
        label: 'Merchant Location',
        key: 'merchantLocationMeta.address',
    },
    {
        label: 'Imported by User',
        key: 'createdBy.fullName',
    },
    {
        label: 'Status',
        key: 'status',
    },
];
