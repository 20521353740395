/* eslint-disable indent */
/* eslint-disable camelcase */
import {
    GET_PRODUCT_MASTER_DATA,
    GET_PRODUCT_MASTER_DATA_BY_ID,
    GET_ALL_PRODUCT_MASTER_DATA,
    GET_PRODUCT_MASTER_DATA_DELETED,
    GET_ALL_PRODUCT_MASTER_DATA_BY_MERCHANT,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Product Master Data Service
 * @returns {object}
 */
export const getProductMasterDataService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_PRODUCT_MASTER_DATA}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Product Master Data Deleted Service
 * @returns {object}
 */
export const getProductMasterDataDeletedService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_PRODUCT_MASTER_DATA_DELETED}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Product Master Data By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getProductMasterDataByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_PRODUCT_MASTER_DATA_BY_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Product Master Data
 * @returns {Promise}
 */
export const getAllProductMasterDataService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_PRODUCT_MASTER_DATA).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Product Master Data By Merchant
 * @returns {Promise}
 */
export const getAllProductMasterDataByMerchantService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_PRODUCT_MASTER_DATA_BY_MERCHANT).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
