/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';

// #region other imports
import { TRANSACTION_REFERENCE } from 'constants/enums';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';

/**
 * Modal for Transaction details
 */
function TransactionUserModel(props) {
    const {
        show,
        handleToggle,
        transactionId,
        name,
        newUserDetails,
        transactionReference,
        newRole,
        previousRole,
        previousDetails,
    } = props;

    const { USER_PROFILE_UPDATED, ROLE_PERMISSION_UPDATED } = TRANSACTION_REFERENCE.USER_MANAGEMENT;

    return (
        <DeveloperModal header={`Transaction data for ${transactionId}`} show={show} handleToggle={handleToggle}>
            <Container>
                <Card style={{ height: '200px', overflowY: 'scroll', overflowX: 'hidden', boxShadow: 'none' }}>
                    <Row className="mb-2 mt-4">
                        <Col>
                            {transactionReference !== ROLE_PERMISSION_UPDATED &&
                                transactionReference !== USER_PROFILE_UPDATED && <h6>{name}</h6>}

                            {transactionReference === ROLE_PERMISSION_UPDATED && (
                                <>
                                    <h6>{name}</h6>
                                    <h6>{newRole}</h6>
                                    <h6>{previousRole}</h6>
                                </>
                            )}

                            {transactionReference === USER_PROFILE_UPDATED && (
                                <>
                                    <h5>Previous User Details</h5>
                                    <br />
                                    <h6>{name}</h6>
                                    <h6>First name: {previousDetails.firstName}</h6>
                                    <h6>Last name: {previousDetails.lastName}</h6>
                                    <h6>Designation: {previousDetails.designation}</h6>
                                    <h6>Address: {previousDetails.address}</h6>
                                    <h6>Department: {previousDetails.department}</h6>
                                    <h6>Merchant name: {previousDetails.merchantName}</h6>
                                    <h6>Client name: {previousDetails.clientName}</h6>
                                    <h6>Country: {previousDetails.country}</h6>
                                    <h6>Email: {previousDetails.email}</h6>
                                    <h6>Mobile No: {previousDetails.mobileNo}</h6>
                                    <h6>Office No: {previousDetails.officeNo}</h6>
                                    <h6>Ciy: {previousDetails.city}</h6>
                                    <h6>Postal Code: {previousDetails.postalCode}</h6>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-2 mt-4">
                        <Col>
                            {transactionReference === USER_PROFILE_UPDATED && (
                                <>
                                    <h5>New User Details</h5>
                                    <br />
                                    <h6>{name}</h6>
                                    <h6>First name: {newUserDetails.firstName}</h6>
                                    <h6>Last name: {newUserDetails.lastName}</h6>
                                    <h6>Designation: {newUserDetails.designation}</h6>
                                    <h6>Address: {newUserDetails.address}</h6>
                                    <h6>Department: {newUserDetails.department}</h6>
                                    <h6>Merchant name: {newUserDetails.merchantName}</h6>
                                    <h6>Client name: {newUserDetails.clientName}</h6>
                                    <h6>Country: {newUserDetails.country}</h6>
                                    <h6>Email: {newUserDetails.email}</h6>
                                    <h6>Mobile No: {newUserDetails.mobileNo}</h6>
                                    <h6>Office No: {newUserDetails.officeNo}</h6>
                                    <h6>Ciy: {newUserDetails.city}</h6>
                                    <h6>Postal Code: {newUserDetails.postalCode}</h6>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card>
            </Container>
        </DeveloperModal>
    );
}

TransactionUserModel.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    transactionId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    newUserDetails: PropTypes.object,
    transactionReference: PropTypes.string,
    newRole: PropTypes.string,
    previousRole: PropTypes.string,
    previousDetails: PropTypes.any,
};

export default TransactionUserModel;
