import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';

function AlertModal(props) {
    const {
        show = false,
        title = null,
        message = '',
        onConfirm = null,
        onHide = null,
        confirmMessage = null,
        deleteMessage = null,
        data = null,
    } = props;

    return (
        <>
            {show && (
                <SweetAlert
                    showCancel
                    title={title || 'Are you sure?'}
                    confirmBtnText={confirmMessage || 'Ok'}
                    cancelBtnText={deleteMessage || 'Cancel'}
                    cancelBtnBsStyle="danger"
                    confirmBtnBsStyle="success"
                    onConfirm={() => {
                        if (onConfirm) {
                            onConfirm(data);
                        }
                    }}
                    onCancel={() => {
                        if (onHide) {
                            onHide();
                        }
                    }}
                >
                    {message}
                </SweetAlert>
            )}
        </>
    );
}

AlertModal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    onHide: PropTypes.func,
    confirmMessage: PropTypes.string,
    deleteMessage: PropTypes.string,
    data: PropTypes.object,
};

export default AlertModal;
