import React from 'react';
import PropTypes from 'prop-types';

import CreatableSelect from 'react-select/creatable';

function CreatableSelectAdvanced(props) {
    const { isDisabled = false, isLoading = false, onCreate, required, submitted, value, options = [] } = props;

    const handleChange = (newValue, actionMeta) => {
        // console.group('Value Changed');
        // console.log(newValue);
        // console.log(`action: ${actionMeta.action}`);
        // console.groupEnd();
        // setValue(newValue);
    };

    const handleCreate = (inputValue) => {
        if (onCreate) {
            onCreate(inputValue);
        }
        // console.log('Wait a moment...');
        // setTimeout(() => {
        //     const { options } = this.state;
        //     const newOption = createOption(inputValue);
        //     console.log(newOption);
        //     console.groupEnd();
        //     this.setState({
        //         isLoading: false,
        //         options: [...options, newOption],
        //         value: newOption,
        //     });
        // }, 1000);
    };

    return (
        <CreatableSelect
            isClearable
            isDisabled={isLoading || isDisabled}
            isLoading={isLoading}
            onChange={handleChange}
            onCreateOption={handleCreate}
            options={options}
            value={value}
            submitted={submitted}
            required={required}
            maxMenuHeight={200}
            {...props}
        />
    );
}

// Component Prop Types
CreatableSelectAdvanced.propTypes = {
    onChange: PropTypes.func,
    required: PropTypes.bool,
    submitted: PropTypes.any,
    value: PropTypes.any,
    onCreate: PropTypes.func,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    options: PropTypes.array,
};

export default CreatableSelectAdvanced;
