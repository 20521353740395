/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { motion } from 'framer-motion';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Alert, Col, FormGroup, Label, Row, Spinner } from 'reactstrap';
import toast from 'react-hot-toast';

// #region assets | components
import { CrossCircle } from 'components/Icons';

// #region services
import { cancelRoutePlanningEntryById } from 'services/routePlanning/routePlanning.service';

// #endregion imports
import CustomToast from 'components/Toast/Custom';

// #regions utils
import { apiErrorHandlerV2 } from 'utils/helpers';
import { validateRoutePlanningCancelForm } from './utils';

/**
 * Cancel route planning entry modal
 *
 * @param {func} toggleModal - Toggles the modal
 * @returns {JSX.Element} A React component that displays the delivery types modal.
 */
function CancelRoutePlanningEntryModule(props) {
    const {
        toggleModal,
        routePlanningId,
        fetchRoutePlanningData,
        fetchRelevantDeliveryData,
        location,
        loadTransactions,
    } = props;
    const history = useHistory();

    // form
    const [formLoading, setFormLoading] = useState(false);
    // form data
    const [formData, setFormData] = useState({});

    /**
     * Handles the "CANCEL THE ROUTE PLANNING ENTRY" button click
     * @returns {void}
     */
    const handleSubmit = async () => {
        setFormLoading(true);
        const formValidation = validateRoutePlanningCancelForm(formData);

        // whether the cancellation request success
        let isSuccess = false;

        // if form contains empty fields
        if (!formValidation.isFormValid) {
            toast.custom((t) => <CustomToast text={formValidation.message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        try {
            const response = await cancelRoutePlanningEntryById(routePlanningId, formData);
            //  if the cancellation process is success
            if (response.status === 200) {
                isSuccess = true;
                toast.custom(
                    (t) => <CustomToast text="Successfully Cancelled The Route Planning Entry" t={t} type="success" />,
                    {
                        position: 'top-right',
                    }
                );

                history.replace({
                    pathname: location.pathname,
                    search: location.search,
                    state: response.data,
                });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (isSuccess) {
            toggleModal();
            // fetch updated route planning entry data
            fetchRoutePlanningData(routePlanningId);
            // fetch updated route planning entry delivery data
            fetchRelevantDeliveryData(routePlanningId);
            // fetch updated route planning entry Transactions data
            loadTransactions(routePlanningId);
        }
        setFormLoading(false);
    };

    /**
     * Updates the form data state with the user input for the cancel form.
     * @param {Object} event - The input change event.
     */
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    return (
        <>
            <div className="font-size-14 w-100 p-0">
                <div className="modal__form-table">
                    <AvForm onSubmit={handleSubmit}>
                        <fieldset>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="reason">Reason for Cancelling the Route Planning Entry</Label>
                                        <AvField
                                            name="reason"
                                            placeholder=""
                                            type="text"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="reason"
                                            onChange={handleInputChange}
                                            autoComplete="new-reason" // prevent auto suggestions
                                            spellCheck="false"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Alert color="warning" style={{ borderRadius: 8 }}>
                                Please enter your password to cancel.
                            </Alert>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <AvField
                                            name="password"
                                            placeholder=""
                                            type="password"
                                            errorMessage="Enter Password"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="password"
                                            onChange={handleInputChange}
                                            autoComplete="new-password" // prevent auto suggestions
                                            spellCheck="false"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                       />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="auto" className="mb-2 mb-xs-0">
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="remove__btn form-delete-btn shadow-lg"
                                        style={{ borderRadius: 10 }}
                                        type="submit"
                                        disabled={formLoading}
                                    >
                                        {formLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <CrossCircle color="#FFF" />
                                                &nbsp;CANCEL THE ROUTE PLANNING ENTRY
                                            </>
                                        )}
                                    </motion.button>
                                </Col>
                                <Col xs="auto">
                                    <motion.button
                                        className="remove__btn form-delete-btn shadow-lg"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        style={{ borderRadius: 10 }}
                                        onClick={toggleModal}
                                    >
                                        <CrossCircle color="#FFF" />
                                        &nbsp;CLOSE
                                    </motion.button>
                                </Col>
                            </Row>
                        </fieldset>
                    </AvForm>
                </div>
            </div>
        </>
    );
}

CancelRoutePlanningEntryModule.propTypes = {
    toggleModal: PropTypes.func,
    routePlanningId: PropTypes.string,
    fetchRoutePlanningData: PropTypes.func,
    fetchRelevantDeliveryData: PropTypes.func,
    location: PropTypes.any,
    loadTransactions: PropTypes.func,
};

export default CancelRoutePlanningEntryModule;
