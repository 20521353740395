/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment from 'moment';
import { Card, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';

// #region constants
import { TRANSACTION_METHODS } from 'constants/enums';

/**
 * Modal for Transaction details
 */
function RoutePlanTransactionModal(props) {
    const { show, handleToggle, data } = props;

    const { sequence, method = '', meta, rider = {}, routePlan } = data;

    return (
        <DeveloperModal header={`Transaction data for ${sequence}`} show={show} handleToggle={handleToggle}>
            <Container>
                <Card style={{ height: '200px', overflowY: 'scroll', overflowX: 'hidden', boxShadow: 'none' }}>
                    <Row className="mb-2 mt-4">
                        <Col>
                            {method === TRANSACTION_METHODS.ROUTE_PLANNING.DELIVERY_ADDED && (
                                <>
                                    <h5>Newly Added Deliveries</h5>
                                    <br />
                                    <h6>Delivery IDs : {meta.deliveryIds.join(', ')}</h6>
                                </>
                            )}
                            {method === TRANSACTION_METHODS.ROUTE_PLANNING.RIDER_UPDATED && (
                                <>
                                    <h5>New Rider</h5>
                                    <br />
                                    <h6>Rider ID : {rider.referenceNumber}</h6>
                                    <h6>Rider Name : {rider.fullName}</h6>
                                </>
                            )}
                            {method === TRANSACTION_METHODS.ROUTE_PLANNING.DELIVERY_DATE_UPDATED && (
                                <>
                                    <h5>New Date</h5>
                                    <br />
                                    <h6>Delivery Date : {moment(routePlan[0]?.deliveryDate).format('DD-MM-YYYY')}</h6>
                                </>
                            )}
                            {method === TRANSACTION_METHODS.ROUTE_PLANNING.DELIVERY_DETACHED && (
                                <>
                                    <h5>Detach Delivery</h5>
                                    <br />
                                    <h6>Delivery ID : {meta.deliveryReferenceNumber}</h6>
                                </>
                            )}
                            {method === TRANSACTION_METHODS.ROUTE_PLANNING.ENTRY_CANCELLED && (
                                <>
                                    <h5>Cancelled Transaction Details</h5>
                                    <br />
                                    <h6>Reason : {meta.routePlanningCancellationReason}</h6>
                                    <h6>Deliveries Assigned : {meta.deliveryIds.join(', ')}</h6>
                                    <h6>Rider Assigned : {rider.fullName}</h6>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-2 mt-4">
                        <Col>
                            {method === TRANSACTION_METHODS.ROUTE_PLANNING.RIDER_UPDATED && (
                                <>
                                    <h5>Previous Rider</h5>
                                    <br />
                                    <h6>Rider Name : {meta.previousRider}</h6>
                                    <h6>Rider ID : {meta.previousRiderId}</h6>
                                </>
                            )}
                            {method === TRANSACTION_METHODS.ROUTE_PLANNING.DELIVERY_DATE_UPDATED && (
                                <>
                                    <h5>Previous Delivery Date</h5>
                                    <br />
                                    <h6>Delivery Date : {moment(meta.previousDeliveryDate).format('DD-MM-YYYY')}</h6>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card>
            </Container>
        </DeveloperModal>
    );
}

RoutePlanTransactionModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    data: PropTypes.object,
};

export default RoutePlanTransactionModal;
