import moment from 'moment';

/**
 * This function is responsible for mapping data which came from the backend to the table list view
 */
export const mapRoutePlanningEntryViewTableDocs = (docs) => {
    let allDocs = [];
    allDocs = docs.map((doc, index) => ({
        ...doc,
        plan: index + 1,
        routeType: doc.routeType.charAt(0).toUpperCase() + doc.routeType.slice(1), // make the first letter capital of 'doc.routeType' string
        pickedUpAt: doc.pickedUpAt ? moment(doc.pickedUpAt).format('DD-MM-YYYY HH:mm a') : 'Not yet picked',
        deliveredAt: doc.deliveredAt ? moment(doc.deliveredAt).format('DD-MM-YYYY HH:mm a') : 'Not yet delivered',
        merchantName: doc.merchant.name,
    }));
    return allDocs;
};

/**
 * This function is responsible for mapping data which came from the backend to the table list view
 */
export const mapRoutePlanningEntryViewTransactionTableDocs = (docs) => {
    let allDocs = [];
    allDocs = docs.map((doc) => ({
        ...doc,
        createdAt: moment(doc.createdAt).format('DD-MM-YYYY HH:mm a'),
        createdByUserFullName: `${doc.createdByUserFullName} - ${doc.createdByUser.username}`,
        transactionName: doc.name,
        details: doc.meta,
    }));
    return allDocs;
};
