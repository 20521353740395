export const privateKeyEndpoints = {
    getQuotation: {
        label: 'Quotation',
        key: 'getQuotation',
    },
    createDelivery: {
        label: 'Create Delivery',
        key: 'createDelivery',
    },
    updateDelivery: {
        label: 'Update Delivery',
        key: 'updateDelivery',
    },
    cancelDelivery: {
        label: 'Cancel Delivery',
        key: 'cancelDelivery',
    },
    // getDeliveryReadyStatusUpdate: {
    //     label: 'Delivery Ready Status Update',
    //     key: 'getDeliveryReadyStatusUpdate',
    // },
    getShippingLabel: {
        label: 'Shipping Label',
        key: 'getShippingLabel',
    },
    trackDelivery: {
        label: 'Tracking',
        key: 'trackDelivery',
    },
    getDeliveryCharges: {
        label: 'Delivery Charges',
        key: 'getDeliveryCharges',
    },
    getDeliveryByDeliveryId: {
        label: 'Get Delivery By Delivery Id',
        key: 'getDeliveryByDeliveryId',
    },
    bulkDeliveryCreate: {
        label: 'Create Bulk Deliveries',
        key: 'bulkDeliveryCreate',
    },
};

export const publicKeyEndpoints = {
    getQuotation: {
        label: 'Quotation',
        key: 'getQuotation',
    },
    trackDelivery: {
        label: 'Tracking',
        key: 'trackDelivery',
    },
};

/** Default values for the 'Manage API Keys' form and 'Generate API Key' form */
export const emptyFormValues = {
    name: '',
    password: '',
    type: 'public',
    allowedEndpoints: [],
};
