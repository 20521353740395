/* eslint-disable camelcase */
export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { country, gender, address } = form;
    if (!country || !gender || !address) {
        return { isFormValid, message };
    }

    if (form.isLimitedToMerchant && !form.merchantId) {
        return { isFormValid, message: 'Select Merchant' };
    }

    if (form.isLimitedToMerchantLocation && !form.merchantLocationId) {
        return { isFormValid, message: 'Select Merchant Location' };
    }

    isFormValid = true;
    return { isFormValid, message };
};

// eslint-disable-next-line consistent-return
export const buildLocationData = (id, idMeta, data) => {
    if (id === 'address') {
        if (data) {
            const { city = '', address = '', state = null, postal_code = '' } = data;
            return {
                city,
                state,
                [id]: `${address ? `${address}` : ''}`,
                [idMeta]: data,
                postalCode: postal_code || '',
            };
        }
        return {
            [id]: null,
            [idMeta]: null,
            city: '',
            state: '',
            postalCode: '',
        };
    }
};
