/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';

/**
 * Modal for enabling the API key
 */
function TransactionDataModal(props) {
    const { show, handleToggle, data = {} } = props;

    const { transactionId, customMessage } = data;

    return (
        <DeveloperModal header={`Transaction data for ${transactionId || ''}`} show={show} handleToggle={handleToggle}>
            <AvForm>
                <Row className="px-3">
                    <Col md={12}>
                        <FormGroup>
                            <Label htmlFor="customMessage">Custom Message</Label>
                            <AvField
                                name="customMessage"
                                type="textarea"
                                className="form-control"
                                value={customMessage || ''}
                                rows={5}
                                readOnly
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </AvForm>
        </DeveloperModal>
    );
}

TransactionDataModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    data: PropTypes.any,
};

export default TransactionDataModal;
