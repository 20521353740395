/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';

/**
 * Modal for enabling the API key
 */
function WebhookEventData(props) {
    const { show, handleToggle, name, data } = props;

    return (
        <DeveloperModal header="Webhook Event Data" show={show} handleToggle={handleToggle}>
            <Container>
                <Row className="mb-2">
                    <Col>
                        <Label htmlFor="name">Webhook Name</Label>
                        <Input name="name" className="form-control" value={name} readOnly />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Label htmlFor="data">Data</Label>
                        <Input
                            name="data"
                            type="textarea"
                            className="form-control"
                            style={{
                                borderRadius: 15,
                                backgroundColor: '#F5F4F4',
                                border: '#E7E4E4 1px solid',
                            }}
                            value={JSON.stringify(data)}
                            readOnly
                        />
                    </Col>
                </Row>
            </Container>
        </DeveloperModal>
    );
}

WebhookEventData.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
};

export default WebhookEventData;
