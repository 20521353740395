/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker } from 'react-leaflet';
import toast from 'react-hot-toast';
import L from 'leaflet';
import './styles/index.scss';
import polylineEncryption from '@mapbox/polyline';

// #region components
import { CustomToast } from 'components';
import { HouseMapMarker } from 'components/Icons';

// #region services
import { generatesRiderPathService } from 'services/routePlanning/routePlanning.service';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #region constants
import { VEHICLE_IMAGES } from 'constants/constants';

// #endregion components
import RiderTooltip from './RiderTooltip';

// #endregion utils
import { buildRiderCoordinates, buildRiderCoordinatesBasedOnDate } from '../utils';

// #endregion constants
import { RiderMarkerPropTypes } from '../constants';
import { RIDER_LOCATION } from '../modules/RoutePlanningEntry-View/constants';

/**
 * This component is responsible for adding markers in a customized way
 * @returns a customized marker
 */
function RiderMarker(props) {
    const {
        rider,
        setFormData,
        formData,
        isTooltipOpen = false,
        setRidersOnMap,
        isActionDisable,
        deliveryDate,
        isConsiderDate = false, // this property indicate the status of when calculating rider coordinate, consider the delivery date or not
        isRoutePlanStarted = false, // this property indicates the status of rider start the route plan or not
        isLimitedToAssignRiderModel = false, // this property is use to do the validation which are only enables for rider assigned modal
        setRiderRoutePolyline,
    } = props;

    // referencing the marker
    const markerRef = useRef(null);

    // This is the position state which we set lat and lan value as an array
    const [position, setPosition] = useState([0, 0]);
    // This is the state for storing rider location name wether it Tasa, home or current
    const [riderLocationName, setRiderLocationName] = useState(null);
    // this user state holds the custom icon
    const [customIcon, setCustomIcon] = useState(null);

    const [loading, setLoading] = useState(false);

    // Set coordinates
    useEffect(() => {
        /** When there are no deliveries assigned for a rider on a given day, display the rider’s location as Rider’s home location
         * When no home address exists with the rider profile, display the TASA head office address as the rider’s location.  */

        const { riderCurrentCoordinate, riderLocation } = isConsiderDate
            ? buildRiderCoordinatesBasedOnDate(rider, deliveryDate)
            : buildRiderCoordinates(rider, isRoutePlanStarted);

        setPosition(riderCurrentCoordinate);
        setRiderLocationName(riderLocation);
    }, [rider, deliveryDate]);

    // once isTooltipOpen is true, it triggers the openPopup option in marker to open the popup
    useEffect(() => {
        if (isTooltipOpen && markerRef.current) {
            markerRef.current.openPopup();
        } else if (!isTooltipOpen && markerRef.current) {
            markerRef.current.closePopup();
        }
    }, [isTooltipOpen]);

    /** once the user hover over the marker this will triggered and popup the tooltip */
    useEffect(() => {
        if (markerRef.current) {
            markerRef.current.on('mouseover', () => {
                markerRef.current.openPopup();
                if (isLimitedToAssignRiderModel) {
                    loadRidersRoutes();
                }
            });

            markerRef.current.on('mouseout', () => {
                if (isLimitedToAssignRiderModel) {
                    setRiderRoutePolyline([]);
                }
            });
        }

        // Clean up function to remove event listeners
        return () => {
            if (markerRef.current) {
                markerRef.current.off('mouseover');
                markerRef.current.off('mouseout');
            }
        };
    }, [markerRef.current, deliveryDate, formData?.isDisplayDeliveryRoute, customIcon, position]);

    // this useEffect suppose to set the custom marker based on the name of the location
    useEffect(() => {
        switch (riderLocationName) {
            case RIDER_LOCATION.CURRENT_LOCATION:
                setCustomIcon(customOnlineIcon);
                break;
            case RIDER_LOCATION.HOME:
                if (isLimitedToAssignRiderModel) {
                    setCustomIcon(customOfflineIcon);
                } else {
                    setCustomIcon(customRiderHomeIcon);
                }
                break;
            case RIDER_LOCATION.TASA:
                if (isLimitedToAssignRiderModel) {
                    setCustomIcon(customOfflineIcon);
                } else {
                    setCustomIcon(customTasaIcon);
                }
                break;
            default:
                break;
        }
    }, [riderLocationName]);

    /** This Refers to load and generate the selected rider route plan
     *  Initially this sends all the riderIds and the deliveryIds to generate the route plan
     */
    const loadRidersRoutes = async () => {
        const buildQuery = {
            riderId: rider._id,
            deliveryDate,
        };
        setLoading(true);
        try {
            const { data } = await generatesRiderPathService(buildQuery);
            const { encodedPolylinePath } = data;
            if (encodedPolylinePath.length > 0) {
                const decodedPolylines = encodedPolylinePath.map((item) => polylineEncryption.decode(item));
                setRiderRoutePolyline(decodedPolylines || '');
            } else {
                /** set time interval for the notification  */
                setTimeout(() => {
                    const message = `${rider.fullName} Doesn't Have a Route Plan on Selected date`;
                    toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                        position: 'top-right',
                    });

                    setRiderRoutePolyline([]);
                }, 500);
            }
        } catch (error) {
            const { message } = apiErrorHandlerV2(error);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setLoading(false);
    };

    // Leaflet js allows us to create customizable jsx component in the below way
    // Custom rider marker showing when rider online
    const customOnlineIcon = L.divIcon({
        className: 'rider-on-map',
        html: ReactDOMServer.renderToString(
            // Below conditionally apply scss stylings for different marker types
            <span
                className={`${
                    rider?.status === 'active' ? 'rider-on-map__marker-online' : 'rider-on-map__marker-offline'
                }`}
            >
                <img src={VEHICLE_IMAGES[rider.vehicleType]} height={20} width={20} alt="" />
            </span>
        ),
    });

    // Custom rider marker showing when rider online
    const customOfflineIcon = L.divIcon({
        className: 'rider-on-map',
        html: ReactDOMServer.renderToString(
            // Below conditionally apply scss stylings for different marker types
            <span className="rider-on-map__marker-offline">
                <img src={VEHICLE_IMAGES[rider.vehicleType]} height={20} width={20} alt="" />
            </span>
        ),
    });

    // Custom home marker showing home icon when rider offline
    const customRiderHomeIcon = L.divIcon({
        className: 'rider-on-map',
        html: ReactDOMServer.renderToString(
            // Below conditionally apply scss stylings for different marker types
            <span className="rider-on-map__marker-home">
                <HouseMapMarker height={24} width={24} />
            </span>
        ),
    });

    // Custom TASA marker showing Tasa logo icon when rider is not online and not having home address
    const customTasaIcon = L.divIcon({
        className: 'rider-on-map',
        html: ReactDOMServer.renderToString(
            // Below conditionally apply scss stylings for different marker types
            <span className="rider-on-map__marker-tasa">{/* TODO! Add the TASA Logo Here */}</span>
        ),
    });

    return (
        /** setting zIndexOffset will give the first priority to this marker,
         *  if there is many markers in the single location, this marker will be showing on top of all the markers  */
        <>
            {customIcon && (
                <Marker ref={markerRef} position={position} icon={customIcon} zIndexOffset={1000}>
                    <RiderTooltip
                        rider={rider}
                        setRidersOnMap={setRidersOnMap}
                        setFormData={setFormData}
                        isTooltipOpen={isTooltipOpen}
                        isActionDisable={isActionDisable}
                        loading={loading}
                    />
                </Marker>
            )}
        </>
    );
}

RiderMarker.propTypes = RiderMarkerPropTypes;
export default RiderMarker;
