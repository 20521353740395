/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Row, Label, Col, Alert, Input, Button, Container, Spinner } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import InputMask from 'react-input-mask';

// #region components
import CheckCircleIcon from 'components/Icons/Check-Circle';
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';

// #region utils
import { cleanMobileNo } from 'utils/helpers';

// #endregion imports
import { modalInitialDisabledFields, modalInitialFormValues } from '../constants';

/**
 * This modal contains the form for entering developer contact details
 * when enabling the API service.
 * @param {Object} props
 */
function EnableApiServiceModal(props) {
    const { show, handleToggle, handleSubmit, data } = props;

    /** State for storing entered form data */
    const [formData, setFormData] = useState(modalInitialFormValues);
    /** State for storing the backend response type (error, success) */
    const [formResponse, setFormResponse] = useState({ type: null });
    /**
     * State for tracking which fields to disable.
     * When 'Use Merchant\'s Primary Contact' is checked, some fields are populated,
     * and those fields should become read-only.
     */
    const [disabledFields, setDisabledFields] = useState(modalInitialDisabledFields);
    /** State for showing whether the submit operation is in progress */
    const [loading, setLoading] = useState(false);

    // Show previously saved data if available
    useEffect(() => {
        if (data) {
            const payload = data;
            if (payload.developerContactNumber) {
                payload.developerContactNumber = cleanMobileNo(payload.developerContactNumber);
            }
            if (payload.developerWhatsappNumber) {
                payload.developerWhatsappNumber = cleanMobileNo(payload.developerWhatsappNumber);
            }
            setFormData(payload);
        }
    }, [data]);

    const handleModalSubmit = async () => {
        setFormResponse({ type: null });
        setLoading(true);
        const response = await handleSubmit(formData);
        if ('error' in response) {
            setFormResponse({ type: 'error' });
        } else {
            setFormResponse({ type: 'success' });
            handleModalToggle(data);
        }
        setLoading(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckChange = (event) => {
        const { name, checked } = event.target;
        setFormData({
            ...formData,
            [name]: checked,
        });

        if (name === 'usePrimaryContact') {
            if (!checked) {
                resetForm();
                setDisabledFields({ ...modalInitialDisabledFields });
                return;
            }

            const newFormData = { isDeveloperTestPassed: false, developerWhatsappNumber: '' };
            const newDisabledFields = { developerName: false, developerEmail: false, developerContactNumber: false };

            if (data.merchant.primaryContactName) {
                newFormData.developerName = data.merchant.primaryContactName;
                newDisabledFields.developerName = true;
            }
            if (data.merchant.primaryContactEmail) {
                newFormData.developerEmail = data.merchant.primaryContactEmail;
                newDisabledFields.developerEmail = true;
            }
            if (data.merchant.primaryContactNumber) {
                newFormData.developerContactNumber = data.merchant.primaryContactNumber;
                newDisabledFields.developerContactNumber = true;
            }

            setFormData((prev) => ({ ...prev, ...newFormData }));
            setDisabledFields((prev) => ({ ...prev, ...newDisabledFields }));
        }
    };

    const handleModalToggle = (row) => {
        resetForm();
        handleToggle(row);
    };

    const resetForm = () => {
        setFormData({ merchantId: data.merchantId, ...modalInitialFormValues });
        setFormResponse({ type: null });
        setLoading(false);
    };

    return (
        <DeveloperModal
            header={`${data && data.serviceStatusClient ? 'Disable' : 'Enable'} API Service for ${
                data ? data.merchantName : ''
            }`}
            show={show}
            handleToggle={handleModalToggle}
        >
            <AvForm
                className="needs-validation default-form"
                noValidate
                onValidSubmit={handleModalSubmit}
                autoComplete="off"
            >
                <Container>
                    <Row>
                        <Col>
                            <Label htmlFor="developerContactName">Developer Contact Name</Label>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col xs="auto" className="mb-4">
                            <Input
                                name="usePrimaryContact"
                                className="ml-0 mt-0"
                                type="checkbox"
                                checked={formData.usePrimaryContact}
                                onChange={handleCheckChange}
                            />
                        </Col>
                        <Col>
                            <Label htmlFor="usePrimaryContact" className="text-muted">
                                Use Merchant&apos;s Primary Contact as Developer Contact
                            </Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <AvField
                                    name="developerName"
                                    type="text"
                                    className="form-control"
                                    value={formData.developerName}
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true, errorMessage: 'Required' } }}
                                    disabled={disabledFields.developerName}
                                    autoComplete="new-password"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="developerEmail">Developer Email Address</Label>
                                <AvField
                                    name="developerEmail"
                                    type="text"
                                    className="form-control"
                                    value={formData.developerEmail}
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true, errorMessage: 'Required' } }}
                                    disabled={disabledFields.developerEmail}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="developerContactNumber">Developer Contact Number</Label>
                                <AvField
                                    name="developerContactNumber"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    value={formData.developerContactNumber}
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                    disabled={disabledFields.developerContactNumber}
                                    mask="+60 99-999 99999"
                                    maskChar=""
                                    tag={[Input, InputMask]}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="developerWhatsappNumber">Developer WhatsApp Contact Number</Label>
                                <AvField
                                    name="developerWhatsappNumber"
                                    type="text"
                                    className="form-control"
                                    value={formData.developerWhatsappNumber}
                                    onChange={handleInputChange}
                                    mask="+60 99-999 99999"
                                    maskChar=""
                                    tag={[Input, InputMask]}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert color="warning" style={{ borderRadius: 8 }}>
                                Please enter your password to confirm the transaction.
                            </Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <AvField
                                    name="password"
                                    value={formData.password}
                                    type="password"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true, errorMessage: 'Password is Required' } }}
                                    autoComplete="new-password" // prevent auto suggestions
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col xs="auto" className="mb-4">
                            <Input
                                name="isDeveloperTestPassed"
                                className="ml-0 mt-0"
                                type="checkbox"
                                checked={formData.isDeveloperTestPassed}
                                onChange={handleCheckChange}
                            />
                        </Col>
                        <Col>
                            <Label htmlFor="isDeveloperTestPassed">Passed Developer Test</Label>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Button
                                disabled={formResponse.type === 'success' || !formData.isDeveloperTestPassed || loading}
                                className="scootix-btn-radius"
                                style={{ borderRadius: 10 }}
                            >
                                {loading ? (
                                    <Spinner size="sm" color="warning" className="spinner-white mx-1" />
                                ) : (
                                    <>
                                        <CheckCircleIcon color="#fff" />
                                    </>
                                )}
                                &nbsp;Enable
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </AvForm>
        </DeveloperModal>
    );
}

EnableApiServiceModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    data: PropTypes.object,
};

export default EnableApiServiceModal;
