/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, NavLink, NavItem, Nav, DropdownItem, Alert } from 'reactstrap';
import * as qs from 'query-string';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import moment from 'moment';
import { motion } from 'framer-motion';
import classnames from 'classnames';

// #region components | assets
import CustomToast from 'components/Toast/Custom';
import Knob from 'components/knob/knob';
import { BackdropLoader, ScootixModal, StatCard } from 'components';

// #region imports
import { centerElementContainerStyles } from 'styles';
import { THEME_COLOR } from 'theme';
import paths from 'routes/paths';

// #region utils
import { __DEV__ } from 'utils/checks';

// #region services
import {
    approvePendingDataDeliveryJobService,
    getImportHistoryByPendingDataIdService,
    retryDeliveryImportJobService,
    revertPendingDataDeliveryJobService,
} from 'services/import-history.service';
import { apiErrorHandlerV2 } from 'utils/helpers';
import { downloadExcelImportFile } from 'pages/Imports/Delivery-Imports/services';
import { IS_SUPER_ADMIN } from 'utils/checkAuth';

function PendingDeliveryDataViewPage(props) {
    const history = useHistory();

    // loading
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [importHistoryData, setImportHistoryData] = useState({});
    const [pendingData, setPendingData] = useState();

    const [activeTab, setActiveTab] = useState('1');
    const [tabStatus, setTabStatus] = useState('all');

    const [displayRecords, setDisplayRecords] = useState([]);

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    useEffect(() => {
        const urlQuery = qs.parse(props.location.search);
        if (urlQuery && urlQuery.id) {
            if (urlQuery.id === 'undefined' || urlQuery.id === undefined) {
                history.push(paths.IMPORTS.DELIVERY_IMPORTS.DEFAULT);
            }
            loadImportHistoryData(urlQuery.id);
        } else {
            history.push(paths.IMPORTS.DELIVERY_IMPORTS.DEFAULT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location]);

    useEffect(() => {
        if (importHistoryData && importHistoryData._id && pendingData && pendingData.records) {
            if (tabStatus === 'all') {
                if (pendingData.records.errorRecords && pendingData.records.successRecords) {
                    setDisplayRecords(pendingData.records.errorRecords.concat(pendingData.records.successRecords));
                } else if (pendingData.records.successRecords) {
                    setDisplayRecords(pendingData.records.successRecords);
                } else if (pendingData.records.errorRecords) {
                    setDisplayRecords(pendingData.records.errorRecords);
                } else {
                    setDisplayRecords([]);
                }
                return;
            }
            if (tabStatus === 'fail') {
                if (pendingData.records.errorRecords) {
                    setDisplayRecords(pendingData.records.errorRecords);
                } else {
                    setDisplayRecords([]);
                }
                return;
            }

            if (tabStatus === 'success') {
                if (pendingData.records.successRecords) {
                    setDisplayRecords(pendingData.records.successRecords);
                } else {
                    setDisplayRecords([]);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabStatus, pendingData]);

    const loadImportHistoryData = async (id) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getImportHistoryByPendingDataIdService(id);
            if (data) {
                setImportHistoryData(data);
                if (data.pendingDataId && typeof data.pendingDataId === 'object') {
                    setPendingData(data.pendingDataId);
                }
                if (data.errorRecordsLength > 0) {
                    toast.custom((t) => <CustomToast text="Changes required before approval" t={t} type="info" />, {
                        position: 'top-right',
                    });
                } else if (
                    data.errorRecordsLength === 0 &&
                    data.successRecordsLength > 0 &&
                    data.pendingDataId &&
                    !data.pendingDataId.isApproved
                ) {
                    toast.custom((t) => <CustomToast text="Deliveries can be approved" t={t} type="info" />, {
                        position: 'top-right',
                    });
                }
            }
        } catch (e) {
            console.log(e);
            toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    const handleRevertProcess = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        if (pendingData) {
            try {
                await revertPendingDataDeliveryJobService(pendingData._id);
                toast.custom((t) => <CustomToast text="Successfully Reverted Process" t={t} type="success" />, {
                    position: 'top-right',
                });
            } catch (e) {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            setScootixModalState({ ...scootixModalState, show: null });

            setTimeout(() => {
                history.push(paths.IMPORTS.DELIVERY_IMPORTS.DEFAULT);
            }, 1000);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleRetryImportProcess = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        if (pendingData) {
            try {
                const { merchantId, _id, jobId } = pendingData;
                const payload = { merchantId, jobId, pendingDataId: _id };
                await retryDeliveryImportJobService(payload);
                toast.custom((t) => <CustomToast text="Retrying Process Please Wait" t={t} type="success" />, {
                    position: 'top-right',
                });
            } catch (e) {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            setScootixModalState({ ...scootixModalState, show: null });

            setTimeout(() => {
                history.push(paths.IMPORTS.DELIVERY_IMPORTS.DEFAULT);
            }, 1000);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleApproveProcess = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        if (pendingData) {
            try {
                await approvePendingDataDeliveryJobService(pendingData._id);
                toast.custom((t) => <CustomToast text="Successfully Approved Process" t={t} type="success" />, {
                    position: 'top-right',
                });
                setTimeout(() => {
                    history.push(paths.IMPORTS.DELIVERY_IMPORTS.DEFAULT);
                }, 1000);
            } catch (e) {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            setScootixModalState({ ...scootixModalState, show: null });
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleDownloadFile = async (file) => {
        if (!file?.fileName) {
            toast.custom((t) => <CustomToast text="File Not Found, Contact Admin" t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await downloadExcelImportFile(file.fileName);
            if (data) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.importedFileName); // or any other extension
                document.body.appendChild(link);
                link.click();

                toast.custom((t) => <CustomToast text="Successfully Downloaded File" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setScootixModalState({ ...scootixModalState, show: null });
        setBackdropLoading((prevState) => prevState - 1);
    };

    return (
        <>
            <div className="page-content">
                <Row>
                    <div style={{ backgroundColor: '#E7F6FC' }} className="w-100 p-5">
                        <div className="bg-white shadow-lg p-3" style={{ borderRadius: 15 }}>
                            <Row className="justify-content-between">
                                <div className="ml-4">
                                    <div style={{ fontWeight: 'bold', fontSize: 15 }}>
                                        {importHistoryData.fileImportId || ''}
                                    </div>
                                </div>
                                <div className="mr-4 d-flex row" style={{ fontWeight: 'bold', fontSize: 15 }}>
                                    <div color="ml-2">
                                        Imported At {'    '}
                                        {moment(importHistoryData.createdAt).format('DD-MM-YY hh:mm A')}
                                    </div>
                                </div>
                            </Row>
                            <br />

                            <Row>
                                {pendingData && pendingData.records && (
                                    <Col md={8}>
                                        <Row className="ml-3" style={{ height: 200 }}>
                                            <StatCard
                                                title="Total Records"
                                                value={`${
                                                    pendingData.records.successRecords.length +
                                                        pendingData.records.errorRecords.length || 0
                                                }`}
                                                tooltipTitle="Total Records"
                                                tooltipPlacement="bottom"
                                                icon="fas fa-info-circle"
                                                customClassName="shadow-lg"
                                                iconProps={{ style: { color: '#0779e3' } }}
                                                cardStyle={{ maxHeight: 100 }}
                                            />
                                            <StatCard
                                                title="Successful Records"
                                                value={`${pendingData.records.successRecords.length || 0}`}
                                                tooltipTitle="Total Success Records"
                                                tooltipPlacement="bottom"
                                                icon="fas fa-check-circle"
                                                customClassName="shadow-lg"
                                                iconProps={{ style: { color: '#4ccc06' } }}
                                                cardStyle={{ maxHeight: 100 }}
                                            />
                                            <StatCard
                                                title="Failed Records"
                                                value={`${pendingData.records.errorRecords.length || 0}`}
                                                tooltipTitle="Total Failed Records"
                                                tooltipPlacement="bottom"
                                                icon="fas fa-ban"
                                                customClassName="shadow-lg"
                                                iconProps={{ style: { color: '#D61C4E' } }}
                                                cardStyle={{ maxHeight: 100 }}
                                            />
                                        </Row>
                                        <br />
                                        <Row className="ml-3">
                                            <StatCard
                                                title="File Name"
                                                value={`${importHistoryData.importedFileName || ''}`}
                                                tooltipTitle="Imported File Nae"
                                                tooltipPlacement="bottom"
                                                icon="fas fa-file-excel"
                                                customClassName="shadow-lg"
                                                // iconProps={{ style: { color: '#ed1822' } }}
                                            />
                                        </Row>
                                    </Col>
                                )}

                                <Col md={4}>
                                    <div className="text-center shadow" style={{ marginRight: 60 }}>
                                        <br />
                                        <br />
                                        <h3 style={{ color: '#8a8a8a', fontWeight: 'bold' }} className="mb-2">
                                            {' '}
                                            Completion Status{' '}
                                        </h3>
                                        <br />
                                        {pendingData && pendingData.records && (
                                            <div style={{ marginTop: 5 }}>
                                                <Knob
                                                    disableTextInput
                                                    value={(
                                                        (pendingData.records.successRecords.length /
                                                            (pendingData.records.successRecords.length +
                                                                pendingData.records.errorRecords.length)) *
                                                        100
                                                    ).toFixed(0)}
                                                    fgColor="#5468da"
                                                    thickness={0.2}
                                                    readOnly
                                                    height={200}
                                                    width={150}
                                                    onChange={(e) => {}}
                                                />
                                            </div>
                                        )}
                                        <br />
                                    </div>
                                </Col>
                            </Row>
                            <div className="ml-3">
                                <h4> Other Info </h4>
                                {importHistoryData.merchantLocation && (
                                    <div className="mt-3">
                                        <h5> Selected Merchant Location </h5>
                                        <div style={{ fontSize: 15 }}>{importHistoryData.merchantLocation || ''}</div>
                                    </div>
                                )}
                                {!importHistoryData.merchantLocation && (
                                    <div className="mt-3">
                                        <ul>
                                            <li>
                                                <h5> Merchant Locations Were Inserted Manually </h5>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <Row className="justify-content-end pr-3">
                                {pendingData && (
                                    <>
                                        {IS_SUPER_ADMIN() && (
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md pointer"
                                                onClick={() => {
                                                    setScootixModalState({
                                                        ...scootixModalState,
                                                        show: true,
                                                        message: 'Are you sure you want to Download?',
                                                        onConfirm: () => handleDownloadFile(importHistoryData),
                                                    });
                                                }}
                                            >
                                                <i className="fas fa-download fa-lg mt-1" />{' '}
                                                <span className="h6">Download</span>
                                            </motion.div>
                                        )}
                                        {((!pendingData.isApproved &&
                                            pendingData.records.errorRecords.length === 0 &&
                                            pendingData.records.successRecords.length > 0) ||
                                            __DEV__) && (
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md pointer"
                                                onClick={() => {
                                                    setScootixModalState({
                                                        ...scootixModalState,
                                                        show: true,
                                                        message: 'Are you sure you want to proceed?',
                                                        onConfirm: handleApproveProcess,
                                                    });
                                                }}
                                            >
                                                <i className="fas fa-check fa-lg mt-1" />{' '}
                                                <span className="h6">Approve</span>
                                            </motion.div>
                                        )}
                                        {pendingData && !pendingData.isApproved && (
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md pointer"
                                                onClick={() => {
                                                    setScootixModalState({
                                                        ...scootixModalState,
                                                        show: true,
                                                        message: 'Are you sure you want to revert the process?',
                                                        onConfirm: handleRevertProcess,
                                                    });
                                                }}
                                            >
                                                <i className="fas fa-history"></i>{' '}
                                                <span className="h6">Revert Process</span>
                                            </motion.div>
                                        )}
                                        {((pendingData && !pendingData.isApproved) || __DEV__) && (
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md pointer"
                                                onClick={() => {
                                                    setScootixModalState({
                                                        ...scootixModalState,
                                                        show: true,
                                                        message: 'Are you sure you want to proceed?',
                                                        onConfirm: handleRetryImportProcess,
                                                    });
                                                }}
                                            >
                                                <i className="fas fa-history"></i>{' '}
                                                <span className="h6">Retry Import Process</span>
                                            </motion.div>
                                        )}
                                        {pendingData && pendingData.isApproved && (
                                            <div style={centerElementContainerStyles} className="mt-2 mr-5">
                                                <div
                                                    className="mb-3"
                                                    style={{
                                                        backgroundColor: '#c2f2c7',
                                                        minWidth: 'min-content',
                                                        width: 'max-content',
                                                        borderRadius: 15,
                                                        fontWeight: 'bold',
                                                        paddingLeft: 20,
                                                        paddingRight: 20,
                                                        fontSize: 18,
                                                        paddingTop: 5,
                                                        paddingBottom: 5,
                                                    }}
                                                >
                                                    Successfully Approved
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Row>
                        </div>
                    </div>
                </Row>
                {pendingData && pendingData.records.errorRecords.length > 0 && (
                    <Alert color="danger" className="mr-5 ml-5">
                        Fix Deliveries that contain issues
                    </Alert>
                )}
                <br />
                {pendingData && pendingData.records && (
                    <Row>
                        <div
                            className="shadow-lg w-100 bg-white p-2"
                            style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, minHeight: 300 }}
                        >
                            <Row style={centerElementContainerStyles}>
                                <Nav
                                    tabs
                                    className="nav-tabs-custom "
                                    style={{ backgroundColor: 'white', marginTop: 5 }}
                                >
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: activeTab === '1',
                                            })}
                                            onClick={() => {
                                                setActiveTab('1');
                                                setTabStatus('all');
                                            }}
                                        >
                                            <span className="d-none d-sm-block h5">
                                                All Records ({' '}
                                                {(pendingData.records.successRecords.length || 0) +
                                                    (pendingData.records.errorRecords.length || 0)}{' '}
                                                )
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: activeTab === '2',
                                            })}
                                            onClick={() => {
                                                setActiveTab('2');
                                                setTabStatus('success');
                                            }}
                                        >
                                            <span className="d-none d-sm-block h5">
                                                Successful Records ( {pendingData.records.successRecords.length || 0} )
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: activeTab === '3',
                                            })}
                                            onClick={() => {
                                                setActiveTab('3');
                                                setTabStatus('fail');
                                            }}
                                        >
                                            <span className="d-none d-sm-block h5">
                                                Failed Records ( {pendingData.records.errorRecords.length || 0} )
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Row>
                            <br />

                            {displayRecords &&
                                displayRecords.map((record, key) => {
                                    const { data, message, type } = record;
                                    return (
                                        <>
                                            <Row
                                                style={{
                                                    minHeight: 100,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    alignContent: 'center',
                                                }}
                                            >
                                                <div style={{ width: '85%' }} className="p-3 shadow">
                                                    <Row>
                                                        <Col md={3}>
                                                            <h5>
                                                                {data.orderReferenceNumber ||
                                                                    data.merchantOrderNo ||
                                                                    ''}
                                                            </h5>
                                                            <h5>{data.recipientName || ''}</h5>
                                                            <h5>{data.recipientMobileNumber || ''}</h5>
                                                            <h5>{data.recipientEmail || ''}</h5>
                                                        </Col>
                                                        <Col
                                                            md={2}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                alignContent: 'center',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {data.deliveryType && (
                                                                <div
                                                                    className="p-2"
                                                                    style={{
                                                                        backgroundColor: '#EDECFF',
                                                                        minWidth: 'min-content',
                                                                        maxWidth: 200,
                                                                        width: 'max-content',
                                                                        borderRadius: 15,
                                                                        fontWeight: 'bold',
                                                                    }}
                                                                >
                                                                    {data.deliveryType}
                                                                </div>
                                                            )}
                                                            {data.deliveryType && data.deliveryType === 'System Order' && (
                                                                <div
                                                                    className="p-3"
                                                                    style={{
                                                                        backgroundColor: '#EDFFFD',
                                                                        minWidth: 'min-content',
                                                                        maxWidth: 200,
                                                                        width: 'max-content',
                                                                        borderRadius: 15,
                                                                        fontWeight: 'bold',
                                                                    }}
                                                                >
                                                                    {data.deliveryType}
                                                                </div>
                                                            )}
                                                        </Col>
                                                        <Col
                                                            md={2}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                alignContent: 'center',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {data.source && (
                                                                <div
                                                                    className="p-3"
                                                                    style={{
                                                                        backgroundColor:
                                                                            type === 'DELIVERY_READY_TO_APPROVE' ||
                                                                            message === 'Delivery Updated'
                                                                                ? '#EDFAF8'
                                                                                : '#FFF0F3',
                                                                        minWidth: 'min-content',
                                                                        maxWidth: 200,
                                                                        width: 'max-content',
                                                                        borderRadius: 15,
                                                                        fontWeight: 'bold',
                                                                    }}
                                                                >
                                                                    {data.source}
                                                                </div>
                                                            )}
                                                        </Col>
                                                        <Col
                                                            md={3}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                alignContent: 'center',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {message && (
                                                                <div
                                                                    className="p-3"
                                                                    style={{
                                                                        backgroundColor:
                                                                            type === 'DELIVERY_READY_TO_APPROVE' ||
                                                                            message === 'Delivery Updated'
                                                                                ? '#EDFAF8'
                                                                                : '#FFF0F3',
                                                                        minWidth: 'min-content',
                                                                        maxWidth: 200,
                                                                        width: 'max-content',
                                                                        borderRadius: 15,
                                                                        fontWeight: 'bold',
                                                                    }}
                                                                >
                                                                    {message}
                                                                </div>
                                                            )}
                                                        </Col>
                                                        <Col
                                                            md={2}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                alignContent: 'center',
                                                            }}
                                                        >
                                                            {((pendingData && !pendingData.isApproved) || __DEV__) && (
                                                                <motion.div
                                                                    id="excel-config-icon-container"
                                                                    whileHover={{ scale: 1.1 }}
                                                                    whileTap={{ scale: 0.9 }}
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="shadow-lg nw-md mr-2"
                                                                    onClick={() => {
                                                                        history.push({
                                                                            pathname: `${paths.DELIVERY.FORM}`,
                                                                            search: `?type=delivery-pending&pendingdataid=${pendingData._id}&importhistoryid=${importHistoryData._id}`,
                                                                            state: {
                                                                                deliveryParentData: data,
                                                                                message,
                                                                            },
                                                                        });
                                                                    }}
                                                                >
                                                                    <i
                                                                        id="excel-config-icon"
                                                                        className="fas fa-edit fa-2x"
                                                                        style={{ color: THEME_COLOR }}
                                                                    />
                                                                </motion.div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Row>
                                            <DropdownItem divider />
                                        </>
                                    );
                                })}
                        </div>
                    </Row>
                )}
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.8} />
            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

PendingDeliveryDataViewPage.propTypes = {
    location: PropTypes.any,
    socket: PropTypes.any,
};

const HotPendingDeliveryDataViewPage = hot(PendingDeliveryDataViewPage);

export default connect(mapStateToProps, {})(HotPendingDeliveryDataViewPage);
