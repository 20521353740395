export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { clientId, vehicleType, billingMethod, validFrom, validTo, deliveryType, commission } = form;

    if (!clientId || !billingMethod || !vehicleType || !validFrom || !validTo || !deliveryType) {
        return { isFormValid, message: 'Fill all fields' };
    }
    if (billingMethod === 'Percentage of Delivery Fee' && !commission) {
        return { isFormValid, message: 'Fill the Commission Rate' };
    }

    isFormValid = true;
    return { isFormValid, message };
};
