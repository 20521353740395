export const selectedDeliveriesModalTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'DELIVERY ID',
    },
    {
        key: 'merchantOrderNo',
        value: 'MERCHANT ORDER NO.',
    },
];

export const selectedDeliveryInitialTableValues = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: selectedDeliveriesModalTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};
