/* eslint-disable indent */
/* eslint-disable camelcase */
import {
    GET_STOCKS_RECEIVING,
    GET_STOCKS_RECEIVING_BY_ID,
    GET_ALL_STOCKS_RECEIVING,
    GET_STOCKS_RECEIVING_DELETED,
    RESTORE_STOCKS_RECEIVING,
    DELETE_STOCKS_RECEIVING_PERMANENT,
    GET_TRANSACTIONS_BY_STOCK_RECEIVING_ID,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Stocks Receiving Service
 * @returns {object}
 */
export const getStocksReceivingService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_STOCKS_RECEIVING}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : 'sort=updatedAt&'}${order ? `order=${order}&` : `&order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Stocks Receiving Deleted Service
 * @returns {object}
 */
export const getStocksReceivingDeletedService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_STOCKS_RECEIVING_DELETED}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Stocks Receiving By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getStocksReceivingByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_STOCKS_RECEIVING_BY_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Stocks Receiving
 * @returns {Promise}
 */
export const getAllStocksReceivingService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_STOCKS_RECEIVING).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const deleteMerchantPermanentService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_STOCKS_RECEIVING_PERMANENT).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Restore Merchant Service
 * @returns {Promise}
 */
export const restoreMerchantService = (id) => {
    try {
        const url = UrlBuilder().setUrl(RESTORE_STOCKS_RECEIVING).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * This build the url for getting stock receiving transactions by stock receiving id
 * @param {string} id - Stock receiving id
 * @returns url
 */
export const getTransactionsByStockReceivingId = (id) => {
    const url = UrlBuilder().setUrl(GET_TRANSACTIONS_BY_STOCK_RECEIVING_ID).setPathParams({ id }).build();
    return instance.get(url);
};
