import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { centerElementContainerStyles } from 'styles';

function BackdropLoader(props) {
    const { show = 0, opacity = 0.7, message } = props;

    return (
        <>
            {(show > 0 || show === true) && (
                <motion.div className="custom-backdrop" style={{ opacity }}>
                    <div
                        style={{ height: '100%', width: '100vw', ...centerElementContainerStyles }}
                        className="backdrop-spinner"
                    >
                        <Spinner size="sm" />
                        {message && (
                            <h5 className="spinner-message" style={{ color: '#FFF' }}>
                                {message}
                            </h5>
                        )}
                    </div>
                </motion.div>
            )}
        </>
    );
}

BackdropLoader.propTypes = {
    show: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    opacity: PropTypes.number,
    message: PropTypes.string,
};

export default BackdropLoader;
