/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Row, Container } from 'reactstrap';
import * as qs from 'query-string';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { TableCustom, CustomToast } from 'components';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #endregion imports
import Header from './Header';
import { getShippingLabelPrintData } from '../../Shipping-Label/services';
import { breadcrumbItemsArchivePrints, defaultTableHeaders, filters } from '../constant';

function ArchivedBatchPrint(props) {
    const { location } = props;

    const history = useHistory();

    const [loading, setLoading] = useState(0);

    const [urlParamsSearch, setUrlParamsSearch] = useState({});

    const [searchParams, setSearchParams] = useState(null);

    const [shippingLabelData, setShippingLabelData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    useEffect(() => {
        if (urlParamsSearch) {
            loadShippingLabelData({
                showLoading: true,
                ...shippingLabelData,
                searchBy: { searchBy: urlParamsSearch?.searchBy, searchText: urlParamsSearch?.searchText },
                searchByDate: { searchByDate: urlParamsSearch?.searchByDate, searchDate: urlParamsSearch?.searchDate },
                type: urlParamsSearch.type,
                page: urlParamsSearch.page,
            });
        }
    }, [urlParamsSearch]);

    useEffect(() => {
        const urlSearch = qs.parse(location.search);
        if (urlSearch && urlSearch.page) {
            const { tab, status, page, searchBy, searchText, searchByDate, searchDate } = urlSearch;
            setUrlParamsSearch({ tab, status, page, searchBy, searchText, searchByDate, searchDate });
            setSearchParams({ searchBy, searchText, searchByDate, searchDate });
        } else {
            replaceUrlParamsHandler({ page: '1' });
        }
    }, [location.search]);

    const loadShippingLabelData = (args) => {
        if (args.showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getShippingLabelPrintData({ ...args, isArchived: true })
            .then((res) => {
                const { data } = res;
                setShippingLabelData({
                    ...shippingLabelData,
                    ...data,
                    docs: data.docs.map((x) => ({
                        ...x,
                    })),
                });
            })
            .catch((e) => {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            })
            .finally(() => {
                if (args.showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };
    const handleFilter = (field, filterText) => {
        let modifiedFilterText = null;
        if (filterText && filterText !== '') {
            modifiedFilterText = filterText.replaceAll(' ', '$2');
        } else {
            modifiedFilterText = null;
        }
        if (field === 'createdAt') {
            replaceUrlParamsHandler({
                searchByDate: field,
                searchDate: String(modifiedFilterText),
            });
        } else {
            replaceUrlParamsHandler({
                searchBy: field || 'createdByUserFullName',
                searchText: modifiedFilterText,
                page: '1',
            });
        }
    };

    const handlePageChange = (pageNo) => {
        replaceUrlParamsHandler({
            page: pageNo,
            searchBy: searchParams?.searchBy,
            searchText: searchParams?.searchText,
        });
    };

    const replaceUrlParamsHandler = (
        params = { tab: null, status: null, page: '1', searchBy: null, searchText: null }
    ) => {
        try {
            history.replace({
                search: `${
                    params.page || urlParamsSearch.page ? `page=${params.page || urlParamsSearch.page || '1'}&` : ''
                }${params.tab || urlParamsSearch.tab ? `tab=${params.tab || urlParamsSearch.tab || '1'}&` : ''}${
                    params.status || urlParamsSearch.status
                        ? `status=${params.status || urlParamsSearch.status || 'all'}&`
                        : ''
                }${params?.searchBy ? `searchBy=${params.searchBy}&` : ''}${
                    params?.searchText ? `searchText=${params.searchText}&` : ''
                }${params?.searchByDate ? `searchByDate=${params.searchByDate}&` : ''}${
                    params?.searchDate ? `searchDate=${params.searchDate}&` : ''
                }`,
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleViewRow = (e, row) => {
        history.push({
            pathname: `batch-print`,
            search: `?id=${row._id}`,
            state: { batchPrintParentData: row },
        });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItemsArchivePrints} />
                    <Header />
                    <Row>
                        <TableCustom
                            tableData={shippingLabelData}
                            filters={filters}
                            loading={loading}
                            showEdit={false}
                            showDelete={false}
                            removeId={false}
                            searchProp={searchParams}
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            isManualSearch
                            tableHeaderType="LABEL_PRINT_SHIPPING_LABEL_LIST"
                            handleView={handleViewRow}
                        />
                    </Row>
                </Container>
            </div>
        </>
    );
}
ArchivedBatchPrint.propTypes = {
    location: PropTypes.object,
};

export default ArchivedBatchPrint;
