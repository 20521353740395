import {
    GET_DELIVERY_PROGRESS_TRANSACTION,
    GET_RIDER_MODIFIED_TRANSACTION,
    GET_USER_MODIFIED_TRANSACTION,
    GET_CUSTOM_TRANSACTIONS_BY_TYPE_AND_DOCUMENT_ID,
    GET_PARTNER_API_KEY_TRANSACTION,
    GET_PARTNER_API_SANDBOX_ACCOUNT_TRANSACTION,
    GET_PARTNER_API_WEBHOOK_TRANSACTION,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const getUserModifiedTransactionsService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_USER_MODIFIED_TRANSACTION).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getRiderModifiedTransactionsService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_RIDER_MODIFIED_TRANSACTION).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getDeliveryProgressTransactionsService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_DELIVERY_PROGRESS_TRANSACTION).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getCustomTransactionsByTypeAndDocumentsService = (id, type) => {
    try {
        const url = UrlBuilder()
            .setUrl(GET_CUSTOM_TRANSACTIONS_BY_TYPE_AND_DOCUMENT_ID)
            .setPathParams({ type, id })
            .build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getPartnerApiKeyTransactionsService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_PARTNER_API_KEY_TRANSACTION).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getPartnerApiWebhookTransactionsService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_PARTNER_API_WEBHOOK_TRANSACTION).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getPartnerApiSandboxAccountTransactionsService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_PARTNER_API_SANDBOX_ACCOUNT_TRANSACTION).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
