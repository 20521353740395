export const getDeliveryStatus = (deliveryStatus, rider) => {
    if (deliveryStatus === 'dispatched') {
        return 'dispatchReady';
    }

    if (deliveryStatus === 'pickedUp') {
        return 'dispatched';
    }

    if (deliveryStatus === 'deliveryStarted') {
        return 'deliveryStarted';
    }

    if (deliveryStatus === 'completed') {
        return 'completed';
    }

    if (deliveryStatus === 'delivered') {
        return 'delivered';
    }

    if (rider) {
        return 'riderAssigned';
    }

    return 'delivering';
};
