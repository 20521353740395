/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Container,
    Spinner,
    CardHeader,
    ButtonGroup,
    Button,
    Media,
} from 'reactstrap';
import * as qs from 'query-string';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AvForm, AvField, AvCheckbox, AvCheckboxGroup } from 'availity-reactstrap-validation';

// #region assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import {
    Select,
    ClientAlerts,
    BackdropLoader,
    ImageUploadModal,
    CustomToast,
    FormStatusBtn,
    TableCustom,
} from 'components';
import { SubTitle } from 'styles';
import AttachmentPreviewModal from 'components/Modals/Image-Preview';
import CreatableAdvanced from 'components/CreateableSelect';
import { IMAGE_UPLOAD_AVATAR_IMAGE } from 'definitions/assets';

// #region utils
import { cleanObject, reactSelectCustomStyles, getDefaultValueForSelect, isObject } from 'utils/helpers';
import { getVisitingObject } from 'utils/checkAuth';
import { DEBUG_MODE } from 'utils/checks';

// #region imports
import PATHS from 'routes/paths';
import { SYSTEM_DATA_TYPES } from 'constants/enums';

// #region services
import { getAttachmentByKey } from 'services/attachment.service';
import { getAllSystemDataByTypeAndModuleService } from 'services/system-data.service';
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';
import { getCustomTransactionsByTypeAndDocumentsService } from 'services/transaction.service';

// #endregion imports
import { handleCreateSelectChange, handleSelectChange, handleCheckChange, handleImageUpdate } from './functions';
import { validateForm } from './utils';
import { createProductMasterDataService, updateProductMasterDataService } from './services';
import { transactionProductTableHeaders } from '../constants';
import ProductMasterDataTransactionModal from '../components';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Product Master Data', link: PATHS.PRODUCT_MASTER_DATA.DEFAULT },
];

// This is the initial format for Attachments same in default state in DB
const initialAttachmentState = {
    productPhotoUrl: { data: null, lastUpdated: null, isDbSaved: false },
};

function ProductFormPage(props) {
    const history = useHistory();

    const { visitingClient } = getVisitingObject();

    const initialFormValues = {
        country: visitingClient?.country || 'Malaysia',
    };

    // prev comp
    const [prevCompState, setPrevCompState] = useState({});

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [customLoading, setCustomLoading] = useState({ productCategory: false, unitOfMeasure: false });

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);

    const [imageUploadModalState, setImageUploadModalState] = useState({
        show: false,
        type: null,
        data: null,
        header: null,
        title: null,
        subTitle: null,
    });

    const [attachmentPreviewModalState, setAttachmentPreviewModalState] = useState({
        show: false,
        multiple: false,
        imageUrl: null,
    });

    // attachments
    const [initialAttachmentDb, setInitialAttachmentDb] = useState(initialAttachmentState); // If Editing Mode This will be replaced with Value from DB
    const [attachmentsObj, setAttachmentsObj] = useState(initialAttachmentState); // This state handles attachments

    // images ( all Images with presigned URL are stored here )
    const [images, setImages] = useState({
        productPhotoUrl: null,
    });

    // other
    const [merchants, setMerchants] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const [unitOfMeasures, setUnitOfMeasures] = useState([]);
    const [allProductTransaction, setAllProductTransactions] = useState({
        totalDocs: 0,
        headers: transactionProductTableHeaders,
        totalPages: 0,
        limit: 10,
        page: 1,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    // transaction modal data
    const [showTransactionModalData, setShowTransactionModalData] = useState({});

    // show transaction modal
    const [showTransactionModal, setShowTransactionModal] = useState(false);

    const _isMounted = useRef(true);

    useEffect(() => {
        if (!props.location) {
            history.goBack();
            return;
        }

        const formType = qs.parse(props.location.search);
        if (formType && formType.type && formType.type === 'new') {
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Product', link: '#' }]);
        }

        if (formType && formType.type && formType.type === 'edit') {
            handleUpdateComponentEditMode();
        }

        if (formType && formType.type && formType.type === 'view') {
            handleUpdateComponentViewMode();
        }

        const { prevCompState = null } = props.location.state || {};
        if (prevCompState) {
            setPrevCompState(prevCompState);
        }
    }, []);

    useEffect(() => {
        loadProductCategories();
        loadUnitOfMeasures();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (formData.attachmentObj) {
            // Load All Attachments Presigned Urls
            // Either this way or dont do this automatically presigned URL will load after preview modal opens
            // getAllAttachmentPresignedUrls();
            // Update Attachments Obj
            const cleanedDbObject = cleanObject(formData.attachmentObj);
            setAttachmentsObj((prevState) => ({ ...prevState, ...cleanedDbObject }));
        }
    }, [formData.attachmentObj]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                if (_isMounted.current) {
                    const { data } = res;
                    if (data.docs) {
                        setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                    }
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                if (_isMounted.current) {
                    const { data } = res;
                    if (data.docs) {
                        setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                    }
                }
            });
        }
    }, []);

    useEffect(() => {
        if (formData.merchantProductCode && formData.isUseMerchantCodeCrossPlatformReference) {
            setFormData((prevState) => ({
                ...prevState,
                crossPlatformReference: formData.merchantProductCode,
            }));
        }
    }, [formData.isUseMerchantCodeCrossPlatformReference, formData.merchantProductCode]);

    useEffect(() => {
        formData._id && loadProductTransactions();
    }, [formData._id]);

    useEffect(() => {
        if (attachmentsObj.productPhotoUrl.data && typeof attachmentsObj.productPhotoUrl.data === 'string') {
            loadImages('single', 'productPhotoUrl', attachmentsObj.productPhotoUrl.data);
        }
    }, [attachmentsObj.productPhotoUrl.data]);

    const loadProductTransactions = () => {
        if (formData._id) {
            getCustomTransactionsByTypeAndDocumentsService(formData._id, 'PRODUCT_MASTER_DATA').then((res) => {
                if (res && _isMounted.current) {
                    const { data } = res;
                    // data.docs && setAllProductTransactions(data.docs);
                    data.docs &&
                        setAllProductTransactions({
                            ...allProductTransaction,
                            ...data,
                            docs: data.docs.map((doc) => ({
                                ...doc,
                                username: doc?.user?.username,
                                transactionMadeByUserUsername: `${doc?.createdByUser?.fullName}-${doc?.createdByUser?.username}`,
                            })),
                        });
                }
            });
        }
    };

    const handleUpdateComponentViewMode = () => {
        try {
            !props.location.state && history.goBack();
            const { productMasterDataParentData } = props.location.state;
            setFormData({ ...formData, ...productMasterDataParentData });
            setIsViewMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'View User', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpdateComponentEditMode = () => {
        try {
            !props.location.state && history.goBack();
            const { productMasterDataParentData } = props.location.state;
            let updatedFormData = { ...formData, ...productMasterDataParentData };
            if (updatedFormData.merchantId && isObject(updatedFormData.merchantId)) {
                updatedFormData = {
                    ...updatedFormData,
                    merchant: updatedFormData.merchantId,
                    merchantId: updatedFormData.merchantId?._id,
                };
            }
            setFormData(updatedFormData);
            const { prevCompState = null } = props.location.state || {};
            // Update URL Based on Condition
            handleUpdateUrl(prevCompState, productMasterDataParentData, 'productMasterDataParentData');
            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Product Master Data', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    // Update Address Bar URL
    // PATH | QUERY | STATE
    const handleUpdateUrl = (prevState, data, id) => {
        // Handle Remove PrevComponent
        try {
            history.replace({
                pathname: `${PATHS.PRODUCT_MASTER_DATA.FORM_VIEW}`,
                search: `?type=edit&id=${data._id}`,
                state: {
                    [id]: data,
                    prevCompState: { ...prevState, refreshPage: true },
                },
            });
        } catch (e) {
            console.log(e);
        }
    };

    const loadProductCategories = async () => {
        try {
            const { data } = await getAllSystemDataByTypeAndModuleService(
                SYSTEM_DATA_TYPES.PRODUCT_MASTER_DATA.TYPES.PRODUCT_CATEGORY,
                SYSTEM_DATA_TYPES.PRODUCT_MASTER_DATA.MODULE
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                setProductCategories(data.map((x) => ({ label: x.name, value: x.name })));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadUnitOfMeasures = async () => {
        try {
            const { data } = await getAllSystemDataByTypeAndModuleService(
                SYSTEM_DATA_TYPES.PRODUCT_MASTER_DATA.TYPES.UNIT_OF_MEASURE,
                SYSTEM_DATA_TYPES.PRODUCT_MASTER_DATA.MODULE
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                setUnitOfMeasures(data.map((x) => ({ label: x.name, value: x.name })));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadImages = async (type, id, key) => {
        try {
            if (type === 'single' && key) {
                getAttachmentByKey(key)
                    .then((res) => {
                        if (res && _isMounted.current) {
                            setImages((prevState) => ({ ...prevState, [id]: res }));
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const { isFormValid, message } = validateForm(formData);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);

        try {
            let responseData;
            if (editingMode) {
                const { data } = await updateProductMasterDataService(formData);
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            } else {
                const { data } = await createProductMasterDataService(formData);
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            }
            setEditingMode(true);

            try {
                // Replace URL so relevant changes can be done
                if (responseData) {
                    history.replace({
                        pathname: `${PATHS.PRODUCT_MASTER_DATA.FORM_VIEW}`,
                        search: `?type=edit&id=${responseData._id}`,
                        state: {
                            productMasterDataParentData: responseData,
                            prevCompState,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} Product`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setIsFormSaved(true);
        } catch (e) {
            console.log(e);
            toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                position: 'top-right',
            });
        }

        loadProductTransactions();

        setFormLoading(false);
        setSubmitted(false);
    };

    const handleImageUpload = (header, type, title, fileTypes) => {
        setImageUploadModalState({
            show: true,
            data: null,
            header,
            type,
            title,
            fileTypes,
            onUpload: async (files) => {
                const newAttachmentObj = {
                    ...attachmentsObj,
                    [type]: {
                        data: files[0],
                        lastUpdated: new Date(),
                        isDbSaved: false,
                        name: files[0].name || '',
                    },
                };
                setAttachmentsObj(newAttachmentObj);
                setImageUploadModalState((prevState) => ({
                    ...prevState,
                    loading: false,
                    show: false,
                }));
                if (editingMode) {
                    setBackdropLoading((x) => x + 1);
                    await handleImageUpdate(
                        newAttachmentObj,
                        initialAttachmentDb,
                        setInitialAttachmentDb,
                        formData,
                        setFormData,
                        history,
                        prevCompState
                    );
                    setBackdropLoading((x) => x - 1);
                }
            },
        });
    };

    /**
     * Handle Select Change
     */
    const onHandleSelectChange = (event, id) => {
        handleSelectChange(event, id, setIsFormSaved, merchants, setFormData, formData);
    };

    const onHandleCreateChange = (record, id) => {
        handleCreateSelectChange(
            record,
            id,
            setIsFormSaved,
            setCustomLoading,
            setProductCategories,
            productCategories,
            setFormData,
            formData,
            setUnitOfMeasures,
            unitOfMeasures
        );
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const onHandleCheckChange = (event) => {
        handleCheckChange(event, setIsFormSaved, setFormData, formData);
    };

    /**
     * Handles the transaction modal type and their visibility
     * @param {object} data - data comes from the row of the table
     * @void
     */
    const toggleTransactionModal = (data) => {
        setShowTransactionModalData(data);
        setShowTransactionModal(!showTransactionModal);
    };
    const displayModal = (row) => {
        toggleTransactionModal(row);
    };

    const getMerchantSelect = (id) => {
        // eslint-disable-next-line no-console
        DEBUG_MODE() && console.log('select-func');
        if (id) {
            const relevantObj = merchants.find((x) => x._id === id);
            if (relevantObj) {
                return { label: relevantObj.name, value: relevantObj._id };
            }
        }
        return null;
    };

    // ** Memos

    const selectedProductCategory = useMemo(
        () => getDefaultValueForSelect(formData.productCategory),
        [formData.productCategory]
    );

    const selectedUnitOfMeasure = useMemo(
        () => getDefaultValueForSelect(formData.unitOfMeasure),
        [formData.unitOfMeasure]
    );

    const selectedMerchantId = useMemo(() => getMerchantSelect(formData.merchantId), [formData.merchantId, merchants]);

    const selectedTakeawayMethod = useMemo(
        () => getDefaultValueForSelect(formData.takeawayMethod),
        [formData.takeawayMethod]
    );

    // -----------------------------------------------------------

    const closeImageUploadModal = () => {
        setImageUploadModalState({
            show: false,
            data: null,
            type: null,
        });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={isViewMode ? 'View Product' : editingMode ? 'Edit Product' : 'New Product'}
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Row style={{ justifyContent: 'space-between' }}>
                        {!editingMode && (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                onClick={() => {
                                    history.push({
                                        pathname: PATHS.PRODUCT_MASTER_DATA.DEFAULT,
                                    });
                                }}
                            >
                                <i className="far fa-times-circle mr-2 fa-lg" />
                                Cancel
                            </motion.div>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FormStatusBtn isFormSaved={isFormSaved} />
                        </div>
                        {editingMode && (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                onClick={() => {
                                    history.push({
                                        pathname: PATHS.PRODUCT_MASTER_DATA.DEFAULT,
                                    });
                                }}
                            >
                                <i className="fas fa-arrow-left mr-2 fa-lg" />
                                Back to Products
                            </motion.div>
                        )}
                    </Row>
                    <Row>
                        <Col md={editingMode ? 6 : 12}>
                            <Card>
                                <CardBody>
                                    {!editingMode && <ClientAlerts />}
                                    <Row className="d-flex justify-content-between ml-3 mr-3 ml-n2 mb-2">
                                        <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                            PRODUCT DETAILS
                                        </SubTitle>
                                    </Row>
                                    <AvForm
                                        autoComplete="off"
                                        className="needs-validation default-form custom-form"
                                        noValidate
                                        onSubmit={handleSubmit}
                                        id="productMasterDataForm"
                                    >
                                        <input type="hidden" value="something" />
                                        <fieldset disabled={isViewMode || formLoading}>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="merchantId">Merchant</Label>
                                                        <Select
                                                            value={selectedMerchantId}
                                                            isClearable
                                                            options={merchants}
                                                            styles={reactSelectCustomStyles}
                                                            onChange={(event) =>
                                                                onHandleSelectChange(event, 'merchantId')
                                                            }
                                                            submitted={submitted}
                                                            validated={validated}
                                                            required
                                                            isDisabled={editingMode}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="productDescription">Product Description</Label>
                                                        <AvField
                                                            name="productDescription"
                                                            placeholder="Product Description"
                                                            type="text"
                                                            className="form-control"
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: 'Enter a valid description',
                                                                },
                                                            }}
                                                            id="productDescription"
                                                            value={formData.productDescription}
                                                            onChange={handleInputChange}
                                                            disabled={formData.isApproved}
                                                            autoComplete="off"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="productCategory">Product Category</Label>
                                                        <CreatableAdvanced
                                                            options={productCategories}
                                                            isClearable
                                                            value={selectedProductCategory}
                                                            submitted={submitted}
                                                            validated={validated}
                                                            required
                                                            // isDisabled={editingMode}
                                                            styles={reactSelectCustomStyles}
                                                            id="productCategory"
                                                            isLoading={customLoading.productCategory}
                                                            onChange={(event) =>
                                                                onHandleSelectChange(event, 'productCategory')
                                                            }
                                                            onCreate={(event) =>
                                                                onHandleCreateChange(event, 'productCategory')
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="merchantProductCode">
                                                            Merchant Product Code
                                                        </Label>
                                                        <AvField
                                                            name="merchantProductCode"
                                                            placeholder="Merchant Product Code"
                                                            type="text"
                                                            className="form-control"
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: 'Enter Valid Code',
                                                                },
                                                            }}
                                                            id="merchantProductCode"
                                                            value={formData.merchantProductCode}
                                                            onChange={handleInputChange}
                                                            disabled={formData.isApproved}
                                                            autoComplete="off"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="crossPlatformReference">
                                                            Cross Platform Reference
                                                        </Label>
                                                        <AvField
                                                            name="crossPlatformReference"
                                                            placeholder="Cross Platform Reference"
                                                            type="text"
                                                            className="form-control"
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: 'Enter Valid Reference',
                                                                },
                                                            }}
                                                            id="crossPlatformReference"
                                                            value={formData.crossPlatformReference}
                                                            onChange={handleInputChange}
                                                            disabled={
                                                                formData.isApproved ||
                                                                formData.isUseMerchantCodeCrossPlatformReference
                                                            }
                                                            autoComplete="off"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <Label htmlFor="isUseMerchantCodeCrossPlatformReference">
                                                        {''}
                                                    </Label>
                                                    <AvCheckboxGroup
                                                        name="isUseMerchantCodeCrossPlatformReference"
                                                        className="form-check-group mt-2"
                                                    >
                                                        <AvCheckbox
                                                            label="Use the Merchant Code"
                                                            checked={formData.isUseMerchantCodeCrossPlatformReference}
                                                            id="isUseMerchantCodeCrossPlatformReference"
                                                            onChange={onHandleCheckChange}
                                                        />
                                                    </AvCheckboxGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="skuCode">SKU Code</Label>
                                                        <AvField
                                                            name="skuCode"
                                                            placeholder="SKU Code"
                                                            type="text"
                                                            className="form-control"
                                                            id="skuCode"
                                                            value={formData.skuCode}
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: 'Enter a valid Sku Code',
                                                                },
                                                            }}
                                                            onChange={handleInputChange}
                                                            disabled={formData.isApproved}
                                                            autoComplete="off"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="upc">UPC</Label>
                                                        <AvField
                                                            name="upc"
                                                            placeholder="UPC"
                                                            type="text"
                                                            className="form-control"
                                                            id="upc"
                                                            value={formData.upc}
                                                            onChange={handleInputChange}
                                                            disabled={formData.isApproved}
                                                            autoComplete="off"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="uan">UAN</Label>
                                                        <AvField
                                                            name="uan"
                                                            placeholder="UAN"
                                                            type="text"
                                                            className="form-control"
                                                            id="uan"
                                                            value={formData.uan}
                                                            onChange={handleInputChange}
                                                            disabled={formData.isApproved}
                                                            autoComplete="off"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="unitOfMeasure">Unit Of Measure</Label>
                                                        <CreatableAdvanced
                                                            options={unitOfMeasures}
                                                            isClearable
                                                            value={selectedUnitOfMeasure}
                                                            submitted={submitted}
                                                            validated={validated}
                                                            required
                                                            isDisabled={editingMode}
                                                            styles={reactSelectCustomStyles}
                                                            id="unitOfMeasure"
                                                            isLoading={customLoading.unitOfMeasure}
                                                            onChange={(event) =>
                                                                onHandleSelectChange(event, 'unitOfMeasure')
                                                            }
                                                            onCreate={(event) =>
                                                                onHandleCreateChange(event, 'unitOfMeasure')
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="takeawayMethod">Takeaway Method</Label>
                                                        <Select
                                                            value={selectedTakeawayMethod}
                                                            isClearable
                                                            options={[
                                                                { label: 'FIFO', value: 'FIFO' },
                                                                { label: 'FEFO', value: 'FEFO' },
                                                            ]}
                                                            styles={reactSelectCustomStyles}
                                                            onChange={(event) =>
                                                                onHandleSelectChange(event, 'takeawayMethod')
                                                            }
                                                            submitted={submitted}
                                                            validated={validated}
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="minimumStockLevel">Minimum Stock Level</Label>
                                                        <AvField
                                                            name="minimumStockLevel"
                                                            placeholder="Minimum Stock Level"
                                                            type="number"
                                                            className="form-control"
                                                            id="minimumStockLevel"
                                                            value={formData.minimumStockLevel}
                                                            onChange={handleInputChange}
                                                            disabled={formData.isApproved}
                                                            autoComplete="off"
                                                            min={1}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={editingMode ? 6 : 4}>
                                                    <FormGroup>
                                                        <Label htmlFor="minimumShelfLife">Minimum Shelf Life %</Label>
                                                        <AvField
                                                            name="minimumShelfLife"
                                                            placeholder="Minimum Shelf Life"
                                                            type="number"
                                                            className="form-control"
                                                            id="minimumShelfLife"
                                                            value={formData.minimumShelfLife}
                                                            onChange={handleInputChange}
                                                            disabled={formData.isApproved}
                                                            autoComplete="off"
                                                            min={1}
                                                            max={100}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="pl-3">
                                                <Label htmlFor="materialHandling">Material Handling</Label>
                                            </Row>

                                            <AvCheckboxGroup
                                                name="isUseMerchantCodeCrossPlatformReference"
                                                className="form-check-group mt-2"
                                            >
                                                <Row className="pl-3">
                                                    <Col md={2}>
                                                        <AvCheckbox
                                                            label="Fragile"
                                                            checked={formData.isFragile}
                                                            id="isFragile"
                                                            onChange={onHandleCheckChange}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <AvCheckbox
                                                            label="Frozen"
                                                            checked={formData.isFrozen}
                                                            id="isFrozen"
                                                            onChange={onHandleCheckChange}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <AvCheckbox
                                                            label="High Value"
                                                            checked={formData.isHighValue}
                                                            id="isHighValue"
                                                            onChange={onHandleCheckChange}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <AvCheckbox
                                                            label="Chill"
                                                            checked={formData.isChill}
                                                            id="isChill"
                                                            onChange={onHandleCheckChange}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <AvCheckbox
                                                            label="Halal"
                                                            checked={formData.isHalal}
                                                            id="isHalal"
                                                            onChange={onHandleCheckChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </AvCheckboxGroup>
                                            <br />
                                            <Row style={{ justifyContent: 'flex-start' }}>
                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                    type="submit"
                                                    disabled={formLoading}
                                                >
                                                    {formLoading ? (
                                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                    ) : (
                                                        <>
                                                            <i className="fas fa-save left-icon"></i>
                                                            <span className="h6">
                                                                {editingMode ? 'Update' : 'Save'}
                                                            </span>
                                                        </>
                                                    )}
                                                </motion.button>
                                            </Row>
                                        </fieldset>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                        {editingMode && (
                            <Col md={6}>
                                <Card
                                    style={{ borderTopRightRadius: 15, borderTopLeftRadius: 15 }}
                                    className="shadow-lg"
                                >
                                    <CardHeader
                                        className="border-bottom"
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 'bolder',
                                            backgroundColor: '#f2ebeb',
                                            borderTopRightRadius: 15,
                                            borderTopLeftRadius: 15,
                                        }}
                                    >
                                        Product Photo
                                    </CardHeader>
                                    <CardBody className="justify-content-center">
                                        <Row className="justify-content-center">
                                            <Card className="shadow-lg" style={{ minWidth: 350, minHeight: 350 }}>
                                                <CardBody
                                                    className="p-4"
                                                    style={{
                                                        backgroundColor: '#FCFCFC',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {images.productPhotoUrl ? (
                                                        <Media
                                                            // className="image-upload-avatar"
                                                            onClick={() =>
                                                                handleImageUpload(
                                                                    'Upload Product Photo',
                                                                    'productPhotoUrl',
                                                                    'Product Photo',
                                                                    'image/jpeg, image/png'
                                                                )
                                                            }
                                                            src={images.productPhotoUrl}
                                                            style={{ width: 370, height: 370 }}
                                                        />
                                                    ) : (
                                                        <Media
                                                            // className="image-upload-avatar"
                                                            onClick={() =>
                                                                handleImageUpload(
                                                                    'Upload Product Photo',
                                                                    'productPhotoUrl',
                                                                    'Product Photo',
                                                                    'image/jpeg, image/png'
                                                                )
                                                            }
                                                            src={IMAGE_UPLOAD_AVATAR_IMAGE}
                                                            style={{ width: 100, height: 100 }}
                                                        />
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </Row>
                                        <br />
                                        <Row className="justify-content-center">
                                            <ButtonGroup>
                                                <Button
                                                    style={{ borderRadius: 20, width: 140 }}
                                                    color="warning"
                                                    onClick={() => {
                                                        handleImageUpload(
                                                            'Upload Product Photo',
                                                            'productPhotoUrl',
                                                            'Product Photo'
                                                        );
                                                    }}
                                                >
                                                    {attachmentsObj.productPhotoUrl.data ? 'Update' : 'Upload'}
                                                </Button>
                                            </ButtonGroup>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>
                    <br />
                    {editingMode && (
                        <>
                            <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                                <CardBody>
                                    <div>
                                        <Col>
                                            <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}>
                                                SYSTEM TRANSACTIONS
                                            </SubTitle>
                                        </Col>
                                        <br />

                                        <TableCustom
                                            id="product-master-data-table-id"
                                            tableData={allProductTransaction}
                                            removeAction
                                            showDelete={false}
                                            showPagination={false}
                                            showView={false}
                                            removeId
                                            isFullScreenShow={false}
                                            isFilter={false}
                                            isSearch={false}
                                            isFixedHeight
                                            showApiCustomAction
                                            customActionHandlers={{ displayModal }}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <br />

                            {/* Transaction modal */}
                            <ProductMasterDataTransactionModal
                                show={showTransactionModal}
                                handleToggle={toggleTransactionModal}
                                data={showTransactionModalData}
                            />
                        </>
                    )}
                </Container>
            </div>

            {imageUploadModalState.show && (
                <ImageUploadModal toggleModal={closeImageUploadModal} {...imageUploadModalState} />
            )}

            {attachmentPreviewModalState.show && (
                <AttachmentPreviewModal
                    image={attachmentPreviewModalState.imageUrl}
                    show={attachmentPreviewModalState.show}
                    type={attachmentPreviewModalState.type}
                    header={attachmentPreviewModalState.header}
                    toggleModal={() => {
                        setAttachmentPreviewModalState({ show: false, multiple: false });
                    }}
                    {...attachmentPreviewModalState}
                    isFormAttachmentModal
                />
            )}

            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

ProductFormPage.propTypes = {};

const mapStateToProps = (state) => ({
    ...state,
});

const HotProductFormPagePage = hot(ProductFormPage);

export default connect(mapStateToProps, {})(HotProductFormPagePage);
