/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, Row, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import * as qs from 'query-string';

// #region assets | components
import ExternalLinkIcon from 'components/Icons/ExternalLink';
import DisconnectIcon from 'components/Icons/Disconnect';
import ConnectIcon from 'components/Icons/Connect';
import { CustomToast, TableCustom } from 'components';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #region services
import {
    enableApiServiceByMerchantUser,
    getPartnerApiDataByMerchantId,
    getApiKeyData,
    showSecretKey,
    disableApiServiceByMerchantUser,
} from 'services/partner-api.service';

// #region other imports
import PATHS from 'routes/paths';

// #endregion imports
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS, PERMISSION_TYPES } from 'utils/constants';
import EnableApiServiceModal from './components/EnableApiServiceModal';
import DisableApiServiceModal from '../../components/DisableApiServiceModal';
import { apiKeyListTableHeaders } from './constants';
import { mapDocs } from './utils';
import ViewSecretKeyModal from './components/ViewSecretKey';

function BasicSettingsTab(props) {
    const { merchantId, merchantName, location } = props;
    const history = useHistory();

    /** Currently selected merchant */
    const [merchant, setMerchant] = useState(null);
    /** Which modal to show. Name can be 'enable', 'disable', 'secret' or null */
    const [showModal, setShowModal] = useState({ name: null, data: null });
    /** Whether table data is being fetched currently */
    const [apiKeyDataLoading, setApiKeyDataLoading] = useState(false);
    /** Whether merchant data is being fetched currently */
    const [merchantDataLoading, setMerchantDataLoading] = useState(false);
    /** Table search parameters */
    const [searchParams, setSearchParams] = useState(null);
    /** Data for the table */
    const [apiKeyList, setApiKeyList] = useState({
        matchedCount: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: apiKeyListTableHeaders,
        docs: [],
        sort: null,
        search: undefined,
        order: -1,
        status: ['active'],
    });
    /** URL search parameters */
    const [urlParamsSearch, setUrlParamsSearch] = useState({});

    const defaultSearchParams = {
        page: searchParams?.page,
        searchText: searchParams?.searchText,
        limit: searchParams?.limit,
        status: searchParams?.status,
    };

    /** Triggers the loadApiKeyData function if the URL search params (in urlParamsSearch state) have changed */
    useEffect(() => {
        if (urlParamsSearch) {
            loadApiKeyData(
                urlParamsSearch.page,
                urlParamsSearch.limit,
                apiKeyList.sort,
                apiKeyList.order,
                {
                    searchBy: 'name',
                    searchText: urlParamsSearch?.searchText,
                },
                urlParamsSearch.status
            );
        }
    }, [urlParamsSearch]);

    /** Reads the URL and updates the urlParamsSearch state, which triggers another useEffect to fetch data */
    useEffect(() => {
        const urlSearch = qs.parse(location.search);
        if (urlSearch && (urlSearch.searchText || urlSearch.page || urlSearch.status)) {
            const { page, searchBy, searchText, limit, status } = urlSearch;
            setUrlParamsSearch({ page, searchBy, searchText, limit, status });
            setSearchParams({ searchBy, searchText });
        } else {
            replaceUrlParamsHandler({ page: '1', limit: 10, status: 'active' });
        }
    }, [location.search]);

    /** Re-fetches the merchant data if the selected merchant has changed */
    useEffect(() => {
        loadPartnerApiDataByMerchantId();
    }, [merchantId]);

    /**
     * Replaces the URL params when user types in the search box
     * @param {object} params - should contain page (string), limit (string), searchBy (string), searchText (string)
     */
    const replaceUrlParamsHandler = (
        params = { page: '1', limit: '10', searchBy: null, searchText: null, status: null }
    ) => {
        // do not run this for other tabs except basic settings tab
        if (!location.search.includes('tab=basicSettings')) {
            return;
        }

        try {
            history.replace({
                search: `?tab=basicSettings&${
                    params.page || urlParamsSearch.page ? `page=${params.page || urlParamsSearch.page || '1'}&` : ''
                }${
                    params.limit || urlParamsSearch.limit
                        ? `limit=${params.limit || urlParamsSearch.limit || '10'}&`
                        : ''
                }${params?.searchBy ? `searchBy=${params.searchBy}&` : ''}${
                    params?.searchText ? `searchText=${params.searchText}&` : ''
                }${params?.status ? `status=${params.status}&` : ''}`,
                state: location.state,
            });
        } catch (e) {
            console.error(e);
            toast.custom(
                (t) => <CustomToast text="Failed to update URL to match search parameters" t={t} type="error" />,
                { position: 'top-right' }
            );
        }
    };

    /** Calls the relevant service to get merchant's partner API data */
    const loadPartnerApiDataByMerchantId = async () => {
        setMerchantDataLoading(true);
        if (!merchantId) {
            return;
        }

        try {
            const { data } = await getPartnerApiDataByMerchantId(merchantId);
            const { payload } = data;
            if (data && payload) {
                setMerchant(payload);
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setMerchantDataLoading(false);
    };

    /**
     * Calls the relevant service to get API key list
     * @param {number} page - page number
     * @param {number} limit - number of entries to paginate by
     * @param {string} sort - field to sort by
     * @param {number} order - order to sort by: -1 or 1
     * @param {string} status - whether 'active' or 'inactive' keys to fetch
     * @param {object} searchByProp - search prop - contains searchBy and searchText fields
     */
    const loadApiKeyData = async (page, limit, sort, order, searchByProp, status) => {
        setApiKeyDataLoading(true);

        if (!merchantId) {
            return;
        }

        // do not run this for other tabs except basic settings tab
        if (!location.search.includes('tab=basicSettings')) {
            setApiKeyDataLoading(false);
            return;
        }

        try {
            const { data } = await getApiKeyData(page, limit, sort, order, searchByProp, status, merchantId);
            const { payload } = data;
            if (data && payload) {
                const mappedDocs = mapDocs(payload.docs);
                setApiKeyList({ ...apiKeyList, ...payload, docs: mappedDocs });
            } else {
                toast.custom((t) => <CustomToast text="Failed to load API Key list" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setApiKeyDataLoading(false);
    };

    /** Handles table filter and adds those parameters to the URL */
    const handleFilter = (field, filterText) => {
        let modifiedFilterText = null;
        if (filterText && filterText !== '') {
            modifiedFilterText = filterText.replaceAll(' ', '$2');
        } else if (!filterText || filterText === '') {
            modifiedFilterText = null;
        }

        replaceUrlParamsHandler({
            ...defaultSearchParams,
            searchBy: field || 'name',
            searchText: modifiedFilterText,
            page: '1',
        });
    };

    /** Handles page change and adds those parameters to the URL */
    const handlePageChange = (pageNo) => {
        replaceUrlParamsHandler({
            ...defaultSearchParams,
            page: pageNo,
        });
    };

    /**
     * Load the 'Manage API Key' page for the selected row
     * @param {object} data - data from the row where 'Manage' was clicked
     */
    const navigateToManagePage = (data) => {
        if (!data._id) {
            return;
        }
        // TODO! add a tost or something and handle this
        if (data.name === 'shopify_default') {
            return;
        }
        history.push({
            pathname: `/${PATHS.DEVELOPER.API_KEY_MANAGE}`,
            state: { merchantId, merchantName, apiKey: data },
        });
    };

    /** Open the secret key modal */
    const openSecretKey = (data) => {
        setShowModal({ name: 'secret', data: data._id });
    };

    /**
     * Controls the toggling of Enable/Disable API Service modals
     * @param {string} modalName - name of the modal to show. Leave undefined to hide modals.
     */
    const handleModalToggle = (modalName) => {
        if (modalName) {
            setShowModal({ ...showModal, name: modalName });
        } else {
            setShowModal({ name: null, data: null });
        }
    };

    /**
     * Once the user submits a modal, call the relevant endpoint and display the response in a toast.
     * @param {object} formData - data entered in the modal. Should contain a 'password' field.
     */
    const handleServiceStatusModalSubmit = async (formData) => {
        const handlerResponse = {};
        try {
            let serviceResponse;
            if (merchant.serviceStatusMerchant) {
                serviceResponse = await disableApiServiceByMerchantUser(merchant.merchantId, formData);
            } else {
                serviceResponse = await enableApiServiceByMerchantUser(merchant.merchantId, formData);
            }

            if (serviceResponse.status === 200) {
                const toastMessage = `API service ${
                    merchant.serviceStatusMerchant ? 'disabled' : 'enabled'
                } successfully`;
                toast.custom((t) => <CustomToast text={toastMessage} t={t} type="success" />, {
                    position: 'top-right',
                });
                setMerchant((prev) => ({ ...prev, serviceStatusMerchant: !merchant.serviceStatusMerchant }));
            } else {
                toast.custom((t) => <CustomToast text="Failed to update API service status" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
            handlerResponse.error = true;
        }

        return handlerResponse;
    };

    /** Navigate to the Generate API Key page */
    const handleNewApiKeyButton = () => {
        history.push({
            pathname: `/${PATHS.DEVELOPER.API_KEY_CREATE}`,
            state: { merchantId, merchantName },
        });
    };

    /**
     * Handles the changing of checkboxes in the table header.
     * This function is passed to the custom header as a callback,
     * via the apiKeysTableHeaderProps prop on the TableCustom component.
     * Un-checking operations are ignored because at least one value should always be true.
     * @param {string} checkbox - name of the checkbox ('active' or 'inactive')
     * @param {boolean} value - whether checked or unchecked
     */
    const handleCheckChange = (checkbox, value) => {
        let newStatus;
        if (value === true) {
            // A checkbox was checked
            // If already checked, ignore
            if (apiKeyList.status.includes(checkbox)) return;

            newStatus = [...apiKeyList.status, checkbox];

            // If one checkbox was checked, set the selected checkbox as the status
            if (newStatus.length === 1) {
                replaceUrlParamsHandler({
                    ...defaultSearchParams,
                    status: newStatus[0],
                });
            }
        } else {
            // A checkbox was unchecked
            newStatus = apiKeyList.status.filter((status) => status !== checkbox);

            // If one checkbox was unchecked, that status value of the remaining one should be the query
            if (newStatus.length === 1) {
                replaceUrlParamsHandler({
                    ...defaultSearchParams,
                    status: newStatus[0],
                });
            }
        }

        // If both checkboxes checked or unchecked, clear the status query - to fetch all
        if (newStatus.length === 2 || newStatus.length === 0) {
            replaceUrlParamsHandler({
                ...defaultSearchParams,
                status: null,
            });
        }

        setApiKeyList((prev) => ({ ...prev, status: newStatus }));
    };

    /**
     * Call the service to retrieve the secret key
     * @param {string}} apiKeyId - ID of the API key
     * @param {object} modalFormData - should contain a 'password' field
     */
    const handleViewSecretKeyModalSubmit = async (apiKeyId, modalFormData) => {
        try {
            const { data } = await showSecretKey(apiKeyId, modalFormData);
            const { payload } = data;
            const { secretKey } = payload;
            if (data && payload && secretKey) {
                return { secretKey };
            }

            toast.custom((t) => <CustomToast text="Failed to retrieve secret key" t={t} type="error" />, {
                position: 'top-right',
            });
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        return { error: true };
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Card className="shadow default-card">
                            <CardHeader
                                className="border-bottom pl-5"
                                style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}
                            >
                                <Row>
                                    <Col>
                                        Service Status Settings
                                        {merchantDataLoading && <Spinner size="sm" className="ml-4" />}
                                        {merchant && (
                                            <>
                                                {merchant.serviceStatusMerchant ? (
                                                    <Badge
                                                        className="ml-4 p-1 px-2"
                                                        color="success"
                                                        style={{ backgroundColor: '#26C60C' }}
                                                    >
                                                        ACTIVE
                                                    </Badge>
                                                ) : (
                                                    <Badge className="ml-4 p-1 px-2" color="danger">
                                                        INACTIVE
                                                    </Badge>
                                                )}
                                            </>
                                        )}
                                    </Col>
                                    <Col xs="auto">
                                        <Button
                                            className="scootix-btn-radius"
                                            style={{ borderRadius: 5 }}
                                            onClick={() => {
                                                window.open(
                                                    `${PATHS.MERCHANT.MERCHANT_FORM}?type=edit&id=${merchantId}`,
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            <ExternalLinkIcon />
                                            <span>VIEW MERCHANT</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            {hasAccess(PERMISSIONS.PARTNER_API_BASIC_SETTINGS, [PERMISSION_TYPES.enableAndDisable]) && (
                                <CardBody className="pl-5 py-4">
                                    {merchant && merchant.serviceStatusMerchant ? (
                                        <Button
                                            style={{
                                                backgroundColor: '#FD4A4A',
                                                borderRadius: 10,
                                                borderColor: '#FD4A4A',
                                            }}
                                            onClick={() => handleModalToggle('disable')}
                                            disabled={merchantDataLoading}
                                        >
                                            <DisconnectIcon color="#FFF" />
                                            &nbsp;Disable
                                        </Button>
                                    ) : (
                                        <Button
                                            style={{
                                                backgroundColor: '#4AB054',
                                                borderRadius: 10,
                                                borderColor: '#4AB054',
                                            }}
                                            onClick={() => handleModalToggle('enable')}
                                            disabled={merchantDataLoading}
                                        >
                                            <ConnectIcon color="#FFF" />
                                            &nbsp;Enable
                                        </Button>
                                    )}
                                </CardBody>
                            )}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {hasAccess(PERMISSIONS.PARTNER_API_BASIC_SETTINGS, ['View']) && (
                            <Card className="shadow default-card">
                                <CardHeader
                                    className="border-bottom pl-5"
                                    style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}
                                >
                                    Manage API Keys
                                </CardHeader>
                                <CardBody>
                                    <TableCustom
                                        tableData={apiKeyList}
                                        loading={apiKeyDataLoading}
                                        onFilter={handleFilter}
                                        onPageChange={handlePageChange}
                                        customActionHandlers={{ navigateToManagePage, openSecretKey }}
                                        showApiBasicSettingsAction={hasAccess(PERMISSIONS.PARTNER_API_BASIC_SETTINGS, [
                                            'EnableAndDisable',
                                        ])}
                                        searchProp={searchParams}
                                        removeAction
                                        tableHeaderType="MANAGE_API_KEYS"
                                        isFixedHeight
                                        apiKeysTableHeaderProps={{
                                            handleNewApiKeyButton,
                                            handleCheckChange,
                                            checked: apiKeyList.status,
                                        }}
                                        filters={[
                                            {
                                                key: 'name',
                                                label: 'Name',
                                            },
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        )}
                    </Col>
                </Row>
            </Container>
            <EnableApiServiceModal
                show={showModal.name === 'enable'}
                handleSubmit={handleServiceStatusModalSubmit}
                handleToggle={handleModalToggle}
                merchantName={merchant ? merchant.merchantName : 'loading..'}
            />
            <DisableApiServiceModal
                show={showModal.name === 'disable'}
                handleSubmit={handleServiceStatusModalSubmit}
                handleToggle={handleModalToggle}
                data={{ merchantName: merchant ? merchant.merchantName : 'loading..' }}
            />
            <ViewSecretKeyModal
                show={showModal.name === 'secret'}
                handleToggle={handleModalToggle}
                handleSubmit={handleViewSecretKeyModalSubmit}
                apiKeyId={showModal.data}
            />
        </>
    );
}

BasicSettingsTab.propTypes = {
    merchantId: PropTypes.string.isRequired,
    merchantName: PropTypes.string.isRequired,
    location: PropTypes.any,
};

export default BasicSettingsTab;
