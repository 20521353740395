/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Row, Col, FormGroup, Label, Spinner, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';
import Lottie from 'react-lottie';

// #region assets | components
import NoDataLottie from 'assets/animations/6226-nodata.json';
import { CustomToast, Select } from 'components';
import { centerElementContainerStyles } from 'styles';

// #region utils
import { getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { cleanExcelColumns, cleanExcelDocs, exportToExcel } from 'utils/excel';

// #endregion imports
import './index.css';
import moment from 'moment';

const typesOptions = [
    { label: 'Excel', value: 'Excel' },
    { label: 'PDF', value: 'PDF' },
];

const initialFormValues = {
    type: 'Excel',
};

const notFoundLottieOptions = (lottieName) => ({
    loop: true,
    autoplay: true,
    animationData: lottieName || '',
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
});

/**
 * This is custom export modal which we can use for exporting graph data and ect.
 * @param {*} props
 * @returns the export modal
 */
function ExportModal(props) {
    const {
        toggleModal,
        header = 'Export',
        description,
        data = { records: [], columnNames: [] },
        excelTabName,
        fileName,
        period,
        merchantName = 'All',
    } = props;

    // form state
    const [loading, setLoading] = useState(0);
    const [formData, setFormData] = useState(initialFormValues);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);

    const [columns, setColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);

    useEffect(() => {
        if (data.columnNames) {
            const allColumns = data.columnNames.map((x) => ({
                ...x,
                label: x.value,
                value: x.key,
            }));
            setColumns(allColumns);
            setSelectedColumns(allColumns);
        }
    }, []);

    useEffect(() => {
        console.log(data);
        if (formData.type === 'Excel') {
            setFormData((prevState) => ({
                ...prevState,
                fileName: fileName,
            }));
        }
    }, [formData.type]);

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    /**
     * Multi Select Supplier Recipients Handler
     */
    const handleMultiColumnChange = (option) => {
        setSelectedColumns(option);
    };

    const handleExcelExport = async () => {
        setLoading((prevState) => prevState + 1);

        const cleanedColumns = cleanExcelColumns(selectedColumns);
        const cleanedDocs = cleanExcelDocs(data.records);
        // Change Columns Object Keys Required As Per Excel Format
        const customColumns = cleanedColumns.map((val) => {
            const newVal = val;
            newVal.header = newVal.label;
            return newVal;
        });

        try {
            await exportToExcel({
                title: header,
                description: description,
                period: period,
                worksheetName: excelTabName,
                columns: customColumns,
                rows: cleanedDocs,
                fileName: formData.fileName,
                merchant: merchantName || 'All',
                generatedAt: moment().format('MM/DD/YYYY HH:mm:ss'),
            });
            toast.custom((t) => <CustomToast text="Successfully Exported Excel" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            console.log(e);
        }
        setSubmitted(false);
        setLoading((prevState) => prevState - 1);
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        if (data.records.length === 0) {
            toast.custom((t) => <CustomToast text="No Data Found On Table" t={t} type="error" />, {
                position: 'top-right',
            });
            return;
        }

        if (formData.type === 'Excel') {
            handleExcelExport();
        }
    };

    return (
        <>
            <Modal
                fullscreen="xl"
                size="xl"
                isOpen
                toggle={toggleModal}
                center
                className="modal-dialog modal-dialog-centered"
            >
                <ModalHeader toggle={toggleModal}>{header}</ModalHeader>
                <ModalBody>
                    <AvForm
                        className="needs-validation default-form"
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                        id="userRegistrationForm"
                    >
                        {data.records.length > 0 && (
                            <>
                                <Row>
                                    <Col md="3">
                                        <Label className="mt-2" htmlFor="type">
                                            Select Export type
                                        </Label>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Select
                                                value={getDefaultValueForSelect(formData.type)}
                                                options={typesOptions.map((val) => ({
                                                    label: val.label,
                                                    value: val.value,
                                                }))}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'type')}
                                                required
                                                submitted={submitted}
                                                isDisabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {formData.type === 'Excel' && (
                                    <Row>
                                        <Col md="3">
                                            <Label className="mt-2" htmlFor="columns">
                                                Columns
                                            </Label>
                                        </Col>
                                        <Col md="9">
                                            <FormGroup>
                                                <Select
                                                    value={selectedColumns}
                                                    options={columns}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={handleMultiColumnChange}
                                                    submitted={submitted}
                                                    required
                                                    isMulti
                                                    // className="select2 select2-multiple "
                                                    validated={validated}
                                                    // isDisabled={!selectedRole.roleName}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col md="3">
                                        <Label className="mt-2" htmlFor="fileName">
                                            File Name
                                        </Label>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <AvField
                                                name="fileName"
                                                placeholder="File Name"
                                                type="text"
                                                errorMessage="Enter File Name"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="fileName"
                                                value={formData.fileName}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {data.records.length === 0 && (
                            <Row style={centerElementContainerStyles}>
                                <div
                                    style={{
                                        height: '200px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {/* <img src={SEARCH_NO_RESULTS_IMAGE} alt="No Results Found" height={80} width={80} /> */}
                                    <Lottie
                                        className="page-center-lottie"
                                        options={notFoundLottieOptions(NoDataLottie)}
                                        height={100}
                                        width={100}
                                        speed={0.8}
                                        // style={{ marginLeft: -12 }}
                                    />
                                    <br />
                                    <span style={{ fontWeight: 'bolder', fontSize: 22, marginTop: -20 }}>
                                        Looks Like No Data Were Found In The Table!
                                    </span>
                                </div>
                            </Row>
                        )}

                        <Row style={{ justifyContent: 'flex-end' }}>
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="cancel-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                onClick={toggleModal}
                            >
                                <i className="fas fa-ban mr-2 fa-lg" />
                                Cancel
                            </motion.div>
                            {data.records.length > 0 && (
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="export-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                    type="submit"
                                    disabled={loading > 0}
                                >
                                    {loading > 0 ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>
                                            <i className="fas fa-save left-icon"></i>
                                            <span className="h6">Export</span>
                                        </>
                                    )}
                                </motion.button>
                            )}
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>
        </>
    );
}

ExportModal.propTypes = {
    header: PropTypes.string,
    type: PropTypes.string,
    toggleModal: PropTypes.func,
    excelTabName: PropTypes.string,
    data: PropTypes.shape({
        records: PropTypes.array,
        columnNames: PropTypes.array,
    }),
};

export default ExportModal;
