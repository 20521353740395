import {
    defaultSummaryCompletedTableHeaders,
    defaultSummaryTableHeaders,
    deliveryTypesBasedColumns,
    DELIVERY_TYPE_OPTIONS,
} from '../constants';

export const getStatus = (deliveryStatus, rider) => {
    if (deliveryStatus === 'dispatched') {
        return 'dispatchReady';
    }

    if (deliveryStatus === 'pickedUp') {
        return 'dispatched';
    }

    if (deliveryStatus === 'deliveryStarted') {
        return 'deliveryStarted';
    }

    if (deliveryStatus === 'completed') {
        return 'completed';
    }

    if (deliveryStatus === 'delivered') {
        return 'delivered';
    }

    if (rider || deliveryStatus === 'pickUpStarted') {
        return 'riderAssigned';
    }

    return 'delivering';
};

export const buildTableHeaders = (formData) => {
    let tableHeaders = defaultSummaryTableHeaders;

    if (formData.report === 'created') {
        tableHeaders = defaultSummaryTableHeaders;
    }
    if (formData.report === 'completed') {
        tableHeaders = defaultSummaryCompletedTableHeaders;
    }

    if (formData.deliveryType === DELIVERY_TYPE_OPTIONS[0].value && formData.report === 'completed') {
        tableHeaders = tableHeaders.concat(deliveryTypesBasedColumns);
    }

    return { tableHeaders };
};
