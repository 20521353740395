import { useState, useEffect } from 'react';

// #region services
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';

// #region utils
import { getVisitingObject } from 'utils/checkAuth';

/**
 * Merchant Service Hook
 * @param {*} shouldLoad
 * @returns
 */
export const useMerchantsService = (shouldLoad) => {
    const [merchants, setMerchants] = useState({ status: 'loading' });
    useEffect(() => {
        const { visitingClient } = getVisitingObject();
        if (shouldLoad) {
            if (visitingClient && visitingClient._id) {
                getMerchantsByClientIdService(visitingClient._id).then((res) => {
                    const { data } = res;
                    if (data.docs) {
                        setMerchants({
                            status: 'loaded',
                            data: data.docs.map((x) => ({ ...x, label: x.name, value: x._id })),
                        });
                    }
                });
            } else {
                getAllMerchantsService().then((res) => {
                    const { data } = res;
                    if (data.docs) {
                        setMerchants({
                            status: 'loaded',
                            data: data.docs.map((x) => ({ ...x, label: x.name, value: x._id })),
                        });
                    }
                });
            }
        }
    }, [shouldLoad]);

    return merchants;
};
