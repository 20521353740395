/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Alert } from 'reactstrap';
import { getBaseUrl } from 'utils/httpclients/config';
import { __DEV__ } from 'utils/checks';

function DangerApiConnectedHeader() {
    let apiName = 'PRODUCTION API';
    let color = 'warning';

    const SERVICE = getBaseUrl();

    function isProdAPIConnected() {
        if (SERVICE.includes('api.scootix.com') && __DEV__) {
            apiName = 'PRODUCTION API';
            color = 'danger';
            return true;
        }
        if (SERVICE.includes('staging') && __DEV__) {
            apiName = 'STAGING API';
            color = 'warning';
            return true;
        }
        if (SERVICE.includes('test') && __DEV__) {
            apiName = 'TEST API';
            color = 'warning';
            return true;
        }
        return false;
    }

    return (
        <>
            {isProdAPIConnected() && (
                <div className="maintenance-container">
                    <Alert color={color}>
                        <div className="text-center d-flex row justify-content-center">
                            {apiName} CONNECTED {'  '}{' '}
                        </div>
                    </Alert>
                </div>
            )}
        </>
    );
}

DangerApiConnectedHeader.propTypes = {};

export default DangerApiConnectedHeader;
