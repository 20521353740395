/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Row, Col, Alert, Button, Container, FormGroup, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// #region imports
import { removeStorageItems } from 'utils/checkAuth';

// #endregion imports
import { forgetUser } from '../../../store/actions';

// #region constants
import { PASSWORD_RESET_RESPONDS } from '../../../constants/auth/forgetPassword.constant';

/**
 * Under forgetPasswordpage function, There is a message{'Reset email sent check your email inbox'}
 * is coming through {props.message} and here it equals to {PASSWORD_RESET_RESPONDS.emailCheckInbox}
 * constant created in src/constants/auth/forgetPassword.constant.js.
 */
function ForgetPasswordPage(props) {
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        removeStorageItems();
        if (props.message && props.message === PASSWORD_RESET_RESPONDS.emailCheckInbox) {
            const timer1 = setTimeout(() => (window.location.href = '/'), 2000);
            // this will clear Timeout
            // when component unmount like in willComponentUnmount
            // and show will not change to true
            return () => {
                clearTimeout(timer1);
            };
        }
    }, [props.message]);

    // handleValidSubmit
    const handleValidSubmit = async (event, values) => {
        event.preventDefault();
        await props.forgetUser(values, props.history);
        const form = document.getElementById('forget-pwd-form');
        if (form) {
            form.reset();
        }
    };

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/">
                    <i className="mdi mdi-home-variant h2 text-white"></i>
                </Link>
            </div>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="auth-body w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        {/* <Link to="/" className="logo">
                                                            <img src={logodark} height="20" alt="logo" />
                                                        </Link> */}
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">Reset Password</h4>
                                                    <p className="text-muted">Reset your password to ScootiX.</p>
                                                </div>

                                                <div className="p-2 mt-5">
                                                    {props.forgetError && props.forgetError ? (
                                                        <Alert color="danger" className="mb-4">
                                                            {props.forgetError}
                                                        </Alert>
                                                    ) : null}
                                                    {props.message ? (
                                                        <Alert color="success" className="mb-4">
                                                            {props.message}
                                                        </Alert>
                                                    ) : null}
                                                    <AvForm
                                                        id="forget-pwd-form"
                                                        className="form-horizontal"
                                                        onValidSubmit={handleValidSubmit}
                                                    >
                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-mail-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="email">Username</Label>
                                                            <AvField
                                                                name="username"
                                                                placeholder="Username"
                                                                type="text"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{ required: { value: true } }}
                                                                id="username"
                                                            />
                                                        </FormGroup>

                                                        <div className="mt-4 text-center">
                                                            <Button
                                                                color="primary"
                                                                className="w-md waves-effect waves-light"
                                                                type="submit"
                                                            >
                                                                {props.loading ? 'Loading...' : 'Reset'}
                                                            </Button>
                                                        </div>
                                                    </AvForm>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p>
                                                        Already have an account ?{' '}
                                                        <Link to="/login" className="font-weight-medium text-primary">
                                                            {' '}
                                                            Log in{' '}
                                                        </Link>{' '}
                                                    </p>
                                                    <p>© 2023 ScootiX. Developed By TASA Global Solutions Sdn Bhd</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <div className="bg-overlay"></div>
                                {/* <img src={APP_LOGO_WIDE_IMAGE} alt="icon" height={80} width={230} /> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    const { message, forgetError, loading } = state.Forget;
    return { message, forgetError, loading };
};

export default withRouter(connect(mapStateToProps, { forgetUser })(ForgetPasswordPage));
