import axios from 'axios';
import { getSocketStorageSession } from 'utils/local-storage';
// import { handleApiError } from 'utils/requests';
import { backToLogin, cleanUserRequestHeader, getAccessToken, getUser, getVisitingObject } from '../checkAuth';
import { getBaseUrl } from './config';

const instance = axios.create({
    baseURL: getBaseUrl(),
    timeout: 60000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use((request) => {
    const token = getAccessToken();
    if (token) {
        request.headers.authorization = `Bearer ${token}`;
    }
    request.headers.clientType = 'web';
    return request;
});

// Append Socket ID
instance.interceptors.request.use((request) => {
    const socketClient = getSocketStorageSession('socket-id');
    if (socketClient) {
        request.headers.socketid = socketClient.socketId;
    }
    return request;
});

instance.interceptors.request.use((request) => {
    let user = getUser();
    if (user) {
        user = cleanUserRequestHeader(user);
    }
    const { visitingMerchant, visitingClient } = getVisitingObject();
    if (user) {
        request.headers['x-user'] = JSON.stringify(user);
    }
    if (visitingMerchant) {
        request.headers['x-merchant'] = JSON.stringify(visitingMerchant);
    }
    if (visitingClient) {
        request.headers['x-client'] = JSON.stringify(visitingClient);
    }
    if (user && user.role === 'superAdmin') {
        request.headers['x-super-admin'] = true;
    } else {
        request.headers['x-super-admin'] = false;
    }
    return request;
});

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        try {
            console.log('http-error', error);
            if (error.response) {
                const { status } = error.response;
                if (status === 401) {
                    backToLogin('sessiontimeout');
                } else if (status === 500 || status === 422 || status === 404 || status === 503) {
                    try {
                        // !TODO
                        // handleApiError(error);
                    } catch (e) {
                        console.log(e);
                    }
                }
            } else {
                // backToLogin('sessiontimeout');
            }
            return Promise.reject(error.response);
        } catch (e) {
            console.info(e);
            return Promise.reject(error);
        }
    }
);

// instance.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         try {
//             const { status } = error.response;
//             if (status === 401) {
//                 backToLogin();
//             }
//             return Promise.reject(error.response);
//         } catch (e) {
//             console.info(e);
//             return Promise.reject(error);
//         }
//     }
// );

export function delayedPromise(ms, value) {
    return new Promise((resolve) => {
        setTimeout(() => resolve(value), ms);
    });
}

export default instance;
