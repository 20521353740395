import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Spinner } from 'reactstrap';

/**
 * This is a customizable button
 * @param {buttonTitle: string, icon: Icon, iconStyle: styleObject, onExport: function, customStyle: styleObject} props
 * @returns CustomizableButton
 */
function CustomizableButton(props) {
    const { buttonTitle, icon, iconStyle = {}, handleOnClick, customStyle = {}, loading } = props;

    return (
        <motion.button
            whileHover={{ scale: 1.04 }}
            whileTap={{ scale: 0.95 }}
            className="export-btn-table shadow-lg nw-md pr-2 pl-2 pt-2 pb-2 float-right"
            style={{
                ...customStyle,
                borderRadius: '10px',
                fontSize: '14px',
                fontFamily: 'Nunito',
                alignContent: 'center',
            }}
            onClick={handleOnClick}
            disabled={loading}
        >
            {loading ? (
                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
            ) : (
                <row style={{ alignItems: 'center' }}>
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img src={icon} alt="Icon image" style={{ ...iconStyle, marginTop: -2, marginLeft: -2 }} />
                    <span className="h6 customButtonText" style={{ fontSize: 12 }}>
                        {buttonTitle}
                    </span>
                </row>
            )}
        </motion.button>
    );
}

CustomizableButton.propTypes = {
    buttonTitle: PropTypes.string,
    icon: PropTypes.string,
    handleOnClick: PropTypes.func,
    iconStyle: PropTypes.any,
    customStyle: PropTypes.any,
    loading: PropTypes.bool,
};

export default CustomizableButton;
