import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { BackdropLoader, CustomToast, ScootixModal, TableCustom } from 'components';

// #region imports
import PATHS from 'routes/paths';
import { PERMISSIONS } from 'utils/constants';

// #region services
import { getUsersService } from 'services/user.service';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';
import { getUser, hasAccess } from 'utils/checkAuth';

// #endregion imports
import reducer from './reducer';
import saga from './saga';
import { defaultTableHeaders } from './constants';
import { deleteUserPermanentService, restoreUserService } from '../User-Form/services';
import {
    PERMANENT_DELETE_USER_END,
    PERMANENT_DELETE_USER_START,
    RESTORE_USER_END,
    RESTORE_USER_START,
} from './actionTypes';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'User Management', link: PATHS.USER_PROFILE_MANAGEMENT.DEFAULT },
    { title: 'Profile', link: '#' },
];

const key = 'DeletedUsers';

function DeletedUsersPage(props) {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    const history = useHistory();
    const dispatch = useDispatch();

    // component state
    const [loading, setLoading] = useState(0);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [usersListData, setUsersListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    const [searchBy, setSearchBy] = useState({ searchBy: '', searchText: null });

    useEffect(() => {
        loadUsersListData(
            true,
            usersListData.all,
            1,
            usersListData.limit,
            usersListData.filter,
            usersListData.sort,
            usersListData.order,
            searchBy,
            'deleted'
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.DeletedUsers && props.DeletedUsers?.restoreUserData) {
            handleOnRestore(props.DeletedUsers?.restoreUserData);
        }
        if (props.DeletedUsers && props.DeletedUsers?.permanentDeleteUserData) {
            handleOnDeletePermanent(props.DeletedUsers?.permanentDeleteUserData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.DeletedUsers]);

    const loadUsersListData = (showLoading = true, all, page, limit, filter, sort, order, searchByProp, status) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getUsersService(all, page, limit, filter, sort, order, searchByProp, null, status)
            .then((res) => {
                const { data } = res;
                setUsersListData({
                    ...usersListData,
                    ...data,
                    docs: data.docs.map((x) => ({
                        ...x,
                        name: `${x.firstName || ''} ${x.lastName || ''}`,
                    })),
                });
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const handlePageChange = (pageNo) => {
        loadUsersListData(
            true,
            usersListData.all,
            pageNo,
            usersListData.limit,
            usersListData.filter,
            usersListData.sort,
            usersListData.order,
            searchBy,
            'deleted'
        );
    };

    const handleFilter = (field, filterText) => {
        setSearchBy({ searchBy: field || 'fullName', searchText: filterText });
        loadUsersListData(
            false,
            usersListData.all,
            1,
            usersListData.limit,
            usersListData.filter,
            usersListData.sort,
            usersListData.order,
            { searchBy: field || 'fullName', searchText: filterText },
            'deleted'
        );
    };

    const handleOnRestore = (user) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: { _id: user._id },
            message: `Are you sure you want to restore User ${user.fullName}?`,
            onConfirm: (data) => handleRestoreUser(data),
        });
    };

    const handleRestoreUser = async (data) => {
        if (data && data._id) {
            setBackdropLoading((prevState) => prevState + 1);
            try {
                await restoreUserService(data._id);
                history.push({
                    search: ``,
                });
                loadUsersListData(
                    true,
                    usersListData.all,
                    usersListData.page,
                    usersListData.limit,
                    usersListData.filter,
                    usersListData.sort,
                    usersListData.order,
                    searchBy,
                    'deleted'
                );
                toast.custom((t) => <CustomToast text="Successfully Restored User" t={t} type="success" />, {
                    position: 'top-right',
                });
            } catch (e) {
                const { message } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            setBackdropLoading((prevState) => prevState - 1);
        }
        closeScootixModal();
    };

    const handleOnDeletePermanent = (user) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: { _id: user._id },
            message: `Are you sure you want to Permanently delete User ${user.fullName} ?`,
            onConfirm: (data) => handleDeletePermanentUser(data),
        });
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: !scootixModalState.show, data: null });
        dispatch({ type: PERMANENT_DELETE_USER_END });
        dispatch({ type: RESTORE_USER_END });
    };

    const closeScootixModal = () => {
        setScootixModalState({ show: false, data: null });
        if (props.DeletedUsers?.permanentDeleteUserData) dispatch({ type: PERMANENT_DELETE_USER_END });
        if (props.DeletedUsers?.restoreUserData) dispatch({ type: RESTORE_USER_END });
    };

    const handleDeletePermanentUser = async (data) => {
        if (data && data._id) {
            setBackdropLoading((prevState) => prevState + 1);
            try {
                await deleteUserPermanentService(data._id);
                history.push({
                    search: ``,
                });
                loadUsersListData(
                    true,
                    usersListData.all,
                    usersListData.page,
                    usersListData.limit,
                    usersListData.filter,
                    usersListData.sort,
                    usersListData.order,
                    searchBy,
                    'deleted'
                );
                toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                    position: 'top-right',
                });
            } catch (e) {
                const { message } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            setBackdropLoading((prevState) => prevState - 1);
        }
        closeScootixModal();
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <br />
                    <Row>
                        <TableCustom
                            tableData={usersListData}
                            onFilter={handleFilter}
                            onPageChange={handlePageChange}
                            showDelete={false}
                            showEdit={false}
                            showView={false}
                            loading={loading}
                            showPermanentDelete={hasAccess(PERMISSIONS.DELETED_USERS, ['Permanent Delete'])}
                            showRestore={hasAccess(PERMISSIONS.DELETED_USERS, ['Restore'])}
                            filters={[
                                {
                                    label: 'Full Name',
                                    key: 'fullName',
                                },
                                {
                                    label: 'Last Name',
                                    key: 'lastName',
                                },
                            ]}
                            disabledRows={{
                                role: { key: 'role', value: 'superAdmin' },
                                _id: {
                                    key: '_id',
                                    value: getUser()._id,
                                },
                            }}
                            onRestore={(data) => dispatch({ type: RESTORE_USER_START, payload: data })}
                            onPermanentDelete={(data) => dispatch({ type: PERMANENT_DELETE_USER_START, payload: data })}
                        />
                    </Row>
                </Container>
            </div>
            <BackdropLoader show={backdropLoading} opacity={0.8} />
            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
        </>
    );
}

DeletedUsersPage.propTypes = {
    DeletedUsers: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotDeletedUsersPage = hot(DeletedUsersPage);

export default connect(mapStateToProps, {})(HotDeletedUsersPage);
