import React from 'react';
import PropTypes from 'prop-types';

// #region assets | components
import { TableCustom } from 'components';
import { PRINT_METHODS } from 'constants/constants';

function ListViewTable(props) {
    const { shippingLabelData, filters, loading, searchParams, handlePageChange, handleFilter, handleViewRow } = props;

    return (
        <TableCustom
            tableData={shippingLabelData}
            filters={filters}
            loading={loading}
            showEdit={false}
            showDelete={false}
            removeId={false}
            searchProp={searchParams}
            onPageChange={handlePageChange}
            onFilter={handleFilter}
            handleView={handleViewRow}
            isManualSearch
            tableHeaderType="LABEL_PRINT_SHIPPING_LABEL_LIST"
            actionDisabledRows={{
                printType: { key: 'printType', value: PRINT_METHODS.QUICK_PRINT },
            }}
        />
    );
}
ListViewTable.propTypes = {
    shippingLabelData: PropTypes.object,
    filters: PropTypes.func,
    loading: PropTypes.func,
    searchParams: PropTypes.func,
    handlePageChange: PropTypes.func,
    handleFilter: PropTypes.func,
    handleViewRow: PropTypes.func,
};
export default ListViewTable;
