import moment from 'moment';
import { TRANSACTION_METHODS } from 'constants/enums';

export function transactionMappedData(method, otherValues) {
    let formattedMethod = method;

    let consoleUserFormatted = '';
    let driverFormatted = '';
    let remarks = '';

    const { meta, createdByUser, rider, deliveryData } = otherValues;
    const { DELIVERY_METHODS, BILLING_METHODS } = TRANSACTION_METHODS;
    const {
        COMPLETE_DELIVERY,
        DELIVERY_CANCELLED,
        DELIVERY_CREATED,
        DELIVERY_CREATED_RETURN,
        DELIVERY_IMPORT,
        DELIVERY_RETURN_ORDER_BILLING_CREATED,
        DELIVERY_RIDER_ASSIGNED,
        DELIVERY_RIDER_UNASSIGNED,
        DELIVERY_TYPE_UPDATED,
        NEW_DELIVERY_ATTEMPT_CREATED,
        DELIVERY_STARTED,
        ORDER_DELIVERED,
        POD_SUBMITTED_RIDER,
        RIDER_ACCEPTED,
        RIDER_PICKED_UP,
        RIDER_PICKUP_STARTED,
        PICKUP_LOCATION_UPDATED,
        DELIVERY_LOCATION_UPDATED,
        VEHICLE_TYPE_UPDATED,
        ROUTE_PLANNING_ENTRY_LINKED,
        ROUTE_PLANNING_ENTRY_DETACHED,
    } = DELIVERY_METHODS;

    const { CREATE_BILLING_RECORD } = BILLING_METHODS;

    switch (method) {
        case DELIVERY_CREATED:
            formattedMethod = 'Delivery Created';
            consoleUserFormatted = `${createdByUser?.fullName || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            break;
        case DELIVERY_IMPORT:
            formattedMethod = 'Delivery Imported';
            consoleUserFormatted = `${createdByUser?.fullName || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            break;
        case DELIVERY_RIDER_ASSIGNED:
            formattedMethod = 'Rider Assigned';
            consoleUserFormatted = `${createdByUser?.fullName || ''} - ${createdByUser?.username || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            break;
        case RIDER_ACCEPTED:
            formattedMethod = 'Rider Accepted - Self Assign';
            consoleUserFormatted = `${createdByUser?.fullName || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            break;
        case RIDER_PICKUP_STARTED:
            formattedMethod = 'Pickup Started';
            consoleUserFormatted = `${createdByUser?.fullName || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            remarks = `${meta?.remark || ''} -  Pick Up Started At: ${
                meta?.pickUpStartedAt ? moment(meta.pickUpStartedAt).format('DD-MM-YYYY hh:mm A') : ''
            } `;
            break;
        case RIDER_PICKED_UP:
            formattedMethod = 'Picked Up';
            consoleUserFormatted = `${createdByUser?.fullName || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            remarks = `${meta?.remark || ''}  -  Picked Up At: ${
                meta?.pickedUpAt ? moment(meta.pickedUpAt).format('DD-MM-YYYY hh:mm A') : ''
            } `;
            break;
        case DELIVERY_STARTED:
            formattedMethod = 'Delivery Started';
            consoleUserFormatted = `${createdByUser?.fullName || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            remarks = `${meta?.remark || ''}  -  Delivery Started At: ${
                meta?.pickedUpAt ? moment(meta.deliveryStartedAt).format('DD-MM-YYYY hh:mm A') : ''
            } `;
            break;
        case ORDER_DELIVERED:
            formattedMethod = 'Delivered';
            consoleUserFormatted = `${createdByUser?.fullName || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            remarks = `${meta?.remark || ''}`;
            break;
        case POD_SUBMITTED_RIDER:
            formattedMethod = 'Proof Of Delivery Submitted by the Rider';
            consoleUserFormatted = `${createdByUser?.fullName || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            break;
        case DELIVERY_RIDER_UNASSIGNED:
            formattedMethod = 'Rider Unassigned - Console User';
            consoleUserFormatted = `${createdByUser?.fullName || ''} - ${createdByUser?.username || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            remarks = `Reset the delivery status: ${meta?.isResetDeliveryStatus ? 'Yes' : 'No'} ${
                meta?.reason ? `/ Reason: ${meta.reason}` : ''
            }  ${meta?.description ? `/ Description : ${meta.description}` : ''}`;
            break;
        case DELIVERY_CANCELLED:
            formattedMethod = 'Delivery Cancelled';
            consoleUserFormatted = `${createdByUser?.fullName || ''} - ${createdByUser?.username || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            remarks = `Reason : ${meta?.reason || ''} / Description : ${meta?.description || ''} / is Billable : ${
                deliveryData?.isBillable ? 'Yes' : 'No'
            } / Return Order Billable : ${deliveryData?.isReturnOrderBillable ? 'Yes' : 'No'}`;
            break;
        case DELIVERY_CREATED_RETURN:
            formattedMethod = 'Return Delivery Order Created';
            consoleUserFormatted = `${createdByUser?.fullName || ''} - ${createdByUser?.username || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            remarks = `Return Delivery ID: ${meta?.referenceNumber || ''}`;
            break;
        case NEW_DELIVERY_ATTEMPT_CREATED:
            formattedMethod = 'New Delivery Attempt Created';
            consoleUserFormatted = `${createdByUser?.fullName || ''} - ${createdByUser?.username || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            remarks = `Delivery Attempt: ${deliveryData?.deliveryAttemptCount || '0'}, Is Billable: ${
                deliveryData?.isBillable ? 'Yes' : 'No'
            }`;
            break;
        case COMPLETE_DELIVERY:
            formattedMethod = 'Delivery Completed';
            consoleUserFormatted = `${createdByUser?.fullName || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            remarks = `${meta?.remark || ''} Reason: ${meta?.selectReason || ''}  Description : ${
                meta?.description || ''
            }`;
            break;
        case CREATE_BILLING_RECORD:
            formattedMethod = 'Added a billing record ';
            consoleUserFormatted = `${createdByUser?.fullName || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            break;
        case DELIVERY_RETURN_ORDER_BILLING_CREATED:
            formattedMethod = 'Added a billing record ';
            consoleUserFormatted = `${createdByUser?.fullName || ''} - ${createdByUser?.username || ''}`;
            break;
        case DELIVERY_TYPE_UPDATED:
            formattedMethod = 'Delivery Type Updated';
            consoleUserFormatted = `${createdByUser?.fullName || ''} - ${createdByUser?.username || ''}`;
            remarks = `Reason: ${meta?.reason || ''}  Previous Delivery Type: ${meta?.previousDeliveryType || ''}`;
            break;
        case PICKUP_LOCATION_UPDATED:
            formattedMethod = 'Picked Up Location Updated';
            consoleUserFormatted = `${createdByUser?.fullName || ''} - ${createdByUser?.username || ''}`;
            remarks = `Reason: ${meta?.reason || ''}  Previous Pickup Location: ${meta?.previousPickupLocation || ''}`;
            break;
        case DELIVERY_LOCATION_UPDATED:
            formattedMethod = 'Delivery Location Updated';
            consoleUserFormatted = `${createdByUser?.fullName || ''} - ${createdByUser?.username || ''}`;
            remarks = `Reason: ${meta?.reason || ''} - Previous Delivery  Location: ${
                meta?.previousDeliveryLocation || ''
            }`;
            break;
        case VEHICLE_TYPE_UPDATED:
            formattedMethod = 'Vehicle type Updated';
            consoleUserFormatted = `${createdByUser?.fullName || ''} - ${createdByUser?.username || ''}`;
            remarks = `Reason: ${meta?.reason || ''} - Previous Vehicle Type: ${meta?.previousVehicleType || ''}`;
            break;
        case ROUTE_PLANNING_ENTRY_LINKED:
            formattedMethod = 'Route Planning Entry Linked';
            consoleUserFormatted = `${createdByUser?.fullName || ''} - ${createdByUser?.username || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            break;
        case ROUTE_PLANNING_ENTRY_DETACHED:
            formattedMethod = 'Route Planning Entry Detached';
            consoleUserFormatted = `${createdByUser?.fullName || ''} - ${createdByUser?.username || ''}`;
            driverFormatted = `${rider?.fullName || ''} - ${rider?.referenceNumber || ''}`;
            break;
        default:
            formattedMethod = method;
    }

    return { formattedMethod, consoleUserFormatted, driverFormatted, remarks };
}

export function partnerApiKeyTransactionMapData(method, otherValues) {
    let formattedMethod = method;
    let consoleUserFormatted = '';

    const { createdByUser } = otherValues;

    switch (method) {
        default:
            consoleUserFormatted = `${createdByUser?.fullName || ''}`;
            formattedMethod = method;
    }

    return { formattedMethod, consoleUserFormatted };
}

export function partnerApiWebhookTransactionMapData(method, otherValues) {
    let formattedMethod = method;
    let consoleUserFormatted = '';

    const { createdByUser } = otherValues;

    switch (method) {
        default:
            consoleUserFormatted = `${createdByUser?.fullName || ''}`;
            formattedMethod = method;
    }

    return { formattedMethod, consoleUserFormatted };
}
