import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import './styles/index.scss';

// #region assets | components

/**
 * A React component that displays delivery date
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.deliveryCountDetails - Delivery date
 * @returns {JSX.Element} - The rendered component.
 */
function DeliveryDateCard(props) {
    const { deliveryDate } = props;

    return (
        <React.Fragment>
            <Card className="shadow default-card route-planning-entry__card">
                <CardBody>
                    <Row>
                        <Col>
                            <h5 className="mb-2" style={{ fontWeight: 'bold' }}>
                                Delivery Date
                            </h5>
                            <Row>
                                <Col>
                                    <h5 className="text-muted mb-0">{deliveryDate}</h5>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

DeliveryDateCard.propTypes = {
    deliveryDate: PropTypes.any,
};

export default DeliveryDateCard;
