import { ASSIGN_BATCH_DELIVERIES_TO_RIDER } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Assign Batch Deliveries To Rider Service
 * @param {*} data
 * @returns
 */
export const assignBatchDeliveriesToRiderService = (data, riderId) => {
    try {
        const url = UrlBuilder().setUrl(ASSIGN_BATCH_DELIVERIES_TO_RIDER).setPathParams({ id: riderId }).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
