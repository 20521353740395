import React from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';

/**
 * This is a helper function to render the checkboxes
 * @param {object} checkBox - object that contains the 'name' and 'label'
 * @param {boolean} checked - whether the checkbox is checked or not
 * @param {function} changeHandler - function to run when checkbox status changes
 */
export const renderEndpointCheckbox = (checkBox, checked, changeHandler) => (
    <Col xs="auto" key={checkBox.name}>
        <FormGroup className="mb-0">
            <Input
                name={checkBox.name}
                type="checkbox"
                checked={checked}
                onChange={changeHandler}
                className="mr-1 form-check-left"
            />
            <Label htmlFor={checkBox.name} className="text-muted">
                {checkBox.label}
            </Label>
        </FormGroup>
    </Col>
);

export const createApiKeyValidateForm = (formData) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { allowedEndpoints } = formData;

    if (allowedEndpoints.length === 0) {
        return { isFormValid, message: 'At least Select a One Endpoint to Continue' };
    }

    isFormValid = true;
    return { isFormValid, message };
};
