/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';

// #region assets | components
import { Breadcrumbs, CustomToast } from 'components';
import CheckCircleIcon from 'components/Icons/Check-Circle';
import CrossCircleIcon from 'components/Icons/CrossCircle';

// #region services
import { generateApiKey } from 'services/partner-api.service';

// #region imports
import PATHS from 'routes/paths';

// #region utils
import { getUser } from 'utils/checkAuth';
import { apiErrorHandlerV2 } from 'utils/helpers';

// #endregion imports
import { privateKeyEndpoints, emptyFormValues, publicKeyEndpoints } from '../constants';
import { createApiKeyValidateForm, renderEndpointCheckbox } from '../utils';

function GenerateApiKeyPage(props) {
    const { location } = props;

    const state = location?.state;

    const history = useHistory();

    /** State for storing entered form data */
    const [formData, setFormData] = useState(emptyFormValues);
    /** Whether the submit operation is in progress */
    const [inProgress, setInProgress] = useState(false);

    if (!state) {
        history.push({
            pathname: `/${PATHS.DEVELOPER.API_SERVICE}`,
        });
        return <div />;
    }

    const { merchantId, merchantName } = state;

    const breadcrumbItems = [
        { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
        { title: 'API Portal', link: `/${PATHS.DEVELOPER.API_PORTAL}` },
        { title: merchantName, link: '#' },
        { title: 'API Keys', link: '#' },
        { title: 'New API Key', link: '#' },
    ];

    /** Invoke the service to generate a new API key */
    const handleSubmit = async () => {
        setInProgress(true);
        const { isFormValid, message } = createApiKeyValidateForm(formData);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            setInProgress(false);
            return;
        }
        const requestData = {
            merchantId,
            name: formData.name,
            allowedEndpoints: formData.allowedEndpoints,
            type: formData.type,
            password: formData.password,
        };

        // For non-superadmin users, client ID is in the user object
        const user = getUser();
        if (user.role !== 'superAdmin') {
            requestData.clientId = user.clientId;
        }

        try {
            const { data } = await generateApiKey(requestData);
            if (data) {
                const { payload } = data;
                toast.custom((t) => <CustomToast text="API Key generated successfully" t={t} type="success" />, {
                    position: 'top-right',
                });
                history.push({
                    pathname: `/${PATHS.DEVELOPER.API_KEY_MANAGE}`,
                    state: { ...state, apiKey: { _id: payload._id } },
                    search: '?tab=basicSettings',
                });
            } else {
                toast.custom((t) => <CustomToast text="Unable to Generate Api Key, Try again" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setInProgress(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    /**
     * Updates the value for the checkboxes in the formData state.
     * The checked boxes are saved in an array. Unchecked items are removed from the array.
     * @param {string} keyType - whether this key is a secret key or public key ('secretKey' or 'publicKey')
     * @param {object} event - event generated from the component
     */
    const handleEndpointCheckboxChange = (event) => {
        const { name, checked } = event.target;
        let updatedEndpoints = [];
        if (checked) {
            updatedEndpoints = [...formData.allowedEndpoints, name];
        } else {
            updatedEndpoints = formData.allowedEndpoints.filter((endpoint) => endpoint !== name);
        }
        setFormData((prevData) => ({ ...prevData, allowedEndpoints: updatedEndpoints }));
    };

    /** Navigate back to the API keys page when form is cancelled */
    const handleCancel = () => {
        history.push({
            pathname: `/${PATHS.DEVELOPER.API_PORTAL}`,
            state,
            search: '?tab=basicSettings',
        });
    };

    /** Handle changing of dropdown inputs */
    const handleSelectChange = (event) => {
        const { value } = event.target;
        // When the key type changes, allowedEndpoints are reset to avoid remembering previous endpoints
        setFormData((prevData) => ({ ...prevData, type: value, allowedEndpoints: [] }));
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                <Row>
                    <Col>
                        <Card className="shadow default-card">
                            <CardHeader className="pl-5" style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}>
                                Generate a New API Key
                            </CardHeader>
                            <CardBody className="px-5">
                                <AvForm
                                    className="needs-validation default-form"
                                    noValidate
                                    onValidSubmit={handleSubmit}
                                >
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label htmlFor="name" className="text-muted">
                                                    Name
                                                </Label>
                                                <AvField
                                                    name="name"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                    validate={{ required: { value: true } }}
                                                    autoComplete="new-password"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label htmlFor="keyType" className="text-muted">
                                                    Select the API Key type
                                                </Label>
                                                <AvField name="keyType" type="select" onChange={handleSelectChange}>
                                                    <option value="public">Public</option>
                                                    <option value="private">Private</option>
                                                </AvField>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row
                                        style={{
                                            backgroundColor: '#F5F4F4',
                                            borderRadius: 15,
                                            marginLeft: 0,
                                            border: '#E7E4E4 1px solid',
                                        }}
                                        className="p-4 mt-4"
                                    >
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <span className="font-weight-bold">
                                                        Select the allowed API Endpoints
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                {Object.keys(
                                                    formData.type === 'private'
                                                        ? privateKeyEndpoints
                                                        : publicKeyEndpoints
                                                ).map((checkBox) =>
                                                    renderEndpointCheckbox(
                                                        {
                                                            name: checkBox,
                                                            ...(formData.type === 'private'
                                                                ? privateKeyEndpoints
                                                                : publicKeyEndpoints)[checkBox],
                                                        },
                                                        formData.allowedEndpoints.includes(checkBox),
                                                        (e) => handleEndpointCheckboxChange(e)
                                                    )
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="mt-4">
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label htmlFor="password" className="text-muted">
                                                    Your Password
                                                </Label>
                                                <AvField
                                                    name="password"
                                                    type="password"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={formData.password}
                                                    onChange={handleInputChange}
                                                    autoComplete="new-password"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row className="my-4">
                                        <Col xs="auto">
                                            <Button
                                                className="scootix-btn-radius"
                                                type="submit"
                                                disabled={inProgress}
                                                style={{ borderRadius: 10 }}
                                            >
                                                {inProgress ? (
                                                    <Spinner size="sm" color="warning" className="spinner-white mx-1" />
                                                ) : (
                                                    <CheckCircleIcon color="#fff" />
                                                )}
                                                &nbsp;Generate API Keys
                                            </Button>
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                className="form-delete-btn"
                                                style={{ backgroundColor: '#FD4A4A', borderRadius: 10 }}
                                                onClick={handleCancel}
                                            >
                                                <CrossCircleIcon color="#FFF" />
                                                &nbsp;Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

GenerateApiKeyPage.propTypes = {
    location: PropTypes.any,
};

const HotGenerateApiKeyPage = hot(GenerateApiKeyPage);

export default connect(null, {})(HotGenerateApiKeyPage);
