// Stock-Receiving transactions table header
const headersForTransactionTable = [
    {
        key: 'sequence',
        value: 'TRANSACTION ID',
    },
    {
        key: 'reference',
        value: 'TRANSACTION REFERENCE',
    },
    {
        key: 'transactionName',
        value: 'TRANSACTION',
    },
    {
        key: 'createdByUserFullName',
        value: 'USER',
    },
    {
        key: 'createdAt',
        value: 'TRANSACTION DATE AND TIME',
    },
    {
        key: 'action',
        value: 'DETAILS',
        type: 'customActionButton',
        params: {
            buttonLabel: 'View',
            customActionHandlerName: 'displayModal',
        },
    },
];
/**
 * Initial table data for the Transaction table
 */
export const initialTransactionTableData = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: headersForTransactionTable,
    docs: [],
    all: false,
    sort: null,
    order: -1,
};
