/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';

// #region assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { AlertModal, TableCustom } from 'components';
import PATHS from 'routes/paths';
import { getPickingTasksService } from 'services/pickingtask.service';
import { defaultTableHeaders } from '../constants';
import { deletePickingTaskService } from '../services';

// #endregion imports

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Order Processing', link: PATHS.ORDER_PROCESSING.DEFAULT },
    { title: 'Dashboard', link: PATHS.ORDER_PROCESSING.DEFAULT },
];

const IS_MOCK_DATA = false;

function PickingTaskList() {
    const history = useHistory();

    // component state
    const [loading, setLoading] = useState(0);

    const [pickingTasksListData, setPickingTasksListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    const [searchBy] = useState({ searchBy: '', searchText: null });
    const [showDeleteModal, setShowDeleteModal] = useState({ state: false, data: null });

    useEffect(() => {
        if (IS_MOCK_DATA) {
            // setPickingTasksListData({ ...pickingTasksListData, docs: pickingTasksListMockData });
        }
    }, []);

    useEffect(() => {
        loadPickingTasksListData(
            true,
            pickingTasksListData.all,
            pickingTasksListData.page,
            pickingTasksListData.limit,
            pickingTasksListData.filter,
            pickingTasksListData.sort,
            pickingTasksListData.order,
            searchBy
        );
    }, [searchBy]);

    const loadPickingTasksListData = (showLoading = true, all, page, limit, filter, sort, order, searchByProp) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getPickingTasksService(all, page, limit, filter, sort, order, searchByProp, 'orders')
            .then((res) => {
                const { data } = res;
                setPickingTasksListData({
                    ...pickingTasksListData,
                    ...data,
                });
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const handleViewRecord = (e, row) => {
        history.push({
            pathname: `${PATHS.PICKING_TASK.FORM}`,
            search: `?type=view`,
            state: { taskParentData: row },
        });
    };

    const handleOnDelete = (e, row) => {
        setShowDeleteModal({ state: true, data: row });
    };

    const handleDeleteTask = async (data) => {
        try {
            await deletePickingTaskService(data._id);
            loadPickingTasksListData(
                true,
                pickingTasksListData.all,
                1,
                pickingTasksListData.limit,
                pickingTasksListData.filter,
                pickingTasksListData.sort,
                pickingTasksListData.order,
                searchBy
            );
            toast.success('Successfully Deleted');
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors && e.data.errors.userMessage) {
                toast.error(e.data.errors.userMessage);
            } else {
                toast.error('Something went wrong');
            }
        }
        setShowDeleteModal({ state: false, data: null });
    };

    const handleEditRow = (e, row) => {
        history.push({
            pathname: `${PATHS.PICKING_TASK.FORM}`,
            search: `?type=edit`,
            state: { taskParentData: row },
        });
    };

    const handlePageChange = (pageNo) => {
        loadPickingTasksListData(
            true,
            pickingTasksListData.all,
            pageNo,
            pickingTasksListData.limit,
            pickingTasksListData.filter,
            pickingTasksListData.sort,
            pickingTasksListData.order,
            searchBy
        );
    };

    const handleFilter = (field, filterText) => {
        loadPickingTasksListData(
            false,
            pickingTasksListData.all,
            1,
            pickingTasksListData.limit,
            pickingTasksListData.filter,
            pickingTasksListData.sort,
            pickingTasksListData.order,
            { searchBy: field || 'reference', searchText: filterText }
        );
    };

    return (
        <>
            <div className="page-content order-processing-dashboard">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <div>
                            {/* {hasAccess(PERMISSIONS.PICKING, ['View', 'Edit']) && ( */}
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                onClick={() =>
                                    history.push({
                                        pathname: `${PATHS.PICKING_TASK.FORM}`,
                                        search: `?type=new`,
                                    })
                                }
                            >
                                <i className="fas fa-cart-plus fa-lg"></i> <span className="h6">New Picking Task</span>
                            </motion.div>
                            {/* )} */}
                        </div>
                        <div>
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                onClick={() =>
                                    history.push({
                                        pathname: `${PATHS.ORDER_PROCESSING.DEFAULT}`,
                                        search: `?tab=2`,
                                    })
                                }
                            >
                                <i className="fas fa-arrow-left fa-lg"></i>{' '}
                                <span className="h6">Back to Dashboard</span>
                            </motion.div>
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <TableCustom
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            tableData={pickingTasksListData}
                            loading={loading}
                            handleEdit={handleEditRow}
                            handleDelete={handleOnDelete}
                            handleView={handleViewRecord}
                            showView={false}
                            filters={[
                                {
                                    label: 'Task Id',
                                    key: 'referenceNumber',
                                },
                                {
                                    label: 'Picker Name',
                                    key: 'pickerName',
                                },
                            ]}
                        />
                    </Row>
                </Container>
                <AlertModal
                    show={showDeleteModal.state}
                    onConfirm={handleDeleteTask}
                    data={showDeleteModal.data}
                    onHide={() =>
                        setShowDeleteModal({
                            state: false,
                            data: null,
                        })
                    }
                />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

const HotPickingTaskList = hot(PickingTaskList);

export default connect(mapStateToProps, {})(HotPickingTaskList);
