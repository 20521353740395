export const defaultTableHeaders = [
    {
        key: 'fileImportId',
        value: 'File Import ID',
    },
    {
        key: 'importedFileName',
        value: 'File Name',
    },
    {
        key: 'createdAt',
        value: 'Imported Date and Time',
        type: 'dateTime',
    },
    {
        key: 'createdBy',
        value: 'Imported By User',
        type: 'userObject',
    },
];
