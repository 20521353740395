/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Button, ButtonGroup, Col, FormFeedback, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';
import moment from 'moment';

// #region assets | components
import { CustomToast, ImageUploadModal } from 'components';
import AttachmentPreviewModal from 'components/Modals/Image-Preview';
import CreatableSelectAdvanced from 'components/CreateableSelect';

// #region other imports
import { SYSTEM_DATA_TYPES } from 'constants/enums';
import { THEME_COLOR_2 } from 'theme';

// #regions utils
import {
    apiErrorHandlerV2,
    buildFormDataSingle,
    buildNewAttachment,
    getDateValue,
    getDefaultValueForSelect,
    reactSelectCustomStyles,
} from 'utils/helpers';

// #region services
import { completeDeliveryService } from 'pages/Modules/Delivery-Management/services';

// #region services
import { createSystemDataService, getAllSystemDataByTypeAndModuleService } from 'services/system-data.service';
import { createAttachmentService } from 'services/attachment.service';

// #endregion imports
import { validateForm } from '../utils';
import './style.css';

const initialFormData = {
    confirmation: null,
};
// This is the initial format for Attachments same in default state in DB
const initialAttachmentState = {
    podImageUrl: { data: null, lastUpdated: null, isDbSaved: false },
};

function CompleteDelivery(props) {
    const {
        merchantOrder,
        deliveryId,
        data: deliveryData,
        setShowModal,
        createBillingRecord,
        loadTransactions,
        setDeliveryFormData,
        updateHistoryWithData,
    } = props;

    const { referenceNumber, isPickedUp, isPickUpStarted, isDeliveryStarted, isCompleted } = deliveryData;

    const _isMounted = useRef(true);

    // Form
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);

    // Data
    const [formData, setFormData] = useState(initialFormData);
    const [reason, setReason] = useState([]);

    // Loading
    const [customLoading, setCustomLoading] = useState({ reason: false });
    const [formLoading, setFormLoading] = useState(false);

    // POD attachment data
    const [attachmentsObj, setAttachmentsObj] = useState(initialAttachmentState);

    const [attachmentPreviewModalState, setAttachmentPreviewModalState] = useState({
        show: false,
        multiple: false,
        imageUrl: null,
    });

    const [imageUploadModalState, setImageUploadModalState] = useState({
        show: false,
        type: null,
        data: null,
        header: null,
        title: null,
        subTitle: null,
    });

    // load delivery complete reasons to dropdown
    useEffect(() => {
        loadDeliveryCompleteReasons();
        setFormData({ completedAt: new Date(), isPickUpStarted, isPickedUp, isDeliveryStarted });
        return () => {
            _isMounted.current = false;
        };
    }, []);

    /**
     * Handle Create Change
     */
    const handleCreateSelectChange = async (record, id) => {
        if (record) {
            if (id === 'selectReason') {
                setCustomLoading((prev) => ({ ...prev, productCategory: true }));
                try {
                    const { data } = await createSystemDataService({
                        type: SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION_REASONS.DELIVERY_COMPLETE_REASON,
                        module: SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION,
                        name: record,
                    });

                    if (data) {
                        setReason([...reason, { label: data.name, value: data.name }]);
                        setFormData({
                            ...formData,
                            [id]: record,
                        });
                    }
                } catch (e) {
                    const { message } = apiErrorHandlerV2(e);
                    toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                        position: 'top-right',
                    });
                }
                setCustomLoading((prev) => ({ ...prev, productCategory: false }));
                return;
            }

            setFormData({
                ...formData,
                [id]: record,
            });
        }
        setCustomLoading((prev) => ({ ...prev, productCategory: false }));
    };

    const handleSelectChange = (event, id) => {
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    /**
     * Handle Submit
     */
    const handleSubmit = async (event, errors = []) => {
        let updatedPayloadData;

        setSubmitted(true);
        event.persist();

        const formValidation = validateForm(formData, attachmentsObj);
        if (!formValidation.isFormValid) {
            toast.custom((t) => <CustomToast text={formValidation.message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        if (errors.length > 0) {
            setValidated(true);
            return;
        }

        setFormLoading(true);
        let isSuccess = false;

        let payload = { ...formData };
        // Attachments Handling
        let imageKey = null;
        if (attachmentsObj.podImageUrl.data) {
            if (!attachmentsObj.podImageUrl.isDbSaved) {
                const imageData = buildFormDataSingle(attachmentsObj.podImageUrl.data);
                try {
                    const { data } = await createAttachmentService(imageData);
                    if (data && data.key) {
                        imageKey = buildNewAttachment(data.key);
                    }
                } catch (e) {
                    const { message } = apiErrorHandlerV2(e);
                    toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                        position: 'top-right',
                    });
                }
            }
        }
        const podUrl = imageKey.data;
        payload = { ...payload, podUrl, _id: deliveryId };

        if (podUrl) {
            try {
                const { data } = await completeDeliveryService(payload);
                await createBillingRecord();
                if (data) {
                    setFormData({ ...payload, ...data });
                    updatedPayloadData = { ...deliveryData, ...data };
                    setDeliveryFormData({ ...deliveryData, ...data });
                    isSuccess = true;
                    toast.custom((t) => <CustomToast text="Successfully Updated the delivery" t={t} type="success" />, {
                        position: 'top-right',
                    });
                }
            } catch (e) {
                const { message } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        }

        if (updatedPayloadData) {
            updateHistoryWithData({ ...deliveryData, ...updatedPayloadData });
        }

        setValidated(false);
        setSubmitted(false);
        loadTransactions();
        setFormLoading(false);

        if (isSuccess) {
            setShowModal(false);
        }
    };

    /**
     * Load delivery Complete Reasons to the Dropdown
     */
    const loadDeliveryCompleteReasons = async () => {
        try {
            const { data } = await getAllSystemDataByTypeAndModuleService(
                SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION_REASONS.DELIVERY_COMPLETE_REASON,
                SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                setReason(data.map((x) => ({ label: x.name, value: x.name })));
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleCheckChangeFilter = (event) => {
        const { id, checked } = event.target;
        if (id === 'isPickUpStarted') {
            setFormData({
                ...formData,
                pickUpStartedAt: checked ? new Date() : '',
                [id]: checked,
            });
        }
        if (id === 'isPickedUp') {
            setFormData({
                ...formData,
                pickedUpAt: checked ? new Date() : '',
                [id]: checked,
            });
        }
        if (id === 'isDeliveryStarted') {
            setFormData({
                ...formData,
                deliveryStartedAt: checked ? new Date() : '',
                [id]: checked,
            });
        }
    };

    const handleImageUpload = (header, type, title, fileTypes) => {
        setImageUploadModalState({
            show: true,
            data: null,
            header,
            type,
            title,
            fileTypes,
            onUpload: async (files) => {
                setAttachmentsObj({
                    ...attachmentsObj,
                    [type]: {
                        data: files[0],
                        lastUpdated: new Date(),
                        isDbSaved: false,
                        name: files[0].name || '',
                    },
                });
                setImageUploadModalState((prevState) => ({
                    ...prevState,
                    loading: false,
                    show: false,
                }));
            },
        });
    };

    const toggleImageUploadModal = () => {
        setImageUploadModalState({
            show: false,
            data: null,
            type: null,
        });
    };

    /** Check picked up date/time sets after the pickup start date/time   */
    function isPickUpStartedInValid() {
        if (formData.pickedUpAt && formData.pickUpStartedAt) {
            const { pickedUpAt, pickUpStartedAt } = formData;
            if (moment(pickUpStartedAt).isAfter(moment(pickedUpAt))) {
                return true;
            }
        }
        return false;
    }

    /** Check delivery start date/time sets after the picked up date/time   */
    function isPickedUpInValid() {
        if (formData.pickedUpAt && formData.deliveryStartedAt) {
            const { pickedUpAt, deliveryStartedAt } = formData;
            if (moment(pickedUpAt).isAfter(moment(deliveryStartedAt))) {
                return true;
            }
        }
        return false;
    }

    /** Check delivery completed date/time sets after the delivery start date/time   */
    function isDeliveryStartedAtInValid() {
        if (formData.deliveryStartedAt && formData.completedAt) {
            const { deliveryStartedAt, completedAt } = formData;
            if (moment(deliveryStartedAt).isAfter(moment(completedAt))) {
                return true;
            }
        }
        return false;
    }

    const selectedReasons = useMemo(() => getDefaultValueForSelect(formData.selectReason), [formData.selectReason]);

    return (
        <>
            <div className="font-size-14 w-100 p-0">
                <div
                    className="font-size-16 text-white"
                    style={{ backgroundColor: THEME_COLOR_2, padding: 15, borderRadius: '5px 5px 0 0' }}
                >
                    Complete the Delivery - {merchantOrder} | {referenceNumber}
                </div>
                <div className="modal__form-table">
                    <AvForm onSubmit={handleSubmit}>
                        <fieldset disabled={isCompleted}>
                            <div className="modal_form-item">
                                {(!isPickUpStarted || !isPickedUp || !isDeliveryStarted) && (
                                    <div
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            background: '#fffbf0',
                                            marginBottom: '15px',
                                            border: '1px solid #FFEDB7',
                                        }}
                                    >
                                        Delivery{' '}
                                        <em>
                                            <b>
                                                {!isPickUpStarted && 'Started to Pick Up At'}
                                                {!isPickedUp && ', Picked At'}{' '}
                                                {!isDeliveryStarted && ', Started the Delivery At  '}
                                            </b>
                                        </em>
                                        not marked for this delivery. Please select the status date and time to complete
                                        the delivery.
                                    </div>
                                )}
                                {!isPickUpStarted && (
                                    <div className="modal_form-item">
                                        <Row>
                                            <Col xs="auto">
                                                <Label htmlFor="isStartedPickUP" className="form-check-label">
                                                    Started the Pick Up At
                                                </Label>
                                            </Col>
                                            <Col xs="auto">
                                                <Input
                                                    onChange={handleCheckChangeFilter}
                                                    type="checkbox"
                                                    validate={{ required: { value: true } }}
                                                    checked={formData.isPickUpStarted}
                                                    id="isPickUpStarted"
                                                />
                                            </Col>
                                        </Row>
                                        <div>
                                            <FormGroup>
                                                <Input
                                                    onChange={handleInputChange}
                                                    type="datetime-local"
                                                    value={getDateValue(formData.pickUpStartedAt, 'datetime-local')}
                                                    validate={{ required: { value: true } }}
                                                    id="pickUpStartedAt"
                                                    disabled={!formData.isPickUpStarted}
                                                    max={getDateValue(formData.pickedUpAt, 'datetime-local')}
                                                    invalid={isPickUpStartedInValid()}
                                                />
                                                {isPickUpStartedInValid() && (
                                                    <FormFeedback>
                                                        {"Date/Time cannot be after 'Picked At'"}
                                                    </FormFeedback>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                )}
                                {!isPickedUp && (
                                    <div className="modal_form-item">
                                        <Row>
                                            <Col xs="auto">
                                                <Label htmlFor="isPickedAt" className="form-check-label">
                                                    Picked At
                                                </Label>
                                            </Col>
                                            <Col xs="auto">
                                                <Input
                                                    onChange={handleCheckChangeFilter}
                                                    type="checkbox"
                                                    checked={formData.isPickedUp}
                                                    validate={{ required: { value: true } }}
                                                    id="isPickedUp"
                                                />
                                            </Col>
                                        </Row>
                                        <div>
                                            <FormGroup>
                                                <Input
                                                    onChange={handleInputChange}
                                                    type="datetime-local"
                                                    value={getDateValue(formData.pickedUpAt, 'datetime-local')}
                                                    validate={{ required: { value: true } }}
                                                    id="pickedUpAt"
                                                    disabled={!formData.isPickedUp}
                                                    max={getDateValue(formData.deliveryStartedAt, 'datetime-local')}
                                                    invalid={isPickedUpInValid()}
                                                />
                                                {isPickedUpInValid() && (
                                                    <FormFeedback>
                                                        {"Date/Time cannot be after 'Started Delivery At'"}
                                                    </FormFeedback>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                )}
                                {!isDeliveryStarted && (
                                    <div className="modal_form-item">
                                        <Row>
                                            <Col xs="auto">
                                                <Label htmlFor="DeliveredAt" className="form-check-label">
                                                    Started the Delivery At
                                                </Label>
                                            </Col>
                                            <Col xs="auto">
                                                <Input
                                                    onChange={handleCheckChangeFilter}
                                                    type="checkbox"
                                                    checked={formData.isDeliveryStarted}
                                                    validate={{ required: { value: true } }}
                                                    id="isDeliveryStarted"
                                                />
                                            </Col>
                                        </Row>
                                        <div>
                                            <FormGroup>
                                                <Input
                                                    onChange={handleInputChange}
                                                    type="datetime-local"
                                                    value={getDateValue(formData.deliveryStartedAt, 'datetime-local')}
                                                    validate={{ required: { value: true } }}
                                                    id="deliveryStartedAt"
                                                    disabled={!formData.isDeliveryStarted}
                                                    max={getDateValue(formData.completedAt, 'datetime-local')}
                                                    invalid={isDeliveryStartedAtInValid()}
                                                />
                                                {isDeliveryStartedAtInValid() && (
                                                    <FormFeedback>
                                                        {"Date/Time cannot be after 'Delivery Completed At'"}
                                                    </FormFeedback>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="modal_form-item">
                                <Label htmlFor="reason">Reason for not completing through the Mobile App</Label>
                                <CreatableSelectAdvanced
                                    options={reason}
                                    isClearable
                                    value={selectedReasons}
                                    required
                                    styles={reactSelectCustomStyles}
                                    id="selectReason"
                                    isLoading={customLoading.reason}
                                    onChange={(event) => handleSelectChange(event, 'selectReason')}
                                    onCreate={(event) => handleCreateSelectChange(event, 'selectReason')}
                                    submitted={submitted}
                                    validated={validated}
                                />
                            </div>
                            <div className="modal_form-item">
                                <Label htmlFor="Description">Notes</Label>
                                <div>
                                    <Input
                                        style={{ borderRadius: 2, padding: 5, width: '100%' }}
                                        rows="3"
                                        cols=""
                                        type="textarea"
                                        id="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="modal_form-item">
                                <Label htmlFor="completedAt">Delivery Completed At</Label>
                                <div>
                                    <Input
                                        onChange={handleInputChange}
                                        type="datetime-local"
                                        value={getDateValue(formData.completedAt, 'datetime-local')}
                                        validate={{ required: { value: true } }}
                                        id="completedAt"
                                        max={getDateValue(moment(), 'datetime-local')}
                                    />
                                </div>
                            </div>

                            <div className="modal_form-item">
                                <Label htmlFor="pod">
                                    Proof of Delivery (POD){' '}
                                    {attachmentsObj.podImageUrl.data && attachmentsObj.podImageUrl.data.name && (
                                        <div>{attachmentsObj.podImageUrl.data.name}</div>
                                    )}
                                </Label>
                                <div>
                                    <ButtonGroup>
                                        {attachmentsObj.podImageUrl.data && (
                                            <Button
                                                color="warning"
                                                onClick={() =>
                                                    setAttachmentPreviewModalState({
                                                        type: 'default',
                                                        show: true,

                                                        header: `Delivery Id  ${deliveryId ? `- ${deliveryId}` : ''}`,
                                                        imageUrl: attachmentsObj.podImageUrl.data,
                                                        isDbSaved: attachmentsObj.podImageUrl.isDbSaved,
                                                    })
                                                }
                                                id="view-pod-front-btn"
                                            >
                                                View File
                                            </Button>
                                        )}
                                        <Button
                                            color="warning"
                                            onClick={() => {
                                                handleImageUpload(
                                                    'Proof of Delivery (POD)',
                                                    'podImageUrl',
                                                    'Proof of Delivery (POD)',
                                                    'image/jpeg, image/png'
                                                );
                                            }}
                                            id="upload-pod-btn"
                                        >
                                            {attachmentsObj.podImageUrl.data ? 'Update' : 'Upload'}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>

                            <div
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    background: '#fffbf0',
                                    marginBottom: '15px',
                                    border: '1px solid #FFEDB7',
                                }}
                            >
                                Type &apos;confirm&apos; to confirm the transaction and click on confirm button.
                            </div>

                            <div className="modal_form-item">
                                {/* <FormGroup> */}
                                <AvField
                                    value={formData.confirmation}
                                    name="confirmation"
                                    onChange={handleInputChange}
                                    errorMessage="Confirmation Required"
                                    required
                                    id="confirmation"
                                    autoComplete="off"
                                />
                                {/* </FormGroup> */}
                            </div>

                            <div style={{ fontSize: 14 }} className="mb-6 flex row">
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn pt-1 pb-1 m-2 mb-3 pr-5 pl-5 shadow-lg "
                                    style={{ borderRadius: 10 }}
                                    type="submit"
                                    disabled={formLoading}
                                >
                                    {formLoading ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>CONFIRM</>
                                    )}
                                </motion.button>
                            </div>
                        </fieldset>
                    </AvForm>
                </div>
            </div>

            {imageUploadModalState.show && (
                <ImageUploadModal toggleModal={toggleImageUploadModal} {...imageUploadModalState} />
            )}

            {attachmentPreviewModalState.show && (
                <AttachmentPreviewModal
                    image={attachmentPreviewModalState.imageUrl}
                    show={attachmentPreviewModalState.show}
                    type={attachmentPreviewModalState.type}
                    header={attachmentPreviewModalState.header}
                    toggleModal={() => {
                        setAttachmentPreviewModalState({ show: false, multiple: false });
                    }}
                    {...attachmentPreviewModalState}
                    isFormAttachmentModal
                />
            )}
        </>
    );
}

CompleteDelivery.propTypes = {
    merchantOrder: PropTypes.any,
    deliveryId: PropTypes.any,
    setShowModal: PropTypes.func,
    createBillingRecord: PropTypes.func,
    toggleModal: PropTypes.func,
    referenceNumber: PropTypes.any,
    data: PropTypes.any,
    loadTransactions: PropTypes.func,
    setDeliveryFormData: PropTypes.func,
    updateHistoryWithData: PropTypes.func,
};

export default CompleteDelivery;
