import { DOWNLOAD_FILE_BY_LOCATION } from 'utils/apiEndpoints';
import { downloadFile } from 'utils/httpclients/instances';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Download Excel Import File
 * @returns {Promise}
 */
export const downloadExcelImportFile = (fileLocation) => {
    try {
        const url = UrlBuilder().setUrl(DOWNLOAD_FILE_BY_LOCATION).build();
        return downloadFile(url, { fileLocation });
    } catch (exception) {
        return Promise.reject(exception);
    }
};
