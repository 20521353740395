export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Delivery ID',
    },
    {
        key: 'deliveryType',
        value: 'Delivery Type',
    },
    {
        key: 'orderReferenceNumber',
        value: 'Order Number',
    },
    {
        key: 'merchantOrderNo',
        value: 'Merchant Order Number',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
    {
        key: 'deliveryReadyTime',
        value: 'Ready Time',
        type: 'dateTime',
    },
    {
        key: 'deliveryDate',
        value: 'Delivery Date',
    },
    {
        key: 'pickupLocation',
        value: 'From',
        style: ['increase-width'],
    },
    {
        key: 'riderDetails',
        value: 'Rider',
    },
    {
        key: 'recipientMobileNumber',
        value: 'Recipient Mobile No',
    },
    {
        key: 'deletedAt',
        value: 'Deleted At',
        type: 'dateTime',
    },
    {
        key: '_id',
        value: 'Restore',
        type: 'restoreButton',
    },
    {
        key: '_id',
        value: 'Permanent Delete',
        type: 'permanentDeleteButton',
    },
    { key: 'deletedBy', value: 'Deleted By', type: 'userObject' },
];
