/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Row, Col, Card, CardBody, FormGroup, Label, Spinner, Input, Container, Alert } from 'reactstrap';
import toast from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import * as qs from 'query-string';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// #region components
import { BackdropLoader, CustomToast, TableCustom } from 'components';
import Hoc from 'components/Hoc';
import Breadcrumbs from 'components/Common/Breadcrumb';

// #region imports
import PATHS from 'routes/paths';
import { SubTitle } from 'styles';

// #region utils
import { apiErrorHandler, apiErrorHandlerV2, formatter, getDateValue } from 'utils/helpers';

// #region services
import {
    updateBillingRecordService,
    getBillingRecordByIdService,
    recalculateBillingRecord,
} from 'services/billing-record.service';
import { getDeliveriesByBillingRecordId } from '../../services';
import { initialDeliveryData } from '../constants/index';
import { getMerchantPaymentStatus, mapDocs } from '../utils';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Billing Records', link: PATHS.PAYMENTS.BILLING_LIST },
];

function BillingRecordFormPage(props) {
    // Component
    const [loading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const history = useHistory();

    const [formData, setFormData] = useState({ currency: 'MYR', merchantPaidAmount: 0 });

    // Form State
    const [isViewMode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [validated, setValidated] = useState(false);
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);

    const [alertData, setAlertData] = useState({ message: null, color: 'warning' });

    const [deliveryData, setDeliveryData] = useState(initialDeliveryData);

    const [paymentStatus, setPaymentStatus] = useState('Unpaid');

    useEffect(() => {
        if (!props.location) {
            history.goBack();
            return;
        }

        const formType = qs.parse(props.location.search);

        if (formType && formType.type && formType.type === 'edit') {
            handleUpdateComponentEditMode();
        }
    }, []);

    useEffect(() => {
        let balanceAmount = formData.deliveryFee - formData.merchantPaidAmount;

        if (balanceAmount < 0) {
            balanceAmount = 0;
        }

        setFormData((prevState) => ({
            ...prevState,
            merchantBalanceAmount: balanceAmount.toFixed(2),
        }));
    }, [formData.merchantPaidAmount]);

    /** Opens the delivery page in a new tab */
    const handleViewDelivery = (row) => {
        if (!row) return;
        window.open(`${PATHS.DELIVERY.FORM}?type=edit&id=${row._id}`);
    };

    /**
     * Retrieves deliveries for billing by billingRecordId and updates the delivery data state.
     * @param {string} id - The ID of the billing record.
     * @returns {Promise<void>} - A promise that resolves when the deliveries have been retrieved and the state has been updated.
     */
    const getDeliveries = async (id) => {
        try {
            const { data } = await getDeliveriesByBillingRecordId(id);
            if (data && data.deliveries) {
                // add formatted recipient column data
                const docs = mapDocs(data?.deliveries);
                setDeliveryData({ ...deliveryData, docs });
            }
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    const handleSubmit = async (event, errors) => {
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        let updatedPayloadData;

        setFormLoading(true);

        try {
            const payload = formData;

            setAlertData((prevState) => ({ ...prevState, message: null }));

            const { data } = await updateBillingRecordService(payload);
            if (data) {
                // get deliveryFee and merchantPaidAmount to calculate the balance amount
                const { deliveryFee, merchantPaidAmount } = data;
                const balanceAmount = deliveryFee - merchantPaidAmount;

                // set payment status
                setPaymentStatus(getMerchantPaymentStatus(balanceAmount));
                setFormData({ ...formData, ...data });
                updatedPayloadData = { ...formData, ...data };
            }

            try {
                if (updatedPayloadData) {
                    history.replace({
                        pathname: `${PATHS.PAYMENTS.BILLING_RECORD}`,
                        search: `?type=edit&id=${data._id}`,
                        state: {
                            billingRecordParentData: updatedPayloadData,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }

            toast.custom((t) => <CustomToast text="Successfully updated Billing Record" t={t} type="success" />, {
                position: 'top-right',
            });
            setIsFormSaved(true);
        } catch (e) {
            console.log(e);
            const errorMessage = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading(false);
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleUpdateComponentEditMode = async () => {
        try {
            const urlSearch = qs.parse(props.location.search);
            const { id } = urlSearch;
            if (!id && !props.location.state) {
                history.push(PATHS.PAYMENTS.BILLING_LIST);
                return;
            }
            getDeliveries(id);

            loadBillingRecordById(id);
            // Update URL Based on Condition
            setIsFormSaved(true);

            // Destructuring the billingRecordParentData object from the state property of props.location
            const { billingRecordParentData } = props.location.state;
            // get deliveryFee and merchantPaidAmount to calculate the balance amount
            const { deliveryFee, merchantPaidAmount } = billingRecordParentData;
            const balanceAmount = deliveryFee - merchantPaidAmount;
            // set payment status
            setPaymentStatus(getMerchantPaymentStatus(balanceAmount));

            // add data to formData
            setFormData({
                ...formData,
                ...billingRecordParentData,
                merchantBalanceAmount: balanceAmount || 0,
            });

            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Billing Record', link: '#' }]);
            // eslint-disable-next-line no-unused-expressions
        } catch (e) {
            console.log(e);
        }
    };

    const loadBillingRecordById = async (id) => {
        setBackdropLoading((x) => x + 1);
        try {
            const { data } = await getBillingRecordByIdService(id);
            if (data) {
                setFormData((prevState) => ({
                    ...prevState,
                    ...data,
                    merchantBalanceAmount: data.deliveryFee - data.merchantPaidAmount,
                }));
                history.replace({
                    pathname: `${PATHS.PAYMENTS.BILLING_RECORD}`,
                    search: `?type=edit&id=${data._id}`,
                    state: {
                        billingRecordParentData: data,
                    },
                });
            } else {
                toast.custom((t) => <CustomToast text="Billing Record Details Not Found" t={t} type="error" />, {
                    position: 'top-right',
                });
                history.goBack();
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((x) => x - 1);
    };

    const handleRecalculateBillingRecord = async () => {
        setBackdropLoading((x) => x + 1);
        try {
            const { data } = await recalculateBillingRecord(formData._id);
            if (data) {
                if (data.deliveryFee !== formData.deliveryFee || data.riderCommission !== formData.riderCommission) {
                    setAlertData((prevState) => ({
                        ...prevState,
                        message: 'Delivery Fee and Rider Commission Changed, Press Update to Save ',
                    }));
                }
                setFormData((prevState) => ({
                    ...prevState,
                    ...data,
                    merchantBalanceAmount: data.deliveryFee - prevState.merchantPaidAmount || 0,
                }));
            } else {
                toast.custom((t) => <CustomToast text="Billing Record Details Not Found" t={t} type="error" />, {
                    position: 'top-right',
                });
                history.goBack();
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setBackdropLoading((x) => x - 1);
    };

    return (
        <>
            <Hoc {...props}>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                        <Row
                            style={{
                                margin: 2,
                                justifyContent: 'space-between',
                            }}
                        >
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                <i className="fas fa-arrow-left mr-2 fa-lg" />
                                Back to Billing Records
                            </motion.div>
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                onClick={() => {
                                    handleRecalculateBillingRecord();
                                }}
                            >
                                Recalculate Record
                            </motion.div>
                        </Row>

                        {alertData && alertData.message && (
                            <Alert color={alertData.color || 'warning'}>{alertData.message}</Alert>
                        )}

                        {formData._id && (
                            <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                                <CardBody>
                                    <Row className="mt-4">
                                        <Col>
                                            <h6>Pickup Addresss</h6>
                                            <h5>
                                                <b>{`${formData.pickupLocation}`}</b>
                                            </h5>
                                        </Col>
                                        <Col>
                                            <h6>Delivery Address</h6>
                                            <h5>
                                                <b>{`${formData.deliveryAddress}`}</b>
                                            </h5>
                                        </Col>
                                        <Col>
                                            <h6>Rider</h6>
                                            <h5>
                                                <b>{`${formData?.riderId?.fullName}`}</b>
                                            </h5>
                                        </Col>
                                        <Col>
                                            <h6>Distance</h6>
                                            <h5>
                                                <b>{`${formData.formattedDistance || formData.distance || '0'} KM`}</b>
                                            </h5>
                                        </Col>
                                        <Col>
                                            <h6>Billing Method</h6>
                                            <h5>
                                                <b>{`${formData.billingMethod}`}</b>
                                            </h5>
                                        </Col>
                                        {/* <Col>
                                        <h6>Rate</h6>
                                        <h5>
                                            <b>{`MYR ${formData.fixedDeliveryRate}`}</b>
                                        </h5>
                                    </Col> */}
                                        <Col>
                                            <h6>Delivery Fee</h6>
                                            <h5>
                                                <b>{`MYR ${formatter.format(formData.deliveryFee?.toFixed(2))}`}</b>
                                            </h5>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        )}

                        <Card>
                            <CardBody>
                                <Row style={{ justifyContent: 'space-between', alignItems: 'center', margin: 2 }}>
                                    <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                        PAYMENTS FROM MERCHANT
                                    </SubTitle>
                                    <SubTitle
                                        style={{
                                            color: '#574b90',
                                            letterSpacing: 0.5,
                                            marginLeft: -2,
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {paymentStatus}
                                    </SubTitle>
                                </Row>
                                <br />
                                <AvForm
                                    autoComplete="off"
                                    className="needs-validation default-form"
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                    id="billingForm"
                                >
                                    <fieldset disabled={isViewMode || formLoading}>
                                        <input type="hidden" value="something" />

                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="amount">Amount</Label>
                                                    <AvField
                                                        name="amount"
                                                        placeholder="Amount"
                                                        type="number"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                            },
                                                        }}
                                                        id="amount"
                                                        disabled
                                                        value={formData.deliveryFee}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="merchantPaidAmount">Paid Amount</Label>
                                                    <AvField
                                                        name="merchantPaidAmount"
                                                        placeholder="Paid Amount"
                                                        type="number"
                                                        errorMessage="Invalid Amount"
                                                        className="form-control"
                                                        validate={{
                                                            max: {
                                                                value: formData.deliveryFee,
                                                            },
                                                        }}
                                                        max={formData.deliveryFee}
                                                        min={0}
                                                        id="merchantPaidAmount"
                                                        value={formData.merchantPaidAmount}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="balanceAmount">Balance Amount</Label>
                                                    <AvField
                                                        name="balanceAmount"
                                                        placeholder="Balance Amount"
                                                        type="string"
                                                        className="form-control"
                                                        disabled
                                                        id="balanceAmount"
                                                        value={formData.merchantBalanceAmount}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            {formData.merchantBalanceAmount === '0.00' && (
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="merchantPaymentReceivedDate">
                                                            Payment Received Date and Time
                                                        </Label>
                                                        <Input
                                                            onChange={handleInputChange}
                                                            type="datetime-local"
                                                            className="form-control"
                                                            value={getDateValue(
                                                                formData.merchantPaymentReceivedDate,
                                                                'datetime-local'
                                                            )}
                                                            validate={{ required: { value: true } }}
                                                            id="merchantPaymentReceivedDate"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            )}
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="merchantPaymentReference">Payment Reference</Label>
                                                    <AvField
                                                        name="merchantPaymentReference"
                                                        placeholder="Payment Reference"
                                                        type="text"
                                                        className="form-control"
                                                        id="merchantPaymentReference"
                                                        value={formData.merchantPaymentReference}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ justifyContent: 'flex-start' }}>
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                type="submit"
                                                disabled={formLoading}
                                            >
                                                {formLoading ? (
                                                    <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                ) : (
                                                    <>
                                                        <i className="fas fa-save left-icon"></i>
                                                        <span className="h6">Update</span>
                                                    </>
                                                )}
                                            </motion.button>
                                        </Row>
                                    </fieldset>
                                </AvForm>
                            </CardBody>
                        </Card>

                        <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                            <CardBody>
                                <Row style={{ justifyContent: 'space-between', alignItems: 'center', margin: 2 }}>
                                    <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}>
                                        Deliveries
                                    </SubTitle>
                                </Row>

                                <TableCustom
                                    tableData={deliveryData}
                                    onViewDelivery={handleViewDelivery}
                                    loading={loading}
                                    showView={false}
                                    isFilter={false}
                                    isSearch={false}
                                    showEdit={false}
                                    removeAction
                                    isFixedHeight
                                />
                            </CardBody>
                        </Card>

                        <BackdropLoader show={backdropLoading || loading} opacity={0.7} />
                    </Container>
                </div>
            </Hoc>
        </>
    );
}

BillingRecordFormPage.propTypes = {
    location: PropTypes.any,
};

const HotBillingRecordFormPage = hot(BillingRecordFormPage);

export default HotBillingRecordFormPage;
