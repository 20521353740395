import moment from 'moment';

/**
 * Change values in partner-api service response to work with table component
 * @param {array} docs - array of docs returned from service
 * @returns {array} array of formatted docs
 */
export const mapDocs = (docs) => {
    let allDocs = [];
    allDocs = docs.map((doc) => ({
        ...doc,
        developerContactNumber: doc.developerContactNumber ? `+${doc.developerContactNumber}` : '',
        serviceStatusClientValue: doc.serviceStatusClient ? 'active' : 'inactive',
        merchantPictureUrl: doc.merchant.merchantPictureUrl,
        merchantReferenceNumber: doc.merchant.referenceNumber,
        statusUpdatedAt: moment(doc.createdAt).format('DD-MM-YYYY hh:mm A'),
    }));
    return allDocs;
};
