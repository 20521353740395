/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import { AvForm } from 'availity-reactstrap-validation';
import { Alert, Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';

// #region assets | components
import { CheckCircle, CrossCircle } from 'components/Icons';
import CustomToast from 'components/Toast/Custom';

// #region services
import { detachDeliveryFromRoutePlan } from 'services/routePlanning/routePlanning.service';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #endregion imports

/**
 * Renders a modal component to detach a delivery from a route planning.
 *
 * @typedef {Object} Props
 * @property {Function} toggleModal - A function to toggle the visibility of the modal.
 * @property {string} detachDeliveryId - The ID of the delivery to detach.
 * @property {boolean} isUnassignRiderPossible - Whether unassigning a rider from the delivery is possible.
 * @property {string} routePlanningId - The ID of the route planning to detach the delivery from.
 * @property {Function} fetchRelevantDeliveryData - A function to fetch the relevant delivery data.
 * @property {Function} fetchRoutePlanningData - A function to fetch the route planning data.
 *
 * @param {Props} props - The component's props.
 *
 * @returns {JSX.Element} A React component that displays a modal to detach a delivery from a route planning.
 */
function DetachDeliveryModule(props) {
    const {
        toggleModal,
        detachDeliveryId,
        isUnassignRiderPossible,
        routePlanningId,
        fetchRelevantDeliveryData,
        fetchRoutePlanningData,
        loadTransactions,
    } = props;

    const [unassignRider, setUnassignRider] = useState(false);
    // form
    const [formLoading, setFormLoading] = useState(false);

    /**
     * Handles the unassign rider checkbox in the modal.
     * @returns {void}
     */
    const handleUnassignRider = () => {
        setUnassignRider(!unassignRider);
    };

    /**
     * Handles the "I am Sure, Detach the Delivery" button click
     * @returns {void}
     */
    const handleSubmit = async () => {
        // whether the detach delivery request success
        let isSuccess = false;
        setFormLoading(true);

        const detachPayload = {
            deliveryId: detachDeliveryId,
            unassignRider,
        };

        try {
            const { status } = await detachDeliveryFromRoutePlan(routePlanningId, detachPayload);
            //  if the rider update process is success
            if (status === 200) {
                isSuccess = true;
                toast.custom(
                    (t) => <CustomToast text="Delivery Detached from  Route Planning Entry" t={t} type="success" />,
                    {
                        position: 'top-right',
                    }
                );
            }
        } catch (error) {
            const { message: exception } = apiErrorHandlerV2(error);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (isSuccess) {
            toggleModal();
            // fetch updated route planning entry data
            fetchRoutePlanningData(routePlanningId);
            // fetch updated route planning entry delivery data
            fetchRelevantDeliveryData(routePlanningId);
            // fetch updated route planning entry Transactions data
            loadTransactions(routePlanningId);
        }
        setFormLoading(false);
    };

    return (
        <>
            <div className="font-size-14 w-100 p-0">
                <div className="modal__form-table">
                    <AvForm onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                {isUnassignRiderPossible && (
                                    <FormGroup className="form-group">
                                        <div className="form-check mb-3">
                                            <Input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={handleUnassignRider}
                                                // checked={isUnassigned}
                                                id="unassignRider"
                                            />
                                            <Label className="form-check-label">Unassign Rider</Label>
                                        </div>
                                    </FormGroup>
                                )}
                            </Col>
                        </Row>
                        <Alert color="warning" style={{ borderRadius: 8 }}>
                            Are you sure you want to detach this delivery from this route planning?
                        </Alert>
                        <Row className="mt-3">
                            <Col xs="auto" className="mb-2 mb-xs-0">
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn shadow-lg "
                                    style={{ borderRadius: 10 }}
                                    type="submit"
                                    disabled={formLoading}
                                >
                                    {formLoading ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>
                                            {' '}
                                            <CheckCircle color="#fff" />
                                            &nbsp;I am Sure, Detach the Delivery
                                        </>
                                    )}
                                </motion.button>
                            </Col>
                            <Col xs="auto">
                                <motion.button
                                    className="remove__btn form-delete-btn shadow-lg"
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    style={{ borderRadius: 10 }}
                                    onClick={toggleModal}
                                >
                                    <CrossCircle color="#FFF" />
                                    &nbsp;CANCEL
                                </motion.button>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </div>
        </>
    );
}

DetachDeliveryModule.propTypes = {
    toggleModal: PropTypes.func,
    detachDeliveryId: PropTypes.string,
    routePlanningId: PropTypes.string,
    isUnassignRiderPossible: PropTypes.bool,
    fetchRoutePlanningData: PropTypes.func,
    fetchRelevantDeliveryData: PropTypes.func,
    loadTransactions: PropTypes.func,
};

export default DetachDeliveryModule;
