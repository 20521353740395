/* eslint-disable indent */
import {
    GET_ORDERS,
    GET_ORDER_BY_ID,
    GET_ALL_ORDERS,
    GET_ALL_ORDERS_META,
    GET_ALL_ORDERS_BY_STATUS,
    UPDATE_ORDER,
    GET_ORDER_BY_MERCHANT_ID,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Orders Service
 * @returns {object}
 */
export const getOrdersService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate = 'merchantId',
    status
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_ORDERS}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : 'sort=updatedAt&'}${order ? `order=${order}&` : `&order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }${status ? `status=${status}` : `status=new`}`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Orders By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getOrderByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ORDER_BY_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Orders
 * @returns {Promise}
 */
export const getAllOrdersService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_ORDERS).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Orders Meta
 * @returns {Promise}
 */
export const getAllOrdersMetaService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_ORDERS_META).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Orders By Status
 * @returns {Promise}
 */
export const getAllOrdersByStatusService = (status = 'new') => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_ORDERS_BY_STATUS).setPathParams({ status }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Orders By Merchant ID
 * @returns {Promise}
 */
export const getAllOrdersByMerchantIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ORDER_BY_MERCHANT_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Order Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateOrderService2 = (_id, data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_ORDER).setPathParams({ id: _id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
