import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody } from 'reactstrap';

// #region utils
import { getDateValue } from 'utils/helpers';

// #endregion imports
import { allowedTransactionsReferences } from './constant';

function DeliveryProgressComponent(props) {
    const { deliveryProgressHistory } = props;

    const filteredList = deliveryProgressHistory.filter(
        (x) => !x.reference || allowedTransactionsReferences.includes(x.reference)
    );

    return (
        <>
            <Card className="shadow-lg default-card">
                <CardBody>
                    <div className="container">
                        {filteredList.map((historyRecord) => (
                            <Row
                                style={{
                                    alignItems: 'center',
                                    marginLeft: -25,
                                }}
                            >
                                <Col md={1} xs={1}>
                                    <i className="mdi mdi-check-circle-outline text-warning fa-2x"></i>
                                </Col>
                                <Col md={11} xs={11}>
                                    <span className="h6 m-0 delivery-view-progress-text">
                                        {`${historyRecord.description || ''} ${getDateValue(
                                            historyRecord.createdAt,
                                            'datetime'
                                        )}`}
                                    </span>
                                </Col>
                            </Row>
                        ))}
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

DeliveryProgressComponent.propTypes = {
    deliveryProgressHistory: PropTypes.array,
};

export default DeliveryProgressComponent;
