import moment from 'moment';
import { privateKeyEndpoints } from 'pages/Modules/Developer/Api-Keys/constants';

export const tableDefaultFilters = [
    {
        label: 'Status',
        key: 'referenceNumber',
    },
    {
        label: 'Status Code',
        key: 'deliveryType',
    },
    {
        label: 'Request Type',
        key: 'referenceNumber',
    },
    {
        label: 'From Date and Time',
        key: 'orderReferenceNumber',
    },
    {
        label: 'To Date',
        key: 'merchantName',
    },
];

export const defaultTableHeadersForMerchant = [
    {
        key: 'sequenceId',
        value: 'REQUEST ID',
    },
    {
        key: 'actionPath',
        value: 'REQUEST TYPE',
    },
    {
        key: 'apiKeyName',
        value: 'API KEY NAME',
    },
    {
        key: 'status',
        value: 'STATUS',
        type: 'apiStatusLabel',
    },
    {
        key: 'statusDescription',
        value: 'STATUS DESCRIPTION',
    },
    {
        key: 'deliverySequenceId',
        value: 'DELIVERY ID',
    },
    {
        key: 'quotationSequenceId',
        value: 'QUOTATION ID',
    },
    {
        key: 'merchantOrderNo',
        value: 'MERCHANT ORDER ID',
    },
    {
        key: 'action',
        value: 'REQUEST DATA',
        type: 'customActionButton',
        params: {
            buttonLabel: 'View',
            customActionHandlerName: 'displayRequestData',
        },
    },
];

export const defaultTableHeadersForClient = [
    {
        key: 'sequenceId',
        value: 'REQUEST ID',
    },
    {
        key: 'actionPath',
        value: 'REQUEST TYPE',
    },
    {
        key: 'merchantName',
        value: 'MERCHANT',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
        style: ['increase-width'],
        customHeaderStyles: {
            minWidth: 250,
        },
    },
    {
        key: 'apiKeyName',
        value: 'API KEY NAME',
    },
    {
        key: 'status',
        value: 'STATUS',
        type: 'apiStatusLabel',
    },
    {
        key: 'statusDescription',
        value: 'STATUS DESCRIPTION',
    },
    {
        key: 'deliverySequenceId',
        value: 'DELIVERY ID',
    },
    {
        key: 'quotationSequenceId',
        value: 'QUOTATION ID',
    },
    {
        key: 'merchantOrderNo',
        value: 'MERCHANT ORDER ID',
    },
    {
        key: 'action',
        value: 'REQUEST DATA',
        type: 'customActionButton',
        params: {
            buttonLabel: 'View',
            customActionHandlerName: 'displayRequestData',
        },
    },
];

export const exportHeadersClientTable = [
    {
        key: 'sequenceId',
        value: 'Request ID',
    },
    {
        key: 'actionPath',
        value: 'Request Type',
    },
    {
        key: 'merchantName',
        value: 'Merchant Name',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
        style: ['increase-width'],
        customHeaderStyles: {
            minWidth: 250,
        },
    },
    {
        key: 'merchantReferenceNumber',
        value: 'Merchant ID',
    },
    {
        key: 'apiKeyName',
        value: 'API Key Name',
    },
    {
        key: 'status',
        value: 'Status',
    },
    {
        key: 'statusDescription',
        value: 'Status Description',
    },
    {
        key: 'deliverySequenceId',
        value: 'Delivery ID',
    },
    {
        key: 'quotationSequenceId',
        value: 'Quotation ID',
    },
    {
        key: 'merchantOrderNo',
        value: 'MERCHANT ORDER ID',
    },
    {
        key: 'ipAddress',
        value: 'IP Address',
    },
];

export const exportHeadersMerchantTable = [
    {
        key: 'sequenceId',
        value: 'Request ID',
    },
    {
        key: 'actionPath',
        value: 'Request Type',
    },
    {
        key: 'apiKeyName',
        value: 'API Key Name',
    },
    {
        key: 'status',
        value: 'Status',
    },
    {
        key: 'statusDescription',
        value: 'Status Description',
    },
    {
        key: 'deliverySequenceId',
        value: 'Delivery ID',
    },
    {
        key: 'quotationSequenceId',
        value: 'Quotation ID',
    },
    {
        key: 'merchantOrderNo',
        value: 'MERCHANT ORDER ID',
    },
    {
        key: 'ipAddress',
        value: 'IP Address',
    },
];

export const tableDataInitialInMerchantDashboardTable = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: defaultTableHeadersForMerchant,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};

export const tableDataInitialInClientDashboardTable = {
    formData: moment().startOf('day').toISOString(),
    toDate: moment().toISOString(),
    clientId: '',
    userType: '',
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: defaultTableHeadersForClient,
    docs: [],
    all: false,
    sort: null,
    order: -1,
};

export const initialCustomDateFormValues = {
    fromDate: moment().startOf('day').toISOString(),
    toDate: moment().toISOString(),
    merchantId: 'All',
};

export const statusDropDownValues = [
    { label: 'All', value: 'all' },
    { label: 'Success', value: 'success' },
    { label: 'Fail', value: ' fail' },
];

export const partnerApiStatusCodeValues = [
    { label: '5120 - Api Key Needed', value: 5120 },
    { label: '5125 - Api Key Inactive', value: 5125 },
    { label: '5129 - Partner Service Disabled', value: 5129 },
    { label: '5133 - Api Rate Limit Exceeded', value: 5133 },
];

const {
    cancelDelivery,
    createDelivery,
    getDeliveryByDeliveryId,
    getDeliveryCharges,
    // getDeliveryReadyStatusUpdate,
    getQuotation,
    getShippingLabel,
    trackDelivery,
    updateDelivery,
} = privateKeyEndpoints;

export const partnerApiSActionPathsDropDownValues = [
    { label: 'Create Delivery', value: createDelivery.key },
    { label: 'Update Delivery', value: updateDelivery.key },
    { label: 'Cancel Delivery', value: cancelDelivery.key },
    { label: 'Track Delivery', value: trackDelivery.key },
    { label: 'Get Shipping Label', value: getShippingLabel.key },
    { label: 'Get Delivery', value: getDeliveryByDeliveryId.key },
    { label: 'Calculate Delivery Charges', value: getDeliveryCharges.key },
    { label: 'Get Quotation', value: getQuotation.key },
    // { label: 'Get Delivery Ready Status Update', value: getDeliveryReadyStatusUpdate.key },
];
