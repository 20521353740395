/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Container, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';

// #region components | assets
import { centerElementInnerStyles } from 'styles';
import { getAttachmentByKey } from 'services/attachment.service';

/**
 * Rider Card
 * @param {*} props
 * @returns
 */
function RiderCard(props) {
    const { riderData = { currentTasks: [] }, setSelectedRider, selectedRider, riderKey } = props;

    const [isActive, setIsActive] = useState(false);

    // image
    const [images, setImages] = useState({
        profilePictureUrl: null,
    });

    useEffect(() => {
        if (riderData.profilePictureUrl) {
            getAttachmentByKey(riderData.profilePictureUrl).then((res) => {
                setImages({ ...images, profilePictureUrl: res });
            });
        }
    }, [riderData.profilePictureUrl]);

    useEffect(() => {
        if (selectedRider?.rider?.riderId === riderData?.riderId) {
            // console.log(`rider-card-active ${riderData.riderId}`);
            setIsActive(true);
        } else {
            setIsActive(false);
            // console.log(`rider-card-inactive ${riderData.riderId}`);
        }
    }, [selectedRider]);

    return (
        <Card
            className={`mt-3 pt-2 pb-2 ${isActive ? 'shadow-lg' : 'shadow-sm'} pointer`}
            style={{ minHeight: 100, display: 'flex' }}
            // onMouseEnter={() => {
            //     setSelectedRider({ selectedType: 'card', rider: riderData });
            // }}
            onMouseLeave={() => {
                setSelectedRider({ selectedType: 'none', rider: null });
            }}
            onClick={() => {
                setSelectedRider({ selectedType: 'card', rider: riderData });
            }}
            key={riderKey}
        >
            <div style={{ flex: 0.1 }} />
            <Container style={{ width: '100%', flex: 0.8 }} className="tracking-rider-card">
                <Row>
                    <Col md={2} style={{ ...centerElementInnerStyles }}>
                        {images.profilePictureUrl ? (
                            <img
                                className="rounded-circle header-profile-user ml-2"
                                src={images.profilePictureUrl}
                                alt="Header Avatar"
                                style={{ height: 50, width: 50, marginLeft: 10 }}
                            />
                        ) : (
                            <Avatar
                                className="image-upload-avatar ml-1 mb-2 mt-2"
                                name={riderData.fullName}
                                size="40"
                                round="50px"
                            />
                        )}
                        <br />
                        <div className="rider-badge" style={{ marginLeft: -2 }}>
                            {riderData.currentTasks ? (riderData.currentTasks.length > 0 ? 'Busy' : 'Idle') : 'Idle'}
                        </div>
                    </Col>
                    <Col md={8} style={{ ...centerElementInnerStyles, marginLeft: -13 }}>
                        <Badge style={{ backgroundColor: '#c5e3f6', color: 'grey' }}>New</Badge>
                        <div className="mt-1" style={{ fontSize: 17 }}>
                            <b>{riderData.fullName || ''}</b>
                        </div>
                        <div className="mt-n1" style={{ color: 'grey' }}>
                            <b>{riderData.email || ''}</b>
                        </div>
                        <div style={{ color: 'grey' }}>
                            <i>{riderData.mobileNo || ''}</i>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div style={{ flex: 0.1 }} />
        </Card>
    );
}

RiderCard.propTypes = {
    riderData: PropTypes.object,
    setSelectedRider: PropTypes.any,
    selectedRider: PropTypes.object,
    riderKey: PropTypes.string,
};

export default RiderCard;
