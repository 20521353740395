import React from 'react';
import PropTypes from 'prop-types';

// #endregion imports
import Search from './TableHeaderComponents/TableSearch';

function DeliveryBatchAssignmentDeliveriesTableHeader(props) {
    const { isManualSearch, onFilter, isSearch, setSearch, search } = props;

    return (
        <>
            <div className="table-top-row ">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div className="font-weight-bold" style={{ fontSize: 15 }}>
                        Delivery ID
                    </div>
                    {isSearch && (
                        <Search
                            onFilter={onFilter}
                            isManualSearch={isManualSearch}
                            setSearch={setSearch}
                            search={search}
                            searchPlaceholder="Search"
                        />
                    )}
                </div>
            </div>
        </>
    );
}

DeliveryBatchAssignmentDeliveriesTableHeader.propTypes = {
    isManualSearch: PropTypes.bool,
    onFilter: PropTypes.func,
    isSearch: PropTypes.bool,
    setSearch: PropTypes.func,
    search: PropTypes.object,
};

export default DeliveryBatchAssignmentDeliveriesTableHeader;
