import * as React from 'react';
import PropTypes from 'prop-types';

function NotesIcon(props) {
    const { color, width, height } = props;
    return (
        <svg
            width={width || 21}
            height={height || 21}
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2689_365)">
                <path
                    d="M14.875 2.625H6.125C5.1585 2.625 4.375 3.4085 4.375 4.375V16.625C4.375 17.5915 5.1585 18.375 6.125 18.375H14.875C15.8415 18.375 16.625 17.5915 16.625 16.625V4.375C16.625 3.4085 15.8415 2.625 14.875 2.625Z"
                    stroke={color || '#fff'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.875 6.125H13.125"
                    stroke={color || '#fff'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.875 9.625H13.125"
                    stroke={color || '#fff'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.875 13.125H11.375"
                    stroke={color || '#fff'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2689_365">
                    <rect width="21" height="21" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
NotesIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default NotesIcon;
