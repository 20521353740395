/* eslint-disable indent */
import {
    CREATE_PARTNER_API_DATA,
    GET_PARTNERS_FROM_PARTNER_API,
    GET_PARTNER_API_DATA_BY_MERCHANT_ID,
    ENABLE_PARTNER_API_SERVICE,
    DISABLE_PARTNER_API_SERVICE,
    ENABLE_PARTNER_API_SERVICE_BY_MERCHANT_USER,
    GET_API_KEY_DATA,
    GENERATE_API_KEY,
    GET_API_KEY_BY_ID,
    UPDATE_API_KEY_BY_ID,
    ENABLE_API_KEY_BY_ID,
    DISABLE_API_KEY_BY_ID,
    SHOW_SECRET_KEY,
    GET_SERVICE_STATUS,
    GET_DASHBOARD_STATS_BY_MERCHANT_ID,
    GET_DASHBOARD_STATS_BY_CLIENT_ID,
    GET_DASHBOARD_GRAPH_DATA_BY_MERCHANT_ID,
    GET_DASHBOARD_TABLE_DATA_BY_ID,
    GET_WEBHOOKS_LIST,
    CREATE_WEBHOOK,
    GET_WEBHOOK_BY_ID,
    UPDATE_WEBHOOK,
    ENABLE_WEBHOOK,
    DISABLE_WEBHOOK,
    WEBHOOK_VIEW_AUTH_VALUE,
    GET_DASHBOARD_LATEST_REQUEST_BY_CLIENT_ID,
    GET_DASHBOARD_OVERVIEW_GRAPH_DATA_BY_CLIENT_ID,
    DISABLE_PARTNER_API_SERVICE_BY_MERCHANT_USER,
    GET_PARTNER_API_CONNECTED_APPS_LIST,
    DISCONNECT_PARTNER_API_CONNECTED_APP,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Partner API Data By Merchant Id
 * @param {string} id
 * @returns {Promise}
 */

export const getPartnerApiDataByMerchantId = (merchantId) => {
    const url = UrlBuilder().setUrl(GET_PARTNER_API_DATA_BY_MERCHANT_ID).setPathParams({ merchantId }).build();
    return instance.get(url);
};

/**
 * Create Partner API Data
 * @param {object} data
 * @returns {Promise}
 */
export const createPartnerApiData = (data) => {
    const url = UrlBuilder().setUrl(CREATE_PARTNER_API_DATA).build();
    return instance.post(url, data);
};

/**
 * Get the list of partners
 * @param {number} page - page number
 * @param {number} limit - page size
 * @param {string} sort - key to sort by
 * @param {number} order - order of sorting
 * @param {object} search - search props object
 * @param {string} status - whether 'active' or 'inactive'
 * @returns {object} response from the partner api
 */
export const getPartnersFromPartnerApi = (page = 1, limit = 10, sort = 'createdAt', order = -1, search, status) => {
    const queryParams = {
        page,
        limit,
        sort: sort ? Object.keys(sort)[0] : 'createdAt',
        order,
    };
    if (search?.searchBy) queryParams.searchBy = search.searchBy;
    if (search?.searchText) queryParams.searchText = search.searchText;

    if (status) {
        if (status === 'active') {
            queryParams.serviceStatusClient = 1;
        }
        if (status === 'inactive') {
            queryParams.serviceStatusClient = 0;
        }
    }

    const url = UrlBuilder().setUrl(GET_PARTNERS_FROM_PARTNER_API).setQueryParams(queryParams).build();
    return instance.get(url);
};

/**
 * Enable the API service for the given merchant
 * @param {string} merchantId - ID of the merchant
 * @param {object} data - modal form data
 * @returns {object} response from the partner api
 */
export const enableApiService = (merchantId, data) => {
    const url = UrlBuilder().setUrl(ENABLE_PARTNER_API_SERVICE).setPathParams({ merchantId }).build();
    return instance.patch(url, data);
};

/**
 * Disable the API service for the given merchant
 * @param {string} merchantId - ID of the merchant
 * @param {object} data - modal form data - should contain a 'password' field
 * @returns {object} response from the partner api
 */
export const disableApiService = (merchantId, data) => {
    const url = UrlBuilder().setUrl(DISABLE_PARTNER_API_SERVICE).setPathParams({ merchantId }).build();
    return instance.patch(url, data);
};

/**
 * Enable the API service for the given merchant, without supplying the developer info, etc
 * @param {string} merchantId - ID of the merchant
 * @param {object} data - modal form data - should contain a 'password' field
 * @returns {object} response from the partner API
 */
export const enableApiServiceByMerchantUser = (merchantId, data) => {
    const url = UrlBuilder().setUrl(ENABLE_PARTNER_API_SERVICE_BY_MERCHANT_USER).setPathParams({ merchantId }).build();
    return instance.patch(url, data);
};

// !TODO add docs
export const disableApiServiceByMerchantUser = (merchantId, data) => {
    const url = UrlBuilder().setUrl(DISABLE_PARTNER_API_SERVICE_BY_MERCHANT_USER).setPathParams({ merchantId }).build();
    return instance.patch(url, data);
};

/**
 * Get statistical data
 * @param {string} id --> merchantId - ID of the merchant
 * ---------OR---------
 * @param {string} id --> clientId - ID of the client
 * @param {string} userType --> type of the user (Merchant or Client)
 * @returns {object} response from the partner API (With stats data about api requests for particular merchant or client)
 */
export const getPartnerApiStatsData = (id, userType = 'client', fromDate, toDate) => {
    let url;

    if (userType.toLowerCase() === 'merchant') {
        url = UrlBuilder().setUrl(GET_DASHBOARD_STATS_BY_MERCHANT_ID).setQueryParams({ merchantId: id }).build();
    }
    if (userType.toLowerCase() === 'client') {
        url = UrlBuilder().setUrl(GET_DASHBOARD_STATS_BY_CLIENT_ID).setQueryParams({ clientId: id }).build();
    }
    if (fromDate && toDate) {
        url = url.setQueryParams({ merchantId: id, fromDate, toDate }).build();
    }
    return instance.get(url);
};

/**
 * Get latest request time
 * @param {string} id --> merchantId - ID of the merchant
 * ---------OR---------
 * @param {string} id --> clientId - ID of the client
 * @param {string} userType --> type of the user (Merchant or Client)
 * @returns {object} response from the partner API (With stats data about api requests for particular merchant or client)
 */
export const getLatestRequest = (id, userType, fromDate, toDate) => {
    let url;

    if (userType === 'Merchant') {
        url = UrlBuilder().setUrl().setQueryParams({ merchantId: id, fromDate, toDate }).build();
    } else if (userType === 'Client') {
        url = UrlBuilder()
            .setUrl(GET_DASHBOARD_LATEST_REQUEST_BY_CLIENT_ID)
            .setQueryParams({ clientId: id, fromDate, toDate })
            .build();
    }

    return instance.get(url);
};

/**
 * Get overview graph data for errors and success requests
 * @param {string} id --> merchantId - ID of the merchant
 * ---------OR---------
 * @param {string} id --> clientId - ID of the client
 * @param {string} userType --> type of the user (Merchant or Client)
 * @returns {object} response from the partner API (With graph's data about api requests for particular merchant or client)
 */
export const getPartnerApiOverviewGraphData = (id, userType, fromDate, toDate) => {
    let url;

    if (userType === 'Merchant') {
        url = UrlBuilder().setUrl().setPathParams({ merchantId: id }).build();
    } else if (userType === 'Client') {
        url = UrlBuilder()
            .setUrl(GET_DASHBOARD_OVERVIEW_GRAPH_DATA_BY_CLIENT_ID)
            .setQueryParams({ clientId: id, fromDate, toDate })
            .build();
    }

    return instance.get(url);
};

/**
 * Get graph data
 * @param {string} id --> merchantId - ID of the merchant
 * ---------OR---------
 * @param {string} id --> clientId - ID of the client
 * @param {string} userType --> type of the user (Merchant or Client)
 * @returns {object} response from the partner API (With graphs' data about api requests for particular merchant or client)
 */
export const getPartnerApiGraphData = (id, userType) => {
    let url;

    if (userType === 'Merchant') {
        url = UrlBuilder().setUrl(GET_DASHBOARD_GRAPH_DATA_BY_MERCHANT_ID).setPathParams({ merchantId: id }).build();
    } else if (userType === 'Client') {
        url = UrlBuilder().setUrl().setPathParams({ clientId: id }).build();
    }

    return instance.get(url);
};

/**
 * Get table data
 * @param {string} id --> merchantId - ID of the merchant
 * ---------OR---------
 * @param {string} id --> clientId - ID of the client
 * @param {string} userType --> type of the user (Merchant or Client)
 * @returns {object} response from the partner API (With table's data about api requests for particular merchant or client)
 */
export const getPartnerApiTableData = (
    fromDate,
    toDate,
    id,
    userType,
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    search
) => {
    let queryParams;

    if (userType === 'Merchant') {
        queryParams = {
            fromDate,
            toDate,
            merchantId: id,
            page,
            limit,
            sort: sort ? Object.keys(sort)[0] : 'createdAt',
            order,
        };
    } else if (userType === 'Client') {
        queryParams = {
            fromDate,
            toDate,
            clientId: id,
            page,
            limit,
            sort: sort ? Object.keys(sort)[0] : 'createdAt',
            order,
        };
    }
    if (search?.searchBy) queryParams.searchBy = search.searchBy;
    if (search?.searchText) queryParams.searchText = search.searchText;

    if (search?.searchBy && search?.searchBy === 'serviceStatusClient') {
        if (search?.searchText) {
            if (search.searchText.toLowerCase() === 'active') {
                queryParams.serviceStatusClient = 1;
            } else if (search.searchText.toLowerCase() === 'inactive') {
                queryParams.serviceStatusClient = 0;
            }
        }
    }
    console.log('queryParams:::::::::::', queryParams);
    const url = UrlBuilder().setUrl(GET_DASHBOARD_TABLE_DATA_BY_ID).setQueryParams(queryParams).build();
    return instance.get(url);
};

/**
 * Get the list of API keys
 * @param {number} page - page number
 * @param {number} limit - page size
 * @param {string} sort - key to sort by
 * @param {number} order - order of sorting
 * @param {object} search - search props object
 * @param {string} status - whether 'active' or 'inactive' keys to fetch
 * @param {string} merchantId - ID of the merchant whose keys to fetch
 * @returns {object} response from the partner API
 */
export const getApiKeyData = (page = 1, limit = 10, sort = 'createdAt', order = -1, search, status, merchantId) => {
    const queryParams = {
        page,
        limit,
        sort: sort ? Object.keys(sort)[0] : 'createdAt',
        order,
    };

    const pathParams = { merchantId };

    if (search?.searchBy) queryParams.searchBy = search.searchBy;
    if (search?.searchText) queryParams.searchText = search.searchText;

    if (status === 'active') {
        queryParams.isActive = 1;
    }
    if (status === 'inactive') {
        queryParams.isActive = 0;
    }

    const url = UrlBuilder().setUrl(GET_API_KEY_DATA).setPathParams(pathParams).setQueryParams(queryParams).build();
    return instance.get(url);
};

/**
 * Generate a new API key
 * @param {object} params - must contain:
 *  merchantId,
 *  clientId (optional),
 *  name,
 *  allowedEndpoints (array),
 *  type ('public' or 'private'),
 *  password
 * @returns {object} response from the partner API
 */
export const generateApiKey = ({ merchantId, clientId, name, allowedEndpoints, type, password }) => {
    const data = {
        merchantId,
        clientId,
        name,
        allowedEndpoints,
        type,
        password,
    };
    const url = UrlBuilder().setUrl(GENERATE_API_KEY).build();
    return instance.post(url, data);
};

/**
 * Get an API key by ID
 * @param {string} apiKeyId - ID of the API key
 * @returns {object} response from the API
 */
export const getApiKeyById = (apiKeyId) => {
    const pathParams = { id: apiKeyId };
    const url = UrlBuilder().setUrl(GET_API_KEY_BY_ID).setPathParams(pathParams).build();
    return instance.get(url);
};

/**
 * Update an API key by ID
 * @param {string} apiKeyId - ID of the API key
 * @param {object} data - Updated API key data
 * @returns {object} response from the API
 */
export const updateApiKeyById = (apiKeyId, data) => {
    const pathParams = { id: apiKeyId };
    const url = UrlBuilder().setUrl(UPDATE_API_KEY_BY_ID).setPathParams(pathParams).build();
    return instance.post(url, data);
};

/**
 * Enable an API key by ID
 * @param {string} apiKeyId - ID of the API key
 * @param {object} data - should contain a 'password' key
 * @returns {object} response from the API
 */
export const enableApiKeyById = (apiKeyId, data) => {
    const pathParams = { id: apiKeyId };
    const url = UrlBuilder().setUrl(ENABLE_API_KEY_BY_ID).setPathParams(pathParams).build();
    return instance.post(url, data);
};

/**
 * Disable an API key by ID
 * @param {string} apiKeyId - ID of the API key
 * @param {object} data - should contain a 'password' key
 * @returns {object} response from the API
 */
export const disableApiKeyById = (apiKeyId, data) => {
    const pathParams = { id: apiKeyId };
    const url = UrlBuilder().setUrl(DISABLE_API_KEY_BY_ID).setPathParams(pathParams).build();
    return instance.post(url, data);
};

/**
 * Retrieve the secret key of an API key
 * @param {string} apiKeyId - ID of the API key
 * @param {string} data - should contain a 'password' key
 * @returns {object} response from the API
 */
export const showSecretKey = (apiKeyId, data) => {
    const pathParams = { id: apiKeyId };
    const url = UrlBuilder().setUrl(SHOW_SECRET_KEY).setPathParams(pathParams).build();
    return instance.post(url, data);
};

/**
 * Get Partner Api Server Status
 * @returns {object} response from the API
 */
export const getApiServiceStatus = () => {
    const url = UrlBuilder().setUrl(GET_SERVICE_STATUS).build();
    return instance.get(url);
};

/**
 * Get list of webhooks for merchant
 * @param {string} merchantId - ID of the merchant
 * @param {string[]} status - array of statuses to fetch ('active', 'inactive')
 * @param {string} search - search field content
 * @returns {object} response from the API
 */
export const getWebhooksList = (merchantId, status, search) => {
    const pathParams = { merchantId };
    const queryParams = {};

    if (search) {
        queryParams.searchBy = 'name';
        queryParams.searchText = search;
    }

    // If status includes both of these values, we can ignore it and just call the URL.
    if (status.length === 1 && status[0] === 'active') {
        queryParams.isActive = 1;
    }
    if (status.length === 1 && status[0] === 'inactive') {
        queryParams.isActive = 0;
    }
    const url = UrlBuilder().setUrl(GET_WEBHOOKS_LIST).setPathParams(pathParams).setQueryParams(queryParams).build();
    return instance.get(url);
};

/** Create a new webhook by calilng the endpoint */
export const createWebhook = (data) => {
    const url = UrlBuilder().setUrl(CREATE_WEBHOOK).build();
    return instance.post(url, data);
};

/** Fetch a single webhook data by ID */
export const getWebhookDataById = (id) => {
    const pathParams = { id };
    const url = UrlBuilder().setUrl(GET_WEBHOOK_BY_ID).setPathParams(pathParams).build();
    return instance.get(url);
};

/** Update webhook data by ID */
export const updateWebhook = (id, data) => {
    const pathParams = { id };
    const url = UrlBuilder().setUrl(UPDATE_WEBHOOK).setPathParams(pathParams).build();
    return instance.post(url, data);
};

/**
 * Enable webhook by ID
 * @param {string} id - Webhook ID
 * @param {object} data - should contain a 'password' field
 * @returns {object} response from the API
 */
export const enableWebhook = (id, data) => {
    const pathParams = { id };
    const url = UrlBuilder().setUrl(ENABLE_WEBHOOK).setPathParams(pathParams).build();
    return instance.post(url, data);
};

/**
 * Disable webhook by ID
 * @param {string} id - Webhook ID
 * @param {object} data - should contain a 'password' field
 * @returns {object} response from the API
 */
export const disableWebhook = (id, data) => {
    const pathParams = { id };
    const url = UrlBuilder().setUrl(DISABLE_WEBHOOK).setPathParams(pathParams).build();
    return instance.post(url, data);
};

/**
 * Retrieve auth value from the API
 * @param {string} id - Webhook ID
 * @param {object} data - should contain a 'password' field
 * @returns {object} response from the API
 */
export const viewAuthValue = (id, data) => {
    const pathParams = { id };
    const url = UrlBuilder().setUrl(WEBHOOK_VIEW_AUTH_VALUE).setPathParams(pathParams).build();
    return instance.post(url, data);
};

/**
 * Get list of webhooks for merchant
 * @param {string} merchantId - ID of the merchant
 * @param {string[]} status - array of statuses to fetch ('active', 'inactive')
 * @param {string} search - search field content
 * @returns {object} response from the API
 */
export const getCreatedAppList = (id, status, search) => {
    const pathParams = { id };
    const queryParams = {};

    if (search) {
        queryParams.searchBy = 'storeName';
        queryParams.searchText = search;
    }
    // If status includes both of these values, we can ignore it and just call the URL.
    if (status.length === 1 && status[0] === 'active') {
        queryParams.isActive = 1;
    }
    if (status.length === 1 && status[0] === 'inactive') {
        queryParams.isActive = 0;
    }
    const url = UrlBuilder()
        .setUrl(GET_PARTNER_API_CONNECTED_APPS_LIST)
        .setPathParams(pathParams)
        .setQueryParams(queryParams)
        .build();
    return instance.get(url);
};

/**
 * Disconnect the Shopify/WooCommerce Application
 * @param {string} connectedAppId - ID of the connected App
 * @param {string} password - password
 * @returns {object} response from the API
 */
export const disconnectAppService = (id, password) => {
    const pathParams = { id };
    const url = UrlBuilder().setUrl(DISCONNECT_PARTNER_API_CONNECTED_APP).setPathParams(pathParams).build();
    return instance.post(url, { password });
};
