import { PROFILE_CHANGE_PASSWORD, UPDATE_PROFILE, PROFILE_CHANGE_PASSWORD_NEW } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const profileChangePasswordService = (data) => {
    try {
        const url = UrlBuilder().setUrl(PROFILE_CHANGE_PASSWORD).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const updateProfileService = (id, data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_PROFILE).setPathParams({ id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const profileChangeNewPasswordService = (data) => {
    try {
        const url = UrlBuilder().setUrl(PROFILE_CHANGE_PASSWORD_NEW).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
