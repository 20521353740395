/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

// #region imports
import { SOCKET_EVENTS } from 'constants/socket';

// #region utils
import { getUser } from 'utils/checkAuth';
import { getSessionStorageItem, removeSessionStorageItem, storeSessionStorageItem } from 'utils/session-storage';

/**
 * Delivery Watcher Hook -
 * When User is watching a delivery document and delivery is updated from elsewhere
 * user will be notified
 * @param {*} deliveryId
 * @returns
 */
export default function useDeliveryWatcher(deliveryId) {
    const _isMounted = useRef(true);

    const globalState = useSelector((state) => state.Global);

    const [socketClient, setSocketClient] = useState(null);

    const [isDataConflict, setIsDataConflict] = useState(false);

    useEffect(() => {
        if (globalState && _isMounted?.current) {
            const { socket } = globalState;
            if (socket) {
                setSocketClient(socket);
            }
        }
    }, [globalState?.socket]);

    useEffect(() => {
        if (deliveryId && socketClient && _isMounted?.current) {
            const { _id } = getUser();
            storeSessionStorageItem('delivery-document-id', deliveryId);
            socketClient.emit(SOCKET_EVENTS.DOCUMENT_WATCHER_DELIVERY, {
                doc: {
                    documentId: deliveryId,
                    userId: _id,
                    socketId: socketClient.id,
                },
                status: 'mounted',
            });
        }
    }, [deliveryId, socketClient]);

    useEffect(() => {
        if (deliveryId && socketClient && _isMounted?.current) {
            socketClient.on(SOCKET_EVENTS.DOCUMENT_WATCHER_DELIVERY, (res) => {
                const { status } = res;
                if (status && status === 'dataConflict') {
                    setIsDataConflict(true);
                }
            });
        }
    }, [deliveryId, socketClient]);

    useEffect(() => () => unMountDocumentWatcher(), []);

    function unMountDocumentWatcher() {
        const documentId = getSessionStorageItem('delivery-document-id');
        const { socket } = globalState;
        if (socket && documentId) {
            const { _id } = getUser();
            socket.emit(SOCKET_EVENTS.DOCUMENT_WATCHER_DELIVERY, {
                doc: {
                    documentId,
                    userId: _id,
                    socketId: socket.id,
                },
                status: 'unmounted',
            });
        }
        removeSessionStorageItem('delivery-document-id');
    }

    return { isDataConflict };
}
