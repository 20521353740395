/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, FormGroup, Label, Input, FormText, Spinner, Alert } from 'reactstrap';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { AvForm } from 'availity-reactstrap-validation';
import moment from 'moment';

// #region components
import { ClientAlerts, MerchantAlerts, TableCustom } from 'components';
import ImportExampleModal from 'components/Modals/Import-Example';
import CustomToast from 'components/Toast/Custom';

// #region imports
import { TOAST_STYLES } from 'theme';
import paths from 'routes/paths';
import BillingTemplate from 'assets/files/BillingTemplate.xlsx';

// #region services
import { uploadBillingImportXlCsvAttachment } from 'services/attachment.service';
import { getImportHistoryService } from 'services/import-history.service';
import { getExcelCsvConfigByMerchantIdService } from 'services/excel-csv-config.service';

// #region utils
import { fileDownload, fileListToArray } from 'utils/helpers';
import { getVisitingObject, hasAccess } from 'utils/checkAuth';
import { deliveryExcelCsvImportConfig } from 'utils/db/excel-csv/delivery-excel-csv';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import { validateForm } from './utils';
import { defaultTableHeaders } from './constants';

let initialFormValues = {};

function BillingImportPage() {
    const history = useHistory();

    const { visitingMerchant } = getVisitingObject();

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    const formRef = useRef(null);

    // form
    const [formData, setFormData] = useState(initialFormValues);
    const [validated, setValidated] = useState(false);

    // loading
    const [loading, setLoading] = useState(0);
    const [formLoading, setFormLoading] = useState(false);

    const [importHistoryData, setImportHistoryData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });
    const [searchBy] = useState({ searchBy: '', searchText: null });

    const [showDeliveryExampleModal, setShowDeliveryExampleModal] = useState(false);
    const [excelDeliveryConfig, setExcelDeliveryConfig] = useState();

    const [showExcelConfigIcon, setShowExcelConfigIcon] = useState(false);

    const checkIfLastUploadedFileHadIssues = (record) => {
        if (record && record.errorRecordsLength > 0) {
            const currentTime = moment();
            const { createdAt } = record;
            const createdAtWithConditionTime = moment(createdAt).add('5', 'minutes');
            if (currentTime.isBefore(createdAtWithConditionTime)) {
                toast.custom((t) => <CustomToast text="Looks like last uploaded excel had issues" t={t} />, {
                    position: 'top-right',
                });
            }
        }
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (showExcelConfigIcon) {
            const timer1 = setTimeout(() => setShowExcelConfigIcon(false), 3 * 1000);
            return () => {
                clearTimeout(timer1);
            };
        }
    }, [showExcelConfigIcon]);

    useEffect(() => {
        loadImportHistoryData(
            true,
            importHistoryData.all,
            1,
            importHistoryData.limit,
            importHistoryData.filter,
            importHistoryData.sort,
            importHistoryData.order,
            searchBy
        );
    }, []);

    useEffect(() => {
        if (formData.merchantId) {
            loadExcelCsvConfig(formData.merchantId);
        }
    }, [formData.merchantId]);

    const loadExcelCsvConfig = async (id) => {
        setLoading((prevState) => prevState + 1);
        try {
            const { data } = await getExcelCsvConfigByMerchantIdService(id, 'DELIVERY_IMPORT');
            if (data) {
                setExcelDeliveryConfig({ ...excelDeliveryConfig, ...data });
                setTimeout(() => {
                    setShowExcelConfigIcon(true);
                }, 1000);
            } else {
                setExcelDeliveryConfig({ ...excelDeliveryConfig, ...deliveryExcelCsvImportConfig });
            }
        } catch (e) {
            console.log(e);
        }
        setLoading((prevState) => prevState - 1);
    };

    const handlePageChange = (pageNo) => {
        loadImportHistoryData(
            true,
            importHistoryData.all,
            pageNo,
            importHistoryData.limit,
            importHistoryData.filter,
            importHistoryData.sort,
            importHistoryData.order,
            searchBy
        );
    };

    const handleFilter = (field, filterText) => {
        loadImportHistoryData(
            false,
            importHistoryData.all,
            1,
            importHistoryData.limit,
            importHistoryData.filter,
            importHistoryData.sort,
            importHistoryData.order,
            { searchBy: field || 'reference', searchText: filterText }
        );
    };

    const loadImportHistoryData = (showLoading = true, all, page, limit, filter, sort, order, searchByProp) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getImportHistoryService(all, page, limit, filter, sort, order, searchByProp, null, 'BILLING_IMPORT_EXCEL_CSV')
            .then((res) => {
                const { data } = res;
                setImportHistoryData({
                    ...importHistoryData,
                    ...data,
                });
                if (data.docs && data.docs.length > 0) {
                    checkIfLastUploadedFileHadIssues(data.docs[0]);
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const handleSubmit = async (event, errors) => {
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const isFormValid = validateForm(formData);

        if (!isFormValid) {
            toast.error('Choose delivery reference type', TOAST_STYLES.ERROR);
            return;
        }

        const { merchantId, fileType, merchantName, isDeliveryId, isMerchantOrderNo } = formData;
        const meta = {
            merchantId,
            fileType,
            merchantName,
            isDeliveryId,
            isMerchantOrderNo,
        };
        // Meta
        formData.meta = meta;

        // If merchant is not selected showing error
        if (!merchantId) {
            toast.error('Merchant not selected', TOAST_STYLES.ERROR);
            return;
        }

        // Attachment Meta
        const { name, lastModifiedDate, size } = formData.attachment;
        formData.meta.attachmentMeta = { name, lastModifiedDate, size };

        const attachmentData = new FormData();
        attachmentData.append('file', formData.attachment);
        formData.uploadData = attachmentData;
        setFormLoading(true);
        try {
            await uploadBillingImportXlCsvAttachment({ ...formData });
            toast.success('Successfully Uploaded', TOAST_STYLES.SUCCESS);
        } catch (e) {
            console.log(e);
            toast.error('Something went wrong', TOAST_STYLES.ERROR);
        } finally {
            setFormLoading(false);
        }
        setFormLoading(false);
        resetForm();
        setValidated(false);
        loadImportHistoryData();
    };

    // Reset Form
    const resetForm = () => {
        document.getElementById('deliveryImportForm').reset();
        setFormData(initialFormValues);
    };

    const handleChange = (event) => {
        event.preventDefault();

        if (event.target.id === 'attachment') {
            const files = fileListToArray(event.target.files);
            const uploadedFile = files[0];
            let fileType = null;
            let fileTypeName = null;

            if (uploadedFile.name.includes('xlsx')) {
                fileType = 'xlsx';
                fileTypeName = 'Excel';
            }
            if (uploadedFile.name.includes('csv')) {
                fileType = 'csv';
                fileTypeName = 'Csv';
            }
            if (!fileType) {
                toast.error('Invalid File Format', TOAST_STYLES.ERROR);
                setFormData({
                    ...formData,
                    fileType: null,
                    fileTypeName: '',
                    attachment: null,
                });
                return;
            }
            setFormData({
                ...formData,
                fileType,
                fileTypeName,
                attachment: files[0],
            });
        } else {
            setFormData({
                ...formData,
                [event.target.id]: event.target.value,
            });
        }
    };

    const handleCheckChange = (event) => {
        const { id, checked } = event.target;
        setFormData({
            ...formData,
            [id]: checked,
        });
    };

    const toggleShowDeliveryExampleModal = () => {
        setShowDeliveryExampleModal(!showDeliveryExampleModal);
    };

    return (
        <>
            <div className="page-content">
                <AvForm
                    noValidate
                    id="deliveryImportForm"
                    className="needs-validation"
                    validated={validated}
                    onSubmit={handleSubmit}
                    ref={formRef}
                >
                    <Col>
                        <ClientAlerts />
                        <MerchantAlerts />
                    </Col>
                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div></div>
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius m-2 mb-3 pr-3 pl-3 shadow-lg nw-md mr-4 pr-4 pl-4"
                            onClick={() =>
                                history.push({
                                    pathname: `${paths.PAYMENTS.BILLING_LIST}`,
                                })
                            }
                        >
                            <i className="fas fa-chevron-left" /> <span className="h6">Back</span>
                        </motion.div>
                    </Row>
                    <Card style={{ borderRadius: 15, margin: 10, marginTop: -10 }}>
                        <CardBody style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                            <div
                                style={{
                                    backgroundColor: '#F9F7F7',
                                    borderTopRightRadius: 15,
                                    borderTopLeftRadius: 15,
                                    minHeight: 20,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div
                                    style={{
                                        padding: 23,
                                        color: '#807F7D',
                                        fontSize: 19,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Upload Billing Payment Details
                                </div>
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    type="button"
                                    onClick={() => fileDownload(BillingTemplate, 'Billing Template.xlsx')}
                                    className="scootix-form-btn shadow-lg mr-2"
                                    disabled={formLoading}
                                >
                                    {formLoading ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>
                                            <i className="fas fa-download left-icon"></i>
                                            Download Template
                                        </>
                                    )}
                                </motion.button>
                            </div>

                            <Row style={{ marginLeft: 20 }} className="mt-3">
                                <Label htmlFor="">&nbsp;</Label>
                                <div className="form-check mb-3">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        onChange={handleCheckChange}
                                        checked={formData.isDeliveryId}
                                        defaultChecked={formData.isDeliveryId}
                                        id="isDeliveryId"
                                    />
                                    <Label className="form-check-label" htmlFor="defaultCheck1">
                                        Delivery ID
                                    </Label>
                                </div>
                                <Label htmlFor="">&nbsp;</Label>
                                <div className="form-check mb-3 ml-3">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        onChange={handleCheckChange}
                                        checked={formData.isMerchantOrderNo}
                                        defaultChecked={formData.isMerchantOrderNo}
                                        id="isMerchantOrderNo"
                                    />
                                    <Label className="form-check-label" htmlFor="defaultCheck1">
                                        Merchant Order Number
                                    </Label>
                                </div>
                            </Row>

                            <Row style={{ padding: 10, marginLeft: 5 }}>
                                <Col md="3">
                                    <FormGroup>
                                        <Label for="attachment">File</Label>
                                        <Input type="file" name="attachment" id="attachment" onChange={handleChange} />
                                        <FormText color="muted">Excel or CSV</FormText>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ padding: 10, marginLeft: 5 }}>
                                {hasAccess(PERMISSIONS.DELIVERY_EXCEL_UPLOAD, ['Upload']) && (
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn shadow-lg ml-2"
                                        type="submit"
                                        disabled={formLoading}
                                    >
                                        {formLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <i className="fas fa-upload left-icon"></i>
                                                <span className="h6">Upload</span>
                                            </>
                                        )}
                                    </motion.button>
                                )}
                            </Row>
                            <Row style={{ justifyContent: 'flex-start' }}>
                                <Col className="mx-4 mt-4">
                                    <Alert className="mb-0" color="warning">
                                        Please include the route planning entry number instead of the delivery ID for
                                        Multi-Stop (route planning) pricing based billings.
                                    </Alert>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <br />
                    <Row>
                        <TableCustom
                            removeAction
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            tableData={importHistoryData}
                            loading={loading}
                            isFullScreenShow
                            filters={[
                                {
                                    label: 'File Import Id',
                                    key: 'fileImportId',
                                },
                                {
                                    label: 'Merchant',
                                    key: 'merchantName',
                                },
                            ]}
                        />
                    </Row>
                </AvForm>
            </div>

            {showDeliveryExampleModal && deliveryExcelCsvImportConfig && (
                <ImportExampleModal
                    toggleModal={toggleShowDeliveryExampleModal}
                    header="Delivery Import Example"
                    importConfiguration={excelDeliveryConfig}
                    text="Below format will be used when processing file"
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

BillingImportPage.propTypes = {};

const HotBillingImportPage = hot(BillingImportPage);

export default connect(mapStateToProps, {})(HotBillingImportPage);
