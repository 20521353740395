/* eslint-disable indent */
/* eslint-disable camelcase */
import {
    GET_ORDER_ITEMS,
    GET_ORDER_ITEMS_BY_ID,
    GET_ALL_ORDER_ITEMS,
    GET_ORDER_ITEMS_DELETED,
    GET_ORDER_ITEMS_BY_ORDER_ID,
    UPDATE_ORDER_ITEMS,
    COMPLETE_ORDER_ITEM_PICKING,
    UNDO_COMPLETE_ORDER_ITEM_PICKING,
    COMPLETE_ORDER_ITEM_PACKING,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get OrderItems Service
 * @returns {object}
 */
export const getOrderItemsService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_ORDER_ITEMS}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get OrderItems By OrderItems Receiving Service
 * @returns {object}
 */
export const getOrderItemsByOrderIdService = function api(
    stockReceivingId,
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_ORDER_ITEMS_BY_ORDER_ID}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).setPathParams({ id: stockReceivingId }).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get OrderItems Deleted Service
 * @returns {object}
 */
export const getOrderItemsDeletedService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_ORDER_ITEMS_DELETED}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get OrderItems By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getOrderItemsByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ORDER_ITEMS_BY_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All OrderItems
 * @returns {Promise}
 */
export const getAllOrderItemsService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_ORDER_ITEMS).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Order Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateOrderItemService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_ORDER_ITEMS).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Complete Order Item Picking Service
 * @param {object} data
 * @returns {Promise}
 */
export const completeOrderItemPickingService = (data) => {
    try {
        const url = UrlBuilder().setUrl(COMPLETE_ORDER_ITEM_PICKING).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Undo Complete Order Item Picking Service
 * @param {object} data
 * @returns {Promise}
 */
export const undoCompleteOrderItemPickingService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UNDO_COMPLETE_ORDER_ITEM_PICKING).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Complete Order Item Picking Service
 * @param {object} data
 * @returns {Promise}
 */
export const completeOrderItemPackingService = (data) => {
    try {
        const url = UrlBuilder().setUrl(COMPLETE_ORDER_ITEM_PACKING).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
