import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spinner, Label, FormGroup, Input } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { motion } from 'framer-motion';
import InputMask from 'react-input-mask';

// #region components | assets
import { Select } from 'components';
import CheckCircleIcon from 'components/Icons/Check-Circle';

// #region other imports

// #region utils
import { reactSelectCustomStyles } from 'utils/helpers';

function SandboxAccountCreationFormContainer(props) {
    const {
        handleSubmit,
        formLoading,
        editingMode,
        history,
        selectedMerchantId,
        merchants,
        handleSelectChange,
        submitted,
        validated,
        formData,
        handleCheckChange,
        handleInputChange,
    } = props;

    return (
        <AvForm className="needs-validation default-form" onValidSubmit={handleSubmit}>
            <fieldset>
                <Row className="justify-content-between pr-4">
                    <div />
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="scootix-form-btn shadow-lg align-content-center"
                        onClick={() => history.goBack()}
                    >
                        <i className="far fa-times-circle mr-2 fa-lg" />
                        Cancel
                    </motion.div>
                </Row>
                <Row className="px-3">
                    <Col md={editingMode ? 6 : 4}>
                        <Label htmlFor="merchantId">Merchant</Label>
                        <Select
                            value={selectedMerchantId}
                            options={merchants}
                            styles={reactSelectCustomStyles}
                            onChange={(event) => handleSelectChange(event, 'merchantId')}
                            submitted={submitted}
                            required
                            validated={validated}
                            id="merchantId"
                            isClearable
                        />
                    </Col>
                </Row>

                <Row className="mt-4 px-3">
                    <Col md={6} xl={6}>
                        <Row className="align-items-center">
                            <Col xs="auto" className="mt-0">
                                <Label htmlFor="isDeveloperNameSameAsMerchant">Developer Name</Label>
                            </Col>
                            <Col xs="auto" className="mb-4">
                                <Input
                                    id="isDeveloperNameSameAsMerchant"
                                    type="checkbox"
                                    className="ml-0 mt-0"
                                    checked={formData.isDeveloperNameSameAsMerchant || false}
                                    onChange={handleCheckChange}
                                />
                            </Col>
                            <Col>
                                <Label htmlFor="usePrimaryContact" className="font-small">
                                    Use Merchant&apos;s Primary Contact as Developer Contact
                                </Label>
                            </Col>
                        </Row>
                        <Col md={12} className="ml-n3">
                            <FormGroup>
                                <AvField
                                    name="developerName"
                                    type="text"
                                    className="form-control"
                                    value={formData.developerName || ''}
                                    onChange={handleInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Required' },
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Col>

                    <Col md={5} xl={5} className="">
                        <FormGroup>
                            <Label htmlFor="developerEmail">Developer Email Address</Label>
                            <AvField
                                name="developerEmail"
                                type="text"
                                className="form-control"
                                value={formData.developerEmail || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Required' },
                                }}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={5} xl={5} className="">
                        <FormGroup>
                            <Label htmlFor="developerContactNumber">Developer Contact Number</Label>
                            <AvField
                                name="developerContactNumber"
                                type="text"
                                className="form-control"
                                value={formData.developerContactNumber || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Required' },
                                }}
                                mask="+60 99-999 99999"
                                maskChar=""
                                tag={[Input, InputMask]}
                            />
                        </FormGroup>
                    </Col>

                    <Col lg={5} xl={7} className="">
                        <Row className="align-items-center">
                            <Col xs="auto" className="mt-0">
                                <Label htmlFor="isWhatsAppSameAsDeveloperContact">
                                    Developer WhatsApp Contact Number
                                </Label>
                            </Col>
                            <Col xs="auto" className="mb-4">
                                <Input
                                    id="isWhatsAppSameAsDeveloperContact"
                                    type="checkbox"
                                    className="ml-0 mt-0"
                                    checked={formData.isWhatsAppSameAsDeveloperContact || false}
                                    onChange={handleCheckChange}
                                />
                            </Col>
                            <Col>
                                <Label htmlFor="isWhatsAppSameAsDeveloperContact" className="font-small">
                                    Same as the Developer Contact Number
                                </Label>
                            </Col>
                        </Row>
                        <Col md={12} className="ml-n3">
                            <FormGroup>
                                <AvField
                                    name="developerWhatsappNumber"
                                    type=""
                                    className="form-control"
                                    value={formData.developerWhatsappNumber || ''}
                                    onChange={handleInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Required' },
                                    }}
                                    mask="+60 99-999 99999"
                                    maskChar=""
                                    tag={[Input, InputMask]}
                                />
                            </FormGroup>
                        </Col>
                    </Col>
                </Row>
                <Row className="px-2">
                    <Col md={7} lg={7} xl={6}>
                        <FormGroup>
                            <Label htmlFor="customMessage">Custom Message</Label>
                            <AvField
                                name="customMessage"
                                type="textarea"
                                helpMessage="Maximum 2000 Words"
                                className="form-control"
                                value={formData.customMessage || ''}
                                onChange={handleInputChange}
                                rows={5}
                                maxLength={2000}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <br />
                <Row style={{ justifyContent: 'flex-start' }} className="px-3">
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.99 }}
                        className="scootix-form-btn shadow-lg align-content-center"
                        style={{
                            borderRadius: 10,
                            display: formData.sandboxAccountMerchantId ? 'none' : 'inline-block',
                        }}
                        type="submit"
                        disabled={formLoading.createSandboxAccount}
                    >
                        {formLoading.createSandboxAccount ? (
                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                        ) : (
                            <>
                                <span className="h6">
                                    <CheckCircleIcon color="#FFF" />
                                    &nbsp;Create a Sand Box Account
                                </span>
                            </>
                        )}
                    </motion.button>
                </Row>
            </fieldset>
        </AvForm>
    );
}

SandboxAccountCreationFormContainer.propTypes = {
    handleSubmit: PropTypes.func,
    formLoading: PropTypes.bool,
    editingMode: PropTypes.bool,
    history: PropTypes.any,
    selectedMerchantId: PropTypes.object,
    merchants: PropTypes.array,
    handleSelectChange: PropTypes.func,
    submitted: PropTypes.bool,
    validated: PropTypes.bool,
    formData: PropTypes.object,
    handleCheckChange: PropTypes.func,
    handleInputChange: PropTypes.func,
};

export default SandboxAccountCreationFormContainer;
