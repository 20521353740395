import { CREATE_ROLE, GET_ALL_PERMISSIONS, GET_ALL_ROLES_BY_USER_TYPE, UPDATE_ROLE } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const getPermissions = (data) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_PERMISSIONS).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getAllRolesByUserType = (type) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_ROLES_BY_USER_TYPE).setPathParams({ type }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const createRole = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_ROLE).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const updateRole = (data, id) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_ROLE).setPathParams({ id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
