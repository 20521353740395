export const webhooksTableHeaders = [
    {
        key: 'name',
        value: 'NAME',
    },
    {
        key: 'isActiveString',
        value: 'STATUS',
        type: 'statusLabel',
        params: { shape: 'rectangle', activeLabel: 'ACTIVE', inactiveLabel: 'INACTIVE' },
    },
    {
        key: 'updateType',
        value: 'UPDATE TYPE',
    },
    {
        key: 'createdAt',
        value: 'GENERATED AT',
    },
    {
        key: 'createdBy',
        value: 'GENERATED BY',
    },
    {
        key: 'lastWebhookAttempt',
        value: 'LAST WEBHOOK ATTEMPT',
    },
    {
        key: 'action',
        value: 'ACTION',
        type: 'customActionButton',
        params: {
            buttonLabel: 'Manage',
            customActionHandlerName: 'handleManageButton',
        },
    },
];

export const webhookEventTableHeaders = [
    {
        key: 'sequenceId',
        value: 'EVENT ID',
    },
    {
        key: 'eventType',
        value: 'EVENT TYPE',
    },
    {
        key: 'merchantName',
        value: 'MERCHANT',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
        style: ['increase-width'],
        customHeaderStyles: {
            minWidth: 250,
        },
    },
    {
        key: 'webhookName',
        value: 'WEBHOOK NAME',
    },
    {
        key: 'responseStatus',
        value: 'RESPONSE',
        type: 'apiStatusLabel',
    },
    {
        key: 'description',
        value: 'EVENT DESCRIPTION',
    },
    {
        key: 'deliverySequenceId',
        value: 'DELIVERY ID',
    },
    {
        key: 'quotationSequenceId',
        value: 'QUOTATION ID',
    },
    {
        key: 'merchantOrderNo',
        value: 'MERCHANT ORDER ID',
    },
    {
        key: 'action',
        value: 'OUTPUT DATA',
        type: 'customActionButton',
        params: {
            buttonLabel: 'View',
            customActionHandlerName: 'displayModal',
        },
    },
];

export const tableDefaultFilters = [
    {
        label: 'Status',
        key: 'referenceNumber',
    },
    {
        label: 'Status Code',
        key: 'deliveryType',
    },
    {
        label: 'Request Type',
        key: 'referenceNumber',
    },
    {
        label: 'From Date and Time',
        key: 'orderReferenceNumber',
    },
    {
        label: 'To Date',
        key: 'merchantName',
    },
];

export const exportHeadersWebhookEventTable = [
    {
        key: 'sequenceId',
        value: 'EVENT ID',
    },
    {
        key: 'eventType',
        value: 'EVENT TYPE',
    },
    {
        key: 'merchantName',
        value: 'MERCHANT',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
    {
        key: 'webhookName',
        value: 'WEBHOOK NAME',
    },
    {
        key: 'responseStatus',
        value: 'RESPONSE',
        type: 'apiStatusLabel',
    },
    {
        key: 'description',
        value: 'EVENT DESCRIPTION',
    },
    {
        key: 'deliverySequenceId',
        value: 'DELIVERY ID',
    },
    {
        key: 'quotationSequenceId',
        value: 'QUOTATION ID',
    },
    {
        key: 'merchantOrderNo',
        value: 'MERCHANT ORDER ID',
    },
];

export const statusDropDownValues = [
    { label: 'All', value: 'all' },
    { label: 'Success', value: 'success' },
    { label: 'Fail', value: ' fail' },
];

export const partnerApiWebhookEvents = [
    { label: 'Rider Assigned', value: 'riderAssigned' },
    { label: 'Rider Unassigned', value: 'riderUnassigned' },
    { label: 'Pickup Started', value: 'pickUpStarted' },
    { label: 'Picked Up', value: 'pickedUp' },
    { label: 'Delivery Started', value: 'deliveryStarted' },
    { label: 'Delivered', value: 'delivered' },
    { label: 'POD Received', value: 'proofOfDeliveryReceived' },
    { label: 'Delivery Completed', value: 'completed' },
    { label: 'Delivery Cancelled', value: 'cancelled' },
    { label: 'New Delivery Attempt', value: 'newDeliveryAttemptCreated' },
];
export const partnerWebhookEventStatusCodeValues = [
    { label: '200 - OK', value: 200 },
    { label: '201 - Created', value: 201 },
    { label: '500 - Internal Server Error', value: 500 },
    { label: '401 - Unauthorized', value: 401 },
    { label: '409 - Conflict', value: 409 },
];

export const WEBHOOK_EVENT_TYPES = {
    RIDER_ASSIGNED: 'riderAssigned',
    RIDER_UNASSIGNED: 'riderUnassigned',
    PICK_UP_STARTED: 'pickUpStarted',
    PICKED_UP: 'pickedUp',
    DELIVERY_STARTED: 'deliveryStarted',
    DELIVERED: 'delivered',
    POD_RECEIVED: 'proofOfDeliveryReceived',
    COMPLETED: 'deliveryCompleted',
    CANCELLED: 'deliveryCancelled',
    NEW_DELIVERY_ATTEMPT_CREATED: 'newDeliveryAttemptCreated',
};
