export const lottieOptionsLoading = (lottieName) => ({
    loop: true,
    autoplay: true,
    animationData: lottieName || '',
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
});

export const mapLottieOptionsLoading = (lottieName) => ({
    loop: true,
    autoplay: true,
    animationData: lottieName || '',
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
});
