/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

// #endregion imports
import CancelDelivery from './Modules/CancelDelivery';
import UnassignedDelivery from './Modules/UnassignedDelivery';
import CompleteDelivery from './Modules/CompleteDelivery';
import AddDeliveryAttempt from './Modules/AddDeliveryAttempt';
import UpdateDeliveryType from './Modules/UpdateDeliveryType';
import MoreOptions from './Modules/MoreOptions';

function DeliveryTransactionModal(props) {
    const {
        toggleModal,
        show = false,
        ref,
        type,
        title = '',
        body,
        data,
        setShowModal,
        merchantOrder,
        deliveryId,
        riderId,
        createBillingRecord,
        loadTransactions,
        setDeliveryFormData,
        setIsViewMode,
        updateRiderData,
        updateHistoryWithData,
    } = props;

    const commonProps = {
        loadTransactions,
        data,
        show,
        setShowModal,
        toggleModal,
        deliveryId,
        riderId,
        setDeliveryFormData,
        setIsViewMode,
        updateRiderData,
        updateHistoryWithData,
        createBillingRecord,
    };

    const generateBody = () => {
        if (body) {
            return <>{body}</>;
        }
        if (type === 'cancelDelivery') {
            return <CancelDelivery merchantOrder={merchantOrder} riderId={riderId} {...commonProps} />;
        }
        if (type === 'completeDelivery') {
            return <CompleteDelivery merchantOrder={merchantOrder} {...commonProps} />;
        }
        if (type === 'unassignedDelivery') {
            return <UnassignedDelivery merchantOrder={merchantOrder} {...commonProps} />;
        }
        if (type === 'addDeliveryAttempt') {
            return <AddDeliveryAttempt merchantOrder={merchantOrder} {...commonProps} />;
        }
        if (type === 'updateDeliveryType') {
            return <UpdateDeliveryType merchantOrder={merchantOrder} {...commonProps} />;
        }
        if (type === 'moreOptions') {
            return <MoreOptions merchantOrder={merchantOrder} {...commonProps} />;
        }
        return <></>;
    };

    return (
        <>
            <Modal isOpen={show} center className="modal-dialog modal-dialog-centered" ref={ref} toggle={toggleModal}>
                {title && <ModalHeader toggle={toggleModal}>{title}</ModalHeader>}
                <ModalBody className="p-0">{generateBody()}</ModalBody>
            </Modal>
        </>
    );
}

DeliveryTransactionModal.propTypes = {
    toggleModal: PropTypes.func,
    show: PropTypes.bool,
    type: PropTypes.any,
    title: PropTypes.any,
    body: PropTypes.any,
    ref: PropTypes.any,
    data: PropTypes.any,
    setShowModal: PropTypes.func,
    merchantOrder: PropTypes.any,
    deliveryId: PropTypes.any,
    riderId: PropTypes.any,
    createBillingRecord: PropTypes.func,
    loadTransactions: PropTypes.func,
    setDeliveryFormData: PropTypes.func,
    setIsViewMode: PropTypes.func,
    updateRiderData: PropTypes.func,
    updateHistoryWithData: PropTypes.func,
};

export default DeliveryTransactionModal;
