import * as React from 'react';
import PropTypes from 'prop-types';

function StartOverIcon(props) {
    const { color = '#FFFFFF', width = '24', height = '24' } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6331_380)">
                <path
                    d="M4.04999 10.9993C4.30287 9.07007 5.25021 7.29911 6.71467 6.01795C8.17912 4.7368 10.0603 4.03328 12.0061 4.0391C13.9518 4.04492 15.8287 4.75967 17.2855 6.04956C18.7423 7.33945 19.679 9.11605 19.9204 11.0468C20.1617 12.9775 19.6911 14.93 18.5967 16.5388C17.5022 18.1476 15.859 19.3024 13.9745 19.787C12.0901 20.2716 10.0936 20.0528 8.35886 19.1715C6.6241 18.2903 5.26999 16.807 4.54999 14.9993M4.04999 19.9993V14.9993H9.04999"
                    stroke="#F7F7F7"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_6331_380">
                    <rect width={width} height={height} fill={color} />
                </clipPath>
            </defs>
        </svg>
    );
}

StartOverIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};
export default StartOverIcon;
