/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import * as qs from 'query-string';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';

// #region components | assets
import { BackdropLoader, Breadcrumbs, CustomToast, ScootixModal, TableCustom } from 'components';
import LeftArrow from 'components/Icons/LeftArrow';

// #region other imports
import PATHS from 'routes/paths';
import { PARTNER_API_SANDBOX_ACCOUNT } from 'enums/partnerApi/partnerApi.enum';

// #region constants
import { initialScootixModalStateValues } from 'constants/modals/scootixModal.constant';

// #region services
import {
    createSandboxAccountService,
    getSandboxAccountByIdService,
    rejectSandboxAccountService,
    updateCreateSandboxAccountService,
} from 'services/partnerApi/sandboxAccount.service';
import { getPartnerApiSandboxAccountTransactionsService } from 'services/transaction.service';
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';

// #region utils
import { apiErrorHandlerV2, cleanMobileNo } from 'utils/helpers';
import { getVisitingObject } from 'utils/checkAuth';

// #endregion imports
import { mapSandboxAccountTransactions, validateForm } from './utils';
import { transactionInitialValues, transactionsLogTableHeaders } from './constant';
import SandboxAccountCreationForm from './containers/SandboxAccountCreationForm';
import MerchantRequestForm from './containers/MerchantRequestForm';
import FormDivider from './components/FormDivider';
import TransactionDataModal from './components/TransactionDataModal';

let initialFormValues = {};

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Sandbox Accounts', link: `/${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.LIST}` },
];

const { REQUEST_STATUS } = PARTNER_API_SANDBOX_ACCOUNT;

// !TODO add docs
function SandboxAccountFormViewPage(props) {
    const { location } = props;
    const { visitingClient, visitingMerchant } = getVisitingObject();

    const history = useHistory();
    const _isMounted = useRef(true);

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState({
        createSandboxAccount: false,
        rejectRequest: false,
    });
    // eslint-disable-next-line no-unused-vars
    const [backdropLoading, setBackdropLoading] = useState(0);

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);

    const [merchants, setMerchants] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState(null);

    const [formData, setFormData] = useState(initialFormValues);

    // Modals
    const [scootixModalState, setScootixModalState] = useState({ ...initialScootixModalStateValues });
    const [modalOptions, setModalOptions] = useState({ modal: null, data: {} });

    /** Data for the transactions log table */
    const [transactionData, setTransactionData] = useState([]);

    // !TODO: add docs
    useEffect(
        () => () => {
            _isMounted.current = false;
        },
        []
    );

    /** On page load, fetch the API key data from the backend */
    useEffect(() => {
        if (formData._id) {
            loadSandboxAccountTransactions();
        }
    }, [formData._id]);

    // Side Effect to load transactions
    // works on an interval basis
    useEffect(() => {
        const interval = setInterval(() => {
            if (formData._id) {
                loadSandboxAccountTransactions();
            }
        }, 20000);
        return () => clearInterval(interval);
    }, []);

    // !TODO: add docs
    useEffect(() => {
        if (!props.location) {
            history.push(PATHS.DELIVERY.DEFAULT);
            return;
        }
        const urlSearch = qs.parse(props.location.search);
        if (urlSearch?.type === 'new') {
            setEditingMode(false);
        } else if (urlSearch?.type === 'edit') {
            handleUpdateComponentEditMode();
        } else {
            history.replace({
                pathname: `/${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.FORM}`,
                search: `?type=new`,
            });
        }
    }, [props.location.search]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    // !TODO: add docs
    function handleUpdateComponentEditMode() {
        try {
            const urlSearch = qs.parse(props.location.search);
            const { id } = urlSearch;
            if (!id && !props.location.state) {
                history.push(`/${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.LIST}`);
                return;
            }

            if (id) {
                getSandboxAccountById(id);
            }
            if (location?.state?.sandboxData) {
                const stateData = location.state.sandboxData;
                setFormData((prevState) => ({ ...prevState, ...stateData }));
                getSandboxAccountById(stateData._id);
            }
            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Sandbox Request', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    }

    // !TODO: add docs
    const loadSandboxAccountTransactions = () => {
        if (formData._id) {
            getPartnerApiSandboxAccountTransactionsService(formData._id).then((res) => {
                if (res) {
                    const { data } = res;
                    if (data?.payload && Array.isArray(data.payload)) {
                        setTransactionData(data.payload);
                    }
                }
            });
        }
    };

    // !TODO: add docs
    const getSandboxAccountById = async (id) => {
        setFormLoading((prevState) => ({ ...prevState, createSandboxAccount: true, rejectRequest: true }));
        try {
            const { data } = await getSandboxAccountByIdService(id);
            if (data?.payload) {
                const responseRecord = data.payload;
                setFormData((prevState) => ({ ...prevState, ...responseRecord }));
                history.replace({
                    pathname: `/${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.FORM}`,
                    search: `?type=edit&id=${responseRecord._id}`,
                    state: {
                        sandboxData: responseRecord,
                    },
                });
            } else {
                toast.custom((t) => <CustomToast text="Sandbox Account Details Not Found" t={t} type="error" />, {
                    position: 'top-right',
                });
                history.goBack();
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading((prevState) => ({ ...prevState, createSandboxAccount: false, rejectRequest: false }));
    };

    // !TODO add docs and validation
    const handleCreateSandboxAccount = async () => {
        setFormLoading((prevState) => ({ ...prevState, createSandboxAccount: true }));

        try {
            const payload = {
                ...formData,
            };
            if (payload.developerContactNumber) {
                payload.developerContactNumber = cleanMobileNo(payload.developerContactNumber);
            }
            if (payload.developerWhatsappNumber) {
                payload.developerWhatsappNumber = cleanMobileNo(payload.developerWhatsappNumber);
            }

            const { data } = await createSandboxAccountService(payload);
            if (data?.payload) {
                closeScootixModal();
                const responseRecord = data.payload;
                setFormData((prevState) => ({ ...prevState, ...responseRecord }));
                history.replace({
                    pathname: `/${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.FORM}`,
                    search: `?type=edit&id=${responseRecord._id}`,
                    state: {
                        sandboxData: responseRecord,
                    },
                });
                toast.custom(
                    (t) => (
                        <CustomToast text="Successfully created sandbox account for merchant" t={t} type="success" />
                    ),
                    {
                        position: 'top-right',
                    }
                );
                loadSandboxAccountTransactions();
            }
        } catch (e) {
            const { message: error } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={error} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setSubmitted(false);
        setValidated(false);
        setFormLoading((prevState) => ({ ...prevState, createSandboxAccount: false }));
    };

    // !TODO add docs and validation
    const handleUpdateCreateSandboxAccount = async () => {
        setFormLoading((prevState) => ({ ...prevState, createSandboxAccount: true }));

        try {
            const payload = {
                ...formData,
            };

            if (payload.developerContactNumber) {
                payload.developerContactNumber = cleanMobileNo(payload.developerContactNumber);
            }
            if (payload.developerWhatsappNumber) {
                payload.developerWhatsappNumber = cleanMobileNo(payload.developerWhatsappNumber);
            }

            const { data } = await updateCreateSandboxAccountService(payload, formData._id);
            if (data?.payload) {
                closeScootixModal();
                const responseRecord = data.payload;
                setFormData((prevState) => ({ ...prevState, ...responseRecord }));
                history.replace({
                    pathname: `/${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.FORM}`,
                    search: `?type=edit&id=${responseRecord._id}`,
                    state: {
                        sandboxData: responseRecord,
                    },
                });
                toast.custom(
                    (t) => (
                        <CustomToast text="Successfully created sandbox account for merchant" t={t} type="success" />
                    ),
                    {
                        position: 'top-right',
                    }
                );
                loadSandboxAccountTransactions();
            }
        } catch (e) {
            const { message: error } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={error} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setSubmitted(false);
        setValidated(false);
        setFormLoading((prevState) => ({ ...prevState, createSandboxAccount: false }));
    };

    // !TODO add docs and validation
    const handleRejectSandboxAccount = async () => {
        setFormLoading((prevState) => ({ ...prevState, rejectRequest: true }));

        try {
            const payload = {
                ...formData,
            };

            if (payload.developerContactNumber) {
                payload.developerContactNumber = cleanMobileNo(payload.developerContactNumber);
            }
            if (payload.developerWhatsappNumber) {
                payload.developerWhatsappNumber = cleanMobileNo(payload.developerWhatsappNumber);
            }

            const { data } = await rejectSandboxAccountService(payload, formData._id);
            if (data?.payload) {
                closeScootixModal();
                const responseRecord = data.payload;
                setFormData((prevState) => ({ ...prevState, ...responseRecord }));
                history.replace({
                    pathname: `/${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.FORM}`,
                    search: `?type=edit&id=${responseRecord._id}`,
                    state: {
                        sandboxData: responseRecord,
                    },
                });
                toast.custom(
                    (t) => <CustomToast text="Successfully rejected sandbox account request" t={t} type="success" />,
                    {
                        position: 'top-right',
                    }
                );
                loadSandboxAccountTransactions();
            }
        } catch (e) {
            const { message: error } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={error} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setSubmitted(false);
        setValidated(false);
        setFormLoading((prevState) => ({ ...prevState, rejectRequest: false }));
    };

    // !TODO: add docs
    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors?.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setScootixModalState({
            ...scootixModalState,
            show: true,
            message: `Do you want to create a sandbox Account for ${formData.merchantName} - ${formData.merchantReferenceNumber}`,
            onConfirm: () => handleCreateSandboxAccount(),
        });
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;

        if (id === 'developerName' || id === 'developerContactNumber' || id === 'developerEmail') {
            setFormData({
                ...formData,
                isDeveloperNameSameAsMerchant: false,
                [id]: value,
            });
        } else if (id === 'developerWhatsappNumber') {
            setFormData({
                ...formData,
                isWhatsAppSameAsDeveloperContact: false,
                [id]: value,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    };

    // !TODO add docs
    const handleSelectChange = (event, id) => {
        if (event) {
            if (id === 'merchantId') {
                const relevantMerchant = merchants.find((x) => x._id === event.value);
                if (relevantMerchant) {
                    setFormData({
                        ...formData,
                        merchantName: relevantMerchant.name || '',
                        merchantReferenceNumber: relevantMerchant.referenceNumber || '',
                        [id]: event ? event.value : null,
                    });
                    setSelectedMerchant(relevantMerchant);
                    return;
                }
            }

            setFormData({
                ...formData,
                [id]: event ? event.value : null,
            });
        } else {
            setFormData({
                ...formData,
                [id]: null,
            });
            if (id === 'merchantId') {
                setSelectedMerchant(null);
            }
        }
    };

    // !TODO add docs
    const handleCheckChange = (event) => {
        setIsFormSaved(false);
        const { id, checked } = event.target;

        if (id === 'isDeveloperNameSameAsMerchant' && checked) {
            if (!selectedMerchant) {
                toast.custom((t) => <CustomToast text="Select Merchant" t={t} type="warning" />, {
                    position: 'top-right',
                });
                return;
            }
            const {
                primaryContactName = null,
                primaryContactEmail = null,
                primaryContactNumber = null,
            } = selectedMerchant;
            setFormData({
                ...formData,
                developerName: primaryContactName,
                developerEmail: primaryContactEmail,
                developerContactNumber: primaryContactNumber,
                [id]: checked,
            });
            return;
        }

        if (id === 'isWhatsAppSameAsDeveloperContact' && checked) {
            if (!selectedMerchant) {
                toast.custom((t) => <CustomToast text="Select Merchant" t={t} type="warning" />, {
                    position: 'top-right',
                });
                return;
            }
            const { primaryContactNumber = null } = selectedMerchant;
            setFormData({
                ...formData,
                developerWhatsappNumber: primaryContactNumber,
                [id]: checked,
            });
            return;
        }

        setFormData({
            ...formData,
            [id]: checked,
        });
    };

    const closeScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: !scootixModalState.show, data: null });
    };

    /**
     * Change the modalOptions state so modals can be shown or hidden.
     * See comments on modalOptions state.
     * @param {string} modalName - name of the modal to show - falsey value hides modal
     */
    const handleTransactionModalToggle = (modalName) => {
        if (modalName) {
            setModalOptions((prev) => ({ ...prev, modal: modalName }));
        } else {
            setModalOptions((prev) => ({ ...prev, modal: null }));
        }
    };

    // !TODO add docs
    const displayTransactionModal = (data) => {
        const { meta = {}, sequence } = data;
        if (!meta) {
            return;
        }
        const { customMessage } = meta;
        setModalOptions({
            modal: 'transaction',
            data: {
                transactionId: sequence,
                customMessage,
            },
        });
    };

    // !TODO add docs
    function renderHeader() {
        if (editingMode && formData.isMerchantRequest) {
            return (
                <>
                    <div
                        className="d-flex p-3 justify-content-between align-items-center"
                        style={{
                            backgroundColor: '#F5F4F4',
                            border: '1px solid #E7E4E4',
                            borderRadius: '15px 15px 0px 0px',
                        }}
                    >
                        <div
                            style={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                color: '#3A3937',
                            }}
                        >
                            Create a Sandbox Account for {formData.merchantName} {formData.sequenceId || ''}
                        </div>
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-form-btn shadow-lg align-content-center"
                            onClick={() => history.goBack()}
                        >
                            <LeftArrow />{' '}
                            <span>
                                <b>Back to Requests</b>
                            </span>
                        </motion.div>
                    </div>
                </>
            );
        }
        return <div />;
    }

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return { label: relevantMerchant.name, value: relevantMerchant._id };
        }
        return null;
    };

    const selectedMerchantId = useMemo(() => getMerchantSelect(formData.merchantId), [formData.merchantId, merchants]);

    const formProps = {
        handleSubmit,
        formLoading,
        editingMode,
        history,
        selectedMerchantId,
        merchants,
        handleSelectChange,
        submitted,
        validated,
        formData,
        handleCheckChange,
        handleInputChange,
        handleRejectSandboxAccount: () => {
            setScootixModalState({
                ...scootixModalState,
                show: true,
                message: `Do you want to reject the sandbox account request for ${formData.merchantName} - ${formData.merchantReferenceNumber}`,
                onConfirm: () => handleRejectSandboxAccount(),
                headerColor: '#FD4A4A',
            });
        },
        handleUpdateCreateSandboxAccount: () => {
            setScootixModalState({
                ...scootixModalState,
                show: true,
                message: `Do you want to create a sandbox Account for ${formData.merchantName} - ${formData.merchantReferenceNumber}`,
                onConfirm: () => handleUpdateCreateSandboxAccount(),
            });
        },
    };

    function renderForm() {
        if (formData.requestStatus === REQUEST_STATUS.COMPLETED || formData.requestStatus === REQUEST_STATUS.REJECTED) {
            return <div />;
        }

        if (formData.isMerchantRequest) {
            return (
                <>
                    <MerchantRequestForm {...formProps} />;
                    <FormDivider />
                </>
            );
        }
        if (!formData.isMerchantRequest) {
            return (
                <>
                    <SandboxAccountCreationForm {...formProps} />;
                    <FormDivider />
                </>
            );
        }

        return <div />;
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <Card
                        style={{
                            paddingBottom: 50,
                            padding: 0,
                            borderRadius: 15,
                            border: '1px solid #E7E4E4',
                        }}
                    >
                        <CardBody className="" style={{ padding: 0 }}>
                            {renderHeader()}
                            <div className="p-3">
                                {renderForm()}
                                {formData._id && (
                                    <>
                                        <Row className="mt-2">
                                            <Col>
                                                <span className="font-weight-bold">Transactions Log</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TableCustom
                                                    tableData={{
                                                        ...transactionInitialValues,
                                                        docs: mapSandboxAccountTransactions(transactionData, formData),
                                                        headers: transactionsLogTableHeaders,
                                                    }}
                                                    showPagination={false}
                                                    removeAction
                                                    isFilter={false}
                                                    isSearch={false}
                                                    isFixedHeight
                                                    isCardContainer={false}
                                                    isOuterTableBorder
                                                    customActionHandlers={{ displayTransactionModal }}
                                                />
                                                <TransactionDataModal
                                                    show={modalOptions.modal === 'transaction'}
                                                    handleToggle={handleTransactionModalToggle}
                                                    {...modalOptions}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Container>

                <ScootixModal
                    toggleModal={toggleScootixModal}
                    {...scootixModalState}
                    loading={formLoading.createSandboxAccount || formLoading.rejectRequest}
                />
                <BackdropLoader show={backdropLoading} opacity={0.7} />
            </div>
        </>
    );
}

SandboxAccountFormViewPage.propTypes = {
    location: PropTypes.any,
};

const HotSandboxAccountFormViewPage = hot(SandboxAccountFormViewPage);

export default HotSandboxAccountFormViewPage;
