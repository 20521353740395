export const defaultTableHeaders = [
    {
        key: 'riderName',
        value: 'Rider Name',
    },
    {
        key: 'riderReferenceNo',
        value: 'Rider ID',
    },
    {
        key: 'riderMobileNo',
        value: 'Rider Mobile Number',
    },
    {
        key: 'vehicleType',
        value: 'Vehicle Type',
    },
];
