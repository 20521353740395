/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as qs from 'query-string';
import { motion } from 'framer-motion';

// #region assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { BackdropLoader, CustomToast, ScootixModal, TableCustom } from 'components';
import PATHS from 'routes/paths';

// #region utils
import { hasAccess, IS_SUPER_ADMIN } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import { apiErrorHandlerV2, isObject } from 'utils/helpers';

// #region services
import { getDeliveriesService } from 'services/deliveries.service';

// #endregion services
import { defaultDeliveryListView, defaultScootixModalState, deliveryFilters } from '../../constants';
import { deleteDelivery } from '../../services';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Delivery', link: '#' },
];

/**
 * Un Assigned Delivery Management Page
 * @param {*} props
 * @returns {React.Component}
 */
function UnAssignedDeliveryManagementPage(props) {
    const { location } = props;

    const history = useHistory();

    // component state
    const [loading, setLoading] = useState(0);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [scootixModalState, setScootixModalState] = useState(defaultScootixModalState);
    const [deliveryListData, setDeliveryListData] = useState(defaultDeliveryListView);
    const [urlParamsSearch, setUrlParamsSearch] = useState(null);
    const [searchParams, setSearchParams] = useState(null);

    useEffect(() => {
        const urlSearch = qs.parse(location.search);
        if (urlSearch && urlSearch.status) {
            const { status, page, searchBy, searchText } = urlSearch;
            setUrlParamsSearch({ status, page, searchBy, searchText });
            setSearchParams({ searchBy, searchText });
        } else {
            replaceUrlParamsHandler({ status: 'unAssigned', page: '1' });
        }
    }, [location.search]);

    useEffect(() => {
        if (urlParamsSearch) {
            loadDeliveriesListData({
                showLoading: true,
                ...deliveryListData,
                searchBy: { searchBy: urlParamsSearch?.searchBy, searchText: urlParamsSearch?.searchText },
                type: urlParamsSearch.type,
                status: urlParamsSearch.status,
                page: urlParamsSearch.page,
            });
        }
    }, [urlParamsSearch]);

    const replaceUrlParamsHandler = (params = { status: null, page: '1', searchBy: null, searchText: null }) => {
        try {
            history.replace({
                search: `${
                    params.page || urlParamsSearch.page ? `page=${params.page || urlParamsSearch.page || '1'}&` : ''
                }${
                    params.status || urlParamsSearch.status
                        ? `status=${params.status || urlParamsSearch.status || 'unAssigned'}&`
                        : ''
                }${params?.searchBy ? `searchBy=${params.searchBy}&` : ''}${
                    params?.searchText ? `searchText=${params.searchText}&` : ''
                }`,
            });
        } catch (e) {
            console.log(e);
        }
    };

    const reloadComponent = () => {
        loadDeliveriesListData({
            showLoading: true,
            ...deliveryListData,
            page: urlParamsSearch.page,
            searchBy: { searchBy: urlParamsSearch?.searchBy, searchText: urlParamsSearch?.searchText },
            type: urlParamsSearch.type,
            status: urlParamsSearch.status,
        });
    };

    const loadDeliveriesListData = (args) => {
        if (args.showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getDeliveriesService({ ...args, populate: 'riderId,merchantId' })
            .then((res) => {
                const { data } = res;
                if (data && data.docs) {
                    const docs = mapDocs(data.docs);
                    setDeliveryListData({
                        ...deliveryListData,
                        ...data,
                        docs,
                    });
                }
            })
            .catch((e) => {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            })
            .finally(() => {
                if (args.showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const mapDocs = (docs) => {
        let allDocs = [];
        allDocs = docs.map((x) => ({
            ...x,
            recipientName: `${x.recipientName || 'No Recipient Given'}, ${x.deliveryAddress || ''}`,
            riderDetails: x.riderReference ? `${x.riderName} ${x.riderReference}` : `Rider Not Assigned`,
            merchant: x.merchantId && isObject(x.merchantId) ? x.merchantId : null,
            merchantPictureUrl: x.merchantId && isObject(x.merchantId) ? x.merchantId.merchantPictureUrl : null,
            merchantId: x.merchantId && isObject(x.merchantId) ? x.merchantId._id : x.merchantId,
        }));
        return allDocs;
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: !scootixModalState.show, data: null });
    };

    const handlePageChange = (pageNo) => {
        replaceUrlParamsHandler({
            page: pageNo,
            searchBy: searchParams?.searchBy,
            searchText: searchParams?.searchText,
        });
    };

    const handleFilter = (field, filterText) => {
        let modifiedFilterText = null;
        if (filterText && filterText !== '') {
            modifiedFilterText = filterText.replaceAll(' ', '$2');
        } else {
            modifiedFilterText = null;
        }
        replaceUrlParamsHandler({ searchBy: field || 'referenceNumber', searchText: modifiedFilterText, page: '1' });
    };

    const handleEditRow = (e, row) => {
        history.push({
            pathname: `${PATHS.DELIVERY.FORM}`,
            search: `?type=edit`,
            state: { deliveryParentData: row },
        });
    };

    const handleOnDelete = (e, row) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: { _id: row._id },
            message: `Are you sure you want to Permanently delete Delivery ${row.referenceNumber || ''} ?`,
            onConfirm: (data) => handleDeleteDelivery(data),
        });
    };

    const handleDeleteDelivery = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteDelivery(data._id);
            reloadComponent();
            toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        toggleScootixModal();
        setBackdropLoading((prevState) => prevState - 1);
    };

    const allowDeliveryDelete = (delivery) => {
        if (IS_SUPER_ADMIN()) {
            return true;
        }

        if (delivery) {
            if (delivery.riderId) return false;
        }
        return true;
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <br />
                    <Row className="m-2" style={{ justifyContent: 'space-between' }}>
                        <div />
                        <div>
                            <motion.button
                                id="reload-comp-btn"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-form-btn circle shadow-lg nw-md pr-2 pl-3 pointer m3-3 mr-3"
                                onClick={() => {
                                    replaceUrlParamsHandler({
                                        status: 'unAssigned',
                                        page: '1',
                                        searchBy: null,
                                        searchText: null,
                                    });
                                }}
                            >
                                <>Reset Filters</>
                            </motion.button>
                            <motion.button
                                id="reload-comp-btn"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-form-btn circle shadow-lg nw-md pr-2 pl-3 pointer"
                                onClick={() => {
                                    reloadComponent();
                                }}
                            >
                                <>
                                    <i className="fas fa-redo-alt left-icon"></i>
                                </>
                            </motion.button>
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <TableCustom
                            id="unassigned-delivery-table-id"
                            tableData={deliveryListData}
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            handleDelete={handleOnDelete}
                            loading={loading}
                            isFullScreenShow
                            handleEdit={handleEditRow}
                            showView={false}
                            showEdit={hasAccess(PERMISSIONS.DELIVERY, ['Edit'])}
                            showDelete={hasAccess(PERMISSIONS.DELIVERY, ['Delete'])}
                            removeId={false}
                            isExport
                            type="delivery"
                            exportModalHeader="Export Delivery Data"
                            filters={deliveryFilters}
                            allowDelete={allowDeliveryDelete}
                        />
                    </Row>
                </Container>
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.7} />
            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
        </>
    );
}

UnAssignedDeliveryManagementPage.propTypes = {
    location: PropTypes.any,
    socket: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotUnAssignedDeliveryManagementPage = hot(UnAssignedDeliveryManagementPage);

export default connect(mapStateToProps, {})(HotUnAssignedDeliveryManagementPage);
