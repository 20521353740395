/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';

/** Modal which shows the reason for disabling an API Key */
function DisabledKeyDetails(props) {
    const { show, handleToggle, transactionId, name, reason } = props;

    return (
        <DeveloperModal header={`Transaction Data for ${transactionId}`} show={show} handleToggle={handleToggle}>
            <Container>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="name">Name</Label>
                            <Input name="name" type="text" disabled value={name} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="reason">Reason for Disabling the API Key</Label>
                            <Input
                                name="reason"
                                type="textarea"
                                disabled
                                value={reason}
                                style={{ borderRadius: 15, backgroundColor: '#F5F4F4', border: '#E7E4E4 1px solid' }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
        </DeveloperModal>
    );
}

DisabledKeyDetails.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    transactionId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
};

export default DisabledKeyDetails;
