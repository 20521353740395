/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../index.css';

// #region other imports
import { TABLE_HEADER_TYPES } from 'constants/table';

// #region utils
import { isObject } from 'utils/helpers';

// #endregion imports
import Default from '../components/TableHeaders/Default';
import ShippingLabelTableHeader from '../components/TableHeaders/ShippingLabelTableHeader';
import SelectToPrintTableHeader from '../components/TableHeaders/SelectToPrintTableHeader';
import SelectedItemPrintTableHeader from '../components/TableHeaders/SelectedItemPrintTableHeader';
import DeliveryBatchAssignmentDeliveriesTableHeader from '../components/TableHeaders/DeliveryBatchAssignmentDeliveriesTableHeader';
import ManageApiKeysTableHeader from '../components/TableHeaders/ManageApiKeysTableHeader';
import ManageWebhooksTableHeader from '../components/TableHeaders/ManageWebhooksTableHeader';
import PartnerListTableHeader from '../components/TableHeaders/PartnerListTableHeader';
import PartnerApiSandboxAccountListViewHeader from '../components/TableHeaders/PartnerApi/SandboxAccountListViewHeader';
import ManageConnectedAppsTableHeader from '../components/TableHeaders/ManageConnectedAppsTableHeader';

function TableHeader(props) {
    const {
        tableHeaderType,
        searchProp,
        isManualSearch,
        filters,
        onFilter,
        isFilter,
        isExport,
        showStickyView,
        tableData,
        isFullScreenShow,
        isSearch,
        dateFilters,
        onDateFilter,
        setIsFullScreen,
        isFullScreen,
        handleFullScreen,
        exportModalShow,
        setExportModalShow,
        setEnableStickyView,
        enableStickyView,
        headerHandlers,
        showHeaderHandlers,
        isSetting,
        apiKeysTableHeaderProps,
        webhooksTableHeaderProps,
        connectedAppsHeaderProps,
        partnerListTableHeaderProps,
        customHeaderProps = {},
        setIsUp, // column wise sort arrow
    } = props;
    const [showFilters, setShowFilters] = useState(false);

    // set whether date filter is visible
    const [dateFilter, setDateFilter] = useState(false);

    const [search, setSearch] = useState({
        searchBy: null,
        searchText: '',
        filterDate: '',
    });

    useEffect(() => {
        if (searchProp && isManualSearch && isObject(searchProp)) {
            setSearch({
                searchBy: searchProp.searchBy || null,
                searchText: searchProp.searchText || '',
                filterDate: searchProp.filterDate || '',
            });
        }
    }, [searchProp, isManualSearch]);

    const toggleExportModal = () => setExportModalShow(!exportModalShow);

    const getFilterTitle = (key) => {
        const relevantFilter = filters.find((x) => x.key === key);
        if (relevantFilter) {
            return relevantFilter.label;
        }
        return 'Filter';
    };

    const defaultProps = {
        searchProp,
        isManualSearch,
        filters,
        onFilter,
        isFilter,
        isExport,
        showStickyView,
        tableData,
        isFullScreenShow,
        isSearch,
        dateFilters,
        onDateFilter,
        showFilters,
        setShowFilters,
        getFilterTitle,
        setSearch,
        search,
        toggleExportModal,
        setEnableStickyView,
        enableStickyView,
        isFullScreen,
        setIsFullScreen,
        handleFullScreen,
        headerHandlers,
        showHeaderHandlers,
        isSetting,
        customHeaderProps,
        setIsUp,
        dateFilter,
        setDateFilter,
    };
    function renderHeader() {
        switch (tableHeaderType) {
            case TABLE_HEADER_TYPES.LABEL_PRINT_SHIPPING_LABEL_LIST:
                return <ShippingLabelTableHeader {...defaultProps} />; // replace defaultprops in this line when moving further
            case TABLE_HEADER_TYPES.BATCH_PRINT_SELECT_TO_PRINT:
                return <SelectToPrintTableHeader {...defaultProps} />;
            case TABLE_HEADER_TYPES.BATCH_PRINT_SELECTED_ITEM:
                return <SelectedItemPrintTableHeader {...defaultProps} />;
            case TABLE_HEADER_TYPES.DELIVERY_BATCH_ASSIGNMENT_DELIVERIES:
                return <DeliveryBatchAssignmentDeliveriesTableHeader {...defaultProps} />;
            case TABLE_HEADER_TYPES.MANAGE_API_KEYS:
                return <ManageApiKeysTableHeader {...apiKeysTableHeaderProps} {...defaultProps} />;
            case TABLE_HEADER_TYPES.MANAGE_WEBHOOKS:
                return <ManageWebhooksTableHeader {...webhooksTableHeaderProps} {...defaultProps} />;
            case TABLE_HEADER_TYPES.PARTNER_LIST:
                return <PartnerListTableHeader {...partnerListTableHeaderProps} {...defaultProps} />;
            case TABLE_HEADER_TYPES.PARTNER_API.SANDBOX_ACCOUNT_LIST_VIEW:
                return <PartnerApiSandboxAccountListViewHeader {...partnerListTableHeaderProps} {...defaultProps} />;
            case TABLE_HEADER_TYPES.CONNECTED_APPS:
                return <ManageConnectedAppsTableHeader {...connectedAppsHeaderProps} {...defaultProps} />;
            default:
                return <Default {...defaultProps} />;
        }
    }

    return <>{renderHeader()}</>;
}

TableHeader.propTypes = {
    isFullScreenShow: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    setIsFullScreen: PropTypes.func,
    exportModalShow: PropTypes.bool,
    setExportModalShow: PropTypes.func,
    isSearch: PropTypes.bool,
    isFilter: PropTypes.bool,
    onFilter: PropTypes.func,
    filters: PropTypes.array,
    tableData: PropTypes.object,
    isExport: PropTypes.bool,
    enableStickyView: PropTypes.bool,
    isManualSearch: PropTypes.bool,
    handleFullScreen: PropTypes.any,
    searchProp: PropTypes.any,
    tableHeaderType: PropTypes.string,
    setEnableStickyView: PropTypes.func,
    showStickyView: PropTypes.bool,
    headerHandlers: PropTypes.object,
    showHeaderHandlers: PropTypes.bool,
    isSetting: PropTypes.bool,
    apiKeysTableHeaderProps: PropTypes.object,
    webhooksTableHeaderProps: PropTypes.object,
    connectedAppsHeaderProps: PropTypes.object,
    partnerListTableHeaderProps: PropTypes.object,
    customHeaderProps: PropTypes.object,
    setIsUp: PropTypes.func,
    dateFilters: PropTypes.bool,
    onDateFilter: PropTypes.func,
};
export default TableHeader;
