/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';

// #region assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { BackdropLoader, CustomToast, ScootixModal, TableCustom } from 'components';
import PATHS from 'routes/paths';

// #region utils
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import { apiErrorHandler, isObject } from 'utils/helpers';

// #region services
import { getStocksReceivingService } from 'services/stocks-receiving';

// #endregion services
import { defaultSearchFilters, defaultTableHeaders } from './constants';
import { deleteStocksReceivingService } from './services';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Stocks Receiving', link: '#' },
];

/**
 * Stocks Receiving Management Page
 * @param {*} props
 * @returns {React.Component}
 */
function StockReceivingManagementPage(props) {
    const history = useHistory();

    const _isMounted = useRef(true);

    // component state
    const [loading, setLoading] = useState(0);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    const [searchBy] = useState({ searchBy: '', searchText: null });

    // db - data
    const [stocksReceivingListData, setStocksReceivingListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    // !FIX CLEANUP
    useEffect(() => {
        loadStocksReceivingListData(
            true,
            stocksReceivingListData.all,
            1,
            stocksReceivingListData.limit,
            stocksReceivingListData.filter,
            stocksReceivingListData.sort,
            stocksReceivingListData.order,
            searchBy
        );
        return () => {
            _isMounted.current = false;
        };
    }, [props.location]);

    const loadStocksReceivingListData = (showLoading = true, all, page, limit, filter, sort, order, searchByParam) => {
        if (showLoading && _isMounted.current) {
            setLoading((prevState) => prevState + 1);
        }
        getStocksReceivingService(all, page, limit, filter, sort, order, searchByParam, 'merchantId,warehouseSiteId')
            .then((res) => {
                if (_isMounted.current && res) {
                    const { data } = res;
                    if (data && data.docs) {
                        const docs = mapDocs(data.docs);
                        setStocksReceivingListData({
                            ...stocksReceivingListData,
                            ...data,
                            docs,
                        });
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading && _isMounted.current) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const mapDocs = (docs) => {
        let allDocs = [];
        allDocs = docs.map((x) => ({
            ...x,
            merchantPictureUrl: x.merchantId && isObject(x.merchantId) ? x.merchantId.merchantPictureUrl : null,
            merchantId: x.merchantId && isObject(x.merchantId) ? x.merchantId._id : x.merchantId,
            warehouseSiteId:
                x.warehouseSiteId && isObject(x.warehouseSiteId) ? x.warehouseSiteId._id : x.warehouseSiteId,
            warehouseSiteName:
                x.warehouseSiteId && isObject(x.warehouseSiteId) ? x.warehouseSiteId.siteName : x.warehouseSiteId,
            warehouseSite: x.warehouseSiteId && isObject(x.warehouseSiteId) ? x.warehouseSiteId : null,
        }));
        return allDocs;
    };

    const handleOnClickNewStocksReceiving = () => {
        history.push({
            pathname: `${PATHS.STOCKS_RECEIVING.FORM_VIEW}`,
            search: `?type=new`,
        });
    };

    const handlePageChange = (pageNo) => {
        loadStocksReceivingListData(
            true,
            stocksReceivingListData.all,
            pageNo,
            stocksReceivingListData.limit,
            stocksReceivingListData.filter,
            stocksReceivingListData.sort,
            stocksReceivingListData.order,
            searchBy
        );
    };

    const handleFilter = (field, filterText) => {
        loadStocksReceivingListData(
            false,
            stocksReceivingListData.all,
            1,
            stocksReceivingListData.limit,
            stocksReceivingListData.filter,
            stocksReceivingListData.sort,
            stocksReceivingListData.order,
            { searchBy: field || 'name', searchText: filterText }
        );
    };

    const handleEditRow = (e, row) => {
        history.push({
            pathname: `${PATHS.STOCKS_RECEIVING.FORM_VIEW}`,
            search: `?type=edit`,
            state: { stocksReceivingParentData: row },
        });
    };

    const handleOnDelete = (e, row) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: row,
            message: 'Are you sure you want to delete Stocks Receiving?',
            onConfirm: (data) => handleDeleteStocksReceiving(data),
        });
    };

    const handleDeleteStocksReceiving = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteStocksReceivingService(data._id);
            loadStocksReceivingListData(
                false,
                stocksReceivingListData.all,
                1,
                stocksReceivingListData.limit,
                stocksReceivingListData.filter,
                stocksReceivingListData.sort,
                stocksReceivingListData.order,
                searchBy
            );
            toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        toggleScootixModal();
        setBackdropLoading((prevState) => prevState - 1);
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />

                    <>
                        <Row>
                            <div className="d-flex row ml-2">
                                {hasAccess(PERMISSIONS.STOCKS_RECEIVING, ['Add']) && (
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                        onClick={() => handleOnClickNewStocksReceiving()}
                                    >
                                        <i className="fas fa-warehouse left-icon mr-2" />
                                        {'  '} <div style={{ marginTop: 1, marginLeft: 3 }}>Receive New Stocks</div>
                                    </motion.div>
                                )}
                            </div>
                        </Row>
                    </>
                    <br />

                    <Row>
                        <TableCustom
                            id="stocksReceiving-table-id"
                            tableData={stocksReceivingListData}
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            handleDelete={handleOnDelete}
                            loading={loading}
                            isFullScreenShow
                            handleEdit={handleEditRow}
                            showView={false}
                            showEdit={hasAccess(PERMISSIONS.STOCKS_RECEIVING, ['Edit'])}
                            showDelete={false}
                            removeId={false}
                            isExport
                            type="stocksReceiving"
                            exportModalHeader="Export Stocks Receiving Data"
                            filters={defaultSearchFilters}
                        />
                    </Row>
                </Container>
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.7} />

            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
        </>
    );
}

StockReceivingManagementPage.propTypes = {
    location: PropTypes.any,
    socket: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotStockReceivingManagementPage = hot(StockReceivingManagementPage);

export default connect(mapStateToProps, {})(HotStockReceivingManagementPage);
