/**
 * Change values in the service response to work with table component
 * @param {array} docs - array of docs returned from service
 * @returns {array} array of formatted docs
 */
export const mapDocs = (docs) => {
    let allDocs = [];
    allDocs = docs.map((doc) => ({
        ...doc,
        isActiveString: doc.isActive ? 'active' : 'inactive',
        keyTypeString: doc.type === 'public' ? 'Public' : 'Private',
    }));
    return allDocs;
};
