/* eslint-disable radix */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Row, Col, Card, CardBody, FormGroup, Label, Spinner, Input, Container } from 'reactstrap';
import toast from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useHistory } from 'react-router-dom';
import * as qs from 'query-string';
import PropTypes from 'prop-types';

// #region assets | components
import Hoc from 'components/Hoc';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { Select, BackdropLoader, CustomToast, FormStatusBtn } from 'components';

// #region imports
import { currencyCodes, DELIVERY_TYPES, BILLING_METHODS } from 'constants/constants';
import PATHS from 'routes/paths';
import { SubTitle } from 'styles';
import { billingSettingModelKeys } from 'models';

// #region utils
import { __CLIENT_VIEW__ } from 'utils/checks';
import { getVisitingObject } from 'utils/checkAuth';
import { apiErrorHandler, getDateValue, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';

// #region services
import { getAllClientsService } from 'services/client.service';

// #endregion imports
import { updateBillingService, createBillingService } from './services';
import { validateForm } from './utils';
import { BILLING_METHOD_OPTIONS_DEFAULT, BILLING_METHOD_OPTIONS_RETURN_ORDER } from './constants';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Merchant Edit', link: PATHS.MERCHANT.MERCHANT_FORM },
];

const initialFormValues = { currency: 'MYR' };

function BillingSettingFormPage(props) {
    // Component
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading] = useState(0);

    const history = useHistory();

    const { visitingClient, visitingMerchant } = getVisitingObject();

    if (visitingClient) {
        initialFormValues.clientId = visitingClient._id;
    }
    if (visitingMerchant) {
        initialFormValues.merchantId = visitingMerchant._id;
    }

    const [formData, setFormData] = useState(initialFormValues);

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [merchantParentData, setMerchantParentData] = useState({});

    const [allClients, setAllClients] = useState([]);

    useEffect(() => {
        if (!props.location) {
            history.goBack();
            return;
        }

        const formType = qs.parse(props.location.search);
        if (formType && formType.type && formType.type === 'new') {
            if (!props.location?.state) {
                history.goBack();
                return;
            }
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Billing Setting', link: '#' }]);
            const { merchantParentData } = props.location.state;
            setMerchantParentData({ ...merchantParentData });
        }

        if (formType && formType.type && formType.type === 'edit') {
            handleUpdateComponentEditMode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getAllClientsService().then((res) => {
            const { data } = res;
            if (data && data.docs) {
                setAllClients(
                    data.docs.map((doc) => ({
                        label: doc.name,
                        value: doc._id,
                        _id: doc._id,
                    }))
                );
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const { isFormValid, message } = validateForm(formData);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        let updatedPayloadData;

        setFormLoading(true);

        try {
            const payload = formData;
            payload.merchantId = merchantParentData._id;

            if (editingMode) {
                const { data } = await updateBillingService(payload);
                if (data) {
                    setFormData({ ...formData, ...data });
                    updatedPayloadData = { ...formData, ...data };
                }
            } else {
                const { data } = await createBillingService(payload);
                if (data) {
                    setFormData({ ...formData, ...data });
                    updatedPayloadData = { ...formData, ...data };
                }
                setEditingMode(true);
            }

            try {
                if (updatedPayloadData) {
                    history.replace({
                        pathname: `${PATHS.PAYMENTS.BILLING_FORM}`,
                        search: `?type=edit`,
                        state: {
                            billingSettingParentData: updatedPayloadData,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }

            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} Billing Setting`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setIsFormSaved(true);
        } catch (e) {
            const errorMessage = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading(false);
        setSubmitted(false);
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;

        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleUpdateComponentEditMode = () => {
        try {
            const { billingSettingParentData } = props.location.state;
            setFormData({ ...formData, ...billingSettingParentData });
            // Update URL Based on Condition
            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Billing Setting', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    const getSelectedClient = (clientId) => {
        const selectedClientId = allClients.find((client) => client._id === clientId);
        if (selectedClientId) {
            return selectedClientId.label;
        }
        return '';
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);
        const valuesForBilling = {
            baseMileage: null,
            baseMileageRate: null,
            additionalMileageSlab1: null,
            additionalMileageSlab1Limit: null,
            ratePerAdditionalMileage1: null,
            additionalMileageSlab2: null,
        };

        if (id === billingSettingModelKeys.billingMethod && event.value === BILLING_METHODS.FIXED_PER_DELIVERY) {
            setFormData({
                ...formData,
                ...valuesForBilling,
                commission: null,

                [id]: event ? event.value : null,
            });
        } else if (
            id === billingSettingModelKeys.billingMethod &&
            event.value === BILLING_METHODS.PERCENTAGE_OF_DELIVERY_FEE
        ) {
            setFormData({
                ...formData,
                ...valuesForBilling,
                fixedDeliveryRate: null,

                [id]: event ? event.value : null,
            });
        } else if (id === billingSettingModelKeys.billingMethod && event.value === BILLING_METHODS.MILEAGE_BASED_FEE) {
            setFormData({
                ...formData,
                commission: null,
                fixedDeliveryRate: null,

                [id]: event ? event.value : null,
            });
        } else {
            setFormData({
                ...formData,
                [id]: event ? event.value : null,
            });
        }
    };

    return (
        <>
            <Hoc {...props}>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                        <Row
                            style={{
                                margin: 2,
                                justifyContent: 'space-between',
                            }}
                        >
                            {!editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                >
                                    <i className="far fa-times-circle mr-2 fa-lg" />
                                    Cancel
                                </motion.div>
                            )}
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormStatusBtn isFormSaved={isFormSaved} />
                            </div>

                            {editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                >
                                    <i className="fas fa-arrow-left mr-2 fa-lg" />
                                    Back to Billing Settings
                                </motion.div>
                            )}
                        </Row>
                        <Card>
                            <CardBody>
                                <Row style={{ justifyContent: 'space-between', alignItems: 'center', margin: 2 }}>
                                    <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                        BILLING SETTING
                                    </SubTitle>
                                </Row>
                                <br />
                                <AvForm
                                    autoComplete="off"
                                    className="needs-validation default-form"
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                    id="billingForm"
                                >
                                    <fieldset disabled={formLoading}>
                                        <input type="hidden" value="something" />

                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="clientId">Client</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(
                                                            getSelectedClient(formData.clientId)
                                                        )}
                                                        options={allClients}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'clientId')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                        isDisabled={__CLIENT_VIEW__}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="deliveryType">Delivery Type</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.deliveryType)}
                                                        options={[
                                                            {
                                                                label: DELIVERY_TYPES.NEW_ORDER,
                                                                value: DELIVERY_TYPES.NEW_ORDER,
                                                            },
                                                            {
                                                                label: DELIVERY_TYPES.RETURN_ORDER,
                                                                value: DELIVERY_TYPES.RETURN_ORDER,
                                                            },
                                                            {
                                                                label: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
                                                                value: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
                                                            },
                                                        ]}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'deliveryType')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="vehicleType">Vehicle Type</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.vehicleType)}
                                                        options={[
                                                            {
                                                                label: 'Motorbike',
                                                                value: 'Motorbike',
                                                            },
                                                            { label: 'Car', value: 'Car' },
                                                            { label: 'Van - 7FT', value: 'Van7FT' },
                                                            { label: 'Truck', value: 'Truck' },
                                                        ]}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'vehicleType')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="billingMethod">Billing Method</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.billingMethod)}
                                                        options={
                                                            formData.deliveryType === DELIVERY_TYPES.RETURN_ORDER
                                                                ? BILLING_METHOD_OPTIONS_RETURN_ORDER
                                                                : BILLING_METHOD_OPTIONS_DEFAULT
                                                        }
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'billingMethod')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="currency">Currency</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.currency)}
                                                        options={currencyCodes.map((currency) => ({
                                                            label: currency,
                                                            value: currency,
                                                        }))}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'currency')}
                                                        submitted={submitted}
                                                        required
                                                        isDisabled
                                                        validated={validated}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            {formData.billingMethod === 'Fixed Per Delivery' && (
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="fixedDeliveryRate">Fixed Delivery Rate</Label>
                                                        <AvField
                                                            name="fixedDeliveryRate"
                                                            placeholder="Delivery Rate"
                                                            type="number"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            validate={{
                                                                required: {
                                                                    value:
                                                                        formData.billingMethod === 'Fixed Per Delivery',
                                                                },
                                                            }}
                                                            id="fixedDeliveryRate"
                                                            value={formData.fixedDeliveryRate}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row>
                                            {formData.billingMethod === 'Percentage of Delivery Fee' && (
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="commission">Commission</Label>
                                                        <AvField
                                                            name="commission"
                                                            placeholder="Commission"
                                                            type="number"
                                                            errorMessage="Allow only two decimal places"
                                                            className="form-control"
                                                            step="0.01"
                                                            id="commission"
                                                            value={formData.commission}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            )}
                                        </Row>

                                        {formData.billingMethod === 'Mileage-Based Fee' && (
                                            <>
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="baseMileage">Base Mileage in KM</Label>
                                                            <AvField
                                                                name="baseMileage"
                                                                placeholder="Base Mileage (KM)"
                                                                type="number"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{
                                                                    required: {
                                                                        value:
                                                                            formData.billingMethod ===
                                                                            'Mileage-Based Fee',
                                                                    },
                                                                }}
                                                                id="baseMileage"
                                                                value={formData.baseMileage}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="baseMileageRate">Base Mileage Rate</Label>
                                                            <AvField
                                                                name="baseMileageRate"
                                                                placeholder="Base Mileage Rate"
                                                                type="number"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{
                                                                    required: {
                                                                        value:
                                                                            formData.billingMethod ===
                                                                            'Mileage-Based Fee',
                                                                    },
                                                                }}
                                                                id="baseMileageRate"
                                                                value={formData.baseMileageRate}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="additionalMileageSlab1">
                                                                Additional Mileage Slab 1 in KM
                                                            </Label>
                                                            <AvField
                                                                name="additionalMileageSlab1"
                                                                placeholder="Additional Mileage Slab 1"
                                                                type="number"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{
                                                                    required: {
                                                                        value:
                                                                            formData.billingMethod ===
                                                                            'Mileage-Based Fee',
                                                                    },
                                                                }}
                                                                id="additionalMileageSlab1"
                                                                value={formData.additionalMileageSlab1}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="additionalMileageSlab1Limit">
                                                                Additional Mileage Slab 1 Limit in KM
                                                            </Label>
                                                            <AvField
                                                                name="additionalMileageSlab1Limit"
                                                                placeholder="Additional Mileage Slab"
                                                                type="number"
                                                                errorMessage="Additional Mileage Limit should be greater than the Base Mileage"
                                                                className="form-control"
                                                                validate={{
                                                                    required: {
                                                                        value:
                                                                            formData.billingMethod ===
                                                                            'Mileage-Based Fee',
                                                                    },
                                                                    min: {
                                                                        value: parseInt(formData.baseMileage) + 1,
                                                                    },
                                                                }}
                                                                id="additionalMileageSlab1Limit"
                                                                value={formData.additionalMileageSlab1Limit}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="ratePerAdditionalMileage1">
                                                                Rate Per Additional Mileage Slab 1
                                                            </Label>
                                                            <AvField
                                                                name="ratePerAdditionalMileage1"
                                                                placeholder="Rate for Additional Mileage"
                                                                type="number"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{ required: { value: true } }}
                                                                id="ratePerAdditionalMileage1"
                                                                value={formData.ratePerAdditionalMileage1}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="additionalMileageSlab2">
                                                                Additional Mileage Slab 2 in KM
                                                            </Label>
                                                            <AvField
                                                                name="additionalMileageSlab2"
                                                                placeholder="Additional Mileage Slab"
                                                                type="number"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{
                                                                    required: {
                                                                        value:
                                                                            formData.billingMethod ===
                                                                            'Mileage-Based Fee',
                                                                    },
                                                                }}
                                                                id="additionalMileageSlab2"
                                                                value={formData.additionalMileageSlab2}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="ratePerAdditionalMileage2">
                                                                Rate Per Additional Mileage Slab 2
                                                            </Label>
                                                            <AvField
                                                                name="ratePerAdditionalMileage2"
                                                                placeholder="Additional Mileage Slab"
                                                                type="number"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{
                                                                    required: {
                                                                        value:
                                                                            formData.billingMethod ===
                                                                            'Mileage-Based Fee',
                                                                    },
                                                                }}
                                                                id="ratePerAdditionalMileage2"
                                                                value={formData.ratePerAdditionalMileage2}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="validFrom">Valid From</Label>
                                                    <Input
                                                        onChange={handleInputChange}
                                                        type="date"
                                                        className="form-control"
                                                        value={getDateValue(formData.validFrom, 'date')}
                                                        validate={{ required: { value: true } }}
                                                        id="validFrom"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="validTo">Valid To</Label>
                                                    <Input
                                                        onChange={handleInputChange}
                                                        type="date"
                                                        className="form-control"
                                                        value={getDateValue(formData.validTo, 'date')}
                                                        validate={{ required: { value: true } }}
                                                        id="validTo"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ justifyContent: 'flex-start' }}>
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                type="submit"
                                                disabled={formLoading}
                                            >
                                                {formLoading ? (
                                                    <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                ) : (
                                                    <>
                                                        <i className="fas fa-save left-icon"></i>
                                                        <span className="h6">
                                                            {editingMode ? 'Update' : 'Create Billing'}
                                                        </span>
                                                    </>
                                                )}
                                            </motion.button>
                                        </Row>
                                    </fieldset>
                                </AvForm>
                            </CardBody>
                        </Card>
                        <BackdropLoader show={backdropLoading} />
                    </Container>
                </div>
            </Hoc>
        </>
    );
}

BillingSettingFormPage.propTypes = {
    location: PropTypes.any,
};

const HotBillingSettingFormPage = hot(BillingSettingFormPage);

export default HotBillingSettingFormPage;
