import { CREATE_STOCKS_RECEIVING_ITEM } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Save Stocks Receiving Item Batch Service
 * @param {object} data
 * @returns {Promise}
 */
export const saveStocksReceivingItemBatchService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_STOCKS_RECEIVING_ITEM).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
