/* eslint-disable indent */

import moment from 'moment';

/* eslint-disable no-nested-ternary */
export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { zoneName } = form;
    if (!zoneName) {
        return { isFormValid, message };
    }

    isFormValid = true;
    return { isFormValid, message };
};

/**
 * This function is responsible for mapping data which came from the backend to the table list view
 */
export const mapTransactionTableDocs = (docs) => {
    let allDocs = [];
    allDocs = docs.map((doc) => ({
        ...doc,
        createdAt: moment(doc.createdAt).format('DD-MM-YYYY HH:mm a'),
        createdByUserFullName: `${doc.createdByUserFullName} - ${doc.createdByUser.username}`,
        transactionName: doc.name,
        details: doc.meta,
    }));
    return allDocs;
};
