export const constantAppsTableHeaders = [
    {
        key: 'type',
        value: 'APP TYPE',
    },
    {
        key: 'status',
        value: 'STATUS',
        type: 'statusLabel',
        params: { shape: 'rectangle', activeLabel: 'ACTIVE', inactiveLabel: 'DISCONNECTED' },
    },
    {
        key: 'storeName',
        value: 'APP NAME',
    },
    {
        key: 'reference',
        value: 'REFERENCE/STORE ID',
    },
    {
        key: 'createdAt',
        value: 'CONNECTED AT',
    },
    {
        key: 'createdBy',
        value: 'CONNECTED BY',
    },
    {
        key: 'lastAccessAt',
        value: 'LAST ACCESS AT',
    },
    {
        key: 'disconnectedAt',
        value: 'DISCONNECTED AT',
    },
    {
        key: 'action',
        value: 'ACTION',
        type: 'customActionButton',
        params: {
            buttonLabel: 'Disconnect',
            customActionHandlerName: 'handleDisconnectButton',
            btnBgColor: '#FD4A4A',
        },
    },
];
