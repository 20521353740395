import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Card, CardBody, Col, Container, Row, FormGroup, Label } from 'reactstrap';

// #region assets | components
import { BackdropLoader, CustomToast } from 'components';

// #region utils
/* eslint-disable react-hooks/exhaustive-deps */
import { apiErrorHandlerV2 } from 'utils/helpers';

// #endRegions imports
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import CustomizableButton from '../../../Api-Service/Dashboard/components/CustomizableButton';
import updateIcon from '../../../../../../assets/images/developer/updateIcon.svg';
import { updateApiRateLimitService } from '../../services';

const APIRatesLimits = () => {
    const [formData, setFormData] = useState({});

    const [formLoading, setFormLoading] = useState(false);

    const [loaded, setLoaded] = useState(false);

    const apiPartnerData = useSelector((state) => state.apiPartnerData);

    useEffect(() => {
        if (apiPartnerData?.merchantRecordData) {
            setLoaded(true);
            setFormData((prev) => ({ ...prev, apiRateLimit: apiPartnerData.merchantRecordData.payload.apiRateLimit }));
        } else {
            setLoaded(false);
        }
    }, [apiPartnerData?.merchantRecordData]);

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value,
        });
    };

    const handleSubmit = async (event) => {
        event.persist();

        setFormLoading(true);
        const payload = {
            apiRateLimit: formData.apiRateLimit,
            merchantId: apiPartnerData?.merchantRecordData?.payload?.merchantId,
        };
        try {
            const { data } = await updateApiRateLimitService(payload);
            if (data) {
                toast.custom(
                    (t) => <CustomToast text="Successfully Updated the API Rate Limit" t={t} type="success" />,
                    {
                        position: 'top-right',
                    }
                );
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setFormLoading(false);
    };

    return (
        <Container fluid>
            <Card style={{ borderRadius: 15, marginTop: 10 }}>
                {loaded ? (
                    <CardBody style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                        <div
                            style={{
                                backgroundColor: '#E7E4E4',
                                borderTopRightRadius: 15,
                                borderTopLeftRadius: 15,
                                minHeight: 20,
                                padding: 15,
                                fontFamily: 'inter',
                                alignItems: 'center',
                            }}
                        >
                            <div className="font-weight-bold font-size-16 ml-3" style={{ color: '#3A3937' }}>
                                API Rate Limits
                            </div>
                        </div>
                        <AvForm
                            className="needs-validation default-form"
                            onValidSubmit={handleSubmit}
                            id="ApiRateLimitForm"
                            autoComplete="off"
                        >
                            <Row
                                style={{
                                    marginTop: 10,
                                    marginLeft: 30,
                                    marginRight: 30,
                                    justifyContent: 'space-between',
                                }}
                            >
                                <input type="hidden" value="something" />
                                <fieldset disabled={formLoading} style={{ width: '100%' }}>
                                    <Row>
                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <FormGroup>
                                                <Label htmlFor="apiRateLimit">Maximum API request per hour</Label>
                                                <AvField
                                                    name="apiRateLimit"
                                                    type="number"
                                                    errorMessage="Enter the API Rate Limit"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="apiRateLimit"
                                                    value={formData.apiRateLimit || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                    style={{ backgroundColor: '#FCFCFC' }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </fieldset>
                            </Row>
                            <Row>
                                {hasAccess(PERMISSIONS.PARTNER_API_ADVANCED_SETTINGS, ['Edit']) && (
                                    <CustomizableButton
                                        buttonTitle="Update"
                                        icon={updateIcon}
                                        iconStyle={{ width: 24, height: 24 }}
                                        customStyle={{ marginLeft: 40 }}
                                        loading={formLoading}
                                    />
                                )}
                            </Row>
                        </AvForm>
                    </CardBody>
                ) : (
                    <BackdropLoader show opacity={0.8} />
                )}
            </Card>
        </Container>
    );
};

export default APIRatesLimits;
