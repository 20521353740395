import React from 'react';

// #region imports
import { AddDeliveryAttempt, CancelDelivery, MoreOption, UnassignDelivery } from 'components/Icons';
import CheckCircleIcon from 'components/Icons/Check-Circle';

export const transactionTableHeadersForAll = [
    {
        key: 'createdAt',
        value: 'TR. DATE AND TIME',
        type: 'dateTime',
    },
    {
        key: 'methodFormatted',
        value: 'TRANSACTION',
    },
    {
        key: 'reference',
        value: 'TR. REFERENCE',
    },
    {
        key: 'sequence',
        value: 'TR. ID',
    },
    {
        key: 'consoleUserFormatted',
        value: 'CONSOLE USER',
    },
    {
        key: 'driverFormatted',
        value: 'RIDER/DRIVER',
    },
    {
        key: 'remarks',
        value: 'REMARKS',
    },
];
export const transactionTableHeadersForMerchants = [
    {
        key: 'createdAt',
        value: 'TR. DATE AND TIME',
        type: 'dateTime',
    },
    {
        key: 'methodFormatted',
        value: 'TRANSACTION',
    },
    {
        key: 'reference',
        value: 'TR. REFERENCE',
    },
    {
        key: 'sequence',
        value: 'TR. ID',
    },
    {
        key: 'consoleUserFormatted',
        value: 'CONSOLE USER',
    },
];

export const attachmentTableHeaders = [
    {
        key: 'category',
        value: 'CATEGORY',
    },
    {
        key: 'type',
        value: 'TYPE',
    },
    {
        key: 'attachment',
        value: 'Attachment',
        type: 'attachmentViewBtn',
    },
    {
        key: 'text',
        value: 'TEXT',
    },
    {
        key: 'user',
        value: 'USER',
    },
    {
        key: 'userName',
        value: 'USERNAME/ RIDER ID',
    },
    {
        key: 'userType',
        value: 'USER TYPE',
    },
    {
        key: 'createdAt',
        value: 'UPLOADED AT',
    },
    {
        key: 'updatedAt',
        value: 'LAST UPDATED',
    },
];

export const attachmentArchiveTableHeaders = [
    {
        key: 'category',
        value: 'CATEGORY',
    },
    {
        key: 'type',
        value: 'TYPE',
    },
    {
        key: 'attachment',
        value: 'Attachment',
        type: 'attachmentViewBtn',
    },
    {
        key: 'text',
        value: 'TEXT',
    },
    {
        key: 'user',
        value: 'USER',
    },
    {
        key: 'userName',
        value: 'USERNAME/ RIDER ID',
    },
    {
        key: 'userType',
        value: 'USER TYPE',
    },
    {
        key: 'createdAt',
        value: 'UPLOADED AT',
    },
    {
        key: 'updatedAt',
        value: 'LAST UPDATED',
    },
    {
        key: '_id',
        value: 'Action',
        type: 'restoreButton',
    },
];

export const transactionInitialValues = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: transactionTableHeadersForAll,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};

export const deliveryAttachmentsInitialValues = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: attachmentTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};

export const transactionButtonData = [
    {
        type: 'completeDelivery',
        buttonName: 'Complete Delivery',
        icon: <CheckCircleIcon color="#FFFFFF" />,
        // permissionKey: PERMISSIONS.TRANSACTION_COMPLETE_DELIVERY,
        // permissionValue: PERMISSION_TYPES.complete,
    },
    {
        type: 'cancelDelivery',
        buttonName: 'Cancel Delivery',
        icon: <CancelDelivery />,
    },
    {
        type: 'unassignedDelivery',
        buttonName: 'Unassign Delivery',
        icon: <UnassignDelivery />,
    },
    {
        type: 'addDeliveryAttempt',
        buttonName: 'Add Delivery Attempt',
        icon: <AddDeliveryAttempt />,
    },

    {
        type: 'updateDeliveryType',
        buttonName: 'Update Delivery Type',
        icon: <AddDeliveryAttempt />,
    },
    {
        type: 'moreOptions',
        buttonName: 'More Options',
        icon: <MoreOption />,
    },
];
export const transactionButtonDataForCompleteDelivery = [
    {
        type: 'cancelDelivery',
        buttonName: 'Cancel Delivery',
        icon: <CancelDelivery />,
    },
    {
        type: 'unassignedDelivery',
        buttonName: 'Unassign Delivery',
        icon: <UnassignDelivery />,
    },
    {
        type: 'addDeliveryAttempt',
        buttonName: 'Add Delivery Attempt',
        icon: <AddDeliveryAttempt />,
    },

    {
        type: 'updateDeliveryType',
        buttonName: 'Update Delivery Type',
        icon: <AddDeliveryAttempt />,
    },
    {
        type: 'moreOptions',
        buttonName: 'More Options',
        icon: <MoreOption />,
    },
];
