/* eslint-disable camelcase */
export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { settings } = form;
    // if (!productCategory || !unitOfMeasure) {
    //     return { isFormValid, message };
    // }

    if (settings.deliveryImport?.isEnabled) {
        if (!settings.deliveryImport?.type) {
            return { isFormValid, message: 'Select Delivery Import Type' };
        }
    }

    isFormValid = true;
    return { isFormValid, message };
};

// eslint-disable-next-line consistent-return
export const buildLocationData = (id, idMeta, data) => {
    if (id === 'address') {
        if (data) {
            const { city = '', address = '', state = null, postal_code = '' } = data;
            return {
                city,
                state,
                [id]: `${address ? `${address}` : ''}`,
                [idMeta]: data,
                postalCode: postal_code || '',
            };
        }
        return {
            [id]: null,
            [idMeta]: null,
            city: '',
            state: '',
            postalCode: '',
        };
    }
};
