export const validateForm = (form) => {
    let isFormValid = false;
    let message = 'Fill all fields';

    const { userType } = form;
    if (!userType) {
        return { isFormValid, message };
    }

    if (form.userType && form.userType === 'Merchant') {
        const { merchantId } = form;
        if (!merchantId) {
            return { isFormValid, message: 'Select Merchant Id' };
        }
    }

    isFormValid = true;
    return { isFormValid, message };
};
