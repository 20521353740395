import * as React from 'react';
import PropTypes from 'prop-types';

function Finalize(props) {
    const { color, width, height } = props;
    return (
        <svg
            width={width || 24}
            height={height || 24}
            viewBox="0 2 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke={color || '#fff'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 12L11 14L15 10"
                stroke={color || '#fff'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
Finalize.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default Finalize;
