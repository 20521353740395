// @flow
import {
    UPDATE_BATCH_PRINT_DELIVERY_DATA,
    GET_PRINTER_SETTINGS_DATA,
    UPDATE_SELECTED_DELIVERIES,
    UPDATE_DELIVERY_FILTERS,
} from './actionTypes';

const INIT_STATE = {
    deliveryTableData: null,
    printerSettingsData: null,
    selectedDeliveries: [],
};

const batchPrintTableDataReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_BATCH_PRINT_DELIVERY_DATA:
            return {
                ...state,
                deliveryTableData: action.payload,
            };
        case GET_PRINTER_SETTINGS_DATA:
            return {
                ...state,
                printerSettingsData: action.payload,
            };
        case UPDATE_SELECTED_DELIVERIES:
            return {
                ...state,
                selectedDeliveries: action.payload,
            };
        case UPDATE_DELIVERY_FILTERS:
            return {
                ...state,
                deliveryFilters: action.payload,
            };
        default:
            return state;
    }
};

export default batchPrintTableDataReducer;
