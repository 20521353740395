/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import { motion } from 'framer-motion';
import moment from 'moment';
import toast from 'react-hot-toast';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { BackdropLoader, CustomToast, Select, TableCustom } from 'components';

// #region imports
import PATHS from 'routes/paths';
import AccessDenied from 'pages/Utility/AccessDenied';
import { SubTitle } from 'styles';

// #region services
import { getMerchantsByClientIdService } from 'services/merchant.service';

// #region utils
import { getVisitingObject, hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import {
    apiErrorHandlerV2,
    formatter,
    getDateValue,
    getDefaultValueForSelect,
    isObject,
    reactSelectCustomStyles,
} from 'utils/helpers';

// #endregion imports
import { defaultTableHeaders } from './constants';
import { generateReport } from '../services';
import { buildMerchantName, validateForm } from './utils';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    // { title: 'Payments', link: PATHS.billing.DEFAULT },
    { title: 'Billing', link: '#' },
];

/**
 * Billing Profile Page
 * @param {*} props
 * @returns {React.Component}
 */
function BillingManagementPage() {
    const history = useHistory();
    const location = useLocation();

    // component state
    const { visitingClient } = getVisitingObject();
    const [formLoading, setFormLoading] = useState(0);

    const [formData, setFormData] = useState({
        fromDate: moment().subtract(1, 'days').set({ hour: 23, minute: 59, second: 59 }),
        toDate: moment().set({ hour: 23, minute: 59, second: 59 }),
    });

    const [billingRecordMeta, setBillingRecordMeta] = useState({
        totalDeliveries: 0,
        totalPaidAmount: 0,
        totalUnpaidAmount: 0,
        totalAmount: 0,
    });
    const [merchants, setMerchants] = useState([]);

    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);

    const [backdropLoading] = useState(0);

    const [billingRecordData, setBillingsRecordData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }

        // Retrieve filter values from URL params
        const urlParams = new URLSearchParams(location.search);

        if (urlParams.size > 0) {
            // get url params as an object
            const queryParams = Object.fromEntries(urlParams.entries());

            // extract the 'page', 'limit' parameter and remove it from the object
            const { page, limit, ...formParams } = queryParams;

            // populate form from query param data
            setFormData(formParams);
            setBillingsRecordData({ page, limit, ...billingRecordData });

            const updatedBillingRecordData = {
                ...billingRecordData,
                page,
                limit,
            };

            const postData = {};
            postData.filters = formParams;

            // fetch billing records
            handleGenerateReport(updatedBillingRecordData, postData);
        }
    }, []);

    /**
     * Generate Summary of Payment and merchant payment paginated table data
     * @param {object} data - Billing record data
     * @returns Table data and sets the billingRecordMeta that holds the payment summary(statistical data)
     */
    const mapDocs = (data) => {
        const {
            docs,
            totalDeliveryFee: totalAmount = 0,
            totalMerchantPaidAmount: totalPaidAmount = 0,
            totalDeliveryCount: totalDeliveries = 0,
        } = data;

        let allDocs = [];
        const totalUnpaidAmount = totalAmount - totalPaidAmount;

        allDocs = docs.map((x) => {
            const balanceAmount = x.deliveryFee - x.merchantPaidAmount;
            let merchantName = x.merchantId?.name;
            const vehicleType = x.riderId?.vehicleType;

            let { referenceNumber, merchantOrderNo, pickupLocation, deliveryAddress, recipientName } = x;
            const merchantPaymentStatus = balanceAmount === 0 ? 'Paid' : 'Unpaid';
            // if Multi-Stop billing record(has more than one deliveries)
            if (x.deliveries && x.deliveries?.length > 1) {
                pickupLocation = 'Multiple';
                deliveryAddress = 'Multiple';
                recipientName = 'Multiple';

                // get merchant name or Multiple depending on merchants of the deliveries
                merchantName = buildMerchantName(x.deliveries);
                // get all delivery reference numbers as comma separated string
                referenceNumber = x.deliveries?.map((delivery) => delivery.referenceNumber).join(', ');
                // get all merchant order numbers as comma separated string
                merchantOrderNo = x.deliveries?.map((delivery) => delivery.merchantOrderNo).join(', ');
            }

            return {
                ...x,
                merchantPaymentStatus,
                referenceNumber,
                merchantName,
                merchantOrderNo,
                pickupLocation,
                deliveryAddress,
                recipientName,
                vehicleType,
                formattedDistance: `${x.distance} KM`,
                formattedDeliveryFee: `MYR ${formatter.format(x.deliveryFee.toFixed(2))}`,
                deliveryType: x.deliveryId && isObject(x.deliveryId) ? x.deliveryId.deliveryType : null,
            };
        });
        setBillingRecordMeta({
            totalDeliveries,
            totalAmount,
            totalPaidAmount,
            totalUnpaidAmount,
        });
        return allDocs;
    };

    const handleOnClickImportBillings = () => {
        history.push({
            pathname: `${PATHS.IMPORTS.BILLING_IMPORTS.DEFAULT}`,
        });
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;

        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleSelectChange = (event, id) => {
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleGenerateReport = async (updatedBillingRecordData = billingRecordData, filters = {}) => {
        try {
            const { data } = await generateReport(updatedBillingRecordData, filters);
            const docs = mapDocs(data);
            setBillingsRecordData({
                ...billingRecordData,
                ...data,
                docs,
            });
        } catch (error) {
            const { message: exception } = apiErrorHandlerV2(error);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    const handleSubmitGenerateReport = async (event, errors = []) => {
        setSubmitted(true);
        event.persist();

        if (errors.length > 0) {
            setValidated(true);
            return;
        }

        const { isFormValid } = validateForm(formData);
        if (!isFormValid) {
            return;
        }

        setFormLoading(true);

        const page = 1;
        const limit = 10;

        const updatedBillingRecordData = { ...billingRecordData, limit, page };
        setBillingsRecordData(updatedBillingRecordData);

        const postData = {};
        postData.filters = formData;

        // Convert the tableFilterOptions object to a URL-encoded query string
        const queryString = new URLSearchParams(
            Object.entries(formData).filter(([, value]) => value !== null) // remove pairs where value is null
        ).toString();
        // update url with filter values
        history.push({ search: queryString });

        await handleGenerateReport(updatedBillingRecordData, postData);

        setFormLoading(false);
        setValidated(false);
        setSubmitted(false);
    };

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    const handleEditRow = (e, row) => {
        // Open in same page
        history.push({
            pathname: `${PATHS.PAYMENTS.BILLING_RECORD}`,
            search: `?type=edit&id=${row._id}`,
            state: { billingRecordParentData: row },
        });
    };

    const handleViewRow = (e, row) => {
        history.push({
            pathname: `${PATHS.PAYMENTS.BILLING_RECORD}`,
            search: `?type=view`,
            state: { billingRecordParentData: row },
        });
    };

    /**
     * A callback function that is triggered when the user changes page number.
     * @param {number} pageNo - The new limit value (i.e. number of results per page).
     */
    const handlePageChange = async (pageNo) => {
        const updatedBillingRecordData = { ...billingRecordData, page: pageNo };

        const { page, limit } = updatedBillingRecordData;
        setBillingsRecordData(updatedBillingRecordData);

        // Convert the tableFilterOptions object to a URL-encoded query string
        const queryString = new URLSearchParams(
            Object.entries({ page, limit, ...formData }).filter(([, value]) => value !== null) // remove pairs where value is null
        ).toString();
        // update url with filter values
        history.push({ search: queryString });

        const postData = {};
        postData.filters = formData;

        await handleGenerateReport(updatedBillingRecordData, postData);
    };

    if (!hasAccess(PERMISSIONS.BILLING, ['View'])) {
        return <AccessDenied pageName="Billing " />;
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />

                    <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                        <CardBody>
                            <AvForm
                                // autoComplete="off"
                                // className="needs-validation default-form"
                                id="billingForm"
                                onSubmit={handleSubmitGenerateReport}
                            >
                                <Row>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="merchantId">Merchant</Label>
                                            <Select
                                                value={getDefaultValueForSelect(getMerchantSelect(formData.merchantId))}
                                                options={merchants}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                submitted={submitted}
                                                validated={validated}
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="searchFilter">Search Filter</Label>
                                            <Select
                                                value={getDefaultValueForSelect(formData.searchFilter)}
                                                options={[
                                                    { label: 'Order Reference', value: 'Order Reference' },
                                                    { label: 'Merchant Order Number', value: 'Merchant Order Number' },
                                                    { label: 'Delivery ID', value: 'Delivery ID' },
                                                    { label: 'Recipient Name', value: 'Recipient Name' },
                                                    { label: 'Billing Reference', value: 'Billing Reference' },
                                                ]}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'searchFilter')}
                                                submitted={submitted}
                                                validated={validated}
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                    {formData.searchFilter && (
                                        <Col md="2">
                                            <FormGroup>
                                                <Label htmlFor="searchBy">&nbsp;</Label>
                                                <AvField
                                                    name="searchBy"
                                                    placeholder="Search By"
                                                    type="text"
                                                    className="form-control"
                                                    id="searchBy"
                                                    value={formData.searchBy}
                                                    onChange={handleInputChange}
                                                    isClearable
                                                    validate={{ required: { value: true } }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    )}
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="fromDate">Delivery Date (FROM)</Label>
                                            <Input
                                                onChange={handleInputChange}
                                                type="datetime-local"
                                                className="form-control"
                                                value={getDateValue(formData.fromDate, 'datetime-local')}
                                                id="fromDate"
                                                min={getDateValue(moment().subtract('days', 30), 'datetime-local')}
                                                max={getDateValue(moment().subtract('days', 1), 'datetime-local')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="toDate">Delivery Date (TO)</Label>
                                            <Input
                                                onChange={handleInputChange}
                                                type="datetime-local"
                                                className="form-control"
                                                value={getDateValue(formData.toDate, 'datetime-local')}
                                                id="toDate"
                                                min={getDateValue(
                                                    moment(formData.fromDate).add('days', 1),
                                                    'datetime-local'
                                                )}
                                                max={getDateValue(moment().add('days', 1), 'datetime-local')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="billingMethod">Billing Method</Label>
                                            <Select
                                                value={getDefaultValueForSelect(formData.billingMethod)}
                                                options={[
                                                    {
                                                        label: 'Fixed Per Delivery',
                                                        value: 'Fixed Per Delivery',
                                                    },
                                                    { label: 'Mileage-Based Fee', value: 'Mileage-Based Fee' },
                                                    {
                                                        label: 'Percentage of Delivery Fee',
                                                        value: 'Percentage of Delivery Fee',
                                                    },
                                                ]}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'billingMethod')}
                                                submitted={submitted}
                                                isClearable
                                                validated={validated}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="ratePerAdditionalMileage">Payment Status</Label>
                                            <Select
                                                value={getDefaultValueForSelect(formData.merchantPaymentStatus)}
                                                options={[
                                                    { label: 'Due', value: 'Due' },
                                                    { label: 'Paid', value: 'Paid' },
                                                ]}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'merchantPaymentStatus')}
                                                submitted={submitted}
                                                validated={validated}
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ justifyContent: 'flex-start' }}>
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                        // onClick={}
                                        disabled={formLoading}
                                    >
                                        {formLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <i className="fas fa-save left-icon"></i>
                                                <span className="h6">Generate</span>
                                            </>
                                        )}
                                    </motion.button>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>

                    <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                        <CardBody>
                            <Row className="justify-content-between">
                                <SubTitle
                                    style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}
                                    className="ml-3 mt-2"
                                >
                                    Summary of Payments
                                </SubTitle>
                            </Row>

                            <Row className="mt-4">
                                <Col>
                                    <h6>Total Amount</h6>
                                    <h5>
                                        <b>{`MYR ${formatter.format(billingRecordMeta.totalAmount.toFixed(2))}`}</b>
                                    </h5>
                                </Col>
                                <Col>
                                    <h6>Total Deliveries</h6>
                                    <h5>
                                        <b>{`${billingRecordMeta.totalDeliveries}`}</b>
                                    </h5>
                                </Col>
                                <Col>
                                    <h6>Paid Amount</h6>
                                    <h5>
                                        <b>{`MYR ${formatter.format(billingRecordMeta.totalPaidAmount.toFixed(2))}`}</b>
                                    </h5>
                                </Col>
                                <Col>
                                    <h6>Unpaid Amount</h6>
                                    <h5>
                                        <b>{`MYR ${formatter.format(
                                            billingRecordMeta.totalUnpaidAmount.toFixed(2)
                                        )}`}</b>
                                    </h5>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Row className="px-2 mb-1">
                        {hasAccess(PERMISSIONS.MERCHANTS, ['Add']) && (
                            <div>
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => handleOnClickImportBillings()}
                                >
                                    <i className="fas fa-plus left-icon"></i>
                                    Upload Payment Details
                                </motion.div>
                            </div>
                        )}
                    </Row>

                    <Row>
                        <TableCustom
                            tableData={billingRecordData}
                            excelData={billingRecordData.docs}
                            onPageChange={handlePageChange}
                            handleView={handleViewRow}
                            loading={formLoading}
                            isExport
                            type="billingRecords"
                            exportModalHeader="Export Billing Records"
                            handleEdit={handleEditRow}
                            showEdit={hasAccess(PERMISSIONS.BILLING, ['Edit'])}
                            showView={false}
                            isFilter={false}
                            isSearch={false}
                        />
                    </Row>
                </Container>
            </div>
            <BackdropLoader show={backdropLoading} opacity={0.7} />
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

const HotBillingManagementPage = hot(BillingManagementPage);

export default connect(mapStateToProps, {})(HotBillingManagementPage);
