export const SYSTEM_DATA_TYPES = {
    PRODUCT_MASTER_DATA: {
        MODULE: 'PRODUCT_MASTER_DATA',
        TYPES: { UNIT_OF_MEASURE: 'UNIT_OF_MEASURE', PRODUCT_CATEGORY: 'PRODUCT_CATEGORY' },
    },
    STOCKS_RECEIVING: {
        MODULE: 'STOCKS_RECEIVING',
        TYPES: { STOCKS_FROM: 'STOCKS_FROM' },
    },
    TEMPLATES: {
        DELIVERY_IMPORT: {
            DELIVERY_IMPORT_MODEL: 'DELIVERY_IMPORT_MODEL',
        },
    },
    IMPORT: 'IMPORT',
    PRINTERS: 'PRINTERS',
    LABEL_PRINT: {
        SHIPPING_LABEL: 'SHIPPING_LABEL',
    },
    DELIVERY_TRANSACTION: 'DELIVERY_TRANSACTION',

    DELIVERY_TRANSACTION_REASONS: {
        DELIVERY_COMPLETE_REASON: 'DELIVERY_COMPLETE_REASON',
        DELIVERY_CANCEL_REASON: 'DELIVERY_CANCEL_REASON',
        DELIVERY_UNASSIGN_REASON: 'DELIVERY_UNASSIGN_REASON',
        UPDATE_DELIVERY_TYPE_REASON: 'UPDATE_DELIVERY_TYPE_REASON',
    },

    DELIVERY_ATTACHMENT_TYPES: {
        MODULE: 'DELIVERY_ATTACHMENT',
        TYPES: { ATTACHMENT_REASON: 'ATTACHMENT_REASON', NOTE_REASON: 'NOTE_REASON' },
    },
};

export const INVENTORY_LOG_TYPES = {
    PICKING_COMPLETED: 'PICKING_COMPLETED',
    STOCKS_RECEIVED: 'STOCKS_RECEIVED',
    STOCKS_ADJUSTMENT: 'STOCKS_ADJUSTMENT',
};

export const NOTIFICATION_VIEW_TYPES = {
    DELIVERY_UNASSIGNED: 'DELIVERY_UNASSIGNED',
    MERCHANT_REGISTERED: 'MERCHANT_REGISTERED',
    MERCHANT_BILLING_SETTING_NOT_FOUND: 'MERCHANT_BILLING_SETTING_NOT_FOUND',
    RIDER_COMMISSION_SETTING_NOT_FOUND: 'RIDER_COMMISSION_SETTING_NOT_FOUND',
    LABEL_PRINTING_SHIPPING_LABEL_BATCH_PRINT: 'LABEL_PRINTING_SHIPPING_LABEL_BATCH_PRINT',
};

export const BATCH_PRINT = {
    PRINTER_SETTINGS: {
        MODULE: 'SHIPPING_LABEL',
        TYPES: 'PRINTERS',
    },
};

export const TRANSACTION_METHODS = {
    DELIVERY_METHODS: {
        DELIVERY_CREATED: 'DELIVERY_CREATED',
        DELIVERY_CANCELLED: 'DELIVERY_CANCELLED',
        DELIVERY_IMPORT: 'DELIVERY_IMPORT',
        DELIVERY_CREATED_RETURN: 'DELIVERY_CREATED_RETURN',
        DELIVERY_RETURN_ORDER_BILLING_CREATED: 'DELIVERY_RETURN_ORDER_BILLING_CREATED',
        NEW_DELIVERY_ATTEMPT_CREATED: 'NEW_DELIVERY_ATTEMPT_CREATED',
        DELIVERY_RIDER_ASSIGNED: 'DELIVERY_RIDER_ASSIGNED',
        DELIVERY_RIDER_UNASSIGNED: 'DELIVERY_RIDER_UNASSIGNED',
        DELIVERY_TYPE_UPDATED: 'DELIVERY_TYPE_UPDATED',
        COMPLETE_DELIVERY: 'COMPLETE_DELIVERY',
        RIDER_ACCEPTED: 'RIDER_ACCEPTED',
        RIDER_PICKUP_STARTED: 'PICKUP_STARTED',
        RIDER_PICKED_UP: 'RIDER_PICKED_UP',
        DELIVERY_STARTED: 'DELIVERY_STARTED',
        ORDER_DELIVERED: 'ORDER_DELIVERED',
        POD_SUBMITTED_RIDER: 'POD_SUBMITTED_BY_RIDER',
        PICKUP_LOCATION_UPDATED: 'PICKUP_LOCATION_UPDATED',
        DELIVERY_LOCATION_UPDATED: 'DELIVERY_LOCATION_UPDATED',
        VEHICLE_TYPE_UPDATED: 'VEHICLE_TYPE_UPDATED',
        ROUTE_PLANNING_ENTRY_LINKED: 'ROUTE_PLANNING_ENTRY_LINKED',
        ROUTE_PLANNING_ENTRY_DETACHED: 'ROUTE_PLANNING_ENTRY_DETACHED',
    },
    BILLING_METHODS: {
        CREATE_BILLING_RECORD: 'CREATE_BILLING_RECORD',
    },
    DELIVERY_OTHER_TRANSACTION_METHODS: {
        PICKUP_LOCATION_UPDATED: 'PICKUP_LOCATION_UPDATED',
        DELIVERY_LOCATION_UPDATED: 'DELIVERY_LOCATION_UPDATED',
        VEHICLE_TYPE_UPDATED: 'VEHICLE_TYPE_UPDATED',
    },
    USER_TRANSACTION_METHODS: {
        USER_CREATED: 'USER_CREATED',
        USER_APPROVED: 'USER_APPROVED',
        USER_TOGGLED: 'USER_TOGGLED',
        USER_UPDATED: 'USER_UPDATED',
        USER_DELETED: 'USER_DELETED',
        USER_RESTORED: 'USER_RESTORED',
    },
    RIDER_TRANSACTION_METHODS: {
        APPROVE_RIDER: 'APPROVE_RIDER',
        TOGGLE_DISABLE_RIDER: 'TOGGLE_DISABLE_RIDER',
        RESTORE_RIDER: 'RESTORE_RIDER',
    },
    PARTNER_API_KEY: {
        CREATE_API_KEY: 'PARTNER_API_CREATE_API_KEY',
        UPDATE_API_KEY: 'PARTNER_API_UPDATE_API_KEY',
        ENABLE_API_KEY: 'PARTNER_API_ENABLE_API_KEY',
        DISABLE_API_KEY: 'PARTNER_API_DISABLE_API_KEY',
    },
    PARTNER_API_WEBHOOK: {
        CREATE_WEBHOOK: 'PARTNER_API_WEBHOOK_CREATED',
        ENABLE_WEBHOOK: 'PARTNER_API_WEBHOOK_ENABLED',
        DISABLE_WEBHOOK: 'PARTNER_API_WEBHOOK_DISABLED',
        UPDATE_WEBHOOK: 'PARTNER_API_WEBHOOK_UPDATED',
        CLIENT_API_KEY_UPDATED: 'PARTNER_API_CLIENT_API_KEY_UPDATED',
        AUTO_DISABLE_WEBHOOK: 'PARTNER_API_WEBHOOK_AUTO_DISABLED',
    },
    ROUTE_PLANNING: {
        ENTRY_CREATED: 'ROUTE_PLANNING_ENTRY_CREATED',
        ENTRY_CANCELLED: 'ROUTE_PLANNING_ENTRY_CANCELLED',
        RIDER_UPDATED: 'ROUTE_PLANNING_RIDER_UPDATED',
        DELIVERY_DATE_UPDATED: 'ROUTE_PLANNING_DELIVERY_DATE_UPDATED',
        DELIVERY_DETACHED: 'ROUTE_PLANNING_DELIVERY_DETACHED',
        DELIVERY_ADDED: 'ROUTE_PLANNING_DELIVERY_ADDED',
    },
    INVENTORY: {
        STOCK_RECEIVING: {
            GRN_CREATED: 'GRN_CREATED',
            GRN_UPDATED: 'GRN_UPDATED',
            GRN_CONFIRMED: 'GRN_CONFIRMED',
            GRN_REVERSED: 'GRN_REVERSED',
            GRN_DELETED: 'GRN_DELETED',
        },
        PRODUCT_MASTER_DATA: {
            PRODUCT_CREATED: 'PRODUCT_CREATED',
            PRODUCT_UPDATED: 'PRODUCT_UPDATED',
            PRODUCT_DELETED: 'PRODUCT_DELETED',
            PRODUCT_PHOTO_ADDED: 'PRODUCT_PHOTO_ADDED',
        },
        WAREHOUSE: {
            WAREHOUSE_CREATED: 'WAREHOUSE_CREATED',
            WAREHOUSE_UPDATED: 'WAREHOUSE_UPDATED',
        },
        ZONE: {
            ZONE_CREATED: 'ZONE_CREATED',
            ZONE_UPDATED: 'ZONE_UPDATED',
            ZONE_DELETED: 'ZONE_DELETED',
        },
        BIN: {
            BIN_CREATED: 'BIN_CREATED',
            BIN_UPDATED: 'BIN_UPDATED',
            BIN_DELETED: 'BIN_DELETED',
            LABEL_LOCATION_PRINTED: 'LABEL_LOCATION_PRINTED',
        },
    },
};

export const TRANSACTION_REFERENCE = {
    DELIVERY_TRANSACTION_TYPES: {
        DELIVERY_CREATED: 'DTR1',
        RIDER_ASSIGNED: 'DTR2',
        RIDER_UNASSIGNED: 'DTR11',
        DELIVERY_CANCELLED: 'DTR13',
        DELIVERY_CREATED_RETURN: 'DTR14',
        NEW_DELIVERY_ATTEMPT: 'DTR15',
        DELIVERY_COMPLETED: 'DTR16',
        DELIVERY_COMPLETED_CONSOLE: 'DTR9',
        RIDER_ACCEPTED: 'DTR12',
        PICKUP_STARTED: 'DTR3',
        PICKED_UP: 'DTR4',
        DELIVERY_STARTED: 'DTR5',
        DELIVERED: 'DTR6',
        POD_SUBMITTED_RIDER: 'DTR7',
        PICKUP_LOCATION_UPDATED: 'DTR17',
        DELIVERY_LOCATION_UPDATED: 'DTR18',
        VEHICLE_TYPE_UPDATED: 'DTR19',
        DELIVERY_TYPE_UPDATED: 'DTR20',
        ROUTE_PLANNING_ENTRY_LINKED: 'DTR21',
        ROUTE_PLANNING_ENTRY_DETACHED: 'DTR22',
    },
    BILLING_TRANSACTION_TYPES: {
        CREATE_BILLING_RECORD: 'FTR1',
        BILLING_RECORD_MODIFIED: 'FTR3',
    },
    PARTNER_API_KEY: {
        CREATE_PARTNER_API_KEY: 'API1001',
        ENABLE_PARTNER_API_KEY: 'API1002',
        DISABLE_PARTNER_API_KEY: 'API1003',
        UPDATE_PARTNER_API_KEY: 'API1004',
    },
    PARTNER_API_WEBHOOK: {
        CREATE_WEBHOOK: 'WHK001',
        ENABLE_WEBHOOK: 'WHK002',
        DISABLE_DISABLED: 'WHK003',
        UPDATE_WEBHOOK: 'WHK004',
        CLIENT_API_KEY_UPDATED: 'WHK005',
        AUTO_DISABLE_WEBHOOK: 'WHK006',
    },
    USER_MANAGEMENT: {
        USER_SIGN_UP: 'UMTR0001',
        USER_CREATED: 'UMTR0002',
        USER_VERIFIED: 'UMTR0003',
        USER_APPROVED: 'UMTR0004',
        ROLE_PERMISSION_UPDATED: 'UMTR0005',
        USER_PASSWORD_RESET: 'UMTR0006',
        USER_PASSWORD_RESET_SELF: 'UMTR0007',
        USER_PASSWORD_UPDATED: 'UMTR0008',
        USER_PROFILE_UPDATED: 'UMTR0009',
        USER_PROFILE_ENABLED: 'UMTR0010',
        USER_PROFILE_DISABLED: 'UMTR0011',
        USER_ACCOUNT_DELETED: 'UMTR0012',
        USER_ACCOUNT_RESTORED: 'UMTR0013',
        USER_ACCOUNT_PERMANENTLY_DELETED: 'UMTR0014',
        USER_REQUESTED_TO_DELETE_ACCOUNT: 'UMTR0015',
        USER_ACCOUNT_RESTORED_FROM_DELETED_USERS: 'UMTR0016',
    },
    RIDER_MANAGEMENT: {
        RIDER_SIGN_UP: 'RPTR0001',
        RIDER_REGISTRATION: 'RPTR0002',
        RIDER_VERIFIED: 'RPTR0003',
        RIDER_APPROVED: 'RPTR0004',
        NEW_RIDER_COMMISSION_SETTINGS_ADDED: 'RPTR0005',
        RIDER_PASSWORD_RESET_ADMIN: 'RPTR0006',
        RIDER_PASSWORD_RESET_SELF_RESET: 'RPTR0007',
        RIDER_PASSWORD_UPDATED_SELF: 'RPTR0008',
        RIDER_PROFILE_UPDATED_CONSOLE: 'RPTR0009',
        RIDER_PROFILE_ENABLED: 'RPTR0010',
        RIDER_PROFILE_DISABLED: 'RPTR0011',
        RIDER_ACCOUNT_DELETED: 'RPTR0012',
        RIDER_ACCOUNT_RESTORED: 'RPTR0013',
        RIDER_ACCOUNT_PERMANENTLY_DELETED: 'RPTR0014',
        RIDER_REQUESTED_TO_DELETE_ACCOUNT: 'RPTR0015',
        RIDER_COMMISSION_SETTINGS_UPDATED: 'RPTR0016',
        RIDER_ACCOUNT_RESTORED_FROM_DELETED_RIDERS: 'RPTR0017',
    },
    INVENTORY_TRANSACTION_TYPES: {
        STOCK_RECEIVING: {
            GRN_CREATED: 'GRNTR1',
            GRN_UPDATED: 'GRNTR2',
            GRN_CONFIRMED: 'GRNTR3',
            GRN_REVERSED: 'GRNTR4',
            GRN_DELETED: 'GRNTR5',
        },
        PRODUCT_MASTER_DATA: {
            PRODUCT_CREATED: 'PRMD1',
            PRODUCT_UPDATED: 'PRMD2',
            PRODUCT_DELETED: 'PRMD3',
            PRODUCT_PHOTO_ADDED: 'PRMD4',
        },
    },
};
