// #region other imports
import { TRANSACTION_REFERENCE as REFERENCES } from 'constants/enums';

const { DELIVERY_TRANSACTION_TYPES, BILLING_TRANSACTION_TYPES } = REFERENCES;

const {
    DELIVERED,
    DELIVERY_COMPLETED,
    DELIVERY_CREATED,
    RIDER_ASSIGNED,
    PICKED_UP,
    DELIVERY_STARTED,
    PICKUP_STARTED,
    POD_SUBMITTED_RIDER,
    RIDER_ACCEPTED,
} = DELIVERY_TRANSACTION_TYPES;
const { CREATE_BILLING_RECORD } = BILLING_TRANSACTION_TYPES;

export const allowedTransactionsReferences = [
    DELIVERED,
    RIDER_ACCEPTED,
    DELIVERY_COMPLETED,
    DELIVERY_CREATED,
    RIDER_ASSIGNED,
    PICKED_UP,
    DELIVERY_STARTED,
    PICKUP_STARTED,
    POD_SUBMITTED_RIDER,
    CREATE_BILLING_RECORD,
];
