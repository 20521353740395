/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, CardBody, CardHeader, Col, Modal, Row } from 'reactstrap';

// #region components
import CheckCircleIcon from 'components/Icons/Check-Circle';
import CrossCircleIcon from 'components/Icons/CrossCircle';
import { THEME_COLOR_2 } from 'theme';
import { WarningIcon } from 'components/Icons';

/** This is a general modals which prompts the warning for and showing passed header text and warning text */
function WarningModal(props) {
    const { show, toggleModal, title, text } = props;

    const handleModalToggle = () => {
        toggleModal();
    };

    return (
        <Modal className="modal-dialog modal-dialog-centered" isOpen={show}>
            <CardHeader
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: THEME_COLOR_2,
                    borderTopLeftRadius: '7px',
                    borderTopRightRadius: '7px',
                }}
            >
                <div className="d-flex text-white">{title}</div>
                <div className="ml-2 cursor-pointer" onClick={handleModalToggle} role="button">
                    <CrossCircleIcon color="#FFF" />
                </div>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col>
                        <Alert color="warning" style={{ borderRadius: 8 }}>
                            <Row>
                                <Col sm={2}>
                                    <div style={{ paddingTop: '30%' }}>
                                        <WarningIcon />
                                    </div>
                                </Col>
                                <Col sm={10}>{text}</Col>
                            </Row>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button style={{ borderRadius: 10 }} className="scootix-btn-radius" onClick={handleModalToggle}>
                            <CheckCircleIcon color="#FFF" />
                            &nbsp;I UNDERSTOOD
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </Modal>
    );
}

WarningModal.propTypes = {
    show: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default WarningModal;
