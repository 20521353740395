import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import './styles.css';

import PATHS from 'routes/paths';

export default function Header(props) {
    const { buttonIconRight } = props;
    const history = useHistory();

    const handleGoBack = () => {
        history.push({
            pathname: `/${PATHS.LABEL_PRINT.SHIPPING_LABEL.LIST_VIEW}`,
        });
    };

    return (
        <Row className="header_button_batch_print">
            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="right scootix-btn-radius m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                onClick={() => handleGoBack()}
            >
                {buttonIconRight}
                <div className="ml-2">Back to Prints</div>
            </motion.div>
        </Row>
    );
}

Header.propTypes = {
    buttonIconRight: PropTypes.object,
};
