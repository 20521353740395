/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { AlertModal, BackdropLoader, TableCustom } from 'components';

// #region imports
import { userProfileManagementListData } from 'mock/user-profile-management';
import PATHS from 'routes/paths';

// #region services
import { getMerchantsService } from 'services/merchant.service';

// #region utils
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import { deleteMerchantService } from '../Merchant-Form/services';
import { defaultTableHeaders } from '../../constants';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'User Management', link: PATHS.USER_PROFILE_MANAGEMENT.DEFAULT },
    { title: 'Merchant', link: '#' },
];

const IS_MOCK_DATA = false;

/**
 * Merchant Profile Page
 * @param {*} props
 * @returns {React.Component}
 */
function MerchantManagementListPage(props) {
    const history = useHistory();

    // component state
    const [loading, setLoading] = useState(0);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [merchantsListData, setMerchantsListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });
    const [searchBy, setSearchBy] = useState({ searchBy: '', searchText: null });
    const [showDeleteModal, setShowDeleteModal] = useState({ state: false, data: null });

    useEffect(() => {
        if (IS_MOCK_DATA) {
            setMerchantsListData({
                ...merchantsListData,
                docs: userProfileManagementListData,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadMerchantsListData(
            true,
            merchantsListData.all,
            1,
            merchantsListData.limit,
            merchantsListData.filter,
            merchantsListData.sort,
            merchantsListData.order,
            searchBy
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadMerchantsListData = (showLoading = true, all, page, limit, filter, sort, order, searchByProp) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getMerchantsService(all, page, limit, filter, sort, order, searchByProp, 'merchantId')
            .then((res) => {
                const { data } = res;
                if (data && data.docs) {
                    const docs = mapDocs(data.docs);
                    setMerchantsListData({
                        ...merchantsListData,
                        ...data,
                        docs,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const mapDocs = (docs) => {
        let allDocs = [];
        allDocs = docs.map((x) => ({
            ...x,
        }));
        return allDocs;
    };

    const handleOnClickNewMerchant = () => {
        history.push({
            pathname: `${PATHS.MERCHANT.MERCHANT_FORM}`,
            search: `?type=new`,
        });
    };

    const handlePageChange = (pageNo) => {
        loadMerchantsListData(
            true,
            merchantsListData.all,
            pageNo,
            merchantsListData.limit,
            merchantsListData.filter,
            merchantsListData.sort,
            merchantsListData.order,
            searchBy
        );
    };

    const handleFilter = (field, filterText) => {
        setSearchBy({ searchBy: field || 'name', searchText: filterText });
        loadMerchantsListData(
            false,
            merchantsListData.all,
            1,
            merchantsListData.limit,
            merchantsListData.filter,
            merchantsListData.sort,
            merchantsListData.order,
            { searchBy: field || 'name', searchText: filterText }
        );
    };

    const handleEditRow = (e, row) => {
        history.push({
            pathname: `${PATHS.MERCHANT.MERCHANT_FORM}`,
            search: `?type=edit&id=${row._id}`,
            state: { merchantParentData: row },
        });
    };

    const handleViewRow = (e, row) => {
        history.push({
            pathname: `${PATHS.MERCHANT.MERCHANT_FORM}`,
            search: `?type=view`,
            state: { merchantParentData: row },
        });
    };

    const handleOnDelete = (e, row) => {
        setShowDeleteModal({ state: true, data: row });
    };

    const handleDeleteMerchant = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteMerchantService(data._id);
            loadMerchantsListData(
                false,
                merchantsListData.all,
                1,
                merchantsListData.limit,
                merchantsListData.filter,
                merchantsListData.sort,
                merchantsListData.order
            );
            toast.success('Successfully Deleted');
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors && e.data.errors.userMessage) {
                toast.error(e.data.errors.userMessage);
            } else {
                toast.error('Something went wrong');
            }
        }
        setShowDeleteModal({ state: false, data: null });
        setBackdropLoading((prevState) => prevState - 1);
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        {hasAccess(PERMISSIONS.MERCHANTS, ['Add']) && (
                            <div>
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => handleOnClickNewMerchant()}
                                >
                                    <i className="fas fa-plus left-icon"></i>
                                    Create New Merchant
                                </motion.div>
                            </div>
                        )}
                    </Row>

                    <Row>
                        <TableCustom
                            tableData={merchantsListData}
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            handleView={handleViewRow}
                            loading={loading}
                            handleEdit={handleEditRow}
                            showEdit={hasAccess(PERMISSIONS.MERCHANTS, ['Edit'])}
                            // showDelete={shouldShowDeleteBtn()}
                            showDelete={false}
                            handleDelete={handleOnDelete}
                            showView={false}
                            filters={[
                                {
                                    label: 'Merchant Name',
                                    key: 'name',
                                },
                                {
                                    label: 'Reference Number',
                                    key: 'referenceNumber',
                                },
                            ]}
                            // isManualSearch
                        />
                    </Row>
                </Container>
            </div>

            <AlertModal
                show={showDeleteModal.state}
                onConfirm={handleDeleteMerchant}
                data={showDeleteModal.data}
                onHide={() =>
                    setShowDeleteModal({
                        state: false,
                        data: null,
                    })
                }
            />
            <BackdropLoader show={backdropLoading} opacity={0.7} />
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

const HotMerchantManagementPage = hot(MerchantManagementListPage);

export default connect(mapStateToProps, {})(HotMerchantManagementPage);
