/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { motion } from 'framer-motion';

// #region imports
import { SubTitle } from 'styles';
import { isObject } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import { defaultTableHeaders } from './constants';

function CycleCountReportComponent(props) {
    const { reportData } = props;
    const { ExportCSVButton } = CSVExport;

    const [allRows, setAllRows] = useState([]);

    useEffect(() => {
        if (reportData && reportData.docs && Array.isArray(reportData.docs)) {
            const allBinLocationIds = [];
            const mappedReportData = [];

            reportData.docs.map((val) => {
                if (val.binLocationId && isObject(val.binLocationId)) {
                    if (!allBinLocationIds.includes(val.binLocationId._id.toString())) {
                        allBinLocationIds.push(val.binLocationId._id.toString());
                    }
                }
                return val;
            });

            allBinLocationIds.map((binLocationId) => {
                const relevantProductStockData = reportData.docs.filter(
                    (x) => x.binLocationId._id.toString() === binLocationId
                );

                const productStockObject = {
                    currentStock: 0,
                    merchantName: '',
                    warehouseSiteName: '',
                    binLocationName: '',
                    binLocationAddress: '',
                    warehouseZoneName: '',
                    productDescription: '',
                    upc: '',
                    productMasterDataReferenceNumber: '',
                    skuCode: '',
                    minimumStockLevel: 0,
                    unitOfMeasure: '',
                };

                if (relevantProductStockData.length > 0) {
                    if (relevantProductStockData[0].merchantId && isObject(relevantProductStockData[0].merchantId)) {
                        productStockObject.merchantName = relevantProductStockData[0].merchantId.name || '';
                    }
                    if (
                        relevantProductStockData[0].binLocationId &&
                        isObject(relevantProductStockData[0].binLocationId)
                    ) {
                        productStockObject.binLocationName =
                            relevantProductStockData[0].binLocationId.binLocationName || '';
                        productStockObject.binLocationAddress =
                            relevantProductStockData[0].binLocationId.referenceNumber || '';
                    }
                    if (
                        relevantProductStockData[0].warehouseSiteId &&
                        isObject(relevantProductStockData[0].warehouseSiteId)
                    ) {
                        productStockObject.warehouseSiteName = relevantProductStockData[0].warehouseSiteId.siteName
                            ? `${relevantProductStockData[0].warehouseSiteId.referenceNumber || ''}-${
                                  relevantProductStockData[0].warehouseSiteId.siteName
                              }`
                            : '';
                    }
                    if (
                        relevantProductStockData[0].warehouseZoneId &&
                        isObject(relevantProductStockData[0].warehouseZoneId)
                    ) {
                        productStockObject.warehouseZoneName = relevantProductStockData[0].warehouseZoneId.zoneName
                            ? `${relevantProductStockData[0].warehouseZoneId.referenceNumber || ''}-${
                                  relevantProductStockData[0].warehouseZoneId.zoneName
                              }`
                            : '';
                    }
                    if (
                        relevantProductStockData[0].productMasterDataId &&
                        isObject(relevantProductStockData[0].productMasterDataId)
                    ) {
                        productStockObject.productDescription =
                            relevantProductStockData[0].productMasterDataId.productDescription || '';
                        productStockObject.productMasterDataReferenceNumber =
                            relevantProductStockData[0].productMasterDataId.referenceNumber || '';
                        productStockObject.upc = relevantProductStockData[0].productMasterDataId.upc || '';
                        productStockObject.skuCode = relevantProductStockData[0].productMasterDataId.skuCode || '';
                        productStockObject.minimumStockLevel =
                            relevantProductStockData[0].productMasterDataId.minimumStockLevel || 0;
                        productStockObject.unitOfMeasure =
                            relevantProductStockData[0].productMasterDataId.unitOfMeasure || '';
                    }
                }

                relevantProductStockData.map((stock) => {
                    productStockObject.currentStock += stock.quantity;
                    return stock;
                });

                mappedReportData.push(productStockObject);

                return binLocationId;
            });

            setAllRows(mappedReportData);
        }
    }, [reportData]);

    return (
        <>
            <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                <CardBody>
                    <div
                        style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'col' }}>
                            <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}>
                                Cycle Count Report
                            </SubTitle>
                        </div>
                    </div>
                    <hr />
                    <ToolkitProvider
                        keyField="_id"
                        data={allRows}
                        columns={defaultTableHeaders}
                        exportCSV={{
                            fileName: 'Cycle Count Report.csv',
                            // separator: '|',
                            // ignoreHeader: true,
                            // noAutoBOM: false,
                            // blobType: 'text/csv;charset=ansi',
                        }}
                    >
                        {(tableProps) => (
                            <div>
                                {allRows.length > 0 && (
                                    <Row className="d-flex justify-content-end">
                                        {hasAccess(PERMISSIONS.INVENTORY_REPORT, ['Export']) && (
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 pt-0 pb-0"
                                            >
                                                <ExportCSVButton {...tableProps.csvProps}>
                                                    {' '}
                                                    <span style={{ color: '#FFF' }}> Export</span>
                                                </ExportCSVButton>
                                            </motion.button>
                                        )}
                                    </Row>
                                )}

                                <hr />
                                <BootstrapTable {...tableProps.baseProps} />
                            </div>
                        )}
                    </ToolkitProvider>
                </CardBody>
            </Card>
        </>
    );
}

CycleCountReportComponent.propTypes = {
    reportData: PropTypes.any,
};

export default CycleCountReportComponent;
