/* eslint-disable indent */
import { SHIPPING_LABEL_PRINT_DATA } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Shipping Label List View Data
 * @param {object} data
 * @returns {Promise}
 */

export const getShippingLabelPrintData = ({
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    searchBy,
    searchByDate,
    isArchived = false,
}) =>
    new Promise(async function promiseCall(resolve, reject) {
        try {
            const buildQuery = {
                page,
                limit,
                sort: sort || 'createdAt',
                order,
                isArchived,
            };

            if (searchBy?.searchBy) buildQuery.searchBy = searchBy.searchBy;
            if (searchBy?.searchText) buildQuery.searchText = searchBy.searchText;
            if (searchByDate?.searchByDate) buildQuery.searchByDate = searchByDate.searchByDate;
            if (searchByDate?.searchDate) buildQuery.searchDate = searchByDate.searchDate;

            const url = UrlBuilder().setUrl(SHIPPING_LABEL_PRINT_DATA).setQueryParams(buildQuery).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
