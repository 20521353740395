import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// #region imports
import PATHS from 'routes/paths';
import { SOCKET_EVENTS } from 'constants/socket';
import { SubTitle } from 'styles';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';

// #region utils
// import { readNotification, storeNotification } from 'utils/local-storage';

// #region imports
import { getAllNotificationService } from 'services/notification.service';

// #endregion imports
import { BackdropLoader } from 'components';
import TabSubView from './components/tabs';
import ListSubView from './components/list';
import NotificationSubView from './components/view';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Notifications', link: PATHS.NOTIFICATION.DEFAULT },
];

function NotificationsView(props) {
    const { location } = props;

    const reduxState = useSelector((state) => state);

    const history = useHistory();

    // Component
    const [breadcrumbItems] = useState(initialBreadcrumbItems);
    const [socketClient, setSocketClient] = useState(null);
    const [backdropLoading, setBackdropLoading] = useState(0);

    // notifications states
    const [allNotifications, setAllNotifications] = useState([]);

    const [selectedNotification, setSelectedNotification] = useState(null);

    const [activeTab] = useState('all');

    useEffect(() => {
        loadNotificationsDb();
    }, []);

    useEffect(() => {
        try {
            if (location && location.state && location.state.notification) {
                if (location.state.notification._id) {
                    const findNotification = allNotifications.find((x) => x._id === location.state.notification._id);
                    if (findNotification) {
                        setSelectedNotification(findNotification);
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }, [location, allNotifications]);

    useEffect(() => {
        if (reduxState?.Global) {
            const { socket } = reduxState.Global;
            if (socket) {
                setSocketClient(socket);
            }
        }
    }, [reduxState.Global]);

    useEffect(() => {
        if (socketClient) {
            socketClient.on(SOCKET_EVENTS.CLIENT_USERS_NOTIFY, (res) => {
                if (res && res.doc) {
                    const { doc } = res;
                    setAllNotifications((prevState) => [doc, ...prevState]);
                }
            });
        }
    }, [socketClient]);

    // const handleReadNotification = (_id) => {
    //     readNotification(_id);
    // };

    const loadNotificationsDb = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getAllNotificationService();
            if (data && data.docs) {
                setAllNotifications(data.docs.reverse());
            }
        } catch (e) {
            console.log(e);
        }

        setBackdropLoading((prevState) => prevState - 1);
    };

    const commonProps = {
        setBackdropLoading,
    };

    return (
        <>
            <div className="page-content ">
                <Container fluid>
                    <Row className="d-flex justify-content-between pl-1">
                        <SubTitle style={{ color: '#574b90', letterSpacing: 0.5 }} className="ml-3">
                            SYSTEM NOTIFICATIONS
                        </SubTitle>
                        <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    </Row>
                    <br />
                    <Card style={{ borderRadius: 10, minHeight: '70vh' }}>
                        <CardBody>
                            <Row className="d-flex row justify-content-between p-2">
                                <Col md={2}>
                                    <TabSubView activeTab={activeTab} />
                                </Col>
                                <Col md={4}>
                                    <ListSubView
                                        allNotifications={allNotifications}
                                        selectedNotification={selectedNotification}
                                        setSelectedNotification={setSelectedNotification}
                                        history={history}
                                    />
                                </Col>
                                <Col md={6}>
                                    {selectedNotification && (
                                        <NotificationSubView
                                            selectedNotification={selectedNotification}
                                            {...commonProps}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

NotificationsView.propTypes = {
    location: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotNotificationsView = hot(NotificationsView);

export default connect(mapStateToProps, {})(HotNotificationsView);
