/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Row, Col, Alert, Button, Container, FormGroup, Label } from 'reactstrap';
import toast from 'react-hot-toast';

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Hoc from 'components/Hoc';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { removeStorageItems } from 'utils/checkAuth';

// action
import { forgetUser } from '../../../store/actions';

// import images
import { resetPasswordService } from './services';

function ResetPasswordPage(props) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordDoNotMatch, setPasswordDoNotMatch] = useState(null);
    const [loading, setLoading] = useState(false);

    const [token, setToken] = useState(null);

    useEffect(() => {
        removeStorageItems();
        const currentPagePathname = props.location.pathname;
        if (currentPagePathname) {
            let formatToken = currentPagePathname.replace('reset-password', '');
            formatToken = formatToken.replace(/\/+/g, '');
            setToken(formatToken);
        }
    }, []);

    // handleValidSubmit
    const handleSubmit = async (event, values) => {
        event.preventDefault();
        // eslint-disable-next-line no-shadow
        const { password, confirmPassword } = values;
        if (password !== confirmPassword) {
            setPasswordDoNotMatch('Password do not match');
            toast.error('Password do not match');
            return;
        }
        setPasswordDoNotMatch(null);
        setLoading(true);
        try {
            await resetPasswordService({ password, id: token });
            setPassword('');
            setConfirmPassword('');
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
            toast.success('Password reset successfully.');
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };

    return (
        <Hoc {...props}>
            <div className="home-btn d-none d-sm-block">
                <Link to="/">
                    <i className="mdi mdi-home-variant h2 text-white"></i>
                </Link>
            </div>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="auth-body w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        {/* <Link to="/" className="logo">
                                                            <img src={logodark} height="20" alt="logo" />
                                                        </Link> */}
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">Change Password</h4>
                                                    <p className="text-muted">Change your password to ScootiX.</p>
                                                </div>

                                                <div className="p-2 mt-5">
                                                    {passwordDoNotMatch && passwordDoNotMatch ? (
                                                        <Alert color="danger" className="mb-4">
                                                            {passwordDoNotMatch}
                                                        </Alert>
                                                    ) : null}
                                                    {props.message ? (
                                                        <Alert color="success" className="mb-4">
                                                            {props.message}
                                                        </Alert>
                                                    ) : null}
                                                    <AvForm
                                                        id="forget-pwd-form"
                                                        className="form-horizontal"
                                                        onValidSubmit={handleSubmit}
                                                    >
                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-mail-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="password">Password</Label>
                                                            <AvField
                                                                name="password"
                                                                value={password}
                                                                type="password"
                                                                validate={{ required: true }}
                                                                className="form-control"
                                                                id="password"
                                                                placeholder="Enter password"
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-mail-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="confirmPassword">Confirm Password</Label>
                                                            <AvField
                                                                name="confirmPassword"
                                                                value={confirmPassword}
                                                                type="password"
                                                                validate={{ required: true }}
                                                                className="form-control"
                                                                id="confirmPassword"
                                                                placeholder="Re enter password"
                                                            />
                                                        </FormGroup>

                                                        <div className="mt-4 text-center">
                                                            <Button
                                                                color="primary"
                                                                className="w-md waves-effect waves-light"
                                                                type="submit"
                                                            >
                                                                {loading ? 'Loading...' : 'Reset'}
                                                            </Button>
                                                        </div>
                                                    </AvForm>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p>
                                                        Don't have an account ?{' '}
                                                        <Link to="/login" className="font-weight-medium text-primary">
                                                            {' '}
                                                            Log in{' '}
                                                        </Link>{' '}
                                                    </p>
                                                    <p>© 2023 ScootiX. Developed By TASA Global Solutions Sdn Bhd</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <div className="bg-overlay"></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Hoc>
    );
}

const mapStatetoProps = (state) => {
    const { message, forgetError, loading } = state.Forget;
    return { message, forgetError, loading };
};

export default withRouter(connect(mapStatetoProps, { forgetUser })(ResetPasswordPage));
