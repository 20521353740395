import { deliveryTypeOptions } from 'pages/Modules/Delivery-Management/modules/Route-Planning/modules/Form-View/containers/Form-Options/constants';

export const addDeliveriesTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Delivery Id',
        type: 'viewDeliveryNavigation',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
        style: ['increase-width'],
        customHeaderStyles: {
            minWidth: 250,
        },
    },
    {
        key: 'orderReferenceNumber',
        value: 'Order Number',
    },
    {
        key: 'merchantOrderNo',
        value: 'Merchant Order Number',
    },
    {
        key: 'pickupLocation',
        value: 'Pickup Location',
    },
    {
        key: 'deliveryAddress',
        value: 'Delivery Location',
    },
];

export const deliveryListInitialValues = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: addDeliveriesTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};

export const addDeliveryTypeHeaders = [
    {
        key: 'label',
        value: 'value',
    },
];

export const deliveryTypesInitialValues = {
    totalDocs: 3,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: addDeliveryTypeHeaders,
    docs: deliveryTypeOptions,
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};

export const selectMerchantsHeaders = [
    {
        key: 'referenceNumber',
        value: 'Merchant ID',
    },
    {
        key: 'name',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
];

export const selectMerchantsInitialValues = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: selectMerchantsHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};
