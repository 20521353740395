import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

// #endregion imports
import { IS_MERCHANT_VIEW, hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import AccessDenied from 'pages/Utility/AccessDenied';
import RoutePlanningForm from './containers/Form-Options';
import RoutePlanningMapGrid from './containers/Delivery-Grid';
import RoutePlaningPageFormHeader from './containers/Form-Header';

// #endregion constants
import { RoutePlanningPagePropTypes } from '../../constants';

/**
 * This modal contains the both form and map grid for the route planning module
 * @param {RoutePlanningPagePropTypes} props
 */
function RoutePlaningPage() {
    /*
     * Base layout of the map and form
     */
    const [unplannedDeliveries, setUnplannedDeliveries] = useState([]);
    const [isPickupShowing, setIsPickupShowing] = useState(false);
    const [isDeliveryShowing, setIsDeliveryShowing] = useState(false);

    //* * callback function to get and set unplanned deliveries */
    const mapGridDeliveriesCallback = (deliveries) => {
        setUnplannedDeliveries(deliveries);
    };

    const pickupDeliverySetting = (isPickupLocationsShowing, isDeliveryLocationsShowing) => {
        setIsPickupShowing(isPickupLocationsShowing);
        setIsDeliveryShowing(isDeliveryLocationsShowing);
    };

    // not allow merchant users, allow client user that has `Add` role permission
    if (!hasAccess(PERMISSIONS.ROUTE_PLANNING, ['Add']) || IS_MERCHANT_VIEW()) {
        return <AccessDenied pageName="Route Planning" />;
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Card className="shadow default-card">
                        <RoutePlaningPageFormHeader />
                        <CardBody className="px-3">
                            <Row>
                                <Col sm={4}>
                                    <RoutePlanningForm
                                        mapGridDeliveries={mapGridDeliveriesCallback}
                                        pickupDeliverySetting={pickupDeliverySetting}
                                    />
                                </Col>
                                <Col sm={8}>
                                    <RoutePlanningMapGrid
                                        deliveries={unplannedDeliveries}
                                        isPickupShowing={isPickupShowing}
                                        isDeliveryShowing={isDeliveryShowing}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    );
}

RoutePlaningPage.propTypes = RoutePlanningPagePropTypes;

const HotRoutePlaningPage = hot(RoutePlaningPage);
export default HotRoutePlaningPage;
