export const billingSettingEmbeddedHeaders = [
    {
        key: 'clientName',
        value: 'Client',
    },
    {
        key: 'vehicleType',
        value: 'Vehicle Type',
        type: 'billingSettingVehicleType',
    },
    {
        key: 'deliveryType',
        value: 'Delivery Type',
    },
    {
        key: 'billingMethod',
        value: 'Billing Method',
    },
    {
        key: 'fixedDeliveryRate',
        value: 'Fixed Delivery Rate',
    },
    {
        key: 'baseMileage',
        value: 'Base Mileage (KM)',
    },
    {
        key: 'baseMileageRate',
        value: 'Base Mileage Rate',
    },
    {
        key: 'commission',
        value: 'Commission',
    },
    {
        key: 'validFrom',
        value: 'Valid From',
        type: 'dateTime',
    },
    {
        key: 'validTo',
        value: 'Valid To',
        type: 'dateTime',
    },
];
