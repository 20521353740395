import * as React from 'react';

function Delivered(props) {
    const { width, height, color } = props;

    return (
        <svg
            width={width || 30}
            height={height || 30}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3 19c3.333-2 5-4 5-6 0-3-1-3-2-3s-2.032 1.085-2 3c.034 2.048 1.658 2.877 2.5 4C8 19 9 19.5 10 18c.667-1 1.167-1.833 1.5-2.5 1 2.333 2.333 3.5 4 3.5H18M20 17V5c0-1.121-.879-2-2-2s-2 .879-2 2v12l2 2 2-2zM16 7h4"
                stroke={color || '#fff'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Delivered;
