import * as React from 'react';
import PropTypes from 'prop-types';

function SelectedDeliveryModalIcon(props) {
    const { color = '#FFF', width = 24, height = 24 } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 8L8 12L12 16M16 12H9"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
SelectedDeliveryModalIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.any,
    height: PropTypes.any,
};

export default SelectedDeliveryModalIcon;
