/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { hot } from 'react-hot-loader/root';
import { toast } from 'react-hot-toast';

// #region components
import { CustomToast } from 'components';

// #region services
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';
import { getRoutePlanningIds, getRoutePlanningListData } from 'services/routePlanning/routePlanning.service';

// #region utils
import { apiErrorHandlerV2, getDefaultValueForSelect } from 'utils/helpers';
import { IS_MERCHANT_VIEW, getVisitingObject } from 'utils/checkAuth';

// #region other imports
import PATHS from 'routes/paths';

// #endregion imports
import AccessDenied from 'pages/Utility/AccessDenied';
import { initialRouteListTableData } from './constants';
import RoutePlanningListView from './ListView';
import { mapRoutePlanningEntryTableDocs } from './utils';
import { getRiderDataByFilters } from '../Form-View/containers/Delivery-Grid/Modal/AssignRider-Modal/services';

/**
 * This is Route Planning Entries section where we can see the route plannings with more details
 * @returns the RoutePlanning List-view
 */
function RoutePlaningEntries() {
    const history = useHistory();
    const location = useLocation();

    const routePlanIdRef = useRef(null);

    // Get the visiting client
    const { visitingClient } = getVisitingObject();
    // Route List Table Data
    const [routeListTableData, setRouteListTableData] = useState(initialRouteListTableData);
    // Merchants state for the dropdown
    const [merchants, setMerchants] = useState([]);
    // Table Filter objects
    const [tableFilterOptions, setTableFilterOptions] = useState({});

    // FormData
    const [formData, setFormData] = useState({});
    // Route planning IDs
    const [routePlanningIds, setRoutePlanningIds] = useState([]);
    // Rider list data
    const [riderListData, setRiderListData] = useState([]);

    // Loadings for rider, merchant, routing requests and table list view
    const [riderLoading, setRiderLoading] = useState(false);
    const [merchantLoading, setMerchantLoading] = useState(false);
    const [routingRequestLoading, setRoutingRequestLoading] = useState(false);
    const [routePlanningListLoading, setRoutePlanningListLoading] = useState(false);
    // is route planning id field cleared
    const [fieldCleared, setFieldCleared] = useState('');

    useEffect(() => {
        // Retrieve filter values from URL params
        const urlParams = new URLSearchParams(location.search);

        if (urlParams.size > 0) {
            // get url params as an object
            const queryParams = Object.fromEntries(urlParams.entries());

            setTableFilterOptions(queryParams);
            // Fetch all the route planning list view data with pagination at the initial page load
            getRoutePlanningListViewData(queryParams);

            queryParams[fieldCleared] = null;
            if (queryParams.routePlanIdLabel) {
                loadRoutePlanIdsOptions(queryParams.routePlanIdLabel, null, { page: 1 });
            }
        } else {
            getRoutePlanningListViewData();
        }
        // Merchant Handlers
        getVisitingUser();
    }, [formData.riderId]);

    /**
     * This function is responsible for fetching route planning list data to the table
     */
    const getRoutePlanningListViewData = async (params) => {
        setRoutePlanningListLoading(true);
        try {
            const routePlanningList = await getRoutePlanningListData(params);
            const { data } = routePlanningList;
            if (data.docs) {
                const docs = mapRoutePlanningEntryTableDocs(data.docs || []);
                setRouteListTableData({ ...routeListTableData, ...data, docs });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setRoutePlanningListLoading(false);
    };

    /**
     * This function is responsible to get merchants to the filters
     */
    const getVisitingUser = async () => {
        setMerchantLoading(true);
        try {
            if (visitingClient && visitingClient._id) {
                const merchantList = await getMerchantsByClientIdService(visitingClient._id);
                const { data } = merchantList;
                if (data.docs) {
                    const merchantArr = data.docs.map((x) => ({ ...x, label: x.name, value: x._id }));
                    merchantArr.push({ label: 'Various', value: 'various' });
                    setMerchants(merchantArr);
                }
            } else {
                const merchantList = await getAllMerchantsService();
                const { data } = merchantList;
                if (data.docs) {
                    const merchantArr = data.docs.map((x) => ({ ...x, label: x.name, value: x._id }));
                    merchantArr.push({ label: 'Various', value: 'various' });
                    setMerchants(merchantArr);
                }
            }
            setMerchantLoading(false);
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
            setMerchantLoading(false);
        }
    };

    /**
     * This function is responsible for fetching route planning ids with pagination
     */
    const loadRoutePlanIdsOptions = async (search, loadedOptions, { page }) => {
        // the data only loading only if user typing
        if (!search) {
            return { options: [] };
        }
        setRoutingRequestLoading(true);
        const { data } = await getRoutePlanningIds({
            search,
            limit: 10,
            all: '0',
            page,
            tableFilterOptions,
        });
        // route plan ids and sequenceId pair
        const routePlanIdsAndLabels = data?.docs.map((x) => ({ label: x.sequenceId, value: x._id }));

        const { docs, totalPages = 0 } = data;
        const hasMore = totalPages > page;
        if (docs && Array.isArray(docs)) {
            setRoutePlanningIds([...routePlanningIds, ...docs.map((x) => ({ label: x.sequenceId, value: x._id }))]);
            setRoutingRequestLoading(false);
        }

        return {
            options: routePlanIdsAndLabels,
            hasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    /**
     * This function is responsible for fetching riders with pagination
     */
    const loadRidersOptions = async (search, loadedOptions, { page }) => {
        setRiderLoading(true);
        const response = await getRiderDataByFilters({
            search,
            all: '1',
            ignoreIsLimited: '1',
            limit: 10,
            page,
        });
        const data = response.data.docs.map((x) => ({
            label: `${x.fullName} - ${x.referenceNumber} - ${x.mobileNo}`,
            value: x._id,
        }));
        const hasMore = response.data?.totalPages > page;
        setRiderListData([...riderListData, ...data]);
        setRiderLoading(false);
        return {
            options: data,
            hasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    // back button functionality
    const handleBackPress = () => {
        history.goBack();
    };

    // Form Rider Selectors
    const getRiderSelect = (_id) => {
        if (_id) {
            const relevantObj = riderListData.find((x) => x.value === _id);
            if (relevantObj) {
                return relevantObj.label;
            }
        }
        return null;
    };

    // Form Merchant Selectors
    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        const variousMerchants = merchants.find((x) => x.value === _id);

        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        if (variousMerchants) {
            return variousMerchants.label;
        }
        return null;
    };

    // Form Route Plan ID Selectors
    const getRoutePlanningIdSelect = (_id) => {
        const relevantRoutePlanning = routePlanningIds.find((x) => x.value === _id);
        if (relevantRoutePlanning) {
            return relevantRoutePlanning.label;
        }
        return null;
    };

    const handleDateChange = (event) => {
        const { id, value } = event.target;
        setTableFilterOptions({
            ...tableFilterOptions,
            [id]: value ? new Date(value).toISOString() : null,
        });
        setFormData({
            ...formData,
            [id]: event ? value : null,
        });
        if (!value) {
            showNotificationWhenClear();
        }
    };

    const handleSelectChange = (event, id) => {
        if (event?.value) {
            setFieldCleared('');
            setTableFilterOptions({
                ...tableFilterOptions,
                [id]: event.value,
            });
        } else {
            // set cleared field
            setFieldCleared(id);
            setTableFilterOptions({
                ...tableFilterOptions,
                [id]: null,
            });
            showNotificationWhenClear();
        }
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    /**  Checkbox state handler */
    const handleCheckChange = (event) => {
        const { id, checked } = event.target;
        if (checked) {
            setTableFilterOptions({
                ...tableFilterOptions,
                [id]: true,
            });
        } else {
            setTableFilterOptions({
                ...tableFilterOptions,
                [id]: false,
            });
            showNotificationWhenClear();
        }
        setFormData({
            ...formData,
            [id]: checked,
        });
    };

    const showNotificationWhenClear = () => {
        toast.custom(
            (t) => (
                <CustomToast
                    text="Once You Add or Clear the Filters, Clicked on Filter Request Button Always "
                    t={t}
                    type="info"
                />
            ),
            {
                position: 'top-right',
            }
        );
    };

    const handleSubmit = () => {
        const formattedFilterOptions = { ...tableFilterOptions };
        if (selectedRoutePlanningId?.label) {
            // add route plan id label if route plan is selected
            formattedFilterOptions.routePlanIdLabel = selectedRoutePlanningId?.label;
        } else {
            // if route plan id is not selected need to be null, later null will be removed
            formattedFilterOptions.routePlanIdLabel = null;
        }
        if (!selectedRider?.label) {
            formattedFilterOptions.riderId = null;
        }
        // Convert the tableFilterOptions object to a URL-encoded query string
        const queryString = new URLSearchParams(
            Object.entries(formattedFilterOptions).filter(([, value]) => value !== null) // remove pairs where value is null
        ).toString();
        // update url with filter values
        history.push({ search: queryString });

        getRoutePlanningListViewData(tableFilterOptions);
    };

    const handlePageChange = (pageNo) => {
        getRoutePlanningListViewData({ ...routeListTableData, ...tableFilterOptions, page: pageNo });
    };

    /** Handles the custom action column, which is the 'Manage' button on the last column */
    const navigateToViewRoutePlan = (row) => {
        history.push({
            pathname: `/${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_ENTRY_VIEW}`,
            search: `?type=edit&id=${row._id}`,
            state: { ...row },
        });
    };

    /** Memos */
    const selectedRider = useMemo(() => {
        if (fieldCleared === 'riderId') {
            return getDefaultValueForSelect(getRiderSelect());
        }
        return getDefaultValueForSelect(getRiderSelect(tableFilterOptions.riderId));
    }, [tableFilterOptions.riderId, riderListData]);

    const selectedRoutePlanningId = useMemo(() => {
        if (tableFilterOptions.routePlanIdLabel && fieldCleared !== 'routePlanId') {
            return getDefaultValueForSelect(tableFilterOptions.routePlanIdLabel);
        }
        const x = getDefaultValueForSelect(getRoutePlanningIdSelect(tableFilterOptions.routePlanId));
        return x;
    }, [tableFilterOptions.routePlanId, tableFilterOptions.routePlanIdLabel, routePlanningIds]);

    /** All common props for RoutePlaningFormBody page */
    const commonProps = {
        formData,
        riderLoading,
        merchantLoading,
        routingRequestLoading,
        routePlanningListLoading,
        tableFilterOptions,
        merchants,
        riderListData,
        routeListTableData,
        routePlanningIds,
        handleSelectChange,
        handleCheckChange,
        handleDateChange,
        handleBackPress,
        handleSubmit,
        getMerchantSelect,
        getRiderSelect,
        getRoutePlanningIdSelect,
        loadRoutePlanIdsOptions,
        navigateToViewRoutePlan,
        handlePageChange,
        loadRidersOptions,
        selectedRider,
        getRoutePlanningListViewData,
        selectedRoutePlanningId,
        routePlanIdRef,
    };

    // not allow merchant users
    if (IS_MERCHANT_VIEW()) {
        return <AccessDenied pageName="Route Planning" />;
    }

    return <RoutePlanningListView {...commonProps} />;
}

const HotRoutePlaningEntries = hot(RoutePlaningEntries);
export default HotRoutePlaningEntries;
