export const initialScootixModalStateValues = {
    show: false,
    title: null,
    message: 'Are you sure?',
    confirmMessage: 'Accept',
    cancelMessage: 'Cancel',
    onConfirm: null,
    onCancel: null,
    data: null,
};
