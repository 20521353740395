export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Scootix Product Code',
    },
    {
        key: 'productDescription',
        value: 'Product Description',
    },
    {
        key: 'merchantProductCode',
        value: 'Merchant Product Code',
    },
    {
        key: 'takeawayMethod',
        value: 'Take Away Method',
    },
    {
        key: 'skuCode',
        value: 'SKU Code',
    },
    {
        key: 'unitOfMeasure',
        value: 'Unit Of Measure',
    },
    {
        key: 'upc',
        value: 'UPC',
    },
    {
        key: 'uan',
        value: 'UAN',
    },
    {
        key: 'minimumStockLevel',
        value: 'Minimum stock level',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
];

export const transactionProductTableHeaders = [
    {
        key: 'sequence',
        value: 'TR. ID',
    },
    {
        key: 'reference',
        value: 'TR. REFERENCE',
    },
    {
        key: 'description',
        value: 'TRANSACTION',
    },
    {
        key: 'transactionMadeByUserUsername',
        value: 'USER',
    },
    {
        key: 'createdAt',
        value: 'TR. DATE AND TIME',
        type: 'dateTime',
    },
    {
        key: 'action',
        value: 'DETAILS',
        type: 'customActionButton',
        params: {
            buttonLabel: 'View',
            customActionHandlerName: 'displayModal',
        },
    },
];
