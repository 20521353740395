import { partnerApiWebhookTransactionMapData } from 'helpers/transactions';
import moment from 'moment';
import { capitalizeFirstLetter } from 'utils/helpers';
import { WEBHOOK_EVENT_TYPES } from '../constants';

/**
 * Change values in the service response to work with table component
 * @param {array} docs - array of docs returned from service
 * @returns {array} array of formatted docs
 */
export const mapDocs = (docs) => {
    let allDocs = [];
    allDocs = docs.map((doc) => ({
        ...doc,
        createdAt: moment(doc.createdAt).format('DD-MM-YYYY hh:mm A'),
        isActiveString: doc.isActive ? 'active' : 'inactive',
    }));
    return allDocs;
};

export const mapWebhookTransactions = (records, formData) => {
    let allDocs = [];
    const webhookData = formData;
    if (records && Array.isArray(records)) {
        allDocs = records.map((transaction) => ({
            ...transaction,
            createdAt: moment(transaction.createdAt).format('DD-MM-YYYY hh:mm A'),
            consoleUserFormatted: partnerApiWebhookTransactionMapData(transaction.method, {
                ...transaction,
                webhookData,
            }).consoleUserFormatted,
        }));
    }
    return allDocs;
};

export const mapWebhookEventsTableDocs = (docs) => {
    let allDocs = [];
    allDocs = docs.map((doc) => ({
        ...doc,
        // actionPath: getActionPathLabel(doc.actionPath || ''),
        eventType:
            doc.eventType === WEBHOOK_EVENT_TYPES.RIDER_ASSIGNED
                ? 'Rider Assigned'
                : doc.eventType === WEBHOOK_EVENT_TYPES.RIDER_UNASSIGNED
                ? 'Rider Unassigned'
                : doc.eventType === WEBHOOK_EVENT_TYPES.PICK_UP_STARTED
                ? 'Pickup Started'
                : doc.eventType === WEBHOOK_EVENT_TYPES.PICKED_UP
                ? 'Picked Up'
                : doc.eventType === WEBHOOK_EVENT_TYPES.DELIVERY_STARTED
                ? 'Delivery Started'
                : doc.eventType === WEBHOOK_EVENT_TYPES.DELIVERED
                ? 'Delivered'
                : doc.eventType === WEBHOOK_EVENT_TYPES.POD_RECEIVED
                ? 'Proof of Delivery Received'
                : doc.eventType === WEBHOOK_EVENT_TYPES.CANCELLED
                ? 'Delivery Cancelled'
                : doc.eventType === WEBHOOK_EVENT_TYPES.COMPLETED
                ? 'Delivery Completed'
                : doc.eventType === WEBHOOK_EVENT_TYPES.NEW_DELIVERY_ATTEMPT_CREATED
                ? 'New Delivery Attempt Created'
                : '',
        responseStatus:
            doc.responseStatus === 'fail'
                ? `Failed - ${doc.statusCode || ''}`
                : capitalizeFirstLetter(doc.responseStatus),
    }));
    return allDocs;
};
