/* eslint-disable indent */
import {
    GET_ALL_BILLING_RECORDS,
    GET_BILLING_RECORD_BY_ID,
    UPDATE_BILLING_RECORD,
    RECALCULATE_BILLING_RECORD_BY_ID,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get BillingRecords Service
 * @returns {object}
 */
export const getBillingRecordsService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_ALL_BILLING_RECORDS}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

export const updateBillingRecordService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_BILLING_RECORD).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All BillingRecords
 * @returns {Promise}
 */
export const getAllBillingRecordsService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_BILLING_RECORDS).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Billing Record By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getBillingRecordByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_BILLING_RECORD_BY_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Recalculate Billing Record
 * @param {string} id
 * @returns {Promise}
 */

export const recalculateBillingRecord = (id) => {
    try {
        const url = UrlBuilder().setUrl(RECALCULATE_BILLING_RECORD_BY_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
