import {
    GET_BATCH_PRINT_DELIVERIES_BY_FILTER,
    GET_MERCHANTS_LOCATIONS_LIST_DATA,
    GET_RIDERS_LIST_DATA,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Generate Print Deliveries by filter
 * @param {object} data
 * @returns {Promise}
 */
export const getBatchPrintDeliveriesByFilter = (data) => {
    try {
        const url = UrlBuilder().setUrl(GET_BATCH_PRINT_DELIVERIES_BY_FILTER).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getMerchantsLocationList = (filters) =>
    new Promise(async function promiseCall(resolve, reject) {
        try {
            const url = UrlBuilder().setUrl(GET_MERCHANTS_LOCATIONS_LIST_DATA).setQueryParams(filters).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });

export const getRiderListData = (filters) =>
    new Promise(async function promiseCall(resolve, reject) {
        try {
            const url = UrlBuilder().setUrl(GET_RIDERS_LIST_DATA).setQueryParams(filters).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
