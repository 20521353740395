/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { FeatureGroup, MapContainer, TileLayer } from 'react-leaflet';
import { motion } from 'framer-motion';
import { EditControl } from 'react-leaflet-draw';
import { Spinner } from 'reactstrap';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet-gesture-handling';
import '../../styles/index.scss';

// #region assets | components
import SelectedDeliveryModalIcon from 'components/Icons/SelectedDeliveryModal';

// #region utils
import { getMapCenterCoordinatesByClient } from 'utils/maps';

// #endregion components
import SelectedDeliveriesSliderModal from './Modal/selectedDelivery-Modal';
import CustomMarker from '../../../../components/CustomMarker';
import GenerateMapLoadingModal from './Modal/loading-Modal';

// #endregion utils
import { mapOrders } from '../../../../utils';

// #endregion constants
import { ATTRIBUTION, GOOGLE_MAPS_URL, RoutePlanningMapGridPropTypes } from '../../../../constants';

/**
 * This modal contains the  map grid of the route planning module
 * @param {RoutePlanningMapGridPropTypes} props
 */
function RoutePlanningMapGrid(props) {
    const { isPickupShowing, isDeliveryShowing, deliveries } = props;

    const featureGroupRef = useRef();
    const _isMounted = useRef(true);

    // map reference
    const mapRef = useRef(null);

    // reference to the initial orders which is coming from parent component
    const [initialOrders, setInitialOrders] = useState([]);

    // reference to the orders which displays in the map with markers
    const [orders, setOrders] = useState([]);

    /** array of deliveries which we are selecting on the map */
    const [selectedOrders, setSelectedOrders] = useState([]);

    /** array of deliveries which we are deselecting from the table */
    const [deselectedOrders, setDeselectedOrders] = useState([]);

    /** the boundaries of the markers in the map */
    const [bound, setBound] = useState([]);

    /** the initial center coordinates of the the map */
    const [centerCoordinate, setCenterCoordinate] = useState(null);

    /** the boundaries of the rectangle of drag and select */
    const [dragSelectBound, setDragSelectBound] = useState();

    /** Modal state */
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    /** once deliveries are changed set orders also changed */
    useEffect(() => {
        // Here mapOrders function is responsible for mapping delivery orders to presentable manner for multiple pickups and delivery
        // And this returns object with two arrays of arrays named "pickupMappedObjectArray" and "deliveryMappedObjectArray"
        const mappedOrders = mapOrders(deliveries);
        setInitialOrders(deliveries);
        setOrders(mappedOrders);

        // clear the selected delivery list when start over
        if (deliveries.length === 0) {
            setSelectedOrders([]);
        }
    }, [deliveries]);

    /** once deliveries are changed set the center or the boundaries of the map dynamically */
    useEffect(() => {
        if (deliveries.length > 0) {
            let bounds = null;
            setLoading(true); // set the loading modal
            // create a array of coordinates of the deliveries
            const pickupMarkerCoordinates = deliveries.map((order) => [
                order.pickupLocationMeta.coordinates.lat,
                order.pickupLocationMeta.coordinates.lng,
            ]);
            const deliveryMarkerCoordinates = deliveries.map((order) => [
                order.deliveryAddressMeta.coordinates.lat,
                order.deliveryAddressMeta.coordinates.lng,
            ]);
            // based on the users choice of isPickupShowing or isDeliveryShowing the bounds will be differ
            if (isPickupShowing && isDeliveryShowing) {
                const coordinateArray = pickupMarkerCoordinates.concat(deliveryMarkerCoordinates);
                bounds = L.latLngBounds(coordinateArray);
            } else if (isPickupShowing && !isDeliveryShowing) {
                bounds = L.latLngBounds(pickupMarkerCoordinates);
            } else if (!isPickupShowing && isDeliveryShowing) {
                bounds = L.latLngBounds(deliveryMarkerCoordinates);
            }
            setTimeout(() => {
                setBound(bounds);
                setLoading(false);
            }, 1000); // Simulate a 1-second delay
        }
    }, [deliveries, isPickupShowing, isDeliveryShowing]);

    /** every time user remove item from the selected table, this useEffect get triggered and update the map markers */
    useEffect(() => {
        if (deselectedOrders.length > 0) {
            setInitialOrders([...initialOrders, ...deselectedOrders]);
            const updatedOrders = selectedOrders.filter((obj) => obj.id !== deselectedOrders[0].id);
            setSelectedOrders(updatedOrders);
            const mappedOrders = mapOrders([...initialOrders, ...deselectedOrders]);
            setOrders(mappedOrders);
        }
    }, [deselectedOrders]);

    /** every time 'selectedOrders' gets updated update map markers, tooltips, and selected delivery modal */
    useEffect(() => {
        if (selectedOrders.length > 0) {
            const updatedArr = initialOrders.filter((obj) => !selectedOrders.some((item) => obj.id === item.id));
            setInitialOrders(updatedArr);
            const mappedOrders = mapOrders(updatedArr);
            setOrders(mappedOrders);
        }
    }, [selectedOrders]);

    /** this useEffect get triggers once dragSelectBound get sets by the handleDragSelect function */
    useEffect(() => {
        /** loop through the array and check whether if the pickupLocation or deliveryAddress lat and long fall withing selected rectangle */
        /** if so returns the relevant object */
        const selected = initialOrders.filter((obj) => {
            if (
                obj.pickupLocationMeta &&
                isPickupShowing &&
                !isDeliveryShowing &&
                dragSelectBound.contains(
                    L.latLng(obj.pickupLocationMeta.coordinates.lat, obj.pickupLocationMeta.coordinates.lng)
                )
            ) {
                return obj;
            }
            if (
                obj.deliveryAddressMeta &&
                isDeliveryShowing &&
                !isPickupShowing &&
                dragSelectBound.contains(
                    L.latLng(obj.deliveryAddressMeta.coordinates.lat, obj.deliveryAddressMeta.coordinates.lng)
                )
            ) {
                return obj;
            }
            if (
                obj.pickupLocationMeta &&
                obj.deliveryAddressMeta &&
                isDeliveryShowing &&
                isPickupShowing &&
                (dragSelectBound.contains(
                    L.latLng(obj.pickupLocationMeta.coordinates.lat, obj.pickupLocationMeta.coordinates.lng)
                ) ||
                    dragSelectBound.contains(
                        L.latLng(obj.deliveryAddressMeta.coordinates.lat, obj.deliveryAddressMeta.coordinates.lng)
                    ))
            ) {
                return obj;
            }
            return null;
        });
        /** set selected data to the selectedOrder array */
        if (selected.length > 0) {
            setSelectedOrders((prevState) => [...prevState, ...selected]);
        }
    }, [dragSelectBound]);

    /** this useEffect triggers only initial rendering, once this runs this set the center coordinates of the map
     * Based on the users current location */
    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (position && _isMounted.current) {
                        setCenterCoordinate([position.coords.latitude, position.coords.longitude]);
                    }
                },
                (error) => {
                    console.log(error);
                    updateCenterCoordinatesByClient();
                }
            );
        } else {
            updateCenterCoordinatesByClient();
            console.log('GEO Not Available');
        }
        return () => {
            _isMounted.current = false;
        };
    }, []);

    // once we set the initial bound based on the delivery, every time we did something on map it automatically reset back to the initial bound
    // this useEffect avoid it
    useEffect(() => {
        if (mapRef.current && bound) {
            mapRef.current.fitBounds(bound);
        }
    }, [bound]);

    // this GestureHandling to enables the zoom facility with using the cntrl + scroll
    useEffect(() => {
        L.Map.addInitHook('addHandler', 'gestureHandling', L.GestureHandling);
    }, []);

    function updateCenterCoordinatesByClient() {
        const coords = getMapCenterCoordinatesByClient();
        if (coords) {
            setCenterCoordinate(coords);
        }
    }

    /** Drag select mechanism and logic
     * This business logics are utilized from a drawing function in leaflet
     * if the location inside the rectangle that user draw in a window
     * set those objects into the 'setSelectedOrders'
     */
    const handleDragSelect = (e) => {
        /** get the SW and NE corners(lat,long) of a rectangle */
        const bounds = e.layer.getBounds();
        setDragSelectBound(bounds);
        /** remove the drawn rectangle once function is over */
        e.layer.remove();
    };

    /** set to show the selected delivery slider modal when clicking the button */
    const toggleSelectedDeliveriesModal = () => {
        setShow(!show);
    };

    /** common props for modal component  */
    const modalProps = {
        show,
        setShow,
        selectedOrders,
        setSelectedOrders,
        setDeselectedOrders,
    };

    /** monkey patch the leaflet zoom focus to prevent scroll the map */
    L.Control.prototype._refocusOnMap = function _refocusOnMap() {};

    /** monkey patch the leaflet draw which shows the 'Drag to Select Deliveries' instead of 'draw rectangle'  */
    L.drawLocal.draw.toolbar.buttons.rectangle = 'drag-to-select';
    L.drawLocal.draw.handlers.rectangle.tooltip = 'Drag to Draw Select Deliveries';

    return (
        <>
            <div className="map-grid">
                {!centerCoordinate ? (
                    <div className="d-flex justify-content-center align-content-center align-items-center map-grid__map-container">
                        <Spinner size="50" />
                    </div>
                ) : (
                    <>
                        {/* Main map component */}
                        <MapContainer
                            center={centerCoordinate}
                            zoom={11}
                            className="map-grid__map-container"
                            scrollWheelZoom={false}
                            whenCreated={(mapInstance) => {
                                mapRef.current = mapInstance;
                            }}
                            gestureHandling
                        >
                            {isPickupShowing &&
                                orders?.pickupMappedObjectArray?.map((arrayElement) => (
                                    <CustomMarker
                                        key={arrayElement[0]._id}
                                        mappedOrderArray={arrayElement}
                                        isPickup
                                        setSelectedOrders={setSelectedOrders}
                                    />
                                ))}
                            {isDeliveryShowing &&
                                orders.deliveryMappedObjectArray.map((arrayElement) => (
                                    <CustomMarker
                                        key={arrayElement[0]._id}
                                        mappedOrderArray={arrayElement}
                                        isPickup={false}
                                        setSelectedOrders={setSelectedOrders}
                                    />
                                ))}
                            <FeatureGroup ref={featureGroupRef}>
                                <TileLayer attribution={ATTRIBUTION} url={GOOGLE_MAPS_URL} />
                                {/* top left controller which allow to draw the rectangle  */}
                                <EditControl
                                    position="topleft"
                                    draw={{
                                        rectangle: true,
                                        polygon: false,
                                        circle: false,
                                        polyline: false,
                                        circlemarker: false,
                                        marker: false,
                                    }}
                                    edit={{
                                        remove: false,
                                        edit: false,
                                    }}
                                    onCreated={(e) => handleDragSelect(e)}
                                    onError={(e) => console.log('Error:', e)}
                                />
                            </FeatureGroup>
                        </MapContainer>
                        {/* Selected Deliveries Modal Open Button */}
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="map-grid__modal-opener"
                            onClick={toggleSelectedDeliveriesModal}
                        >
                            <p className="text">Selected Deliveries</p>
                            <div className="icon">
                                <SelectedDeliveryModalIcon width="100%" />
                            </div>
                        </motion.div>
                    </>
                )}

                {/* Slider Modal */}
                <SelectedDeliveriesSliderModal {...modalProps} />
                {/* Loading Modal */}
                <GenerateMapLoadingModal loading={loading} />
            </div>
        </>
    );
}

RoutePlanningMapGrid.propTypes = RoutePlanningMapGridPropTypes;
export default RoutePlanningMapGrid;
