import moment from 'moment';

// TODO: fix warning below
// @champika

/**
 * This is helper function to show value in kilos
 * @param {Number} num
 * @returns
 */
export function kFormatter(num) {
    return Math.abs(num) > 999
        ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
        : Math.sign(num) * Math.abs(num);
}

/**
 * This is a helper function can be used to moderate data to exportable manner to excel file
 * @param {Array} stringArray
 * @param {Array} valueArray
 * @param {String} type
 * @param {String} timelyChartType
 * @returns
 */
export function chartDataMapToExportableFormat(stringArray, valueArray, type, timelyChartType = 'hourly') {
    switch (type) {
        // Type === columns, used for formatting the column names
        case 'columns':
            const mappedObjectArray = stringArray.map((column) => {
                const key = column.toLowerCase().replace(/ /g, '');
                return { key, value: column };
            });

            return mappedObjectArray;

        // Type === overviewChart, used for formatting the data to fill according to the column name in Overview Chart
        case 'overviewChart':
            const combinedArrayForOverviewChart = stringArray.map((x, index) => ({
                requesttype: x,
                numberofrequests: valueArray[index] ? valueArray[index] : 0,
            }));

            return combinedArrayForOverviewChart;

        // Type === timelyChart, used for formatting the data to fill according to the column name in Hourly, Daily and Monthly Chart
        case 'timelyChart':
            let timelyChartTypeLower = timelyChartType.toLowerCase();
            const combinedArrayForTimelyChart = stringArray.map((x, index) => ({
                [timelyChartTypeLower]: x,
                numberofsuccessrequests: valueArray[0].data[index],
                numberoferrorrequests: valueArray[1].data[index],
            }));

            return combinedArrayForTimelyChart;

        // Type === pieChart, used for formatting the data to fill according to the column name in Pie Chart
        case 'pieChart':
            const pieChartRow = {
                numberofsuccessrequests: valueArray[0],
                numberoferrorrequests: valueArray[1],
                successpercentage: `${((valueArray[0] * 100) / (valueArray[0] + valueArray[1])).toFixed(2)}%`,
                errorpercentage: `${((valueArray[1] * 100) / (valueArray[0] + valueArray[1])).toFixed(2)}%`,
            };

            return [pieChartRow];

        // Type === pieChartClient, used for formatting the data to fill according to the column name in Pie Chart for the client with merchants
        case 'pieChartClient':
            const pieChartRowClient = valueArray.map((x) => {
                return {
                    merchant: x.merchantName,
                    numberofsuccessrequests: x.successCount,
                    numberoferrorrequests: x.errorCount,
                    successpercentage: `${((x.successCount * 100) / (x.successCount + x.errorCount)).toFixed(2)}%`,
                    errorpercentage: `${((x.errorCount * 100) / (x.successCount + x.errorCount)).toFixed(2)}%`,
                };
            });

            return pieChartRowClient;
        default:
            return [];
    }
}

export function getDatePeriod(fromDate, toDate) {
    const datePeriod = `${moment.utc(fromDate).format('MM/DD/YYYY HH:mm:ss')} - ${moment
        .utc(toDate)
        .format('MM/DD/YYYY HH:mm:ss')}`;

    return datePeriod;
}
