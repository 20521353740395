/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Label, Spinner } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

// #region components
import toast from 'react-hot-toast';
import CreatableSelectAdvanced from 'components/CreateableSelect';

// #region assets | components
import { Select, CustomToast } from 'components';
import { THEME_COLOR_2 } from 'theme';

// #region services
import { updateDeliveryTypeService } from 'pages/Modules/Delivery-Management/services';

// #region utils
import { apiErrorHandlerV2, getDefaultValueForSelect } from 'utils/helpers';

// #region other imports
import { DELIVERY_TYPES } from 'constants/constants';

// #endregion imports
import './style.css';
import { SYSTEM_DATA_TYPES } from 'constants/enums';
import { createSystemDataService, getAllSystemDataByTypeAndModuleService } from 'services/system-data.service';
import { confirmValidateForm } from '../utils';

function UpdateDelivery(props) {
    const { data: deliveryData, setShowModal, loadTransactions, setDeliveryFormData, updateHistoryWithData } = props;

    const { merchantOrderNo, referenceNumber, _id } = deliveryData;
    const initialFormValues = {
        currentDeliveryType: deliveryData.deliveryType || '',
        newDeliveryType: '',
        reason: 'Other',
        confirmation: '',
    };

    const _isMounted = useRef(true);

    const [reasons, setReasons] = useState([]);

    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);

    const [customLoading, setCustomLoading] = useState({ reason: false });
    const [formLoading, setFormLoading] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);

    // load delivery cancel reasons to dropdown
    useEffect(() => {
        loadDeliveryCancelReasons();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    const handleCreateSelectChange = async (record, id) => {
        if (record) {
            if (id === 'reason') {
                setCustomLoading((prev) => ({ ...prev, reason: true }));
                try {
                    const { data } = await createSystemDataService({
                        type: SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION_REASONS.UPDATE_DELIVERY_TYPE_REASON,
                        module: SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION,
                        name: record,
                    });
                    if (data) {
                        setReasons([...reasons, { label: data.name, value: data.name }]);
                        setFormData({
                            ...formData,
                            [id]: record,
                        });
                    }
                } catch (e) {
                    const { message: exception } = apiErrorHandlerV2(e);
                    toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                        position: 'top-right',
                    });
                }
            }
        }

        setCustomLoading((prev) => ({ ...prev, reason: false }));
    };

    const handleSelectChange = (event, id) => {
        if (id === 'confirmation' && event) {
            setFormData({
                ...formData,
                [id]: event ? event.target.value : null,
            });
            return;
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleSubmit = async (event, errors = []) => {
        let updatedPayloadData;

        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.persist();
        setFormLoading(true);

        const { isFormValid, message } = confirmValidateForm(formData);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        const payload = { ...formData, deliveryId: _id };

        let isSuccess = false;

        try {
            const { data } = await updateDeliveryTypeService(payload);
            if (data) {
                setFormData({ ...payload, ...data });
                updatedPayloadData = { ...deliveryData, ...data };
                setDeliveryFormData({ ...deliveryData, ...data });
                isSuccess = true;
                toast.custom((t) => <CustomToast text="Delivery Type Updated" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (updatedPayloadData) {
            updateHistoryWithData({ ...deliveryData, ...updatedPayloadData });
        }

        setFormLoading(false);
        setValidated(false);
        setSubmitted(false);
        loadTransactions();

        if (isSuccess) {
            setShowModal(false);
        }
    };

    /**
     * Load delivery Cancel Reasons to the Dropdown
     */
    const loadDeliveryCancelReasons = async () => {
        try {
            const { data } = await getAllSystemDataByTypeAndModuleService(
                SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION_REASONS.UPDATE_DELIVERY_TYPE_REASON,
                SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                setReasons(data.map((x) => ({ label: x.name, value: x.name })));
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    // Options array for the New Delivery Type Dropdown except the current delivery type
    const newDeliveryOptions = (currentDeliveryType) => {
        const options = [
            {
                label: DELIVERY_TYPES.NEW_ORDER,
                value: DELIVERY_TYPES.NEW_ORDER,
            },
            {
                label: DELIVERY_TYPES.RETURN_ORDER,
                value: DELIVERY_TYPES.RETURN_ORDER,
            },
            {
                label: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
                value: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
            },
        ];
        const obj = options.find((x) => x.value === currentDeliveryType);
        const index = options.indexOf(obj);
        options.splice(index, 1);

        return options;
    };

    return (
        <div className="font-size-14 w-100 p-0">
            <div
                className="font-size-16 text-white"
                style={{ backgroundColor: THEME_COLOR_2, padding: 15, borderRadius: '5px 5px 0 0' }}
            >
                Update delivery - {merchantOrderNo} | {referenceNumber}{' '}
            </div>
            <div className="modal__form-table">
                <AvForm onSubmit={handleSubmit}>
                    <div className="modal_form-item">
                        <Label htmlFor="Current Delivery Type">Current Delivery Type</Label>
                        <Select
                            value={getDefaultValueForSelect(formData.currentDeliveryType)}
                            options={[
                                {
                                    label: DELIVERY_TYPES.NEW_ORDER,
                                    value: DELIVERY_TYPES.NEW_ORDER,
                                },
                                {
                                    label: DELIVERY_TYPES.RETURN_ORDER,
                                    value: DELIVERY_TYPES.RETURN_ORDER,
                                },
                                {
                                    label: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
                                    value: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
                                },
                            ]}
                            id="currentDeliveryType"
                            onChange={(event) => handleSelectChange(event, 'currentDeliveryType')}
                            submitted={submitted}
                            validated={validated}
                            isDisabled
                        />
                    </div>

                    <div className="modal_form-item">
                        <Label htmlFor="New Delivery Type">New Delivery Type</Label>
                        <Select
                            value={getDefaultValueForSelect(formData.newDeliveryType)}
                            options={newDeliveryOptions(formData.currentDeliveryType)}
                            id="newDeliveryType"
                            onChange={(event) => handleSelectChange(event, 'newDeliveryType')}
                            errorMessage="Delivery Type Required"
                            submitted={submitted}
                            required
                            // validate={{ required: { value: true } }}
                            validated={validated}
                        />
                    </div>

                    <div className="modal_form-item">
                        <Label htmlFor="reason">Reason</Label>
                        <CreatableSelectAdvanced
                            value={getDefaultValueForSelect(formData.reason)}
                            options={reasons}
                            id="reason"
                            isLoading={customLoading.reason}
                            isClearable
                            required
                            onChange={(event) => handleSelectChange(event, 'reason')}
                            onCreate={(event) => handleCreateSelectChange(event, 'reason')}
                            submitted={submitted}
                            validated={validated}
                        />
                    </div>

                    <div style={{ width: '100%', padding: '10px', background: '#fffbf0', marginBottom: '15px' }}>
                        Type &apos;confirm&apos; to confirm the transaction and click confirm.
                    </div>

                    <div className="modal_form-item">
                        <AvField
                            value={formData.confirmation}
                            name="confirmation"
                            onChange={(event) => handleSelectChange(event, 'confirmation')}
                            errorMessage="Confirmation Required"
                            submitted={submitted}
                            required
                            id="confirmation"
                            validated={validated}
                            autoComplete="off"
                        />
                    </div>

                    <div style={{ fontSize: 14 }} className="mb-6">
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius secondary-btn pt-1 pb-1 m-2 mb-3 pr-5 pl-5 shadow-lg "
                            style={{ borderRadius: 10 }}
                            type="submit"
                            disabled={formLoading}
                        >
                            {formLoading ? <Spinner className="mr-4 ml-4 0" color="info" size="sm" /> : <>CONFIRM</>}
                        </motion.button>
                    </div>
                </AvForm>
            </div>
        </div>
    );
}

UpdateDelivery.propTypes = {
    setShowModal: PropTypes.func,
    loadTransactions: PropTypes.func,
    setDeliveryFormData: PropTypes.func,
    data: PropTypes.any,
    updateHistoryWithData: PropTypes.func,
};

export default UpdateDelivery;
