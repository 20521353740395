import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { Card, CardBody } from 'reactstrap';
import QRCode from 'react-qr-code';
import { motion } from 'framer-motion';
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

function LocationLabelViewCard(props) {
    const { referenceNumber } = props;

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div>
            <Card style={{ display: 'inline-block', maxWidth: 240 }}>
                <CardBody>
                    <div ref={componentRef}>
                        <QRCode value={referenceNumber} size={200} />
                    </div>
                    <div className="mt-3 text-center" style={{ fontWeight: 'bolder', fontSize: 15 }}>
                        <p>{referenceNumber}</p>
                    </div>
                    {/* <div className="mt-2">
                        <span>TO</span>
                        <p>{`${packageData.deliveryData.recipientName}, ${packageData.deliveryData.deliveryAddress}`}</p>
                    </div>
                    <div className="text-center" style={{ fontSize: 20, fontWeight: 'bolder' }}>
                        PACKAGES {index + 1}/{packages.length}
                    </div> */}
                </CardBody>
            </Card>
            <div style={{ marginLeft: 40 }}>
                {hasAccess(PERMISSIONS.SHIPPING_LABELS, ['Print']) && (
                    <motion.div
                        style={{ width: 150, alignSelf: 'center' }}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md pointer"
                        onClick={handlePrint}
                    >
                        <i className="fas fa-print"></i> <span className="h6">Print</span>
                    </motion.div>
                )}
            </div>
        </div>
    );
}

LocationLabelViewCard.propTypes = {
    referenceNumber: PropTypes.string,
};

export default LocationLabelViewCard;
