/* eslint-disable react-hooks/exhaustive-deps */

import React, { useMemo } from 'react';
import { Row, Col, Label, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { AvForm } from 'availity-reactstrap-validation';

import PropTypes from 'prop-types';

// #region imports
import { Select } from 'components';
import { CheckCircle } from 'components/Icons';

// #region utils
import { getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import { RIDER_STATUS_OPTIONS } from '../../constants';

function RiderAssignFormBody(props) {
    const {
        formData,
        merchants,
        submitted,
        validated,
        handleSelectChange,
        getRiderSelect,
        riderFilteredListData,
        loading,
        handleSubmit,
        formLoading,
    } = props;

    // ** Memos
    const selectedRiderId = useMemo(() => getRiderSelect(formData.riderId), [formData.riderId, merchants]);

    return (
        <>
            <AvForm
                className="needs-validation default-form"
                noValidate
                validated={String(validated)}
                onSubmit={handleSubmit}
                id="riderAssignForm"
            >
                <div className="delivery-batch-assign__rider-assign-container">
                    <Row>
                        <Col md={4}>
                            <Label htmlFor="riderStatus">Riders / Drivers Status</Label>
                            <Select
                                value={getDefaultValueForSelect(formData.riderStatus)}
                                options={RIDER_STATUS_OPTIONS}
                                styles={reactSelectCustomStyles}
                                onChange={(event) => handleSelectChange(event, 'riderStatus')}
                                submitted={submitted}
                                required
                                isDisabled={loading}
                                validated={validated}
                            />
                        </Col>
                        <Col md={4}>
                            <Label htmlFor="riderId">Rider / Driver</Label>
                            <Select
                                value={selectedRiderId}
                                options={riderFilteredListData?.docs.map((x) => ({
                                    label: x.fullName || '',
                                    value: x._id,
                                }))}
                                styles={reactSelectCustomStyles}
                                onChange={(event) => handleSelectChange(event, 'riderId')}
                                submitted={submitted}
                                required
                                isClearable
                                validated={validated}
                                isLoading={loading}
                            />
                        </Col>
                        {hasAccess(PERMISSIONS.DELIVERY_BATCH_ASSIGNMENT, ['Assign']) && (
                            <motion.button
                                initial={{ scale: 0.8, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.8, opacity: 0 }}
                                transition={{ type: 'spring', duration: 1.5 }}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md ml-3 mt-4"
                                style={{ borderRadius: 15 }}
                                type="submit"
                                disabled={loading || formLoading}
                            >
                                {formLoading ? (
                                    <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                ) : (
                                    <>
                                        <CheckCircle color="#FFF" /> <div className="ml-1">Assign</div>
                                    </>
                                )}
                            </motion.button>
                        )}
                        {/* <motion.div
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                            transition={{ type: 'spring', duration: 1.5 }}
                            className="scootix-btn-radius p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md ml-3 mt-4"
                            style={{ borderRadius: 15 }}
                            type="submit"
                            disabled={loading || formLoading}
                        >
                            {formLoading ? (
                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                            ) : (
                                <>
                                    <CheckCircle color="#FFF" /> <div className="ml-1">Assign</div>
                                </>
                            )}
                        </motion.div> */}
                    </Row>
                </div>
            </AvForm>
        </>
    );
}

RiderAssignFormBody.propTypes = {
    formData: PropTypes.object,
    merchants: PropTypes.array,
    submitted: PropTypes.bool,
    validated: PropTypes.bool,
    loading: PropTypes.bool,
    handleSelectChange: PropTypes.func,
    getRiderSelect: PropTypes.func,
    handleSubmit: PropTypes.func,
    riderFilteredListData: PropTypes.object,
    formLoading: PropTypes.bool,
};

export default RiderAssignFormBody;
