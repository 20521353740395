import { privateKeyEndpoints } from 'pages/Modules/Developer/Api-Keys/constants';

export function getActionPathLabel(actionPath) {
    const {
        cancelDelivery,
        createDelivery,
        getDeliveryByDeliveryId,
        getDeliveryCharges,
        // getDeliveryReadyStatusUpdate,
        getQuotation,
        getShippingLabel,
        trackDelivery,
        updateDelivery,
        bulkDeliveryCreate,
    } = privateKeyEndpoints;

    switch (actionPath) {
        case getDeliveryByDeliveryId.key:
            return getDeliveryByDeliveryId.label;

        case cancelDelivery.key:
            return cancelDelivery.label;

        case createDelivery.key:
            return createDelivery.label;

        case getDeliveryCharges.key:
            return getDeliveryCharges.label;

        // case getDeliveryReadyStatusUpdate.key:
        //     return getDeliveryReadyStatusUpdate.label;

        case getQuotation.key:
            return getQuotation.label;

        case getShippingLabel.key:
            return getShippingLabel.label;

        case trackDelivery.key:
            return trackDelivery.label;

        case updateDelivery.key:
            return updateDelivery.label;

        case bulkDeliveryCreate.key:
            return bulkDeliveryCreate.label;

        default:
            break;
    }

    return '';
}
