/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';

// #region components | assets
import { ClientAlerts, CustomToast, SelectModal } from 'components';
import CustomGridTable from 'components/CustomGridTable';
import { apiErrorHandlerV2 } from 'utils/helpers';

// #region services
import { getAllSystemDataByTypeAndModuleService } from 'services/system-data.service';
import { getAllProductMasterDataByMerchantService } from 'services/product-master-data.service';
import { getOrderItemsByOrderIdService } from 'services/order-items.service';
import { getAllStocksService } from 'services/stocks';

// #region imports
import { SYSTEM_DATA_TYPES } from 'constants/enums';

// #region utils
import { getVisitingObject } from 'utils/checkAuth';
import { DEV_ENV } from 'utils/checks';

// #endregion imports
import { saveOrderItemsBatchService } from './services';
import { validateOrderItems } from './utils';

function OrderItemsComponent(props) {
    const { orderData = {}, setBackdropLoading } = props;

    const _isMounted = useRef(true);

    const { visitingClient } = getVisitingObject();

    // component
    const [formLoading, setFormLoading] = useState(false);

    // other
    const [unitOfMeasures, setUnitOfMeasures] = useState([]);
    const [productMasterDataArray, setProductMasterDataArray] = useState([]);
    const [allStocks, setAllStocks] = useState([]);

    const [stockItemsColumns, setStockItemsColumns] = useState([]);

    const [activeCell, setActiveCell] = useState(null); // Active Cell

    const [allOrderItems, setAllOrderItems] = useState([]);
    const [allDeletedOrderItems, setAllDeletedOrderItems] = useState([]);

    // Booleans
    const [disableGridAction, setDisableGridAction] = useState(false);

    // loading
    const [initialLoading, setInitialLoading] = useState(true);

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
        options: [],
    });

    useEffect(() => {
        loadUnitOfMeasures();
        loadAllStocks();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        orderData.merchantId && loadAllProductMasterDataByMerchant(orderData.merchantId);
    }, [orderData.merchantId]);

    useEffect(() => {
        if (!initialLoading && allOrderItems.length === 0) {
            handleAddNewRow();
        }
    }, [initialLoading, allOrderItems]);

    // useEffect(() => {
    //     orderData && loadAllOrderItemByOrder(orderData._id);
    // }, [orderData._id]);

    useEffect(() => {
        productMasterDataArray.length > 0 && loadAllOrderItemByOrder(orderData._id);
    }, [productMasterDataArray]);

    useEffect(() => {
        setStockItemsColumns([
            {
                title: 'UPC',
                type: 'text',
                key: 'upc',
                disabled: ['dispatchReady', 'dispatched', 'completed'].includes(orderData.status),
            },
            {
                title: 'SKU Code',
                type: 'text',
                key: 'skuCode',
                disabled: ['dispatchReady', 'dispatched', 'completed'].includes(orderData.status),
            },
            {
                title: 'Product Description',
                type: 'select',
                choices: productMasterDataArray,
                key: 'productMasterDataId',
                disabled: ['dispatchReady', 'dispatched', 'completed'].includes(orderData.status),
            },
            {
                title: 'Scootix Product Code',
                type: 'text',
                key: 'productMasterDataReferenceNumber',
                disabled: true,
            },
            {
                title: 'Unit of Measure',
                type: 'select',
                choices: unitOfMeasures,
                key: 'unitOfMeasure',
                disabled: true,
            },
            {
                title: 'Available Quantity',
                type: 'number',
                key: 'availableQuantity',
                disabled: true,
            },
            {
                title: 'Quantity',
                type: 'number',
                key: 'quantity',
                disabled: ['dispatchReady', 'dispatched', 'completed'].includes(orderData.status),
            },
        ]);
    }, [productMasterDataArray, unitOfMeasures, orderData]);

    const loadAllStocks = async () => {
        try {
            const { data } = await getAllStocksService();
            if (data && Array.isArray(data)) {
                setAllStocks(data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadAllOrderItemByOrder = async (id) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getOrderItemsByOrderIdService(id, true);
            if (data && data.docs) {
                const mappedData = data.docs.map((x) => {
                    const item = x;
                    let { availableQuantity } = item;
                    const relevantObject = productMasterDataArray.find((y) => y._id === item.productMasterDataId);
                    if (relevantObject) {
                        availableQuantity = loadProductStock(relevantObject);
                    }
                    item.availableQuantity = availableQuantity || 0;
                    return item;
                });
                const isAnyItemPickingCompleted = mappedData.some((a) => a.isPickingCompleted);
                if (isAnyItemPickingCompleted) {
                    setDisableGridAction(true);
                }
                setAllOrderItems(mappedData);
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
        setInitialLoading(false);
    };

    const loadProductStock = (productData) => {
        let totalAvailableQuantity = 0;
        if (allStocks && allStocks.length > 0) {
            const { _id } = productData;
            const relevantStocks = allStocks.filter((x) => x.productMasterDataId === _id);
            relevantStocks.map((x) => {
                totalAvailableQuantity += x.quantity || 0;
                return x;
            });
        }
        return totalAvailableQuantity;
    };

    const loadUnitOfMeasures = async () => {
        try {
            const { data } = await getAllSystemDataByTypeAndModuleService(
                SYSTEM_DATA_TYPES.PRODUCT_MASTER_DATA.TYPES.UNIT_OF_MEASURE,
                SYSTEM_DATA_TYPES.PRODUCT_MASTER_DATA.MODULE
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                setUnitOfMeasures(data.map((x) => ({ label: x.name, value: x.name })));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadAllProductMasterDataByMerchant = async (merchantId) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getAllProductMasterDataByMerchantService(merchantId);
            if (_isMounted.current && data && data.docs && Array.isArray(data.docs)) {
                setProductMasterDataArray(data.docs.map((x) => ({ ...x, label: x.productDescription, value: x._id })));
            }
            if (data && (data.docs || []).length === 0) {
                toast.custom(
                    (t) => <CustomToast text="Product Master Data Not Found For Merchant" t={t} type="warning" />,
                    {
                        position: 'top-right',
                    }
                );
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleAddNewRow = () => {
        let lastItemIndex = 0;
        if (allOrderItems.length > 0) {
            const lastItem = allOrderItems[allOrderItems.length - 1];
            lastItemIndex = lastItem.index;
        }
        setAllOrderItems((prevState) => [...prevState, { index: lastItemIndex + 1, uuid: uuid() }]);
    };

    const handleOnRowChange = (data) => {
        let changeType = 'add';

        const allExistingOrderItems = allOrderItems;
        const modifiedOrderItems = data;

        const removedItems = allDeletedOrderItems;

        if (allExistingOrderItems.length === modifiedOrderItems.length) {
            changeType = 'edit';
        }

        if (modifiedOrderItems.length > allExistingOrderItems.length) {
            changeType = 'add';
        }

        if (modifiedOrderItems.length < allExistingOrderItems.length) {
            changeType = 'remove';
            allExistingOrderItems.map((val) => {
                const relevantItem = modifiedOrderItems.find((x) => (x._id ? x._id === val._id : x.uuid === val.uuid));
                if (!relevantItem) {
                    removedItems.push(val);
                }
                return val;
            });
        }

        if (changeType === 'add') {
            handleAddNewRow();
        }
        if (changeType === 'remove') {
            setAllOrderItems(modifiedOrderItems);
            setAllDeletedOrderItems(removedItems);
        }

        if (changeType === 'edit') {
            const changedCell = activeCell;

            if (changedCell) {
                const { row, colId } = changedCell.cell;

                const modifiedRow = modifiedOrderItems[row];

                if (colId === 'productMasterDataId') {
                    const relevantObject = productMasterDataArray.find(
                        (x) => x._id === modifiedRow.productMasterDataId
                    );
                    if (relevantObject) {
                        const availableQuantity = loadProductStock(relevantObject);
                        const newObject = {
                            ...modifiedRow,
                            productMasterDataDescription: relevantObject.productDescription,
                            productMasterDataId: relevantObject._id,
                            productMasterData: relevantObject,
                            unitOfMeasure: relevantObject.unitOfMeasure,
                            skuCode: relevantObject.skuCode,
                            upc: relevantObject.upc,
                            productMasterDataReferenceNumber: relevantObject.referenceNumber,
                            availableQuantity,
                            quantity: 0,
                        };
                        const filteredOutStocks = allOrderItems.filter((x) => x.index !== modifiedRow.index);
                        filteredOutStocks.push(newObject);
                        const sortedArray = filteredOutStocks.sort((a, b) => a.index - b.index);
                        setAllOrderItems(sortedArray);
                    }
                } else if (colId === 'skuCode') {
                    const relevantProduct = productMasterDataArray.find((x) => x.skuCode === modifiedRow.skuCode);
                    if (relevantProduct) {
                        toast.custom((t) => <CustomToast text="Found Product Master Data" t={t} type="success" />, {
                            position: 'top-right',
                        });
                        const { productDescription, _id, unitOfMeasure, upc, referenceNumber } = relevantProduct;
                        const availableQuantity = loadProductStock(relevantProduct);

                        const newObject = {
                            ...modifiedRow,
                            productMasterDataDescription: productDescription,
                            productMasterDataId: _id,
                            productMasterData: relevantProduct,
                            upc,
                            unitOfMeasure,
                            productMasterDataReferenceNumber: referenceNumber,
                            quantity: 0,
                            availableQuantity,
                        };

                        const filteredOutStocks = allOrderItems.filter((x) => x.index !== modifiedRow.index);
                        filteredOutStocks.push(newObject);
                        const sortedArray = filteredOutStocks.sort((a, b) => a.index - b.index);
                        setAllOrderItems(sortedArray);
                    } else {
                        setAllOrderItems(modifiedOrderItems);
                    }
                }
                // UPC FIELD
                else if (colId === 'upc') {
                    const relevantProduct = productMasterDataArray.find((x) => x.upc === modifiedRow.upc);
                    if (relevantProduct) {
                        toast.custom((t) => <CustomToast text="Found Product Master Data" t={t} type="success" />, {
                            position: 'top-right',
                        });
                        const { productDescription, _id, unitOfMeasure, referenceNumber } = relevantProduct;

                        const availableQuantity = loadProductStock(relevantProduct);

                        const newObject = {
                            ...modifiedRow,
                            productMasterDataDescription: productDescription,
                            productMasterDataId: _id,
                            productMasterData: relevantProduct,
                            skuCode: relevantProduct.skuCode,
                            unitOfMeasure,
                            productMasterDataReferenceNumber: referenceNumber,
                            quantity: 0,
                            availableQuantity,
                        };

                        const filteredOutStocks = allOrderItems.filter((x) => x.index !== modifiedRow.index);
                        filteredOutStocks.push(newObject);
                        const sortedArray = filteredOutStocks.sort((a, b) => a.index - b.index);
                        setAllOrderItems(sortedArray);
                    }
                }
                // Quantity FIELD
                else if (colId === 'quantity') {
                    const newObject = {
                        ...modifiedRow,
                    };

                    if (!modifiedRow.productMasterDataId) {
                        toast.custom((t) => <CustomToast text="Select Product" t={t} type="warning" />, {
                            position: 'top-right',
                        });
                        return;
                    }

                    if (modifiedRow.quantity === 0 || modifiedRow.quantity < 0) {
                        toast.custom(
                            (t) => <CustomToast text="Quantity Should be greater than 0" t={t} type="warning" />,
                            {
                                position: 'top-right',
                            }
                        );
                        return;
                    }

                    // if (modifiedRow.quantity > modifiedRow.availableQuantity) {
                    //     toast.custom(
                    //         (t) => <CustomToast text="Quantity Exceeding Available Quantity" t={t} type="warning" />,
                    //         {
                    //             position: 'top-right',
                    //         }
                    //     );
                    //     return;
                    // }

                    const filteredOutStocks = allOrderItems.filter((x) => x.index !== modifiedRow.index);
                    filteredOutStocks.push(newObject);
                    const sortedArray = filteredOutStocks.sort((a, b) => a.index - b.index);
                    setAllOrderItems(sortedArray);
                } else {
                    setAllOrderItems(modifiedOrderItems);
                }
            }
        }
    };

    const handleActiveCellChange = (val) => {
        const changedCell = val;
        if (changedCell && allOrderItems) {
            setActiveCell(changedCell);
        }
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    const handleSaveOrderItems = async () => {
        if (formLoading) {
            return;
        }
        const modifiedItems = allOrderItems.filter((x) => x.productMasterDataId);
        console.log('modifiedItems', modifiedItems);

        if (!modifiedItems.length && !allDeletedOrderItems.length > 0) {
            toast.custom((t) => <CustomToast text="Add Items To Save" t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        const { isValid, message } = validateOrderItems(modifiedItems);
        if (!isValid) {
            toast.custom((t) => <CustomToast text={message || 'Fill all Item Fields'} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);
        const payload = {
            orderId: orderData._id,
            docs: modifiedItems,
            removedDocs: allDeletedOrderItems,
            merchantId: orderData.merchantId,
            merchantName: orderData.merchantName,
            warehouseSiteId: orderData.warehouseSiteId,
        };
        try {
            const { data } = await saveOrderItemsBatchService(payload);
            if (data && Array.isArray(data)) {
                setAllOrderItems(data);
            }
            toast.custom((t) => <CustomToast text="Successfully Saved Order Items" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading(false);
    };

    return (
        <>
            <Row style={{ justifyContent: 'flex-start' }}>
                {DEV_ENV() && (
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                        disabled={formLoading}
                        onClick={() => handleAddNewRow()}
                    >
                        Add Row DEV
                    </motion.button>
                )}
                <Col md={12}>
                    <ClientAlerts />
                </Col>
                <Col md={12} style={!visitingClient ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
                    <CustomGridTable
                        columns={stockItemsColumns}
                        data={allOrderItems}
                        setData={handleOnRowChange}
                        handleActiveCellChange={handleActiveCellChange}
                        removeActions={disableGridAction}
                    />
                </Col>
            </Row>
            <Row style={{ justifyContent: 'flex-start', marginTop: 10, marginLeft: 10 }}>
                <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                    disabled={formLoading}
                    onClick={() => !formLoading && handleSaveOrderItems()}
                >
                    {formLoading ? (
                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                    ) : (
                        <>
                            <i className="fas fa-save left-icon"></i>
                            <span className="h6">Save</span>
                        </>
                    )}
                </motion.button>
            </Row>

            {scootixModalState.show && <SelectModal toggleModal={toggleScootixModal} {...scootixModalState} />}
        </>
    );
}

export default OrderItemsComponent;
