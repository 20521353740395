/* eslint-disable no-param-reassign */
import {
    SET_SOCKET,
    SET_CLIENT_SETTING,
    SET_CLIENT_MERCHANT_X_CLIENTS_LOADED,
    SET_CLIENT_MERCHANT_X_MERCHANTS_LOADED,
} from './actionTypes';

const initialState = {
    socket: null,
    clientSetting: null,
    clientMerchantXMerchantsLoaded: false,
    clientMerchantXClientsLoaded: false,
};

const global = (state = initialState, action) => {
    switch (action.type) {
        case SET_SOCKET:
            state = {
                ...state,
                socket: action.payload,
            };
            break;
        case SET_CLIENT_SETTING:
            state = {
                ...state,
                clientSetting: action.payload,
            };
            break;
        case SET_CLIENT_MERCHANT_X_CLIENTS_LOADED:
            state = {
                ...state,
                clientMerchantXClientsLoaded: true,
            };
            break;
        case SET_CLIENT_MERCHANT_X_MERCHANTS_LOADED:
            state = {
                ...state,
                clientMerchantXMerchantsLoaded: true,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default global;
