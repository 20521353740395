export const transactionTableHeaderForUsers = [
    {
        key: 'sequence',
        value: 'TR. ID',
    },
    {
        key: 'reference',
        value: 'TR. REFERENCE',
    },
    {
        key: 'description',
        value: 'TRANSACTION',
    },
    {
        key: 'transactionMadeByUserUsername',
        value: 'USER',
    },
    {
        key: 'createdAt',
        value: 'TR. DATE AND TIME',
        type: 'dateTime',
    },
    {
        key: 'action',
        value: 'DETAILS',
        type: 'customActionButton',
        params: {
            buttonLabel: 'View',
            customActionHandlerName: 'displayModal',
        },
    },
];
