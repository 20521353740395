export const packingTaskOrderItems = {
    columns: [
        {
            text: 'Product Description',
            dataField: 'productMasterDataDescription',
            sort: 'asc',
            editable: false,
            width: 150,
        },
        {
            text: 'SKU Code',
            dataField: 'skuCode',
            sort: 'asc',
            editable: false,
            width: 150,
        },
        {
            text: 'Quantity',
            dataField: 'quantity',
            sort: 'asc',
            editable: false,
            width: 100,
        },
        {
            text: 'Unit Of Measure',
            dataField: 'unitOfMeasure',
            sort: 'asc',
            editable: false,
            width: 100,
        },
        {
            dataField: 'selectedBinLocationsString',
            text: 'Selected Bin Locations',
            // editor: {
            //     type: Type.SELECT,
            //     options: allUsableStocks,
            // },
            sort: 'asc',
            width: 200,
            editable: false,
        },
        {
            text: 'Batch',
            dataField: 'batch',
            sort: 'asc',
            width: 200,
        },
        {
            text: 'Serial',
            dataField: 'serial',
            sort: 'asc',
            width: 200,
        },
    ],
};
