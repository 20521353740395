import { PERMISSIONS } from 'utils/constants';

const sandboxAccountTableHeaders = [
    {
        key: 'sequenceId',
        value: 'REQUEST ID',
    },
    {
        key: 'merchantReferenceNumber',
        value: 'MERCHANT ID',
    },
    {
        key: 'merchantName',
        value: 'MERCHANT',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
    {
        key: 'developerName',
        value: 'DEVELOPER',
    },
    {
        key: 'developerEmail',
        value: 'DEVELOPER EMAIL',
    },
    {
        key: 'requestedByUserFullName',
        value: 'REQUESTED BY',
    },
    {
        key: 'requestedAt',
        value: 'REQUESTED AT',
        type: 'date',
    },
    {
        key: 'requestStatus',
        value: 'REQUEST STATUS',
        type: 'requestStatusLabel',
        style: ['centerElements'],
    },
    {
        key: 'merchantProfile',
        value: 'Merchant Profile',
        type: 'viewMerchantButton',
        style: ['increaseWidth', 'centerElements'],
    },
    {
        key: 'action',
        value: 'Action',
        type: 'customActionButton',
        style: ['centerElements'],
        params: {
            buttonLabel: 'Manage',
            customActionHandlerName: 'navigateToManagePage',
            permissionType: PERMISSIONS.PARTNER_API_API_SERVICE,
            permissions: ['EnableAndDisable'],
        },
    },
];

export const sandboxAccountInitialTableData = {
    matchedCount: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: sandboxAccountTableHeaders,
    docs: [],
    sort: null,
    search: undefined,
    order: -1,
    requestStatus: ['pending'],
};
