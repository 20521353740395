export const defaultTableHeaders = [
    {
        text: 'Merchant Name',
        dataField: 'merchantName',
        sort: 'asc',
        width: 150,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Warehouse',
        dataField: 'warehouseSiteName',
        sort: 'asc',
        width: 150,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Zone',
        dataField: 'zoneName',
        sort: 'asc',
        width: 100,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Bin Location',
        dataField: 'binLocation',
        sort: 'asc',
        width: 150,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Order Reference',
        dataField: 'orderReferenceNumber',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Merchant Order Number',
        dataField: 'merchantOrderNumber',
        sort: 'asc',
        width: 150,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Order date and time ',
        dataField: 'orderDateTime',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Delivery ID',
        dataField: 'deliveryReferenceNumber',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Delivered date and time ',
        dataField: 'deliveredDateTime',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Product Description',
        dataField: 'productDescription',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'UPC',
        dataField: 'upc',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'ScootiX Product Code',
        dataField: 'productMasterDataReferenceNumber',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'SKU Code',
        dataField: 'skuCode',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Unit',
        dataField: 'unitOfMeasure',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Quantity',
        dataField: 'quantity',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
];
