/* eslint-disable no-plusplus */
/* eslint-disable no-new */
/* eslint-disable indent */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MetisMenu from 'metismenujs';
import { withRouter, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import PATHS from 'routes/paths';

// #constants
import { USER_ROLES } from 'constants/user/user.constant';
import { PERMISSIONS, PERMISSION_TYPES } from 'utils/constants';

import { getUser, hasAccess, IS_MERCHANT_VIEW } from 'utils/checkAuth';
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader,
    toggleRightSidebar,
} from '../../store/actions';
import { buildPathNamesForSideBar } from './sidebar.util';
import { hasAccessDeveloperMenu } from './sidebarPermissionHandler';

class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
        };
        this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
    }

    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.type !== prevProps.type) {
                this.initMenu();
            }
        }
    }

    toggleMenuCallback = () => {
        this.props.changeSidebarType('default', this.state.isMobile);
    };

    initMenu() {
        new MetisMenu('#side-menu');

        let matchingMenuItem = null;
        const ul = document.getElementById('side-menu');
        const items = ul.getElementsByTagName('a');

        // !FIXME Keep Side Menu Open When Relevant Item Is Selected

        for (let i = 0; i < items.length; ++i) {
            let currentPathname = this.props.location.pathname;

            if (currentPathname.includes('imports/delivery')) {
                currentPathname = currentPathname.replace('/imports/delivery', '/delivery/list');
            }
            if (currentPathname.includes('pending-data/delivery/form')) {
                currentPathname = currentPathname.replace('/pending-data/delivery/form', '/delivery/list');
            }
            if (currentPathname.includes('billing/form')) {
                currentPathname = currentPathname.replace('/billing/form', '/merchant/form');
            }
            if (currentPathname.includes('shipping-label/batch-print')) {
                currentPathname = currentPathname.replace('/batch-print', '/list');
            }
            if (currentPathname.includes('order-processing/packaging/form')) {
                currentPathname = currentPathname.replace('/packaging/form', '/list/packing');
            }
            if (currentPathname.includes('/warehouse/warehouse-site/location/form')) {
                currentPathname = currentPathname.replace('/location/form', '/list');
            }
            if (currentPathname.includes('/warehouse/warehouse-site/location/bin/form')) {
                currentPathname = currentPathname.replace('/location/bin/form', '/list');
            }

            currentPathname = currentPathname.replace('/form', '/list');

            // build custom path names
            // Keep the sidebar item active even when you navigate to item form or item relevant page
            // eg deliveries will be active even when delivery form page is open
            currentPathname = buildPathNamesForSideBar(currentPathname);
            if (currentPathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = (item) => {
        item.classList.add('active');
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add('mm-active');
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add('mm-show');

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add('mm-active'); // li
                    parent3.childNodes[0].classList.add('mm-active'); // a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add('mm-active');
                    }
                }
            }
            return false;
        }
        return false;
    };

    hasRole = (roles = []) => {
        const { role = null } = getUser();
        if (role) {
            if (roles.includes(role)) {
                return true;
            }
        }
        return false;
    };

    /**
     * Render route planning link based on permissions user have
     */
    renderRoutePlanningLink = () => {
        // path to route planning form
        let path = `/${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_FORM}`;
        if (!hasAccess(PERMISSIONS.ROUTE_PLANNING, ['Add'])) {
            // path to route planning list view
            path = `/${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_ENTRIES}`;
        }

        return (
            <li>
                <Link
                    className="list-item"
                    onClick={() => {
                        this.toggleMenuCallback();
                    }}
                    to={path}
                >
                    <span className="ml-1">Route Planning</span>
                </Link>
            </li>
        );
    };

    render() {
        // eslint-disable-next-line no-unused-vars
        const { clientAdmin, clientUser, merchantAdmin, merchantUser, superAdmin } = USER_ROLES;

        // API PORTAL - MERCHANT ONLY
        const hasAccessApiPortal = () =>
            this.hasRole([merchantAdmin, merchantUser]) &&
            hasAccess(PERMISSIONS.PARTNER_API_API_SERVICE, [PERMISSION_TYPES.view]);

        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu" style={{ height: '100vh' }}>
                        <li className="menu-title">{this.props.t('Menu')}</li>

                        <li>
                            <Link
                                to={PATHS.HOME.DEFAULT}
                                className="waves-effect list-item"
                                onClick={() => {
                                    this.toggleMenuCallback();
                                }}
                            >
                                <i className="ri-home-line"></i>
                                {/* <span className="ml-1">{this.props.t('Dashboard')}</span> */}
                                <span className="ml-1">Home</span>
                            </Link>
                        </li>
                        {(hasAccess(PERMISSIONS.DELIVERY, ['View']) ||
                            hasAccess(PERMISSIONS.DELETED_DELIVERY, ['View'])) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="fas fa-box"></i>
                                    <span className="ml-1">Delivery Management</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    {hasAccess(PERMISSIONS.DELIVERY, ['View']) && (
                                        <li>
                                            <Link
                                                className="list-item"
                                                onClick={() => {
                                                    this.toggleMenuCallback();
                                                }}
                                                to={PATHS.DELIVERY.DEFAULT}
                                            >
                                                <span className="ml-1">Deliveries</span>
                                            </Link>
                                        </li>
                                    )}
                                    {hasAccess(PERMISSIONS.DELIVERY, ['View']) && (
                                        <li>
                                            <Link
                                                className="list-item"
                                                onClick={() => {
                                                    this.toggleMenuCallback();
                                                }}
                                                to={PATHS.DELIVERY.COMPLETED}
                                            >
                                                <span className="ml-1">Completed Deliveries</span>
                                            </Link>
                                        </li>
                                    )}
                                    {hasAccess(PERMISSIONS.DELIVERY, ['View']) && (
                                        <li>
                                            <Link
                                                className="list-item"
                                                onClick={() => {
                                                    this.toggleMenuCallback();
                                                }}
                                                to={PATHS.DELIVERY.UNASSIGNED}
                                            >
                                                <span className="ml-1">Un Assigned Deliveries</span>
                                            </Link>
                                        </li>
                                    )}

                                    {hasAccess(PERMISSIONS.DELETED_DELIVERY, ['View']) && (
                                        <li>
                                            <Link
                                                className="list-item"
                                                onClick={() => {
                                                    this.toggleMenuCallback();
                                                }}
                                                to={PATHS.DELIVERY.DELETED}
                                            >
                                                <span className="ml-1">Deleted Deliveries</span>
                                            </Link>
                                        </li>
                                    )}

                                    {hasAccess(PERMISSIONS.DELIVERY, ['View']) && (
                                        <li>
                                            <Link
                                                className="list-item"
                                                onClick={() => {
                                                    this.toggleMenuCallback();
                                                }}
                                                to={PATHS.DELIVERY.REPORT}
                                            >
                                                <span className="ml-1">Delivery Reports</span>
                                            </Link>
                                        </li>
                                    )}
                                    {hasAccess(PERMISSIONS.DELIVERY_BATCH_ASSIGNMENT, ['Assign']) && (
                                        <li>
                                            <Link
                                                className="list-item"
                                                onClick={() => {
                                                    this.toggleMenuCallback();
                                                }}
                                                to={`/${PATHS.DELIVERY.BATCH_ASSIGNMENT}`}
                                            >
                                                <div className="ml-1">Batch Delivery Assignment</div>
                                            </Link>
                                        </li>
                                    )}
                                    {!IS_MERCHANT_VIEW() &&
                                        hasAccess(PERMISSIONS.ROUTE_PLANNING, ['View']) &&
                                        this.renderRoutePlanningLink()}
                                </ul>
                            </li>
                        )}

                        {(hasAccess(PERMISSIONS.ORDER, ['View']) || hasAccess(PERMISSIONS.PICKING, ['View'])) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="fas fa-shopping-cart"></i>
                                    <span className="ml-1">Order Processing</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    {hasAccess(PERMISSIONS.ORDER, ['View']) && (
                                        <li>
                                            <Link
                                                className="list-item"
                                                onClick={() => {
                                                    this.toggleMenuCallback();
                                                }}
                                                to={PATHS.ORDER_PROCESSING.DEFAULT}
                                            >
                                                {/* <i className="fas fa-user mr-3" /> */}
                                                Order List
                                            </Link>
                                        </li>
                                    )}
                                    {hasAccess(PERMISSIONS.PICKING, ['View']) && (
                                        <li>
                                            <Link
                                                className="list-item"
                                                onClick={() => {
                                                    this.toggleMenuCallback();
                                                }}
                                                to={PATHS.PICKING_TASK.DEFAULT}
                                            >
                                                {/* <i className="fas fa-user mr-3" /> */}
                                                Picking Tasks
                                            </Link>
                                        </li>
                                    )}
                                    {hasAccess(PERMISSIONS.ORDER, ['View']) && (
                                        <li>
                                            <Link
                                                className="list-item"
                                                onClick={() => {
                                                    this.toggleMenuCallback();
                                                }}
                                                to={{ pathname: PATHS.ORDER_PROCESSING.PACKING, search: '?tab=3' }}
                                            >
                                                {/* <i className="fas fa-user mr-3" /> */}
                                                Packing
                                            </Link>
                                        </li>
                                    )}
                                    {hasAccess(PERMISSIONS.ORDER, ['View']) && (
                                        <li>
                                            <Link
                                                className="list-item"
                                                onClick={() => {
                                                    this.toggleMenuCallback();
                                                }}
                                                to={PATHS.ORDER_PROCESSING.PROCESSED_ORDERS}
                                            >
                                                {/* <i className="fas fa-user mr-3" /> */}
                                                Processed Orders
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}

                        {this.hasRole(['clientAdmin', 'superAdmin', 'clientUser']) &&
                            (hasAccess(PERMISSIONS.RIDER_ACCOUNT, ['View']) ||
                                hasAccess(PERMISSIONS.DELETED_RIDER, ['View'])) && (
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="fas fa-biking"></i>
                                        <span className="ml-1">Rider / Driver Management</span>
                                    </Link>
                                    <ul className="sub-menu" aria-expanded="false">
                                        {hasAccess(PERMISSIONS.RIDER_ACCOUNT, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.RIDER_PROFILE_MANAGEMENT.LIST_VIEW}
                                                >
                                                    {/* <i className="fas fa-user mr-3" /> */}
                                                    Riders
                                                </Link>
                                            </li>
                                        )}
                                        {hasAccess(PERMISSIONS.DELETED_RIDER, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.RIDER_PROFILE_MANAGEMENT.DELETED_RIDERS}
                                                >
                                                    {/* <i className="fas fa-user mr-3" /> */}
                                                    Deleted Riders
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}

                        {this.hasRole(['clientUser', 'clientAdmin', 'superAdmin']) &&
                            (hasAccess(PERMISSIONS.USER_ACCOUNT, ['View']) ||
                                hasAccess(PERMISSIONS.DELETED_USERS, ['View']) ||
                                hasAccess(PERMISSIONS.ROLE_PERMISSION, ['View'])) && (
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="fas fa-users"></i>
                                        <span className="ml-1">User Management</span>
                                    </Link>
                                    <ul className="sub-menu" aria-expanded="false">
                                        {hasAccess(PERMISSIONS.USER_ACCOUNT, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.USER_PROFILE_MANAGEMENT.DEFAULT}
                                                >
                                                    {/* <i className="fas fa-user mr-3" /> */}
                                                    Users
                                                </Link>
                                            </li>
                                        )}
                                        {hasAccess(PERMISSIONS.ROLE_PERMISSION, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.ROLE_PERMISSION.DEFAULT}
                                                >
                                                    {/* <i className="fas fa-user mr-3" /> */}
                                                    Role Permission
                                                </Link>
                                            </li>
                                        )}
                                        {hasAccess(PERMISSIONS.DELETED_USERS, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.DELETED_USERS.DEFAULT}
                                                >
                                                    {/* <i className="fas fa-user mr-3" /> */}
                                                    Deleted Users
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}

                        {this.hasRole(['clientAdmin', 'superAdmin', 'clientUser']) &&
                            (hasAccess(PERMISSIONS.MERCHANTS, ['View']) ||
                                hasAccess(PERMISSIONS.DELETED_MERCHANT, ['View']) ||
                                hasAccess(PERMISSIONS.MERCHANT_LOCATION, ['View']) ||
                                hasAccess(PERMISSIONS.DELETED_MERCHANT_LOCATION, ['View'])) && (
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="fas fa-user-tie"></i>
                                        <span className="ml-1">Merchants</span>
                                    </Link>
                                    <ul className="sub-menu" aria-expanded="false">
                                        {hasAccess(PERMISSIONS.MERCHANTS, ['View']) && !IS_MERCHANT_VIEW() && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.MERCHANT.DEFAULT}
                                                >
                                                    {/* <i className="fas fa-user mr-3" /> */}
                                                    Merchant Profiles
                                                </Link>
                                            </li>
                                        )}
                                        {hasAccess(PERMISSIONS.DELETED_MERCHANT, ['View']) && !IS_MERCHANT_VIEW() && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.MERCHANT.MERCHANT_DELETED}
                                                >
                                                    {/* <i className="fas fa-user mr-3" /> */}
                                                    Deleted Merchants
                                                </Link>
                                            </li>
                                        )}
                                        {hasAccess(PERMISSIONS.MERCHANT_LOCATION, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.MERCHANT.MERCHANT_LOCATION_LIST}
                                                >
                                                    {/* <i className="fas fa-user mr-3" /> */}
                                                    Merchant Locations
                                                </Link>
                                            </li>
                                        )}
                                        {hasAccess(PERMISSIONS.DELETED_MERCHANT_LOCATION, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.MERCHANT.MERCHANT_LOCATION_DELETED}
                                                >
                                                    {/* <i className="fas fa-user mr-3" /> */}
                                                    Deleted Merchant Location
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}

                        {this.hasRole(['clientAdmin', 'superAdmin', 'clientUser']) &&
                            (hasAccess(PERMISSIONS.WAREHOUSE, ['View']) ||
                                hasAccess(PERMISSIONS.PRODUCT_MASTER_DATA, ['View']) ||
                                hasAccess(PERMISSIONS.STOCKS_RECEIVING, ['View']) ||
                                hasAccess(PERMISSIONS.STOCK_ADJUSTMENT, ['View']) ||
                                hasAccess(PERMISSIONS.INVENTORY_REPORT, ['View'])) && (
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="fas fa-warehouse"></i>
                                        <span className="ml-1">Inventory</span>
                                    </Link>
                                    <ul className="sub-menu" aria-expanded="false">
                                        {hasAccess(PERMISSIONS.WAREHOUSE, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.WAREHOUSE_SITE.DEFAULT}
                                                >
                                                    Warehouse
                                                </Link>
                                            </li>
                                        )}
                                        {hasAccess(PERMISSIONS.PRODUCT_MASTER_DATA, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.PRODUCT_MASTER_DATA.DEFAULT}
                                                >
                                                    Product Master Data
                                                </Link>
                                            </li>
                                        )}
                                        {hasAccess(PERMISSIONS.STOCKS_RECEIVING, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.STOCKS_RECEIVING.DEFAULT}
                                                >
                                                    Stocks Receiving
                                                </Link>
                                            </li>
                                        )}
                                        {hasAccess(PERMISSIONS.STOCKS_RECEIVING, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.STOCKS_RECEIVING.DELETED_LIST}
                                                >
                                                    Deleted Stocks Receiving
                                                </Link>
                                            </li>
                                        )}
                                        {hasAccess(PERMISSIONS.STOCK_ADJUSTMENT, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.STOCK_ADJUSTMENT.DEFAULT}
                                                >
                                                    Stocks Adjustments
                                                </Link>
                                            </li>
                                        )}
                                        {hasAccess(PERMISSIONS.INVENTORY_REPORT, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.INVENTORY_REPORT.DEFAULT}
                                                >
                                                    Inventory Report
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}

                        {this.hasRole(['clientUser', 'clientAdmin', 'superAdmin']) &&
                            (hasAccess(PERMISSIONS.BILLING, ['View']) ||
                                hasAccess(PERMISSIONS.RIDER_COMMISSION, ['View'])) && (
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="fas fa-users"></i>
                                        <span className="ml-1">Payments</span>
                                    </Link>
                                    <ul className="sub-menu" aria-expanded="false">
                                        {hasAccess(PERMISSIONS.BILLING, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.PAYMENTS.BILLING_LIST}
                                                >
                                                    Billing
                                                </Link>
                                            </li>
                                        )}
                                        {hasAccess(PERMISSIONS.RIDER_COMMISSION, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.PAYMENTS.RIDER_COMMISSIONS_LIST}
                                                >
                                                    Rider Commissions
                                                </Link>
                                            </li>
                                        )}
                                        {hasAccess(PERMISSIONS.RIDER_COMMISSION, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={PATHS.PAYMENTS.EARNING_SUMMARY}
                                                >
                                                    Earning Summary
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}
                        {hasAccess(PERMISSIONS.LABEL_PRINT_SHIPPING_LABEL, ['View']) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="fas fa-print"></i>
                                    <span className="ml-1">Label Print</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link
                                            className="list-item"
                                            onClick={() => {
                                                this.toggleMenuCallback();
                                            }}
                                            to={`/${PATHS.LABEL_PRINT.SHIPPING_LABEL.LIST_VIEW}`}
                                        >
                                            Shipping Label
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            className="list-item"
                                            onClick={() => {
                                                this.toggleMenuCallback();
                                            }}
                                            to={`/${PATHS.LABEL_PRINT.ARCHIVED_BATCH_PRINTS.DEFAULT}`}
                                        >
                                            Archived Batch Print
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}
                        {hasAccessDeveloperMenu() && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="fas fa-code"></i>
                                    <span className="ml-1">Developer</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    {this.hasRole([clientUser, clientAdmin, superAdmin]) &&
                                        hasAccess(PERMISSIONS.PARTNER_API_SANDBOX_ACCOUNTS, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={`/${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.LIST}`}
                                                >
                                                    Sandbox Accounts
                                                </Link>
                                            </li>
                                        )}
                                    {this.hasRole([clientUser, clientAdmin, superAdmin]) &&
                                        hasAccess(PERMISSIONS.PARTNER_API_CLIENT_API_DASHBOARD, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={`/${PATHS.DEVELOPER.API_DASHBOARD}`}
                                                >
                                                    API Dashboard
                                                </Link>
                                            </li>
                                        )}
                                    {this.hasRole(['superAdmin', 'clientAdmin', 'clientUser']) &&
                                        hasAccess(PERMISSIONS.PARTNER_API_API_SERVICE, ['View']) && (
                                            <li>
                                                <Link
                                                    className="list-item"
                                                    onClick={() => {
                                                        this.toggleMenuCallback();
                                                    }}
                                                    to={`/${PATHS.DEVELOPER.API_SERVICE}`}
                                                >
                                                    API Service
                                                </Link>
                                            </li>
                                        )}
                                    {hasAccessApiPortal() && (
                                        <li>
                                            <Link
                                                className="list-item"
                                                onClick={() => {
                                                    this.toggleMenuCallback();
                                                }}
                                                to={`/${PATHS.DEVELOPER.API_PORTAL}`}
                                            >
                                                API Portal
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                        {this.hasRole(['clientAdmin', 'superAdmin', 'clientUser']) &&
                            hasAccess(PERMISSIONS.EXTERNAL_SYSTEM_CONNECTION, ['View']) && (
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="fas fa-cog"></i>
                                        <span className="ml-1">Settings</span>
                                    </Link>
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li>
                                            <Link to="/#" className="has-arrow waves-effect">
                                                <span className="">Delivery</span>
                                            </Link>
                                            <ul className="sub-menu" aria-expanded="false">
                                                {hasAccess(PERMISSIONS.EXTERNAL_SYSTEM_CONNECTION, ['View']) && (
                                                    <li>
                                                        <Link
                                                            className="list-item"
                                                            onClick={() => {
                                                                this.toggleMenuCallback();
                                                            }}
                                                            to={PATHS.SETTINGS.DELIVERY.EXTERNAL_SYSTEM_CONNECTIONS}
                                                        >
                                                            External System Connections
                                                        </Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            )}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({ ...state.Layout });

SidebarContent.propTypes = {
    changeSidebarType: PropTypes.any,
    type: PropTypes.any,
    location: PropTypes.any,
    t: PropTypes.any,
};

export default withRouter(
    connect(mapStateToProps, {
        changeLayout,
        changeSidebarTheme,
        changeSidebarType,
        changeLayoutWidth,
        changePreloader,
        toggleRightSidebar,
    })(withNamespaces()(SidebarContent))
);
