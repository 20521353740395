/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { motion } from 'framer-motion';
import moment from 'moment';

// #region imports
import { SubTitle } from 'styles';
import { isObject } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import { defaultTableHeaders } from './constants';

function StocksReceivingReportComponent(props) {
    const { reportData } = props;

    const { ExportCSVButton } = CSVExport;

    const [allRows, setAllRows] = useState([]);

    useEffect(() => {
        if (reportData && reportData.docs && Array.isArray(reportData.docs)) {
            // eslint-disable-next-line no-unused-vars
            const allProductMasterDataIds = [];
            const mappedReportData = [];

            reportData.docs.map((val) => {
                const item = val;
                // if (val.productMasterDataId && isObject(val.productMasterDataId)) {
                //     if (!allProductMasterDataIds.includes(val.productMasterDataId._id.toString())) {
                //         allProductMasterDataIds.push(val.productMasterDataId._id.toString());
                //     }
                // }

                const itemObject = {
                    merchantName: '',
                    merchantNamePicture: '',
                    warehouseSiteName: '',
                    productDescription: '',
                    upc: '',
                    productMasterDataReferenceNumber: '',
                    skuCode: '',
                    unitOfMeasure: '',
                    stocksReceivingReference: '',
                    stocksReceivedDateTime: '',
                    additionalDetails: '',
                    deliveryNoteReference: '',
                    stocksFrom: '',
                    quantity: 0,
                };

                // Warehouse
                if (item.warehouseSiteId && isObject(item.warehouseSiteId)) {
                    itemObject.warehouseSiteName = item.warehouseSiteId.siteName
                        ? `${item.warehouseSiteId.referenceNumber || ''}-${item.warehouseSiteId.siteName}`
                        : '';
                }

                // Merchant
                if (item.merchantId && isObject(item.merchantId)) {
                    itemObject.merchantName = item.merchantId.name || '';
                    itemObject.merchantPictureUrl =
                        item.merchantId && isObject(item.merchantId) ? item.merchantId.merchantPictureUrl : null;
                    itemObject.merchantNamePicture = `${item.merchantId.name}||${
                        item.merchantId.merchantPictureUrl || ''
                    }`;
                }

                // Stocks From
                if (item.stocksReceivingId && isObject(item.stocksReceivingId)) {
                    itemObject.stocksFrom = item.stocksReceivingId.stocksFrom || '';
                }

                // Delivery Note Reference
                if (item.stocksReceivingId && isObject(item.stocksReceivingId)) {
                    itemObject.deliveryNoteReference = item.stocksReceivingId.deliveryNoteReference || '';
                }

                // Additional Details
                if (item.stocksReceivingId && isObject(item.stocksReceivingId)) {
                    itemObject.additionalDetails = item.stocksReceivingId.additionalDetails || '';
                }

                // Stocks Received Date time
                if (item.stocksReceivingId && isObject(item.stocksReceivingId)) {
                    itemObject.stocksReceivedDateTime = item.stocksReceivingId.confirmedAt
                        ? moment(item.stocksReceivingId.confirmedAt).format('YYYY-MM-DD - hh:mm:a')
                        : '';
                }

                // Stocks Receiving Reference
                if (item.stocksReceivingId && isObject(item.stocksReceivingId)) {
                    itemObject.stocksReceivingReference = item.stocksReceivingId.referenceNumber || '';
                }

                if (item.productMasterDataId && isObject(item.productMasterDataId)) {
                    itemObject.productDescription = item.productMasterDataId.productDescription || '';
                    itemObject.productMasterDataReferenceNumber = item.productMasterDataId.referenceNumber || '';
                    itemObject.upc = item.productMasterDataId.upc || '';
                    itemObject.skuCode = item.productMasterDataId.skuCode || '';
                    itemObject.unitOfMeasure = item.productMasterDataId.unitOfMeasure || '';
                }

                itemObject.quantity = item.quantity || '';

                mappedReportData.push(itemObject);

                return val;
            });

            setAllRows(mappedReportData);
        }
    }, [reportData]);

    return (
        <>
            <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                <CardBody>
                    <div
                        style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'col' }}>
                            <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}>
                                Stocks Receiving Report
                            </SubTitle>
                        </div>
                    </div>
                    <hr />
                    <ToolkitProvider
                        keyField="_id"
                        data={allRows}
                        columns={defaultTableHeaders}
                        exportCSV={{
                            fileName: 'Stocks Receiving Report.csv',
                            // separator: '|',
                            // ignoreHeader: true,
                            // noAutoBOM: false,
                            // blobType: 'text/csv;charset=ansi',
                        }}
                    >
                        {(tableProps) => (
                            <div>
                                {allRows.length > 0 && (
                                    <Row className="d-flex justify-content-end">
                                        {hasAccess(PERMISSIONS.INVENTORY_REPORT, ['Export']) && (
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 pt-0 pb-0"
                                            >
                                                <ExportCSVButton {...tableProps.csvProps}>
                                                    {' '}
                                                    <span style={{ color: '#FFF' }}> Export</span>
                                                </ExportCSVButton>
                                            </motion.button>
                                        )}
                                    </Row>
                                )}

                                <hr />
                                <BootstrapTable {...tableProps.baseProps} />
                            </div>
                        )}
                    </ToolkitProvider>
                </CardBody>
            </Card>
        </>
    );
}

StocksReceivingReportComponent.propTypes = {
    reportData: PropTypes.any,
};

export default StocksReceivingReportComponent;
