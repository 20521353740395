// @flow
import {
    RESTORE_RIDER_START,
    RESTORE_RIDER_END,
    PERMANENT_DELETE_RIDER_END,
    PERMANENT_DELETE_RIDER_START,
} from './actionTypes';

const INIT_STATE = {
    restoreRiderData: null,
    permanentDeleteRiderData: null,
};

const DeletedRidersReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESTORE_RIDER_START:
            return {
                ...state,
                restoreRiderData: action.payload,
            };
        case RESTORE_RIDER_END:
            return {
                ...state,
                restoreRiderData: null,
            };
        case PERMANENT_DELETE_RIDER_START:
            return {
                ...state,
                permanentDeleteRiderData: action.payload,
            };
        case PERMANENT_DELETE_RIDER_END:
            return {
                ...state,
                permanentDeleteRiderData: null,
            };
        default:
            return state;
    }
};

export default DeletedRidersReducer;
