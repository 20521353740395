/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';

// #region assets | components
import { CustomToast } from 'components';

// #region services
import { getMerchantsByClientIdService } from 'services/merchant.service';

// #region utils
import { capitalizeWords, getDefaultValueForSelect } from 'utils/helpers';
import { getVisitingObject } from 'utils/checkAuth';

// #region constants
import { VEHICLE_TYPES } from 'constants/constants';

// #endregion imports
import { deliveryTypesInitialValues } from 'components/Modals/Route-Planning/Modules/containers/ListTable/constants';

// #endregion services
import { getCitiesListService, getMerchantLocationService, getUnPlannedDeliveryService } from './services';

// #endregion utils
import { buildDeliverySearchData, selectMerchantsHelper, validateRoutePlanningForm } from './utils';

// #endregion constants
import { RoutePlanningFormPropTypes } from '../../../../constants';
import { deliveryTypeOptions } from './constants';
import RoutePlanningFormBody from './FormBody';

/**  Initial values for the formData based on business logic */
const initialValues = {
    vehicleType: VEHICLE_TYPES.MOTOR_BIKE,
    deliveryDate: new Date(),
    isPickupReadyOnly: true,
    isSelectAllDeliveryTypes: true,
    isSelectAllUnplannedDeliveries: true,
    isSelectAllMerchants: false,
    isDisplayDeliveryLocations: false,
    isDisplayPickupLocations: false,
};

/**
 * This modal contains the form functions and logics of the route planning module
 * @param {RoutePlanningFormPropTypes} props
 */
function RoutePlanningForm(props) {
    const { mapGridDeliveries, pickupDeliverySetting } = props;

    // Getting client Information
    const { visitingClient } = getVisitingObject();

    const _isMounted = useRef(true);

    // FormData
    const [formData, setFormData] = useState({ ...initialValues });

    /** Dropdown selected value arrays for deliveryTypes, merchants and merchantLocations */
    const [selectedDeliveryTypes, setSelectedDeliveryTypes] = useState([]);
    const [selectedMerchants, setSelectedMerchants] = useState([]);
    const [selectedMerchantLocations, setSelectedMerchantLocations] = useState([]);
    const [citiesList, setCitiesList] = useState([]);

    const [pickupCityList, setPickupCityList] = useState([]);
    const [deliveryCityList, setDeliveryCityList] = useState([]);

    const [stateList, setStateList] = useState([]);

    /** Holds all the selected unplanned deliveries */
    const [selectedUnPlannedDeliveryList, setSelectedUnPlannedDeliveryList] = useState([]);

    /** all dropdown options for merchant location and merchants */
    const [allMerchantLocations, setAllMerchantLocations] = useState([]);
    const [allMerchantIds, setAllMerchantIds] = useState([]);
    const [allUnPlannedDeliveryList, setAllUnPlannedDeliveryList] = useState([]);

    /** submitted and validated states */
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);

    /** state of the form */
    const [isEditable, setIsEditable] = useState(false);

    /** Whether the submit operation is in progress */
    const [formLoading, setFormLoading] = useState(false);
    /** Whether the merchant dropdown data loading is in progress */
    const [customLoading, setCustomLoading] = useState({
        merchantsLoading: false,
        citiesLoading: false,
        deliveriesLoading: false,
    });

    //* * scootix confirmation modal */
    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        confirmMessage: 'Yes',
        cancelMessage: 'No',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    /** states for showing warning modal based on business logics */
    const [showWarningModal, setShowWarningModal] = useState(false);

    /** states for showing confirmation modal of start over based on business logics */
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    /** useEffect dependency array - which use in useEffect hook that loading the unplanned delivery data */
    const dependencyArray = [
        selectedDeliveryTypes,
        selectedMerchants,
        selectedMerchantLocations,
        formData.vehicleType,
        formData.isPickupReadyOnly,
        formData.deliveryCity,
        formData.pickupCity,
        formData.deliveryDate,
        formData.deliveryReadyTime,
        formData.isSelectAllUnplannedDeliveries,
    ];

    /** Load pickup and delivery cities */
    useEffect(() => {
        loadPickupAndDeliveryCities();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    /**
     * Side Effect - load all the delivery types to the dropdown by checking "Select All Delivery Types"
     * Side Effect - load all the merchants to the dropdown by checking "Select All Merchants"
     */
    useEffect(() => {
        loadAllMerchants();
        if (formData.isSelectAllDeliveryTypes) {
            setSelectedDeliveryTypes(deliveryTypeOptions);
        }
    }, [formData.isSelectAllDeliveryTypes, formData.isSelectAllMerchants]);

    /**
     * Set all the merchant Location to the dropdown only when already selected a merchant
     */
    useEffect(() => {
        if (selectedMerchants) {
            loadMerchantLocations();
        }
    }, [selectedMerchants]);

    /** delivery type checkbox states upon selected delivery type change */
    useEffect(() => {
        if (selectedDeliveryTypes.length < deliveryTypesInitialValues.docs.length) {
            setFormData({
                ...formData,
                isSelectAllDeliveryTypes: false,
            });
        }

        /**
         * get count of merchants where isLimitDeliveriesToMerchantRider: false
         * this is the selectAllMerchant count
         */
        const selectedAllMerchantsCount = allMerchantIds.filter(
            (obj) => obj.isLimitDeliveriesToMerchantRider === false
        ).length;

        // uncheck the `Select All Merchants` check box if items removed
        if (selectedMerchants.length < selectedAllMerchantsCount) {
            setFormData({
                ...formData,
                isSelectAllMerchants: false,
            });
        }
    }, [selectedDeliveryTypes, selectedMerchants]);

    /** Load Unplanned Deliveries */
    useEffect(() => {
        loadUnplannedDeliveries();
    }, [...dependencyArray]);

    /** Handle Submit */
    const handleSubmit = (event) => {
        event.persist();
        const { isFormValid, message } = validateRoutePlanningForm(
            formData,
            selectedUnPlannedDeliveryList,
            selectedMerchants,
            allUnPlannedDeliveryList
        );
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }
        setFormLoading(true);
        /** if user checked isSelectAllUnplannedDeliveries, we are assigning all deliveries to the selectedUnPlannedDeliveryList */
        if (formData.isSelectAllUnplannedDeliveries) {
            const mappedAllUnplannedDeliveries = allUnPlannedDeliveryList.map((x, index) => ({ ...x, id: index + 1 }));
            mapGridDeliveries(mappedAllUnplannedDeliveries);
        } else {
            const mappedSelectedUnplannedDeliveries = selectedUnPlannedDeliveryList.map((x, index) => ({
                ...x,
                id: index + 1,
            }));
            mapGridDeliveries(mappedSelectedUnplannedDeliveries);
        }
        setSubmitted(true);
        setValidated(true);
        setIsEditable(true);
        pickupDeliverySetting(formData.isDisplayPickupLocations, formData.isDisplayDeliveryLocations);
        setFormLoading(false);
    };

    /** Reset the form once user clicked on start over */
    const resetForm = () => {
        setSubmitted(false);
        setValidated(false);
        setIsEditable(false);
        setSelectedUnPlannedDeliveryList([]);
        setSelectedMerchants([]);
        setSelectedMerchantLocations([]);
        setFormData({ ...initialValues });
        mapGridDeliveries([]);
        toggleStartOverConfirmationModal();
    };

    /**  get all pickup and delivery cities by country iso code */
    const loadPickupAndDeliveryCities = async () => {
        setCustomLoading({ citiesLoading: true });
        if (visitingClient?.countryIsoCode) {
            const { data } = await getCitiesListService(visitingClient.countryIsoCode);
            if (_isMounted.current && data) {
                const cityList = data
                    .map((x) => ({ ...x, label: capitalizeWords(x.name), value: x.name }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                const uniqueStatesSet = new Set();
                for (const obj of cityList) {
                    uniqueStatesSet.add(obj.state);
                }

                const uniqueStates = Array.from(uniqueStatesSet)
                    .map((state) => ({ label: capitalizeWords(state), value: state }))
                    .sort((a, b) => a.value.localeCompare(b.value));
                setStateList(uniqueStates);

                // sorting the cities according to the alphabetical order
                setCitiesList(cityList);
            }
        }
        setCustomLoading({ citiesLoading: false });
    };

    /**  get all pickup and delivery cities by country iso code */
    const loadMerchantLocations = async () => {
        if (selectedMerchants.length > 0) {
            const merchantIds = selectedMerchants.map((obj) => obj.value);
            const { data } = await getMerchantLocationService({ merchants: merchantIds });
            if (_isMounted.current && data) {
                setAllMerchantLocations(
                    data.map((x) => ({ ...x, label: `${x.merchantLocationName} - ${x.location}`, value: x._id }))
                );
            }
        }
    };

    /**  load unplanned deliveries based on filters */
    const loadUnplannedDeliveries = async () => {
        setCustomLoading({ deliveriesLoading: true });
        /**  build filter data */
        const queryOptions = buildDeliverySearchData(
            formData,
            selectedDeliveryTypes,
            selectedMerchants,
            selectedMerchantLocations
        );
        if (formData.vehicleType) {
            const { data } = await getUnPlannedDeliveryService(queryOptions);
            if (_isMounted.current && data) {
                setAllUnPlannedDeliveryList(
                    data.map((x) => ({ ...x, label: `${x.referenceNumber} - ${x.merchantOrderNo}`, value: x._id }))
                );
            }
        }
        setCustomLoading({ deliveriesLoading: false });
    };

    /**  get all merchant data by client id */
    const loadAllMerchants = async () => {
        setCustomLoading({ merchantsLoading: true });

        if (visitingClient?._id) {
            const { data } = await getMerchantsByClientIdService(visitingClient._id);
            if (_isMounted.current && data.docs) {
                setAllMerchantIds(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                if (formData.isSelectAllMerchants) {
                    /** added only merchants to the list who's isMerchantLimitRiders is false */
                    setSelectedMerchants(
                        data.docs
                            .filter((x) => !x.isLimitDeliveriesToMerchantRider)
                            .map((x) => ({ ...x, label: x.name, value: x._id }))
                    );
                }
            }
        }
        setCustomLoading({ merchantsLoading: false });
    };

    /** Handle scootix confirmation modal */
    const handleDeliveriesRelevantToMerchant = (id) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: id,
            message: `Once you remove the selected merchant all selected deliveries relate to that merchant also be discarded from the list, Are you Sure ?`,
            onConfirm: (data) => discardDeliveries(data),
        });
    };

    /** Handle scootix confirmation modal */
    const handleFormReset = () => {
        toggleStartOverConfirmationModal();
    };

    /** once we remove the the selected merchant from the list if there is already selected deliveries for
     * that merchant need to remove from the selected delivery list
     * @param merchantId
     */
    const discardDeliveries = (id) => {
        if (id) {
            const remainingDeliveries = selectedUnPlannedDeliveryList.filter((obj) => obj.merchantId !== id);
            const remainingMerchants = selectedMerchants.filter((obj) => obj._id !== id);
            setSelectedUnPlannedDeliveryList(remainingDeliveries);
            setSelectedMerchants(remainingMerchants);
            setScootixModalState({ show: false, data: null });
        } else {
            /** if we remove all the data in the merchant section, we pass id as null */
            setSelectedUnPlannedDeliveryList([]);
            setSelectedMerchants([]);
            setScootixModalState({ show: false, data: null });
        }
    };

    /**  validate the merchant location dropdown click */
    const handleDropdownClick = () => {
        if (selectedMerchants.length === 0) {
            toast.custom(
                (t) => (
                    <CustomToast text="Select a merchant first to search for merchant locations" t={t} type="warning" />
                ),
                {
                    position: 'top-right',
                }
            );
        }
    };

    /**  Dropdown select handler */
    const handleSelectChange = (event, id) => {
        if (selectedUnPlannedDeliveryList.length > 0 && id !== 'unplannedDelivery' && id !== 'merchants') {
            toggleWarningModal();
        }
        if (event) {
            if (id === 'deliveryTypes') {
                setSelectedDeliveryTypes(event);
            } else if (id === 'merchants') {
                if (selectedMerchants.length < event.length) {
                    // this is an adding action
                    selectMerchantsHelper(event, selectedMerchants, setSelectedMerchants);
                } else if (selectedMerchants.length > event.length) {
                    // this is an removing action
                    const removingMerchant = selectedMerchants.filter(
                        (obj1) => !event.some((obj2) => obj2._id === obj1._id)
                    );
                    handleDeliveriesRelevantToMerchant(removingMerchant[0]._id);
                }
            } else if (id === 'merchantLocations') {
                setSelectedMerchantLocations(event);
            } else if (id === 'unplannedDelivery') {
                setSelectedUnPlannedDeliveryList(event);
            } else if (id === 'pickupState') {
                const { value } = event;
                const filteredCities = citiesList.filter((x) => x.state === value);
                setPickupCityList(filteredCities);
            } else if (id === 'deliveryState') {
                const { value } = event;
                const filteredCities = citiesList.filter((x) => x.state === value);
                setDeliveryCityList(filteredCities);
            }
        } else if (id === 'deliveryTypes') {
            setSelectedDeliveryTypes([]);
        } else if (id === 'merchants') {
            handleDeliveriesRelevantToMerchant(null);
        } else if (id === 'merchantLocations') {
            setSelectedMerchantLocations([]);
        } else if (id === 'unplannedDelivery') {
            setSelectedUnPlannedDeliveryList([]);
        } else if (id === 'pickupState') {
            setPickupCityList([]);
            setFormData();
        } else if (id === 'deliveryState') {
            setDeliveryCityList([]);
        }

        if (!event) {
            if (id === 'pickupState') {
                setPickupCityList([]);
                setFormData({
                    ...formData,
                    [id]: null,
                    pickupCity: null,
                });
                return;
            }
            if (id === 'deliveryState') {
                setDeliveryCityList([]);
                setFormData({
                    ...formData,
                    [id]: null,
                    deliveryCity: null,
                });
                return;
            }
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    /**  Checkbox state handler */
    const handleCheckChange = (event) => {
        const { id, checked } = event.target;
        if (
            selectedUnPlannedDeliveryList.length > 0 &&
            id !== 'isSelectAllUnplannedDeliveries' &&
            id !== 'isDisplayPickupLocations' &&
            id !== 'isDisplayDeliveryLocations'
        ) {
            toggleWarningModal();
        }
        setFormData({
            ...formData,
            [id]: checked,
        });
    };

    /**  Select date handler */
    const handleDateChange = (event) => {
        const { id, value } = event.target;
        if (selectedUnPlannedDeliveryList.length > 0) {
            toggleWarningModal();
        }
        setFormData({
            ...formData,
            [id]: value ? new Date(value) : null,
        });
    };

    /**  Remove all handler */
    // const handleRemoveAll = () => {
    //     setFormData({
    //         ...formData,
    //         isSelectAllDeliveryTypes: false,
    //     });
    //     setSelectedUnPlannedDeliveryList([]);
    // };

    /**  toggle warning model */
    const toggleWarningModal = () => {
        setShowWarningModal(!showWarningModal);
    };

    /**  toggle warning model */
    const toggleStartOverConfirmationModal = () => {
        setShowConfirmationModal(!showConfirmationModal);
    };

    /** Opens the scootix confirmation modal */
    const toggleScootixModal = () => {
        setScootixModalState({ show: !scootixModalState.show, data: null });
    };

    /**  Memo handlers */
    const getSelectCity = (value) => {
        if (value) {
            const relevantObj = citiesList.find((x) => x.value === value);
            if (relevantObj) {
                return { label: capitalizeWords(relevantObj.label), value: relevantObj.value };
            }
        }
        return null;
    };

    const getSelectState = (value) => {
        if (value) {
            const relevantObj = stateList.find((x) => x.value === value);
            if (relevantObj) {
                return { label: capitalizeWords(relevantObj.label), value: relevantObj.value };
            }
        }
        return null;
    };

    // ** Memos
    const selectedVehicleType = useMemo(() => getDefaultValueForSelect(formData.vehicleType), [formData.vehicleType]);

    const selectedPickupState = useMemo(() => getSelectState(formData.pickupState), [formData.pickupState]);
    const selectedDeliveryState = useMemo(() => getSelectState(formData.deliveryState), [formData.deliveryState]);
    const selectedPickupCity = useMemo(() => getSelectCity(formData.pickupCity), [formData.pickupCity]);
    const selectedDeliveryCity = useMemo(() => getSelectCity(formData.deliveryCity), [formData.deliveryCity]);

    /** All common props for RoutePlaningFormBody page */
    const commonProps = {
        formData,
        setFormData,
        handleSelectChange,
        handleCheckChange,
        handleDateChange,
        selectedVehicleType,
        selectedPickupCity,
        selectedDeliveryCity,
        selectedDeliveryTypes,
        allMerchantIds,
        selectedMerchants,
        allMerchantLocations,
        handleDropdownClick,
        selectedMerchantLocations,
        formLoading,
        submitted,
        validated,
        handleSubmit,
        setSelectedDeliveryTypes,
        setSelectedMerchants,
        allUnPlannedDeliveryList,
        selectedUnPlannedDeliveryList,
        setSelectedUnPlannedDeliveryList,
        customLoading,
        toggleWarningModal,
        showWarningModal,
        scootixModalState,
        toggleScootixModal,
        isEditable,
        handleFormReset,
        toggleStartOverConfirmationModal,
        showConfirmationModal,
        resetForm,
        selectedPickupState,
        selectedDeliveryState,
        deliveryCityList,
        pickupCityList,
        stateList,
    };

    return (
        <>
            <RoutePlanningFormBody {...commonProps} />
        </>
    );
}

RoutePlanningForm.propTypes = RoutePlanningFormPropTypes;
export default RoutePlanningForm;
