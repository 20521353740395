/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Avatar from 'react-avatar';

import { getAttachmentByKey } from 'services/attachment.service';
import { AUTH_USER, IS_USER_PROFILE_PIC_IMAGE_CHECKED, USER_PROFILE_PIC } from 'constants/local-storage.constants';

import { encryptedLocalStorage } from 'utils/local-storage';

// i18n
import { withNamespaces } from 'react-i18next';

// users
import paths from 'routes/paths';

class ProfileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            isImageErrorHandled: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    toggle() {
        this.setState((prevState) => ({
            menu: !prevState.menu,
        }));
    }

    handleOnProfileImageError() {
        const tempCheck = window.sessionStorage.getItem(IS_USER_PROFILE_PIC_IMAGE_CHECKED);
        if (this.state.isImageErrorHandled === false && !tempCheck) {
            getAttachmentByKey(this.props.userData.profilePictureUrl).then((res) => {
                if (this.mounted) {
                    this.props.setImages({ ...this.props.images, profilePictureUrl: res });
                    this.setState((prevState) => ({
                        isImageErrorHandled: true,
                    }));
                    window.sessionStorage.setItem(IS_USER_PROFILE_PIC_IMAGE_CHECKED, true);
                    window.sessionStorage.setItem(USER_PROFILE_PIC, res);
                }
            });
        }
    }

    render() {
        let fullName = '';
        let email = '';

        const userObject = encryptedLocalStorage.getItem(AUTH_USER);
        if (userObject) {
            if (userObject && userObject.fullName) {
                fullName = userObject.fullName;
                email = userObject.email;
            }
        }

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block user-dropdown">
                    <DropdownToggle
                        tag="button"
                        className="btn header-item waves-effect"
                        id="page-header-user-dropdown"
                    >
                        {this.props.profilePictureUrl && this.mounted && (
                            <img
                                height={1}
                                width={1}
                                src={this.props.profilePictureUrl}
                                onError={() => this.handleOnProfileImageError()}
                            />
                        )}
                        {this.mounted && (
                            <Avatar
                                className="image-upload-avatar"
                                name={this.props.userData.fullName}
                                size="40"
                                round="50px"
                                src={this.props.profilePictureUrl}
                            />
                        )}

                        {/* <div>
                            <span className="d-none d-xl-inline-block ml-1 text-transform">{username}</span>
                            <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
                        </div> */}
                    </DropdownToggle>
                    <DropdownMenu right className="user-dropdown-menu" style={{ width: 200 }}>
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ fontSize: 16, fontWeight: 'bold' }}>{fullName || ''}</div>
                            <div style={{ fontSize: 14 }}>{email || ''}</div>
                        </div>
                        <DropdownItem divider />

                        <DropdownItem href={paths.USER_PROFILE.DEFAULT}>
                            <i className="ri-user-line align-middle mr-1"></i> {this.props.t('Profile')}
                        </DropdownItem>
                        {/* <DropdownItem href="#">
                            <i className="ri-wallet-2-line align-middle mr-1"></i> {this.props.t('My Wallet')}
                        </DropdownItem>
                        <DropdownItem className="d-block" href="#">
                            <span className="badge badge-success float-right mt-1">11</span>
                            <i className="ri-settings-2-line align-middle mr-1"></i> {this.props.t('Settings')}
                        </DropdownItem>
                        <DropdownItem href="#">
                            <i className="ri-lock-unlock-line align-middle mr-1"></i> {this.props.t('Lock screen')}
                        </DropdownItem> */}
                        <DropdownItem divider />
                        <DropdownItem className="text-danger" href="/logout">
                            <i className="ri-shut-down-line align-middle mr-1 text-danger"></i> {this.props.t('Logout')}
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(ProfileMenu);
