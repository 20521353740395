import {
    CREATE_RIDER_COMMISSION,
    GET_ALL_RIDER_COMMISSIONS_BY_RIDER_ID,
    UPDATE_RIDER_COMMISSION,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const createRiderCommissionService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_RIDER_COMMISSION).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const updateRiderCommissionService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_RIDER_COMMISSION).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getAllRiderCommissionsByRiderId = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_RIDER_COMMISSIONS_BY_RIDER_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
