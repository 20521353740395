import React from 'react';
import PropTypes from 'prop-types';

function PhoneVolume(props) {
    const { color, width, height } = props;
    return (
        <svg
            width={width || 16}
            height={height || 16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_5697_745)">
                <path
                    d="M3.33333 2.66602H6L7.33333 5.99935L5.66667 6.99935C6.38064 8.44704 7.55231 9.61871 9 10.3327L10 8.66602L13.3333 9.99935V12.666C13.3333 13.0196 13.1929 13.3588 12.9428 13.6088C12.6928 13.8589 12.3536 13.9993 12 13.9993C9.39951 13.8413 6.94677 12.737 5.10455 10.8948C3.26234 9.05258 2.15803 6.59984 2 3.99935C2 3.64573 2.14048 3.30659 2.39052 3.05654C2.64057 2.80649 2.97971 2.66602 3.33333 2.66602"
                    stroke={color || '#fff'}
                    strokeWidth="0.666667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 4.66602C10.3536 4.66602 10.6928 4.80649 10.9428 5.05654C11.1929 5.30659 11.3333 5.64573 11.3333 5.99935"
                    stroke={color || '#fff'}
                    strokeWidth="0.666667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 2C11.0609 2 12.0783 2.42143 12.8284 3.17157C13.5786 3.92172 14 4.93913 14 6"
                    stroke={color || '#fff'}
                    strokeWidth="0.666667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5697_745">
                    <rect width={width || 16} height={width || 16} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

PhoneVolume.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default PhoneVolume;
