import { CREATE_BILLING, GET_ALL_BILLINGS_BY_MERCHANT_ID, UPDATE_BILLING } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const createBillingService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_BILLING).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const updateBillingService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_BILLING).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getAllBillingsByMechantId = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_BILLINGS_BY_MERCHANT_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
