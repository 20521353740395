/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { motion } from 'framer-motion';

// #region imports
import { SubTitle } from 'styles';
import { isObject } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import { defaultTableHeaders } from './constants';

function StocksIssuingReportComponent(props) {
    const { reportData } = props;
    const { ExportCSVButton } = CSVExport;

    const [allRows, setAllRows] = useState([]);

    useEffect(() => {
        if (reportData && reportData.docs && Array.isArray(reportData.docs)) {
            const mappedReportData = [];

            reportData.docs.map((val) => {
                const item = val;

                const itemObject = {
                    merchantName: '',
                    warehouseSiteName: '',
                    zoneName: '',
                    binLocation: '',
                    productDescription: '',
                    orderReferenceNumber: '',
                    merchantOrderNumber: '',
                    orderDateTime: '',
                    deliveryReferenceNumber: '',
                    deliveredDateTime: '',
                    upc: '',
                    productMasterDataReferenceNumber: '',
                    skuCode: '',
                    unitOfMeasure: '',
                    quantity: 0,
                };

                // Warehouse
                if (item.warehouseSiteId && isObject(item.warehouseSiteId)) {
                    itemObject.warehouseSiteName = item.warehouseSiteId.siteName
                        ? `${item.warehouseSiteId.referenceNumber || ''}-${item.warehouseSiteId.siteName}`
                        : '';
                }

                // Merchant
                if (item.merchantId && isObject(item.merchantId)) {
                    itemObject.merchantName = item.merchantId.name || '';
                }

                if (item.productMasterDataId && isObject(item.productMasterDataId)) {
                    itemObject.productDescription = item.productMasterDataId.productDescription || '';
                    itemObject.productMasterDataReferenceNumber = item.productMasterDataId.referenceNumber || '';
                    itemObject.upc = item.productMasterDataId.upc || '';
                    itemObject.skuCode = item.productMasterDataId.skuCode || '';
                    itemObject.unitOfMeasure = item.productMasterDataId.unitOfMeasure || '';
                }

                if (item.orderId && isObject(item.orderId)) {
                    itemObject.orderReferenceNumber = item.orderId.referenceNumber || '';
                    itemObject.merchantOrderNumber = item.orderId.merchantOrderNumber || '';
                }

                if (item.warehouseZoneId && isObject(item.warehouseZoneId)) {
                    itemObject.zoneName = item.warehouseZoneId.zoneName || '';
                }

                // Bin Location
                if (item.selectedBinLocationsString) {
                    itemObject.binLocation = item.selectedBinLocationsString || '';
                }

                itemObject.quantity = item.quantity || '';

                mappedReportData.push(itemObject);

                return val;
            });

            setAllRows(mappedReportData);
        }
    }, [reportData]);

    return (
        <>
            <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                <CardBody>
                    <div
                        style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'col' }}>
                            <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}>
                                Stocks Issuing Report
                            </SubTitle>
                        </div>
                    </div>
                    <hr />
                    <ToolkitProvider
                        keyField="_id"
                        data={allRows}
                        columns={defaultTableHeaders}
                        exportCSV={{
                            fileName: 'Stocks Issuing Report.csv',
                            // separator: '|',
                            // ignoreHeader: true,
                            // noAutoBOM: false,
                            // blobType: 'text/csv;charset=ansi',
                        }}
                    >
                        {(tableProps) => (
                            <div>
                                {allRows.length > 0 && (
                                    <Row className="d-flex justify-content-end">
                                        {hasAccess(PERMISSIONS.INVENTORY_REPORT, ['Export']) && (
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 pt-0 pb-0"
                                            >
                                                <ExportCSVButton {...tableProps.csvProps}>
                                                    {' '}
                                                    <span style={{ color: '#FFF' }}> Export</span>
                                                </ExportCSVButton>
                                            </motion.button>
                                        )}
                                    </Row>
                                )}

                                <hr />
                                <BootstrapTable {...tableProps.baseProps} />
                            </div>
                        )}
                    </ToolkitProvider>
                </CardBody>
            </Card>
        </>
    );
}

StocksIssuingReportComponent.propTypes = {
    reportData: PropTypes.any,
};

export default StocksIssuingReportComponent;
