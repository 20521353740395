export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Rider Id',
        sortingEnabled: true,
    },
    {
        key: 'fullName',
        value: 'Name',
    },
    {
        key: 'mobileNo',
        value: 'Mobile No',
    },
    {
        key: 'email',
        value: 'E-Mail',
    },
    {
        key: 'vehicleType',
        value: 'Vehicle Type',
    },
    {
        key: 'vehicleNo',
        value: 'Vehicle Number',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
        sortingEnabled: true,
    },
    {
        key: 'status',
        value: 'Status',
        type: 'UPPERCASE',
    },
];

export const defaultSearchFilters = [
    {
        label: 'Rider Name',
        key: 'fullName',
    },
    {
        label: 'Rider ID',
        key: 'referenceNumber',
    },
    {
        label: 'Mobile No',
        key: 'mobileNo',
    },
    {
        label: 'Email',
        key: 'email',
    },
    {
        label: 'Vehicle Type',
        key: 'vehicleType',
    },
    {
        label: 'Vehicle Number',
        key: 'vehicleNo',
    },
];

export const riderCommissionSettingEmbeddedHeaders = [
    {
        key: 'riderName',
        value: 'Rider',
    },
    {
        key: 'deliveryType',
        value: 'Delivery Type',
        type: 'billingSettingVehicleType',
    },
    {
        key: 'riderCommissionMethod',
        value: 'Commission Method',
    },
    {
        key: 'fixedRiderCommissionRate',
        value: 'Fixed Commission Rate',
    },
    {
        key: 'riderCommissionPercentage',
        value: 'Commission Percentage',
    },
    {
        key: 'baseMileage',
        value: 'Base Mileage (KM)',
    },
    {
        key: 'baseMileageRate',
        value: 'Base Mileage Rate',
    },
    {
        key: 'validFrom',
        value: 'Valid From',
        type: 'dateTime',
    },
    {
        key: 'validTo',
        value: 'Valid To',
        type: 'dateTime',
    },
];

export const transactionTableHeaderForRider = [
    {
        key: 'sequence',
        value: 'TR. ID',
    },
    {
        key: 'reference',
        value: 'TR. REFERENCE',
    },
    {
        key: 'description',
        value: 'TRANSACTION',
    },
    {
        key: 'transactionMadeByUserUsername',
        value: 'USER',
    },
    {
        key: 'createdAt',
        value: 'TR. DATE AND TIME',
        type: 'dateTime',
    },
    {
        key: 'action',
        value: 'DETAILS',
        type: 'customActionButton',
        params: {
            buttonLabel: 'View',
            customActionHandlerName: 'displayModal',
        },
    },
];
