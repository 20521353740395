/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import PropTypes from 'prop-types';

// #region components | assets
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

// #endregion imports
import AddDeliveryAttachmentsModule from './Modules/AddImages';
import AddDeliveryNotesModule from './Modules/AddNotes';
import AttachmentArchiveModel from './Modules/AttachmentArchive';

function AttachmentAndNoteModal(props) {
    const {
        toggleModal,
        show = false,
        ref,
        type,
        title = '',
        body,
        data,
        setShowModal,
        merchantOrderNo,
        deliveryId,
        riderId,
        loadAttachments,
        setIsViewMode,
        updateFormData,
        updateAttachmentData,
        deliveryAttachments,
    } = props;

    const commonProps = {
        loadAttachments,
        data,
        show,
        setShowModal,
        toggleModal,
        deliveryId,
        riderId,
        setIsViewMode,
        updateFormData,
        updateAttachmentData,
        deliveryAttachments,
    };

    const generateBody = () => {
        if (body) {
            return <>{body}</>;
        }
        if (type === 'addNote') {
            return <AddDeliveryNotesModule merchantOrderNo={merchantOrderNo} riderId={riderId} {...commonProps} />;
        }
        if (type === 'addImage') {
            return <AddDeliveryAttachmentsModule merchantOrder={merchantOrderNo} {...commonProps} />;
        }
        if (type === 'archive') {
            return <AttachmentArchiveModel {...commonProps} />;
        }
        return <></>;
    };

    return (
        <>
            {type && (
                <Modal
                    isOpen={show}
                    className="modal-dialog modal-dialog-centered"
                    style={type === 'archive' ? { maxWidth: '80%', width: '100%' } : {}}
                    ref={ref}
                    toggle={toggleModal}
                >
                    {title && <ModalHeader toggle={toggleModal}>{title}</ModalHeader>}
                    <ModalBody className="p-0">{generateBody()}</ModalBody>
                </Modal>
            )}
        </>
    );
}

AttachmentAndNoteModal.propTypes = {
    toggleModal: PropTypes.func,
    show: PropTypes.bool,
    type: PropTypes.any,
    deliveryAttachments: PropTypes.any,
    body: PropTypes.any,
    ref: PropTypes.any,
    title: PropTypes.any,
    data: PropTypes.any,
    setShowModal: PropTypes.func,
    merchantOrderNo: PropTypes.any,
    deliveryId: PropTypes.any,
    riderId: PropTypes.any,
    loadAttachments: PropTypes.func,
    setIsViewMode: PropTypes.func,
    updateFormData: PropTypes.any,
    updateAttachmentData: PropTypes.any,
};

export default AttachmentAndNoteModal;
