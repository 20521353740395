import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import moment from 'moment';

// #region components | assets
import { AttachmentAndNoteModal, BackdropLoader, CustomToast, ScootixModal, TableCustom } from 'components';
import AttachmentPreviewModal from 'components/Modals/Image-Preview';
import TextPreviewModal from 'components/Modals/Texts-Previews';

// #region services
import { deleteDeliveryAttachmentService } from 'services/deliveries.service';

// #region utils
import { hasAccess } from 'utils/checkAuth';
import { __BLOCKED_FOR_MERCHANTS__ } from 'utils/checks';

// #endRegions imports
import { PERMISSIONS, PERMISSION_TYPES } from 'utils/constants';
import { deliveryAttachmentsInitialValues } from '../../constants';
/**
 * Mapping the Delivery Attachment Data to the AttachmentTable
 *
 * @type {React.FC<InferProps<import("./propTypes").DeliveryFormProps>>} <-- passing the delivery attachment data
 * @returns {TableCustom} <-- mapping data to delivery attachment table
 */
function AttachmentTable(props) {
    const {
        deliveryAttachments = [],
        deliveryId,
        riderId,
        merchantOrderNo,
        loadAttachments,
        data: deliveryData,
    } = props;

    const { referenceNumber } = deliveryData;

    // Showing models
    const [noteShowPreview, setNoteShowPreview] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });
    const [modelType, setModelType] = useState();

    // data
    const [attachment, setAttachment] = useState();
    const [noteAttachment, setNoteAttachment] = useState();
    const [modelAttachment, setModelAttachment] = useState();
    const [modelData, setModelData] = useState({ referenceNumber });

    // loading
    const [backdropLoading, setBackdropLoading] = useState(0);

    // toggles models
    const toggleAttachmentModal = () => {
        setShowPreview(!showPreview);
    };

    const toggleNoteAttachmentModal = () => {
        setNoteShowPreview(!noteShowPreview);
    };

    const toggleModal = (id) => {
        if (id) {
            setModelType(id);
            setShowModal(!showModal);
        }
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: !scootixModalState.show, data: null });
    };

    const closeScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    // filtering and mapping attachment data to the table
    const mapAttachmentAndNotes = (record) => {
        let allDocs = [];
        allDocs = record
            .filter((x) => x.isArchived === false)
            .map((x) => ({
                ...x,
                id: x._id,
                attachment: x.attachment?.presignedUrl,
                mimetype: x.attachment?.mimetype,
                key: x.attachment?.key,
                isVisibleToRider: x?.isVisibleToRider,
                user: x.createdByRider.length > 0 ? x?.createdByRider[0]?.fullName : x?.createdByUser[0]?.fullName,
                userName:
                    x.createdByRider.length > 0 ? x?.createdByRider[0]?.referenceNumber : x?.createdByUser[0]?.username,
                userType: x.createdByRider.length > 0 ? 'Rider' : 'Console',
                createdAt: moment(x.createdAt).format('DD-MM-YYYY hh:mm A'),
                updatedAt: moment(x.updatedAt).format('DD-MM-YYYY hh:mm A'),
            }));
        return allDocs;
    };

    // handle view attachment btn function
    const handleViewAttachment = (row) => {
        const { category, mimetype, note } = row;
        if (row.attachment && category === 'Attachment') {
            if (mimetype === 'application/pdf' || mimetype === 'message/rfc822') {
                handleDownloadRow(row);
            } else {
                setAttachment(row.attachment);
                toggleAttachmentModal();
            }
        }
        if (!row.attachment && category === 'Note') {
            setNoteAttachment(note);
            toggleNoteAttachmentModal();
        }
    };

    const handleEditRow = (e, row) => {
        if (row) {
            const { id, type, text, category, note, isVisibleToRider, key } = row;
            const data = { id, type, text, note, isVisibleToRider, key };
            setModelData({ ...modelData, ...data });
            setNoteAttachment(note);
            if (row.attachment) {
                // setting image data to show on update attachment model
                const attachmentData = {
                    attachmentImageUrl: {
                        data: {
                            preview: row.attachment,
                            type: 'image/png',
                        },
                        isDbSaved: true,
                    },
                };
                setModelAttachment(attachmentData);
            }
            if (category === 'Attachment') {
                toggleModal('addImage');
            }
            if (category === 'Note') {
                toggleModal('addNote');
            }
        }
    };

    const handleOnDelete = (e, row) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: { id: row.id },
            message: `Are you sure you want to delete this Delivery Attachment ?`,
            onConfirm: (data) => handleDeleteDeliveryAttachment(data),
        });
    };

    const handleDeleteDeliveryAttachment = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteDeliveryAttachmentService(data.id);
            toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors && e.data.errors.userMessage) {
                toast.error(e.data.errors.userMessage);
            } else {
                toast.error('Something went wrong');
            }
        }
        setBackdropLoading((prevState) => prevState - 1);
        closeScootixModal();
        loadAttachments();
    };

    const handleDownloadRow = async (row) => {
        if (!row?.attachment) {
            toast.custom((t) => <CustomToast text="File Not Found, Contact Admin" t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setBackdropLoading((prevState) => prevState + 1);

        try {
            if (row?.attachment) {
                const link = document.createElement('a');
                link.href = row?.attachment;
                window.open(link.href);
                toast.custom((t) => <CustomToast text="Successfully Downloaded File" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            console.log(e);
        }

        setBackdropLoading((prevState) => prevState - 1);
    };

    return (
        <>
            <TableCustom
                tableData={{
                    ...deliveryAttachmentsInitialValues,
                    docs: mapAttachmentAndNotes(deliveryAttachments),
                }}
                showPagination={false}
                showAttachmentView={hasAccess(PERMISSIONS.DELIVERY_ATTACHMENT_NOTES, [PERMISSION_TYPES.view])}
                onAttachmentView={handleViewAttachment}
                isFilter={false}
                isSearch={false}
                isCardContainer={false}
                isOuterTableBorder
                showEdit={
                    !__BLOCKED_FOR_MERCHANTS__ &&
                    hasAccess(PERMISSIONS.DELIVERY_ATTACHMENT_NOTES, [PERMISSION_TYPES.edit])
                }
                handleEdit={handleEditRow}
                showDelete={
                    !__BLOCKED_FOR_MERCHANTS__ &&
                    hasAccess(PERMISSIONS.DELIVERY_ATTACHMENT_NOTES, [PERMISSION_TYPES.delete])
                }
                handleDelete={handleOnDelete}
                allowDelete
                showView={false}
            />
            <AttachmentPreviewModal
                toggleModal={toggleAttachmentModal}
                image={attachment}
                show={showPreview}
                type="default"
            />
            <TextPreviewModal
                toggleModal={toggleNoteAttachmentModal}
                text={noteAttachment}
                show={noteShowPreview}
                type="default"
            />
            <AttachmentAndNoteModal
                data={modelData}
                updateFormData={modelData}
                updateAttachmentData={modelAttachment}
                setShowModal={setShowModal}
                toggleModal={toggleModal}
                type={modelType}
                show={showModal}
                merchantOrderNo={merchantOrderNo}
                deliveryId={deliveryId}
                riderId={riderId}
                loadAttachments={loadAttachments}
            />
            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
            <BackdropLoader show={backdropLoading} opacity={0.7} />
        </>
    );
}

AttachmentTable.propTypes = {
    deliveryAttachments: PropTypes.any,
    merchantOrderNo: PropTypes.any,
    deliveryId: PropTypes.any,
    riderId: PropTypes.any,
    loadAttachments: PropTypes.func,
    data: PropTypes.any,
};

export default AttachmentTable;
