/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */

import React from 'react';
import moment from 'moment';

export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const {
        deliveryAddress,
        pickupLocation,
        merchantId,
        orderReferenceNumber,
        source,
        vehicleType,
        deliveryAddressMeta,
        pickupLocationMeta,
        isDeliveryReadyDateTime,
        deliveryReadyTime,
    } = form;

    if (isDeliveryReadyDateTime) {
        const now = moment().add(5, 'minutes');
        if (moment(deliveryReadyTime).isAfter(now)) {
            return { isFormValid: false, message: 'Invalid Delivery Ready Date' };
        }
    }

    if (source === 'System Order') {
        if (!orderReferenceNumber) {
            return { isFormValid, message };
        }
    }

    if (
        !deliveryAddress ||
        !pickupLocation ||
        !merchantId ||
        !vehicleType ||
        !deliveryAddressMeta ||
        !pickupLocationMeta ||
        !source
    ) {
        return { isFormValid, message };
    }

    isFormValid = true;
    return { isFormValid, message };
};

// eslint-disable-next-line consistent-return
export const buildLocationData = (id, idMeta, data) => {
    if (id === 'pickupLocation') {
        if (data) {
            const { city = '', address = '', state = null, postal_code = '', merchantLocationId = null } = data;
            return {
                pickupLocationCity: city,
                pickupLocationState: state,
                [id]: `${address ? `${address}` : ''}`,
                [idMeta]: data,
                pickupLocationPostalCode: postal_code,
                merchantLocationId,
            };
        }
        return {
            [id]: null,
            [idMeta]: null,
            pickupLocationCity: null,
            pickupLocationState: null,
            pickupLocationPostalCode: null,
        };
    }
    if (id === 'deliveryAddress') {
        if (data) {
            const { city = '', address = '', state = null, postal_code = '', merchantLocationId = null } = data;
            return {
                city,
                state,
                [id]: `${address ? `${address}` : ''}`,
                [idMeta]: data,
                postalCode: postal_code || '',
                merchantLocationId,
            };
        }
        return {
            [id]: null,
            [idMeta]: null,
            city: '',
            state: '',
            postalCode: '',
        };
    }
};

export const renderExistingPendingData = (formData, existingPendingData) => {
    const cellStyle = {
        border: '1px solid #282829',
        padding: 10,
        fontWeight: 'bold',
    };
    return (
        <div className="p-3" style={{ overflowY: 'scroll' }}>
            <table>
                <thead>
                    <tr>
                        <th style={cellStyle}>Delivery Type</th>
                        <th style={cellStyle}>Source</th>
                        <th style={cellStyle}>Order Number</th>
                        <th style={cellStyle}>Merchant Order Number</th>
                        <th style={cellStyle}>Delivery Ready Time</th>
                        <th style={cellStyle}>Special Delivery Time</th>
                        <th style={cellStyle}>Special Delivery Date and Time</th>
                        <th style={cellStyle}>Pickup Location</th>
                        <th style={cellStyle}>Recipient Mobile Number</th>
                        <th style={cellStyle}>Recipient Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td
                            style={{
                                ...cellStyle,
                                color: existingPendingData.deliveryType
                                    ? '#000'
                                    : formData.deliveryType
                                    ? '#42c72c'
                                    : '#000',
                            }}
                        >
                            {existingPendingData.deliveryType || formData.deliveryType || ''}
                        </td>
                        <td
                            style={{
                                ...cellStyle,
                                color: existingPendingData.source ? '#000' : formData.source ? '#42c72c' : '#000',
                            }}
                        >
                            {existingPendingData.source || formData.source || ''}
                        </td>
                        <td
                            style={{
                                ...cellStyle,
                                color: existingPendingData.orderReferenceNumber
                                    ? '#000'
                                    : formData.orderReferenceNumber
                                    ? '#42c72c'
                                    : '#000',
                            }}
                        >
                            {existingPendingData.orderReferenceNumber || formData.orderReferenceNumber || ''}
                        </td>
                        <td
                            style={{
                                ...cellStyle,
                                color: existingPendingData.merchantOrderNo
                                    ? '#000'
                                    : formData.merchantOrderNo
                                    ? '#42c72c'
                                    : '#000',
                            }}
                        >
                            {existingPendingData.merchantOrderNo || formData.merchantOrderNo || ''}
                        </td>
                        <td
                            style={{
                                ...cellStyle,
                                color: existingPendingData.deliveryReadyTime
                                    ? '#000'
                                    : formData.deliveryReadyTime
                                    ? '#42c72c'
                                    : '#000',
                            }}
                        >
                            {existingPendingData.deliveryReadyTime
                                ? moment(existingPendingData.deliveryReadyTime).format('DD-MM-YYYY hh:mm A')
                                : formData.deliveryReadyTime
                                ? moment(formData.deliveryReadyTime).format('DD-MM-YYYY hh:mm A')
                                : ''}
                        </td>
                        <td
                            style={{
                                ...cellStyle,
                                color: existingPendingData.isDeliveryTimeSlot
                                    ? '#000'
                                    : formData.isDeliveryTimeSlot
                                    ? '#42c72c'
                                    : '#000',
                            }}
                        >
                            {existingPendingData.isDeliveryTimeSlot
                                ? String(existingPendingData.isDeliveryTimeSlot)
                                : formData.isDeliveryTimeSlot
                                ? String(formData.isDeliveryTimeSlot)
                                : ''}
                        </td>
                        <td
                            style={{
                                ...cellStyle,
                                color: existingPendingData.deliveryTimeSlot
                                    ? '#000'
                                    : formData.deliveryTimeSlot
                                    ? '#42c72c'
                                    : '#000',
                            }}
                        >
                            {existingPendingData.deliveryTimeSlot
                                ? moment(existingPendingData.deliveryTimeSlot).format('DD-MM-YYYY hh:mm A')
                                : formData.deliveryTimeSlot
                                ? moment(formData.deliveryTimeSlot).format('DD-MM-YYYY hh:mm A')
                                : ''}
                        </td>

                        <td
                            style={{
                                ...cellStyle,
                                color: existingPendingData.pickupLocation
                                    ? '#000'
                                    : formData.pickupLocation
                                    ? '#42c72c'
                                    : '#000',
                            }}
                        >
                            {existingPendingData.pickupLocation || formData.pickupLocation || ''}
                        </td>
                        <td
                            style={{
                                ...cellStyle,
                                color: existingPendingData.recipientMobileNumber
                                    ? '#000'
                                    : formData.recipientMobileNumber
                                    ? '#42c72c'
                                    : '#000',
                            }}
                        >
                            {existingPendingData.recipientMobileNumber || formData.recipientMobileNumber || ''}
                        </td>
                        <td
                            style={{
                                ...cellStyle,
                                color: existingPendingData.recipientName
                                    ? '#000'
                                    : formData.recipientName
                                    ? '#42c72c'
                                    : '#000',
                            }}
                        >
                            {existingPendingData.recipientName || formData.recipientName || 'No Recipient Given'}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export const emptyFormData = (formData, initialFormValues) => ({
    ...initialFormValues,
    deliveryType: formData.deliveryType || null,
    orderReferenceNumber: null,
    isChill: false,
    isFragile: false,
    isFrozen: false,
    recipientEmail: '',
    recipientName: '',
    pickupLocation: null,
    pickupLocationMeta: null,
    pickupLocationCity: '',
    pickupLocationState: '',
    pickupLocationPostalCode: '',
    recipientMobileNumber: '',
    customerId: null,
    deliveryAddress: null,
    deliveryAddressMeta: null,
    deliveryState: '',
    deliveryCity: '',
    deliveryPostalCode: '',
    riderId: null,
    riderName: null,
    riderReference: null,
    riderMobileNumber: null,
    orderId: null,
    state: '',
    postalCode: '',
    city: '',
});

export const emptyOrderData = () => ({
    orderReferenceNumber: null,
    isChill: false,
    isFragile: false,
    isFrozen: false,
    recipientEmail: '',
    recipientName: '',
    pickupLocation: null,
    pickupLocationMeta: null,
    pickupLocationCity: '',
    pickupLocationState: '',
    pickupLocationPostalCode: '',
    recipientMobileNumber: '',
    customerId: null,
    deliveryAddress: null,
    deliveryAddressMeta: null,
    deliveryState: '',
    deliveryCity: '',
    deliveryPostalCode: '',
    riderId: null,
    riderName: null,
    riderReference: '',
    riderMobileNumber: '',
    orderId: null,
    state: '',
    postalCode: '',
    city: '',
});
