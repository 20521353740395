import * as React from 'react';

function Tick(props) {
    const { width, height, color } = props;
    return (
        <svg
            width={width || 30}
            height={height || 30}
            viewBox="0 0 17 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1 6l5 5L16 1"
                stroke={color || '#fff'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Tick;
