import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import CONFIG from 'app-config';

import PackageJson from '../../../package.json';

const Footer = () => (
    <React.Fragment>
        <footer className="footer">
            <Container fluid>
                <Row>
                    <Col sm={6} className="row align-items-center">
                        {new Date().getFullYear()} © ScootiX{' '}
                        <>
                            {PackageJson ? (
                                <>
                                    {' '}
                                    <div className="font-size-10 ml-2 mt-1">
                                        {PackageJson.version || ''} - {CONFIG.subVersion}
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                        </>{' '}
                    </Col>
                    <Col sm={6}>
                        <div className="text-sm-right d-none d-sm-block">
                            Developed By TASA Global Solutions Sdn Bhd
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    </React.Fragment>
);

export default Footer;
