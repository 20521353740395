/* eslint-disable indent */
import {
    GET_WAREHOUSE_SITES,
    GET_ALL_WAREHOUSE_SITES,
    GET_TRANSACTIONS_BY_WAREHOUSE_SITE_ID,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Warehouse Sites Service
 * @returns {object}
 */
export const getWarehouseSitesService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_WAREHOUSE_SITES}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get All WarehouseSites
 * @returns {Promise}
 */
export const getAllWarehouseSitesService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_WAREHOUSE_SITES).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * This build the url for getting warehouse site transactions by warehouse id
 * @param {string} id - warehouse id
 * @returns url
 */
export const getTransactionsByWarehouseSiteId = (id) => {
    const url = UrlBuilder().setUrl(GET_TRANSACTIONS_BY_WAREHOUSE_SITE_ID).setPathParams({ id }).build();
    return instance.get(url);
};
