export const defaultTableHeaders = [
    {
        key: 'sequenceId',
        value: 'Billing Reference',
    },
    {
        key: 'referenceNumber',
        value: 'Delivery ID',
    },
    {
        key: 'deliveryType',
        value: 'Delivery Type',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
    {
        key: 'type',
        value: 'Billing Type',
        type: 'allCapsToHumanReadable',
    },
    {
        key: 'orderReferenceNumber',
        value: 'Order Number',
    },
    {
        key: 'merchantOrderNo',
        value: 'Merchant Order Number',
    },
    {
        key: 'pickupLocation',
        value: 'Pickup Address',
    },
    {
        key: 'deliveryAddress',
        value: 'Delivery Address',
    },
    {
        key: 'recipientName',
        value: 'Recipient Name',
    },
    {
        key: 'vehicleType',
        value: 'Vehicle Type',
    },
    {
        key: 'pickedUpAt',
        value: 'Pickup Date and Time',
        type: 'dateTime',
    },
    {
        key: 'deliveredAt',
        value: 'Delivery Date and Time',
        type: 'dateTime',
    },
    {
        key: 'riderName',
        value: 'Rider Name',
    },
    {
        key: 'riderReferenceNumber',
        value: 'Rider Id',
    },
    {
        key: 'formattedDistance',
        value: 'Distance',
    },
    {
        key: 'riderCommissionMethod',
        value: 'Rider Commission Method',
    },
    {
        key: 'riderPaymentStatus',
        value: 'Payment Status',
    },
    {
        key: 'formattedAmount',
        value: 'Amount',
    },
];
