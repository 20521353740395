/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable func-names */
/* eslint-disable array-callback-return */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Container, Table, Spinner, DropdownItem } from 'reactstrap';
import { motion } from 'framer-motion';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import Lottie from 'react-lottie';
import classnames from 'classnames';
import './index.css';

// #region assets | components
import NoResultAnimation from 'assets/animations/no_result.json';
import CrossCircleIcon from 'components/Icons/CrossCircle';
import { ExportTableModal } from 'components';

// #region styles
import { FONT_PRIMARY_BLACK } from 'theme';

// #endregion imports
import TableStickyView from './table-sticky-view';
import Pagination from './custom-pagination/pagination';
import { applyFormats, renderCheck } from './utils';
import TableHeader from './containers/TableHeader';
import { generateIndexCellStyles, generateSelectCellStyles } from './constants';
import ResultsPerPage from './components/ResultPerPage';

/**
 * ScootiX Custom Table
 * @param {*} props
 * @returns {React.Component}
 */
function CustomTable(props) {
    const {
        tableId = 'custom-table',
        removeId = true,
        onSort, // call back function for column wise sorting
        removeAction = false, // remove action column
        isTableBorder = false,
        isFilter = true,
        isSearch = true,
        onDateFilter,
        isFullScreenShow = false,
        isLazyLoading = false, // Apply table lazy loading [set true]
        tableData = {},
        loading = false,
        handleEdit,
        onPageChange,
        onFilter,
        filters = [],
        handleView,
        handleDelete,
        disabledRows,
        showDelete = true,
        showView = true,
        showEdit = true,
        handleRestore,
        showManageDeliveryImportExcelCsv = true, // DELIVERY EXCEL CSV IMPORT SHOW
        showPermanentDelete = true, // PERMANENT DELETE BTN SHOW
        showRestore = true, // RESTORE DELETE BTN SHOW
        showPagination = true,
        isExport = false,
        exportModalHeader = null,
        type = null,
        handleDeletePermanent,
        showStickyView = false, // Show Table Sticky View
        allowDelete = true,
        isManualSearch = false,
        searchProp,
        onRestore,
        onPermanentDelete,
        showDownload = false,
        showApiCustomAction = false,
        onDownload,
        tableHeaderType = '',
        cardBorderRadius = 20,
        isSelectable = false,
        onSelectAll,
        onSelect,
        headerHandlers,
        showHeaderHandlers = true,
        actionDisabledRows,
        isFixedHeight = false,
        isResponsive = true,
        cardStyleProps = {},
        containerId,
        isSetting = false,
        isCardContainer = true,
        isOuterTableBorder = false,
        showAttachmentView = false,
        onAttachmentView,
        showViewMerchantProfileButton = false,
        onViewMerchantProfile,
        onQuickAction,
        apiKeysTableHeaderProps,
        customActionHandlers = null,
        webhooksTableHeaderProps,
        connectedAppsHeaderProps,
        partnerListTableHeaderProps,
        numberOfRows = 10,
        showQuickAction = false,
        showApiBasicSettingsAction = false,
        showApiWebhookAction = false,
        showCustomDiscardActionIcon = false,
        customHeaderProps,
        selectStyles = { direction: 'left' }, // positioning of select column
        isSelectHeaderText = false, // is select column header a text box or a text
        onViewDelivery, // redirect to delivery
        handleResultsPerPageChange, // handle results per page dropdown change
        showResultsPerPage,
        showTransactionDataRecords = false,
        isStickyView = false,
    } = props;

    const handleFullScreen = useFullScreenHandle();

    const history = useHistory();

    const tablePermissions = {
        showManageDeliveryImportExcelCsv,
        showPermanentDelete,
        showAttachmentView,
        showRestore,
        showDownload,
        showViewMerchantProfileButton,
        showApiCustomAction,
        showQuickAction,
        showApiBasicSettingsAction,
        showApiWebhookAction,
        showTransactionDataRecords,
    };

    // holds the column and sorted order
    const [isUp, setIsUp] = useState({});
    const [isFullScreen, setIsFullScreen] = useState(false);

    // const [rowToolBox, setRowToolBox] = useState(false);
    // const [rowToolBoxId, setRowToolBoxId] = useState(null);

    const [exportModalShow, setExportModalShow] = useState(false);
    const [enableStickyView, setEnableStickyView] = useState(isStickyView);
    const [visible, setVisible] = useState(numberOfRows);

    /**
     * Handler Props
     */
    const handlerProps = {
        onRestore,
        onPermanentDelete,
        onDownload,
        onSelect,
        onSelectAll,
        onViewMerchantProfile,
        onAttachmentView,
        onQuickAction,
        onViewDelivery,
    };

    // Add the custom action handlers to the above object
    if (customActionHandlers) {
        Object.keys(customActionHandlers).map((key) => {
            handlerProps[key] = customActionHandlers[key];
        });
    }
    /**
     * Sort results based on column value ascending or descending order
     * @param {object} header - Include the key, value and other params
     */
    const handleHeaderClick = (header) => {
        // ascending order or descending order
        const isDescending = isUp[header.key];

        if (onSort) {
            onSort(!!isDescending, header.key);
        }
        setIsUp({
            [header.key]: !isDescending,
        });
    };

    const handleOpenToolBox = () => {
        // setRowToolBox(true);
        // setRowToolBoxId(id);
    };

    // const toggleExportModal = () => setExportModalShow(!exportModalShow);

    // const getActionSectionBar = (i, row) => (
    //     <td key={`col_end_${i}`} className="text-center">
    //         {isSubTable ? (
    //             <div className="tableaction clearfix withouticon">
    //                 <Button variant="danger" onClick={() => onSubItemRemove(i, row)}>
    //                     Remove
    //                 </Button>
    //             </div>
    //         ) : (
    //             <div
    //                 style={{
    //                     flexDirection: 'row',
    //                     justifyContent: 'center',
    //                     alignContent: 'center',
    //                     alignItems: 'center',
    //                     height: 35,
    //                     paddingRight: 15,
    //                     paddingTop: 8,
    //                 }}
    //             ></div>
    //         )}
    //     </td>
    // );

    const allowRecordDelete = (row) => {
        if (typeof allowDelete === 'boolean') {
            if (allowDelete) {
                return true;
            }
        }
        return allowDelete(row);
    };

    // handle table data lazy loading onclick event
    const handleClick = () => {
        setVisible((preValue) => preValue + numberOfRows);
    };

    // const renderTooltip = (i, row) => <Tooltip>{getActionSectionBar(i, row)}</Tooltip>;

    const getActionSection = (i, row) => {
        const hideAction = isActionDisabled(row);

        if (hideAction) return <div />;
        return (
            <td
                key={`col_end_${i}`}
                className="text-center"
                style={{
                    verticalAlign: 'middle',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {showView && (
                        <motion.div
                            whileHover={{ scale: 1.25 }}
                            whileTap={{ scale: 0.9 }}
                            className="w3-animate-opacity"
                            onClick={(e) => handleView(e, row)}
                        >
                            <i className="far fa-eye fa-lg" style={{ color: '#071e3d' }} />
                        </motion.div>
                    )}
                    {showEdit && (
                        <motion.div
                            whileHover={{ scale: 1.25 }}
                            whileTap={{ scale: 0.9 }}
                            className="w3-animate-opacity ml-3"
                            onClick={(e) => handleEdit(e, row)}
                        >
                            <i className="far fa-edit fa-lg" style={{ color: '#5d5d5a' }} />
                        </motion.div>
                    )}
                    {showDelete && handleDelete && allowRecordDelete(row) && (
                        <motion.div
                            whileHover={{ scale: 1.25 }}
                            whileTap={{ scale: 0.9 }}
                            className="w3-animate-opacity ml-3"
                            onClick={(e) => handleDelete(e, row)}
                        >
                            {showCustomDiscardActionIcon ? (
                                <CrossCircleIcon color="#E90909" />
                            ) : (
                                <i className="fas fa-trash fa-lg" style={{ color: '#ff304f' }} />
                            )}
                        </motion.div>
                    )}
                </div>
            </td>
        );
    };

    const isRowDisabled = (row) => {
        if (disabledRows) {
            let isDisabled = false;
            const fieldsToDisable = disabledRows;
            Object.keys(fieldsToDisable).map(function (key) {
                if (row[key] === fieldsToDisable[key].value) {
                    isDisabled = true;
                }
            });
            return isDisabled ? 'tableData-row-disabled' : '';
        }
        return 'tableData';
    };

    const isActionDisabled = (row) => {
        if (actionDisabledRows) {
            let isDisabled = false;
            const fieldsToDisable = actionDisabledRows;
            Object.keys(fieldsToDisable).map(function (key) {
                if (row[key] === fieldsToDisable[key].value) {
                    isDisabled = true;
                }
            });
            return !!isDisabled;
        }
        return false;
    };

    // return table row data
    const buildTableRow = (row, i) => (
        <tr
            style={{
                cursor: 'pointer',
                height: 10,
                ...(row?.isLatest ? '#fdefd6' : ''),
            }}
            key={`row_${i}`}
            fixed="left"
            className={classnames(`${isRowDisabled(row)} custom-table__row `, {
                'table-row__border-bottom': i !== tableData?.docs.length - 1, // apply border and remove border from last row
            })}
        >
            {isSelectable && selectStyles.direction === 'left' && (
                <td key={`col_check_${i}`} style={generateSelectCellStyles(isTableBorder)}>
                    {renderCheck(row, onSelect, {
                        marginTop: -8,
                        marginLeft: -6,
                    })}
                </td>
            )}
            {!removeId && (
                <td key={`col_start_${i}`} style={generateIndexCellStyles(isTableBorder)}>
                    {tableData.page
                        ? tableData.page > 1
                            ? i + (tableData.page - 1) * tableData.limit + 1
                            : i + 1
                        : i + 1}
                </td>
            )}
            {tableData.headers.map((header, k) => (
                <td
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        textAlign: 'left',
                        border: isTableBorder ? '2px solid #f0f0f0' : 'none',
                        verticalAlign: 'middle',
                        minHeight: 50,
                        height: 80,
                    }}
                    key={`row_${k}`}
                    onClick={(e) => {
                        // If there are additional buttons in the table prevent the click of the table
                        const anyAdditionalTableButtonHandlers = Object.values(tableAdditionalButtonHandlers).some(
                            (item) => item
                        );
                        if (anyAdditionalTableButtonHandlers) {
                            return;
                        }
                        if (handleEdit && showEdit) {
                            handleEdit(e, row);
                        }
                        // handleOpenToolBox(`row_${k}${header}${i}`);
                    }}
                >
                    <div style={buildCellStyles(header.style)}>
                        {applyFormats(
                            row[header.key],
                            header,
                            row,
                            history,
                            tablePermissions,
                            handlerProps,
                            otherProps
                        )}
                    </div>
                </td>
            ))}

            {isSelectable && selectStyles.direction === 'right' && (
                <td key={`col_check_${i}`} style={generateSelectCellStyles(isTableBorder)}>
                    {renderCheck(row, onSelect, {
                        marginTop: -8,
                        marginLeft: -6,
                    })}
                </td>
            )}

            {!removeAction && getActionSection(i, row)}
        </tr>
    );

    const cardStyle = { borderRadius: cardBorderRadius, ...(cardStyleProps || {}) };

    const tableAdditionalButtonHandlers = {
        onRestore,
        onPermanentDelete,
        onAttachmentView,
    };

    /**
     * Other Props
     */
    const otherProps = {
        isSelectable,
    };

    const isTableResponsive = () => isResponsive;

    function buildCellStyles(headerStyles = []) {
        let customStyles = {};

        if (headerStyles.includes('centerElements')) {
            customStyles = {
                ...customStyles,
                display: 'flex',
                justifyContent: 'center',
            };
        }

        return customStyles;
    }

    const renderTableContainer = () => (
        <>
            <TableHeader
                searchProp={searchProp}
                isManualSearch={isManualSearch}
                filters={filters}
                onFilter={onFilter}
                isFilter={isFilter}
                isExport={isExport}
                enableStickyView={enableStickyView}
                tableData={tableData}
                isFullScreenShow={isFullScreenShow}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
                setEnableStickyView={setEnableStickyView}
                showStickyView={showStickyView}
                isSearch={isSearch}
                onDateFilter={onDateFilter}
                tableHeaderType={tableHeaderType}
                handleFullScreen={handleFullScreen}
                exportModalShow={exportModalShow}
                setExportModalShow={setExportModalShow}
                headerHandlers={headerHandlers}
                showHeaderHandlers={showHeaderHandlers}
                isSetting={isSetting}
                apiKeysTableHeaderProps={apiKeysTableHeaderProps}
                webhooksTableHeaderProps={webhooksTableHeaderProps}
                connectedAppsHeaderProps={connectedAppsHeaderProps}
                partnerListTableHeaderProps={partnerListTableHeaderProps}
                customHeaderProps={customHeaderProps}
                setIsUp={setIsUp}
            />
            {!isSearch && !isFilter && <br />}
            <div className={`custom-table-container ${isFullScreen && 'screen-height-90'} `}>
                <div
                    // className={`custom-table-${tableData.docs.length > 0 ? 'rows' : 'none'}`}
                    className={classnames(`custom-table-${tableData.docs.length > 0 ? 'rows' : 'none'}`, {
                        'table-fixed-height': isFixedHeight,
                    })}
                >
                    {enableStickyView ? (
                        <>
                            <TableStickyView
                                tableData={tableData}
                                isRowDisabled={isRowDisabled}
                                handleEdit={handleEdit}
                                showEdit={showEdit}
                                handleOpenToolBox={handleOpenToolBox}
                                applyFormats={applyFormats}
                                history={history}
                                tablePermissions={tablePermissions}
                                handleRestore={handleRestore}
                                handleDeletePermanent={handleDeletePermanent}
                                isTableBorder={isTableBorder}
                                handlerProps={handlerProps}
                                otherProps={otherProps}
                            />
                        </>
                    ) : (
                        <>
                            <Table
                                id={tableId}
                                className={classnames('table-x', {
                                    'hide-table-scroll': !!(loading || tableData?.docs?.length === 0),
                                    'table-fixed-height': isFixedHeight,
                                    'outer-border': isOuterTableBorder,
                                })}
                                responsive={isTableResponsive()}
                            >
                                <thead>
                                    <tr style={{ backgroundColor: '#F9F7F7' }} id={`${tableId}-table-header`}>
                                        {isSelectable &&
                                            tableData.docs.length > 0 &&
                                            selectStyles.direction === 'left' && (
                                                <th
                                                    style={{
                                                        width: 35,
                                                        textAlign: 'center',
                                                        minWidth: 50,
                                                        verticalAlign: 'center',
                                                    }}
                                                >
                                                    {!isSelectHeaderText ? (
                                                        renderCheck(tableData.docs, onSelectAll, {
                                                            marginTop: -17,
                                                            marginLeft: -6,
                                                        })
                                                    ) : (
                                                        <div
                                                            className="th-row"
                                                            style={{
                                                                display: 'flex',
                                                                minWidth: 'min-content',
                                                                maxWidth: 500,
                                                                width: 'max-content',
                                                                justifyContent: 'space-between',
                                                                position: 'relative',
                                                            }}
                                                        >
                                                            <div style={{ fontSize: 'initial' }}>SELECT</div>
                                                        </div>
                                                    )}
                                                </th>
                                            )}
                                        {!removeId && (
                                            <th
                                                style={{
                                                    width: 35,
                                                    textAlign: 'center',
                                                    minWidth: 50,
                                                    verticalAlign: 'center',
                                                }}
                                            >
                                                #
                                            </th>
                                        )}
                                        {tableData.headers.map((header) => (
                                            <th
                                                className={classnames('tableHeader', {
                                                    'table-header-relative': isFixedHeight,
                                                })}
                                                key={uuid()}
                                                onClick={
                                                    header.sortingEnabled ? () => handleHeaderClick(header) : undefined
                                                }
                                                style={{
                                                    borderLeft: isTableBorder ? '1px solid #d9d9d9' : '',
                                                    borderRight: isTableBorder ? '1px solid #d9d9d9' : '',
                                                    // eslint-disable-next-line no-nested-ternary
                                                    minWidth: header.style
                                                        ? header.style.includes('increaseWidth')
                                                            ? 300
                                                            : 170
                                                        : 170,
                                                    maxWidth: 600,
                                                    height: 40,
                                                    position: 'sticky',
                                                    top: 0,
                                                    ...(header?.customHeaderStyles || {}),
                                                }}
                                            >
                                                <div
                                                    className="th-row"
                                                    style={{
                                                        display: 'flex',
                                                        minWidth: 'min-content',
                                                        maxWidth: 500,
                                                        width: 'max-content',
                                                        justifyContent: 'space-between',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div>{header.value}</div>

                                                    {header.sortingEnabled && (
                                                        <div>
                                                            {isUp[header.key] ? (
                                                                <i className="fas fa-angle-up table-sorting-arrow"></i>
                                                            ) : (
                                                                <i className="fas fa-angle-down table-sorting-arrow"></i>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </th>
                                        ))}
                                        {isSelectable &&
                                            tableData.docs.length > 0 &&
                                            selectStyles.direction === 'right' && (
                                                <th
                                                    style={{
                                                        width: 35,
                                                        textAlign: 'center',
                                                        minWidth: 50,
                                                        verticalAlign: 'center',
                                                    }}
                                                >
                                                    {!isSelectHeaderText ? (
                                                        renderCheck(tableData.docs, onSelectAll, {
                                                            marginTop: -17,
                                                            marginLeft: -6,
                                                        })
                                                    ) : (
                                                        <div
                                                            className="th-row"
                                                            style={{
                                                                display: 'flex',
                                                                minWidth: 'min-content',
                                                                maxWidth: 500,
                                                                width: 'max-content',
                                                                justifyContent: 'space-between',
                                                                position: 'relative',
                                                            }}
                                                        >
                                                            <div style={{ fontSize: 'initial' }}>SELECT</div>
                                                        </div>
                                                    )}
                                                </th>
                                            )}
                                        {!removeAction && (
                                            <th className="th-row" style={{ fontSize: 15 }}>
                                                ACTION
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                {(!loading || loading < 1) && tableData.docs.length > 0 && (
                                    <>
                                        <tbody>
                                            {!isLazyLoading && tableData.docs.map((row, i) => buildTableRow(row, i))}
                                            {isLazyLoading &&
                                                tableData.docs.slice(0, visible).map((row, i) => buildTableRow(row, i))}
                                        </tbody>
                                    </>
                                )}
                            </Table>
                            {(!loading || loading < 1) && tableData.docs.length === 0 && (
                                <div
                                    style={{
                                        height: '300px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <motion.div
                                        initial={{ scale: 0.8, opacity: 0 }}
                                        animate={{ scale: 1, opacity: 1 }}
                                        exit={{ scale: 0.8, opacity: 0 }}
                                        transition={{ type: 'spring', duration: 1.5 }}
                                        style={{ borderRadius: 15 }}
                                    >
                                        <Lottie
                                            lat={0}
                                            lng={0}
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: NoResultAnimation,
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid slice',
                                                },
                                            }}
                                            height={180}
                                            width={180}
                                            speed={0.4}
                                        />
                                    </motion.div>
                                    <div
                                        style={{
                                            fontWeight: 'bolder',
                                            marginTop: -15,
                                            fontSize: 22,
                                            color: FONT_PRIMARY_BLACK,
                                        }}
                                    >
                                        No Results Found
                                    </div>
                                </div>
                            )}
                            {(!loading || loading < 1) && tableData.docs.length > 0 && isLazyLoading && (
                                <p className="table-lazy-loading" onClick={handleClick}>
                                    LOAD MORE ...
                                </p>
                            )}
                        </>
                    )}
                </div>

                {(loading || loading > 0) && (
                    <div
                        style={{
                            width: '100%',
                            height: '300px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                        }}
                    >
                        <Spinner />
                    </div>
                )}
            </div>

            <br />

            {(!loading || loading < 1) && showPagination && !isLazyLoading && (
                <>
                    {tableData?.docs?.length === 0 && <DropdownItem divider />}
                    <div className="pagination-row-container mt-3">
                        <div />
                        <div className="pagination-container">
                            {showResultsPerPage && (
                                <div className="results-per-page-container">
                                    <ResultsPerPage
                                        limit={tableData.limit}
                                        handleResultsPerPageChange={handleResultsPerPageChange}
                                    />
                                </div>
                            )}

                            <Pagination
                                className="pagination-bar"
                                currentPage={tableData.page}
                                totalCount={tableData.totalDocs || tableData.matchedCount}
                                pageSize={tableData.limit}
                                onPageChange={onPageChange}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );

    return (
        <>
            <div className="table-default">
                <Container fluid>
                    <FullScreen handle={handleFullScreen}>
                        {isCardContainer && (
                            <Card
                                style={{ ...cardStyle }}
                                className={`${isFullScreen && 'full-screen'} shadow ${containerId || ''}`}
                            >
                                <CardBody>{renderTableContainer()}</CardBody>
                            </Card>
                        )}
                        {!isCardContainer && renderTableContainer()}
                    </FullScreen>
                </Container>
            </div>

            {exportModalShow && (
                <ExportTableModal
                    tableData={tableData}
                    toggleModal={() => setExportModalShow(!exportModalShow)}
                    header={exportModalHeader}
                    type={type}
                />
            )}
        </>
    );
}

CustomTable.propTypes = {
    tableId: PropTypes.string,
    removeId: PropTypes.bool,
    removeAction: PropTypes.bool,
    isTableBorder: PropTypes.bool,
    isFullScreenShow: PropTypes.bool,
    showDelete: PropTypes.bool,
    showView: PropTypes.bool,
    showEdit: PropTypes.bool,
    isSearch: PropTypes.bool,
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    isFilter: PropTypes.bool,
    onSort: PropTypes.func,
    onPageChange: PropTypes.func,
    onFilter: PropTypes.func,
    handleDelete: PropTypes.func,
    handleView: PropTypes.func,
    handleEdit: PropTypes.func,
    disabledRows: PropTypes.object,
    filters: PropTypes.array,
    tableData: PropTypes.object,
    handleRestore: PropTypes.func,
    showManageDeliveryImportExcelCsv: PropTypes.bool,
    showPermanentDelete: PropTypes.bool,
    showRestore: PropTypes.bool,
    showPagination: PropTypes.bool,
    isExport: PropTypes.bool,
    exportModalHeader: PropTypes.string,
    type: PropTypes.string,
    handleDeletePermanent: PropTypes.func,
    showStickyView: PropTypes.bool,
    isManualSearch: PropTypes.bool,
    allowDelete: PropTypes.any,
    searchProp: PropTypes.any,
    onRestore: PropTypes.func,
    onPermanentDelete: PropTypes.func,
    onDownload: PropTypes.func,
    showDownload: PropTypes.bool,
    showApiCustomAction: PropTypes.bool,
    tableHeaderType: PropTypes.string,
    cardBorderRadius: PropTypes.number,
    numberOfRows: PropTypes.number,
    isLazyLoading: PropTypes.bool,
    isSelectable: PropTypes.bool,
    onSelectAll: PropTypes.func,
    onSelect: PropTypes.func,
    headerHandlers: PropTypes.object,
    showHeaderHandlers: PropTypes.bool,
    actionDisabledRows: PropTypes.object,
    isFixedHeight: PropTypes.bool,
    isResponsive: PropTypes.bool,
    cardStyleProps: PropTypes.object,
    containerId: PropTypes.string,
    isSetting: PropTypes.bool,
    isCardContainer: PropTypes.bool,
    isOuterTableBorder: PropTypes.bool,
    showAttachmentView: PropTypes.bool,
    onAttachmentView: PropTypes.func,
    showViewMerchantProfileButton: PropTypes.bool,
    onViewMerchantProfile: PropTypes.func,
    onQuickAction: PropTypes.func,
    apiKeysTableHeaderProps: PropTypes.object,
    /** An object of handlers that can be called by 'customActionButton' columns */
    customActionHandlers: PropTypes.object,
    webhooksTableHeaderProps: PropTypes.object,
    connectedAppsHeaderProps: PropTypes.object,
    partnerListTableHeaderProps: PropTypes.object,
    showQuickAction: PropTypes.bool,
    showApiBasicSettingsAction: PropTypes.bool,
    showApiWebhookAction: PropTypes.bool,
    customHeaderProps: PropTypes.object,
    selectStyles: PropTypes.shape({
        direction: PropTypes.oneOf(['left', 'right']),
    }),
    isSelectHeaderText: PropTypes.bool,
    onViewDelivery: PropTypes.func,
    showCustomDiscardActionIcon: PropTypes.bool,
    handleResultsPerPageChange: PropTypes.func,
    showResultsPerPage: PropTypes.bool,
    showTransactionDataRecords: PropTypes.bool,
    isStickyView: PropTypes.bool,
    onDateFilter: PropTypes.func,
};

export default CustomTable;
