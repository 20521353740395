import { CREATE_MERCHANT_SETTING, UPDATE_MERCHANT_SETTING, DELETE_MERCHANT_SETTING } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Create MerchantSettingService
 * @param {object} data
 * @returns {Promise}
 */
export const createMerchantSettingService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_MERCHANT_SETTING).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update MerchantSettingService
 * @param {object} data
 * @returns {Promise}
 */
export const updateMerchantSettingService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_MERCHANT_SETTING).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update MerchantSettingService
 * @param {string} id
 * @returns {Promise}
 */
export const deleteMerchantSettingService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_MERCHANT_SETTING).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
