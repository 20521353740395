export const validateRoutePlanningCancelForm = (formData) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { reason, password } = formData;

    if (!reason) {
        return { isFormValid, message: 'Please provide a reason for the cancellation' };
    }
    if (!password) {
        return { isFormValid, message: 'Please provide your password for confirmation' };
    }

    isFormValid = true;
    return { isFormValid, message };
};

/** This suppose to build query filters based on the business logics in the change rider modal - route planning entry
 */
export const buildQueryFilters = (deliveries, riderStatus = '') => {
    const queryFilters = {
        all: '1',
        ...(deliveries && { vehicleType: deliveries[0]?.vehicleType }),
    };

    const firstMerchantId = deliveries[0].merchant._id;
    const limitedToMerchant = deliveries?.some((obj) => !!obj.merchant.isLimitDeliveriesToMerchantRider);
    const isSingleMerchant = deliveries?.every((obj) => obj.merchant._id === firstMerchantId);

    if (isSingleMerchant) {
        queryFilters.merchantId = firstMerchantId;
    }

    if (riderStatus) {
        if (riderStatus === 'active') {
            queryFilters.isOnline = '1';
        } else if (riderStatus === 'inactive') {
            queryFilters.isOffline = '1';
        }
    }

    if (limitedToMerchant) {
        queryFilters.isLimitedToMerchant = '1';
    }
    if (!limitedToMerchant) {
        queryFilters.isLimitedToMerchant = '0';
    }

    return queryFilters;
};

/** This function validates that the selected deliveries are from the same merchant and same delivery date */
export const validateSelectedDeliveries = (selectedDeliveries, routePlanData) => {
    let merchantValidationFails = false;
    const currentMerchantId = routePlanData.merchantIds[0];

    const isCurrentMerchantIsLimited = routePlanData?.merchants[0]?.isLimitDeliveriesToMerchantRider || false;

    if (isCurrentMerchantIsLimited) {
        // Validate that the same merchant
        merchantValidationFails = selectedDeliveries.some((delivery) => delivery.merchantId !== currentMerchantId);
    }

    // check whether any of selected delivery has a limited merchant
    const isSelectedDeliveryHasLimitedMerchant = selectedDeliveries.some(
        (obj) => obj.merchant.isLimitDeliveriesToMerchantRider
    );

    if (isSelectedDeliveryHasLimitedMerchant && !isCurrentMerchantIsLimited) {
        merchantValidationFails = true;
    }

    if (isSelectedDeliveryHasLimitedMerchant && isCurrentMerchantIsLimited) {
        merchantValidationFails = selectedDeliveries.some((delivery) => delivery.merchantId !== currentMerchantId);
    }

    if (merchantValidationFails) {
        return false;
    }
    return true;
};

/** This function validates that the current rider can carry the selected deliveries or not */
export const validateRider = (selectedDeliveries, routePlanData) => {
    let riderValidationFails = false;
    const currentMerchantId = routePlanData.merchantIds[0];

    const isCurrentMerchantIsLimited = routePlanData?.merchants[0]?.isLimitDeliveriesToMerchantRider || false;

    if (!isCurrentMerchantIsLimited && routePlanData.rider?.isLimitedToMerchant) {
        riderValidationFails = selectedDeliveries.some((delivery) => delivery.merchantId !== currentMerchantId);
    }

    if (riderValidationFails) {
        return false;
    }
    return true;
};

/**
 * This suppose to build query data for generate route plan
 */
export const buildAddedMoreDeliveriesData = (deliveryData, routePlanData) => {
    const mapDeliveries = deliveryData.map((doc) => ({
        _id: doc._id,
        pickupLocationMeta: { coordinates: doc.pickupLocationMeta?.coordinates },
        deliveryAddressMeta: {
            coordinates: doc.deliveryAddressMeta?.coordinates,
        },
        pickupLocation: doc.pickupLocation,
        deliveryAddress: doc.deliveryAddress,
        merchantOrderNo: doc.merchantOrderNo,
        referenceNumber: doc.referenceNumber,
        isCompleted: doc.isCompleted,
        isPickedUp: doc.isPickedUp,
        pickedUpAt: doc.pickedUpAt,
        completedAt: doc.completedAt,
        merchantId: doc.merchantId,
    }));
    const buildData = {
        deliveries: mapDeliveries,
        riderId: routePlanData?.rider._id,
    };

    return buildData;
};
