/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// #region assets | components
import { CustomToast, TooltipDefault } from 'components';
import { Card, CardBody, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import Switch from 'react-switch';
import classnames from 'classnames';
import toast from 'react-hot-toast';

// #region services
import { getMerchantSettingByMerchantIdService } from 'services/merchant-setting.service';
import { createDeliveryDateConfiguration } from 'pages/Modules/Settings/services';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import DeliveryDateConfigurationOptions from '../components/Delivery-Date-Configuration-Options';
import { daysTabs } from '../components/constants';

function DeliveryDateSettings(props) {
    const { merchantData } = props;

    /** Users who have access to Edit permissions can only edit delivery date settings */
    const hasEditPermission = hasAccess(PERMISSIONS.AUTOMATIC_DELIVERY_DATE_SETTINGS, ['Edit']);

    /** set all Merchant settings with delivery date configuration settings */
    const [formData, setFormData] = useState({});

    /** delivery date configuration settings */
    const [deliveryDateSettings, setDeliveryDateSettings] = useState();

    const [formLoading, setFormLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    /** set current tab of delivery date configuration table */
    const [currentActiveTab, setCurrentActiveTab] = useState('monday');

    /** load merchant settings data */
    useEffect(() => {
        if (merchantData._id) {
            loadDeliverySettingsData(merchantData._id);
        }
    }, [merchantData._id]);

    /** load the delivery date configuration settings after update */
    useEffect(() => {
        if (Object.keys(formData).length > 0) {
            const updatedFormData = formData;
            formData.automaticDeliveryDateSettings[currentActiveTab] = deliveryDateSettings;
            setFormData(updatedFormData);
        }
    }, [deliveryDateSettings]);

    /** handle the toggle button */
    const handleCheckChange = (checked, id) => {
        setFormData({
            ...formData,
            [id]: checked,
        });
    };

    /** set toggle tabs */
    /** set delivery date configuration settings related to the day when it's toggling  */
    const toggle = (tab) => {
        const deliveryDateConfigurations = formData?.automaticDeliveryDateSettings[tab];
        setDeliveryDateSettings(deliveryDateConfigurations);
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    };

    /** set the merchant setting data related to merchant id */
    const loadDeliverySettingsData = async (id) => {
        try {
            const { data } = await getMerchantSettingByMerchantIdService(id);

            if (data) {
                setFormData({ ...formData, ...data });

                // get delivery date configuration options related to the day
                const deliveryDateConfigurations = data?.automaticDeliveryDateSettings[currentActiveTab];

                // set delivery date configuration settings related to the day
                if (deliveryDateConfigurations) {
                    setDeliveryDateSettings({ ...deliveryDateConfigurations });
                }
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        setFormLoading(true);

        try {
            const { data } = await createDeliveryDateConfiguration(formData);
            if (data) {
                setFormData({ ...formData, ...data });
                toast.custom((t) => <CustomToast text="Successfully updated User" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setFormLoading(false);
        setSubmitted(false);
    };

    return (
        <>
            <Card className="shadow default-card mt-4">
                <div className="route-planning__form-header pl-3">
                    <Row className="flex align-items-center align-content-center" style={{ height: 60 }}>
                        <Col className="header-text">Delivery Dates Configuration</Col>
                        <Col md={2.5} className="pr-2 mr-4">
                            <Row className="pl-3">
                                <div
                                    id="toggleDeliveryDateSettingsEnable"
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <Label
                                        htmlFor="isSetDateAutomatically"
                                        className="mt-2"
                                        style={{ marginRight: '1rem' }}
                                    >
                                        Set the Delivery Date Automatically
                                    </Label>
                                    <Switch
                                        className="mr-5"
                                        uncheckedIcon={<div />}
                                        checkedIcon={<div />}
                                        onColor="#6289D6"
                                        offColor="#808080"
                                        height={22}
                                        width={40}
                                        onChange={(event) => handleCheckChange(event, 'isDeliveryDateAutoEnabled')}
                                        checked={formData?.isDeliveryDateAutoEnabled}
                                        defaultChecked={formData?.isDeliveryDateAutoEnabled}
                                        disabled={!hasEditPermission}
                                    />
                                    <TooltipDefault
                                        targetId="toggleDeliveryDateSettingsEnable"
                                        text={`${
                                            formData?.isDeliveryDateAutoEnabled ? 'Enable' : 'Disable'
                                        } Delivery Date Automatically`}
                                    />
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <CardBody className="px-3">
                    <Col>
                        <Row>
                            <Col>
                                {/* Tab navigation bar */}
                                {deliveryDateSettings && (
                                    <Nav tabs className="nav-tabs-custom">
                                        {daysTabs.map((val) => (
                                            <NavItem style={{ width: '100px', textAlign: 'center' }}>
                                                <NavLink
                                                    className={classnames({
                                                        active: currentActiveTab === val.tabKey,
                                                    })}
                                                    onClick={() => {
                                                        toggle(val.tabKey);
                                                    }}
                                                >
                                                    <span style={{ fontSize: '12px' }}>{val.tabName}</span>
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                )}
                            </Col>
                        </Row>

                        {/* tab content */}
                        <TabContent activeTab={currentActiveTab}>
                            {daysTabs.map((val) => (
                                <>
                                    <TabPane tabId={val.tabKey}>
                                        <DeliveryDateConfigurationOptions
                                            deliveryDateSettings={deliveryDateSettings}
                                            setDeliveryDateSettings={setDeliveryDateSettings}
                                            handleSubmit={handleSubmit}
                                            formLoading={formLoading}
                                            validated={validated}
                                            submitted={submitted}
                                            hasEditPermission={hasEditPermission}
                                            formData={formData}
                                        />
                                    </TabPane>
                                </>
                            ))}
                        </TabContent>
                    </Col>
                </CardBody>
            </Card>
        </>
    );
}

DeliveryDateSettings.propTypes = {
    merchantData: PropTypes.object,
};

export default DeliveryDateSettings;
