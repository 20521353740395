/* eslint-disable indent */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Col, Label, Row, Input, Card, CardBody, FormGroup, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import * as qs from 'query-string';
import InputMask from 'react-input-mask';
import toast from 'react-hot-toast';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import Hoc from 'components/Hoc';
import {
    FormStatusBtn,
    Select,
    PlacesAutocompleteModal,
    ClientAlerts,
    PlacesAutocompleteField,
    CustomToast,
    TableCustom,
    ScootixModal,
    BackdropLoader,
} from 'components';
import { SubTitle } from 'styles';

// #region imports
import PATHS from 'routes/paths';

// #region utils
import { getVisitingObject, hasAccess } from 'utils/checkAuth';
import {
    apiErrorHandler,
    apiErrorHandlerV2,
    cleanMobileNo,
    getDefaultValueForSelect,
    reactSelectCustomStyles,
} from 'utils/helpers';
import { PERMISSIONS } from 'utils/constants';

// #region services
import { getWarehouseZonesBySiteIdService } from 'services/warehouse-zone.service';
import { getTransactionsByWarehouseSiteId } from 'services/warehouse-site.service';

// #endregion imports
import { createWarehouseSiteService, deleteWarehouseZoneService, updateWarehouseSiteService } from '../services';
import { buildLocationData, mapTransactionTableDocs, validateForm } from './utils';
import { initialTransactionTableData, siteLocationEmbeddedHeaders } from './constants';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Warehouse', link: PATHS.WAREHOUSE_SITE.DEFAULT },
];

let initialFormValues = {
    placedDateTime: new Date(),
    pickupLocationMeta: {},
};

function WarehouseSiteFormPage(props) {
    const history = useHistory();

    const { visitingClient } = getVisitingObject();

    if (visitingClient) {
        initialFormValues = {
            ...initialFormValues,
            clientId: visitingClient._id,
            clientName: visitingClient.name,
        };
    }

    // prev comp
    const [prevCompState, setPrevCompState] = useState({});

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [formLoading, setFormLoading] = useState(false);
    const [autoCompleteModal, setAutoCompleteModal] = useState({ type: null, show: false });
    const [siteLocationLoading, setSiteLocationLoading] = useState(0);
    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);
    const [isViewMode, setIsViewMode] = useState(false);

    const [siteLocationListData, setSiteLocationListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: siteLocationEmbeddedHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    // transaction table data
    const [transactionTableData, setTransactionTableData] = useState(initialTransactionTableData);
    const [isTransactionLoading, setIsTransactionLoading] = useState(false);

    useEffect(() => {
        if (!props.location) {
            history.push(PATHS.WAREHOUSE_SITE.DEFAULT);
            return;
        }

        const formType = qs.parse(props.location.search);
        if (formType && formType.type && formType.type === 'new') {
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Site', link: '#' }]);
        }

        if (formType && formType.type && formType.type === 'edit') {
            handleUpdateComponentEditMode();
        }

        if (formType && formType.type && formType.type === 'view') {
            handleUpdateComponentViewMode();
        }

        const { prevCompState = null } = props.location.state || {};
        if (prevCompState) {
            setPrevCompState(prevCompState);
        }
    }, []);

    useEffect(() => {
        formData._id && loadSiteTransactions();
        formData._id &&
            loadSiteLocationsBySiteId(
                true,
                formData._id,
                siteLocationListData.all,
                siteLocationListData.page,
                siteLocationListData.limit,
                siteLocationListData.filter,
                siteLocationListData.sort,
                siteLocationListData.order
            );
    }, [formData._id]);

    /**
     * Get transactions
     */
    const loadSiteTransactions = async () => {
        setIsTransactionLoading(true);
        if (formData._id) {
            try {
                const { data } = await getTransactionsByWarehouseSiteId(formData?._id);
                const mappedData = mapTransactionTableDocs(data);
                setTransactionTableData({ ...transactionTableData, docs: mappedData });
            } catch (e) {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            } finally {
                setIsTransactionLoading(false);
            }
        }
    };

    const loadSiteLocationsBySiteId = (
        showLoading = true,
        id,
        all,
        page,
        limit,
        filter,
        sort,
        order,
        searchByParam
    ) => {
        if (showLoading) {
            setSiteLocationLoading((prevState) => prevState + 1);
        }
        getWarehouseZonesBySiteIdService(id, all, page, limit, filter, sort, order, searchByParam, null)
            .then((res) => {
                const { data } = res;
                if (data && data.docs) {
                    setSiteLocationListData({
                        ...siteLocationListData,
                        ...data,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setSiteLocationLoading((prevState) => prevState - 1);
                }
            });
    };

    const handleUpdateComponentViewMode = () => {
        try {
            !props.location.state && history.push(PATHS.WAREHOUSE_SITE.DEFAULT);
            const { warehouseSiteParentData } = props.location.state;
            setFormData({ ...formData, ...warehouseSiteParentData });
            setIsViewMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'View User', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpdateComponentEditMode = () => {
        try {
            !props.location.state && history.push(PATHS.WAREHOUSE_SITE.DEFAULT);
            const { warehouseSiteParentData } = props.location.state;
            setFormData({ ...formData, ...warehouseSiteParentData });
            const { prevCompState = null } = props.location.state || {};
            // Update URL Based on Condition
            handleUpdateUrl(prevCompState, warehouseSiteParentData, 'warehouseSiteParentData');
            if (prevCompState && prevCompState.refreshUser) {
                // This code is executed only during page reload
                // loadUserById(warehouseSiteParentData._id);
            }
            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Warehouse', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    // Update Address Bar URL
    // PATH | QUERY | STATE
    const handleUpdateUrl = (prevState, data, id) => {
        // Handle Remove PrevComponent
        try {
            history.replace({
                pathname: `${PATHS.WAREHOUSE_SITE.FORM_VIEW}`,
                search: `?type=edit&id=${data._id}`,
                state: {
                    [id]: data,
                    prevCompState: { ...prevState, refreshPage: true },
                },
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);

        formData.primaryContactNumber = cleanMobileNo(formData.primaryContactNumber);
        formData.officeNo = cleanMobileNo(formData.officeNo);

        try {
            let responseData;
            if (editingMode) {
                const { data } = await updateWarehouseSiteService(formData);
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            } else {
                const { data } = await createWarehouseSiteService(formData);
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            }
            setEditingMode(true);

            try {
                // Replace URL so relevant changes can be done
                if (responseData) {
                    history.replace({
                        pathname: `${PATHS.WAREHOUSE_SITE.FORM_VIEW}`,
                        search: `?type=edit&id=${responseData._id}`,
                        state: {
                            warehouseSiteParentData: responseData,
                            prevCompState,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} site`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setIsFormSaved(true);
        } catch (e) {
            console.log(e);
            toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading(false);
        setSubmitted(false);
        loadSiteTransactions();
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);
        if (id === 'state') {
            setFormData({
                ...formData,
                city: '',
                [id]: event ? event.value : null,
            });
            return;
        }
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const returnLocationData = (id, idMeta, data) => {
        const builtData = buildLocationData(id, idMeta, data);
        setFormData({ ...formData, ...builtData });
    };

    const handleOnClickNewLocation = () => {
        history.push({
            pathname: `${PATHS.WAREHOUSE_SITE.LOCATION_FORM}`,
            search: `?type=new`,
            state: { warehouseSiteParentData: formData },
        });
    };

    const handleEditSiteLocationRow = (e, row) => {
        history.push({
            pathname: `${PATHS.WAREHOUSE_SITE.LOCATION_FORM}`,
            search: `?type=edit`,
            state: { warehouseZoneParentData: row },
        });
    };

    const handleOnDelete = (e, row) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: row,
            message: 'Are you sure you want to delete Location?',
            onConfirm: (data) => handleDeleteWarehouseSite(data),
        });
    };

    const handleDeleteWarehouseSite = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteWarehouseZoneService(data._id);
            loadSiteLocationsBySiteId(
                true,
                formData._id,
                siteLocationListData.all,
                siteLocationListData.page,
                siteLocationListData.limit,
                siteLocationListData.filter,
                siteLocationListData.sort,
                siteLocationListData.order
            );
            toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        toggleScootixModal();
        setBackdropLoading((prevState) => prevState - 1);
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    return (
        <>
            <Hoc {...props}>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                        <Row
                            style={{
                                margin: 2,
                                justifyContent: 'space-between',
                            }}
                        >
                            {!editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                    onClick={() => {
                                        history.push({
                                            pathname: prevCompState.pageUrl || `${PATHS.WAREHOUSE_SITE.DEFAULT}`,
                                        });
                                    }}
                                >
                                    <i className="far fa-times-circle mr-2 fa-lg" />
                                    Cancel
                                </motion.div>
                            )}
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormStatusBtn isFormSaved={isFormSaved} />
                            </div>

                            {editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => {
                                        history.push({
                                            pathname: prevCompState.pageUrl || `${PATHS.WAREHOUSE_SITE.DEFAULT}`,
                                        });
                                    }}
                                >
                                    <i className="fas fa-arrow-left mr-2 fa-lg" />
                                    Back to Warehouses
                                </motion.div>
                            )}
                        </Row>
                        {!editingMode && (
                            <>
                                <ClientAlerts />
                            </>
                        )}
                        <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                            <CardBody>
                                <Row style={{ justifyContent: 'space-between', alignItems: 'center', margin: 2 }}>
                                    <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                        WAREHOUSE SITE
                                    </SubTitle>
                                </Row>
                                <br />
                                <AvForm
                                    className="needs-validation default-form"
                                    noValidate
                                    validated={String(validated)}
                                    onSubmit={handleSubmit}
                                    id="warehouseSiteForm"
                                >
                                    <fieldset disabled={isViewMode || formLoading}>
                                        {/* <Row>
                                            {(editingMode || isViewMode) && (
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="referenceNumber">Site ID</Label>
                                                        <AvField
                                                            name="referenceNumber"
                                                            placeholder="Site ID"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            disabled
                                                            id="referenceNumber"
                                                            value={formData.referenceNumber}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            )}
                                        </Row> */}

                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="siteName">Warehouse Name</Label>
                                                    <AvField
                                                        name="siteName"
                                                        placeholder="Warehouse Name"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        id="siteName"
                                                        value={formData.siteName || ''}
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <Label htmlFor="siteType">Warehouse Type</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(formData.siteType)}
                                                    options={[
                                                        {
                                                            label: 'Physical Stock Location',
                                                            value: 'Physical Stock Location',
                                                        },
                                                        {
                                                            label: 'Virtual Stock Location',
                                                            value: 'Virtual Stock Location',
                                                        },
                                                    ]}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'siteType')}
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                    // isDisabled={editingMode}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="4">
                                                <Row>
                                                    <Col md="10" sm="10" xs="10">
                                                        <FormGroup>
                                                            <Label htmlFor="address">Address</Label>
                                                            <PlacesAutocompleteField
                                                                addressData={formData.addressMeta || null}
                                                                returnLocationData={returnLocationData}
                                                                id="address"
                                                                idMeta="addressMeta"
                                                                isRequired
                                                                submitted={submitted}
                                                                validated={validated}
                                                                isClearable
                                                            />
                                                            {autoCompleteModal.type === 'address' &&
                                                                autoCompleteModal.show &&
                                                                !isViewMode && (
                                                                    <PlacesAutocompleteModal
                                                                        show={autoCompleteModal.show}
                                                                        isModal
                                                                        addressData={formData.addressMeta || null}
                                                                        returnLocationData={returnLocationData}
                                                                        toggle={() =>
                                                                            setAutoCompleteModal({
                                                                                show: !autoCompleteModal.show,
                                                                                type: 'address',
                                                                            })
                                                                        }
                                                                        id="address"
                                                                        idMeta="addressMeta"
                                                                    />
                                                                )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="2" sm="2" xs="2">
                                                        <motion.div
                                                            style={{ width: 35, height: 35 }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.9 }}
                                                            className="scootix-btn-circle shadow-lg nw-md ml-3 order-map-pin-container"
                                                            onClick={() =>
                                                                setAutoCompleteModal({
                                                                    show: !autoCompleteModal.show,
                                                                    type: 'address',
                                                                })
                                                            }
                                                        >
                                                            <div className="map-pin">
                                                                <i className="ri-map-pin-line fa-lg" />
                                                            </div>
                                                        </motion.div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label htmlFor="postalCode">Postal Code</Label>
                                                    <AvField
                                                        name="postalCode"
                                                        placeholder="Postal Code"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="postalCode"
                                                        value={formData.postalCode}
                                                        type="Number"
                                                        minLength={5}
                                                        maxLength={5}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label htmlFor="state">State</Label>
                                                    <AvField
                                                        name="state"
                                                        placeholder="State"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        id="state"
                                                        value={formData.state || ''}
                                                        onChange={handleInputChange}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label htmlFor="city">City</Label>
                                                    <AvField
                                                        name="city"
                                                        placeholder="City"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="city"
                                                        value={formData.city || ''}
                                                        onChange={handleInputChange}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="officeNo">Office Contact Number (Optional)</Label>
                                                    <AvField
                                                        name="officeNo"
                                                        placeholder="Office Contact Number"
                                                        mask="+60 99-999 99999"
                                                        maskChar=""
                                                        tag={[Input, InputMask]}
                                                        className="form-control"
                                                        // validate={{ required: { value: true } }}
                                                        id="officeNo"
                                                        onChange={handleInputChange}
                                                        value={formData.officeNo}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="primaryContactName">
                                                        Primary Contact Name (Optional)
                                                    </Label>
                                                    <AvField
                                                        name="primaryContactName"
                                                        placeholder="Primary Contact Name"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        id="primaryContactName"
                                                        value={formData.primaryContactName || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="primaryContactNumber">
                                                        Primary Mobile Number (Optional)
                                                    </Label>
                                                    <AvField
                                                        name="primaryContactNumber"
                                                        placeholder="Primary Mobile Number"
                                                        mask="+60 99-999 99999"
                                                        maskChar=""
                                                        tag={[Input, InputMask]}
                                                        className="form-control"
                                                        // validate={{ required: { value: true } }}
                                                        id="primaryContactNumber"
                                                        onChange={handleInputChange}
                                                        value={formData.primaryContactNumber}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="primaryContactEmail">Primary Email</Label>
                                                    <AvField
                                                        name="primaryContactEmail"
                                                        placeholder="Primary Email"
                                                        type="email"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        id="primaryContactEmail"
                                                        value={formData.primaryContactEmail || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row style={{ justifyContent: 'flex-end' }}>
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                type="submit"
                                                disabled={formLoading}
                                            >
                                                {formLoading ? (
                                                    <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                ) : (
                                                    <>
                                                        <i className="fas fa-save left-icon"></i>
                                                        <span className="h6">{editingMode ? 'Update' : 'Save'}</span>
                                                    </>
                                                )}
                                            </motion.button>
                                        </Row>
                                    </fieldset>
                                </AvForm>
                            </CardBody>
                        </Card>

                        <br />
                        {editingMode && (
                            <>
                                <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                                    <div className="route-planning__form-header pl-3">
                                        <Row
                                            className="flex align-items-center align-content-center"
                                            style={{ height: 60 }}
                                        >
                                            <Col className="header-text">System Transactions</Col>
                                        </Row>
                                    </div>

                                    <CardBody className="px-3">
                                        <Row>
                                            <TableCustom
                                                tableData={transactionTableData}
                                                loading={isTransactionLoading}
                                                showDelete={false}
                                                showPagination={false}
                                                showView={false}
                                                removeId
                                                isFullScreenShow={false}
                                                isFilter={false}
                                                isSearch={false}
                                                removeAction
                                                isFixedHeight
                                            />
                                        </Row>
                                    </CardBody>
                                </Card>

                                <br />

                                <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                                    <CardBody>
                                        <Row className="justify-content-between">
                                            <SubTitle
                                                style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}
                                                className="ml-3 mt-2"
                                            >
                                                WAREHOUSE ZONES
                                            </SubTitle>
                                            {hasAccess(PERMISSIONS.WAREHOUSE, ['Add']) && (
                                                <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                                    onClick={() => handleOnClickNewLocation()}
                                                >
                                                    <i className="fas fa-warehouse left-icon mr-2" />
                                                    {'  '} <div style={{ marginTop: 1, marginLeft: 3 }}>New Zone</div>
                                                </motion.div>
                                            )}
                                        </Row>
                                        <hr />
                                        <div style={{ marginTop: -15 }}>
                                            <TableCustom
                                                id="warehouse-site-location-id"
                                                tableData={siteLocationListData}
                                                loading={siteLocationLoading}
                                                isFullScreenShow
                                                handleDelete={handleOnDelete}
                                                handleEdit={handleEditSiteLocationRow}
                                                showView={false}
                                                showEdit={hasAccess(PERMISSIONS.WAREHOUSE, ['Edit'])}
                                                showDelete={hasAccess(PERMISSIONS.WAREHOUSE, ['Delete'])}
                                                removeId={false}
                                                isExport
                                                type="warehouseZoneSite"
                                                exportModalHeader="Export Warehouse Site Location Data"
                                                filters={[]}
                                                isSearch={false}
                                                isFilter={false}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </>
                        )}
                    </Container>
                </div>
            </Hoc>

            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

WarehouseSiteFormPage.propTypes = {};

const mapStateToProps = (state) => ({
    ...state,
});

const HotWarehouseSiteFormPage = hot(WarehouseSiteFormPage);

export default connect(mapStateToProps, {})(HotWarehouseSiteFormPage);
