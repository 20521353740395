import React from 'react';
import { Alert } from 'reactstrap';
import { getVisitingObject } from 'utils/checkAuth';

function ClientAlerts() {
    const { visitingClient } = getVisitingObject();

    return (
        <>
            {!visitingClient && (
                <Alert color="danger" className="">
                    CLIENT NOT SELECTED
                </Alert>
            )}
        </>
    );
}

ClientAlerts.propTypes = {};

export default ClientAlerts;
