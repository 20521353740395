import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Row, Col, Card, CardBody, Spinner, Label, DropdownItem, FormGroup } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import './styles.css';

// #region assets | components
import { SubTitle } from 'styles';
import { SaveAndUpdate } from 'components/Icons';
import { Select } from 'components';

// #region utils
import { reactSelectCustomStyles, getDefaultValueForSelect, getDateValue } from 'utils/helpers';

// #region imports
import { batchPrintSizes } from 'constants/options';

export default function FormBody(props) {
    const {
        showSaveBtn = true,
        formData,
        handleSelectChange,
        editingMode,
        allPrinters,
        formLoading,
        validated,
        submitted,
        handleSubmit,
    } = props;

    const getSelectPrintSize = (value) => {
        if (value) {
            const relevantObj = batchPrintSizes.find((x) => x.key === value);
            if (relevantObj) {
                return { label: relevantObj.label, value: relevantObj.key };
            }
        }
        return null;
    };

    const selectedPrinter = useMemo(() => getDefaultValueForSelect(formData.printer), [formData.printer]);

    const selectedPrintSize = useMemo(() => getSelectPrintSize(formData.printSize), [formData.printSize]);

    return (
        <>
            <AvForm className="needs-validation default-form" onValidSubmit={handleSubmit}>
                <fieldset disabled={formLoading}>
                    <Card className="printer-settings__card" style={{ borderRadius: 10 }}>
                        <CardBody style={{ marginTop: -100 }}>
                            <Row className="pl-2 mb-3">
                                <SubTitle>Select Your Default Printer Settings</SubTitle>
                            </Row>
                            <DropdownItem divider />
                            <Row className="mt-2">
                                <Col md={3}>
                                    <FormGroup>
                                        <Label htmlFor="printerId">Printer</Label>
                                        <Select
                                            value={selectedPrinter}
                                            isClearable
                                            options={allPrinters}
                                            styles={reactSelectCustomStyles}
                                            validate={{ required: { value: true } }}
                                            onChange={(event) => handleSelectChange(event, 'printer')}
                                            submitted={submitted}
                                            validated={validated}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label htmlFor="printerSize">Print Size</Label>
                                        <Select
                                            value={selectedPrintSize}
                                            isClearable
                                            options={batchPrintSizes
                                                .filter((x) => x.printerType === formData.printer)
                                                .map((val) => ({ ...val, value: val.key }))}
                                            styles={reactSelectCustomStyles}
                                            validate={{ required: { value: true } }}
                                            onChange={(event) => handleSelectChange(event, 'printSize')}
                                            submitted={submitted}
                                            validated={validated}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br />
                            <Row style={{ marginTop: -13, justifyContent: 'flex-start' }}>
                                {showSaveBtn && (
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                        type="submit"
                                        disabled={formLoading}
                                        style={{ borderRadius: 30 }}
                                    >
                                        {formLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <SaveAndUpdate />
                                                <span className="h6">{editingMode ? 'Update' : 'Save'}</span>
                                            </>
                                        )}
                                    </motion.button>
                                )}
                            </Row>
                            {editingMode ? (
                                <div className="printer-settings__footer">
                                    <div className="printer-settings__footer-text">
                                        This setting has been updated at {getDateValue(formData.updatedAt, 'datetime')}
                                    </div>
                                </div>
                            ) : null}
                        </CardBody>
                    </Card>
                </fieldset>
            </AvForm>
        </>
    );
}

FormBody.propTypes = {
    showSaveBtn: PropTypes.bool,
    formData: PropTypes.object,
    handleSelectChange: PropTypes.func,
    allPrinters: PropTypes.array,
    editingMode: PropTypes.bool,
    handleSubmit: PropTypes.func,
    formLoading: PropTypes.func,
    validated: PropTypes.bool,
    submitted: PropTypes.bool,
};
