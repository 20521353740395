/* eslint-disable no-unused-expressions */
/* eslint-disable no-useless-concat */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

// #region assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { BackdropLoader, CustomToast, ScootixModal, TableCustom } from 'components';
import PATHS from 'routes/paths';

// #region utils
import { PERMISSIONS } from 'utils/constants';
import { getUser, hasAccess } from 'utils/checkAuth';
import { apiErrorHandlerV2 } from 'utils/helpers';

// #region services
import {
    getAllRidersMetaService,
    getRidersByBoolStatusService,
    restoreRiderService,
    deleteRiderPermanentService,
} from 'services/rider.service';

// #endregion imports
import reducer from './reducer';
import saga from './saga';
import { defaultTableHeaders } from './constants';
import {
    PERMANENT_DELETE_RIDER_END,
    PERMANENT_DELETE_RIDER_START,
    RESTORE_RIDER_END,
    RESTORE_RIDER_START,
} from './actionTypes';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Rider / Driver Management', link: PATHS.RIDER_PROFILE_MANAGEMENT.LIST_VIEW },
    { title: 'Deleted Riders', link: '#' },
];

const key = 'DeletedRiders';

function DeletedRidersManagementPage(props) {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    const history = useHistory();
    const dispatch = useDispatch();

    // component state
    const [loading, setLoading] = useState(0);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [ridersMeta, setRidersMeta] = useState({
        activeRiders: 0,
        approvedRiders: 0,
        inactiveRiders: 0,
        notApprovedRiders: 0,
    });

    const [ridersListData, setRidersListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    const [searchBy, setSearchBy] = useState({ searchBy: '', searchText: null });

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    useEffect(() => {
        loadRiderMeta();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadRidersListData(
            true,
            ridersListData.all,
            1,
            ridersListData.limit,
            ridersListData.filter,
            ridersListData.sort,
            ridersListData.order,
            searchBy,
            'isDeleted',
            'true'
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.DeletedRiders && props.DeletedRiders?.restoreRiderData) {
            handleOnRestore(props.DeletedRiders?.restoreRiderData);
        }
        if (props.DeletedRiders && props.DeletedRiders?.permanentDeleteRiderData) {
            handleOnDeletePermanent(props.DeletedRiders?.permanentDeleteRiderData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.DeletedRiders]);

    const loadRidersListData = (showLoading = true, all, page, limit, filter, sort, order, searchBy, status, bool) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getRidersByBoolStatusService(all, page, limit, filter, sort, order, searchBy, null, status, bool)
            .then((res) => {
                const { data } = res;
                setRidersListData({
                    ...ridersListData,
                    ...data,
                    docs: data.docs.map((x) => ({
                        ...x,
                        name: `${x.firstName || ''} ${x.lastName || ''}`,
                    })),
                });
            })
            .catch((e) => {
                apiErrorHandlerV2(e);
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const loadRiderMeta = async () => {
        try {
            const { data } = await getAllRidersMetaService();
            if (data) {
                setRidersMeta({ ...ridersMeta, ...data });
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        }
    };

    const handlePageChange = (pageNo) => {
        loadRidersListData(
            true,
            ridersListData.all,
            pageNo,
            ridersListData.limit,
            ridersListData.filter,
            ridersListData.sort,
            ridersListData.order,
            searchBy,
            'isDeleted',
            'true'
        );
    };

    const handleFilter = (field, filterText) => {
        setSearchBy({ searchBy: field || 'fullName', searchText: filterText });
        loadRidersListData(
            false,
            ridersListData.all,
            1,
            ridersListData.limit,
            ridersListData.filter,
            ridersListData.sort,
            ridersListData.order,
            { searchBy: field || 'fullName', searchText: filterText },
            'isDeleted',
            'true'
        );
    };

    const handleViewRider = (e, row) => {
        history.push({
            pathname: `${PATHS.RIDER_PROFILE_MANAGEMENT.FORM_VIEW}`,
            search: `?type=view`,
            state: { riderParentData: row, prevCompState: {} },
        });
    };

    const handleOnRestore = (rider) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: { _id: rider._id },
            message: `Are you sure you want to restore Rider ${rider.fullName}?`,
            onConfirm: (data) => handleRestoreRider(data),
        });
    };

    const handleRestoreRider = async (data) => {
        try {
            if (data && data._id) {
                setBackdropLoading((prevState) => prevState + 1);
                await restoreRiderService(data._id);
                history.push({
                    search: ``,
                });
                loadRidersListData(
                    true,
                    ridersListData.all,
                    1,
                    ridersListData.limit,
                    ridersListData.filter,
                    ridersListData.sort,
                    ridersListData.order,
                    searchBy,
                    'isDeleted',
                    'true'
                );
                toast.custom((t) => <CustomToast text="Successfully Restored" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
            setBackdropLoading((prevState) => prevState - 1);
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        closeScootixModal();
    };

    const handleOnDeletePermanent = (rider) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: { _id: rider._id },
            message: `Are you sure you want to Permanently delete Rider ${rider.fullName} ?`,
            onConfirm: (data) => handleDeletePermanentRider(data),
        });
    };

    const handleDeletePermanentRider = async (data) => {
        if (data && data._id) {
            setBackdropLoading((prevState) => prevState + 1);

            try {
                await deleteRiderPermanentService(data._id);
                history.push({
                    search: ``,
                });
                loadRidersListData(
                    true,
                    ridersListData.all,
                    1,
                    ridersListData.limit,
                    ridersListData.filter,
                    ridersListData.sort,
                    ridersListData.order,
                    searchBy,
                    'isDeleted',
                    'true'
                );
                toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                    position: 'top-right',
                });
            } catch (e) {
                const { message } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            setBackdropLoading((prevState) => prevState - 1);
        }
        closeScootixModal();
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: !scootixModalState.show, data: null });
        dispatch({ type: PERMANENT_DELETE_RIDER_END });
        dispatch({ type: RESTORE_RIDER_END });
    };

    const closeScootixModal = () => {
        setScootixModalState({ show: false, data: null });
        props.DeletedRiders?.permanentDeleteRiderData && dispatch({ type: PERMANENT_DELETE_RIDER_END });
        props.DeletedRiders?.restoreRiderData && dispatch({ type: RESTORE_RIDER_END });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />

                    <br />
                    <Row style={{ justifyContent: 'space-between' }} className="ml-0 mr-3">
                        <div>
                            <span className="ml-2 font-weight-bold">SYSTEM DELETED RIDER ACCOUNTS</span>
                        </div>
                    </Row>

                    <br />
                    <Row>
                        <TableCustom
                            tableData={ridersListData}
                            handleView={handleViewRider}
                            onFilter={handleFilter}
                            onPageChange={handlePageChange}
                            showDelete={false}
                            showEdit={false}
                            showView={false}
                            loading={loading}
                            showPermanentDelete={hasAccess(PERMISSIONS.DELETED_RIDER, ['Permanent Delete'])}
                            showRestore={hasAccess(PERMISSIONS.DELETED_RIDER, ['Restore'])}
                            filters={[
                                {
                                    label: 'Full Name',
                                    key: 'fullName',
                                },
                                {
                                    label: 'Last Name',
                                    key: 'lastName',
                                },
                            ]}
                            disabledRows={{
                                role: { key: 'role', value: 'superAdmin' },
                                _id: {
                                    key: '_id',
                                    value: getUser()._id,
                                },
                            }}
                            onRestore={(data) => dispatch({ type: RESTORE_RIDER_START, payload: data })}
                            onPermanentDelete={(data) =>
                                dispatch({ type: PERMANENT_DELETE_RIDER_START, payload: data })
                            }
                        />
                    </Row>
                </Container>
            </div>

            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

DeletedRidersManagementPage.propTypes = {};

const mapStateToProps = (state) => ({
    ...state,
});

const HotDeletedRidersManagementPage = hot(DeletedRidersManagementPage);

export default connect(mapStateToProps, {})(HotDeletedRidersManagementPage);
