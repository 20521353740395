/* eslint-disable indent */
import {
    GET_EXCEL_CSV_CONFIG_BY_MERCHANT_ID,
    CREATE_EXCEL_CSV_CONFIG,
    UPDATE_EXCEL_CSV_CONFIG,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Excel CSV Config By Merchant Id
 * @param {string} id
 * @returns {Promise}
 */

export const getExcelCsvConfigByMerchantIdService = (id, type) => {
    try {
        const url = UrlBuilder().setUrl(GET_EXCEL_CSV_CONFIG_BY_MERCHANT_ID).setPathParams({ id, type }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Create Excel CSV Config
 * @param {string} id
 * @returns {Promise}
 */

export const createExcelCsvConfigService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_EXCEL_CSV_CONFIG).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Excel CSV Config
 * @param {string} id
 * @returns {Promise}
 */

export const updateExcelCsvConfigService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_EXCEL_CSV_CONFIG).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
