/* eslint-disable indent */
import {
    CREATE_PARTNER_API_SANDBOX_ACCOUNT,
    GET_ALL_PARTNER_API_SANDBOX_ACCOUNT_BY_MERCHANT_ID,
    GET_PARTNER_API_SANDBOX_ACCOUNTS_TABLE,
    GET_PARTNER_API_SANDBOX_ACCOUNT_BY_ID,
    GET_PARTNER_API_SANDBOX_ACCOUNT_BY_MERCHANT_ID,
    GET_PARTNER_API_SANDBOX_ACCOUNT_PENDING_ACTIONS,
    REJECT_PARTNER_API_SANDBOX_ACCOUNT,
    REQUEST_API_ACCESS_MERCHANT_SANDBOX_ACCOUNT,
    UPDATE_CREATE_PARTNER_API_SANDBOX_ACCOUNT,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const createSandboxAccountService = (data) => {
    const url = UrlBuilder().setUrl(CREATE_PARTNER_API_SANDBOX_ACCOUNT).build();
    return instance.post(url, data);
};

/**
 * @param {number} page - page number
 * @param {number} limit - page size
 * @param {string} sort - key to sort by
 * @param {number} order - order of sorting
 * @param {object} search - search props object
 * @param {string} status - whether 'active' or 'inactive'
 * @returns {object} response from the partner api
 */
export const getSandboxAccountTableService = (
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    search,
    requestStatus
) => {
    const queryParams = {
        page,
        limit,
        sort: sort ? Object.keys(sort)[0] : 'createdAt',
        order,
    };
    if (search?.searchBy) queryParams.searchBy = search.searchBy;
    if (search?.searchText) queryParams.searchText = search.searchText;

    if (requestStatus) {
        queryParams.requestStatus = requestStatus;
    }

    const url = UrlBuilder().setUrl(GET_PARTNER_API_SANDBOX_ACCOUNTS_TABLE).setQueryParams(queryParams).build();
    return instance.get(url);
};

export const getSandboxAccountByIdService = (id) => {
    const url = UrlBuilder().setUrl(GET_PARTNER_API_SANDBOX_ACCOUNT_BY_ID).setPathParams({ id }).build();
    return instance.get(url);
};

export const getSandboxAccountByMerchantIdService = (merchantId) => {
    const url = UrlBuilder()
        .setUrl(GET_PARTNER_API_SANDBOX_ACCOUNT_BY_MERCHANT_ID)
        .setPathParams({ merchantId })
        .build();
    return instance.get(url);
};

export const getAllSandboxAccountByMerchantIdService = (merchantId) => {
    const url = UrlBuilder()
        .setUrl(GET_ALL_PARTNER_API_SANDBOX_ACCOUNT_BY_MERCHANT_ID)
        .setPathParams({ merchantId })
        .build();
    return instance.get(url);
};

export const getPartnerApiSandboxAccountPendingActions = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_PARTNER_API_SANDBOX_ACCOUNT_PENDING_ACTIONS).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

// !TODO: add docs
export const merchantRequestPartnerApiSandboxAccountService = (data) => {
    const url = UrlBuilder().setUrl(REQUEST_API_ACCESS_MERCHANT_SANDBOX_ACCOUNT).build();
    return instance.post(url, data);
};

export const rejectSandboxAccountService = (data, id) => {
    const url = UrlBuilder().setUrl(REJECT_PARTNER_API_SANDBOX_ACCOUNT).setPathParams({ id }).build();
    return instance.patch(url, data);
};

export const updateCreateSandboxAccountService = (data, id) => {
    const url = UrlBuilder().setUrl(UPDATE_CREATE_PARTNER_API_SANDBOX_ACCOUNT).setPathParams({ id }).build();
    return instance.patch(url, data);
};
