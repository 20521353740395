/* eslint-disable indent */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Col, Label, Row, Card, CardBody, FormGroup, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { AvForm, AvField, AvCheckbox, AvCheckboxGroup } from 'availity-reactstrap-validation';
import * as qs from 'query-string';
import toast from 'react-hot-toast';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { FormStatusBtn, ClientAlerts, CustomToast, TableCustom, ScootixModal, BackdropLoader } from 'components';
import Hoc from 'components/Hoc';
import { SubTitle } from 'styles';

// #region imports
import PATHS from 'routes/paths';

// #region utils
import { getVisitingObject, hasAccess } from 'utils/checkAuth';
import { apiErrorHandler, apiErrorHandlerV2 } from 'utils/helpers';
import { PERMISSIONS } from 'utils/constants';

// #region services
import { getBinLocationsByZoneIdService } from 'services/bin-location.service';
import { getTransactionsByWarehouseZoneId } from 'services/warehouse-zone.service';

// #endregion imports
import { createWarehouseZoneService, deleteBinLocationService, updateWarehouseZoneService } from '../../services';
import { mapTransactionTableDocs, validateForm } from './utils';
import { binLocationEmbeddedHeaders, initialTransactionTableData } from './constants';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Warehouse Zones', link: PATHS.WAREHOUSE_SITE.DEFAULT },
];

let initialFormValues = {
    placedDateTime: new Date(),
    pickupLocationMeta: {},
};

function SiteLocationFormPage(props) {
    const history = useHistory();

    const { visitingClient } = getVisitingObject();

    if (visitingClient) {
        initialFormValues = {
            ...initialFormValues,
            clientId: visitingClient._id,
            clientName: visitingClient.name,
        };
    }

    // prev comp
    const [prevCompState, setPrevCompState] = useState({});

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [binLocationLoading, setBinLocationLoading] = useState(0);

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);
    const [isViewMode, setIsViewMode] = useState(false);

    const [warehouseSiteData, setWarehouseSiteData] = useState({});

    const [binLocationListData, setBinLocationListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: binLocationEmbeddedHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    // transaction table data
    const [transactionTableData, setTransactionTableData] = useState(initialTransactionTableData);
    const [isTransactionLoading, setIsTransactionLoading] = useState(false);

    useEffect(() => {
        if (!props.location) {
            history.goBack();
            return;
        }

        const formType = qs.parse(props.location.search);
        if (formType && formType.type && formType.type === 'new') {
            !props.location.state && history.goBack();
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Location', link: '#' }]);
            const { warehouseSiteParentData } = props.location.state;
            setWarehouseSiteData({ ...warehouseSiteParentData });
        }

        if (formType && formType.type && formType.type === 'edit') {
            handleUpdateComponentEditMode();
        }

        if (formType && formType.type && formType.type === 'view') {
            handleUpdateComponentViewMode();
        }

        const { prevCompState = null } = props.location.state || {};
        if (prevCompState) {
            setPrevCompState(prevCompState);
        }
    }, []);

    useEffect(() => {
        formData._id && loadWarehouseZoneTransactions();
        formData._id &&
            loadBinLocationsByZoneId(
                true,
                formData._id,
                binLocationListData.all,
                binLocationListData.page,
                binLocationListData.limit,
                binLocationListData.filter,
                binLocationListData.sort,
                binLocationListData.order
            );
    }, [formData._id]);

    const loadWarehouseZoneTransactions = async () => {
        setIsTransactionLoading(true);
        if (formData._id) {
            try {
                const { data } = await getTransactionsByWarehouseZoneId(formData?._id);
                const mappedData = mapTransactionTableDocs(data);
                setTransactionTableData({ ...transactionTableData, docs: mappedData });
            } catch (e) {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            } finally {
                setIsTransactionLoading(false);
            }
        }
        setIsTransactionLoading(false);
    };

    const loadBinLocationsByZoneId = (showLoading = true, id, all, page, limit, filter, sort, order, searchByParam) => {
        if (showLoading) {
            setBinLocationLoading((prevState) => prevState + 1);
        }
        getBinLocationsByZoneIdService(id, all, page, limit, filter, sort, order, searchByParam, null)
            .then((res) => {
                const { data } = res;
                if (data && data.docs) {
                    setBinLocationListData({
                        ...binLocationListData,
                        ...data,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setBinLocationLoading((prevState) => prevState - 1);
                }
            });
    };

    const handleUpdateComponentViewMode = () => {
        try {
            !props.location.state && history.goBack();
            const { warehouseZoneParentData } = props.location.state;
            setFormData({ ...formData, ...warehouseZoneParentData });
            setIsViewMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'View User', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpdateComponentEditMode = () => {
        try {
            !props.location.state && history.goBack();
            const { warehouseZoneParentData } = props.location.state;
            setFormData({ ...formData, ...warehouseZoneParentData });
            const { prevCompState = null } = props.location.state || {};
            // Update URL Based on Condition
            handleUpdateUrl(prevCompState, warehouseZoneParentData, 'warehouseZoneParentData');
            if (prevCompState && prevCompState.refreshUser) {
                // This code is executed only during page reload
                // loadUserById(warehouseZoneParentData._id);
            }
            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Zone', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    // Update Address Bar URL
    // PATH | QUERY | STATE
    const handleUpdateUrl = (prevState, data, id) => {
        // Handle Remove PrevComponent
        try {
            history.replace({
                pathname: `${PATHS.WAREHOUSE_SITE.LOCATION_FORM}`,
                search: `?type=edit&id=${data._id}`,
                state: {
                    [id]: data,
                    prevCompState: { ...prevState, refreshPage: true },
                },
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleSubmit = async (event, errors) => {
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);

        let payload = { ...formData };

        if (!editingMode && !warehouseSiteData._id && !warehouseSiteData.referenceNumber) {
            setFormLoading(false);
            return;
        }

        payload = {
            ...payload,
            warehouseSiteId: warehouseSiteData._id,
            warehouseSiteReferenceNumber: warehouseSiteData.referenceNumber,
        };

        // payload.primaryContactNumber = cleanMobileNo(payload.primaryContactNumber);
        // payload.officeNo = cleanMobileNo(payload.officeNo);

        try {
            let responseData;
            if (editingMode) {
                const { data } = await updateWarehouseZoneService(payload);
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            } else {
                const { data } = await createWarehouseZoneService(payload);
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            }
            setEditingMode(true);

            try {
                // Replace URL so relevant changes can be done
                if (responseData) {
                    history.replace({
                        pathname: `${PATHS.WAREHOUSE_SITE.LOCATION_FORM}`,
                        search: `?type=edit&id=${responseData._id}`,
                        state: {
                            warehouseZoneParentData: responseData,
                            prevCompState,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} Location`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setIsFormSaved(true);
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        loadWarehouseZoneTransactions();
        setFormLoading(false);
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleCheckChange = (event) => {
        setIsFormSaved(false);
        const { id, checked } = event.target;
        setFormData({
            ...formData,
            [id]: checked,
        });
    };

    const handleOnClickNewBin = () => {
        history.push({
            pathname: `${PATHS.WAREHOUSE_SITE.LOCATION_BIN_FORM}`,
            search: `?type=new`,
            state: { warehouseZoneParentData: formData },
        });
    };

    const handleEditBinLocationRow = (e, row) => {
        history.push({
            pathname: `${PATHS.WAREHOUSE_SITE.LOCATION_BIN_FORM}`,
            search: `?type=edit`,
            state: { binLocationParentData: row, warehouseZoneParentData: formData },
        });
    };

    const handleOnDelete = (e, row) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: row,
            message: 'Are you sure you want to delete Bin Location?',
            onConfirm: (data) => handleDeleteWarehouseSite(data),
        });
    };

    const handleDeleteWarehouseSite = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteBinLocationService(data._id);
            loadBinLocationsByZoneId(
                true,
                formData._id,
                binLocationListData.all,
                binLocationListData.page,
                binLocationListData.limit,
                binLocationListData.filter,
                binLocationListData.sort,
                binLocationListData.order
            );
            toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        toggleScootixModal();
        setBackdropLoading((prevState) => prevState - 1);
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    return (
        <>
            <Hoc {...props}>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                        <Row
                            style={{
                                margin: 2,
                                justifyContent: 'space-between',
                            }}
                        >
                            {!editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                >
                                    <i className="far fa-times-circle mr-2 fa-lg" />
                                    Cancel
                                </motion.div>
                            )}
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormStatusBtn isFormSaved={isFormSaved} />
                            </div>

                            {editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                >
                                    <i className="fas fa-arrow-left mr-2 fa-lg" />
                                    Back to Warehouse
                                </motion.div>
                            )}
                        </Row>
                        {!editingMode && (
                            <>
                                <ClientAlerts />
                            </>
                        )}
                        <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                            <CardBody>
                                <Row style={{ justifyContent: 'space-between', alignItems: 'center', margin: 2 }}>
                                    <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                        WAREHOUSE ZONE
                                    </SubTitle>
                                </Row>
                                <br />
                                <AvForm
                                    className="needs-validation default-form"
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                    id="warehouseZoneForm"
                                >
                                    <fieldset disabled={isViewMode || formLoading}>
                                        <Row>
                                            {(editingMode || isViewMode) && (
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="referenceNumber">Zone ID</Label>
                                                        <AvField
                                                            name="referenceNumber"
                                                            placeholder="Site ID"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            disabled
                                                            id="referenceNumber"
                                                            value={formData.referenceNumber}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            )}
                                        </Row>

                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="zoneName">Zone Name</Label>
                                                    <AvField
                                                        name="zoneName"
                                                        placeholder="Zone Name"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        id="zoneName"
                                                        value={formData.zoneName || ''}
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="stringVolumeInCmb">Storage Volume in CBM </Label>
                                                    <AvField
                                                        name="stringVolumeInCmb"
                                                        placeholder="Storage Volume in CBM "
                                                        type="number"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        id="stringVolumeInCmb"
                                                        value={formData.stringVolumeInCmb || ''}
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <Label htmlFor="description">Description</Label>
                                                    <AvField
                                                        name="description"
                                                        placeholder="Description"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        id="description"
                                                        value={formData.description || ''}
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={4}>
                                                <Label htmlFor="materialHandling">Material Handling</Label>
                                                <AvCheckboxGroup name="materialHandling" className="form-check-group">
                                                    <AvCheckbox
                                                        label="Fragile"
                                                        checked={formData.isFragile}
                                                        id="isFragile"
                                                        onChange={handleCheckChange}
                                                    />
                                                    <AvCheckbox
                                                        label="Frozen"
                                                        checked={formData.isFrozen}
                                                        id="isFrozen"
                                                        onChange={handleCheckChange}
                                                    />
                                                    <AvCheckbox
                                                        label="Chill"
                                                        checked={formData.isChill}
                                                        id="isChill"
                                                        onChange={handleCheckChange}
                                                    />
                                                </AvCheckboxGroup>
                                            </Col>
                                        </Row>

                                        <Row style={{ justifyContent: 'flex-end' }}>
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                type="submit"
                                                disabled={formLoading}
                                            >
                                                {formLoading ? (
                                                    <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                ) : (
                                                    <>
                                                        <i className="fas fa-save left-icon"></i>
                                                        <span className="h6">{editingMode ? 'Update' : 'Save'}</span>
                                                    </>
                                                )}
                                            </motion.button>
                                        </Row>
                                    </fieldset>
                                </AvForm>
                            </CardBody>
                        </Card>

                        <br />
                        {editingMode && (
                            <>
                                <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                                    <div className="route-planning__form-header pl-3">
                                        <Row
                                            className="flex align-items-center align-content-center"
                                            style={{ height: 60 }}
                                        >
                                            <Col className="header-text">System Transactions</Col>
                                        </Row>
                                    </div>

                                    <CardBody className="px-3">
                                        <Row>
                                            <TableCustom
                                                tableData={transactionTableData}
                                                loading={isTransactionLoading}
                                                showDelete={false}
                                                showPagination={false}
                                                showView={false}
                                                removeId
                                                isFullScreenShow={false}
                                                isFilter={false}
                                                isSearch={false}
                                                removeAction
                                                isFixedHeight
                                            />
                                        </Row>
                                    </CardBody>
                                </Card>
                                <br />
                                <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                                    <CardBody>
                                        <Row className="justify-content-between">
                                            <SubTitle
                                                style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}
                                                className="ml-3 mt-2"
                                            >
                                                BIN LOCATIONS{' '}
                                            </SubTitle>
                                            {hasAccess(PERMISSIONS.WAREHOUSE, ['Add']) && (
                                                <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                                    onClick={() => handleOnClickNewBin()}
                                                >
                                                    <i className="fas fa-warehouse left-icon mr-2" />
                                                    {'  '}{' '}
                                                    <div style={{ marginTop: 1, marginLeft: 3 }}>New Bin Location</div>
                                                </motion.div>
                                            )}
                                        </Row>
                                        <hr />
                                        <div style={{ marginTop: -15 }}>
                                            <TableCustom
                                                id="warehouse-bin-location-id"
                                                tableData={binLocationListData}
                                                loading={binLocationLoading}
                                                isFullScreenShow
                                                handleEdit={handleEditBinLocationRow}
                                                handleDelete={handleOnDelete}
                                                showView={false}
                                                showEdit={hasAccess(PERMISSIONS.WAREHOUSE, ['Edit'])}
                                                showDelete={hasAccess(PERMISSIONS.WAREHOUSE, ['Delete'])}
                                                removeId={false}
                                                isExport
                                                type="binLocation"
                                                exportModalHeader="Export Bin Location Data"
                                                filters={[]}
                                                isSearch={false}
                                                isFilter={false}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </>
                        )}
                    </Container>
                </div>
            </Hoc>

            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

SiteLocationFormPage.propTypes = {};

const mapStateToProps = (state) => ({
    ...state,
});

const HotSiteLocationFormPage = hot(SiteLocationFormPage);

export default connect(mapStateToProps, {})(HotSiteLocationFormPage);
