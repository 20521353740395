import { AUTH_VERIFY_RIDER } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const verifyRiderService = (data) => {
    try {
        const url = UrlBuilder().setUrl(AUTH_VERIFY_RIDER).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
