/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import { motion } from 'framer-motion';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { BackdropLoader, Select, TableCustom } from 'components';

// #region imports
import PATHS from 'routes/paths';
import AccessDenied from 'pages/Utility/AccessDenied';

// #region services
import { getAllRidersService } from 'services/rider.service';

// #region utils
import { getDateValue, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import { defaultTableHeaders } from './constants';
import { generateEarningsReport } from '../services';
import { getMappedData } from '../Billing/helpers';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'User Management', link: PATHS.USER_PROFILE_MANAGEMENT.DEFAULT },
    { title: 'Billing', link: '#' },
];

/**
 * Billing Profile Page
 * @param {*} props
 * @returns {React.Component}
 */
function EarningSummaryPage() {
    const todayTo = new Date();
    const todayFrom = new Date();

    // component state
    const [loading, setLoading] = useState(0);
    const [formLoading] = useState(0);
    const [formData, setFormData] = useState({
        fromDate: new Date(todayTo.setMonth(todayTo.getMonth() - 1)).toISOString(),
        toDate: todayFrom.toISOString(),
        reportType: 'Daily',
    });
    const [riders, setRiders] = useState([]);

    const [submitted] = useState(false);
    const [validated] = useState(false);

    const [backdropLoading] = useState(0);

    const [earningSummaryData, setEarningSummaryData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    useEffect(() => {
        getAllRiders();
    }, []);

    const getAllRiders = async () => {
        const { data } = await getAllRidersService();
        if (data.docs) {
            setRiders(data.docs);
        }
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;

        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleSelectChange = (event, id) => {
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleGenerateReport = async () => {
        const postData = {};
        postData.filters = formData;
        setLoading(true);
        try {
            const { data } = await generateEarningsReport(postData);

            if (data.tableData) {
                if (Array.isArray(data.tableData)) {
                    const customColumns = [
                        {
                            key: 'riderName',
                            value: 'Rider Name',
                        },
                        {
                            key: 'riderReferenceNo',
                            value: 'Rider ID',
                        },
                        {
                            key: 'riderMobileNo',
                            value: 'Rider Mobile Number',
                        },
                    ];

                    const mappedData = data.tableData.map((val) => ({
                        ...val,
                        [`numberOfDeliveries${val.date}`]: val.billingRecordData?.length || 0,
                        [`earningSummary${val.date}`]: `MYR ${val.totalEarnings.toFixed(2) || 0}`,
                    }));

                    const finalMappedData = mappedData.filter((stuff) => stuff.riderName);

                    for (let i = 0; i < mappedData.length; i++) {
                        const val = mappedData[i];
                        const columnNoOfDeliveries = {
                            value: `No of Deliveries ${val.date}`,
                            key: `numberOfDeliveries${val.date}`,
                        };
                        customColumns.push(columnNoOfDeliveries);
                        const columnEarningSummary = {
                            value: `Earning Summary on ${val.date}`,
                            key: `earningSummary${val.date}`,
                        };
                        customColumns.push(columnEarningSummary);
                    }
                    setEarningSummaryData({
                        ...earningSummaryData,
                        docs: getMappedData(finalMappedData, data.uniqueRiders),
                        headers: customColumns,
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const getSelectedRider = (_id) => {
        const relevantRider = riders.find((x) => x._id === _id);
        if (relevantRider) {
            return `${relevantRider.fullName} - ${relevantRider.referenceNumber} - ${relevantRider.mobileNo}`;
        }
        return null;
    };

    if (!hasAccess(PERMISSIONS.RIDER_COMMISSION, ['View'])) {
        return <AccessDenied pageName="Rider Commissions " />;
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />

                    <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                        <CardBody>
                            <AvForm
                                autoComplete="off"
                                className="needs-validation default-form"
                                noValidate
                                validated={validated}
                                id="billingForm"
                            >
                                <Row>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="fromDate">From </Label>
                                            <Input
                                                onChange={handleInputChange}
                                                type="datetime-local"
                                                className="form-control"
                                                value={getDateValue(formData.fromDate, 'datetime-local')}
                                                validate={{ required: { value: true } }}
                                                id="fromDate"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="toDate">To</Label>
                                            <Input
                                                onChange={handleInputChange}
                                                type="datetime-local"
                                                className="form-control"
                                                value={getDateValue(formData.toDate, 'datetime-local')}
                                                validate={{ required: { value: true } }}
                                                id="toDate"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="reportType">Report Type</Label>
                                            <Select
                                                value={getDefaultValueForSelect(formData.reportType)}
                                                options={[
                                                    { label: 'Daily', value: 'Daily' },
                                                    { label: 'Weekly', value: 'Weekly' },
                                                    { label: 'Monthly', value: 'Monthly' },
                                                ]}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'reportType')}
                                                submitted={submitted}
                                                validated={validated}
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="riderId">Rider</Label>
                                            <Select
                                                value={getDefaultValueForSelect(getSelectedRider(formData.riderId))}
                                                options={riders.map((rider) => ({
                                                    label: `${rider.fullName} - ${rider.referenceNumber} - ${rider.mobileNo}`,
                                                    value: rider._id,
                                                }))}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'riderId')}
                                                submitted={submitted}
                                                validated={validated}
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ justifyContent: 'flex-start' }}>
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                        onClick={handleGenerateReport}
                                        disabled={formLoading}
                                    >
                                        {formLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <i className="fas fa-save left-icon"></i>
                                                <span className="h6">Create Report</span>
                                            </>
                                        )}
                                    </motion.button>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>

                    <Row>
                        <TableCustom
                            tableData={earningSummaryData}
                            loading={loading}
                            isExport
                            type="earningSummary"
                            exportModalHeader="Export Earning Summary"
                            showEdit={false}
                            removeAction
                            showView={false}
                            isFilter={false}
                        />
                    </Row>
                </Container>
            </div>
            <BackdropLoader show={backdropLoading} opacity={0.7} />
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

const HotEarningSummaryPage = hot(EarningSummaryPage);

export default connect(mapStateToProps, {})(HotEarningSummaryPage);
