/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { motion } from 'framer-motion';
import { Row, Col, Card, CardBody, Spinner, Label, Input, FormGroup, DropdownItem } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { connect, useDispatch } from 'react-redux';
import './styles.css';

// #region assets | components
import { Calendar, Clock, Search } from 'components/Icons';
import { Select } from 'components';

// #region utils
import { reactSelectCustomStyles, apiErrorHandlerV2, getDateValue } from 'utils/helpers';

// #endregion imports
import { UPDATE_BATCH_PRINT_DELIVERY_DATA, UPDATE_DELIVERY_FILTERS } from '../../actionTypes';
import { deliveryStatusOptions, mapDocs } from './utils';
import { getBatchPrintDeliveriesByFilter, getMerchantsLocationList, getRiderListData } from './services';
import { batchPrintDeliveryListData } from './constant';

function DeliveryFilters(props) {
    const { BatchPrintForm } = props;

    const _isMounted = useRef(true);

    const dispatch = useDispatch();

    const { handleSubmit, formLoading, submitted, validated, initialFormData } = props;

    const initialFormValues = { deliveryStatus: null, merchantLocations: null, fromDate: null, toDate: null };
    // Data
    const [dataLoading, setDataLoading] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);
    const [merchantLocations, setMerchantLocations] = useState();
    const [riders, setRiders] = useState();

    // Table Data
    const [deliveryListData, setDeliveryListData] = useState(batchPrintDeliveryListData);

    useEffect(
        () => () => {
            _isMounted.current = false;
        },
        []
    );

    useEffect(() => {
        loadDeliveryData({ showLoading: true, status: 'PICKUP_PENDING' });
        loadMerchantLocations();
        loadRiderListData();
    }, []);

    /**
     * Load Merchant Locations to Dropdown
     *
     */
    const loadMerchantLocations = async () => {
        try {
            const { data } = await getMerchantsLocationList({
                page: 1,
                limit: 1000,
                selectOnly: 'merchantLocationName',
            });
            if (_isMounted.current && data) {
                setMerchantLocations(data.docs.map((x) => ({ ...x, label: x.merchantLocationName, value: x._id })));
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        }
    };

    /**
     * Load Riders data to Dropdown
     *
     */
    const loadRiderListData = async () => {
        try {
            const { data } = await getRiderListData({ page: 1, limit: 1000, selectOnly: 'fullName' });
            if (_isMounted.current && data?.docs) {
                setRiders(data.docs.map((x) => ({ ...x, label: x.fullName, value: x._id })));
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        }
    };

    /**
     * Loading List data initially and according to the filters as well
     *
     */
    const loadDeliveryData = async (args) => {
        if (args.showLoading) {
            setDataLoading(true);
        }

        // creating a payload conditionally for both quick print and detailed print cases
        let payload = null;

        const { merchantId, clientId } = initialFormData;
        const { deliveryStatus, merchantLocationId, riderId, fromDate, toDate } = formData;

        if (args.status) {
            payload = {
                module: 'SHIPPING_LABEL',
                quickFilterStatus: args.status,
                merchantId,
                clientId,
            };
            setFormData(initialFormValues);
        } else {
            payload = {
                module: 'SHIPPING_LABEL',
                deliveryStatus,
                merchantLocationId,
                riderId,
                createdFrom: fromDate,
                createdTo: toDate,
                merchantId,
                clientId,
            };
        }

        try {
            const { data } = await getBatchPrintDeliveriesByFilter(payload);
            if (_isMounted.current && data) {
                const docs = mapDocs(data.docs);

                // dispatching the filter details
                dispatch({
                    type: UPDATE_DELIVERY_FILTERS,
                    payload: { ...payload },
                });

                // Selected Deliveries
                const selectedDeliveries = BatchPrintForm?.selectedDeliveries || [];

                // Filtered Deliveries to be listed on table
                const filteredDeliveries = [];

                // filter out deliveries that only can be shown
                docs.map((record) => {
                    const isExist = selectedDeliveries.some((x) => x._id === record._id);
                    if (!isExist) {
                        filteredDeliveries.push(record);
                    }
                    return record;
                });

                const stateUpdate = {
                    ...deliveryListData,
                    ...data,
                    docs: filteredDeliveries,
                };

                setDeliveryListData(stateUpdate);
                dispatch({
                    type: UPDATE_BATCH_PRINT_DELIVERY_DATA,
                    payload: { ...stateUpdate, updatedAt: new Date() },
                });
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        } finally {
            if (args.showLoading) {
                setDataLoading(false);
            }
        }
    };

    const handleSelectChange = (event, id) => {
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const getSelectMerchantLocation = (value) => {
        if (value) {
            const relevantObj = merchantLocations.find((x) => x.value === value);
            if (relevantObj) {
                return { label: relevantObj.label, value: relevantObj.value };
            }
        }
        return null;
    };
    const getSelectRider = (value) => {
        if (value) {
            const relevantObj = riders.find((x) => x.value === value);
            if (relevantObj) {
                return { label: relevantObj.label, value: relevantObj.value };
            }
        }
        return null;
    };

    const getSelectDeliveryStatus = (value) => {
        if (value) {
            const relevantObj = deliveryStatusOptions.find((x) => x.value === value);
            if (relevantObj) {
                return { label: relevantObj.label, value: relevantObj.value };
            }
        }
        return null;
    };

    // Memos
    const selectedDeliveryStatus = useMemo(
        () => getSelectDeliveryStatus(formData.deliveryStatus),
        [formData.deliveryStatus]
    );
    const selectedMerchantLocation = useMemo(
        () => getSelectMerchantLocation(formData.merchantLocationId),
        [formData.merchantLocationId]
    );
    const selectedRiderId = useMemo(() => getSelectRider(formData.riderId), [formData.riderId]);

    return (
        <>
            <AvForm className="select-batch-print default-form" onValidSubmit={handleSubmit}>
                <fieldset disabled={formLoading}>
                    <Card className="delivery-filter__container">
                        <CardBody className="delivery-filter__body">
                            <Row className="mt-2">
                                <Col md={12}>
                                    <Label htmlFor="selectBatchPrint">Select the Deliveries for the Batch Print</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className={`new-deliveries-created-today-btn scootix-form-btn save-btn m-2 mb-3 nw-md pr-4 pl-4 ${
                                            BatchPrintForm?.deliveryFilters?.quickFilterStatus === 'New'
                                                ? 'bg-warning'
                                                : ''
                                        }`}
                                        id="newDeliveries"
                                        onClick={() => {
                                            loadDeliveryData({ showLoading: true, status: 'New' });
                                        }}
                                        disabled={formLoading || dataLoading}
                                        style={{ borderRadius: 30 }}
                                    >
                                        {formLoading || dataLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <div className="select-batch-print-btn-content-wrap">
                                                    <Calendar />
                                                    <span className="h6">New Deliveries</span>
                                                </div>
                                            </>
                                        )}
                                    </motion.button>
                                </Col>
                                <Col md={2}>
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className={`pickup-pending-btn scootix-form-btn save-btn m-2 mb-3 nw-md pr-4 pl-4 ${
                                            BatchPrintForm?.deliveryFilters?.quickFilterStatus === 'PICKUP_PENDING'
                                                ? 'bg-warning'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            loadDeliveryData({ showLoading: true, status: 'PICKUP_PENDING' });
                                        }}
                                        disabled={formLoading || dataLoading}
                                        style={{ borderRadius: 30 }}
                                    >
                                        {formLoading || dataLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <div className="select-batch-print-btn-content-wrap-clock">
                                                    <Clock />
                                                    <span className="h6">Pickup Pending</span>
                                                </div>
                                            </>
                                        )}
                                    </motion.button>
                                </Col>
                                <Col md={2}>
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className={`new-deliveries-created-today-btn scootix-form-btn save-btn m-2 mb-3 nw-md pr-4 pl-4 ${
                                            BatchPrintForm?.deliveryFilters?.quickFilterStatus === 'CREATED_TODAY'
                                                ? 'bg-warning'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            loadDeliveryData({ showLoading: true, status: 'CREATED_TODAY' });
                                        }}
                                        disabled={formLoading || dataLoading}
                                        style={{ borderRadius: 30 }}
                                    >
                                        {formLoading || dataLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <div className="select-batch-print-btn-content-wrap">
                                                    <Calendar styles={{ marginTop: 1 }} />
                                                    <span className="h6">Created Today</span>
                                                </div>
                                            </>
                                        )}
                                    </motion.button>
                                </Col>
                            </Row>
                            <br />
                            <Row className="mt-2">
                                <Label className="pl-3">Detailed Search</Label>
                            </Row>
                            <DropdownItem style={{ borderTopColor: '#000000' }} divider />
                            <br />
                            <Row className="mt-2" style={{ paddingLeft: 24 }}>
                                <Col md={6}>
                                    <Label style={{ marginLeft: -25 }}>Create Date and Time</Label>
                                    <div className="row" style={{ marginTop: 1 }}>
                                        <FormGroup className="row">
                                            <Label className="mt-2" htmlFor="from">
                                                From
                                            </Label>
                                            <div className="pl-2" style={{ width: 187 }}>
                                                <Input
                                                    onChange={handleInputChange}
                                                    type="datetime-local"
                                                    value={getDateValue(formData.fromDate, 'datetime-local')}
                                                    validate={{ required: { value: true } }}
                                                    id="fromDate"
                                                />
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="row ml-4">
                                            <Label className="mt-2" htmlFor="to">
                                                To
                                            </Label>
                                            <div className="ml-2" style={{ width: 187 }}>
                                                <Input
                                                    onChange={handleInputChange}
                                                    type="datetime-local"
                                                    value={getDateValue(formData.toDate, 'datetime-local')}
                                                    validate={{ required: { value: true } }}
                                                    id="toDate"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label htmlFor="selectBatchPrint">Delivery Status</Label>
                                        <Select
                                            value={selectedDeliveryStatus}
                                            id="deliveryStatus"
                                            isClearable
                                            options={deliveryStatusOptions}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'deliveryStatus')}
                                            submitted={submitted}
                                            validated={validated}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label htmlFor="selectBatchPrint">Rider</Label>
                                        <Select
                                            value={selectedRiderId}
                                            isClearable
                                            options={riders}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'riderId')}
                                            submitted={submitted}
                                            validated={validated}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label htmlFor="from">Merchant Locations</Label>
                                        <Select
                                            value={selectedMerchantLocation}
                                            isClearable
                                            options={merchantLocations}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'merchantLocationId')}
                                            submitted={submitted}
                                            validated={validated}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col md={2}>
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="search-btn scootix-form-btn save-btn m-2 mb-3 nw-md pr-4 pl-4"
                                        onClick={() => loadDeliveryData({ showLoading: true })}
                                        disabled={formLoading || dataLoading}
                                        style={{ borderRadius: 30 }}
                                    >
                                        {formLoading || dataLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <Search />
                                                <span className="h6">Search</span>
                                            </>
                                        )}
                                    </motion.button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </fieldset>
            </AvForm>
        </>
    );
}

DeliveryFilters.propTypes = {
    BatchPrintForm: PropTypes.object,
    handleSubmit: PropTypes.func,
    formLoading: PropTypes.bool,
    submitted: PropTypes.bool,
    validated: PropTypes.bool,
    initialFormData: PropTypes.object,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotDeliveryFilters = hot(DeliveryFilters);

export default connect(mapStateToProps, {})(HotDeliveryFilters);
