const PREFIX = `app`;

export default {
    HOME: {
        DEFAULT: '/home',
    },
    AUTH: {
        LOGIN: '/login',
        VERIFY_RIDER: '/verify-rider',
        VERIFY_USER: '/verify',
        TEMPORARY_PASSWORD_RESET: '/temporary-password-reset',
    },
    SWITCH_PROFILE: {
        DEFAULT: '/switch-profile',
    },
    USER_PROFILE_MANAGEMENT: {
        DEFAULT: `/user-management/list`,
        FORM: `/user-management/form`,
    },
    RIDER_PROFILE_MANAGEMENT: {
        LIST_VIEW: `/rider-profiles/list`,
        FORM_VIEW: `/rider-profiles/form`,
        DELETED_RIDERS: `/rider-profile-management/deleted`,
    },
    ORDER_PROCESSING: {
        DEFAULT: `/order-processing/list`,
        PACKING: `/order-processing/list/packing`,
        ORDER_FORM: `/order-processing/form`,
        PROCESSED_ORDERS: `/order-processing/processed-orders`,
    },
    PICKING_TASK: {
        DEFAULT: `/picking-task/list`,
        FORM: `/picking-task/form`,
    },
    PACKAGING: {
        FORM: `/order-processing/packaging/form`,
    },
    DELIVERY: {
        DEFAULT: `/delivery/list`,
        FORM: `/delivery/form`,
        COMPLETED: '/delivery/completed-list',
        DELETED: '/delivery/deleted-list',
        UNASSIGNED: '/delivery/unassigned-list',
        REPORT: '/delivery/report',
        BATCH_ASSIGNMENT: `${PREFIX}/delivery/batch-assignment`,
        ROUTE_PLANNING: {
            ROUTE_PLANNING_FORM: 'delivery/route-planning',
            ROUTE_PLANNING_ENTRIES: 'delivery/route-planning/entries',
            ROUTE_PLANNING_ENTRY_VIEW: 'delivery/route-planning/entry-view',
        },
    },
    PENDING_DATA: {
        DEFAULT: `/pending-data`,
        DELIVERY_VIEW: `/pending-data/delivery/form`,
    },
    MERCHANT: {
        DEFAULT: `/merchant/list`,
        MERCHANT_FORM: `/merchant/form`,
        MERCHANT_LOCATION_LIST: `/merchant/locations-list`,
        MERCHANT_LOCATION_FORM: `/merchant/locations-form`,
        MERCHANT_DELETED: `/merchant/list/deleted`,
        MERCHANT_LOCATION_DELETED: `/merchant-locations/list/deleted`,
    },
    USER_PROFILE: {
        DEFAULT: '/user-profile',
    },
    DELETED_USERS: {
        DEFAULT: '/deleted-users',
    },
    ROLE_PERMISSION: {
        DEFAULT: '/role-permission',
        NEW_ROLE: '/role/form',
    },
    SHIPPING_LABEL: {
        DEFAULT: '/shipping-label',
        VIEW: '/shipping-label/view',
        PRINT_VIEW: '/shipping-label/view/print',
    },
    ADVANCED_RIDER_TRACKING: {
        DEFAULT: '/riders/advanced-rider-tracking',
    },
    IMPORTS: {
        DELIVERY_IMPORTS: {
            DEFAULT: '/imports/delivery',
        },
        BILLING_IMPORTS: {
            DEFAULT: '/imports/billing',
        },
        RIDER_COMMISSION_IMPORTS: {
            DEFAULT: '/imports/rider-commissions',
        },
    },
    SETTINGS: {
        DELIVERY: {
            DEFAULT: '/settings/delivery',
            EXTERNAL_SYSTEM_CONNECTIONS: '/settings/delivery/external-connections',
            EXCEL_CSV_IMPORT: '/settings/delivery/external-connections/excel-csv',
        },
    },

    WAREHOUSE_SITE: {
        DEFAULT: `/warehouse/warehouse-site/list`,
        FORM_VIEW: `/warehouse/warehouse-site/form`,
        LOCATION_FORM: `/warehouse/warehouse-site/location/form`,
        LOCATION_BIN_FORM: `/warehouse/warehouse-site/location/bin/form`,
    },
    PRODUCT_MASTER_DATA: {
        DEFAULT: `/warehouse/product-master-data/list`,
        FORM_VIEW: `/warehouse/product-master-data/form`,
    },

    STOCKS_RECEIVING: {
        DEFAULT: `/warehouse/stocks-receiving/list`,
        FORM_VIEW: `/warehouse/stocks-receiving/form`,
        DELETED_LIST: `/warehouse/stocks-receiving/deleted/list`,
    },

    PAYMENTS: {
        DEFAULT: `/warehouse/list`,
        BILLING_LIST: `/billing/list`,
        BILLING_FORM: `/billing/form`,
        BILLING_RECORD: `/billing/record/form`,
        RIDER_COMMISSIONS_FORM: `/rider-commissions/form`,
        RIDER_COMMISSIONS_LIST: `/rider-commissions/list`,
        RIDER_COMMISSION_RECORD: `/rider-commission/record/form`,
        EARNING_SUMMARY: `/earning-summary`,
    },

    NOTIFICATION: {
        DEFAULT: '/notifications',
    },

    STOCK_ADJUSTMENT: {
        DEFAULT: '/warehouse/stock-adjustment/list',
        FORM: '/warehouse/stock-adjustment/form',
    },

    INVENTORY_REPORT: {
        DEFAULT: `/warehouse/reports/inventory/view`,
    },

    TERMS_AND_CONDITIONS: {
        SCOOTIX_APPLICATION: {
            'V1.0': `/terms-conditions/scootix-application/v1.0`,
            'V2.0': `/terms-conditions/scootix-application/v2.0`,
        },
    },
    LABEL_PRINT: {
        SHIPPING_LABEL: {
            LIST_VIEW: `${PREFIX}/label-print/shipping-label/list`,
            BATCH_PRINT_VIEW: `${PREFIX}/label-print/shipping-label/batch-print`,
        },
        PRINTER_SETTINGS: {
            DEFAULT: `/label-print/shipping-label/printer-settings`,
        },
        ARCHIVED_BATCH_PRINTS: {
            DEFAULT: `${PREFIX}/label-print/shipping-label/archive-print`,
        },
    },
    TEMP: {
        SHIPPING_LABEL: '/temp/label-print/shipping-label',
    },
    // TODO: Need to change this to `PARTNER_API_DEVELOPER`
    DEVELOPER: {
        API_SERVICE: 'developer/api-service/list',
        API_PORTAL: 'developer/api-portal',
        API_KEY_MANAGE: 'developer/api-key/manage',
        API_KEY_CREATE: 'developer/api-key/create',
        API_DASHBOARD: 'developer/api-dashboard',
        WEBHOOK_CREATE: 'developer/webhook/create',
        WEBHOOK_MANAGE: 'developer/webhook/manage',
    },
    PARTNER_API_DEVELOPER: {
        SANDBOX_ACCOUNT: {
            LIST: 'partner-api-developer/sandbox-account/list',
            FORM: 'partner-api-developer/sandbox-account/form',
        },
    },
};
