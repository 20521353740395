/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import * as qs from 'query-string';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// #region assets | components
import { Breadcrumbs, ClientAlerts, CustomToast, TableCustom } from 'components';

// #region imports
import PATHS from 'routes/paths';
import { FONT_PRIMARY_BLACK } from 'theme';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #region services
import {
    getPartnersFromPartnerApi,
    enableApiService,
    disableApiService,
    getApiServiceStatus,
} from 'services/partner-api.service';

// #endregion imports
import { hasAccess, IS_MERCHANT_VIEW } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import AccessDenied from 'pages/Utility/AccessDenied';
import { mapDocs } from './utils';
import { partnerListTableHeaders } from './constants';
import EnableApiServiceModal from './components/EnableApiServiceModal';
import DisableApiServiceModal from '../../components/DisableApiServiceModal';

function ApiSettingsPartnerList(props) {
    const { location } = props;
    const history = useHistory();
    const _isMounted = useRef(true);

    const breadcrumbItems = [
        { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
        { title: 'Developer', link: `/${PATHS.DEVELOPER.API_SERVICE}` },
    ];

    /** Whether data is being fetched currently */
    const [loading, setLoading] = useState(0);
    /** Table search parameters */
    const [searchParams, setSearchParams] = useState(null);
    /** Options for controlling the enable/disable modals */
    const [apiModalOptions, setApiModalOptions] = useState({ mode: null, show: false, data: null });
    /** Loaded data */
    const [partnerListData, setPartnerListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: partnerListTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });
    /** URL search parameters */
    const [urlParamsSearch, setUrlParamsSearch] = useState({});
    const [partnerApiServiceStatusOptions, setPartnerApiServiceStatusOptions] = useState({ status: false });

    const defaultSearchParams = {
        page: searchParams?.page,
        searchBy: searchParams?.searchBy,
        searchText: searchParams?.searchText,
        limit: searchParams?.limit,
    };

    // !TODO: add docs
    useEffect(
        () => () => {
            _isMounted.current = false;
        },
        []
    );

    useEffect(() => {
        getPartnerApiServiceStatus();
    }, []);

    /** Triggers the loadPartnerListData function if the URL search params (in urlParamsSearch state) have changed */
    useEffect(() => {
        if (urlParamsSearch) {
            loadPartnerListData(
                true,
                true,
                urlParamsSearch.page,
                urlParamsSearch.limit,
                partnerListData.filter,
                partnerListData.sort,
                partnerListData.order,
                { searchBy: urlParamsSearch?.searchBy, searchText: urlParamsSearch?.searchText },
                urlParamsSearch.status
            );
        }
    }, [urlParamsSearch]);

    /** Reads the URL and updates the urlParamsSearch state, which triggers another useEffect to fetch data */
    useEffect(() => {
        const urlSearch = qs.parse(location.search);
        if (urlSearch && (urlSearch.searchText || urlSearch.page)) {
            const { page, searchBy, searchText, limit, status } = urlSearch;
            setUrlParamsSearch({ page, searchBy, searchText, limit, status });
            setSearchParams({ searchBy, searchText });
        } else {
            replaceUrlParamsHandler({ page: '1', limit: 10 });
        }
    }, [location.search]);

    /**
     * Replaces the URL params when user types in the search box
     * @param {object} params - should contain page (string), limit (string), searchBy (string), searchText (string)
     */
    const replaceUrlParamsHandler = (
        params = { page: '1', limit: '10', searchBy: null, searchText: null, status: null }
    ) => {
        try {
            history.replace({
                search: `${
                    params.page || urlParamsSearch.page ? `page=${params.page || urlParamsSearch.page || '1'}&` : ''
                }${
                    params.limit || urlParamsSearch.limit
                        ? `limit=${params.limit || urlParamsSearch.limit || '10'}&`
                        : ''
                }${params?.searchBy ? `searchBy=${params.searchBy}&` : ''}${
                    params?.searchText ? `searchText=${params.searchText}&` : ''
                }${params?.status ? `status=${params.status}&` : ''}`,
            });
        } catch (e) {
            console.error(e);
            toast.custom(
                (t) => <CustomToast text="Failed to update URL to match search parameters" t={t} type="error" />,
                { position: 'top-right' }
            );
        }
    };

    async function getPartnerApiServiceStatus() {
        try {
            const { data } = await getApiServiceStatus();
            if (data?.payload?.service) {
                const { status } = data.payload.service;
                setPartnerApiServiceStatusOptions({
                    status,
                });
                return;
            }
            setPartnerApiServiceStatusOptions({
                status: false,
            });
        } catch (e) {
            console.error(e);
            setPartnerApiServiceStatusOptions({
                status: false,
            });
        }
    }

    /**
     * Populate the partner list by calling the relevant service
     * @param {bool} showLoading - whether to show the loading spinner in the table
     * @param {bool} all - whether to load all
     * @param {number} page - page number
     * @param {number} limit - number of entries to paginate by
     * @param {object} filter - filter object
     * @param {string} sort - field to sort by
     * @param {number} order - order to sort by: -1 or 1
     * @param {object} searchByProp - search prop - contains searchBy and searchText fields
     */
    const loadPartnerListData = async (
        showLoading = true,
        all,
        page,
        limit,
        filter,
        sort,
        order,
        searchByProp,
        status
    ) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }

        try {
            const res = await getPartnersFromPartnerApi(page, limit, sort, order, searchByProp, status);
            const { data } = res;
            const { payload } = data;
            if (data && payload && payload.docs) {
                const mappedDocs = mapDocs(payload.docs);
                setPartnerListData({
                    ...partnerListData,
                    ...payload,
                    docs: mappedDocs,
                });
            } else {
                toast.custom((t) => <CustomToast text="Failed to load merchant list" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (showLoading) {
            setLoading((prevState) => prevState - 1);
        }
    };

    /** Opens the merchant profile page in a new tab */
    const handleViewMerchantProfile = (row) => {
        if (!row) return;
        window.open(`${PATHS.MERCHANT.MERCHANT_FORM}?type=edit&id=${row.merchantId}`, '_blank');
    };

    /** Depending on the status of the clicked row, show the enable or disable modal */
    const handleQuickAction = (row) => {
        if (row.serviceStatusClient) {
            setApiModalOptions((prevState) => ({ ...prevState, mode: 'disable', show: !prevState.show, data: row }));
        } else if (!partnerApiServiceStatusOptions.status && !apiModalOptions.show) {
            toast.custom((t) => <CustomToast text="API Service Inactive" t={t} type="warning" />, {
                position: 'top-right',
            });
        } else {
            setApiModalOptions((prevState) => ({ ...prevState, mode: 'enable', show: !prevState.show, data: row }));
        }
    };

    /** Handles the custom action column, which is the 'Manage' button on the last column */
    const navigateToApiPortalPage = (row) => {
        history.push({
            pathname: `/${PATHS.DEVELOPER.API_PORTAL}`,
            state: { merchantId: row.merchantId, merchantName: row.merchantName, ...row },
        });
    };

    /** Handles table filter and adds those parameters to the URL */
    const handleFilter = (filterText) => {
        let modifiedFilterText = null;
        if (filterText && filterText !== '') {
            modifiedFilterText = filterText.replaceAll(' ', '$2');
        } else if (!filterText || filterText === '') {
            modifiedFilterText = null;
        }

        let field = 'merchantName';
        // If the search query starts with 'MRID', automatically assume that the user is
        // searching by merchant's reference number.
        if (modifiedFilterText && modifiedFilterText.startsWith('MRID')) {
            field = 'merchantReferenceNumber';
        }

        replaceUrlParamsHandler({
            ...defaultSearchParams,
            searchBy: field || 'merchantName',
            searchText: modifiedFilterText,
            status: urlParamsSearch.status,
            page: '1',
        });
    };

    /** Handles page change and adds those parameters to the URL */
    const handlePageChange = (pageNo) => {
        replaceUrlParamsHandler({
            ...defaultSearchParams,
            page: pageNo,
        });
    };

    /** Once the user submits a modal, call the enable/disable endpoint and display status in a toast */
    const handleModalSubmit = async (modalFormData) => {
        const response = {};
        try {
            let res;
            if (modalFormData.serviceStatusClient) {
                res = await disableApiService(modalFormData.merchantId, modalFormData);
            } else {
                res = await enableApiService(modalFormData.merchantId, modalFormData);
            }

            if (res.status === 200) {
                response.success = true;
                const toastMessage = modalFormData.serviceStatusClient
                    ? 'API disabled successfully'
                    : 'API enabled successfully';
                toast.custom((t) => <CustomToast text={toastMessage} t={t} type="success" />, {
                    position: 'top-right',
                });
            } else {
                toast.custom((t) => <CustomToast text="Failed to update status" t={t} type="error" />, {
                    position: 'top-right',
                });
            }

            loadPartnerListData(
                true,
                partnerListData.all,
                urlParamsSearch.page,
                urlParamsSearch.limit,
                partnerListData.filter,
                partnerListData.sort,
                partnerListData.order,
                searchParams
            );
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
            response.error = true;
        }
        return response;
    };

    /** Handle the changing of the status dropdown in the table header  */
    const handleStatusFilterChange = (status) => {
        replaceUrlParamsHandler({
            ...defaultSearchParams,
            status,
        });
    };

    if (!(hasAccess(PERMISSIONS.PARTNER_API_API_SERVICE, ['View']) && !IS_MERCHANT_VIEW())) {
        return <AccessDenied pageName="API Service" />;
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <Row style={{ justifyContent: 'space-between' }} className="ml-2 mr-3 mb-4">
                        <div>
                            <h5 className="ml-0 font-weight-bold" style={{ color: FONT_PRIMARY_BLACK }}>
                                API Service Status for Merchants
                            </h5>
                        </div>
                    </Row>
                    <ClientAlerts />
                    <Row>
                        <TableCustom
                            tableData={partnerListData}
                            loading={loading}
                            onFilter={handleFilter}
                            onPageChange={handlePageChange}
                            showViewMerchantProfileButton
                            onViewMerchantProfile={handleViewMerchantProfile}
                            onQuickAction={handleQuickAction}
                            showQuickAction={hasAccess(PERMISSIONS.PARTNER_API_API_SERVICE, ['EnableAndDisable'])}
                            removeAction
                            customActionHandlers={{ navigateToApiPortalPage }}
                            showApiCustomAction={hasAccess(PERMISSIONS.PARTNER_API_API_SERVICE, ['Edit'])}
                            searchProp={searchParams}
                            filters={[
                                {
                                    key: 'merchantReferenceNumber',
                                    label: 'Merchant Id',
                                },
                                {
                                    key: 'merchantName',
                                    label: 'Merchant Name',
                                },
                                {
                                    key: 'serviceStatusClient',
                                    label: 'Service Status',
                                },
                            ]}
                            isFixedHeight
                            showStickyView
                            tableHeaderType="PARTNER_LIST"
                            partnerListTableHeaderProps={{ handleFilter, handleStatusChange: handleStatusFilterChange }}
                        />
                    </Row>
                </Container>
            </div>

            <EnableApiServiceModal
                show={apiModalOptions.mode === 'enable' && apiModalOptions.show}
                handleToggle={handleQuickAction}
                handleSubmit={handleModalSubmit}
                data={apiModalOptions.data}
            />
            <DisableApiServiceModal
                show={apiModalOptions.mode === 'disable' && apiModalOptions.show}
                handleToggle={handleQuickAction}
                handleSubmit={handleModalSubmit}
                data={apiModalOptions.data}
            />
        </>
    );
}

ApiSettingsPartnerList.propTypes = {
    location: PropTypes.any,
};

const HotApiSettingsPartnerList = hot(ApiSettingsPartnerList);

export default connect(null, {})(HotApiSettingsPartnerList);
