/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable vars-on-top */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Col, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { motion } from 'framer-motion';
import GooglePlacesAutocomplete, { geocodeByPlaceId, geocodeByLatLng } from 'react-google-places-autocomplete';
// import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

// #region imports
import { getAddressObject } from 'utils/functions';
import { getVisitingObject } from 'utils/checkAuth';

import Map from './Map';

// Getting visitingClient purpose of here is to get the countryIsoCode of the current client
// And Pass it to the componentRestrictions to avoid searching unnecessary locations rather than client's country
const { visitingClient } = getVisitingObject();

const initialAutoCompletionRequestArgs = { componentRestrictions: { country: visitingClient?.countryIsoCode || 'my' } };

/**
 * Google Places Auto Complete Modal
 * @param {*} props
 * @returns
 */
function PlacesAutocompleteModal(props) {
    const { id, idMeta, returnLocationData, toggle, addressData = null, fieldId = 'places-field' } = props;

    const [addressSearch, setAddressSearch] = useState(
        addressData && addressData.searchedValue
            ? { label: '', value: {}, ...addressData.searchedValue }
            : { label: '', value: {} }
    );

    const [inputAddress, setInputAddress] = useState('');

    const [addressObj, setAddressObj] = useState(addressData);

    const [placeIdByCoordinates, setPlaceIdByCoordinates] = useState(null);

    // useEffect(() => {
    //     if (addressData) {
    //         setAddressObj(addressObj);
    //     }
    //     if (addressData && addressData.label) {
    //         setAddressSearch({ ...addressSearch, label: addressData.label });
    //     }
    // }, [addressData]);

    useEffect(() => {
        const func = async () => {
            if (addressSearch && addressSearch.value && addressSearch.value.place_id) {
                const geocodeObj =
                    addressSearch && addressSearch.value && (await geocodeByPlaceId(addressSearch.value.place_id));
                let addressObject =
                    geocodeObj &&
                    getAddressObject(geocodeObj[0].address_components, addressSearch ? addressSearch.label : null);
                if (returnLocationData && addressObject) {
                    addressObject = {
                        ...addressObject,
                        place_id: geocodeObj[0].place_id || null,
                        coordinates: geocodeObj[0]
                            ? geocodeObj[0].geometry.location
                                ? {
                                      lat: geocodeObj[0].geometry.location.lat(),
                                      lng: geocodeObj[0].geometry.location.lng(),
                                  }
                                : { lat: 59.95, lng: 30.33 }
                            : { lat: 59.95, lng: 30.33 },
                        searchedValue: { label: addressSearch.label || '' },
                        inputAddress,
                    };
                    setAddressObj(addressObject);
                    returnLocationData(id, idMeta, addressObject);
                }
                console.log('addressObject', addressObject);
                if (addressObject.address) {
                    setInputAddress(addressObject.address);
                }
            }
        };
        func();
    }, [addressSearch.value]);

    useEffect(() => {
        const func = async () => {
            if (placeIdByCoordinates) {
                const geocodeObj = await geocodeByPlaceId(placeIdByCoordinates);
                let addressObject =
                    geocodeObj &&
                    getAddressObject(geocodeObj[0].address_components, addressSearch ? addressSearch.label : null);
                if (returnLocationData && addressObject) {
                    addressObject = {
                        ...addressObject,
                        place_id: geocodeObj[0].place_id || null,
                        coordinates: geocodeObj[0]
                            ? geocodeObj[0].geometry.location
                                ? {
                                      lat: geocodeObj[0].geometry.location.lat(),
                                      lng: geocodeObj[0].geometry.location.lng(),
                                  }
                                : { lat: 59.95, lng: 30.33 }
                            : { lat: 59.95, lng: 30.33 },
                        searchedValue: { label: addressSearch.label || '' },
                        inputAddress,
                    };
                    setAddressObj(addressObject);
                    console.log('addressObject', addressObject);
                    if (addressObject.address) {
                        setInputAddress(addressObject.address);
                        setAddressSearch({ ...addressSearch, label: addressObject.address });
                    }
                    returnLocationData(id, idMeta, addressObject);
                }
            }
        };
        func();
    }, [placeIdByCoordinates]);

    const getMarkerLocation = async (coordinates) => {
        const geocodeObj = await geocodeByLatLng(coordinates);
        if (geocodeObj && geocodeObj.length > 0) {
            setPlaceIdByCoordinates(geocodeObj[0].place_id);
        }
    };

    return (
        <>
            <Col sm={6} md={4} xl={3} className="mt-4">
                <Modal size="xl" isOpen center toggle={toggle} className="modal-dialog modal-dialog-centered">
                    <ModalHeader>Enter Location</ModalHeader>
                    <ModalBody>
                        <div>
                            <GooglePlacesAutocomplete
                                minLengthAutocomplete={5}
                                autocompletionRequest={initialAutoCompletionRequestArgs}
                                selectProps={{
                                    // inputValue: inputAddress,
                                    isClearable: true,
                                    value: addressSearch,
                                    onInputChange: (val) => {
                                        if (val) {
                                            setInputAddress(val);
                                        }
                                    },
                                    onChange: (val) => {
                                        if (val) {
                                            setAddressSearch(val);
                                            setAddressObj();
                                        }
                                    },
                                }}
                                id={fieldId}
                            />
                            {/* <pre style={{ textAlign: 'left', background: '#f0f0f0', padding: 20 }}>
                                {JSON.stringify(addressObj, 0, 2)}
                            </pre> */}
                        </div>

                        <br />
                        {addressObj && addressObj.coordinates && (
                            <div style={{ height: '70vh', width: '100%' }}>
                                {/* <GoogleMapReact
                                        bootstrapURLKeys={{ key: 'AIzaSyBxr0J6fFAla-aWT_qKdbAQqu0pwV0zHmc' }}
                                        defaultCenter={addressObj ? addressObj.coordinates : { lat: 59.95, lng: 30.33 }}
                                        defaultZoom={18}
                                        // onChildMouseDown={onCircleInteraction}
                                        onChildMouseUp={onCircleInteraction}
                                        // onChildMouseMove={onCircleInteraction}
                                        onChildClick={() => console.log('child click')}
                                        onClick={() => console.log('mapClick')}
                                    >
                                        {addressObj.coordinates.lat && (
                                            <Marker
                                                lat={addressObj.coordinates.lat}
                                                lng={addressObj.coordinates.lng}
                                                text={addressObj.address || {}}
                                                draggable
                                            />
                                        )}
                                    </GoogleMapReact> */}
                                {addressObj && addressObj.coordinates && (
                                    <Map
                                        isMarkerShown
                                        defaultCenter={addressObj ? addressObj.coordinates : { lat: 59.95, lng: 30.33 }}
                                        getMarkerLocation={getMarkerLocation}
                                    />
                                )}
                            </div>
                        )}

                        <br />
                        {addressObj && addressObj.coordinates && (
                            <Row
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {/* <>
                                        {addressObj.coordinates.lat}
                                        <br />
                                        {addressObj.coordinates.lng}
                                    </> */}
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius  m-2 mb-3 pr-5 pl-5 shadow-lg nw-md"
                                    onClick={() => toggle()}
                                >
                                    <span>Ok</span>
                                </motion.div>
                            </Row>
                        )}
                    </ModalBody>
                </Modal>
            </Col>
        </>
    );
}

PlacesAutocompleteModal.propTypes = {
    toggle: PropTypes.func,
    returnLocationData: PropTypes.func,
    id: PropTypes.string,
    idMeta: PropTypes.string,
    addressData: PropTypes.any,
    fieldId: PropTypes.string,
};

export default PlacesAutocompleteModal;
