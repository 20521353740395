import { capitalizeFirstLetter } from 'utils/helpers';

/**
 * Create recipient column data by combining recipient name and delivery address
 * Capitalizes the first letter of recipientName
 * @param  docs - Deliveries
 * @returns updated deliveries
 */
export const mapDocs = (docs) => {
    let allDocs = [];
    allDocs = docs.map((delivery) => ({
        ...delivery,
        recipientNameFormatted: `${capitalizeFirstLetter(delivery.recipientName) || 'No Recipient Given'}, ${
            delivery.deliveryAddress || ''
        }`,
        recipientName: capitalizeFirstLetter(delivery.recipientName),
    }));
    return allDocs;
};
