/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { connect, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';

// #region components | assets
import { ClientSelectorModal } from 'components';
import LoadingLottie from 'assets/animations/8707-loading.json';

// #region imports
import { getUser, getVisitingObject } from 'utils/checkAuth';
import { SOCKET_EVENTS } from 'constants/socket';
import { inputSocketRiders } from 'store/reducers/riderManagement/actions';
// #region services
import { getAllRidersCoordinatesByClient } from 'services/rider.service';

// #endregion imports
import { lottieOptionsLoading } from './constants';
import MapView from './components/map';
import FilterView from './components/filters';

/**
 * Advanced Rider Tracking Page
 * @param {*} props
 * @returns
 */
function AdvancedRiderTrackingPage(props) {
    const { onInputSocketRiders } = props;
    const { visitingClient } = getVisitingObject();

    const _isMounted = useRef(true);

    const reduxState = useSelector((state) => state);

    const [socketClient, setSocketClient] = useState(null);
    // loading states
    const [initialLoading, setInitialLoading] = useState(0);

    const [allRidersByClientFixed, setAllRidersByClientFixed] = useState([]);

    const [allRidersByClient, setAllRidersByClient] = useState([]);
    const [allFilteredRidersToDisplay, setAllFilteredRidersToDisplay] = useState(null);

    const [selectedRider, setSelectedRider] = useState({
        selectedType: 'filter',
        rider: null,
    });

    useEffect(() => {
        loadAllRidersByClient();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (reduxState?.Global) {
            const { socket } = reduxState.Global;
            if (socket && _isMounted.current) {
                setSocketClient(socket);
            }
        }
    }, [reduxState.Global]);

    useEffect(() => {
        if (socketClient && _isMounted.current) {
            socketClient.on(SOCKET_EVENTS.SERVER_CLIENT_RIDER_LOCATION_MAP, (res) => {
                if (_isMounted.current) {
                    const { doc } = res;
                    console.log(doc);
                    if (doc && Array.isArray(doc)) {
                        let parsedDocs = doc.map((val) => {
                            return JSON.parse(val);
                        });
                        parsedDocs = parsedDocs.sort((x) => x.riderId);
                        appendRiderMap(parsedDocs);
                    }
                }
            });
        }
    }, [socketClient, _isMounted.current]);

    useEffect(() => {
        const interval = setInterval(() => {
            let payload = {};
            try {
                const user = getUser();
                if (user) {
                    const { username, _id } = user;
                    payload = { username, userId: _id };
                }
            } catch (e) {
                console.error(e);
            }
            if (socketClient && _isMounted.current) {
                socketClient.emit(SOCKET_EVENTS.CLIENT_SERVER_LISTEN_RIDERS_LOCATION, { ...payload });
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [socketClient, _isMounted.current]);

    const appendRiderMap = (allRidersMeta) => {
        try {
            console.log('visitingClient._id', visitingClient._id);
            const filterRidersByClient = allRidersMeta.filter((x) => x.clientId === visitingClient._id);
            const allExistingRiderObjects = allRidersByClient || [];

            filterRidersByClient.map((val) => {
                const isRiderExist = allExistingRiderObjects.find((x) => x.riderId === val.riderId);
                if (isRiderExist) {
                    const objIndex = allExistingRiderObjects.findIndex((obj) => obj.riderId === `${val.riderId}`);
                    allExistingRiderObjects[objIndex].coordinates = val.coordinates;
                } else {
                    allExistingRiderObjects.push(val);
                }
                return val;
            });

            console.log('filterRidersByClient', filterRidersByClient);
            console.log('allExistingRiderObjects', allExistingRiderObjects);

            if (allExistingRiderObjects.length > 0) {
                setAllRidersByClient(allRidersMeta);
                onInputSocketRiders(allRidersMeta);
            } else {
                setAllRidersByClient(allRidersMeta);
                onInputSocketRiders(allRidersMeta);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadAllRidersByClient = async () => {
        if (_isMounted.current) {
            setInitialLoading((prevState) => prevState + 1);
            if (visitingClient) {
                try {
                    const { data } = await getAllRidersCoordinatesByClient(visitingClient._id);
                    if (data && data.docs && _isMounted.current) {
                        setAllRidersByClientFixed(data.docs);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            _isMounted.current && setInitialLoading((prevState) => prevState - 1);
        }
    };

    if (initialLoading > 0) {
        return (
            <div className="page-content page-center">
                <Lottie
                    className="page-center-lottie"
                    options={lottieOptionsLoading(LoadingLottie)}
                    height={230}
                    width={230}
                    speed={0.5}
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h3 style={{ color: 'grey' }}>Loading</h3>
                    <div className="dot-elastic ml-4 mt-3"></div>
                    {/* <h3 style={{ color: 'grey' }} className=" ml-5 ">
                        Please Wait
                    </h3> */}
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="page-content">
                <Row>
                    <Col lg={5}>
                        <FilterView
                            allRidersByClient={allRidersByClient}
                            setSelectedRider={setSelectedRider}
                            selectedRider={selectedRider}
                            allFilteredRidersToDisplay={allFilteredRidersToDisplay}
                            setAllFilteredRidersToDisplay={setAllFilteredRidersToDisplay}
                        />
                    </Col>
                    <Col lg={7}>
                        <Row className="justify-content-center row-container">
                            <MapView
                                socket={socketClient}
                                allRidersByClient={allRidersByClient.filter((x) => x.coordinates !== null)}
                                setSelectedRider={setSelectedRider}
                                selectedRider={selectedRider}
                                allRidersByClientFixed={allRidersByClientFixed}
                            />
                        </Row>
                    </Col>
                </Row>
            </div>

            {!visitingClient && <ClientSelectorModal />}
        </>
    );
}

AdvancedRiderTrackingPage.propTypes = {
    onInputSocketRiders: PropTypes.func,
};

const HotAdvancedRiderTrackingPage = hot(AdvancedRiderTrackingPage);

const mapStateToProps = (state) => ({
    riderManagement: state.RiderManagement,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onInputSocketRiders: (data) => {
            dispatch(inputSocketRiders(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(HotAdvancedRiderTrackingPage));
