import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

function TableStickyView(props) {
    const { setEnableStickyView, enableStickyView } = props;
    return (
        <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="top-row btn"
            onClick={() => {
                setEnableStickyView(!enableStickyView);
            }}
            style={{ cursor: 'pointer', marginRight: -5 }}
        >
            {enableStickyView && <i className="fas fa-border-all fa-2x" />}
            {!enableStickyView && <i className="fas fa-table fa-2x" />}
        </motion.div>
    );
}

TableStickyView.propTypes = {
    enableStickyView: PropTypes.bool,
    setEnableStickyView: PropTypes.func,
};

export default TableStickyView;
