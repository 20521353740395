import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';

function Search(props) {
    const { onFilter, isManualSearch, setSearch, search, searchPlaceholder, setIsUp } = props;
    return (
        <div>
            <Form className="table__custom__search-default d-lg-block">
                <div className="position-relative">
                    <DebounceInput
                        type="text"
                        className="form-control"
                        placeholder={searchPlaceholder}
                        onChange={(e) => {
                            setIsUp({});
                            if (onFilter) {
                                onFilter(search.searchBy, e.target.value);
                            }
                            if (!isManualSearch) {
                                setSearch({
                                    ...search,
                                    searchText: e.target.value,
                                });
                            }
                        }}
                        debounceTimeout={500}
                        value={search.searchText ? search.searchText.replaceAll('$2', ' ') : ''}
                    />
                    <span className="ri-search-line search-icon"></span>
                </div>
            </Form>
        </div>
    );
}

Search.propTypes = {
    isManualSearch: PropTypes.bool,
    setSearch: PropTypes.func,
    search: PropTypes.object,
    onFilter: PropTypes.func,
    searchPlaceholder: PropTypes.string,
    setIsUp: PropTypes.func,
};

export default Search;
