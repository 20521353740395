import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import { Container, Row, Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';

// #region components
import { BackdropLoader } from 'components';
import DeliveryProgressBar from 'components/DeliveryProgressbar';
import ShippingLabelViewCard from 'components/Cards/Labels/label-view-card';

// #region imports
import PATHS from 'routes/paths';
import { centerElementContainerStyles, centerElementInnerStyles } from 'styles';

// #region services
import { getAllShippingLabelByDeliveryIdService } from 'services/shipping-label.service';

// #endregion services
import { createShippingLabelService, deleteShippingLabelService } from '../services';

function ShippingLabelViewPage(props) {
    const history = useHistory();

    const [deliveryData, setDeliveryData] = useState({});

    const [packages, setPackages] = useState([]);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [noOfPackages, setNoOfPackages] = useState(null);

    useEffect(() => {
        if (!props.location.state) {
            history.push(PATHS.DELIVERY.DEFAULT);
        }
        const { deliveryParentData } = props.location.state;
        if (deliveryParentData) {
            setDeliveryData(deliveryParentData);
            loadAllShippingLabelsByDeliveryId(deliveryParentData._id);
        } else {
            history.push(PATHS.DELIVERY.DEFAULT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location]);

    useEffect(() => {}, [noOfPackages]);

    const loadAllShippingLabelsByDeliveryId = async (id) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getAllShippingLabelByDeliveryIdService(id);
            if (data) {
                setPackages(data.docs.map((x) => ({ ...x, deliveryData: x.deliveryId, deliveryId: x.deliveryId._id })));
                setNoOfPackages(data.length);
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleAddShippingLabel = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await createShippingLabelService({ ...deliveryData, packageNumber: noOfPackages + 1 });
        } catch (e) {
            console.log(e);
        }
        try {
            const { data } = await getAllShippingLabelByDeliveryIdService(deliveryData._id);
            if (data && data.docs) {
                setPackages(data.docs.map((x) => ({ ...x, deliveryData: x.deliveryId, deliveryId: x.deliveryId._id })));
                setNoOfPackages(data.length);
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleRemoveShippingLabel = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteShippingLabelService(packages[packages.length - 1]._id);
        } catch (e) {
            console.log(e);
        }
        try {
            const { data } = await getAllShippingLabelByDeliveryIdService(deliveryData._id);
            if (data && data.docs) {
                setPackages(data.docs.map((x) => ({ ...x, deliveryData: x.deliveryId, deliveryId: x.deliveryId._id })));
                setNoOfPackages(data.length);
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    {/* <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} /> */}
                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <DeliveryProgressBar options={deliveryData} />
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                onClick={() =>
                                    history.push({
                                        pathname: `${PATHS.DELIVERY.FORM}`,
                                        search: `?type=edit&id=${deliveryData._id}`,
                                        state: { deliveryParentData: deliveryData },
                                    })
                                }
                            >
                                <i className="fas fa-chevron-left"></i> <span className="h6">Back to Delivery</span>
                            </motion.div>
                        </div>
                    </Row>
                    <br />
                    <Card className="shadow-lg default-card">
                        <CardBody>
                            <div className="mb-3">
                                <span className="ml-3">No of packages</span>
                                <div
                                    style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        margin: 10,
                                    }}
                                >
                                    {!deliveryData.isCompleted && (
                                        <motion.div
                                            style={{ width: 35, height: 35 }}
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-btn-circle-icon"
                                            onClick={() => {
                                                handleRemoveShippingLabel();
                                            }}
                                        >
                                            <i className="fas fa-minus fa-lg" />
                                        </motion.div>
                                    )}
                                    <div
                                        style={{
                                            height: 30,
                                            width: 100,
                                            ...centerElementContainerStyles,
                                            backgroundColor: '#F1F1F1',
                                            borderRadius: 20,
                                            marginRight: 5,
                                            marginLeft: 5,
                                        }}
                                    >
                                        {noOfPackages}
                                    </div>
                                    {!deliveryData.isCompleted && (
                                        <motion.div
                                            className="scootix-btn-circle-icon"
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            onClick={() => {
                                                handleAddShippingLabel();
                                            }}
                                        >
                                            <i className="fas fa-plus fa-lg" />
                                        </motion.div>
                                    )}
                                </div>
                            </div>
                            <Row className="m-3 ">
                                <h6>Print the shipping label here</h6>
                            </Row>
                            <Row
                                className="m-3"
                                style={{ flexDirection: 'row', display: 'flex', justifyContent: 'left' }}
                            >
                                {packages.map((x, i) => (
                                    <>
                                        <div style={{ ...centerElementInnerStyles }} className="ml-4">
                                            <ShippingLabelViewCard
                                                deliveryData={deliveryData}
                                                packageData={x}
                                                index={i}
                                                packages={packages}
                                            />
                                        </div>
                                    </>
                                ))}
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

ShippingLabelViewPage.propTypes = {
    location: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotShippingLabelViewPage = hot(ShippingLabelViewPage);

export default connect(mapStateToProps, {})(HotShippingLabelViewPage);
