import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

export default function RoutePlanModalTable(props) {
    const { tableData, status = '' } = props;

    return (
        <>
            <table
                ClassName="modal_table"
                style={{
                    borderCollapse: 'collapse',
                    fontFamily: 'Inter',
                    margin: '25px 0',
                    minWidth: '400px',
                    border: '1px solid #E5E5E5',
                }}
            >
                <div className="modal_table-scrollkit">
                    <thead className="modal_table-thead" style={{ background: '#ECEAEA' }}>
                        <tr style={{ textAlign: 'left', fontSize: '14px' }}>
                            <th>DELIVERY ORDER NUMBER</th>
                            <th>MERCHANT ORDER NO.</th>
                            <th>{status === 'fail' ? 'REASON' : '     '}</th>
                        </tr>
                    </thead>
                    <tbody className="modal_table-tbody">
                        {tableData?.map((rowData) => (
                            <tr
                                style={{
                                    textAlign: 'left',
                                    fontSize: '14px',
                                }}
                            >
                                <td>{rowData?.record?.referenceNumber}</td>
                                <td>{rowData?.record?.merchantOrderNo}</td>
                                <td>{rowData?.reason}</td>
                            </tr>
                        ))}
                    </tbody>
                </div>
            </table>
        </>
    );
}

RoutePlanModalTable.propTypes = {
    tableData: PropTypes.func,
    status: PropTypes.string,
};
