/* eslint-disable indent */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { motion } from 'framer-motion';

// #region components | assets
import { DeliveryTransactionModal } from 'components';
import { AddDeliveryAttempt, CancelDelivery, MoreOption, UnassignDelivery } from 'components/Icons';
import CheckCircleIcon from 'components/Icons/Check-Circle';

// #region utils
import { PERMISSIONS, PERMISSION_TYPES } from 'utils/constants';
import { hasAccess, hasRole } from 'utils/checkAuth';

// #endregion imports
import TransactionTable from '../../components/TransactionTable';

function DeliveryTransactions(props) {
    const {
        data,
        merchantOrder,
        deliveryId,
        riderId,
        createBillingRecord,
        deliveryTransactions,
        loadTransactions,
        setDeliveryFormData,
        setIsViewMode,
        updateRiderData,
        updateHistoryWithData,
    } = props;

    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState('');

    const { isCompleted, isDelivered } = data;

    const toggleModal = (id) => {
        setShowModal(!showModal);
        setType(id);
    };

    const showCompleted = () => !data.isCompleted && !data.isCancelled;

    const showUnassign = () => !data.isCompleted && !data.isCancelled;

    return (
        <div className="mt-2">
            <Row>
                {hasRole(['clientAdmin', 'superAdmin', 'clientUser']) && (
                    <div style={{ display: 'flex', margin: '5px 12px 5px auto' }}>
                        {hasAccess(PERMISSIONS.TRANSACTION_COMPLETE_DELIVERY, [PERMISSION_TYPES.complete]) &&
                            showCompleted() && (
                                <div>
                                    {data.riderId && (
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-2 pl-2 pt-2 pb-2"
                                            style={{ borderRadius: 10 }}
                                            onClick={() => {
                                                toggleModal('completeDelivery');
                                            }}
                                        >
                                            <CheckCircleIcon color="#FFFFFF" />{' '}
                                            <span style={{ fontSize: '12px', fontWeight: '700' }}>
                                                Complete Delivery
                                            </span>
                                        </motion.button>
                                    )}
                                </div>
                            )}
                        {hasAccess(PERMISSIONS.TRANSACTION_CANCEL_DELIVERY, [PERMISSION_TYPES.add]) &&
                            !data.isCancelled && (
                                <div>
                                    {!isCompleted && !isDelivered && (
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-2 pl-2 pt-2 pb-2"
                                            style={{ borderRadius: 10 }}
                                            onClick={() => {
                                                toggleModal('cancelDelivery');
                                            }}
                                        >
                                            <CancelDelivery />{' '}
                                            <span style={{ fontSize: '12px', fontWeight: '700' }}>Cancel Delivery</span>
                                        </motion.button>
                                    )}
                                </div>
                            )}

                        {hasAccess(PERMISSIONS.TRANSACTION_UNASSIGN_DELIVERY, [PERMISSION_TYPES.add]) &&
                            showUnassign() && (
                                <div>
                                    {data.riderId !== null && (
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-2 pl-2 pt-2 pb-2"
                                            style={{ borderRadius: 10 }}
                                            onClick={() => {
                                                toggleModal('unassignedDelivery');
                                            }}
                                        >
                                            <UnassignDelivery />{' '}
                                            <span style={{ fontSize: '12px', fontWeight: '700' }}>
                                                Unassign Delivery
                                            </span>
                                        </motion.button>
                                    )}
                                </div>
                            )}

                        {hasAccess(PERMISSIONS.TRANSACTION_ADD_DELIVERY_ATTEMPT, [PERMISSION_TYPES.add]) &&
                            !data.isCancelled && (
                                <div>
                                    {isCompleted === true && (
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-2 pl-2 pt-2 pb-2"
                                            style={{ borderRadius: 10 }}
                                            onClick={() => {
                                                toggleModal('addDeliveryAttempt');
                                            }}
                                        >
                                            <AddDeliveryAttempt />{' '}
                                            <span style={{ fontSize: '12px', fontWeight: '700' }}>
                                                Add Delivery Attempt
                                            </span>
                                        </motion.button>
                                    )}
                                </div>
                            )}

                        {hasAccess(PERMISSIONS.TRANSACTION_DELIVERY_UPDATE_VEHICLE_TYPE, ['OnGoing', 'Completed']) && (
                            <div>
                                {isCompleted === false && (
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-2 pl-2 pt-2 pb-2"
                                        style={{ borderRadius: 10 }}
                                        onClick={() => {
                                            toggleModal('updateDeliveryType');
                                        }}
                                    >
                                        <AddDeliveryAttempt />{' '}
                                        <span style={{ fontSize: '12px', fontWeight: '700' }}>
                                            Update Delivery Type
                                        </span>
                                    </motion.button>
                                )}
                            </div>
                        )}

                        {(hasAccess(PERMISSIONS.TRANSACTION_DELIVERY_UPDATE_PICKUP_LOCATION, [
                            'OnGoing',
                            'Completed',
                        ]) ||
                            hasAccess(PERMISSIONS.TRANSACTION_DELIVERY_UPDATE_DELIVERY_LOCATION, [
                                'OnGoing',
                                'Completed',
                            ]) ||
                            hasAccess(PERMISSIONS.TRANSACTION_DELIVERY_UPDATE_VEHICLE_TYPE, [
                                'OnGoing',
                                'Completed',
                            ])) && (
                            <div>
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-2 pl-2 pt-2 pb-2"
                                    style={{ borderRadius: 10 }}
                                    onClick={() => {
                                        toggleModal('moreOptions');
                                    }}
                                >
                                    <MoreOption />{' '}
                                    <span style={{ fontSize: '12px', fontWeight: '700' }}>More Options</span>
                                </motion.button>
                            </div>
                        )}
                    </div>
                )}
            </Row>
            <Col>
                <TransactionTable deliveryTransactions={deliveryTransactions} data={data} />
            </Col>

            {/* Transaction modal form */}
            {showModal && (
                <DeliveryTransactionModal
                    data={data}
                    toggleModal={toggleModal}
                    setShowModal={setShowModal}
                    type={type}
                    show={showModal}
                    merchantOrder={merchantOrder}
                    deliveryId={deliveryId}
                    riderId={riderId}
                    createBillingRecord={createBillingRecord}
                    loadTransactions={loadTransactions}
                    setDeliveryFormData={setDeliveryFormData}
                    setIsViewMode={setIsViewMode}
                    updateRiderData={updateRiderData}
                    updateHistoryWithData={updateHistoryWithData}
                />
            )}
        </div>
    );
}

DeliveryTransactions.propTypes = {
    data: PropTypes.any,
    merchantOrder: PropTypes.any,
    deliveryId: PropTypes.any,
    riderId: PropTypes.any,
    createBillingRecord: PropTypes.func,
    loadTransactions: PropTypes.func,
    deliveryTransactions: PropTypes.any,
    setDeliveryFormData: PropTypes.func,
    setIsViewMode: PropTypes.func,
    updateRiderData: PropTypes.func,
    updateHistoryWithData: PropTypes.func,
};

export default DeliveryTransactions;
