import { CREATE_SHIPPING_LABEL, DELETE_SHIPPING_LABEL, UPDATE_SHIPPING_LABEL } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const createShippingLabelService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_SHIPPING_LABEL).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const updateShippingLabelService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_SHIPPING_LABEL).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const deleteShippingLabelService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_SHIPPING_LABEL).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
