/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Import Logo
import bg from 'assets/images/access-denied.png';

function AccessDenied(props) {
    const { pageName = 'Page', message } = props;

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="mdi mdi-home-variant h2"></i>
                </Link>
            </div>

            <div className="my-5 pt-sm-5">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="text-center">
                                <Row className="justify-content-center">
                                    <Col sm={4}>
                                        <div className="maintenance-img">
                                            <img src={bg} alt="access-denied" className="img-fluid mx-auto d-block" />
                                        </div>
                                    </Col>
                                </Row>
                                <h2 className="mt-5">{pageName || 'Page'} is Not Accessible!</h2>

                                {message && <h4>{message}</h4>}
                                <p>Sorry - you don't seem to have access to this page.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <br />
            </div>
        </React.Fragment>
    );
}

AccessDenied.propTypes = {
    pageName: PropTypes.string,
    message: PropTypes.string,
};

export default AccessDenied;
