/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */

import { motion } from 'framer-motion';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { useEffect, useState } from 'react';
import {
    DataSheetGrid,
    checkboxColumn,
    keyColumn,
    textColumn,
    intColumn,
    floatColumn,
    dateColumn,
    percentColumn,
} from 'react-datasheet-grid';
import { v4 as uuid } from 'uuid';

import { SelectComponent } from './TableReactSelect';

const selectColumn = (options = {}) => ({
    component: SelectComponent,
    columnData: options,
    disableKeys: true,
    keepFocus: true,
    disabled: options.disabled,
    deleteValue: () => null,
    copyValue: ({ rowData }) => options.choices.find((choice) => choice.value === rowData)?.label,
    pasteValue: ({ value }) => options.choices.find((choice) => choice.label === value)?.value ?? null,
});

function CustomGridTable(props) {
    const { columns = [], data = [], setData, removeActions = false, handleActiveCellChange } = props;
    const [modifiedCols, setModifiedCols] = useState([]);

    const [gridKey, setGridKey] = useState(uuid());

    const { width } = useWindowDimensions();

    const gridRowSelector = document.getElementsByClassName('dsg-row');
    const gridContainerSelector = document.getElementsByClassName('dsg-container');

    if (gridRowSelector[0] && width) {
        for (let i = 0; i < gridRowSelector.length; i++) {
            gridRowSelector[i].style.width = `${width - 350}px`;
            gridRowSelector[i].style.maxWidth = `${width - 350}px`;
            gridRowSelector[i].style.minWidth = `${width - 350}px`;
        }
    }

    if (gridContainerSelector[0] && width) {
        for (let i = 0; i < gridContainerSelector.length; i++) {
            gridContainerSelector[i].style.width = `${width - 350}px`;
            gridContainerSelector[i].style.maxWidth = `${width - 350}px`;
            gridContainerSelector[i].style.minWidth = `${width - 350}px`;
        }
    }

    useEffect(() => {
        const someCols = columns.map((col) => {
            if (col.type === 'checkbox') {
                return {
                    ...keyColumn(col.key, checkboxColumn),
                    title: col.title,
                    disabled: col.disabled,
                };
            }
            if (col.type === 'text') {
                return {
                    ...keyColumn(col.key, textColumn),
                    title: col.title,
                    disabled: col.disabled,
                    minWidth: 150,
                };
            }
            if (col.type === 'number') {
                return {
                    ...keyColumn(col.key, intColumn),
                    title: col.title,
                    disabled: col.disabled,
                };
            }
            if (col.type === 'float') {
                return {
                    ...keyColumn(col.key, floatColumn),
                    title: col.title,
                    disabled: col.disabled,
                };
            }
            if (col.type === 'date') {
                return {
                    ...keyColumn(col.key, dateColumn),
                    title: col.title,
                    disabled: col.disabled,
                };
            }
            if (col.type === 'percentage') {
                return {
                    ...keyColumn(col.key, percentColumn),
                    title: col.title,
                };
            }
            if (col.type === 'select') {
                return {
                    ...keyColumn(
                        col.key,
                        selectColumn({
                            choices: col.choices,
                        })
                    ),
                    title: col.title,
                    disabled: col.disabled,
                    minWidth: col.minWidth || 150,
                };
            }
            return {
                ...keyColumn(col.key, textColumn),
                title: col.title,
                disabled: col.disabled,
            };
        });

        if (!removeActions) {
            someCols.push({
                title: 'Actions',
                component: ActionButtons,
            });
        }

        setGridKey(uuid());
        setModifiedCols(someCols);
    }, [columns, removeActions]);

    const ActionButtons = ({ insertRowBelow, deleteRow }) => (
        <>
            <motion.div
                whileHover={{ scale: 1.25 }}
                whileTap={{ scale: 0.9 }}
                className="w3-animate-opacity ml-3"
                onClick={() => insertRowBelow()}
            >
                <i className="fa fa-plus fa-lg" style={{ color: '#5d5d5a' }} />
            </motion.div>
            <motion.div
                whileHover={{ scale: 1.25 }}
                whileTap={{ scale: 0.9 }}
                className="w3-animate-opacity ml-3"
                onClick={() => deleteRow()}
            >
                <i className="fas fa-trash fa-lg" style={{ color: '#ff304f' }} />
            </motion.div>
        </>
    );

    return (
        <div style={{ marginBottom: 20 }} className="custom-grid-container">
            {modifiedCols.length > 0 && (
                <DataSheetGrid
                    value={data}
                    onChange={setData}
                    columns={modifiedCols}
                    key={gridKey}
                    onActiveCellChange={(val) => handleActiveCellChange(val)}
                    rowHeight={70}
                    className="custom-grid"
                />
            )}
        </div>
    );
}

export default CustomGridTable;
