import { TASA_HEAD_OFFICE_COORDINATE } from 'constants/constants';
import moment from 'moment';
import { RIDER_LOCATION } from '../modules/RoutePlanningEntry-View/constants';

/** * Map array into Arrays of arrays
 * * @author Champika
 * * @param {Array} orders
 * * @return {Object} with "pickupMappedObjectArray, deliveryMappedObjectArray" properties
 * * And those "pickupMappedObjectArray, deliveryMappedObjectArray" are Arrays of Arrays
 * * Let's assume multiple pickups for one order, it will return pickupArray like => {pickupMappedObjectArray: {[order1, order2],[order3],[order4]}} */
export const mapOrders = (objectList) => {
    const pickupMappedObjectArray = Object.values(
        objectList.reduce((acc, order) => {
            const key = `${order.pickupLocationMeta.coordinates.lat},${order.pickupLocationMeta.coordinates.lng}`;
            if (!acc[key]) {
                acc[key] = [order];
            } else {
                acc[key].push(order);
            }
            return acc;
        }, {})
    );

    const deliveryMappedObjectArray = Object.values(
        objectList.reduce((acc, order) => {
            const key = `${order.deliveryAddressMeta.coordinates.lat},${order.deliveryAddressMeta.coordinates.lng}`;
            if (!acc[key]) {
                acc[key] = [order];
            } else {
                acc[key].push(order);
            }
            return acc;
        }, {})
    );

    return { pickupMappedObjectArray, deliveryMappedObjectArray };
};

/**  This function suppose to return rider coordinates based on the business logic - this current coordinate will showing on the map
 * * Use In - Delivery Route Planning - When showing Rider Locations
 * * @param {Array} rider plannedDeliveryDate
 * * @return {Array} riderCurrentCoordinate */

/** When there are no deliveries assigned for a rider on a given day, display the rider’s location as Rider’s home location
 * When no home address exists with the rider profile, display the TASA head office address as the rider’s location.  */
export const buildRiderCoordinatesBasedOnDate = (rider, plannedDeliveryDate) => {
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const filteredDate = moment(plannedDeliveryDate).format('YYYY-MM-DD');

    let riderCurrentCoordinate = TASA_HEAD_OFFICE_COORDINATE; // TASA Head Office Coordinates
    let riderLocation = RIDER_LOCATION.TASA; // name of rider current location

    if (filteredDate === todayDate) {
        if (rider.status === 'active') {
            if (rider?.currentCoordinates) {
                riderCurrentCoordinate = [rider?.currentCoordinates?.latitude, rider?.currentCoordinates?.longitude];
                riderLocation = RIDER_LOCATION.CURRENT_LOCATION;
            }
            // if user not allowing his location on the mobile, there can be scenarios status is 'active' and riderCoordinate is null
            if (!rider?.currentCoordinates && rider?.addressCoordinate) {
                riderCurrentCoordinate = [rider?.addressCoordinate?.lat, rider?.addressCoordinate?.lng];
                riderLocation = RIDER_LOCATION.HOME;
            }
        } else if (rider.status === 'inactive') {
            if (rider?.addressCoordinate) {
                riderCurrentCoordinate = [rider?.addressCoordinate?.lat, rider?.addressCoordinate?.lng];
                riderLocation = RIDER_LOCATION.HOME;
            }
        }
    } else if (filteredDate !== todayDate) {
        if (rider?.addressCoordinate) {
            riderCurrentCoordinate = [rider?.addressCoordinate?.lat, rider?.addressCoordinate?.lng];
            riderLocation = RIDER_LOCATION.HOME;
        }
    }

    return { riderCurrentCoordinate, riderLocation };
};

/**  This function suppose to return rider coordinates based on the online status of the rider
 * * Use In - Delivery Route Planning - When showing Rider Locations
 * * @param {Array} rider
 * * @return {Array} riderCurrentCoordinate */
export const buildRiderCoordinates = (rider, isRoutePlanStarted) => {
    let riderCurrentCoordinate = TASA_HEAD_OFFICE_COORDINATE; // TASA Head Office Coordinates
    let riderLocation = RIDER_LOCATION.TASA; // name of rider current location

    if (isRoutePlanStarted && rider.status === 'active') {
        if (rider?.currentCoordinates) {
            riderCurrentCoordinate = [rider?.currentCoordinates?.latitude, rider?.currentCoordinates?.longitude];
            riderLocation = RIDER_LOCATION.CURRENT_LOCATION;
        }
        // if user not allowing his location on the mobile, there can be scenarios status is 'active' and riderCoordinate is null
        if (!rider?.currentCoordinates && rider?.addressMeta?.coordinates) {
            riderCurrentCoordinate = [rider?.addressMeta?.coordinates?.lat, rider?.addressMeta?.coordinates?.lng];
            riderLocation = RIDER_LOCATION.HOME;
        }
    } else if (rider.status === 'inactive' || !isRoutePlanStarted) {
        if (rider?.addressMeta?.coordinates) {
            riderCurrentCoordinate = [rider?.addressMeta?.coordinates?.lat, rider?.addressMeta?.coordinates?.lng];
            riderLocation = RIDER_LOCATION.HOME;
        }
    }

    return { riderCurrentCoordinate, riderLocation };
};
