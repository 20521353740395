/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Col, Form, Input, Row } from 'reactstrap';
import '../styles/index.scss';

// #region assets | components
import CrossCircle from 'components/Icons/CrossCircle';
import CustomToast from 'components/Toast/Custom';
import { CheckCircle } from 'components/Icons';

// #region imports
import PATHS from 'routes/paths';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #endregion imports
import ListTable from './containers/ListTable';
import { deliveryListInitialValues } from './containers/ListTable/constants';

/**
 * Add unplanned deliveries modal for route planning
 *
 * @param {array} allUnPlannedDeliveryList - All unplanned delivery array
 * @param {func} setSelectedDeliveryTypes -  Set dropdown selected value arrays for deliveryTypes
 * @param {array} selectedUnPlannedDeliveryList - Selected values for selectedUnPlannedDeliveryList
 * @param {func} setSelectedUnPlannedDeliveryList - Set selected values for unPlannedDeliveryList
 * @param {func} toggleModal - Toggles the modal
 * @returns {JSX.Element} A React component that displays the add deliveries modal.
 */
function SelectDeliveriesModule(props) {
    const { allUnPlannedDeliveryList, selectedUnPlannedDeliveryList, setSelectedUnPlannedDeliveryList, toggleModal } =
        props;

    /** "selectedDeliveryData" stores checked records from the modal table */
    const [selectedDeliveryData, setSelectedDeliveryData] = useState(selectedUnPlannedDeliveryList);
    // array containing all the unplanned deliveries
    const [allDeliveryData] = useState(allUnPlannedDeliveryList);
    // formatted payload for ListTable module
    const [tableData, setTableData] = useState({
        ...deliveryListInitialValues,
        totalDocs: allUnPlannedDeliveryList.length,
        docs: allUnPlannedDeliveryList,
    });
    // search box text
    const [filterText, setFilterText] = useState('');

    // Used to store filtered records only such as searched records
    const [filteredRecords, setFilteredRecords] = useState(null);

    /**
     * Generate payload for ListTable module by marking the selected delivery objects
     * does the same if filteredRecords if available
     */
    useEffect(() => {
        try {
            if (Array.isArray(selectedDeliveryData)) {
                const updatedSelectedDocs = tableData.docs.map((val) => {
                    const isSelectedType = selectedDeliveryData.find((delivery) => delivery._id === val._id);
                    return {
                        ...val,
                        isSelected: !!isSelectedType,
                    };
                });
                setTableData({ ...tableData, docs: updatedSelectedDocs });
                // if filteredRecords available show them in table
                if (filteredRecords) {
                    const updatedFilteredDocs = filteredRecords.map((val) => {
                        const isSelectedType = selectedDeliveryData.find((delivery) => delivery._id === val._id);
                        return {
                            ...val,
                            isSelected: !!isSelectedType,
                        };
                    });
                    setFilteredRecords(updatedFilteredDocs);
                }
            }
        } catch {
            toast.error('Something went wrong');
        }
    }, [selectedDeliveryData]);

    /**
     * Handles the click event of the "Add Selected" button.
     * Adds the selected delivery types to the route planning form.
     * @returns {void}
     */
    const handleAddSelected = () => {
        setSelectedUnPlannedDeliveryList(selectedDeliveryData);
        toggleModal();
    };

    /**
     * Handles search functionality
     * @param {*} event - Text box change event
     */
    const handleSearch = (event) => {
        const text = event.target.value;

        try {
            setFilterText(text.toLowerCase());

            // filtered merchant data array of merchant objects
            const filteredDeliveryArray = allDeliveryData.filter(
                (item) =>
                    item.referenceNumber.toLowerCase().indexOf(text) !== -1 ||
                    item.merchantId.toLowerCase().indexOf(text) !== -1
            );
            const selectedDeliveriesData = selectedDeliveryData;

            // create final table data by adding filtered deliveries
            const filteredData = filteredDeliveryArray.map((delivery) => {
                const isMerchantSelected = selectedDeliveriesData.find(
                    (selectedDelivery) => selectedDelivery._id.toLowerCase() === delivery._id.toLowerCase()
                );
                return {
                    ...delivery,
                    isSelected: !!isMerchantSelected,
                };
            });
            // if no match found for filter text return all table data
            setFilteredRecords(filteredData.length === 0 ? null : filteredData);
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    /** Opens the delivery page in a new tab */
    const handleViewDelivery = (row) => {
        if (!row) return;
        window.open(`${PATHS.DELIVERY.FORM}?type=edit&id=${row._id}`, '_blank');
    };

    return (
        <>
            <div className="font-size-14 w-100 p-0">
                <div className="modal__form-table">
                    <Row>
                        <Col>
                            <p>The list displays deliveries that matches the selected search criteria</p>
                        </Col>
                    </Row>
                    <hr className="divider" />
                    <Row>
                        <Col md={6}>
                            <Form className="table__custom__search-right d-lg-block p-0">
                                <div className="position-relative">
                                    <Input
                                        type="text"
                                        value={filterText}
                                        className="form-control"
                                        placeholder="Enter the Delivery ID or Merchant ID to Search"
                                        onChange={handleSearch}
                                    />
                                    <span className="ri-search-line search-icon"></span>
                                </div>
                            </Form>
                        </Col>
                    </Row>

                    <ListTable
                        tableData={tableData}
                        isSearch={false}
                        selectedData={selectedDeliveryData}
                        setSelectedData={setSelectedDeliveryData}
                        allRecords={allUnPlannedDeliveryList || null}
                        filteredRecords={filteredRecords}
                        onViewDelivery={handleViewDelivery}
                    />

                    <Row>
                        <Col xs="auto">
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn shadow-lg "
                                style={{ borderRadius: 10 }}
                                onClick={handleAddSelected}
                            >
                                <CheckCircle color="#fff" />
                                &nbsp;Add Selected
                            </motion.div>
                        </Col>
                        <Col xs="auto">
                            <motion.div
                                className="remove__btn form-delete-btn shadow-lg"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                style={{ borderRadius: 10 }}
                                onClick={toggleModal}
                            >
                                <CrossCircle color="#FFF" />
                                &nbsp;Close
                            </motion.div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

SelectDeliveriesModule.propTypes = {
    toggleModal: PropTypes.func,
    allUnPlannedDeliveryList: PropTypes.any,
    selectedUnPlannedDeliveryList: PropTypes.any,
    setSelectedUnPlannedDeliveryList: PropTypes.func,
};

export default SelectDeliveriesModule;
