/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import { Col } from 'reactstrap';
import toast from 'react-hot-toast';
import Lottie from 'react-lottie';

// #region components | assets
import SuccessLottie from 'assets/animations/69380-success-check.json';
import ErrorLottie from 'assets/animations/34313-failure-error-icon.json';
import InfoLottie from 'assets/animations/23283-info.json';
import WarningLottie from 'assets/animations/15426-warning-animation.json';
import WaitLottie from 'assets/animations/waiting-circle.json';

// #region utils
import { getUser } from 'utils/checkAuth';

// #endregion imports
import {
    lottieOptionsError,
    lottieOptionsInfo,
    lottieOptionsSuccess,
    lottieOptionsWait,
    lottieOptionsWarning,
} from './constants';

function CustomToast(props) {
    const { t, text = '', textHeader, type } = props;

    const userData = getUser();

    return (
        <div
            style={{
                width: 430,
                display: 'flex',
                flexDirection: 'row',
                borderRadius: 15,
                alignItems: 'center',
                alignContent: 'center',
                backgroundColor: 'red',
            }}
            className={`${
                t.visible ? 'w3-animate-right' : 'animate-out-right-toast'
            } shadow-lg bg-white pl-4 pt-4 pb-4`}
        >
            <Col lg={2}>
                {type && (
                    <>
                        {type === 'wait' && (
                            <Lottie
                                className="page-center-lottie"
                                options={lottieOptionsWait(WaitLottie)}
                                height={90}
                                width={90}
                                speed={1}
                                style={{ marginLeft: -12 }}
                            />
                        )}
                        {type === 'success' && (
                            <Lottie
                                className="page-center-lottie"
                                options={lottieOptionsSuccess(SuccessLottie)}
                                height={50}
                                width={50}
                                speed={1.5}
                                style={{ marginLeft: -12 }}
                            />
                        )}
                        {type === 'error' && (
                            <Lottie
                                className="page-center-lottie"
                                options={lottieOptionsError(ErrorLottie)}
                                height={50}
                                width={50}
                                speed={0.5}
                                style={{ marginLeft: -12 }}
                            />
                        )}

                        {type === 'info' && (
                            <Lottie
                                className="page-center-lottie"
                                options={lottieOptionsInfo(InfoLottie)}
                                height={50}
                                width={50}
                                speed={1}
                                style={{ marginLeft: -12 }}
                            />
                        )}
                        {type === 'warning' && (
                            <Lottie
                                className="page-center-lottie"
                                options={lottieOptionsWarning(WarningLottie)}
                                height={50}
                                width={50}
                                speed={0.7}
                                style={{ marginLeft: -12 }}
                            />
                        )}
                    </>
                )}
                {!type && (
                    <Avatar
                        className="image-upload-avatar"
                        name={textHeader || userData.fullName || ''}
                        size="40"
                        round="50px"
                    />
                )}
            </Col>
            <Col lg={8}>
                <div className="">
                    <div className="font-weight-bold" style={{ fontSize: 18 }}>
                        {userData ? userData.fullName : ''}
                    </div>
                    <div style={{ fontSize: 15 }}>{text || ''}</div>
                </div>
            </Col>
            <Col lg={2} style={{ borderLeft: '1px solid grey', height: '100%' }}>
                <div
                    className="mt-3"
                    onClick={() => {
                        if (t) {
                            toast.dismiss(t.id);
                        }
                    }}
                    style={{
                        alignSelf: 'flex-end',
                        color: '#972bd6',
                        fontSize: 17,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                    }}
                >
                    Close
                </div>
            </Col>
        </div>
    );
}

CustomToast.propTypes = {
    t: PropTypes.any,
    text: PropTypes.string,
    textHeader: PropTypes.string,
    type: PropTypes.oneOf(['success', 'error', 'info', 'warning', 'wait']),
};

export default CustomToast;
