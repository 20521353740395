import {
    UPDATE_API_RATE_LIMIT_BY_MERCHANT_ID,
    UPDATE_DEVELOPER_DATA_BY_MERCHANT_ID,
    UPDATE_REQUEST_SETTINGS_BY_MERCHANT_ID,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Update Developer contacts
 * @param {object} data
 * @returns {Promise}
 */
export const updateDeveloperContactsService = (data) => {
    try {
        const { merchantId } = data;
        const url = UrlBuilder().setUrl(UPDATE_DEVELOPER_DATA_BY_MERCHANT_ID).setPathParams({ merchantId }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update API Rate Limit
 * @param {object} data
 * @returns {Promise}
 */
export const updateApiRateLimitService = (data) => {
    try {
        const { merchantId } = data;
        const url = UrlBuilder().setUrl(UPDATE_API_RATE_LIMIT_BY_MERCHANT_ID).setPathParams({ merchantId }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Request Settings
 * @param {object} data
 * @returns {Promise}
 */
export const updateRequestSettingsService = (data) => {
    try {
        const { merchantId } = data;
        const url = UrlBuilder().setUrl(UPDATE_REQUEST_SETTINGS_BY_MERCHANT_ID).setPathParams({ merchantId }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
