/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Container, Alert, Row, Col, FormGroup, Button, Label, Spinner } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// #region components
import CrossCircleIcon from 'components/Icons/CrossCircle';
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';

/**
 * This modal prompts the user for the password before disabling the API service
 * @param {object} props
 */
function DisableApiServiceModal(props) {
    const { show, handleToggle, handleSubmit } = props;

    /** State for storing entered form data */
    const [formData, setFormData] = useState({ password: '' });
    /** State for disabling the button once the submit is successful */
    const [buttonDisabled, setButtonDisabled] = useState(false);
    /** State for showing whether the submit operation is in progress */
    const [loading, setLoading] = useState(false);

    const handleModalToggle = (row) => {
        resetForm();
        handleToggle(row);
    };

    const handleModalSubmit = async () => {
        setLoading(true);
        const response = await handleSubmit(formData);
        if ('error' in response) {
            setButtonDisabled(false);
        } else {
            handleModalToggle();
        }
        setLoading(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const resetForm = () => {
        setFormData({ password: '' });
        setButtonDisabled(false);
        setLoading(false);
    };

    return (
        <DeveloperModal header="Disable Webhook" show={show} handleToggle={handleModalToggle} mode="disable">
            <AvForm className="needs-validation default-form" noValidate onValidSubmit={handleModalSubmit}>
                <Container>
                    <Row>
                        <Col>
                            <Alert color="warning" style={{ borderRadius: 8 }}>
                                Please enter your password to disable the webhook.
                            </Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="password">Your Password</Label>
                                <AvField
                                    name="password"
                                    value={formData.password}
                                    type="password"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    errorMessage="Required"
                                    validate={{ required: { value: true } }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                disabled={buttonDisabled || loading}
                                style={{ backgroundColor: '#FD4A4A', borderRadius: 8 }}
                                className="form-delete-btn"
                            >
                                {loading ? (
                                    <Spinner size="sm" color="warning" className="spinner-white mx-1" />
                                ) : (
                                    <CrossCircleIcon />
                                )}
                                &nbsp;DISABLE
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </AvForm>
        </DeveloperModal>
    );
}

DisableApiServiceModal.propTypes = {
    show: propTypes.bool.isRequired,
    handleToggle: propTypes.func.isRequired,
    handleSubmit: propTypes.func.isRequired,
};

export default DisableApiServiceModal;
