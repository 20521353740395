/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import {
    Alert,
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Label,
    FormGroup,
    Button,
    Input,
    Spinner,
} from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';

// #region components
import { BackdropLoader, CustomToast, ScootixModal } from 'components';
import { WARNING_ICON_IMAGE } from 'definitions/assets';
import CheckCircleIcon from 'components/Icons/Check-Circle';

// #region other imports
import { PARTNER_API_SANDBOX_ACCOUNT } from 'enums/partnerApi/partnerApi.enum';
import { initialScootixModalStateValues } from 'constants/modals/scootixModal.constant';

// #region services
import {
    getAllSandboxAccountByMerchantIdService,
    merchantRequestPartnerApiSandboxAccountService,
} from 'services/partnerApi/sandboxAccount.service';

// #region utils
import { getVisitingObject } from 'utils/checkAuth';
import { apiErrorHandlerV2, cleanMobileNo } from 'utils/helpers';

// #endregion imports
import { validateForm } from './utils';

const { REQUEST_STATUS } = PARTNER_API_SANDBOX_ACCOUNT;

let initialFormValues = {};

/**
 * Page to show when the API service is not enabled and a sandbox account
 * is not created for a merchant
 */
function NotEnabledPage() {
    const history = useHistory();

    const _isMounted = useRef(true);
    const apiAccessFormRef = useRef();

    const { visitingMerchant } = getVisitingObject();
    // !TODO: add docs
    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    // Form State
    // eslint-disable-next-line no-unused-vars
    const [submitted, setSubmitted] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [validated, setValidated] = useState(false);

    const [formData, setFormData] = useState(initialFormValues);

    const [selectedMerchant] = useState(visitingMerchant);

    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [disableForm, setDisableForm] = useState(false);

    const [showNoRequestWarning, setShowNoRequestWarning] = useState(false);
    const [showPendingRequestWarning, setShowPendingRequestWarning] = useState(false);

    // Modals
    const [scootixModalState, setScootixModalState] = useState({ ...initialScootixModalStateValues });

    // !TODO: add docs
    useEffect(
        () => () => {
            _isMounted.current = false;
        },
        []
    );
    // !TODO: add docs
    useEffect(() => {
        if (formData.merchantId) {
            getSandboxAccountByMerchantId(formData.merchantId);
        }
    }, [formData.merchantId]);

    // !TODO: add docs
    const getSandboxAccountByMerchantId = async (id) => {
        setBackdropLoading((x) => x + 1);
        try {
            const { data } = await getAllSandboxAccountByMerchantIdService(id);
            if (data?.payload && Array.isArray(data.payload)) {
                const records = data.payload;
                const { COMPLETED, PENDING } = REQUEST_STATUS;
                const findPrevRequest = records.find(
                    (x) => x.requestStatus === PENDING || x.requestStatus === COMPLETED
                );
                const isPendingRequest = records.find((x) => x.requestStatus === PENDING);
                if (isPendingRequest) {
                    setShowPendingRequestWarning(true);
                    setShowNoRequestWarning(false);
                }

                if (findPrevRequest) {
                    setDisableForm(true);
                    toast.custom(
                        (t) => (
                            <CustomToast
                                text="API Service Request Already Exists, Please await reply"
                                t={t}
                                type="warning"
                            />
                        ),
                        {
                            position: 'top-right',
                        }
                    );
                } else {
                    setShowNoRequestWarning(true);
                }
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((x) => x - 1);
    };

    const handleRequestSandboxAccount = async () => {
        setFormLoading(true);

        try {
            const payload = {
                ...formData,
            };

            if (payload.developerContactNumber) {
                payload.developerContactNumber = cleanMobileNo(payload.developerContactNumber);
            }
            if (payload.developerWhatsappNumber) {
                payload.developerWhatsappNumber = cleanMobileNo(payload.developerWhatsappNumber);
            }

            const { data } = await merchantRequestPartnerApiSandboxAccountService(payload);
            if (data) {
                closeScootixModal();
                toast.custom(
                    (t) => <CustomToast text="You're Request is sent, Please await reply" t={t} type="success" />,
                    {
                        position: 'top-right',
                    }
                );
                getSandboxAccountByMerchantId(formData.merchantId);
                setFormData(initialFormValues);
                // eslint-disable-next-line no-unused-expressions
                apiAccessFormRef.current?.reset();
                setTimeout(() => {
                    history.goBack();
                }, 1000);
            }
        } catch (e) {
            const { message: error } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={error} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        closeScootixModal();
        setFormLoading(false);
        setSubmitted(false);
        setValidated(false);
    };

    // !TODO: add docs
    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors?.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
        }

        setScootixModalState({
            ...scootixModalState,
            show: true,
            message: `Do you want to request a sandbox Account for ${formData.merchantName} - ${
                selectedMerchant.referenceNumber || ''
            }`,
            onConfirm: () => handleRequestSandboxAccount(),
        });
    };

    const handleCheckChange = (event) => {
        const { id, checked } = event.target;

        if (id === 'isDeveloperNameSameAsMerchant' && checked) {
            if (!selectedMerchant) {
                toast.custom((t) => <CustomToast text="Select Merchant" t={t} type="warning" />, {
                    position: 'top-right',
                });
                return;
            }
            const {
                primaryContactName = null,
                primaryContactEmail = null,
                primaryContactNumber = null,
            } = selectedMerchant;

            setFormData({
                ...formData,
                developerName: primaryContactName,
                developerEmail: primaryContactEmail,
                developerContactNumber: primaryContactNumber,
                [id]: checked,
            });
            return;
        }

        if (id === 'isWhatsAppSameAsDeveloperContact' && checked) {
            if (!selectedMerchant) {
                toast.custom((t) => <CustomToast text="Select Merchant" t={t} type="warning" />, {
                    position: 'top-right',
                });
                return;
            }
            const { primaryContactNumber = null } = selectedMerchant;
            setFormData({
                ...formData,
                developerWhatsappNumber: primaryContactNumber,
                [id]: checked,
            });
            return;
        }

        setFormData({
            ...formData,
            [id]: checked,
        });
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;

        if (id === 'developerName' || id === 'developerContactNumber' || id === 'developerEmail') {
            setFormData({
                ...formData,
                isDeveloperNameSameAsMerchant: false,
                [id]: value,
            });
        } else if (id === 'developerWhatsappNumber') {
            setFormData({
                ...formData,
                isWhatsAppSameAsDeveloperContact: false,
                [id]: value,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: !scootixModalState.show, data: null });
    };

    const closeScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    {showNoRequestWarning && (
                        <Row>
                            <Col>
                                <Alert color="warning" className="p-4" style={{ border: '1px solid #ffaf59' }}>
                                    <img src={WARNING_ICON_IMAGE} alt="warning icon" className="mr-4" />
                                    Sorry! The API Service is not enabled for your merchant account. Click below to
                                    request API Access.
                                </Alert>
                            </Col>
                        </Row>
                    )}
                    {showPendingRequestWarning && (
                        <Row>
                            <Col>
                                <Alert color="warning" className="p-4" style={{ border: '1px solid #ffaf59' }}>
                                    <img src={WARNING_ICON_IMAGE} alt="warning icon" className="mr-4" />
                                    You have already requested for API Access, Please await for reply from developer
                                    team.
                                </Alert>
                            </Col>
                        </Row>
                    )}
                    {!showPendingRequestWarning && (
                        <Row>
                            <Col>
                                <Card className="shadow default-card">
                                    <CardHeader
                                        className="border-bottom pl-5"
                                        style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}
                                    >
                                        Request API Access
                                    </CardHeader>
                                    <CardBody className="pl-5">
                                        <AvForm
                                            className="needs-validation default-form"
                                            noValidate
                                            onSubmit={handleSubmit}
                                            ref={apiAccessFormRef}
                                        >
                                            <fieldset disabled={disableForm}>
                                                <Row>
                                                    <Col>
                                                        <Label htmlFor="isDeveloperNameSameAsMerchant">
                                                            Developer Contact Name
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center">
                                                    <Col xs="auto" className="mb-4">
                                                        <Input
                                                            id="isDeveloperNameSameAsMerchant"
                                                            type="checkbox"
                                                            className="ml-0 mt-0"
                                                            checked={formData.isDeveloperNameSameAsMerchant || false}
                                                            onChange={handleCheckChange}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Label htmlFor="usePrimaryContact" className="text-muted">
                                                            Use Merchant&apos;s Primary Contact as Developer Contact
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <AvField
                                                                name="developerName"
                                                                type="text"
                                                                className="form-control"
                                                                value={formData.developerName || ''}
                                                                onChange={handleInputChange}
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Required' },
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Col>
                                                                <Label htmlFor="developerEmail">
                                                                    Developer Email Address
                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <AvField
                                                                        name="developerEmail"
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={formData.developerEmail || ''}
                                                                        onChange={handleInputChange}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: 'Required',
                                                                            },
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col>
                                                                <Label htmlFor="developerContactNumber">
                                                                    Developer Contact Number
                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <AvField
                                                                        name="developerContactNumber"
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={formData.developerContactNumber || ''}
                                                                        onChange={handleInputChange}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: 'Required',
                                                                            },
                                                                        }}
                                                                        mask="+60 99-999 99999"
                                                                        maskChar=""
                                                                        tag={[Input, InputMask]}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label htmlFor="isWhatsAppSameAsDeveloperContact">
                                                            Developer WhatsApp Contact Number
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center">
                                                    <Col xs="auto" className="mb-4">
                                                        <Input
                                                            id="isWhatsAppSameAsDeveloperContact"
                                                            type="checkbox"
                                                            className="ml-0 mt-0"
                                                            checked={formData.isWhatsAppSameAsDeveloperContact || false}
                                                            onChange={handleCheckChange}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Label
                                                            htmlFor="isWhatsAppSameAsDeveloperContact"
                                                            className="text-muted"
                                                        >
                                                            Same as the Developer Contact Number
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {/* NOTE: try maxWidth to fix sizes */}
                                                        <FormGroup style={{ maxWidth: '50%' }}>
                                                            <AvField
                                                                name="developerWhatsappNumber"
                                                                type="text"
                                                                className="form-control"
                                                                value={formData.developerWhatsappNumber || ''}
                                                                onChange={handleInputChange}
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Required' },
                                                                }}
                                                                mask="+60 99-999 99999"
                                                                maskChar=""
                                                                tag={[Input, InputMask]}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="">
                                                    <Col md={7} lg={7} xl={6}>
                                                        <FormGroup>
                                                            <Label htmlFor="customMessage">Custom Message</Label>
                                                            <AvField
                                                                name="customMessage"
                                                                type="textarea"
                                                                helpMessage="Maximum 2000 Words"
                                                                className="form-control"
                                                                value={formData.customMessage || ''}
                                                                onChange={handleInputChange}
                                                                rows={5}
                                                                maxLength={2000}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Button
                                                            className="form-delete-btn"
                                                            style={{ backgroundColor: '#f99922' }}
                                                            type="submit"
                                                        >
                                                            {formLoading > 0 ? (
                                                                <Spinner
                                                                    className="mr-4 ml-4 0"
                                                                    color="info"
                                                                    size="sm"
                                                                />
                                                            ) : (
                                                                <>
                                                                    <CheckCircleIcon color="#fff" />
                                                                    &nbsp;Request API Access
                                                                </>
                                                            )}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Container>

                <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} loading={formLoading} />
                <BackdropLoader show={backdropLoading} opacity={0.7} />
            </div>
        </>
    );
}

const HotNotEnabledPage = hot(NotEnabledPage);

export default connect(null, {})(HotNotEnabledPage);
