/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */

export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { merchantId } = form;

    if (!merchantId) {
        return { isFormValid, message };
    }

    isFormValid = true;
    return { isFormValid, message };
};
