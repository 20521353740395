import {
    CREATE_DELIVERY,
    UPDATE_DELIVERY,
    DELETE_DELIVERY,
    UPDATE_PENDING_DELIVERY,
    DELETE_DELIVERY_PERMANENT,
    RESTORE_DELIVERY,
    SEND_DELIVERY_PROGRESS_MSG,
    GENERATE_DELIVERY_REPORT,
    CANCEL_DELIVERY,
    UNASSIGN_DELIVERY,
    NEW_DELIVERY_ATTEMPT,
    UPDATE_DELIVERY_TYPE,
    COMPLETE_DELIVERY,
    UPDATE_OTHER_INFORMATION_DELIVERY,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Create Delivery
 * @param {object} data
 * @returns {Promise}
 */
export const createDeliveryService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_DELIVERY).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Delivery
 * @param {object} data
 * @returns {Promise}
 */
export const updateDeliveryService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_DELIVERY).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Pending Delivery
 * @param {object} data
 * @returns {Promise}
 */
export const updatePendingDelivery = (pendingDataId, uuid, data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_PENDING_DELIVERY).setPathParams({ pendingDataId, uuid }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Delivery
 * @param {string} id
 * @returns {Promise}
 */
export const deleteDelivery = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_DELIVERY).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const deleteDeliveryPermanentService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_DELIVERY_PERMANENT).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Restore Delivery Service
 * @returns {Promise}
 */
export const restoreDeliveryService = (id) => {
    try {
        const url = UrlBuilder().setUrl(RESTORE_DELIVERY).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Send Delivery Rating Service
 * @returns {Promise}
 */
export const sendDeliveryProgressMsg = (data) => {
    try {
        const url = UrlBuilder().setUrl(SEND_DELIVERY_PROGRESS_MSG).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Generate Report
 * @returns {Promise}
 */
export const generateDeliveryReport = (data) => {
    try {
        const url = UrlBuilder().setUrl(GENERATE_DELIVERY_REPORT).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Cancel Delivery
 * @param {object} data
 * @returns {Promise}
 */
export const cancelDeliveryService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CANCEL_DELIVERY).setPathParams({ id: data.deliveryId }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Unassign Delivery
 * @param {object} data
 * @returns {Promise}
 */
export const unassignDeliveryService = (data) => {
    const url = UrlBuilder().setUrl(UNASSIGN_DELIVERY).setPathParams({ id: data.deliveryId }).build();
    return instance.patch(url, data);
};

/**
 * New Delivery Attempt
 * @param {object} data
 * @returns {Promise}
 */
export const newDeliveryAttemptService = (data) => {
    const url = UrlBuilder().setUrl(NEW_DELIVERY_ATTEMPT).setPathParams({ id: data.deliveryId }).build();
    return instance.patch(url, data);
};

/**
 * Update Delivery Type
 * @param {object} data
 * @returns {Promise}
 */
export const updateDeliveryTypeService = (data) => {
    const url = UrlBuilder().setUrl(UPDATE_DELIVERY_TYPE).setPathParams({ id: data.deliveryId }).build();
    return instance.patch(url, data);
};

/**
 * Complete Delivery
 * @param {object} data
 * @returns {Promise}
 */
export const completeDeliveryService = (data) => {
    try {
        const url = UrlBuilder().setUrl(COMPLETE_DELIVERY).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Delivery Type
 * @param {object} data
 * @returns {Promise}
 */
export const updateOtherInfoDeliveryService = (data) => {
    const url = UrlBuilder().setUrl(UPDATE_OTHER_INFORMATION_DELIVERY).setPathParams({ id: data.deliveryId }).build();
    return instance.patch(url, data);
};
