export const defaultTableHeaders = [
    {
        text: 'Type',
        dataField: 'title',
        sort: 'asc',
        width: 150,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Reference',
        dataField: 'reference',
        sort: 'asc',
        width: 100,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Additional References',
        dataField: 'additionalReferences',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Transaction Date And Time',
        dataField: 'transactionDateTime',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'User',
        dataField: 'user',
        sort: 'asc',
        width: 150,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'In',
        dataField: 'in',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Out',
        dataField: 'out',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Unit',
        dataField: 'unitOfMeasure',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
    {
        text: 'Balance Quantity',
        dataField: 'balanceQuantity',
        sort: 'asc',
        width: 200,
        editable: false,
        headerStyle: {
            backgroundColor: '#303a52',
            color: '#FFF',
        },
    },
];
