/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import RatingTooltip from 'react-rating-tooltip';
import { Button, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';

// #region imports
import { CustomToast, BackdropLoader } from 'components';
import Hoc from 'components/Hoc';
import customerReview from 'assets/images/users/user-review.png';
import { apiErrorHandlerV2, getDateValue } from 'utils/helpers';
import { APP_LOGO_WIDE_IMAGE } from 'definitions/assets';

// #endregion imports
import { getDeliveryByRatingId } from './services';
import { updateDeliveryService } from '../../services';

function DeliveryProgressScreen(props) {
    const [formData, setFormData] = useState({
        referenceNumber: '',
        orderReferenceNumber: '',
        pickupLocation: '',
    });
    const [token, setToken] = useState(null);
    const [ratingClick, setRatingClick] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const handleOnClick = async () => {
        const postData = formData;
        postData.isRated = true;
        try {
            const updatedDeliveryData = await updateDeliveryService(postData);
            if (updatedDeliveryData) {
                setFormData({ ...formData, ...updatedDeliveryData });
            }
            toast.custom((t) => <CustomToast text="Successfully rated delivery" t={t} type="success" />, {
                position: 'top-right',
            });
            setRatingClick(true);
        } catch (error) {
            console.log(error);
            toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    useEffect(() => {
        // eslint-disable-next-line react/prop-types
        const currentPagePathname = props.location.pathname;
        if (currentPagePathname) {
            let formatToken = currentPagePathname.replace('dp', '');
            formatToken = formatToken.replace(/\/+/g, '');
            setToken(formatToken);
        }
    }, []);

    useEffect(() => {
        if (token) {
            handleVerifyTokenAndRetrieveData(token);
        }
    }, [token]);

    const handleRateChange = (RatingIndex, RatingValue) => {
        setFormData({
            ...formData,
            deliveryRating: RatingValue,
        });
    };

    const handleTextChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value,
        });
    };

    const handleVerifyTokenAndRetrieveData = async (ratingToken) => {
        setBackdropLoading((prevState) => prevState + 1);

        try {
            const { data } = await getDeliveryByRatingId(ratingToken);
            if (data) {
                setFormData(data);
            } else {
                toast.custom((t) => <CustomToast text="Details not Found, Contact Support" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setBackdropLoading((prevState) => prevState - 1);
    };

    return (
        <Hoc {...props}>
            <div style={{ backgroundColor: '#E5E5E5', maxWidth: 820, margin: 'auto' }}>
                <div
                    style={{
                        backgroundColor: '#2F2D2D',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: 25,
                    }}
                >
                    <img src={APP_LOGO_WIDE_IMAGE} alt="icon" style={{ height: '85px', objectFit: 'contain' }} />
                </div>

                <div style={{ height: '5px', backgroundColor: '#FDBA24' }} />

                <div style={{ padding: '14px' }}>
                    <p
                        style={{
                            fontSize: '14px',
                            fontFamily: 'Inter',
                            textAlign: 'center',
                            color: '#5E5858',
                            fontWeight: 'bold',
                            marginBottom: 0,
                        }}
                    >
                        {`Delivery ${formData.referenceNumber}`}
                    </p>
                    <p
                        style={{
                            fontSize: '11px',
                            fontFamily: 'Inter',
                            textAlign: 'center',
                            color: '#828282',
                            marginBottom: 3,
                        }}
                    >
                        {`We are delivering your order from ${formData.pickupLocation}`}
                    </p>
                    <p
                        style={{
                            fontSize: '14px',
                            fontFamily: 'Inter',
                            textAlign: 'center',
                            color: '#828282',
                            fontWeight: 'bold',
                        }}
                    >
                        {`Order Reference: ${formData.orderReferenceNumber || formData.merchantOrderNo || 'Not Found'}`}
                    </p>
                </div>

                <div
                    style={
                        formData.isCompleted
                            ? { maxWidth: 480, margin: 'auto' }
                            : { maxWidth: 480, margin: 'auto', height: '65vh' }
                    }
                >
                    {/* Card 1 */}

                    {formData.createdAt && (
                        <div
                            style={{
                                display: 'flex',
                                borderRadius: '8px',
                                padding: 10,
                                backgroundColor: '#FCFCFC',
                                marginBottom: 8,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <i className="ri-shopping-cart-line" style={{ fontSize: 25, margin: 15 }} />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            fontSize: '13px',
                                            lineHeight: '14.7px',
                                            color: '#828282',
                                        }}
                                    >
                                        ScootiX planning your delivery.
                                    </span>
                                    <span
                                        className="item-time"
                                        style={{
                                            fontFamily: 'Inter',
                                            fontWeight: 'normal',
                                            color: '#6B6B6B',
                                            fontSize: '10px',
                                        }}
                                    >
                                        {`${getDateValue(formData.createdAt, 'datetime')}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Card 2 */}

                    {formData.pickUpStartedAt && (
                        <div
                            style={{
                                display: 'flex',
                                borderRadius: '8px',
                                padding: 10,
                                backgroundColor: '#FCFCFC',
                                marginBottom: 8,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <i className="ri-takeaway-line" style={{ fontSize: 25, margin: 15 }} />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            fontSize: '13px',
                                            lineHeight: '14.7px',
                                            color: '#828282',
                                        }}
                                    >
                                        Rider is on the way to pick your derlivery.
                                    </span>
                                    <span
                                        className="item-time"
                                        style={{
                                            fontFamily: 'Inter',
                                            fontWeight: 'normal',
                                            color: '#6B6B6B',
                                            fontSize: '10px',
                                        }}
                                    >
                                        {`${getDateValue(formData.pickUpStartedAt, 'datetime')}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Card 3 */}

                    {formData.pickedUpAt && (
                        <div
                            style={{
                                display: 'flex',
                                borderRadius: '8px',
                                padding: 10,
                                backgroundColor: '#FCFCFC',
                                marginBottom: 8,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <i className="ri-hand-coin-line" style={{ fontSize: 25, margin: 15 }} />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            fontSize: '13px',
                                            lineHeight: '14.7px',
                                            color: '#828282',
                                        }}
                                    >
                                        Your order is in our hand.
                                    </span>
                                    <span
                                        className="item-time"
                                        style={{
                                            fontFamily: 'Inter',
                                            fontWeight: 'normal',
                                            color: '#6B6B6B',
                                            fontSize: '10px',
                                        }}
                                    >
                                        {`${getDateValue(formData.pickedUpAt, 'datetime')}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Card 4 */}

                    {formData.deliveryStartedAt && (
                        <div
                            style={{
                                display: 'flex',
                                borderRadius: '8px',
                                padding: 10,
                                backgroundColor: '#FCFCFC',
                                marginBottom: 8,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <i className="ri-takeaway-line" style={{ fontSize: 25, margin: 15 }} />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            fontSize: '13px',
                                            lineHeight: '14.7px',
                                            color: '#828282',
                                        }}
                                    >
                                        We are heading to you for the delivery.
                                    </span>
                                    <span
                                        className="item-time"
                                        style={{
                                            fontFamily: 'Inter',
                                            fontWeight: 'normal',
                                            color: '#6B6B6B',
                                            fontSize: '10px',
                                        }}
                                    >
                                        {`${getDateValue(formData.deliveryStartedAt, 'datetime')}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Card 5 */}

                    {formData.deliveredAt && (
                        <div
                            style={{
                                display: 'flex',
                                borderRadius: '8px',
                                padding: 10,
                                backgroundColor: '#FCFCFC',
                                marginBottom: 15,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <i className="ri-check-line" style={{ fontSize: 25, margin: 15 }} />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            fontSize: '13px',
                                            lineHeight: '14.7px',
                                            color: '#828282',
                                        }}
                                    >
                                        We have delivered your order.
                                    </span>
                                    <span
                                        className="item-time"
                                        style={{
                                            fontFamily: 'Inter',
                                            fontWeight: 'normal',
                                            color: '#6B6B6B',
                                            fontSize: '10px',
                                        }}
                                    >
                                        {`${getDateValue(formData.deliveredAt, 'datetime')}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Rate Card */}
                    {formData.isCompleted && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 10,
                                maxWidth: '493px',
                                borderRadius: '10px',
                                backgroundColor: '#FCFCFC',
                                margin: 'auto',
                                marginBottom: '5px',
                            }}
                        >
                            {!ratingClick && !formData.isRated ? (
                                <>
                                    <span
                                        style={{
                                            fontSize: '14px',
                                            fontFamily: 'Inter',
                                            color: '#828282',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Rate your Delivery with ScootiX!
                                    </span>
                                    <span
                                        className="item-time"
                                        style={{
                                            fontSize: '9px',
                                            fontFamily: 'Inter',
                                            color: '#828282',
                                            fontWeight: 'normal',
                                            marginBottom: 5,
                                        }}
                                    >
                                        Help us to improve your delivery experience!
                                    </span>
                                    <RatingTooltip
                                        max={5}
                                        ratingValue={[1, 2, 3, 4, 5]}
                                        onChange={handleRateChange}
                                        styleConfig={{
                                            backgroundColor: '#F2F2F2',
                                            height: '28px',
                                        }}
                                        ActiveComponent={
                                            <i
                                                key="active_7"
                                                className="mdi mdi-star"
                                                style={{
                                                    color: '#fdba24',
                                                    fontSize: '70px',
                                                }}
                                            />
                                        }
                                        InActiveComponent={
                                            <i
                                                key="active_07"
                                                className="mdi mdi-star-outline"
                                                style={{
                                                    color: '#fdba24',
                                                    fontSize: '70px',
                                                }}
                                            />
                                        }
                                    />
                                    <span
                                        className="item-time"
                                        style={{
                                            fontSize: '10px',
                                            fontFamily: 'Inter',
                                            color: '#828282',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        <b>Tell us good and bad </b>(optional)
                                    </span>
                                    <FormGroup>
                                        <textarea
                                            style={{ borderRadius: 10, padding: 5 }}
                                            id="deliveryRatingMessage"
                                            rows="3"
                                            onChange={handleTextChange}
                                            value={formData.deliveryRatingMessage}
                                        ></textarea>
                                    </FormGroup>
                                    <Button
                                        style={{
                                            backgroundColor: '#19E269',
                                            border: 'hidden',
                                            borderRadius: '15px',
                                        }}
                                        type="button"
                                        onClick={() => handleOnClick()}
                                    >
                                        <span
                                            style={{
                                                fontSize: '12px',
                                                fontFamily: 'Inter',
                                                color: '#FCFAFA',
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            Rate ScootiX
                                        </span>
                                    </Button>
                                </>
                            ) : (
                                <div
                                    style={{
                                        padding: 25,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span>
                                        <img src={customerReview} alt="" height="80" style={{ objectFit: 'contain' }} />
                                    </span>
                                    <span
                                        className="item-time"
                                        style={{
                                            marginTop: 5,
                                            fontSize: '14px',
                                            fontFamily: 'Inter',
                                            color: '#828282',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Thank you for rating Scootix!
                                    </span>
                                    <span
                                        className="item-time"
                                        style={{
                                            fontSize: '10px',
                                            fontFamily: 'Inter',
                                            color: '#828282',
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Your rating is so much important us to serve you better in the future.
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <hr />
                <div
                    style={{
                        textAlign: 'center',
                        marginBottom: 10,
                    }}
                >
                    <span
                        style={{
                            fontSize: '11px',
                            fontFamily: 'Inter',
                            color: '#9C9C9C',
                            fontWeight: 'normal',
                        }}
                    >
                        ScootiX is a Delivery Management Solution. <br />
                        Managed by TASA Global Solutions Sdn Bhd
                    </span>
                </div>
            </div>
            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </Hoc>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

const HotDeliveryProgressScreen = hot(DeliveryProgressScreen);

export default connect(mapStateToProps, {})(HotDeliveryProgressScreen);
