/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Label, Spinner } from 'reactstrap';

// #region assets | components
import { Select, SelectAll } from 'components/Icons';

// #region other imports
import { centerElementContainerStyles } from 'styles';

// #endregion imports
import Search from './TableHeaderComponents/TableSearch';

function SelectToPrintTableHeader(props) {
    const { isManualSearch, onFilter, formLoading, isSearch, setSearch, search, headerHandlers, showHeaderHandlers } =
        props;

    return (
        <>
            <div className="select-to-print table-top-row">
                <div className="top-row">
                    <Label>Delivery ID</Label>
                    {isSearch && (
                        <Search
                            onFilter={onFilter}
                            isManualSearch={isManualSearch}
                            setSearch={setSearch}
                            search={search}
                            searchPlaceholder="Search"
                        />
                    )}
                </div>
                {showHeaderHandlers && (
                    <div className="d-flex row" style={centerElementContainerStyles}>
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                            onClick={() => headerHandlers.onAddSelectedToPrint()}
                            disabled={formLoading}
                            style={{ borderRadius: 30 }}
                        >
                            {formLoading ? (
                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                            ) : (
                                <div className="row mt-1">
                                    <Select />
                                    <div className="h6 mt-1 pr-3">Add Selected to Print</div>
                                </div>
                            )}
                        </motion.button>
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                            disabled={formLoading}
                            style={{ borderRadius: 30 }}
                            onClick={() => headerHandlers.onAddAllToPrint()}
                        >
                            {formLoading ? (
                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                            ) : (
                                <div className="row mt-1">
                                    <SelectAll />
                                    <div className="h6 mt-1 pr-3">Add All to Print</div>
                                </div>
                            )}
                        </motion.button>
                    </div>
                )}
            </div>
        </>
    );
}

SelectToPrintTableHeader.propTypes = {
    isManualSearch: PropTypes.bool,
    onFilter: PropTypes.func,
    formLoading: PropTypes.bool,
    isSearch: PropTypes.bool,
    setSearch: PropTypes.func,
    search: PropTypes.object,
    headerHandlers: PropTypes.object,
    showHeaderHandlers: PropTypes.bool,
};

export default SelectToPrintTableHeader;
