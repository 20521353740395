import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import toast from 'react-hot-toast';

// #region assets | components
import { CustomToast } from 'components';

// #region services
import { getAllSystemDataByTypeModuleAndHeadService } from 'services/system-data.service';
import {
    createPrinterSettingsData,
    updatePrinterSettingsData,
    getPrinterSettingsByTypeModuleUser,
} from 'services/printer-settings.service';

// #region other imports
import { SYSTEM_DATA_TYPES, BATCH_PRINT } from 'constants/enums';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';
import { getUser } from 'utils/checkAuth';

// #endregion components
import { validatePrinterSettings } from '../utils';
import FormBody from './FormBody';

function Form() {
    const _isMounted = useRef(true);

    const userData = getUser();

    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [editingMode, setEditingMode] = useState(false);

    // Data
    const [allPrinters, setAllPrinters] = useState([]);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        loadSystemDataPrinters();
        loadPrinterSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        return () => {
            _isMounted.current = false;
        };
    }, []);

    const loadSystemDataPrinters = async () => {
        try {
            const { data } = await getAllSystemDataByTypeModuleAndHeadService(
                SYSTEM_DATA_TYPES.PRINTERS,
                SYSTEM_DATA_TYPES.LABEL_PRINT.SHIPPING_LABEL
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                setAllPrinters(data.map((x) => ({ label: x.name, value: x.name })));
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        }
    };

    const loadPrinterSettings = async () => {
        setFormLoading(true);
        try {
            const { data } = await getPrinterSettingsByTypeModuleUser(
                BATCH_PRINT.PRINTER_SETTINGS.TYPES,
                BATCH_PRINT.PRINTER_SETTINGS.MODULE,
                userData._id
            );
            if (_isMounted.current && data?.settings) {
                const updatedPayload = _.clone(data); // Cloned to separate Obj
                delete updatedPayload.settings; // delete unwanted properties
                setFormData((prevState) => ({ ...prevState, ...data.settings, ...updatedPayload }));
                setEditingMode(true);
            }
        } catch (e) {
            apiErrorHandlerV2(e);
        }
        setFormLoading(false);
    };

    const handleSelectChange = (event, id) => {
        if (id === 'printer') {
            setFormData({
                ...formData,
                [id]: event ? event.value : null,
                printSize: null,
            });
            return;
        }
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const isFormValid = validatePrinterSettings(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text="Fill all fields" t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);

        // Build Payload for API
        const payload = _.clone(formData);
        delete payload._id; // delete object Id

        // API Response Data
        let apiResponseData = null;

        try {
            if (editingMode) {
                const { data } = await updatePrinterSettingsData({
                    settings: payload,
                    _id: formData._id,
                });
                if (data) {
                    apiResponseData = data;
                }
            } else {
                const { data } = await createPrinterSettingsData({
                    type: 'PRINTERS',
                    module: 'SHIPPING_LABEL',
                    userId: userData._id,
                    settings: payload,
                });
                if (data) {
                    apiResponseData = data;
                }
            }
            setEditingMode(true);
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'Updated' : 'Created'} Printer Settings`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
        } catch (e) {
            const { message: error } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={error} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        // If API Response is Available Update State
        if (apiResponseData) {
            setFormData({ ...formData, ...apiResponseData });
        }

        setFormLoading(false);
        setSubmitted(false);
    };

    const formBodyProps = {
        allPrinters,
        formData,
        handleSelectChange,
        editingMode,
        setEditingMode,
        setFormData,
        setFormLoading,
        formLoading,
        handleSubmit,
        setSubmitted,
        submitted,
        validated,
    };

    return <FormBody {...formBodyProps} />;
}

export default Form;
