export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Reference',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
    {
        key: 'warehouseSiteName',
        value: 'Warehouse',
    },
    {
        key: 'stocksAdjustmentType',
        value: 'Stocks Adjustment Type',
    },
    {
        key: 'stocksTransferLocation',
        value: 'Stocks Transfer Location',
    },
    {
        key: 'scootixOrderNumber',
        value: 'Scootix Order Number',
    },
    {
        key: 'note',
        value: 'Note',
    },
    {
        key: 'status',
        value: 'Status',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
    {
        key: 'createdBy',
        value: 'Created By',
        type: 'userObject',
    },
    {
        key: 'approvedAt',
        value: 'Approved At',
        type: 'dateTime',
    },
    {
        key: 'approvedBy',
        value: 'Approved By',
        type: 'userObject',
    },
];

export const reportTypes = [
    { value: 'Inventory Report', label: 'Inventory Report' },
    { value: 'Cycle Count Report', label: 'Cycle Count Report' },
    { value: 'Inventory Transaction Log', label: 'Inventory Transaction Log' },
    { value: 'Stocks Receiving', label: 'Stocks Receiving' },
    { value: 'Stocks Issuing', label: 'Stocks Issuing' },
    { value: 'Stocks Adjustments', label: 'Stocks Adjustments' },
];
