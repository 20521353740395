// @flow
import {
    RESTORE_DELIVERY_START,
    RESTORE_DELIVERY_END,
    PERMANENT_DELETE_DELIVERY_END,
    PERMANENT_DELETE_DELIVERY_START,
} from './actionTypes';

const INIT_STATE = {
    restoreDeliveryData: null,
    permanentDeleteDeliveryData: null,
};

const DeletedDeliveriesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESTORE_DELIVERY_START:
            return {
                ...state,
                restoreDeliveryData: action.payload,
            };
        case RESTORE_DELIVERY_END:
            return {
                ...state,
                restoreDeliveryData: null,
            };
        case PERMANENT_DELETE_DELIVERY_START:
            return {
                ...state,
                permanentDeleteDeliveryData: action.payload,
            };
        case PERMANENT_DELETE_DELIVERY_END:
            return {
                ...state,
                permanentDeleteDeliveryData: null,
            };
        default:
            return state;
    }
};

export default DeletedDeliveriesReducer;
