import * as React from 'react';
import PropTypes from 'prop-types';

function MultiStopBilling(props) {
    const { width = '22', height = '22', color = '#fff' } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 24">
            <g
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                clipPath="url(#clip0_6802_477)"
            >
                <path d="M13.038 20.666c-.902.665-2.393.337-2.713-.983a1.723 1.723 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37 1 .608 2.296.07 2.572-1.065.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 00.402 2.248M15 12a3 3 0 10-1.724 2.715M21 15h-2.5a1.5 1.5 0 100 3h1a1.5 1.5 0 110 3H17M19 21v1m0-8v1"></path>
            </g>
            <defs>
                <clipPath id="clip0_6802_477">
                    <path fill="#fff" d="M0 0H24V24H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

MultiStopBilling.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};

export default MultiStopBilling;
