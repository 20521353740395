export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Delivery ID',
    },
    {
        key: 'merchantOrderNo',
        value: 'Merchant Order Number',
    },
    {
        key: 'deliveryType',
        value: 'Delivery Type',
    },
    {
        key: 'pickupLocation',
        value: 'From',
    },
    {
        key: 'recipientNameFormatted',
        value: 'Recipient',
    },
    {
        key: 'recipientName',
        value: 'Recipient Name',
    },
    {
        key: 'pickedUpAt',
        value: 'Pickup Date and Time',
        type: 'dateTime',
    },
    {
        key: 'deliveredAt',
        value: 'Delivery Date and Time',
        type: 'dateTime',
    },
    {
        key: 'view',
        value: 'View',
        type: 'viewDeliveryNavigation',
    },
];

export const initialDeliveryData = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: defaultTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};
