import { GET_DELIVERY_BY_RATING_TOKEN } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';
/**
 * Restore Delivery Service
 * @returns {Promise}
 */
export const getDeliveryByRatingId = (token) => {
    try {
        const url = UrlBuilder().setUrl(GET_DELIVERY_BY_RATING_TOKEN).setPathParams({ token }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
