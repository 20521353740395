import * as React from 'react';

function RightArrowColored(props) {
    const { width, height, color } = props;
    return (
        <svg
            width={width || 25}
            height={height || 25}
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M2 6h8V2.414a1 1 0 011.707-.707l6.586 6.586a1 1 0 010 1.414l-6.586 6.586A1 1 0 0110 15.586V12H2a1 1 0 01-1-1V7a1 1 0 011-1v0z"
                stroke={color || '#FDBA24'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default RightArrowColored;
