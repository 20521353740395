// Table header data for the merchant list table
export const partnerListTableHeaders = [
    {
        key: 'merchantReferenceNumber',
        value: 'Merchant ID',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
    {
        key: 'developerName',
        value: 'Developer',
    },
    {
        key: 'developerEmail',
        value: 'Developer Email',
    },
    {
        key: 'developerContactNumber',
        value: 'Developer Contact Number',
    },
    {
        key: 'serviceStatusClientValue',
        value: 'Service Status',
        type: 'statusLabel',
        style: ['centerElements'],
    },
    {
        key: 'quickAction',
        value: 'Quick Action',
        type: 'quickActionButton',
        style: ['centerElements'],
    },
    {
        key: 'merchantProfile',
        value: 'Merchant Profile',
        type: 'viewMerchantButton',
        style: ['increaseWidth', 'centerElements'],
    },
    {
        key: 'statusClientUpdatedAt',
        value: 'Status Updated At',
        type: 'dateTime',
    },
    {
        key: 'action',
        value: 'Action',
        type: 'customActionButton',
        style: ['centerElements'],
        params: { buttonLabel: 'Manage', customActionHandlerName: 'navigateToApiPortalPage' },
    },
];

// Initial form values for the API service enable modal
export const modalInitialFormValues = {
    developerName: '',
    developerEmail: '',
    developerContactNumber: '',
    developerWhatsappNumber: '',
    password: '',
    usePrimaryContact: false,
    isDeveloperTestPassed: false,
};

// Initially disabled fields in the API service enable modal
export const modalInitialDisabledFields = {
    developerName: false,
    developerEmail: false,
    developerContactNumber: false,
};
