/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
import moment from 'moment';
import axios from 'axios';

// #region imports
import { getApiErrorMessage } from 'constants/api';
import { MALAYSIA_STATE_CITIES } from 'constants/constants';

// #region services
import { getAttachmentByKey } from 'services/attachment.service';

// #endregion imports
import { DEBUG_MODE } from './checks';
import { getVisitingObject } from './checkAuth';

export function isEmptyObject(obj) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}

export const fileDownload = (file, fileName) => {
    axios({
        url: file,
        method: 'GET',
        responseType: 'blob',
    }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    });
};

export const buildFormData = (files) => {
    const image = files[0];
    const imageData = new FormData();
    imageData.append('file', image);
    return imageData;
};

export const buildFormDataForMultiple = (files) => {
    const image = files;
    const imageData = new FormData();
    image.forEach((attachment) => {
        imageData.append('file', attachment);
    });
    return imageData;
};

export const buildFormDataSingle = (file) => {
    const imageData = new FormData();
    imageData.append('file', file);
    return imageData;
};

export const buildFormDataForMultipleNew = (files) => {
    const image = files;
    const imageData = new FormData();
    image.forEach((attachment) => {
        if (attachment) {
            imageData.append('file', attachment.data);
        }
    });
    return imageData;
};

export const makeUrl = (filters) => {
    let str = '';
    // eslint-disable-next-line guard-for-in
    for (const data in filters) {
        if (str !== '') {
            str += '&';
        }
        str += `${data}=${filters[data]}`;
    }
    return str;
};

export const getDateValue = (date, mode = 'date') => {
    if (date) {
        if (mode === 'date') {
            return moment(date).format('YYYY-MM-DD');
        }
        if (mode === 'datetime') {
            return moment(date).format('DD-MM-YYYY hh:mm A');
        }
        if (mode === 'datetime-local') {
            // 2020-03-14T13:45:00
            const formattedDate = moment(date).format('YYYY-MM-DD');
            const formattedTime = moment(date).format('HH:mm:ss');
            const dateToReturn = `${formattedDate}T${formattedTime}`;
            return dateToReturn;
        }
        if (mode === 'time') {
            return moment(date).format('hh:mm A');
        }
    } else {
        return '';
    }
};

export const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
});

export const logDataToCopy = (data) => {
    console.log('JSON', JSON.stringify(data, null, 2));
};

export const fileListToArray = (list) => {
    const array = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < list.length; i++) {
        array.push(list.item(i));
    }
    return array;
};

export const reactSelectCustomStyles = {
    menu: (provided) => ({ ...provided, zIndex: 9999 }), // increase z-index
    option: (provided) => ({
        ...provided,
        fontSize: '14px',
    }),
    control: (provided, state) => ({
        ...provided,
        minHeight: 38,
        fontSize: '13px',
        borderRadius: 5,
        borderColor:
            state.selectProps.required && !state.hasValue && state.selectProps.submitted
                ? 'hsl(0, 99%, 48%)'
                : '#ced4da',
    }),
    container: (provided) => ({
        ...provided,
        flex: 1, // full width
        alignSelf: 'stretch', // full height
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.9 : 1;
        return {
            ...provided,
            opacity,
            fontSize: '12px',
            maxWidth: 'max-content',
            color: 'black',
        };
    },
};

// Default Value for React Select
export const getDefaultValueForSelect = (data, array = null, id) => {
    DEBUG_MODE() && console.log('select-func');
    if (data && array && id) {
        const relevantDropdownItem = array.find((x) => x[id] === data);
        if (relevantDropdownItem) {
            return { label: relevantDropdownItem?.label || data, value: data };
        }
        return { label: data, value: data };
    }
    if (data) {
        return { label: data, value: data };
    }
    return null;
};

/**
 * Get Relevant State Cities
 * @param {string} state
 * @returns
 */
export const getRelevantStateCities = (state) => {
    const relevantCities = MALAYSIA_STATE_CITIES[state];
    return relevantCities || [];
};

// if number less than zero add "0" before val
export function pad(n) {
    if (n === 0) {
        return '0';
    }
    return n < 10 ? `0${n}` : n;
}

export const getGreetings = () => {
    const now = new Date();
    const hrs = now.getHours();
    let msg = '';

    if (hrs >= 0) msg = 'Good morning!'; // After 12am
    if (hrs >= 12) msg = 'Good Afternoon!'; // After 12pm
    if (hrs >= 16) msg = 'Good Evening!'; // After 4pm
    return msg;
};

/**
 * @desc Encode Params Including Forward Slash
 * @param {string} Url
 * @returns {string} encodedString
 */
export const encodeForwardSlashParam = (url) => {
    if (url && typeof url === 'string') {
        if (url.indexOf('/') > -1) {
            return url.replaceAll('/', '-');
        }
        return url;
    }
    return url;
};

export const cleanMobileNo = (mobileNo) => {
    let customMobileNo = mobileNo;
    if (mobileNo) {
        try {
            if (customMobileNo.indexOf('-') > -1) {
                customMobileNo = customMobileNo.split('-').join('');
            }
            if (customMobileNo.indexOf('(') > -1) {
                customMobileNo = customMobileNo.split('(').join('');
            }
            if (customMobileNo.indexOf('_') > -1) {
                customMobileNo = customMobileNo.split('_').join('');
            }
            if (customMobileNo.indexOf(')') > -1) {
                customMobileNo = customMobileNo.split(')').join('');
            }
            if (customMobileNo.indexOf('+') > -1) {
                customMobileNo = customMobileNo.split('+').join('');
            }
            if (customMobileNo.indexOf(' ') > -1) {
                customMobileNo = customMobileNo.split(' ').join('');
            }

            customMobileNo = customMobileNo.replace(/[^\w\s]/gi, '');
            customMobileNo = customMobileNo.replace(/[a-z]/gi, '');
        } catch (e) {
            console.error(e);
        }
    }
    return customMobileNo;
};

export const buildNewAttachment = (key) => ({
    data: key,
    isDbSaved: true,
    lastUpdated: new Date(),
});

export const cleanObject = (obj) => {
    const modifiedObj = obj;
    for (const propName in modifiedObj) {
        if (modifiedObj[propName] === null || modifiedObj[propName] === undefined) {
            delete modifiedObj[propName];
        }
    }
    return modifiedObj;
};

export const cleanAttachmentObject = (obj) => {
    const modifiedObj = obj;
    for (const propName in modifiedObj) {
        if (
            modifiedObj[propName].data &&
            typeof modifiedObj[propName].data !== 'string' &&
            !modifiedObj[propName].default
        ) {
            delete modifiedObj[propName];
            return;
        }
        if (modifiedObj[propName] === null || modifiedObj[propName] === undefined) {
            delete modifiedObj[propName];
        }
    }
    return modifiedObj;
};

export const validateAttachments = (attachment) => {
    let isInvalid = false;
    attachment.forEach((val) => {
        if (!val.data) {
            isInvalid = true;
        }
    });
    return !isInvalid;
};

export const apiErrorHandler = (error) => {
    console.log(error);
    let isMessage = false;

    try {
        if (error && error.data && error.data.errors && error.data.errors.userMessage) {
            const message = error.data.errors.userMessage;
            if (message) {
                isMessage = true;
                return message;
            }
        }

        if (error.response && error.response.data && error.response.data.errors && !isMessage) {
            const message = getApiErrorMessage(error.response.data.errors.msg, error.response.status);
            if (message) {
                isMessage = true;
                return message;
            }
        }

        if (error && error.data && error.data.errors && error.data.errors.msg && !isMessage) {
            const message = getApiErrorMessage(error.data.errors.msg, error.status || null);
            if (message) {
                isMessage = true;
                return message;
            }
        }

        if (error && error.data && error.data.errors && error.data.errors.msg && !isMessage) {
            const message = getApiErrorMessage(error.data.errors.msg);
            if (message) {
                isMessage = true;
                return message;
            }
        }

        if (!isMessage) {
            return 'Something went wrong';
        }
    } catch (e) {
        console.log(e);
    }

    return 'Something went wrong';
};

export function isObject(item) {
    return typeof item === 'object' && !Array.isArray(item) && item !== null;
}

export const renderImageUtil = (image) =>
    new Promise((resolve) => {
        if (image && typeof existingFile === 'string' && !image.includes('Signature') && !image.includes('Algorithm')) {
            getAttachmentByKey(image)
                .then((res) => {
                    if (res) {
                        return resolve(res);
                    }
                    return resolve(null);
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {});
        } else if (image && typeof image === 'string' && image.includes('Signature') && image.includes('Algorithm')) {
            return resolve(image);
        }
        return resolve(null);
    });

export const mapDefaultForeignKeys = (docs) => {
    let allDocs = [];
    allDocs = docs.map((x) => ({
        ...x,
    }));
    allDocs.map((x) => {
        const val = x;
        if (val.merchantId && isObject(val.merchantId)) {
            val.merchant = x.merchantId && isObject(x.merchantId) ? x.merchantId : null;
            val.merchantId = x.merchantId && isObject(x.merchantId) ? x.merchantId._id : x.merchantId;
        }
        if (val.clientId && isObject(val.clientId)) {
            val.client = x.clientId && isObject(x.clientId) ? x.clientId : null;
            val.clientId = x.clientId && isObject(x.clientId) ? x.clientId._id : x.clientId;
        }
        return val;
    });
    return allDocs;
};

export function hasWhiteSpace(s) {
    if (s) {
        return s.indexOf(' ') >= 0;
    }
    return false;
}

export function capitalizeFirstLetter(string) {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
}

function isUpperCase(str) {
    if (!str) return null;
    const isUpper = str === str.toUpperCase();
    return {
        text: str,
        isUpper,
    };
}

// BACKEND UTIL -> CLIENT SERVICE
export function mapUserObj(user) {
    return {
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.fullName || '',
        email: user.email,
        id: user._id || user.id,
        _id: user._id || user.id,
        clientId: user.clientId,
        merchantId: user.merchantId,
    };
}

/**
 * Handle All API errors here
 * Destructure the error in a readable format and return
 * @param {*} e
 * @param {*} showToast
 * @returns
 */
export const apiErrorHandlerV2 = (e, showToast = false) => {
    // eslint-disable-next-line no-console
    console.error(e);
    let message = 'Something went wrong';
    let isFound = false;
    if (!e) {
        return { message: 'Something went wrong, Try Again' };
    }

    if (e?.status) {
        if (e.data && e.data?.errors) {
            const errors = e.data?.errors;
            if (errors.userMessage) {
                isFound = true;
                message = errors.userMessage;
            }
            if (errors.msg && !isFound && typeof errors.msg === 'string') {
                isFound = true;
                message = errors.msg;
            }
        }
    }

    if (!isFound) {
        if (e?.status && e?.status === 404) {
            message = 'Something went wrong Contact Admin [404]';
        } else if (e?.status && e?.status === 401) {
            message = 'UnAuthorized Request Please Login Again';
        } else if (e?.data?.msg) {
            message = getApiErrorMessage(e.data.errors.msg, e.status);
        } else if (e.message) {
            message = e.message;
        }
    }

    if (typeof message !== 'string') {
        return { message };
    }

    const { isUpper, text: errorCode } = isUpperCase(message);
    if (isUpper) {
        message = getApiErrorMessage(message);
    }
    return { message, errorCode };
};

export const cleanMask = (text) => {
    let customText = text;
    if (text) {
        if (customText.indexOf('-') > -1) {
            customText = customText.split('-').join('');
        }
        if (customText.indexOf('(') > -1) {
            customText = customText.split('(').join('');
        }
        if (customText.indexOf(')') > -1) {
            customText = customText.split(')').join('');
        }
        if (customText.indexOf('+') > -1) {
            customText = customText.split('+').join('');
        }
        if (customText.indexOf(' ') > -1) {
            customText = customText.split(' ').join('');
        }
    }
    return customText;
};

export function checkIfMerchantOrClientChanged(formData) {
    try {
        const { visitingMerchant, visitingClient } = getVisitingObject();
        if (formData && formData.merchantId && typeof formData.merchantId === 'string' && visitingMerchant?._id) {
            if (formData.merchantId !== visitingMerchant._id) {
                window.location.href = '/home';
                return;
            }
        }
        if (formData && formData.merchantId && isObject(formData.merchantId) && visitingMerchant?._id) {
            if (formData.merchantId?._id !== visitingMerchant._id) {
                window.location.href = '/home';
                return;
            }
        }

        if (formData && formData.clientId && typeof formData.clientId === 'string' && visitingClient?._id) {
            if (formData.clientId !== visitingClient._id) {
                // return history.push(PATHS.HOME.DEFAULT);
                window.location.href = '/home';
                return;
            }
        }

        if (formData && formData.clientId && isObject(formData.clientId) && visitingClient?._id) {
            if (formData.clientId?._id !== visitingClient._id) {
                window.location.href = '/home';
                return;
            }
        }
    } catch (e) {
        console.error();
    }
}

export function truncateString(str, n) {
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
}

export function sortObject(obj) {
    return Object.keys(obj)
        .sort()
        .reduce(function (result, key) {
            result[key] = obj[key];
            return result;
        }, {});
}

export function checkValidMongoId(id) {
    const checkForValidMongoDbID = new RegExp('^[0-9a-fA-F]{24}$');
    if (checkForValidMongoDbID.test(id)) {
        return true;
    }
    return false;
}

/**
 * Get Current Page URL with base url
 * @returns - page url
 */
export function getCurrentPageUrl() {
    let fullUrl = window.location.href;
    fullUrl = fullUrl.replace(window.location.origin, '');
    return fullUrl;
}

/**
 * Capitalizes the first letter of each word in the input string, excluding non-letter characters.
 *
 * @param {string} str - The input string to capitalize.
 * @returns {string} The capitalized string.
 */

export function capitalizeWords(str) {
    if (!str) {
        return str;
    }
    if (str === '') {
        return '';
    }
    const words = str.split(' ');
    try {
        for (let i = 0; i < words.length; i += 1) {
            if (words[i].length > 0) {
                words[i] = words[i][0].toUpperCase() + words[i].substring(1);
            }
        }
    } catch (error) {
        console.log(words);
    }
    return words.join(' ');
}

/**
 * Generates a unique random color.
 *
 * @param {string[]} existingColors - An array of existing colors to validate against.
 * @returns {string} A unique random color in hexadecimal format (#rrggbb).
 */
export function getRandomColor(existingColors = []) {
    // Generate a random color and check if it's already in use
    let color;
    do {
        color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    } while (existingColors.includes(color));

    // Return the generated color
    return color;
}
