import { isObject } from 'utils/helpers';

export const getRelevantBinLocationsBasedOnStocks = (stocks = [], orderItem) => {
    const productMasterDataId =
        orderItem.productMasterDataId && isObject(orderItem.productMasterDataId)
            ? orderItem.productMasterDataId._id
            : orderItem.productMasterDataId || null;
    // Get Only Relevant Stocks
    const allStocks = stocks.filter((x) => x.productMasterDataId === productMasterDataId);
    // Return Values
    const selectedBinLocationsArray = []; // Selected Bin Locations Array
    let selectedBinLocationsString = ''; // concatenated Bin Locations
    let orderItemStockStatus = 'Not Available'; // Order Item Stock Availability Status

    let modifiedAllUsableStocks = []; // All Usable Stock In Order
    let remainingQty = 0; // Remaining Qty
    let totalAvailableQty = 0; // Total Available Qty

    let isStocksAvailable = false; // Final Output Is Stock Available
    const selectedStocks = []; // Selected Stocks

    // Order Item Relevant Data
    let productMasterData;
    let takeAwayMethod = null;

    try {
        const requiredQty = orderItem.quantity;

        // Check For Takeaway Method
        if (orderItem.productMasterDataId && isObject(orderItem.productMasterDataId)) {
            productMasterData = orderItem.productMasterDataId;
            takeAwayMethod = productMasterData.takeawayMethod;
        }

        if (productMasterData && takeAwayMethod) {
            let sortedStocksByDate = [];

            // First In First Out
            if (takeAwayMethod === 'FIFO') {
                sortedStocksByDate = allStocks.sort(
                    (a, b) => (a.dateReceived > b.dateReceived) - (a.dateReceived < b.dateReceived)
                );
            }

            // First Expiry First Out
            if (takeAwayMethod === 'FEFO') {
                sortedStocksByDate = allStocks.sort(
                    (a, b) =>
                        Math.abs(Date.now() - new Date(a.expiryDate)) - Math.abs(Date.now() - new Date(b.expiryDate))
                );
            }

            let isStockQuantityMet = false; // If Stock Thats needed has met
            let quantityMetTotal = 0; // Total Quantity thats met,  during process will change
            let requiredRemainingQty = requiredQty;
            modifiedAllUsableStocks = sortedStocksByDate.map((x) => {
                const stock = x;
                // Increment Total Available QTY
                if (stock.quantity) {
                    totalAvailableQty += stock.quantity;
                }

                // If Required Qty is Met
                if (quantityMetTotal === requiredQty) {
                    isStockQuantityMet = true;
                }

                if (stock.quantity === 0) {
                    return stock;
                }

                // If Stock Qty Not Met and And not equals to required
                if (!isStockQuantityMet && requiredRemainingQty > 0) {
                    // If Stock Has Available QTY Greater than Required QTY
                    if (stock.quantity >= requiredRemainingQty) {
                        selectedStocks.push({
                            stockId: stock._id,
                            availableQuantity: stock.quantity,
                            selectedQuantity: requiredRemainingQty,
                            binLocationReferenceNumber: stock.binLocationReferenceNumber,
                            binLocationId: stock.binLocationId,
                        });
                        stock.quantity -= requiredRemainingQty;
                        quantityMetTotal += requiredRemainingQty;
                        requiredRemainingQty -= requiredRemainingQty;
                        return stock;
                    }

                    // If Stock has Greater than 0 use everything it got
                    if (stock.quantity > 0) {
                        const selectedQty =
                            stock.quantity < requiredRemainingQty
                                ? stock.quantity
                                : requiredRemainingQty - stock.quantity;

                        selectedStocks.push({
                            stockId: stock._id,
                            availableQuantity: stock.quantity,
                            selectedQuantity: selectedQty,
                            binLocationReferenceNumber: stock.binLocationReferenceNumber,
                            binLocationId: stock.binLocationId,
                        });
                        stock.quantity -= selectedQty;
                        quantityMetTotal += selectedQty;
                        requiredRemainingQty -= selectedQty;
                        return stock;
                    }
                }
                return stock;
            });

            // Found Stock Quantity && Quantity Taken From Bin Location should meet Required Qty
            if (quantityMetTotal === requiredQty) {
                orderItemStockStatus = 'Available';
                isStocksAvailable = true;
            } else {
                orderItemStockStatus = 'Not Available';
                isStocksAvailable = false;
            }

            // Append Required Values
            selectedStocks.map((x, i) => {
                selectedBinLocationsString = selectedBinLocationsString.concat(
                    `${x.binLocationReferenceNumber}${i === selectedStocks.length - 1 ? '' : ', '} `
                );
                selectedBinLocationsArray.push(x.binLocationId);
                return x;
            });

            // Calculate Remaining Qty
            remainingQty = totalAvailableQty - quantityMetTotal;
        }
    } catch (e) {
        console.log(e);
    }

    // If Required Stock Quantity Not Met Then Don't allow to show Bin Locations
    if (!isStocksAvailable) {
        // selectedBinLocationsArray = [];
        // selectedStocks = [];
        // selectedBinLocationsString = '';
        remainingQty = totalAvailableQty;
    }

    return {
        selectedBinLocationsArray,
        selectedBinLocationsString,
        isStocksAvailable,
        selectedStocks,
        orderItemStockStatus,
        modifiedAllUsableStocks,
        remainingQty,
        totalAvailableQty,
    };
};
