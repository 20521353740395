import React from 'react';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const MyMapComponent = compose(
    withProps({
        googleMapURL:
            'hsttp://maps.googleapis.com/maps/api/js?key=AIzaSyBxr0J6fFAla-aWT_qKdbAQqu0pwV0zHmc&libraries=places',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) => {
    const onMarkerDragEnd = (coord, index) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        if (props.getMarkerLocation) {
            props.getMarkerLocation({ lat, lng });
        }
    };

    return (
        <GoogleMap defaultZoom={18} defaultCenter={props.defaultCenter || { lat: -34.397, lng: 150.644 }}>
            {props.isMarkerShown && (
                <Marker
                    onDragEnd={onMarkerDragEnd}
                    draggable
                    position={props.defaultCenter || { lat: -34.397, lng: 150.644 }}
                />
            )}
        </GoogleMap>
    );
});

export default MyMapComponent;
