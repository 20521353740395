import React from 'react';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap/es';
import '../../styles/index.scss';

// #region assets | components
import { RoutePlanEntityIcon } from 'components/Icons';

// #region other imports
import PATHS from 'routes/paths';

// #endregion constants
import { FormHeaderPropTypes } from '../../../../constants';

/**
 * This modal contains the  main header of the route planing form and map grids
 * @param {FormHeaderPropTypes} props
 */
function RoutePlaningPageFormHeader() {
    const history = useHistory();

    /*
     * Redirect function to the route planing entries list view page
     */
    const handleOnClickRoutePlanningEntries = () => {
        history.push({
            pathname: `/${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_ENTRIES}`,
        });
    };
    return (
        <div className="route-planning__form-header pl-3">
            <Row className="flex align-items-center align-content-center">
                <Col className="header-text">Select the Delivery Plan</Col>
                <Col md="auto">
                    <div className="mr-4">
                        <Row className="flex mt-2 pl-2">
                            <div className="flex justify-content-center p-2 ml-2">
                                <Row>
                                    <span className="dot-theme_1"> </span>
                                    <p className="dot__badge-text ml-3 font-size-12">Pick Up Locations</p>
                                </Row>
                            </div>
                            <div className="flex justify-content-center p-2 ml-4">
                                <Row>
                                    <span className="dot-theme_2"> </span>
                                    <p className="dot__badge-text ml-3 font-size-12">Delivery Locations</p>
                                </Row>
                            </div>
                        </Row>
                    </div>
                </Col>

                <Col md="2.5" className="pr-2 mr-4">
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="scootix-btn-radius"
                        style={{ borderRadius: 10 }}
                        onClick={() => handleOnClickRoutePlanningEntries()}
                    >
                        <div className="pr-1">
                            <RoutePlanEntityIcon />
                        </div>
                        Route Planning Entries
                    </motion.div>
                </Col>
            </Row>
        </div>
    );
}

RoutePlaningPageFormHeader.propTypes = FormHeaderPropTypes;

export default RoutePlaningPageFormHeader;
