/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Container, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';
import CheckCircleIcon from 'components/Icons/Check-Circle';
import CrossCircleIcon from 'components/Icons/CrossCircle';

/** This modal prompts the user for the password before disabling an API Key */
function AuthValueModal(props) {
    const { show, handleToggle, handleRetrieveKey, handleUpdateAuthValue } = props;

    /** State for showing whether the submit operation is in progress */
    const [loading, setLoading] = useState(false);
    /** State for storing entered form data */
    const [formData, setFormData] = useState({ password: '' });
    /** State for storing retrieved API key */
    const [apiKey, setApiKey] = useState(null);

    const handleModalPasswordSubmit = async () => {
        setLoading(true);
        const response = await handleRetrieveKey(formData);
        if (!('error' in response)) {
            setApiKey(response.apiKey);
            setFormData({ apiKey: response.apiKey });
        }
        setLoading(false);
    };

    const handleModalUpdateAuthValue = async () => {
        setLoading(true);
        const response = await handleUpdateAuthValue({ newValue: formData.apiKey, password: formData.password });
        if (!('error' in response)) {
            handleModalToggle();
        }
        setLoading(false);
    };

    const handleModalToggle = () => {
        resetForm();
        handleToggle();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === apiKey) {
            setApiKey(value);
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const resetForm = () => {
        setFormData({ password: '' });
        setApiKey(null);
        setLoading(false);
    };

    return (
        <DeveloperModal header="View and Update Your API Key" show={show} handleToggle={handleModalToggle}>
            <Container>
                {apiKey ? (
                    <AvForm
                        className="needs-validation default-form"
                        noValidate
                        onValidSubmit={handleModalUpdateAuthValue}
                    >
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="apiKey" className="text-muted">
                                        Value
                                    </Label>
                                    <AvField
                                        name="apiKey"
                                        id="apiKey"
                                        type="text"
                                        value={formData.apiKey}
                                        className="form-control"
                                        errorMessage="Required"
                                        onChange={handleInputChange}
                                        validate={{ required: { value: true } }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Alert color="warning" style={{ borderRadius: 8 }}>
                                    Please enter your password to update the API key.
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AvField
                                    name="password"
                                    id="password"
                                    type="password"
                                    value={formData.password ? formData.password : ''}
                                    autocomplete="off"
                                    className="form-control"
                                    errorMessage="Required"
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="auto">
                                <Button disabled={loading} style={{ borderRadius: 10 }} className="scootix-btn-radius">
                                    {loading ? (
                                        <Spinner size="sm" color="warning" className="spinner-white mx-1" />
                                    ) : (
                                        <CheckCircleIcon color="#FFF" />
                                    )}
                                    &nbsp;Update and Close
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    disabled={loading}
                                    style={{ borderRadius: 10 }}
                                    className="scootix-btn-radius"
                                    onClick={handleModalToggle}
                                >
                                    <CrossCircleIcon color="#FFF" />
                                    &nbsp;Close
                                </Button>
                            </Col>
                        </Row>
                    </AvForm>
                ) : (
                    <AvForm
                        className="needs-validation default-form"
                        noValidate
                        onValidSubmit={handleModalPasswordSubmit}
                    >
                        <Row>
                            <Col>
                                <Alert color="warning" style={{ borderRadius: 8 }}>
                                    Please enter your password to view the current API key.
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AvField
                                    name="password"
                                    type="password"
                                    value={formData.password}
                                    className="form-control"
                                    errorMessage="Required"
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="auto">
                                <Button disabled={loading} style={{ borderRadius: 10 }} className="scootix-btn-radius">
                                    {loading ? (
                                        <Spinner size="sm" color="warning" className="spinner-white mx-1" />
                                    ) : (
                                        <CheckCircleIcon color="#FFF" />
                                    )}
                                    &nbsp;Submit
                                </Button>
                            </Col>
                        </Row>
                    </AvForm>
                )}
            </Container>
        </DeveloperModal>
    );
}

AuthValueModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleRetrieveKey: PropTypes.func.isRequired,
    handleUpdateAuthValue: PropTypes.func.isRequired,
};

export default AuthValueModal;
