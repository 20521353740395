/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import CONFIG from 'app-config';
import { Alert } from 'reactstrap';

function UnderMaintenanceHeader() {
    return (
        <>
            {CONFIG.IS_MAINTENANCE && (
                <div className="maintenance-container">
                    <Alert color="danger">
                        <div className="text-center d-flex row justify-content-center">
                            APPLICATION UNDER MAINTENANCE {'  '}{' '}
                            {/* <div>
                                <i
                                    className="fa fa-info-circle ml-3 pointer"
                                    onCLick={() => handleClickUnderMaintenance()}
                                />
                            </div> */}
                        </div>
                    </Alert>
                </div>
            )}
        </>
    );
}

UnderMaintenanceHeader.propTypes = {};

export default UnderMaintenanceHeader;
