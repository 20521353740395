/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { motion } from 'framer-motion';
import { Row, Col, Card, CardBody, Spinner, Label, DropdownItem, FormGroup, Input } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';

// #region components | assets
import { Printer } from 'components/Icons';
import { Select } from 'components';

// #region other imports
import { PRINT_TYPES } from 'constants/constants';
import { SubTitle } from 'styles';
import { batchPrintSizes } from 'constants/options';

// #region utils
import { reactSelectCustomStyles } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #endregion components
import { renderDeliverIdColWidth, renderMerchantOrderNumberColWidth } from '../utils';

export default function FormBody(props) {
    const {
        formData,
        allDeliveryIds,
        handleSubmit,
        formLoading,
        handleCheckChangeFilter,
        printType,
        selectedDeliveryIds,
        handleSelectChange,
        submitted,
        validated,
        deliveryIdLoading,
        allPrinters,
        handleInputChange,
        selectedMerchantId,
        merchants,
        visitingMerchant,
        selectedDeliveryReferenceNumber,
        selectedMerchantOrderNo,
        selectedPrinter,
        selectedPrintSize,
    } = props;

    return (
        <>
            <AvForm className="needs-validation default-form" onValidSubmit={handleSubmit}>
                <fieldset disabled={formLoading}>
                    <Card style={{ borderRadius: 10, paddingBottom: 50 }}>
                        <CardBody className="p-4" style={{}}>
                            <Row className="pl-2 mb-3">
                                <SubTitle>Quick Print</SubTitle>
                            </Row>
                            <DropdownItem divider />
                            <Row className="mt-3" style={{ paddingLeft: 30 }}>
                                <div className="">
                                    <Input
                                        onChange={handleCheckChangeFilter}
                                        type="checkbox"
                                        checked={formData.isNotAssigned}
                                        defaultChecked={formData.isNotAssigned}
                                        id="isNotAssigned"
                                    />
                                    <Label htmlFor="isNotAssigned" className="form-check-label">
                                        Not Assigned
                                    </Label>
                                </div>
                                <div className="ml-5">
                                    <Input
                                        onChange={handleCheckChangeFilter}
                                        type="checkbox"
                                        checked={formData.isPickupPending}
                                        defaultChecked={formData.isPickupPending}
                                        id="isPickupPending"
                                    />
                                    <Label htmlFor="isPickupPending" className="form-check-label">
                                        Pickup Pending
                                    </Label>
                                </div>
                            </Row>
                            <Row className="mt-4">
                                <Col md={3}>
                                    <FormGroup>
                                        <Label htmlFor="merchantId">Merchant</Label>
                                        <Select
                                            value={selectedMerchantId}
                                            isClearable
                                            options={merchants}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'merchantId')}
                                            submitted={submitted}
                                            validate={{ required: { value: true } }}
                                            isDisabled={visitingMerchant?._id}
                                        />
                                    </FormGroup>
                                </Col>
                                {printType === PRINT_TYPES.MULTIPLE && (
                                    <>
                                        <Col md={renderDeliverIdColWidth(selectedDeliveryIds.length)}>
                                            <FormGroup>
                                                <Label htmlFor="deliveryReferenceNumber">Delivery ID</Label>
                                                <Select
                                                    value={selectedDeliveryIds.map((val) => ({
                                                        ...val,
                                                        label: val.referenceNumber,
                                                    }))}
                                                    options={allDeliveryIds.map((val) => ({
                                                        ...val,
                                                        label: val.referenceNumber,
                                                    }))}
                                                    isMulti
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) =>
                                                        handleSelectChange(event, 'deliveryReferenceNumber')
                                                    }
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                    isClearable
                                                    isLoading={deliveryIdLoading}
                                                    maxMenuHeight={150}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={renderMerchantOrderNumberColWidth(selectedDeliveryIds.length)}>
                                            <FormGroup>
                                                <Label htmlFor="merchantOrderNo">Merchant Order Number</Label>
                                                <Select
                                                    value={selectedDeliveryIds.map((val) => ({
                                                        ...val,
                                                        label: val.merchantOrderNo,
                                                    }))}
                                                    options={allDeliveryIds.map((val) => ({
                                                        ...val,
                                                        label: val.merchantOrderNo,
                                                    }))}
                                                    isMulti
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'merchantOrderNo')}
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                    isClearable
                                                    isLoading={deliveryIdLoading}
                                                    maxMenuHeight={150}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </>
                                )}
                                {printType === PRINT_TYPES.SINGLE && (
                                    <>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label htmlFor="deliveryReferenceNumber">Delivery ID</Label>
                                                <Select
                                                    value={selectedDeliveryReferenceNumber}
                                                    options={allDeliveryIds.map((val) => ({
                                                        ...val,
                                                        label: val.referenceNumber,
                                                    }))}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) =>
                                                        handleSelectChange(event, 'deliveryReferenceNumber')
                                                    }
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                    isClearable
                                                    isLoading={deliveryIdLoading}
                                                    maxMenuHeight={150}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label htmlFor="merchantOrderNo">Merchant Order Number</Label>
                                                <Select
                                                    value={selectedMerchantOrderNo}
                                                    options={allDeliveryIds.map((val) => ({
                                                        ...val,
                                                        label: val.merchantOrderNo,
                                                    }))}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'merchantOrderNo')}
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                    isClearable
                                                    isLoading={deliveryIdLoading}
                                                    maxMenuHeight={150}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </>
                                )}
                                <Col md={3}>
                                    <FormGroup>
                                        <Label htmlFor="printer">Printer</Label>
                                        <Select
                                            value={selectedPrinter}
                                            options={allPrinters}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'printer')}
                                            submitted={submitted}
                                            required
                                            validated={validated}
                                            default={selectedPrinter}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label htmlFor="printSize">Print Size</Label>
                                        <Select
                                            value={selectedPrintSize}
                                            options={batchPrintSizes
                                                .filter((x) => x.printerType === formData.printer)
                                                .map((val) => ({ ...val, value: val.key }))}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'printSize')}
                                            submitted={submitted}
                                            required
                                            validated={validated}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                {/* {printType === PRINT_TYPES.SINGLE && ( */}
                                <Col md={2}>
                                    <FormGroup>
                                        <Label htmlFor="noOfCopies">Copies</Label>
                                        <AvField
                                            name="noOfCopies"
                                            placeholder=""
                                            type="number"
                                            errorMessage="No of copies should be between 1 and 20"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="noOfCopies"
                                            value={formData.noOfCopies}
                                            onChange={handleInputChange}
                                            min={1}
                                            max={20}
                                        />
                                    </FormGroup>
                                </Col>
                                {/* )} */}
                            </Row>
                            <br />
                            <Row style={{ justifyContent: 'flex-start' }}>
                                {hasAccess(PERMISSIONS.LABEL_PRINT_SHIPPING_LABEL, ['Print']) && (
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                        type="submit"
                                        disabled={formLoading}
                                        style={{ borderRadius: 30 }}
                                    >
                                        {formLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <Printer />
                                                <span className="h6">Print</span>
                                            </>
                                        )}
                                    </motion.button>
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                </fieldset>
            </AvForm>
        </>
    );
}

FormBody.propTypes = {
    formData: PropTypes.object,
    allDeliveryIds: PropTypes.array,
    handleSubmit: PropTypes.func,
    formLoading: PropTypes.bool,
    handleCheckChangeFilter: PropTypes.func,
    printType: PropTypes.string,
    selectedDeliveryIds: PropTypes.array,
    handleSelectChange: PropTypes.func,
    submitted: PropTypes.bool,
    validated: PropTypes.bool,
    deliveryIdLoading: PropTypes.bool,
    allPrinters: PropTypes.array,
    handleInputChange: PropTypes.func,
    selectedMerchantId: PropTypes.func,
    merchants: PropTypes.array,
    visitingMerchant: PropTypes.func,
    selectedDeliveryReferenceNumber: PropTypes.func,
    selectedMerchantOrderNo: PropTypes.func,
    selectedPrinter: PropTypes.func,
    selectedPrintSize: PropTypes.func,
};
