/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Card, CardBody, Col, Container, Row, FormGroup, Label, Input, Alert } from 'reactstrap';

// #region assets | components
import { BackdropLoader, CustomToast } from 'components';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #endRegions imports
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import CustomizableButton from '../../Api-Service/Dashboard/components/CustomizableButton';
import updateIcon from '../../../../../assets/images/developer/updateIcon.svg';
import { validateForm } from './utils';
import { updateDeveloperContactsService } from '../services';

const DeveloperContacts = () => {
    const [formData, setFormData] = useState({});

    const [formLoading, setFormLoading] = useState(false);

    const [loaded, setLoaded] = useState(false);

    const apiPartnerData = useSelector((state) => state.apiPartnerData);

    useEffect(() => {
        if (apiPartnerData?.merchantRecordData) {
            setLoaded(true);
            setFormData((prev) => ({ ...prev, ...apiPartnerData.merchantRecordData.payload }));
        } else {
            setLoaded(false);
        }
    }, [apiPartnerData?.merchantRecordData]);

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value,
        });
    };

    const handleSubmit = async (event) => {
        event.persist();
        const formValidation = validateForm(formData);
        if (!formValidation.isFormValid) {
            toast.custom((t) => <CustomToast text={formValidation.message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);
        const payload = { ...formData };
        try {
            const { data } = await updateDeveloperContactsService(payload);
            if (data) {
                toast.custom(
                    (t) => <CustomToast text="Successfully Updated the Developer Information" t={t} type="success" />,
                    {
                        position: 'top-right',
                    }
                );
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setFormLoading(false);
    };

    const handleCheckChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.checked,
        });
    };

    return (
        <Container fluid>
            <Card style={{ borderRadius: 15, marginTop: 10 }}>
                {loaded ? (
                    <CardBody style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                        <div
                            style={{
                                backgroundColor: '#E7E4E4',
                                borderTopRightRadius: 15,
                                borderTopLeftRadius: 15,
                                minHeight: 20,
                                padding: 15,
                                fontFamily: 'inter',
                                alignItems: 'center',
                            }}
                        >
                            <div className="font-weight-bold font-size-16 ml-3" style={{ color: '#3A3937' }}>
                                Developer Contact Details
                            </div>
                        </div>
                        <AvForm
                            className="needs-validation default-form"
                            onValidSubmit={handleSubmit}
                            id="developerContactsForm"
                            autoComplete="off"
                        >
                            <Row
                                style={{
                                    marginTop: 10,
                                    marginLeft: 30,
                                    marginRight: 30,
                                    justifyContent: 'space-between',
                                }}
                            >
                                <input type="hidden" value="something" />
                                <fieldset
                                    disabled={formLoading || hasAccess(PERMISSIONS.DEVELOPER_CONTACT, ['Edit'])}
                                    style={{ width: '100%' }}
                                >
                                    <Row>
                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <FormGroup>
                                                <Label htmlFor="developerName">Developer Contact Name</Label>
                                                <AvField
                                                    name="developerName"
                                                    type="text"
                                                    errorMessage="Enter the name of the developer"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="developerName"
                                                    value={formData.developerName || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                    style={{ backgroundColor: '#FCFCFC' }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <FormGroup>
                                                <Label htmlFor="developerEmail">Developer Email Address</Label>
                                                <AvField
                                                    name="developerEmail"
                                                    type="email"
                                                    errorMessage="Enter a valid email address"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="developerEmail"
                                                    value={formData.developerEmail || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                    style={{ backgroundColor: '#FCFCFC' }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <FormGroup>
                                                <Label htmlFor="developerContactNumber">Developer Contact Number</Label>
                                                <AvField
                                                    name="developerContactNumber"
                                                    type="text"
                                                    id="developerContactNumber"
                                                    errorMessage="Enter a valid contact number"
                                                    className="form-control"
                                                    value={formData.developerContactNumber || ''}
                                                    onChange={handleInputChange}
                                                    validate={{ required: { value: true } }}
                                                    mask="+60 99-999 99999"
                                                    maskChar=""
                                                    tag={[Input, InputMask]}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <FormGroup>
                                                <Label htmlFor="developerWhatsappNumber">
                                                    Developer WhatsApp Contact Number
                                                </Label>
                                                <AvField
                                                    name="developerWhatsappNumber"
                                                    type="text"
                                                    id="developerWhatsappNumber"
                                                    errorMessage="Enter a valid contact number"
                                                    className="form-control"
                                                    value={formData.developerWhatsappNumber || ''}
                                                    onChange={handleInputChange}
                                                    validate={{ required: { value: true } }}
                                                    mask="+60 99-999 99999"
                                                    maskChar=""
                                                    tag={[Input, InputMask]}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div className="form-check mb-3">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        onChange={handleCheckChange}
                                                        checked={formData.isWhatsappNotificationEnabled}
                                                        defaultChecked={formData.isWhatsappNotificationEnabled}
                                                        id="isWhatsappNotificationEnabled"
                                                        disabled={!formData.developerWhatsappNumber}
                                                    />
                                                    <Label
                                                        className="form-check-label"
                                                        htmlFor="primaryWhatsappNotificationCheck"
                                                    >
                                                        WhatsApp Notification
                                                    </Label>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </fieldset>
                            </Row>
                            <Row style={{ marginLeft: 20, marginRight: 20 }}>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <Alert color="warning">
                                        We send developer notifications to the primary contact by default.
                                    </Alert>
                                </Col>
                            </Row>

                            <div
                                className="font-weight-bold font-size-14"
                                style={{ color: '#807F7D', marginLeft: 30, marginRight: 30, marginTop: 30 }}
                            >
                                Secondary Developer Contact
                                <hr style={{ color: '#807F7D', borderWidth: 1 }} />
                            </div>
                            <Row
                                style={{
                                    marginTop: 10,
                                    marginLeft: 30,
                                    marginRight: 30,
                                    justifyContent: 'space-between',
                                }}
                            >
                                <input autoComplete="off" type="hidden" value="something" />
                                <fieldset autoComplete="off" disabled={formLoading} style={{ width: '100%' }}>
                                    <Row>
                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <FormGroup>
                                                <Label htmlFor="secondaryDeveloperName">Developer Contact Name</Label>
                                                <AvField
                                                    name="secondaryDeveloperName"
                                                    type="text"
                                                    errorMessage="Enter the name of the developer"
                                                    className="form-control"
                                                    id="secondaryDeveloperName"
                                                    value={formData.secondaryDeveloperName || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                    style={{ backgroundColor: '#FCFCFC' }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <FormGroup>
                                                <Label htmlFor="secondaryDeveloperEmail">Developer Email Address</Label>
                                                <AvField
                                                    name="secondaryDeveloperEmail"
                                                    type="email"
                                                    errorMessage="Enter a valid email address"
                                                    className="form-control"
                                                    validate={
                                                        formData.secondaryDeveloperName
                                                            ? { required: { value: true } }
                                                            : {}
                                                    }
                                                    id="secondaryDeveloperEmail"
                                                    value={formData.secondaryDeveloperEmail || ''}
                                                    onChange={handleInputChange}
                                                    disabled={formData.isApproved}
                                                    autoComplete="off"
                                                    style={{ backgroundColor: '#FCFCFC' }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <FormGroup>
                                                <Label htmlFor="secondaryDeveloperContactNumber">
                                                    Developer Contact Number
                                                </Label>
                                                <AvField
                                                    name="secondaryDeveloperContactNumber"
                                                    type="text"
                                                    id="secondaryDeveloperContactNumber"
                                                    errorMessage="Enter a valid whatsapp number"
                                                    className="form-control"
                                                    value={formData.secondaryDeveloperContactNumber || ''}
                                                    onChange={handleInputChange}
                                                    mask="+60 99-999 99999"
                                                    maskChar=""
                                                    tag={[Input, InputMask]}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <FormGroup>
                                                <Label htmlFor="secondaryDeveloperWhatsappNumber">
                                                    Developer WhatsApp Contact Number
                                                </Label>
                                                <AvField
                                                    name="secondaryDeveloperWhatsappNumber"
                                                    type="text"
                                                    id="secondaryDeveloperWhatsappNumber"
                                                    errorMessage="Enter a valid whatsapp number"
                                                    className="form-control"
                                                    value={formData.secondaryDeveloperWhatsappNumber || ''}
                                                    onChange={handleInputChange}
                                                    mask="+60 99-999 99999"
                                                    maskChar=""
                                                    tag={[Input, InputMask]}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div className="form-check mb-3">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        onChange={handleCheckChange}
                                                        checked={formData.isSecondaryDevelopeEmailNotificationEnabled}
                                                        defaultChecked={
                                                            formData.isSecondaryDevelopeEmailNotificationEnabled
                                                        }
                                                        id="isSecondaryDevelopeEmailNotificationEnabled"
                                                        disabled={!formData.secondaryDeveloperEmail}
                                                    />
                                                    <Label
                                                        className="form-check-label"
                                                        htmlFor="secondaryEmailNotificationCheck"
                                                    >
                                                        E-Mail Notification
                                                    </Label>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div className="form-check mb-3">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        onChange={handleCheckChange}
                                                        checked={
                                                            formData.isSecondaryDeveloperWhatsappNotificationEnabled
                                                        }
                                                        defaultChecked={
                                                            formData.isSecondaryDeveloperWhatsappNotificationEnabled
                                                        }
                                                        id="isSecondaryDeveloperWhatsappNotificationEnabled"
                                                        disabled={!formData.secondaryDeveloperWhatsappNumber}
                                                    />
                                                    <Label
                                                        className="form-check-label"
                                                        htmlFor="secondaryWhatsappNotificationCheck"
                                                    >
                                                        WhatsApp Notification
                                                    </Label>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </fieldset>
                            </Row>
                            <Row>
                                {hasAccess(PERMISSIONS.DEVELOPER_CONTACT, ['Edit']) && (
                                    <CustomizableButton
                                        buttonTitle="Update"
                                        icon={updateIcon}
                                        iconStyle={{ width: 24, height: 24 }}
                                        customStyle={{ marginLeft: 40 }}
                                        loading={formLoading}
                                    />
                                )}
                            </Row>
                        </AvForm>
                    </CardBody>
                ) : (
                    <BackdropLoader show opacity={0.8} />
                )}
            </Card>
        </Container>
    );
};

export default DeveloperContacts;
