/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { BackdropLoader, CustomToast, Select, TableCustom } from 'components';

// #region imports
import PATHS from 'routes/paths';

// #region services
import {
    getAllMerchantsService,
    getMerchantsByClientIdService,
    getMerchantLocationsByVisitingMerchantIdService,
} from 'services/merchant.service';
import { getAllMerchantLocationByMerchantId } from 'services/merchant-location.service';

// #region utils
import { getVisitingObject, hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import {
    apiErrorHandlerV2,
    getDateValue,
    getDefaultValueForSelect,
    isObject,
    reactSelectCustomStyles,
} from 'utils/helpers';

// #endregion imports
import {
    REPORT_OPTIONS,
    DELIVERY_TYPE_OPTIONS,
    initialDeliveryReportData,
    initialDeliverySummaryData,
} from './constants';
import { generateDeliveryReport } from '../../services';
import DeliveryCompletedTable from './containers/DeliveryCompletedTable';
import { buildTableHeaders, getStatus } from './utils';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Deliveries', link: PATHS.DELIVERY.DEFAULT },
    { title: 'Delivery Report', link: '#' },
];

const todayFrom = new Date();
const todayTo = new Date();

let initialFormValues = {
    fromDate: new Date(todayTo.setMonth(todayTo.getMonth() - 1)).toISOString(),
    toDate: todayFrom.toISOString(),
    reportType: 'Daily',
    report: 'created',
    deliveryType: DELIVERY_TYPE_OPTIONS[0].value,
};

/**
 * DeliveryReport Profile Page
 * @param {*} props
 * @returns {React.Component}
 */
function DeliveryReport() {
    const history = useHistory();

    const { visitingClient, visitingMerchant } = getVisitingObject();

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    // component state
    const [formLoading, setFormLoading] = useState(0);
    const [formData, setFormData] = useState(initialFormValues);
    const [merchants, setMerchants] = useState([]);
    const [merchantLocations, setMerchantLocations] = useState([]);

    const [submitted] = useState(false);
    const [validated] = useState(false);

    const [backdropLoading, setBackdropLoading] = useState(0);

    const [deliveryReportData, setDeliveryReportData] = useState(initialDeliveryReportData);
    const [deliverySummaryData, setDeliverySummaryData] = useState(initialDeliverySummaryData);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    useEffect(() => {
        if (visitingMerchant && visitingMerchant._id) {
            const id = visitingMerchant._id;
            loadAllMerchantLocationsVisitingMerchant(id);
        }
    }, []);

    useEffect(() => {
        if (formData.merchantId) {
            loadAllMerchantLocations(formData.merchantId);
        }
    }, [formData.merchantId]);

    // Merchant Location Handlers of visiting merchant
    const loadAllMerchantLocationsVisitingMerchant = async (id) => {
        await getMerchantLocationsByVisitingMerchantIdService(id).then((res) => {
            const { data } = res;
            if (data.docs) {
                setMerchantLocations(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.merchantLocationName || ''} - ${x.location || ''}`,
                        key: x._id,
                    }))
                );
            }
        });
    };

    // Merchant locations for selected form data
    const loadAllMerchantLocations = async (id) => {
        const { data } = await getAllMerchantLocationByMerchantId(id);
        if (data && data.docs) {
            setMerchantLocations(
                data.docs.map((x) => ({
                    ...x,
                    label: `${x.merchantLocationName || ''} - ${x.location || ''}`,
                    key: x._id,
                }))
            );
        }
    };

    // const loadAllMerchantLocationsByMerchantId = async (id) => {
    //     try {
    //         const { data } = await getAllMerchantLocationByMerchantId(id);
    //         if (data && data.docs) {
    //             setAllMerchantLocations(
    //                 data.docs.map((x) => ({
    //                     ...x,
    //                     label: `${x.merchantLocationName || ''} - ${x.location || ''}`,
    //                     key: x._id,
    //                 }))
    //             );
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const mapDocs = (docs) => {
        let allDocs = [];
        allDocs = docs.map((x) => ({
            ...x,
            merchantName: x.merchantId.name,
            formattedStatus: getStatus(x.status, x.riderId),
            riderName:
                isObject(x.riderId) && x.riderId?.referenceNumber
                    ? `${x.riderId.fullName || ''} ${x.riderId?.referenceNumber}`
                    : `Rider Not Assigned`,
            distance: x.distance ? `${x.distance} KM` : '',
        }));
        return allDocs;
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;

        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleSelectChange = (event, id) => {
        if (id === 'merchantId' && event) {
            loadAllMerchantLocations(event.value);
        }
        if (id === 'report' && event) {
            setFormData({
                ...formData,
                status: event.value === 'completed' ? 'Completed' : null,
                [id]: event ? event.value : null,
            });
            return;
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleGenerateReport = async () => {
        const postData = {};
        setBackdropLoading((x) => x + 1);

        postData.filters = formData;
        setFormLoading(true);

        const { tableHeaders } = buildTableHeaders(formData);

        try {
            const { data } = await generateDeliveryReport(postData);
            if (data.deliveryData) {
                const docs = mapDocs(data.deliveryData.docs);
                setDeliveryReportData({
                    ...deliveryReportData,
                    docs,
                });
                setDeliverySummaryData({
                    ...deliverySummaryData,
                    docs: data.tableData,
                    headers: tableHeaders,
                });
            }
        } catch (error) {
            const { message: exception } = apiErrorHandlerV2(error);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setBackdropLoading((x) => x - 1);
        setFormLoading(false);
    };

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    const getMerchantLocationSelect = (_id) => {
        const relevantMerchantLocation = merchantLocations.find((x) => x._id === _id);
        if (relevantMerchantLocation) {
            return `${relevantMerchantLocation.merchantLocationName || ''} - ${
                relevantMerchantLocation.location || ''
            }`;
        }
        return null;
    };

    const handleEditRow = (e, row) => {
        window.open(`${PATHS.DELIVERY.FORM}?type=edit&id=${row._id}`);
    };

    const handleViewRow = (e, row) => {
        history.push({
            pathname: `${PATHS.DELIVERY.FORM}`,
            search: `?type=view`,
            state: { deliveryParentData: row },
        });
    };

    const getSelectedReportOptions = (value) => {
        const relevantObject = REPORT_OPTIONS.find((x) => x.value === value);
        if (relevantObject) {
            return relevantObject.label;
        }
        return null;
    };

    // Selects Memos Handlers

    // ** Memos

    const selectedDeliveryType = useMemo(
        () => getDefaultValueForSelect(formData.deliveryType),
        [formData.deliveryType]
    );

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />

                    <Card style={{ borderRadius: 15, margin: 10, marginTop: -10 }}>
                        <CardBody style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                            <div
                                style={{
                                    backgroundColor: '#F9F7F7',
                                    borderTopRightRadius: 15,
                                    borderTopLeftRadius: 15,
                                    minHeight: 20,
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        padding: 23,
                                        paddingBottom: 0,
                                        color: '#807F7D',
                                        fontSize: 19,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    SELECT FILTERS FOR YOUR REPORT
                                </div>
                                <div
                                    style={{
                                        marginLeft: 23,
                                        paddingBottom: 10,
                                        color: '#807F7D',
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Report Generates based on the delivery created date and time
                                </div>
                            </div>
                            <AvForm
                                autoComplete="off"
                                className="needs-validation default-form"
                                noValidate
                                validated={validated}
                                id="deliveryReportForm"
                            >
                                <Row style={{ padding: 23 }}>
                                    <Col md="3">
                                        <FormGroup>
                                            <Label htmlFor="report">Report</Label>
                                            <Select
                                                value={getDefaultValueForSelect(
                                                    getSelectedReportOptions(formData.report)
                                                )}
                                                options={REPORT_OPTIONS}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'report')}
                                                submitted={submitted}
                                                required
                                                validated={validated}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <Label htmlFor="deliveryType">Delivery Type</Label>
                                            <Select
                                                value={selectedDeliveryType}
                                                options={DELIVERY_TYPE_OPTIONS}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'deliveryType')}
                                                submitted={submitted}
                                                validated={validated}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <Label htmlFor="fromDate">From Date</Label>
                                            <Input
                                                onChange={handleInputChange}
                                                type="datetime-local"
                                                className="form-control"
                                                value={getDateValue(formData.fromDate, 'datetime-local')}
                                                validate={{ required: { value: true } }}
                                                id="fromDate"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <Label htmlFor="toDate">To Date</Label>
                                            <Input
                                                onChange={handleInputChange}
                                                type="datetime-local"
                                                className="form-control"
                                                value={getDateValue(formData.toDate, 'datetime-local')}
                                                validate={{ required: { value: true } }}
                                                id="toDate"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <Label htmlFor="reportType">Report Type</Label>
                                            <Select
                                                value={getDefaultValueForSelect(formData.reportType)}
                                                options={[
                                                    { label: 'Daily', value: 'Daily' },
                                                    { label: 'Weekly', value: 'Weekly' },
                                                    { label: 'Monthly', value: 'Monthly' },
                                                ]}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'reportType')}
                                                submitted={submitted}
                                                validated={validated}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <Label htmlFor="merchantId">Merchant</Label>
                                            <Select
                                                value={getDefaultValueForSelect(getMerchantSelect(formData.merchantId))}
                                                options={merchants}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                submitted={submitted}
                                                validated={validated}
                                                isClearable
                                                isDisabled={!!visitingMerchant}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <Label htmlFor="merchantLocationId">Merchant Location</Label>
                                            <Select
                                                value={getDefaultValueForSelect(
                                                    getMerchantLocationSelect(formData.merchantLocationId)
                                                )}
                                                options={merchantLocations.map((x) => ({
                                                    label: x.label,
                                                    value: x.key,
                                                }))}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'merchantLocationId')}
                                                submitted={submitted}
                                                validated={validated}
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <Label htmlFor="status">Status</Label>
                                            <Select
                                                value={getDefaultValueForSelect(formData.status)}
                                                options={[
                                                    { label: 'New', value: 'New' },
                                                    { label: 'Pending', value: 'Pending' },
                                                    { label: 'Completed', value: 'Completed' },
                                                ]}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'status')}
                                                submitted={submitted}
                                                validated={validated}
                                                isClearable
                                                isDisabled={formData.report === 'completed'}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ justifyContent: 'flex-start' }}>
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 ml-4"
                                        onClick={handleGenerateReport}
                                        disabled={formLoading}
                                    >
                                        {formLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <i className="fas fa-save left-icon"></i>
                                                <span className="h6">Generate</span>
                                            </>
                                        )}
                                    </motion.button>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>

                    {formData.report === 'created' && (
                        <Card style={{ borderRadius: 15, margin: 10 }}>
                            <CardBody style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                <div
                                    style={{
                                        backgroundColor: '#F9F7F7',
                                        borderTopRightRadius: 15,
                                        borderTopLeftRadius: 15,
                                        minHeight: 20,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: 23,
                                            color: '#807F7D',
                                            fontSize: 19,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Deliveries Based on Delivery Creation Date
                                    </div>
                                </div>
                                <div>
                                    <Row>
                                        <TableCustom
                                            tableData={deliverySummaryData}
                                            // onPageChange={handlePageChange}
                                            // onFilter={handleFilter}
                                            isLazyLoading={true}
                                            handleView={handleViewRow}
                                            loading={formLoading}
                                            isExport
                                            type="deliverySummary"
                                            exportModalHeader="Export Delivery Summary"
                                            removeAction
                                            isFilter={false}
                                            removeId={false}
                                            isSearch={false}
                                        />
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    )}

                    {formData.report === 'completed' && (
                        <DeliveryCompletedTable
                            deliverySummaryData={deliverySummaryData}
                            formLoading={formLoading}
                            handleViewRow={handleViewRow}
                        />
                    )}

                    <Row>
                        <TableCustom
                            tableData={deliveryReportData}
                            // onPageChange={handlePageChange}
                            // onFilter={handleFilter}
                            isLazyLoading={true}
                            handleView={handleViewRow}
                            loading={formLoading}
                            isExport
                            type="deliveryReport"
                            exportModalHeader="Delivery Report"
                            handleEdit={handleEditRow}
                            showEdit={hasAccess(PERMISSIONS.DELIVERY, ['Edit'])}
                            showView={false}
                            isFilter={false}
                            removeId={false}
                            isSearch={false}
                        />
                    </Row>
                </Container>
            </div>
            <BackdropLoader show={backdropLoading} opacity={0.7} />
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

const HotDeliveryReport = hot(DeliveryReport);

export default connect(mapStateToProps, {})(HotDeliveryReport);
