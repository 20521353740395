/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

// #region hooks
import { useMerchantsService } from 'hooks/merchants';

// #endregion imports
import FormBody from './FormBody';

/**
 * Initial Form
 * @param {*} props
 * @returns {React.FunctionComponent}
 */
function InitialForm(props) {
    const { setFormData, formData, loadDeliveryListData } = props;

    const _isMounted = useRef(true);

    //  backend data
    const [merchants, setMerchants] = useState([]);

    // form state
    const [validated, setValidated] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // custom hooks
    const merchantServiceHook = useMerchantsService(_isMounted.current);

    useEffect(() => {
        if (merchantServiceHook.status === 'loaded') {
            setMerchants([...merchantServiceHook.data]);
        }
    }, [merchantServiceHook.status]);

    // unmount component
    useEffect(
        () => () => {
            _isMounted.current = false;
        },
        []
    );

    // Reset the merchant and delivery type filters
    useEffect(() => {
        if (!formData.merchantId && !formData.deliveryType) {
            handleSearch();
        }
    }, [formData?.merchantId || formData?.deliveryType]);

    /**
     * Handle Search Of Deliveries
     */
    const handleSearch = async () => {
        const newArgs = { ...formData, showLoading: true };
        setSubmitted(true);
        await loadDeliveryListData(newArgs);
        setSubmitted(false);
        setValidated(false);
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setFormData({
            ...formData,
            [id]: event?.value || null,
        });
    };

    // Form Selectors
    const getMerchantSelect = (_id) => {
        if (_id) {
            const relevantObj = merchants.find((x) => x._id === _id);
            if (relevantObj) {
                return { label: relevantObj.name, value: relevantObj._id };
            }
        }
        return null;
    };

    const commonProps = {
        formData,
        setFormData,
        merchants,
        getMerchantSelect,
        validated,
        submitted,
        handleSelectChange,
        handleSearch,
    };

    return (
        <>
            <FormBody {...commonProps} />
        </>
    );
}

InitialForm.propTypes = {
    setFormData: PropTypes.func,
    formData: PropTypes.object,
    handleSearch: PropTypes.func,
    filters: PropTypes.object,
    setFilters: PropTypes.func,
    loadDeliveryListData: PropTypes.func,
};

export default InitialForm;
