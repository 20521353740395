/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { Alert, Container, Row, Col, Card, CardHeader, CardBody, Label, FormGroup, Button, Spinner } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';

// #region components
import SendIcon from 'components/Icons/Send';
import { CustomToast } from 'components';

// #region other imports
import { DEVELOPMENT_PLAN_ICON_IMAGE } from 'definitions/assets';

// #region services
import { createDeveloperSupportRequest } from 'services/support.service';

// #region utils
import { getVisitingObject } from 'utils/checkAuth';
import { apiErrorHandlerV2 } from 'utils/helpers';

// #endregion imports
import { validateForm } from './utils';

let initialFormValues = {
    message: '',
};

function SandboxCreatedPage() {
    const _isMounted = useRef(true);

    const contactDeveloperSupportRef = useRef();

    const { visitingMerchant } = getVisitingObject();
    // !TODO: add docs
    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    // Form State
    // eslint-disable-next-line no-unused-vars
    const [submitted, setSubmitted] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);

    const [formLoading, setFormLoading] = useState(false);

    // !TODO: add docs
    useEffect(
        () => () => {
            _isMounted.current = false;
        },
        []
    );

    // !TODO: add docs
    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors?.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);

        try {
            const payload = {
                ...formData,
            };
            const { data } = await createDeveloperSupportRequest(payload);
            if (data) {
                toast.custom((t) => <CustomToast text="You're support ticket is created" t={t} type="success" />, {
                    position: 'top-right',
                });
                setFormData(initialFormValues);
            }
            // eslint-disable-next-line no-unused-expressions
            contactDeveloperSupportRef.current?.reset();
            setFormData(initialFormValues);
        } catch (e) {
            const { message: error } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={error} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setSubmitted(false);
        setValidated(false);
        setFormLoading(false);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Alert color="warning" className="p-4" style={{ border: '1px solid #ffaf59' }}>
                                <Row className="align-items-center">
                                    <Col xs="auto">
                                        <img
                                            src={DEVELOPMENT_PLAN_ICON_IMAGE}
                                            alt="development plan icon"
                                            className="mr-4"
                                        />
                                    </Col>
                                    Your sandbox account has been already created. API Service for the Production
                                    Environment will be enabled soon after we evaluate your sandbox integration.
                                    <br />
                                    Please use the following form to contact the development support.
                                </Row>
                            </Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="shadow default-card">
                                <CardHeader
                                    className="border-bottom pl-5"
                                    style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}
                                >
                                    Contact Developer Support
                                </CardHeader>
                                <CardBody className="pl-5">
                                    <AvForm
                                        onSubmit={handleSubmit}
                                        id="developerSupportForm"
                                        ref={contactDeveloperSupportRef}
                                    >
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="message">Your Message</Label>
                                                    <AvField
                                                        name="message"
                                                        type="textarea"
                                                        helpMessage="Maximum 2000 Words"
                                                        className="form-control"
                                                        value={formData.message || ''}
                                                        onChange={handleInputChange}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Required' },
                                                        }}
                                                        rows={5}
                                                        maxLength={2000}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button
                                                    className="form-delete-btn"
                                                    style={{ backgroundColor: '#f99922' }}
                                                    type="submit"
                                                >
                                                    {formLoading > 0 ? (
                                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                    ) : (
                                                        <>
                                                            <SendIcon color="#fff" />
                                                            &nbsp;Send a Message
                                                        </>
                                                    )}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

const HotSandboxCreatedPage = hot(SandboxCreatedPage);

export default connect(null, {})(HotSandboxCreatedPage);
