import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { Card, CardBody } from 'reactstrap';
import QRCode from 'react-qr-code';
import { motion } from 'framer-motion';

// #region utils
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #region other imports
import ScootixBW from '../../../assets/images/scootix-black&white.png';
import Fragile from '../../../assets/images/fragile.png';

import './shippingLabel.css';
import { centerElementInnerStyles } from 'styles';

function ShippingLabelViewCard(props) {
    const { packages, packageData = {}, index = 0, deliveryData } = props;

    const componentRef = useRef();
    const pageStyle = `
        @page {
        size: 61mm 89mm;
        }
        `;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: true,
        pageStyle,
    });

    return (
        <div>
            <Card style={{ display: 'inline-block', maxWidth: 300, borderColor: 'black', borderWidth: 2 }}>
                <CardBody>
                    <div ref={componentRef} className="print-card">
                        <div className="d-flex align-items-center line-1" style={{ ...centerElementInnerStyles }}>
                            <img src={ScootixBW} alt="" height="120" className="scootix-image" />
                            <div style={{ border: 2, borderColor: 'black' }}></div>
                            <div>
                                <span style={{ fontWeight: 'bolder' }} className="from">
                                    FROM
                                </span>
                                <p className="from-address">{packageData?.deliveryData?.merchantName || ''}</p>
                            </div>
                        </div>
                        <hr style={{ borderColor: 'black', borderWidth: 2 }} className="line-breaker" />
                        <div
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            className="line-2"
                        >
                            {packageData.deliveryData.isFragile && (
                                <img src={Fragile} alt="" height="60" style={{ marginLeft: 30, marginRight: 10 }} />
                            )}
                            <div>
                                <span style={{ fontWeight: 'bolder' }} className="to">
                                    TO
                                </span>
                                <p className="to-address" style={{ lineHeight: 1, marginBottom: 3 }}>{`${
                                    packageData.deliveryData.recipientName || 'No Recipient Given'
                                }, `}</p>
                                <p
                                    className="to-address"
                                    style={{ lineHeight: 1, marginBottom: 3 }}
                                >{`${packageData.deliveryData.deliveryAddress}, `}</p>
                                <p
                                    className="to-address"
                                    style={{ lineHeight: 1, marginBottom: 3 }}
                                >{`Postal Code: ${packageData.deliveryData.postalCode}, `}</p>
                                <p className="to-address" style={{ lineHeight: 1, marginBottom: 3 }}>{`TEL: ${
                                    packageData.deliveryData.recipientMobileNumber || 'No Contact Number Given'
                                } `}</p>
                            </div>
                        </div>
                        <hr style={{ borderColor: 'black', borderWidth: 2 }} className="line-breaker" />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            className="line-3"
                        >
                            <span className="order-reference-title">MERCHANT ORDER REFERENCE</span>
                            <p style={{ fontWeight: 'bolder' }} className="order-reference">
                                {packageData.deliveryData.merchantOrderNo ||
                                    packageData.deliveryData.orderReferenceNumber ||
                                    ''}
                            </p>
                        </div>
                        <hr style={{ borderColor: 'black', borderWidth: 2 }} className="line-breaker" />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            className="line-4"
                        >
                            <QRCode value={packageData._id} className="qr-code-normal" size={200} />
                            <QRCode value={packageData._id} className="qr-code-print" />
                        </div>

                        <div className="text-center mt-2 packages-length" style={{ fontWeight: 'bolder' }}>
                            {index + 1}/{packages.length}
                        </div>

                        <div className="text-center scootix-ref" style={{ fontWeight: 'bolder' }}>
                            {`SCOOTIX REF: ${
                                packageData.deliveryData.orderReferenceNumber ||
                                packageData.deliveryData.merchantOrderNo ||
                                ''
                            }`}
                        </div>
                    </div>
                </CardBody>
            </Card>
            {!deliveryData.isCompleted && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {hasAccess(PERMISSIONS.SHIPPING_LABELS, ['Print']) && (
                        <motion.div
                            style={{ width: 150, alignSelf: 'center' }}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                            onClick={handlePrint}
                        >
                            <i className="fas fa-print"></i> <span className="h6">Print</span>
                        </motion.div>
                    )}
                </div>
            )}
        </div>
    );
}

ShippingLabelViewCard.propTypes = {
    packages: PropTypes.array,
    packageData: PropTypes.object,
    index: PropTypes.number,
    deliveryData: PropTypes.object,
};

export default ShippingLabelViewCard;
