/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React from 'react';
import toast from 'react-hot-toast';

// #region components
import { CustomToast } from 'components';

/**
 * Custom Form Validation
 * Validates form before submitting to backend
 * @param {*} form
 * @returns
 */
export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Enter All Fields';

    const { fromDate, toDate } = form;

    if (!fromDate || !toDate) {
        toast.custom((t) => <CustomToast text="Select From & To Dates (Delivery Dates)" t={t} type="warning" />, {
            position: 'top-right',
        });
        return { isFormValid };
    }

    isFormValid = true;
    return { isFormValid, message };
};

/**
 * Builds the merchant name based on the provided deliveries.
 * If the merchant names are different, it returns "Multiple".
 * Otherwise, it returns the merchant name from the first delivery.
 * @param {Array} deliveries - The array of delivery objects.
 * @returns {string} - The built merchant name.
 */
export const buildMerchantName = (deliveries) => {
    const firstMerchantName = deliveries[0]?.merchantName;
    const hasDifferentMerchantName = deliveries.some((delivery) => delivery.merchantName !== firstMerchantName);
    const merchantName = hasDifferentMerchantName ? 'Multiple' : firstMerchantName || '';
    return merchantName;
};

/**
 * Create recipient column data by combining recipient name and delivery address
 * @param  docs - Deliveries
 * @returns updated deliveries
 */
export const mapDocs = (docs) => {
    let allDocs = [];
    // create recipient column data
    allDocs = docs.map((delivery) => ({
        ...delivery,
        recipientNameFormatted: `${delivery.recipientName || 'No Recipient Given'}, ${delivery.deliveryAddress || ''}`,
    }));
    return allDocs;
};

/**
 * Determines the payment status for a merchant's balance amount.
 * @returns {string} The payment status: 'Paid' if the balance amount is 0, 'Unpaid' otherwise.
 */
export const getMerchantPaymentStatus = (balanceAmount) => {
    if (balanceAmount === 0) {
        return 'Paid';
    }
    return 'Unpaid';
};
