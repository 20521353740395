/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import {
    Alert,
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row,
    Spinner,
} from 'reactstrap';

// #region assets | components
import { Breadcrumbs, TableCustom, CustomToast } from 'components';
import DisconnectIcon from 'components/Icons/Disconnect';
import ConnectIcon from 'components/Icons/Connect';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import CheckCircleIcon from 'components/Icons/Check-Circle';
import CopyIcon from 'components/Icons/Copy';
import LeftArrowIcon from 'components/Icons/LeftArrow';

// #region services
import {
    getApiKeyById,
    updateApiKeyById,
    enableApiKeyById,
    disableApiKeyById,
    showSecretKey,
} from 'services/partner-api.service';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #region imports
import PATHS from 'routes/paths';
import { TRANSACTION_REFERENCE } from 'constants/enums';

// #region services
import { getPartnerApiKeyTransactionsService } from 'services/transaction.service';

// #endregion imports
import { PERMISSIONS } from 'utils/constants';
import { hasAccess } from 'utils/checkAuth';
import { privateKeyEndpoints, publicKeyEndpoints, emptyFormValues } from '../constants';
import { renderEndpointCheckbox } from '../utils';
import ApiKeyTransactionDataModal from '../../Api-Portal/components/ApiKeyTransactionData';
import DisabledKeyDetailsModal from './components/DisabledKeyDetails';
import ViewSecretKeyModal from './components/ViewSecretKey';
import EnableApiKeyModal from '../../Api-Portal/components/EnableApiKey';
import DisableApiKeyModal from '../../Api-Portal/components/DisableApiKey';
import { mapApiKeyTransactions } from './utils';
import { transactionInitialValues, transactionsLogTableHeaders } from './constants';

function ManageApiKeyPage(props) {
    const history = useHistory();

    // Read the API key passed via props.location.state
    const { location } = props;
    const { state } = location;
    const { apiKey, merchantName } = state || {};
    /**
     * If apiKey or merchantName is missing, it means this page was probably
     * accessed directly using the URL, which should not be done.
     * If this is the case, redirect to the API Portal.
     */
    if (!apiKey || !merchantName) {
        history.push({
            pathname: `/${PATHS.DEVELOPER.API_PORTAL}`,
            state,
        });
    }

    /** Whether the API key data is being fetched */
    const [apiKeyDataLoading, setApiKeyDataLoading] = useState(false);
    /** Data for the transactions log table */
    const [transactionData, setTransactionData] = useState([]);
    /** Control whether to show the secret key or hide it */
    const [secretKey, setSecretKey] = useState(null);
    /**
     * Which modal to show from the table. `modal` can be 'transaction' modal, 'reason' modal, or 'password' modal,
     * or null to hide modals.
     */
    const [modalOptions, setModalOptions] = useState({ modal: null, data: {} });
    /**
     * Which service status modal to show. It can be either the 'enable' modal or 'disable' modal.
     */
    const [statusModalVisible, setStatusModalVisible] = useState(null);
    /** State for storing entered form data */
    const [formData, setFormData] = useState({ ...emptyFormValues, ...apiKey });
    /** State for storing the original unchanged API key's data */
    const [apiKeyFromDb, setApiKeyFromDb] = useState(apiKey || {});

    const breadcrumbItems = [
        { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
        { title: 'API Portal', link: `/${PATHS.DEVELOPER.API_PORTAL}` },
        { title: merchantName, link: '#' },
        { title: 'API Keys', link: '#' },
        { title: `Key for ${apiKey?.name}`, link: '#' },
    ];

    /** On page load, fetch the API key data from the backend */
    useEffect(() => {
        if (apiKey._id) {
            getApiKeyData(apiKey._id);
            loadApiKeyTransactions();
        }
    }, [apiKey]);

    // Side Effect to load transactions
    // works on an interval basis
    useEffect(() => {
        const interval = setInterval(() => {
            if (apiKey._id) {
                loadApiKeyTransactions();
            }
        }, 20000);
        return () => clearInterval(interval);
    }, [apiKey]);

    const loadApiKeyTransactions = () => {
        if (formData._id) {
            getPartnerApiKeyTransactionsService(formData._id).then((res) => {
                if (res) {
                    const { data } = res;
                    if (data && Array.isArray(data)) {
                        setTransactionData(data);
                    }
                }
            });
        }
    };

    /** Function to fetch API key data from the backend */
    const getApiKeyData = async (id) => {
        setApiKeyDataLoading(true);

        try {
            const { data } = await getApiKeyById(id);
            if (data?.payload) {
                setFormData((prev) => ({ ...prev, ...data.payload }));
                setApiKeyFromDb(data.payload);
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setApiKeyDataLoading(false);
    };

    /**
     * Call the service to retrieve the secret key
     * @param {object} modalFormData - should contain a 'password' field
     */
    const handleSubmitViewSecretKey = async (modalFormData) => {
        try {
            const { data } = await showSecretKey(formData._id, modalFormData);
            const { payload } = data;
            const { secretKey: secretKeyResponse } = payload;
            if (data && payload && secretKeyResponse) {
                setSecretKey(secretKeyResponse);
                return { success: true };
            }
            toast.custom((t) => <CustomToast text="Failed to retrieve secret key" t={t} type="error" />, {
                position: 'top-right',
            });
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        return { error: true };
    };

    /**
     * Copy the secret key to the clipboard and display a toast.
     * Uses the Synchronous Clipboard API.
     * See: https://caniuse.com/?search=clipboard
     */
    const copySecretKey = () => {
        navigator.clipboard.writeText(secretKey);
        toast.custom((t) => <CustomToast text="Copied to clipboard" t={t} type="success" />, {
            position: 'top-right',
        });
    };

    /** Hide the secret key */
    const hideSecretKey = () => {
        setSecretKey(null);
    };

    /**
     * Makes one of the modals visible. Modals shown from here could be either
     * the Transaction Data modal or the Disable Reason modal.
     * This function sets the 'modalOptions' state which contains the name of the modal to be
     * shown and the data to be shown in it.
     * @param {object} data - data in the clicked row
     */
    const displayModal = (data) => {
        const { CREATE_PARTNER_API_KEY, DISABLE_PARTNER_API_KEY, UPDATE_PARTNER_API_KEY } =
            TRANSACTION_REFERENCE.PARTNER_API_KEY;

        const { meta } = data;

        if (!meta) {
            return;
        }

        // Show the modal with transaction details
        if (data.reference === CREATE_PARTNER_API_KEY || data.reference === UPDATE_PARTNER_API_KEY) {
            const { allowedEndpoints = [] } = meta;
            if (!allowedEndpoints) {
                return;
            }

            // prepare permissions as a string to be displayed
            allowedEndpoints.forEach((perm) => {
                const permission = allowedEndpoints[perm];
                allowedEndpoints.push(permission);
            });
            const allowedEndpointsString = allowedEndpoints.join(', ');

            // show transaction data modal
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    name: data.name,
                    allowedEndpoints: allowedEndpointsString,
                    previousApiKeyName: data?.meta?.previousApiKeyName,
                },
            });
        }

        // Show the modal with reason for disabling
        if (data.reference === DISABLE_PARTNER_API_KEY) {
            setModalOptions({
                modal: 'reason',
                data: {
                    transactionId: data.sequence,
                    name: data.name,
                    reason: data?.meta?.reason,
                },
            });
        }
    };

    /**
     * Change the modalOptions state so modals can be shown or hidden.
     * See comments on modalOptions state.
     * @param {string} modalName - name of the modal to show - falsey value hides modal
     */
    const handleTransactionModalToggle = (modalName) => {
        if (modalName) {
            setModalOptions((prev) => ({ ...prev, modal: modalName }));
        } else {
            setModalOptions((prev) => ({ ...prev, modal: null }));
        }
    };

    /** Toggle the modals for changing the API key status. Modal name can be 'enable' or 'disable' */
    const handleStatusModalToggle = (modalName) => {
        if (modalName) {
            setStatusModalVisible(modalName);
        } else {
            setStatusModalVisible(null);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    /** Call the relevant service to update the API key data */
    const handleFormSubmit = async () => {
        setApiKeyDataLoading(true);

        const reqBody = {
            name: formData.name,
            allowedEndpoints: formData.allowedEndpoints,
            type: formData.type,
            password: formData.password,
        };

        try {
            const response = await updateApiKeyById(formData._id, reqBody);
            const { data } = response;
            const { payload } = data;
            if (data && payload) {
                toast.custom((t) => <CustomToast text="API Key updated successfully!" t={t} type="success" />, {
                    position: 'top-right',
                });
                setFormData({ ...formData, ...payload });
            } else {
                toast.custom((t) => <CustomToast text="Failed to update API key" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        loadApiKeyTransactions();
        setApiKeyDataLoading(false);
    };

    /**
     * Updates the value for the checkboxes in the formData state.
     * The checked boxes are saved in an array. Unchecked items are removed from the array.
     * @param {string} keyType - whether this key is a secret key or public key ('secretKey' or 'publicKey')
     * @param {object} event - event generated from the component
     */
    const handleCheckChange = (event) => {
        const { name, checked } = event.target;
        let updatedEndpoints = [];
        if (checked) {
            updatedEndpoints = [...formData.allowedEndpoints, name];
        } else {
            updatedEndpoints = formData.allowedEndpoints.filter((endpoint) => endpoint !== name);
        }
        setFormData((prevData) => ({ ...prevData, allowedEndpoints: updatedEndpoints }));
    };

    /** Navigate back to the API Portal (tabs) page with correct data in the state */
    const handleBackToSettings = () => {
        history.push({
            pathname: `/${PATHS.DEVELOPER.API_PORTAL}`,
            state,
            search: '?tab=basicSettings',
        });
    };

    /**
     * Call the services to enable/disable API keys. Invoked from the enable/disable modals.
     * @param {string} newStatus - whether to 'enable' or 'disable' the key
     * @param {object} modalData - should contain the password. If disabling, should contain a reason as well
     * @returns {object} - if this contains an 'error' field, the modal will not close automatically
     */
    const handleChangeStatus = async (newStatus, modalData) => {
        setApiKeyDataLoading(true);

        try {
            let response;
            if (newStatus === 'enable') {
                response = await enableApiKeyById(formData._id, modalData);
            }
            if (newStatus === 'disable') {
                response = await disableApiKeyById(formData._id, modalData);
            }

            const { data } = response;
            const { payload } = data;
            if (data && payload) {
                setFormData((prevData) => ({ ...prevData, ...payload }));
                toast.custom((t) => <CustomToast text="API key status changed successfully!" t={t} type="success" />, {
                    position: 'top-right',
                });
                setApiKeyDataLoading(false);
                return { success: true };
            }
            // If not returned, it means error
            toast.custom((t) => <CustomToast text="Failed to change API key status" t={t} type="error" />, {
                position: 'top-right',
            });
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setApiKeyDataLoading(false);
        return { error: true };
    };

    /** Handle changing of dropdown inputs */
    const handleSelectChange = (event) => {
        const { value } = event.target;
        // When the key type changes between key types,
        // allowed endpoints must keep their original values
        if (value === apiKeyFromDb.type) {
            setFormData((prevData) => ({ ...prevData, type: value, allowedEndpoints: apiKeyFromDb.allowedEndpoints }));
        } else {
            setFormData((prevData) => ({ ...prevData, type: value, allowedEndpoints: [] }));
        }
    };

    // Show an error in the case if the user loads this link directly, because there is no data in location.state.apiKey.
    if (!apiKey) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card className="shadow default-card p-5">No API Key selected.</Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                <Row>
                    <Col>
                        <Card className="shadow default-card">
                            <CardHeader className="pl-5 " style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}>
                                <Row className="align-items-center">
                                    {apiKey.name || apiKeyFromDb.name}
                                    {formData.isActive ? (
                                        <Badge
                                            className="ml-4 p-1 px-2"
                                            color="success"
                                            style={{ backgroundColor: '#26C60C' }}
                                        >
                                            ACTIVE
                                        </Badge>
                                    ) : (
                                        <Badge className="ml-4 p-1 px-2" color="danger">
                                            INACTIVE
                                        </Badge>
                                    )}
                                    {apiKeyDataLoading && <Spinner size="sm" className="ml-4" />}
                                    <Button
                                        className="scootix-btn-radius"
                                        onClick={handleBackToSettings}
                                        style={{ borderRadius: 10, marginLeft: 'auto' }}
                                    >
                                        <LeftArrowIcon color="#FFF" />
                                        Back to Settings
                                    </Button>
                                </Row>
                            </CardHeader>
                            <CardBody className="px-5">
                                <AvForm
                                    className="needs-validation default-form"
                                    noValidate
                                    onValidSubmit={handleFormSubmit}
                                >
                                    <Row>
                                        <Col>
                                            <Alert color="warning" className="p-4">
                                                {formData.isActive ? (
                                                    <Button
                                                        style={{
                                                            backgroundColor: '#FD4A4A',
                                                            borderRadius: 10,
                                                            borderColor: '#FD4A4A',
                                                        }}
                                                        onClick={() => setStatusModalVisible('disable')}
                                                        disabled={apiKeyDataLoading}
                                                    >
                                                        <DisconnectIcon color="#FFF" />
                                                        &nbsp;Disable
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        style={{
                                                            backgroundColor: '#4AB054',
                                                            borderRadius: 10,
                                                            borderColor: '#4AB054',
                                                        }}
                                                        onClick={() => setStatusModalVisible('enable')}
                                                        disabled={apiKeyDataLoading}
                                                    >
                                                        <ConnectIcon color="#FFF" />
                                                        &nbsp;Enable
                                                    </Button>
                                                )}
                                            </Alert>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label htmlFor="name" className="text-muted">
                                                    Name
                                                </Label>
                                                <AvField
                                                    name="name"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                    validate={{ required: { value: true } }}
                                                    autoComplete="new-password"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label htmlFor="secretKey" className="text-muted">
                                                    Secret Key
                                                </Label>
                                                <InputGroup className="align-items-center">
                                                    <Input
                                                        type={secretKey ? 'text' : 'password'}
                                                        disabled
                                                        value={secretKey || 'placeholder'}
                                                    />
                                                    {secretKey && (
                                                        <span
                                                            role="button"
                                                            onClick={copySecretKey}
                                                            style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                                                        >
                                                            <CopyIcon
                                                                color="#000"
                                                                type="button"
                                                                width={24}
                                                                height={24}
                                                            />
                                                        </span>
                                                    )}
                                                    {secretKey ? (
                                                        <Badge
                                                            pill
                                                            style={{
                                                                backgroundColor: '#3A3937',
                                                                marginLeft: '0.5rem',
                                                                paddingTop: '0.5rem',
                                                                paddingBottom: '0.5rem',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={hideSecretKey}
                                                            type="button"
                                                        >
                                                            Hide Key
                                                        </Badge>
                                                    ) : (
                                                        <Badge
                                                            pill
                                                            style={{
                                                                backgroundColor: '#3A3937',
                                                                marginLeft: '0.5rem',
                                                                paddingTop: '0.5rem',
                                                                paddingBottom: '0.5rem',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleTransactionModalToggle('password')}
                                                            type="button"
                                                        >
                                                            View Key
                                                        </Badge>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        {/* <Col>
                                            <FormGroup>
                                                <Label htmlFor="publicKey" className="text-muted">
                                                    Public Key
                                                </Label>
                                                <Input type="text" disabled value={apiKey.key} />
                                            </FormGroup>
                                        </Col> */}
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label htmlFor="keyType" className="text-muted">
                                                    Select the API Key type
                                                </Label>
                                                <AvField
                                                    name="keyType"
                                                    type="select"
                                                    onChange={handleSelectChange}
                                                    value={formData.type}
                                                >
                                                    <option value="public">Public</option>
                                                    <option value="private">Private</option>
                                                </AvField>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row
                                        style={{
                                            backgroundColor: '#F5F4F4',
                                            borderRadius: 15,
                                            marginLeft: 0,
                                            border: '#E7E4E4 1px solid',
                                        }}
                                        className="p-4 mt-4"
                                    >
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <span className="font-weight-bold">
                                                        Select the allowed API Endpoints for the Secret Key
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                {Object.keys(
                                                    formData.type === 'private'
                                                        ? privateKeyEndpoints
                                                        : publicKeyEndpoints
                                                ).map((checkBox) =>
                                                    renderEndpointCheckbox(
                                                        {
                                                            name: checkBox,
                                                            ...(formData.type === 'private'
                                                                ? privateKeyEndpoints
                                                                : publicKeyEndpoints)[checkBox],
                                                        },
                                                        formData.allowedEndpoints.includes(checkBox),
                                                        (e) => handleCheckChange(e)
                                                    )
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="mt-4">
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label htmlFor="password" className="text-muted">
                                                    Your Password
                                                </Label>
                                                <AvField
                                                    name="password"
                                                    type="password"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={formData.password}
                                                    onChange={handleInputChange}
                                                    autoComplete="new-password"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Button
                                                className="scootix-btn-radius"
                                                style={{ borderRadius: 10 }}
                                                type="submit"
                                                disabled={apiKeyDataLoading}
                                            >
                                                <CheckCircleIcon color="#fff" />
                                                &nbsp;Update
                                            </Button>
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row className="mt-4">
                                        <Col>
                                            <span className="font-weight-bold">Transactions Log</span>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <TableCustom
                                                tableData={{
                                                    ...transactionInitialValues,
                                                    docs: mapApiKeyTransactions(transactionData, formData),
                                                    headers: transactionsLogTableHeaders,
                                                }}
                                                showPagination={false}
                                                removeAction
                                                isFilter={false}
                                                isSearch={false}
                                                isFixedHeight
                                                isCardContainer={false}
                                                isOuterTableBorder
                                                showApiBasicSettingsAction={hasAccess(
                                                    PERMISSIONS.PARTNER_API_BASIC_SETTINGS,
                                                    ['View']
                                                )}
                                                customActionHandlers={{ displayModal }}
                                            />
                                            <ApiKeyTransactionDataModal
                                                show={modalOptions.modal === 'transaction'}
                                                handleToggle={handleTransactionModalToggle}
                                                transactionId={modalOptions.data.transactionId}
                                                name={modalOptions.data.name}
                                                allowedEndpoints={modalOptions.data.allowedEndpoints}
                                                previousApiKeyName={modalOptions.data.previousApiKeyName}
                                            />
                                            <DisabledKeyDetailsModal
                                                show={modalOptions.modal === 'reason'}
                                                handleToggle={handleTransactionModalToggle}
                                                transactionId={modalOptions.data.transactionId}
                                                name={modalOptions.data.name}
                                                reason={modalOptions.data.reason}
                                            />
                                            <ViewSecretKeyModal
                                                show={modalOptions.modal === 'password'}
                                                handleToggle={handleTransactionModalToggle}
                                                handleSubmit={handleSubmitViewSecretKey}
                                            />
                                            <EnableApiKeyModal
                                                show={statusModalVisible === 'enable'}
                                                handleToggle={handleStatusModalToggle}
                                                handleSubmit={(data) => handleChangeStatus('enable', data)}
                                            />
                                            <DisableApiKeyModal
                                                show={statusModalVisible === 'disable'}
                                                handleToggle={handleStatusModalToggle}
                                                handleSubmit={(data) => handleChangeStatus('disable', data)}
                                            />
                                        </Col>
                                    </Row>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

ManageApiKeyPage.propTypes = {
    location: PropTypes.any,
};

const HotManageApiKeyPage = hot(ManageApiKeyPage);

export default connect(null, {})(HotManageApiKeyPage);
