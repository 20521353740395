/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Row, Col, Card, CardBody, FormGroup, Spinner, Label, Container, Alert } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';

// #region imports
import PATHS from 'routes/paths';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { BackdropLoader, CustomToast, Select } from 'components';
import { getVisitingObject, hasAccess, IS_MERCHANT_VIEW } from 'utils/checkAuth';
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';
import { getDateValue, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import { getAllRolesByUserType, getPermissions, updateRole } from './services';
import PermissionHeader from './components/PermissionHeader';
import { defaultTableHeaders } from './constants';
import { validateForm } from './utils';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'User Management', link: '/user-profile-management' },
];

let initialFormValues = {};

function RolePermissionPage() {
    const history = useHistory();

    const { visitingClient, visitingMerchant } = getVisitingObject();

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
            role: 'merchantUser',
        };
    }

    // Component
    const [breadcrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);

    // Form State
    const [editingMode] = useState(false);
    const [isViewMode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);

    const [merchants, setMerchants] = useState([]);
    const [allRoles, setAllRoles] = useState([]);
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [selectedRole, setSelectedRole] = useState({});

    // // Merchant Handlers
    useEffect(() => {
        // getAllPermissions();
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    // // Merchant Handlers
    useEffect(() => {
        if (formData.userType) {
            getAllRolesByType(formData.userType);
            getAllPermissions(formData.userType);
        }
    }, [formData.userType]);

    const getAllRolesByType = async (type) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getAllRolesByUserType(type);
            if (data) {
                setRoles(data.docs);
                setAllRoles(data.docs);
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const getAllPermissions = async (type) => {
        const tableHeaders = defaultTableHeaders;
        const { data } = await getPermissions();
        if (data.docs) {
            if (type === 'Merchant' || IS_MERCHANT_VIEW()) {
                const filterNonClientPermission = tableHeaders.map((doc) => ({
                    [doc.value]: data.docs.filter((newDoc) => newDoc.type === doc.value && !doc.isClientOnly),
                }));
                setPermissions(filterNonClientPermission);
            } else {
                const filterNonMerchantPermission = tableHeaders.map((doc) => ({
                    [doc.value]: data.docs.filter((newDoc) => newDoc.type === doc.value && !doc.isMerchantOnly),
                }));
                setPermissions(filterNonMerchantPermission);
            }
        }
    };

    const getMerchantSelect = (_id) => {
        if (_id) {
            const relevantMerchant = merchants.find((x) => x._id === _id);
            if (relevantMerchant) {
                return relevantMerchant.name;
            }
        }
        return null;
    };

    const getSelectedRole = (_id) => {
        const relevantRole = roles.find((x) => x._id === _id);
        if (relevantRole) {
            return relevantRole.roleName;
        }
        return null;
    };
    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);
        if (id === 'userType') {
            setSelectedRole({});
            if (!IS_MERCHANT_VIEW()) {
                setFormData({
                    ...formData,
                    merchantName: '',
                    merchantId: '',
                    roleId: '',
                    [id]: event ? event.value : null,
                });
            } else {
                setFormData({
                    ...formData,
                    roleId: '',
                    [id]: event ? event.value : null,
                });
            }
            return;
        }

        if (id === 'roleId') {
            const relevantRole = roles.find((x) => x._id === event.value);
            if (relevantRole) {
                setSelectedRole(relevantRole);
                setFormData({
                    ...formData,
                    ...relevantRole.permissionArray,
                    roleName: relevantRole.roleName || '',
                    [id]: event ? event.value : null,
                });
            }
            return;
        }

        if (id === 'merchantId' && event) {
            const relevantMerchant = merchants.find((x) => x._id === event.value);
            setSelectedRole({});
            if (relevantMerchant) {
                setRoles(allRoles.filter((x) => x.merchantId === relevantMerchant._id));
                setFormData({
                    ...formData,
                    merchantName: relevantMerchant.name || '',
                    roleId: '',
                    [id]: event ? event.value : null,
                });
            } else {
                setFormData({
                    ...formData,
                    roleId: '',
                });
            }
            return;
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    function cleanFormData(data) {
        const modifiedData = data;
        try {
            modifiedData.clientId && delete modifiedData.clientId;
            modifiedData.roleName && delete modifiedData.roleName;
            modifiedData.merchantName && delete modifiedData.merchantName;
            modifiedData.merchantId && delete modifiedData.merchantId;
            modifiedData.country && delete modifiedData.country;
            modifiedData.role && delete modifiedData.role;
            modifiedData.roleId && delete modifiedData.roleId;
            modifiedData.userType && delete modifiedData.userType;
        } catch (e) {
            console.log(e);
        }

        return modifiedData;
    }

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        const postData = { permissionArray: cleanFormData(formData) };
        setFormLoading(true);

        try {
            const { data } = await updateRole(postData, selectedRole._id);
            setFormData({ ...formData, userType: data.userType, roleId: data._id, merchantId: data.merchantId });
            setSelectedRole(data);
            toast.custom((t) => <CustomToast text="Successfully updated Role" t={t} type="success" />, {
                position: 'top-right',
            });
            setIsFormSaved(true);
        } catch (e) {
            console.log(e);
            toast.error('Something went wrong');
        }
        setFormLoading(false);
        setSubmitted(false);
    };

    const handlePermissionState = (e, key) => {
        setFormData({
            ...formData,
            [key]: { ...e },
        });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        // title={editingMode ? 'Update Merchant' : 'Create New Merchant'}
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Row className="d-flex justify-content-between">
                        {hasAccess(PERMISSIONS.ROLE_PERMISSION, ['Add']) && (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                onClick={() => {
                                    history.push({
                                        pathname: PATHS.ROLE_PERMISSION.NEW_ROLE,
                                    });
                                }}
                            >
                                <i className="fas fa-user-cog mr-2 fa-lg" />
                                Add a new Role
                            </motion.div>
                        )}
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                            onClick={() => {
                                history.push({
                                    pathname: PATHS.USER_PROFILE_MANAGEMENT.DEFAULT,
                                });
                            }}
                        >
                            <i className="fas fa-arrow-left mr-2 fa-lg" />
                            Back to Users
                        </motion.div>
                    </Row>
                    <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                        <fieldset disabled={isViewMode || formLoading}>
                            <Card className="shadow-lg default-card">
                                <CardBody>
                                    <Row>
                                        <Col md="3">
                                            <FormGroup>
                                                <Label htmlFor="userType">User Type</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(formData.userType)}
                                                    options={[
                                                        { label: 'Client', value: 'Client' },
                                                        { label: 'Merchant', value: 'Merchant' },
                                                    ]}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'userType')}
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                />
                                            </FormGroup>
                                        </Col>
                                        {formData.userType === 'Merchant' && (
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label htmlFor="merchantId">Merchant</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(
                                                            getMerchantSelect(formData.merchantId)
                                                        )}
                                                        options={merchants}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                        isDisabled={IS_MERCHANT_VIEW()}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}
                                        <Col md="3">
                                            <FormGroup>
                                                <Label htmlFor="roleId">Role Name</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(getSelectedRole(formData.roleId))}
                                                    options={roles.map((role) => ({
                                                        label: role.roleName,
                                                        value: role._id,
                                                    }))}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'roleId')}
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {formData.roleId && selectedRole && selectedRole.createdBy && (
                                        <Alert color="danger" className="">
                                            {`This user permission created by ${
                                                selectedRole.createdBy.fullName
                                            } at ${getDateValue(selectedRole.createdAt, 'datetime')} and
                                            Last Updated by ${selectedRole.createdBy.fullName} at ${getDateValue(
                                                selectedRole.updatedAt,
                                                'datetime'
                                            )}`}
                                        </Alert>
                                    )}
                                    <br />

                                    {permissions.map((permission) =>
                                        Object.keys(permission).map((type) => (
                                            <PermissionHeader
                                                title={type}
                                                headerRelevantData={permission[type]}
                                                selectedRole={selectedRole}
                                                handlePermissionState={(e, key) => handlePermissionState(e, key)}
                                            />
                                        ))
                                    )}

                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        {hasAccess(PERMISSIONS.ROLE_PERMISSION, ['Edit']) && (
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                type="submit"
                                                disabled={formLoading}
                                            >
                                                {formLoading ? (
                                                    <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                ) : (
                                                    <>
                                                        <i className="fas fa-save left-icon"></i>
                                                        <span className="h6">{editingMode ? 'Update' : 'Update'}</span>
                                                    </>
                                                )}
                                            </motion.button>
                                        )}
                                    </Row>
                                </CardBody>
                            </Card>
                        </fieldset>
                    </AvForm>
                </Container>
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

RolePermissionPage.propTypes = {};

const HotRolePermissionPage = hot(RolePermissionPage);

export default connect(mapStateToProps, {})(HotRolePermissionPage);
