/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row, NavLink, NavItem, Nav } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import * as qs from 'query-string';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';

// #region assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { AlertModal, StatCard, TableCustom } from 'components';
import PATHS from 'routes/paths';

// #region services
import { getAllUsersMetaService, getUsersService, deleteUserService } from 'services/user.service';
import { isObject, pad } from 'utils/helpers';
import { getUser, hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import { defaultTableHeaders } from '../../constants';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'User Management', link: PATHS.USER_PROFILE_MANAGEMENT.DEFAULT },
];

function UserProfileManagementPage(props) {
    const history = useHistory();

    const { location } = props;
    // component state
    const [loading, setLoading] = useState(0);

    const [activeTab, setActiveTab] = useState('1');
    const [urlParamsSearch, setUrlParamsSearch] = useState(null);
    const [searchParams, setSearchParams] = useState(null);

    const [usersMeta, setUsersMeta] = useState({
        activeUsers: 0,
        newUsers: 0,
        inactiveUsers: 0,
        notVerifiedUsers: 0,
        onlineUsers: 0,
        requestedForAccountDeletionUsers: 0,
    });

    const [usersListData, setUsersListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    const [showDeleteModal, setShowDeleteModal] = useState({ state: false, data: null });

    useEffect(() => {
        if (urlParamsSearch) {
            const { searchBy, searchText, page, sort, order, status, limit, filter } = urlParamsSearch;
            const searchByProp = { searchBy, searchText };
            loadUsersListData(true, usersListData.all, page, limit, filter, sort, order, searchByProp, status);

            loadUserMeta();
        }
    }, [urlParamsSearch]);

    useEffect(() => {
        const urlSearch = qs.parse(location.search);
        if (urlSearch && urlSearch.tab) {
            const { tab, status, page, searchBy, searchText, sort, order } = urlSearch;
            setActiveTab(tab);
            setUrlParamsSearch({ tab, status, page, searchBy, searchText, sort, order });
            setSearchParams({ searchBy, searchText, order, sort });
        } else {
            replaceUrlParamsHandler({ tab: '1', status: 'active', page: '1' });
        }
    }, [location.search]);

    const loadUsersListData = (showLoading = true, all, page, limit, filter, sort, order, searchByProp, status) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getUsersService(all, page, limit, filter, sort, order, searchByProp, 'merchantId', status)
            .then((res) => {
                const { data } = res;
                if (data && data.docs) {
                    const docs = mapDocs(data.docs);
                    setUsersListData({
                        ...usersListData,
                        ...data,
                        docs,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const mapDocs = (docs) => {
        let allDocs = [];
        allDocs = docs.map((x) => ({
            ...x,
            name: `${x.firstName || ''} ${x.lastName || ''}`,
            merchantPictureUrl: x.merchantId && isObject(x.merchantId) ? x.merchantId.merchantPictureUrl : null,
            merchantId: x.merchantId && isObject(x.merchantId) ? x.merchantId._id : x.merchantId,
        }));
        return allDocs;
    };

    /**
     * Update URL parameters with provided values
     * @param {object} params - URL parameters
     */
    const replaceUrlParamsHandler = (
        params = {
            tab: null,
            status: null,
            page: '1',
            limit: '10',
            searchBy: null,
            searchText: null,
            sort: null,
            order: null,
        }
    ) => {
        try {
            history.replace({
                search: `${
                    params.page || urlParamsSearch.page ? `page=${params.page || urlParamsSearch.page || '1'}&` : ''
                }${params.tab || urlParamsSearch.tab ? `tab=${params.tab || urlParamsSearch.tab || '1'}&` : ''}${
                    params.status || urlParamsSearch.status
                        ? `status=${params.status || urlParamsSearch.status || 'all'}&`
                        : ''
                }${params?.searchBy ? `searchBy=${params.searchBy}&` : ''}${
                    params?.searchText ? `searchText=${params.searchText}&` : ''
                }${params?.limit ? `limit=${params.limit}&` : ''}${params?.sort ? `sort=${params.sort}&` : ''}${
                    params?.order ? `order=${params.order}` : ''
                }`,
            });
        } catch (e) {
            console.log(e);
        }
    };

    const loadUserMeta = async () => {
        try {
            const { data } = await getAllUsersMetaService();
            if (data) {
                setUsersMeta({ ...usersMeta, ...data });
            }
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Handles pagination page change
     * @param {number} pageNo
     */
    const handlePageChange = (pageNo) => {
        replaceUrlParamsHandler({
            page: pageNo,
            searchBy: searchParams?.searchBy,
            searchText: searchParams?.searchText,
            sort: searchParams?.sort,
            order: searchParams?.order,
            tab: searchParams?.tab,
            status: searchParams?.status,
        });
    };

    /**
     * Handles filtration
     * @param {string} field - Filter field
     * @param {string} filterText - By text
     */
    const handleFilter = (field, filterText) => {
        let modifiedFilterText = null;
        if (filterText && filterText !== '') {
            modifiedFilterText = filterText.replaceAll(' ', '$2');
        } else {
            modifiedFilterText = null;
        }
        replaceUrlParamsHandler({ searchBy: field || 'fullName', searchText: modifiedFilterText, page: '1' });
    };

    const handleOnClickNewUser = () => {
        history.push({
            pathname: `${PATHS.USER_PROFILE_MANAGEMENT.FORM}`,
            search: `?type=new`,
            state: { prevCompState: { activeTab } },
        });
    };

    const handleEditUser = (e, row) => {
        history.push({
            pathname: `${PATHS.USER_PROFILE_MANAGEMENT.FORM}`,
            search: `?type=edit&id=${row._id}`,
            state: { userParentData: row, prevCompState: { activeTab, refreshUser: false } },
        });
    };

    const handleViewUser = (e, row) => {
        history.push({
            pathname: `${PATHS.USER_PROFILE_MANAGEMENT.FORM}`,
            search: `?type=view`,
            state: { userParentData: row, prevCompState: { activeTab } },
        });
    };

    const handleOnDelete = (e, row) => {
        setShowDeleteModal({ state: true, data: row });
    };

    /**
     * Refetch user list data according to url params
     */
    const reloadComponent = () => {
        loadUsersListData({
            showLoading: true,
            ...usersListData,
            page: urlParamsSearch?.page,
            searchBy: { searchBy: urlParamsSearch?.searchBy, searchText: urlParamsSearch?.searchText },
            type: urlParamsSearch.type,
            tab: urlParamsSearch.tab,
            status: urlParamsSearch.status,
        });
        loadUserMeta(urlParamsSearch.tab);
    };

    const handleDeleteUser = async (data) => {
        try {
            await deleteUserService(data._id);
            reloadComponent();
            toast.success('Successfully Deleted');
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors && e.data.errors.userMessage) {
                toast.error(e.data.errors.userMessage);
            } else {
                toast.error('Something went wrong');
            }
        }

        setShowDeleteModal({ state: false, data: null });
    };

    /**
     * Based on the passed column set url query params for column wise sorting
     * @param {boolean} isDescending - If true sorting is descending order else ascending order
     * @param {string} column - Which column need to be used
     */
    const handleColumnWiseSorting = (isDescending, column) => {
        replaceUrlParamsHandler({
            sort: column,
            order: isDescending ? -1 : 1,
            searchBy: urlParamsSearch.searchBy,
            searchText: urlParamsSearch.searchText,
        });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        {hasAccess(PERMISSIONS.USER_ACCOUNT, ['Add']) && (
                            <div>
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => handleOnClickNewUser()}
                                >
                                    <i className="fas fa-user-plus left-icon" />
                                    <span>Create New User</span>
                                </motion.div>
                            </div>
                        )}
                    </Row>
                    <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            style={{ cursor: 'pointer' }}
                            className="w3-animate-opacity"
                            onClick={() => {
                                replaceUrlParamsHandler({ status: 'active', page: '1', tab: '1' });
                                loadUserMeta();
                            }}
                        >
                            <StatCard
                                title="ACTIVE"
                                value={`${pad(usersMeta.activeUsers)} USERS`}
                                icon="mdi mdi-clock-outline"
                                tooltipPlacement="bottom"
                                tooltipTitle="Active Users"
                            />
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            className="w3-animate-opacity pointer-cursor"
                            onClick={() => {
                                replaceUrlParamsHandler({ status: 'new', page: '1', tab: '3' });
                                loadUserMeta();
                            }}
                        >
                            <StatCard
                                title="NEED APPROVAL"
                                value={`${pad(usersMeta.newUsers)} USERS`}
                                icon="fas fa-exclamation-circle pb-3 mt-2"
                                tooltipPlacement="bottom"
                                tooltipTitle="New Users who need approval"
                            />
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            className="w3-animate-opacity"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                replaceUrlParamsHandler({ status: 'online', page: '1', tab: '5' });
                                loadUserMeta();
                            }}
                        >
                            <StatCard
                                title="ONLINE"
                                value={`${pad(usersMeta.onlineUsers)} USERS`}
                                icon="fas fa-exclamation-circle pb-3 mt-2"
                                tooltipPlacement="bottom"
                                tooltipTitle="Users currently online in system"
                            />
                        </motion.div>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: 'space-between' }} className="ml-3 mr-3">
                        <div>
                            <span className="ml-2 font-weight-bold">SYSTEM USER ACCOUNTS </span>
                        </div>
                        {/* <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() =>
                                history.push({
                                    pathname: `${PATHS.ROLE_PERMISSION.DEFAULT}`,
                                })
                            }
                            className="scootix-btn-radius p-2 pr-3 pl-3 shadow-lg"
                        >
                            <i className="fas fa-wrench left-icon"></i>
                            Manage Roles
                        </motion.div> */}
                    </Row>
                    <Row>
                        <Nav tabs className="nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '1',
                                    })}
                                    onClick={() => {
                                        replaceUrlParamsHandler({ status: 'active', page: '1', tab: '1' });
                                        loadUserMeta();
                                    }}
                                >
                                    <span className="d-none d-sm-block">Active Users ( {usersMeta.activeUsers} )</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '2',
                                    })}
                                    onClick={() => {
                                        replaceUrlParamsHandler({ status: 'inactive', page: '1', tab: '2' });
                                        loadUserMeta();
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        Inactive Users ( {usersMeta.inactiveUsers} )
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '3',
                                    })}
                                    onClick={() => {
                                        replaceUrlParamsHandler({ status: 'new', page: '1', tab: '3' });
                                        loadUserMeta();
                                    }}
                                >
                                    <span className="d-none d-sm-block">New Users ( {usersMeta.newUsers} )</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '4',
                                    })}
                                    onClick={() => {
                                        replaceUrlParamsHandler({ status: 'notVerified', page: '1', tab: '4' });
                                        loadUserMeta();
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        Unverified Users ( {usersMeta.notVerifiedUsers} )
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '5',
                                    })}
                                    onClick={() => {
                                        replaceUrlParamsHandler({ status: 'online', page: 1, tab: '5' });
                                        loadUserMeta();
                                    }}
                                >
                                    <span className="d-none d-sm-block">Online Users ( {usersMeta.onlineUsers} )</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '6',
                                    })}
                                    onClick={() => {
                                        replaceUrlParamsHandler({
                                            status: 'requestedForDeletion',
                                            page: '1',
                                            tab: '6',
                                        });
                                        loadUserMeta();
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        Requested For Deletion ( {usersMeta.requestedForAccountDeletionUsers} )
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Row>
                    <br />
                    <Row>
                        <TableCustom
                            tableData={usersListData}
                            loading={loading}
                            handleEdit={handleEditUser}
                            handleView={handleViewUser}
                            onFilter={handleFilter}
                            onPageChange={handlePageChange}
                            handleDelete={handleOnDelete}
                            showDelete={false}
                            showEdit={hasAccess(PERMISSIONS.USER_ACCOUNT, ['Edit'])}
                            showView={false}
                            onSort={handleColumnWiseSorting}
                            searchProp={searchParams}
                            isManualSearch
                            filters={[
                                {
                                    label: 'Full Name',
                                    key: 'fullName',
                                },
                                {
                                    label: 'Last Name',
                                    key: 'lastName',
                                },
                                {
                                    label: 'Email',
                                    key: 'email',
                                },
                            ]}
                            disabledRows={{
                                role: { key: 'role', value: 'superAdmin' },
                                _id: {
                                    key: '_id',
                                    value: getUser()._id,
                                },
                            }}
                        />
                    </Row>
                </Container>
            </div>
            <AlertModal
                show={showDeleteModal.state}
                onConfirm={handleDeleteUser}
                data={showDeleteModal.data}
                onHide={() =>
                    setShowDeleteModal({
                        state: false,
                        data: null,
                    })
                }
            />
        </>
    );
}

UserProfileManagementPage.propTypes = {
    location: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotUserProfileManagementPage = hot(UserProfileManagementPage);

export default connect(mapStateToProps, {})(HotUserProfileManagementPage);
