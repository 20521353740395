/* eslint-disable indent */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Col, Label, Row, Card, CardBody, FormGroup, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import * as qs from 'query-string';
import toast from 'react-hot-toast';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import Hoc from 'components/Hoc';
import { FormStatusBtn, ClientAlerts, CustomToast, TableCustom } from 'components';
import LocationLabelViewCard from 'components/Cards/Labels/location-label';

// #region imports
import PATHS from 'routes/paths';
import { SubTitle } from 'styles';

// #region utils
import { getVisitingObject } from 'utils/checkAuth';
import { apiErrorHandler, apiErrorHandlerV2 } from 'utils/helpers';

// #region services
import { getTransactionsByBinLocationId } from 'services/bin-location.service';

// #endregion imports
import { createBinLocationService, updateBinLocationService } from '../../../services';
import { validateForm } from './utils';
import { initialTransactionTableData } from '../constants';
import { mapTransactionTableDocs } from '../utils';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Warehouse Sites', link: PATHS.WAREHOUSE_SITE.DEFAULT },
];

let initialFormValues = {
    placedDateTime: new Date(),
    pickupLocationMeta: {},
};

function BinLocationFormPage(props) {
    const history = useHistory();

    const { visitingClient } = getVisitingObject();

    if (visitingClient) {
        initialFormValues = {
            ...initialFormValues,
            clientId: visitingClient._id,
            clientName: visitingClient.name,
        };
    }

    // prev comp
    const [prevCompState, setPrevCompState] = useState({});

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);
    const [isViewMode, setIsViewMode] = useState(false);

    const [warehouseZoneData, setWarehouseZoneData] = useState({});

    // transaction table data
    const [transactionTableData, setTransactionTableData] = useState(initialTransactionTableData);
    const [isTransactionLoading, setIsTransactionLoading] = useState(false);

    useEffect(() => {
        if (!props.location) {
            history.goBack();
            return;
        }

        const formType = qs.parse(props.location.search);
        if (formType && formType.type && formType.type === 'new') {
            !props.location.state && history.goBack();
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Site', link: '#' }]);
            const { warehouseZoneParentData } = props.location.state;
            setWarehouseZoneData({ ...warehouseZoneParentData });
        }

        if (formType && formType.type && formType.type === 'edit') {
            const locationStates = props.location.state;
            setWarehouseZoneData({ ...locationStates.warehouseZoneParentData });
            handleUpdateComponentEditMode();
        }

        if (formType && formType.type && formType.type === 'view') {
            const { warehouseZoneParentData } = props.location.state;
            if (warehouseZoneParentData) {
                setWarehouseZoneData({ ...warehouseZoneParentData });
            }
            handleUpdateComponentViewMode();
        }

        const { prevCompState = null } = props.location.state || {};
        if (prevCompState) {
            setPrevCompState(prevCompState);
        }
    }, []);

    useEffect(() => {
        formData._id && loadWarehouseZoneTransactions();
    }, [formData._id]);

    const loadWarehouseZoneTransactions = async () => {
        setIsTransactionLoading(true);
        if (formData._id) {
            try {
                const { data } = await getTransactionsByBinLocationId(formData?._id);
                const mappedData = mapTransactionTableDocs(data);
                setTransactionTableData({ ...transactionTableData, docs: mappedData });
            } catch (e) {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            } finally {
                setIsTransactionLoading(false);
            }
        }
    };

    const handleUpdateComponentViewMode = () => {
        try {
            !props.location.state && history.goBack();
            const { binLocationParentData } = props.location.state;
            setFormData({ ...formData, ...binLocationParentData });
            setIsViewMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'View User', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpdateComponentEditMode = () => {
        try {
            !props.location.state && history.goBack();
            const { binLocationParentData } = props.location.state;
            setFormData({ ...formData, ...binLocationParentData });
            const { prevCompState = null } = props.location.state || {};
            // Update URL Based on Condition
            handleUpdateUrl(prevCompState, binLocationParentData, 'binLocationParentData');
            if (prevCompState && prevCompState.refreshUser) {
                // This code is executed only during page reload
                // loadUserById(binLocationParentData._id);
            }
            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Bin Location', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    // Update Address Bar URL
    // PATH | QUERY | STATE
    const handleUpdateUrl = (prevState, data, id) => {
        // Handle Remove PrevComponent
        try {
            history.replace({
                pathname: `${PATHS.WAREHOUSE_SITE.LOCATION_BIN_FORM}`,
                search: `?type=edit&id=${data._id}`,
                state: {
                    [id]: data,
                    prevCompState: { ...prevState, refreshPage: true },
                },
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleSubmit = async (event, errors) => {
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        let payload = { ...formData };

        if (!editingMode && !warehouseZoneData._id) {
            toast.custom((t) => <CustomToast text="Something Went Wrong" t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);
        payload = {
            ...payload,
            warehouseZoneId: warehouseZoneData._id,
            warehouseZoneReferenceNumber: warehouseZoneData.referenceNumber,
            warehouseSiteReferenceNumber: warehouseZoneData.warehouseSiteReferenceNumber,
            warehouseSiteId: warehouseZoneData.warehouseSiteId,
        };

        try {
            let responseData;

            if (editingMode) {
                const { data } = await updateBinLocationService(payload);
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            } else {
                const { data } = await createBinLocationService(payload);
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            }
            setEditingMode(true);

            try {
                // Replace URL so relevant changes can be done
                if (responseData) {
                    history.replace({
                        pathname: `${PATHS.WAREHOUSE_SITE.LOCATION_BIN_FORM}`,
                        search: `?type=edit&id=${responseData._id}`,
                        state: {
                            binLocationParentData: responseData,
                            prevCompState,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} Bin`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setIsFormSaved(true);
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        loadWarehouseZoneTransactions();
        setFormLoading(false);
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    return (
        <>
            <Hoc {...props}>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                        <Row
                            style={{
                                margin: 2,
                                justifyContent: 'space-between',
                            }}
                        >
                            {!editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                >
                                    <i className="far fa-times-circle mr-2 fa-lg" />
                                    Cancel
                                </motion.div>
                            )}
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormStatusBtn isFormSaved={isFormSaved} />
                            </div>

                            {editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                >
                                    <i className="fas fa-arrow-left mr-2 fa-lg" />
                                    Back to Zone
                                </motion.div>
                            )}
                        </Row>
                        {!editingMode && (
                            <>
                                <ClientAlerts />
                            </>
                        )}
                        <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                            <CardBody>
                                <Row style={{ justifyContent: 'space-between', alignItems: 'center', margin: 2 }}>
                                    <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                        BIN LOCATION
                                    </SubTitle>
                                </Row>
                                <br />
                                <AvForm
                                    className="needs-validation default-form"
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                    id="warehouseSiteForm"
                                >
                                    <fieldset disabled={isViewMode || formLoading}>
                                        <Row>
                                            {(editingMode || isViewMode) && (
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="referenceNumber">Bin ID</Label>
                                                        <AvField
                                                            name="referenceNumber"
                                                            placeholder="Bin ID"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            disabled
                                                            id="referenceNumber"
                                                            value={formData.referenceNumber}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            )}

                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="binLocationName">Bin Location Name</Label>
                                                    <AvField
                                                        name="binLocationName"
                                                        placeholder="Bin Location Name"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        id="binLocationName"
                                                        value={formData.binLocationName || ''}
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <Label htmlFor="description">Description</Label>
                                                    <AvField
                                                        name="description"
                                                        placeholder="Description"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        id="description"
                                                        value={formData.description || ''}
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row style={{ justifyContent: 'flex-end' }}>
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 btn"
                                                type="submit"
                                                disabled={formLoading}
                                            >
                                                {formLoading ? (
                                                    <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                ) : (
                                                    <>
                                                        <i className="fas fa-save left-icon"></i>
                                                        <span className="h6">{editingMode ? 'Update' : 'Save'}</span>
                                                    </>
                                                )}
                                            </motion.button>
                                        </Row>
                                    </fieldset>
                                </AvForm>
                            </CardBody>
                        </Card>

                        <br />
                        {editingMode && (
                            <>
                                <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                                    <CardBody>
                                        <Row>
                                            <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}>
                                                LOCATION LABEL
                                            </SubTitle>{' '}
                                        </Row>

                                        <LocationLabelViewCard
                                            formData={formData}
                                            referenceNumber={formData.referenceNumber || ''}
                                        />
                                    </CardBody>
                                </Card>

                                <br />

                                <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                                    <div className="route-planning__form-header pl-3">
                                        <Row
                                            className="flex align-items-center align-content-center"
                                            style={{ height: 60 }}
                                        >
                                            <Col className="header-text">System Transactions</Col>
                                        </Row>
                                    </div>

                                    <CardBody className="px-3">
                                        <Row>
                                            <TableCustom
                                                tableData={transactionTableData}
                                                loading={isTransactionLoading}
                                                showDelete={false}
                                                showPagination={false}
                                                showView={false}
                                                removeId
                                                isFullScreenShow={false}
                                                isFilter={false}
                                                isSearch={false}
                                                removeAction
                                                isFixedHeight
                                            />
                                        </Row>
                                    </CardBody>
                                </Card>
                                <br />
                            </>
                        )}
                    </Container>
                </div>
            </Hoc>
        </>
    );
}

BinLocationFormPage.propTypes = {};

const mapStateToProps = (state) => ({
    ...state,
});

const HotBinLocationFormPage = hot(BinLocationFormPage);

export default connect(mapStateToProps, {})(HotBinLocationFormPage);
