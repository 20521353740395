/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Spinner } from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import { RIDER_ICON_IMAGE } from 'definitions/assets';
import { getVisitingObject, IS_SUPER_ADMIN } from 'utils/checkAuth';
import { centerElementContainerStyles } from 'styles';
import paths from 'routes/paths';

function TrackRidersModal(props) {
    const { toggleModal, socket, handleNavigate } = props;

    const [allRiders, setAllRiders] = useState([]);

    const [loading] = useState(0);

    const { visitingClient } = getVisitingObject();

    const [currentGeo, setCurrentGeo] = useState(null);

    // effect to get geo data
    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position) {
                    setCurrentGeo({
                        coordinates: {
                            lng: position.coords.longitude,
                            lat: position.coords.latitude,
                        },
                    });
                }
            });
        } else {
            console.log('GEO Not Available');
        }
    }, []);

    useEffect(() => {
        socket.on('rider_update_location_duple', (res) => {
            const { doc } = res;
            if (doc) {
                console.log(doc);
                const { allRidersMeta } = doc;
                if (IS_SUPER_ADMIN()) {
                    handleMapAllUsers(allRidersMeta);
                } else if (visitingClient) {
                    handleMapOnlyClientRiders(allRidersMeta);
                }
            }
        });
    }, []);

    const handleMapOnlyClientRiders = (riders) => {
        const selectedRiders = riders.filter((x) => x.riderId === visitingClient._id);
        setAllRiders(selectedRiders);
    };

    const handleMapAllUsers = (riders) => {
        setAllRiders(riders.filter((x) => x.riderId));
    };

    // eslint-disable-next-line react/prop-types
    const Marker = ({ id, fullName, mobileNo }) => (
        <div>
            {/* <i className="fas fa-map-marker fa-2x" style={{ color: THEME_COLOR }}></i> */}
            <img id={`marker${id}`} src={RIDER_ICON_IMAGE} height={25} width={25} alt="" />
            {/* {showRiderPopoverBoxState.show && ( */}
            <UncontrolledPopover
                className="custom-popover"
                trigger="hover"
                target={`marker${id}`}
                placement="right"
                style={{ zIndex: 1000000 }}
            >
                <PopoverHeader className="custom-popover">{fullName || ''}</PopoverHeader>
                <PopoverBody>{mobileNo || ''}</PopoverBody>
            </UncontrolledPopover>
            {/* )} */}
        </div>
    );

    return (
        <>
            <div className="track-map-modal-container">
                <Modal size="xl" isOpen center toggle={toggleModal} className="modal-dialog modal-dialog-centered">
                    <ModalBody>
                        <div
                            style={{
                                justifyContent: 'space-between',
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div>
                                <h4>
                                    <b>Track Riders</b>
                                </h4>
                            </div>
                            <div>
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-form-btn shadow-lg nw-md pr-4 pl-4"
                                    type="submit"
                                    onClick={() =>
                                        handleNavigate({
                                            pathname: `${paths.ADVANCED_RIDER_TRACKING.DEFAULT}`,
                                        })
                                    }
                                >
                                    <>
                                        <i className="fas fa-map left-icon"></i>
                                        <span className="h6">Advanced Tracking</span>
                                    </>
                                </motion.button>
                            </div>
                        </div>
                        <br />

                        <div style={{ height: '70vh', width: '100%', ...centerElementContainerStyles }}>
                            {!loading > 0 && currentGeo && currentGeo.coordinates ? (
                                <GoogleMapReact
                                    defaultCenter={{
                                        lat: currentGeo.coordinates.lat,
                                        lng: currentGeo.coordinates.lng,
                                        // lat: 7.163373300000001,
                                        // lng: 80.566574,
                                    }}
                                    defaultZoom={12}
                                >
                                    {allRiders.map((marker, i) => (
                                        <Marker
                                            key={marker.riderId}
                                            lat={marker.coordinates.latitude}
                                            lng={marker.coordinates.longitude}
                                            id={marker.riderId}
                                            {...marker}
                                        />
                                    ))}
                                </GoogleMapReact>
                            ) : (
                                <div>
                                    <Spinner size={50} />
                                </div>
                            )}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
}

TrackRidersModal.propTypes = {
    toggleModal: PropTypes.func,
    socket: PropTypes.any,
    handleNavigate: PropTypes.any,
};

export default TrackRidersModal;
