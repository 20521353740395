import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import PermissionRow from '../PermissionRow';

function PermissionHeader(props) {
    const { headerRelevantData, title, selectedRole, handlePermissionState } = props;

    return (
        <>
            {(headerRelevantData || []).length > 0 && (
                <Card className="shadow default-card">
                    <CardHeader
                        className="border-bottom"
                        style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            backgroundColor: 'lightgrey',
                            borderTopRightRadius: 15,
                            borderTopLeftRadius: 15,
                        }}
                    >
                        {title}
                    </CardHeader>
                    <CardBody>
                        {headerRelevantData.map((data) => (
                            <PermissionRow
                                permissionData={data}
                                selectedRole={selectedRole}
                                handlePermissionState={(e, key) => handlePermissionState(e, key)}
                            />
                        ))}
                    </CardBody>
                </Card>
            )}
        </>
    );
}

PermissionHeader.propTypes = {
    headerRelevantData: PropTypes.any,
    handlePermissionState: PropTypes.any,
    title: PropTypes.any,
    selectedRole: PropTypes.any,
};

export default PermissionHeader;
