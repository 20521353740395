import {
    CREATE_STOCKS_RECEIVING,
    UPDATE_STOCKS_RECEIVING,
    DELETE_STOCKS_RECEIVING,
    UPDATE_REVERSE_STOCKS_RECEIVING,
    UPDATE_CONFIRM_STOCKS_RECEIVING,
    DELETE_STOCKS_RECEIVING_PERMANENT,
    RESTORE_STOCKS_RECEIVING,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Create Stocks Receiving Service
 * @param {object} data
 * @returns {Promise}
 */
export const createStocksReceivingService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_STOCKS_RECEIVING).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Stocks Receiving Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateStocksReceivingService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_STOCKS_RECEIVING).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Stocks Receiving Service
 * @param {string} id
 * @returns {Promise}
 */
export const deleteStocksReceivingService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_STOCKS_RECEIVING).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Reverse Stocks Receiving Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateReverseStocksReceivingService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_REVERSE_STOCKS_RECEIVING).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Confirm Stocks Receiving Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateConfirmStocksReceivingService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_CONFIRM_STOCKS_RECEIVING).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const deleteStocksReceivingPermanentService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_STOCKS_RECEIVING_PERMANENT).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Restore Stocks Receiving Service
 * @returns {Promise}
 */
export const restoreStocksReceivingService = (id) => {
    try {
        const url = UrlBuilder().setUrl(RESTORE_STOCKS_RECEIVING).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
