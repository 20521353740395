/* eslint-disable react/prop-types */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Row, Col, Card, CardBody, FormGroup, Alert, Label, Input } from 'reactstrap';
import toast from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import InputMask from 'react-input-mask';

// #region assets | components
import { Select, BackdropLoader, CustomToast, PlacesAutocompleteField, PlacesAutocompleteModal } from 'components';

// #region imports
import { verifyUserService } from 'pages/Auth/Verify-User/services';

// #region services
import { getUserByIdService, resendUserRegistrationVerificationCodeService } from 'services/user.service';
import { getAllCountries } from 'services/countries.service';

// #region utils
import { apiErrorHandler, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';

import { userFormValidation } from 'pages/Modules/User-Management/modules/User-Form/utils/validations';
import VerificationInputModal from './components/VerificationImputModal';
import { buildLocationData } from './utils';
import ToolTipDefault from 'components/Tooltip';

function MerchantAdmin(props) {
    const {
        merchantAdminFormData,
        setMerchantAdminFormData,
        adminFormRef,
        merchantFormRef,
        merchantData,
        isMerchantFormSubmitted,
        formLoading,
        editingMode,
        validated,
    } = props;

    // Component
    const [loading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [autoCompleteModal, setAutoCompleteModal] = useState({ type: null, show: false });

    const [alertMessage, setAlertMessage] = useState(null);

    // Form State
    const [isViewMode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [countries, setCountries] = useState([]);

    const [showVerification, setShowVerification] = useState(false);

    const [verificationToken, setVerificationToken] = useState(null);

    useEffect(() => {
        const timer1 = setTimeout(() => setAlertMessage(null), 4000);
        return () => {
            clearTimeout(timer1);
        };
    }, [alertMessage]);

    useEffect(() => {
        getAllCountriesService();
    }, []);

    const getAllCountriesService = async () => {
        const data = await getAllCountries();
        setCountries(data);
    };

    /*
     * Invoke admin form validations
     */
    const adminFormRefer = (ref) => {
        if (ref && isMerchantFormSubmitted) {
            setSubmitted(false);
            ref.setTouched(Object.keys(ref._inputs));
        }
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;

        if (id === 'nic' && value.indexOf(' ') >= 0) {
            toast.custom((t) => <CustomToast text="No Spaces Allowed for NRIC" t={t} type="error" />, {
                position: 'top-right',
            });
            return;
        }

        setMerchantAdminFormData({
            ...merchantAdminFormData,
            [id]: value,
        });
    };

    const handleInputChangeToken = (event) => {
        const { value } = event.target;
        setVerificationToken(value);
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);

        setMerchantAdminFormData({
            ...merchantAdminFormData,
            [id]: event ? event.value : null,
        });
    };

    const handleCheckChange = (event) => {
        setIsFormSaved(false);
        const { id, checked } = event.target;
        if (checked && id === 'isAddressMerchant' && merchantData.addressMeta) {
            setMerchantAdminFormData({
                ...merchantAdminFormData,
                [id]: checked,
                addressMeta: merchantData.addressMeta,
                address: merchantData.address,
                city: merchantData.city,
                postalCode: merchantData.postalCode,
                state: merchantData.state,
                country: merchantData.country,
            });
            return;
        }

        setMerchantAdminFormData({
            ...merchantAdminFormData,
            [id]: checked,
        });
    };

    const handleTokenSubmit = async () => {
        if (verificationToken) {
            setBackdropLoading((prevState) => prevState + 1);
            try {
                await verifyUserService({ id: verificationToken });
                toast.custom((t) => <CustomToast text="User Verified Successfully" t={t} type="success" />, {
                    position: 'top-right',
                });
                if (merchantFormRef && merchantFormRef.current) {
                    merchantFormRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            } catch (e) {
                console.log(e);
            }
            try {
                const { data } = await getUserByIdService(merchantAdminFormData._id);
                if (data) {
                    setMerchantAdminFormData({ ...merchantAdminFormData, ...data });
                    if (data) {
                        if (data.verification) {
                            try {
                                window.scroll({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                });
                            } catch (error) {
                                window.scrollTo(0, 0);
                            }
                        }
                    }
                }
            } catch (e) {
                console.log(e);
            }

            setShowVerification(false);
            setBackdropLoading((prevState) => prevState - 1);
        } else {
            toast.custom((t) => <CustomToast text="Enter Token" t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    const handleResendVerification = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await resendUserRegistrationVerificationCodeService({
                _id: merchantAdminFormData._id,
                email: merchantAdminFormData.email,
            });
            toast.custom((t) => <CustomToast text="Email Sent Check Email Inbox" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setBackdropLoading((prevState) => prevState - 1);
    };

    const returnLocationData = (id, idMeta, data) => {
        const builtData = buildLocationData(id, idMeta, data);
        setMerchantAdminFormData({ ...merchantAdminFormData, ...builtData });
    };

    return (
        <>
            {!merchantAdminFormData.verified && merchantAdminFormData._id && (
                <Alert color="info" style={{ padding: 3 }}>
                    <div className=" m-1 ml-3 pl-2 pr-2 align-items-center">
                        <div className="d-flex row">
                            <i className="fas fa-check-circle mr-2 fa-2x" style={{ color: '#17b978' }} />
                            <div style={{ fontSize: 15, marginTop: 4 }}>
                                A verification code has been sent to you <b>Email Account</b>{' '}
                            </div>
                        </div>
                        <hr />
                        <div className="mt-n1">
                            <div style={{ fontSize: 13, marginTop: 4 }}>
                                Please copy the link and paste in the verification dialog box . Click Verify Button to
                                open dialog box
                            </div>
                        </div>
                    </div>
                </Alert>
            )}

            <div ref={adminFormRef}>
                <Card className="mb-2" ref={adminFormRef}>
                    <CardBody>
                        {!merchantAdminFormData.verified && merchantAdminFormData._id && (
                            <Alert color="warning">User not verified!</Alert>
                        )}
                        {alertMessage && <Alert>{alertMessage}</Alert>}
                        <h4 className="card-title">Enter Admin details</h4>
                        <AvForm
                            autoComplete="off"
                            className="needs-validation default-form"
                            noValidate
                            validated={validated}
                            // onSubmit={handleSubmit}
                            id="userRegistrationForm"
                            ref={adminFormRefer}
                        >
                            <fieldset disabled={merchantAdminFormData.verified || isViewMode || formLoading}>
                                <input type="hidden" value="something" />

                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="email">E-Mail Address</Label>
                                            <AvField
                                                name="email"
                                                placeholder="E-mail"
                                                type="text"
                                                errorMessage="Enter a valid email address"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="email"
                                                value={merchantAdminFormData.email}
                                                onChange={handleInputChange}
                                                // disabled={editingMode}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="username">Username</Label>
                                            <AvField
                                                name="username"
                                                placeholder="Username"
                                                type="text"
                                                errorMessage="Required"
                                                className="form-control"
                                                validate={userFormValidation.username}
                                                id="username"
                                                value={merchantAdminFormData.username}
                                                onChange={handleInputChange}
                                                disabled={editingMode}
                                            />
                                        </FormGroup>
                                        <ToolTipDefault
                                            targetId="username"
                                            text="Allowed characters are [A-Z] , [0-9] , [ . _ ]"
                                            position="top"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="firstName">First name</Label>
                                            <AvField
                                                name="firstName"
                                                placeholder="First name"
                                                type="text"
                                                errorMessage="Required"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="firstName"
                                                value={merchantAdminFormData.firstName}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="lastName">Last name</Label>
                                            <AvField
                                                name="lastName"
                                                placeholder="Last name"
                                                type="text"
                                                errorMessage="Required"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="lastName"
                                                value={merchantAdminFormData.lastName}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="mobileNo">Mobile Number</Label>
                                            <AvField
                                                name="mobileNo"
                                                placeholder="Contact No"
                                                mask="+60 99-999 99999"
                                                maskChar=""
                                                tag={[Input, InputMask]}
                                                errorMessage="Required"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="mobileNo"
                                                value={merchantAdminFormData.mobileNo}
                                                onChange={handleInputChange}
                                                disabled={editingMode}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="nic">NRIC Number</Label>
                                            <InputMask
                                                mask="999999-99-9999"
                                                value={merchantAdminFormData.nic}
                                                className="form-control"
                                                name="nic"
                                                errorMessage="Please provide a valid NRIC."
                                                onChange={handleInputChange}
                                                required
                                                validate={{ required: { value: true } }}
                                                id="nic"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mb-3" style={{ paddingLeft: 30 }}>
                                    <Label htmlFor="customCheck2" className="form-check-label">
                                        Address Same As Merchant
                                    </Label>
                                    <Input
                                        onChange={handleCheckChange}
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={merchantAdminFormData.isAddressMerchant}
                                        defaultChecked={merchantAdminFormData.isAddressMerchant}
                                        id="isAddressMerchant"
                                    />
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <Row>
                                            <Col md="10" sm="10" xs="10">
                                                <FormGroup>
                                                    <Label htmlFor="address">Address</Label>
                                                    <PlacesAutocompleteField
                                                        addressData={merchantAdminFormData.addressMeta || null}
                                                        returnLocationData={returnLocationData}
                                                        id="address"
                                                        idMeta="addressMeta"
                                                        isRequired
                                                        submitted={submitted}
                                                        validated={validated}
                                                        isClearable
                                                    />
                                                    {autoCompleteModal.type === 'address' &&
                                                        autoCompleteModal.show &&
                                                        !isViewMode && (
                                                            <PlacesAutocompleteModal
                                                                show={autoCompleteModal.show}
                                                                isModal
                                                                addressData={merchantAdminFormData.addressMeta || null}
                                                                returnLocationData={returnLocationData}
                                                                toggle={() =>
                                                                    setAutoCompleteModal({
                                                                        show: !autoCompleteModal.show,
                                                                        type: 'address',
                                                                    })
                                                                }
                                                                id="address"
                                                                idMeta="addressMeta"
                                                            />
                                                        )}
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" sm="2" xs="2">
                                                <motion.div
                                                    style={{ width: 35, height: 35 }}
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="scootix-btn-circle shadow-lg nw-md ml-3 order-map-pin-container"
                                                    onClick={() =>
                                                        setAutoCompleteModal({
                                                            show: !autoCompleteModal.show,
                                                            type: 'address',
                                                        })
                                                    }
                                                >
                                                    <div className="map-pin">
                                                        <i className="ri-map-pin-line fa-lg" />
                                                    </div>
                                                </motion.div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="postalCode">Postal Code</Label>
                                            <AvField
                                                name="postalCode"
                                                placeholder="Postal Code"
                                                type="Number"
                                                minLength={5}
                                                maxLength={5}
                                                errorMessage="Please provide a valid Postal Code."
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="postalCode"
                                                value={merchantAdminFormData.postalCode}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="city">City</Label>
                                            <AvField
                                                name="city"
                                                placeholder="City"
                                                type="text"
                                                errorMessage="Please provide a valid city."
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="city"
                                                value={merchantAdminFormData.city}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="state">State</Label>
                                            <AvField
                                                name="state"
                                                placeholder="State"
                                                type="text"
                                                errorMessage="Please provide a valid state."
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="state"
                                                value={merchantAdminFormData.state}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="country">Country</Label>
                                            <Select
                                                value={getDefaultValueForSelect(merchantAdminFormData.country)}
                                                options={countries.map((country) => ({
                                                    label: country.details.name,
                                                    value: country.details.name,
                                                }))}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'country')}
                                                submitted={submitted}
                                                required
                                                validated={validated}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </fieldset>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>

            {merchantAdminFormData._id && editingMode && !merchantAdminFormData.verified && (
                <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                    onClick={() => handleResendVerification()}
                >
                    <span>Resend Verification</span>
                </motion.button>
            )}
            {merchantAdminFormData._id && editingMode && !merchantAdminFormData.verified && (
                <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                    onClick={() => setShowVerification(true)}
                >
                    <span>Verify User</span>
                </motion.button>
            )}

            {showVerification && (
                <VerificationInputModal
                    title="Merchant Admin Verification"
                    show={showVerification}
                    onConfirm={handleTokenSubmit}
                    onCancel={() => setShowVerification(false)}
                    handleInputChangeToken={handleInputChangeToken}
                    verificationToken={verificationToken}
                    cancelMessage="Back"
                />
            )}

            <BackdropLoader show={backdropLoading || loading} />
        </>
    );
}

MerchantAdmin.propTypes = {};

export default MerchantAdmin;
