export const binLocationEmbeddedHeaders = [
    {
        key: 'referenceNumber',
        value: 'Bin ID',
    },
    {
        key: 'binLocationName',
        value: 'Bin Location Name',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
    {
        key: 'updatedAt',
        value: 'Updated At',
        type: 'dateTime',
    },
    {
        key: 'createdBy',
        value: 'Created By',
        type: 'userObject',
    },
    {
        key: 'updatedBy',
        value: 'Updated By',
        type: 'userObject',
    },
];

// Warehouse Zone transactions table header
const headersForTransactionTable = [
    {
        key: 'sequence',
        value: 'TRANSACTION ID',
    },
    {
        key: 'reference',
        value: 'TRANSACTION REFERENCE',
    },
    {
        key: 'transactionName',
        value: 'TRANSACTION',
    },
    {
        key: 'createdByUserFullName',
        value: 'USER',
    },
    {
        key: 'createdAt',
        value: 'TRANSACTION DATE AND TIME',
    },
];

/**
 * Initial table data for the Transaction table
 */
export const initialTransactionTableData = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: headersForTransactionTable,
    docs: [],
    all: false,
    sort: null,
    order: -1,
};
