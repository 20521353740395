/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Col, Form, Input, Row } from 'reactstrap';
import '../styles/index.scss';

// #region assets | components
import { CheckCircle } from 'components/Icons';
import CrossCircle from 'components/Icons/CrossCircle';

// #region utils

// #endregion imports
import ListTable from './containers/ListTable';
import { selectMerchantsInitialValues } from './containers/ListTable/constants';

/**
 * Merchant selection modal for route planning
 *
 * @param {array} allMerchantIds - All merchant details array
 * @param {array} selectedMerchants - Selected values for merchants
 * @param {func} setSelectedMerchants -  Set dropdown selected value arrays for merchants
 * @param {func} toggleModal - Toggles the modal
 * @returns {JSX.Element} A React component that displays the add merchant modal.
 */
function SelectMerchantModule(props) {
    const { allMerchantIds, selectedMerchants, setSelectedMerchants, toggleModal } = props;

    // "selectedMerchantData" stores checked records from the modal table
    const [selectedMerchantData, setSelectedMerchantData] = useState(selectedMerchants);
    // array containing all the merchants
    const [allMerchantData] = useState(allMerchantIds);
    // formatted payload for ListTable module
    const [tableData, setTableData] = useState({
        ...selectMerchantsInitialValues,
        totalDocs: allMerchantIds.length,
        docs: allMerchantIds,
    });
    // search box text
    const [filterText, setFilterText] = useState('');

    // Used to store filtered records only such as searched records
    const [filteredRecords, setFilteredRecords] = useState(null);

    /**
     * Generate payload for ListTable module by marking the selected merchant objects
     * does the same if filteredRecords if available
     */
    useEffect(() => {
        try {
            if (Array.isArray(selectedMerchantData)) {
                const updatedSelectedDocs = tableData.docs.map((val) => {
                    const isSelectedType = selectedMerchantData.find((merchant) => merchant._id === val._id);
                    return {
                        ...val,
                        isSelected: !!isSelectedType,
                    };
                });
                setTableData({ ...tableData, docs: updatedSelectedDocs });

                // if filteredRecords available show them in table
                if (filteredRecords) {
                    const updatedFilteredDocs = filteredRecords.map((val) => {
                        const isSelectedType = selectedMerchantData.find((merchant) => merchant._id === val._id);
                        return {
                            ...val,
                            isSelected: !!isSelectedType,
                        };
                    });
                    setFilteredRecords(updatedFilteredDocs);
                }
            }
        } catch (e) {
            toast.error('Something went wrong');
        }
    }, [selectedMerchantData]);

    /**
     * Handles the click event of the "Add Selected" button.
     * Adds the selected delivery types to the route planning form.
     * @returns {void}
     */
    const handleAddSelected = () => {
        setSelectedMerchants(selectedMerchantData);
        toggleModal();
    };

    /**
     * Handles search functionality
     * @param {*} event - Text box change event
     */
    const handleSearch = (event) => {
        const text = event.target.value;

        try {
            setFilterText(text.toLowerCase());

            // filtered merchant data array of merchant objects
            const filteredMerchantArray = allMerchantData.filter(
                (item) =>
                    item.name.toLowerCase().indexOf(text) !== -1 ||
                    item.referenceNumber.toLowerCase().indexOf(text) !== -1
            );
            const selectedMerchantsData = selectedMerchantData;

            // create final table data by adding filtered merchant
            const filteredData = filteredMerchantArray.map((merchant) => {
                const isMerchantSelected = selectedMerchantsData.find(
                    (selectedMerchant) => selectedMerchant._id.toLowerCase() === merchant._id.toLowerCase()
                );
                return {
                    ...merchant,
                    isSelected: !!isMerchantSelected,
                };
            });
            setFilteredRecords(filteredData.length === 0 ? null : filteredData);
        } catch (e) {
            toast.error('Something went wrong');
        }
    };

    return (
        <>
            <div className="font-size-14 w-100 p-0">
                <div className="modal__form-table">
                    <Row>
                        <Col className="pl-0">
                            <Form className="table__custom__search-right d-lg-block">
                                <div className="position-relative">
                                    <Input
                                        type="text"
                                        value={filterText}
                                        className="form-control"
                                        placeholder="Search Merchant by ID or Merchant name"
                                        onChange={handleSearch}
                                    />
                                    <span className="ri-search-line search-icon"></span>
                                </div>
                            </Form>
                        </Col>
                    </Row>

                    <ListTable
                        tableData={tableData}
                        isSearch={false}
                        selectedData={selectedMerchantData}
                        setSelectedData={setSelectedMerchantData}
                        allRecords={allMerchantIds || null}
                        filteredRecords={filteredRecords}
                    />

                    <Row>
                        <Col xs="auto">
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn shadow-lg "
                                style={{ borderRadius: 10 }}
                                onClick={handleAddSelected}
                            >
                                <CheckCircle color="#fff" />
                                &nbsp;Add Selected
                            </motion.div>
                        </Col>
                        <Col xs="auto">
                            <motion.div
                                className="remove__btn form-delete-btn shadow-lg"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                style={{ borderRadius: 10 }}
                                onClick={toggleModal}
                            >
                                <CrossCircle color="#FFF" />
                                &nbsp;Close
                            </motion.div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

SelectMerchantModule.propTypes = {
    allMerchantIds: PropTypes.array,
    toggleModal: PropTypes.func,
    selectedMerchants: PropTypes.any,
    setSelectedMerchants: PropTypes.func,
};

export default SelectMerchantModule;
