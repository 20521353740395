/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { clone } from 'lodash';
import PropTypes from 'prop-types';
import { Col, Form, Input, Row } from 'reactstrap';

// #region assets | components
import { TableCustom } from 'components';

/**
 * List Table Container
 * @returns {React.Component}
 */
function ListTable(props) {
    const { tableData, isSearch, setSelectedData, filteredRecords, onViewDelivery } = props;

    /**
     * Handle On Select
     * @param {object} record
     */
    const handleOnSelect = (record, checked) => {
        if (!record || !tableData.docs) {
            return;
        }
        const { docs } = tableData;

        // If Checked then add to selected and remove from table
        if (checked) {
            const tableRecords = clone(docs);
            const itemIndex = tableRecords.findIndex((x) => {
                // if _id is a property
                if (x._id) {
                    return x._id === record._id;
                }
                // if _id doesn't exists check label
                return x.label === record.label;
            });
            tableRecords[itemIndex] = { ...record, isSelected: true };
            setSelectedData(tableRecords.filter((x) => x.isSelected));
        }
        // If Unchecked add to table and remove from selected
        else {
            const tableRecords = clone(docs);
            const itemIndex = tableRecords.findIndex((x) => {
                // if _id is a property
                if (x._id) {
                    return x._id === record._id;
                }
                // if _id doesn't exists check label
                return x.label === record.label;
            });
            tableRecords[itemIndex] = { ...record, isSelected: false };
            setSelectedData(tableRecords.filter((x) => x.isSelected));
        }
    };

    return (
        <Row>
            {isSearch && (
                <Col lg={4}>
                    <Form className="app-search">
                        <div className="position-relative">
                            <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter the Delivery ID or Merchant ID to Search"
                                // onChange={handleFilter}
                            />
                            <span className="ri-search-line search-icon"></span>
                        </div>
                    </Form>
                </Col>
            )}

            <TableCustom
                tableData={{ ...tableData, docs: filteredRecords || tableData.docs }}
                showPagination={false}
                removeAction
                isSelectable
                removeId
                isFilter={false}
                isSearch={false}
                selectStyles={{ direction: 'right' }}
                isSelectHeaderText
                isResponsive
                isFixedHeight
                onSelect={handleOnSelect}
                onViewDelivery={onViewDelivery}
            />
        </Row>
    );
}

ListTable.propTypes = {
    isSearch: PropTypes.bool,
    tableData: PropTypes.any,
    setSelectedData: PropTypes.func,
    filteredRecords: PropTypes.any,
    onViewDelivery: PropTypes.func,
};

export default ListTable;
