/* eslint-disable react-hooks/exhaustive-deps */
import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import { Row, Container } from 'reactstrap';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import * as qs from 'query-string';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';

// #region assets | components
import { CustomToast } from 'components';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #region other imports
import paths from 'routes/paths';

// #endregion imports
import { getShippingLabelPrintData } from '../services';
import { availableDateFilters, defaultListData, filters } from './constant';
import QuickPrintContainer from '../Quick-Print/index';
import Header from './containers/Header';
import ListViewTable from './containers/ListViewTable';
import { breadcrumbItemsShippingLabel } from '../constant';

function LabelPrintListPage(props) {
    const { location } = props;

    const history = useHistory();

    const [loading, setLoading] = useState(0);

    const [urlParamsSearch, setUrlParamsSearch] = useState({});

    const [searchParams, setSearchParams] = useState(null);

    const [shippingLabelData, setShippingLabelData] = useState(defaultListData);

    const defaultSearchParamsHandler = {
        page: searchParams?.page,
        searchBy: searchParams?.searchBy,
        searchText: searchParams?.searchText,
        searchByDate: searchParams?.searchByDate,
        searchDate: searchParams?.searchDate,
    };

    useEffect(() => {
        if (urlParamsSearch) {
            loadShippingLabelData({
                showLoading: true,
                ...shippingLabelData,
                searchBy: { searchBy: urlParamsSearch?.searchBy, searchText: urlParamsSearch?.searchText },
                searchByDate: { searchByDate: urlParamsSearch?.searchByDate, searchDate: urlParamsSearch?.searchDate },
                type: urlParamsSearch.type,
                page: urlParamsSearch.page,
            });
        }
    }, [urlParamsSearch]);

    useEffect(() => {
        const urlSearch = qs.parse(location.search);
        if (urlSearch && (urlSearch.searchText || urlSearch.page)) {
            const { page, searchBy, searchText, searchByDate, searchDate } = urlSearch;
            setUrlParamsSearch({ page, searchBy, searchText, searchByDate, searchDate });
            setSearchParams({ searchBy, searchText, searchByDate, searchDate });
        } else {
            replaceUrlParamsHandler({ page: '1', tab: 'all', status: 'all' });
        }
    }, [location.search]);

    const loadShippingLabelData = (args) => {
        if (args.showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getShippingLabelPrintData({ ...args })
            .then((res) => {
                const { data } = res;
                delete data.sort;
                setShippingLabelData({
                    ...shippingLabelData,
                    ...data,
                });
            })
            .catch((e) => {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            })
            .finally(() => {
                if (args.showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    /**
     * Manipulating Url with search Filters
     * @param {field, filterText } data
     * @returns {replaceUrlParamsHandler}
     */

    const handleFilter = (field, filterText) => {
        let modifiedFilterText = null;
        if (filterText && filterText !== '') {
            modifiedFilterText = filterText.replaceAll(' ', '$2');
        } else if (!filterText || filterText === '') {
            modifiedFilterText = null;
        }

        // Date Filters
        if (availableDateFilters.includes(field)) {
            if (modifiedFilterText) {
                replaceUrlParamsHandler({
                    ...defaultSearchParamsHandler,
                    searchByDate: field,
                    searchDate: String(modifiedFilterText),
                });
            } else {
                replaceUrlParamsHandler({
                    ...defaultSearchParamsHandler,
                    searchByDate: null,
                    searchDate: null,
                });
            }
            return;
        }
        if (field || filterText) {
            replaceUrlParamsHandler({
                ...defaultSearchParamsHandler,
                searchBy: field || 'sequenceId',
                searchText: modifiedFilterText,
                page: '1',
            });
        }
    };

    const handlePageChange = (pageNo) => {
        replaceUrlParamsHandler({
            ...defaultSearchParamsHandler,
            page: pageNo,
        });
    };

    const replaceUrlParamsHandler = (params = { page: '1', searchBy: null, searchText: null }) => {
        try {
            history.replace({
                search: `${
                    params.page || urlParamsSearch.page ? `page=${params.page || urlParamsSearch.page || '1'}&` : ''
                }${params?.searchBy ? `searchBy=${params.searchBy}&` : ''}${
                    params?.searchText ? `searchText=${params.searchText}&` : ''
                }${params?.searchByDate ? `searchByDate=${params.searchByDate}&` : ''}${
                    params?.searchDate ? `searchDate=${params.searchDate}&` : ''
                }`,
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleViewRow = (e, row) => {
        history.push({
            pathname: `/${paths.LABEL_PRINT.SHIPPING_LABEL.BATCH_PRINT_VIEW}`,
            search: `?id=${row._id}`,
            state: { batchPrintParentData: row },
        });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItemsShippingLabel} />
                    <Header />
                    <QuickPrintContainer />
                    <Row className="m-2" style={{ justifyContent: 'end' }}>
                        <motion.button
                            id="reload-comp-btn"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-form-btn circle shadow-lg nw-md pr-2 pl-3 pointer m3-3 mr-3"
                            onClick={() => {
                                replaceUrlParamsHandler({
                                    tab: 'all',
                                    status: 'all',
                                    page: '1',
                                    searchBy: null,
                                    searchText: null,
                                });
                            }}
                        >
                            <>Reset Filters</>
                        </motion.button>
                    </Row>
                    <br />
                    <Row>
                        <ListViewTable
                            shippingLabelData={shippingLabelData}
                            filters={filters}
                            loading={loading}
                            searchParams={searchParams}
                            handleFilter={handleFilter}
                            handlePageChange={handlePageChange}
                            handleViewRow={handleViewRow}
                        />
                    </Row>
                </Container>
            </div>
        </>
    );
}

LabelPrintListPage.propTypes = {
    location: PropTypes.any,
};

const HotLabelPrintListPage = hot(LabelPrintListPage);

export default HotLabelPrintListPage;
