/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

// #region assets | components
import { CustomToast, TableCustom } from 'components';

// #region services
import { disconnectAppService, getCreatedAppList } from 'services/partner-api.service';

// # region utils
import { apiErrorHandlerV2 } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import DisconnectAppModal from './modals/disconnectAppModal';
import { mapDocs } from './utils';
import { constantAppsTableHeaders } from './constants';

function ConnectedApps(props) {
    const { merchantId, location } = props;

    /** Whether the connected app list is being fetched */
    const [appsDataLoading, setAppsDataLoading] = useState(false);

    /** Data for the connected apps table */
    const [connectedAppList, setConnectedAppList] = useState({
        matchedCount: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: constantAppsTableHeaders,
        docs: [],
        status: ['active'],
    });

    /** state holds data and state for showing the disconnect model */
    const [showModal, setShowModal] = useState({ data: null, state: false });

    /** Trigger the fetching of webhooks list */
    useEffect(() => {
        loadConnectedAppsList();
    }, [merchantId, location.search]);

    /** Call the service and fetch the list of connected apps from the API */
    const loadConnectedAppsList = async (status, search) => {
        setAppsDataLoading(true);

        if (!merchantId) return;

        if (!location.search.includes('tab=connectedApps')) {
            setAppsDataLoading(false);
            return;
        }

        try {
            const { data } = await getCreatedAppList(merchantId, status || connectedAppList.status, search);
            const { payload } = data;
            if (data && payload) {
                const mappedDocs = mapDocs(payload);
                setConnectedAppList((prev) => ({ ...prev, ...payload, docs: mappedDocs }));
            } else {
                toast.custom((t) => <CustomToast text="Failed to load webhooks list" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setAppsDataLoading(false);
    };

    /** function gets triggered when user trying to disconnect the app */
    const handleDisconnectApp = async (data) => {
        const handlerResponse = {};
        try {
            const { data: responseData } = await disconnectAppService(data._id, data?.password);
            const { payload } = responseData;
            if (data && payload.status === false) {
                toast.custom((t) => <CustomToast text="Successfully disconnected the app" t={t} type="success" />, {
                    position: 'top-right',
                });
            } else {
                toast.custom((t) => <CustomToast text="Failed to disconnect the app" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            // reload the list view
            loadConnectedAppsList();
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
            handlerResponse.error = true;
        }
        return handlerResponse;
    };

    /** Handle table search and trigger fetching data again */
    const handleFilter = (field, filterText) => {
        let modifiedFilterText = null;
        if (filterText && filterText !== '') {
            modifiedFilterText = filterText.replaceAll(' ', '$2');
        } else if (!filterText || filterText === '') {
            modifiedFilterText = null;
        }

        loadConnectedAppsList(undefined, modifiedFilterText);
    };

    /** Change status according to the checkbox in the table header, and trigger fetching data */
    const handleCheckChange = (checkbox, value) => {
        let newStatus;
        if (value === true) {
            if (connectedAppList.status.includes(checkbox)) return;
            newStatus = [...connectedAppList.status, checkbox];
            setConnectedAppList((prev) => ({ ...prev, status: newStatus }));
        } else {
            newStatus = connectedAppList.status.filter((status) => status !== checkbox);
            setConnectedAppList((prev) => ({ ...prev, status: newStatus }));
        }
        loadConnectedAppsList(newStatus);
    };

    /** showing the model when clicked on a app disconnect action button */
    const handleDisconnectButton = (row) => {
        setShowModal({ data: row, state: !showModal.state });
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Card className="shadow default-card">
                            <CardHeader
                                className="border-bottom pl-5"
                                style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}
                            >
                                <Row>
                                    <Col>Manage Connected Apps</Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <TableCustom
                                    tableData={connectedAppList}
                                    loading={appsDataLoading}
                                    onFilter={handleFilter}
                                    customActionHandlers={{ handleDisconnectButton }}
                                    removeAction
                                    isFixedHeight
                                    showApiCustomAction={hasAccess(PERMISSIONS.CONNECTED_APPS, ['Edit'])}
                                    tableHeaderType="CONNECTED_APPS"
                                    connectedAppsHeaderProps={{
                                        handleFilter,
                                        handleCheckChange,
                                        checked: connectedAppList.status,
                                    }}
                                    showPagination={false}
                                    filters={[
                                        {
                                            key: 'name',
                                            label: 'Name',
                                        },
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {showModal.state && (
                    <DisconnectAppModal
                        show={showModal.state}
                        handleSubmit={handleDisconnectApp}
                        handleToggle={handleDisconnectButton}
                        data={showModal.data}
                    />
                )}
            </Container>
        </>
    );
}

ConnectedApps.propTypes = {
    merchantId: PropTypes.string.isRequired,
    location: PropTypes.any,
};

export default ConnectedApps;
