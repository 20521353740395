// Route Planning Entry Constants
const headersForRoutePlanningEntryTable = [
    {
        key: 'sequenceId',
        value: 'Route Planning ID',
    },
    {
        key: 'merchantReferenceNumber',
        value: 'Merchant ID',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
        style: ['increase-width'],
        customHeaderStyles: {
            minWidth: 250,
        },
    },
    {
        key: 'totalDeliveryCount',
        value: 'Total Deliveries',
    },
    {
        key: 'deliveredDeliveryCount',
        value: 'Delivered',
    },
    {
        key: 'pendingDeliveryCount',
        value: 'Pending',
    },
    {
        key: 'riderName',
        value: 'Rider',
    },
    {
        key: 'deliveryDate',
        value: 'Delivery Date',
    },
    {
        key: 'plannedBy',
        value: 'Planned By',
    },
    {
        key: 'plannedAt',
        value: 'Planned At',
    },
    {
        key: 'status',
        value: 'Status',
        type: 'routePlanningStatusLabel',
    },
    {
        key: 'action',
        value: 'Action',
        type: 'customActionButton',
        style: ['centerElements'],
        params: { buttonLabel: 'Manage', customActionHandlerName: 'navigateToViewRoutePlan' },
    },
];

export const initialRouteListTableData = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: headersForRoutePlanningEntryTable,
    docs: [],
    all: false,
    sort: null,
    order: -1,
};
