export const defaultTableHeaders = [
    {
        key: 'name',
        value: 'Name',
        isAvatar: true,
        isImage: true,
        imageProp: 'profilePictureUrl',
    },
    {
        key: 'username',
        value: 'Username',
    },
    {
        key: 'roleName',
        value: 'Role',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
        sortingEnabled: true,
    },
    {
        key: 'email',
        value: 'E-Mail',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
        sortingEnabled: true,
    },
    {
        key: 'status',
        value: 'Status',
        type: 'UPPERCASE',
    },
];
