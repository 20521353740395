export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Reference',
    },
    {
        key: 'name',
        value: 'Name',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
    {
        key: 'country',
        value: 'Country',
    },
    {
        key: 'address',
        value: 'Address',
    },
    {
        key: 'primaryContactNumber',
        value: 'Contact Number',
    },
    {
        key: 'businessRegistrationNo',
        value: 'Business Registration No',
    },
];
