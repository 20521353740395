import { useState, useEffect, useRef } from 'react';

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function useMarkerMove(initialValue, { delta = 100, delay = 20 } = {}) {
    const [currentPosition, setCurrentPosition] = useState(initialValue);
    const [currentDeltaPosition, setCurrentDeltaPosition] = useState([0, 0]);
    // eslint-disable-next-line prefer-const
    let [currentDelta, setCurrentDelta] = useState(null);

    useInterval(
        () => {
            // eslint-disable-next-line no-plusplus
            setCurrentDelta(++currentDelta);
            setCurrentPosition([
                currentPosition[0] + currentDeltaPosition[0],
                currentPosition[1] + currentDeltaPosition[1],
            ]);
        },
        currentDelta !== null && currentDelta !== delta ? delay : null
    );

    function setPosition(coordinates) {
        console.log('coordinates', coordinates);
        const [latitude, longitude] = coordinates;
        setCurrentDelta(0);
        setCurrentDeltaPosition([(latitude - currentPosition[0]) / delta, (longitude - currentPosition[1]) / delta]);
    }

    return [currentPosition, setPosition];
}

export default useMarkerMove;
