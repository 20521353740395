/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { toast } from 'react-hot-toast';
import {
    Alert,
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row,
    Spinner,
} from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

// #region assets | components
import { Breadcrumbs, CustomToast, TableCustom } from 'components';
import CheckCircleIcon from 'components/Icons/Check-Circle';
import LeftArrowIcon from 'components/Icons/LeftArrow';
import ConnectIcon from 'components/Icons/Connect';
import DisconnectIcon from 'components/Icons/Disconnect';

// #region services
import {
    updateWebhook,
    getWebhookDataById,
    enableWebhook,
    disableWebhook,
    viewAuthValue,
} from 'services/partner-api.service';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';

// #region other imports
import PATHS from 'routes/paths';

// #endregion imports
import { getPartnerApiWebhookTransactionsService } from 'services/transaction.service';
import AccessDenied from 'pages/Utility/AccessDenied';
import { PERMISSIONS } from 'utils/constants';
import { TRANSACTION_REFERENCE } from 'constants/enums';
import EnableWebhookModal from './components/EnableWebhookModal';
import DisableWebhookModal from './components/DisableWebhookModal';
import AuthValueModal from './components/AuthValueModal';
import { emptyUpdateFormValues } from '../constants';
import { transactionInitialValues, transactionsLogTableHeaders } from './constants';
import { mapWebhookTransactions } from '../../Api-Portal/Webhook/utils';
import WebhookTransactionData from '../../Api-Service/Partner-List/components/webhookTransactionDataModel';

function ManageWebhookPage(props) {
    const history = useHistory();

    const { location } = props;
    const state = location?.state || {};
    const { webhookData, merchantId, merchantName } = state;

    if (!state || !webhookData) {
        history.push({
            pathname: `/${PATHS.DEVELOPER.API_PORTAL}`,
            search: '?tab=webhook',
        });
    }

    /** State for storing entered form data */
    const [formData, setFormData] = useState({ ...emptyUpdateFormValues, ...webhookData });
    /** Whether the submit operation is in progress  */
    const [inProgress, setInProgress] = useState(false);
    /** Whether the webhook data is being fetched */
    const [webhookDataLoading, setWebhookDataLoading] = useState(false);
    /** Whether the transaction data is being fetched */
    const [transactionDataLoading] = useState(false);

    const [modalOptions, setModalOptions] = useState({ modal: null, data: {} });

    /** Whether to show 'enable' modal or 'disable' modal or 'apiKey' modal or none (null) */
    const [modalVisible, setModalVisible] = useState(null);
    /** Data for the transactions log table */
    const [transactionData, setTransactionData] = useState([]);

    const breadcrumbItems = [
        { title: 'Scootix', link: PATHS.HOME.DEFAULT },
        { title: 'API Portal', link: `/${PATHS.DEVELOPER.API_PORTAL}` },
        { title: merchantName, link: '#' },
        { title: 'Webhook', link: '#' },
        { title: 'Manage Webhook', link: '#' },
    ];

    /** On page load, fetch the webhook data from the API */
    useEffect(() => {
        if (webhookData && webhookData._id) {
            getWebhookData(webhookData._id);
            loadWebhookTransactions();
        }
    }, [webhookData]);

    /** Function to fetch the webhook data from the API */
    const getWebhookData = async (id) => {
        setWebhookDataLoading(true);

        try {
            const { data } = await getWebhookDataById(id);
            if (data?.payload) {
                setFormData((prev) => ({ ...prev, ...data.payload }));
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setWebhookDataLoading(false);
    };

    // Side Effect to load transactions
    // works on an interval basis
    useEffect(() => {
        const interval = setInterval(() => {
            if (webhookData._id) {
                loadWebhookTransactions();
            }
        }, 20000);
        return () => clearInterval(interval);
    }, [webhookData]);

    const loadWebhookTransactions = async () => {
        if (formData._id) {
            const { data } = await getPartnerApiWebhookTransactionsService(formData._id);
            if (data) {
                if (data && Array.isArray(data)) {
                    setTransactionData(data);
                }
            }
        }
    };

    // * Makes one of the modals visible. Modals shown from here could be either
    //  * the Transaction Data modal or the Disable Reason modal.
    //  * This function sets the 'modalOptions' state which contains the name of the modal to be
    //  * shown and the data to be shown in it.
    //  * @param {object} data - data in the clicked row
    //  */
    const displayModal = (data) => {
        const { meta } = data;

        if (!meta) {
            return;
        }

        // show transaction data modal
        if (data.reference === TRANSACTION_REFERENCE.PARTNER_API_WEBHOOK.UPDATE_WEBHOOK) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    name: data.name,
                    url: data.meta.postUrl,
                    eventGroup: data.meta.eventGroup,
                    previousPostUrl: data.meta.previousPostUrl,
                    previousWebhookName: data.meta.previousWebhookName,
                },
            });
        }
    };

    /**
     * Change the modalOptions state so modals can be shown or hidden.
     * See comments on modalOptions state.
     * @param {string} modalName - name of the modal to show - falsey value hides modal
     */
    const handleTransactionModalToggle = (modalName) => {
        if (modalName) {
            setModalOptions((prev) => ({ ...prev, modal: modalName }));
        } else {
            setModalOptions((prev) => ({ ...prev, modal: null }));
        }
    };

    /** Function to call the update service with correct data */
    const handleSubmit = async () => {
        setInProgress(true);

        const reqBody = {
            merchantId,
            name: formData.name,
            postUrl: formData.postUrl,
            eventGroup: formData.eventGroup,
            password: formData.password,
        };

        try {
            const response = await updateWebhook(formData._id, reqBody);
            const { data } = response;
            const { payload } = data;
            if (data && payload) {
                toast.custom((t) => <CustomToast text="Webhook updated successfully!" t={t} type="success" />, {
                    position: 'top-right',
                });
            } else {
                toast.custom((t) => <CustomToast text="Failed to update webhook" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setInProgress(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    /** Handle changing of dropdown inputs */
    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    /**
     * Navigate to the documentation site.
     */
    const handleDocsLinkClick = () => {
        window.open('https://docs.scootix.com.my', '_blank');
    };

    /** Navigate back to the webhooks page */
    const handleBackButton = () => {
        history.push({
            pathname: `/${PATHS.DEVELOPER.API_PORTAL}`,
            state,
            search: '?tab=webhook',
        });
    };

    /** Toggle the modals for enabling/disabling the webhook */
    const handleModalToggle = (modalName) => {
        if (modalName) {
            setModalVisible(modalName);
        } else {
            setModalVisible(null);
        }
    };

    /**
     * Call the services to enable/disable API keys. Invoked from the enable/disable modals.
     * @param {string} newStatus - whether to 'enable' or 'disable' the key
     * @param {object} modalData - should contain the password. If disabling, should contain a reason as well
     * @returns {object} - if this contains an 'error' field, the modal will not close automatically
     */
    const handleStatusModalSubmit = async (newStatus, modalData) => {
        setWebhookDataLoading(true);

        try {
            let response;
            if (newStatus === 'enable') {
                response = await enableWebhook(formData._id, modalData);
            }
            if (newStatus === 'disable') {
                response = await disableWebhook(formData._id, modalData);
            }

            const { data } = response;
            const { payload } = data;

            if (data && payload) {
                setFormData((prevData) => ({ ...prevData, ...payload }));
                toast.custom((t) => <CustomToast text="Webhook status changed succesfully!" t={t} type="success" />, {
                    position: 'top-right',
                });
                setWebhookDataLoading(false);
                return { success: true };
            }

            // If not returned, it means error
            toast.custom((t) => <CustomToast text="Failed to change the webhook status" t={t} type="error" />, {
                position: 'top-right',
            });
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setWebhookDataLoading(false);
        return { error: true };
    };

    /** Calls the service and retrieves the auth value from the API */
    const handleViewAuthValue = async (modalFormData) => {
        try {
            const response = await viewAuthValue(formData._id, modalFormData);
            const { data } = response;
            const { payload } = data;
            const { apiKey } = payload;
            if (data && payload && apiKey) {
                toast.custom((t) => <CustomToast text="Validated password!" t={t} type="success" />, {
                    position: 'top-right',
                });
                return { apiKey };
            }

            // If not returned, it means error
            toast.custom((t) => <CustomToast text="Failed to retrieve API key" t={t} type="error" />, {
                position: 'top-right',
            });
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        return { error: true };
    };

    /**
     * Update the auth value of the webhook
     * @param {object} modalFormData - contains the newValue and the password
     */
    const handleUpdateAuthValue = async (modalFormData) => {
        const reqBody = { systemAuthValue: modalFormData.newValue, password: modalFormData.password };

        try {
            const response = await updateWebhook(formData._id, reqBody);
            const { data } = response;
            const { payload } = data;
            if (data && payload) {
                toast.custom((t) => <CustomToast text="Webhook updated successfully!" t={t} type="success" />, {
                    position: 'top-right',
                });
                return { success: true };
            }

            // If not returned, it means error
            toast.custom((t) => <CustomToast text="Failed to update webhook" t={t} type="error" />, {
                position: 'top-right',
            });
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        return { error: true };
    };

    if (!hasAccess(PERMISSIONS.PARTNER_API_WEBHOOK, ['View'])) {
        return <AccessDenied pageName="API Dashboard" />;
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                <Row>
                    <Col>
                        <Card className="shadow default-card">
                            <CardHeader className="pl-5">
                                <Row>
                                    <Col xs="auto" style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}>
                                        {webhookData && formData.name}
                                    </Col>
                                    <Col xs="auto">
                                        {formData.isActive ? (
                                            <Badge
                                                className="ml-4 p-1 px-2"
                                                color="success"
                                                style={{ backgroundColor: '#26C60C' }}
                                            >
                                                ACTIVE
                                            </Badge>
                                        ) : (
                                            <Badge className="ml-4 p-1 px-2" color="danger">
                                                INACTIVE
                                            </Badge>
                                        )}
                                        {webhookDataLoading && <Spinner size="sm" className="ml-4" />}
                                    </Col>
                                    <Col className="text-muted ml-5">
                                        <span>Need Help? </span>
                                        <span
                                            role="button"
                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                            onClick={handleDocsLinkClick}
                                        >
                                            Check out the docs
                                        </span>
                                    </Col>
                                    <Col>
                                        <Button
                                            className="scootix-btn-radius"
                                            onClick={handleBackButton}
                                            style={{ marginLeft: 'auto', borderRadius: 10 }}
                                        >
                                            <LeftArrowIcon color="#FFF" />
                                            Back to Webhooks
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="px-5">
                                <AvForm
                                    className="needs-vaildation default-form"
                                    noValidate
                                    onValidSubmit={handleSubmit}
                                >
                                    <Row>
                                        <Col>
                                            {hasAccess(PERMISSIONS.PARTNER_API_WEBHOOK, ['EnableAndDisable']) && (
                                                <Alert color="warning" className="p-4">
                                                    {formData.isActive ? (
                                                        <Button
                                                            style={{
                                                                backgroundColor: '#FD4A4A',
                                                                borderRadius: 10,
                                                                borderColor: '#FD4A4A',
                                                            }}
                                                            onClick={() => setModalVisible('disable')}
                                                            disabled={webhookDataLoading}
                                                        >
                                                            <DisconnectIcon color="#FFF" />
                                                            &nbsp;Disable
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            style={{
                                                                backgroundColor: '#4AB054',
                                                                borderRadius: 10,
                                                                borderColor: '#4AB054',
                                                            }}
                                                            onClick={() => setModalVisible('enable')}
                                                            disabled={webhookDataLoading}
                                                        >
                                                            <ConnectIcon color="#FFF" />
                                                            &nbsp;Enable
                                                        </Button>
                                                    )}
                                                </Alert>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label htmlFor="name" className="text-muted">
                                                    Name
                                                </Label>
                                                <AvField
                                                    name="name"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                    validate={{ required: { value: true } }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label htmlFor="postUrl" className="text-muted">
                                                    Post URL
                                                </Label>
                                                <AvField
                                                    name="postUrl"
                                                    type="text"
                                                    errorMessage="Please add valid URL"
                                                    className="form-control"
                                                    pattern="^(http(s)?:\/\/)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$"
                                                    value={formData.postUrl}
                                                    onChange={handleInputChange}
                                                    validate={{ required: { value: true } }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        {(formData.authenticationType === 'apiKey' ||
                                            formData.authenticationType === 'bearer') && (
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label htmlFor="systemAuthValue" className="text-muted">
                                                        Your System Authorization Value
                                                    </Label>
                                                    <InputGroup className="align-items-center">
                                                        <Input
                                                            name="systemAuthValue"
                                                            type="text"
                                                            className="form-control"
                                                            value={formData.systemAuthValue}
                                                            onChange={handleInputChange}
                                                            disabled
                                                        />
                                                        <Badge
                                                            pill
                                                            style={{
                                                                backgroundColor: '#3A3937',
                                                                marginLeft: '0.5rem',
                                                                paddingTop: '0.5rem',
                                                                paddingBottom: '0.5rem',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleModalToggle('apiKey')}
                                                            type="button"
                                                        >
                                                            View or Update
                                                        </Badge>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label htmlFor="eventGroup" className="text-muted">
                                                    Event Group
                                                </Label>
                                                <AvField name="eventGroup" type="select" onChange={handleSelectChange}>
                                                    <option value="deliveryUpdates">Delivery Updates</option>
                                                </AvField>
                                            </FormGroup>
                                        </Col>
                                        {/* <Col md={4}>
                                            <FormGroup>
                                                <Label htmlFor="authenticationType" className="text-muted">
                                                    Authentication Type
                                                </Label>
                                                <AvField
                                                    name="authenticationType"
                                                    type="select"
                                                    onChange={handleSelectChange}
                                                    value={formData.authenticationType}
                                                    disabled
                                                >
                                                    {authenticationTypes.map((authType) => (
                                                        <option value={authType.value}>{authType.name}</option>
                                                    ))}
                                                </AvField>
                                            </FormGroup>
                                        </Col> */}
                                        {formData.authenticationType === 'apiKey' && (
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label htmlFor="systemAuthKey" className="text-muted">
                                                        Your System Authorization Key
                                                    </Label>
                                                    <AvField
                                                        name="systemAuthKey"
                                                        type="text"
                                                        className="form-control"
                                                        value={formData.systemAuthKey}
                                                        onChange={handleInputChange}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        {/* <Col md={4}>
                                            <FormGroup>
                                                <Label htmlFor="addTo" className="text-muted">
                                                    Add to
                                                </Label>
                                                <AvField
                                                    name="addTo"
                                                    type="select"
                                                    disabled
                                                    value={formData.addTo}
                                                    onChange={handleSelectChange}
                                                >
                                                    {addToValues.map((val) => (
                                                        <option value={val.value}>{val.name}</option>
                                                    ))}
                                                </AvField>
                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Alert color="warning" style={{ borderRadius: 8 }}>
                                                Please enter your password to update the webhook.
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <AvField
                                                    name="password"
                                                    type="password"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={formData.password}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="auto">
                                            {hasAccess(PERMISSIONS.PARTNER_API_WEBHOOK, ['EnableAndDisable']) && (
                                                <Button
                                                    className="scootix-btn-radius"
                                                    type="submit"
                                                    disabled={inProgress || webhookDataLoading}
                                                    style={{ borderRadius: 10 }}
                                                >
                                                    {inProgress ? (
                                                        <Spinner
                                                            size="sm"
                                                            color="warning"
                                                            className="spinner-white mx-1"
                                                        />
                                                    ) : (
                                                        <CheckCircleIcon color="#FFF" />
                                                    )}
                                                    <span className="mr-3">&nbsp;Update</span>
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <hr />

                <Row className="my-4">
                    <Col>
                        <span className="font-weight-bold">Transactions Log</span>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <TableCustom
                            tableData={{
                                ...transactionInitialValues,
                                docs: mapWebhookTransactions(transactionData, formData),
                                headers: transactionsLogTableHeaders,
                            }}
                            loading={transactionDataLoading}
                            removeAction
                            showApiWebhookAction
                            customActionHandlers={{ displayModal }}
                            isSearch={false}
                            isFilter={false}
                            showPagination={false}
                            isFixedHeight
                        />
                    </Col>
                </Row>

                <Row>
                    <EnableWebhookModal
                        show={modalVisible === 'enable'}
                        handleToggle={handleModalToggle}
                        handleSubmit={(data) => handleStatusModalSubmit('enable', data)}
                    />
                    <WebhookTransactionData
                        show={modalOptions.modal === 'transaction'}
                        handleToggle={handleTransactionModalToggle}
                        transactionId={modalOptions.data.transactionId}
                        name={modalOptions.data.name}
                        eventGroup={modalOptions.data.eventGroup}
                        postUrl={modalOptions.data.url}
                        previousWebhookName={modalOptions.data.previousWebhookName}
                        previousPostUrl={modalOptions.data.previousPostUrl}
                    />
                    <DisableWebhookModal
                        show={modalVisible === 'disable'}
                        handleToggle={handleModalToggle}
                        handleSubmit={(data) => handleStatusModalSubmit('disable', data)}
                    />
                    <AuthValueModal
                        show={modalVisible === 'apiKey'}
                        handleToggle={handleModalToggle}
                        handleRetrieveKey={handleViewAuthValue}
                        handleUpdateAuthValue={handleUpdateAuthValue}
                    />
                </Row>
            </Container>
        </div>
    );
}

ManageWebhookPage.propTypes = {
    location: PropTypes.any,
};

const HotManageWebhookPage = hot(ManageWebhookPage);

export default connect(null, {})(HotManageWebhookPage);
