/** This suppose to build query filters based on the business logics in the assign rider modal
 */
export const buildQueryFilters = (deliveries, formData) => {
    const queryFilters = {
        all: 1,
        ...(deliveries && { vehicleType: deliveries[0]?.vehicleType }),
    };

    const firstMerchantId = deliveries[0].merchant._id;

    const limitedToMerchant = deliveries?.some((obj) => obj.merchant.isLimitDeliveriesToMerchantRider === true);

    const isSingleMerchant = deliveries?.every((obj) => obj.merchant._id === firstMerchantId);

    if (isSingleMerchant) {
        queryFilters.merchantId = firstMerchantId;
    }

    if (formData?.isOnline) {
        queryFilters.isOnline = '1';
    }
    if (formData?.isActive) {
        queryFilters.isActive = '1';
    }
    if (formData?.isOffline) {
        queryFilters.isOffline = '1';
    }
    if (!formData?.isOffline && !formData.isOnline) {
        queryFilters.isOffline = '0';
        queryFilters.isOnline = '0';
    }

    if (limitedToMerchant) {
        queryFilters.isLimitedToMerchant = '1';
    }
    if (!limitedToMerchant) {
        queryFilters.isLimitedToMerchant = '0';
    }

    return queryFilters;
};

/** This suppose to build query data for assigning rider
 */
export const buildRiderAssignRoutePlanData = (deliveries, formData, locationMeta) => {
    const { deliveryDate, riderId } = formData;
    const mapDeliveries = deliveries.map((doc) => ({
        _id: doc._id,
        merchantOrderNo: doc.merchantOrderNo,
        referenceNumber: doc.referenceNumber,
        merchantId: doc.merchantId,
    }));
    const buildData = {
        deliveryDate,
        riderId,
        deliveries: mapDeliveries,
        startDestination: locationMeta.startDestination,
        endDestination: locationMeta.endDestination,
    };

    return buildData;
};

/** This suppose to build query data for generate route plan
 */
export const buildGenerateRoutePlanData = (deliveries, formData) => {
    const mapDeliveries = deliveries.map((doc) => ({
        pickupLocationMeta: { coordinates: doc.pickupLocationMeta?.coordinates },
        deliveryAddressMeta: {
            coordinates: doc.deliveryAddressMeta?.coordinates,
        },
        pickupLocation: doc.pickupLocation,
        deliveryAddress: doc.deliveryAddress,
        merchantOrderNo: doc.merchantOrderNo,
        referenceNumber: doc.referenceNumber,
        isCompleted: doc.isCompleted,
        isPickedUp: doc.isPickedUp,
        pickedUpAt: doc.pickedUpAt,
        completedAt: doc.completedAt,
        _id: doc._id,
    }));
    const buildData = {
        deliveryDate: formData?.plannedDeliveryDate || null,
        riderId: formData.riderId,
        deliveries: mapDeliveries,
    };

    return buildData;
};

/** This suppose to build query data for generate route plan
 */
export const buildGenerateRidersRouteData = (riders, formData) => {
    const riderIds = riders.map((obj) => obj._id);

    const buildData = {
        deliveryDate: formData?.plannedDeliveryDate || new Date(),
        riderIds,
    };

    return buildData;
};

/** Validate the relevant properties wether are there before going to submit the form  */
export const validateRiderAssign = (formData, selectedDeliveries) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { deliveryDate, riderId } = formData;

    if (!deliveryDate) {
        return {
            isFormValid,
            message: 'You must have to select a delivery date to proceed further',
        };
    }
    if (!riderId) {
        return {
            isFormValid,
            message: 'Please select a Rider/Driver to assign the deliveries',
        };
    }
    if (selectedDeliveries.length === 0) {
        return {
            isFormValid,
            message: 'Please Select at least a delivery before proceed further',
        };
    }
    isFormValid = true;
    return { isFormValid, message };
};
