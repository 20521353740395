/** delivery date options /** */
const generateDeliveryDays = (startIndex) => {
    const deliveryDays = [
        { label: 'Same Day', value: 0 },
        { label: 'Next Day', value: 1 },
        { label: '+ 2 Days', value: 2 },
        { label: '+ 3 Days', value: 3 },
        { label: '+ 4 Days', value: 4 },
        { label: '+ 5 Days', value: 5 },
    ];

    return deliveryDays.slice(startIndex);
};

export const newDeliveryDays = generateDeliveryDays(0);

export const nextDeliveryDays = generateDeliveryDays(1);

/** Data for the tabs component /** */
export const daysTabs = [
    {
        tabKey: 'monday',
        tabNumber: 1,
        tabName: 'Monday',
    },
    {
        tabKey: 'tuesday',
        tabNumber: 2,
        tabName: 'Tuesday',
    },
    {
        tabKey: 'wednesday',
        tabNumber: 3,
        tabName: 'Wednesday',
    },
    {
        tabKey: 'thursday',
        tabNumber: 4,
        tabName: 'Thursday',
    },
    {
        tabKey: 'friday',
        tabNumber: 5,
        tabName: 'Friday',
    },
    {
        tabKey: 'saturday',
        tabNumber: 6,
        tabName: 'Saturday',
    },
    {
        tabKey: 'sunday',
        tabNumber: 7,
        tabName: 'Sunday',
    },
];
