/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Label, Container, Spinner, DropdownItem } from 'reactstrap';
import * as qs from 'query-string';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// #region assets | components
import Breadcrumbs from 'components/Common/Breadcrumb';
import {
    Select,
    ClientAlerts,
    CustomToast,
    FormStatusBtn,
    ScootixModal,
    BackdropLoader,
    CreatableAdvanced,
    TableCustom,
} from 'components';
import { centerElementInnerStyles, SubTitle } from 'styles';
import PageDisabled from 'pages/Utility/PageDisabled';

// #region utils
import { reactSelectCustomStyles, apiErrorHandler } from 'utils/helpers';
import { getVisitingObject, hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import { DEBUG_MODE } from 'utils/checks';

// #region services
import { getAllWarehouseSitesService } from 'services/warehouse-site.service';
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';
import { getAllMerchantLocationByMerchantId } from 'services/merchant-location.service';
import { getAllSystemDataByTypeAndModuleService } from 'services/system-data.service';
import { getAllStocksByStocksReceivingIdService } from 'services/stocks';

// #region imports
import PATHS from 'routes/paths';
import { SYSTEM_DATA_TYPES } from 'constants/enums';
import { getTransactionsByStockReceivingId } from 'services/stocks-receiving';

// #endregion imports
import StocksReceivingItemsFormPage from './Items';
import { mapTransactionTableDocs, validateForm } from './utils';
import {
    createStocksReceivingService,
    updateStocksReceivingService,
    deleteStocksReceivingService,
    updateReverseStocksReceivingService,
    updateConfirmStocksReceivingService,
} from '../services';
import { handleCreateSelectChange } from './functions';
import { initialTransactionTableData } from './constants';
import StockReceivingTransactionModal from '../components';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Stocks Receiving', link: PATHS.STOCKS_RECEIVING.DEFAULT },
];

function StocksReceivingFormPage(props) {
    const { location } = props;

    const reduxState = useSelector((state) => state);

    const history = useHistory();

    const { visitingClient } = getVisitingObject();

    const initialFormValues = {
        country: visitingClient?.country || 'Malaysia',
    };

    const _isMounted = useRef(true);

    // prev comp
    const [prevCompState, setPrevCompState] = useState({});

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [customLoading, setCustomLoading] = useState({ stocksFrom: false });

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);

    const [allStocks, setAllStocks] = useState([]);
    const [allStocksReceivingItems, setAllStocksReceivingItems] = useState([]);
    const [isReversedAllowed, setIsReversedAllowed] = useState(false);
    const [stocksFromArray, setStocksFromArray] = useState([]);
    const [isConfirmedAllowed, setIsConfirmedAllowed] = useState(false);

    const [isItemsFormSaved, setIsItemsFormSaved] = useState(true);

    // const [imageUploadModalState, setImageUploadModalState] = useState({
    //     show: false,
    //     type: null,
    //     data: null,
    //     header: null,
    //     title: null,
    //     subTitle: null,
    // });

    // const [attachmentPreviewModalState, setAttachmentPreviewModalState] = useState({
    //     show: false,
    //     multiple: false,
    //     imageUrl: null,
    // });

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    // other
    const [merchants, setMerchants] = useState([]);
    const [merchantLocations, setMerchantLocations] = useState([]);
    const [warehouseSites, setWarehouseSites] = useState([]);

    // Global
    const [stocksReceivingSetting, setStocksReceivingSetting] = useState(null);
    // transaction table data
    const [transactionTableData, setTransactionTableData] = useState(initialTransactionTableData);
    // Loading state for the table
    const [isLoadingStockReceivingData, setIsLoadingStockReceivingData] = useState(false);
    // transaction modal data
    const [showTransactionModalData, setShowTransactionModalData] = useState({});
    // show transaction modal
    const [showTransactionModal, setShowTransactionModal] = useState(false);

    useEffect(() => {
        if (!location) {
            history.goBack();
            return;
        }

        const formType = qs.parse(location.search);
        if (formType && formType.type && formType.type === 'new') {
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Stocks Receiving', link: '#' }]);
        }

        if (formType && formType.type && formType.type === 'edit') {
            handleUpdateComponentEditMode();
        }

        if (formType && formType.type && formType.type === 'view') {
            handleUpdateComponentViewMode();
        }

        const { prevCompState: prevCompStateProp = null } = location.state || {};
        if (prevCompStateProp) {
            setPrevCompState(prevCompStateProp);
        }
    }, []);

    useEffect(() => {
        if (reduxState?.Global) {
            const { clientSetting: clientSettingState } = reduxState.Global;
            if (clientSettingState && _isMounted.current) {
                if (clientSettingState.stocksReceiving) {
                    setStocksReceivingSetting(clientSettingState.stocksReceiving);
                }
            }
        }
    }, [reduxState.Global.clientSetting]);

    useEffect(() => {
        loadWarehouseSites();
        loadStocksFrom();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (formData._id) {
            loadStocksByStocksReceivingId(formData._id);
        }
    }, [formData._id]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                if (_isMounted.current) {
                    const { data } = res;
                    if (data.docs) {
                        setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                    }
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                if (_isMounted.current) {
                    const { data } = res;
                    if (data.docs) {
                        setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                    }
                }
            });
        }
    }, []);

    useEffect(() => {
        formData.merchantId && loadAllMerchantLocations(formData.merchantId);
    }, [formData.merchantId]);

    useEffect(() => {
        loadStocksReceivingTransactions();
    }, [formData._id]);

    useEffect(() => {
        if (allStocks.length > 0 && allStocksReceivingItems.length > 0) {
            let showReverseButton = true;
            allStocksReceivingItems.map((val) => {
                const stockItem = val;
                const relevantStock = allStocks.find((x) => x.stocksReceivingItemId === stockItem._id);
                if (relevantStock && relevantStock.quantity !== stockItem.quantity) {
                    showReverseButton = false;
                }
                return val;
            });
            setIsReversedAllowed(showReverseButton);
        }
    }, [allStocks, allStocksReceivingItems]);

    const handleUpdateComponentViewMode = () => {
        try {
            !location.state && history.goBack();
            const { stocksReceivingParentData } = location.state;
            setFormData({ ...formData, ...stocksReceivingParentData });
            setIsViewMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'View Stocks Receiving', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpdateComponentEditMode = () => {
        try {
            !location.state && history.goBack();
            const { stocksReceivingParentData } = location.state;
            setFormData({ ...formData, ...stocksReceivingParentData });
            const { prevCompState: prevCompStateProp = null } = location.state || {};
            // Update URL Based on Condition
            handleUpdateUrl(prevCompStateProp, stocksReceivingParentData, 'stocksReceivingParentData');
            if (prevCompStateProp && prevCompStateProp.refreshUser) {
                // This code is executed only during page reload
                // loadUserById(stocksReceivingParentData._id);
            }
            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Stocks Receiving', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    // Update Address Bar URL
    // PATH | QUERY | STATE
    const handleUpdateUrl = (prevState, data, id) => {
        // Handle Remove PrevComponent
        try {
            history.replace({
                pathname: `${PATHS.STOCKS_RECEIVING.FORM_VIEW}`,
                search: `?type=edit&id=${data._id}`,
                state: {
                    [id]: data,
                    prevCompState: { ...prevState, refreshPage: true },
                },
            });
        } catch (e) {
            console.log(e);
        }
    };

    const loadStocksFrom = async () => {
        try {
            const { data } = await getAllSystemDataByTypeAndModuleService(
                SYSTEM_DATA_TYPES.STOCKS_RECEIVING.TYPES.STOCKS_FROM,
                SYSTEM_DATA_TYPES.STOCKS_RECEIVING.MODULE
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                setStocksFromArray(data.map((x) => ({ ...x, label: x.name, value: x.name })));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadAllMerchantLocations = async (id) => {
        try {
            const { data } = await getAllMerchantLocationByMerchantId(id);
            if (_isMounted.current && data && data.docs) {
                setMerchantLocations(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.merchantLocationName || ''} - ${x.location || ''}`,
                        key: x._id,
                    }))
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadStocksReceivingTransactions = async () => {
        setIsLoadingStockReceivingData(true);
        if (formData._id) {
            const { data } = await getTransactionsByStockReceivingId(formData._id);
            const mappedData = mapTransactionTableDocs(data);
            setTransactionTableData({ ...transactionTableData, docs: mappedData });
        }
        setIsLoadingStockReceivingData(false);
    };

    const loadWarehouseSites = async () => {
        try {
            const { data } = await getAllWarehouseSitesService();
            if (_isMounted.current && data && data.docs) {
                setWarehouseSites(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.siteName}`,
                        key: x._id,
                    }))
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onHandleCreateChange = (record, id) => {
        handleCreateSelectChange(
            record,
            id,
            setIsFormSaved,
            setCustomLoading,
            setStocksFromArray,
            stocksFromArray,
            setFormData,
            formData
        );
    };

    const handleSubmit = async (event, errors) => {
        event.persist();
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }

        if (!isItemsFormSaved) {
            toast.custom((t) => <CustomToast text="Save Items to Proceed" t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);
        try {
            let responseData;
            if (editingMode) {
                const { data } = await updateStocksReceivingService(formData);
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            } else {
                const { data } = await createStocksReceivingService(formData);
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            }
            setEditingMode(true);

            try {
                // Replace URL so relevant changes can be done
                if (responseData) {
                    history.replace({
                        pathname: `${PATHS.STOCKS_RECEIVING.FORM_VIEW}`,
                        search: `?type=edit&id=${responseData._id}`,
                        state: {
                            stocksReceivingParentData: responseData,
                            prevCompState,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} Stocks Receiving`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setIsFormSaved(true);
        } catch (e) {
            console.log(e);
            toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                position: 'top-right',
            });
        }

        loadStocksReceivingTransactions();
        setFormLoading(false);
        setSubmitted(false);
    };

    const handleConfirmStocks = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await updateConfirmStocksReceivingService(formData);
            if (data) {
                setFormData({ ...formData, ...data });
            }
            toast.custom((t) => <CustomToast text="Successfully Confirmed" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((prevState) => prevState - 1);
        loadStocksByStocksReceivingId(formData._id);
        loadStocksReceivingTransactions();
        toggleScootixModal();
    };

    const handleReverseStocks = async () => {
        setBackdropLoading((prevState) => prevState + 1);

        try {
            const { data } = await updateReverseStocksReceivingService(formData);
            if (data) {
                setFormData({ ...formData, ...data });
            }
            toast.custom((t) => <CustomToast text="Successfully Reversed" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((prevState) => prevState - 1);
        loadStocksByStocksReceivingId(formData._id);
        loadStocksReceivingTransactions();
        toggleScootixModal();
    };

    const handleOnDelete = () => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            message: 'Are you sure you want to proceed?',
            onConfirm: () => handleDeleteStocksReceiving(),
        });
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    const handleOnConfirm = () => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            message: 'Are you sure you want to proceed?',
            onConfirm: () => handleConfirmStocks(),
        });
    };

    const handleOnReverse = () => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            message: 'Are you sure you want to proceed?',
            onConfirm: () => handleReverseStocks(),
        });
    };

    const handleDeleteStocksReceiving = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteStocksReceivingService(formData._id);
            toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                position: 'top-right',
            });
            history.push({
                pathname: PATHS.STOCKS_RECEIVING.DEFAULT,
            });
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        loadStocksReceivingTransactions();
        setBackdropLoading((prevState) => prevState - 1);
        toggleScootixModal();
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);

        if (event) {
            if (id === 'merchantId' && event) {
                const relevantMerchant = merchants.find((x) => x._id === event.value);
                if (relevantMerchant) {
                    setFormData({
                        ...formData,
                        merchantName: relevantMerchant.name || '',
                        stocksFrom: null,
                        merchantLocationId: null,
                        warehouseSiteId: null,
                        warehouseSiteName: null,
                        [id]: event ? event.value : null,
                    });
                }
                return;
            }

            if (id === 'merchantLocationId') {
                const relevantObj = merchantLocations.find((x) => x._id === event.value);
                if (relevantObj) {
                    setFormData({
                        ...formData,
                        stocksFrom: relevantObj.location,
                        [id]: event ? event.value : null,
                    });
                }
                return;
            }

            if (id === 'warehouseSiteId') {
                const relevantObj = warehouseSites.find((x) => x._id === event.value);
                if (relevantObj) {
                    setFormData({
                        ...formData,
                        warehouseSiteName: relevantObj.siteName,
                        [id]: event ? event.value : null,
                    });
                }
                return;
            }

            setFormData({
                ...formData,
                [id]: event ? event.value : null,
            });
        } else {
            if (id === 'merchantId') {
                setFormData({
                    ...formData,
                    merchantName: null,
                    warehouseSiteId: null,
                    warehouseSiteName: null,
                    [id]: null,
                });
                setMerchantLocations([]);
                return;
            }
            setFormData({
                ...formData,
                [id]: null,
            });
        }
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const getMerchantSelect = (_id) => {
        // eslint-disable-next-line no-console
        DEBUG_MODE() && console.log('select-func');
        if (_id) {
            const relevantObj = merchants.find((x) => x._id === _id);
            if (relevantObj) {
                return { label: relevantObj.name, value: relevantObj._id };
            }
        }
        return null;
    };

    const getWarehouseSiteSelect = (_id) => {
        // eslint-disable-next-line no-console
        DEBUG_MODE() && console.log('select-func');
        if (_id) {
            const relevantObj = warehouseSites.find((x) => x._id === _id);
            if (relevantObj) {
                return {
                    label: `${relevantObj.siteName}`,
                    value: relevantObj._id,
                };
            }
        }
        return null;
    };

    const getStocksFromSelect = (val) => {
        // eslint-disable-next-line no-console
        DEBUG_MODE() && console.log('select-func');
        if (val) {
            const relevantObj = stocksFromArray.find((x) => x.name === val);
            if (relevantObj) {
                return {
                    label: `${relevantObj.name}`,
                    value: relevantObj._id,
                };
            }
        }
        return null;
    };

    const loadStocksReceivingItems = (allRows) => {
        setAllStocksReceivingItems(allRows || []);
        if (allRows.length > 0) {
            const isAllRecordsHaveId = allRows.every((x) => x._id);
            if (isAllRecordsHaveId) {
                setIsConfirmedAllowed(true);
            }
        }
    };

    const loadStocksByStocksReceivingId = async (id) => {
        setIsLoadingStockReceivingData(true);
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getAllStocksByStocksReceivingIdService(id);
            if (_isMounted.current && data && data.docs) {
                setAllStocks(data.docs);
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
        setIsLoadingStockReceivingData(false);
    };

    /**
     * Handles the transaction modal type and their visibility
     * @param {object} data - data comes from the row of the table
     * @void
     */
    const toggleTransactionModal = (data) => {
        setShowTransactionModalData(data);
        setShowTransactionModal(!showTransactionModal);
    };
    const displayModal = (row) => {
        toggleTransactionModal(row);
    };

    // ** Memos

    const selectedMerchantId = useMemo(() => getMerchantSelect(formData.merchantId), [formData.merchantId, merchants]);

    const selectedWarehouseSiteId = useMemo(
        () => getWarehouseSiteSelect(formData.warehouseSiteId),
        [formData.warehouseSiteId, warehouseSites]
    );

    const selectedStocksFrom = useMemo(
        () => getStocksFromSelect(formData.stocksFrom),
        [formData.stocksFrom, stocksFromArray]
    );

    if (stocksReceivingSetting && !stocksReceivingSetting.isEnabled) {
        return <PageDisabled message="Stocks Adjustment in Progress" type="stockAdjustmentDisabled" />;
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={
                            isViewMode
                                ? 'View Stocks Receiving'
                                : editingMode
                                ? 'Edit Stocks Receiving'
                                : 'New Stocks Receiving'
                        }
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Row style={{ justifyContent: 'space-between' }}>
                        {!editingMode && (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                onClick={() => {
                                    history.push({
                                        pathname: PATHS.STOCKS_RECEIVING.DEFAULT,
                                    });
                                }}
                            >
                                <i className="far fa-times-circle mr-2 fa-lg" />
                                Cancel
                            </motion.div>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FormStatusBtn isFormSaved={isFormSaved} />
                        </div>
                        {editingMode && (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                onClick={() => {
                                    history.push({
                                        pathname: PATHS.STOCKS_RECEIVING.DEFAULT,
                                    });
                                }}
                            >
                                <i className="fas fa-arrow-left mr-2 fa-lg" />
                                Back to Stocks Receiving
                            </motion.div>
                        )}
                    </Row>
                    <ClientAlerts />

                    <Card style={!visitingClient ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
                        <CardBody>
                            <Row className="d-flex justify-content-between ml-3 mr-3 ml-n2 mb-2">
                                {/* <h4 className="card-title">Enter user details</h4> */}
                                <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                    STOCKS RECEIVING DETAILS
                                </SubTitle>
                            </Row>
                            <AvForm
                                autoComplete="off"
                                className="needs-validation default-form"
                                noValidate
                                onSubmit={handleSubmit}
                                id="stocksReceivingDataForm"
                            >
                                <input type="hidden" value="something" />
                                <fieldset disabled={isViewMode || formLoading}>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="merchantId">Merchant</Label>
                                                <Select
                                                    value={selectedMerchantId}
                                                    isClearable
                                                    options={merchants}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                    submitted={submitted}
                                                    validated={validated}
                                                    required
                                                    isDisabled={allStocksReceivingItems.length > 0}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="stocksFrom">Stocks From</Label>
                                                <CreatableAdvanced
                                                    options={stocksFromArray}
                                                    isClearable
                                                    value={selectedStocksFrom}
                                                    submitted={submitted}
                                                    validated={validated}
                                                    required
                                                    styles={reactSelectCustomStyles}
                                                    id="stocksFrom"
                                                    isLoading={customLoading.stocksFrom}
                                                    onChange={(event) => handleSelectChange(event, 'stocksFrom')}
                                                    onCreate={(event) => onHandleCreateChange(event, 'stocksFrom')}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label htmlFor="deliveryNoteReference">Delivery Note Reference</Label>
                                                <AvField
                                                    name="deliveryNoteReference"
                                                    placeholder="Delivery Note Reference"
                                                    type="text"
                                                    errorMessage="Enter a valid description"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="deliveryNoteReference"
                                                    value={formData.deliveryNoteReference}
                                                    onChange={handleInputChange}
                                                    disabled={formData.isApproved}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label htmlFor="additionalDetails">Additional Details</Label>
                                                <AvField
                                                    name="additionalDetails"
                                                    placeholder="Additional Details"
                                                    type="text"
                                                    errorMessage="Enter a valid description"
                                                    className="form-control"
                                                    id="additionalDetails"
                                                    value={formData.additionalDetails}
                                                    onChange={handleInputChange}
                                                    disabled={formData.isApproved}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="warehouseSiteId">Warehouse Site</Label>
                                                <Select
                                                    value={selectedWarehouseSiteId}
                                                    options={warehouseSites.map((x) => ({
                                                        label: x.label,
                                                        value: x.key,
                                                    }))}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'warehouseSiteId')}
                                                    submitted={submitted}
                                                    validated={validated}
                                                    isDisabled={allStocksReceivingItems.length > 0}
                                                    required
                                                    isClearable
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ justifyContent: 'flex-start' }}>
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                            type="submit"
                                            disabled={formLoading}
                                        >
                                            {formLoading ? (
                                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                            ) : (
                                                <>
                                                    <i className="fas fa-save left-icon"></i>
                                                    <span className="h6">{editingMode ? 'Update' : 'Save'}</span>
                                                </>
                                            )}
                                        </motion.button>
                                    </Row>
                                </fieldset>
                            </AvForm>
                            <Row className="d-flex justify-content-between ml-n1 mb-2 mt-4">
                                <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}>
                                    ITEMS
                                </SubTitle>
                            </Row>
                            <DropdownItem divider className="mt-0" />
                            <br />
                            {formData._id && (
                                <StocksReceivingItemsFormPage
                                    stocksReceivingData={formData}
                                    loadStocksReceivingItems={loadStocksReceivingItems}
                                    setBackdropLoading={setBackdropLoading}
                                    setIsItemsFormSaved={setIsItemsFormSaved}
                                />
                            )}
                        </CardBody>
                    </Card>

                    <br />

                    {(editingMode || isViewMode) && (
                        <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                            <CardBody>
                                <div
                                    style={{
                                        justifyContent: 'space-between',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'col' }}>
                                        <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}>
                                            Options
                                        </SubTitle>
                                    </div>
                                    {!isViewMode && (
                                        <div className="d-flex row">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'col',
                                                    ...centerElementInnerStyles,
                                                }}
                                            >
                                                {!formData.isConfirmed &&
                                                    !formData.isUsed &&
                                                    hasAccess(PERMISSIONS.STOCKS_RECEIVING, ['Delete']) && (
                                                        <motion.div
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.9 }}
                                                            className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                                            onClick={(e) => handleOnDelete(e, formData)}
                                                        >
                                                            <i className="fas fa-trash mr-2 fa-lg" />
                                                            Delete
                                                        </motion.div>
                                                    )}
                                                {allStocksReceivingItems.length > 0 &&
                                                    !formData.isConfirmed &&
                                                    isConfirmedAllowed &&
                                                    hasAccess(PERMISSIONS.STOCKS_RECEIVING, ['Confirm']) && (
                                                        <motion.div
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.9 }}
                                                            className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                                            onClick={(e) => handleOnConfirm(e)}
                                                        >
                                                            <i className="fas fa-check mr-2 fa-lg" />
                                                            Confirm Stocks
                                                        </motion.div>
                                                    )}
                                                {isReversedAllowed &&
                                                    formData.isConfirmed &&
                                                    hasAccess(PERMISSIONS.STOCKS_RECEIVING, ['Reverse']) && (
                                                        <motion.div
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.9 }}
                                                            className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                                            onClick={(e) => handleOnReverse(e)}
                                                        >
                                                            <i className="fas fa-arrow-left mr-2 fa-lg" />
                                                            Reverse Stocks
                                                        </motion.div>
                                                    )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <hr />
                            </CardBody>
                        </Card>
                    )}

                    {editingMode && (
                        <>
                            <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                                <div className="route-planning__form-header pl-3">
                                    <Row
                                        className="flex align-items-center align-content-center"
                                        style={{ height: 60 }}
                                    >
                                        <Col className="header-text">Transaction Log</Col>
                                    </Row>
                                </div>
                                <CardBody className="px-3">
                                    <Row>
                                        <TableCustom
                                            tableData={transactionTableData}
                                            loading={isLoadingStockReceivingData}
                                            showDelete={false}
                                            showPagination={false}
                                            showView={false}
                                            removeId
                                            isFullScreenShow={false}
                                            isFilter={false}
                                            isSearch={false}
                                            removeAction
                                            isFixedHeight
                                            showApiCustomAction
                                            customActionHandlers={{ displayModal }}
                                        />
                                    </Row>
                                </CardBody>
                            </Card>
                            <br />
                            <StockReceivingTransactionModal
                                show={showTransactionModal}
                                handleToggle={toggleTransactionModal}
                                data={showTransactionModalData}
                            />
                        </>
                    )}
                </Container>
            </div>

            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />

            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

StocksReceivingFormPage.propTypes = {
    location: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotStocksReceivingFormPagePage = hot(StocksReceivingFormPage);

export default connect(mapStateToProps, {})(HotStocksReceivingFormPagePage);
