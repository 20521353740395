/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Input, Label, Spinner } from 'reactstrap';

// #region assets | components
import { Select, CustomToast } from 'components';
import toast from 'react-hot-toast';
import CreatableSelectAdvanced from 'components/CreateableSelect';

// #region services
import { getRiderListData } from 'services/rider.service';
import { unassignDeliveryService } from 'pages/Modules/Delivery-Management/services';

// #region other imports
import { THEME_COLOR_2 } from 'theme';

// #region utils
import { apiErrorHandlerV2, getDefaultValueForSelect } from 'utils/helpers';

// #endregion imports
import { SYSTEM_DATA_TYPES } from 'constants/enums';
import { createSystemDataService, getAllSystemDataByTypeAndModuleService } from 'services/system-data.service';
import { confirmValidateForm } from '../utils';

function UnassignedDelivery(props) {
    const {
        data: deliveryData,
        setShowModal,
        loadTransactions,
        setDeliveryFormData,
        updateRiderData,
        updateHistoryWithData,
    } = props;

    const _isMounted = useRef(true);

    const { merchantOrderNo, referenceNumber, _id, vehicleType } = deliveryData;

    const initialFormValues = {
        reason: 'Other',
        description: '',
        isResetDeliveryStatus: false,
        isAssignedToRider: false,
        riderId: deliveryData.riderId,
        routePlanningId: deliveryData.routePlanningId,
    };
    const [reasons, setReasons] = useState([]);

    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);

    const [customLoading, setCustomLoading] = useState({ reason: false });
    const [formLoading, setFormLoading] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);

    const [riderListData, setRiderListData] = useState({ docs: [] });

    // load delivery cancel reasons to dropdown
    useEffect(() => {
        loadDeliveryUnassignReasons();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    const handleSelectChange = (event, id) => {
        if (id === 'confirmation' && event) {
            setFormData({
                ...formData,
                [id]: event ? event.target.value : null,
            });
            return;
        }
        if (id === 'description' && event) {
            setFormData({
                ...formData,
                [id]: event ? event.target.value : null,
            });
            return;
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleCreateSelectChange = async (record, id) => {
        if (record) {
            if (id === 'reason') {
                setCustomLoading((prev) => ({ ...prev, reason: true }));
                try {
                    const { data } = await createSystemDataService({
                        type: SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION_REASONS.DELIVERY_UNASSIGN_REASON,
                        module: SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION,
                        name: record,
                    });
                    if (data) {
                        setReasons([...reasons, { label: data.name, value: data.name }]);
                        setFormData({
                            ...formData,
                            [id]: record,
                        });
                    }
                } catch (e) {
                    const { message: exception } = apiErrorHandlerV2(e);
                    toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                        position: 'top-right',
                    });
                }
            }
        }

        setCustomLoading((prev) => ({ ...prev, reason: false }));
    };

    const handleSubmit = async (event, errors = []) => {
        let updatedPayloadData;

        setSubmitted(true);

        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.persist();

        const type = 'riderUnAssign';

        const { isFormValid, message } = confirmValidateForm(formData, type);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        const payload = { ...formData, deliveryId: _id };
        setFormLoading(true);
        let isSuccess = false;

        try {
            const { data } = await unassignDeliveryService(payload);
            if (data) {
                setFormData({ ...payload, ...data });
                updatedPayloadData = { ...deliveryData, ...data };
                setDeliveryFormData({ ...deliveryData, ...data });
                isSuccess = true;
                toast.custom((t) => <CustomToast text="Successfully Unassigned Rider" t={t} type="success" />, {
                    position: 'top-right',
                });
                // Update relevant Rider Data
                const { riderId, riderReference, riderMobileNo, riderName } = data;
                updateRiderData({ _id: riderId, riderReference, riderMobileNo, riderName });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (updatedPayloadData) {
            updateHistoryWithData({ ...deliveryData, ...updatedPayloadData });
        }

        loadTransactions();
        setFormLoading(false);
        setValidated(false);
        setSubmitted(false);

        if (isSuccess) {
            setShowModal(false);
        }
    };

    /**
     * Load delivery Unassign Reasons to the Dropdown
     */
    const loadDeliveryUnassignReasons = async () => {
        try {
            const { data } = await getAllSystemDataByTypeAndModuleService(
                SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION_REASONS.DELIVERY_UNASSIGN_REASON,
                SYSTEM_DATA_TYPES.DELIVERY_TRANSACTION
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                setReasons(data.map((x) => ({ label: x.name, value: x.name })));
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    useEffect(() => {
        loadRiderListData({ ...formData, showLoading: true });
    }, [formData.vehicleType]);

    /**
     * Load Rider List
     * @param {*} args
     */
    const loadRiderListData = (args) => {
        getRiderListData({
            ...args,
            limit: 100,
        }).then((res) => {
            const { data } = res;
            delete data.sort;
            setRiderListData({ ...data });
        });
    };

    const relevantRidersList = (vehicle) => {
        const ridersList = riderListData.docs.filter((x) => x.vehicleType === vehicle);

        const relevantRider = ridersList.map((riders) => {
            if (riders) {
                return { label: riders.fullName || '', value: riders._id };
            }
            return null;
        });
        return relevantRider;
    };

    const selectedDeliveryType = useMemo(() => getDefaultValueForSelect(formData.reason), [formData.reason]);

    return (
        <div className="font-size-14 w-100 p-0">
            <div
                className="font-size-16 text-white"
                style={{ backgroundColor: THEME_COLOR_2, padding: 15, borderRadius: '5px 5px 0 0' }}
            >
                Unassign Delivery - {merchantOrderNo} | {referenceNumber}{' '}
            </div>
            <div className="modal__form-table">
                <AvForm onSubmit={handleSubmit}>
                    <div className="modal_form-item">
                        <Label htmlFor="reason">Reason</Label>
                        <CreatableSelectAdvanced
                            value={selectedDeliveryType}
                            options={reasons}
                            id="reason"
                            isLoading={customLoading.reason}
                            isClearable
                            required
                            onChange={(event) => handleSelectChange(event, 'reason')}
                            onCreate={(event) => handleCreateSelectChange(event, 'reason')}
                            submitted={submitted}
                            validated={validated}
                        />
                    </div>

                    <div className="modal_form-item">
                        <Label htmlFor="Description">Description</Label>
                        <div>
                            <Input
                                style={{ borderRadius: 2, padding: 5, width: '100%' }}
                                type="textarea"
                                name="text"
                                rows="3"
                                cols=""
                                id="description"
                                value={formData.description}
                                onChange={(event) => handleSelectChange(event, 'description')}
                            />
                        </div>
                    </div>

                    <div className="modal_form-item">
                        <Label htmlFor="Reset the delivery Status">Reset the delivery Status</Label>
                        <Select
                            value={getDefaultValueForSelect(formData.isResetDeliveryStatus === false ? 'No' : 'Yes')}
                            options={[
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ]}
                            id="isResetDeliveryStatus"
                            required
                            onChange={(event) => handleSelectChange(event, 'isResetDeliveryStatus')}
                            submitted={submitted}
                            validated={validated}
                        />
                    </div>

                    <div className="modal_form-item">
                        <Label>Assign to another Rider</Label>
                        <Select
                            value={getDefaultValueForSelect(formData.isAssignedToRider === false ? 'No' : 'Yes')}
                            options={[
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ]}
                            id="isAssignedToRider"
                            required
                            onChange={(event) => handleSelectChange(event, 'isAssignedToRider')}
                            submitted={submitted}
                            validated={validated}
                        />
                    </div>

                    {formData.isAssignedToRider && (
                        <div className="modal_form-item">
                            <Label>Assign a rider</Label>
                            <Select
                                defaultValue={[{ label: deliveryData.riderName || '', value: deliveryData.riderId }]}
                                options={relevantRidersList(vehicleType)}
                                id="riderId"
                                isClearable
                                onChange={(event) => handleSelectChange(event, 'riderId')}
                                submitted={submitted}
                                validated={validated}
                                required={formData.isAssignedToRider}
                            />
                        </div>
                    )}

                    <div style={{ width: '100%', padding: '10px', background: '#fffbf0', marginBottom: '15px' }}>
                        Type &apos;confirm&apos; to confirm the transaction and click confirm.
                    </div>

                    <div className="modal_form-item">
                        <AvField
                            value={formData.confirmation}
                            name="confirmation"
                            onChange={(event) => handleSelectChange(event, 'confirmation')}
                            errorMessage="Confirmation Required"
                            submitted={submitted}
                            required
                            id="confirmation"
                            validated={validated}
                            autoComplete="off"
                        />
                    </div>

                    <div style={{ fontSize: 14 }} className="mb-6">
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius secondary-btn pt-1 pb-1 m-2 mb-3 pr-5 pl-5 shadow-lg "
                            style={{ borderRadius: 10 }}
                            type="submit"
                            disabled={formLoading}
                        >
                            {formLoading ? <Spinner className="mr-4 ml-4 0" color="info" size="sm" /> : <>CONFIRM</>}
                        </motion.button>
                    </div>
                </AvForm>
            </div>
        </div>
    );
}

UnassignedDelivery.propTypes = {
    setShowModal: PropTypes.func,
    loadTransactions: PropTypes.func,
    setDeliveryFormData: PropTypes.func,
    data: PropTypes.any,
    updateRiderData: PropTypes.func,
    updateHistoryWithData: PropTypes.func,
};

export default UnassignedDelivery;
