import {
    GET_CITIES_BY_COUNTRY,
    GET_DELIVERIES_BY_FILTERS,
    GET_MERCHANT_LOCATIONS_BY_MERCHANT_ID_LIST,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const getCitiesListService = (params) => {
    const buildQuery = {
        countryIsoCode: params,
    };
    const url = UrlBuilder()
        .setUrl(GET_CITIES_BY_COUNTRY)
        .setQueryParams({ ...buildQuery })
        .build();
    return instance.get(url);
};

export const getUnPlannedDeliveryService = (data) => {
    const url = UrlBuilder().setUrl(GET_DELIVERIES_BY_FILTERS).build();
    return instance.post(url, data);
};

export const getMerchantLocationService = (data) => {
    const url = UrlBuilder().setUrl(GET_MERCHANT_LOCATIONS_BY_MERCHANT_ID_LIST).build();
    return instance.post(url, data);
};
