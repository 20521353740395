export const TABLE_HEADER_TYPES = {
    LABEL_PRINT_SHIPPING_LABEL_LIST: 'LABEL_PRINT_SHIPPING_LABEL_LIST',
    BATCH_PRINT_SELECT_TO_PRINT: 'BATCH_PRINT_SELECT_TO_PRINT',
    BATCH_PRINT_SELECTED_ITEM: 'BATCH_PRINT_SELECTED_ITEM',
    DELIVERY_BATCH_ASSIGNMENT_DELIVERIES: 'DELIVERY_BATCH_ASSIGNMENT_DELIVERIES',
    MANAGE_API_KEYS: 'MANAGE_API_KEYS',
    MANAGE_WEBHOOKS: 'MANAGE_WEBHOOKS',
    CONNECTED_APPS: 'CONNECTED_APPS',
    PARTNER_LIST: 'PARTNER_LIST',
    PARTNER_API: {
        SANDBOX_ACCOUNT_LIST_VIEW: 'PARTNER_API_SANDBOX_ACCOUNT_LIST_VIEW',
    },
};
