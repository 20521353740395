export const validateOrderItems = (allOrders) => {
    let isValid = true;
    const message = 'Fill All Fields';

    allOrders.map((val) => {
        const { productMasterDataDescription, skuCode, unitOfMeasure, quantity } = val;

        if (!productMasterDataDescription || !skuCode || !unitOfMeasure || !quantity) {
            isValid = false;
        }
        return val;
    });

    return { message, isValid };
};
