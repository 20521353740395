import React from 'react';
import { Card, CardBody, DropdownItem } from 'reactstrap';
import { motion } from 'framer-motion';

import PropTypes from 'prop-types';

const allTabs = [
    {
        id: 0,
        label: 'All',
        value: 'all',
        icon: 'far fa-envelope mr-5 ml-3',
        isBreak: true,
        iconStyle: { fontSize: 20, marginTop: 5 },
    },
    // {
    //     id: 1,
    //     label: 'Read',
    //     value: 'read',
    //     icon: 'far fa-envelope-open mr-5 ml-3',
    //     iconStyle: { fontSize: 20 },
    // },
    // {
    //     id: 2,
    //     label: 'Starred',
    //     value: 'starred',
    //     icon: 'far fa-star',
    //     iconStyle: { marginLeft: 13, marginRight: 40, fontSize: 20 },
    // },
    // {
    //     id: 3,
    //     label: 'Deleted',
    //     value: 'deleted',
    //     icon: 'far fa-trash-alt mr-5 ml-3',
    //     iconStyle: { fontSize: 20 },
    // },
];

function TabSubView(props) {
    const { activeTab = 'all' } = props;

    return (
        <>
            <Card style={{ borderRadius: 10, minHeight: '70vh' }}>
                <CardBody>
                    {allTabs.map((val) => (
                        <>
                            <motion.div
                                className="pointer row"
                                key={val.id}
                                style={{
                                    width: '100%',
                                    backgroundColor: activeTab === val.value ? '#107a8b' : '#FFF',
                                    borderRadius: 15,
                                    marginLeft: 0,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 15,
                                }}
                                whileTap={{ scale: 0.9 }}
                            >
                                <div className="d-flex row align-content-center align-items-center">
                                    <i
                                        className={`${val.icon}`}
                                        style={{
                                            color: activeTab === val.value ? '#FFF' : '#107a8b',
                                            ...(val.iconStyle || {}),
                                        }}
                                    />
                                    <div
                                        style={{
                                            fontSize: 14,
                                            color: activeTab === val.value ? '#FFF' : '#107a8b',
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                        }}
                                        className="mt-1"
                                    >
                                        {val.label}
                                    </div>
                                </div>
                            </motion.div>
                            {val.isBreak && (
                                <>
                                    <br />
                                    <DropdownItem divider />
                                </>
                            )}
                        </>
                    ))}
                </CardBody>
            </Card>
        </>
    );
}

TabSubView.propTypes = {
    activeTab: PropTypes.string,
};

export default TabSubView;
