import { PERMISSION_NAME } from 'utils/permission';

export const defaultTableHeaders = [
    {
        key: 'merchant',
        value: 'Merchant',
        isClientOnly: true,
    },
    {
        key: 'userAccounts',
        value: 'User Accounts',
    },
    {
        key: 'riderProfiles',
        value: 'Rider Profiles',
        isClientOnly: true,
    },
    {
        key: 'deliveryProcessing',
        value: 'Delivery Processing',
    },
    {
        key: 'deliveries',
        value: 'Deliveries',
    },
    {
        key: 'externalSystemConnection',
        value: 'External System Connection',
    },
    // {
    //     key: 'stockAdjustments',
    //     value: 'Inventory',
    //     isClientOnly: true,
    // },
    {
        key: 'payments',
        value: 'Payments',
        isClientOnly: true,
    },
    {
        key: 'inventory',
        value: 'Inventory',
        isClientOnly: true,
    },
    {
        key: 'labelPrint',
        value: 'Label print',
    },
    {
        key: 'batchDeliveryAssignment',
        value: 'Batch Delivery Assignment',
        isClientOnly: true,
    },
    {
        key: 'deliveryTransaction',
        value: 'Delivery Transactions',
        // isClientOnly: true,
    },
    {
        key: PERMISSION_NAME.PARTNER_API,
        value: PERMISSION_NAME.PARTNER_API,
        // isClientOnly: true,
    },
    {
        key: PERMISSION_NAME.ROUTE_PLANNING,
        value: PERMISSION_NAME.ROUTE_PLANNING,
        isClientOnly: true,
    },
    {
        key: PERMISSION_NAME.AUTOMATIC_DELIVERY_DATE_SETTINGS,
        value: PERMISSION_NAME.AUTOMATIC_DELIVERY_DATE_SETTINGS,
        isClientOnly: true,
    },
];
