/* eslint-disable camelcase */

export const buildLocationData = (id, idMeta, data) => {
    if (data) {
        const { city = '', address = '', state = null, postal_code = '' } = data;
        return {
            city,
            state,
            [id]: `${address ? `${address}` : ''}`,
            [idMeta]: data,
            postalCode: postal_code || '',
        };
    }
    return {
        [id]: null,
        [idMeta]: null,
        city: '',
        state: '',
        postalCode: '',
    };
};

export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { address, addressMeta } = form;
    if (!address || !addressMeta) {
        return { isFormValid, message };
    }

    isFormValid = true;
    return { isFormValid, message };
};
