import { compose, createStore, applyMiddleware } from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import createReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false })
    : compose;

const store = createStore(
    createReducer(),
    undefined,
    composeEnhancers(
        applyMiddleware(sagaMiddleware),
        createInjectorsEnhancer({
            createReducer,
            runSaga: sagaMiddleware.run,
        })
    )
);

sagaMiddleware.run(rootSaga);

export default store;
