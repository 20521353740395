/* eslint-disable indent */
import _ from 'lodash';
import {
    GET_DELIVERIES,
    GET_ONE_DELIVERY,
    GET_DELIVERIES_ORDERS,
    GET_ALL_DELIVERIES,
    GET_ALL_DELIVERIES_META,
    GET_ALL_DELIVERIES_BY_CLIENT_ID,
    GET_ONGOING_DELIVERIES,
    GET_DELIVERIES_BY_STATUS,
    GET_ALL_DELIVERY_IDS,
    GET_DELIVERIES_DELETED,
    DELIVERY_LOCATION_CHANGED,
    GET_DELIVERIES_LIST,
    POST_LINK_DELIVERY_RECORD,
    UPLOAD_DELIVERY_ATTACHMENT,
    CREATE_DELIVERY_ATTACHMENT,
    GET_ALL_DELIVERY_ATTACHMENTS_BY_ID,
    UPDATE_DELIVERY_ATTACHMENT,
    DELETE_DELIVERY_ATTACHMENT,
    GET_ALL_DELIVERY_IDS_NEW,
    GET_DELIVERIES_BY_ROUTE_PLANNING_ID,
    GET_TRANSACTIONS_BY_ROUTE_PLANNING_ID,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import { postAttachments } from 'utils/httpclients/instances';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Deliveries Service
 * @returns {object}
 */
export const getDeliveriesService = ({
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate,
    status = null,
    tab: type,
}) => {
    const requestURL = `${GET_DELIVERIES}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
        filter ? `filter=${filter}&` : ''
    }${sort ? `sort=${sort}&` : 'sort=createdAt&'}${order ? `order=${order}&` : `&order=-1`}${
        populate ? `&populate=${populate}&` : ''
    }${
        searchBy
            ? `&${
                  // eslint-disable-next-line no-nested-ternary
                  searchBy.searchBy && searchBy.searchText
                      ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                      : searchBy.searchBy && searchBy.filterDate
                      ? `searchBy=${searchBy.searchBy}&filterDate=${searchBy.filterDate}&`
                      : ''
              }&`
            : ''
    }${status ? `status=${status}&` : ''}${type ? `type=${type}&` : ''}`;
    const url = UrlBuilder().setUrl(requestURL).build();
    return instance.get(url);
};

export const getOngoingDeliveriesService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate,
    status = null
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_ONGOING_DELIVERIES}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${populate ? `&${populate}&` : ''}${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }${status ? `status=${status}&` : ''}`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Deliveries Deleted Service
 * @returns {object}
 */
export const getDeliveriesDeletedService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate,
    status = null,
    type
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_DELIVERIES_DELETED}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }${status ? `status=${status}&` : ''}${type ? `type=${type}&` : ''}`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Deliveries By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getDeliveryByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ONE_DELIVERY).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Deliveries By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getDeliveriesByStatus = (status) => {
    try {
        const url = UrlBuilder().setUrl(GET_DELIVERIES_BY_STATUS).setPathParams({ status }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Deliveries
 * @returns {Promise}
 */
export const getAllDeliveriesService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_DELIVERIES).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Deliveries Meta
 * @returns {Promise}
 */
export const getAllDeliveriesMetaService = (type) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_DELIVERIES_META).setPathParams({ type }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Delivery's Orders By Id Service
 * @returns {Promise}
 */
export const getDeliveriesOrdersByIdService = (id) => {
    try {
        const url = UrlBuilder()
            .setUrl(GET_DELIVERIES_ORDERS)
            .setPathParams({
                merchantId: id,
            })
            .build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Deliveries By Client Id Service
 * @returns {Promise}
 */
export const getDeliveriesByClientIdService = (id) => {
    try {
        const url = UrlBuilder()
            .setUrl(GET_ALL_DELIVERIES_BY_CLIENT_ID)
            .setPathParams({
                clientId: id,
            })
            .build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Post Delivery Location Changed
 * @returns {Promise}
 */
export const postDeliveryLocationChanged = (deliveryData) => {
    try {
        const url = UrlBuilder()
            .setUrl(DELIVERY_LOCATION_CHANGED)
            .setPathParams({
                id: deliveryData._id,
            })
            .build();
        return instance.post(url, deliveryData);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Post Link Billing Record With Delivery Record
 * @returns {Promise}
 */
export const postLinkBillingRecordWithDeliveryRecord = (deliveryData) => {
    try {
        const url = UrlBuilder().setUrl(POST_LINK_DELIVERY_RECORD).build();
        return instance.post(url, deliveryData);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Delivery Ids
 * @returns {Promise}
 */
export const getAllDeliveryIds = (query = {}) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_DELIVERY_IDS).setQueryParams(query).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Delivery List Data
 * @param {object} queryParams
 * @returns {Promise}
 */

export const getDeliveryListData = ({
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    select,
    searchBy,
    searchByDate,
    merchantId,
    deliveryType,
    vehicleType,
    status,
}) =>
    new Promise(async function promiseCall(resolve, reject) {
        try {
            const buildQuery = {
                page,
                limit,
                sort: sort || 'createdAt',
                order,
            };

            if (select) buildQuery.select = select;

            if (searchBy?.searchBy) buildQuery.searchBy = searchBy.searchBy;
            if (searchBy?.searchText) buildQuery.searchText = searchBy.searchText;
            if (searchByDate?.searchByDate) buildQuery.searchByDate = searchByDate.searchByDate;
            if (searchByDate?.searchDate) buildQuery.searchDate = searchByDate.searchDate;
            if (searchByDate?.searchDate) buildQuery.searchDate = searchByDate.searchDate;
            if (searchByDate?.searchDate) buildQuery.searchDate = searchByDate.searchDate;

            if (merchantId) buildQuery.merchantId = merchantId;
            if (deliveryType) buildQuery.deliveryType = deliveryType;
            if (vehicleType) buildQuery.vehicleType = vehicleType;
            if (status) buildQuery.status = status;

            const url = UrlBuilder().setUrl(GET_DELIVERIES_LIST).setQueryParams(buildQuery).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });

/**
 * upload delivery Attachment Service
 * @returns {Promise}
 */
export const uploadDeliveryAttachmentService = (deliveryId, data, objectData) => {
    const url = UrlBuilder().setUrl(UPLOAD_DELIVERY_ATTACHMENT).setPathParams({ id: deliveryId }).build();
    return postAttachments(url, data, objectData);
};

/**
 * create delivery Attachment Service
 * @returns {Promise}
 */
export const createDeliveryAttachmentService = (data) => instance.post(CREATE_DELIVERY_ATTACHMENT, data);

/**
 * update delivery Attachment Service
 * @returns {Promise}
 */
export const updateDeliveryAttachmentService = (id, data) => {
    const url = UrlBuilder().setUrl(UPDATE_DELIVERY_ATTACHMENT).setPathParams({ id }).build();
    return instance.patch(url, data);
};

/**
 * delete delivery Attachment Service
 * @returns {Promise}
 */
export const deleteDeliveryAttachmentService = (id) => {
    const url = UrlBuilder().setUrl(DELETE_DELIVERY_ATTACHMENT).setPathParams({ id }).build();
    return instance.delete(url);
};

/**
 * Get All Delivery Attachments based on DeliveryId
 * @returns {Promise}
 */
export const getAllDeliveriesAttachmentsService = (id) => {
    const url = UrlBuilder().setUrl(GET_ALL_DELIVERY_ATTACHMENTS_BY_ID).setPathParams({ id }).build();
    return instance.get(url);
};

export const getAllDeliveryIdsService = (params) => {
    const { clientId, fromDateTime, toDateTime, merchantId, status } = params;
    const queryParams = _.pickBy({ clientId, fromDateTime, toDateTime, merchantId, status });
    const url = UrlBuilder().setUrl(GET_ALL_DELIVERY_IDS_NEW).setQueryParams(queryParams).build();
    return instance.get(url);
};

/**
 * This build the url for getting deliveries by route planning id
 * @param {string} id - Route planning id
 * @returns url
 */
export const getDeliveriesByRoutePlanningId = (id) => {
    const url = UrlBuilder().setUrl(GET_DELIVERIES_BY_ROUTE_PLANNING_ID).setPathParams({ id }).build();
    return instance.get(url);
};

/**
 * This build the url for getting route planning transactions by route planning id
 * @param {string} id - Route planning id
 * @returns url
 */
export const getTransactionsByRoutePlanningId = (id) => {
    const url = UrlBuilder().setUrl(GET_TRANSACTIONS_BY_ROUTE_PLANNING_ID).setPathParams({ id }).build();
    return instance.get(url);
};
