/* eslint-disable react-hooks/exhaustive-deps */
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import { Col, Label, Row, Spinner } from 'reactstrap';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import '../styles/index.scss';

// #region other imports
import { riderStatusOptions } from 'pages/Modules/Delivery-Management/modules/Route-Planning/modules/Form-View/containers/Form-Options/constants';
import { getRiderDataByFilters } from 'pages/Modules/Delivery-Management/modules/Route-Planning/modules/Form-View/containers/Delivery-Grid/Modal/AssignRider-Modal/services';

// #region assets | components
import { CheckCircle, CrossCircle } from 'components/Icons';
import { CustomToast, Select } from 'components';
import RiderDetailCard from 'components/Cards/RiderDetailCard';

// #region services
import { updateRiderByRoutePlanningEntryId } from 'services/routePlanning/routePlanning.service';

// #region utils
import { apiErrorHandlerV2, reactSelectCustomStyles } from 'utils/helpers';

// #endregion imports
import { buildQueryFilters } from './utils';

/**
 * Changes the assigned rider of a route planning entry.
 * @param {Object} props - The component props.
 * @param {function} props.toggleModal - Function to toggle the visibility of the modal.
 * @param {Object} props.riderDetails - The details of the current assigned rider.
 * @param {string} props.routePlanningId - The ID of the route planning entry.
 * @param {Object} props.deliveryArray - The delivery data for the current route planning entry.
 * @param {function} props.fetchRoutePlanningData - Function to fetch updated route planning entry data.
 * @param {function} props.fetchRelevantDeliveryData - Function to fetch updated delivery data.
 * @returns {JSX.Element} - The rendered component.
 */
function ChangeTheRiderModule(props) {
    const {
        toggleModal,
        riderDetails,
        routePlanningId,
        deliveryArray,
        fetchRoutePlanningData,
        fetchRelevantDeliveryData,
        loadTransactions,
    } = props;

    // Rider status state
    const [riderStatus, setRiderStatus] = useState('');
    // Rider id state
    const [riderId, setRiderId] = useState('');
    // Riders state
    const [riders, setRiders] = useState([]);
    // Rider options state
    const [riderOptions, setRiderOptions] = useState([]);
    // Rider loading state
    const [riderLoading, setRiderLoading] = useState(false);
    // form
    const [formLoading, setFormLoading] = useState(false);

    useEffect(() => {
        loadRiderListData();
    }, []);

    useEffect(() => {
        if (riderStatus) {
            const filteredRiders = riders.filter((rider) => rider.status === riderStatus);
            setRiderOptions(mapRidersToSelectArray(filteredRiders));
        }
        // if the user clear the status field
        else {
            setRiderOptions(mapRidersToSelectArray(riders));
        }
    }, [riderStatus]);

    /** Load Rider List Data using filters (eg: isLimitDeliveriesToMerchantRider) */
    const loadRiderListData = async () => {
        setRiderLoading(true);
        try {
            const queryFilters = buildQueryFilters(deliveryArray.docs, riderStatus);
            const response = await getRiderDataByFilters({ ...queryFilters });
            const data = response.data.docs.map((x) => ({ ...x, label: x.fullName || '', value: x._id }));

            setRiders(data);
            setRiderOptions(mapRidersToSelectArray(data));
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setRiderLoading(false);
    };

    // Map riders to select array
    const mapRidersToSelectArray = (ridersObjArray) => {
        /** remove current rider from the array */
        const mappedRiderSelectArray = ridersObjArray
            .filter((rider) => rider._id !== riderDetails._id)
            .map((rider) => ({
                label: `${rider.fullName} - ${rider.vehicleType}`,
                value: rider._id,
            }));
        return mappedRiderSelectArray;
    };

    /**
     * Handles the click event of the "CHANGE THE RIDER" button.
     * By selecting a rider, the system update the rider that is assigned to the route planning entry.
     * When attempts to continue without selecting a Rider, will display an error message
     * @returns {void}
     */
    const handleSubmit = async () => {
        // whether the rider update request success
        let isSuccess = false;
        setFormLoading(true);

        if (riderId) {
            // Update route planning entry with changed riderId
            const body = { riderId };

            try {
                const { status } = await updateRiderByRoutePlanningEntryId(routePlanningId, body);
                //  if the rider update process is success
                if (status === 200) {
                    isSuccess = true;
                    toast.custom(
                        (t) => (
                            <CustomToast text="New Rider Assigned to the Route Planning Entry" t={t} type="success" />
                        ),
                        {
                            position: 'top-right',
                        }
                    );
                }
            } catch (error) {
                const { message: exception } = apiErrorHandlerV2(error);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        } else {
            const errorMessage = 'Select a Rider to Continue';
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (isSuccess) {
            toggleModal();
            // fetch updated route planning entry data
            fetchRoutePlanningData(routePlanningId);
            // fetch updated route planning entry delivery data
            fetchRelevantDeliveryData(routePlanningId);
            // fetch updated route planning entry Transactions data
            loadTransactions(routePlanningId);
        }
        setFormLoading(false);
    };

    /**  Select change handler */
    const handleSelectChange = (event, id) => {
        if (id === 'riderStatus') {
            if (event?.value) {
                setRiderStatus(event.value === 'online' ? 'active' : 'inactive');
            } else {
                setRiderStatus('');
            }
        } else if (id === 'riderId') {
            if (event?.value) {
                setRiderId(event.value);
            } else {
                setRiderId('');
            }
        }
    };

    return (
        <>
            <div className="font-size-14 w-100 p-0">
                <div className="modal__form-table">
                    <AvForm onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <p>This Route planning Entry is Currently Assigned to the following Rider.</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={10}>
                                <RiderDetailCard riderDetails={riderDetails} />
                            </Col>
                        </Row>
                        <hr className="divider" />

                        <h5 className="mb-2 text-muted">Select a New Rider</h5>

                        <Row className="mb-4">
                            <Col md={6}>
                                <Label htmlFor="riderStatus">Rider Status</Label>
                                <Select
                                    options={riderStatusOptions}
                                    styles={reactSelectCustomStyles}
                                    onChange={(event) => handleSelectChange(event, 'riderStatus')}
                                    id="riderStatus"
                                    isClearable
                                />
                            </Col>
                            <Col md={6}>
                                <Label htmlFor="riderId">Select a Rider</Label>
                                <Select
                                    options={riderOptions}
                                    styles={reactSelectCustomStyles}
                                    onChange={(event) => handleSelectChange(event, 'riderId')}
                                    required
                                    id="riderId"
                                    isLoading={riderLoading}
                                    isClearable
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs="auto" className="mb-2 mb-xs-0">
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn shadow-lg "
                                    style={{ borderRadius: 10 }}
                                    type="submit"
                                    disabled={formLoading}
                                >
                                    {formLoading ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>
                                            <CheckCircle color="#fff" />
                                            &nbsp;CHANGE THE RIDER
                                        </>
                                    )}
                                </motion.button>
                            </Col>
                            <Col xs="auto">
                                <motion.button
                                    className="remove__btn form-delete-btn shadow-lg"
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    style={{ borderRadius: 10 }}
                                    onClick={toggleModal}
                                >
                                    <CrossCircle color="#FFF" />
                                    &nbsp;CANCEL
                                </motion.button>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </div>
        </>
    );
}

ChangeTheRiderModule.propTypes = {
    riderDetails: PropTypes.any,
    toggleModal: PropTypes.func,
    routePlanningId: PropTypes.string,
    deliveryArray: PropTypes.any,
    fetchRoutePlanningData: PropTypes.func,
    fetchRelevantDeliveryData: PropTypes.func,
    loadTransactions: PropTypes.func,
};

export default ChangeTheRiderModule;
