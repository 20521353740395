export const generateIndexCellStyles = ({ isTableBorder }) => ({
    width: 35,
    border: isTableBorder ? '2px solid #f0f0f0' : 'none',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    verticalAlign: 'middle',
});

export const generateSelectCellStyles = ({ isTableBorder }) => ({
    width: 35,
    border: isTableBorder ? '2px solid #f0f0f0' : 'none',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    verticalAlign: 'middle',
});
