/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import * as qs from 'query-string';
import { useHistory } from 'react-router-dom';
import QRCode from 'react-qr-code';

// #region imports
import paths from 'routes/paths';
import { BackdropLoader } from 'components';
import Fragile from 'assets/images/fragile.png';
import ScootixBW from 'assets/images/scootix-black&white.png';

// import './shippingLabel.css';
import { getShippingLabelByIdService } from 'services/shipping-label.service';
import { isObject } from 'utils/helpers';
import { useReactToPrint } from 'react-to-print';

/**
 * Shipping Label Print Page
 * ( Brother Ql-800 Settings )
 * @param {*} props
 * @returns
 */

function ShippingLabelPrintPage(props) {
    const history = useHistory();

    const componentRef = useRef();

    const [backdropLoading, setBackdropLoading] = useState(0);

    const [packageData, setPackageData] = useState({});
    const [packagesLength, setPackagesLength] = useState(1);
    const [index, setIndex] = useState(0);

    const pageStyle = `
        @page {
        size: 62mm 123mm;
        }
        `;

    useEffect(() => {
        if (!props.location) {
            history.push(paths.DELIVERY.DEFAULT);
            return;
        }
        const params = qs.parse(props.location.search);
        const { id, index: paramsIndex, noOfPackages } = params;
        loadPackageData(id);
        setIndex(paramsIndex);
        setPackagesLength(noOfPackages);
    }, []);

    const loadPackageData = async (id) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getShippingLabelByIdService(id);
            if (data) {
                const payload = { ...data };
                payload.deliveryData = data.deliveryId && isObject(data.deliveryId) ? data.deliveryId : null;
                setPackageData(payload);
                handlePrint();
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: true,
        pageStyle,
    });

    return (
        <>
            <div>
                {packageData && packageData._id && (
                    <Card style={{ display: 'inline-block', borderColor: 'black', borderWidth: 2 }}>
                        <CardBody>
                            <div ref={componentRef} className="print-card">
                                <div className="d-flex align-items-center">
                                    <img src={ScootixBW} alt="" height="120" className="scootix-image" />
                                    <div style={{ border: 2, borderColor: 'black' }}></div>
                                    <div>
                                        <span style={{ fontWeight: 'bolder' }} className="from">
                                            FROM
                                        </span>
                                        <p className="from-address">{packageData.deliveryData.pickupLocation}</p>
                                    </div>
                                </div>
                                <hr style={{ borderColor: 'black', borderWidth: 2 }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {packageData.deliveryData.isFragile && (
                                        <img
                                            src={Fragile}
                                            alt=""
                                            height="60"
                                            style={{ marginLeft: 30, marginRight: 10 }}
                                        />
                                    )}
                                    <div>
                                        <span style={{ fontWeight: 'bolder' }}>TO</span>
                                        <p style={{ fontSize: 10, lineHeight: 1, marginBottom: 3 }}>{`${
                                            packageData.deliveryData.recipientName || 'No Recipient Given'
                                        }, `}</p>
                                        <p
                                            style={{ fontSize: 10, lineHeight: 1, marginBottom: 3 }}
                                        >{`${packageData.deliveryData.deliveryAddress}, `}</p>
                                        <p
                                            style={{ fontSize: 10, lineHeight: 1, marginBottom: 3 }}
                                        >{`Postal Code: ${packageData.deliveryData.postalCode}, `}</p>
                                        <p style={{ fontSize: 10, lineHeight: 1, marginBottom: 3 }}>{`TEL: ${
                                            packageData.deliveryData.recipientMobileNumber || 'No Recipient Given'
                                        } `}</p>
                                    </div>
                                </div>
                                <hr style={{ borderColor: 'black', borderWidth: 2 }} />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <span style={{ fontSize: 10 }}>MERCHANT ORDER REFERENCE</span>
                                    <p style={{ fontSize: 16, fontWeight: 'bolder' }}>
                                        {packageData.deliveryData.merchantOrderNo ||
                                            packageData.deliveryData.orderReferenceNumber ||
                                            ''}
                                    </p>
                                </div>
                                <hr style={{ borderColor: 'black', borderWidth: 2 }} />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <QRCode value={packageData._id} size={200} />
                                </div>

                                <div className="text-center mt-2" style={{ fontSize: 20, fontWeight: 'bolder' }}>
                                    {index + 1}/{packagesLength}
                                </div>

                                <div className="text-center" style={{ fontSize: 10, fontWeight: 'bolder' }}>
                                    {`SCOOTIX REF: ${
                                        packageData.deliveryData.orderReferenceNumber ||
                                        packageData.deliveryData.merchantOrderNo ||
                                        ''
                                    }`}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                )}
            </div>
            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

ShippingLabelPrintPage.propTypes = {
    location: PropTypes.any,
};

export default ShippingLabelPrintPage;
