import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';

// Front
import Layout from './reducers/layout/reducer';
import Page from './reducers/page/reducer';

// Authentication Module
import Account from './reducers/auth/register/reducer';
import Login from './reducers/auth/login/reducer';
import Forget from './reducers/auth/forgetpwd/reducer';

// Global
import Global from './reducers/global/reducer';

import RiderManagement from './reducers/riderManagement/reducer';

export default function createReducer(injectedReducers = {}) {
    const rootReducer = combineReducers({
        ...injectedReducers,

        // public
        Layout,
        Page,

        // Authentication
        Account,
        Login,
        Forget,

        Global,

        // Rider
        RiderManagement,

        // Delivery
        router: connectRouter(history),
    });

    return rootReducer;
}
