/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Row, Col, Card, CardBody, FormGroup, Spinner, Label, Container, Input, Media, Alert } from 'reactstrap';
import * as qs from 'query-string';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import Avatar from 'react-avatar';
import Switch from 'react-switch';

// #region components | assets
import {
    BackdropLoader,
    ClientAlerts,
    CustomToast,
    ImageUploadModal,
    PlacesAutocompleteField,
    PlacesAutocompleteModal,
    ScootixModal,
    Select,
    TableCustom,
    TooltipDefault,
} from 'components';
import { MultiStopBilling } from 'components/Icons';

// #region imports
import PATHS from 'routes/paths';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { SubTitle } from 'styles';
import { MODULES } from 'constants/session-storage';

// #region utils
import {
    cleanMask,
    apiErrorHandler,
    apiErrorHandlerV2,
    cleanMobileNo,
    getDefaultValueForSelect,
    reactSelectCustomStyles,
} from 'utils/helpers';
import { getVisitingObject, hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import { __DEV__ } from 'utils/checks';

// #region functions
import {
    buildUrlSearchParams,
    removeParamKeyFromUrlSearchParams,
    transformQsSearchToArray,
} from 'functions/url_search';

// #region services
import { createAttachmentOpenService, updateAttachmentOpenService } from 'services/attachment.service';
import { getAllCountries } from 'services/countries.service';
import { getMerchantByIdService } from 'services/merchant.service';
import { getUsersByQueryService } from 'services/user.service';
import { createPartnerApiData, getPartnerApiDataByMerchantId } from 'services/partner-api.service';
import { updateMultiStopBillingSettingsService } from 'pages/Modules/Settings/services';
import { getMerchantSettingByMerchantIdService } from 'services/merchant-setting.service';
import { getBillingSettingsByMerchantIdService } from 'services/billing-setting.service';
import { createMerchantWithAdminService } from 'pages/Modules/User-Management/modules/User-Form/services';
import { updateMerchantService, updateMerchantPropsService, deleteMerchantService } from './services';

// #endregion imports
import { buildLocationData, validateForm } from './utils';
import { billingSettingEmbeddedHeaders } from './constants';
import MerchantAdminForm from './modules/Admin-Form';
import MerchantSettings from './modules/Merchant-Settings';
import DeliveryDateSettings from './modules/Merchant-Settings/modules/delivery-dateSettings';
import DeleteMerchantModal from './components/DeleteMerchantModal';
import MultiStopBillingUpdateModal from './components/MultiStopBillingUpdateModal';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Merchant Management', link: '/merchant/list' },
];

function MerchantFormPage(props) {
    const { location } = props;

    const history = useHistory();

    const adminFormRef = useRef(null);
    const merchantFormRef = useRef(null);
    const merchantSettingComponentRef = useRef(null);

    const { visitingClient } = getVisitingObject();

    const initialFormValues = {
        country: visitingClient?.country || 'Malaysia',
    };

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [billingSettingLoading, setBillingSettingLoading] = useState(0);

    const [autoCompleteModal, setAutoCompleteModal] = useState({ type: null, show: false });
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    const [billingSettingListData, setBillingSettingListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: billingSettingEmbeddedHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    // images ( all Images with presigned URL are stored here )
    const [images, setImages] = useState({
        merchantPictureUrl: null,
    });

    const [imageUploadModalState, setImageUploadModalState] = useState({
        show: false,
        type: null,
        data: null,
        header: null,
        title: null,
        subTitle: null,
        existingFile: null,
        isOpen: false,
    });

    /** Multi-Stop billing settings modal */
    const [showMultiStopBillingModal, setShowMultiStopBillingModal] = useState(false);

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState(initialFormValues);

    const [merchantAdminFormData, setMerchantAdminFormData] = useState({
        country: visitingClient?.country || 'Malaysia',
        isAddressMerchant: false,
    });
    const [merchantUsers, setMerchantUsers] = useState(null);

    /** merchant settings */
    const [merchantSettingsFormData, setMerchantSettingsFormData] = useState({});

    const [showSaveBtn, setShowSaveBtn] = useState(false);

    useEffect(() => {
        if (editingMode) {
            if (hasAccess(PERMISSIONS.MERCHANTS, ['Edit'])) {
                setShowSaveBtn(true);
            }
        } else if (hasAccess(PERMISSIONS.MERCHANTS, ['Add'])) {
            setShowSaveBtn(true);
        }
    }, [editingMode]);

    useEffect(() => {
        const urlSearchParams = qs.parse(props.location.search);
        if (urlSearchParams && urlSearchParams.type && urlSearchParams.type === 'new') {
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Merchant', link: '#' }]);
        }
        if (urlSearchParams && urlSearchParams.type && urlSearchParams.type === 'edit') {
            handleUpdateComponentEditMode();
        }
    }, [props.location.search]);

    useEffect(() => {
        const timer = setTimeout(() => {
            const urlSearch = qs.parse(props.location.search);
            if (urlSearch.module && urlSearch.module === 'settings') {
                if (!__DEV__) {
                    merchantSettingComponentRef?.current?.offsetTop &&
                        window.scrollTo({ behavior: 'smooth', top: merchantSettingComponentRef?.current.offsetTop });
                }

                removeParamKeyFromUrlSearchParams(MODULES.MERCHANT_FORM.URL_SEARCH, 'module');
            }
        }, 1 * 500);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        if (formData._id) {
            loadPartnerApiDataByMerchantId(formData._id);
            loadUsersByMerchant(formData._id);
            loadMerchantSettingsData(formData._id);
            getBillingSettings(
                true,
                formData._id,
                billingSettingListData.all,
                billingSettingListData.page,
                billingSettingListData.limit,
                billingSettingListData.filter,
                billingSettingListData.sort,
                billingSettingListData.order
            );
        }
    }, [formData._id]);

    useEffect(() => {
        if (formData.merchantPictureUrl) {
            setImages((prevState) => ({ ...prevState, merchantPictureUrl: formData.merchantPictureUrl }));
        }
    }, [formData.merchantPictureUrl]);

    useEffect(() => {
        getAllCountriesService();
    }, []);

    function handleUpdateComponentEditMode() {
        try {
            let merchantId = null;
            if (!props.location) {
                return history.push(PATHS.MERCHANT.DEFAULT);
            }
            const urlSearch = qs.parse(props.location.search);
            if (!urlSearch.id) {
                return history.push(PATHS.MERCHANT.DEFAULT);
            }
            const searchArray = transformQsSearchToArray(urlSearch);
            buildUrlSearchParams(MODULES.MERCHANT_FORM.URL_SEARCH, searchArray);
            merchantId = urlSearch.id;
            merchantId && loadMerchantById(merchantId);
            setEditingMode(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Merchant', link: '#' }]);

            return true;
        } catch (e) {
            console.log(e);
        }
        return true;
    }

    /**
     *when click Multi-Stop billing settings button in billing setting,
     *pop up Multi-Stop billing settings modal
     *  */
    const toggleRestoreModal = () => {
        setShowMultiStopBillingModal(!showMultiStopBillingModal);
    };

    const loadMerchantById = async (id) => {
        setBackdropLoading((x) => x + 1);
        try {
            const { data } = await getMerchantByIdService(id);
            if (data) {
                setFormData((prevState) => ({ ...prevState, ...data }));
                const urlSearchSession = buildUrlSearchParams(MODULES.MERCHANT_FORM.URL_SEARCH, [
                    { key: 'type', value: 'edit' },
                    { key: 'id', value: data._id },
                ]);
                history.replace({
                    pathname: `${PATHS.MERCHANT.MERCHANT_FORM}`,
                    search: `${urlSearchSession || `?type=edit&id=${data._id}`}`,
                    state: {
                        merchantParentData: data,
                    },
                });
            } else {
                toast.custom((t) => <CustomToast text="Merchant Details Not Found" t={t} type="error" />, {
                    position: 'top-right',
                });
                history.goBack();
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((x) => x - 1);
    };

    const loadPartnerApiDataByMerchantId = async (id) => {
        try {
            const { data } = await getPartnerApiDataByMerchantId(id);
            if (!data.payload) {
                const payload = {
                    merchantId: id,
                };
                await createPartnerApiData(payload);
                toast.custom((t) => <CustomToast text="Created Partner Api Settings" t={t} type="info" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    /**
     * handle Multi-Stop billing settings
     */
    const handleMultiStopBillingUpdate = async (merchantSettingsData) => {
        setFormLoading(true);
        try {
            const { data } = await updateMultiStopBillingSettingsService(merchantSettingsData);
            if (data) {
                setMerchantSettingsFormData({ ...merchantSettingsFormData, ...data });

                /** close the Multi-Stop billing modal after click update button */
                setShowMultiStopBillingModal(!showMultiStopBillingModal);
                toast.custom((t) => <CustomToast text="Updated Multi-Stop billing Settings" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        } finally {
            setFormLoading(false);
        }
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const getAllCountriesService = async () => {
        const data = await getAllCountries();
        setCountries(data);
    };

    const getBillingSettings = (showLoading = true, id, all, page, limit, filter, sort, order, searchByParam) => {
        if (showLoading) {
            setBillingSettingLoading((prevState) => prevState + 1);
        }
        getBillingSettingsByMerchantIdService(id, all, page, limit, filter, sort, order, searchByParam, 'clientId')
            .then((res) => {
                const { data } = res;
                if (data && data.docs) {
                    setBillingSettingListData({
                        ...billingSettingListData,
                        ...data,
                        docs: data.docs.map((doc) => ({
                            ...doc,
                            clientName: doc.clientId.name,
                            clientId: doc.clientId._id,
                        })),
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setBillingSettingLoading((prevState) => prevState - 1);
                }
            });
    };

    const loadUsersByMerchant = async (id) => {
        try {
            const { data } = await getUsersByQueryService({ query: { merchantId: id } });
            if (data && Array.isArray(data)) {
                setMerchantUsers(data);
                return;
            }
        } catch (e) {
            console.log(e);
        }
        setMerchantUsers([]);
    };

    const handleEditBillingSetting = (e, row) => {
        history.push({
            pathname: `${PATHS.PAYMENTS.BILLING_FORM}`,
            search: `?type=edit`,
            state: { billingSettingParentData: row },
        });
    };

    const handleNewBillingSetting = () => {
        history.push({
            pathname: `${PATHS.PAYMENTS.BILLING_FORM}`,
            search: `?type=new`,
            state: { merchantParentData: formData },
        });
    };

    /** set the merchant setting data related to merchant id */
    const loadMerchantSettingsData = async (id) => {
        try {
            /** get merchant setting from merchant id */
            const { data } = await getMerchantSettingByMerchantIdService(id);

            if (data) {
                setMerchantSettingsFormData({ ...merchantSettingsFormData, ...data });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleCheckChange = (event, id) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.checked,
        });
    };

    const handleImageUpload = (header, type, title, fileTypes, existingFile = null, isOpen) => {
        setImageUploadModalState({
            show: true,
            data: null,
            header,
            type,
            title,
            fileTypes,
            isOpen,
            onUpload: async (files) => {
                if (type === 'merchantPictureKey') {
                    await updateMerchantPicture(files);
                    setImageUploadModalState((prevState) => ({
                        ...prevState,
                        loading: false,
                        show: false,
                        isOpen,
                    }));
                }
            },
            existingFile,
        });
    };

    const toggleImageUploadModal = () => {
        setImageUploadModalState({
            show: false,
            data: null,
            type: null,
        });
    };

    const updateMerchantPicture = async (files) => {
        const image = files[0];
        const imageData = new FormData();
        imageData.append('file', image);
        setImageUploadModalState((prevState) => ({
            ...prevState,
            loading: true,
        }));

        let isSuccess = false;
        try {
            if (formData.merchantPictureKey) {
                const { data } = await updateAttachmentOpenService(formData.merchantPictureKey, imageData);
                if (data && data.key) {
                    const response = await updateMerchantPropsService({
                        _id: formData._id,
                        merchantPictureKey: data.key,
                        merchantPictureUrl: data.location,
                    });
                    if (response.data) {
                        setFormData({ ...formData, ...response.data });
                        const urlSearchSession = buildUrlSearchParams(MODULES.MERCHANT_FORM.URL_SEARCH, []);
                        history.replace({
                            search: `${urlSearchSession || `?type=edit&id=${data._id}`}`,
                            state: { merchantParentData: { ...formData, ...response.data } },
                        });
                        isSuccess = true;
                    }
                }
            } else {
                const { data } = await createAttachmentOpenService(imageData);
                if (data && data.key) {
                    const response = await updateMerchantPropsService({
                        _id: formData._id,
                        merchantPictureKey: data.key,
                        merchantPictureUrl: data.location,
                    });
                    if (response.data) {
                        setFormData({ ...formData, ...response.data });
                        const urlSearchSession = buildUrlSearchParams(MODULES.MERCHANT_FORM.URL_SEARCH, []);
                        history.replace({
                            search: `${urlSearchSession || `?type=edit&id=${data._id}`}`,
                            state: { merchantParentData: { ...formData, ...response.data } },
                        });
                        isSuccess = true;
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }

        if (isSuccess) {
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${formData.merchantPictureKey ? 'updated' : 'saved'} profile picture `}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
        setImageUploadModalState((prevState) => ({
            ...prevState,
            loading: false,
            show: false,
        }));
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null, type: null });
    };

    const toggleDisable = async (isDisabled) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await updateMerchantService({ ...formData, isDisabled: !isDisabled });
            if (data) {
                setFormData({ ...formData, isDisabled: !isDisabled });
            }
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Merchant Successfully ${isDisabled ? 'Enabled' : 'Disabled'} `}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
        } catch (e) {
            console.log(e);
        }
        setScootixModalState({ ...scootixModalState, show: null });
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleSubmit = async (event, errors, values) => {
        setSubmitted(true);

        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        let { isFormValid, message } = validateForm(editingMode ? formData : merchantAdminFormData);

        let updatedPayloadData;

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        // validate merchant form
        ({ isFormValid, message } = validateForm(formData));

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);

        // from merchant admin form
        merchantAdminFormData.mobileNo = cleanMobileNo(merchantAdminFormData.mobileNo);
        merchantAdminFormData.nic = cleanMask(merchantAdminFormData.nic);

        // from merchant form
        formData.primaryContactNumber = cleanMobileNo(formData.primaryContactNumber);
        formData.secondaryContactNumber = cleanMobileNo(formData.secondaryContactNumber);

        const merchantAdminFormPayload = {
            ...merchantAdminFormData,
            isAdmin: true,
            isMerchantAdmin: true,
            role: 'merchantAdmin',
            fullName: `${merchantAdminFormData.firstName} ${merchantAdminFormData.lastName}`,
            isApproved: true, // Reason This true is Because he is created from console he is already approved
            status: 'active',
        };

        const payload = {
            merchant: formData,
            merchantAdmin: merchantAdminFormPayload,
        };

        try {
            if (editingMode) {
                const { data } = await updateMerchantService(payload.merchant);
                if (data) {
                    setFormData({ ...FormData, ...data });
                    updatedPayloadData = { ...formData, ...data };
                    const urlSearchSession = buildUrlSearchParams(MODULES.MERCHANT_FORM.URL_SEARCH, []);
                    history.replace({
                        search: `${urlSearchSession}`,
                        state: { merchantParentData: { ...formData, ...updatedPayloadData } },
                    });
                }
            } else {
                const { data } = await createMerchantWithAdminService(payload);
                const { merchantRecord, userRecord } = data;

                updatedPayloadData = { ...formData, ...data };
                const urlSearchSession = buildUrlSearchParams(MODULES.MERCHANT_FORM.URL_SEARCH, []);
                history.replace({
                    search: `${urlSearchSession}`,
                    state: { merchantParentData: { ...formData, ...updatedPayloadData.merchantRecord } },
                });

                if (data) {
                    setMerchantAdminFormData({ ...merchantAdminFormData, ...userRecord });
                    setFormData({ ...formData, ...merchantRecord });
                }
                setEditingMode(true);
            }

            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} merchant`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            if (!editingMode) {
                toast.custom(
                    (t) => (
                        <CustomToast
                            text="Merchant Admin Created with Temporary Password, Check Email Inbox"
                            t={t}
                            type="info"
                        />
                    ),
                    {
                        position: 'top-right',
                    }
                );
            }
        } catch (e) {
            console.log(e);
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading(false);
        setSubmitted(false);
    };

    const returnLocationData = (id, idMeta, data) => {
        const builtData = buildLocationData(id, idMeta, data);
        setFormData({ ...formData, ...builtData });
        if (merchantAdminFormData.isAddressMerchant && id === 'address') {
            setMerchantAdminFormData({ ...merchantAdminFormData, ...builtData });
        }
    };

    const handleDeleteMerchant = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteMerchantService(data._id);
            setShowDeleteModal(false);
            toast.custom((t) => <CustomToast text="Successfully Deleted Merchant Profile" t={t} type="success" />, {
                position: 'top-right',
            });
            setTimeout(() => {
                history.push({
                    pathname: PATHS.MERCHANT.DEFAULT,
                });
            }, 500);
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        // title={editingMode ? 'Update Merchant' : 'Create New Merchant'}
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Row style={{ justifyContent: 'space-between' }}>
                        {!editingMode && (
                            <div>
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md ml-4"
                                    onClick={() => {
                                        history.push({
                                            pathname: PATHS.MERCHANT.DEFAULT,
                                        });
                                    }}
                                >
                                    <i className="far fa-times-circle mr-2 fa-lg" />
                                    Cancel
                                </motion.div>
                            </div>
                        )}
                        {editingMode && (
                            <div>
                                {formData._id && images.merchantPictureUrl ? (
                                    <Media
                                        className="image-upload-avatar ml-4 pointer"
                                        onClick={() =>
                                            handleImageUpload(
                                                'Upload a Profile Picture',
                                                'merchantPictureKey',
                                                'Profile Picture',
                                                'image/jpeg, image/png',
                                                images.merchantPictureUrl,
                                                true
                                            )
                                        }
                                        src={images.merchantPictureUrl}
                                        roundedCircle
                                        style={{ borderRadius: 50, width: 60, height: 60 }}
                                    />
                                ) : (
                                    <Avatar
                                        className="image-upload-avatar ml-3 pointer"
                                        onClick={() =>
                                            handleImageUpload(
                                                'Upload a Merchant Picture',
                                                'merchantPictureKey',
                                                'Merchant Picture',
                                                'image/jpeg, image/png',
                                                images.merchantPictureUrl,
                                                true
                                            )
                                        }
                                        name={formData.name}
                                        size="60"
                                        round="50px"
                                    />
                                )}
                            </div>
                        )}
                        {editingMode && (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md btn-custom"
                                onClick={() => {
                                    history.push({
                                        pathname: PATHS.MERCHANT.DEFAULT,
                                    });
                                }}
                            >
                                <i className="fas fa-arrow-left mr-2 fa-lg" />
                                Back to Merchants
                            </motion.div>
                        )}
                    </Row>
                    <br />
                    {!editingMode && (
                        <>
                            <ClientAlerts />
                        </>
                    )}

                    {!editingMode && !merchantAdminFormData._id && !merchantAdminFormData.verified && (
                        <>
                            <Alert color="warning" style={{ padding: 3 }}>
                                <div className="d-flex row m-1 ml-3 justify-content-between pl-2 pr-2 align-items-center">
                                    <div className="d-flex row">
                                        <i className="fas fa-exclamation-circle mr-2 fa-2x" />
                                        <div style={{ fontSize: 15, marginTop: 4 }}>
                                            Create merchant admin below before creating <b>Merchant profile</b>{' '}
                                        </div>
                                    </div>
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        style={{ maxWidth: 40, width: 40, height: 40, borderRadius: 10 }}
                                        className="square-btn secondary-btn"
                                        onClick={() => {
                                            if (adminFormRef && adminFormRef.current) {
                                                adminFormRef.current.scrollIntoView({ behavior: 'smooth' });
                                            }
                                        }}
                                    >
                                        <i className="fas fa-chevron-down fa-lg" />
                                    </motion.div>
                                </div>
                            </Alert>

                            <br />
                        </>
                    )}
                    <AvForm id="merchantForm" className="needs-validation default-form" onValidSubmit={handleSubmit}>
                        <fieldset disabled={formLoading}>
                            <div ref={merchantFormRef}>
                                <Card className="mb-0">
                                    <CardBody>
                                        <Row className="d-flex justify-content-between ml-3 mr-3 ml-n2 mb-3">
                                            <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                                MERCHANT DETAILS
                                            </SubTitle>
                                            {formData._id && (
                                                <div id="toggleMerchantEnable">
                                                    <Switch
                                                        uncheckedIcon={<div />}
                                                        checkedIcon={<div />}
                                                        onColor="#FAA123"
                                                        offColor="#808080"
                                                        onChange={() => {
                                                            setScootixModalState({
                                                                ...scootixModalState,
                                                                show: true,
                                                                message: 'Are you sure you want to proceed?',
                                                                onConfirm: () => toggleDisable(formData.isDisabled),
                                                            });
                                                        }}
                                                        checked={!formData.isDisabled}
                                                    />
                                                    <TooltipDefault
                                                        targetId="toggleMerchantEnable"
                                                        text={`${formData.isDisabled ? 'Enable' : 'Disable'} Merchant`}
                                                    />
                                                </div>
                                            )}
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="name">Merchant Name</Label>
                                                    <AvField
                                                        name="name"
                                                        placeholder="Name"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="name"
                                                        onChange={handleInputChange}
                                                        value={formData.name}
                                                        disabled={editingMode}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="businessRegistrationNo">
                                                        Business Registration Number
                                                    </Label>
                                                    <AvField
                                                        name="businessRegistrationNo"
                                                        placeholder="Registration Number"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="businessRegistrationNo"
                                                        onChange={handleInputChange}
                                                        value={formData.businessRegistrationNo}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <Row>
                                                    <Col md="10" sm="10" xs="10">
                                                        <FormGroup>
                                                            <Label htmlFor="address">Address</Label>
                                                            <PlacesAutocompleteField
                                                                addressData={formData.addressMeta || null}
                                                                returnLocationData={returnLocationData}
                                                                id="address"
                                                                idMeta="addressMeta"
                                                                validate={{ required: { value: true } }}
                                                                isRequired
                                                                submitted={submitted}
                                                                validated={validated}
                                                                isClearable
                                                            />
                                                            {autoCompleteModal.type === 'address' &&
                                                                autoCompleteModal.show && (
                                                                    <PlacesAutocompleteModal
                                                                        show={autoCompleteModal.show}
                                                                        isModal
                                                                        addressData={formData.addressMeta || null}
                                                                        returnLocationData={returnLocationData}
                                                                        toggle={() =>
                                                                            setAutoCompleteModal({
                                                                                show: !autoCompleteModal.show,
                                                                                type: 'address',
                                                                            })
                                                                        }
                                                                        id="address"
                                                                        idMeta="addressMeta"
                                                                    />
                                                                )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="2" sm="2" xs="2">
                                                        <motion.div
                                                            style={{ width: 35, height: 35 }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.9 }}
                                                            className="scootix-btn-circle shadow-lg nw-md ml-3 order-map-pin-container"
                                                            onClick={() =>
                                                                setAutoCompleteModal({
                                                                    show: !autoCompleteModal.show,
                                                                    type: 'address',
                                                                })
                                                            }
                                                        >
                                                            <div className="map-pin">
                                                                <i className="ri-map-pin-line fa-lg" />
                                                            </div>
                                                        </motion.div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="postalCode">Postal Code</Label>
                                                    <AvField
                                                        name="postalCode"
                                                        placeholder="Postal Code"
                                                        type="Number"
                                                        minLength={5}
                                                        maxLength={5}
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="postalCode"
                                                        onChange={handleInputChange}
                                                        value={formData.postalCode}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="city">City</Label>
                                                    <AvField
                                                        name="city"
                                                        placeholder="City"
                                                        type="text"
                                                        errorMessage="Please provide a valid city."
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="city"
                                                        value={formData.city}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="state">State</Label>
                                                    <AvField
                                                        name="state"
                                                        placeholder="State"
                                                        type="text"
                                                        errorMessage="Please provide a valid state."
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="state"
                                                        value={formData.state}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="country">Country</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.country)}
                                                        options={countries.map((country) => ({
                                                            label: country.details.name,
                                                            value: country.details.name,
                                                        }))}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'country')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="primaryContactName">Primary Contact Name</Label>
                                                    <AvField
                                                        name="primaryContactName"
                                                        placeholder="Primary Contact Name"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="primaryContactName"
                                                        onChange={handleInputChange}
                                                        value={formData.primaryContactName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="primaryContactNumber">Primary Contact Number</Label>
                                                    <AvField
                                                        name="primaryContactNumber"
                                                        placeholder="Contact Number"
                                                        mask="+60 99-999 99999"
                                                        maskChar=""
                                                        tag={[Input, InputMask]}
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="primaryContactNumber"
                                                        onChange={handleInputChange}
                                                        value={formData.primaryContactNumber}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="officeNo">Office No</Label>
                                                    <AvField
                                                        name="officeNo"
                                                        placeholder="Office No"
                                                        mask="+609-99999999"
                                                        maskChar=""
                                                        tag={[Input, InputMask]}
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="officeNo"
                                                        value={formData.officeNo}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="primaryContactEmail">
                                                        Primary Contact Email Address
                                                    </Label>
                                                    <AvField
                                                        name="primaryContactEmail"
                                                        placeholder="Primary Contact Email Address"
                                                        type="email"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="primaryContactEmail"
                                                        onChange={handleInputChange}
                                                        value={formData.primaryContactEmail}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="secondaryContactName">Secondary Contact Name</Label>
                                                    <AvField
                                                        name="secondaryContactName"
                                                        placeholder="Secondary Contact Name"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="secondaryContactName"
                                                        onChange={handleInputChange}
                                                        value={formData.secondaryContactName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="secondaryContactNumber">
                                                        Secondary Contact Number
                                                    </Label>
                                                    <AvField
                                                        name="secondaryContactNumber"
                                                        placeholder="Secondary Contact Number"
                                                        mask="+60 99-999 99999"
                                                        maskChar=""
                                                        tag={[Input, InputMask]}
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="secondaryContactNumber"
                                                        onChange={handleInputChange}
                                                        value={formData.secondaryContactNumber}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="secondaryContactEmail">
                                                        Secondary Contact Email Address
                                                    </Label>
                                                    <AvField
                                                        name="secondaryContactEmail"
                                                        placeholder="Contact Email Address"
                                                        type="email"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="secondaryContactEmail"
                                                        onChange={handleInputChange}
                                                        value={formData.secondaryContactEmail}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {/* <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="country">License Type</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.licenseType)}
                                                        options={licenses.map((licenseType) => ({
                                                            label: licenseType,
                                                            value: licenseType,
                                                        }))}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'licenseType')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                    />
                                                </FormGroup>
                                            </Col> */}
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="contractNo">Contract Number ( Optional )</Label>
                                                    <AvField
                                                        name="contractNo"
                                                        placeholder="Contract Number"
                                                        type="text"
                                                        className="form-control"
                                                        id="contractNo"
                                                        onChange={handleInputChange}
                                                        value={formData.contractNo}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="website">Website ( Optional )</Label>
                                                    <AvField
                                                        name="website"
                                                        placeholder="Website"
                                                        type="text"
                                                        className="form-control"
                                                        id="website"
                                                        onChange={handleInputChange}
                                                        value={formData.website}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Label htmlFor="">&nbsp;</Label>
                                                    <div className="form-check mb-3">
                                                        <Input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            onChange={handleCheckChange}
                                                            checked={formData.isLimitDeliveriesToMerchantRider}
                                                            defaultChecked={formData.isLimitDeliveriesToMerchantRider}
                                                            id="isLimitDeliveriesToMerchantRider"
                                                        />
                                                        <Label className="form-check-label" htmlFor="defaultCheck1">
                                                            Limit Deliveries to Merchant Riders
                                                        </Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ justifyContent: 'flex-end' }}>
                                            {hasAccess(PERMISSIONS.MERCHANTS, ['Delete']) &&
                                                editingMode &&
                                                merchantUsers && (
                                                    <motion.div
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        className="form-delete-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                        onClick={() => {
                                                            setScootixModalState({
                                                                ...scootixModalState,
                                                                show: true,
                                                                message: 'Are you sure you want to proceed?',
                                                                onConfirm: () => {
                                                                    toggleScootixModal();
                                                                    setShowDeleteModal(true);
                                                                },
                                                                confirmMessage: 'Next',
                                                            });
                                                        }}
                                                    >
                                                        <i className="fas fa-trash left-icon fa-lg mr-3 mt-1" />
                                                        Delete Merchant
                                                    </motion.div>
                                                )}
                                            {showSaveBtn && editingMode && (
                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                    type="submit"
                                                    disabled={formLoading}
                                                >
                                                    {formLoading ? (
                                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                    ) : (
                                                        <>
                                                            <i className="fas fa-save left-icon" />
                                                            <span className="h6">
                                                                {editingMode ? 'Update' : 'Save'}
                                                            </span>
                                                        </>
                                                    )}
                                                </motion.button>
                                            )}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </fieldset>
                    </AvForm>

                    {editingMode && (
                        <MerchantSettings
                            merchantData={formData}
                            componentRef={merchantSettingComponentRef}
                            location={location}
                        />
                    )}

                    {/* {editingMode && <Billing merchantData={formData} />} */}

                    <br />
                    {editingMode && (
                        <>
                            <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                                <CardBody>
                                    <Row className="justify-content-between">
                                        <SubTitle
                                            style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}
                                            className="ml-3 mt-2"
                                        >
                                            BILLING SETTING
                                        </SubTitle>
                                        <Row className="pr-4 ml-2">
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                                onClick={() => handleNewBillingSetting()}
                                            >
                                                <i className="fas fa-warehouse left-icon mr-2" />
                                                <div style={{ marginTop: 1, marginLeft: 3 }}>NEW BILLING SETTINGS</div>
                                            </motion.div>
                                            {hasAccess(PERMISSIONS.MULTI_STOP_BILLING_SETTINGS, ['Edit']) && (
                                                <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                                    onClick={() => toggleRestoreModal()}
                                                >
                                                    <MultiStopBilling />
                                                    <div style={{ marginTop: 1, marginLeft: 3 }}>
                                                        MULTI-STOP BILLING
                                                    </div>
                                                </motion.div>
                                            )}
                                        </Row>
                                    </Row>
                                    <hr />
                                    <div style={{ marginTop: -15 }}>
                                        <TableCustom
                                            id="warehouse-site-location-id"
                                            tableData={billingSettingListData}
                                            loading={billingSettingLoading}
                                            isFullScreenShow
                                            handleEdit={handleEditBillingSetting}
                                            showView={false}
                                            showEdit={hasAccess(PERMISSIONS.DELIVERY, ['Edit'])}
                                            removeId={false}
                                            filters={[]}
                                            isSearch={false}
                                            isFilter={false}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </>
                    )}

                    {/* Delivery Dates Configuration Section */}
                    {editingMode && hasAccess(PERMISSIONS.AUTOMATIC_DELIVERY_DATE_SETTINGS, ['View']) && (
                        <DeliveryDateSettings merchantData={formData} />
                    )}
                    <br />
                    {!editingMode && visitingClient && (
                        <>
                            <MerchantAdminForm
                                merchantAdminFormData={merchantAdminFormData}
                                setMerchantAdminFormData={setMerchantAdminFormData}
                                adminFormRef={adminFormRef}
                                merchantFormRef={merchantFormRef}
                                merchantData={formData}
                                setFormData={setFormData}
                                isMerchantFormSubmitted={submitted}
                                setSubmitted={setSubmitted}
                                formLoading={formLoading}
                                editingMode={editingMode}
                                validated={validated}
                            />

                            <div className="container-fluid">
                                <Row className="mb-3" style={{ justifyContent: 'flex-end' }}>
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                        disabled={formLoading}
                                        form="merchantForm"
                                        type="submit"
                                    >
                                        {formLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <i className="fas fa-save left-icon"></i>
                                                <span className="h6">{editingMode ? 'Update' : 'Create Merchant'}</span>
                                            </>
                                        )}
                                    </motion.button>
                                </Row>
                            </div>
                        </>
                    )}

                    {/* {editingMode && (
                        <>
                            <h4 className="card-title">Summary of the deliveries</h4>
                            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="w3-animate-opacity"
                                >
                                    <StatCard
                                        title="Ongoing Deliveries"
                                        value="45 DELIVERIES"
                                        icon="mdi mdi-clock-outline"
                                    />
                                </motion.div>
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="w3-animate-opacity"
                                >
                                    <StatCard
                                        title="Deliveries ready to pickup"
                                        value="12 NEW DELIVERIES"
                                        icon="fas fa-exclamation-circle pb-3 mt-2"
                                    />
                                </motion.div>
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="w3-animate-opacity"
                                >
                                    <StatCard
                                        title="Deliveries Completed Today"
                                        value="12 COMPLETED DELIVERIES"
                                        icon="fas fa-exclamation-circle pb-3 mt-2"
                                    />
                                </motion.div>
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="w3-animate-opacity"
                                >
                                    <StatCard
                                        title="Deliveries completed month"
                                        value="12 NEW COMPLETED MONTH"
                                        icon="fas fa-exclamation-circle pb-3 mt-2"
                                    />
                                </motion.div>
                            </Row>
                        </>
                    )} */}
                </Container>
            </div>

            {imageUploadModalState.show && (
                <ImageUploadModal toggleModal={toggleImageUploadModal} {...imageUploadModalState} />
            )}

            {!scootixModalState.type && <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />}

            {showDeleteModal && (
                <DeleteMerchantModal
                    show={showDeleteModal}
                    onCancel={() => setShowDeleteModal(false)}
                    cancelMessage="Back"
                    onConfirm={() => handleDeleteMerchant(formData)}
                    merchantUsers={merchantUsers || []}
                />
            )}

            {/* Multi-Stop billing setting update Modal */}
            {showMultiStopBillingModal && (
                <MultiStopBillingUpdateModal
                    show={showMultiStopBillingModal}
                    onclose={setShowMultiStopBillingModal}
                    handleMultiStopBillingUpdate={handleMultiStopBillingUpdate}
                    merchantSettings={merchantSettingsFormData}
                    formLoading={formLoading}
                />
            )}

            {!__DEV__ && <BackdropLoader show={backdropLoading} opacity={0.8} />}
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

MerchantFormPage.propTypes = {
    location: PropTypes.any,
};

const HotMerchantFormPage = hot(MerchantFormPage);

export default connect(mapStateToProps, {})(HotMerchantFormPage);
