import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Label } from 'reactstrap';

// #endregion imports
import Search from './TableHeaderComponents/TableSearch';

function ManageConnectedAppsTableHeader(props) {
    const { isManualSearch, onFilter, isSearch, setSearch, search, checked, handleCheckChange } = props;

    const handleCheck = (event) => {
        handleCheckChange(event.target.name, event.target.checked);
    };

    return (
        <div className="table-top-row align-items-center justify-content-start">
            {isSearch && (
                <Col xs="auto" className="pl-0">
                    <Search
                        onFilter={onFilter}
                        isManualSearch={isManualSearch}
                        setSearch={setSearch}
                        search={search}
                        searchPlaceholder="Type the App name"
                    />
                </Col>
            )}
            <Col xs="auto" className="ml-5">
                <Input type="checkbox" name="active" checked={checked.includes('active')} onChange={handleCheck} />
                <Label htmlFor="active">Connected Apps</Label>
            </Col>
            <Col xs="auto" className="ml-5">
                <Input type="checkbox" name="inactive" checked={checked.includes('inactive')} onChange={handleCheck} />
                <Label htmlFor="inactive">Disconnected Apps</Label>
            </Col>
        </div>
    );
}

ManageConnectedAppsTableHeader.propTypes = {
    isManualSearch: PropTypes.bool,
    onFilter: PropTypes.func,
    isSearch: PropTypes.bool,
    setSearch: PropTypes.func,
    search: PropTypes.object,
    /** Shows which checkbox is selected ('active' or 'inactive') */
    checked: PropTypes.arrayOf(PropTypes.string),
    handleCheckChange: PropTypes.func,
};

export default ManageConnectedAppsTableHeader;
