import { GET_MERCHANT_PARTNER_DATA } from './actionTypes';

const INIT_STATE = {
    merchantRecordData: null,
};

const apiPartnerDataReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MERCHANT_PARTNER_DATA:
            return {
                ...state,
                merchantRecordData: action.payload,
            };
        default:
            return state;
    }
};

export default apiPartnerDataReducer;
