/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment from 'moment';
import { Card, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';

// #region constants
import { TRANSACTION_METHODS } from 'constants/enums';

/**
 * Modal for Transaction details
 */
function StockReceivingTransactionModal(props) {
    const { show, handleToggle, data } = props;

    const {
        sequence,
        method = '',
        meta,
        createdByUserFullName,
        updatedByUserFullName,
        merchant,
        warehouseSite,
        createdAt,
    } = data;

    const formatDate = (date) => moment(date).format('DD-MM-YYYY HH:mm a') || '';

    const updatedAt = formatDate(data.updatedAt);

    return (
        <DeveloperModal header={`Transaction data for ${sequence}`} show={show} handleToggle={handleToggle}>
            <Container>
                <Card style={{ height: '200px', overflowY: 'scroll', overflowX: 'hidden', boxShadow: 'none' }}>
                    <Row className="mb-2">
                        <Col>
                            {method === TRANSACTION_METHODS.INVENTORY.STOCK_RECEIVING.GRN_CREATED && (
                                <>
                                    <div className="ml-1">
                                        <h6>
                                            <b>Merchant</b>: {merchant.name || ''}
                                        </h6>
                                        <h6>
                                            <b>Stock From</b>: {meta?.formData?.stocksFrom || ''}
                                        </h6>
                                        <h6>
                                            <b>Delivery Note Reference</b>:{' '}
                                            {meta?.formData?.deliveryNoteReference || ''}
                                        </h6>
                                        <h6>
                                            <b>Additional Details</b>: {meta?.formData?.additionalDetails || ''}
                                        </h6>
                                        <h6>
                                            <b>Warehouse Site</b>: {warehouseSite.siteName}
                                        </h6>
                                    </div>
                                    <hr />
                                    <h6>
                                        <b>Created By</b>: {updatedByUserFullName || ''}
                                    </h6>
                                    <h6>
                                        <b>Created At</b>: {createdAt || ''}
                                    </h6>
                                </>
                            )}
                            {method === TRANSACTION_METHODS.INVENTORY.STOCK_RECEIVING.GRN_UPDATED && (
                                <>
                                    <div className="ml-1">
                                        <h6>
                                            <b>Merchant</b>: {merchant?.name || ''}
                                        </h6>
                                        <h6>
                                            <b>Stock From</b>: {meta?.formData?.stocksFrom || ''}
                                        </h6>
                                        <h6>
                                            <b>Delivery Note Reference</b>:{' '}
                                            {meta?.formData?.deliveryNoteReference || ''}
                                        </h6>
                                        <h6>
                                            <b>Additional Details</b>: {meta?.formData?.additionalDetails || ''}
                                        </h6>
                                        <h6>
                                            <b>Warehouse Site</b>: {warehouseSite.siteName}
                                        </h6>
                                    </div>
                                    <hr />
                                    <h5>Items</h5>
                                    <div
                                        style={{
                                            maxWidth: '100%',
                                            display: 'grid',
                                            gridTemplateColumns: 'max-content auto',
                                            gap: '10px',
                                        }}
                                    >
                                        {meta.previousItems.map((item) => (
                                            <div key={item._id}>
                                                <div>
                                                    <b>SKU Code:</b> {item.skuCode}
                                                </div>
                                                <div>
                                                    <b>UPC:</b> {item.upc}
                                                </div>
                                                <div>
                                                    <b>Product Description:</b> {item.productMasterDataDescription}
                                                </div>
                                                <div>
                                                    <b>Scootix Product Code:</b> {item.productMasterDataReferenceNumber}
                                                </div>
                                                <div>
                                                    <b>Batch:</b> {item.batch}
                                                </div>
                                                <div>
                                                    <b>Serial Number:</b> {item.serialNumber}
                                                </div>
                                                <div>
                                                    <b>Manufacture Date:</b> {formatDate(item.manufactureDate)}
                                                </div>
                                                <div>
                                                    <b>Expiry Date:</b> {formatDate(item.expiryDate)}
                                                </div>
                                                <div>
                                                    <b>Unit of Measure:</b> {item.unitOfMeasure}
                                                </div>
                                                <div>
                                                    <b>Available Quantity:</b> {item.availableQuantity}
                                                </div>
                                                <div>
                                                    <b>Quantity:</b> {item.quantity}
                                                </div>
                                                <div>
                                                    <b>Warehouse Zone:</b> {item.warehouseZone?.zoneName}
                                                </div>
                                                <div>
                                                    <b>Bin Location:</b> {item.binLocation?.binLocationName}
                                                </div>
                                                <div>
                                                    <b>Date Received:</b> {formatDate(item.dateReceived)}
                                                </div>
                                                <hr />
                                            </div>
                                        ))}
                                    </div>

                                    <h6>
                                        <b>Updated By</b>: {createdByUserFullName || ''}
                                    </h6>
                                    <h6>
                                        <b>Updated At</b>: {updatedAt || ''}
                                    </h6>
                                </>
                            )}
                            {method === TRANSACTION_METHODS.INVENTORY.STOCK_RECEIVING.GRN_CONFIRMED && (
                                <>
                                    <h6>
                                        <b>Confirmed By</b>: {createdByUserFullName || ''}
                                    </h6>
                                    <h6>
                                        <b>Confirmed At</b>: {createdAt || ''}
                                    </h6>
                                </>
                            )}
                            {method === TRANSACTION_METHODS.INVENTORY.STOCK_RECEIVING.GRN_REVERSED && (
                                <>
                                    <h6>
                                        <b>Reversed By</b>: {createdByUserFullName || ''}
                                    </h6>
                                    <h6>
                                        <b>Reversed At</b>: {createdAt || ''}
                                    </h6>
                                </>
                            )}
                            {method === TRANSACTION_METHODS.INVENTORY.STOCK_RECEIVING.GRN_DELETED && (
                                <>
                                    <h6>
                                        <b>Deleted By</b>: {createdByUserFullName || ''}
                                    </h6>
                                    <h6>
                                        <b>Deleted At</b>: {createdAt || ''}
                                    </h6>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card>
            </Container>
        </DeveloperModal>
    );
}

StockReceivingTransactionModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    data: PropTypes.object,
};

export default StockReceivingTransactionModal;
