/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Row } from 'reactstrap';

// #region assets | components
import Hoc from 'components/Hoc';
import { SCOOTIX_DARK_LOGO_IMAGE, TASA_LOGO_IMAGE } from 'definitions/assets';

// #region imports

// #endregion imports
import './index.css';

function TermsConditionScootixApplication(props) {
    return (
        <Hoc {...props}>
            <div>
                <div className="a4-sheet-container p-4 pl-5 pr-5">
                    <Row className="justify-content-between" style={{ alignItems: 'center' }}>
                        <div>
                            <img src={SCOOTIX_DARK_LOGO_IMAGE} width={100} height={100} alt="ScootiX Logo" />
                        </div>
                        <div>
                            <img src={TASA_LOGO_IMAGE} width={120} height={60} alt="ScootiX Logo" />
                        </div>
                    </Row>
                    <hr color="#000" />
                    <div className="pl-4 pr-4">
                        <Row className="justify-content-between" style={{ alignItems: 'center' }}>
                            <div className="common-text">ScootiX Application Privacy Policy</div>
                            <div className="common-text">Version 1.0 Published on March 10, 2022</div>
                        </Row>
                        <Row className="mt-3 mb-3">
                            <span className="heading">Introduction</span>
                        </Row>
                        <Row className="mb-3">
                            <div className="common-text">
                                ScootiX is a B2B Rider Management platform that allows businesses to assign, track and
                                manage dedicated or shared riders for their delivery needs in Malaysia.
                            </div>
                        </Row>
                        <Row>
                            <div className="common-text">
                                ScootiX mobile application facilitate for ScootiX riders to;
                            </div>
                        </Row>
                        <Row className="pl-1 mt-3">
                            <ul className="common-text">
                                <li>Register as a ScootiX Rider.</li>
                                <li>Accept deliveries created by merchants or by TASA Global Solutions Sdn Bhd</li>
                                <li>Manage the accepted or assigned deliveries</li>
                                <li>
                                    Provide the delivery progress updates to relevant merchants and to the recipients
                                    (customers)
                                </li>
                                <li>Collect proof of deliveries from the recipients (customers)</li>
                            </ul>
                        </Row>
                        <Row>
                            <div className="common-text mt-2">
                                ScootiX mobile application facilitate for ScootiX merchants to;
                            </div>
                        </Row>
                        <Row className="pl-1 mt-3">
                            <ul className="common-text">
                                <li>Register as a ScootiX merchant.</li>
                                <li>Create Deliveries</li>
                                <li>Monitor the real time progress of the delivery</li>
                                <li>View your delivery fees</li>
                                <li>View your order fulfillment progress</li>
                            </ul>
                        </Row>
                        <Row className="mt-3 mb-3">
                            <span className="heading">Privacy Notice</span>
                        </Row>
                        <Row className="mb-2">
                            <div className="common-text">
                                Thank you for choosing to be part of our community at TASA Global Solutions Sdn Bhd
                                ("Company/’ "we," "us," or "our"). We are committed to protecting your personal
                                information and your right to privacy. If you have any questions or concerns about this
                                privacy notice or our practices with regard to your personal information, please contact
                                us at scootix@tasa.com.my.
                            </div>
                        </Row>
                        <Row>
                            <div className="common-text mt-2">
                                This privacy notice describes how we might use your information if you:
                            </div>
                        </Row>
                        <Row className="pl-1 mt-3">
                            <ul className="common-text">
                                <li>Download and use our mobile application — ScootiX</li>
                                <li>
                                    "App," we are referring to any application of ours that references or links to this
                                    policy, including any listed above
                                </li>
                                <li>
                                    "Services," we are referring to our App, and other related services, including any
                                    sales, marketing, or events
                                </li>
                            </ul>
                        </Row>
                        <Row className="mb-2">
                            <div className="common-text">
                                The purpose of this privacy notice is to explain to you in the clearest way possible
                                what information we collect, how we use it, and what rights you have in relation to it.
                                If there are any terms in this privacy notice that you do not agree with, please
                                discontinue use of our Services immediately
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <div className="common-text">
                                Please read this privacy notice carefully, as it will help you understand what we do
                                with the information that we collect
                            </div>
                        </Row>
                        <Row>
                            <div className="common-text mt-2">TABLE OF CONTENTS</div>
                        </Row>
                        <Row className="pl-1 mt-3">
                            <ol className="common-text toc-1">
                                <li>WHAT INFORMATION DO WE COLLECT?</li>
                                <li>HOW DO WE USE YOUR INFORMATION?</li>
                                <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
                                <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
                                <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                                <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
                                <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
                                <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
                                <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                                <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
                                <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
                                <li>HOW CAN YOU REVIEW. UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</li>
                            </ol>
                        </Row>
                        <Row className="mb-n2 mt-3">
                            <div className="heading-2">1. WHAT INFORMATION DO WE COLLECT?</div>
                        </Row>
                        <Row className="mb-3 d-flex flex-column para common-text">
                            <div>Personal information you disclose to us</div>
                            <div>In Short: We collect personal information that you provide to us</div>
                            <div>
                                We collect personal information that you voluntarily provide to us when you register on
                                the App, express an interest in obtaining information about us or our products and
                                Services, when you participate in activities on the App or otherwise when you contact
                                us.
                            </div>
                            <div>
                                The personal information that we collect depends on the context of your interactions
                                with us and the App, the choices you make and the products and features you use. The
                                personal information we collect may include the following:
                            </div>
                            <div>
                                Personal Information Provided by You. We collect names; phone numbers; email addresses;
                                mailing addresses; usernames; contact preferences; contact or authentication data;
                                password for the app; job titles; profile picture, national registration identity card;
                                road tax license; photo of the vehicle use to provide the service; driver’s license; and
                                other similar information
                            </div>
                            <div>
                                All personal information that you provide to us must be true, complete and accurate, and
                                you must notify us of any changes to such personal information.
                            </div>
                            <div>Information collected through our App</div>
                            <div>
                                In Short: We collect information regarding your geolocation, mobile device, push
                                notifications, when you use our App.
                            </div>
                            <div>If you use our App, we also collect the following information:</div>
                            <div>■ {'   '} Geolocation Information.</div>
                            <div>
                                When you use the Services delivering the parcels/packages, we collect precise location
                                data about the trip from the ScootiX App used by the ScootiX Delivery Partner. If you
                                permit the ScootiX App to access location services through the permission system used by
                                your mobile operating system (“Platform”), we may also collect the precise location of
                                your device when the App is running in the foreground or background. We may also derive
                                your approximate location from your IP address.
                            </div>
                            <div>In detail we collect location in following transactions;</div>
                            <Row className="mt-3 mb-n2" style={{ paddingLeft: 12 }}>
                                <span className="heading">For Delivery Partners (Riders and Drivers)</span>
                            </Row>

                            <Row className="pl-1 mt-3">
                                <div className="common-text toc-1 pl-4">
                                    <div>
                                        - To assign the deliveries for delivery partners from the nearest merchants to
                                        your current location.
                                    </div>
                                    <div>
                                        - To provide real-time delivery updates for the merchants about the deliveries
                                        that delivery partners are delivering (Location will be shared with the
                                        merchant, only till the delivery is marked as completed from the event of
                                        delivery picking)
                                    </div>
                                    <div>
                                        - To monitor the delivery progress ( Delivery Trip of the delivery partner) by
                                        the ScootiX Delivery management team at TASA GLOBAL SDN BHD.
                                    </div>
                                </div>
                            </Row>
                            <div>
                                ■ Mobile Device Access. We may request access or permission to certain features from
                                your mobile device, including your mobile device's camera, storage. If you wish to
                                change our access or permissions, you may do so in your device's settings
                            </div>
                            <div>
                                ■ Mobile Device Data. We automatically collect device information (such as your mobile
                                device ID, model and manufacturer), operating system, version information and system
                                configuration information, device and application identification numbers, browser type
                                and version, hardware model, Internet service provider and/or mobile carrier, and
                                Internet Protocol (IP) address (or proxy server). If you are using our App, we may also
                                collect information about the phone network associated with your mobile device, your
                                mobile device’s operating system or platform, the type of mobile device you use, your
                                mobile device’s unique device ID and information about the features of our App you
                                accessed.
                            </div>
                            <div>
                                ■ Push Notifications. We may request to send you push notifications regarding your
                                account or certain features of the App. If you wish to opt-out from receiving these
                                types of communications, you may turn them off in your device's settings
                            </div>
                            <div>
                                This information is primarily needed to maintain the security and operation of our App,
                                for troubleshooting and for our internal analytics and reporting purposes.
                            </div>
                        </Row>
                        <Row className="mb-n2 mt-3">
                            <div className="heading-2">2. HOW DO WE USE YOUR INFORMATION?</div>
                        </Row>
                        <Row className="mb-3 d-flex flex-column para common-text">
                            <div>
                                In Short: We process your information for purposes based on legitimate business
                                interests, the fulfillment of our contract with you, compliance with our legal
                                obligations, and/or your consent.
                            </div>
                            <div>
                                We use personal information collected via our App for a variety of business purposes
                                described below. We process your personal information for these purposes in reliance on
                                our legitimate business interests, in order to enter into or perform a contract with
                                you, with your consent, and/or for compliance with our legal obligations. We indicate
                                the specific processing grounds we rely on next to each purpose listed below.
                            </div>
                            <div>We use the information we collect or receive:</div>
                            <div>
                                ■ To manage user accounts. We may use your information for the purposes of managing our
                                account and keeping it in working order.
                            </div>
                            <div>
                                ■ To send administrative information to you. We may use your personal information to
                                send you product, service and new feature information and/or information about changes
                                to our terms, conditions, and policies.
                            </div>
                            <div>
                                ■ To provide the real-time delivery updates for the recipients and the relevant merchant
                                of the deliveries that you are delivering.{' '}
                            </div>
                            <div>
                                ■ To protect our Services. We may use your information as part of our efforts to keep
                                our App safe and secure (for example, for fraud monitoring and prevention).
                            </div>
                            <div>
                                ■ To enforce our terms, conditions and policies for business purposes, to comply with
                                legal and regulatory requirements or in connection with our contract.
                            </div>
                            <div>
                                ■ To respond to legal requests and prevent harm. If we receive a subpoena or other legal
                                request, we may need to inspect the data we hold to determine how to respond
                            </div>
                            <div>
                                ■ To respond to user inquiries/offer support to users. We may use your information to
                                respond to your inquiries and solve any potential issues you might have with the use of
                                our Services.
                            </div>
                            <div>
                                ■ For other business purposes. We may use your information for other business purposes,
                                such as data analysis, identifying usage trends, determining the effectiveness of our
                                promotional campaigns and to evaluate and improve our App, products, marketing and your
                                experience. We may use and store this information in aggregated and anonymized form so
                                that it is not associated with individual end users and does not include personal
                                information.
                            </div>
                        </Row>
                        <Row className="mb-n2 mt-3">
                            <div className="heading-2">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</div>
                        </Row>
                        <Row className="mb-3 d-flex flex-column para common-text">
                            <div>
                                In Short: We only share information with your consent, to comply with laws, to provide
                                you with services, to protect your rights, or to fulfill business obligations.
                            </div>
                            <div>
                                We may process or share your data that we hold based on the following legal basis:
                            </div>
                            <div>
                                ■ Consent: We may process your data if you have given us specific consent to use your
                                personal information for a specific purpose
                            </div>
                            <div>
                                ■ Legitimate Interests: We may process your data when it is reasonably necessary to
                                achieve our legitimate business interests
                            </div>
                            <div>
                                ■ Performance of a Contract: Where we have entered into a contract with you, we may
                                process your personal information to fulfill the terms of our contract.
                            </div>
                            <div>
                                ■ Legal Obligations: We may disclose your information where we are legally required to
                                do so in order to comply with applicable law, governmental requests, a judicial
                                proceeding, court order, or legal process, such as in response to a court order or a
                                subpoena (including in response to public authorities to meet national security or law
                                enforcement requirements).
                            </div>
                            <div>
                                ■ Vital Interests: We may disclose your information where we believe it is necessary to
                                investigate, prevent, or take action regarding potential violations of our policies,
                                suspected fraud, situations involving potential threats to the safety of any person and
                                illegal activities, or as evidence in litigation in which we are involved.
                            </div>
                            <div>
                                More specifically, we may need to process your data or share your personal information
                                in the following situations:
                            </div>
                            <div>
                                ■ Business Transfers. We may share or transfer your information in connection with, or
                                during negotiations of, any merger, sale of company assets, financing, or acquisition of
                                all or a portion of our business to another company.
                            </div>
                            <div>
                                ■ Google Maps Platform APIs. We may share your information with certain Google Maps
                                Platform APIs (e.g., Google Maps API, Place API). To find out more about Google’s
                                Privacy Policy, please refer to this link. We use certain Google Maps Platform APIs to
                                retrieve certain information when you make location-specific requests. This includes:
                                Rider's live location; Distance travelled for a delivery; and other similar information.
                                A full list of what we use information for can be found in this section and in the
                                previous section titled "FiOW DO WE USE YOUR INFORMATION?". The Google Maps Platform
                                APIs that we use store and access cookies and other information on your devices. If you
                                are a user currently in the European Economic Area (EU countries, Iceland, Liechtenstein
                                and Norway) or United Kingdom, please take a look at our Cookie Notice.
                            </div>
                        </Row>
                        <Row className="mb-n2 mt-3">
                            <div className="heading-2">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</div>
                        </Row>
                        <Row className="mb-3 d-flex flex-column para common-text">
                            <div>
                                In Short: We may use cookies and other tracking technologies to collect and store your
                                information
                            </div>
                        </Row>
                        <Row className="mb-n2 mt-3">
                            <div className="heading-2">5. HOW LONG DO WE KEEP YOUR INFORMATION?</div>
                        </Row>
                        <Row className="mb-3 d-flex flex-column para common-text">
                            <div>
                                In Short: We keep your information for as long as necessary to fulfill the purposes
                                outlined in this privacy notice unless otherwise required by law.
                            </div>
                            <div>
                                We will only keep your personal information for as long as it is necessary for the
                                purposes set out in this privacy notice, unless a longer retention period is required or
                                permitted by law (such as tax, accounting or other legal requirements). No purpose in
                                this notice will require us keeping your personal information for longer than the period
                                of time in which users have an account with us.
                            </div>
                            <div>
                                When we have no ongoing legitimate business need to process your personal information,
                                we will either delete or anonymize such information, or, if this is not possible (for
                                example, because your personal information has been stored in backup archives), then we
                                will securely store your personal information and isolate it from any further processing
                                until deletion is possible.
                            </div>
                        </Row>
                        <Row className="mb-n2 mt-3">
                            <div className="heading-2">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</div>
                        </Row>
                        <Row className="mb-3 d-flex flex-column para common-text">
                            <div>
                                In Short: We aim to protect your personal information through a system of organizational
                                and technical security measures
                            </div>
                            <div>
                                We have implemented appropriate technical and organizational security measures designed
                                to protect the security of any personal information we process. However, despite our
                                safeguards and efforts to secure your information, no electronic transmission over the
                                Internet or information storage technology can be guaranteed to be 100% secure, so we
                                cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third
                                parties will not be able to defeat our security, and improperly collect, access, steal,
                                or modify your information. Although we will do our best to protect your personal
                                information, transmission of personal information to and from our App is at your own
                                risk. You should only access the App within a secure environment
                            </div>
                        </Row>
                        <Row className="mb-n2 mt-3">
                            <div className="heading-2">7. DO WE COLLECT INFORMATION FROM MINORS?</div>
                        </Row>
                        <Row className="mb-3 d-flex flex-column para common-text">
                            <div>
                                In Short: We do not knowingly collect data from or market to children under 18 years of
                                age.
                            </div>
                            <div>
                                We do not knowingly solicit data from or market to children under 18 years of age. By
                                using the App, you represent that you are at least 18 or that you are the parent or
                                guardian of such a minor and consent to such minor dependent’s use of the App. If we
                                learn that personal information from users less than 18 years of age has been collected,
                                we will deactivate the account and take reasonable measures to promptly delete such data
                                from our records. If you become aware of any data we may have collected from children
                                under age 18, please contact us at scootix@tasa.com.my.
                            </div>
                        </Row>
                        <Row className="mb-n2 mt-3">
                            <div className="heading-2">8. WHAT ARE YOUR PRIVACY RIGHTS?</div>
                        </Row>
                        <Row className="mb-3 d-flex flex-column para common-text">
                            <div>In Short: You may review, change, or terminate your account at any time.</div>
                            <div>
                                If you are a resident in the EEA or UK and you believe we are unlawfully processing your
                                personal information, you also have the right to complain to your local data protection
                                supervisory authority. You can find their contact details here:
                                https://ec.europa.eu/iustice/data- protection/bodies/authorities/index en.htm.
                            </div>
                            <div>
                                If you are a resident in Switzerland, the contact details for the data protection
                                authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.
                            </div>
                            <div>
                                If you have questions or comments about your privacy rights, you may email us at
                                scootix@tasa.com.my.
                            </div>
                            <div>Account Information</div>
                            <div>
                                If you would at any time like to review or change the information in your account or
                                terminate your account, you can:
                            </div>
                            <div>■ Contact us using the contact information provided.</div>
                            <div>
                                Upon your request to terminate your account, we will deactivate or delete your account
                                and information from our active databases. However, we may retain some information in
                                our files to prevent fraud, troubleshoot problems, assist with any investigations,
                                enforce our Terms of Use and/or comply with applicable legal requirements
                            </div>
                        </Row>
                        <Row className="mb-n2 mt-3">
                            <div className="heading-2">9. CONTROLS FOR DO-NOT-TRACK FEATURES</div>
                        </Row>
                        <Row className="mb-3 d-flex flex-column para common-text">
                            <div>
                                Most web browsers and some mobile operating systems and mobile applications include a
                                Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy
                                preference not to have data about your online browsing activities monitored and
                                collected. At this stage no uniform technology standard for recognizing and implementing
                                DNT signals has been finalized. As such, we do not currently respond to DNT browser
                                signals or any other mechanism that automatically communicates your choice not to be
                                tracked online. If a standard for online tracking is adopted that we must follow in the
                                future, we will inform you about that practice in a revised version of this privacy
                                notice.
                            </div>
                        </Row>
                        <Row className="mb-n2 mt-3">
                            <div className="heading-2">10. DO WE MAKE UPDATES TO THIS NOTICE?</div>
                        </Row>
                        <Row className="mb-3 d-flex flex-column para common-text">
                            <div>
                                In Short: Yes, we will update this notice as necessary to stay compliant with relevant
                                laws
                            </div>
                            <div>
                                We may update this privacy notice from time to time. The updated version will be
                                indicated by an updated "Revised" date and the updated version will be effective as soon
                                as it is accessible. If we make material changes to this privacy notice, we may notify
                                you either by prominently posting a notice of such changes or by directly sending you a
                                notification. We encourage you to review this privacy notice frequently to be informed
                                of how we are protecting your information.
                            </div>
                        </Row>
                        <Row className="mb-n2 mt-3">
                            <div className="heading-2">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</div>
                        </Row>
                        <Row className="mb-3 d-flex flex-column para common-text">
                            <div>
                                If you have questions or comments about this notice, you may email us at
                                scootix@tasa.com.my or by post to:
                            </div>
                            <div>
                                Unit 1.1, Level 1, Block B, Quill 18, 3, Lingkaran Teknokrat 3 Barat, Cyber 4, 63000
                                Cyberjaya, Selangor, Malaysia
                            </div>
                        </Row>
                        <Row className="mb-n2 mt-3">
                            <div className="heading-2">
                                12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                            </div>
                        </Row>
                        <Row className="mb-3 d-flex flex-column para common-text">
                            <div>
                                Based on the applicable laws of your country, you may have the right to request access
                                to the personal information we collect from you, change that information, or delete it
                                in some circumstances. To request to review, update, or delete your personal
                                information, please email to: scootix@tasa.com.my
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        </Hoc>
    );
}

TermsConditionScootixApplication.propTypes = {};

export default TermsConditionScootixApplication;
