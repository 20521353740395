export const userFormValidation = {
    username: {
        required: { value: true, errorMessage: 'Please enter a name' },

        pattern: {
            value: '([A-Za-z0-9]+[.]?[_]?)$',

            errorMessage:
                'Username must be composed only with letters, numbers and can be one occurrence of "." and "_"',
        },

        minLength: {
            value: 6,

            errorMessage: 'Username must be between 6 and 16 characters',
        },

        maxLength: {
            value: 16,

            errorMessage: 'Username must be between 6 and 16 characters',
        },
    },
};
