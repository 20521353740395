// Excel CSV Import Default Config
// Y = true & N = null
export const deliveryExcelCsvImportConfig = {
    fieldNames: {
        no: {
            default: 'No',
            key: 'no',
            value: 'No',
            type: 'Number',
        },
        deliveryType: {
            default: 'Delivery Type',
            key: 'deliveryType',
            value: 'Delivery Type',
            type: 'String',
        },
        source: {
            default: 'Source',
            key: 'source',
            value: 'Source',
            type: 'String',
        },
        vehicleType: {
            default: 'Vehicle Type',
            key: 'vehicleType',
            value: 'Vehicle Type',
            type: 'String',
        },
        deliveryReadyTime: {
            default: 'Delivery Ready Date and Time',
            key: 'deliveryReadyTime',
            value: 'Delivery Ready Date and Time',
            type: 'Date',
        },
        isDeliveryTimeSlot: {
            default: 'Special Delivery Time',
            key: 'isDeliveryTimeSlot',
            value: 'Special Delivery Time',
            type: 'Boolean',
        },
        deliveryTimeSlot: {
            default: 'Special Delivery Date and Time',
            key: 'deliveryTimeSlot',
            value: 'Special Delivery Date and Time',
            type: 'Date',
        },
        orderReferenceNumber: {
            default: 'Order Number',
            key: 'orderReferenceNumber',
            value: 'Order Number',
            type: 'String',
        },
        pickupLocation: {
            default: 'Pickup Location',
            key: 'pickupLocation',
            value: 'Pickup Location',
            type: 'String',
        },
        recipientName: {
            default: 'Recipient Name',
            key: 'recipientName',
            value: 'Recipient Name',
            type: 'String',
        },
        recipientMobileNumber: {
            default: 'Recipient Mobile Number',
            key: 'recipientMobileNumber',
            value: 'Recipient Mobile Number',
            type: 'String',
        },
        recipientEmail: {
            default: 'Recipient Email Address',
            key: 'recipientEmail',
            value: 'Recipient Email Address',
            type: 'String',
        },
        deliveryAddress: {
            default: 'Delivery Address',
            key: 'deliveryAddress',
            value: 'Delivery Address',
            type: 'String',
        },
        city: {
            default: 'City',
            key: 'city',
            value: 'City',
            type: 'String',
        },
        state: {
            default: 'State',
            key: 'state',
            value: 'State',
            type: 'String',
        },
        isFragile: {
            default: 'Fragile',
            key: 'isFragile',
            value: 'Fragile',
            type: 'Boolean',
        },
        isFrozen: {
            default: 'Frozen',
            key: 'isFrozen',
            value: 'Frozen',
            type: 'Boolean',
        },
        isHighValue: {
            default: 'High Value',
            key: 'isHighValue',
            value: 'High Value',
            type: 'Boolean',
        },
        isChill: {
            default: 'Chill',
            key: 'isChill',
            value: 'Chill',
            type: 'Boolean',
        },
        isHalal: {
            default: 'Halal',
            key: 'isHalal',
            value: 'Halal',
            type: 'Boolean',
        },
        isFemaleDriver: {
            default: 'Female Driver',
            key: 'isFemaleDriver',
            value: 'Female Driver',
            type: 'Boolean',
        },
        deliveryNote: {
            default: 'Delivery Note',
            key: 'deliveryNote',
            value: 'Delivery Note',
            type: 'String',
        },
    },
};

export const deliveryExcelCsvImportConfigDoctorOnCallModel = {
    fieldNames: {
        no: {
            default: 'No',
            key: 'no',
            value: 'No',
            type: 'Number',
        },
        source: {
            default: 'Source',
            key: 'source',
            value: 'Source',
            type: 'String',
        },
        deliveryReadyTime: {
            default: 'Delivery Ready Date and Time',
            key: 'deliveryReadyTime',
            value: 'Delivery Ready Date and Time',
            type: 'Date',
        },
        deliveryDate: {
            default: 'Delivery Date',
            key: 'deliveryDate',
            value: 'Delivery Date',
            type: 'Date',
        },
        specialDeliveryTime: {
            default: 'Special Delivery Time',
            key: 'specialDeliveryTime',
            value: 'Special Delivery Time',
            type: 'String',
        },
        specialDeliveryDateTime: {
            default: 'Special Delivery Date Time',
            key: 'specialDeliveryDateTime',
            value: 'Special Delivery Date Time',
            type: 'Date',
        },
        merchantOrderNo: {
            default: 'Merchant Order Number',
            key: 'merchantOrderNo',
            value: 'Merchant Order Number',
            type: 'String',
        },
        recipientEmail: {
            default: 'Recipient Email Address',
            key: 'recipientEmail',
            value: 'Recipient Email Address',
            type: 'String',
        },
        deliveryAddress: {
            default: 'Delivery Address',
            key: 'deliveryAddress',
            value: 'Delivery Address',
            type: 'String',
        },
        postalCode: {
            default: 'Delivery Postal Code',
            key: 'postalCode',
            value: 'Delivery Postal Code',
            type: 'String',
        },
        vehicleType: {
            default: 'Vehicle Type',
            key: 'vehicleType',
            value: 'Vehicle Type',
            type: 'String',
        },
        deliveryType: {
            default: 'Delivery Type',
            key: 'deliveryType',
            value: 'Delivery Type',
            type: 'String',
        },
        orderId: {
            default: 'Order ID',
            key: 'orderId',
            value: 'Order ID',
            type: 'String',
        },
        recipientName: {
            default: 'Name',
            key: 'recipientName',
            value: 'Name',
            type: 'String',
        },
        city: {
            default: 'City',
            key: 'city',
            value: 'City',
            type: 'String',
        },
        recipientMobileNumber: {
            default: 'Recipient Mobile Number',
            key: 'recipientMobileNumber',
            value: 'Recipient Mobile Number',
            type: 'String',
        },
        pickupLocation: {
            default: 'Pickup Location',
            key: 'pickupLocation',
            value: 'Pickup Location',
            type: 'String',
        },
        pickupLocationPostalCode: {
            default: 'Pickup Location Postal Code',
            key: 'pickupLocationPostalCode',
            value: 'Pickup Location Postal Code',
            type: 'String',
        },
        isFragile: {
            default: 'Fragile',
            key: 'isFragile',
            value: 'Fragile',
            type: 'Boolean',
        },
        isFrozen: {
            default: 'Frozen',
            key: 'isFrozen',
            value: 'Frozen',
            type: 'Boolean',
        },
        isHighValue: {
            default: 'High Value',
            key: 'isHighValue',
            value: 'High Value',
            type: 'Boolean',
        },
        isChill: {
            default: 'Chill',
            key: 'isChill',
            value: 'Chill',
            type: 'Boolean',
        },
        isHalal: {
            default: 'Halal',
            key: 'isHalal',
            value: 'Halal',
            type: 'Boolean',
        },
        isFemaleDriver: {
            default: 'Female Driver',
            key: 'isFemaleDriver',
            value: 'Female Driver',
            type: 'Boolean',
        },
        deliveryNote: {
            default: 'Delivery Note',
            key: 'deliveryNote',
            value: 'Delivery Note',
            type: 'String',
        },
    },
};
