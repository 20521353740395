import React from 'react';
import { DropdownItem } from 'reactstrap';

function FormDivider() {
    return (
        <div className="d-flex justify-content-center my-4">
            <DropdownItem divider style={{ border: '1px solid #807F7D', width: '95%' }} />
        </div>
    );
}

FormDivider.propTypes = {};

export default FormDivider;
