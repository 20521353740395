import { CREATE_PRINTER_SETTINGS_DATA, UPDATE_PRINTER_SETTINGS_DATA, PRINTER_SETTINGS_DATA } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const createPrinterSettingsData = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_PRINTER_SETTINGS_DATA).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const updatePrinterSettingsData = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_PRINTER_SETTINGS_DATA).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get printer settings data by type, module and user
 * @param {object} data
 * @returns {Promise}
 */
export const getPrinterSettingsByTypeModuleUser = (type, module, userId) => {
    try {
        const url = UrlBuilder().setUrl(PRINTER_SETTINGS_DATA).setPathParams({ type, module, userId }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
