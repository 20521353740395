/* eslint-disable global-require */
/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { setConfig } from 'react-hot-loader';

// #region imports
import ErrorBoundary from 'hoc/ErrorBoundary';
// import { __PROD__ } from 'utils/checks';

import App from './App';
import * as serviceWorker from './configs/serviceWorker';
import store from './store';
import './styles/index.css';
import './configs/ReactotronConfig';

const MOUNT_NODE = document.getElementById('root');
setConfig({ logLevel: 'no-errors-please' });

// if (__PROD__) {
//     // console.error = () => {};
//     console.warn = () => {};
// }

const app = (
    <ErrorBoundary>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </ErrorBoundary>
);

ReactDOM.render(app, MOUNT_NODE);

serviceWorker.unregister();

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        ReactDOM.render(
            <ErrorBoundary>
                <Provider store={store}>
                    <BrowserRouter>
                        <NextApp />
                    </BrowserRouter>
                </Provider>
            </ErrorBoundary>,
            MOUNT_NODE
        );
    });
}
