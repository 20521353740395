/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker } from 'react-leaflet';
import L from 'leaflet';

import { CustomMarkerPropTypes } from '../constants';
import CustomTooltip from './CustomTooltip';
import './styles/index.scss';

/**
 * This component is responsible for adding markers in a customized way
 * @returns a customized marker
 */
function CustomMarker(props) {
    const { mappedOrderArray, isPickup, setSelectedOrders, showRemove, setDeselectedOrders, isActionDisable } = props;
    /** create an initial reference for the marker */
    const markerRef = useRef(null);

    /** once the user hover over the marker this will triggered and popup the tooltip */
    useEffect(() => {
        const marker = markerRef.current;
        if (marker) {
            marker.on('mouseover', () => {
                marker.openPopup();
            });
        }
    }, [markerRef.current]);

    // This is the position state which we set lat and lan value as an array
    const [position, setPosition] = useState([0, 0]);
    // Checking whether multiple markers in the same place or not
    const isMultipleOrdersAtSamePoint = mappedOrderArray.length !== 1;

    // Set coordinates
    useEffect(() => {
        if (isPickup) {
            const pickupCoordinates = [
                mappedOrderArray[0]?.pickupLocationMeta?.coordinates?.lat,
                mappedOrderArray[0]?.pickupLocationMeta?.coordinates?.lng,
            ];
            setPosition(pickupCoordinates);
        } else {
            const deliverCoordinates = [
                mappedOrderArray[0]?.deliveryAddressMeta?.coordinates?.lat,
                mappedOrderArray[0]?.deliveryAddressMeta?.coordinates?.lng,
            ];
            setPosition(deliverCoordinates);
        }
    }, [mappedOrderArray, isPickup]);

    // Leaflet js allows us to create customizable jsx component in the below way
    const customIcon = L.divIcon({
        className: 'marker-on-map',
        html: ReactDOMServer.renderToString(
            // Below conditionally apply scss stylings for different marker types
            <span
                className={`${
                    isPickup ? 'marker-on-map__custom-marker-pickup' : 'marker-on-map__custom-marker-deliver'
                }`}
            >
                <h5 className="text-center">{isMultipleOrdersAtSamePoint ? 'M' : mappedOrderArray[0].id}</h5>
            </span>
        ),
    });

    return (
        <Marker ref={markerRef} position={position} icon={customIcon} isPickup={isPickup}>
            <CustomTooltip
                mappedOrderArray={mappedOrderArray}
                setSelectedOrders={setSelectedOrders}
                setDeselectedOrders={setDeselectedOrders}
                showRemove={showRemove}
                isActionDisable={isActionDisable}
            />
        </Marker>
    );
}

CustomMarker.propTypes = CustomMarkerPropTypes;
export default CustomMarker;
