/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Select } from 'components';
import { reactSelectCustomStyles } from 'utils/helpers';

function PermissionRow(props) {
    const { selectedRole, handlePermissionState, permissionData = {} } = props;
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    useEffect(() => {
        if (selectedRole.permissionArray) {
            const features = selectedRole.permissionArray[permissionData.permissionKey];
            if (features) {
                setSelectedPermissions(Object.values(features));
            } else {
                setSelectedPermissions([]);
            }
        } else {
            setSelectedPermissions([]);
        }
    }, [selectedRole]);

    /**
     * Multi Select Supplier Recipients Handler
     */
    const handleMultiChange = (option) => {
        // for those permissions, `View` permission will be added
        const addViewAutomaticallyLabels = ['Edit', 'Add', 'Detach Deliveries', 'Cancel'];

        if ((option || []).some((e) => e.label === 'All')) {
            setSelectedPermissions(permissionData.permissionsGiven, permissionData.permissionKey);
            handlePermissionState(permissionData.permissionsGiven, permissionData.permissionKey);
        }
        // if user select permission in `allViewLabels`, it automatically add the view as well
        // concat two arrays and removed duplicated values tp achieve this functionality
        else if ((option || []).some((e) => addViewAutomaticallyLabels.includes(e.label))) {
            const filteredPermission = permissionData.permissionsGiven.filter(
                (obj) => obj.label === 'View' || obj.label === option[option.length - 1].label
            );
            const permissions = option.concat(filteredPermission);
            const uniquePermissionArray = permissions.filter(
                (item, index, self) => self.findIndex((t) => t.label === item.label) === index
            );
            setSelectedPermissions(uniquePermissionArray, permissionData.permissionKey);
            handlePermissionState(uniquePermissionArray, permissionData.permissionKey);
        } else {
            setSelectedPermissions(option, permissionData.permissionKey);
            handlePermissionState(option, permissionData.permissionKey);
        }
    };

    return (
        <div>
            <Row style={{ alignItems: 'center' }}>
                <Col md={4}>
                    <h5 style={{ marginRight: 30, fontSize: 14 }}>{permissionData.permissionName}</h5>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label htmlFor="userType">Permissions</Label>
                        <Select
                            value={selectedPermissions}
                            options={permissionData.permissionsGiven}
                            styles={reactSelectCustomStyles}
                            onChange={handleMultiChange}
                            required
                            isMulti
                            isDisabled={!selectedRole.roleName}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

PermissionRow.propTypes = {
    permissionData: PropTypes.any,
    selectedRole: PropTypes.any,
    handlePermissionState: PropTypes.any,
};

export default PermissionRow;
