export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Reference',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
        style: ['increase-width'],
        customHeaderStyles: {
            minWidth: 250,
        },
    },
    {
        key: 'pickupLocation',
        value: 'From',
        style: ['increase-width'],
        customHeaderStyles: {
            minWidth: 230,
        },
    },
    {
        key: 'recipientName',
        value: 'Recipient',
    },
    {
        key: 'placedDateTime',
        value: 'Order Date',
        type: 'date',
    },
    {
        key: 'deliveryDate',
        value: 'Delivery Date',
        type: 'date',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
    {
        key: 'updatedAt',
        value: 'Updated At',
        type: 'dateTime',
    },
];

export const defaultProcessedOrdersTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Reference',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
    },
    {
        key: 'pickupLocation',
        value: 'From',
    },
    {
        key: 'recipientName',
        value: 'Recipient',
    },
    {
        key: 'placedDateTime',
        value: 'Placed Date',
        type: 'date',
    },
    {
        key: 'deliveryDate',
        value: 'Delivery Date',
        type: 'date',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
];
