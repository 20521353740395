import { TRANSACTION_REFERENCE } from 'constants/enums';
import { partnerApiKeyTransactionMapData } from 'helpers/transactions';

/**
 * Determine whether the 'View' button should be shown in the transactions table.
 * This function is passed as a value to the 'customAction' type column.
 * That column calls this function for each row to determine whether to show the button or not.
 * @param {object} data - the data in the table row
 * @returns {boolean} whether the button should be shown or not
 */
export const isActionButtonVisible = (data) => {
    if (!data) return false;

    const { CREATE_PARTNER_API_KEY, DISABLE_PARTNER_API_KEY, ENABLE_PARTNER_API_KEY, UPDATE_PARTNER_API_KEY } =
        TRANSACTION_REFERENCE.PARTNER_API_KEY;

    const buttonVisibleReferences = [
        CREATE_PARTNER_API_KEY,
        DISABLE_PARTNER_API_KEY,
        ENABLE_PARTNER_API_KEY,
        UPDATE_PARTNER_API_KEY,
    ];
    if (buttonVisibleReferences.includes(data.reference)) {
        return true;
    }

    return false;
};

export const mapApiKeyTransactions = (records, formData) => {
    let allDocs = [];
    const apiKeyData = formData;
    if (records && Array.isArray(records)) {
        allDocs = records.map((transaction) => ({
            ...transaction,
            consoleUserFormatted: partnerApiKeyTransactionMapData(transaction.method, { ...transaction, apiKeyData })
                .consoleUserFormatted,
        }));
    }
    return allDocs;
};
