import React from 'react';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import { motion } from 'framer-motion';
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';

// #region assets | components
import CustomDateInput from 'components/CustomDateInput/CustomDateInput';
import { LazySelect, Select, TableCustom } from 'components';
import { RoundLeftArrow, Search } from 'components/Icons';

// #region utils
import { getDateValue, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';

// #endregion constants
import { RoutePlanningListViewPropTypes } from '../../constants';
import '../Form-View/styles/form-header.scss';

/**
 * This modal contains the form body jsx of the route planning module
 * @param {RoutePlanningListViewPropTypes} props
 */

function RoutePlanningListView(props) {
    const {
        riderLoading,
        merchantLoading,
        routingRequestLoading,
        routePlanningListLoading,
        tableFilterOptions,
        merchants,
        routeListTableData,
        handleSelectChange,
        handleCheckChange,
        handleDateChange,
        handleBackPress,
        handleSubmit,
        getMerchantSelect,
        loadRoutePlanIdsOptions,
        navigateToViewRoutePlan,
        handlePageChange,
        loadRidersOptions,
        getRoutePlanningListViewData,
        selectedRider,
        selectedRoutePlanningId,
        routePlanIdRef,
    } = props;

    return (
        <div className="page-content">
            <Card className="shadow default-card">
                <div className="route-planning__form-header pl-3">
                    <Row className="flex align-items-center align-content-center" style={{ height: 60 }}>
                        <Col className="header-text">Select Filters to Filter the Route Planning Entries</Col>
                        <Col md={2.5} className="pr-2 mr-4">
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius"
                                style={{ borderRadius: 10 }}
                                onClick={handleBackPress}
                            >
                                <RoundLeftArrow />
                                &nbsp;&nbsp;BACK TO ROUTE PLANNING
                            </motion.div>
                        </Col>
                    </Row>
                </div>
                <CardBody className="px-3">
                    {/** Filter Component */}
                    <AvForm
                        className="route-planning__form needs-validation default-form"
                        onValidSubmit={handleSubmit}
                        id="routePlanningListViewForm"
                        autoComplete="off"
                    >
                        <Row className="pl-4 pr-4 mt-4">
                            <Col md="2">
                                <FormGroup>
                                    <Label htmlFor="merchantId">Merchant</Label>
                                    <Select
                                        value={getDefaultValueForSelect(
                                            getMerchantSelect(tableFilterOptions.merchantId)
                                        )}
                                        options={merchants}
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => handleSelectChange(event, 'merchantId')}
                                        isLoading={merchantLoading}
                                        isClearable
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <Label htmlFor="routePlanningId">Route Planning ID</Label>
                                    <LazySelect
                                        // value={getDefaultValueForSelect(
                                        //     getRoutePlanningIdSelect(tableFilterOptions.routePlanId)
                                        // )}
                                        value={selectedRoutePlanningId}
                                        loadOptions={loadRoutePlanIdsOptions}
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => handleSelectChange(event, 'routePlanId')}
                                        isLoading={routingRequestLoading}
                                        isClearable
                                        ref={routePlanIdRef}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label className="text-nowrap" htmlFor="riderId">
                                        Rider Name/Profile ID/Phone Number
                                    </Label>
                                    <LazySelect
                                        value={selectedRider}
                                        loadOptions={loadRidersOptions}
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => handleSelectChange(event, 'riderId')}
                                        isLoading={riderLoading}
                                        isClearable
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <Label htmlFor="deliveryDate">Delivery Date</Label>
                                    <CustomDateInput
                                        onChange={handleDateChange}
                                        type="date"
                                        className="form-control"
                                        value={getDateValue(tableFilterOptions.deliveryDate, 'date')}
                                        id="deliveryDate"
                                        isClearable
                                    />
                                </FormGroup>
                            </Col>
                            {/* Check boxes */}
                            <Col md={2}>
                                <FormGroup className="form-group mt-4">
                                    <div className="form-check mb-3">
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={handleCheckChange}
                                            checked={tableFilterOptions.completedEntries}
                                            id="completedEntries"
                                        />
                                        <Label className="form-check-label" htmlFor="completedEntries">
                                            Completed Entries
                                        </Label>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup className="form-group mt-4">
                                    <div className="form-check mb-3">
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={handleCheckChange}
                                            checked={tableFilterOptions.pendingEntries}
                                            id="pendingEntries"
                                        />
                                        <Label className="form-check-label" htmlFor="pendingEntries">
                                            Pending Entries
                                        </Label>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup className="form-group mt-4">
                                    <div className="form-check mb-3">
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={handleCheckChange}
                                            checked={tableFilterOptions.cancelledEntries}
                                            id="cancelledEntries"
                                        />
                                        <Label className="form-check-label" htmlFor="cancelledEntries">
                                            Cancelled Entries
                                        </Label>
                                    </div>
                                </FormGroup>
                            </Col>

                            {/** Filter Button */}
                            <Col
                                md={3}
                                style={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'center' }}
                                className=""
                            >
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius"
                                    style={{ borderRadius: 10 }}
                                    onClick={handleSubmit}
                                >
                                    <Search />
                                    &nbsp;&nbsp;Filter Requests
                                </motion.div>
                            </Col>
                        </Row>
                        <Row>
                            <TableCustom
                                tableData={routeListTableData}
                                onPageChange={handlePageChange}
                                loading={routePlanningListLoading}
                                showDelete={false}
                                showView={false}
                                removeId
                                isFullScreenShow={false}
                                isFilter={false}
                                isSearch={false}
                                removeAction
                                isFixedHeight
                                showApiCustomAction
                                customActionHandlers={{ navigateToViewRoutePlan }}
                                showResultsPerPage
                                handleResultsPerPageChange={(limit) =>
                                    getRoutePlanningListViewData({ limit: parseInt(limit, 10) })
                                }
                            />
                        </Row>
                    </AvForm>
                </CardBody>
            </Card>
        </div>
    );
}

RoutePlanningListView.propTypes = RoutePlanningListViewPropTypes;
export default RoutePlanningListView;
