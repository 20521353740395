import axios from 'axios';
import { cleanUserRequestHeader, getAccessToken, getUser, getVisitingObject } from '../checkAuth';
import { getBaseUrl } from './config';

const instance = axios.create({
    baseURL: getBaseUrl(),
    timeout: 60000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use((request) => {
    const token = getAccessToken();
    if (token) {
        request.headers.authorization = `Bearer ${token}`;
    }
    request.headers.clientType = 'web';
    return request;
});

instance.interceptors.request.use((request) => {
    let user = getUser();
    if (user) {
        user = cleanUserRequestHeader(user);
    }

    const visitingObject = getVisitingObject();
    if (visitingObject) {
        const { visitingMerchant, visitingClient } = visitingObject;
        if (user) {
            request.headers['x-user'] = JSON.stringify(user);
        }
        if (visitingMerchant) {
            request.headers['x-merchant'] = JSON.stringify(visitingMerchant);
        }
        if (visitingClient) {
            request.headers['x-client'] = JSON.stringify(visitingClient);
        }
    }
    if (user && user.role === 'superAdmin') {
        request.headers['x-super-admin'] = true;
    } else {
        request.headers['x-super-admin'] = false;
    }
    return request;
});

export const postAttachments = (url, data, objectData) => {
    const cancelTokenSource = axios.CancelToken.source();
    if (objectData && objectData.field && objectData.value) {
        instance.interceptors.request.use((request) => {
            request.headers.objectfield = objectData.field;
            request.headers.objectvalue = objectData.value;
            return request;
        });
    }

    return instance.post(url, data, {
        headers: { 'Content-type': 'multipart/form-data' },
        cancelToken: cancelTokenSource.token,
    });
};

export const postFileAttachment = (url, data, meta = {}) =>
    instance.post(url, data.file, {
        headers: {
            'Content-type': 'multipart/form-data',
            meta,
        },
    });

export const downloadPdfFile = (url, data) =>
    instance.post(url, data, {
        responseType: 'arraybuffer',
        headers: {
            Accept: 'application/pdf',
        },
    });

export const downloadFile = (url, data) =>
    instance.post(url, data, {
        responseType: 'blob',
    });

export const authenticate = (url, data) => instance.post(url, data);
export const get = (url) => instance.get(url);
export const remove = (url) => instance.delete(url);
export const post = (url, data) => instance.post(url, data);
export const update = (url, data) => instance.put(url, data);
