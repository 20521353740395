/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Row, Col, Card, Input, Form, Dropdown, DropdownToggle } from 'reactstrap';
import PropTypes from 'prop-types';

// #region components | assets
import { centerElementInnerStyles } from 'styles';
import { THEME_COLOR } from 'theme';

// #endregion imports
import './index.css';
import RiderCard from './rider-card';

/**
 * Advanced Rider Tracking Page Filter
 * @param {*} props
 * @returns
 */
function AdvancedRiderTrackingFilter(props) {
    const {
        allRidersByClient = [],
        setSelectedRider,
        selectedRider,
        setAllFilteredRidersToDisplay,
        allFilteredRidersToDisplay,
    } = props;

    const [showFilterOne, setShowFilterOne] = useState(false);

    const toggleFilterOne = () => {
        setShowFilterOne(!showFilterOne);
    };

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        const filteredVals = allRidersByClient.filter(
            (x) => x.fullName.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
        if (value && value !== '') {
            setAllFilteredRidersToDisplay(filteredVals);
        } else {
            setAllFilteredRidersToDisplay(null);
        }
    };

    return (
        <div className="d-flex shadow-lg bg-white rounded advanced-tracking-filter-container">
            <Col md={12} style={{ marginBottom: 50, height: '100%' }}>
                <Row>
                    <Col md={6}>
                        <Form className="app-search d-none d-lg-block">
                            <div className="position-relative">
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    onChange={handleChangeSearch}
                                />
                                <span className="ri-search-line search-icon"></span>
                            </div>
                        </Form>
                    </Col>
                    <Col md={5} style={{ padding: 0 }} className="ml-0">
                        <Dropdown isOpen={showFilterOne} toggle={toggleFilterOne} style={{ padding: 0 }}>
                            <DropdownToggle
                                style={{
                                    backgroundColor: 'transparent',
                                    marginTop: 6,
                                }}
                                tag="div"
                                className="pr-3 w-100 waves-effect"
                            >
                                <Card className="p-2" style={{ marginTop: 6, ...centerElementInnerStyles }}>
                                    <div style={{ color: THEME_COLOR, fontWeight: 'bold', fontSize: 16 }}>
                                        <i className="fas fa-map-marker-alt mt-1 mr-2" />
                                        Name
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="sort-down"
                                            className="svg-inline--fa fa-sort-down fa-w-10 ml-4"
                                            role="img"
                                            viewBox="0 0 320 512"
                                            width={10}
                                            height={10}
                                            style={{ marginTop: -5 }}
                                        >
                                            <path
                                                fill={THEME_COLOR}
                                                d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"
                                            />
                                        </svg>
                                        {/* <i className="ri-arrow-down-s-fill ml-4  advanced-rider-icon-city" /> */}
                                    </div>
                                </Card>
                            </DropdownToggle>
                        </Dropdown>
                    </Col>
                    {/* <Col md={3} style={{ padding: 0 }} className="ml-0">
                        <Dropdown isOpen={showFilterTwo} toggle={toggleFilterTwo} style={{ padding: 0 }}>
                            <DropdownToggle
                                style={{
                                    backgroundColor: 'transparent',
                                    marginTop: 6,
                                }}
                                tag="div"
                                className="pr-3 w-100 waves-effect"
                            >
                                <Card className="p-2" style={{ marginTop: 6, ...centerElementInnerStyles }}>
                                    <div style={{ color: THEME_COLOR, fontWeight: 'bold', fontSize: 16 }}>
                                        <i className="fas fa-sliders-h mt-1 mr-2" />
                                        Filters
                                    </div>
                                </Card>
                            </DropdownToggle>
                            <DropdownMenu left>
                                <DropdownItem>Hello</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Col> */}
                </Row>

                <Row className="p-3">
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <h5 style={{ fontWeight: 'bolder' }}>
                            {' '}
                            {allFilteredRidersToDisplay
                                ? allFilteredRidersToDisplay.length
                                : allRidersByClient.length}{' '}
                            Result
                            {allFilteredRidersToDisplay
                                ? allFilteredRidersToDisplay.length > 1
                                    ? 's'
                                    : ''
                                : allRidersByClient.length > 1
                                ? 's'
                                : ''}
                        </h5>
                        {/* <h6 className=" ml-1" style={{ fontWeight: 'bolder', color: 'gray', marginTop: 2 }}>
                            in Colombo
                        </h6> */}
                    </div>
                </Row>

                <br />

                {(allFilteredRidersToDisplay || allRidersByClient).map((rider, idx) => (
                    <RiderCard
                        riderData={rider}
                        setSelectedRider={setSelectedRider}
                        selectedRider={selectedRider}
                        riderKey={rider.riderId || rider._id || idx}
                        key={rider.riderId || rider._id || idx}
                    />
                ))}
            </Col>
        </div>
    );
}

AdvancedRiderTrackingFilter.propTypes = {
    allRidersByClient: PropTypes.array,
    allFilteredRidersToDisplay: PropTypes.array,
    setSelectedRider: PropTypes.any,
    setAllFilteredRidersToDisplay: PropTypes.any,
    selectedRider: PropTypes.object,
};

export default AdvancedRiderTrackingFilter;
