import moment from 'moment';

/**
 * Change values in the service response to work with table component
 * @param {array} docs - array of docs returned from service
 * @returns {array} array of formatted docs
 */
export const mapDocs = (docs) => {
    let allDocs = [];
    allDocs = docs.map((doc) => ({
        ...doc,
        createdAt: moment(doc.createdAt).format('DD-MM-YYYY hh:mm A'),
        disconnectedAt: doc.disconnectedAt ? moment(doc.disconnectedAt).format('DD-MM-YYYY hh:mm A') : '',
        createdBy: doc?.createdByUser?.username,
        type: doc.type.charAt(0).toUpperCase() + doc.type.slice(1),
        lastAccessAt: moment(doc.lastAccessAt).format('DD-MM-YYYY hh:mm A'),
        status: doc.status ? 'active' : 'inactive',
    }));
    return allDocs;
};
