import React from 'react';

import { PARTNER_API_SANDBOX_ACCOUNT } from 'enums/partnerApi/partnerApi.enum';
import { ROUTE_PLANNING } from 'enums/routePlanning/routePlanning.enum';

// !TODO add docs
export function renderPartnerApiSandboxAccountRequestStatus(status) {
    let formattedText;
    let backgroundColor = '#A39D90';

    const { REQUEST_STATUS } = PARTNER_API_SANDBOX_ACCOUNT;
    const { COMPLETED, PENDING, REJECTED } = REQUEST_STATUS;

    if (status === COMPLETED) {
        formattedText = 'Completed';
        backgroundColor = '#26C60C';
    }
    if (status === PENDING) {
        formattedText = 'Action Pending';
        backgroundColor = '#A39D90';
    }
    if (status === REJECTED) {
        formattedText = 'Rejected';
        backgroundColor = '#FD4A4A';
    }

    return (
        <>
            <h5 className="general-status" style={{ backgroundColor, color: '#FFF' }}>
                {formattedText}
            </h5>
        </>
    );
}

// !TODO add docs
export function renderPartnerApiApiPortalStatus(data) {
    const { serviceStatusClient } = data;

    let formattedText;
    let backgroundColor = '#A39D90';

    if (serviceStatusClient) {
        formattedText = 'ACTIVE';
        backgroundColor = '#26C60C';
    }
    if (!serviceStatusClient) {
        formattedText = 'INACTIVE';
        backgroundColor = '#FD4A4A';
    }

    return (
        <>
            <h5
                className="general-status"
                style={{ backgroundColor, color: '#FFF', borderRadius: 5, fontWeight: 'normal' }}
            >
                {formattedText}
            </h5>
        </>
    );
}

/**
 * This function is responsible for rendering status of the route planning entry with the custom colors
 * This returns formatted text according to the status with relevant color
 */
export function renderRoutePlanningEntryStatus(status) {
    let formattedText;
    let backgroundColor = '#A39D90';

    const { REQUEST_STATUS } = ROUTE_PLANNING;
    const { COMPLETED, PENDING, CANCELLED } = REQUEST_STATUS;

    if (status === COMPLETED) {
        formattedText = 'Completed';
        backgroundColor = '#26C60C';
    }
    if (status === PENDING) {
        formattedText = 'Pending';
        backgroundColor = '#A39D90';
    }
    if (status === CANCELLED) {
        formattedText = 'Cancelled';
        backgroundColor = '#FF2825';
    }

    return (
        <>
            <h5 className="general-status" style={{ backgroundColor, color: '#FFF', fontWeight: 'normal' }}>
                {formattedText}
            </h5>
        </>
    );
}
