import styled from 'styled-components';

export const centerElementInnerStyles = {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
};
export const centerElementContainerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
};

export const SubTitle = styled.span`
    font-size: 20px !important;
    font-weight: bolder;
`;
