/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Container, Col, Label, Row, Input, Card, CardBody, FormGroup, Spinner, DropdownItem } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { AvForm, AvField, AvCheckbox, AvCheckboxGroup } from 'availity-reactstrap-validation';
import * as qs from 'query-string';
import InputMask from 'react-input-mask';
import toast from 'react-hot-toast';

// #region imports
import PATHS from 'routes/paths';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Hoc from 'components/Hoc';
import {
    FormStatusBtn,
    Select,
    PlacesAutocompleteModal,
    ClientAlerts,
    PlacesAutocompleteField,
    CustomToast,
    BackdropLoader,
} from 'components';
import { getUser, getVisitingObject, IS_MERCHANT_VIEW } from 'utils/checkAuth';
import { cleanMobileNo, getDateValue, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { initialMockOrderData } from 'mock/order-data';
import PageDisabled from 'pages/Utility/PageDisabled';
import { SubTitle } from 'styles';

// #region services
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';
import { getCustomerByMobileNo } from 'services/customer.service';
import { getAllMerchantLocationByMerchantId } from 'services/merchant-location.service';
import { getAllWarehouseSitesService } from 'services/warehouse-site.service';

// #region utils
import { __DEV__ } from 'utils/checks';

// #endregion imports
import { createOrderService, updateOrderService } from '../services';
import { buildLocationData, validateForm } from './utils';
import OrderItemsComponent from './OrderItems';

const isMockData = false;

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Order Processing', link: PATHS.ORDER_PROCESSING.DEFAULT },
];

let initialFormValues = {
    placedDateTime: new Date(),
    pickupLocationMeta: {},
};

if (isMockData && __DEV__) {
    /* For quick order creations */
    initialFormValues = initialMockOrderData;
}

function OrderPage(props) {
    const reduxState = useSelector((state) => state);

    const history = useHistory();

    const { visitingClient, visitingMerchant } = getVisitingObject();

    const _isMounted = useRef(true);

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    // prev comp
    const [prevCompState, setPrevCompState] = useState({});

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);

    // Global
    // eslint-disable-next-line no-unused-vars
    const [clientSetting, setClientSetting] = useState(null);
    const [orderProcessingSetting, setOrderProcessingSetting] = useState(null);

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);
    const [merchants, setMerchants] = useState([]);
    const [isViewMode, setIsViewMode] = useState(false);
    const { role = 'merchantUser' } = getUser();

    const [showPickupLocation, setShowPickupLocation] = useState(false);
    const [showDeliveryLocation, setShowDeliveryLocation] = useState(false);

    // arrays
    const [allMerchantLocations, setAllMerchantLocations] = useState([]);
    const [warehouseSites, setWarehouseSites] = useState([]);

    useEffect(() => {
        if (!props.location) {
            history.push(PATHS.ORDER_PROCESSING.DEFAULT);
            return;
        }
        const formType = qs.parse(props.location.search);
        if (formType && formType.type && formType.type === 'new') {
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Order', link: '#' }]);
        }
        if (formType && formType.type && formType.type === 'edit') {
            if (!props.location.state) {
                history.push(PATHS.ORDER_PROCESSING.DEFAULT);
                return;
            }
            const { orderParentData } = props.location.state;
            if (!orderParentData) {
                history.push(PATHS.ORDER_PROCESSING.DEFAULT);
                return;
            }
            setFormData({ ...formData, ...orderParentData });
            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Order', link: '#' }]);
        }
        if (formType && formType.type && formType.type === 'view') {
            !props.location.state && history.push(PATHS.ORDER_PROCESSING.DEFAULT);
            const { orderParentData } = props.location.state;
            setFormData({ ...formData, ...orderParentData });
            setIsViewMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'View Order', link: '#' }]);
        }
        const { prevCompState = null } = props.location.state || {};
        if (prevCompState) {
            setPrevCompState(prevCompState);
        }
        // }, [props.location]);
    }, []);

    useEffect(() => {
        loadAllMerchantLocations();
        loadWarehouseSites();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (reduxState?.Global) {
            const { clientSetting: clientSettingState } = reduxState.Global;
            if (clientSettingState && _isMounted.current) {
                setClientSetting(clientSettingState);
                if (clientSettingState.orderProcessing) {
                    setOrderProcessingSetting(clientSettingState.orderProcessing);
                }
            }
        }
    }, [reduxState.Global.clientSetting]);

    useEffect(() => {
        if (formData.recipientMobileNumber && cleanMobileNo(formData.recipientMobileNumber).length === 11) {
            // loadCustomerByPhone(formData.recipientMobileNumber);
        }
    }, [formData.recipientMobileNumber]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    const loadCustomerByPhone = async (mobileNo) => {
        setBackdropLoading((x) => x + 1);
        try {
            const { data } = await getCustomerByMobileNo(mobileNo);
            if (data) {
                setFormData({
                    ...formData,
                    customerId: data._id,
                    recipientEmail: data.email,
                    recipientName: data.name,
                    deliveryAddress: data.address || formData.deliveryAddress || null,
                    deliveryAddressMeta: data.addressMeta || formData.deliveryAddressMeta || null,
                    city: data?.addressMeta?.city,
                    postalCode: data?.addressMeta?.postalCode,
                    state: data?.addressMeta?.state,
                });
                toast.custom((t) => <CustomToast text="Updated Customer Details" t={t} type="success" />, {
                    position: 'top-right',
                });
            } else {
                toast.custom((t) => <CustomToast text="Customer Details not found" t={t} type="warning" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((x) => x - 1);
    };

    const loadWarehouseSites = async () => {
        try {
            const { data } = await getAllWarehouseSitesService();
            if (_isMounted.current && data && data.docs) {
                setWarehouseSites(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.siteName}`,
                        key: x._id,
                    }))
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadAllMerchantLocations = async () => {
        try {
            const { data } = await getAllMerchantLocationByMerchantId(formData.merchantId);
            if (data && data.docs && Array.isArray(data.docs)) {
                setAllMerchantLocations(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.merchantLocationName || ''} - ${x.location || ''}`,
                    }))
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.persist();

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);
        formData.recipientMobileNumber = cleanMobileNo(formData.recipientMobileNumber);
        // console.log(JSON.stringify(formData));
        try {
            let orderData;
            if (editingMode) {
                const { data } = await updateOrderService(formData);
                if (data) {
                    orderData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            } else {
                const { data } = await createOrderService(formData);
                if (data) {
                    orderData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            }
            setEditingMode(true);

            try {
                // Replace URL so relevant changes can be done
                if (orderData) {
                    history.replace({
                        pathname: `${PATHS.ORDER_PROCESSING.ORDER_FORM}`,
                        search: `?type=edit`,
                        state: {
                            orderParentData: orderData,
                            prevCompState,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }

            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} order`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setIsFormSaved(true);
        } catch (e) {
            console.log(e);
            toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading(false);
        setSubmitted(false);
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        if (id === 'recipientMobileNumber') {
            setFormData({
                ...formData,
                [id]: value,
            });
            return;
        }
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleCheckChange = (event) => {
        setIsFormSaved(false);
        const { id, checked } = event.target;
        setFormData({
            ...formData,
            [id]: checked,
        });
    };

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);
        if (id === 'state') {
            setFormData({
                ...formData,
                city: '',
                [id]: event ? event.value : null,
            });
            return;
        }
        if (id === 'merchantId') {
            const relevantMerchant = merchants.find((x) => x._id === event.value);
            if (relevantMerchant) {
                setFormData({
                    ...formData,
                    merchantName: relevantMerchant.name || '',
                    [id]: event ? event.value : null,
                });
            }
            return;
        }
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const returnLocationData = (id, idMeta, data) => {
        const builtData = buildLocationData(id, idMeta, data);
        setFormData({ ...formData, ...builtData });
    };

    // Select Handlers
    const getWarehouseSiteSelect = (_id) => {
        if (_id) {
            const relevantObj = warehouseSites.find((x) => x._id === _id);
            if (relevantObj) {
                return {
                    label: `${relevantObj.siteName}`,
                    value: relevantObj._id,
                };
            }
        }
        return null;
    };

    // ** Memos
    const selectedWarehouseSiteId = useMemo(
        () => getWarehouseSiteSelect(formData.warehouseSiteId),
        [formData.warehouseSiteId, warehouseSites]
    );

    if (orderProcessingSetting && !orderProcessingSetting.isEnabled) {
        return <PageDisabled message="Stocks Adjustment in Progress" type="stockAdjustmentDisabled" />;
    }

    return (
        <>
            <Hoc {...props}>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                        <Row
                            style={{
                                margin: 2,
                                justifyContent: 'space-between',
                            }}
                        >
                            {!editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                    onClick={() => {
                                        history.push({
                                            pathname: prevCompState.pageUrl || `${PATHS.ORDER_PROCESSING.DEFAULT}`,
                                            search: `?tab=${prevCompState.activeTab || '1'}`,
                                        });
                                    }}
                                >
                                    <i className="far fa-times-circle mr-2 fa-lg" />
                                    Cancel
                                </motion.div>
                            )}
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormStatusBtn isFormSaved={isFormSaved} />
                            </div>

                            {editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => {
                                        history.push({
                                            pathname: prevCompState.pageUrl || `${PATHS.ORDER_PROCESSING.DEFAULT}`,
                                            search: `?tab=${prevCompState.activeTab || '1'}`,
                                        });
                                    }}
                                >
                                    <i className="fas fa-arrow-left mr-2 fa-lg" />
                                    Back to Orders
                                </motion.div>
                            )}
                        </Row>
                        {!editingMode && (
                            <>
                                <ClientAlerts />
                            </>
                        )}
                        <Card>
                            <CardBody>
                                <Row
                                    style={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        margin: 2,
                                        marginBottom: 10,
                                    }}
                                >
                                    <h4 className="card-title ml-n1">Delivery Order</h4>
                                    {/* {editingMode && (
                                        <div className="p-2 pr-3 pl-3 shadow-lg nw-md">
                                            <i className="far fa-edit"></i>
                                        </div>
                                    )}
                                    {!editingMode && (
                                        <div className="p-2 pr-3 pl-3 shadow-lg nw-md">
                                            <i className="far fa-file"></i>
                                        </div>
                                    )} */}
                                </Row>
                                <AvForm
                                    className="needs-validation default-form"
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                    id="orderForm"
                                >
                                    <fieldset disabled={isViewMode || formLoading || formData.status === 'completed'}>
                                        <Row>
                                            {(editingMode || isViewMode) && (
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="referenceNumber">Order Number</Label>
                                                        <AvField
                                                            name="referenceNumber"
                                                            placeholder="Order Number"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            disabled
                                                            id="referenceNumber"
                                                            value={formData.referenceNumber}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            )}
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="merchantId">Merchant</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(
                                                            getMerchantSelect(formData.merchantId)
                                                        )}
                                                        options={merchants}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                        isDisabled={
                                                            role === 'merchantUser' || editingMode || IS_MERCHANT_VIEW()
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="name">Vehicle Type</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.vehicleType)}
                                                        options={[
                                                            { label: 'Motorbike', value: 'Motorbike' },
                                                            { label: 'Car', value: 'Car' },
                                                            { label: 'Van - 7FT', value: 'Van7FT' },
                                                            { label: 'Truck', value: 'Truck' },
                                                        ]}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'vehicleType')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="warehouseSiteId">Warehouse</Label>
                                                    <Select
                                                        value={selectedWarehouseSiteId}
                                                        options={warehouseSites.map((x) => ({
                                                            label: x.label,
                                                            value: x.key,
                                                        }))}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) =>
                                                            handleSelectChange(event, 'warehouseSiteId')
                                                        }
                                                        submitted={submitted}
                                                        validated={validated}
                                                        required
                                                        isClearable
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="merchantId">Merchant Order Number</Label>
                                                    <AvField
                                                        name="merchantOrderNo"
                                                        placeholder="Merchant Order Number"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        value={formData.merchantOrderNo}
                                                        validate={{ required: { value: true } }}
                                                        id="merchantOrderNo"
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="placedDateTime">Order Placed Date and time</Label>
                                                    <Input
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                        type="datetime-local"
                                                        value={getDateValue(formData.placedDateTime, 'datetime-local')}
                                                        defaultValue={getDateValue(new Date(), 'datetime-local')}
                                                        validate={{ required: { value: true } }}
                                                        id="placedDateTime"
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md={4}>
                                                <FormGroup>
                                                    <Row>
                                                        <div className="form-check form-check-right mb-2 ml-3">
                                                            <Input
                                                                onChange={handleCheckChange}
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={formData.isDeliveryTimeSlot}
                                                                defaultChecked={formData.isDeliveryTimeSlot}
                                                                id="isDeliveryTimeSlot"
                                                            />
                                                            <Label htmlFor="customCheck2" className="form-check-label">
                                                                Delivery Time Slot
                                                            </Label>
                                                        </div>
                                                    </Row>
                                                    <Input
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                        type="datetime-local"
                                                        value={getDateValue(
                                                            formData.deliveryTimeSlot,
                                                            'datetime-local'
                                                        )}
                                                        disabled={!formData.isDeliveryTimeSlot}
                                                        validate={{ required: { value: true } }}
                                                        id="deliveryTimeSlot"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="4">
                                                <Row>
                                                    <Col md="10" sm="10" xs="10">
                                                        <FormGroup>
                                                            <Label htmlFor="pickupLocation">Pickup Location</Label>
                                                            <PlacesAutocompleteField
                                                                addressData={formData.pickupLocationMeta || null}
                                                                returnLocationData={returnLocationData}
                                                                id="pickupLocation"
                                                                idMeta="pickupLocationMeta"
                                                                isRequired
                                                                isMerchantLocation
                                                                submitted={submitted}
                                                                validated={validated}
                                                                predefinedValues={allMerchantLocations}
                                                                isClearable
                                                            />
                                                            {showPickupLocation && !isViewMode && (
                                                                <PlacesAutocompleteModal
                                                                    show={showPickupLocation}
                                                                    isModal
                                                                    addressData={formData.pickupLocationMeta || null}
                                                                    returnLocationData={returnLocationData}
                                                                    toggle={() =>
                                                                        setShowPickupLocation(!showPickupLocation)
                                                                    }
                                                                    id="pickupLocation"
                                                                    idMeta="pickupLocationMeta"
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="2" sm="2" xs="2">
                                                        <motion.div
                                                            style={{ width: 35, height: 35 }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.9 }}
                                                            className="scootix-btn-circle shadow-lg nw-md ml-3 order-map-pin-container"
                                                            onClick={() => setShowPickupLocation(!showPickupLocation)}
                                                        >
                                                            <div className="map-pin">
                                                                <i className="ri-map-pin-line fa-lg" />
                                                            </div>
                                                        </motion.div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="postalCode">Postal Code</Label>
                                                    <AvField
                                                        name="postalCode"
                                                        placeholder="Postal Code"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="postalCode"
                                                        value={formData.postalCode}
                                                        type="Number"
                                                        minLength={5}
                                                        maxLength={5}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="city">City</Label>
                                                    <AvField
                                                        name="city"
                                                        placeholder="City"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="city"
                                                        value={formData.city || ''}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="state">State</Label>
                                                    <AvField
                                                        name="state"
                                                        placeholder="State"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        id="state"
                                                        value={formData.state || ''}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Row>
                                                    <Col md="10" sm="10" xs="10">
                                                        <FormGroup>
                                                            <Label htmlFor="recipientMobileNumber">
                                                                Recipient Mobile Number
                                                            </Label>
                                                            <AvField
                                                                mask="+60 99-999 99999"
                                                                maskChar=""
                                                                tag={[Input, InputMask]}
                                                                onChange={handleInputChange}
                                                                value={formData.recipientMobileNumber}
                                                                name="recipientMobileNumber"
                                                                id="recipientMobileNumber"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="2" sm="2" xs="2">
                                                        <motion.div
                                                            style={{ width: 35, height: 35 }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.9 }}
                                                            className="scootix-btn-circle shadow-lg nw-md ml-3 delivery-contact-refresh-container"
                                                            onClick={() =>
                                                                loadCustomerByPhone(formData.recipientMobileNumber)
                                                            }
                                                        >
                                                            <div className="map-pin">
                                                                <i className="fas fa-sync-alt fa-lg" />
                                                            </div>
                                                        </motion.div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="recipientName">Recipient Name</Label>
                                                    <AvField
                                                        name="recipientName"
                                                        placeholder="Recipient Name"
                                                        type="text"
                                                        className="form-control"
                                                        value={formData.recipientName}
                                                        id="recipientName"
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="recipientEmail">Recipient Email</Label>
                                                    <AvField
                                                        name="recipientEmail"
                                                        placeholder="Recipient Email"
                                                        type="email"
                                                        className="form-control"
                                                        value={formData.recipientEmail}
                                                        id="recipientEmail"
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <Row>
                                                    <Col md="10" sm="10" xs="10">
                                                        <FormGroup>
                                                            <Label htmlFor="deliveryAddress">Delivery Address</Label>
                                                            <PlacesAutocompleteField
                                                                addressData={formData.deliveryAddressMeta || null}
                                                                returnLocationData={returnLocationData}
                                                                id="deliveryAddress"
                                                                idMeta="deliveryAddressMeta"
                                                                isRequired
                                                                submitted={submitted}
                                                                validated={validated}
                                                            />
                                                            {showDeliveryLocation && !isViewMode && (
                                                                <PlacesAutocompleteModal
                                                                    show={showDeliveryLocation}
                                                                    isModal
                                                                    addressData={formData.deliveryAddressMeta || null}
                                                                    returnLocationData={returnLocationData}
                                                                    toggle={() =>
                                                                        setShowDeliveryLocation(!showDeliveryLocation)
                                                                    }
                                                                    id="deliveryAddress"
                                                                    idMeta="deliveryAddressMeta"
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    {!formData.isAcceptedByRider && (
                                                        <Col md="2" sm="2" xs="2">
                                                            <motion.div
                                                                style={{ width: 35, height: 35 }}
                                                                whileHover={{ scale: 1.1 }}
                                                                whileTap={{ scale: 0.9 }}
                                                                className="scootix-btn-circle shadow-lg nw-md ml-3 delivery-map-pin-container"
                                                                onClick={() =>
                                                                    setShowDeliveryLocation(!showDeliveryLocation)
                                                                }
                                                            >
                                                                <div className="map-pin">
                                                                    <i className="ri-map-pin-line fa-lg" />
                                                                </div>
                                                            </motion.div>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="deliveryPostalCode">Delivery Postal Code</Label>
                                                    <AvField
                                                        name="deliveryPostalCode"
                                                        placeholder="Delivery Postal Code"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="deliveryPostalCode"
                                                        value={formData.deliveryPostalCode}
                                                        type="Number"
                                                        minLength={5}
                                                        maxLength={5}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="deliveryCity">Delivery City</Label>
                                                    <AvField
                                                        name="deliveryCity"
                                                        placeholder="Delivery City"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="deliveryCity"
                                                        value={formData.deliveryCity || ''}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="deliveryState">Delivery State</Label>
                                                    <AvField
                                                        name="deliveryState"
                                                        placeholder="Delivery State"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        id="deliveryState"
                                                        value={formData.deliveryState || ''}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={4}>
                                                <Label htmlFor="specialCare">Special Care</Label>
                                                <AvCheckboxGroup name="specialCare" className="form-check-group">
                                                    <AvCheckbox
                                                        label="Fragile"
                                                        checked={formData.isFragile}
                                                        id="isFragile"
                                                        onChange={handleCheckChange}
                                                    />
                                                    <AvCheckbox
                                                        label="Frozen"
                                                        checked={formData.isFrozen}
                                                        id="isFrozen"
                                                        onChange={handleCheckChange}
                                                    />
                                                    <AvCheckbox
                                                        label="High Value"
                                                        checked={formData.isHighValue}
                                                        id="isHighValue"
                                                        onChange={handleCheckChange}
                                                    />
                                                    <AvCheckbox
                                                        label="Chill"
                                                        checked={formData.isChill}
                                                        id="isChill"
                                                        onChange={handleCheckChange}
                                                    />
                                                </AvCheckboxGroup>
                                            </Col>
                                            <Col md={4}>
                                                <Label htmlFor="specialConditions">Special Conditions</Label>
                                                <AvCheckboxGroup name="specialConditions" className="form-check-group">
                                                    <AvCheckbox
                                                        label="Halal"
                                                        checked={formData.isHalal}
                                                        id="isHalal"
                                                        onChange={handleCheckChange}
                                                    />
                                                    <AvCheckbox
                                                        label="Deliver by Female Rider"
                                                        checked={formData.isFemaleDriver}
                                                        id="isFemaleDriver"
                                                        onChange={handleCheckChange}
                                                    />
                                                </AvCheckboxGroup>
                                            </Col>
                                        </Row>

                                        <Row style={{ justifyContent: 'flex-end' }}>
                                            {/* {hasAccess(PERMISSIONS.NEW_ORDER, ['View', 'Edit']) && ( */}
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                type="submit"
                                                disabled={formLoading}
                                            >
                                                {formLoading ? (
                                                    <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                ) : (
                                                    <>
                                                        <i className="fas fa-save left-icon"></i>
                                                        <span className="h6">{editingMode ? 'Update' : 'Save'}</span>
                                                    </>
                                                )}
                                            </motion.button>
                                            {/* )} */}
                                        </Row>
                                    </fieldset>
                                </AvForm>
                                <Row className="d-flex justify-content-between ml-n1 mb-2 mt-4">
                                    <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}>
                                        ORDERS ITEMS
                                    </SubTitle>
                                </Row>
                                <DropdownItem divider className="mt-0" />
                                <br />
                                {formData._id && (
                                    <OrderItemsComponent orderData={formData} setBackdropLoading={setBackdropLoading} />
                                )}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </Hoc>

            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

OrderPage.propTypes = {};

const mapStateToProps = (state) => ({
    ...state,
});

const HotOrderPage = hot(OrderPage);

export default connect(mapStateToProps, {})(HotOrderPage);
