export const lottieOptionsSuccess = (lottieName) => ({
    loop: false,
    autoplay: true,
    animationData: lottieName || '',
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
});
export const lottieOptionsWait = (lottieName) => ({
    loop: true,
    autoplay: true,
    animationData: lottieName || '',
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
});

export const lottieOptionsError = (lottieName) => ({
    loop: false,
    autoplay: true,
    animationData: lottieName || '',
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
});

export const lottieOptionsInfo = (lottieName) => ({
    loop: false,
    autoplay: true,
    animationData: lottieName || '',
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
});

export const lottieOptionsWarning = (lottieName) => ({
    loop: true,
    autoplay: true,
    animationData: lottieName || '',
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
});
