let ENV = process.env.REACT_APP_ENV || '';

export const socketLocalUrl = 'http://localhost:4003';
export const socketProductionUrl = 'https://api.scootix.com.my:4003';
export const socketStagingUrl = 'https://console-staging.scootix.com.my';
export const socketTestUrl = 'https://scootix-queue-service-test.herokuapp.com';

export const gatewayLocalUrl = 'http://localhost:4000';
export const gatewayTestUrl = 'https://scootix-api-gateway-test.herokuapp.com';
export const gatewayStagingUrl = 'https://console-staging.scootix.com.my/api/';
export const gatewayProdUrl = 'https://api.scootix.com.my';

if (ENV) {
    ENV = ENV.trim();
    ENV = ENV.toLowerCase();
}

export const getBaseWatchApiUrl = () => {
    if (ENV && ENV !== 'development') {
        return 'https://scm-watch-api.herokuapp.com';
    }

    return 'http://localhost:6001';
};

export const getBaseUrlSocket = () => {
    if (ENV === 'production') {
        return socketProductionUrl;
    }
    if (ENV === 'staging') {
        return socketStagingUrl;
    }
    if (ENV === 'test') {
        return socketTestUrl;
    }
    return socketLocalUrl;
    // return socketStagingUrl;
    // return socketProductionUrl;
    // return socketTestUrl;
};

export const getBaseUrl = () => {
    if (ENV === 'production') {
        return gatewayProdUrl;
    }
    if (ENV === 'staging') {
        return gatewayStagingUrl;
    }
    if (ENV === 'test') {
        return gatewayTestUrl;
    }
    return gatewayLocalUrl;
    // return gatewayStagingUrl;
    // return gatewayTestUrl;
};
