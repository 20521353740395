import {
    CREATE_BATCH_PRINT,
    GET_PRINT_RECORDS_BY_ID,
    UPDATE_BATCH_PRINT_RECORD,
    FINALIZE_BATCH_PRINT_RECORD,
    UPDATE_ARCHIVE_BATCH_PRINT_RECORD,
    START_PRINT_PROCESS_BATCH_PRINT_RECORD,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Generate Quick Print
 * @param {object} data
 * @returns {Promise}
 */
export const createBatchPrint = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_BATCH_PRINT).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getPrintRecordById = (id) =>
    new Promise(async function promiseCall(resolve, reject) {
        try {
            const url = UrlBuilder().setUrl(GET_PRINT_RECORDS_BY_ID).setPathParams({ id }).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });

export const updateBatchPrintRecord = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_BATCH_PRINT_RECORD).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const finalizeBatchPrintRecord = (id, { selectedDeliveryIds }) => {
    try {
        const url = UrlBuilder().setUrl(FINALIZE_BATCH_PRINT_RECORD).setPathParams({ id }).build();
        return instance.post(url, { selectedDeliveryIds });
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const updateArchiveBatchPrintRecord = (id, { isArchived }) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_ARCHIVE_BATCH_PRINT_RECORD).setPathParams({ id }).build();
        return instance.patch(url, { isArchived });
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const startPrintProcessBatchPrintRecord = (id) => {
    try {
        const url = UrlBuilder().setUrl(START_PRINT_PROCESS_BATCH_PRINT_RECORD).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
