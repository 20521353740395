import { createAttachmentService, getAttachmentByKey, updateAttachmentService } from 'services/attachment.service';
import {
    CREATE_USER,
    DELETE_USER,
    UPDATE_USER,
    APPROVE_USER,
    DELETE_USER_PERMANENT,
    RESTORE_USER,
    CREATE_MERCHANT_ADMIN,
    CREATE_MERCHANT_WITH_ADMIN,
} from 'utils/apiEndpoints';
import { buildFormDataSingle, buildNewAttachment } from 'utils/helpers';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const createUserService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_USER).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 *
 * @param {object} formData
 * @param {object} attachmentsObj  Attachment Object with new changes
 * @param {object} initialAttachmentDb Attachment Object With Only DB Changes
 * !! attachmentObj and initialAttachmentDb should have same keys
 * @returns
 */
export const updateUserService = async (formData, attachmentsObj, initialAttachmentDb) => {
    try {
        const payload = { ...formData, attachmentObj: formData.attachmentObj || attachmentsObj };

        try {
            delete payload.verified;
            delete payload.verification;
            delete payload.isApproved;
        } catch (e) {
            console.log(e);
        }
        // Attachments Handling
        if (attachmentsObj && initialAttachmentDb) {
            const attachmentPromises = Object.keys(attachmentsObj).map(
                (k) =>
                    new Promise(async (res, rej) => {
                        const attachment = attachmentsObj[k];
                        const initialAttachment = initialAttachmentDb[k];
                        if (!attachment || !initialAttachment) {
                            console.log('Attachment Key Not Found on State');
                            return rej();
                        }
                        // Attachment Should Update Or Create ?
                        // Based on two conditions last updated and not saved DB
                        // Either One
                        if (attachment.lastUpdated !== initialAttachment.lastUpdated || !attachment.isDbSaved) {
                            const imageData = buildFormDataSingle(attachment.data);
                            if (initialAttachment.data && initialAttachment.isDbSaved) {
                                try {
                                    const { data } = await updateAttachmentService(initialAttachment.data, imageData);
                                    if (data && data.key) {
                                        payload.attachmentObj[k] = buildNewAttachment(data.key);
                                    }
                                } catch (e) {
                                    console.log(e);
                                }
                            } else {
                                try {
                                    const { data } = await createAttachmentService(imageData);
                                    if (data && data.key) {
                                        payload.attachmentObj[k] = buildNewAttachment(data.key);
                                    }
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        }
                        return res(true);
                    })
            );

            await Promise.all(attachmentPromises);
        }
        const url = UrlBuilder().setUrl(UPDATE_USER).setPathParams({ id: payload._id }).build();
        return instance.patch(url, payload);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const deleteUserService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_USER).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const deleteUserPermanentService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_USER_PERMANENT).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Restore User Service
 * @returns {Promise}
 */
export const restoreUserService = (id) => {
    try {
        const url = UrlBuilder().setUrl(RESTORE_USER).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const approveUserService = (id) => {
    try {
        const url = UrlBuilder().setUrl(APPROVE_USER).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getAllAttachmentPresignedUrlsUserForm = async (formData) => {
    let newObject = formData;
    try {
        if (newObject?.nicFrontImageUrl?.data) {
            const nicFrontImageKey = await getAttachmentByKey(newObject.nicFrontImageUrl.data);
            if (nicFrontImageKey) {
                newObject = {
                    ...newObject,
                    nicFrontImageUrl: { ...newObject.nicFrontImageUrl, data: nicFrontImageKey },
                };
            }
        }
        if (newObject?.nicBackImageUrl?.data) {
            const nicBackImageKey = await getAttachmentByKey(newObject.nicBackImageUrl.data);
            if (nicBackImageKey) {
                newObject = {
                    ...newObject,
                    nicBackImageUrl: { ...newObject.nicBackImageUrl, data: nicBackImageKey },
                };
            }
        }
    } catch (e) {
        console.log(e);
    }
    return newObject;
};

/**
 * Create Merchant Admin Service
 * @param {object} data
 * @returns
 */
export const createMerchantAdminService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_MERCHANT_ADMIN).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const createMerchantWithAdminService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_MERCHANT_WITH_ADMIN).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
