import PATHS from 'routes/paths';

export const SHIPPING_LABEL_HEADER_BUTTON_NAME = {
    LEFT: 'New Batch Print',
    RIGHT: 'Printer Settings',
};

export const SHIPPING_BATCH_PRINT_HEADER_BUTTON_NAME = {
    LEFT: '',
    RIGHT: 'Back to Prints',
};

export const breadcrumbItemsShippingLabel = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Label Printing', link: '#' },
    { title: 'Shipping Label', link: '#' },
];

export const breadcrumbItemsBatchPrinting = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Label Printing', link: '#' },
    { title: 'New Batch Printing', link: '#' },
];
