/* eslint-disable indent */
import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';

// #region assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { BackdropLoader, CustomToast, ScootixModal, TableCustom } from 'components';
import PATHS from 'routes/paths';

// #region utils
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import { apiErrorHandler } from 'utils/helpers';

// #region services
import { getWarehouseSitesService } from 'services/warehouse-site.service';

// #endregion services
import { defaultTableHeaders } from './constants';
import { deleteWarehouseSiteService } from './services';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Warehouse', link: '#' },
];

/**
 * Warehouse Site Management Page
 * @param {*} props
 * @returns {React.Component}
 */
function WarehouseSiteManagementPage(props) {
    const history = useHistory();

    // component state
    const [loading, setLoading] = useState(0);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    const [searchBy] = useState({ searchBy: '', searchText: null });

    // db - data
    const [warehouseSiteListData, setWarehouseSiteListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    useEffect(() => {
        loadDeliveriesListData(
            true,
            warehouseSiteListData.all,
            1,
            warehouseSiteListData.limit,
            warehouseSiteListData.filter,
            warehouseSiteListData.sort,
            warehouseSiteListData.order,
            searchBy
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location]);

    const loadDeliveriesListData = (showLoading = true, all, page, limit, filter, sort, order, searchByParam) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getWarehouseSitesService(all, page, limit, filter, sort, order, searchByParam, 'merchantId')
            .then((res) => {
                const { data } = res;
                if (data && data.docs) {
                    const docs = mapDocs(data.docs);
                    setWarehouseSiteListData({
                        ...warehouseSiteListData,
                        ...data,
                        docs,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const mapDocs = (docs) => {
        let allDocs = [];
        allDocs = docs.map((x) => ({
            ...x,
        }));
        return allDocs;
    };

    const handleOnClickNewDelivery = () => {
        history.push({
            pathname: `${PATHS.WAREHOUSE_SITE.FORM_VIEW}`,
            search: `?type=new`,
        });
    };

    const handlePageChange = (pageNo) => {
        loadDeliveriesListData(
            true,
            warehouseSiteListData.all,
            pageNo,
            warehouseSiteListData.limit,
            warehouseSiteListData.filter,
            warehouseSiteListData.sort,
            warehouseSiteListData.order,
            searchBy
        );
    };

    const handleFilter = (field, filterText) => {
        loadDeliveriesListData(
            false,
            warehouseSiteListData.all,
            1,
            warehouseSiteListData.limit,
            warehouseSiteListData.filter,
            warehouseSiteListData.sort,
            warehouseSiteListData.order,
            { searchBy: field || 'name', searchText: filterText }
        );
    };

    const handleEditRow = (e, row) => {
        history.push({
            pathname: `${PATHS.WAREHOUSE_SITE.FORM_VIEW}`,
            search: `?type=edit`,
            state: { warehouseSiteParentData: row },
        });
    };

    // eslint-disable-next-line no-unused-vars
    const handleDeleteWarehouseSite = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteWarehouseSiteService(data._id);
            loadDeliveriesListData(
                false,
                warehouseSiteListData.all,
                1,
                warehouseSiteListData.limit,
                warehouseSiteListData.filter,
                warehouseSiteListData.sort,
                warehouseSiteListData.order,
                searchBy
            );
            toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        toggleScootixModal();
        setBackdropLoading((prevState) => prevState - 1);
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />

                    <>
                        <Row>
                            <div className="d-flex row ml-2">
                                {hasAccess(PERMISSIONS.WAREHOUSE, ['Add']) && (
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                        onClick={() => handleOnClickNewDelivery()}
                                    >
                                        <i className="fas fa-warehouse left-icon mr-2" />
                                        {'  '} <div style={{ marginTop: 1, marginLeft: 3 }}>New Warehouse</div>
                                    </motion.div>
                                )}
                            </div>
                        </Row>
                    </>
                    <br />

                    <Row>
                        <TableCustom
                            id="warehouse-site-id"
                            tableData={warehouseSiteListData}
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            // handleDelete={handleOnDelete}
                            loading={loading}
                            isFullScreenShow
                            handleEdit={handleEditRow}
                            showView={false}
                            showEdit={hasAccess(PERMISSIONS.WAREHOUSE, ['Edit'])}
                            // showDelete={hasAccess(PERMISSIONS.WAREHOUSE, ['Delete'])}
                            showDelete={false}
                            removeId={false}
                            isExport
                            type="warehouseSite"
                            exportModalHeader="Export Warehouse Site Data"
                            filters={[]}
                        />
                    </Row>
                </Container>
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.7} />

            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
        </>
    );
}

WarehouseSiteManagementPage.propTypes = {
    location: PropTypes.any,
    socket: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotWarehouseSiteManagementPage = hot(WarehouseSiteManagementPage);

export default connect(mapStateToProps, {})(HotWarehouseSiteManagementPage);
