/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

// #region assets | components
import { motion } from 'framer-motion';
import { Col, Input, Label, Row, Spinner } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import CreatableSelectAdvanced from 'components/CreateableSelect';
import CustomToast from 'components/Toast/Custom';

// #region other imports
import { THEME_COLOR_2 } from 'theme';

// #region services
import { createDeliveryAttachmentService, updateDeliveryAttachmentService } from 'services/deliveries.service';
import { createSystemDataService, getAllSystemDataByTypeAndModuleService } from 'services/system-data.service';

// #regions utils
import { apiErrorHandlerV2, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { SYSTEM_DATA_TYPES } from 'constants/enums';
import { validateForm } from './utils';

const initialFormData = {
    category: 'Note',
};
function AddDeliveryNotesModule(props) {
    const { data: deliveryData, deliveryId, setShowModal, loadAttachments, updateFormData, riderId } = props;

    const { referenceNumber } = deliveryData;

    const _isMounted = useRef(true);

    // Form
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [editingMode, setEditingMode] = useState(false);
    const [attachmentType, settAttachmentType] = useState([]);

    // Data
    const [formData, setFormData] = useState({ ...initialFormData, ...updateFormData });

    // Loading
    const [customLoading, setCustomLoading] = useState({ type: false });
    const [formLoading, setFormLoading] = useState(false);

    const handleSelectChange = (event, id) => {
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    // sideEffect to load delivery complete reasons
    useEffect(() => {
        if (updateFormData) {
            setEditingMode(true);
        }
    }, []);

    // load delivery complete reasons to dropdown
    useEffect(() => {
        loadDeliveryCompleteReasons();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    /**
     * Handle Create Change
     */
    const handleCreateSelectChange = async (record, id) => {
        if (record) {
            if (id === 'type') {
                setCustomLoading((prev) => ({ ...prev, productCategory: true }));
                try {
                    const { data } = await createSystemDataService({
                        type: SYSTEM_DATA_TYPES.DELIVERY_ATTACHMENT_TYPES.TYPES.NOTE_REASON,
                        module: SYSTEM_DATA_TYPES.DELIVERY_ATTACHMENT_TYPES.MODULE,
                        name: record,
                        isVisibleToRider: false,
                    });

                    if (data) {
                        settAttachmentType([...attachmentType, { label: data.name, value: data.name }]);
                        setFormData({
                            ...formData,
                            [id]: record,
                        });
                    }
                } catch (e) {
                    const { message } = apiErrorHandlerV2(e);
                    toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                        position: 'top-right',
                    });
                }
                setCustomLoading((prev) => ({ ...prev, productCategory: false }));
                return;
            }

            setFormData({
                ...formData,
                [id]: record,
            });
        }
        setCustomLoading((prev) => ({ ...prev, productCategory: false }));
    };

    /**
     * Load delivery Complete Reasons to the Dropdown
     */
    const loadDeliveryCompleteReasons = async () => {
        try {
            const { data } = await getAllSystemDataByTypeAndModuleService(
                SYSTEM_DATA_TYPES.DELIVERY_ATTACHMENT_TYPES.TYPES.NOTE_REASON,
                SYSTEM_DATA_TYPES.DELIVERY_ATTACHMENT_TYPES.MODULE
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                settAttachmentType(data.map((x) => ({ label: x.name, value: x.name })));
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    /**
     * Handle Submit
     */
    const handleSubmit = async (event, errors = []) => {
        let isSuccess = false;
        setSubmitted(true);
        event.persist();

        const formValidation = validateForm(formData);
        if (!formValidation.isFormValid) {
            toast.custom((t) => <CustomToast text={formValidation.message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        setFormLoading(true);
        const payload = { ...formData, deliveryId, riderId };
        try {
            if (editingMode) {
                const { data } = await updateDeliveryAttachmentService(updateFormData.id, payload);
                if (data) {
                    isSuccess = true;
                    toast.custom(
                        (t) => <CustomToast text="Successfully Update the Delivery Attachment" t={t} type="success" />,
                        {
                            position: 'top-right',
                        }
                    );
                }
            }
            if (!editingMode) {
                const { data } = await createDeliveryAttachmentService(payload);
                if (data._id) {
                    isSuccess = true;
                    toast.custom(
                        (t) => <CustomToast text="Successfully created the Delivery Attachment" t={t} type="success" />,
                        {
                            position: 'top-right',
                        }
                    );
                }
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setValidated(false);
        setSubmitted(false);
        setFormLoading(false);

        if (isSuccess) {
            setShowModal(false);
            loadAttachments();
        }
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleCheckChangeFilter = (event) => {
        const { id, checked } = event.target;
        if (id === 'isVisibleToRider') {
            setFormData({
                ...formData,
                [id]: checked,
            });
        }
    };

    const selectedType = useMemo(() => getDefaultValueForSelect(formData.type), [formData.type]);
    return (
        <>
            <div className="font-size-14 w-100 p-0">
                <div
                    className="font-size-16 text-white"
                    style={{ backgroundColor: THEME_COLOR_2, padding: 15, borderRadius: '5px 5px 0 0' }}
                >
                    Add Notes for Delivery - {referenceNumber}
                </div>
                <div className="modal__form-table">
                    <AvForm onSubmit={handleSubmit}>
                        <fieldset>
                            <div className="modal_form-item">
                                <Label htmlFor="type">Type</Label>
                                <CreatableSelectAdvanced
                                    options={attachmentType}
                                    isClearable
                                    value={selectedType}
                                    required
                                    styles={reactSelectCustomStyles}
                                    id="type"
                                    isLoading={customLoading.reason}
                                    onChange={(event) => handleSelectChange(event, 'type')}
                                    onCreate={(event) => handleCreateSelectChange(event, 'type')}
                                    submitted={submitted}
                                    validated={validated}
                                />
                            </div>
                            <div className="modal_form-item">
                                <Label htmlFor="Description">Text</Label>
                                <div>
                                    <Input
                                        style={{ borderRadius: 2, padding: 5, width: '100%' }}
                                        rows="3"
                                        cols=""
                                        type="textarea"
                                        id="note"
                                        value={formData.note}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <Row className="ml-2 mt-4 modal_form-item">
                                <Col xs="auto">
                                    <Input
                                        onChange={handleCheckChangeFilter}
                                        type="checkbox"
                                        validate={{ required: { value: true } }}
                                        checked={formData.isVisibleToRider}
                                        id="isVisibleToRider"
                                    />
                                </Col>
                                <Col className="ml-n3" xs="auto">
                                    <Label htmlFor="isVisibleToRider" className="form-check-label">
                                        Display to Riders
                                    </Label>
                                </Col>
                            </Row>

                            <div style={{ fontSize: 14 }} className="mb-6 mt-3 flex row">
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn pt-1 pb-1 m-2 mb-3 pr-5 pl-5 shadow-lg "
                                    style={{ borderRadius: 10 }}
                                    type="submit"
                                    disabled={formLoading}
                                >
                                    {formLoading ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>{editingMode ? 'Update' : 'Confirm'}</>
                                    )}
                                </motion.button>
                            </div>
                        </fieldset>
                    </AvForm>
                </div>
            </div>
        </>
    );
}

AddDeliveryNotesModule.propTypes = {
    riderId: PropTypes.any,
    data: PropTypes.any,
    deliveryId: PropTypes.any,
    setShowModal: PropTypes.func,
    loadAttachments: PropTypes.func,
    updateFormData: PropTypes.func,
};

export default AddDeliveryNotesModule;
