import {
    CREATE_STOCKS_ADJUSTMENT,
    UPDATE_STOCKS_ADJUSTMENT,
    DELETE_STOCKS_ADJUSTMENT,
    UPDATE_CONFIRM_STOCKS_ADJUSTMENT,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Create Stocks Adjustment Service
 * @param {object} data
 * @returns {Promise}
 */
export const createStocksAdjustmentService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_STOCKS_ADJUSTMENT).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Stocks Adjustment Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateStocksAdjustmentService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_STOCKS_ADJUSTMENT).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Stocks Adjustment Service
 * @param {string} id
 * @returns {Promise}
 */
export const deleteStocksAdjustmentService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_STOCKS_ADJUSTMENT).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Confirm Stocks Adjustment Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateConfirmStocksAdjustmentService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_CONFIRM_STOCKS_ADJUSTMENT).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
