import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, DropdownItem } from 'reactstrap';
import { motion } from 'framer-motion';

// #region utils
import { __DEV__ } from 'utils/checks';

// #region other imports
import paths from 'routes/paths';

function AdvanceOptionsComponent(props) {
    const { formData, mapDocumentData, handleLinkBillingRecord } = props;
    return (
        <>
            <Row>
                <Col md={12}>
                    <Card className="shadow-lg default-card">
                        <CardBody>
                            <Row>
                                {formData.isDelivered && (
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                        onClick={handleLinkBillingRecord}
                                        style={{ borderRadius: 10 }}
                                    >
                                        {formData.billingRecordId
                                            ? 'Re Generate Billing Record'
                                            : 'Generate Billing Record'}
                                    </motion.div>
                                )}
                                {formData.billingRecordId && formData.isDelivered && (
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                        onClick={() => {
                                            window.open(
                                                `${paths.PAYMENTS.BILLING_RECORD}?type=edit&id=${formData.billingRecordId}`
                                            );
                                        }}
                                        style={{ borderRadius: 10 }}
                                    >
                                        Open Billing Record
                                    </motion.div>
                                )}
                            </Row>
                            <br />
                            <DropdownItem divider />
                            {/** !FIXME uncomment below */}
                            {__DEV__ && <div>{mapDocumentData()}</div>}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

AdvanceOptionsComponent.propTypes = {
    mapDocumentData: PropTypes.any,
    formData: PropTypes.object,
    handleLinkBillingRecord: PropTypes.func,
};

export default AdvanceOptionsComponent;
