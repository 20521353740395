export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Warehouse ID',
    },
    {
        key: 'siteName',
        value: 'Warehouse Name',
    },
    {
        key: 'siteType',
        value: 'Warehouse Type',
    },
    {
        key: 'address',
        value: 'Address',
    },
    {
        key: 'officeNo',
        value: 'Office Number',
    },
    {
        key: 'primaryContactNumber',
        value: 'Mobile Contact',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
    {
        key: 'updatedAt',
        value: 'Updated At',
        type: 'dateTime',
    },
    {
        key: 'createdBy',
        value: 'Created By',
        type: 'userObject',
    },
    {
        key: 'updatedBy',
        value: 'Updated By',
        type: 'userObject',
    },
];
