import { CREATE_ORDER, UPDATE_ORDER, DELETE_ORDER } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Create Order Service
 * @param {object} data
 * @returns {Promise}
 */
export const createOrderService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_ORDER).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Order Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateOrderService = (formData) => {
    const payload = formData;
    try {
        delete payload.orderItems;
    } catch (e) {
        console.log(e);
    }
    try {
        const url = UrlBuilder().setUrl(UPDATE_ORDER).setPathParams({ id: payload._id }).build();
        return instance.patch(url, payload);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Delete Order Service
 * @param {string} id
 * @returns {Promise}
 */
export const deleteOrderService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_ORDER).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
