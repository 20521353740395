export const storeSessionStorageItem = (key, value) => {
    const storageVal = window.sessionStorage.setItem(key, JSON.stringify(value));
    return storageVal;
};

export const getSessionStorageItem = (key) => {
    const storageVal = window.sessionStorage.getItem(key);
    if (storageVal) {
        return JSON.parse(storageVal);
    }
    return null;
};

export const removeSessionStorageItem = (key) => {
    window.sessionStorage.removeItem(key);
};
