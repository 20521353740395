/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Container, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';
import CrossCircleIcon from 'components/Icons/CrossCircle';

/** This omdal propts the user for a reason and the password before disabling an API key */
function DisableApiKey(props) {
    const { show, handleToggle, handleSubmit } = props;

    /** State for showing whether the submit operation is in progress */
    const [loading, setLoading] = useState(false);
    /** State for storing entered form data */
    const [formData, setFormData] = useState({ reason: '', password: '' });

    const handleModalSubmit = async () => {
        setLoading(true);
        const response = await handleSubmit(formData);
        if (!('error' in response)) {
            handleModalToggle();
        }
        setLoading(false);
    };

    const handleModalToggle = () => {
        resetForm();
        handleToggle();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const resetForm = () => {
        setFormData({ reason: '', password: '' });
        setLoading(false);
    };

    return (
        <DeveloperModal header="Disable API Key" show={show} handleToggle={handleModalToggle} mode="disable">
            <Container>
                <AvForm className="needs-validation default-form" noValidate onValidSubmit={handleModalSubmit}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="reason">Reason for Disabling API Key</Label>
                                <AvField
                                    name="reason"
                                    type="text"
                                    value={formData.reason}
                                    className="form-control"
                                    errorMessage="Required"
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                    autoComplete="new-password"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert color="warning" style={{ borderRadius: 8 }}>
                                Please enter your password to disable the API Key
                            </Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AvField
                                name="password"
                                type="password"
                                value={formData.password}
                                className="form-control"
                                errorMessage="Required"
                                onChange={handleInputChange}
                                validate={{ required: { value: true } }}
                                autoComplete="new-password"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                disabled={loading}
                                style={{ backgroundColor: '#FD4A4A', borderRadius: 8 }}
                                className="form-delete-btn"
                            >
                                {loading ? (
                                    <Spinner size="sm" color="warning" className="spinner-white mx-1" />
                                ) : (
                                    <CrossCircleIcon color="#FFF" />
                                )}
                                &nbsp;DISABLE
                            </Button>
                        </Col>
                    </Row>
                </AvForm>
            </Container>
        </DeveloperModal>
    );
}

DisableApiKey.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default DisableApiKey;
