/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

// #region assets | components
import { CheckCircle } from 'components/Icons';

// #endregion imports
import ListTable from './containers/ListTable';
import { deliveryTypesInitialValues } from './containers/ListTable/constants';

/**
 * Delivery type selection modal for route planning
 *
 * @param {object} formData - Route planning form data
 * @param {func} setSelectedDeliveryTypes -  Set dropdown selected value arrays for deliveryTypes
 * @param {func} selectedDeliveryTypes - Selected values for deliveryTypes
 * @param {func} toggleModal - Toggles the modal
 * @returns {JSX.Element} A React component that displays the delivery types modal.
 */
function SelectDeliveryTypeModule(props) {
    const {
        formData,
        setSelectedDeliveryTypes: updateSelectedDeliveryTypesCallback,
        selectedDeliveryTypes: prevSelectedDeliveryTypes = [],
        toggleModal,
    } = props;

    /** "selectedDeliveryData" stores checked records from the modal table */
    const [selectedDeliveryTypes, setSelectedDeliveryTypes] = useState(prevSelectedDeliveryTypes);
    // all delivery records selected or not
    const [isAllSelected, setIsAllSelected] = useState(formData.isSelectAllDeliveryTypes);
    const [tableData, setTableData] = useState(deliveryTypesInitialValues);

    /**
     * Generate payload for ListTable module by marking the selected delivery type objects
     * by length of the all delivery types sets if all are selected or not
     */
    useEffect(() => {
        if (Array.isArray(selectedDeliveryTypes)) {
            const updatedSelectedDocs = tableData.docs.map((val) => {
                const isSelectedType = selectedDeliveryTypes.find((deliveryType) => deliveryType.value === val.value);
                return {
                    ...val,
                    isSelected: !!isSelectedType,
                };
            });
            setTableData({ ...tableData, docs: updatedSelectedDocs });

            if (selectedDeliveryTypes.length === tableData.docs.length) {
                setIsAllSelected(true);
            } else {
                setIsAllSelected(false);
            }
        }
    }, [selectedDeliveryTypes]);

    /**
     * Handles the click event of the "Add Selected" button.
     * Adds the selected delivery types to the route planning form.
     * @returns {void}
     */
    const handleAddSelected = () => {
        updateSelectedDeliveryTypesCallback(selectedDeliveryTypes);
        toggleModal();
    };

    /**
     * Handles the select all checkbox in a modal.
     * It toggles the state of isAllSelected between true and false.
     * @returns {void}
     */
    const handleSelectAll = (event) => {
        const { checked } = event.target;
        setIsAllSelected(checked);
        const updatedSelectedData = tableData.docs.map((doc) => ({ ...doc, isSelected: checked }));
        if (checked) {
            setSelectedDeliveryTypes(updatedSelectedData);
        } else {
            setSelectedDeliveryTypes([]);
        }
    };

    return (
        <>
            <div className="font-size-14 w-100 p-0">
                <div className="modal__form-table">
                    <Row>
                        <Col>
                            <FormGroup className="form-group">
                                <div className="form-check mb-3">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        onChange={handleSelectAll}
                                        checked={isAllSelected}
                                        id="isSelectAllDeliveryTypes"
                                    />
                                    <Label className="form-check-label">Select All Delivery Types</Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr className="divider" />

                    <ListTable tableData={tableData} isSearch={false} setSelectedData={setSelectedDeliveryTypes} />

                    <AvForm>
                        <fieldset>
                            <div style={{ fontSize: 14 }} className="mb-6 mt-3 flex row">
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn pt-1 pb-1 m-2 mb-3 pr-5 pl-5 shadow-lg "
                                    style={{ borderRadius: 10 }}
                                    onClick={handleAddSelected}
                                >
                                    <CheckCircle color="#fff" />
                                    &nbsp;Add Selected
                                </motion.button>
                            </div>
                        </fieldset>
                    </AvForm>
                </div>
            </div>
        </>
    );
}

SelectDeliveryTypeModule.propTypes = {
    formData: PropTypes.any,
    setSelectedDeliveryTypes: PropTypes.func,
    toggleModal: PropTypes.func,
    selectedDeliveryTypes: PropTypes.any,
};

export default SelectDeliveryTypeModule;
