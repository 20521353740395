export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Delivery ID',
        sortingEnabled: true,
    },
    {
        key: 'deliveryType',
        value: 'Delivery Type',
        sortingEnabled: true,
    },
    {
        key: 'orderReferenceNumber',
        value: 'Order Number',
    },
    {
        key: 'merchantOrderNo',
        value: 'Merchant Order Number',
        sortingEnabled: true,
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
        sortingEnabled: true,
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        sortingEnabled: true,
    },
    {
        key: 'deliveryReadyTime',
        value: 'Ready Time',
        type: 'dateTime',
        sortingEnabled: true,
    },
    {
        key: 'deliveryDate',
        value: 'Delivery Date',
        sortingEnabled: true,
    },
    {
        key: 'pickupLocation',
        value: 'From',
        style: ['increase-width'],
    },
    {
        key: 'recipientName',
        value: 'Recipient',
        style: ['increase-width'],
    },
    {
        key: 'riderDetails',
        value: 'Rider',
    },
    {
        key: 'recipientMobileNumber',
        value: 'Recipient Mobile No',
    },
    {
        key: 'status',
        value: 'Current Status',
        type: 'deliveryStatus',
    },
];

export const tableDefaultFilters = [
    {
        label: 'Delivery ID',
        key: 'referenceNumber',
    },
    {
        label: 'Delivery Type',
        key: 'deliveryType',
    },
    {
        label: 'Reference Number',
        key: 'referenceNumber',
    },
    {
        label: 'Order Number',
        key: 'orderReferenceNumber',
    },
    {
        label: 'Merchant',
        key: 'merchantName',
    },
    {
        label: 'Merchant Order Number',
        key: 'merchantOrderNo',
    },
    {
        label: 'Recipient Mobile No',
        key: 'recipientMobileNumber',
    },
    {
        label: 'Rider',
        key: 'riderName',
    },
    {
        label: 'Rider ID',
        key: 'riderReference',
    },
    {
        label: 'Rider Mobile Number',
        key: 'riderMobileNumber',
    },
    {
        label: 'Delivery Date',
        key: 'deliveryDate',
        type: 'dateFilter',
    },
];
