/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';

// #region assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { AlertModal, BackdropLoader, CustomToast, TableCustom } from 'components';
import PATHS from 'routes/paths';

// #region services
import { getMerchantLocationsService } from 'services/merchant-location.service';

// #region utils
import { PERMISSIONS } from 'utils/constants';
import { hasAccess, SUPER_AND_CLIENT_ADMINS } from 'utils/checkAuth';

// #endregion imports
import { deleteMerchantLocationService } from '../../services';
import { defaultTableHeaders } from '../../constants';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'User Management', link: PATHS.USER_PROFILE_MANAGEMENT.DEFAULT },
    { title: 'Merchant', link: '#' },
];

/**
 * Merchant Location List Page
 * @param {*} props
 * @returns {React.Component}
 */
function MerchantLocationListPage() {
    const history = useHistory();

    // component state
    const [loading, setLoading] = useState(0);

    const [backdropLoading, setBackdropLoading] = useState(0);

    const [merchantLocationsListData, setMerchantLocationsListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });
    const [searchBy] = useState({ searchBy: '', searchText: null });
    const [showDeleteModal, setShowDeleteModal] = useState({ state: false, data: null });

    useEffect(() => {
        loadMerchantLocationsListData(
            true,
            merchantLocationsListData.all,
            1,
            merchantLocationsListData.limit,
            merchantLocationsListData.filter,
            merchantLocationsListData.sort,
            merchantLocationsListData.order,
            searchBy
        );
    }, []);

    const loadMerchantLocationsListData = (showLoading = true, all, page, limit, filter, sort, order, searchByProp) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getMerchantLocationsService(all, page, limit, filter, sort, order, searchByProp, null)
            .then((res) => {
                const { data } = res;
                setMerchantLocationsListData({
                    ...merchantLocationsListData,
                    ...data,
                });
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const handleOnClickNewMerchant = () => {
        history.push({
            pathname: `${PATHS.MERCHANT.MERCHANT_LOCATION_FORM}`,
            search: `?type=new`,
        });
    };

    const handlePageChange = (pageNo) => {
        loadMerchantLocationsListData(
            true,
            merchantLocationsListData.all,
            pageNo,
            merchantLocationsListData.limit,
            merchantLocationsListData.filter,
            merchantLocationsListData.sort,
            merchantLocationsListData.order,
            searchBy
        );
    };

    const handleFilter = (field, filterText) => {
        loadMerchantLocationsListData(
            false,
            merchantLocationsListData.all,
            1,
            merchantLocationsListData.limit,
            merchantLocationsListData.filter,
            merchantLocationsListData.sort,
            merchantLocationsListData.order,
            { searchBy: field || 'merchantLocationName', searchText: filterText }
        );
    };

    const handleEditRow = (e, row) => {
        history.push({
            pathname: `${PATHS.MERCHANT.MERCHANT_LOCATION_FORM}`,
            search: `?type=edit&id=${row._id}`,
            state: { merchantLocationParentData: row },
        });
    };

    const handleViewRow = (e, row) => {
        history.push({
            pathname: `${PATHS.MERCHANT.MERCHANT_LOCATION_FORM}`,
            search: `?type=view`,
            state: { merchantLocationParentData: row },
        });
    };

    const shouldShowDeleteBtn = () => SUPER_AND_CLIENT_ADMINS() || hasAccess(PERMISSIONS.MERCHANT_LOCATION, ['Delete']);

    const handleOnDelete = (e, row) => {
        setShowDeleteModal({ state: true, data: row });
    };

    const handleDeleteMerchantLocation = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteMerchantLocationService(data._id);
            loadMerchantLocationsListData(
                false,
                merchantLocationsListData.all,
                1,
                merchantLocationsListData.limit,
                merchantLocationsListData.filter,
                merchantLocationsListData.sort,
                merchantLocationsListData.order
            );
            toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors && e.data.errors.userMessage) {
                toast.error(e.data.errors.userMessage);
            } else {
                toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        }
        setShowDeleteModal({ state: false, data: null });
        setBackdropLoading((prevState) => prevState - 1);
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        {hasAccess(PERMISSIONS.MERCHANT_LOCATION, ['Add']) && (
                            <div>
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => handleOnClickNewMerchant()}
                                >
                                    <i className="fas fa-map-pin left-icon"></i>
                                    Create New Merchant Location
                                </motion.div>
                            </div>
                        )}
                    </Row>

                    <Row>
                        <TableCustom
                            tableData={merchantLocationsListData}
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            showEdit={hasAccess(PERMISSIONS.MERCHANT_LOCATION, ['Edit'])}
                            showView={false}
                            handleView={handleViewRow}
                            loading={loading}
                            handleEdit={handleEditRow}
                            showDelete={shouldShowDeleteBtn()}
                            handleDelete={handleOnDelete}
                            filters={[
                                {
                                    label: 'Merchant',
                                    key: 'merchantName',
                                },
                                {
                                    label: 'Merchant Location Name',
                                    key: 'merchantLocationName',
                                },
                                {
                                    label: 'Location',
                                    key: 'location',
                                },
                                {
                                    label: 'City',
                                    key: 'city',
                                },
                                {
                                    label: 'State',
                                    key: 'state',
                                },
                            ]}
                        />
                    </Row>
                </Container>
            </div>

            <AlertModal
                show={showDeleteModal.state}
                onConfirm={handleDeleteMerchantLocation}
                data={showDeleteModal.data}
                onHide={() =>
                    setShowDeleteModal({
                        state: false,
                        data: null,
                    })
                }
            />
            <BackdropLoader show={backdropLoading} opacity={0.7} />
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

const HotMerchantLocationListPage = hot(MerchantLocationListPage);

export default connect(mapStateToProps, {})(HotMerchantLocationListPage);
