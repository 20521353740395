import React from 'react';
import PropTypes from 'prop-types';

function PlusSquare(props) {
    const { color="#FF9300", width="25", height="25" } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 0L10.324 0.000999928L10.642 0.00500011L11.258 0.0220001L11.557 0.0350001L12.136 0.069L12.689 0.115C17.474 0.579 19.421 2.526 19.885 7.311L19.931 7.864L19.965 8.443C19.97 8.541 19.975 8.641 19.978 8.742L19.995 9.358L20 10L19.995 10.642L19.978 11.258L19.965 11.557L19.931 12.136L19.885 12.689C19.421 17.474 17.474 19.421 12.689 19.885L12.136 19.931L11.557 19.965C11.459 19.97 11.359 19.975 11.258 19.978L10.642 19.995L10 20L9.358 19.995L8.742 19.978L8.443 19.965L7.864 19.931L7.311 19.885C2.526 19.421 0.579 17.474 0.115 12.689L0.069 12.136L0.0350001 11.557C0.0301352 11.4574 0.0258018 11.3577 0.0220001 11.258L0.00500011 10.642C0.00200011 10.432 0 10.218 0 10L0.000999928 9.676L0.00500011 9.358L0.0220001 8.742L0.0350001 8.443L0.069 7.864L0.115 7.311C0.579 2.526 2.526 0.579 7.311 0.115L7.864 0.069L8.443 0.0350001C8.541 0.0300001 8.641 0.0250001 8.742 0.0220001L9.358 0.00500011C9.568 0.00200011 9.782 0 10 0ZM10 6C9.73478 6 9.48043 6.10536 9.29289 6.29289C9.10536 6.48043 9 6.73478 9 7V9H7L6.883 9.007C6.6299 9.0371 6.39785 9.16271 6.23426 9.35817C6.07067 9.55362 5.98789 9.80416 6.00283 10.0586C6.01776 10.313 6.1293 10.5522 6.31463 10.7272C6.49997 10.9021 6.74512 10.9997 7 11H9V13L9.007 13.117C9.0371 13.3701 9.16271 13.6021 9.35817 13.7657C9.55362 13.9293 9.80416 14.0121 10.0586 13.9972C10.313 13.9822 10.5522 13.8707 10.7272 13.6854C10.9021 13.5 10.9997 13.2549 11 13V11H13L13.117 10.993C13.3701 10.9629 13.6021 10.8373 13.7657 10.6418C13.9293 10.4464 14.0121 10.1958 13.9972 9.94139C13.9822 9.68695 13.8707 9.44782 13.6854 9.27285C13.5 9.09788 13.2549 9.00028 13 9H11V7L10.993 6.883C10.9643 6.63975 10.8474 6.41547 10.6644 6.25272C10.4813 6.08996 10.2449 6.00003 10 6Z"
                fill={color}
            />
        </svg>
    );
}

PlusSquare.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

export default PlusSquare;
