export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Rider Id',
    },
    {
        key: 'fullName',
        value: 'Name',
    },
    {
        key: 'mobileNo',
        value: 'Mobile No',
    },
    {
        key: 'email',
        value: 'E-Mail',
    },
    {
        key: 'deletedAt',
        value: 'Deleted At',
        type: 'dateTime',
    },
    {
        key: '_id',
        value: 'Restore',
        type: 'restoreButton',
    },
    {
        key: '_id',
        value: 'Permanent Delete',
        type: 'permanentDeleteButton',
    },
    { key: 'deletedBy', value: 'Deleted By', type: 'userObject' },
];
