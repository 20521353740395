/* eslint-disable indent */
/* eslint-disable camelcase */
import {
    GET_STOCKS_ADJUSTMENT,
    GET_STOCKS_ADJUSTMENT_BY_ID,
    GET_ALL_STOCKS_ADJUSTMENT,
    GET_STOCKS_ADJUSTMENT_DELETED,
    RESTORE_STOCKS_ADJUSTMENT,
    DELETE_STOCKS_ADJUSTMENT_PERMANENT,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Stocks Adjustment Service
 * @returns {object}
 */
export const getStocksAdjustmentService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_STOCKS_ADJUSTMENT}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Stocks Adjustment Deleted Service
 * @returns {object}
 */
export const getStocksAdjustmentDeletedService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_STOCKS_ADJUSTMENT_DELETED}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Stocks Adjustment By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getStocksAdjustmentByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_STOCKS_ADJUSTMENT_BY_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Stocks Adjustment
 * @returns {Promise}
 */
export const getAllStocksAdjustmentService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_STOCKS_ADJUSTMENT).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const deleteMerchantPermanentService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_STOCKS_ADJUSTMENT_PERMANENT).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Restore Merchant Service
 * @returns {Promise}
 */
export const restoreMerchantService = (id) => {
    try {
        const url = UrlBuilder().setUrl(RESTORE_STOCKS_ADJUSTMENT).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
