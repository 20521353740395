import * as React from 'react';
import PropTypes from 'prop-types';

function DeliveryAttachmentArchiveIcon(props) {
    const { color, width, height } = props;
    return (
        <svg
            width={width || 24}
            height={height || 25}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2689_436)">
                <path
                    d="M19 4H5C3.89543 4 3 4.89543 3 6C3 7.10457 3.89543 8 5 8H19C20.1046 8 21 7.10457 21 6C21 4.89543 20.1046 4 19 4Z"
                    stroke={color || '#000000'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5 8V18C5 18.5304 5.21071 19.0391 5.58579 19.4142C5.96086 19.7893 6.46957 20 7 20H17C17.5304 20 18.0391 19.7893 18.4142 19.4142C18.7893 19.0391 19 18.5304 19 18V8"
                    stroke={color || '#000000'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 12H14"
                    stroke={color || '#000000'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2689_436">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
DeliveryAttachmentArchiveIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default DeliveryAttachmentArchiveIcon;
