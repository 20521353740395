export const BILLING_METHOD_OPTIONS_DEFAULT = [
    {
        label: 'Fixed Per Delivery',
        value: 'Fixed Per Delivery',
    },
    {
        label: 'Mileage-Based Fee',
        value: 'Mileage-Based Fee',
    },
];

export const BILLING_METHOD_OPTIONS_RETURN_ORDER = [
    {
        label: 'Fixed Per Delivery',
        value: 'Fixed Per Delivery',
    },
    {
        label: 'Mileage-Based Fee',
        value: 'Mileage-Based Fee',
    },
    {
        label: 'Percentage of Delivery Fee',
        value: 'Percentage of Delivery Fee',
    },
];
