import * as React from 'react';
import PropTypes from 'prop-types';

function Bike(props) {
    const { color = '#fff' } = props;
    return (
        <svg width={30} height={30} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5 19a3 3 0 100-6 3 3 0 000 6zM19 19a3 3 0 100-6 3 3 0 000 6zM7.5 14l4-4m-4 4h5l4-4H6l1.5 4z"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M13 6h2l1.5 3 2 4" stroke={color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

Bike.propTypes = {
    color: PropTypes.string,
};

export default Bike;
