/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, FormGroup, Label, Spinner, Alert } from 'reactstrap';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import * as qs from 'query-string';
import toast from 'react-hot-toast';

// #region components | assets
import { BackdropLoader, CustomToast } from 'components';
import CheckCircleIcon from 'components/Icons/Check-Circle';

// #region imports
import paths from 'routes/paths';
import { DELIVERY_IMPORT_TYPE_NAMES } from 'constants/db/merchant-setting';
import { FONT_PRIMARY_GRAY } from 'theme';

// #region services
import {
    createExcelCsvConfigService,
    getExcelCsvConfigByMerchantIdService,
    updateExcelCsvConfigService,
} from 'services/excel-csv-config.service';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';
import {
    deliveryExcelCsvImportConfig,
    deliveryExcelCsvImportConfigDoctorOnCallModel,
} from 'utils/db/excel-csv/delivery-excel-csv';
import { getMerchantSettingByMerchantIdService } from 'services/merchant-setting.service';

function ExcelCsvConfigurationPage(props) {
    const { location } = props;

    const history = useHistory();

    // * States

    // component
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [selectedMerchantId, setSelectedMerchantId] = useState(null);

    // form
    const [formData, setFormData] = useState({ type: 'DELIVERY_IMPORT' });
    const [merchantSetting, setMerchantSetting] = useState({});
    const [editingMode, setEditingMode] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const [importType, setImportType] = useState(null);

    useEffect(() => {
        const formType = qs.parse(location.search);
        if (formType && formType.merchantId) {
            setSelectedMerchantId(formType.merchantId);
            setEditingMode(true);
        } else {
            history.push(paths.SETTINGS.DELIVERY.EXTERNAL_SYSTEM_CONNECTIONS);
        }
    }, [location]);

    useEffect(() => {
        if (selectedMerchantId) {
            loadMerchantSettings(selectedMerchantId);
        }
    }, [selectedMerchantId]);

    const loadExcelCsvConfig = async (id, type) => {
        setLoading((prevState) => prevState + 1);
        try {
            const { data } = await getExcelCsvConfigByMerchantIdService(id, 'DELIVERY_IMPORT');
            if (data) {
                setEditingMode(true);
                if (type === DELIVERY_IMPORT_TYPE_NAMES.DOCTOR_ON_CALL) {
                    const fieldNames = {
                        ...(formData.fieldNames || {}),
                        ...(deliveryExcelCsvImportConfigDoctorOnCallModel.fieldNames || {}),
                        ...(data.fieldNames || {}),
                    };
                    setFormData({ ...formData, ...data, fieldNames });
                } else {
                    const fieldNames = {
                        ...(formData.fieldNames || {}),
                        ...(deliveryExcelCsvImportConfig.fieldNames || {}),
                    };

                    setFormData({ ...formData, fieldNames });
                }
            } else {
                setEditingMode(false);
                if (type === DELIVERY_IMPORT_TYPE_NAMES.DOCTOR_ON_CALL) {
                    const fieldNames = {
                        ...(formData.fieldNames || {}),
                        ...(deliveryExcelCsvImportConfigDoctorOnCallModel.fieldNames || {}),
                    };
                    setFormData({ ...formData, fieldNames });
                } else {
                    const fieldNames = {
                        ...(formData.fieldNames || {}),
                        ...(deliveryExcelCsvImportConfig.fieldNames || {}),
                    };

                    setFormData({ ...formData, fieldNames });
                }
            }
        } catch (e) {
            console.log(e);
        }
        setLoading((prevState) => prevState - 1);
    };

    const loadMerchantSettings = async (id) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getMerchantSettingByMerchantIdService(id);
            if (data) {
                setEditingMode(true);
                setMerchantSetting({ ...merchantSetting, ...data });
                if (data?.settings?.deliveryImport?.type) {
                    loadExcelCsvConfig(id, data.settings.deliveryImport.type);
                    setImportType(data.settings.deliveryImport.type);
                } else {
                    const fieldNames = {
                        ...(formData.fieldNames || {}),
                        ...(deliveryExcelCsvImportConfig.fieldNames || {}),
                    };
                    setFormData({ ...formData, fieldNames });
                }
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleSubmit = async (event, errors) => {
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();
        // const isFormValid = validateForm(formData);
        // if (!isFormValid) {
        //     toast.error('Fill all fields', TOAST_STYLES.ERROR);
        //     return;
        // }

        const payload = {
            ...formData,
            merchantId: selectedMerchantId,
        };

        setFormLoading(true);
        try {
            if (editingMode) {
                const { data } = await updateExcelCsvConfigService(payload);
                if (data) {
                    setFormData({ ...payload, ...data });
                }
            } else {
                const { data } = await createExcelCsvConfigService(payload);
                if (data) {
                    setFormData({ ...payload, ...data });
                }
            }
            setEditingMode(true);
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} configuration`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading(false);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            fieldNames: {
                ...formData.fieldNames,
                [id]: {
                    ...formData.fieldNames[id],
                    value,
                },
            },
        });
    };

    const generateFormFields = () => {
        if (importType === DELIVERY_IMPORT_TYPE_NAMES.DOCTOR_ON_CALL) {
            return (
                <div>
                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="deliveryType">Delivery Type</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="deliveryType"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="deliveryType"
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                    placeholder={formData.fieldNames.deliveryType.default}
                                    value={formData.fieldNames.deliveryType.value}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <Row>
                                <Col md="1">
                                    <div className="mt-2">
                                        <CheckCircleIcon />
                                    </div>
                                </Col>
                                <Col md="10">
                                    <div className="mt-2" style={{ color: '#FFBF00' }}>
                                        ( d1 = New Delivery , d2 = Return Delivery, d3 = On Demand Delivery )
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="source">Source</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="source"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="source"
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                    disabled="true"
                                    placeholder={formData.fieldNames.source.default}
                                    value={formData.fieldNames.source.value}
                                />
                            </FormGroup>
                        </Col>

                        <Col md="5">
                            <Row>
                                <Col md="1">
                                    <div className="mt-2"></div>
                                </Col>
                                <Col md="10">
                                    <div className="mt-2" style={{ color: '#FFBF00' }}>
                                        ( 1 = System Order , 2 = Manual Order )
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="vehicleType">Vehicle Type</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="vehicleType"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="vehicleType"
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                    placeholder={formData.fieldNames.vehicleType.default}
                                    value={formData.fieldNames.vehicleType.value}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <Row>
                                <Col md="1">
                                    <div className="mt-2"></div>
                                </Col>
                                <Col md="10">
                                    <div className="mt-2" style={{ color: '#FFBF00' }}>
                                        ( V1 = Car , V2 = Motorbike, V3 = Van 7 Feet, V4 = Truck )
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="deliveryReadyTime">Delivery Ready Time</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="deliveryReadyTime"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="deliveryReadyTime"
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                    disabled="true"
                                    placeholder={formData.fieldNames.deliveryReadyTime.default}
                                    value={formData.fieldNames.deliveryReadyTime.value}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="deliveryDate">Delivery Date</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="deliveryDate"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="deliveryDate"
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                    placeholder={formData.fieldNames?.deliveryDate?.default}
                                    value={formData.fieldNames?.deliveryDate?.value}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="specialDeliveryTime">Special Delivery Time</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="specialDeliveryTime"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="specialDeliveryTime"
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                    placeholder={formData.fieldNames.specialDeliveryTime.default}
                                    value={formData.fieldNames.specialDeliveryTime.value}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="specialDeliveryDateTime">Special Delivery Date and Time</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="specialDeliveryDateTime"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="specialDeliveryDateTime"
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                    placeholder={formData.fieldNames.specialDeliveryDateTime.default}
                                    value={formData.fieldNames.specialDeliveryDateTime.value}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="orderId">Order Number</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="orderId"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="orderId"
                                    placeholder={formData.fieldNames.orderId.default}
                                    value={formData.fieldNames.orderId.value}
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="merchantOrderNo">Merchant Order Number</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="merchantOrderNo"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="merchantOrderNo"
                                    placeholder={formData.fieldNames.merchantOrderNo.default}
                                    value={formData.fieldNames.merchantOrderNo.value}
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <div className="mt-2">
                                <CheckCircleIcon />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="pickupLocation">Pickup Location</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="pickupLocation"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="pickupLocation"
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                    placeholder={formData.fieldNames.pickupLocation.default}
                                    value={formData.fieldNames.pickupLocation.value}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <div className="mt-2">
                                <CheckCircleIcon />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="pickupLocationPostalCode">Pickup Location Postal Code</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="pickupLocationPostalCode"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="pickupLocationPostalCode"
                                    placeholder={formData.fieldNames.pickupLocationPostalCode.default}
                                    value={formData.fieldNames.pickupLocationPostalCode.value}
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <div className="mt-2">
                                <CheckCircleIcon />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="recipientMobileNumber">Recipient Mobile Number</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="recipientMobileNumber"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="recipientMobileNumber"
                                    placeholder={formData.fieldNames.recipientMobileNumber.default}
                                    value={formData.fieldNames.recipientMobileNumber.value}
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <div className="mt-2">
                                <CheckCircleIcon />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="recipientName">Recipient Name</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="recipientName"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="recipientName"
                                    placeholder={formData.fieldNames.recipientName.default}
                                    value={formData.fieldNames.recipientName.value}
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <div className="mt-2">
                                <CheckCircleIcon />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="recipientEmail">Recipient E-mail</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="recipientEmail"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="recipientEmail"
                                    placeholder={formData.fieldNames.recipientEmail.default}
                                    value={formData.fieldNames.recipientEmail.value}
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="deliveryAddress">Delivery Address</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="deliveryAddress"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="deliveryAddress"
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                    placeholder={formData.fieldNames.deliveryAddress.default}
                                    value={formData.fieldNames.deliveryAddress.value}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <div className="mt-2">
                                <CheckCircleIcon />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="postalCode">Delivery Postal Code</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="postalCode"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="postalCode"
                                    placeholder={formData.fieldNames.postalCode.default}
                                    value={formData.fieldNames.postalCode.value}
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <div className="mt-2">
                                <CheckCircleIcon />
                            </div>
                        </Col>
                    </Row>
                    {/* Boolean Value Fields */}
                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="isFragile">Fragile</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="isFragile"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="isFragile"
                                    placeholder={formData.fieldNames.isFragile.default}
                                    value={formData.fieldNames.isFragile.value}
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <Row>
                                <Col md="1">
                                    <div className="mt-2"></div>
                                </Col>
                                <Col md="10">
                                    <div className="mt-2" style={{ color: '#FFBF00' }}>
                                        ( Y = Yes , N = No )
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="isFrozen">Frozen</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="isFrozen"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="isFrozen"
                                    placeholder={formData.fieldNames.isFrozen.default}
                                    value={formData.fieldNames.isFrozen.value}
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <Row>
                                <Col md="1">
                                    <div className="mt-2"></div>
                                </Col>
                                <Col md="10">
                                    <div className="mt-2" style={{ color: '#FFBF00' }}>
                                        ( Y = Yes , N = No )
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="isHighValue">High Value</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="isHighValue"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="isHighValue"
                                    placeholder={formData.fieldNames.isHighValue.default}
                                    value={formData.fieldNames.isHighValue.value}
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <Row>
                                <Col md="1">
                                    <div className="mt-2"></div>
                                </Col>
                                <Col md="10">
                                    <div className="mt-2" style={{ color: '#FFBF00' }}>
                                        ( Y = Yes , N = No )
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="isHalal">Halal</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="isHalal"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="isHalal"
                                    placeholder={formData.fieldNames.isHalal.default}
                                    value={formData.fieldNames.isHalal.value}
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <Row>
                                <Col md="1">
                                    <div className="mt-2"></div>
                                </Col>
                                <Col md="10">
                                    <div className="mt-2" style={{ color: '#FFBF00' }}>
                                        ( Y = Yes , N = No )
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="isChill">Chill</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="isChill"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="isChill"
                                    placeholder={formData.fieldNames.isChill.default}
                                    value={formData.fieldNames.isChill.value}
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <Row>
                                <Col md="1">
                                    <div className="mt-2"></div>
                                </Col>
                                <Col md="10">
                                    <div className="mt-2" style={{ color: '#FFBF00' }}>
                                        ( Y = Yes , N = No )
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="isFemaleDriver">Delivery by Female</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="isFemaleDriver"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="isFemaleDriver"
                                    placeholder={formData.fieldNames.isFemaleDriver.default}
                                    value={formData.fieldNames.isFemaleDriver.value}
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <Row>
                                <Col md="1">
                                    <div className="mt-2"></div>
                                </Col>
                                <Col md="10">
                                    <div className="mt-2" style={{ color: '#FFBF00' }}>
                                        ( Y = Yes , N = No )
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="3" style={{ padding: 10, paddingLeft: 50 }}>
                            <Label htmlFor="deliveryNote">Delivery Note</Label>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <AvField
                                    name="deliveryNote"
                                    type="text"
                                    errorMessage="Required"
                                    className="form-control"
                                    id="deliveryNote"
                                    placeholder={formData.fieldNames.deliveryNote.default}
                                    value={formData.fieldNames.deliveryNote.value}
                                    validate={{ required: { value: true } }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            );
        }

        return (
            <>
                <Row className="justify-content-center ">
                    <div className="justify-content-center">
                        <h3 className="font-poppins" style={{ fontWeight: 'bold', color: FONT_PRIMARY_GRAY }}>
                            No Import Type Found
                        </h3>
                        <h5
                            className="font-poppins text-center pointer"
                            style={{ color: '#edb211' }}
                            onClick={() => {
                                history.push({
                                    pathname: `${paths.MERCHANT.MERCHANT_FORM}`,
                                    search: `?type=edit&id=${selectedMerchantId}&module=settings`,
                                });
                            }}
                        >
                            Click here to Setup
                        </h5>
                    </div>
                </Row>
            </>
        );
    };

    return (
        <>
            <div className="page-content">
                {formData.fieldNames && (
                    <AvForm
                        noValidate
                        id="excelCsvConfigForm"
                        className="needs-validation"
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div></div>
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius m-2 mb-3 pr-3 pl-3 shadow-lg nw-md mr-4 pr-4 pl-4"
                                onClick={() =>
                                    history.push({
                                        pathname: `${paths.SETTINGS.DELIVERY.EXTERNAL_SYSTEM_CONNECTIONS}`,
                                    })
                                }
                            >
                                <i className="fas fa-chevron-left"></i> <span className="h6">Back</span>
                            </motion.div>
                        </Row>
                        <br />

                        <Alert color="info" style={{ padding: 3 }}>
                            <div className=" m-1 ml-3 pl-2 pr-2 align-items-center">
                                <div className="d-flex row">
                                    <div className="mt-1 mr-2">
                                        <CheckCircleIcon />
                                    </div>
                                    <div style={{ fontSize: 15, marginTop: 4 }}>
                                        Marked fields are required <b>Columns</b>{' '}
                                    </div>
                                </div>
                            </div>
                        </Alert>
                        <Alert color="info" style={{ padding: 3 }}>
                            <div className=" m-1 ml-3 pl-2 pr-2 align-items-center">
                                <div className="d-flex row pl-1">
                                    <div className="mt-1 mr-2">
                                        <i className="fa fa-info-circle mt-1 fa-lg" aria-hidden="true" />
                                    </div>
                                    <div style={{ fontSize: 15, marginTop: 4 }}>
                                        You are required to change the fields according to your Import Model
                                    </div>
                                </div>
                            </div>
                        </Alert>
                        <br />
                        <Card style={{ borderRadius: 15, marginTop: -10 }}>
                            <CardBody style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                <div
                                    style={{
                                        backgroundColor: '#F9F7F7',
                                        borderTopRightRadius: 15,
                                        borderTopLeftRadius: 15,
                                        minHeight: 20,
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: 23,
                                            color: '#807F7D',
                                            fontSize: 19,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Excel format
                                    </div>
                                </div>
                                <br />
                                {generateFormFields()}

                                {importType && selectedMerchantId && (
                                    <Row style={{ justifyContent: 'flex-end' }} className="mr-3 pointer">
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-5 pl-5"
                                            type="submit"
                                            disabled={formLoading}
                                        >
                                            {formLoading ? (
                                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                            ) : (
                                                <>
                                                    <i className="fas fa-save left-icon"></i>
                                                    <span className="h6">{editingMode ? 'Update' : 'Save'}</span>
                                                </>
                                            )}
                                        </motion.button>
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    </AvForm>
                )}
            </div>

            <BackdropLoader show={loading} opacity={0.8} />
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

ExcelCsvConfigurationPage.propTypes = {
    location: PropTypes.any,
};

const HotExcelCsvConfigurationPage = hot(ExcelCsvConfigurationPage);

export default connect(mapStateToProps, {})(HotExcelCsvConfigurationPage);
