/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';

// #region imports
import PATHS from 'routes/paths';

// #region assets | components
import Tabs from 'components/Tabs';

// #endregion imports
import DeveloperContacts from './DeveloperContacts';
import BasicSettingsTab from './Basic-Settings';
import MerchantDashboard from '../Api-Service/Dashboard/Merchant';
import AdvancedSettings from './Advanced-Settings';
import ConnectedApps from './Connected-Apps';
import WebhookTab from './Webhook';
import { hasAccessTabs } from './utils/permissionHandler';

/**
 * This page contains the Tabs component, and loads the relevant tab according to the URL
 * @param {object} props
 */
function TabsPage(props) {
    const history = useHistory();

    const { location } = props;
    const { state } = location || {};
    const merchantId = (state && state.merchantId) || props.merchantId;
    const merchantName = (state && state.merchantName) || props.merchantName;

    /** Currently active tab to pass to the Tabs component. Should be read from the URL if present */
    const [activeTab, setActiveTab] = useState('0');

    /** Data for the tabs component. The tabKey is not needed for the component, it is used in this page component itself */
    const tabs = [
        {
            tabKey: 'dashboard',
            tabName: 'Dashboard',
            tabContent: <MerchantDashboard merchantId={merchantId} merchantName={merchantName} />,
        },
        {
            tabKey: 'basicSettings',
            tabName: 'Basic Settings',
            tabContent: <BasicSettingsTab merchantId={merchantId} merchantName={merchantName} location={location} />,
        },
        {
            tabKey: 'advancedSettings',
            tabName: 'Advanced Settings',
            tabContent: <AdvancedSettings />,
        },
        {
            tabKey: 'webhook',
            tabName: 'Webhook',
            tabContent: <WebhookTab merchantId={merchantId} merchantName={merchantName} location={location} />,
        },
        {
            tabKey: 'developerContact',
            tabName: 'Developer Contact',
            tabContent: <DeveloperContacts />,
        },
        {
            tabKey: 'connectedApps',
            tabName: 'Connected Apps',
            tabContent: <ConnectedApps merchantId={merchantId} location={location} />,
        },
    ];

    /** Read the URL and set the ?tab=NAME tab as the selected tab, where NAME is the tabKey */
    useEffect(() => {
        const urlSearch = qs.parse(props.location.search);
        if (urlSearch && urlSearch.tab) {
            // if (hasAccess(PERMISSIONS.PARTNER_API_MERCHANT_DASHBOARD, ['View']))
            const activeTabIndex = hasAccessTabs(tabs).findIndex((tab) => tab.tabKey === urlSearch.tab);
            setActiveTab(activeTabIndex.toString());
        }
    }, [props.location]);

    /**
     * Callback that is called when the Tabs component switches tabs
     * @param {string} tabIndex - index of the newly switched tab
     */
    const handleTabSwitch = (tabIndex) => {
        const switchedTab = tabs.find((tab, index) => index.toString() === tabIndex);
        if (switchedTab) {
            history.replace({
                pathname: `/${PATHS.DEVELOPER.API_PORTAL}`,
                search: `?tab=${switchedTab.tabKey}`,
                state: props.location.state,
            });
        }
    };

    return (
        <div className="page-content">
            <Tabs tabData={hasAccessTabs(tabs)} activeTab={activeTab} onTabSwitch={handleTabSwitch} />
        </div>
    );
}

TabsPage.propTypes = {
    /** Location prop for parsing the current tab info, search info, etc */
    location: PropTypes.any,
    merchantId: PropTypes.string,
    merchantName: PropTypes.string,
};

const HotTabsPage = hot(TabsPage);

export default connect(null, {})(HotTabsPage);
