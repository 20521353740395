import {
    CREATE_MERCHANT,
    DELETE_MERCHANT,
    UPDATE_MERCHANT,
    DELETE_MERCHANT_PERMANENT,
    RESTORE_MERCHANT,
    UPDATE_MERCHANT_PROPS,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const createMerchantService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_MERCHANT).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const updateMerchantService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_MERCHANT).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const updateMerchantPropsService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_MERCHANT_PROPS).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const deleteMerchantService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_MERCHANT).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const deleteMerchantPermanentService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_MERCHANT_PERMANENT).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Restore Merchant Service
 * @returns {Promise}
 */
export const restoreMerchantService = (id) => {
    try {
        const url = UrlBuilder().setUrl(RESTORE_MERCHANT).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
