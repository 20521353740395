// Route Planning Entry View delivery data
const headersForRoutePlanningEntryViewTable = [
    {
        key: 'plan',
        value: 'Plan',
        type: 'planSequenceNo',
    },
    {
        key: 'referenceNumber',
        value: 'Delivery ID',
        type: 'viewDeliveryNavigation',
    },
    {
        key: 'routeType',
        value: 'Delivery Action',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
        style: ['increase-width'],
        customHeaderStyles: {
            minWidth: 250,
        },
    },
    {
        key: 'merchantOrderNo',
        value: 'Merchant Order Number',
    },
    {
        key: 'pickupLocation',
        value: 'Pickup Location',
    },
    {
        key: 'deliveryAddress',
        value: 'Delivered Location',
    },
    {
        key: 'pickedUpAt',
        value: 'Picked Up At',
    },
    {
        key: 'deliveredAt',
        value: 'Delivered At',
    },
    {
        key: 'status',
        value: 'Status',
        type: 'routePlanningEntryViewStatusLabel',
    },
    {
        key: 'action',
        value: 'Action',
        type: 'customActionButton',
        style: ['centerElements'],
        params: {
            buttonLabel: 'Detach',
            customActionHandlerName: 'detachRoutePlanningDelivery',
            btnBgColor: '#FF2825',
        },
    },
];

// Route Planning Entry View delivery data
const headersForRoutePlanningEntryViewTransactionTable = [
    {
        key: 'sequence',
        value: 'TRANSACTION ID',
    },
    {
        key: 'reference',
        value: 'TRANSACTION REFERENCE',
    },
    {
        key: 'transactionName',
        value: 'TRANSACTION',
    },
    {
        key: 'createdByUserFullName',
        value: 'USER',
    },
    {
        key: 'createdAt',
        value: 'TRANSACTION DATE AND TIME',
    },
    {
        key: 'action',
        value: 'DETAILS',
        type: 'customActionButton',
        params: {
            buttonLabel: 'View',
            customActionHandlerName: 'displayModal',
        },
    },
];

/**
 * Initial table data for the route planning entry view table
 */
export const initialRoutePlanningEntryViewTableData = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: headersForRoutePlanningEntryViewTable,
    docs: [],
    all: false,
    sort: null,
    order: -1,
};

/**
 * Initial table data for the route planning entry view Transaction table
 */
export const initialRoutePlanningEntryViewTransactionTableData = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: headersForRoutePlanningEntryViewTransactionTable,
    docs: [],
    all: false,
    sort: null,
    order: -1,
};

export const ROUTE_TYPES = {
    PICKUP: 'Pickup',
    DELIVERY: 'delivery',
};

export const RIDER_LOCATION = {
    HOME: 'home',
    TASA: 'tasa',
    CURRENT_LOCATION: 'currentLocation',
};
