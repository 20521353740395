/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row, NavLink, NavItem, Nav } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';
import * as qs from 'query-string';

// #region imports
import Breadcrumbs from 'components/Common/Breadcrumb';
import { AlertModal, BackdropLoader, StatCard, TableCustom } from 'components';
import PATHS from 'routes/paths';
import { getOrdersService, getAllOrdersMetaService } from 'services/order.service';

// #endregion imports
import { deleteOrderService } from './services';
import { defaultTableHeaders } from './constants';
import { mapDocs } from './utils/list';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Order Processing', link: PATHS.ORDER_PROCESSING.DEFAULT },
    { title: 'Dashboard', link: PATHS.ORDER_PROCESSING.DEFAULT },
];

const IS_MOCK_DATA = false;

/**
 * Order Processing Dashboard Page
 * @param {*} props
 * @returns {React.Component}
 */
function OrderProcessingDashboardPage(props) {
    const history = useHistory();

    // component state
    const [loading, setLoading] = useState(0);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [activeTab, setActiveTab] = useState('1');
    const [tabStatus, setTabStatus] = useState(null);

    const [orderMeta, setOrderMeta] = useState({
        newOrders: 0,
        pickingOrders: 0,
        packagingOrders: 0,
        dispatchReadyOrders: 0,
    });
    const [ordersListData, setOrdersListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    const [searchBy] = useState({ searchBy: '', searchText: null });

    const [showDeleteModal, setShowDeleteModal] = useState({ state: false, data: null });

    useEffect(() => {
        if (IS_MOCK_DATA) {
            // setOrdersList*Data({ ...ordersListData, docs: ordersListMockData });
        }
    }, []);

    // useEffect(() => {
    //     const urlSearch = qs.parse(props.location.search);
    //     if (urlSearch && urlSearch.tab) {
    //         setActiveTab(urlSearch.tab);
    //         setTabStatus(
    //             urlSearch.tab === '1'
    //                 ? 'new'
    //                 : urlSearch.tab === '2'
    //                 ? 'picking'
    //                 : urlSearch.tab === '3'
    //                 ? 'packaging'
    //                 : 'dispatchReady'
    //         );
    //     }
    // }, [props.location]);

    useEffect(() => {
        const urlSearch = qs.parse(props.location.search);
        if (urlSearch && urlSearch.tab) {
            setActiveTab(urlSearch.tab);
            setTabStatus(
                urlSearch.tab === '1'
                    ? 'new'
                    : urlSearch.tab === '2'
                    ? 'picking'
                    : urlSearch.tab === '3'
                    ? 'packaging'
                    : 'dispatchReady'
            );
        } else {
            setTabStatus('new');
            // REPLACE URL
            history.replace({
                pathname: `${PATHS.ORDER_PROCESSING.DEFAULT}`,
                search: `?tab=1`,
            });
        }
    }, [props.location]);

    useEffect(() => {
        if (tabStatus) {
            loadOrdersListData(
                true,
                ordersListData.all,
                1,
                ordersListData.limit,
                ordersListData.filter,
                ordersListData.sort,
                ordersListData.order,
                searchBy,
                tabStatus
            );
        }
    }, [tabStatus]);

    useEffect(() => {
        loadOrdersMeta();
    }, [tabStatus]);

    const loadOrdersMeta = () => {
        getAllOrdersMetaService().then((res) => {
            const { data } = res;
            if (data) {
                setOrderMeta({ ...orderMeta, ...data });
            }
        });
    };

    const handlePageChange = (pageNo) => {
        loadOrdersListData(
            true,
            ordersListData.all,
            pageNo,
            ordersListData.limit,
            ordersListData.filter,
            ordersListData.sort,
            ordersListData.order,
            searchBy,
            tabStatus
        );
    };

    const handleFilter = (field, filterText) => {
        loadOrdersListData(
            false,
            ordersListData.all,
            1,
            ordersListData.limit,
            ordersListData.filter,
            ordersListData.sort,
            ordersListData.order,
            { searchBy: field || 'reference', searchText: filterText },
            tabStatus
        );
    };

    const loadOrdersListData = (showLoading = true, all, page, limit, filter, sort, order, searchByProp, status) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getOrdersService(all, page, limit, filter, sort, order, searchByProp, 'merchantId', status)
            .then((res) => {
                const { data } = res;
                if (data && data.docs) {
                    const docs = mapDocs(data.docs);
                    setOrdersListData({
                        ...ordersListData,
                        ...data,
                        docs,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const handlePickingTasks = () => {
        history.push({
            pathname: `${PATHS.PICKING_TASK.DEFAULT}`,
        });
    };

    const handleNewDelivery = () => {
        history.push({
            pathname: `${PATHS.DELIVERY.FORM}`,
            search: `?type=new`,
        });
    };

    const handleEditRow = (e, row) => {
        if (tabStatus === 'packaging') {
            history.push({
                pathname: `${PATHS.PACKAGING.FORM}`,
                search: `?type=edit`,
                state: { orderParentData: row, prevCompState: { activeTab, pageUrl: PATHS.PACKAGING.FORM } },
            });
        } else {
            history.push({
                pathname: `${PATHS.ORDER_PROCESSING.ORDER_FORM}`,
                search: `?type=edit`,
                state: { orderParentData: row, prevCompState: { activeTab, pageUrl: PATHS.ORDER_PROCESSING.DEFAULT } },
            });
        }
    };

    const handleViewOrder = (e, row) => {
        if (tabStatus === 'packaging') {
            history.push({
                pathname: `${PATHS.PACKAGING.FORM}`,
                search: `?type=view`,
                state: { orderParentData: row, prevCompState: { activeTab, pageUrl: PATHS.PACKAGING.FORM } },
            });
        } else {
            history.push({
                pathname: `${PATHS.ORDER_PROCESSING.ORDER_FORM}`,
                search: `?type=view`,
                state: { orderParentData: row, prevCompState: { activeTab, pageUrl: PATHS.ORDER_PROCESSING.DEFAULT } },
            });
        }
    };

    const handleOnDelete = (e, row) => {
        setShowDeleteModal({ state: true, data: row });
    };

    const handleDeleteUser = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteOrderService(data._id);
            loadOrdersListData(
                true,
                ordersListData.all,
                1,
                ordersListData.limit,
                ordersListData.filter,
                ordersListData.sort,
                ordersListData.order,
                searchBy,
                tabStatus
            );
            loadOrdersMeta();
            toast.success('Successfully Deleted');
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors && e.data.errors.userMessage) {
                toast.error(e.data.errors.userMessage);
            } else {
                toast.error('Something went wrong');
            }
        }
        setShowDeleteModal({ state: false, data: null });
        setBackdropLoading((prevState) => prevState - 1);
    };

    return (
        <>
            <div className="page-content order-processing-dashboard">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        {/* {hasAccess('NEW_ORDER', ['Edit']) && ( */}
                        {activeTab === '1' && (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                onClick={() =>
                                    history.push({
                                        pathname: `${PATHS.ORDER_PROCESSING.ORDER_FORM}`,
                                        search: `?type=new`,
                                        state: { prevCompState: { activeTab } },
                                    })
                                }
                            >
                                <i className="fas fa-cart-plus fa-lg"></i> <span className="h6">Add a new order </span>
                            </motion.div>
                        )}
                        {/* )} */}
                    </Row>

                    <br />
                    <Row style={{ justifyContent: 'space-between' }} className="ml-0 mr-3">
                        <div>
                            <span className="ml-1 font-weight-bold h6">
                                Select the order processing step from below.
                            </span>
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <Nav tabs className="nav-tabs-custom order-processing">
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '1',
                                    })}
                                    onClick={() => {
                                        history.replace({
                                            pathname: `${PATHS.ORDER_PROCESSING.DEFAULT}`,
                                            search: `?tab=1`,
                                        });
                                    }}
                                >
                                    <span className="d-none d-sm-block h5">New Orders ( {orderMeta.newOrders} )</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '2',
                                    })}
                                    onClick={() => {
                                        history.replace({
                                            pathname: `${PATHS.ORDER_PROCESSING.DEFAULT}`,
                                            search: `?tab=2`,
                                        });
                                    }}
                                >
                                    <span className="d-none d-sm-block h5">Picking ( {orderMeta.pickingOrders} )</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '3',
                                    })}
                                    onClick={() => {
                                        history.replace({
                                            pathname: `${PATHS.ORDER_PROCESSING.PACKING}`,
                                            search: `?tab=3`,
                                        });
                                    }}
                                >
                                    <span className="d-none d-sm-block h5">
                                        Packing ( {orderMeta.packagingOrders} )
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '4',
                                    })}
                                    onClick={() => {
                                        history.replace({
                                            pathname: `${PATHS.ORDER_PROCESSING.DEFAULT}`,
                                            search: `?tab=4`,
                                        });
                                    }}
                                >
                                    <span className="d-none d-sm-block h5">
                                        Dispatch Ready ( {orderMeta.dispatchReadyOrders} )
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Row>
                    <br />
                    <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div>
                            {activeTab === '1' && (
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="w3-animate-opacity"
                                >
                                    <StatCard
                                        title="TO BE PROCESSED"
                                        value={`${orderMeta.newOrders} NEW ORDERS`}
                                        icon="fas fa-shopping-cart"
                                        tooltipTitle="New orders to be processed"
                                        tooltipPlacement="right"
                                        iconSize="fa-2x"
                                    />
                                </motion.div>
                            )}
                            {activeTab === '2' && (
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="w3-animate-opacity"
                                >
                                    <StatCard
                                        title="PICKING NOW"
                                        value={`${orderMeta.pickingOrders} NEW ORDERS`}
                                        icon="fas fa-route"
                                        tooltipTitle="New orders to be processed"
                                        tooltipPlacement="right"
                                        iconSize="fa-2x"
                                    />
                                </motion.div>
                            )}
                            {activeTab === '3' && (
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="w3-animate-opacity"
                                >
                                    <StatCard
                                        title="PACKING NOW"
                                        value={`${orderMeta.packagingOrders} NEW ORDERS`}
                                        icon="fas fa-box-open"
                                        tooltipTitle="New orders to be processed"
                                        tooltipPlacement="right"
                                        iconSize="fa-2x"
                                    />
                                </motion.div>
                            )}
                        </div>

                        <div>
                            {activeTab === '2' && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md w3-animate-opacity"
                                    onClick={() => handlePickingTasks()}
                                >
                                    <i className="fas fa-warehouse fa-lg"></i> <span className="h6">Picking Tasks</span>
                                </motion.div>
                            )}
                        </div>

                        {activeTab === '4' && (
                            <div>
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md w3-animate-opacity"
                                    onClick={() => handleNewDelivery()}
                                >
                                    <i className="fas fa-warehouse fa-lg"></i> <span className="h6">New Delivery</span>
                                </motion.div>
                            </div>
                        )}
                    </Row>
                    <Row>
                        {/* {hasAccess('NEW_ORDER', ['View']) && ( */}
                        <TableCustom
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            tableData={ordersListData}
                            loading={loading}
                            handleEdit={handleEditRow}
                            handleDelete={handleOnDelete}
                            handleView={handleViewOrder}
                            isFullScreenShow
                            showStickyView
                            filters={[
                                {
                                    label: 'Reference Number',
                                    key: 'referenceNumber',
                                },
                                {
                                    label: 'Merchant Name',
                                    key: 'merchantName',
                                },
                                {
                                    label: 'From',
                                    key: 'pickupLocation',
                                },
                                {
                                    label: 'Recipient',
                                    key: 'recipientName',
                                },
                                {
                                    label: 'Order Date',
                                    key: 'placedDate',
                                },
                            ]}
                        />
                        {/* )} */}
                    </Row>
                </Container>
                <BackdropLoader show={backdropLoading} opacity={0.7} />

                <AlertModal
                    show={showDeleteModal.state}
                    onConfirm={handleDeleteUser}
                    data={showDeleteModal.data}
                    onHide={() =>
                        setShowDeleteModal({
                            state: false,
                            data: null,
                        })
                    }
                />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

OrderProcessingDashboardPage.propTypes = {
    location: PropTypes.any,
};

const HotOrderProcessingDashboardPage = hot(OrderProcessingDashboardPage);

export default connect(mapStateToProps, {})(HotOrderProcessingDashboardPage);
