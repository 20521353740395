/* eslint-disable consistent-return */
/* eslint-disable camelcase */

export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { deliveryAddress, pickupLocation, merchantId, deliveryAddressMeta, warehouseSiteId } = form;
    if (!deliveryAddress || !pickupLocation || !merchantId || !deliveryAddressMeta || !warehouseSiteId) {
        return { isFormValid, message };
    }

    isFormValid = true;
    return { isFormValid, message };
};

export const buildLocationData = (id, idMeta, data) => {
    if (id === 'deliveryAddress') {
        if (data) {
            const { city = '', address = '', state = null, postal_code = '' } = data;
            return {
                deliveryCity: city,
                deliveryState: state,
                [id]: `${address ? `${address}` : ''}`,
                [idMeta]: data,
                deliveryPostalCode: postal_code,
            };
        }
        return {
            [id]: null,
            [idMeta]: null,
            deliveryCity: null,
            deliveryState: null,
            deliveryPostalCode: null,
        };
    }
    if (id === 'pickupLocation') {
        if (data) {
            const { city = '', address = '', state = null, postal_code = '', merchantLocationId } = data;
            return {
                city,
                state,
                [id]: `${address ? `${address}` : ''}`,
                [idMeta]: data,
                postalCode: postal_code || '',
                merchantLocationId,
            };
        }
        return {
            [id]: null,
            [idMeta]: null,
            city: '',
            state: '',
            postalCode: '',
        };
    }
};
