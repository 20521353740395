import {
    CREATE_PICKING_TASK,
    DELETE_PICKING_TASK,
    REMOVE_ORDER_FROM_PICKING_TASK,
    MARK_ALL_ORDERS_COMPLETED_IN_PICKING_TASK,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Create PickingTask Service
 * @param {object} data
 * @returns {Promise}
 */
export const createPickingTaskService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_PICKING_TASK).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update PickingTask Service
 * @param {string} id
 * @returns {Promise}
 */
export const deletePickingTaskService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_PICKING_TASK).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Remove Order From PickingTask Service
 * @param {string} pickingTaskId
 * @param {string} orderId
 * @returns {Promise}
 */
export const removeOrderFromPickingTask = (pickingTaskId, orderId) => {
    try {
        const url = UrlBuilder()
            .setUrl(REMOVE_ORDER_FROM_PICKING_TASK)
            .setPathParams({ pickingTaskId, orderId })
            .build();
        return instance.patch(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Mark all Orders Completed From PickingTask Service
 * @param {object} body
 * @returns {Promise}
 */
export const markOrdersAsCompletedInPickingTaskService = (body) => {
    try {
        const url = UrlBuilder().setUrl(MARK_ALL_ORDERS_COMPLETED_IN_PICKING_TASK).build();
        return instance.post(url, body);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
