import React from 'react';
import { Spinner } from 'reactstrap';

import { SCOOTIX_DARK_LOGO_IMAGE } from 'definitions/assets';

/**
 * Loading Screen
 */
function LoadingScreen() {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                flexDirection: 'column',
            }}
        >
            <div style={{ marginBottom: -25 }}>
                <img src={SCOOTIX_DARK_LOGO_IMAGE} width={150} height={150} alt="ScootiX Logo" />
            </div>
            <Spinner color="info" size="lg" />
        </div>
    );
}

export default LoadingScreen;
