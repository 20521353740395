/* eslint-disable no-plusplus */
/* eslint-disable radix */
import * as ExcelJS from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import moment from 'moment';

// #endregion imports
import { hasWhiteSpace } from './helpers';

// TODO: check if the recent changes here impact other modules

/**
 * Export Data to Excel
 * Table -> Excel
 * @param {Object} data
 */
export const exportToExcel = async (data) => {
    const wb = new ExcelJS.Workbook();

    // Set the worksheet name
    const worksheet = wb.addWorksheet(data.worksheetName);

    const { title } = data;
    const { description } = data;
    const { period } = data;
    const { merchant } = data;
    const { generatedAt } = data;

    // Adding title, description and period
    if (title) {
        // Merge cells
        worksheet.mergeCells('A1:B1');
        worksheet.mergeCells('C1:D1');
        worksheet.mergeCells('A2:B2');

        const customTitleCell = worksheet.getCell('A1');
        const customDescriptionCell = worksheet.getCell('C1');
        const customPeriodCell = worksheet.getCell('A2');
        const merchantNameCell = worksheet.getCell('E1');
        const generatedAtCell = worksheet.getCell('F1');

        customTitleCell.value = title;
        customDescriptionCell.value = description;
        customPeriodCell.value = `Period: ${period}`;
        merchantNameCell.value = `Merchant: ${merchant}`;
        generatedAtCell.value = `Generated At: ${generatedAt}`;

        // Adding column names in the Row 4
        worksheet.getRow(4).values = data.columns.map((column) => column.label);
        worksheet.columns = data.columns.map((column) => ({ key: column.key }));
        worksheet.properties.defaultColWidth = 25;
        const row = worksheet.getRow(4);
        row.values = data.columns.map((column) => column.label);

        row.eachCell((cell) => {
            // eslint-disable-next-line no-param-reassign
            cell.font = { bold: true };
            // eslint-disable-next-line no-param-reassign
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDDDDD' } };
        });
    } else {
        // Default flow seperated from the custom flow
        worksheet.properties.defaultColWidth = 25;
        worksheet.columns = data.columns;
    }

    for (let i = 0; i < data.columns.length; i++) {
        // worksheet.getCell(`${excelColumns[i]}${1}`).fill = {
        //     type: 'pattern',
        //     pattern: 'solid',
        //     bgColor: { argb: '#00008B' },
        // };
        // worksheet.getCell(`${excelColumns[i]}${1}`).font = {
        //     color: { argb: 'FFFFFFFF' },
        // };
    }

    data.rows.map((row) => {
        worksheet.addRow(row);
        return row;
    });
    const buf = await wb.xlsx.writeBuffer();
    if (data.title) {
        saveAs(new Blob([buf]), `${data.fileName}.xlsx`);
    } else {
        saveAs(new Blob([buf]), `${data.worksheetName}.xlsx`);
    }
};

export const cleanExcelColumns = (headers = []) => {
    const filteredHeaders = headers.filter((x) => x.key !== 'addressMeta');
    return filteredHeaders;
};

export const cleanExcelDocs = (docs = []) => {
    const regExpLetters = /[a-zA-Z]/g;

    const filteredDocs = _.cloneDeep(docs);

    filteredDocs.map((x) => {
        const val = x;

        if (val.createdAt) {
            val.createdAt = `${moment(val.createdAt).format('DD-MM-YYYY hh:mm A')}`;
        }

        if (val.recipientMobileNumber) {
            val.recipientMobileNumber = parseInt(val.recipientMobileNumber);
        } else {
            val.recipientMobileNumber = 'No Contact Number';
        }

        // if (val.referenceNumber) {
        //     if (!regExpLetters.test(val.referenceNumber)) {
        //         val.referenceNumber = parseInt(val.referenceNumber);
        //     }
        // }

        if (
            val.orderReferenceNumber &&
            typeof val.orderReferenceNumber === 'string' &&
            !hasWhiteSpace(val.orderReferenceNumber)
        ) {
            if (!regExpLetters.test(val.orderReferenceNumber)) {
                val.orderReferenceNumber = parseInt(val.orderReferenceNumber);
            }
        }

        if (val.deletedAt) {
            val.deletedAt = `${moment(val.deletedAt).format('DD-MM-YYYY hh:mm A')}`;
        }

        if (val.updatedAt) {
            val.updatedAt = `${moment(val.updatedAt).format('DD-MM-YYYY hh:mm A')}`;
        }

        if (val.pickedUpAt) {
            val.pickedUpAt = `${moment(val.pickedUpAt).format('DD-MM-YYYY hh:mm A')}`;
        }

        if (val.deliveredAt) {
            val.deliveredAt = `${moment(val.deliveredAt).format('DD-MM-YYYY hh:mm A')}`;
        }

        if (val.merchantPaymentReceivedDate) {
            val.merchantPaymentReceivedDate = `${moment(val.merchantPaymentReceivedDate).format('DD-MM-YYYY hh:mm A')}`;
        }

        if (val.riderPaymentReceivedDate) {
            val.riderPaymentReceivedDate = `${moment(val.riderPaymentReceivedDate).format('DD-MM-YYYY hh:mm A')}`;
        }
        if (val.createdBy) {
            val.createdBy = val.createdBy?.fullName || 'System';
        }
        if (val.updatedBy) {
            val.updatedBy = val.updatedBy?.fullName || 'Record Not Updated';
        }
        return val;
    }); // ES5: arr.map(item => object.assign({}, item))

    return filteredDocs;
};
