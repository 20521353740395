/* eslint-disable indent */
import _ from 'lodash';
import {
    GET_DASHBOARD_API_USAGE_OVERVIEW,
    GET_DASHBOARD_API_USAGE_OVERVIEW_TIME,
    GET_DASHBOARD_API_USAGE_STATS,
    GET_DASHBOARD_LATEST_API_USAGE_STAT,
    GET_DASHBOARD_API_USAGE_TABLE_DATA,
    GET_DASHBOARD_SUCCESS_ERROR_COUNT_MERCHANT_VISE,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const getDashboardPartnerApiUsageStats = (params) => {
    const { clientId, fromDateTime, toDateTime, merchantId } = params;
    const queryParams = _.pickBy({ clientId, fromDateTime, toDateTime, merchantId });
    const url = UrlBuilder().setUrl(GET_DASHBOARD_API_USAGE_STATS).setQueryParams(queryParams).build();
    return instance.get(url);
};

export const getDashboardPartnerApiUsageDataOverview = (params) => {
    const { clientId, fromDateTime, toDateTime, merchantId } = params;
    const queryParams = _.pickBy({ clientId, fromDateTime, toDateTime, merchantId });
    const url = UrlBuilder().setUrl(GET_DASHBOARD_API_USAGE_OVERVIEW).setQueryParams(queryParams).build();
    return instance.get(url);
};

export const getDashboardPartnerApiUsageDataOverviewTime = (params) => {
    const { clientId, fromDateTime, toDateTime, merchantId } = params;
    const queryParams = _.pickBy({ clientId, fromDateTime, toDateTime, merchantId });
    const url = UrlBuilder().setUrl(GET_DASHBOARD_API_USAGE_OVERVIEW_TIME).setQueryParams(queryParams).build();
    return instance.get(url);
};

export const getDashboardPartnerApiLatestUsageStat = (params) => {
    const { clientId, merchantId } = params;
    const queryParams = _.pickBy({ clientId, merchantId });
    const url = UrlBuilder().setUrl(GET_DASHBOARD_LATEST_API_USAGE_STAT).setQueryParams(queryParams).build();
    return instance.get(url);
};

export const getDashboardSuccessErrorCountMerchantVise = (params) => {
    const { clientId, fromDateTime, toDateTime, merchantId } = params;
    const queryParams = _.pickBy({ clientId, fromDateTime, toDateTime, merchantId });
    const url = UrlBuilder().setUrl(GET_DASHBOARD_SUCCESS_ERROR_COUNT_MERCHANT_VISE).setQueryParams(queryParams).build();
    return instance.get(url);
};

export const getDashboardPartnerUsageTableData = (params) => {
    const {
        clientId,
        fromDateTime,
        toDateTime,
        merchantId,
        page,
        limit,
        sort = 'createdAt',
        order = -1,
        status,
        statusCode,
        actionPath,
        deliveryId,
        merchantOrderNo,
    } = params;
    const queryParams = _.pickBy({
        clientId,
        fromDateTime,
        toDateTime,
        merchantId,
        page,
        limit,
        sort,
        order,
        status,
        statusCode,
        actionPath,
        deliveryId,
        merchantOrderNo,
    });
    const url = UrlBuilder().setUrl(GET_DASHBOARD_API_USAGE_TABLE_DATA).setQueryParams(queryParams).build();
    return instance.get(url);
};
