/* eslint-disable indent */
import {
    DELETE_RIDER,
    GET_ALL_RIDERS,
    GET_ALL_RIDERS_BY_STATUS,
    GET_RIDERS,
    GET_RIDERS_META,
    GET_RIDER_BY_ID,
    TOGGLE_DISABLE_RIDER,
    GET_ALL_RIDERS_BY_CLIENT,
    GET_ALL_RIDERS_COORDINATES_BY_CLIENT,
    RIDER_PROFILE_CHANGE_PASSWORD,
    GET_RIDERS_BOOL_STATUS,
    RIDER_PROFILE_CHANGE_PASSWORD_NEW,
    GET_RESTORE_RIDER,
    UPDATE_RIDER_FIELDS,
    DELETE_RIDER_PERMANENT,
    GET_ALL_ACTIVE_RIDERS_BY_CLIENT,
    GET_RIDERS_LIST_DATA,
    RESTORE_RIDER_SELF_ACCOUNT_DELETION_ACCESS,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Riders Service
 * @returns {object}
 */
export const getRidersService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate,
    status
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_RIDERS}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${populate ? `&${populate}&` : ''}${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }${status ? `status=${status}` : `status=new`}`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

export const getRidersByBoolStatusService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate,
    status,
    bool
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_RIDERS_BOOL_STATUS}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${populate ? `&${populate}&` : ''}${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }${status ? `status=${status}` : `status=new`}&${bool ? `bool=${bool}` : `bool=true`}`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get All Riders
 * @returns {Promise}
 */
export const getAllRidersService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_RIDERS).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Riders By Status
 * @returns {Promise}
 */
export const getAllRidersByStatus = (status) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_RIDERS_BY_STATUS).setPathParams({ status }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Restore Rider Service
 * @returns {Promise}
 */
export const restoreRiderService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_RESTORE_RIDER).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Rider By Id
 * @returns {Promise}
 */
export const getRiderByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_RIDER_BY_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const updateRiderProfileService = (id, data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_RIDER_FIELDS).setPathParams({ id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const toggleDisableRiderService = (id) => {
    try {
        const url = UrlBuilder().setUrl(TOGGLE_DISABLE_RIDER).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Delete User
 * @param id
 */
export const deleteRiderService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_RIDER).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const deleteRiderPermanentService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_RIDER_PERMANENT).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Riders Meta
 * @returns {Promise}
 */
export const getAllRidersMetaService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_RIDERS_META).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Riders By Client
 * @returns {Promise}
 */
export const getAllRidersByClient = (clientId) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_RIDERS_BY_CLIENT).setPathParams({ clientId }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Riders Coordinates By Client
 * @returns {Promise}
 */
export const getAllRidersCoordinatesByClient = (clientId) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_RIDERS_COORDINATES_BY_CLIENT).setPathParams({ clientId }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Active Riders By Client
 * @returns {Promise}
 */
export const getAllActiveRidersByClient = (clientId) => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_ACTIVE_RIDERS_BY_CLIENT).setPathParams({ clientId }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const riderProfileChangePasswordService = (data) => {
    try {
        const url = UrlBuilder().setUrl(RIDER_PROFILE_CHANGE_PASSWORD).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const riderProfileChangeNewPasswordService = (data) => {
    try {
        const url = UrlBuilder().setUrl(RIDER_PROFILE_CHANGE_PASSWORD_NEW).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

// Send userId and password to restore user account
export const restoreRiderAccountService = (data) => {
    const url = UrlBuilder().setUrl(RESTORE_RIDER_SELF_ACCOUNT_DELETION_ACCESS).setPathParams({ id: data._id }).build();
    return instance.patch(url, data);
};

/**
 * Get Rider List Data
 * @param {object} queryParams
 * @returns {Promise}
 */

export const getRiderListData = ({
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    select,
    searchBy,
    searchByDate,
    merchantId,
    vehicleType,
    status,
}) =>
    new Promise(async function promiseCall(resolve, reject) {
        try {
            const buildQuery = {
                page,
                limit,
                sort: sort || 'createdAt',
                order,
            };

            if (select) buildQuery.select = select;

            if (searchBy?.searchBy) buildQuery.searchBy = searchBy.searchBy;
            if (searchBy?.searchText) buildQuery.searchText = searchBy.searchText;
            if (searchByDate?.searchByDate) buildQuery.searchByDate = searchByDate.searchByDate;
            if (searchByDate?.searchDate) buildQuery.searchDate = searchByDate.searchDate;
            if (searchByDate?.searchDate) buildQuery.searchDate = searchByDate.searchDate;
            if (searchByDate?.searchDate) buildQuery.searchDate = searchByDate.searchDate;

            if (merchantId) buildQuery.merchantId = merchantId;
            if (vehicleType) buildQuery.vehicleType = vehicleType;
            if (status) buildQuery.status = status;

            // console.log('buildQuery-rider', buildQuery);

            const url = UrlBuilder().setUrl(GET_RIDERS_LIST_DATA).setQueryParams(buildQuery).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
