import React from 'react';
import { Row, Col, Media } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { motion } from 'framer-motion';

// #region imports
import paths from 'routes/paths';

function NotificationItem(props) {
    const {
        title = '',
        description = '',
        createdAt = moment(),
        _id = null,
        handleReadNotification = null,
        history = null,
    } = props;

    return (
        <>
            <div to="/#" className="text-reset notification-item">
                <Media>
                    <div className="avatar-xs mr-3" style={{ alignSelf: 'center' }}>
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="ri-shopping-cart-line" />
                        </span>
                    </div>
                    <Media body>
                        <Row>
                            <Col md={9}>
                                {' '}
                                <h6 className="mt-0 mb-1">
                                    {title.length > 20 ? <>{`${title.substring(0, 30)}...`}</> : <>{title}</>}
                                </h6>
                                <div className="font-size-12 text-muted">
                                    <p className="mb-1">
                                        {description.length > 30 ? (
                                            <>{`${description.substring(0, 30)}...`}</>
                                        ) : (
                                            <>{description}</>
                                        )}
                                    </p>
                                    <p className="mb-0">
                                        <i className="mdi mdi-clock-outline" /> {moment(createdAt).fromNow()}
                                    </p>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                                    <motion.div
                                        style={{ width: 150, alignSelf: 'center' }}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        onClick={() => {
                                            if (history) {
                                                history.push({
                                                    pathname: `${paths.NOTIFICATION.DEFAULT}`,
                                                    search: `?id=${_id}`,
                                                    state: {
                                                        notification: { _id },
                                                    },
                                                });
                                            }
                                        }}
                                        className="pointer"
                                    >
                                        <i className="fas fa-eye" style={{ fontSize: 15 }} />
                                    </motion.div>
                                    <motion.div
                                        style={{ width: 150, alignSelf: 'center', marginLeft: 15 }}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        onClick={() => {
                                            if (_id) {
                                                handleReadNotification(_id);
                                            }
                                        }}
                                        className="pointer"
                                    >
                                        <i className="fas fa-check" style={{ fontSize: 15 }} />
                                    </motion.div>
                                </div>
                            </Col>
                        </Row>
                    </Media>
                </Media>
            </div>
        </>
    );
}

NotificationItem.propTypes = {
    createdAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    description: PropTypes.string,
    _id: PropTypes.string,
    handleReadNotification: PropTypes.func,
    history: PropTypes.any,
};

export default NotificationItem;
