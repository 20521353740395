import React from 'react';
import PropTypes from 'prop-types';

function Connect(props) {
    const { color = '#FFF' } = props;

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4076_221)">
                <path
                    d="M7.00006 12L12.0001 17L10.5001 18.5C10.1737 18.8371 9.78356 19.1059 9.35231 19.2907C8.92106 19.4755 8.45734 19.5727 7.98817 19.5765C7.519 19.5803 7.05376 19.4907 6.61957 19.3129C6.18537 19.1351 5.7909 18.8727 5.45913 18.5409C5.12737 18.2092 4.86495 17.8147 4.68716 17.3805C4.50937 16.9463 4.41977 16.4811 4.42358 16.0119C4.42739 15.5427 4.52453 15.079 4.70934 14.6477C4.89416 14.2165 5.16295 13.8263 5.50006 13.5L7.00006 12Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17 12.0004L12 7.00042L13.5 5.50042C13.8263 5.16332 14.2165 4.89453 14.6477 4.70971C15.079 4.5249 15.5427 4.42775 16.0119 4.42394C16.4811 4.42014 16.9463 4.50974 17.3805 4.68752C17.8147 4.86531 18.2092 5.12773 18.5409 5.4595C18.8727 5.79126 19.1351 6.18574 19.3129 6.61993C19.4907 7.05413 19.5803 7.51936 19.5765 7.98853C19.5727 8.4577 19.4755 8.92143 19.2907 9.35268C19.1059 9.78393 18.8371 10.1741 18.5 10.5004L17 12.0004Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3 21L5.5 18.5"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.5 5.5L21 3"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M10 11L8 13" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13 14L11 16" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_4076_221">
                    <rect width="24" height="24" fill={color} />
                </clipPath>
            </defs>
        </svg>
    );
}

Connect.propTypes = {
    color: PropTypes.string,
};

export default Connect;
