export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Picking Task Id',
    },
    // {
    //     key: 'reference',
    //     value: 'Reference',
    // },
    {
        key: 'merchantName',
        value: 'Merchant',
    },
    {
        key: 'pickerName',
        value: 'Picker Name',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
    {
        key: 'taskStartDate',
        value: 'Task Date',
        type: 'date',
    },
    {
        key: 'status',
        value: 'Status',
        type: 'pickingTaskStatus',
    },
];
