/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';

// #region imports
import { convertDeliveryStatusToNormalText } from 'utils/constants';
import paths from 'routes/paths';
import useMoveMarker from 'components/Maps/Markers/useMoveMarker';
import { InfoWindow, Marker } from '@react-google-maps/api';

// #endregion imports

/**
 * Rider Marker
 * @param {*} props
 * @returns
 */
function RiderMarker({ lat, lng, ...props }) {
    const { riderData = {}, setSelectedRider, riderExtendedData = { currentTasks: [] } } = props;

    const [isMarkerActive, setIsMarkerActive] = useState(false);

    const [coordinates, setDestination] = useMoveMarker([lat, lng]);

    useEffect(() => {
        setDestination([lat, lng]);
    }, [lat, lng]);

    return (
        <>
            {isMarkerActive && (
                <InfoWindow
                    position={{
                        lat: coordinates[0],
                        lng: coordinates[1],
                    }}
                    onCloseClick={() => {
                        setIsMarkerActive(false);
                    }}
                >
                    <div className="m-2 p-4" style={{ minWidth: 280 }}>
                        <Col>
                            <div style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>
                                <Avatar
                                    className="image-upload-avatar"
                                    name={riderData.fullName}
                                    size="40"
                                    round="50px"
                                />
                            </div>
                            <div style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center', marginTop: 5 }}>
                                {riderData.fullName}
                            </div>
                            <div style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>
                                {riderData.mobileNo}
                            </div>
                        </Col>
                        <br />
                        <Row style={{ justifyContent: 'space-between' }}>
                            <div style={{ color: 'grey' }}> CURRENT TASKS </div>
                        </Row>

                        {riderExtendedData &&
                            (riderExtendedData.currentTasks || []).map((val) => (
                                <Row
                                    key={val._id}
                                    style={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: 5,
                                    }}
                                >
                                    <div>{val.referenceNumber || ''}</div>
                                    <div
                                        style={{
                                            backgroundColor: '#02E9AB',
                                            borderRadius: 20,
                                            padding: 3,
                                            paddingRight: 13,
                                            paddingLeft: 13,
                                            color: '#FFF',
                                            paddingTop: 5,
                                            marginLeft: 10,
                                        }}
                                    >
                                        {val.status ? convertDeliveryStatusToNormalText(val.status) : ''}
                                    </div>
                                    <div
                                        className="pointer"
                                        style={{
                                            borderRadius: 20,
                                            padding: 3,
                                            paddingRight: 13,
                                            paddingLeft: 13,
                                        }}
                                        onClick={() => {
                                            window.open(`${paths.DELIVERY.FORM}?type=edit&id=${val._id}`);
                                        }}
                                    >
                                        <i className="fa fa-arrow-right" aria-hidden="true" />
                                    </div>
                                </Row>
                            ))}
                    </div>
                </InfoWindow>
            )}

            <div id={`marker${riderData.riderId}`}>
                <Marker
                    onMouseLeave={() => {
                        setSelectedRider({ selectedType: 'none', rider: null });
                        setIsMarkerActive(false);
                    }}
                    onClick={() => {
                        setIsMarkerActive(true);
                        setSelectedRider({ selectedType: 'marker', rider: riderData });
                    }}
                    position={{
                        lat: coordinates[0],
                        lng: coordinates[1],
                    }}
                    zIndex={1}
                    icon={{
                        url: 'https://scootix-prod-open.s3.ap-southeast-1.amazonaws.com/assets/rider_icon.svg',
                    }}
                />
            </div>
        </>
    );
}

RiderMarker.propTypes = {
    riderData: PropTypes.any,
    setSelectedRider: PropTypes.any,
    riderExtendedData: PropTypes.any,
    lng: PropTypes.any,
    lat: PropTypes.any,
    selectedRider: PropTypes.object,
};

export default RiderMarker;
