/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { BackdropLoader, CustomToast, ScootixModal, TableCustom } from 'components';

// #region importsF
import PATHS from 'routes/paths';
import { PERMISSIONS } from 'utils/constants';

// #region services
import { getStocksReceivingDeletedService } from 'services/stocks-receiving';

// #region utils
import { apiErrorHandler } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';

// #endregion imports
import { defaultTableHeaders } from './constants';
import { deleteStocksReceivingPermanentService, restoreStocksReceivingService } from '../services';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Stocks Receiving', link: PATHS.STOCKS_RECEIVING.DEFAULT },
    { title: 'Deleted Stocks Receiving', link: '#' },
];

function DeletedStocksReceivingListPage(props) {
    const { location } = props;

    const history = useHistory();

    // component state
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [stocksReceivingListData, setStocksReceivingListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    const [searchBy, setSearchBy] = useState({ searchBy: '', searchText: null });

    const [isUserRestored, setIsUserRestored] = useState(false);
    const [isUserDeletePermanent, setIsUserDeletePermanent] = useState(false);

    useEffect(() => {
        loadStocksReceivingListData(
            true,
            stocksReceivingListData.all,
            1,
            stocksReceivingListData.limit,
            stocksReceivingListData.filter,
            stocksReceivingListData.sort,
            stocksReceivingListData.order,
            searchBy,
            'deleted'
        );
    }, []);

    useEffect(() => {
        try {
            if (location && location.search) {
                if (location.search.includes('restoreid')) {
                    const regexParams = location.search.match(new RegExp('restoreid=' + '(.*)' + '&'));
                    if (regexParams && regexParams.length > 1) {
                        const checkForValidMongoDbID = new RegExp('^[0-9a-fA-F]{24}$');
                        if (checkForValidMongoDbID.test(regexParams[1])) {
                            handleOnRestore(regexParams[1]);
                            setIsUserRestored(true);
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
        try {
            if (location && location.search) {
                if (location.search.includes('deletepermenantid')) {
                    const regexParams = location.search.match(new RegExp('deletepermenantid=' + '(.*)' + '&'));
                    if (regexParams && regexParams.length > 1) {
                        const checkForValidMongoDbID = new RegExp('^[0-9a-fA-F]{24}$');
                        if (checkForValidMongoDbID.test(regexParams[1])) {
                            handleOnDeletePermanent(regexParams[1]);
                            setIsUserDeletePermanent(true);
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }, [location]);

    const loadStocksReceivingListData = (
        showLoading = true,
        all,
        page,
        limit,
        filter,
        sort,
        order,
        searchByProp,
        status
    ) => {
        if (showLoading) {
            // setLoading((prevState) => prevState + 1);
        }
        getStocksReceivingDeletedService(all, page, limit, filter, sort, order, searchByProp, null, status)
            .then((res) => {
                const { data } = res;
                setStocksReceivingListData({
                    ...stocksReceivingListData,
                    ...data,
                });
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    // setLoading((prevState) => prevState - 1);
                }
            });
    };

    const handlePageChange = (pageNo) => {
        loadStocksReceivingListData(
            true,
            stocksReceivingListData.all,
            pageNo,
            stocksReceivingListData.limit,
            stocksReceivingListData.filter,
            stocksReceivingListData.sort,
            stocksReceivingListData.order,
            searchBy,
            'deleted'
        );
    };

    const handleFilter = (field, filterText) => {
        setSearchBy({ searchBy: field || 'fullName', searchText: filterText });
        loadStocksReceivingListData(
            false,
            stocksReceivingListData.all,
            1,
            stocksReceivingListData.limit,
            stocksReceivingListData.filter,
            stocksReceivingListData.sort,
            stocksReceivingListData.order,
            { searchBy: field || 'fullName', searchText: filterText },
            'deleted'
        );
    };

    const handleOnRestore = (id) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: { _id: id },
            message: 'Are you sure you want to proceed?',
            onConfirm: (data) => handleRestore(data),
        });
    };

    const handleRestore = async (data) => {
        if (data && data._id) {
            setBackdropLoading((prevState) => prevState + 1);

            try {
                await restoreStocksReceivingService(data._id);
                history.push({
                    search: ``,
                });
                loadStocksReceivingListData(
                    true,
                    stocksReceivingListData.all,
                    stocksReceivingListData.page,
                    stocksReceivingListData.limit,
                    stocksReceivingListData.filter,
                    stocksReceivingListData.sort,
                    stocksReceivingListData.order,
                    searchBy,
                    'deleted'
                );
                toast.custom((t) => <CustomToast text="Successfully Restored" t={t} type="success" />, {
                    position: 'top-right',
                });
            } catch (e) {
                const message = apiErrorHandler(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            setBackdropLoading((prevState) => prevState - 1);
        }

        toggleScootixModal();
    };

    const handleOnDeletePermanent = (id) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: { _id: id },
            message: 'Are you sure you want to Permanently delete Stocks Receiving?',
            onConfirm: (data) => handleDeletePermanent(data),
        });
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
        if (isUserRestored) {
            history.push({
                search: ``,
            });
            setIsUserRestored(false);
        }
        if (isUserDeletePermanent) {
            history.push({
                search: ``,
            });
            setIsUserDeletePermanent(false);
        }
    };

    const handleDeletePermanent = async (data) => {
        if (data && data._id) {
            setBackdropLoading((prevState) => prevState + 1);

            try {
                await deleteStocksReceivingPermanentService(data._id);
                history.push({
                    search: ``,
                });
                loadStocksReceivingListData(
                    true,
                    stocksReceivingListData.all,
                    stocksReceivingListData.page,
                    stocksReceivingListData.limit,
                    stocksReceivingListData.filter,
                    stocksReceivingListData.sort,
                    stocksReceivingListData.order,
                    searchBy,
                    'deleted'
                );
                toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                    position: 'top-right',
                });
            } catch (e) {
                const message = apiErrorHandler(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            setBackdropLoading((prevState) => prevState - 1);
        }

        toggleScootixModal();
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <br />
                    <Row>
                        <TableCustom
                            tableData={stocksReceivingListData}
                            onFilter={handleFilter}
                            onPageChange={handlePageChange}
                            showDelete={false}
                            showEdit={false}
                            showView={false}
                            removeAction
                            showPermanentDelete={hasAccess(PERMISSIONS.STOCKS_RECEIVING, ['Delete'])}
                            showRestore={hasAccess(PERMISSIONS.STOCKS_RECEIVING, ['Add'])}
                            // filters={[
                            //     {
                            //         label: 'Merchant Name',
                            //         key: 'Name',
                            //     },
                            // ]}
                            // disabledRows={{
                            //     role: { key: 'role', value: 'superAdmin' },
                            //     _id: {
                            //         key: '_id',
                            //         value: getUser()._id,
                            //     },
                            // }}
                        />
                    </Row>
                </Container>
            </div>
            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

DeletedStocksReceivingListPage.propTypes = {
    location: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotDeletedStocksReceivingListPage = hot(DeletedStocksReceivingListPage);

export default connect(mapStateToProps, {})(HotDeletedStocksReceivingListPage);
