/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import polylineEncryption from '@mapbox/polyline';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Alert, Button, CardBody, CardHeader, Col, FormGroup, Input, Label, Modal, Row, Spinner } from 'reactstrap';
import './styles/assignRider-modal-styles.scss';

// #region components
import { CustomToast, InfoModal, Select } from 'components';
import ConfirmationModal from 'components/Modals/Route-Planning/Modules/Confirmation';
import CustomDateInput from 'components/CustomDateInput/CustomDateInput';
import CheckCircleIcon from 'components/Icons/Check-Circle';
import CrossCircleIcon from 'components/Icons/CrossCircle';

// #region services
import {
    assigningRoutPlanService,
    generatesRidersPathService,
    generatesRoutPlanService,
} from 'services/routePlanning/routePlanning.service';

// #region utils
import { apiErrorHandlerV2, getDateValue, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';

// #region constants
import { THEME_COLOR_2 } from 'theme';

// #endregion utils
import { mapOrders } from 'pages/Modules/Delivery-Management/modules/Route-Planning/utils';
import {
    buildGenerateRoutePlanData,
    buildRiderAssignRoutePlanData,
    buildQueryFilters,
    validateRiderAssign,
    buildGenerateRidersRouteData,
} from './utils';

// #endregion components
import AssignedRiderMapGrid from './components/mapGrid';

// #endregion services
import { getRiderDataByFilters } from './services';
import GenerateMapLoadingModal from '../loading-Modal';

const initialValues = {
    isOnline: true,
    plannedDeliveryDate: new Date(),
    isOffline: false,
    isShowRiderDetails: false,
    isDisplayDeliveryRoute: false,
};
/**
 * A modal used in Route planning for assigning the riders after selecting the deliveries.
 * @param {object} props
 */
function AssignedRiderModal(props) {
    const { openRiderAssignModal, toggleRiderAssignedModal } = props;

    const { data: deliveries, show } = openRiderAssignModal;

    const [formData, setFormData] = useState({ ...initialValues });
    const [riders, setRiders] = useState([]);
    const [ridersOnMap, setRidersOnMap] = useState([]);

    const [selectedDeliveriesMapData, setSelectedDeliveriesMapData] = useState([]);

    /** state that handles selected and deselected deliveries */
    const [selectedDeliveries, setSelectedDeliveries] = useState([]);
    const [deselectedDeliveries, setDeselectedDeliveries] = useState([]);

    /** start and end location data */
    const [locationMeta, setLocationMeta] = useState({});

    /** form loading state */
    const [customLoading, setCustomLoading] = useState(false);

    /** loading state for the map */
    const [mapLoading, setMapLoading] = useState(false);

    /** info modal state - after assigning deliveries */
    const [infoModalData, setInfoModalData] = useState({
        title: null,
        body: '',
        type: 'routePlanningRiderAssignStatus',
        size: 'lg',
        show: false,
        data: null,
    });

    //* * rider assign confirmation modal */
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const [optimizedDeliveryRoutePolyline, setOptimizedDeliveryRoutePolyline] = useState([]);
    const [ridersRoutesPolylines, setRidersRoutesPolylines] = useState([]);

    /** once get the data into the modal, set data to 'selectedDeliveries' array */
    useEffect(() => {
        if (deliveries) {
            const mappedOrders = mapOrders(deliveries);
            setSelectedDeliveriesMapData(mappedOrders);
            setSelectedDeliveries(
                deliveries.map((x) => ({
                    ...x,
                    label: `${x.referenceNumber} - ${x.merchantOrderNo}`,
                    value: x._id,
                    isSelected: true,
                }))
            );
        }
    }, [show]);

    /** this user effect handles the data showing on the map when selecting and deselecting them */
    useEffect(() => {
        if (deselectedDeliveries.length > 0 && deliveries?.length > selectedDeliveries.length) {
            const mappedOrders = mapOrders(selectedDeliveries);
            setSelectedDeliveriesMapData(mappedOrders);
        } else if (deliveries?.length === selectedDeliveries.length) {
            const mappedOrders = mapOrders(selectedDeliveries);
            setSelectedDeliveriesMapData(mappedOrders);
        }
    }, [deselectedDeliveries]);

    /** After loading the data into the dropdown
     *  this handles the displaying riders on the map based on the filters
     */
    useEffect(() => {
        loadOptions();
    }, [deliveries, formData.isOnline, formData.isOffline]);

    /** Trigger loadRoutePlans function when isDisplayDeliveryRoute is true
     *  this responsible to show the each riders delivery routes
     */
    useEffect(() => {
        if (riders.length > 0 && formData.isDisplayDeliveryRoute) {
            loadRidersRoutes();
        }
        if (!formData.isDisplayDeliveryRoute || riders.length === 0) {
            setRidersRoutesPolylines([]);
        }
    }, [formData.isDisplayDeliveryRoute, formData.plannedDeliveryDate, riders]);

    /** triggering the load route planing function whenever riders and selected deliveries list gets changes  */
    useEffect(() => {
        if (formData.riderId) {
            loadRoutePlans();
            // once select a rider only showing that rider on tha map
            const selectedRider = riders.find((obj) => obj._id === formData.riderId);
            setRidersOnMap([selectedRider]);
            // once user selects a rider automatically uncheck the display delivery routes checkbox, cuz there is only one rider on the map at that happens
            setFormData({
                ...formData,
                isDisplayDeliveryRoute: false,
            });
        }
        if (!formData.riderId && riders) {
            setRidersOnMap(riders);
            setOptimizedDeliveryRoutePolyline([]);
        }
    }, [formData.riderId, selectedDeliveries, deselectedDeliveries, formData.plannedDeliveryDate]);

    /** This Refers to load the options to the rider dropdown
     *  Initially its load 20, and when scroll to the end it loads another 20 options to the dropdown
     */
    const loadOptions = async () => {
        const queryFilters = buildQueryFilters(deliveries, formData);
        try {
            const { data } = await getRiderDataByFilters({ ...queryFilters });
            if (data?.docs) {
                const mappedData = data.docs.map((x) => ({ ...x, label: x.fullName || '', value: x._id }));
                setRiders(mappedData);
                setRidersOnMap(mappedData);
            }
        } catch (error) {
            const { message } = apiErrorHandlerV2(error);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    /** This Refers to load and generate the route plan to the map
     *  Initially this sends all the riderId and the deliveryIds to generate the route plan
     */
    const loadRoutePlans = async () => {
        if (selectedDeliveries.length > 0) {
            const buildData = buildGenerateRoutePlanData(selectedDeliveries, formData);
            setMapLoading(true);
            try {
                const { data } = await generatesRoutPlanService(buildData);
                if (data) {
                    const { encodedPolylinePath, startDestination, endDestination } = data;
                    const decodedPolyline = polylineEncryption.decode(encodedPolylinePath);
                    setOptimizedDeliveryRoutePolyline(decodedPolyline || '');
                    setRidersRoutesPolylines([]);
                    setLocationMeta({ startDestination, endDestination });
                } else {
                    setOptimizedDeliveryRoutePolyline([]);
                    setRidersRoutesPolylines([]);
                    setLocationMeta({});
                }
            } catch (error) {
                const { message } = apiErrorHandlerV2(error);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            setMapLoading(false);
        } else {
            setOptimizedDeliveryRoutePolyline([]);
            setRidersRoutesPolylines([]);
            setLocationMeta({});
        }
    };

    /** This Refers to load and generate the riders route plan
     *  Initially this sends all the riderIds and the deliveryIds to generate the route plan
     */
    const loadRidersRoutes = async () => {
        if (riders.length > 0) {
            const buildData = buildGenerateRidersRouteData(riders, formData);
            setMapLoading(true);
            try {
                const { data } = await generatesRidersPathService(buildData);
                const { encodedPolylinePath } = data;
                if (encodedPolylinePath.length > 0) {
                    const decodedPolylines = encodedPolylinePath.map((item) => polylineEncryption.decode(item));
                    setRidersRoutesPolylines(decodedPolylines || '');
                } else {
                    if (formData.riderId) {
                        const message = `${selectedRider.value} Doesn't Have a Route Plan on Selected date`;
                        toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                            position: 'top-right',
                        });
                    }
                    setRidersRoutesPolylines([]);
                }
            } catch (error) {
                const { message } = apiErrorHandlerV2(error);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            setMapLoading(false);
        }
    };

    /** Handle the route plan */
    const handleSubmit = async () => {
        setShowConfirmationModal(false);
        setCustomLoading(true);
        try {
            const buildData = buildRiderAssignRoutePlanData(selectedDeliveries, formData, locationMeta);
            const { data } = await assigningRoutPlanService(buildData);
            if (data) {
                if (data.statusReport) {
                    setInfoModalData({ ...infoModalData, show: true, data: data.statusReport });
                }
            }
        } catch (error) {
            const { message: exception } = apiErrorHandlerV2(error);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setCustomLoading(false);
    };

    /** selected and deselected fields handler
     *  Handling the functionality relate to the passing object between two select fields
     */
    const handleSelectChange = (event, id) => {
        if (event) {
            /**  if this is true, remove the object in selectedDelivery array and added it to the deselectedDeliveries array */
            if (id === 'selectedDeliveries') {
                setSelectedDeliveries(event);
                const removedObject = selectedDeliveries.filter((obj1) => !event.some((obj2) => obj2.id === obj1.id));
                removedObject[0].isSelected = false;
                setDeselectedDeliveries([...deselectedDeliveries, ...removedObject]);
            }
            if (id === 'deselectedDeliveries') {
                setDeselectedDeliveries(event);
                const removedObject = deselectedDeliveries.filter((obj1) => !event.some((obj2) => obj2.id === obj1.id));
                removedObject[0].isSelected = true;
                setSelectedDeliveries([...selectedDeliveries, ...removedObject]);
            }
            if (id === 'riderId') {
                setRidersOnMap([event]);
            }
        } else if (id === 'selectedDeliveries') {
            setSelectedDeliveries([]);
            /**  event = null, when removing the last item in the list, this statement handle those scenarios */
            selectedDeliveries[0].isSelected = false;
            setDeselectedDeliveries([...deselectedDeliveries, ...selectedDeliveries]);
        } else if (id === 'deselectedDeliveries') {
            setDeselectedDeliveries([]);
            deselectedDeliveries[0].isSelected = true;
            setSelectedDeliveries([...deselectedDeliveries, ...selectedDeliveries]);
        } else if (id === 'riderId') {
            setRidersOnMap(riders);
        }
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    /**  Checkbox state handler */
    const handleCheckChange = (event) => {
        const { id, checked } = event.target;
        setFormData({
            ...formData,
            [id]: checked,
        });
    };

    /**  Select date handler */
    const handleDateChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value ? new Date(value) : null,
        });
    };

    /**  Common props relate to the map grid */
    const mapGridCommonProps = {
        selectedDeliveriesMapData,
        ridersOnMap,
        setRidersOnMap,
        setFormData,
        formData,
        setDeselectedDeliveries,
        setSelectedDeliveries,
        optimizedDeliveryRoutePolyline,
        ridersRoutesPolylines,
        selectedDeliveries,
    };

    // Form Rider Selectors
    const getRiderSelect = (_id) => {
        if (_id) {
            const relevantObj = riders.find((x) => x._id === _id);
            if (relevantObj) {
                return relevantObj.fullName;
            }
        }
        return null;
    };

    /** toggle - closing the modal */
    const handleModalToggle = () => {
        // clear all data when toggling
        setFormData({ ...initialValues });
        setLocationMeta([]);
        setOptimizedDeliveryRoutePolyline([]);
        setRidersRoutesPolylines([]);
        setDeselectedDeliveries([]);
        // toggling
        toggleRiderAssignedModal();
    };

    const toggleInfoModal = () => {
        setInfoModalData({ ...infoModalData, show: false });
        window.location.reload(true);
    };

    /** Opens the scootix confirmation modal */
    const toggleAssignRiderConfirmationModal = () => {
        const { isFormValid, message } = validateRiderAssign(formData, selectedDeliveries);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }
        setShowConfirmationModal(!showConfirmationModal);
    };

    // memos
    const selectedRider = useMemo(
        () => getDefaultValueForSelect(getRiderSelect(formData.riderId)),
        [formData.riderId, riders]
    );

    return (
        <Modal
            className="assign-rider__modal modal-dialog modal-dialog-centered"
            style={{ maxWidth: '80%', width: '100%', margin: 'auto', padding: 10 }}
            isOpen={openRiderAssignModal.show}
        >
            <CardHeader
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: THEME_COLOR_2,
                    borderTopLeftRadius: '7px',
                    borderTopRightRadius: '7px',
                }}
            >
                <div className="d-flex text-white">Assign Deliveries to Rider/Driver</div>
                <div className="ml-2 cursor-pointer" onClick={handleModalToggle} role="button">
                    <CrossCircleIcon color="#FFF" />
                </div>
            </CardHeader>
            <CardBody>
                <div>
                    {/* map component */}
                    <AssignedRiderMapGrid {...mapGridCommonProps} />
                </div>
                <hr className="divider" />
                {/** select and deselect delivery Component */}
                <Row className="select-deselect__fields">
                    <Col sm={6}>
                        <FormGroup className="selected-deliveries__form-group">
                            <Label htmlFor="selectedDeliveries">Selected Deliveries</Label>
                            <Select
                                value={selectedDeliveries}
                                isMulti
                                styles={reactSelectCustomStyles}
                                onChange={(event) => {
                                    handleSelectChange(event, 'selectedDeliveries');
                                }}
                                isSearchable={false} // this will remove the search input and dropdown arrow
                                menuIsOpen={false} // this will keep the values visible without showing the dropdown
                                maxMenuHeight={150}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup className="deselected-deliveries__form-group">
                            <Label htmlFor="deselectedDeliveries">Deselected Deliveries</Label>
                            <Select
                                value={deselectedDeliveries}
                                isMulti
                                styles={reactSelectCustomStyles}
                                onChange={(event) => {
                                    handleSelectChange(event, 'deselectedDeliveries');
                                }}
                                isSearchable={false} // this will remove the search input and dropdown arrow
                                menuIsOpen={false} // this will keep the values visible without showing the dropdown
                                maxMenuHeight={150}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {/** Filter Component */}
                <Row className="d-flex mt-3">
                    <Col>
                        <FormGroup className="form-group">
                            <div className="form-check">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={handleCheckChange}
                                    checked={formData.isOnline}
                                    id="isOnline"
                                />
                                <Label className="form-check-label" htmlFor="isOnline">
                                    Show Online Riders
                                </Label>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup className="form-group">
                            <div className="form-check mb-3">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={handleCheckChange}
                                    checked={formData.isOffline}
                                    id="isOffline"
                                />
                                <Label className="form-check-label" htmlFor="isOffline">
                                    Show Offline Riders
                                </Label>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup className="form-group">
                            <div className="form-check mb-3">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={handleCheckChange}
                                    checked={formData.isShowRiderDetails}
                                    id="isShowRiderDetails"
                                />
                                <Label className="form-check-label" htmlFor="isShowRiderDetails">
                                    Display Rider Details
                                </Label>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup className="form-group">
                            <div className="form-check mb-3">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={handleCheckChange}
                                    checked={formData.isDisplayDeliveryRoute}
                                    disabled={formData.riderId}
                                    id="isDisplayDeliveryRoute"
                                />
                                <Label className="form-check-label" htmlFor="isDisplayDeliveryRoute">
                                    Display Delivery Routes
                                </Label>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col>
                        <Label className="form-check-label">Route for Delivery Date</Label>
                    </Col>
                    <Col className="mt-n2">
                        <CustomDateInput
                            onChange={handleDateChange}
                            type="date"
                            className="form-control"
                            value={getDateValue(formData.plannedDeliveryDate, 'date')}
                            min={new Date().toISOString().split('T')[0]}
                            id="plannedDeliveryDate"
                        />
                    </Col>
                </Row>
                {!formData.isOnline && !formData.isOffline && (
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <Alert color="danger">Select at least one option from Online and Offline Riders</Alert>
                        </Col>
                    </Row>
                )}

                <hr className="divider" />
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Alert color="warning">
                            Please select a Rider/Driver and a Delivery Date to Assign the Deliveries.
                        </Alert>
                    </Col>
                </Row>
                {/** Assign Rider Component */}
                <Row>
                    <Col sm={3}>
                        <FormGroup>
                            <Label htmlFor="selectedDeliveries">Select a Rider</Label>
                            <Select
                                value={selectedRider}
                                styles={reactSelectCustomStyles}
                                options={riders}
                                onChange={(event) => handleSelectChange(event, 'riderId')}
                                isClearable
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Label htmlFor="deliveryDate">Set the Delivery Date</Label>
                            <CustomDateInput
                                onChange={handleDateChange}
                                type="date"
                                className="form-control"
                                value={getDateValue(formData.deliveryDate, 'date')}
                                min={new Date().toISOString().split('T')[0]}
                                id="deliveryDate"
                                isClearable
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Button
                            style={{ borderRadius: 10 }}
                            className="scootix-btn-radius"
                            onClick={toggleAssignRiderConfirmationModal}
                        >
                            {customLoading ? (
                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                            ) : (
                                <CheckCircleIcon color="#FFF" />
                            )}
                            &nbsp;ASSIGN DELIVERIES
                        </Button>
                    </Col>
                </Row>
            </CardBody>
            {/* info model - delivery assigning details */}
            <InfoModal toggleModal={toggleInfoModal} {...infoModalData} />
            {/* Assigning Rider confirmation modal */}
            <ConfirmationModal
                show={showConfirmationModal}
                toggleModal={toggleAssignRiderConfirmationModal}
                onConfirm={handleSubmit}
                title="Confirm Assigning Deliveries"
                text="Assign selected deliveries to the Rider/Driver?"
            />
            {/* Loading Modal */}
            <GenerateMapLoadingModal loading={mapLoading} />
        </Modal>
    );
}

AssignedRiderModal.propTypes = {
    openRiderAssignModal: PropTypes.object.isRequired,
    toggleRiderAssignedModal: PropTypes.func.isRequired,
};

export default AssignedRiderModal;
