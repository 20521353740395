import { GET_CUSTOMER_BY_MOBILE_NO } from 'utils/apiEndpoints';
import { cleanMobileNo } from 'utils/helpers';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Customer By Mobile No
 * @returns {Promise}
 */
export const getCustomerByMobileNo = (mobileNo) => {
    try {
        const url = UrlBuilder()
            .setUrl(GET_CUSTOMER_BY_MOBILE_NO)
            .setPathParams({ mobileNo: cleanMobileNo(mobileNo) })
            .build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
