/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';

// #region other imports
import { TRANSACTION_REFERENCE } from 'constants/enums';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';

/**
 * Modal for Transaction details
 */
function TransactionRiderModel(props) {
    const {
        show,
        handleToggle,
        transactionId,
        name,
        profileId,
        transactionReference,
        newSettings,
        previousSettings,
        newUserDetails,
        previousData,
    } = props;

    const {
        RIDER_SIGN_UP,
        RIDER_VERIFIED,
        NEW_RIDER_COMMISSION_SETTINGS_ADDED,
        RIDER_COMMISSION_SETTINGS_UPDATED,
        RIDER_PROFILE_UPDATED_CONSOLE,
    } = TRANSACTION_REFERENCE.RIDER_MANAGEMENT;

    return (
        <DeveloperModal header={`Transaction data for ${transactionId}`} show={show} handleToggle={handleToggle}>
            <Container>
                <Card style={{ height: '200px', overflowY: 'scroll', overflowX: 'hidden', boxShadow: 'none' }}>
                    <Row className="mb-2">
                        <Col>
                            {transactionReference === RIDER_SIGN_UP &&
                                transactionReference ===
                                    RIDER_VERIFIED(
                                        <>
                                            <h6>{name}</h6>
                                            <h6>{profileId}</h6>
                                        </>
                                    )}
                            {transactionReference !== RIDER_SIGN_UP &&
                                transactionReference !== RIDER_VERIFIED &&
                                transactionReference !== RIDER_PROFILE_UPDATED_CONSOLE && <h6>{name}</h6>}
                            {transactionReference === NEW_RIDER_COMMISSION_SETTINGS_ADDED && (
                                <>
                                    <h5>New Rider Commission Settings</h5>
                                    <br />
                                    <h6>{name}</h6>
                                    <h6>Delivery Type: {newSettings.deliveryType}</h6>
                                    <h6>Commission Method: {newSettings.riderCommissionMethod}</h6>
                                    <h6>Fixed Commission Rate: {newSettings.fixedRiderCommissionRate}</h6>
                                    <h6>Commission Percentage: {newSettings.riderCommissionPercentage}</h6>
                                    <h6>Base Mileage (KM): {newSettings.baseMileage}</h6>
                                    <h6>Base Mileage Rate: {newSettings.baseMileageRate}</h6>
                                    <h6>Slab 1 limit (KM): {newSettings.additionalMileageSlab1}</h6>
                                    <h6>Slab 1 limit in KM: {newSettings.additionalMileageSlab1Limit}</h6>
                                    <h6>Rate per Mileage Slab1: {newSettings.ratePerAdditionalMileageSlab1}</h6>
                                    <h6>Slab 2 in KM: {newSettings.additionalMileageSlab2}</h6>
                                    <h6>Rate per Mileage Slab2: {newSettings.ratePerAdditionalMileage2}</h6>
                                </>
                            )}
                            {transactionReference === RIDER_COMMISSION_SETTINGS_UPDATED && (
                                <>
                                    <h5>Previous Settings</h5>
                                    <br />
                                    <h6>{name}</h6>
                                    <h6>Delivery Type: {previousSettings.deliveryType}</h6>
                                    <h6>Commission Method: {previousSettings.riderCommissionMethod}</h6>
                                    <h6>Fixed Commission Rate: {previousSettings.fixedRiderCommissionRate}</h6>
                                    <h6>Commission Percentage: {previousSettings.riderCommissionPercentage}</h6>
                                    <h6>Base Mileage (KM): {previousSettings.baseMileage}</h6>
                                    <h6>Base Mileage Rate: {previousSettings.baseMileageRate}</h6>
                                    <h6>Slab 1 limit (KM): {previousSettings.additionalMileageSlab1}</h6>
                                    <h6>Slab 1 limit in KM: {previousSettings.additionalMileageSlab1Limit}</h6>
                                    <h6>Rate per Mileage Slab1: {previousSettings.ratePerAdditionalMileageSlab1}</h6>
                                    <h6>Slab 2 in KM: {previousSettings.additionalMileageSlab2}</h6>
                                    <h6>Rate per Mileage Slab2: {previousSettings.ratePerAdditionalMileage2}</h6>
                                </>
                            )}
                            {transactionReference === RIDER_PROFILE_UPDATED_CONSOLE && (
                                <>
                                    <h5>Previous User Details</h5>
                                    <br />
                                    <h6>{name}</h6>
                                    <h6>First name: {previousData.firstName}</h6>
                                    <h6>Last name: {previousData.lastName}</h6>
                                    <h6>Gender: {previousData.gender}</h6>
                                    <h6>Address: {previousData.address}</h6>
                                    <h6>Department: {previousData.department}</h6>
                                    <h6>Country: {previousData.country}</h6>
                                    <h6>Email: {previousData.email}</h6>
                                    <h6>Mobile No: {previousData.mobileNo}</h6>
                                    <h6>Office No: {previousData.officeNo}</h6>
                                    <h6>Ciy: {previousData.city}</h6>
                                    <h6>Postal Code: {previousData.postalCode}</h6>
                                    <h6>SOCSO Number: {previousData.socsoNo}</h6>
                                    <h6>Vehicle No: {previousData.vehicleNo}</h6>
                                    <h6>Vehicle Name: {previousData.vehicleName}</h6>
                                    <h6>Vehicle Type: {previousData.vehicleType}</h6>
                                    <h6>Bank Account Number: {previousData.bankAccountNo}</h6>
                                    <h6>Bank: {previousData.bank}</h6>
                                    <h6>Emergency Contact Number: {previousData.emergencyContactNumber}</h6>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            {transactionReference === RIDER_COMMISSION_SETTINGS_UPDATED && (
                                <>
                                    <h5>New Settings</h5>
                                    <br />
                                    <h6>Delivery Type: {newSettings.deliveryType}</h6>
                                    <h6>Commission Method: {newSettings.riderCommissionMethod}</h6>
                                    <h6>Fixed Commission Rate: {newSettings.fixedRiderCommissionRate}</h6>
                                    <h6>Commission Percentage: {newSettings.riderCommissionPercentage}</h6>
                                    <h6>Base Mileage (KM): {newSettings.baseMileage}</h6>
                                    <h6>Base Mileage Rate: {newSettings.baseMileageRate}</h6>
                                    <h6>Slab 1 limit (KM): {newSettings.additionalMileageSlab1}</h6>
                                    <h6>Slab 1 limit in KM: {newSettings.additionalMileageSlab1Limit}</h6>
                                    <h6>Rate per Mileage Slab1: {newSettings.ratePerAdditionalMileageSlab1}</h6>
                                    <h6>Slab 2 in KM: {newSettings.additionalMileageSlab2}</h6>
                                    <h6>Rate per Mileage Slab2: {newSettings.ratePerAdditionalMileage2}</h6>
                                </>
                            )}

                            {transactionReference === RIDER_PROFILE_UPDATED_CONSOLE && (
                                <>
                                    <h5>New User Details</h5>
                                    <br />
                                    <h6>{name}</h6>
                                    <h6>First name: {newUserDetails.firstName}</h6>
                                    <h6>Last name: {newUserDetails.lastName}</h6>
                                    <h6>Gender: {newUserDetails.gender}</h6>
                                    <h6>Address: {newUserDetails.address}</h6>
                                    <h6>Department: {newUserDetails.department}</h6>
                                    <h6>Country: {newUserDetails.country}</h6>
                                    <h6>Email: {newUserDetails.email}</h6>
                                    <h6>Mobile No: {newUserDetails.mobileNo}</h6>
                                    <h6>Office No: {newUserDetails.officeNo}</h6>
                                    <h6>Ciy: {newUserDetails.city}</h6>
                                    <h6>Postal Code: {newUserDetails.postalCode}</h6>
                                    <h6>SOCSO Number: {newUserDetails.socsoNo}</h6>
                                    <h6>Vehicle No: {newUserDetails.vehicleNo}</h6>
                                    <h6>Vehicle Name: {newUserDetails.vehicleName}</h6>
                                    <h6>Vehicle Type: {newUserDetails.vehicleType}</h6>
                                    <h6>Bank Account Number: {newUserDetails.bankAccountNo}</h6>
                                    <h6>Bank: {newUserDetails.bank}</h6>
                                    <h6>Emergency Contact Number: {newUserDetails.emergencyContactNumber}</h6>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card>
            </Container>
        </DeveloperModal>
    );
}

TransactionRiderModel.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    transactionId: PropTypes.string.isRequired,
    transactionReference: PropTypes.string,
    name: PropTypes.string.isRequired,
    profileId: PropTypes.string,
    newUserDetails: PropTypes.any,
    previousData: PropTypes.any,
    newSettings: PropTypes.any,
    previousSettings: PropTypes.any,
};

export default TransactionRiderModel;
