/* eslint-disable camelcase */
export const validateForm = (formData, attachmentsObj) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { selectReason, confirmation, isPickUpStarted, isPickedUp, isDeliveryStarted } = formData;

    if (!isPickUpStarted) {
        return {
            isFormValid,
            message:
                'Delivery started to pick up at not marked for this delivery. Please select the status date and time to complete the delivery',
        };
    }
    if (!isPickedUp) {
        return {
            isFormValid,
            message:
                'Delivery Picked At not marked for this delivery. Please select the status date and time to complete the delivery.',
        };
    }
    if (!isDeliveryStarted) {
        return {
            isFormValid,
            message:
                'Started the Delivery At not marked for this delivery. Please select the status date and time to complete the delivery.',
        };
    }

    if (!selectReason) {
        return { isFormValid, message: 'Please select a reason to complete before continue' };
    }
    if (!attachmentsObj.podImageUrl.data) {
        return { isFormValid, message: 'Please upload the proof of delivery before submit' };
    }
    if (!confirmation || confirmation.toLowerCase() !== 'confirm') {
        return { isFormValid, message: 'Please confirm by typing confirm before submit' };
    }

    isFormValid = true;
    return { isFormValid, message };
};

export const confirmValidateForm = (formData, type) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { confirmation, newDeliveryType, task, isAssignedToRider, riderId, routePlanningId } = formData;

    if (task === '') {
        return { isFormValid, message: 'Please Select task before continue' };
    }

    if (isAssignedToRider && !riderId) {
        return { isFormValid, message: 'Select Rider To continue' };
    }

    if (newDeliveryType === '') {
        return { isFormValid, message: 'Please Select delivery type before continue' };
    }
    if (!confirmation || confirmation.toLowerCase() !== 'confirm') {
        return { isFormValid, message: 'Please confirm by typing confirm before submit' };
    }

    if (type === 'riderUnAssign' && routePlanningId) {
        return { isFormValid, message: 'Delivery linked to route planning, rider cannot be unassigned' };
    }

    isFormValid = true;
    return { isFormValid, message };
};

export const buildLocationDataForMoreOptions = (id, idMeta, data) => {
    if (id === 'pickupLocation') {
        if (data) {
            const { city = '', address = '', state = null, postal_code = '' } = data;
            return {
                pickupLocationValues: {
                    pickupLocationCity: city,
                    pickupLocationState: state,
                    [id]: `${address ? `${address}` : ''}`,
                    [idMeta]: data,
                    pickupLocationPostalCode: postal_code,
                },
            };
        }
        return {
            pickupLocationValues: {
                [id]: null,
                [idMeta]: null,
                pickupLocationCity: null,
                pickupLocationState: null,
                pickupLocationPostalCode: null,
            },
        };
    }
    if (id === 'deliveryAddress') {
        if (data) {
            const { city = '', address = '', state = null, postal_code = '' } = data;
            return {
                deliveryAddressValues: {
                    city,
                    state,
                    [id]: `${address ? `${address}` : ''}`,
                    [idMeta]: data,
                    postalCode: postal_code || '',
                },
            };
        }
        return {
            deliveryAddressValues: {
                [id]: null,
                [idMeta]: null,
                city: '',
                state: '',
                postalCode: '',
            },
        };
    }
};

export const cancelDeliveryValidateForm = (formData) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { confirmation, newDeliveryType, isAssignedToRider, riderId, reason } = formData;

    if (reason === '' || !reason) {
        return { isFormValid, message: 'Please Select Reason before continue' };
    }

    if (isAssignedToRider && !riderId) {
        return { isFormValid, message: 'Select Rider To continue' };
    }

    if (newDeliveryType === '') {
        return { isFormValid, message: 'Please Select delivery type before continue' };
    }
    if (!confirmation || confirmation.toLowerCase() !== 'confirm') {
        return { isFormValid, message: 'Please confirm by typing confirm before submit' };
    }

    isFormValid = true;
    return { isFormValid, message };
};
