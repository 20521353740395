/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import './styles.css';

// #region assets | components
import { TableCustom } from 'components';

// #endregion imports
import { clone } from 'lodash';
import { batchPrintFormDeliveryListInitialValues } from '../../constant';
import { UPDATE_BATCH_PRINT_DELIVERY_DATA, UPDATE_SELECTED_DELIVERIES } from '../../actionTypes';

function SelectDeliveryList(props) {
    const { BatchPrintForm, formData } = props;

    const dispatch = useDispatch();

    const [deliveriesData, setDeliveriesData] = useState(batchPrintFormDeliveryListInitialValues);

    useEffect(() => {
        if (BatchPrintForm?.deliveryTableData) {
            setDeliveriesData(BatchPrintForm.deliveryTableData);
        }
    }, [BatchPrintForm?.deliveryTableData]);

    /**
     * Handle On Select All
     * @param {array} allRecords
     */
    const handleOnSelectAll = (allRecords, checked) => {
        if (BatchPrintForm?.selectedDeliveries && allRecords && deliveriesData.docs) {
            const { docs } = deliveriesData;
            setDeliveriesData((prevState) => ({
                ...prevState,
                docs: docs.map((x) => ({ ...x, isSelected: !!checked })),
            }));
        }
    };

    /**
     * Handle On Select
     * @param {object} record
     */
    const handleOnSelect = (record, checked) => {
        if (BatchPrintForm?.selectedDeliveries && record && deliveriesData.docs) {
            const { docs } = deliveriesData;
            const isExist = BatchPrintForm.selectedDeliveries.find((obj) => obj._id === record._id);
            if (!isExist) {
                // If Checked then add to selected and remove from table
                if (checked) {
                    const deliveryRecords = clone(docs);
                    const itemIndex = deliveryRecords.findIndex((x) => x._id === record._id);
                    deliveryRecords[itemIndex] = { ...record, isSelected: true };
                    setDeliveriesData((prevState) => ({ ...prevState, docs: deliveryRecords }));
                }
                // If Unchecked add to table and remove from selected
                else {
                    const deliveryRecords = clone(docs);
                    const itemIndex = deliveryRecords.findIndex((x) => x._id === record._id);
                    deliveryRecords[itemIndex] = { ...record, isSelected: false };
                    setDeliveriesData((prevState) => ({ ...prevState, docs: deliveryRecords }));
                }
            }
        }
    };

    const handleFilter = (searchBy, value) => {
        if (BatchPrintForm?.deliveryTableData && value && value !== '') {
            const { docs } = BatchPrintForm?.deliveryTableData;
            const filteredDocs = docs.filter(
                (obj) => obj.referenceNumber.toLowerCase().indexOf(value.toLowerCase()) !== -1
            );
            setDeliveriesData((prevState) => ({ ...prevState, docs: filteredDocs }));
        } else if (BatchPrintForm?.deliveryTableData) {
            const { docs } = BatchPrintForm?.deliveryTableData;
            setDeliveriesData((prevState) => ({ ...prevState, docs }));
        }
    };

    const handleAddSelectedToPrint = () => {
        const filterSelectedDeliveries = deliveriesData.docs.filter((x) => x.isSelected);
        if (filterSelectedDeliveries.length === 0) {
            return;
        }

        const updatedSelectedDeliveries = deliveriesData.docs.filter(
            (objA) => !filterSelectedDeliveries.find((objB) => objA._id === objB._id)
        );

        dispatch({
            type: UPDATE_SELECTED_DELIVERIES,
            payload: [...(BatchPrintForm.selectedDeliveries || []), ...filterSelectedDeliveries],
        });
        dispatch({
            type: UPDATE_BATCH_PRINT_DELIVERY_DATA,
            payload: { ...deliveriesData, docs: updatedSelectedDeliveries, updatedAt: new Date() },
        });
    };

    const handleAddAllSelectedToPrint = () => {
        const filterSelectedDeliveries = deliveriesData.docs.filter((x) => !x.isSelected || x.isSelected);
        if (filterSelectedDeliveries.length === 0) {
            return;
        }
        dispatch({
            type: UPDATE_SELECTED_DELIVERIES,
            payload: [...(BatchPrintForm.selectedDeliveries || []), ...filterSelectedDeliveries],
        });
        dispatch({
            type: UPDATE_BATCH_PRINT_DELIVERY_DATA,
            payload: { ...deliveriesData, docs: [], updatedAt: new Date() },
        });
        window.scrollTo({
            top: 1400,
            behavior: 'smooth',
        });
    };

    return (
        <Row>
            <TableCustom
                tableData={deliveriesData}
                showPagination={false}
                cardBorderRadius={10}
                tableHeaderType="BATCH_PRINT_SELECT_TO_PRINT"
                isSelectable={!formData.isFinalized}
                isFixedHeight
                onSelectAll={handleOnSelectAll}
                onSelect={handleOnSelect}
                onFilter={handleFilter}
                headerHandlers={{
                    onAddSelectedToPrint: handleAddSelectedToPrint,
                    onAddAllToPrint: handleAddAllSelectedToPrint,
                }}
                removeId={false}
                removeAction
                showHeaderHandlers={!formData.isFinalized}
                isResponsive={false}
            />
        </Row>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});
SelectDeliveryList.propTypes = {
    BatchPrintForm: PropTypes.object,
    formData: PropTypes.object,
};

export default connect(mapStateToProps)(SelectDeliveryList);
