/* eslint-disable indent */
import {
    GET_MERCHANTS,
    GET_ONE_MERCHANT,
    GET_MERCHANTS_ORDERS,
    GET_ALL_MERCHANTS,
    GET_ALL_MERCHANT_LOCATIONS_VISITING_MERCHANT,
    GET_ALL_MERCHANTS_META,
    GET_ALL_MERCHANTS_BY_CLIENT_ID,
    GET_MERCHANTS_DELETED,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Merchants Service
 * @returns {object}
 */
export const getMerchantsService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_MERCHANTS}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Merchants Deleted Service
 * @returns {object}
 */
export const getMerchantsDeletedService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_MERCHANTS_DELETED}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Merchants By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getMerchantByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ONE_MERCHANT).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Merchant location By visiting merchant id Service
 * @param {string} id
 * @returns
 */

export const getMerchantLocationsByVisitingMerchantIdService = (id) => {
    const url = UrlBuilder().setUrl(GET_ALL_MERCHANT_LOCATIONS_VISITING_MERCHANT).setPathParams({ id }).build();
    return instance.get(url);
};

/**
 * Get All Merchants
 * @returns {Promise}
 */
export const getAllMerchantsService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_MERCHANTS).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Merchants Meta
 * @returns {Promise}
 */
export const getAllMerchantsMetaService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_MERCHANTS_META).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Merchant's Orders By Id Service
 * @returns {Promise}
 */
export const getMerchantsOrdersByIdService = (id) => {
    try {
        const url = UrlBuilder()
            .setUrl(GET_MERCHANTS_ORDERS)
            .setPathParams({
                merchantId: id,
            })
            .build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Merchants By Client Id Service
 * @returns {Promise}
 */
export const getMerchantsByClientIdService = (id) => {
    try {
        const url = UrlBuilder()
            .setUrl(GET_ALL_MERCHANTS_BY_CLIENT_ID)
            .setPathParams({
                clientId: id,
            })
            .build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
