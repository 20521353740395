import React from 'react';

function MoreOption() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <g
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                clipPath="url(#clip0_2932_458)"
            >
                <path d="M12 21a9 9 0 100-18 9 9 0 000 18zM8 12v.01M12 12v.01M16 12v.01"></path>
            </g>
            <defs>
                <clipPath id="clip0_2932_458">
                    <path fill="#fff" d="M0 0H24V24H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default MoreOption;
