/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { clone } from 'lodash';
import './styles.css';

// #region assets | components
import { ScootixModal, TableCustom } from 'components';

// #endregion imports
import { batchPrintFormDeliveryListInitialValues } from '../../constant';
import { UPDATE_BATCH_PRINT_DELIVERY_DATA, UPDATE_SELECTED_DELIVERIES } from '../../actionTypes';

function SelectedDeliveryList(props) {
    const { BatchPrintForm } = props;

    const dispatch = useDispatch();

    const [deliveriesData, setDeliveriesData] = useState(batchPrintFormDeliveryListInitialValues);

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Yes',
        cancelMessage: 'No',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    useEffect(() => {
        if (BatchPrintForm?.deliveryTableData) {
            setDeliveriesData({
                ...batchPrintFormDeliveryListInitialValues,
                docs: BatchPrintForm?.selectedDeliveries
                    ? BatchPrintForm?.selectedDeliveries.map((x) => ({ ...x, isSelected: false }))
                    : [],
            });
        }
    }, [BatchPrintForm?.deliveryTableData]);

    /**
     * Handle On Select All
     * @param {array} allRecords
     */
    const handleOnSelectAll = (allRecords, checked) => {
        if (BatchPrintForm?.selectedDeliveries && allRecords && deliveriesData.docs) {
            const { docs } = deliveriesData;
            setDeliveriesData((prevState) => ({
                ...prevState,
                docs: docs.map((x) => ({ ...x, isSelected: !!checked })),
            }));
        }
    };

    /**
     * Handle On Select
     * @param {object} record
     */
    const handleOnSelect = (record, checked) => {
        if (BatchPrintForm?.selectedDeliveries && record && deliveriesData.docs) {
            const { docs } = deliveriesData;
            const isExist = BatchPrintForm.deliveryTableData.docs.find((obj) => obj._id === record._id);
            if (!isExist) {
                // If Checked then add to selected and remove from table
                if (checked) {
                    const deliveryRecords = clone(docs);
                    const itemIndex = deliveryRecords.findIndex((x) => x._id === record._id);
                    deliveryRecords[itemIndex] = { ...record, isSelected: true };
                    setDeliveriesData((prevState) => ({ ...prevState, docs: deliveryRecords }));
                }
                // If Unchecked add to table and remove from selected
                else {
                    const deliveryRecords = clone(docs);
                    const itemIndex = deliveryRecords.findIndex((x) => x._id === record._id);
                    deliveryRecords[itemIndex] = { ...record, isSelected: false };
                    setDeliveriesData((prevState) => ({ ...prevState, docs: deliveryRecords }));
                }
            }
        }
    };

    const handleFilter = (searchBy, value) => {
        if (BatchPrintForm?.selectedDeliveries && value && value !== '') {
            const docs = BatchPrintForm.selectedDeliveries;
            const filteredDocs = docs.filter(
                (obj) => obj.referenceNumber.toLowerCase().indexOf(value.toLowerCase()) !== -1
            );
            setDeliveriesData((prevState) => ({ ...prevState, docs: filteredDocs }));
        } else if (BatchPrintForm?.selectedDeliveries) {
            const docs = BatchPrintForm.selectedDeliveries;
            setDeliveriesData((prevState) => ({ ...prevState, docs }));
        }
    };

    const handleRemoveSelectedToPrint = () => {
        // deliveries that are selected
        const filterSelectedDeliveries = deliveriesData.docs.filter((x) => x.isSelected);
        if (filterSelectedDeliveries.length === 0) {
            return;
        }

        // remove records from existing deliveries
        const updatedRemovedDeliveries = deliveriesData.docs.filter(
            (objA) => !filterSelectedDeliveries.find((objB) => objA._id === objB._id)
        );

        dispatch({
            type: UPDATE_BATCH_PRINT_DELIVERY_DATA,
            payload: {
                ...deliveriesData,
                docs: [
                    ...(BatchPrintForm?.deliveryTableData?.docs || []),
                    ...filterSelectedDeliveries.map((val) => ({ ...val, isSelected: false })),
                ],
                updatedAt: new Date(),
            },
        });
        dispatch({
            type: UPDATE_SELECTED_DELIVERIES,
            payload: updatedRemovedDeliveries,
        });
    };

    // Prompt to confirm before remove all data
    const handleRemoveAllPrompt = () => {
        if (BatchPrintForm?.selectedDeliveries.length > 0) {
            setScootixModalState({
                ...scootixModalState,
                show: true,
                message: 'Are you sure? You want to Remove All',
                onConfirm: () => handleRemoveAllSelectedToPrint(),
            });
        }
    };

    const handleRemoveAllSelectedToPrint = () => {
        dispatch({
            type: UPDATE_BATCH_PRINT_DELIVERY_DATA,
            payload: {
                ...deliveriesData,
                docs: [
                    ...(BatchPrintForm?.deliveryTableData?.docs || []),
                    ...deliveriesData.docs.map((val) => ({ ...val, isSelected: false })),
                ],
                updatedAt: new Date(),
            },
        });
        dispatch({
            type: UPDATE_SELECTED_DELIVERIES,
            payload: [],
        });
        setScootixModalState({ ...scootixModalState, show: null });

        window.scrollTo({
            top: 850,
            behavior: 'smooth',
        });
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    return (
        <>
            <div className="selected_item_print_table_footer_wrap">
                <div className="ml-n2">
                    <TableCustom
                        tableData={deliveriesData}
                        showPagination={false}
                        cardBorderRadius={10}
                        tableHeaderType="BATCH_PRINT_SELECTED_ITEM"
                        isSelectable
                        isFixedHeight
                        onSelectAll={handleOnSelectAll}
                        onSelect={handleOnSelect}
                        onFilter={handleFilter}
                        headerHandlers={{
                            onRemoveSelected: handleRemoveSelectedToPrint,
                            onRemoveAllSelected: handleRemoveAllPrompt,
                        }}
                        removeId={false}
                        removeAction
                        isResponsive={false}
                    />
                </div>
                <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});
SelectedDeliveryList.propTypes = {
    BatchPrintForm: PropTypes.object,
};

export default connect(mapStateToProps)(SelectedDeliveryList);
