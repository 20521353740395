import _ from 'lodash';
import {
    GENERATE_EARNINGS_REPORT,
    GENERATE_REPORT,
    GET_DELIVERIES_BY_BILLING_RECORD_ID,
    GET_RIDER_COMMISSION_RECORD_BY_BILLING_RECORD_ID,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const generateReport = (data, filters = {}) => {
    const { page, limit, sort, order = -1 } = data;
    const queryParams = _.pickBy({
        page,
        limit,
        sort: sort ? Object.keys(sort)[0] : 'createdAt',
        order,
    });
    try {
        const url = UrlBuilder()
            .setUrl(GENERATE_REPORT)
            .setQueryParams({ ...queryParams })
            .build();
        return instance.post(url, filters);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const generateEarningsReport = (data) => {
    try {
        const url = UrlBuilder().setUrl(GENERATE_EARNINGS_REPORT).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export const getDeliveriesByBillingRecordId = (id) => {
    const url = UrlBuilder().setUrl(GET_DELIVERIES_BY_BILLING_RECORD_ID).setPathParams({ id }).build();
    return instance.get(url);
};

/**
 * Get rider commission related data by billing record id
 * @param {string} id - Billing record Id
 * @returns billing record data related to rider commission
 */
export const getRiderCommissionRecordByBillingRecordId = (id) => {
    const url = UrlBuilder().setUrl(GET_RIDER_COMMISSION_RECORD_BY_BILLING_RECORD_ID).setPathParams({ id }).build();
    return instance.get(url);
};
