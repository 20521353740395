export const validateForm = (formData) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { type, note } = formData;

    if (!type) {
        return { isFormValid, message: 'Please select a attachment type to complete before continue' };
    }
    if (!note) {
        return { isFormValid, message: 'Please type a note before submitting' };
    }

    isFormValid = true;
    return { isFormValid, message };
};

export const validateAddImageForm = (formData, attachmentsObj) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { type } = formData;

    if (!type) {
        return { isFormValid, message: 'Please select a attachment type to complete before continue' };
    }
    if (!attachmentsObj.attachmentImageUrl.data) {
        return { isFormValid, message: 'Please upload the attachment of delivery before submit' };
    }
    isFormValid = true;
    return { isFormValid, message };
};
