/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row, NavLink, NavItem, Nav } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import * as qs from 'query-string';
import toast from 'react-hot-toast';

// #region assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { AlertModal, CustomToast, StatCard, TableCustom, TooltipDefault } from 'components';
import PATHS from 'routes/paths';
import TrackRidersModal from 'components/Modals/Track-Riders';

// #region services
import { getAllRidersMetaService, deleteRiderService, getRidersByBoolStatusService } from 'services/rider.service';
import { apiErrorHandlerV2, pad } from 'utils/helpers';
import { getUser, hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import { defaultSearchFilters, defaultTableHeaders } from '../../constants';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Rider / Driver Management', link: PATHS.RIDER_PROFILE_MANAGEMENT.LIST_VIEW },
    { title: 'Profile', link: '#' },
];

function RiderProfileManagementPage(props) {
    const { socket, location } = props;

    const history = useHistory();

    // component state
    const [loading, setLoading] = useState(0);

    const [activeTab, setActiveTab] = useState('1');

    const [showLiveLocationModal, setShowLiveLocationModal] = useState(false);

    const [ridersMeta, setRidersMeta] = useState({
        onlineRiders: 0,
        offlineRiders: 0,
        activeRiders: 0,
        inActiveRiders: 0,
        notApprovedRiders: 0,
        unVerifiedRiders: 0,
        requestedForAccountDeletionRiders: 0,
    });

    const [ridersListData, setRidersListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    // const [searchBy, setSearchBy] = useState({ searchBy: '', searchText: null });
    const [urlParamsSearch, setUrlParamsSearch] = useState(null);
    const [searchParams, setSearchParams] = useState(null);

    const [showDeleteModal, setShowDeleteModal] = useState({ state: false, data: null });

    useEffect(() => {
        const urlSearch = qs.parse(location.search);
        if (urlSearch && urlSearch.tab) {
            const { tab, status, page, searchBy, searchText, sort, order, bool } = urlSearch;
            setActiveTab(tab);
            setUrlParamsSearch({ tab, status, page, searchBy, searchText, sort, order, bool });
            setSearchParams({ searchBy, searchText, order, sort });
        } else {
            replaceUrlParamsHandler({ tab: '1', status: 'all', page: '1' });
        }
    }, [location.search]);

    useEffect(() => {
        if (urlParamsSearch) {
            const { searchBy, searchText, page, sort, order, status, bool } = urlParamsSearch;
            const searchByProp = { searchBy, searchText };
            loadRidersListData(
                true,
                ridersListData.all,
                page,
                ridersListData.limit,
                ridersListData.filter,
                sort,
                order,
                searchByProp,
                status,
                bool
            );
            loadRiderMeta();
        }
    }, [urlParamsSearch]);

    const reloadComponent = () => {
        const { sort, order, tab, bool, searchBy, searchText } = urlParamsSearch;
        const searchParams = { searchBy, searchText };

        loadRidersListData(
            true,
            ridersListData.all,
            1,
            ridersListData.limit,
            ridersListData.filter,
            sort,
            order,
            searchParams,
            tab,
            bool
        );
        loadRiderMeta();
    };

    const loadRidersListData = (showLoading = true, all, page, limit, filter, sort, order, searchBy, status, bool) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getRidersByBoolStatusService(all, page, limit, filter, sort, order, searchBy, null, status, bool)
            .then((res) => {
                const { data } = res;
                setRidersListData({
                    ...ridersListData,
                    ...data,
                    docs: data.docs.map((x) => ({
                        ...x,
                        name: `${x.firstName || ''} ${x.lastName || ''}`,
                    })),
                });
            })
            .catch((e) => {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const loadRiderMeta = async () => {
        try {
            const { data } = await getAllRidersMetaService();
            if (data) {
                setRidersMeta({ ...ridersMeta, ...data });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handlePageChange = (pageNo) => {
        const { searchBy, searchText, sort, order, tab, status, bool } = searchParams;

        replaceUrlParamsHandler({
            page: pageNo,
            searchBy,
            searchText,
            sort,
            order,
            tab,
            status,
            bool,
        });
    };

    const handleFilter = (field, filterText) => {
        replaceUrlParamsHandler({ searchBy: field || 'fullName', searchText: filterText, page: '1' });
    };

    const handleOnClickNewRider = () => {
        history.push({
            pathname: `${PATHS.RIDER_PROFILE_MANAGEMENT.FORM_VIEW}`,
            search: '?type=new',
            state: { prevCompState: { activeTab } },
        });
    };

    const handleEditRider = (e, row) => {
        history.push({
            pathname: `${PATHS.RIDER_PROFILE_MANAGEMENT.FORM_VIEW}`,
            search: `?type=edit&id=${row._id}`,
            state: { riderParentData: row, prevCompState: { activeTab, refreshUser: false } },
        });
    };

    const handleViewRider = (e, row) => {
        history.push({
            pathname: `${PATHS.RIDER_PROFILE_MANAGEMENT.FORM_VIEW}`,
            search: `?type=view`,
            state: { riderParentData: row, prevCompState: { activeTab } },
        });
    };

    const handleOnDelete = (e, row) => {
        setShowDeleteModal({ state: true, data: row });
    };

    const handleDeleteRider = async (data) => {
        try {
            await deleteRiderService(data._id);
            reloadComponent();
            toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="error" />, {
                position: 'top-right',
            });
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors && e.data.errors.riderMessage) {
                toast.error(e.data.errors.riderMessage);
            } else {
                toast.error('Something went wrong');
            }
        }

        setShowDeleteModal({ state: false, data: null });
    };

    const toggleTrackModal = () => {
        setShowLiveLocationModal(!showLiveLocationModal);
    };

    const handleNavigate = (data) => {
        history.push(data);
    };

    const replaceUrlParamsHandler = (
        params = {
            tab: null,
            status: null,
            page: '1',
            limit: '10',
            searchBy: null,
            searchText: null,
            sort: null,
            order: null,
        }
    ) => {
        try {
            history.replace({
                search: `${
                    params.page || urlParamsSearch.page ? `page=${params.page || urlParamsSearch.page || '1'}&` : ''
                }${params.tab || urlParamsSearch.tab ? `tab=${params.tab || urlParamsSearch.tab || '1'}&` : ''}${
                    params.status || urlParamsSearch.status
                        ? `status=${params.status || urlParamsSearch.status || 'all'}&`
                        : ''
                }${params?.searchBy ? `searchBy=${params.searchBy}&` : ''}${
                    params?.searchText ? `searchText=${params.searchText}&` : ''
                }${params?.limit ? `limit=${params.limit}&` : ''}${params?.sort ? `sort=${params.sort}&` : ''}${
                    params?.order ? `order=${params.order}` : ''
                }${params?.bool ? `bool=${params.bool}` : ''}`,
            });
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Based on the passed column set url query params for column wise sorting
     * @param {boolean} isDescending - If true sorting is descending order else ascending order
     * @param {string} column - Which column need to be used
     */
    const handleColumnWiseSorting = (isDescending, column) => {
        replaceUrlParamsHandler({
            sort: column,
            order: isDescending ? -1 : 1,
            searchBy: urlParamsSearch.searchBy,
            searchText: urlParamsSearch.searchText,
        });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        {hasAccess(PERMISSIONS.RIDER_ACCOUNT, ['Add']) && (
                            <div>
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => handleOnClickNewRider()}
                                >
                                    <i className="fas fa-user-plus left-icon" />
                                    Create New Rider
                                </motion.div>
                            </div>
                        )}
                    </Row>
                    <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            className="w3-animate-opacity"
                            onClick={() => {
                                replaceUrlParamsHandler({ tab: 3, status: 'isActive', bool: 'true' });
                                setActiveTab('3');
                            }}
                        >
                            <StatCard
                                title="ACTIVE"
                                value={`${pad(ridersMeta.activeRiders || 0)} RIDERS`}
                                icon="mdi mdi-clock-outline"
                                tooltipPlacement="bottom"
                                tooltipTitle="Active Riders"
                            />
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            className="w3-animate-opacity"
                            onClick={() => {
                                setActiveTab('1');
                                replaceUrlParamsHandler({ tab: 1, status: 'isOnline', bool: 'true' });
                            }}
                        >
                            <StatCard
                                title="ONLINE"
                                value={`${pad(ridersMeta.onlineRiders || 0)} RIDERS`}
                                icon="mdi mdi-clock-outline"
                                tooltipPlacement="bottom"
                                tooltipTitle="Online Riders"
                            />
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            className="w3-animate-opacity"
                            onClick={() => {
                                setActiveTab('2');
                                replaceUrlParamsHandler({ tab: 2, status: 'isOnline', bool: 'false' });
                            }}
                        >
                            <StatCard
                                title="OFFLINE"
                                value={`${pad(ridersMeta.offlineRiders || 0)} RIDERS`}
                                icon="mdi mdi-clock-outline"
                                tooltipPlacement="bottom"
                                tooltipTitle="Offline Riders"
                            />
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            className="w3-animate-opacity"
                            onClick={() => {
                                replaceUrlParamsHandler({ tab: 5, status: 'isApproved', bool: 'false' });
                                setActiveTab('5');
                            }}
                        >
                            <StatCard
                                title="NEED APPROVAL"
                                value={`${pad(ridersMeta.notApprovedRiders || 0)} RIDERS`}
                                icon="fas fa-exclamation-circle pb-3 mt-2"
                                tooltipPlacement="bottom"
                                tooltipTitle="New Riders who need approval"
                            />
                        </motion.div>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: 'space-between' }} className="ml-0 mr-3">
                        <div>
                            <span className="ml-2 font-weight-bold">SYSTEM RIDER ACCOUNTS</span>
                        </div>
                        <div>
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-form-btn shadow-lg nw-md pr-4 pl-4"
                                onClick={() => {
                                    history.push({
                                        pathname: `${PATHS.ADVANCED_RIDER_TRACKING.DEFAULT}`,
                                    });
                                }}
                            >
                                <>
                                    <i className="fas fa-map-marker-alt left-icon"></i>
                                    <span className="h6">Trace Live Location</span>
                                </>
                            </motion.button>
                            {/* <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-form-btn circle shadow-lg nw-md pr-2 pl-3 ml-2"
                                onClick={() => {
                                    history.push({
                                        pathname: `${PATHS.ADVANCED_RIDER_TRACKING.DEFAULT}`,
                                    });
                                }}
                            >
                                <>
                                    <i className="far fa-map left-icon"></i>
                                </>
                            </motion.button> */}
                            <motion.button
                                id="reload-comp-btn"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-form-btn circle shadow-lg nw-md pr-2 pl-3 ml-2 pointer"
                                onClick={() => {
                                    reloadComponent();
                                }}
                            >
                                <>
                                    <i className="fas fa-redo-alt left-icon"></i>
                                </>
                            </motion.button>
                            <TooltipDefault targetId="reload-comp-btn" text="Reload" position="bottom" />
                        </div>
                    </Row>
                    <Row style={{ marginTop: 5 }}>
                        <Nav tabs className="nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '1',
                                    })}
                                    onClick={() => {
                                        // setTabStatus({ tab: 'isActive', bool: 'true' });
                                        setActiveTab('1');
                                        replaceUrlParamsHandler({ tab: 1, status: 'isOnline', bool: 'true' });
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        Online Riders ( {ridersMeta.onlineRiders} )
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '2',
                                    })}
                                    onClick={() => {
                                        setActiveTab('2');
                                        replaceUrlParamsHandler({ tab: 2, status: 'isOnline', bool: 'false' });
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        Offline Riders ( {ridersMeta.offlineRiders} )
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '3',
                                    })}
                                    onClick={() => {
                                        replaceUrlParamsHandler({ tab: 3, status: 'isActive', bool: 'true' });
                                        setActiveTab('3');
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        Active Riders ( {ridersMeta.activeRiders} )
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '4',
                                    })}
                                    onClick={() => {
                                        replaceUrlParamsHandler({ tab: 4, status: 'isActive', bool: 'false' });
                                        setActiveTab('4');
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        InActive Riders ( {ridersMeta.inActiveRiders} )
                                    </span>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '5',
                                    })}
                                    onClick={() => {
                                        replaceUrlParamsHandler({ tab: 5, status: 'isApproved', bool: 'false' });
                                        setActiveTab('5');
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        Un Approved Riders ( {ridersMeta.notApprovedRiders} )
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '6',
                                    })}
                                    onClick={() => {
                                        replaceUrlParamsHandler({ tab: 6, status: 'verified', bool: 'false' });
                                        setActiveTab('6');
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        Un-Verified Riders ( {ridersMeta.unVerifiedRiders} )
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === '7',
                                    })}
                                    onClick={() => {
                                        replaceUrlParamsHandler({
                                            tab: 7,
                                            status: 'isRequestAccountDeletion',
                                            bool: 'true',
                                        });
                                        setActiveTab('7');
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        Requested for Deletion ( {ridersMeta.requestedForAccountDeletionRiders} )
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Row>
                    <br />
                    <Row>
                        <TableCustom
                            tableData={ridersListData}
                            handleEdit={handleEditRider}
                            handleView={handleViewRider}
                            onFilter={handleFilter}
                            onPageChange={handlePageChange}
                            handleDelete={handleOnDelete}
                            showView={false}
                            showEdit={hasAccess(PERMISSIONS.MERCHANTS, ['Edit'])}
                            showDelete={false}
                            removeId={false}
                            filters={defaultSearchFilters}
                            onSort={handleColumnWiseSorting}
                            searchProp={searchParams}
                            isManualSearch
                            disabledRows={{
                                role: { key: 'role', value: 'superAdmin' },
                                _id: {
                                    key: '_id',
                                    value: getUser()._id,
                                },
                            }}
                            loading={loading > 0}
                        />
                    </Row>
                </Container>
            </div>
            <AlertModal
                show={showDeleteModal.state}
                onConfirm={handleDeleteRider}
                data={showDeleteModal.data}
                onHide={() =>
                    setShowDeleteModal({
                        state: false,
                        data: null,
                    })
                }
            />
            {showLiveLocationModal && (
                <TrackRidersModal
                    handleNavigate={handleNavigate}
                    clientRiders={ridersListData.docs}
                    toggleModal={toggleTrackModal}
                    socket={socket}
                />
            )}
        </>
    );
}

RiderProfileManagementPage.propTypes = {
    location: PropTypes.any,
    socket: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotRiderProfileManagementPage = hot(RiderProfileManagementPage);

export default connect(mapStateToProps, {})(HotRiderProfileManagementPage);
