import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import DeliveryTransactions from './DeliveryTransactions';
import AttachmentsAndNotes from './AttachmentsAndNotes';

function DeliveryInformation(props) {
    const {
        toggleModal,
        show = false,
        type,
        data,
        merchantOrder,
        deliveryId,
        riderId,
        createBillingRecord,
        deliveryTransactions,
        loadTransactions,
        setDeliveryFormData,
        setIsViewMode,
        updateRiderData,
        updateHistoryWithData,
        deliveryAttachments,
        loadAttachments,
    } = props;

    // current tab of delivery information section
    const [currentActiveTab, setCurrentActiveTab] = useState('1');

    // set toggle tabs
    const toggle = (tab) => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    };
    // Common Props to pass to Child Component
    const commonProps = {
        deliveryId,
        riderId,
        data,
        toggleModal,
        type,
        show,
        loadTransactions,
        setDeliveryFormData,
        setIsViewMode,
        updateRiderData,
        updateHistoryWithData,
        deliveryAttachments,
        loadAttachments,
    };
    return (
        <div>
            <Card className="shadow-lg default-card">
                <CardBody>
                    <Col>
                        <Col>
                            <h5>Delivery Information</h5>
                            <br />
                        </Col>
                        <Row>
                            <Col>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: currentActiveTab === '1',
                                            })}
                                            onClick={() => {
                                                toggle('1');
                                            }}
                                        >
                                            <span style={{ fontSize: '12px' }}>Attachments and Notes</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: currentActiveTab === '2',
                                            })}
                                            onClick={() => {
                                                toggle('2');
                                            }}
                                        >
                                            <span style={{ fontSize: '12px' }}>Delivery Transactions</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>

                        {/* tab content */}
                        <TabContent activeTab={currentActiveTab}>
                            {deliveryAttachments && (
                                <TabPane tabId="1">
                                    <AttachmentsAndNotes
                                        deliveryAttachments={deliveryAttachments}
                                        merchantOrderNo={merchantOrder}
                                        {...commonProps}
                                    />
                                </TabPane>
                            )}
                            <TabPane tabId="2">
                                <DeliveryTransactions
                                    merchantOrder={merchantOrder}
                                    createBillingRecord={createBillingRecord}
                                    deliveryTransactions={deliveryTransactions}
                                    {...commonProps}
                                />
                            </TabPane>
                        </TabContent>
                    </Col>
                </CardBody>
            </Card>
        </div>
    );
}

DeliveryInformation.propTypes = {
    toggleModal: PropTypes.func,
    show: PropTypes.bool,
    type: PropTypes.any,
    data: PropTypes.any,
    merchantOrder: PropTypes.any,
    deliveryId: PropTypes.any,
    riderId: PropTypes.any,
    createBillingRecord: PropTypes.func,
    deliveryTransactions: PropTypes.array,
    loadTransactions: PropTypes.func,
    setDeliveryFormData: PropTypes.func,
    setIsViewMode: PropTypes.func,
    updateRiderData: PropTypes.func,
    updateHistoryWithData: PropTypes.func,
    deliveryAttachments: PropTypes.any,
    loadAttachments: PropTypes.func,
};

export default DeliveryInformation;
