import { batchPrintTableHeaders } from '../../../constant';

export const batchPrintDeliveryListData = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: batchPrintTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};
