/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect, useDispatch } from 'react-redux';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useInjectReducer } from 'redux-injectors';
import toast from 'react-hot-toast';

// #region components | assets
import { BackdropLoader, CustomToast } from 'components';

// #region imports
import PATHS from 'routes/paths';

// #region constants
import { USER_ROLES } from 'constants/user/user.constant';

// #region services
import { getPartnerApiDataByMerchantId } from 'services/partner-api.service';

// #region utils
import { getUser, getVisitingObject, hasRole } from 'utils/checkAuth';
import { apiErrorHandlerV2 } from 'utils/helpers';

// #endregion imports
import AccessDenied from 'pages/Utility/AccessDenied';
import reducer from './reducer';
import NotEnabledPage from './modules/ApiAccessNotEnabled';
import SandboxCreatedPage from './modules/SandboxCreated';
import TabsPage from './TabsPage';
import { GET_MERCHANT_PARTNER_DATA } from './actionTypes';
import { hasAccessApiPortal } from './utils/permissionHandler';

/**
 * This is the main page of the API portal. From here, one of 3 pages will be shown:
 *  - API Portal page with tabs (dashboard, basic settings, etc)
 *  - 'API Service Not Enabled' page
 *  - 'API Sandbox Created' page
 * Validation for the logged in user is done to decide which of these pages to show.
 */
function ApiPortalPage(props) {
    const history = useHistory();

    const { visitingMerchant } = getVisitingObject();

    const { clientAdmin, clientUser, merchantAdmin, merchantUser, superAdmin } = USER_ROLES;

    const { location } = props;
    const { state } = location;
    const { merchantId = visitingMerchant?._id } = state || {};

    const isMerchantUsers = hasRole([merchantAdmin, merchantUser]);

    const [user, setUser] = useState(null);
    const [partnerData, setPartnerData] = useState(null);

    useInjectReducer({ key: 'apiPartnerData', reducer });

    const dispatch = useDispatch();

    /** Load user details */
    useEffect(() => {
        const userDetails = getUser();

        /**
         * If this is the superAdmin or a client, they should be coming here from the partner list page,
         * and the merchantId should be present in the location.state.
         * If it is not found, it means they accessed this page directly using the URL,
         * so we redirect them back to the partner list page.
         */
        if ([superAdmin, clientAdmin, clientUser].includes(userDetails.role)) {
            if (!merchantId) {
                history.push({
                    pathname: `/${PATHS.DEVELOPER.API_SERVICE}`,
                });
                return;
            }
        }

        if (userDetails) {
            setUser(userDetails);
        }
    }, []);

    // !TODO add docs
    useEffect(() => {
        if (merchantId && !location?.state?._id && isMerchantUsers) {
            loadPartnerData(merchantId);
        } else if (merchantId && !partnerData) {
            loadPartnerData(merchantId);
        }
    }, [merchantId, location?.key]);

    const loadPartnerData = async (id) => {
        try {
            const { data } = await getPartnerApiDataByMerchantId(id);
            if (data?.payload) {
                dispatch({
                    type: GET_MERCHANT_PARTNER_DATA,
                    payload: { ...data },
                });
                setPartnerData({
                    ...data.payload,
                });
                history.push({
                    pathname: `/${PATHS.DEVELOPER.API_PORTAL}`,
                    state: { ...data.payload },
                });
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    if (!hasAccessApiPortal()) {
        return <AccessDenied pageName="API Dashboard" />;
    }

    return (
        <>
            {partnerData && user ? (
                <>
                    {isMerchantUsers ? (
                        <>
                            {!partnerData.serviceStatusClient && !partnerData.serviceStatusMerchant ? (
                                <>
                                    {!partnerData.isSandboxCreated && <NotEnabledPage />}
                                    {partnerData.isSandboxCreated && <SandboxCreatedPage />}
                                </>
                            ) : (
                                <TabsPage
                                    location={props.location}
                                    merchantId={user ? user.merchantId : undefined}
                                    merchantName={user ? user.merchantName : undefined}
                                />
                            )}
                        </>
                    ) : (
                        <TabsPage location={props.location} />
                    )}
                </>
            ) : (
                <>
                    <div className="page-content">
                        <Container fluid>
                            <BackdropLoader show opacity={0.8} />
                        </Container>
                    </div>
                </>
            )}
        </>
    );
}

ApiPortalPage.propTypes = {
    location: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotApiPortalPage = hot(ApiPortalPage);

export default connect(mapStateToProps, {})(HotApiPortalPage);
