/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as qs from 'query-string';
import PropTypes from 'prop-types';
import polylineEncryption from '@mapbox/polyline';
import { motion } from 'framer-motion';
import { Card, CardBody, Col, Container, Input, Row, Form, Spinner } from 'reactstrap';
import toast from 'react-hot-toast';
import moment from 'moment';
import { hot } from 'react-hot-loader/root';

// #region assets | components
import { Calendar, ChangeRider, CrossCircle, PlusCircle, LeftArrow } from 'components/Icons';
import { CustomToast, TableCustom } from 'components';
import RoutePlanningModal from 'components/Modals/Route-Planning';
import DeliveryCountCard from 'components/Cards/DeliveryCountCard';
import DeliveryDateCard from 'components/Cards/DeliveryDateCard';
import RiderDetailCard from 'components/Cards/RiderDetailCard';

// #region services
import { getDeliveriesByRoutePlanningId, getTransactionsByRoutePlanningId } from 'services/deliveries.service';
import { getRoutePlanningDataById } from 'services/routePlanning/routePlanning.service';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';

// #region constants
import { TRANSACTION_METHODS } from 'constants/enums';

// #region other imports
import PATHS from 'routes/paths';

// #endregion imports
import { IS_MERCHANT_VIEW, hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import AccessDenied from 'pages/Utility/AccessDenied';
import {
    ROUTE_TYPES,
    initialRoutePlanningEntryViewTableData,
    initialRoutePlanningEntryViewTransactionTableData,
} from './constants';
import { mapOrders } from '../../utils';
import RoutePlanTransactionModal from './components/RoutePlanTransactionModal';
import { mapRoutePlanningEntryViewTableDocs, mapRoutePlanningEntryViewTransactionTableDocs } from './utils';
import RoutePlanEntryViewMapGrid from './components/EntryViewMap';

function RoutePlaningEntryView(props) {
    const { location } = props;

    const history = useHistory();

    const [showModal, setShowModal] = useState(false);

    const [type, setType] = useState(''); // modal type(addDeliveries, changeTheRider etc.)

    // Route planning data is object and it has all the necessary properties for a particular route planning
    const [routePlanningData, setRoutePlanningData] = useState({});
    // According to the route planning id all the deliveries linked to that id is here - delivery object array
    const [relevantDeliveryData, setRelevantDeliveryData] = useState(initialRoutePlanningEntryViewTableData);
    const [filteredDeliveryData, setFilteredDeliveryData] = useState(initialRoutePlanningEntryViewTableData);
    // Loading state for the table
    const [isLoadingDeliveryTableData, setIsLoadingDeliveryTableData] = useState(false);
    // When all deliveries at dispatch ready state, then you can change the rider if necessary, can use for cancel entry too
    const [isChangeTheRiderOptionEnabled, setIsChangeTheRiderOptionEnabled] = useState(true);
    // Delivery id array according to route planning
    const [deliveryIdArray, setDeliveryIdArray] = useState([]);
    // Current route planning delivery date state
    const [currentRoutePlanningDeliveryDate, setCurrentRoutePlanningDeliveryDate] = useState(null);
    // delivery count details
    const [deliveryCountDetails, setDeliveryCountDetails] = useState({});
    // detach delivery from route planning detail
    const [detachDeliveryId, setDetachDeliveryId] = useState('');
    // search box text
    const [filterText, setFilterText] = useState('');
    // Build deliveries list suppose to showing on the map
    const [mapDeliveries, setMapDeliveries] = useState(null);
    // deliveries list
    const [deliveryList, setDeliveryList] = useState([]);
    // polyline showing the path on the map
    const [deliveryRoutePolyline, setDeliveryRoutePolyline] = useState([]);
    // rider details
    const [rider, setRider] = useState([]);
    // transaction table data
    const [transactionTableData, setTransactionTableData] = useState(initialRoutePlanningEntryViewTransactionTableData);
    // show transaction modal
    const [showTransactionModal, setShowTransactionModal] = useState(false);
    // transaction modal data
    const [showTransactionModalData, setShowTransactionModalData] = useState({});
    // is buttons disabled
    const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);

    useEffect(() => {
        if (routePlanningData) {
            setCurrentRoutePlanningDeliveryDate(moment.utc(routePlanningData.deliveryDate).format('YYYY-MM-DD'));
        }
    }, [routePlanningData]);

    useEffect(() => {
        if (!props.location) {
            history.push(PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_ENTRIES);
            return;
        }

        const urlSearch = qs.parse(props.location.search);

        if (urlSearch && urlSearch.type && urlSearch.type === 'edit') {
            handleUpdateComponentEditMode();
        }
    }, [location.search]);

    useEffect(() => {
        const urlSearch = qs.parse(props.location.search);
        const { id } = urlSearch;

        if (routePlanningData.isCancelled === false) {
            fetchRelevantDeliveryData(id);
            // if not cancelled route, enable button
            setIsButtonsDisabled(false);
        } else {
            // cancelled route, plan disable button
            setIsButtonsDisabled(true);
        }
    }, [routePlanningData]);

    function handleUpdateComponentEditMode() {
        try {
            const urlSearch = qs.parse(props.location.search);
            const { id } = urlSearch;
            // if route planning entry id not provided in url
            if (!id) {
                history.push(PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_ENTRIES);
                return;
            }

            if (id) {
                fetchRoutePlanningData(id);
                loadRoutePlanTransactions(id);
            }
        } catch (e) {
            console.log(e);
        }
    }

    // Fetching Route planning data for a route planning id
    const fetchRoutePlanningData = async (id) => {
        try {
            const { data } = await getRoutePlanningDataById(id);
            if (data) {
                setRoutePlanningData(data);
                setRider(data?.rider);
                history.replace({
                    pathname: location.pathname,
                    search: location.search,
                    state: data,
                });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    // Fetching deliveries relevant to a particular route planning id
    const fetchRelevantDeliveryData = async (id) => {
        setIsLoadingDeliveryTableData(true);
        try {
            const { data } = await getDeliveriesByRoutePlanningId(id);

            // if deliveries in rote plan
            if (data.sortedDeliveryArray.length > 0) {
                const { sortedDeliveryArray, encodedPolylinePath } = data;
                // map the table data
                const deliveryData = mapRoutePlanningEntryViewTableDocs(sortedDeliveryArray || []);
                // decode the encoded polyline data
                const decodedPolyline = polylineEncryption.decode(encodedPolylinePath);

                setDeliveryList(sortedDeliveryArray);
                setDeliveryRoutePolyline(decodedPolyline);

                // numbering the sorted delivery array, id suppose to show the number top of the marker in the map
                const modifiedSortedDeliveryArray = sortedDeliveryArray.map((obj, index) => ({
                    ...obj,
                    id: index + 1,
                }));

                // map and set deliveries to show on the map
                const mappedOrders = mapOrders(modifiedSortedDeliveryArray.filter((obj) => obj.routeType === 'pickup'));
                setMapDeliveries(mappedOrders);

                if (sortedDeliveryArray?.length >= 1) {
                    const isAllDeliveryAtDispatchReady = checkDeliveryStatus(sortedDeliveryArray);
                    setIsChangeTheRiderOptionEnabled(isAllDeliveryAtDispatchReady);
                    // Get delivery ids to a array
                    const currentRoutePlanningDeliveries = sortedDeliveryArray?.map((delivery) => delivery._id);
                    setDeliveryIdArray(currentRoutePlanningDeliveries);
                }

                setDeliveryCountDetails(getDeliveryCounts(deliveryData));
                setRelevantDeliveryData({ ...relevantDeliveryData, docs: deliveryData });
                setFilteredDeliveryData({ ...relevantDeliveryData, docs: deliveryData });
            } else {
                // if no deliveries found reset all states to default
                setDeliveryList([]);
                setDeliveryRoutePolyline([]);
                setMapDeliveries(null);
                setDeliveryIdArray([]);
                setDeliveryCountDetails(getDeliveryCounts([]));
                setIsChangeTheRiderOptionEnabled(false);
                // if no deliveries in route plan buttons are disabled
                setIsButtonsDisabled(true);
                setRelevantDeliveryData(initialRoutePlanningEntryViewTableData);
                setFilteredDeliveryData(initialRoutePlanningEntryViewTableData);

                toast.custom(
                    (t) => <CustomToast text="Route Plan Does Not have Any Deliveries" t={t} type="warning" />,
                    {
                        position: 'top-right',
                    }
                );
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setIsLoadingDeliveryTableData(false);
    };

    /**
     * Fetch the route plan transactions by relevant endpoint and set the table data
     */
    const loadRoutePlanTransactions = async (id) => {
        try {
            const { data } = await getTransactionsByRoutePlanningId(id);
            if (data.length > 0) {
                const mappedData = mapRoutePlanningEntryViewTransactionTableDocs(data);
                setTransactionTableData({ ...transactionTableData, docs: mappedData });
            }
        } catch (error) {
            const { message: exception } = apiErrorHandlerV2(error);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    /**
     * Returns an object with the total number of deliveries and the number of pending deliveries.
     *
     * @param {Array} deliveries - An array of delivery objects.
     * @returns {Object} An object containing the total number of deliveries and the number of pending deliveries.
     */
    const getDeliveryCounts = (deliveries) => {
        const completedDeliveries = deliveries.filter(
            (delivery) => delivery.isCompleted === true && delivery.routeType === ROUTE_TYPES.PICKUP
        );
        const pendingDeliveries = deliveries.filter(
            (delivery) => delivery.isCompleted === false && delivery.routeType === ROUTE_TYPES.PICKUP
        );

        return { deliveryCount: completedDeliveries.length, pendingDeliveryCount: pendingDeliveries.length };
    };

    /**
     * This function is responsible for checking delivery status
     * If all the deliveries in the array not moved beyond isPickedUp status returns true
     * If any delivery in the array beyond isPickedUp status returns false
     */
    const checkDeliveryStatus = (deliveryArray) => {
        for (let i = 0; i < deliveryArray.length; i += 1) {
            if (deliveryArray[i].isPickedUp) {
                // return false if status doesn't match
                return false;
            }
        }
        // return true if all statuses match
        return true;
    };

    /**
     * Handles the modal type and their visibility
     * @param {string} id - Modal type(addDeliveries, changeTheRider etc.)
     * @void
     */
    const toggleModal = (id) => {
        setShowModal(!showModal);
        setType(id);
    };

    /**
     * Handles the transaction modal type and their visibility
     * @param {object} data - data comes from the row of the table
     * @void
     */
    const toggleTransactionModal = (data) => {
        setShowTransactionModalData(data);
        setShowTransactionModal(!showTransactionModal);
    };

    /**
     * Handle Add Deliveries
     */
    const handleAddDeliveries = () => {
        const deliveryDate = new Date(routePlanningData.deliveryDate);
        deliveryDate.setHours(0, 0, 0, 0);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        if (routePlanningData.isCompleted) {
            const errorMessage =
                'Since the Route Plan is Already Completed, you cannot add deliveries to this route plan';
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });

            return;
        }

        /** user cannot add deliveries if the delivery date of the route plan is past */
        if (!(deliveryDate < currentDate)) {
            toggleModal('addMoreDeliveries');
        } else {
            const errorMessage = 'Since the date is past, you cannot add deliveries to this route plan';
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    /**
     * Handle cancel route planning entry
     */
    const handleCancelRoutePlanningEntry = () => {
        // no delivery moved beyond the “Picked Up” status
        if (isChangeTheRiderOptionEnabled) {
            toggleModal('cancelRoutePlanningEntry');
        } else {
            const errorMessage = 'Unable to process your request, deliveries are in progress';
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    /**
     * Handle delivery date change
     */
    const handleDeliveryDateChange = () => {
        // no delivery moved beyond the “Picked Up” status
        if (isChangeTheRiderOptionEnabled) {
            toggleModal('updateDeliveryDate');
        } else {
            const errorMessage = `You can't change the delivery date when delivering in progress`;
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    /** Opens the delivery page in a new tab */
    const handleViewDelivery = (row) => {
        if (!row) return;
        window.open(`${PATHS.DELIVERY.FORM}?type=edit&id=${row._id}`, '_blank');
    };

    const handleChangeRider = () => {
        if (isChangeTheRiderOptionEnabled) {
            toggleModal('changeTheRider');
        } else {
            const errorMessage = 'Unable to process your request. The existing rider has started delivering.';
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    const displayModal = (row) => {
        const { method } = row;
        if (method !== TRANSACTION_METHODS.ROUTE_PLANNING.ENTRY_CREATED) toggleTransactionModal(row);
    };

    /** Detach delivery from route planning entry triggered by src\components\Tables\utils.js */
    const detachRoutePlanningDelivery = (row) => {
        if (routePlanningData.isCompleted) {
            const errorMessage = 'Cannot Detach Deliveries from Completed Route Plan';
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });
            return;
        }
        const { deliveryId } = row;
        setDetachDeliveryId(deliveryId);
        toggleModal('detachDelivery');
    };

    /**
     * Handles search functionality
     * @param {*} event - Text box change event
     */
    const handleSearch = (event) => {
        const text = event.target.value;

        try {
            setFilterText(text.toLowerCase());

            // filtered delivery data array
            const filteredDeliveryArray = relevantDeliveryData.docs.filter(
                (item) =>
                    item?.merchant?.referenceNumber?.toLowerCase().indexOf(text) !== -1 ||
                    item?.referenceNumber?.toLowerCase().indexOf(text) !== -1
            );

            setFilteredDeliveryData({ ...relevantDeliveryData, docs: filteredDeliveryArray });
        } catch (e) {
            toast.error('Something went wrong');
        }
    };

    const RoutePlanEntryViewMapGridCommonProps = {
        mapDeliveries,
        deliveryList,
        deliveryRoutePolyline,
        rider,
        isRoutePlanStarted: routePlanningData?.isRoutePlanStarted,
        isRoutePlanCompleted: routePlanningData?.isCompleted,
    };

    // not allow merchant users
    if (IS_MERCHANT_VIEW()) {
        return <AccessDenied pageName="Route Planning" />;
    }

    return (
        <div className="page-content">
            <Container fluid>
                {routePlanningData?.rider && (
                    <Row>
                        <Col sm={7} md={5} xl={5} className="mb-2">
                            <RiderDetailCard riderDetails={routePlanningData?.rider} />
                        </Col>
                        <Col sm={5} md={4} xl={3} className="mb-2">
                            <DeliveryCountCard deliveryCountDetails={deliveryCountDetails} />
                        </Col>
                        <Col md={3} xl={3} className="mb-2">
                            <DeliveryDateCard deliveryDate={moment(routePlanningData?.deliveryDate).format('LL')} />
                        </Col>
                    </Row>
                )}

                <hr className="divider" />

                <Row className="mb-4">
                    {hasAccess(PERMISSIONS.ROUTE_PLANNING, ['Edit']) && (
                        <>
                            <Col md={3} className="p-2">
                                <motion.button
                                    whileHover={{ scale: 0.9 }}
                                    whileTap={{ scale: 0.9 }}
                                    className={`scootix-btn-radius ${
                                        !isChangeTheRiderOptionEnabled || isButtonsDisabled ? 'disabled' : ''
                                    }`}
                                    style={{ borderRadius: 10, width: '100%' }}
                                    onClick={() => {
                                        handleChangeRider();
                                    }}
                                    disabled={isButtonsDisabled || isLoadingDeliveryTableData}
                                >
                                    {isLoadingDeliveryTableData ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>
                                            <ChangeRider />
                                            &nbsp;CHANGE THE RIDER
                                        </>
                                    )}
                                </motion.button>
                            </Col>
                            <Col md={3} className="p-2">
                                <motion.button
                                    whileHover={{ scale: 0.9 }}
                                    whileTap={{ scale: 0.9 }}
                                    className={`scootix-btn-radius ${
                                        !isChangeTheRiderOptionEnabled || isButtonsDisabled ? 'disabled' : ''
                                    }`}
                                    style={{ borderRadius: 10, width: '100%' }}
                                    disabled={isButtonsDisabled || isLoadingDeliveryTableData}
                                    onClick={handleDeliveryDateChange}
                                >
                                    {isLoadingDeliveryTableData ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>
                                            <Calendar />
                                            &nbsp;UPDATE THE DELIVERY DATE
                                        </>
                                    )}
                                </motion.button>
                            </Col>
                        </>
                    )}
                    {hasAccess(PERMISSIONS.ROUTE_PLANNING, ['Cancel']) && (
                        <Col md={2.5} className="p-2">
                            <motion.button
                                whileHover={{ scale: 0.9 }}
                                whileTap={{ scale: 0.9 }}
                                className={`remove__btn form-delete-btn ${
                                    !isChangeTheRiderOptionEnabled || isButtonsDisabled ? 'disabled' : ''
                                }`}
                                style={{ borderRadius: 10, width: '100%' }}
                                onClick={handleCancelRoutePlanningEntry}
                                disabled={isButtonsDisabled || isLoadingDeliveryTableData}
                            >
                                {isLoadingDeliveryTableData ? (
                                    <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                ) : (
                                    <>
                                        <CrossCircle color="#FFF" />
                                        &nbsp;CANCEL ROUTE PLANNING ENTRY
                                    </>
                                )}
                            </motion.button>
                        </Col>
                    )}
                    <Col md={1.5} className="p-2 ml-auto ">
                        <motion.button
                            whileHover={{ scale: 0.9 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius"
                            style={{ borderRadius: 10, width: '100%' }}
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            <LeftArrow />
                            &nbsp;BACK
                        </motion.button>
                    </Col>
                </Row>

                {/** Map Component */}
                {mapDeliveries && <RoutePlanEntryViewMapGrid {...RoutePlanEntryViewMapGridCommonProps} />}

                <Row>
                    <Card className="shadow default-card mt-4">
                        <div className="route-planning__form-header pl-3">
                            <Row className="flex align-items-center align-content-center" style={{ height: 60 }}>
                                <Col className="header-text">Deliveries/Actions Linked with Route Planning Entry</Col>
                                {hasAccess(PERMISSIONS.ROUTE_PLANNING, ['Edit']) && (
                                    <Col md={2.5} className="pr-2 mr-4">
                                        <motion.button
                                            whileHover={{ scale: 0.9 }}
                                            whileTap={{ scale: 0.9 }}
                                            className={`scootix-btn-radius ${
                                                routePlanningData.isCancelled ? 'disabled' : ''
                                            }`}
                                            style={{ borderRadius: 10 }}
                                            onClick={handleAddDeliveries}
                                            disabled={routePlanningData.isCancelled || isLoadingDeliveryTableData}
                                        >
                                            {isLoadingDeliveryTableData ? (
                                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                            ) : (
                                                <>
                                                    <PlusCircle />
                                                    &nbsp;&nbsp;ADD DELIVERIES
                                                </>
                                            )}
                                        </motion.button>
                                    </Col>
                                )}
                            </Row>
                        </div>
                        <CardBody className="px-3">
                            <Row>
                                <Col sm={6} className="pl-0 mb-1">
                                    <Form className="table__custom__search-right d-lg-block">
                                        <div className="position-relative">
                                            <Input
                                                type="text"
                                                value={filterText}
                                                className="form-control"
                                                placeholder="Enter the Delivery ID or Merchant ID to Search"
                                                onChange={handleSearch}
                                            />
                                            <span className="ri-search-line search-icon"></span>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>

                            <Row>
                                <TableCustom
                                    tableData={filteredDeliveryData}
                                    loading={isLoadingDeliveryTableData}
                                    showDelete={false}
                                    showPagination={false}
                                    showView={false}
                                    removeId
                                    isFullScreenShow={false}
                                    isFilter={false}
                                    isSearch={false}
                                    removeAction
                                    isFixedHeight
                                    onViewDelivery={handleViewDelivery}
                                    customActionHandlers={{ detachRoutePlanningDelivery }}
                                    showApiCustomAction={hasAccess(PERMISSIONS.ROUTE_PLANNING, ['DetachDeliveries'])}
                                    isStickyView
                                    // customHeaderProps={tableStyles}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Row>
                <Row>
                    <Card className="shadow default-card mt-4 w-100">
                        <div className="route-planning__form-header pl-3">
                            <Row className="flex align-items-center align-content-center" style={{ height: 60 }}>
                                <Col className="header-text">Transaction Log</Col>
                            </Row>
                        </div>
                        <CardBody className="px-3">
                            <Row>
                                <TableCustom
                                    tableData={transactionTableData}
                                    loading={isLoadingDeliveryTableData}
                                    showDelete={false}
                                    showPagination={false}
                                    showView={false}
                                    removeId
                                    isFullScreenShow={false}
                                    isFilter={false}
                                    isSearch={false}
                                    removeAction
                                    isFixedHeight
                                    showApiCustomAction
                                    customActionHandlers={{ displayModal }}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Row>
                <RoutePlanningModal
                    toggleModal={toggleModal}
                    setShowModal={setShowModal}
                    type={type}
                    show={showModal}
                    deliveryIdArray={deliveryIdArray}
                    routePlanData={routePlanningData}
                    reloads={handleUpdateComponentEditMode}
                    deliveryDate={currentRoutePlanningDeliveryDate}
                    routePlanningId={routePlanningData._id}
                    fetchRoutePlanningData={fetchRoutePlanningData}
                    fetchRelevantDeliveryData={fetchRelevantDeliveryData}
                    riderDetails={routePlanningData?.rider || ''}
                    deliveryArray={relevantDeliveryData}
                    detachDeliveryId={detachDeliveryId}
                    location={props.location}
                    loadTransactions={loadRoutePlanTransactions}
                />
                {/* Route Plan Transaction Modal */}
                <RoutePlanTransactionModal
                    show={showTransactionModal}
                    handleToggle={toggleTransactionModal}
                    data={showTransactionModalData}
                />
            </Container>
        </div>
    );
}

RoutePlaningEntryView.propTypes = {
    location: PropTypes.any,
};

const HotRoutePlaningEntryView = hot(RoutePlaningEntryView);
export default HotRoutePlaningEntryView;
