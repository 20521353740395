import _ from 'lodash';
import { GET_PARTNER_API_WEBHOOK_EVENTS } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

export const getPartnerApiWebhookEventService = (params) => {
    const {
        clientId,
        fromDateTime,
        toDateTime,
        merchantId,
        page,
        limit,
        sort = 'createdAt',
        order = -1,
        responseStatus,
        statusCode,
        eventType,
        merchantOrderNo,
        deliveryId,
        deliverySequenceId,
    } = params;
    const queryParams = _.pickBy({
        clientId,
        fromDateTime,
        toDateTime,
        merchantId,
        page,
        limit,
        sort,
        order,
        responseStatus,
        statusCode,
        eventType,
        merchantOrderNo,
        deliveryId,
        deliverySequenceId,
    });
    const url = UrlBuilder().setUrl(GET_PARTNER_API_WEBHOOK_EVENTS).setQueryParams(queryParams).build();
    return instance.get(url);
};
