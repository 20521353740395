export const validateForm = (formData) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const {
        developerName,
        developerEmail,
        developerContactNumber,
        developerWhatsappNumber,
        secondaryDeveloperEmail,
        secondaryDeveloperName,
    } = formData;

    if (!developerName) {
        return { isFormValid, message: 'Please enter a developer name before continue' };
    }
    if (!developerEmail) {
        return { isFormValid, message: 'Please enter a developer email before continue' };
    }
    if (!developerContactNumber) {
        return { isFormValid, message: 'Please enter a developer contact number before continue' };
    }
    if (!developerWhatsappNumber) {
        return { isFormValid, message: 'Please enter a developer whatsapp number before continue' };
    }
    if (secondaryDeveloperName && !secondaryDeveloperEmail) {
        return { isFormValid, message: 'Please enter a secondary developer email before continue' };
    }

    isFormValid = true;
    return { isFormValid, message };
};
