import React from 'react';
import { Col, Container, Input, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { CopyBlock, dracula } from 'react-code-blocks';

// #region components & assets
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';
// import CustomizableButton from './CustomizableButton';
// import download from '../../../../../../assets/images/developer/downloadFile.svg';
import { buildRequestBody } from '../utils';

/**
 * Modal for viewing the API Requests
 */
function ApiRequestModal(props) {
    const { show, handleToggle, data = {} } = props;

    const { sequenceId, fullUrl, originalUrl, method } = data;

    return (
        <DeveloperModal header={`API Request Body for ${sequenceId || ''}`} show={show} handleToggle={handleToggle}>
            <Container>
                <Row className="mb-2">
                    <Col>
                        <Input
                            name="requestUrl"
                            type="textarea"
                            className="form-control"
                            style={{ borderRadius: 15, backgroundColor: '#F5F4F4', border: '#E7E4E4 1px solid' }}
                            value={`${method || 'GET'} \n ${fullUrl || originalUrl || ''}`}
                            readOnly
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <CopyBlock
                            language="jsx"
                            text={buildRequestBody('typescript', data)}
                            showLineNumbers
                            theme={dracula}
                            wrapLines
                            codeBlock
                        />
                    </Col>
                </Row>
                {/* <Row className="mb-2 ml-1">
                    <CustomizableButton buttonTitle="Download" icon={download} />
                </Row> */}
            </Container>
        </DeveloperModal>
    );
}

ApiRequestModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    data: PropTypes.object,
};

export default ApiRequestModal;
