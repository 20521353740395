import { getAccessToken } from 'utils/checkAuth';
import { getBaseUrlSocket } from 'utils/httpclients/config';
export const SOCKET_SERVICE_ENDPOINT = getBaseUrlSocket();

export const getSocketProps = () => {
    const token = getAccessToken();
    return {
        path: '/socket.io',
        transports: ['websocket'],
        query: { Authorization: token, request_type: 'user' },
        // transports: ['websocket', 'polling', 'flashsocket'],
    };
};
