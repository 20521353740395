import { DELIVERY_TYPES, RIDER_STATUS_TYPES, VEHICLE_TYPES } from 'constants/constants';

export const vehicleTypeOptions = [
    { label: 'Motorbike', value: VEHICLE_TYPES.MOTOR_BIKE },
    { label: 'Car', value: VEHICLE_TYPES.CAR },
    { label: 'Van - 7FT', value: VEHICLE_TYPES.VAN_SEVEN_FEET },
    { label: 'Truck', value: VEHICLE_TYPES.TRUCK },
];

export const deliveryTypeOptions = [
    {
        label: DELIVERY_TYPES.NEW_ORDER,
        value: DELIVERY_TYPES.NEW_ORDER,
    },
    {
        label: DELIVERY_TYPES.RETURN_ORDER,
        value: DELIVERY_TYPES.RETURN_ORDER,
    },
    {
        label: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
        value: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
    },
];

export const riderStatusOptions = [
    { label: 'Online', value: RIDER_STATUS_TYPES.ONLINE },
    { label: 'Offline', value: RIDER_STATUS_TYPES.OFFLINE },
];
