/* eslint-disable indent */
import {
    GET_MERCHANT_SETTING,
    GET_ONE_MERCHANT_SETTING,
    GET_ALL_MERCHANT_SETTING,
    GET_MERCHANT_SETTING_BY_MERCHANT_ID,
    GET_MERCHANT_DELIVERY_DATE_CONFIGURATION,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Merchant Setting
 * @returns {object}
 */
export const getMerchantSettingService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_MERCHANT_SETTING}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${populate ? `&${populate}&` : ''}${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Merchant Setting By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getMerchantSettingByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ONE_MERCHANT_SETTING).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Merchant Setting By Merchant Id
 * @param {string} id
 * @returns {Promise}
 */

export const getMerchantSettingByMerchantIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_MERCHANT_SETTING_BY_MERCHANT_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Merchant Setting
 * @returns {Promise}
 */
export const getAllMerchantSettingService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_MERCHANT_SETTING).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get delivery date configuration Merchant Id
 * @returns {Promise}
 */
export const getDeliveryDateConfigurationService = (id) => {
    const url = UrlBuilder().setUrl(GET_MERCHANT_DELIVERY_DATE_CONFIGURATION).setPathParams({ id }).build();
    return instance.get(url);
};
