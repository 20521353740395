import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { post } from 'utils/httpclients/instances';
import { AUTH_FORGET_PASSWORD } from 'utils/apiEndpoints';
import { getApiErrorMessage, getApiMessage } from 'constants/api';

// Login Redux States
import { FORGET_USER } from './actionTypes';
import { forgetUserSuccessful, userForgetPasswordError } from './actions';

// If user is login then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user } }) {
    try {
        const { data } = yield call(post, AUTH_FORGET_PASSWORD, user);
        if (data) {
            if (data.msg) {
                const message = getApiMessage(data.msg);
                yield put(forgetUserSuccessful(message));
            }
        } else {
            yield put(userForgetPasswordError('Something went wrong'));
        }
    } catch (error) {
        if (error.message) {
            console.log(error.response);
            if (error.response && error.response.data && error.response.data.errors) {
                const message = getApiErrorMessage(error.response.data.errors.msg);
                yield put(userForgetPasswordError(message));
                return;
            }
        }
        yield put(userForgetPasswordError('Something went wrong'));
    }
}

export function* watchUserForget() {
    yield takeEvery(FORGET_USER, forgetUser);
}

function* forgetSaga() {
    yield all([fork(watchUserForget)]);
}

export default forgetSaga;
