import React from 'react';
import PropTypes from 'prop-types';
import { Col, DropdownItem, Row, FormGroup, Label, Input } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';

function DeliveryImportSettingsSubModule(props) {
    const { handleInputChange, settings, handleCheckChange } = props;

    return (
        <>
            <div>
                <h3 style={{ fontWeight: 'bolder' }} className="">
                    Delivery Settings
                </h3>
                <span color="#5d5d5a">Manage Merchant Delivery Settings Here</span>
            </div>
            <DropdownItem divider className="mt-4" />
            <br />

            <Row>
                <Col md={4}>
                    <h5 style={{ color: '#3C405B' }} className="">
                        Rider Self Assignment
                    </h5>
                    <span color="#5d5d5a">Settings for how a Rider Self Assignment are processed </span>
                </Col>
                <Col md={6}>
                    <Row className="pl-3">
                        <div className="custom-control custom-switch" dir="ltr">
                            <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="riderSelfAssignmentIsEnabled"
                                onChange={() =>
                                    handleCheckChange(
                                        !settings.delivery.riderSelfAssignment.isEnabled,
                                        'settings.delivery.riderSelfAssignment.isEnabled'
                                    )
                                }
                                checked={settings.delivery.riderSelfAssignment.isEnabled}
                            />
                            <Label className="custom-control-label" htmlFor="riderSelfAssignmentIsEnabled">
                                Toggle Rider Self Assignment Functionality
                            </Label>
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="slabOneRadius" className="ml-1">
                                    Slab One Radius ( KM )
                                </Label>
                                <AvField
                                    name="slabOneRadius"
                                    placeholder="Slab One Radius"
                                    type="number"
                                    validate={{ required: { value: true } }}
                                    errorMessage="Required"
                                    className="form-control"
                                    id="slabOneRadius"
                                    value={settings.delivery.riderSelfAssignment.slabOneRadius}
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            'settings.delivery.riderSelfAssignment.slabOneRadius',
                                            'number'
                                        )
                                    }
                                    min={1}
                                    max={settings.delivery.riderSelfAssignment.slabTwoRadius}
                                    disabled={!settings.delivery.riderSelfAssignment.isEnabled}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="slabOneTimeInterval" className="ml-1">
                                    Slab One Search Time ( Minutes )
                                </Label>
                                <AvField
                                    name="slabOneTimeInterval"
                                    placeholder="Slab One Search Time"
                                    type="number"
                                    validate={{ required: { value: true } }}
                                    className="form-control"
                                    id="slabOneTimeInterval"
                                    value={settings.delivery.riderSelfAssignment.slabOneTimeInterval}
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            'settings.delivery.riderSelfAssignment.slabOneTimeInterval',
                                            'number'
                                        )
                                    }
                                    min={0}
                                    max={settings.delivery.riderSelfAssignment.slabTwoRadius}
                                    disabled={!settings.delivery.riderSelfAssignment.isEnabled}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="slabTwoRadius" className="ml-1">
                                    Slab Two Search Radius ( KM )
                                </Label>
                                <AvField
                                    name="slabTwoRadius"
                                    placeholder="Slab Two Search Radius"
                                    type="number"
                                    validate={{ required: { value: true } }}
                                    errorMessage="Required"
                                    className="form-control"
                                    id="slabTwoRadius"
                                    value={settings.delivery.riderSelfAssignment.slabTwoRadius}
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            'settings.delivery.riderSelfAssignment.slabTwoRadius',
                                            'number'
                                        )
                                    }
                                    disabled={!settings.delivery.riderSelfAssignment.isEnabled}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="slabTwoTimeInterval" className="ml-1">
                                    Slab Two Search Time ( Minutes )
                                </Label>
                                <AvField
                                    name="slabTwoTimeInterval"
                                    placeholder="Slab Two Search Time"
                                    type="number"
                                    validate={{ required: { value: true } }}
                                    errorMessage="Required"
                                    className="form-control"
                                    id="slabTwoTimeInterval"
                                    value={settings.delivery.riderSelfAssignment.slabTwoTimeInterval}
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            'settings.delivery.riderSelfAssignment.slabTwoTimeInterval',
                                            'number'
                                        )
                                    }
                                    disabled={!settings.delivery.riderSelfAssignment.isEnabled}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="slabThreeRadius" className="ml-1">
                                    Slab Three Search Radius ( KM )
                                </Label>
                                <AvField
                                    name="slabThreeRadius"
                                    placeholder="Slab Three Search Radius"
                                    type="number"
                                    validate={{ required: { value: true } }}
                                    errorMessage="Required"
                                    className="form-control"
                                    id="slabThreeRadius"
                                    value={settings.delivery.riderSelfAssignment.slabThreeRadius}
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            'settings.delivery.riderSelfAssignment.slabThreeRadius',
                                            'number'
                                        )
                                    }
                                    disabled={!settings.delivery.riderSelfAssignment.isEnabled}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="slabThreeTimeInterval" className="ml-1">
                                    Slab Three Search Time ( Minutes )
                                </Label>
                                <AvField
                                    name="slabThreeTimeInterval"
                                    placeholder="Slab Three Search Time"
                                    type="number"
                                    validate={{ required: { value: true } }}
                                    errorMessage="Required"
                                    className="form-control"
                                    id="slabThreeTimeInterval"
                                    value={settings.delivery.riderSelfAssignment.slabThreeTimeInterval}
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            'settings.delivery.riderSelfAssignment.slabThreeTimeInterval',
                                            'number'
                                        )
                                    }
                                    disabled={!settings.delivery.riderSelfAssignment.isEnabled}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />
            <DropdownItem divider className="mt-4" />
            <br />
            <Row>
                <Col md={4}>
                    <h5 style={{ color: '#3C405B' }} className="">
                        Delivery Auto Assignment
                    </h5>
                    <span color="#5d5d5a">Settings for how a Delivery Auto Assignment are processed </span>
                </Col>
                <Col md={6}>
                    <Row className="pl-3">
                        <div className="custom-control custom-switch" dir="ltr">
                            <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="autoAssignmentIsEnabled"
                                defaultChecked
                                checked={settings.schedular.delivery.autoAssignment.isEnabled}
                                onChange={() =>
                                    handleCheckChange(
                                        !settings.schedular.delivery.autoAssignment.isEnabled,
                                        'settings.schedular.delivery.autoAssignment.isEnabled'
                                    )
                                }
                            />
                            <Label className="custom-control-label" htmlFor="autoAssignmentIsEnabled">
                                Toggle Delivery Auto Assignment Functionality
                            </Label>
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="timeout" className="ml-1">
                                    Auto Assignment Timeout ( KM )
                                </Label>
                                <AvField
                                    name="timeout"
                                    placeholder="Auto Assignment Timeout"
                                    type="number"
                                    validate={{ required: { value: true } }}
                                    errorMessage="Required"
                                    className="form-control"
                                    id="timeout"
                                    value={settings.schedular.delivery.autoAssignment.timeout}
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            'settings.schedular.delivery.autoAssignment.timeout',
                                            'number'
                                        )
                                    }
                                    min={1}
                                    disabled={!settings.schedular.delivery.autoAssignment.isEnabled}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

DeliveryImportSettingsSubModule.propTypes = {
    settings: PropTypes.object,
    handleInputChange: PropTypes.func,
    handleCheckChange: PropTypes.func,
};

export default DeliveryImportSettingsSubModule;
