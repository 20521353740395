import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, Label, Col } from 'reactstrap';
import { motion } from 'framer-motion';

// #region imports
import { centerElementContainerStyles, centerElementInnerStyles } from 'styles';
import { Select } from 'components';
import { getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

function RoleAssignModal(props) {
    const {
        toggleModal,
        show = false,
        title = null,
        message = '',
        onConfirm = null,
        onCancel = null,
        confirmMessage = 'Confirm',
        cancelMessage = 'Cancel',
        onChange,
        roles,
        getSelectedRole,
        formData,
    } = props;

    return (
        <>
            <div className="custom-scootix-modal">
                {show && (
                    <Modal
                        size="lg"
                        isOpen
                        center
                        toggle={toggleModal}
                        style={{ borderRadius: 15 }}
                        className="modal-dialog modal-dialog-centered scootix-confirmation-modal"
                    >
                        <ModalHeader
                            className="scootix-bg-radiant"
                            style={{
                                // backgroundColor: THEME_COLOR,
                                marginTop: -1,
                                marginRight: -1,
                                marginLeft: -1,
                                color: '#FFF',
                                borderTopRightRadius: 15,
                                borderTopLeftRadius: 15,
                            }}
                        >
                            <h4
                                style={{
                                    color: '#FFF',
                                }}
                            >
                                {' '}
                                {title || 'Confirmation'}{' '}
                            </h4>
                        </ModalHeader>
                        <ModalBody
                            style={{
                                flexDirection: 'column',
                                borderRadius: 15,
                                backgroundColor: '#FAFAFA',
                                ...centerElementContainerStyles,
                            }}
                            className="p-3"
                        >
                            <br />
                            <div>
                                <h5>{message}</h5>
                            </div>
                            <br />
                            <Col md={6}>
                                <Label htmlFor="roleId">Role</Label>
                                <Select
                                    value={getDefaultValueForSelect(getSelectedRole(formData.roleId))}
                                    isClearable={!hasAccess(PERMISSIONS.ASSIGN_ROLE_PERMISSION, ['Edit'])}
                                    options={roles}
                                    styles={reactSelectCustomStyles}
                                    onChange={(event) => onChange(event, 'roleId')}
                                    isDisabled={!hasAccess(PERMISSIONS.ASSIGN_ROLE_PERMISSION, ['Edit'])}
                                />
                            </Col>
                            <br />
                            <div className="row">
                                {hasAccess(PERMISSIONS.ASSIGN_ROLE_PERMISSION, ['Edit']) && (
                                    <motion.div
                                        className="scootix-bg-radiant ml-2"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        style={{
                                            minWidth: 120,
                                            padding: 17,
                                            paddingRight: 24,
                                            paddingLeft: 24,
                                            borderRadius: 20,
                                            textAlign: 'center',
                                            ...centerElementInnerStyles,
                                        }}
                                        onClick={() => onConfirm && onConfirm()}
                                    >
                                        <span
                                            className="h6"
                                            style={{ color: '#FFF', fontWeight: 'bold', fontSize: 18 }}
                                        >
                                            {confirmMessage}
                                        </span>
                                    </motion.div>
                                )}
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    style={{
                                        backgroundColor: '#FFF9EA',
                                        minWidth: 120,
                                        padding: 17,
                                        paddingRight: 24,
                                        paddingLeft: 24,
                                        borderRadius: 20,
                                        marginLeft: 10,
                                        border: '1px solid #FDBA24',
                                        textAlign: 'center',
                                        ...centerElementInnerStyles,
                                    }}
                                    onClick={() => {
                                        if (onCancel) {
                                            onCancel();
                                        } else {
                                            toggleModal && toggleModal();
                                        }
                                    }}
                                >
                                    <span className="h6" style={{ color: '#FDBA24', fontWeight: 'bold', fontSize: 18 }}>
                                        {cancelMessage}
                                    </span>
                                </motion.div>
                            </div>
                        </ModalBody>
                    </Modal>
                )}
            </div>
        </>
    );
}

RoleAssignModal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    toggleModal: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    confirmMessage: PropTypes.string,
    cancelMessage: PropTypes.string,
    roles: PropTypes.array,
    getSelectedRole: PropTypes.func,
    formData: PropTypes.object,
};

export default RoleAssignModal;
