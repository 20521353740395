/* eslint-disable indent */
/* eslint-disable camelcase */
import {
    GET_STOCKS_ADJUSTMENT_ITEM,
    GET_STOCKS_ADJUSTMENT_ITEM_BY_ID,
    GET_ALL_STOCKS_ADJUSTMENT_ITEM,
    GET_STOCKS_ADJUSTMENT_ITEM_BY_STOCK_ADJUSTMENT_ID,
    GET_ALL_STOCKS_ADJUSTMENT_ITEM_BY_STOCK_ADJUSTMENT_ID,
    UPDATE_STOCKS_ADJUSTMENT_ITEM,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Stocks Adjustment Item Service
 * @returns {object}
 */
export const getStocksAdjustmentItemService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_STOCKS_ADJUSTMENT_ITEM}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Stocks Adjustment Item By Stocks Adjustment Service
 * @returns {object}
 */
export const getStocksAdjustmentItemByStockAdjustmentIdService = function api(
    stockAdjustmentId,
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_STOCKS_ADJUSTMENT_ITEM_BY_STOCK_ADJUSTMENT_ID}?page=${page}&${
            all ? `all=true&` : ''
        }limit=${limit}&${filter ? `filter=${filter}&` : ''}${sort ? `sort=${sort}&` : ''}${
            order ? `order=${order}&` : `order=-1`
        }${populate ? `&populate=${populate}&` : ''}${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).setPathParams({ id: stockAdjustmentId }).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Stocks Adjustment Item By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getStocksAdjustmentItemByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_STOCKS_ADJUSTMENT_ITEM_BY_ID).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Stocks Adjustment Item
 * @returns {Promise}
 */
export const getAllStocksAdjustmentItemService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_STOCKS_ADJUSTMENT_ITEM).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Stocks Adjustment Item By Stock Receiving Id
 * @returns {Promise}
 */
export const getAllStocksAdjustmentItemByStockReceivingIdService = (id) => {
    try {
        const url = UrlBuilder()
            .setUrl(GET_ALL_STOCKS_ADJUSTMENT_ITEM_BY_STOCK_ADJUSTMENT_ID)
            .setPathParams({ id })
            .build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Stocks Adjustment items Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateStocksAdjustmentItemService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_STOCKS_ADJUSTMENT_ITEM).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
