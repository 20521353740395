import React from 'react';
import { Container } from 'reactstrap';

// #endregion imports
import Header from '../containers/HeaderContainer/Header';
import Form from './components/Form';

function PrinterSettingsPage() {
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Header buttonIconRight={<i className="fas fa-chevron-left" />} />
                    <Form />
                </Container>
            </div>
        </>
    );
}

PrinterSettingsPage.propTypes = {};
export default PrinterSettingsPage;
