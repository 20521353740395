import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Label } from 'reactstrap';
import { motion } from 'framer-motion';

// #region assets | components
import PlusCircleIcon from 'components/Icons/PlusCircle';

// #endregion imports
import Search from './TableHeaderComponents/TableSearch';

function ManageWebhooksTableHeader(props) {
    const {
        isManualSearch,
        onFilter,
        isSearch,
        setSearch,
        search,
        handleNewWebhookButton,
        checked,
        handleCheckChange,
    } = props;

    const handleCheck = (event) => {
        handleCheckChange(event.target.name, event.target.checked);
    };

    return (
        <div className="table-top-row align-items-center justify-content-start">
            {isSearch && (
                <Col xs="auto" className="pl-0">
                    <Search
                        onFilter={onFilter}
                        isManualSearch={isManualSearch}
                        setSearch={setSearch}
                        search={search}
                        searchPlaceholder="Type the Webhook to Search"
                    />
                </Col>
            )}
            <Col xs="auto" className="ml-5">
                <Input type="checkbox" name="active" checked={checked.includes('active')} onChange={handleCheck} />
                <Label htmlFor="active">Active Webhooks</Label>
            </Col>
            <Col xs="auto" className="ml-5">
                <Input type="checkbox" name="inactive" checked={checked.includes('inactive')} onChange={handleCheck} />
                <Label htmlFor="inactive">Inactive Webhooks</Label>
            </Col>
            <Col xs="auto" className="ml-auto pr-0">
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="scootix-btn-radius"
                    style={{ borderRadius: 10 }}
                    onClick={() => handleNewWebhookButton()}
                >
                    <PlusCircleIcon />
                    &nbsp;New Webhook
                </motion.div>
            </Col>
        </div>
    );
}

ManageWebhooksTableHeader.propTypes = {
    isManualSearch: PropTypes.bool,
    onFilter: PropTypes.func,
    isSearch: PropTypes.bool,
    setSearch: PropTypes.func,
    search: PropTypes.object,
    handleNewWebhookButton: PropTypes.func,
    /** Shows which checkbox is selected ('active' or 'inactive') */
    checked: PropTypes.arrayOf(PropTypes.string),
    handleCheckChange: PropTypes.func,
};

export default ManageWebhooksTableHeader;
