import { PRINT_TYPES } from 'constants/constants';

export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const {
        deliveryReferenceNumber,
        merchantOrderNo,
        merchantId,
        printer,
        printType,
        selectedDeliveryIds = [],
        printSize,
    } = form;

    if (printType === PRINT_TYPES.SINGLE) {
        if (!merchantOrderNo || !deliveryReferenceNumber) {
            return { isFormValid, message: 'Please select a Delivery ID or merchant order number' };
        }
    }
    if (printType === PRINT_TYPES.MULTIPLE) {
        if (selectedDeliveryIds.length === 0) {
            return { isFormValid, message: 'Please select a Delivery ID or merchant order number' };
        }
    }

    if (!printer) {
        return { isFormValid, message: 'Please select a printer to continue' };
    }
    if (!merchantId) {
        return { isFormValid, message: 'Please select a merchant to continue' };
    }
    if (!printSize) {
        return { isFormValid, message: 'Please select a print size to continue' };
    }

    isFormValid = true;
    return { isFormValid, message };
};

export function renderDeliverIdColWidth(selectedValuesLength) {
    if (selectedValuesLength < 3) {
        return 3;
    }
    if (selectedValuesLength < 8) {
        return 6;
    }
    if (selectedValuesLength >= 8) {
        return 12;
    }
    return 3;
}

export function renderMerchantOrderNumberColWidth(selectedValuesLength) {
    if (selectedValuesLength < 3) {
        return 3;
    }
    if (selectedValuesLength < 8) {
        return 6;
    }
    if (selectedValuesLength >= 8) {
        return 12;
    }
    return 3;
}
