import { PlusSquare } from 'components/Icons';
import React from 'react';
import Avatar from 'react-avatar';
import { Popup } from 'react-leaflet';
import { Col, Row, Spinner } from 'reactstrap';
import './styles/index.scss';

// #endregion constants
import { RiderToolTipPropTypes } from '../constants';

/**
 * This component is responsible for showing Rider tooltip for rider markers
 * Though this component named as a tooltip for searching and understanding easiness,
 * Technically in what is used here is popup in leaflet not the exact tooltip due to technical constraints
 * @returns Rider tooltip
 */
function RiderTooltip(props) {
    const { rider, setFormData, setRidersOnMap, isActionDisable = false, loading } = props;

    // Handling when clicking on plus button in the tooltip
    const handleAddClick = (order) => {
        setFormData((prevState) => ({ ...prevState, riderId: order._id }));
        /** when user click the plus button, the only riders should be shown in the map */
        setRidersOnMap([order]);
    };

    return (
        <Popup autoClose={false} closeButton={false}>
            <div className="tooltip-wrapper">
                <div key={rider?._id} className="marker-on-map">
                    <Row className="ml-2 mr-2">
                        <Col md={4} className="d-flex align-items-center">
                            <Avatar
                                name={rider?.fullName || ''}
                                src={rider?.profilePictureUrl || null}
                                size="50"
                                round="50px"
                                colors={['#1e6262', '#581b98', '#364f6b']}
                            />
                        </Col>
                        <Col md={8} className="d-flex-column">
                            <h6 className="header6-color-white">{rider?.fullName}</h6>
                            <h6 className="header6-color-white">{rider?.referenceNumber}</h6>
                            <h6 className="header6-color-white">{rider?.mobileNo}</h6>
                        </Col>
                    </Row>
                    {!isActionDisable && (
                        <Row className="d-flex justify-content-end ml-2 mr-2">
                            <button
                                type="submit"
                                className="tooltip-wrapper__add__btn"
                                onClick={() => handleAddClick(rider)}
                                disabled={loading}
                            >
                                {loading ? <Spinner color="info" size="sm" /> : <PlusSquare />}
                            </button>
                        </Row>
                    )}
                </div>
            </div>
        </Popup>
    );
}

RiderTooltip.propTypes = RiderToolTipPropTypes;
export default RiderTooltip;
