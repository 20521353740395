/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';

// #region components | assets
import { ClientAlerts, CustomToast, SelectModal } from 'components';
import CustomGridTable from 'components/CustomGridTable';

// #region services
import { getAllWarehouseZonesService } from 'services/warehouse-zone.service';
import { getAllSystemDataByTypeAndModuleService } from 'services/system-data.service';
import { getBinLocationsByZoneIdService } from 'services/bin-location.service';
import { getAllStocksAdjustmentItemByStockReceivingIdService } from 'services/stocks-adjustment-items.service';
import { getAllStocksService } from 'services/stocks';

// #region imports
import { SYSTEM_DATA_TYPES } from 'constants/enums';

// #region utils
import { getVisitingObject } from 'utils/checkAuth';
import { DEV_ENV } from 'utils/checks';
import { apiErrorHandler } from 'utils/helpers';

// #endregion imports
import BinLocationModal from './components/BinLocationModal';
import { saveStocksAdjustmentItemBatchService } from './services';
import { validateItems } from './utils';

function ItemsSheet(props) {
    const { stocksAdjustmentData = {}, loadItems, allProductMasterData, setIsItemsSaved, setBackdropLoading } = props;

    const _isMounted = useRef(true);

    const { visitingClient } = getVisitingObject();

    // component
    const [formLoading, setFormLoading] = useState(false);

    const [showBinLocationModal, setShowBinLocationModal] = useState(false);

    // other
    const [unitOfMeasures, setUnitOfMeasures] = useState([]);
    const [warehouseZones, setWarehouseZones] = useState([]);
    const [binLocations, setBinLocations] = useState([]);
    const [allStocks, setAllStocks] = useState([]);

    const [stockItemsColumns, setStockItemsColumns] = useState([]);

    const [activeCell, setActiveCell] = useState(null); // Active Cell
    const [activeRow, setActiveRow] = useState(null); // Active Cell
    const [selectedWarehouseZone, setSelectedWarehouseZone] = useState(null); // Selected Warehouse Zone

    const [allItems, setAllItems] = useState([]);
    const [allDeletedItems, setAllDeletedItems] = useState([]);

    // loading
    const [initialLoading, setInitialLoading] = useState(true);

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
        options: [],
    });

    useEffect(() => {
        loadUnitOfMeasures();
        loadWarehouseZones();
        loadAllStocks();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const filteredDocs = allItems.filter((x) => x._id);
        loadItems(filteredDocs);
    }, [allItems]);

    useEffect(() => {
        if (allItems.length > 0 && warehouseZones.length > 0) {
            const findOneWithWareHouse = allItems.find((x) => x.warehouseZoneId);
            if (findOneWithWareHouse) {
                const relevantWarehouse = warehouseZones.find((x) => x._id === findOneWithWareHouse.warehouseZoneId);
                if (relevantWarehouse) {
                    setSelectedWarehouseZone(relevantWarehouse);
                }
            }
        }
    }, [allItems, warehouseZones]);

    useEffect(() => {
        if (!initialLoading && allItems.length === 0) {
            handleAddNewRow();
        }
    }, [initialLoading, allItems]);

    useEffect(() => {
        stocksAdjustmentData && loadAllItemsByStocksAdjustment(stocksAdjustmentData._id);
    }, [stocksAdjustmentData._id]);

    useEffect(() => {
        if (selectedWarehouseZone && selectedWarehouseZone._id) {
            setBackdropLoading((prevState) => prevState + 1);
            getBinLocationsByZoneIdService(selectedWarehouseZone._id, true)
                .then((res) => {
                    const { data } = res;
                    if (data && data.docs && _isMounted.current) {
                        setBinLocations(data.docs.map((x) => ({ ...x, label: x.referenceNumber, value: x._id })));
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    setBackdropLoading((prevState) => prevState - 1);
                });
        }
    }, [selectedWarehouseZone]);

    useEffect(() => {
        setStockItemsColumns([
            {
                title: 'Line',
                type: 'number',
                key: 'lineNumber',
                disabled: true,
            },
            {
                title: 'UPC',
                type: 'text',
                key: 'upc',
                disabled: stocksAdjustmentData.isConfirmed,
            },
            {
                title: 'Product Description',
                type: 'select',
                choices: allProductMasterData,
                key: 'productMasterDataId',
                disabled: stocksAdjustmentData.isConfirmed,
            },
            {
                title: 'Scootix Product Code',
                type: 'text',
                key: 'productMasterDataReferenceNumber',
                disabled: stocksAdjustmentData.isConfirmed,
            },
            {
                title: 'SKU Code',
                type: 'text',
                key: 'skuCode',
                disabled: stocksAdjustmentData.isConfirmed,
            },
            {
                title: 'Batch Number',
                type: 'text',
                key: 'batch',
                disabled: stocksAdjustmentData.isConfirmed,
            },
            {
                title: 'Warehouse Zone',
                type: 'select',
                choices: warehouseZones,
                key: 'warehouseZoneId',
                disabled: stocksAdjustmentData.isConfirmed,
            },

            {
                title: 'Bin Location',
                type: 'text',
                key: 'binLocationReferenceNumber',
                disabled: stocksAdjustmentData.isConfirmed,
            },
            {
                title: 'Serial Number',
                type: 'text',
                key: 'serialNumber',
                disabled: stocksAdjustmentData.isConfirmed,
            },
            {
                title: 'Manufacture Date',
                type: 'date',
                key: 'manufactureDate',
                disabled: stocksAdjustmentData.isConfirmed,
            },
            {
                title: 'Expiry Date',
                type: 'date',
                key: 'expiryDate',
                disabled: stocksAdjustmentData.isConfirmed,
            },
            {
                title: 'Unit of Measure',
                type: 'select',
                choices: unitOfMeasures,
                key: 'unitOfMeasure',
                disabled: stocksAdjustmentData.isConfirmed,
            },
            {
                title: 'Available Quantity',
                type: 'number',
                key: 'availableQuantity',
                disabled: true,
            },
            {
                title: 'Method of Adjustment',
                type: 'select',
                choices: [
                    {
                        label: 'Addition',
                        value: 'Addition',
                    },
                    {
                        label: 'Deduction',
                        value: 'Deduction',
                    },
                ],
                key: 'methodOfAdjustment',
                disabled: stocksAdjustmentData.isConfirmed,
            },
            {
                title: 'Adjusting Quantity',
                type: 'number',
                key: 'adjustingQuantity',
                disabled: stocksAdjustmentData.isConfirmed,
            },
            {
                title: 'New Current Stock',
                type: 'number',
                key: 'newCurrentStock',
                disabled: true,
            },
        ]);
    }, [allProductMasterData, binLocations, warehouseZones, unitOfMeasures, stocksAdjustmentData.isConfirmed]);

    const loadAllItemsByStocksAdjustment = async (id) => {
        try {
            const { data } = await getAllStocksAdjustmentItemByStockReceivingIdService(id, true);
            if (data && data.docs) {
                setAllItems(
                    data.docs.map((x) => ({
                        ...x,
                        manufactureDate: x.manufactureDate ? new Date(x.manufactureDate) : null,
                        expiryDate: x.expiryDate ? new Date(x.expiryDate) : null,
                    }))
                );
            }
        } catch (e) {
            console.log(e);
        }
        setInitialLoading(false);
    };

    const loadAllStocks = async () => {
        try {
            const { data } = await getAllStocksService();
            if (data && Array.isArray(data)) {
                setAllStocks(data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadUnitOfMeasures = async () => {
        try {
            const { data } = await getAllSystemDataByTypeAndModuleService(
                SYSTEM_DATA_TYPES.PRODUCT_MASTER_DATA.TYPES.UNIT_OF_MEASURE,
                SYSTEM_DATA_TYPES.PRODUCT_MASTER_DATA.MODULE
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                setUnitOfMeasures(data.map((x) => ({ label: x.name, value: x.name })));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadWarehouseZones = async () => {
        try {
            const { data } = await getAllWarehouseZonesService();
            if (_isMounted.current && data && data.docs && Array.isArray(data.docs)) {
                setWarehouseZones(data.docs.map((x) => ({ ...x, label: x.zoneName, value: x._id })));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleAddNewRow = () => {
        let lineNumberIndex = 0;
        if (allItems.length > 0) {
            const lastItem = allItems[allItems.length - 1];
            lineNumberIndex = lastItem.lineNumber;
        }
        setAllItems((prevState) => [
            ...prevState,
            { dateReceived: new Date(), lineNumber: lineNumberIndex + 10, uuid: uuid() },
        ]);
    };

    const handleOnRowChange = (data) => {
        let changeType = 'add'; // Change Type

        const allExistingStocks = allItems; // Existing Items
        const modifiedStocks = data; // Modified Data
        const removedItems = allDeletedItems;

        if (allExistingStocks.length === modifiedStocks.length) {
            changeType = 'edit';
        }

        if (modifiedStocks.length > allExistingStocks.length) {
            changeType = 'add';
        }

        if (modifiedStocks.length < allExistingStocks.length) {
            changeType = 'remove';
            allExistingStocks.map((val) => {
                const relevantItem = modifiedStocks.find((x) => (x._id ? x._id === val._id : x.uuid === val.uuid));
                if (!relevantItem) {
                    removedItems.push(val);
                }
                return val;
            });
        }

        if (changeType === 'add') {
            handleAddNewRow();
        }
        if (changeType === 'remove') {
            setAllItems(modifiedStocks);
            setAllDeletedItems(removedItems);
        }

        setIsItemsSaved(false);

        if (changeType === 'edit') {
            const changedCell = activeCell;

            if (changedCell) {
                const { row, colId } = changedCell.cell;

                const modifiedRow = modifiedStocks[row];

                if (colId === 'warehouseZoneId') {
                    const relevantObject = warehouseZones.find((x) => x._id === modifiedRow.warehouseZoneId);
                    if (relevantObject) {
                        const newObject = {
                            ...modifiedRow,
                            warehouseZoneName: relevantObject.zoneName,
                            warehouseSiteId: relevantObject.warehouseSiteId,
                            binLocationName: null,
                            binLocationReferenceNumber: null,
                            binLocationId: null,
                        };
                        const filteredOutStocks = allItems.filter((x) => x.lineNumber !== modifiedRow.lineNumber);
                        filteredOutStocks.push(newObject);
                        const sortedArray = filteredOutStocks.sort((a, b) => a.lineNumber - b.lineNumber);
                        setAllItems(sortedArray);
                        setSelectedWarehouseZone(relevantObject);
                    }
                } else if (colId === 'productMasterDataId') {
                    const relevantObject = allProductMasterData.find((x) => x._id === modifiedRow.productMasterDataId);
                    if (relevantObject) {
                        const availableQuantity = loadProductStock(relevantObject);

                        const newObject = {
                            ...modifiedRow,
                            productMasterDataDescription: relevantObject.productDescription,
                            productMasterDataId: relevantObject._id,
                            productMasterData: relevantObject,
                            unitOfMeasure: relevantObject.unitOfMeasure,
                            upc: relevantObject.upc,
                            skuCode: relevantObject.skuCode,
                            availableQuantity,
                            adjustingQuantity: 0,
                            newCurrentStock: availableQuantity,
                            productMasterDataReferenceNumber: relevantObject.referenceNumber,
                        };
                        const filteredOutStocks = allItems.filter((x) => x.lineNumber !== modifiedRow.lineNumber);
                        filteredOutStocks.push(newObject);
                        const sortedArray = filteredOutStocks.sort((a, b) => a.lineNumber - b.lineNumber);
                        setAllItems(sortedArray);
                    }
                } else if (colId === 'binLocationId') {
                    const relevantObject = binLocations.find((x) => x._id === modifiedRow.binLocationId);
                    if (relevantObject) {
                        const newObject = {
                            ...modifiedRow,
                            binLocationName: relevantObject.binLocationName,
                            binLocationReferenceNumber: relevantObject.referenceNumber,
                        };
                        const filteredOutStocks = allItems.filter((x) => x.lineNumber !== modifiedRow.lineNumber);
                        filteredOutStocks.push(newObject);
                        const sortedArray = filteredOutStocks.sort((a, b) => a.lineNumber - b.lineNumber);
                        setAllItems(sortedArray);
                    }
                } else if (colId === 'upc') {
                    const relevantObject = allProductMasterData.find((x) => x.upc === modifiedRow.upc);
                    if (relevantObject) {
                        toast.custom((t) => <CustomToast text="Found Product Master Data" t={t} type="success" />, {
                            position: 'top-right',
                        });
                        const { productDescription, _id, unitOfMeasure } = relevantObject;
                        const availableQuantity = loadProductStock(relevantObject);
                        const newObject = {
                            ...modifiedRow,
                            productMasterDataDescription: productDescription,
                            productMasterDataId: _id,
                            productMasterData: relevantObject,
                            unitOfMeasure,
                            skuCode: relevantObject.skuCode,
                            availableQuantity,
                            adjustingQuantity: 0,
                            newCurrentStock: availableQuantity,
                            productMasterDataReferenceNumber: relevantObject.referenceNumber,
                        };

                        const filteredOutStocks = allItems.filter((x) => x.lineNumber !== modifiedRow.lineNumber);
                        filteredOutStocks.push(newObject);
                        const sortedArray = filteredOutStocks.sort((a, b) => a.lineNumber - b.lineNumber);
                        setAllItems(sortedArray);
                    } else {
                        setAllItems(modifiedStocks);
                    }
                } else if (colId === 'skuCode') {
                    const relevantObject = allProductMasterData.find((x) => x.skuCode === modifiedRow.skuCode);
                    if (relevantObject) {
                        toast.custom((t) => <CustomToast text="Found Product Master Data" t={t} type="success" />, {
                            position: 'top-right',
                        });
                        const { productDescription, _id, unitOfMeasure } = relevantObject;
                        const availableQuantity = loadProductStock(relevantObject);
                        const newObject = {
                            ...modifiedRow,
                            productMasterDataDescription: productDescription,
                            productMasterDataId: _id,
                            productMasterData: relevantObject,
                            unitOfMeasure,
                            upc: relevantObject.upc,
                            availableQuantity,
                            adjustingQuantity: 0,
                            newCurrentStock: availableQuantity,
                            productMasterDataReferenceNumber: relevantObject.referenceNumber,
                        };

                        const filteredOutStocks = allItems.filter((x) => x.lineNumber !== modifiedRow.lineNumber);
                        filteredOutStocks.push(newObject);
                        const sortedArray = filteredOutStocks.sort((a, b) => a.lineNumber - b.lineNumber);
                        setAllItems(sortedArray);
                    } else {
                        setAllItems(modifiedStocks);
                    }
                } else if (colId === 'productMasterDataReferenceNumber') {
                    const relevantObject = allProductMasterData.find(
                        (x) => x.referenceNumber === modifiedRow.productMasterDataReferenceNumber
                    );
                    if (relevantObject) {
                        toast.custom((t) => <CustomToast text="Found Product Master Data" t={t} type="success" />, {
                            position: 'top-right',
                        });
                        const { productDescription, _id, unitOfMeasure } = relevantObject;
                        const availableQuantity = loadProductStock(relevantObject);

                        const newObject = {
                            ...modifiedRow,
                            productMasterDataDescription: productDescription,
                            productMasterDataId: _id,
                            productMasterData: relevantObject,
                            unitOfMeasure,
                            skuCode: relevantObject.skuCode,
                            upc: relevantObject.upc,
                            availableQuantity,
                            adjustingQuantity: 0,
                            newCurrentStock: availableQuantity,
                        };

                        const filteredOutStocks = allItems.filter((x) => x.lineNumber !== modifiedRow.lineNumber);
                        filteredOutStocks.push(newObject);
                        const sortedArray = filteredOutStocks.sort((a, b) => a.lineNumber - b.lineNumber);
                        setAllItems(sortedArray);
                    } else {
                        setAllItems(modifiedStocks);
                    }
                } else if (colId === 'adjustingQuantity') {
                    const newObject = {
                        ...modifiedRow,
                    };

                    if (!modifiedRow.warehouseZoneId || !modifiedRow.binLocationId) {
                        toast.custom((t) => <CustomToast text="Select Zone and Bin" t={t} type="warning" />, {
                            position: 'top-right',
                        });
                        return;
                    }

                    if (!modifiedRow.methodOfAdjustment) {
                        toast.custom((t) => <CustomToast text="Select Method of Adjustment" t={t} type="warning" />, {
                            position: 'top-right',
                        });
                        return;
                    }

                    if (modifiedRow.methodOfAdjustment === 'Deduction') {
                        if (modifiedRow.adjustingQuantity > modifiedRow.availableQuantity) {
                            toast.custom(
                                (t) => <CustomToast text="Exceeding Available Quantity" t={t} type="error" />,
                                {
                                    position: 'top-right',
                                }
                            );
                            return;
                        }

                        const relevantBinStock = allStocks.find((x) => x.binLocationId === modifiedRow.binLocationId);

                        if (relevantBinStock && modifiedRow.methodOfAdjustment === 'Deduction') {
                            if (modifiedRow.adjustingQuantity > relevantBinStock.quantity) {
                                toast.custom(
                                    (t) => (
                                        <CustomToast
                                            text={`Exceeding Available Quantity In Bin ( Bin has only ${relevantBinStock.quantity}`}
                                            t={t}
                                            type="error"
                                        />
                                    ),
                                    {
                                        position: 'top-right',
                                    }
                                );
                                return;
                            }
                        }

                        newObject.newCurrentStock = modifiedRow.availableQuantity - modifiedRow.adjustingQuantity;
                    }

                    if (modifiedRow.methodOfAdjustment === 'Addition') {
                        newObject.newCurrentStock = modifiedRow.availableQuantity + modifiedRow.adjustingQuantity;
                    }

                    const filteredOutStocks = allItems.filter((x) => x.lineNumber !== modifiedRow.lineNumber);
                    filteredOutStocks.push(newObject);
                    const sortedArray = filteredOutStocks.sort((a, b) => a.lineNumber - b.lineNumber);
                    setAllItems(sortedArray);
                } else if (colId === 'methodOfAdjustment') {
                    if (!modifiedRow.warehouseZoneId || !modifiedRow.binLocationId) {
                        toast.custom((t) => <CustomToast text="Select Zone and Bin" t={t} type="warning" />, {
                            position: 'top-right',
                        });
                        return;
                    }
                    if (!modifiedRow.productMasterDataId) {
                        toast.custom((t) => <CustomToast text="Select Product" t={t} type="warning" />, {
                            position: 'top-right',
                        });
                        return;
                    }

                    const relevantBinStock = allStocks.find((x) => x.binLocationId === modifiedRow.binLocationId);

                    if (!relevantBinStock) {
                        toast.custom((t) => <CustomToast text="Stock Not Found in Bin Location" t={t} type="error" />, {
                            position: 'top-right',
                        });
                        return;
                    }

                    const relevantObject = allProductMasterData.find((x) => x._id === modifiedRow.productMasterDataId);
                    if (relevantObject) {
                        const availableQuantity = loadProductStock(relevantObject);
                        const newObject = {
                            ...modifiedRow,
                            availableQuantity,
                            adjustingQuantity: 0,
                            newCurrentStock: availableQuantity,
                        };

                        const filteredOutStocks = allItems.filter((x) => x.lineNumber !== modifiedRow.lineNumber);
                        filteredOutStocks.push(newObject);
                        const sortedArray = filteredOutStocks.sort((a, b) => a.lineNumber - b.lineNumber);
                        setAllItems(sortedArray);
                    } else {
                        setAllItems(modifiedStocks);
                    }
                } else {
                    setAllItems(modifiedStocks);
                }
            }
        }
    };

    const loadProductStock = (productData) => {
        let totalAvailableQuantity = 0;

        if (allStocks && allStocks.length > 0) {
            const { _id } = productData;
            const relevantStocks = allStocks.filter((x) => x.productMasterDataId === _id);
            relevantStocks.map((x) => {
                totalAvailableQuantity += x.quantity || 0;
                return x;
            });
        }
        return totalAvailableQuantity;
    };

    const handleActiveCellChange = (val) => {
        const changedCell = val;

        if (changedCell && changedCell.cell && allItems) {
            setActiveCell(changedCell);
            const { colId, row } = changedCell.cell;

            if (stocksAdjustmentData.isConfirmed) {
                return;
            }

            if (colId && colId === 'binLocationReferenceNumber') {
                setShowBinLocationModal(true);
                const selectedRow = allItems[row];
                if (selectedRow) {
                    setActiveRow(selectedRow);
                    return;
                }
            }
            if (colId && colId !== 'binLocationReferenceNumber' && colId !== 'warehouseZoneId') {
                const selectedRow = allItems[row];
                if (selectedRow) {
                    setActiveRow(selectedRow);
                    if (selectedRow.warehouseZoneId) {
                        const relevantWarehouse = warehouseZones.find((x) => x._id === selectedRow.warehouseZoneId);
                        if (relevantWarehouse) {
                            setSelectedWarehouseZone(relevantWarehouse);
                        }
                    }
                }
            }
        }
    };

    // Handle Select Bin Location
    const handleSelectBinLocation = (val) => {
        if (val) {
            const modifiedRow = activeRow;

            // Find Relevant bin Location
            const relevantObject = binLocations.find((x) => x._id === val);
            if (relevantObject) {
                const newObject = {
                    ...modifiedRow,
                    binLocationId: val,
                    binLocationName: relevantObject.binLocationName,
                    binLocationReferenceNumber: relevantObject.referenceNumber,
                };

                // Relevant Bin Stock
                const relevantBinStock = allStocks.find((x) => x.binLocationId === val);

                // If Bin Stock Not Found Display Error
                if (
                    modifiedRow.methodOfAdjustment &&
                    modifiedRow.methodOfAdjustment === 'Deduction' &&
                    !relevantBinStock
                ) {
                    toast.custom((t) => <CustomToast text="Stock Not Found in Bin Location" t={t} type="error" />, {
                        position: 'top-right',
                    });
                    return;
                }

                setIsItemsSaved(false);

                const filteredOutStocks = allItems.filter((x) => x.lineNumber !== modifiedRow.lineNumber); // filter out modified object
                filteredOutStocks.push(newObject); // push modified object
                const sortedArray = filteredOutStocks.sort((a, b) => a.lineNumber - b.lineNumber); // sort in order
                setAllItems(sortedArray);
                setShowBinLocationModal(false);
            }
        }
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    const handleSaveStocks = async () => {
        if (!allItems.length) {
            toast.custom((t) => <CustomToast text="Add Stocks To Save" t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        const { isValid, message } = validateItems(allItems);
        if (!isValid) {
            toast.custom((t) => <CustomToast text={message || 'Fill all Stock Fields'} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);
        const payload = {
            stocksAdjustmentId: stocksAdjustmentData._id,
            docs: allItems,
            removedDocs: allDeletedItems,
            merchantId: stocksAdjustmentData.merchantId,
            clientId: stocksAdjustmentData.clientId,
        };
        try {
            const { data } = await saveStocksAdjustmentItemBatchService(payload);
            if (data && Array.isArray(data)) {
                setAllItems(
                    data.map((x) => ({
                        ...x,
                        manufactureDate: x.manufactureDate ? new Date(x.manufactureDate) : null,
                        expiryDate: x.expiryDate ? new Date(x.expiryDate) : null,
                    }))
                );
            }
            toast.custom((t) => <CustomToast text="Successfully Saved Items" t={t} type="success" />, {
                position: 'top-right',
            });
            setIsItemsSaved(true);
        } catch (e) {
            const error = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={error} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading(false);
    };

    return (
        <>
            <Row style={{ justifyContent: 'flex-start' }}>
                <Col md={12}>
                    <ClientAlerts />
                </Col>
                {DEV_ENV() && (
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                        disabled={formLoading}
                        onClick={() => handleAddNewRow()}
                    >
                        Add Row DEV
                    </motion.button>
                )}
                <Col md={12} style={!visitingClient ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
                    <CustomGridTable
                        columns={stockItemsColumns}
                        data={allItems}
                        setData={handleOnRowChange}
                        handleActiveCellChange={handleActiveCellChange}
                        removeActions={stocksAdjustmentData.isConfirmed}
                    />
                </Col>
            </Row>
            {!stocksAdjustmentData.isConfirmed && (
                <Row style={{ justifyContent: 'flex-start', marginTop: 10, marginLeft: 10 }}>
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                        disabled={formLoading}
                        onClick={() => !formLoading && handleSaveStocks()}
                    >
                        {formLoading ? (
                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                        ) : (
                            <>
                                <i className="fas fa-save left-icon"></i>
                                <span className="h6">Save</span>
                            </>
                        )}
                    </motion.button>
                </Row>
            )}

            {scootixModalState.show && <SelectModal toggleModal={toggleScootixModal} {...scootixModalState} />}

            {showBinLocationModal && (
                <BinLocationModal
                    show
                    activeCell={activeCell}
                    binLocations={binLocations}
                    activeRow={activeRow}
                    toggleModal={() => setShowBinLocationModal(false)}
                    onConfirm={(val) => handleSelectBinLocation(val)}
                />
            )}
        </>
    );
}

const HotStockPage = hot(ItemsSheet);

export default HotStockPage;
