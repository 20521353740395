import * as React from 'react';
import PropTypes from 'prop-types';

function DeliveryAttachmentImageIcon(props) {
    const { color, width, height } = props;
    return (
        <svg
            width={width || 21}
            height={height || 21}
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2689_371)">
                <path
                    d="M13.125 7H13.1338"
                    stroke={color || '#fff'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.875 3.5H6.125C4.67525 3.5 3.5 4.67525 3.5 6.125V14.875C3.5 16.3247 4.67525 17.5 6.125 17.5H14.875C16.3247 17.5 17.5 16.3247 17.5 14.875V6.125C17.5 4.67525 16.3247 3.5 14.875 3.5Z"
                    stroke={color || '#fff'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.5 13.1252L7 9.6252C7.39905 9.24122 7.85172 9.03906 8.3125 9.03906C8.77328 9.03906 9.22595 9.24122 9.625 9.6252L14 14.0002"
                    stroke={color || '#fff'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.25 12.2502L13.125 11.3752C13.5241 10.9912 13.9767 10.7891 14.4375 10.7891C14.8983 10.7891 15.3509 10.9912 15.75 11.3752L17.5 13.1252"
                    stroke={color || '#fff'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2689_371">
                    <rect width="21" height="21" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
DeliveryAttachmentImageIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default DeliveryAttachmentImageIcon;
