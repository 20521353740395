import {
    CREATE_WAREHOUSE_SITE,
    UPDATE_WAREHOUSE_SITE,
    DELETE_WAREHOUSE_SITE,
    CREATE_WAREHOUSE_LOCATION,
    UPDATE_WAREHOUSE_LOCATION,
    DELETE_WAREHOUSE_LOCATION,
    CREATE_BIN_LOCATION,
    DELETE_BIN_LOCATION,
    UPDATE_BIN_LOCATION,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Create Warehouse Site Service
 * @param {object} data
 * @returns {Promise}
 */
export const createWarehouseSiteService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_WAREHOUSE_SITE).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Warehouse Site Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateWarehouseSiteService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_WAREHOUSE_SITE).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Warehouse Site Service
 * @param {string} id
 * @returns {Promise}
 */
export const deleteWarehouseSiteService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_WAREHOUSE_SITE).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Create Warehouse Zone Service
 * @param {object} data
 * @returns {Promise}
 */
export const createWarehouseZoneService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_WAREHOUSE_LOCATION).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Warehouse Zone Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateWarehouseZoneService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_WAREHOUSE_LOCATION).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Warehouse Zone Service
 * @param {string} id
 * @returns {Promise}
 */
export const deleteWarehouseZoneService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_WAREHOUSE_LOCATION).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Create Bin Location Service
 * @param {object} data
 * @returns {Promise}
 */
export const createBinLocationService = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_BIN_LOCATION).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Bin Location Service
 * @param {object} data
 * @returns {Promise}
 */
export const updateBinLocationService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_BIN_LOCATION).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Bin Location Service
 * @param {string} id
 * @returns {Promise}
 */
export const deleteBinLocationService = (id) => {
    try {
        const url = UrlBuilder().setUrl(DELETE_BIN_LOCATION).setPathParams({ id }).build();
        return instance.delete(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
