/* eslint-disable camelcase */
export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { userType, country, addressMeta, address } = form;
    if (!userType || !country || !addressMeta || !address) {
        return { isFormValid, message };
    }

    isFormValid = true;
    return { isFormValid, message };
};

export const validateUserName = (id, value) => {
    // eslint-disable-next-line no-useless-escape
    const specialChars = /[`!@#$%^&*()-+" "\-\[\]{};=/':"\\|,<>/?~]/;
    const str1 = '.';
    const str2 = '_';
    const count1 = value.split(str1).length - 1;
    const count2 = value.split(str2).length - 1;

    if (value.match(specialChars)) {
        return { [id]: value.replace(specialChars, '') };
    }
    if (count1 > 1) {
        return { [id]: value.replace(str1, '') };
    }
    if (count2 > 1) {
        return { [id]: value.replace(str2, '') };
    }
    return { [id]: value };
};

export const buildLocationData = (id, idMeta, data) => {
    if (id === 'address') {
        if (data) {
            const { city = '', address = '', state = null, postal_code = '' } = data;
            return {
                city,
                state,
                [id]: `${address ? `${address}` : ''}`,
                [idMeta]: data,
                postalCode: postal_code || '',
            };
        }
        return {
            [id]: null,
            [idMeta]: null,
            city: '',
            state: '',
            postalCode: '',
        };
    }
    return null;
};
