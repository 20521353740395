import PackageJson from '../package.json';

const CONFIG = {
    version: PackageJson.version,
    subVersion: '20.01', // TIME
    IS_MAINTENANCE: false,
    isForceReloadNewVersion: false,
};

export default CONFIG;
