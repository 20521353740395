/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Alert,
    Label,
    Container,
    Spinner,
    Media,
    ButtonGroup,
    Button,
    Input,
} from 'reactstrap';
import * as qs from 'query-string';
import toast from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import Avatar from 'react-avatar';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';

// #region assets
import PATHS from 'routes/paths';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {
    Select,
    ClientAlerts,
    BackdropLoader,
    TooltipDefault,
    AlertModal,
    ImageUploadModal,
    ChangePasswordModalNew,
    CustomToast,
    ScootixModal,
    PlacesAutocompleteModal,
    PlacesAutocompleteField,
    TableCustom,
} from 'components';
import { getApiErrorMessage } from 'constants/api';
import { centerElementInnerStyles, SubTitle } from 'styles';
import { THEME_COLOR, THEME_COLOR_1 } from 'theme';
import AttachmentPreviewModal from 'components/Modals/Image-Preview';
import RoleAssignModal from 'components/Modals/RoleAssignModal';
import ToolTipDefault from 'components/Tooltip';

// #region utils
import {
    apiErrorHandler,
    apiErrorHandlerV2,
    buildFormDataForMultipleNew,
    cleanAttachmentObject,
    cleanMask,
    cleanMobileNo,
    getDefaultValueForSelect,
    reactSelectCustomStyles,
} from 'utils/helpers';
import { getUser, getVisitingObject, hasAccess, IS_SUPER_ADMIN } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #region services
import { getAllRolesByClientIdService, getAllRolesByMerchantIdService } from 'services/role.service';
import {
    getAllMerchantsService,
    getMerchantsByClientIdService,
    getMerchantLocationsByVisitingMerchantIdService,
} from 'services/merchant.service';
import { getAllCountries } from 'services/countries.service';
import {
    deleteUserService,
    getUserByIdService,
    getUserVerifiedPassword,
    resendUserRegistrationVerificationUrlService,
    restoreUserAccountService,
    toggleDisableUserService,
} from 'services/user.service';
import { getUserModifiedTransactionsService } from 'services/transaction.service';
import {
    createAttachmentOpenService,
    createMultiAttachmentService,
    updateAttachmentOpenService,
} from 'services/attachment.service';
import { updateProfileService } from 'services/profile.service';
import { getAllMerchantLocationByMerchantId } from 'services/merchant-location.service';
import { getAllClientsService } from 'services/client.service';
import { TRANSACTION_REFERENCE } from 'constants/enums';
import { userFormValidation } from './utils/validations';

// #endregion imports
import {
    approveUserService,
    createUserService,
    // getAllAttachmentPresignedUrlsUserForm,
    updateUserService,
} from './services';
import { buildLocationData, validateForm, validateUserName } from './utils';
import UserAlert from '../../components/UserAlert';
import RestoreUserModel from '../../components/RestoreUserModel';
import DeleteUserModel from '../../components/DeleteUserModel';
import { transactionTableHeaderForUsers } from './constants';
import TransactionUserModel from '../../components/TransactionUserModel';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'User Management', link: PATHS.USER_PROFILE_MANAGEMENT.DEFAULT },
];

// This is the initial format for Attachments same in default state in DB
const initialAttachmentState = {
    nicFrontImageUrl: { data: null, lastUpdated: null, isDbSaved: false },
    nicBackImageUrl: { data: null, lastUpdated: null, isDbSaved: false },
};

function UserRegistration(props) {
    const history = useHistory();

    const { visitingClient, visitingMerchant } = getVisitingObject();

    let initialFormValues = {
        country: visitingClient?.country || 'Malaysia',
        isAddressMerchant: false,
    };

    const { role } = getUser();

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
            userType: 'merchant',
            role: 'merchantUser',
        };
    }
    if (visitingClient) {
        initialFormValues = {
            ...initialFormValues,
            clientId: visitingClient._id,
            clientName: visitingClient.name,
        };
    }

    // prev comp
    const [prevCompState, setPrevCompState] = useState({});

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);

    const [alertMessage, setAlertMessage] = useState(null);
    const [autoCompleteModal, setAutoCompleteModal] = useState({ type: null, show: false });

    const [showDeleteModal, setShowDeleteModal] = useState({ state: false, data: null });
    const [showApproveUserModal, setShowApproveUserModal] = useState({ state: false, data: null });
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

    // Restore and Delete modals
    const [showRestoreUserModal, setShowRestoreUserModal] = useState(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showAssignRole, setShowAssignRole] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);

    const [merchants, setMerchants] = useState([]);
    const [roles, setRoles] = useState([]);
    const [countries, setCountries] = useState([]);

    // Transaction data modal
    const [modalOptions, setModalOptions] = useState({ modal: null, data: {} });

    // transaction user details
    const [allUserTransactionsTableData, setAllUserTransactions] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: transactionTableHeaderForUsers,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    const [imageUploadModalState, setImageUploadModalState] = useState({
        show: false,
        type: null,
        data: null,
        header: null,
        title: null,
        subTitle: null,
    });

    const [attachmentPreviewModalState, setAttachmentPreviewModalState] = useState({
        show: false,
        multiple: false,
        imageUrl: null,
    });

    // attachments
    const [initialAttachmentDb, setInitialAttachmentDb] = useState(initialAttachmentState); // If Editing Mode This will be replaced with Value from DB
    const [attachmentsObj, setAttachmentsObj] = useState(initialAttachmentState); // This state handles attachments

    // images ( all Images with presigned URL are stored here )
    const [images, setImages] = useState({
        profilePictureUrl: null,
        nicBackImageUrl: null,
        nicFrontImageUrl: null,
    });

    // ONLY MERCHANT USER
    const [userWarned, setUserWarned] = useState(false);
    const [showConfirmationMerchantUserDuplicate, setShowConfirmationMerchantUserDuplicate] = useState(false);

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    // Merchant Location States
    const [allMerchantLocations, setAllMerchantLocations] = useState([]);

    const [userTypes, setUserTypes] = useState([]); // Based on this add role
    const [allClients, setAllClients] = useState([]);

    const [showSaveBtn, setShowSaveBtn] = useState(false);

    useEffect(() => {
        if (editingMode) {
            if (hasAccess(PERMISSIONS.USER_ACCOUNT, ['Edit'])) {
                setShowSaveBtn(true);
            }
        } else if (hasAccess(PERMISSIONS.USER_ACCOUNT, ['Add'])) {
            setShowSaveBtn(true);
        }
    }, [editingMode]);

    useEffect(() => {
        if (userWarned) {
            setShowConfirmationMerchantUserDuplicate(true);
        }
    }, [userWarned]);

    useEffect(() => {
        if (!props.location) {
            history.push(PATHS.USER_PROFILE_MANAGEMENT.DEFAULT);
            return;
        }

        const formType = qs.parse(props.location.search);
        if (formType && formType.type && formType.type === 'new') {
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New User', link: '#' }]);
        }

        if (formType && formType.type && formType.type === 'edit') {
            handleUpdateComponentEditMode();
        }

        if (formType && formType.type && formType.type === 'view') {
            handleUpdateComponentViewMode();
        }

        const { prevCompState = null } = props.location.state || {};
        if (prevCompState) {
            setPrevCompState(prevCompState);
        }
    }, []);

    useEffect(() => {
        const timer1 = setTimeout(() => setAlertMessage(null), 4000);
        return () => {
            clearTimeout(timer1);
        };
    }, [alertMessage]);

    useEffect(() => {
        getAllCountriesService();
    }, []);

    useEffect(() => {
        if (formData.attachmentObj) {
            // Load All Attachments Presigned Urls
            // Either this way or dont do this automatically presigned URL will load after preview modal opens
            // getAllAttachmentPresignedUrls();
            // Update Attachments Obj
            const cleanedDbObject = cleanAttachmentObject(formData.attachmentObj);
            setAttachmentsObj({ ...attachmentsObj, ...cleanedDbObject });
        }
    }, [formData.attachmentObj]);

    useEffect(() => {
        if (!visitingClient) {
            getAllClientsService().then((res) => {
                const { data } = res;
                if (data && data.docs) {
                    setAllClients([...allClients, ...data.docs]);
                    const mapClients = data.docs.map((x) => ({
                        ...x,
                        label: `${x.name || ''} - User`,
                        value: x._id,
                        type: 'client',
                    }));
                    setUserTypes([...mapClients, { label: 'Merchant User', type: 'merchant', value: 'merchant' }]);
                }
            });
        } else if (visitingClient && visitingClient._id) {
            setUserTypes([
                { label: `${visitingClient.name || ''} - User`, value: visitingClient._id, type: 'client' },
                { label: 'Merchant User', type: 'merchant', value: 'merchant' },
            ]);
        }
    }, []);

    useEffect(() => {
        formData._id && loadUserModifiedTransactions();
    }, [formData._id]);

    const loadUserModifiedTransactions = () => {
        if (formData._id) {
            getUserModifiedTransactionsService(formData._id).then((res) => {
                if (res) {
                    const { data } = res;
                    data.docs &&
                        setAllUserTransactions({
                            ...allUserTransactionsTableData,
                            ...data,
                            docs: data.docs.map((doc) => ({
                                ...doc,
                                username: doc?.user?.username,
                                transactionMadeByUserUsername: `${doc?.createdByUser?.fullName}-${doc?.createdByUser?.username}`,
                            })),
                        });
                }
            });
        }
    };

    useEffect(() => {
        if (formData.profilePictureUrl) {
            setImages((prevState) => ({ ...prevState, profilePictureUrl: formData.profilePictureUrl }));
        }
    }, [formData.profilePictureUrl]);

    // Update Address Bar URL
    // PATH | QUERY | STATE
    const handleUpdateUrl = (prevState, userData) => {
        // Handle Remove PrevComponent
        try {
            history.replace({
                pathname: `${PATHS.USER_PROFILE_MANAGEMENT.FORM}`,
                search: `?type=edit&id=${userData._id}`,
                state: {
                    userParentData: userData,
                    prevCompState: { ...prevState, refreshUser: true },
                },
            });
        } catch (e) {
            console.log(e);
        }
    };

    // /**
    //  * Load Profile Image
    //  * @param {string} profilePictureUrl
    //  */
    // const loadProfileImage = (profilePictureUrl) => {
    //     getAttachmentByKey(profilePictureUrl)
    //         .then((res) => {
    //             if (res) {
    //                 setImages((prevState) => ({ ...prevState, profilePictureUrl: res }));
    //             }
    //         })
    //         .catch((e) => {
    //             console.log(e);
    //         });
    // };

    const loadUserById = async (id) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getUserByIdService(id);
            setFormData((prevState) => ({ ...formData, ...prevState, ...data }));
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    useEffect(() => {
        if (editingMode) {
            if (formData.merchantId) {
                try {
                    getAllRolesByMerchantIdService(formData.merchantId).then(({ data }) => {
                        if (data.docs) {
                            setRoles(data.docs.map((x) => ({ ...x, label: x.roleName, value: x._id })));
                        }
                    });
                } catch (e) {
                    console.log(e);
                }
            } else if (formData.clientId) {
                try {
                    getAllRolesByClientIdService(formData.clientId).then(({ data }) => {
                        if (data.docs) {
                            setRoles(data.docs.map((x) => ({ ...x, label: x.roleName, value: x._id })));
                        }
                    });
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }, [formData.merchantId, editingMode]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    useEffect(() => {
        if (visitingMerchant && visitingMerchant._id) {
            const visitingMerchantId = visitingMerchant._id;
            getMerchantLocationsByVisitingMerchantId(visitingMerchantId);
        }
    }, []);

    useEffect(() => {
        if (formData.merchantId) {
            loadAllMerchantLocationsByMerchantId(formData.merchantId);
        }
    }, [formData.merchantId]);

    // ONLY MERCHANT USER ---------------------------------------------

    const toggleMerchantUserDuplicate = () => {
        setShowConfirmationMerchantUserDuplicate(!showConfirmationMerchantUserDuplicate);
    };

    const handleConfirmMerchantUserDuplicate = () => {
        setFormData({
            ...formData,
            skipCheckEmail: true,
        });
    };

    const handleUpdateComponentViewMode = () => {
        try {
            !props.location.state && history.push(PATHS.USER_PROFILE_MANAGEMENT.DEFAULT);
            const { userParentData } = props.location.state;
            setInitialAttachmentDb({ ...initialAttachmentDb, ...userParentData.attachmentObj });
            let userType = 'merchant';
            if (userParentData && userParentData.merchantId) {
                userType = 'merchant';
            } else if (userParentData && userParentData.clientId) {
                userType = userParentData.clientId;
            }
            setFormData({ ...formData, ...userParentData, userType });
            setIsViewMode(true);
            // setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'View User', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpdateComponentEditMode = () => {
        try {
            !props.location.state && history.push(PATHS.USER_PROFILE_MANAGEMENT.DEFAULT);
            const { userParentData } = props.location.state;
            setInitialAttachmentDb({ ...initialAttachmentDb, ...userParentData.attachmentObj });
            // Decide User Type
            let userType = 'merchant';
            if (userParentData && userParentData.clientId) {
                userType = userParentData.clientId;
            }
            if (userParentData && userParentData.merchantId) {
                userType = 'merchant';
            }

            setFormData({ ...formData, ...userParentData, userType });

            const { prevCompState = null } = props.location.state || {};
            // Update URL Based on Condition
            handleUpdateUrl(prevCompState, userParentData);
            if (prevCompState && prevCompState.refreshUser) {
                // This code is executed only during page reload
                loadUserById(userParentData._id);
            }
            setEditingMode(true);
            // setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit User', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    // ------------------------------------------------------------------

    // Merchant Location Handlers of visiting merchant
    const getMerchantLocationsByVisitingMerchantId = async (visitingMerchantId) => {
        await getMerchantLocationsByVisitingMerchantIdService(visitingMerchantId).then((res) => {
            const { data } = res;
            if (data.docs) {
                setAllMerchantLocations(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.merchantLocationName || ''} - ${x.location || ''}`,
                        key: x._id,
                    }))
                );
            }
        });
    };

    // Merchant locations for selected form data
    const loadAllMerchantLocationsByMerchantId = async (id) => {
        try {
            const { data } = await getAllMerchantLocationByMerchantId(id);
            if (data && data.docs) {
                setAllMerchantLocations(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.merchantLocationName || ''} - ${x.location || ''}`,
                        key: x._id,
                    }))
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getAllCountriesService = async () => {
        const data = await getAllCountries();
        setCountries(data);
    };

    // /**
    //  * Get All Attachments Presigned URL
    //  * NOT EFFECTIVE WAY
    //  */
    // const getAllAttachmentPresignedUrls = async () => {
    //     const data = await getAllAttachmentPresignedUrlsUserForm(formData.attachmentObj);
    //     if (data) {
    //         let allImages = {};
    //         const { nicBackImageUrl, nicFrontImageUrl } = data;
    //         allImages = {
    //             ...allImages,
    //             nicFrontImageUrl: nicFrontImageUrl ? nicFrontImageUrl.data : null,
    //             nicBackImageUrl: nicBackImageUrl ? nicBackImageUrl.data : null,
    //         };
    //         setImages((prevState) => ({ ...prevState, ...allImages }));
    //     }
    // };

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    const getSelectedRole = (_id) => {
        const relevantRole = roles.find((x) => x._id === _id);
        if (relevantRole) {
            return relevantRole.roleName;
        }
        return null;
    };

    const handleApproveUser = async () => {
        setShowApproveUserModal({ state: false, data: null });
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await approveUserService(formData._id);
            const { data } = await getUserByIdService(formData._id);
            if (data) {
                setFormData({ ...formData, ...data });
            }
            toast.custom((t) => <CustomToast text="Successfully approved User" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            console.log(e);
        }
        loadUserModifiedTransactions();
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleDeleteUser = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteUserService(formData._id);
            toast.custom((t) => <CustomToast text="Successfully deleted User" t={t} type="success" />, {
                position: 'top-right',
            });
            setTimeout(() => {
                history.push({
                    pathname: PATHS.USER_PROFILE_MANAGEMENT.DEFAULT,
                    search: `?tab=${prevCompState.activeTab || '1'}`,
                });
            }, 500);
            return;
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors && e.data.errors.userMessage) {
                toast.custom((t) => <CustomToast text={e.data.errors.userMessage} t={t} type="error" />, {
                    position: 'top-right',
                });
            } else {
                toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        }
        setBackdropLoading((prevState) => prevState - 1);
        setShowDeleteModal({ state: false, data: null });
    };

    const handleAssignRoleToUser = async () => {
        setBackdropLoading((prevState) => prevState + 1);

        try {
            const payload = formData;
            if (editingMode) {
                const { data } = await updateUserService(payload);
                if (data) {
                    setFormData({ ...formData, ...data });
                }
            }
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${formData.roleId ? 'assigned role to' : 'removed role from'} User`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            // setIsFormSaved(true);
            loadUserModifiedTransactions();
        } catch (e) {
            console.log(e);
            toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setShowAssignRole(false);
        setBackdropLoading((prevState) => prevState - 1);
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        formData.mobileNo = cleanMobileNo(formData.mobileNo);
        formData.officeNo = cleanMobileNo(formData.officeNo);
        formData.nic = cleanMask(formData.nic);

        // required code if future attachments have required parameters
        // const isAttachmentsValid = validateAttachments(Object.values(attachmentsObj).flat());
        // if (!isAttachmentsValid) {
        //     toast.custom((t) => <CustomToast text="Upload Required Images" t={t} type="warning" />, {
        //         position: 'top-right',
        //     });
        //     return;
        // }

        setFormLoading(true);

        try {
            const payload = {
                ...formData,
                fullName: `${formData.firstName} ${formData.lastName}`,
            };

            let updatedPayloadData;

            if (editingMode) {
                // Update User  ( Attachment Handling Occurs Within Service )
                const { data } = await updateUserService(payload, attachmentsObj, initialAttachmentDb);
                if (data) {
                    setFormData({ ...formData, ...data });
                    setInitialAttachmentDb(data.attachmentObj);
                    updatedPayloadData = { ...formData, ...data };
                }
            } else {
                const initialCreateData = await createUserService({
                    ...payload,
                });

                // During Creation Build FormData
                const allAttachmentFormData = buildFormDataForMultipleNew(Object.values(attachmentsObj).flat());
                // Create Multiple Attachments
                const responseAttachment = await createMultiAttachmentService(allAttachmentFormData);
                const createPayload = {
                    ...initialCreateData.data,
                    attachmentObj: {},
                };

                if (responseAttachment?.data && Array.isArray(responseAttachment.data)) {
                    if (responseAttachment.data[0] && responseAttachment.data[0].key) {
                        createPayload.attachmentObj.nicFrontImageUrl = {
                            data: responseAttachment.data[0].key || null,
                            isDbSaved: true,
                            lastUpdated: new Date(),
                        };
                    }
                    if (responseAttachment.data[1] && responseAttachment.data[1].key) {
                        createPayload.attachmentObj.nicBackImageUrl = {
                            data: responseAttachment.data[1].key || null,
                            isDbSaved: true,
                            lastUpdated: new Date(),
                        };
                    }
                }
                // Attach attachment keys and update
                const { data } = await updateUserService(createPayload);

                if (data) {
                    setFormData({ ...formData, ...data });
                    setInitialAttachmentDb(data.attachmentObj);
                    updatedPayloadData = { ...formData, ...data };
                }
                setEditingMode(true);

                try {
                    if (updatedPayloadData) {
                        history.replace({
                            pathname: `${PATHS.USER_PROFILE_MANAGEMENT.FORM}`,
                            search: `?type=edit`,
                            state: {
                                userParentData: updatedPayloadData,
                                prevCompState,
                            },
                        });
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            if (!editingMode) {
                setAlertMessage('Check Email Inbox to verify user');
            }
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} User`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            // setIsFormSaved(true);
            loadUserModifiedTransactions();
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors && e.data.errors.msg) {
                const message = getApiErrorMessage(e.data.errors.msg, e.status || null);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
                if (formData.role === 'merchantUser' && e.data.errors.msg === 'EMAIL_ALREADY_EXISTS') {
                    setUserWarned(true);
                } else {
                    setAlertMessage(message);
                }
            } else {
                toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        }
        setFormLoading(false);
        setSubmitted(false);
    };

    const updateProfilePicture = async (files) => {
        const image = files[0];
        const imageData = new FormData();
        imageData.append('file', image);
        setImageUploadModalState((prevState) => ({
            ...prevState,
            loading: true,
        }));
        let isSuccess = false;
        try {
            if (formData.profilePictureUrl) {
                const { data } = await updateAttachmentOpenService(formData.profilePictureKey, imageData, {
                    field: 'userId',
                    value: formData._id,
                });
                if (data && data.key) {
                    const response = await updateProfileService(formData._id, {
                        profilePictureKey: data.key,
                        profilePictureUrl: data.location,
                    });
                    if (response.data) {
                        setFormData({ ...formData, ...response.data });
                        setImages((prevState) => ({ ...prevState, profilePictureUrl: formData.profilePictureUrl }));
                        isSuccess = true;
                    }
                }
            } else {
                const { data } = await createAttachmentOpenService(imageData, { field: 'userId', value: formData._id });
                if (data && data.key) {
                    const response = await updateProfileService(formData._id, {
                        profilePictureKey: data.key,
                        profilePictureUrl: data.location,
                    });
                    if (response.data) {
                        setFormData({ ...formData, ...response.data });
                        setImages((prevState) => ({ ...prevState, profilePictureUrl: formData.profilePictureUrl }));
                        isSuccess = true;
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }

        if (isSuccess) {
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${formData.profilePictureUrl ? 'updated' : 'saved'} profile picture `}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
        setImageUploadModalState((prevState) => ({
            ...prevState,
            loading: false,
            show: false,
        }));
    };

    const handleInputChange = (event) => {
        // setIsFormSaved(false);
        const { id, value } = event.target;

        if (id === 'nic') {
            setFormData({
                ...formData,
                [id]: value,
                socsoNo: value.replace(/-/g, ''),
            });
            return;
        }

        if (id === 'username') {
            const userName = validateUserName(id, value);
            setFormData({ ...formData, ...userName });
            return;
        }

        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleCheckChange = (event) => {
        // setIsFormSaved(false);
        const { id, checked } = event.target;

        if (id === 'isAdmin') {
            setFormData({
                ...formData,
                [id]: checked,
                role: checked ? 'merchantAdmin' : 'merchantUser',
            });
            return;
        }

        if (checked && id === 'isAddressMerchant' && formData.userType === 'merchant' && formData.merchantId) {
            const relevantMerchant = merchants.find((x) => x._id === formData.merchantId);
            if (!relevantMerchant && !relevantMerchant.addressMeta) {
                return;
            }
            setFormData({
                ...formData,
                [id]: checked,
                addressMeta: relevantMerchant.addressMeta,
                address: relevantMerchant.address,
                city: relevantMerchant.city,
                postalCode: relevantMerchant.postalCode,
                state: relevantMerchant.state,
                country: relevantMerchant.country,
            });
            return;
        }

        setFormData({
            ...formData,
            [id]: checked,
        });
    };

    const toggleImageUploadModal = () => {
        setImageUploadModalState({
            show: false,
            data: null,
            type: null,
        });
    };

    const handleImageUpload = (header, type, title, fileTypes) => {
        setImageUploadModalState({
            show: true,
            data: null,
            header,
            type,
            title,
            fileTypes,
            onUpload: async (files) => {
                if (type === 'profilePictureUrl') {
                    await updateProfilePicture(files);
                    setImageUploadModalState((prevState) => ({
                        ...prevState,
                        loading: false,
                        show: false,
                    }));
                } else {
                    setAttachmentsObj({
                        ...attachmentsObj,
                        [type]: {
                            data: files[0],
                            lastUpdated: new Date(),
                            isDbSaved: false,
                            name: files[0].name || '',
                        },
                    });
                    setImageUploadModalState((prevState) => ({
                        ...prevState,
                        loading: false,
                        show: false,
                    }));
                }
            },
        });
    };

    const handleResendVerification = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await resendUserRegistrationVerificationUrlService({ _id: formData._id, email: formData.email });
            toast.custom((t) => <CustomToast text="Email Sent Check Email Inbox" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setBackdropLoading((prevState) => prevState - 1);
    };

    const getMerchantLocationSelect = (_id) => {
        if (_id) {
            const relevantMerchantLocation = allMerchantLocations.find((x) => x._id === _id);
            if (relevantMerchantLocation) {
                return `${relevantMerchantLocation.merchantLocationName || ''} - ${
                    relevantMerchantLocation.location || ''
                }`;
            }
        }
        return null;
    };

    const getUSerTypesSelect = (value) => {
        if (value) {
            const relevantUserType = userTypes.find((x) => x.value === value);
            if (relevantUserType) {
                return relevantUserType.label;
            }
        }
        return null;
    };

    const toggleUserDisable = async (isDisabled) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await toggleDisableUserService(formData._id);
            if (data) {
                setFormData({ ...formData, isDisabled: !isDisabled, status: !isDisabled ? 'inactive' : 'active' });
            }
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`User Successfully ${isDisabled ? 'Enabled' : 'Disabled'} `}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
        } catch (e) {
            console.log(e);
        }
        loadUserModifiedTransactions();
        setScootixModalState({ ...scootixModalState, show: null });
        setBackdropLoading((prevState) => prevState - 1);
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        // setIsFormSaved(false);

        if (event) {
            if (id === 'roleId') {
                const relevantRole = roles.find((x) => x._id === event.value);
                setFormData({
                    ...formData,
                    [id]: event ? event.value : null,
                    roleName: (relevantRole || {}).roleName || '',
                });
                return;
            }

            if (id === 'userType') {
                setFormData({
                    ...formData,
                    [id]: event.value || null,
                    role: event.value === 'merchant' ? 'merchantUser' : 'clientUser',
                    merchantId: event.value !== 'merchant' ? null : formData.merchantId,
                    merchantName: event.value !== 'merchant' ? null : formData.merchantName,
                    merchantLocation: event.value !== 'merchant' ? null : formData.merchantLocation,
                    merchantLocationMeta: event.value !== 'merchant' ? null : formData.merchantLocationMeta,
                    merchantLocationReference: event.value !== 'merchant' ? null : formData.merchantLocationReference,
                    merchantLocationId: event.value !== 'merchant' ? null : formData.merchantLocationId,
                });
                return;
            }

            if (id === 'merchantId' && event) {
                const relevantMerchant = merchants.find((x) => x._id === event.value);
                if (relevantMerchant) {
                    setFormData({
                        ...formData,
                        merchantName: relevantMerchant.name || '',
                        [id]: event ? event.value : null,
                        role: 'merchantUser',
                    });
                }
                // const filterMerchantLocations = allMerchantLocations.filter((x) => x.merchantId === event.value);
                // setFilteredMerchantLocations(filterMerchantLocations);
                return;
            }

            if (id === 'merchantLocationId') {
                const relevantMerchantLocation = allMerchantLocations.find((x) => x._id === event.value);
                if (relevantMerchantLocation) {
                    setFormData({
                        ...formData,
                        merchantLocation: relevantMerchantLocation.location,
                        merchantLocationMeta: relevantMerchantLocation.locationMeta,
                        merchantLocationReference: relevantMerchantLocation.referenceNumber,
                        [id]: event ? event.value : null,
                    });
                }
                return;
            }

            setFormData({
                ...formData,
                [id]: event ? event.value : null,
            });
        } else {
            if (id === 'merchantId') {
                setFormData({
                    ...formData,
                    merchantName: null,
                    [id]: null,
                    // role: 'clientUser',
                });
                return;
            }
            if (id === 'merchantLocationId') {
                setFormData({
                    ...formData,
                    merchantLocation: null,
                    merchantLocationMeta: null,
                    merchantLocationReference: null,
                    [id]: null,
                });
                return;
            }
            if (id === 'roleId') {
                setFormData({
                    ...formData,
                    [id]: event ? event.value : null,
                    roleName: null,
                });
                return;
            }
            setFormData({
                ...formData,
                [id]: null,
            });
        }
    };

    const handleOnDelete = () => {
        setShowDeleteModal({ state: true, data: formData });
    };

    const handleOnApprove = () => {
        setShowApproveUserModal({ state: true, data: formData });
    };

    const toggleResetPasswordModal = () => {
        setShowResetPasswordModal(!showResetPasswordModal);
    };

    const returnLocationData = (id, idMeta, data) => {
        const builtData = buildLocationData(id, idMeta, data);
        setFormData({ ...formData, ...builtData });
    };

    // when click restore the Account button popup restore modal
    const toggleRestoreModal = () => {
        setShowRestoreUserModal(!showRestoreUserModal);
    };

    // when click delete button popup delete modal
    const toggleDeleteModal = () => {
        setShowDeleteUserModal(!showDeleteUserModal);
    };

    // Handle Restore User account for requested for deletion users
    const handleRestoreUserAccount = async (formData) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            // get logged in user
            const userData = getUser();
            // verify logged in user password

            await getUserVerifiedPassword(formData, userData._id);
            // restore user data
            const { data } = await restoreUserAccountService(formData);
            if (data) {
                setFormData({ ...formData, ...data });

                // close the restore modal
                setShowRestoreUserModal(!showRestoreUserModal);
                toast.custom((t) => <CustomToast text="Successfully Restored Account" t={t} type="success" />, {
                    position: 'top-right',
                });
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    // Handle Delete User for requested for deletion users
    const handleDeleteUserAccount = async (formData) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            // get logged in user
            const userData = getUser();
            // verify logged in user password
            await getUserVerifiedPassword(formData, userData._id);
            // Delete user data
            const { data } = await deleteUserService(formData._id);
            if (data) {
                setFormData({ ...formData, ...data });
                // close delete modal
                setShowDeleteUserModal(!showDeleteUserModal);
                toast.custom((t) => <CustomToast text="Successfully Deleted Account" t={t} type="success" />, {
                    position: 'top-right',
                });
                setTimeout(() => {
                    history.push({
                        pathname: PATHS.USER_PROFILE_MANAGEMENT.DEFAULT,
                        search: `?tab=${prevCompState.activeTab || '1'}`,
                    });
                }, 500);
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    // toggle the transaction model
    const handleTransactionModalToggle = (modalName) => {
        if (modalName) {
            setModalOptions((prev) => ({ ...prev, modal: modalName }));
        } else {
            setModalOptions((prev) => ({ ...prev, modal: null }));
        }
    };

    // show transaction data modal
    const displayModal = (data) => {
        const {
            USER_ACCOUNT_RESTORED_FROM_DELETED_USERS,
            USER_APPROVED,
            USER_ACCOUNT_DELETED,
            USER_ACCOUNT_RESTORED,
            USER_ACCOUNT_PERMANENTLY_DELETED,
            USER_PASSWORD_RESET,
            USER_PROFILE_UPDATED,
            ROLE_PERMISSION_UPDATED,
            USER_PROFILE_ENABLED,
            USER_PROFILE_DISABLED,
            USER_SIGN_UP,
            USER_CREATED,
        } = TRANSACTION_REFERENCE.USER_MANAGEMENT;

        // get previous and new data
        const { meta } = data;
        if (
            data.reference === USER_ACCOUNT_RESTORED_FROM_DELETED_USERS ||
            USER_ACCOUNT_DELETED ||
            USER_ACCOUNT_RESTORED ||
            USER_ACCOUNT_PERMANENTLY_DELETED ||
            USER_PASSWORD_RESET ||
            USER_PROFILE_ENABLED ||
            USER_PROFILE_DISABLED
        ) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    name: `User’s name (Updater): ${data?.createdByUser?.fullName}`,
                },
            });
        }
        if (data.reference === USER_APPROVED) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    name: `User’s name (Approved By): ${data?.createdByUser?.fullName}`,
                },
            });
        }
        if (data.reference === USER_SIGN_UP) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    name: `User’s name: ${data?.createdByUser?.fullName}`,
                },
            });
        }
        if (data.reference === ROLE_PERMISSION_UPDATED) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    transactionReference: data.reference,
                    name: `User’s name (Updater): ${data?.createdByUser?.fullName}`,
                    newRole: `New Role: ${data?.user?.roleName}`,
                    previousRole: `New Role: ${data?.previousData?.roleName}`,
                },
            });
        }
        if (data.reference === USER_CREATED) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    transactionReference: data.reference,
                    name: `User’s name (Created By): ${data?.createdByUser?.fullName}`,
                },
            });
        }
        if (data.reference === USER_PROFILE_UPDATED) {
            setModalOptions({
                modal: 'transaction',
                data: {
                    transactionId: data.sequence,
                    transactionReference: data.reference,
                    name: `User’s name (Updater): ${data?.createdByUser?.fullName}`,
                    newUserDetails: {
                        firstName: data?.user?.firstName,
                        lastName: data?.user?.lastName,
                        designation: data?.user?.designation,
                        address: data?.user?.address,
                        department: data?.user?.department,
                        merchantName: data?.user?.merchantName,
                        clientName: data?.user?.clientName,
                        country: data?.user?.country,
                        email: data?.user?.email,
                        mobileNo: data?.user?.mobileNo,
                        officeNo: data?.user?.officeNo,
                        city: data?.user?.city,
                        postalCode: data?.user?.postalCode,
                    },
                    previousDetails: {
                        firstName: meta?.previousData?.firstName,
                        lastName: meta?.previousData?.lastName,
                        designation: meta?.previousData?.designation,
                        address: meta?.previousData?.address,
                        department: meta?.previousData?.department,
                        merchantName: meta?.previousData?.merchantName,
                        clientName: meta?.previousData?.clientName,
                        country: meta?.previousData?.country,
                        email: meta?.previousData?.email,
                        mobileNo: meta?.previousData?.mobileNo,
                        officeNo: meta?.previousData?.officeNo,
                        city: meta?.previousData?.city,
                        postalCode: meta?.previousData?.postalCode,
                    },
                },
            });
        }
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={
                            isViewMode ? 'View User Account' : editingMode ? 'Edit User Account' : 'New User Account'
                        }
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Row style={{ justifyContent: 'space-between' }}>
                        {!editingMode && (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                onClick={() => {
                                    history.push({
                                        pathname: PATHS.USER_PROFILE_MANAGEMENT.DEFAULT,
                                        search: `?tab=${prevCompState.activeTab || '1'}`,
                                    });
                                }}
                            >
                                <i className="far fa-times-circle mr-2 fa-lg" />
                                Cancel
                            </motion.div>
                        )}
                        <div>
                            <Col xs={12} md={12}>
                                {formData._id && (
                                    <>
                                        <div>
                                            <Row style={{ marginLeft: 10, justifyContent: 'center' }}>
                                                {images.profilePictureUrl ? (
                                                    <Media
                                                        className="image-upload-avatar"
                                                        onClick={() =>
                                                            handleImageUpload(
                                                                'Upload a Profile Picture',
                                                                'profilePictureUrl',
                                                                'Profile Picture',
                                                                'image/jpeg, image/png'
                                                            )
                                                        }
                                                        src={images.profilePictureUrl}
                                                        roundedCircle
                                                        style={{ borderRadius: 150, width: 70, height: 70 }}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        className="image-upload-avatar"
                                                        onClick={() =>
                                                            handleImageUpload(
                                                                'Upload a Profile Picture',
                                                                'profilePictureUrl',
                                                                'Profile Picture',
                                                                'image/jpeg, image/png'
                                                            )
                                                        }
                                                        name={formData.fullName}
                                                        size="70"
                                                        round="50px"
                                                    />
                                                )}
                                                <Col>
                                                    <h5 style={{ fontWeight: 'bold', marginBottom: 3 }}>
                                                        {formData.fullName}
                                                    </h5>
                                                    <span>{formData.email}</span>
                                                    <p>
                                                        {`Created at ${moment(formData.createdAt).format(
                                                            'DD-MM-YYYY hh:mm A'
                                                        )}`}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                )}
                            </Col>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'col' }}>
                            {editingMode && (
                                <>
                                    {hasAccess(PERMISSIONS.ASSIGN_ROLE_PERMISSION, ['View']) && (
                                        <motion.div
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-btn-radius p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                            onClick={() => setShowAssignRole(true)}
                                        >
                                            <i className="fas fa-wrench mr-2 fa-lg" />
                                            Role Permission
                                        </motion.div>
                                    )}

                                    {formData._id && hasAccess(PERMISSIONS.USER_ACCOUNT, ['Edit']) && (
                                        <motion.div
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-btn-radius p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                            onClick={() => toggleResetPasswordModal()}
                                        >
                                            <i className="fas fa-key mr-2 fa-lg" />
                                            Change Password
                                        </motion.div>
                                    )}
                                </>
                            )}
                            {editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => {
                                        history.push({
                                            pathname: PATHS.USER_PROFILE_MANAGEMENT.DEFAULT,
                                            search: `?tab=${prevCompState.activeTab || '1'}`,
                                        });
                                    }}
                                >
                                    <i className="fas fa-arrow-left mr-2 fa-lg" />
                                    Back to Users
                                </motion.div>
                            )}
                        </div>
                    </Row>
                    {/* Alert component */}
                    <Col className="mt-2 mb-4">
                        <>
                            {formData.isRequestAccountDeletion && (
                                <UserAlert
                                    toggleRestoreModal={toggleRestoreModal}
                                    toggleDeleteModal={toggleDeleteModal}
                                    requestedAt={formData.requestAccountDeletionAt}
                                    requestedVia={formData.requestAccountDeletionVia}
                                />
                            )}
                        </>
                    </Col>
                    {editingMode && !visitingClient && (
                        <>
                            <br />
                            <Alert color="dark" className="font-weight-bold" style={{ fontSize: 14 }}>
                                {' '}
                                Select Client To Update User{' '}
                            </Alert>
                        </>
                    )}
                    <Card>
                        <CardBody>
                            {!editingMode && <ClientAlerts />}
                            {/* <MerchantAlerts /> */}
                            {!formData.verified && formData._id && <Alert color="warning">User not verified!</Alert>}
                            {alertMessage && <Alert>{alertMessage}</Alert>}
                            <Row className="d-flex justify-content-between ml-3 mr-3 ml-n2 mb-2">
                                {/* <h4 className="card-title">Enter user details</h4> */}
                                <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                    USER DETAILS
                                </SubTitle>
                                {formData.role && (
                                    <div>
                                        <div
                                            style={{
                                                backgroundColor: '#EDFFFD',
                                                minWidth: 'min-content',
                                                maxWidth: 200,
                                                width: 'max-content',
                                                borderRadius: 15,
                                                fontWeight: 'bold',
                                                paddingRight: 20,
                                                paddingLeft: 20,
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                fontSize: 18,
                                            }}
                                        >
                                            {formData.role === 'merchantUser'
                                                ? 'Merchant User'
                                                : formData.role === 'clientUser'
                                                ? 'Client User'
                                                : ''}
                                        </div>
                                    </div>
                                )}
                            </Row>
                            <AvForm
                                autoComplete="off"
                                className="needs-validation default-form"
                                noValidate
                                onSubmit={handleSubmit}
                                id="userRegistrationForm"
                            >
                                <input type="hidden" value="something" />
                                <fieldset disabled={isViewMode || formLoading}>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="user-type-select">User Type</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(
                                                        getUSerTypesSelect(formData.userType)
                                                    )}
                                                    // id="userType"
                                                    options={userTypes}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'userType')}
                                                    submitted={submitted}
                                                    validated={validated}
                                                    // isClearable
                                                    required
                                                    isDisabled={editingMode || visitingMerchant}
                                                    id="user-type-select"
                                                />
                                            </FormGroup>
                                        </Col>
                                        {(formData.role === 'merchantUser' || formData.role === 'merchantAdmin') && (
                                            <>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="merchantId">Merchant</Label>
                                                        <Select
                                                            value={getDefaultValueForSelect(
                                                                getMerchantSelect(formData.merchantId)
                                                            )}
                                                            options={merchants}
                                                            isDisabled={
                                                                role === 'merchantAdmin' ||
                                                                editingMode ||
                                                                role === 'merchantUser' ||
                                                                visitingMerchant
                                                            }
                                                            id="merchantId"
                                                            styles={reactSelectCustomStyles}
                                                            onChange={(event) =>
                                                                handleSelectChange(event, 'merchantId')
                                                            }
                                                            submitted={submitted}
                                                            validated={validated}
                                                            // required={formData.userType === 'merchant'}
                                                            isClearable
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="merchantLocationId">Merchant Locations</Label>
                                                        <Select
                                                            value={getDefaultValueForSelect(
                                                                getMerchantLocationSelect(formData.merchantLocationId)
                                                            )}
                                                            options={allMerchantLocations.map((x) => ({
                                                                label: x.label,
                                                                value: x.key,
                                                            }))}
                                                            id="merchantLocationId"
                                                            styles={reactSelectCustomStyles}
                                                            onChange={(event) =>
                                                                handleSelectChange(event, 'merchantLocationId')
                                                            }
                                                            submitted={submitted}
                                                            validated={validated}
                                                            // required={formData.userType === 'merchant'}
                                                            isClearable
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        )}
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="email">E-Mail Address</Label>
                                                <AvField
                                                    name="email"
                                                    placeholder="E-mail"
                                                    type="email"
                                                    errorMessage="Enter a valid email address"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    disabled={formData.isApproved}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="username">Username</Label>
                                                <AvField
                                                    name="username"
                                                    placeholder="Username"
                                                    type="text"
                                                    className="form-control"
                                                    id="username"
                                                    value={formData.username}
                                                    onChange={handleInputChange}
                                                    disabled={editingMode}
                                                    autoComplete="new-password"
                                                    validate={userFormValidation.username}
                                                />
                                            </FormGroup>
                                            <ToolTipDefault
                                                targetId="username"
                                                text="Allowed characters are [A-Z] , [0-9] , [ . _ ]"
                                                position="top"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="firstName">First name</Label>
                                                <AvField
                                                    name="firstName"
                                                    placeholder="First name"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="firstName"
                                                    value={formData.firstName}
                                                    onChange={handleInputChange}
                                                    autoComplete="new-password"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="lastName">Last name</Label>
                                                <AvField
                                                    name="lastName"
                                                    placeholder="Last name"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="lastName"
                                                    value={formData.lastName}
                                                    onChange={handleInputChange}
                                                    autoComplete="new-password"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="mobileNo">Mobile No</Label>
                                                <AvField
                                                    name="mobileNo"
                                                    placeholder="Mobile No"
                                                    mask="+60 99-999 99999"
                                                    maskChar=""
                                                    tag={[Input, InputMask]}
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="mobileNo"
                                                    value={formData.mobileNo}
                                                    onChange={handleInputChange}
                                                    disabled={formData.isApproved}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="officeNo">Office No</Label>
                                                <AvField
                                                    name="officeNo"
                                                    placeholder="Office No"
                                                    mask="+609-99999999"
                                                    maskChar=""
                                                    tag={[Input, InputMask]}
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="officeNo"
                                                    value={formData.officeNo}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="designation">Designation</Label>
                                                <AvField
                                                    name="designation"
                                                    placeholder="Designation"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="designation"
                                                    value={formData.designation}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="department">Department</Label>
                                                <AvField
                                                    name="department"
                                                    placeholder="Department"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="department"
                                                    value={formData.department}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="nic">
                                                    NRIC Number{' '}
                                                    {formData.userType === 'merchant' ? ' ( Optional )' : ''}
                                                </Label>
                                                <AvField
                                                    name="nic"
                                                    placeholder="NRIC"
                                                    mask="999999-99-9999"
                                                    maskChar="-"
                                                    har=""
                                                    tag={[Input, InputMask]}
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{
                                                        required: {
                                                            value: false,
                                                        },
                                                    }}
                                                    id="nic"
                                                    onChange={handleInputChange}
                                                    value={formData.nic}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="socsoNo">SOCSO Number</Label>
                                                <AvField
                                                    name="socsoNo"
                                                    placeholder="SOCSO"
                                                    type="text"
                                                    errorMessage="Please provide a valid SOCSO."
                                                    maxLength="12"
                                                    className="form-control"
                                                    id="socsoNo"
                                                    validate={{ required: { value: false } }}
                                                    disabled
                                                    value={formData.socsoNo}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="epf">
                                                    EPF Number {formData.userType === 'merchant' ? ' ( Optional )' : ''}
                                                </Label>
                                                <AvField
                                                    name="epf"
                                                    placeholder="EPF Number"
                                                    type="number"
                                                    errorMessage="Please provide a valid EPF Number."
                                                    className="form-control"
                                                    id="epf"
                                                    value={formData.epf}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="3">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Label htmlFor="nic">
                                                    Upload NRIC (FRONT){' '}
                                                    {attachmentsObj.nicFrontImageUrl.data &&
                                                        attachmentsObj.nicFrontImageUrl.data.name && (
                                                            <div>{attachmentsObj.nicFrontImageUrl.data.name}</div>
                                                        )}
                                                </Label>
                                                <ButtonGroup>
                                                    {attachmentsObj.nicFrontImageUrl.data && (
                                                        <Button
                                                            color="warning"
                                                            onClick={() =>
                                                                setAttachmentPreviewModalState({
                                                                    type: 'default',
                                                                    show: true,
                                                                    header: `NRIC Number  ${
                                                                        formData.nic ? `- ${formData.nic}` : ''
                                                                    }`,
                                                                    imageUrl: attachmentsObj.nicFrontImageUrl.data,
                                                                    isDbSaved:
                                                                        attachmentsObj.nicFrontImageUrl.isDbSaved,
                                                                })
                                                            }
                                                            id="view-nric-front-btn"
                                                        >
                                                            View File
                                                        </Button>
                                                    )}

                                                    <Button
                                                        color="warning"
                                                        onClick={() => {
                                                            handleImageUpload(
                                                                'Upload NRIC Number (FRONT)',
                                                                'nicFrontImageUrl',
                                                                'NRIC Number (FRONT)'
                                                            );
                                                        }}
                                                        id="upload-nric-front-btn"
                                                    >
                                                        {attachmentsObj.nicFrontImageUrl.data ? 'Update' : 'Upload'}
                                                    </Button>
                                                </ButtonGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Label htmlFor="nic">
                                                    Upload NRIC (BACK){' '}
                                                    {attachmentsObj.nicBackImageUrl.data &&
                                                        attachmentsObj.nicBackImageUrl.data.name && (
                                                            <div>{attachmentsObj.nicBackImageUrl.data.name}</div>
                                                        )}
                                                </Label>
                                                <ButtonGroup>
                                                    {attachmentsObj.nicBackImageUrl.data && (
                                                        <Button
                                                            color="warning"
                                                            onClick={() =>
                                                                setAttachmentPreviewModalState({
                                                                    type: 'default',
                                                                    show: true,
                                                                    header: `NRIC Number  ${
                                                                        formData.nic ? `- ${formData.nic}` : ''
                                                                    }`,
                                                                    imageUrl: attachmentsObj.nicBackImageUrl.data,
                                                                    isDbSaved: attachmentsObj.nicBackImageUrl.isDbSaved,
                                                                })
                                                            }
                                                            id="nric-back-upload-btn"
                                                        >
                                                            View File
                                                        </Button>
                                                    )}
                                                    <Button
                                                        color="warning"
                                                        onClick={() => {
                                                            handleImageUpload(
                                                                'Upload NRIC Number (BACK)',
                                                                'nicBackImageUrl',
                                                                'NRIC Number (BACK)'
                                                            );
                                                        }}
                                                        id="upload-nric-back-btn"
                                                    >
                                                        {attachmentsObj.nicBackImageUrl.data ? 'Update' : 'Upload'}
                                                    </Button>
                                                </ButtonGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {formData.userType === 'merchant' && formData.merchantId && (
                                        <Row className="mb-3" style={{ paddingLeft: 30 }}>
                                            <Label htmlFor="customCheck2" className="form-check-label">
                                                Address Same As Merchant
                                            </Label>
                                            <Input
                                                onChange={handleCheckChange}
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={formData.isAddressMerchant}
                                                defaultChecked={formData.isAddressMerchant}
                                                id="isAddressMerchant"
                                            />
                                        </Row>
                                    )}
                                    <Row>
                                        <Col md="4">
                                            <Row>
                                                <Col md="10" sm="10" xs="10">
                                                    <FormGroup>
                                                        <Label htmlFor="address">Address</Label>
                                                        <PlacesAutocompleteField
                                                            addressData={formData.addressMeta || null}
                                                            returnLocationData={returnLocationData}
                                                            id="address"
                                                            idMeta="addressMeta"
                                                            isRequired
                                                            submitted={submitted}
                                                            validated={validated}
                                                            isClearable
                                                        />
                                                        {autoCompleteModal.type === 'address' &&
                                                            autoCompleteModal.show &&
                                                            !isViewMode && (
                                                                <PlacesAutocompleteModal
                                                                    show={autoCompleteModal.show}
                                                                    isModal
                                                                    addressData={formData.addressMeta || null}
                                                                    returnLocationData={returnLocationData}
                                                                    toggle={() =>
                                                                        setAutoCompleteModal({
                                                                            show: !autoCompleteModal.show,
                                                                            type: 'address',
                                                                        })
                                                                    }
                                                                    id="address"
                                                                    idMeta="addressMeta"
                                                                    fieldId="user-address-field"
                                                                />
                                                            )}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="2" sm="2" xs="2">
                                                    <motion.div
                                                        style={{ width: 35, height: 35 }}
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        className="scootix-btn-circle shadow-lg nw-md ml-3 order-map-pin-container"
                                                        onClick={() =>
                                                            setAutoCompleteModal({
                                                                show: !autoCompleteModal.show,
                                                                type: 'address',
                                                            })
                                                        }
                                                    >
                                                        <div className="map-pin">
                                                            <i className="ri-map-pin-line fa-lg" />
                                                        </div>
                                                    </motion.div>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="postalCode">Postal Code</Label>
                                                <AvField
                                                    name="postalCode"
                                                    placeholder="Postal Code"
                                                    type="Number"
                                                    minLength={5}
                                                    maxLength={5}
                                                    errorMessage="Please provide a valid Postal Code."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="postalCode"
                                                    value={formData.postalCode}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="city">City</Label>
                                                <AvField
                                                    name="city"
                                                    placeholder="City"
                                                    type="text"
                                                    errorMessage="Please provide a valid city."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="city"
                                                    value={formData.city}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="state">State</Label>
                                                <AvField
                                                    name="state"
                                                    placeholder="State"
                                                    type="text"
                                                    errorMessage="Please provide a valid state."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="state"
                                                    value={formData.state}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="country">Country</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(formData.country)}
                                                    options={countries.map((country) => ({
                                                        label: country.details.name,
                                                        value: country.details.name,
                                                    }))}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'country')}
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {/* <Row>
                                        <Col md="4">
                                            <AvCheckboxGroup name="checkboxExample" label="Other">
                                                <AvCheckbox
                                                    label="Admin"
                                                    checked={formData.isAdmin}
                                                    id="isAdmin"
                                                    errorMessage="Required"
                                                    onChange={handleCheckChange}
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row> */}
                                    <div className="d-flex row ml-2 mt-2">
                                        {!isViewMode &&
                                            (visitingClient || IS_SUPER_ADMIN()) &&
                                            visitingClient &&
                                            showSaveBtn && (
                                                <Row style={{ justifyContent: 'flex-start' }}>
                                                    <motion.button
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                        type="submit"
                                                        disabled={formLoading}
                                                        id="form-submit-btn"
                                                    >
                                                        {formLoading ? (
                                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                        ) : (
                                                            <>
                                                                <i className="fas fa-save left-icon"></i>
                                                                <span className="h6">
                                                                    {editingMode ? 'Update' : 'Create User'}
                                                                </span>
                                                            </>
                                                        )}
                                                    </motion.button>
                                                </Row>
                                            )}
                                        {formData._id && editingMode && !formData.verified && (
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 ml-3"
                                                onClick={() => handleResendVerification()}
                                            >
                                                <span>Resend Verification</span>
                                            </motion.button>
                                        )}
                                        {/* {formData._id &&
                                            editingMode &&
                                            !formData.verified &&
                                            formData.role === 'merchantAdmin' && (
                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                    // onClick={() => setShowVerification(true)}
                                                >
                                                    <span>Verify User</span>
                                                </motion.button>
                                            )} */}
                                    </div>
                                </fieldset>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Container>
                <br />

                {(editingMode || isViewMode) && hasAccess(PERMISSIONS.APPROVE_USER_ACCOUNT, ['View']) && (
                    <Container fluid>
                        <Card>
                            <CardBody>
                                <div
                                    style={{
                                        justifyContent: 'space-between',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'col' }}>
                                        <div>USER ACCOUNT STATUS</div>
                                        <div
                                            className="ml-3 pr-2 pl-2"
                                            style={{
                                                textTransform: 'capitalize',
                                                borderRadius: 20,
                                                height: 20,
                                                backgroundColor: formData.status === 'active' ? '#81EE6F' : '#FFCBD4',
                                            }}
                                        >
                                            {(formData.status || '').toUpperCase()}
                                        </div>
                                    </div>
                                    {!isViewMode && !formData.isRequestAccountDeletion && (
                                        <div className="d-flex row">
                                            {!formData.isApproved && formData.verified && (
                                                <div>
                                                    {hasAccess(PERMISSIONS.APPROVE_USER_ACCOUNT, ['Approve']) && (
                                                        <motion.div
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.9 }}
                                                            className={`scootix-btn-radius pr-3 pl-3 shadow-lg nw-md ${
                                                                formData.isApproved ? 'disabled-div ' : ''
                                                            }`}
                                                            style={{ height: 35 }}
                                                            onClick={() => handleOnApprove()}
                                                        >
                                                            <i className="fas fa-eye left-icon fa-lg"></i>
                                                            Approve User
                                                        </motion.div>
                                                    )}
                                                </div>
                                            )}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'col',
                                                    ...centerElementInnerStyles,
                                                }}
                                            >
                                                {formData.isApproved &&
                                                    hasAccess(PERMISSIONS.APPROVE_USER_ACCOUNT, ['Approve']) && (
                                                        <div className="mr-3 mt-2" id="disable-switch">
                                                            <Switch
                                                                uncheckedIcon={<div />}
                                                                checkedIcon={<div />}
                                                                onColor="#FAA123"
                                                                offColor="#808080"
                                                                onChange={() => {
                                                                    setScootixModalState({
                                                                        ...scootixModalState,
                                                                        show: true,
                                                                        message: 'Are you sure you want to proceed?',
                                                                        onConfirm: () =>
                                                                            toggleUserDisable(formData.isDisabled),
                                                                    });
                                                                }}
                                                                checked={!formData.isDisabled}
                                                            />
                                                            <TooltipDefault
                                                                text={`${
                                                                    formData.isDisabled ? 'Enable' : 'Disable'
                                                                } User`}
                                                                targetId="disable-switch"
                                                            />
                                                        </div>
                                                    )}

                                                <div>
                                                    {hasAccess(PERMISSIONS.USER_ACCOUNT, ['Delete']) && (
                                                        <motion.div
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.9 }}
                                                            className="scootix-btn-radius secondary-btn pr-3 pl-3 shadow-lg nw-md ml-2"
                                                            style={{ height: 35 }}
                                                            onClick={() => handleOnDelete()}
                                                        >
                                                            <i className="fas fa-minus-circle left-icon fa-lg"></i>
                                                            Delete User
                                                        </motion.div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <hr />
                                <div>
                                    <div style={{ color: THEME_COLOR_1 }}>
                                        <i className="fas fa-history" style={{ color: THEME_COLOR }} /> Transaction Log
                                    </div>

                                    <TableCustom
                                        id="warehouse-site-location-id"
                                        tableData={allUserTransactionsTableData}
                                        removeAction
                                        showPagination={false}
                                        showView={false}
                                        showEdit={false}
                                        removeId={false}
                                        filters={[]}
                                        isSearch={false}
                                        isFilter={false}
                                        showTransactionDataRecords
                                        customActionHandlers={{ displayModal }}
                                    />
                                    {/* {allUserTransactionsTableData &&
                                        allUserTransactionsTableData.map((x) => (
                                            <>
                                                <div className="m-2">
                                                    {' '}
                                                    {moment(x.createdAt).format('YYYY-MM-DD')} at{' '}
                                                    {moment(x.createdAt).format('HH.mm A')} - {x.description}
                                                </div>
                                            </>
                                        ))} */}
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                )}
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.8} />

            {showConfirmationMerchantUserDuplicate && (
                <SweetAlert
                    showCancel
                    // title="User already exists with email, are you sure you want to create account using email ?"
                    cancelBtnBsStyle="danger"
                    confirmBtnBsStyle="success"
                    onConfirm={() => {
                        handleConfirmMerchantUserDuplicate();
                        toggleMerchantUserDuplicate();
                    }}
                    onCancel={() => toggleMerchantUserDuplicate()}
                >
                    User already exists with email, are you sure you want to create account using email ?
                </SweetAlert>
            )}

            {showAssignRole && (
                <RoleAssignModal
                    show={showAssignRole}
                    showCancel
                    title="Assign A Role"
                    cancelBtnBsStyle="danger"
                    confirmBtnBsStyle="success"
                    onConfirm={handleAssignRoleToUser}
                    onCancel={() => setShowAssignRole(false)}
                    onChange={(value) => handleSelectChange(value, 'roleId')}
                    toggleModal={() => setShowAssignRole(!showAssignRole)}
                    formData={formData}
                    roles={roles}
                    getSelectedRole={getSelectedRole}
                />
            )}

            <AlertModal
                show={showDeleteModal.state}
                onConfirm={handleDeleteUser}
                data={showDeleteModal.data}
                onHide={() =>
                    setShowDeleteModal({
                        state: false,
                        data: null,
                    })
                }
            />

            <AlertModal
                show={showApproveUserModal.state}
                onConfirm={handleApproveUser}
                data={showApproveUserModal.data}
                onHide={() =>
                    setShowApproveUserModal({
                        state: false,
                        data: null,
                    })
                }
            />

            {imageUploadModalState.show && (
                <ImageUploadModal toggleModal={toggleImageUploadModal} {...imageUploadModalState} />
            )}

            {attachmentPreviewModalState.show && (
                <AttachmentPreviewModal
                    image={attachmentPreviewModalState.imageUrl}
                    show={attachmentPreviewModalState.show}
                    type={attachmentPreviewModalState.type}
                    header={attachmentPreviewModalState.header}
                    toggleModal={() => {
                        setAttachmentPreviewModalState({ show: false, multiple: false });
                    }}
                    {...attachmentPreviewModalState}
                    isFormAttachmentModal
                />
            )}

            {showResetPasswordModal && formData._id && (
                <ChangePasswordModalNew userData={formData} toggleModal={toggleResetPasswordModal} />
            )}

            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />

            {/* Restore Modal */}
            {showRestoreUserModal && (
                <RestoreUserModel
                    show={showRestoreUserModal}
                    onclose={setShowRestoreUserModal}
                    handleRestoreUserAccount={handleRestoreUserAccount}
                    userData={formData}
                />
            )}

            {/* Delete Modal */}
            {showDeleteUserModal && (
                <DeleteUserModel
                    show={showDeleteUserModal}
                    onclose={setShowDeleteUserModal}
                    handleDeleteUserAccount={handleDeleteUserAccount}
                    userData={formData}
                />
            )}

            {/* Transaction Modal */}
            <TransactionUserModel
                show={modalOptions.modal === 'transaction'}
                handleToggle={handleTransactionModalToggle}
                transactionId={modalOptions.data.transactionId}
                name={modalOptions.data.name}
                newUserDetails={modalOptions.data.newUserDetails}
                previousDetails={modalOptions.data.previousDetails}
                transactionReference={modalOptions.data.transactionReference}
                newRole={modalOptions.data.newRole}
                previousRole={modalOptions.data.previousRole}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

const HotUserRegistrationPage = hot(UserRegistration);

export default connect(mapStateToProps, {})(HotUserRegistrationPage);
