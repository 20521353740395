import React from 'react';
import { Card, CardBody, Spinner } from 'reactstrap';
import ToolTipDefault from 'components/Tooltip';
import PropTypes from 'prop-types';
/**
 * This is the card for graphs
 * @param {title: string, value: string, icon: Icon, tooltipTitle: string, tooltipPlacement: string, iconSize: string, customClassName: string, cardStyle: styleObject, children: React.Component, percentage: string} props
 * @returns GraphCard component
 */
function AnalyticsCard(props) {
    const {
        title = '',
        value = '',
        icon,
        tooltipTitle = '',
        tooltipPlacement = 'right',
        iconSize = 'fa-2x',
        customClassName = '',
        iconProps,
        cardStyle = {},
        percentage = '',
        loading = false,
    } = props;

    const cardId = `${title.toLowerCase().replace(/ /g, '')}-stat-card`;

    return (
        <React.Fragment>
            <Card
                className={`shadow m-1 ${customClassName}`}
                id={cardId}
                style={{ ...cardStyle, backgroundColor: cardStyle.backgroundColor || '#FFF', minHeight: 100 }}
            >
                <CardBody className="pt-3 pb-3 pr-2 pl-2">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <div>
                            <img
                                src={icon}
                                alt="Stat Icon"
                                className={`home-card-stat-icon ${iconSize} `}
                                {...iconProps}
                            />
                        </div>
                        <div className="ml-1 mr-1 p-1">
                            <h6 className="mb-2 font-weight-bold" style={{ color: '#3A3937', fontSize: '16px' }}>
                                {title}
                            </h6>
                            {loading ? (
                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                            ) : (
                                <h6
                                    className="text-muted font-weight-normal"
                                    style={{ color: '#3A3937', fontSize: '16px', fontWeight: 300 }}
                                >
                                    {value}
                                </h6>
                            )}
                        </div>
                    </div>
                    {percentage && (
                        <div
                            className="float-right"
                            style={{
                                color: '#3A3937',
                                fontSize: '16px',
                                fontWeight: 400,
                                position: 'absolute',
                                bottom: 10,
                                right: 10,
                            }}
                        >
                            {percentage}
                        </div>
                    )}
                </CardBody>
            </Card>
            <ToolTipDefault targetId={cardId} text={tooltipTitle} position={tooltipPlacement} />
        </React.Fragment>
    );
}

AnalyticsCard.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    tooltipTitle: PropTypes.string,
    iconSize: PropTypes.string,
    customClassName: PropTypes.string,
    iconProps: PropTypes.any,
    cardStyle: PropTypes.any,
    percentage: PropTypes.string,
    loading: PropTypes.bool,
};

export default AnalyticsCard;
