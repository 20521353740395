/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useMemo } from 'react';
import { isObject } from 'lodash';
import { sortObject } from 'utils/helpers';

function MapDeliveryDocument(props) {
    const { deliveryData } = props;

    const displayValue = (key, value, isSubDocument) => {
        if (typeof value === 'string') {
            return (
                <>
                    <div style={{ marginLeft: isSubDocument ? 20 : 0 }}>
                        {key} : {value}
                    </div>
                </>
            );
        }
        if (typeof value === 'boolean') {
            return (
                <>
                    <div style={{ marginLeft: isSubDocument ? 20 : 0 }}>
                        {key} : {String(value)}
                    </div>
                </>
            );
        }
        if (typeof value === 'number') {
            return (
                <>
                    <div style={{ marginLeft: isSubDocument ? 20 : 0 }}>
                        {key} : {String(value)}
                    </div>
                </>
            );
        }
        if (isObject(value)) {
            return <>{mapData(value, true)}</>;
        }

        return <></>;
    };

    function mapData(objectData, subDocument = false) {
        return (
            <>
                {Object.keys(objectData).map((key) => (
                    <>{displayValue(key, objectData[key], subDocument)}</>
                ))}
            </>
        );
    }

    const mapDataMemo = useMemo(
        () => () => {
            if (deliveryData) {
                const sortedData = sortObject(deliveryData);
                return <>{mapData(sortedData)}</>;
            }
            return <></>;
        },
        [deliveryData._id]
    );

    return (
        <>
            <h4>Delivery Document</h4>
            <br />
            <div className="delivery_records_map">{mapDataMemo()}</div>
        </>
    );
}

export default MapDeliveryDocument;
