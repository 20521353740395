/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import PropTypes from 'prop-types';

function ChipCard(props) {
    const { bgColor = '#D9D9D9', title = '', cardStyle = {}, handleChipClick } = props;
    return (
        <button
            className="pl-2 pr-2 pt-2 pb-2 ml-3 mb-2"
            style={{
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: bgColor,
                borderRadius: 20,
                width: 200,
                ...cardStyle,
            }}
            onClick={() => handleChipClick()}
        >
            {title}
        </button>
    );
}
ChipCard.propTypes = {
    title: PropTypes.string,
    bgColor: PropTypes.string,
    cardStyle: PropTypes.any,
    handleChipClick: PropTypes.func,
};

export default ChipCard;
