/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */

import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Row, Col, Card, CardBody, FormGroup, Label, Spinner, Input, Container } from 'reactstrap';
import toast from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import * as qs from 'query-string';
import { useHistory } from 'react-router-dom';

// #region assets | components
import { BackdropLoader, CustomToast, TableCustom } from 'components';
import { apiErrorHandler, apiErrorHandlerV2, formatter, getDateValue } from 'utils/helpers';
import Hoc from 'components/Hoc';

// #region imports
import PATHS from 'routes/paths';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { SubTitle } from 'styles';

// #region services
import { updateBillingRecordService } from 'services/billing-record.service';

// #endregion imports
import { getRiderPaymentStatus } from '../utils';
import { initialDeliveryData } from './constants';
import { getDeliveriesByBillingRecordId, getRiderCommissionRecordByBillingRecordId } from '../../services';
import { mapDocs } from './utils';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Rider Commissions', link: PATHS.PAYMENTS.RIDER_COMMISSIONS_LIST },
];

function RiderCommissionRecordFormPage(props) {
    // Component
    const [loading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading] = useState(0);

    const history = useHistory();

    const [formData, setFormData] = useState({ currency: 'MYR' });
    const [paymentStatus, setPaymentStatus] = useState('Unpaid');

    // Form State
    const [validated, setValidated] = useState(false);
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);

    const [deliveryData, setDeliveryData] = useState(initialDeliveryData);

    useEffect(() => {
        if (!props.location) {
            history.goBack();
            return;
        }

        // eslint-disable-next-line react/prop-types
        const formType = qs.parse(props.location.search);

        if (formType && formType.type && formType.type === 'edit') {
            handleUpdateComponentEditMode();
        }
    }, []);

    useEffect(() => {
        let balanceAmount = formData.riderCommission - formData.riderPaidAmount;
        if (balanceAmount < 0) {
            balanceAmount = 0;
        }

        setFormData((prevState) => ({
            ...prevState,
            riderBalanceAmount: balanceAmount.toFixed(2),
        }));
    }, [formData.riderPaidAmount]);

    const handleSubmit = async (event, errors) => {
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        let updatedPayloadData;

        setFormLoading(true);

        try {
            const payload = formData;
            const { data } = await updateBillingRecordService(payload);

            if (data) {
                const { riderCommission, riderPaidAmount } = data;
                const riderBalanceAmount = riderCommission - riderPaidAmount;

                setPaymentStatus(getRiderPaymentStatus(riderBalanceAmount));
                setFormData({ ...formData, ...data });
                updatedPayloadData = { ...formData, ...data };
            }

            try {
                if (updatedPayloadData) {
                    history.replace({
                        pathname: `${PATHS.PAYMENTS.RIDER_COMMISSION_RECORD}`,
                        search: `?type=edit&id=${data._id}`,
                        state: {
                            riderCommissionRecordParentData: updatedPayloadData,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }

            toast.custom(
                (t) => <CustomToast text="Successfully updated Rider Commission Record" t={t} type="success" />,
                {
                    position: 'top-right',
                }
            );
        } catch (e) {
            console.log(e);
            const errorMessage = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading(false);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    /** Opens the delivery page in a new tab */
    const handleViewDelivery = (row) => {
        if (!row) return;
        window.open(`${PATHS.DELIVERY.FORM}?type=edit&id=${row._id}`, '_blank');
    };

    /**
     * Retrieves deliveries for a rider commission by billingRecordId and updates the delivery data state.
     * @param {string} id - The ID of the billing record.
     * @returns {Promise<void>} - A promise that resolves when the deliveries have been retrieved and the state has been updated.
     */
    const getDeliveries = async (id) => {
        try {
            const { data } = await getDeliveriesByBillingRecordId(id);
            if (data && data.deliveries) {
                const docs = mapDocs(data?.deliveries);
                setDeliveryData({ ...deliveryData, docs });
            }
        } catch (error) {
            const { message } = apiErrorHandlerV2(error);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    const handleUpdateComponentEditMode = async () => {
        try {
            // get billing record id from url
            const urlSearch = qs.parse(props.location.search);
            const { id } = urlSearch;

            if (!id && !props.location.state) {
                history.push(PATHS.PAYMENTS.RIDER_COMMISSIONS_LIST);
                return;
            }
            await getDeliveries(id);
            const { data } = await getRiderCommissionRecordByBillingRecordId(id);

            try {
                if (data) {
                    history.replace({
                        pathname: `${PATHS.PAYMENTS.RIDER_COMMISSION_RECORD}`,
                        search: `?type=edit&id=${data._id}`,
                        state: {
                            riderCommissionRecordParentData: data,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }

            const { riderCommission, riderPaidAmount } = data;
            const riderBalanceAmount = riderCommission - riderPaidAmount;

            setPaymentStatus(getRiderPaymentStatus(riderBalanceAmount));
            setFormData({
                ...formData,
                ...data,
                riderBalanceAmount: data.riderCommission - data.riderPaidAmount,
            });
            // Update URL Based on Condition
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Rider Commission Setting', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <Hoc {...props}>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                        <Row
                            style={{
                                margin: 2,
                                justifyContent: 'space-between',
                            }}
                        >
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                <i className="fas fa-arrow-left mr-2 fa-lg" />
                                Back to Rider Commissions
                            </motion.div>
                        </Row>

                        <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                            <CardBody>
                                <Row className="mt-4">
                                    <Col>
                                        <h6>Pickup Address</h6>
                                        <h5>
                                            <b>{`${formData.pickupLocation || ''}`}</b>
                                        </h5>
                                    </Col>
                                    <Col>
                                        <h6>Delivery Address</h6>
                                        <h5>
                                            <b>{`${formData.deliveryAddress || ''}`}</b>
                                        </h5>
                                    </Col>
                                    <Col>
                                        <h6>Rider</h6>
                                        <h5>
                                            <b>{`${formData?.riderId?.fullName || formData.riderName || ''}`}</b>
                                        </h5>
                                    </Col>
                                    <Col>
                                        <h6>Distance</h6>
                                        <h5>
                                            <b>{`${formData.distance || 0} KM`}</b>
                                        </h5>
                                    </Col>
                                    <Col>
                                        <h6>Rider Commission Method</h6>
                                        <h5>
                                            <b>{`${formData.riderCommissionMethod || ''}`}</b>
                                        </h5>
                                    </Col>
                                    <Col>
                                        <h6>Rider Commission</h6>
                                        <h5>
                                            <b>{`MYR ${formatter.format(
                                                formData.riderCommission?.toFixed(2) || 0
                                            )}`}</b>
                                        </h5>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row style={{ justifyContent: 'space-between', alignItems: 'center', margin: 2 }}>
                                    <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                        COMMISSIONS TO RIDER
                                    </SubTitle>
                                    <SubTitle
                                        style={{
                                            color: '#574b90',
                                            letterSpacing: 0.5,
                                            marginLeft: -2,
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {paymentStatus}
                                    </SubTitle>
                                </Row>
                                <br />
                                <AvForm
                                    autoComplete="off"
                                    className="needs-validation default-form"
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                    id="riderCommissionForm"
                                >
                                    <fieldset disabled={formLoading}>
                                        <input type="hidden" value="something" />

                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="riderCommission">Commission</Label>
                                                    <AvField
                                                        name="riderCommission"
                                                        placeholder="Amount"
                                                        type="number"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                            },
                                                        }}
                                                        id="riderCommission"
                                                        disabled
                                                        value={formData.riderCommission}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="riderPaidAmount">Paid Amount</Label>
                                                    <AvField
                                                        name="riderPaidAmount"
                                                        placeholder="Paid Amount"
                                                        type="number"
                                                        errorMessage="Invalid Amount"
                                                        className="form-control"
                                                        validate={{
                                                            max: {
                                                                value: formData.riderCommission,
                                                            },
                                                        }}
                                                        max={formData.riderCommission}
                                                        min={0}
                                                        id="riderPaidAmount"
                                                        value={formData.riderPaidAmount}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="riderBalanceAmount">Balance Amount</Label>
                                                    <AvField
                                                        name="riderBalanceAmount"
                                                        placeholder="Balance Amount"
                                                        type="number"
                                                        className="form-control"
                                                        disabled
                                                        id="riderBalanceAmount"
                                                        value={formData.riderBalanceAmount}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            {formData.riderBalanceAmount === '0.00' && (
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="riderPaymentReceivedDate">
                                                            Payment Received Date and Time
                                                        </Label>
                                                        <Input
                                                            onChange={handleInputChange}
                                                            type="datetime-local"
                                                            className="form-control"
                                                            value={getDateValue(
                                                                formData.riderPaymentReceivedDate,
                                                                'datetime-local'
                                                            )}
                                                            id="riderPaymentReceivedDate"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            )}
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="riderPaymentReference">Payment Reference</Label>
                                                    <AvField
                                                        name="riderPaymentReference"
                                                        placeholder="Payment Reference"
                                                        type="text"
                                                        errorMessage="Required"
                                                        className="form-control"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                            },
                                                        }}
                                                        id="riderPaymentReference"
                                                        value={formData.riderPaymentReference}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ justifyContent: 'flex-start' }}>
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                type="submit"
                                                disabled={formLoading}
                                            >
                                                {formLoading ? (
                                                    <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                ) : (
                                                    <>
                                                        <i className="fas fa-save left-icon"></i>
                                                        <span className="h6">Update</span>
                                                    </>
                                                )}
                                            </motion.button>
                                        </Row>
                                    </fieldset>
                                </AvForm>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row style={{ justifyContent: 'space-between', alignItems: 'center', margin: 2 }}>
                                    <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                        DELIVERIES
                                    </SubTitle>
                                </Row>

                                <TableCustom
                                    tableData={deliveryData}
                                    onViewDelivery={handleViewDelivery}
                                    loading={loading}
                                    showView={false}
                                    isFilter={false}
                                    isSearch={false}
                                    showEdit={false}
                                    removeAction
                                    isFixedHeight
                                />
                            </CardBody>
                        </Card>

                        <BackdropLoader show={backdropLoading || loading} />
                    </Container>
                </div>
            </Hoc>
        </>
    );
}

RiderCommissionRecordFormPage.propTypes = {};

export default RiderCommissionRecordFormPage;
