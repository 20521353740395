export const getStatus = (deliveryStatus, rider) => {
    if (deliveryStatus === 'inProgress') {
        return 'dispatchReady';
    }

    if (deliveryStatus === 'pickedUp') {
        return 'inProgress';
    }

    if (deliveryStatus === 'deliveryStarted') {
        return 'deliveryStarted';
    }

    if (deliveryStatus === 'completed') {
        return 'completed';
    }

    if (deliveryStatus === 'delivered') {
        return 'delivered';
    }

    if (rider) {
        return 'riderAssigned';
    }

    return 'delivering';
};
