/* eslint-disable camelcase */

const VERSION_1 = 'v1';
const WEB_CORE_PREFIX = 'web-core';

export const AUTH_LOGIN_INITIAL = `/${WEB_CORE_PREFIX}/${VERSION_1}/auth/login-account`;
export const AUTH_LOGIN = `/${WEB_CORE_PREFIX}/${VERSION_1}/auth/login`;
export const AUTH_LOGIN_USERNAME = `/${WEB_CORE_PREFIX}/${VERSION_1}/auth/login-username`;
export const AUTH_FORGET_PASSWORD = `/${WEB_CORE_PREFIX}/${VERSION_1}/auth/forgot`;
export const AUTH_RESET_PASSWORD = `/${WEB_CORE_PREFIX}/${VERSION_1}/auth/reset`;
export const AUTH_VERIFY_USER = `/${WEB_CORE_PREFIX}/${VERSION_1}/auth/verify`;
export const AUTH_TEMPORARY_PASSWORD_RESET = `/${WEB_CORE_PREFIX}/${VERSION_1}/auth/temporary-password-reset/{userId}`;
export const AUTH_VERIFY_PASSWORD = `/${WEB_CORE_PREFIX}/${VERSION_1}/auth/user/{userId}/password/verify`;
export const AUTH_VERIFY_AUTHENTICATED = `/${WEB_CORE_PREFIX}/${VERSION_1}/auth/user/authenticated`;

// Rider
export const AUTH_VERIFY_RIDER = `/${WEB_CORE_PREFIX}/${VERSION_1}/auth/verify-rider`;
export const AUTH_RESET_PASSWORD_RIDER = `/${WEB_CORE_PREFIX}/${VERSION_1}/auth/reset-password-rider`;

// Profile
export const PROFILE_CHANGE_PASSWORD = `/${WEB_CORE_PREFIX}/${VERSION_1}/profile/changePassword`; // POST
export const UPDATE_PROFILE = `/${WEB_CORE_PREFIX}/${VERSION_1}/profile/{id}`; // POST
export const PROFILE_CHANGE_PASSWORD_NEW = `/${WEB_CORE_PREFIX}/${VERSION_1}/profile/changePassword/new`; // POST

// User
export const CREATE_USER = `/${WEB_CORE_PREFIX}/${VERSION_1}/users`; // POST
export const CREATE_MERCHANT_ADMIN = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/merchant-admin`; // POST
export const UPDATE_USER = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/{id}`; // PATCH
export const DELETE_USER = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/{id}`; // DELETE
export const DELETE_USER_PERMANENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/{id}/permanent`; // DELETE
export const GET_USER_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/{id}`; // GET
export const GET_USERS = `/${WEB_CORE_PREFIX}/${VERSION_1}/users`; // GET
export const GET_USERS_BY_QUERY = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/query`; // GET
export const GET_USERS_ALL = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/all`; // GET
export const GET_USERS_META = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/meta`; // GET
export const APPROVE_USER = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/approve/{id}`; // GET
export const TOGGLE_DISABLE_USER = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/{id}/toggle-disable`; // GET
export const RESTORE_USER = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/{id}/restore`; // GET
export const RESEND_REGISTRATION_VERIFICATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/resend-user-registration-verification`; // GET
export const RESEND_REGISTRATION_VERIFICATION_URL = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/resend-user-registration-verification-url`; // GET
export const RESTORE_USER_SELF_ACCOUNT_DELETION_ACCESS = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/{id}/restore/self-account-deletion`; // PATCH

// Riders
export const CREATE_RIDER = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders`; // POST
export const UPDATE_RIDER = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/{id}`; // PATCH
export const UPDATE_RIDER_FIELDS = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/{id}/fields`; // PATCH
export const DELETE_RIDER = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/{id}`; // DELETE
export const DELETE_RIDER_PERMANENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/{id}/permanent`; // DELETE
export const GET_RIDER_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/{id}`; // GET
export const GET_RIDERS = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders`; // GET
export const GET_RIDERS_BOOL_STATUS = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/advanced-status/{status}/{bool}`; // GET
export const GET_RIDERS_META = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/meta`; // GET
export const APPROVE_RIDER = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/approve/{id}`; // GET
export const TOGGLE_DISABLE_RIDER = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/{id}/toggle-disable`; // GET
export const GET_ALL_RIDERS_BY_STATUS = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/status/{status}`; // GET
export const GET_ALL_RIDERS_BY_CLIENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/client/{clientId}`; // GET
export const GET_ALL_RIDERS_COORDINATES_BY_CLIENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/client/coordinates/{clientId}`; // GET
export const GET_ALL_ACTIVE_RIDERS_BY_CLIENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/active/client/{clientId}`; // GET
export const RIDER_PROFILE_CHANGE_PASSWORD = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/changePassword`; // POST
export const GET_ALL_RIDERS = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/all`; // GET
export const RIDER_PROFILE_CHANGE_PASSWORD_NEW = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/changePassword/new`; // POST
export const GET_RESTORE_RIDER = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/{id}/restore`; // GET
export const SEND_VERIFICATION_CODE = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/send-verification-code/{method}/{id}`; // GET
export const GET_RIDERS_LIST_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/list/data`; // GET
export const GET_RIDERS_BY_FILTERS = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/get/list`; // GET
export const RESTORE_RIDER_SELF_ACCOUNT_DELETION_ACCESS = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/{id}/restore/self-account-deletion`; // PATCH

// Orders
export const CREATE_ORDER = `/${WEB_CORE_PREFIX}/${VERSION_1}/orders`; // POST
export const UPDATE_ORDER = `/${WEB_CORE_PREFIX}/${VERSION_1}/orders/{id}`; // PATCH
export const DELETE_ORDER = `/${WEB_CORE_PREFIX}/${VERSION_1}/orders/{id}`; // DELETE
export const GET_ORDER_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/orders/{id}`; // GET
export const GET_ORDER_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/orders/merchant/{id}`; // GET
export const GET_ORDERS = `/${WEB_CORE_PREFIX}/${VERSION_1}/orders`; // GET
export const GET_ALL_ORDERS = `/${WEB_CORE_PREFIX}/${VERSION_1}/orders/all`; // GET
export const GET_ALL_ORDERS_META = `/${WEB_CORE_PREFIX}/${VERSION_1}/orders/meta`; // GET
export const GET_ALL_ORDERS_BY_STATUS = `/${WEB_CORE_PREFIX}/${VERSION_1}/orders/status/{status}`; // GET

// Picking Task
export const GET_ALL_PICKING_TASKS = `/${WEB_CORE_PREFIX}/${VERSION_1}/picking-tasks/all`; // GET
export const GET_PICKING_TASKS = `/${WEB_CORE_PREFIX}/${VERSION_1}/picking-tasks`; // GET
export const GET_ONE_PICKING_TASK = `/${WEB_CORE_PREFIX}/${VERSION_1}/picking-tasks/{id}`; // GET
export const CREATE_PICKING_TASK = `/${WEB_CORE_PREFIX}/${VERSION_1}/picking-tasks`; // POST
export const UPDATE_PICKING_TASK = `/${WEB_CORE_PREFIX}/${VERSION_1}/picking-tasks/{id}`; // PATCH
export const DELETE_PICKING_TASK = `/${WEB_CORE_PREFIX}/${VERSION_1}/picking-tasks/{id}`; // DELETE
export const REMOVE_ORDER_FROM_PICKING_TASK = `/${WEB_CORE_PREFIX}/${VERSION_1}/orders/picking-task/{pickingTaskId}/remove-order/{orderId}`; // PATCH
export const MARK_ALL_ORDERS_COMPLETED_IN_PICKING_TASK = `/${WEB_CORE_PREFIX}/${VERSION_1}/orders/picking-task/complete-all`; // POST

// Merchants
export const GET_ALL_MERCHANTS = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants/all`; // GET
export const GET_ALL_MERCHANTS_META = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants/meta`; // GET
export const GET_MERCHANTS = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants`; // GET
export const CREATE_MERCHANT_WITH_ADMIN = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants/merchant-with-admin/multi`; // POST
export const GET_MERCHANTS_DELETED = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants/deleted`; // GET
export const GET_ONE_MERCHANT = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants/{id}`; // GET
export const CREATE_MERCHANT = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants`; // POST
export const UPDATE_MERCHANT = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants/{id}`; // PATCH
export const DELETE_MERCHANT = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants/{id}`; // DELETE
export const GET_MERCHANTS_ORDERS = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants/orders/{merchantId}`; // GET
export const GET_ALL_MERCHANTS_BY_CLIENT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants/client/{clientId}`; // GET
export const DELETE_MERCHANT_PERMANENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants/{id}/permanent`; // DELETE
export const RESTORE_MERCHANT = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants/{id}/restore`; // GET
export const UPDATE_MERCHANT_PROPS = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchants/{id}/props`; // PATCH

// Deliveries
export const GET_ALL_DELIVERIES = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/all`; // GET
export const GET_ALL_DELIVERIES_META = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/meta/{type}`; // GET
export const GET_DELIVERIES = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries`; // GET
export const GET_ONGOING_DELIVERIES = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/ongoing`; // GET
export const GET_ONE_DELIVERY = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/{id}`; // GET
export const GET_DELIVERIES_BY_STATUS = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/all/:status`; // GET
export const CREATE_DELIVERY = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries`; // POST
export const UPDATE_DELIVERY = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/{id}`; // PATCH
export const DELETE_DELIVERY = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/{id}`; // DELETE
export const DELETE_DELIVERY_PERMANENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/{id}/permanent`; // DELETE
export const RESTORE_DELIVERY = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/{id}/restore`; // DELETE
export const GET_DELIVERIES_DELETED = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/deleted`; // DELETE
export const GET_DELIVERIES_ORDERS = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/orders/{merchantId}`; // GET
export const GET_ALL_DELIVERIES_BY_CLIENT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/client/{clientId}`; // GET
export const UPDATE_PENDING_DELIVERY = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/pending-delivery/{pendingDataId}/delivery/{uuid}`; // PATCH
export const GET_DELIVERIES_BY_UN_ASSIGNED = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/un-assigned`; // GET
export const DELIVERY_LOCATION_CHANGED = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/{id}/location-changed`; // GET
export const SEND_DELIVERY_PROGRESS_MSG = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/send-delivery-progress-msg`; // GET
export const GET_DELIVERY_BY_RATING_TOKEN = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/rating-data-token/{token}`; // GET
export const GENERATE_DELIVERY_REPORT = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/generate-delivery-report`; // POST
export const POST_LINK_DELIVERY_RECORD = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/link-billing-record`; // POST
export const GET_ALL_DELIVERY_IDS = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/id/all`; // GET
export const GET_DELIVERIES_LIST = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/list/data`; // GET
export const ASSIGN_BATCH_DELIVERIES_TO_RIDER = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/batch/assign/{id}`; // GET
export const CANCEL_DELIVERY = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/{id}/transaction/cancel`; // PATCH
export const UNASSIGN_DELIVERY = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/{id}/transaction/unassign-rider`; // PATCH
export const NEW_DELIVERY_ATTEMPT = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/{id}/transaction/new-delivery-attempt`; // PATCH
export const UPDATE_DELIVERY_TYPE = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/{id}/transaction/delivery-type`; // PATCH
export const COMPLETE_DELIVERY = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/{id}/transaction/complete-delivery`; // PATCH
export const UPDATE_OTHER_INFORMATION_DELIVERY = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/{id}/transaction/other-info`; // PATCH
export const GET_ALL_DELIVERY_IDS_NEW = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/get/ids/all`; // GET
// export const GET_DELIVERIES_BY_ROUTE_PLANNING_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/get/route-planning/{id}`; // GET

// Delivery Attachments
export const UPLOAD_DELIVERY_ATTACHMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/delivery-attachment/upload-single/{id}`; // POST
export const CREATE_DELIVERY_ATTACHMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/delivery-attachment`; // POST
export const GET_ALL_DELIVERY_ATTACHMENTS_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/delivery-attachment/delivery/{id}`; // GET
export const UPDATE_DELIVERY_ATTACHMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/delivery-attachment/{id}`; // PATCH
export const DELETE_DELIVERY_ATTACHMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/delivery-attachment/{id}`; // POST

// Clients
export const GET_ALL_CLIENTS = `/${WEB_CORE_PREFIX}/${VERSION_1}/clients/all`; // GET

// Customer
export const GET_CUSTOMER_BY_MOBILE_NO = `/${WEB_CORE_PREFIX}/${VERSION_1}/customers/mobileNo/{mobileNo}`; // DELETE

// Transactions
export const GET_USER_MODIFIED_TRANSACTION = `/${WEB_CORE_PREFIX}/${VERSION_1}/users/transactions/{id}`; // GET
export const GET_RIDER_MODIFIED_TRANSACTION = `/${WEB_CORE_PREFIX}/${VERSION_1}/riders/transactions/{id}`; // GET
export const GET_DELIVERY_PROGRESS_TRANSACTION = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/{id}/transactions`; // GET
export const GET_CUSTOM_TRANSACTIONS_BY_TYPE_AND_DOCUMENT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/transactions/custom/modified/type/{type}/document-id/{id}`; // GET
export const GET_PARTNER_API_KEY_TRANSACTION = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/api-key/{id}/transactions`; // GET
export const GET_PARTNER_API_SANDBOX_ACCOUNT_TRANSACTION = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/sandbox-account/{id}/transactions`; // GET
export const GET_PARTNER_API_WEBHOOK_TRANSACTION = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/webhook/{id}/transactions`; // GET

// Bank
export const GET_ALL_BANKS = `/${WEB_CORE_PREFIX}/${VERSION_1}/banks/all`; // GET

// Vehicle
export const GET_ALL_VEHICLES = `/${WEB_CORE_PREFIX}/${VERSION_1}/vehicles/all`; // GET
export const GET_ALL_VEHICLES_BY_TYPE = `/${WEB_CORE_PREFIX}/${VERSION_1}/vehicles/type/{type}`; // GET

// Merchant Locations
export const GET_ALL_MERCHANT_LOCATIONS = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-locations/all`; // GET
export const GET_MERCHANT_LOCATIONS = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-locations`; // GET
export const GET_MERCHANT_LOCATIONS_DELETED = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-locations/deleted`; // GET
export const GET_ONE_MERCHANT_lOCATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-locations/{id}`; // GET
export const CREATE_MERCHANT_lOCATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-locations`; // POST
export const UPDATE_MERCHANT_lOCATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-locations/{id}`; // PATCH
export const DELETE_MERCHANT_lOCATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-locations/{id}`; // DELETE
export const DELETE_MERCHANT_lOCATION_PERMANENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-locations/{id}/permanent`; // DELETE
export const RESTORE_MERCHANT_lOCATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-locations/{id}/restore`; // GET
export const GET_ALL_MERCHANT_LOCATIONS_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-locations/merchant/{id}`; // GET
export const GET_MERCHANTS_LOCATIONS_LIST_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-locations/list/data`; // GET
export const GET_ALL_MERCHANT_LOCATIONS_VISITING_MERCHANT = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-locations/visiting-merchant/{id}`; // GET

// Shipping Label
export const GET_ALL_SHIPPING_LABELS = `/${WEB_CORE_PREFIX}/${VERSION_1}/shipping-labels/all`; // GET
export const GET_SHIPPING_LABELS = `/${WEB_CORE_PREFIX}/${VERSION_1}/shipping-labels`; // GET
export const GET_ONE_SHIPPING_LABEL = `/${WEB_CORE_PREFIX}/${VERSION_1}/shipping-labels/{id}`; // GET
export const CREATE_SHIPPING_LABEL = `/${WEB_CORE_PREFIX}/${VERSION_1}/shipping-labels`; // POST
export const UPDATE_SHIPPING_LABEL = `/${WEB_CORE_PREFIX}/${VERSION_1}/shipping-labels/{id}`; // PATCH
export const DELETE_SHIPPING_LABEL = `/${WEB_CORE_PREFIX}/${VERSION_1}/shipping-labels/{id}`; // DELETE
export const GET_ALL_SHIPPING_LABELS_BY_DELIVERY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/shipping-labels/delivery/{id}`; // GET

// Attachment
export const CREATE_ATTACHMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/attachment`;
export const CREATE_ATTACHMENT_OPEN = `/${WEB_CORE_PREFIX}/${VERSION_1}/attachment/open`;
export const CREATE_MULTI_ATTACHMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/attachment/multi`;
export const UPDATE_ATTACHMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/attachment/update/{key}`;
export const UPDATE_ATTACHMENT_OPEN = `/${WEB_CORE_PREFIX}/${VERSION_1}/attachment/update/open/{key}`;
export const GET_ATTACHMENT_BY_KEY = `/${WEB_CORE_PREFIX}/${VERSION_1}/attachment/{key}`;

// Client Setting
export const GET_ALL_CLIENT_SETTING = `/${WEB_CORE_PREFIX}/${VERSION_1}/client-setting/all`; // GET
export const GET_ACTIVE_CLIENT_SETTING = `/${WEB_CORE_PREFIX}/${VERSION_1}/client-setting/active/client/{clientId}`; // GET
export const GET_CLIENT_SETTING = `/${WEB_CORE_PREFIX}/${VERSION_1}/client-setting`; // GET
export const GET_ONE_CLIENT_SETTING = `/${WEB_CORE_PREFIX}/${VERSION_1}/client-setting/{id}`; // GET
export const CREATE_CLIENT_SETTING = `/${WEB_CORE_PREFIX}/${VERSION_1}/client-setting`; // POST
export const UPDATE_CLIENT_SETTING = `/${WEB_CORE_PREFIX}/${VERSION_1}/client-setting/{id}`; // PATCH
export const DELETE_CLIENT_SETTING = `/${WEB_CORE_PREFIX}/${VERSION_1}/client-setting/{id}`; // DELETE

// Permission
export const GET_ALL_PERMISSIONS = `/${WEB_CORE_PREFIX}/${VERSION_1}/permissions/all`; // DELETE

// Role
export const CREATE_ROLE = `/${WEB_CORE_PREFIX}/${VERSION_1}/roles`; // CREATE
export const UPDATE_ROLE = `/${WEB_CORE_PREFIX}/${VERSION_1}/roles/{id}`; // PATCH
export const GET_ALL_ROLES_BY_USER_TYPE = `/${WEB_CORE_PREFIX}/${VERSION_1}/roles/type/{type}`; // GET
export const GET_ALL_ROLES_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/roles/merchant/{id}`; // GET
export const GET_ALL_ROLES_BY_CLIENT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/roles/client/{id}`; // GET

// Merchant Setting
export const GET_ALL_MERCHANT_SETTING = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-setting/all`; // GET
export const GET_MERCHANT_SETTING = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-setting`; // GET
export const GET_ONE_MERCHANT_SETTING = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-setting/{id}`; // GET
export const CREATE_MERCHANT_SETTING = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-setting`; // POST
export const UPDATE_MERCHANT_SETTING = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-setting/{id}`; // PATCH
export const DELETE_MERCHANT_SETTING = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-setting/{id}`; // DELETE
export const GET_MERCHANT_SETTING_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-setting/merchant/{id}`; // GET
export const GET_MERCHANT_DELIVERY_DATE_CONFIGURATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-setting/{id}/delivery-date-configuration`; // GET
export const UPDATE_MERCHANT_DELIVERY_DATE_CONFIGURATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-setting/{id}/delivery-date-configuration`; // PATCH
export const UPDATE_MULTI_STOP_BILLING_SETTINGS = `/${WEB_CORE_PREFIX}/${VERSION_1}/merchant-setting/{id}/multi-stop-billing-settings`; // PATCH

// JOBS
export const CREATE_JOB = `/${WEB_CORE_PREFIX}/${VERSION_1}/jobs`; // POST

// Excel Csv Config
export const GET_ONE_EXCEL_CSV_CONFIG = `/${WEB_CORE_PREFIX}/${VERSION_1}/excel-csv-config/{id}`; // GET
export const CREATE_EXCEL_CSV_CONFIG = `/${WEB_CORE_PREFIX}/${VERSION_1}/excel-csv-config`; // POST
export const UPDATE_EXCEL_CSV_CONFIG = `/${WEB_CORE_PREFIX}/${VERSION_1}/excel-csv-config/{id}`; // PATCH
export const DELETE_EXCEL_CSV_CONFIG = `/${WEB_CORE_PREFIX}/${VERSION_1}/excel-csv-config/{id}`; // DELETE
export const GET_EXCEL_CSV_CONFIG_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/excel-csv-config/merchant/{id}/type/{type}`; // GET

// Delivery Attachment
export const POST_DELIVERY_EXCEL_CSV_ATTACHMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/attachment/delivery/import`; // POST
export const POST_BILLING_EXCEL_CSV_ATTACHMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/attachment/billing/import`; // POST
export const POST_RIDER_COMMISSION_EXCEL_CSV_ATTACHMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/attachment/rider-commission/import`; // POST

// Import History
export const GET_ALL_IMPORT_HISTORY = `/${WEB_CORE_PREFIX}/${VERSION_1}/import-history/all`; // GET
export const GET_IMPORT_HISTORY = `/${WEB_CORE_PREFIX}/${VERSION_1}/import-history`; // GET
export const GET_ONE_IMPORT_HISTORY = `/${WEB_CORE_PREFIX}/${VERSION_1}/import-history/{id}`; // GET
export const CREATE_IMPORT_HISTORY = `/${WEB_CORE_PREFIX}/${VERSION_1}/import-history`; // POST
export const UPDATE_IMPORT_HISTORY = `/${WEB_CORE_PREFIX}/${VERSION_1}/import-history/{id}`; // PATCH
export const DELETE_IMPORT_HISTORY = `/${WEB_CORE_PREFIX}/${VERSION_1}/import-history/{id}`; // DELETE
export const GET_IMPORT_HISTORY_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/import-history/merchant/{id}`; // GET
export const GET_ONE_IMPORT_HISTORY_BY_PENDING_DATA_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/import-history/pending-data/{id}`; // GET

// Pending Data
export const DELETE_REVERT_PENDING_DATA_DELIVERY_JOB = `/${WEB_CORE_PREFIX}/${VERSION_1}/pending-data/delivery/revert/{id}`; // DELETE
export const GET_APPROVE_PENDING_DATA_DELIVERY_JOB = `/${WEB_CORE_PREFIX}/${VERSION_1}/pending-data/delivery/approve/{id}`; // GET
export const RETRY_DELIVERY_IMPORT_JOB = `/${WEB_CORE_PREFIX}/${VERSION_1}/deliveries/import/retry`; // GET

// Warehouse Sites
export const CREATE_WAREHOUSE_SITE = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/sites`; // POST
export const UPDATE_WAREHOUSE_SITE = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/sites/{id}`; // PATCH
export const DELETE_WAREHOUSE_SITE = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/sites/{id}`; // DELETE
export const GET_WAREHOUSE_SITE_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/sites/{id}`; // GET
export const GET_WAREHOUSE_SITES = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/sites`; // GET
export const GET_ALL_WAREHOUSE_SITES = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/sites/all`; // GET
export const GET_TRANSACTIONS_BY_WAREHOUSE_SITE_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/sites/transactions/{id}/get`; // GET
// export const GET_ALL_WAREHOUSE_SITES_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/sites/all/merchant/{id}`; // GET //!TODO

// Warehouse Zones
export const CREATE_WAREHOUSE_LOCATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/zones`; // POST
export const UPDATE_WAREHOUSE_LOCATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/zones/{id}`; // PATCH
export const DELETE_WAREHOUSE_LOCATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/zones/{id}`; // DELETE
export const GET_WAREHOUSE_LOCATION_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/zones/{id}`; // GET
export const GET_WAREHOUSE_LOCATIONS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/zones`; // GET
export const GET_ALL_WAREHOUSE_LOCATIONS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/zones/all`; // GET
export const GET_WAREHOUSE_LOCATIONS_BY_SITE_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/zones/site/{id}`; // GET
export const GET_TRANSACTIONS_BY_WAREHOUSE_ZONE_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/zones/transactions/{id}/get`; // GET

// Bin Locations
export const CREATE_BIN_LOCATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/bin-locations`; // POST
export const UPDATE_BIN_LOCATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/bin-locations/{id}`; // PATCH
export const DELETE_BIN_LOCATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/bin-locations/{id}`; // DELETE
export const GET_BIN_LOCATION_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/bin-locations/{id}`; // GET
export const GET_BIN_LOCATIONS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/bin-locations`; // GET
export const GET_ALL_BIN_LOCATIONS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/bin-locations/all`; // GET
export const GET_BIN_LOCATIONS_BY_ZONE_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/bin-locations/zone/{id}`; // GET
export const GET_TRANSACTIONS_BY_BIN_LOCATION_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/bin-locations/transactions/{id}/get`; // GET

// Product Master Data
export const CREATE_PRODUCT_MASTER_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/product-master-data`; // POST
export const UPDATE_PRODUCT_MASTER_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/product-master-data/{id}`; // PATCH
export const DELETE_PRODUCT_MASTER_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/product-master-data/{id}`; // DELETE
export const GET_PRODUCT_MASTER_DATA_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/product-master-data/{id}`; // GET
export const GET_PRODUCT_MASTER_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/product-master-data`; // GET
export const GET_ALL_PRODUCT_MASTER_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/product-master-data/all`; // GET
export const GET_PRODUCT_MASTER_DATA_DELETED = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/product-master-data/deleted`; // GET
export const GET_ALL_PRODUCT_MASTER_DATA_BY_MERCHANT = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/product-master-data/all/merchant/{id}`; // GET

// System Data
export const CREATE_SYSTEM_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/system-data`; // POST
export const UPDATE_SYSTEM_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/system-data/{id}`; // PATCH
export const DELETE_SYSTEM_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/system-data/{id}`; // DELETE
export const GET_SYSTEM_DATA_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/system-data/{id}`; // GET
export const GET_SYSTEM_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/system-data`; // GET
export const GET_ALL_SYSTEM_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/system-data/all`; // GET
export const GET_ALL_SYSTEM_DATA_BY_TYPE_MODULE = `/${WEB_CORE_PREFIX}/${VERSION_1}/system-data/all/type/{type}/module/{module}`; // GET
export const GET_ALL_SYSTEM_DATA_BY_TYPE_MODULE_BY_HEAD = `/${WEB_CORE_PREFIX}/${VERSION_1}/system-data/all/type/{type}/module/{module}`; // POST

// Stocks Receiving
export const CREATE_STOCKS_RECEIVING = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving`; // POST
export const UPDATE_STOCKS_RECEIVING = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/{id}`; // PATCH
export const DELETE_STOCKS_RECEIVING = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/{id}`; // DELETE
export const GET_STOCKS_RECEIVING_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/{id}`; // GET
export const GET_STOCKS_RECEIVING = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving`; // GET
export const GET_ALL_STOCKS_RECEIVING = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/all`; // GET
export const GET_STOCKS_RECEIVING_DELETED = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/deleted`; // GET
export const DELETE_STOCKS_RECEIVING_PERMANENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/{id}/permanent`; // DELETE
export const RESTORE_STOCKS_RECEIVING = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/{id}/restore`; // GET
export const UPDATE_REVERSE_STOCKS_RECEIVING = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/reverse/{id}`; // PATCH
export const UPDATE_CONFIRM_STOCKS_RECEIVING = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/confirm/{id}`; // PATCH
export const GET_TRANSACTIONS_BY_STOCK_RECEIVING_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/transactions/{id}/get`; // GET

// Stocks
export const CREATE_STOCKS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks`; // POST
export const UPDATE_STOCKS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks/{id}`; // PATCH
export const DELETE_STOCKS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks/{id}`; // DELETE
export const GET_STOCKS_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks/{id}`; // GET
export const GET_STOCKS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks`; // GET
export const GET_ALL_STOCKS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks/all`; // GET
export const GET_STOCKS_DELETED = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks/deleted`; // GET
export const GET_ALL_USABLE_STOCKS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks/usable`; // GET
export const GET_ALL_USABLE_STOCKS_BY_PRODUCT = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks/product-master-data/{id}/usable`; // GET
export const GET_ALL_STOCKS_BY_STOCKS_RECEIVING = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks/stocks-receiving/{id}`; // GET
export const GET_ALL_STOCKS_BY_WAREHOUSE = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks/warehouse-site{id}`; // GET
export const GET_ALL_USABLE_STOCKS_BY_WAREHOUSE = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks/warehouse-site{id}/usable`; // GET

// Order Items
export const CREATE_ORDER_ITEMS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/order-items`; // POST
export const UPDATE_ORDER_ITEMS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/order-items/{id}`; // PATCH
export const DELETE_ORDER_ITEMS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/order-items/{id}`; // DELETE
export const GET_ORDER_ITEMS_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/order-items/{id}`; // GET
export const GET_ORDER_ITEMS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/order-items`; // GET
export const GET_ALL_ORDER_ITEMS = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/order-items/all`; // GET
export const GET_ORDER_ITEMS_DELETED = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/order-items/deleted`; // GET
export const GET_ORDER_ITEMS_BY_ORDER_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/order-items/order/{id}`; // GET
export const COMPLETE_ORDER_ITEM_PICKING = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/order-items/complete-picking/{id}`; // PATCH
export const UNDO_COMPLETE_ORDER_ITEM_PICKING = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/order-items/undo-complete-picking/{id}`; // PATCH
export const COMPLETE_ORDER_ITEM_PACKING = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/order-items/complete-packing/{id}`; // PATCH

// Notifications
export const DELETE_NOTIFICATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/notifications/{id}`; // DELETE
export const GET_NOTIFICATION_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/notifications/{id}`; // GET
export const GET_NOTIFICATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/notifications`; // GET
export const GET_ALL_NOTIFICATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/notifications/all`; // GET
export const GET_NOTIFICATION_DELETED = `/${WEB_CORE_PREFIX}/${VERSION_1}/notifications/deleted`; // GET
export const DELETE_NOTIFICATION_PERMANENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/notifications/{id}/permanent`; // DELETE
export const RESTORE_NOTIFICATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/notifications/{id}/restore`; // GET

// Billing Settings
export const GET_ALL_BILLINGS = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingSetting`; // GET
export const GET_ALL_BILLINGS_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingSetting/merchant/{id}`; // GET
export const GET_BILLING = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingSetting/{id}`; // GET
export const CREATE_BILLING = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingSetting`; // POST
export const UPDATE_BILLING = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingSetting/{id}`; // PATCH

// Rider Commission Settings
export const GET_ALL_RIDER_COMMISSIONS = `/${WEB_CORE_PREFIX}/${VERSION_1}/riderCommissionSetting`; // GET
export const GET_ALL_RIDER_COMMISSIONS_BY_RIDER_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/riderCommissionSetting/rider/{id}`; // GET
export const GET_RIDER_COMMISSION = `/${WEB_CORE_PREFIX}/${VERSION_1}/riderCommissionSetting/{id}`; // GET
export const CREATE_RIDER_COMMISSION = `/${WEB_CORE_PREFIX}/${VERSION_1}/riderCommissionSetting`; // POST
export const UPDATE_RIDER_COMMISSION = `/${WEB_CORE_PREFIX}/${VERSION_1}/riderCommissionSetting/{id}`; // PATCH

// Billing Records
export const GET_ALL_BILLING_RECORDS = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingRecord`; // GET
export const GET_ALL_BILLING_RECORDS_BY_RIDER_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingRecord/rider/{id}`; // GET
export const GET_BILLING_RECORD_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingRecord/{id}`; // GET
export const CREATE_BILLING_RECORD = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingRecord`; // POST
export const UPDATE_BILLING_RECORD = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingRecord/{id}`; // PATCH
export const GENERATE_REPORT = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingRecord/generateReport`; // POST
export const GENERATE_EARNINGS_REPORT = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingRecord/earningSummary/generateReport`; // POST
export const RECALCULATE_BILLING_RECORD_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingRecord/{id}/recalculate`; // GET
export const GET_DELIVERIES_BY_BILLING_RECORD_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingRecord/{id}/deliveries`; // GET
export const GET_RIDER_COMMISSION_RECORD_BY_BILLING_RECORD_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/billingRecord/rider-commission/{id}`; // GET

// Stocks Adjustment
export const CREATE_STOCKS_ADJUSTMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment`; // POST
export const UPDATE_STOCKS_ADJUSTMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/{id}`; // PATCH
export const DELETE_STOCKS_ADJUSTMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/{id}`; // DELETE
export const GET_STOCKS_ADJUSTMENT_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/{id}`; // GET
export const GET_STOCKS_ADJUSTMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment`; // GET
export const GET_ALL_STOCKS_ADJUSTMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/all`; // GET
export const GET_STOCKS_ADJUSTMENT_DELETED = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/deleted`; // GET
export const DELETE_STOCKS_ADJUSTMENT_PERMANENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/{id}/permanent`; // DELETE
export const RESTORE_STOCKS_ADJUSTMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/{id}/restore`; // GET
export const UPDATE_CONFIRM_STOCKS_ADJUSTMENT = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/confirm/{id}`; // PATCH

// Stock Adjustment Items
export const CREATE_STOCKS_ADJUSTMENT_ITEM = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/item`; // POST
export const UPDATE_STOCKS_ADJUSTMENT_ITEM = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/item/{id}`; // PATCH
export const DELETE_STOCKS_ADJUSTMENT_ITEM = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/item/{id}`; // DELETE
export const GET_STOCKS_ADJUSTMENT_ITEM_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/item/{id}`; // GET
export const GET_STOCKS_ADJUSTMENT_ITEM = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/item`; // GET
export const GET_ALL_STOCKS_ADJUSTMENT_ITEM = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/item/all`; // GET
export const GET_STOCKS_ADJUSTMENT_ITEM_BY_STOCK_ADJUSTMENT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/item/stock-adjustment/{id}`; // GET
export const GET_ALL_STOCKS_ADJUSTMENT_ITEM_BY_STOCK_ADJUSTMENT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-adjustment/item/all/stock-adjustment/{id}`; // GET

// Stock Receiving Items
export const CREATE_STOCKS_RECEIVING_ITEM = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/item`; // POST
export const UPDATE_STOCKS_RECEIVING_ITEM = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/item/{id}`; // PATCH
export const DELETE_STOCKS_RECEIVING_ITEM = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/item/{id}`; // DELETE
export const GET_STOCKS_RECEIVING_ITEM_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/item/{id}`; // GET
export const GET_STOCKS_RECEIVING_ITEM = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/item`; // GET
export const GET_ALL_STOCKS_RECEIVING_ITEM = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/item/all`; // GET
export const GET_STOCKS_RECEIVING_ITEM_DELETED = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/item/deleted`; // GET
export const GET_STOCKS_RECEIVING_ITEM_BY_STOCK_RECEIVING_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/item/stock-receiving/{id}`; // GET
export const GET_ALL_STOCKS_RECEIVING_ITEM_BY_STOCK_RECEIVING_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/stocks-receiving/item/all/stock-receiving/{id}`; // GET

// Inventory Logs ( Report)
export const GET_INVENTORY_LOG = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/inventory-log`; // GET
export const GET_ALL_INVENTORY_LOG = `/${WEB_CORE_PREFIX}/${VERSION_1}/warehouse/inventory-log/all`; // GET

// Reports
export const POST_INVENTORY_REPORT = `/${WEB_CORE_PREFIX}/${VERSION_1}/report/inventory`; // POST
export const POST_CYCLE_COUNT_REPORT = `/${WEB_CORE_PREFIX}/${VERSION_1}/report/inventory/cycle-count`; // POST
export const POST_TRANSACTION_LOG_REPORT = `/${WEB_CORE_PREFIX}/${VERSION_1}/report/inventory/transaction-log`; // POST
export const POST_STOCK_RECEIVING_REPORT = `/${WEB_CORE_PREFIX}/${VERSION_1}/report/inventory/stock-receiving`; // POST
export const POST_STOCK_ISSUING_REPORT = `/${WEB_CORE_PREFIX}/${VERSION_1}/report/inventory/stock-issuing`; // POST
export const POST_STOCK_ADJUSTMENT_REPORT = `/${WEB_CORE_PREFIX}/${VERSION_1}/report/inventory/stock-adjustment`; // POST

// External ( Maps )
export const POST_DISTANCE_MATRIX_META = `/${WEB_CORE_PREFIX}/${VERSION_1}/external/maps/distance-matrix`; // POST

// Error Handling
export const SYSTEM_TEST_ERROR_500 = `/${WEB_CORE_PREFIX}/${VERSION_1}/backdoor/500-error`; // GET
export const SYSTEM_TEST_ERROR_409 = `/${WEB_CORE_PREFIX}/${VERSION_1}/backdoor/409-error`; // GET

// Model
export const GET_MODEL_BY_TYPE_AND_REFERENCE_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/model/type/{type}/reference/{id}`; // GET

// Attachment Download
export const DOWNLOAD_FILE_BY_LOCATION = `/${WEB_CORE_PREFIX}/${VERSION_1}/attachment/download/file-location`; // POST

// Print Service
export const LABEL_PRINT_SHIPPING_LABEL_GENERATE_QUICK_PRINT = `/${WEB_CORE_PREFIX}/${VERSION_1}/label-print/shipping-label/quick`; // POST
export const LABEL_PRINT_SHIPPING_LABEL_GET_DELIVERY_IDS = `/${WEB_CORE_PREFIX}/${VERSION_1}/label-print/shipping-label/delivery/ids`; // GET

// shipping label print
export const SHIPPING_LABEL_PRINT_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/label-print/print-record`; // GET

// Printer Settings
export const PRINTER_SETTINGS_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/print-setting/type/{type}/module/{module}/user/{userId}`; // GET
export const CREATE_PRINTER_SETTINGS_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/print-setting`; // POST
export const UPDATE_PRINTER_SETTINGS_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/print-setting/{id}`; // PATCH

// Batch Print
export const CREATE_BATCH_PRINT = `/${WEB_CORE_PREFIX}/${VERSION_1}/label-print/print-record/batch-print`; // POST
export const GET_BATCH_PRINT_DELIVERIES_BY_FILTER = `/${WEB_CORE_PREFIX}/${VERSION_1}/label-print/print-record/batch-print/deliveries/filter`; // POST
export const GET_PRINT_RECORDS_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/label-print/print-record/{id}`; // GET
export const UPDATE_BATCH_PRINT_RECORD = `/${WEB_CORE_PREFIX}/${VERSION_1}/label-print/print-record/batch-print/{id}`; // POST
export const FINALIZE_BATCH_PRINT_RECORD = `/${WEB_CORE_PREFIX}/${VERSION_1}/label-print/print-record/batch-print/{id}/finalize`; // POST
export const UPDATE_ARCHIVE_BATCH_PRINT_RECORD = `/${WEB_CORE_PREFIX}/${VERSION_1}/label-print/print-record/batch-print/{id}/archive`; // POST
export const START_PRINT_PROCESS_BATCH_PRINT_RECORD = `/${WEB_CORE_PREFIX}/${VERSION_1}/label-print/print-record/batch-print/{id}/start-print`; // POST

// Partner API
// Partners
export const GET_PARTNER_API_DATA_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/partner/get/merchant/{merchantId}`; // GET
export const CREATE_PARTNER_API_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/partner/create`; // POST
export const GET_PARTNERS_FROM_PARTNER_API = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/partner/list`; // GET
export const ENABLE_PARTNER_API_SERVICE = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/partner/service-enable/{merchantId}`;
export const DISABLE_PARTNER_API_SERVICE = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/partner/service-disable/{merchantId}`;
export const ENABLE_PARTNER_API_SERVICE_BY_MERCHANT_USER = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/partner/service-enable-merchant/{merchantId}`;
export const DISABLE_PARTNER_API_SERVICE_BY_MERCHANT_USER = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/partner/service-disable-merchant/{merchantId}`;

// Dashboard
export const GET_DASHBOARD_API_USAGE_STATS = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/dashboard/usage/stats/get`;
export const GET_DASHBOARD_LATEST_API_USAGE_STAT = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/dashboard/usage/stats/latest-usage/get`;
export const GET_DASHBOARD_ALL_API_USAGE_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/dashboard/usage/data/all`;
export const GET_DASHBOARD_API_USAGE_OVERVIEW = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/dashboard/usage/data/overview`;
export const GET_DASHBOARD_API_USAGE_OVERVIEW_TIME = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/dashboard/usage/data/overview/time`;
export const GET_DASHBOARD_API_USAGE_TABLE_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/dashboard/usage/data/table`;
export const GET_DASHBOARD_SUCCESS_ERROR_COUNT_MERCHANT_VISE = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/dashboard/usage/success-error/get`;

export const GET_DASHBOARD_STATS_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/dashboard/get/stats/merchant/{merchantId}`;
export const GET_DASHBOARD_GRAPH_DATA_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/dashboard/graph-data-merchant/{merchantId}`;
export const GET_DASHBOARD_STATS_BY_CLIENT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/dashboard/stat-data-client`;
export const GET_DASHBOARD_LATEST_REQUEST_BY_CLIENT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/dashboard/latest-request-client`;
export const GET_DASHBOARD_OVERVIEW_GRAPH_DATA_BY_CLIENT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/dashboard/get-overview-graph-client`;
export const GET_DASHBOARD_TABLE_DATA_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/dashboard/table-data`;
export const UPDATE_DEVELOPER_DATA_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/partner/update/developer/{merchantId}`; // GET
export const GET_API_KEY_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/api-key/list/{merchantId}`;
export const GENERATE_API_KEY = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/api-key/generate`;
export const GET_API_KEY_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/api-key/get/{id}`;
export const UPDATE_API_KEY_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/api-key/{id}/update`;
export const ENABLE_API_KEY_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/api-key/{id}/enable`;
export const DISABLE_API_KEY_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/api-key/{id}/disable`;
export const SHOW_SECRET_KEY = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/api-key/{id}/show`;
export const GET_SERVICE_STATUS = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/service-status`;
export const UPDATE_API_RATE_LIMIT_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/partner/update/api-rate/{merchantId}`;
export const UPDATE_REQUEST_SETTINGS_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/partner/update/request-settings/{merchantId}`;
export const GET_WEBHOOKS_LIST = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/webhook/list/{merchantId}`;
export const CREATE_WEBHOOK = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/webhook/create`;
export const GET_WEBHOOK_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/webhook/get/{id}`;
export const UPDATE_WEBHOOK = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/webhook/{id}/update`;
export const ENABLE_WEBHOOK = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/webhook/{id}/enable`;
export const DISABLE_WEBHOOK = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/webhook/{id}/disable`;
export const WEBHOOK_VIEW_AUTH_VALUE = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/webhook/{id}/viewApiKey`;
export const GET_PARTNER_API_WEBHOOK_EVENTS = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/webhook/webhook-events/get`; // GET
export const GET_PARTNER_API_CONNECTED_APPS_LIST = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/connected-apps/{id}/list`; // GET
export const DISCONNECT_PARTNER_API_CONNECTED_APP = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/connected-apps/{id}/disconnect`; // POST

// Sandbox Account
export const CREATE_PARTNER_API_SANDBOX_ACCOUNT = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/sandbox-account/create`; // POST
export const GET_PARTNER_API_SANDBOX_ACCOUNTS_TABLE = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/sandbox-account/table/get`; // GET
export const GET_PARTNER_API_SANDBOX_ACCOUNT_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/sandbox-account/get/{id}`; // GET
export const GET_PARTNER_API_SANDBOX_ACCOUNT_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/sandbox-account/get/merchant/{merchantId}`; // GET
export const GET_PARTNER_API_SANDBOX_ACCOUNT_PENDING_ACTIONS = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/sandbox-account/actions/count/pending`; // GET
export const REQUEST_API_ACCESS_MERCHANT_SANDBOX_ACCOUNT = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/sandbox-account/merchant/request-access`;
export const GET_ALL_PARTNER_API_SANDBOX_ACCOUNT_BY_MERCHANT_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/sandbox-account/get/merchant/{merchantId}/all`; // GET
export const REJECT_PARTNER_API_SANDBOX_ACCOUNT = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/sandbox-account/{id}/reject`; // PATCH
export const UPDATE_CREATE_PARTNER_API_SANDBOX_ACCOUNT = `/${WEB_CORE_PREFIX}/${VERSION_1}/partner-api/sandbox-account/{id}/update-create`; // PATCH

// Support
export const CREATE_DEVELOPER_SUPPORT_REQUEST = `/${WEB_CORE_PREFIX}/${VERSION_1}/support/ticket/create/developer-support`; // POST

// Route Planning
export const GET_CITIES_BY_COUNTRY = `/${WEB_CORE_PREFIX}/${VERSION_1}/city/city-list/get`; // GET
export const GET_DELIVERIES_BY_FILTERS = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/unplanned-deliveries/get/ids/all`; // GET
export const GET_MERCHANT_LOCATIONS_BY_MERCHANT_ID_LIST = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/merchant-locations/get`; // GET
export const GET_ROUTE_PLANNING_IDS = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/ids`; // GET
export const GET_ROUTE_PLANNING_DATA_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/{id}/get`; // GET
export const GET_ROUTE_PLANNING_LIST_DATA = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/list/data`; // GET
export const CANCEL_ROUTE_PLANNING_ENTRY_BY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/{id}/transaction/cancel`; // PATCH
export const UPDATE_DELIVERY_DATE_BY_ROUTE_PLANNING_ENTRY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/{id}/transaction/change-delivery-date`; // PATCH
export const UPDATE_RIDER_BY_ROUTE_PLANNING_ENTRY_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/{id}/transaction/change-rider`; // PATCH
export const ASSIGNING_ROUTE_PLAN = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/assign-route-plan`; // POST
export const GENERATE_ROUTE_PLAN = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/generate-route-plan`; // POST
export const ADD_DELIVERIES_TO_ROUTE_PLAN = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/add-deliveries/{id}`; // POST
export const GENERATE_RIDERS_ROUTES = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/generate-delivery-route`; // POST
export const GENERATE_RIDER_ROUTES = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/generate-rider-path`; // POST
export const GET_DELIVERIES_BY_ROUTE_PLANNING_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/deliveries/{id}/get`; // GET
export const DETACH_DELIVERY_FROM_ROUTE_PLAN = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/{id}/transaction/detach-delivery`; // PATCH
export const GET_TRANSACTIONS_BY_ROUTE_PLANNING_ID = `/${WEB_CORE_PREFIX}/${VERSION_1}/route-planning/transactions/{id}/get`; // GET
