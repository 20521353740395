export const CLIENT_MERCHANT_X_CLIENTS = 'c-m-x-clients'; // client-merchant-x-clients
export const CLIENT_MERCHANT_X_MERCHANTS = 'c-m-x-merchants'; // client-merchant-x-merchants
export const AUTH_USER = 'a-user'; // Scootix Authenticated User
export const USER = 'user'; // Scootix User
export const TOKEN = 'token'; // Scootix Authenticated User
export const USER_PROFILE_PIC = 'profile-pic-user'; // User Profile Pic
export const IS_USER_PROFILE_PIC_IMAGE_CHECKED = 'profile-pic-user-profile-image-checked';
export const LAST_LOGIN = 'set_last_login';
export const AUTH_CLIENT = 'a-client'; // Auth Client
export const AUTH_MERCHANT = 'a-merchant'; // Auth Merchant
// When user session times out he is logged out of system this URL stores the last know address url
export const USER_INITIAL_WINDOW_HREF = 'user-window-initial=href';
