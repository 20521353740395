/* eslint-disable indent */
import {
    GET_ALL_RIDER_COMMISSIONS_BY_RIDER_ID,
    GET_ALL_RIDER_COMMISSIONS,
    GET_RIDER_COMMISSION,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get RiderCommissionSettings Service
 * @returns {object}
 */
export const getRiderCommissionSettingsService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_RIDER_COMMISSION}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `&populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Warehouse Zones Service
 * @returns {object}
 */
export const getRiderCommissionSettingsByRiderIdService = function api(
    id,
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_ALL_RIDER_COMMISSIONS_BY_RIDER_ID}?page=${page}&${
            all ? `all=true&` : ''
        }limit=${limit}&${filter ? `filter=${filter}&` : ''}${sort ? `sort=${sort}&` : ''}${
            order ? `order=${order}&` : `order=-1`
        }${populate ? `&populate=${populate}&` : ''}${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).setPathParams({ id }).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get All RiderCommissionSettings
 * @returns {Promise}
 */
export const getAllRiderCommissionSettingsService = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_RIDER_COMMISSIONS).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
