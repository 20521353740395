/** Default values for New Webhook form */
export const emptyFormValues = {
    name: '',
    postUrl: '',
    eventGroup: 'deliveryUpdates',
    authenticationType: 'none',
    systemAuthKey: '',
    systemAuthValue: '',
    addTo: 'header',
    password: '',
};

/** Default values for Update Webhook form */
export const emptyUpdateFormValues = {
    name: '',
    postUrl: '',
    eventGroup: 'deliveryUpdates',
};

export const authenticationTypes = [
    {
        name: 'None',
        value: 'none',
    },
    {
        name: 'API Key',
        value: 'apiKey',
    },
    {
        name: 'Bearer Token',
        value: 'bearer',
    },
];

export const addToValues = [
    {
        name: 'Header',
        value: 'header',
    },
    {
        name: 'Query Parameters',
        value: 'queryParams',
    },
];
