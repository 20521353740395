import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

function TableExport(props) {
    const { toggleExportModal } = props;
    return (
        <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="export-btn-table"
            onClick={() => toggleExportModal()}
            id="export-table-btn"
        >
            <i className="fas fa-file-import mr-2" style={{ color: '#FFF' }} />
            Export
        </motion.div>
    );
}

TableExport.propTypes = {
    toggleExportModal: PropTypes.func,
};

export default TableExport;
