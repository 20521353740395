/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import { Button, ButtonGroup, Col, Input, Label, Row, Spinner } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

// #region assets | components
import { CustomToast, ImageUploadModal } from 'components';
import AttachmentPreviewModal from 'components/Modals/Image-Preview';
import CreatableSelectAdvanced from 'components/CreateableSelect';

// #region other imports
import { THEME_COLOR_2 } from 'theme';

// #region services
import {
    createDeliveryAttachmentService,
    updateDeliveryAttachmentService,
    uploadDeliveryAttachmentService,
} from 'services/deliveries.service';
import { createSystemDataService, getAllSystemDataByTypeAndModuleService } from 'services/system-data.service';

// #regions utils
import { SYSTEM_DATA_TYPES } from 'constants/enums';
import {
    apiErrorHandlerV2,
    buildFormDataSingle,
    getDefaultValueForSelect,
    reactSelectCustomStyles,
} from 'utils/helpers';
import { validateAddImageForm } from './utils';

// This is the initial format for Attachments same in default state in DB
const initialAttachmentState = {
    attachmentImageUrl: { data: null, lastUpdated: null, isDbSaved: false },
};

const initialFormDataState = {
    category: 'Attachment',
};

function AddDeliveryAttachmentsModule(props) {
    const {
        deliveryId,
        data: deliveryData,
        setShowModal,
        updateFormData,
        updateAttachmentData,
        loadAttachments,
        riderId,
    } = props;

    const { referenceNumber } = deliveryData;

    const _isMounted = useRef(true);

    // Form
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [editingMode, setEditingMode] = useState(false);

    // Data
    const [formData, setFormData] = useState({ ...initialFormDataState, ...updateFormData });
    const [attachmentTypes, settAttachmentTypes] = useState([]);

    // Loading
    const [formLoading, setFormLoading] = useState(false);
    const [customLoading, setCustomLoading] = useState({ type: false });

    // POD attachment data
    const [attachmentsObj, setAttachmentsObj] = useState({ ...initialAttachmentState, ...updateAttachmentData });

    const [attachmentPreviewModalState, setAttachmentPreviewModalState] = useState({
        show: false,
        multiple: false,
        imageUrl: null,
    });

    const [imageUploadModalState, setImageUploadModalState] = useState({
        show: false,
        type: null,
        data: null,
        header: null,
        title: null,
        subTitle: null,
    });

    // sideEffect to load delivery complete reasons
    useEffect(() => {
        if (updateFormData) {
            setEditingMode(true);
        }
    }, []);

    // load delivery complete reasons to dropdown
    useEffect(() => {
        loadDeliveryCompleteReasons();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    /**
     * Handle Create Change
     */
    const handleCreateSelectChange = async (record, id) => {
        if (record) {
            if (id === 'type') {
                setCustomLoading((prev) => ({ ...prev, productCategory: true }));
                try {
                    const { data } = await createSystemDataService({
                        type: SYSTEM_DATA_TYPES.DELIVERY_ATTACHMENT_TYPES.TYPES.ATTACHMENT_REASON,
                        module: SYSTEM_DATA_TYPES.DELIVERY_ATTACHMENT_TYPES.MODULE,
                        name: record,
                        isVisibleToRider: false,
                    });

                    if (data) {
                        settAttachmentTypes([...attachmentTypes, { label: data.name, value: data.name }]);
                        setFormData({
                            ...formData,
                            [id]: record,
                        });
                    }
                } catch (e) {
                    const { message } = apiErrorHandlerV2(e);
                    toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                        position: 'top-right',
                    });
                }
                setCustomLoading((prev) => ({ ...prev, productCategory: false }));
                return;
            }

            setFormData({
                ...formData,
                [id]: record,
            });
        }
        setCustomLoading((prev) => ({ ...prev, productCategory: false }));
    };

    /**
     * Load delivery Complete Reasons to the Dropdown
     */
    const loadDeliveryCompleteReasons = async () => {
        try {
            const { data } = await getAllSystemDataByTypeAndModuleService(
                SYSTEM_DATA_TYPES.DELIVERY_ATTACHMENT_TYPES.TYPES.ATTACHMENT_REASON,
                SYSTEM_DATA_TYPES.DELIVERY_ATTACHMENT_TYPES.MODULE
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                settAttachmentTypes(data.map((x) => ({ label: x.name, value: x.name })));
            }
        } catch (e) {
            const { message } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    /**
     * Handle Submit
     */
    const handleSubmit = async (event, errors = []) => {
        let isSuccess = false;
        setSubmitted(true);
        event.persist();

        const { isFormValid, message } = validateAddImageForm(formData, attachmentsObj);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        if (errors.length > 0) {
            setValidated(true);
            return;
        }

        setFormLoading(true);
        let payload = { ...formData, riderId };

        // Attachments Handling
        if (attachmentsObj.attachmentImageUrl.data) {
            if (!attachmentsObj.attachmentImageUrl.isDbSaved) {
                const imageData = buildFormDataSingle(attachmentsObj.attachmentImageUrl.data);
                try {
                    if (!editingMode) {
                        const { data } = await uploadDeliveryAttachmentService(deliveryId, imageData);
                        if (data._id) {
                            const attachmentId = data._id;
                            payload = { ...payload, attachmentId, deliveryId };
                        }
                    }
                } catch (e) {
                    const { message: exception } = apiErrorHandlerV2(e);
                    toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                        position: 'top-right',
                    });
                }
            }
            try {
                if (editingMode) {
                    const { data } = await updateDeliveryAttachmentService(updateFormData.id, payload);
                    if (data._id) {
                        isSuccess = true;
                        toast.custom(
                            (t) => (
                                <CustomToast text="Successfully Updated the Delivery Attachment" t={t} type="success" />
                            ),
                            {
                                position: 'top-right',
                            }
                        );
                    }
                }
                if (!editingMode) {
                    const { data } = await createDeliveryAttachmentService(payload);
                    if (data._id) {
                        isSuccess = true;
                        toast.custom(
                            (t) => (
                                <CustomToast text="Successfully created the Delivery Attachment" t={t} type="success" />
                            ),
                            {
                                position: 'top-right',
                            }
                        );
                    }
                }
            } catch (e) {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        }

        setValidated(false);
        setSubmitted(false);
        setFormLoading(false);

        if (isSuccess) {
            setShowModal(false);
            loadAttachments();
        }
    };

    // Handling Input functions
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleSelectChange = (event, id) => {
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleCheckChangeFilter = (event) => {
        const { id, checked } = event.target;
        if (id === 'isVisibleToRider') {
            setFormData({
                ...formData,
                [id]: checked,
            });
        }
    };

    const handleImageUpload = (header, type, title, fileTypes) => {
        setImageUploadModalState({
            show: true,
            data: null,
            header,
            type,
            title,
            fileTypes,
            onUpload: async (files) => {
                setAttachmentsObj({
                    ...attachmentsObj,
                    [type]: {
                        data: files[0],
                        lastUpdated: new Date(),
                        isDbSaved: false,
                        name: files[0].name || '',
                    },
                });
                setImageUploadModalState((prevState) => ({
                    ...prevState,
                    loading: false,
                    show: false,
                }));
            },
        });
    };
    const toggleImageUploadModal = () => {
        setImageUploadModalState({
            show: false,
            data: null,
            type: null,
        });
    };

    const selectedType = useMemo(() => getDefaultValueForSelect(formData.type), [formData.type]);
    return (
        <>
            <div className="font-size-14 w-100 p-0">
                <div
                    className="font-size-16 text-white"
                    style={{ backgroundColor: THEME_COLOR_2, padding: 15, borderRadius: '5px 5px 0 0' }}
                >
                    Add Attachment for Delivery - {referenceNumber}
                </div>
                <div className="modal__form-table">
                    <AvForm onSubmit={handleSubmit}>
                        <fieldset>
                            <div className="modal_form-item">
                                <Label htmlFor="type">Type</Label>
                                <CreatableSelectAdvanced
                                    options={attachmentTypes}
                                    isClearable
                                    value={selectedType}
                                    required
                                    styles={reactSelectCustomStyles}
                                    id="type"
                                    isLoading={customLoading.reason}
                                    onChange={(event) => handleSelectChange(event, 'type')}
                                    onCreate={(event) => handleCreateSelectChange(event, 'type')}
                                    submitted={submitted}
                                    validated={validated}
                                />
                            </div>
                            <div className="modal_form-item">
                                <Label htmlFor="description">Text</Label>
                                <div>
                                    <Input
                                        style={{ borderRadius: 2, padding: 5, width: '100%' }}
                                        rows="3"
                                        cols=""
                                        type="textarea"
                                        id="text"
                                        value={formData.text}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            {!editingMode && (
                                <div className="modal_form-item">
                                    <Label htmlFor="attachment">
                                        Upload{' '}
                                        {attachmentsObj.attachmentImageUrl.data &&
                                            attachmentsObj.attachmentImageUrl.data.name && (
                                                <div>{attachmentsObj.attachmentImageUrl.data.name}</div>
                                            )}
                                    </Label>

                                    <div style={{ background: '#FFFAED', height: '80px' }}>
                                        <ButtonGroup>
                                            {attachmentsObj.attachmentImageUrl.data && (
                                                <Button
                                                    className="mt-4 ml-4"
                                                    color="warning"
                                                    onClick={() =>
                                                        setAttachmentPreviewModalState({
                                                            type: 'default',
                                                            show: true,

                                                            header: `Delivery Id  ${
                                                                deliveryId ? `- ${deliveryId}` : ''
                                                            }`,
                                                            imageUrl: editingMode
                                                                ? attachmentsObj.attachmentImageUrl.data.preview
                                                                : attachmentsObj.attachmentImageUrl.data,
                                                            isDbSaved: attachmentsObj.attachmentImageUrl.isDbSaved,
                                                        })
                                                    }
                                                    id="view-pod-front-btn"
                                                >
                                                    View File
                                                </Button>
                                            )}
                                            <Button
                                                className="mt-4 ml-4"
                                                color="warning"
                                                onClick={() => {
                                                    handleImageUpload(
                                                        'Attachment',
                                                        'attachmentImageUrl',
                                                        'Attachment',
                                                        'image/jpeg, image/png, application/pdf, message/rfc822'
                                                    );
                                                }}
                                                id="upload-attachment-btn"
                                            >
                                                {attachmentsObj.attachmentImageUrl.data ? 'Update' : 'Upload'}
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            )}
                            <Row className="ml-2 mt-4 modal_form-item">
                                <Col xs="auto">
                                    <Input
                                        onChange={handleCheckChangeFilter}
                                        type="checkbox"
                                        validate={{ required: { value: true } }}
                                        checked={formData.isVisibleToRider}
                                        id="isVisibleToRider"
                                    />
                                </Col>
                                <Col className="ml-n3" xs="auto">
                                    <Label htmlFor="isVisibleToRider" className="form-check-label">
                                        Display to Riders
                                    </Label>
                                </Col>
                            </Row>

                            <div style={{ fontSize: 14 }} className="mb-6 mt-3 flex row">
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn pt-1 pb-1 m-2 mb-3 pr-5 pl-5 shadow-lg "
                                    style={{ borderRadius: 10 }}
                                    type="submit"
                                    disabled={formLoading}
                                >
                                    {formLoading ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>{editingMode ? 'Update' : 'Confirm'}</>
                                    )}
                                </motion.button>
                            </div>
                        </fieldset>
                    </AvForm>
                </div>
            </div>

            {imageUploadModalState.show && (
                <ImageUploadModal toggleModal={toggleImageUploadModal} {...imageUploadModalState} />
            )}

            {attachmentPreviewModalState.show && (
                <AttachmentPreviewModal
                    image={attachmentPreviewModalState.imageUrl}
                    show={attachmentPreviewModalState.show}
                    type={attachmentPreviewModalState.type}
                    header={attachmentPreviewModalState.header}
                    toggleModal={() => {
                        setAttachmentPreviewModalState({ show: false, multiple: false });
                    }}
                    {...attachmentPreviewModalState}
                    isFormAttachmentModal
                />
            )}
        </>
    );
}

AddDeliveryAttachmentsModule.propTypes = {
    riderId: PropTypes.any,
    deliveryId: PropTypes.any,
    setShowModal: PropTypes.func,
    createBillingRecord: PropTypes.func,
    toggleModal: PropTypes.func,
    referenceNumber: PropTypes.any,
    data: PropTypes.any,
    loadAttachments: PropTypes.func,
    updateFormData: PropTypes.func,
    updateHistoryWithData: PropTypes.func,
    updateAttachmentData: PropTypes.any,
};

export default AddDeliveryAttachmentsModule;
