import { responseErrors } from './error-codes';

// #region constants
import { PASSWORD_RESET_RESPONDS } from './auth/forgetPassword.constant';

// eslint-disable-next-line consistent-return
export const getApiErrorMessage = (error, statusCode, err) => {
    let errorMessage = error;
    if (statusCode && statusCode === 422) {
        const arrayOfMessages = error;
        const isErrorFound = getIsErrorFound(arrayOfMessages);
        if (isErrorFound) {
            errorMessage = isErrorFound.msg;
        }
    }

    // Merchant Profile Deleted Scenario
    if (err) {
        if (err.userMessage && err.userMessage === 'Merchant Profile Deleted') {
            const message = getApiErrorMessage(error, statusCode);
            return `${message} , Merchant Profile deleted contact admin `;
        }
    }

    switch (errorMessage) {
        case 'USER_DOES_NOT_EXIST':
            return 'User does not exist';
        case 'UNAPPROVED_USER':
            return 'User is not approved';
        case 'WRONG_PASSWORD':
            return 'Password do not match try again';
        case 'EMAIL_ALREADY_EXISTS':
            return 'Email Already Exists';
        case 'USERNAME_ALREADY_EXISTS':
            return 'Username already exists';
        case 'MERCHANT_ALREADY_EXISTS':
            return 'Merchant already exists';
        case 'EMAIL_AND_MERCHANT_ID_ALREADY_EXISTS':
            return 'Email is used in selected Merchant';
        case 'PASSWORD_TOO_SHORT':
            return 'Password is too short';
        case 'USER_DISABLED':
            return 'Account Disabled';
        case 'ROLE_ALREADY_EXISTS':
            return 'Role already exists';
        case 'RECORD_NOT_FOUND':
            return 'Record Not Found';
        case 'RECORD_ALREADY_EXISTS':
            return 'Record already exists';
        case 'NOT_FOUND_OR_ALREADY_VERIFIED':
            return 'Already Verified or Not Found';
        case 'VERIFICATION_TOKEN_NOT_FOUND':
            return 'Something Went Wrong';
        case 'USER_ALREADY_VERIFIED':
            return 'User Already Verified';
        case 'USER_NOT_VERIFIED':
            return 'User Account Not Verified';
        case 'MOBILE_NO_ALREADY_EXISTS':
            return 'Mobile Number Already Exists';

        // 422 Validations
        case 'PASSWORD_TOO_SHORT_MIN_5':
            return 'Password Length too Short';
        case 'NIC_ALREADY_EXISTS':
            return 'NRIC Already Exists';

        // API ERROR CUSTOM ERRORS
        case responseErrors.STOCK_QUANTITY_MISMATCH_01.msg:
            return responseErrors.STOCK_QUANTITY_MISMATCH_01.consoleMessage;

        default:
            break;
    }
};

const getIsErrorFound = (arrayOfMessages) => {
    if (Array.isArray(arrayOfMessages)) {
        const isErrorFound = arrayOfMessages.find(
            (x) => x.msg === 'PASSWORD_TOO_SHORT_MIN_5' || x.msg === 'PASSWORD_TOO_SHORT'
        );
        return isErrorFound;
    }
    return null;
};

/**
 * Under getApiMessage function, The message {RESET_EMAIL_SENT } return from backend and
 * That message gets by forgetuser function in \src\store\reducers\auth\forgetpwd\saga.js
 * and that will be pass into getApiMessage function in src/constant/api.js/getapimessage.
 * And getApiMessage function identify the case and return {PASSWORD_RESET_RESPONDS.emailCheckInbox}.
 */

export const getApiMessage = (error) => {
    switch (error) {
        case 'RESET_EMAIL_SENT':
            return PASSWORD_RESET_RESPONDS.emailCheckInbox;
        default:
            break;
    }
    return '';
};
