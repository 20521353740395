import * as React from 'react';
import PropTypes from 'prop-types';

function Restore(props) {
    const { width, height } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 25}
            height={height || 25}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#clip0_5878_403)">
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M9 13L5 9m0 0l4-4M5 9h11a4 4 0 110 8h-1"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_5878_403">
                    <path fill="#fff" d="M0 0H24V24H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

Restore.propTypes = {
    // color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default Restore;
