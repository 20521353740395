import * as React from 'react';
import PropTypes from 'prop-types';

function HouseMapMarker(props) {
    const { color = '#FF9300', width = '35', height = '35' } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6396_373)">
                <path
                    d="M8.54167 20.5H5.125L20.5 5.125L35.875 20.5H32.4583"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.5415 20.5V32.4583C8.5415 33.3645 8.90147 34.2335 9.54222 34.8743C10.183 35.515 11.052 35.875 11.9582 35.875H29.0415C29.9477 35.875 30.8167 35.515 31.4575 34.8743C32.0982 34.2335 32.4582 33.3645 32.4582 32.4583V20.5"
                    stroke="#FF9300"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.0835 20.5H23.9168V27.3333H17.0835V20.5Z"
                    stroke="#FF9300"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_6396_373">
                    <rect width="41" height="41" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
HouseMapMarker.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default HouseMapMarker;
