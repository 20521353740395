import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';

function ToolTipDefault(props) {
    const { targetId, text = '', position = 'right' } = props;

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <div>
            <Tooltip placement={position} isOpen={tooltipOpen} target={targetId} toggle={toggle}>
                {text}
            </Tooltip>
        </div>
    );
}

ToolTipDefault.propTypes = {
    targetId: PropTypes.string,
    text: PropTypes.string,
    position: PropTypes.string,
};

export default ToolTipDefault;
