/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

// #region components
import { FailExclamation, SuccessCheck } from 'components/Icons';

// #region constants
import { THEME_COLOR_2 } from 'theme';

// #endregion imports
import { defaultModelData } from '../constant';
import RoutePlanModalTable from '../containers/Modal-Table/RoutePlanModelTable';

/**
 * This component is responsible for displaying the three different modals
 * Based on the conditions of fail, success and partial for assigning a riders for a multiple deliveries in route plan
 * @returns <RoutePlanningRiderAssignStatus>
 */
export default function RoutePlanningRiderAssignStatus(props) {
    const { data, toggleModal } = props;

    // this suppose to holding the states of 'SUCCESS', 'FAIL' and 'PARTIAL'
    const [status, setStatus] = useState(null);

    const [riderData, setRiderData] = useState(null);

    // Data
    const [failedReasonData, setFailedReasonData] = useState(defaultModelData);
    const [successData, setSuccessData] = useState(defaultModelData);

    useEffect(() => {
        if (data) {
            const { fail = [], success = [], rider } = data;

            if (rider) {
                setRiderData(rider);
            }

            if (fail.length === 0 && success.length > 0) {
                setStatus('SUCCESS');
                setSuccessData(success);
                return;
            }

            if (success.length === 0 && fail.length > 0) {
                setStatus('FAIL');
                setFailedReasonData(fail);
                return;
            }

            if (fail.length > 0 && success.length > 0) {
                setStatus('PARTIAL');
                setFailedReasonData(fail);
                setSuccessData(success);
                return;
            }

            setStatus('SUCCESS');
        }
    }, [data]);

    function renderIcon() {
        if (!status) {
            return <div />;
        }

        if (status === 'FAIL') {
            return (
                <div>
                    <FailExclamation />
                </div>
            );
        }

        if (status === 'SUCCESS') {
            return (
                <div>
                    <SuccessCheck />
                </div>
            );
        }

        if (status === 'PARTIAL') {
            return (
                <div>
                    <SuccessCheck />
                </div>
            );
        }
        return <></>;
    }

    function renderData() {
        if (status === 'SUCCESS') {
            return (
                <>
                    <div>
                        {' '}
                        <span className="font-size-14">Rider / Driver : {riderData?.fullName || ''}</span>
                    </div>
                    <div>
                        <RoutePlanModalTable tableData={successData} status="success" />
                    </div>
                </>
            );
        }
        if (status === 'FAIL') {
            return (
                <>
                    <div>
                        {' '}
                        <span className="font-size-14">Rider / Driver : {riderData?.fullName || ''}</span>
                    </div>
                    <div>
                        <RoutePlanModalTable tableData={failedReasonData} />
                    </div>
                </>
            );
        }
        if (status === 'PARTIAL') {
            return (
                <>
                    <div>
                        {' '}
                        <span className="font-size-14">Rider / Driver : {riderData?.fullName || ''}</span>
                    </div>
                    <div className="font-size-15 font-weight-bold" style={{ color: '#0F891C' }}>
                        Delivery Assignment Success for Following Deliveries
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <RoutePlanModalTable tableData={successData} status="success" />
                    </div>
                    <div className="font-size-15 font-weight-bold" style={{ color: '#FD4A4A' }}>
                        Delivery Assignment Failed for Following Deliveries
                    </div>
                    <div>
                        <RoutePlanModalTable tableData={failedReasonData} />
                    </div>
                </>
            );
        }

        return <></>;
    }

    function renderHeader() {
        if (!status) {
            return <div />;
        }

        if (status === 'FAIL') {
            return <div className="font-weight-bold">Delivery Assignment Failed.</div>;
        }

        if (status === 'SUCCESS') {
            return <div className="font-weight-bold">Delivery Assignment Successful.</div>;
        }

        if (status === 'PARTIAL') {
            return <div className="font-weight-bold">Delivery Assignment Partially Successful.</div>;
        }
        return <></>;
    }

    if (!status) {
        return <div />;
    }

    return (
        <>
            <div className="font-size-18 w-100 p-0">
                <div
                    className="h-5 text-center text-white"
                    style={{
                        backgroundColor:
                            status === 'SUCCESS'
                                ? THEME_COLOR_2
                                : status === 'FAIL'
                                ? '#FD4A4A'
                                : status === 'PARTIAL'
                                ? '#807F7D'
                                : THEME_COLOR_2,
                        padding: 10,
                    }}
                >
                    Delivery Assignment
                </div>
                <div
                    className="d-flex justify-content-center align-content-center align-items-center "
                    style={{ flexDirection: 'column' }}
                >
                    {renderIcon()}
                    <div className="mt-2 mb-2 text-center">{renderHeader()}</div>
                    <div className="mt-2 mb-2 text-center">{renderData()}</div>
                    <div style={{ fontSize: 14 }} className="mb-4">
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius secondary-btn pt-1 pb-1 m-2 mb-3 pr-5 pl-5 shadow-lg "
                            onClick={() => {
                                toggleModal();
                            }}
                            style={{ borderRadius: 10 }}
                        >
                            Close
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
}

RoutePlanningRiderAssignStatus.propTypes = {
    data: PropTypes.any,
    toggleModal: PropTypes.func,
};
