/* eslint-disable indent */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';
import { Row, Card, CardBody, Spinner } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import './styles.css';

// #region assets | components
import { SaveAndUpdate, Finalize } from 'components/Icons';
import { CustomToast, ScootixModal } from 'components';

// #region other imports
import paths from 'routes/paths';

// #region utils
import { apiErrorHandlerV2 } from 'utils/helpers';
import { IS_SUPER_ADMIN } from 'utils/checkAuth';

// #endregion imports
import { finalizeBatchPrintRecord, updateArchiveBatchPrintRecord } from '../../services';
import { UPDATE_BATCH_PRINT_DELIVERY_DATA } from '../../actionTypes';
import { batchPrintFormDeliveryListInitialValues } from '../../constant';
function FooterBatchPrintContainer(props) {
    const { handleSubmit, formLoading, formData, BatchPrintForm, isUpdated, setFormData, setFormLoading } = props;

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    const history = useHistory();

    const dispatch = useDispatch();

    /**
     * Handle Archive Batch Print
     *
     */
    const handleArchivePrintRecord = async () => {
        setFormLoading(true);

        try {
            const { data } = await updateArchiveBatchPrintRecord(formData._id, { isArchived: true });
            if (data) {
                setFormData({ ...formData, ...data });
            }
            toast.custom((t) => <CustomToast text="Successfully Archived Batch Print" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const { message: error } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={error} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading(false);
    };

    /**
     * Handle Finalize Batch Print
     *
     */
    const handleFinalizePrint = async () => {
        setFormLoading(true);

        try {
            const payload = {}; // Update Payload
            // Append Selected Deliveries
            if (BatchPrintForm?.selectedDeliveries) {
                payload.selectedDeliveryIds = BatchPrintForm.selectedDeliveries;
            }
            const { data } = await finalizeBatchPrintRecord(formData._id, payload);
            if (data && data.doc) {
                setFormData({ ...formData, ...data.doc });
                if (data?.doc?.documentsArray) {
                    dispatch({
                        type: UPDATE_BATCH_PRINT_DELIVERY_DATA,
                        payload: {
                            ...batchPrintFormDeliveryListInitialValues,
                            docs: data.doc.documentsArray,
                            updatedAt: new Date(),
                        },
                    });
                }
                toast.custom(
                    (t) => (
                        <CustomToast
                            text="Please Wait You will be Notified Once Print Document Is Ready"
                            t={t}
                            type="wait"
                        />
                    ),
                    {
                        position: 'top-right',
                        duration: 6000,
                    }
                );
                const finalizedData = { ...formData, ...data.doc };
                // Manipulate the Header module with status
                history.replace({
                    pathname: `/${paths.LABEL_PRINT.SHIPPING_LABEL.BATCH_PRINT_VIEW}`,
                    search: `?id=${formData._id}`,
                    state: {
                        batchPrintParentData: finalizedData,
                    },
                });
            }
        } catch (e) {
            const { message: error } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={error} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setScootixModalState({ ...scootixModalState, show: null });
        setFormLoading(false);
        scrollToTop();
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    // Scroll all the way to the top after finalize print
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            {!formData.isArchived && (
                <Card style={{ borderRadius: 10 }}>
                    <CardBody>
                        <Row className="form-button-row" style={{ justifyContent: 'flex-start' }}>
                            {!formData.isFinalized && (
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="batch_print_footer_save_btn scootix-form-btn save-btn m-2 mb-3 nw-md pr-4 pl-4"
                                    onClick={handleSubmit}
                                    disabled={formLoading}
                                    style={{ borderRadius: 30 }}
                                >
                                    {formLoading ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>
                                            <SaveAndUpdate />
                                            <span className="h6">Update Batch Print</span>
                                        </>
                                    )}
                                </motion.button>
                            )}
                            {((isUpdated &&
                                formData.documentsArray &&
                                BatchPrintForm?.selectedDeliveries.length > 0 &&
                                !formData.isFinalized) ||
                                IS_SUPER_ADMIN()) && (
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="batch_print_footer_save_btn scootix-form-btn save-btn m-2 mb-3 nw-md pr-4 pl-4"
                                    onClick={() => {
                                        setScootixModalState({
                                            ...scootixModalState,
                                            show: true,
                                            message:
                                                'Are you sure? You can’t update this batch print once you finalize the batch print',
                                            onConfirm: () => handleFinalizePrint(),
                                        });
                                    }}
                                    disabled={formLoading}
                                    style={{ borderRadius: 30 }}
                                >
                                    {formLoading ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>
                                            <Finalize />
                                            <span className="h6">Finalize Print</span>
                                        </>
                                    )}
                                </motion.button>
                            )}
                            {formData.isFinalized && !formData.isArchived && (
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="batch_print_footer_save_btn scootix-form-btn save-btn m-2 mb-3 nw-md pr-4 pl-4"
                                    onClick={handleArchivePrintRecord}
                                    disabled={formLoading}
                                    style={{ borderRadius: 30 }}
                                >
                                    {formLoading ? (
                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                    ) : (
                                        <>
                                            <SaveAndUpdate />
                                            <span className="h6">Archive Batch Print</span>
                                        </>
                                    )}
                                </motion.button>
                            )}
                        </Row>
                    </CardBody>
                </Card>
            )}

            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} loading={formLoading} />
        </>
    );
}

FooterBatchPrintContainer.propTypes = {
    BatchPrintForm: PropTypes.any,
    handleSubmit: PropTypes.func,
    formLoading: PropTypes.bool,
    formData: PropTypes.object,
    setFormData: PropTypes.any,
    setFormLoading: PropTypes.func,
    isUpdated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    ...state,
});

export default connect(mapStateToProps, {})(FooterBatchPrintContainer);
