/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { Row, Col, Card, CardBody, FormGroup, Alert, Label, Container, Spinner, Media, Input } from 'reactstrap';
import toast from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';
import Avatar from 'react-avatar';
import InputMask from 'react-input-mask';

// #region assets
import {
    Select,
    BackdropLoader,
    ImageUploadModal,
    PlacesAutocompleteModal,
    CustomToast,
    PlacesAutocompleteField,
} from 'components';
import { getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { getMerchantsByClientIdService, getAllMerchantsService } from 'services/merchant.service';
import { getApiErrorMessage } from 'constants/api';
import { getUser, getVisitingObject, updateLocalUser } from 'utils/checkAuth';
import { USER_PROFILE_PIC } from 'constants/local-storage.constants';

// #region utils
import { __BLOCKED_FOR_MERCHANTS__ } from 'utils/checks';

// #endregion imports
import { getAllCountries } from 'services/countries.service';
import { TOAST_STYLES } from 'theme';
import ChangePasswordModal from 'components/Modals/Change-Password';
import { updateProfileService } from 'services/profile.service';
import { createAttachmentService, getAttachmentByKey, updateAttachmentService } from 'services/attachment.service';
import { buildLocationData, validateForm } from './utils';

function UserProfile() {
    const { visitingClient } = getVisitingObject();
    const userData = getUser();

    const initialFormValues = {
        country: visitingClient?.country || 'Malaysia',
    };

    // image
    const [images, setImages] = useState({
        profilePictureUrl: null,
    });

    // Form State
    // eslint-disable-next-line no-unused-vars
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);

    const [merchants, setMerchants] = useState([]);
    const [countries, setCountries] = useState([]);

    // Component State
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [imageUploadModalState, setImageUploadModalState] = useState({
        show: false,
        type: null,
        data: null,
        header: null,
        title: null,
        subTitle: null,
    });
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading] = useState(0);
    const [alertMessage, setAlertMessage] = useState(null);
    const [autoCompleteModal, setAutoCompleteModal] = useState({ type: null, show: false });

    useEffect(() => {
        if (userData && userData._id) {
            setFormData({ ...formData, ...userData });
        }
        getAllCountriesService();
    }, []);

    useEffect(() => {
        if (formData.profilePictureUrl) {
            getAttachmentByKey(formData.profilePictureUrl).then((res) => {
                setImages({ ...images, profilePictureUrl: res });
            });
        }
    }, [formData.profilePictureUrl]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    const getAllCountriesService = async () => {
        const data = await getAllCountries();
        setCountries(data);
    };

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        const { isFormValid, message } = validateForm(formData);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);

        try {
            const payload = {
                ...formData,
                fullName: `${formData.firstName} ${formData.lastName}`,
            };
            const { data } = await updateProfileService(formData._id, payload);
            if (data) {
                setFormData({ ...formData, ...data });
                updateLocalUser({ ...formData, ...data });
            }
            toast.success(`Successfully Updated Profile`);
            setIsFormSaved(true);
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors) {
                const errorMessage = getApiErrorMessage(e.data.errors.msg);
                toast.error(errorMessage);
                setAlertMessage(errorMessage);
            } else {
                toast.error('Something went wrong');
            }
        }
        setFormLoading(false);
        setSubmitted(false);
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const toggleResetPasswordModal = () => {
        setShowResetPasswordModal(!showResetPasswordModal);
    };

    const toggleImageUploadModal = () => {
        setImageUploadModalState({
            show: false,
            data: null,
            type: null,
        });
    };

    const updateProfilePicture = async (files) => {
        const image = files[0];
        const imageData = new FormData();
        imageData.append('file', image);
        setImageUploadModalState((prevState) => ({
            ...prevState,
            loading: true,
        }));
        let isSuccess = false;
        try {
            if (formData.profilePictureUrl) {
                const { data } = await updateAttachmentService(formData.profilePictureUrl, imageData);
                if (data && data.key) {
                    const response = await updateProfileService(formData._id, {
                        profilePictureUrl: data.key,
                        role: formData.role,
                    });
                    if (response.data) {
                        updateLocalUser({ ...formData, ...response.data });
                        setFormData({ ...formData, ...response.data });
                        isSuccess = true;
                        window.sessionStorage.removeItem(USER_PROFILE_PIC);
                    }
                }
            } else {
                const { data } = await createAttachmentService(imageData);
                if (data && data.key) {
                    const response = await updateProfileService(formData._id, {
                        profilePictureUrl: data.key,
                        role: formData.role,
                    });
                    if (response.data) {
                        updateLocalUser({ ...formData, ...response.data });
                        setFormData({ ...formData, ...response.data });
                        isSuccess = true;
                        window.sessionStorage.removeItem(USER_PROFILE_PIC);
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }

        if (isSuccess) {
            toast.success(
                `Successfully ${formData.profilePictureUrl ? 'updated' : 'saved'} profile picture `,
                TOAST_STYLES.SUCCESS
            );
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
        setImageUploadModalState((prevState) => ({
            ...prevState,
            loading: false,
            show: false,
        }));
    };

    const showImageUpload = () => {
        setImageUploadModalState({
            show: true,
            data: null,
            header: 'Upload Profile Picture',
            type: 'profile-picture',
            fileTypes: 'image/jpeg, image/png',
            title: `${formData.profilePictureUrl ? 'Update' : 'Save'} Profile Picture`,
            onUpload: (files) => updateProfilePicture(files),
        });
    };

    const returnLocationData = (id, idMeta, data) => {
        const builtData = buildLocationData(id, idMeta, data);
        setFormData({ ...formData, ...builtData });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row style={{ justifyContent: 'space-between' }}>
                        <div>
                            {formData._id && (
                                <>
                                    <div>
                                        <Row style={{ marginLeft: 10, justifyContent: 'center' }}>
                                            {images.profilePictureUrl ? (
                                                <Media
                                                    className="image-upload-avatar"
                                                    onClick={showImageUpload}
                                                    src={images.profilePictureUrl}
                                                    roundedCircle
                                                    style={{ borderRadius: 150, width: 70, height: 70 }}
                                                />
                                            ) : (
                                                <Avatar
                                                    className="image-upload-avatar"
                                                    onClick={showImageUpload}
                                                    name={formData.fullName}
                                                    size="70"
                                                    round="50px"
                                                />
                                            )}
                                            <Col>
                                                <h5 style={{ fontWeight: 'bold', marginBottom: 3 }}>
                                                    {formData.fullName}
                                                </h5>
                                                <span>{formData.email}</span>
                                                <p>
                                                    {`Created at ${moment(formData.createdAt).format(
                                                        'DD-MM-YYYY hh:mm A'
                                                    )}`}
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            )}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <>
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => toggleResetPasswordModal()}
                                >
                                    <i className="fas fa-key mr-2 fa-lg" />
                                    Change Password
                                </motion.div>
                            </>
                        </div>
                    </Row>
                    <Card>
                        <CardBody>
                            {!formData.verified && formData._id && <Alert color="warning">User not verified!</Alert>}
                            {alertMessage && <Alert>{alertMessage}</Alert>}
                            <h4 className="card-title">Profile details</h4>
                            <AvForm
                                className="needs-validation default-form"
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                                id="userRegistrationForm"
                            >
                                <fieldset disabled>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="email">E-Mail Address</Label>
                                                <AvField
                                                    name="email"
                                                    placeholder="E-mail"
                                                    type="text"
                                                    errorMessage="Enter a valid email address"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="username">Username</Label>
                                                <AvField
                                                    name="username"
                                                    placeholder="Username"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="username"
                                                    value={formData.username}
                                                    onChange={handleInputChange}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="firstName">First name</Label>
                                                <AvField
                                                    name="firstName"
                                                    placeholder="First name"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="firstName"
                                                    value={formData.firstName}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="lastName">Last name</Label>
                                                <AvField
                                                    name="lastName"
                                                    placeholder="Last name"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="lastName"
                                                    value={formData.lastName}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="mobileNo">Mobile No</Label>
                                                <AvField
                                                    name="mobileNo"
                                                    placeholder="Mobile No"
                                                    mask="+60 99-999 99999"
                                                    maskChar=""
                                                    tag={[Input, InputMask]}
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="mobileNo"
                                                    value={formData.mobileNo}
                                                    onChange={handleInputChange}
                                                    disabled={formData.isApproved}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {__BLOCKED_FOR_MERCHANTS__ && (
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="merchantId">Merchant</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(
                                                            getMerchantSelect(formData.merchantId)
                                                        )}
                                                        options={merchants}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                        isDisabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="designation">Designation</Label>
                                                <AvField
                                                    name="designation"
                                                    placeholder="Designation"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="designation"
                                                    value={formData.designation}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="department">Department</Label>
                                                <AvField
                                                    name="department"
                                                    placeholder="Department"
                                                    type="text"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="department"
                                                    value={formData.department}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="nic">NRIC Number</Label>
                                                <InputMask
                                                    mask="999999-99-9999"
                                                    value={formData.nic}
                                                    className="form-control"
                                                    name="nic"
                                                    errorMessage="Please provide a valid NRIC."
                                                    onChange={handleInputChange}
                                                    required
                                                    validate={{ required: { value: true } }}
                                                    id="nic"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="socsoNo">SOCSO Number</Label>
                                                <AvField
                                                    name="socsoNo"
                                                    placeholder="SOCSO"
                                                    type="text"
                                                    errorMessage="Please provide a valid SOCSO."
                                                    maxLength="12"
                                                    className="form-control"
                                                    validate={{ required: { value: !formData.merchantId } }}
                                                    id="socsoNo"
                                                    disabled={formData.isApproved}
                                                    value={formData.socsoNo}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <Row>
                                                <Col md="10" sm="10" xs="10">
                                                    <FormGroup>
                                                        <Label htmlFor="address">Address</Label>
                                                        <PlacesAutocompleteField
                                                            addressData={formData.addressMeta || null}
                                                            returnLocationData={returnLocationData}
                                                            id="address"
                                                            idMeta="addressMeta"
                                                            isRequired
                                                            submitted={submitted}
                                                            validated={validated}
                                                            isClearable
                                                        />
                                                        {autoCompleteModal.type === 'address' &&
                                                            autoCompleteModal.show && (
                                                                <PlacesAutocompleteModal
                                                                    show={autoCompleteModal.show}
                                                                    isModal
                                                                    addressData={formData.addressMeta || null}
                                                                    returnLocationData={returnLocationData}
                                                                    toggle={() =>
                                                                        setAutoCompleteModal({
                                                                            show: !autoCompleteModal.show,
                                                                            type: 'address',
                                                                        })
                                                                    }
                                                                    id="address"
                                                                    idMeta="addressMeta"
                                                                />
                                                            )}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="2" sm="2" xs="2">
                                                    <motion.div
                                                        style={{ width: 35, height: 35 }}
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        className="scootix-btn-circle shadow-lg nw-md ml-3 order-map-pin-container"
                                                        onClick={() =>
                                                            setAutoCompleteModal({
                                                                show: !autoCompleteModal.show,
                                                                type: 'address',
                                                            })
                                                        }
                                                    >
                                                        <div className="map-pin">
                                                            <i className="ri-map-pin-line fa-lg" />
                                                        </div>
                                                    </motion.div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="postalCode">Postal Code</Label>
                                                <AvField
                                                    name="postalCode"
                                                    placeholder="Postal Code"
                                                    type="Number"
                                                    minLength={5}
                                                    maxLength={5}
                                                    errorMessage="Please provide a valid Postal Code."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="postalCode"
                                                    value={formData.postalCode}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="city">City</Label>
                                                <AvField
                                                    name="city"
                                                    placeholder="City"
                                                    type="text"
                                                    errorMessage="Please provide a valid city."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="city"
                                                    value={formData.city}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="state">State</Label>
                                                <AvField
                                                    name="state"
                                                    placeholder="State"
                                                    type="text"
                                                    errorMessage="Please provide a valid state."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="state"
                                                    value={formData.state}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="country">Country</Label>
                                                <Select
                                                    value={getDefaultValueForSelect(formData.country)}
                                                    options={countries.map((country) => ({
                                                        label: country.details.name,
                                                        value: country.details.name,
                                                    }))}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'country')}
                                                    submitted={submitted}
                                                    required
                                                    validated={validated}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ justifyContent: 'flex-start' }}>
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                            type="submit"
                                            disabled={formLoading}
                                        >
                                            {formLoading ? (
                                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                            ) : (
                                                <>
                                                    <i className="fas fa-save left-icon"></i>
                                                    <span className="h6">Update</span>
                                                </>
                                            )}
                                        </motion.button>
                                    </Row>
                                </fieldset>
                            </AvForm>
                        </CardBody>
                    </Card>
                    <BackdropLoader show={backdropLoading} opacity={0.8} />
                    {showResetPasswordModal && formData._id && (
                        <ChangePasswordModal userData={formData} toggleModal={toggleResetPasswordModal} />
                    )}
                    {imageUploadModalState.show && formData._id && (
                        <ImageUploadModal toggleModal={toggleImageUploadModal} {...imageUploadModalState} />
                    )}
                </Container>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

const HotUserProfilePage = hot(UserProfile);

export default connect(mapStateToProps, {})(HotUserProfilePage);
