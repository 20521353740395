/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable radix */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Row, Col, Card, CardBody, FormGroup, Label, Spinner, Input, Container } from 'reactstrap';
import toast from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useHistory } from 'react-router-dom';
import * as qs from 'query-string';

// #region assets | components
import { Select, BackdropLoader, CustomToast, FormStatusBtn } from 'components';
import { apiErrorHandler, getDateValue, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Hoc from 'components/Hoc';

// #region imports
import { currencyCodes, DELIVERY_TYPES } from 'constants/constants';
import PATHS from 'routes/paths';
import { SubTitle } from 'styles';

// #endregion imports
import { updateRiderCommissionService, createRiderCommissionService } from './services';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Rider / Driver Management', link: PATHS.RIDER_PROFILE_MANAGEMENT.LIST_VIEW },
];

/**
 * Rider Commission Form Page
 * @param {*} props
 * @returns
 */
function RiderCommissionFormPage(props) {
    // Component
    const [loading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading] = useState(0);

    const history = useHistory();

    const [formData, setFormData] = useState({ currency: 'MYR' });

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isViewMode] = useState(false);
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [riderParentData, setRiderParentData] = useState({});
    const [prevCompState, setPrevCompState] = useState({});

    useEffect(() => {
        if (!props.location) {
            history.goBack();
            return;
        }

        // eslint-disable-next-line react/prop-types
        const formType = qs.parse(props.location.search);
        if (formType && formType.type && formType.type === 'new') {
            !props.location.state && history.goBack();
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Rider Commission Setting', link: '#' }]);
            const { riderParentData } = props.location.state;
            setRiderParentData({ ...riderParentData });
        }

        if (formType && formType.type && formType.type === 'edit') {
            handleUpdateComponentEditMode();
        }

        const { prevCompState = null } = props.location.state || {};
        if (prevCompState) {
            setPrevCompState(prevCompState);
        }
    }, []);

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        setFormLoading(true);

        try {
            const payload = formData;
            payload.riderId = riderParentData._id;
            let updatedPayloadData;

            if (editingMode) {
                const { data } = await updateRiderCommissionService(payload);
                if (data) {
                    setFormData({ ...formData, ...data });
                    updatedPayloadData = { ...formData, ...data };
                }
            } else {
                const { data } = await createRiderCommissionService(payload);
                if (data) {
                    setFormData({ ...formData, ...data });
                    updatedPayloadData = { ...formData, ...data };
                }
                setEditingMode(true);
            }

            try {
                if (updatedPayloadData) {
                    history.replace({
                        pathname: `${PATHS.PAYMENTS.RIDER_COMMISSIONS_FORM}`,
                        search: `?type=edit`,
                        state: {
                            riderCommissionSettingParentData: updatedPayloadData,
                            prevCompState,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} Rider Commission Setting`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setIsFormSaved(true);
        } catch (e) {
            console.log(e);
            const errorMessage = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={errorMessage} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading(false);
        setSubmitted(false);
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;

        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleUpdateComponentEditMode = () => {
        try {
            // eslint-disable-next-line react/prop-types
            const { riderCommissionSettingParentData } = props.location.state;
            setFormData({ ...formData, ...riderCommissionSettingParentData });
            // Update URL Based on Condition
            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Rider Commission Setting', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    return (
        <>
            <Hoc {...props}>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                        <Row
                            style={{
                                margin: 2,
                                justifyContent: 'space-between',
                            }}
                        >
                            {!editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                >
                                    <i className="far fa-times-circle mr-2 fa-lg" />
                                    Cancel
                                </motion.div>
                            )}
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormStatusBtn isFormSaved={isFormSaved} />
                            </div>

                            {editingMode && (
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                >
                                    <i className="fas fa-arrow-left mr-2 fa-lg" />
                                    Back to Rider Commission Settings
                                </motion.div>
                            )}
                        </Row>
                        <Card>
                            <CardBody>
                                <Row style={{ justifyContent: 'space-between', alignItems: 'center', margin: 2 }}>
                                    <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                        RIDER COMMISSION SETTING
                                    </SubTitle>
                                </Row>
                                <br />
                                <AvForm
                                    autoComplete="off"
                                    className="needs-validation default-form"
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                    id="billingForm"
                                >
                                    <fieldset disabled={isViewMode || formLoading}>
                                        <input type="hidden" value="something" />
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="deliveryType">Delivery Type</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.deliveryType)}
                                                        options={[
                                                            {
                                                                label: DELIVERY_TYPES.NEW_ORDER,
                                                                value: DELIVERY_TYPES.NEW_ORDER,
                                                            },
                                                            {
                                                                label: DELIVERY_TYPES.RETURN_ORDER,
                                                                value: DELIVERY_TYPES.RETURN_ORDER,
                                                            },
                                                            {
                                                                label: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
                                                                value: DELIVERY_TYPES.ON_DEMAND_DELIVERY,
                                                            },
                                                        ]}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'deliveryType')}
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="riderCommissionMethod">Commission Method</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.riderCommissionMethod)}
                                                        options={[
                                                            {
                                                                label: 'Fixed Per Delivery',
                                                                value: 'Fixed Per Delivery',
                                                            },
                                                            { label: 'Mileage-Based Fee', value: 'Mileage-Based Fee' },
                                                            { label: 'Commission', value: 'Commission' },
                                                        ]}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) =>
                                                            handleSelectChange(event, 'riderCommissionMethod')
                                                        }
                                                        submitted={submitted}
                                                        required
                                                        validated={validated}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="currency">Currency</Label>
                                                    <Select
                                                        value={getDefaultValueForSelect(formData.currency)}
                                                        options={currencyCodes.map((currency) => ({
                                                            label: currency,
                                                            value: currency,
                                                        }))}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'currency')}
                                                        submitted={submitted}
                                                        required
                                                        isDisabled
                                                        validated={validated}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {formData.riderCommissionMethod === 'Fixed Per Delivery' && (
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="fixedRiderCommissionRate">
                                                            Fixed Commission Rate
                                                        </Label>
                                                        <AvField
                                                            name="fixedRiderCommissionRate"
                                                            placeholder="Fixed Commission Rate"
                                                            type="number"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            validate={{
                                                                required: {
                                                                    value:
                                                                        formData.riderCommissionMethod ===
                                                                        'Fixed Per Delivery',
                                                                },
                                                            }}
                                                            id="fixedRiderCommissionRate"
                                                            value={formData.fixedRiderCommissionRate}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        )}

                                        {formData.riderCommissionMethod === 'Commission' && (
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="riderCommissionPercentage">
                                                            Commission Percentage
                                                        </Label>
                                                        <AvField
                                                            name="riderCommissionPercentage"
                                                            placeholder="Commission Percentage"
                                                            type="number"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            validate={{
                                                                required: {
                                                                    value:
                                                                        formData.riderCommissionMethod === 'Commission',
                                                                },
                                                            }}
                                                            id="riderCommissionPercentage"
                                                            value={formData.riderCommissionPercentage}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        )}

                                        {formData.riderCommissionMethod === 'Mileage-Based Fee' && (
                                            <>
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="baseMileage">Base Mileage in KM</Label>
                                                            <AvField
                                                                name="baseMileage"
                                                                placeholder="Base Mileage (KM)"
                                                                type="number"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{
                                                                    required: {
                                                                        value:
                                                                            formData.riderCommissionMethod ===
                                                                            'Mileage-Based Fee',
                                                                    },
                                                                }}
                                                                id="baseMileage"
                                                                value={formData.baseMileage}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="baseMileageRate">Base Mileage Rate</Label>
                                                            <AvField
                                                                name="baseMileageRate"
                                                                placeholder="Base Mileage Rate"
                                                                type="number"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{
                                                                    required: {
                                                                        value:
                                                                            formData.riderCommissionMethod ===
                                                                            'Mileage-Based Fee',
                                                                    },
                                                                }}
                                                                id="baseMileageRate"
                                                                value={formData.baseMileageRate}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="additionalMileageSlab1">
                                                                Additional Mileage Slab 1 in KM
                                                            </Label>
                                                            <AvField
                                                                name="additionalMileageSlab1"
                                                                placeholder="Additional Mileage Slab"
                                                                type="number"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{
                                                                    required: {
                                                                        value:
                                                                            formData.riderCommissionMethod ===
                                                                            'Mileage-Based Fee',
                                                                    },
                                                                }}
                                                                id="additionalMileageSlab1"
                                                                value={formData.additionalMileageSlab1}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="additionalMileageSlab1Limit">
                                                                Additional Mileage Slab 1 Limit in KM
                                                            </Label>
                                                            <AvField
                                                                name="additionalMileageSlab1Limit"
                                                                placeholder="Additional Mileage Slab"
                                                                type="number"
                                                                errorMessage="Additional Mileage Limit should be greater than the Base Mileage"
                                                                className="form-control"
                                                                validate={{
                                                                    required: {
                                                                        value:
                                                                            formData.riderCommissionMethod ===
                                                                            'Mileage-Based Fee',
                                                                    },
                                                                    min: {
                                                                        value: parseInt(formData.baseMileage) + 1,
                                                                    },
                                                                }}
                                                                id="additionalMileageSlab1Limit"
                                                                value={formData.additionalMileageSlab1Limit}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="ratePerAdditionalMileageSlab1">
                                                                Rate Per Additional Mileage Slab 1
                                                            </Label>
                                                            <AvField
                                                                name="ratePerAdditionalMileageSlab1"
                                                                placeholder="Rate for Additional Mileage"
                                                                type="number"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{ required: { value: true } }}
                                                                id="ratePerAdditionalMileageSlab1"
                                                                value={formData.ratePerAdditionalMileageSlab1}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="additionalMileageSlab2">
                                                                Additional Mileage Slab 2 in KM
                                                            </Label>
                                                            <AvField
                                                                name="additionalMileageSlab2"
                                                                placeholder="Additional Mileage Slab"
                                                                type="number"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{
                                                                    required: {
                                                                        value:
                                                                            formData.riderCommissionMethod ===
                                                                            'Mileage-Based Fee',
                                                                    },
                                                                }}
                                                                id="additionalMileageSlab2"
                                                                value={formData.additionalMileageSlab2}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="ratePerAdditionalMileage2">
                                                                Rate Per Additional Mileage Slab 2
                                                            </Label>
                                                            <AvField
                                                                name="ratePerAdditionalMileage2"
                                                                placeholder="Additional Mileage Slab"
                                                                type="number"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                validate={{
                                                                    required: {
                                                                        value:
                                                                            formData.riderCommissionMethod ===
                                                                            'Mileage-Based Fee',
                                                                    },
                                                                }}
                                                                id="ratePerAdditionalMileage2"
                                                                value={formData.ratePerAdditionalMileage2}
                                                                onChange={handleInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="validFrom">Valid From</Label>
                                                    <Input
                                                        onChange={handleInputChange}
                                                        type="date"
                                                        className="form-control"
                                                        value={getDateValue(formData.validFrom, 'date')}
                                                        validate={{ required: { value: true } }}
                                                        id="validFrom"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="validTo">Valid To</Label>
                                                    <Input
                                                        onChange={handleInputChange}
                                                        type="date"
                                                        className="form-control"
                                                        value={getDateValue(formData.validTo, 'date')}
                                                        validate={{ required: { value: true } }}
                                                        id="validTo"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ justifyContent: 'flex-start' }}>
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                type="submit"
                                                disabled={formLoading}
                                            >
                                                {formLoading ? (
                                                    <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                ) : (
                                                    <>
                                                        <i className="fas fa-save left-icon"></i>
                                                        <span className="h6">
                                                            {editingMode ? 'Update' : 'Create Rider Commission'}
                                                        </span>
                                                    </>
                                                )}
                                            </motion.button>
                                        </Row>
                                    </fieldset>
                                </AvForm>
                            </CardBody>
                        </Card>
                        <BackdropLoader show={backdropLoading || loading} />
                    </Container>
                </div>
            </Hoc>
        </>
    );
}

RiderCommissionFormPage.propTypes = {};

export default RiderCommissionFormPage;
