import { all } from 'redux-saga/effects';

// public
import accountSaga from './reducers/auth/register/saga';
import loginSaga from './reducers/auth/login/saga';
import forgetSaga from './reducers/auth/forgetpwd/saga';
import LayoutSaga from './reducers/layout/saga';

export default function* rootSaga() {
    yield all([
        // public
        accountSaga(),
        loginSaga(),
        forgetSaga(),
        LayoutSaga(),
    ]);
}
