/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { getDocumentTitle } from 'components/VerticalLayout/util';

export default function Hoc(props) {
    useEffect(() => {
        const currentPageTitle = capitalizeFirstLetter(props.match.path);
        const documentTitle = getDocumentTitle(currentPageTitle);
        document.title = `${documentTitle || currentPageTitle} | ScootiX - Delivery Management Console`;
    }, []);

    const capitalizeFirstLetter = (string) => string.charAt(1).toUpperCase() + string.slice(2);
    return <>{props.children}</>;
}
