/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Alert, Label, Container, Spinner, DropdownItem } from 'reactstrap';
import * as qs from 'query-string';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// #region assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { Select, ClientAlerts, BackdropLoader, CustomToast, ScootixModal, FormStatusBtn } from 'components';
import { SubTitle } from 'styles';

// #region utils
import { apiErrorHandler, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { getVisitingObject, hasAccess, IS_MERCHANT_VIEW } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import { DEBUG_MODE } from 'utils/checks';

// #region imports
import PATHS from 'routes/paths';
import { SYSTEM_DATA_TYPES } from 'constants/enums';

// #region services
import { getCustomTransactionsByTypeAndDocumentsService } from 'services/transaction.service';
import { getAllSystemDataByTypeAndModuleService } from 'services/system-data.service';
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';
import { getAllWarehouseSitesService } from 'services/warehouse-site.service';
import { getAllProductMasterDataByMerchantService } from 'services/product-master-data.service';
import { getAllOrdersByStatusService } from 'services/order.service';

// #endregion imports
import { stockAdjustmentTypes } from '../constants';
import { validateForm } from './utils';
import {
    createStocksAdjustmentService,
    updateConfirmStocksAdjustmentService,
    updateStocksAdjustmentService,
} from '../services';
import ItemsSheet from './Items';
import StockAdjustmentsTransactions from './transactions';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Product Master Data', link: PATHS.STOCK_ADJUSTMENT.DEFAULT },
];

let initialFormValues = {
    country: 'Malaysia',
};

/**
 * Stock Adjustment Form Page
 * @param {*} props
 * @returns
 */
function StockAdjustmentFormPage(props) {
    const { location } = props;

    const reduxState = useSelector((state) => state);

    const history = useHistory();

    const { visitingClient, visitingMerchant } = getVisitingObject();

    const _isMounted = useRef(true);

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    // prev comp
    const [prevCompState, setPrevCompState] = useState({});

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [disableEntirePage, setDisableEntirePage] = useState(false);
    const [backdropMessage, setBackdropMessage] = useState(null);

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    // Global
    // eslint-disable-next-line no-unused-vars
    const [clientSetting, setClientSetting] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [stocksReceivingSetting, setStocksReceivingSetting] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [orderProcessingSetting, setOrderProcessingSetting] = useState(null);

    const [alertMessage, setAlertMessage] = useState(null);

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isItemsSaved, setIsItemsSaved] = useState(true);
    const [formData, setFormData] = useState(initialFormValues);

    const [warehouseSites, setWarehouseSites] = useState([]);
    const [stocksFromArray, setStocksFromArray] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [allProductMasterData, setAllProductMasterData] = useState([]);

    // other
    const [merchants, setMerchants] = useState([]);
    const [allStocksAdjustmentTransactions, setAllStocksAdjustmentTransactions] = useState([]);
    const [dispatchedOrders, setDispatchedOrders] = useState([]);

    useEffect(() => {
        if (!location) {
            history.push(PATHS.STOCK_ADJUSTMENT.DEFAULT);
            return;
        }

        const formType = qs.parse(location.search);
        if (formType && formType.type && formType.type === 'new') {
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Stocks Adjustment', link: '#' }]);
        }

        if (formType && formType.type && formType.type === 'edit') {
            handleUpdateComponentEditMode();
        }

        if (formType && formType.type && formType.type === 'view') {
            handleUpdateComponentViewMode();
        }

        const { prevCompState: prevCompStateProp = null } = location.state || {};
        if (prevCompStateProp) {
            setPrevCompState(prevCompStateProp);
        }
    }, []);

    useEffect(() => {
        loadWarehouseSites();
        loadStocksFrom();
        loadAllOrdersByStatusDispatched();
        return () => {
            _isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        loadStockAdjustmentTransactions();
    }, [formData._id]);

    useEffect(() => {
        formData.merchantId && loadAllProductMasterDataByMerchant(formData.merchantId);
    }, [formData.merchantId]);

    useEffect(() => {
        if (reduxState?.Global) {
            const { clientSetting: clientSettingState } = reduxState.Global;
            if (clientSettingState && _isMounted.current) {
                setClientSetting(clientSettingState);
                if (clientSettingState.orderProcessing) {
                    setOrderProcessingSetting(clientSettingState.orderProcessing);
                }
                if (clientSettingState.stocksReceiving) {
                    setStocksReceivingSetting(clientSettingState.stocksReceiving);
                }
                if (clientSettingState.orderProcessing && clientSettingState.stocksReceiving) {
                    if (clientSettingState.orderProcessing.isEnabled || clientSettingState.stocksReceiving.isEnabled) {
                        setDisableEntirePage(true);
                        setAlertMessage('Order Processing Module and Stock Receiving Module Must Be Disabled');
                    } else {
                        setAlertMessage(null);
                    }
                }
            }
        }
    }, [reduxState.Global.clientSetting]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                if (_isMounted.current) {
                    const { data } = res;
                    if (data.docs) {
                        setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                    }
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                if (_isMounted.current) {
                    const { data } = res;
                    if (data.docs) {
                        setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                    }
                }
            });
        }
    }, []);

    const handleUpdateComponentViewMode = () => {
        try {
            !location.state && history.goBack();
            const { stocksAdjustmentParentData } = location.state;
            setFormData({ ...formData, ...stocksAdjustmentParentData });
            setIsViewMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'View Stocks Adjustment', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpdateComponentEditMode = () => {
        try {
            !location.state && history.goBack();
            const { stocksAdjustmentParentData } = location.state;
            setFormData({ ...formData, ...stocksAdjustmentParentData });
            const { prevCompState: prevCompStateProp = null } = location.state || {};
            // Update URL Based on Condition
            handleUpdateUrl(prevCompStateProp, stocksAdjustmentParentData, 'stocksAdjustmentParentData');
            if (prevCompStateProp && prevCompStateProp.refreshUser) {
                // This code is executed only during page reload
                // loadUserById(stocksAdjustmentParentData._id);
            }
            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Edit Stocks Adjustment', link: '#' }]);
        } catch (e) {
            console.log(e);
        }
    };

    // Update Address Bar URL
    // PATH | QUERY | STATE
    const handleUpdateUrl = (prevState, data, id) => {
        // Handle Remove PrevComponent
        try {
            history.replace({
                pathname: `${PATHS.STOCK_ADJUSTMENT.FORM}`,
                search: `?type=edit&id=${data._id}`,
                state: {
                    [id]: data,
                    prevCompState: { ...prevState, refreshPage: true },
                },
            });
        } catch (e) {
            console.log(e);
        }
    };

    const loadAllProductMasterDataByMerchant = async (merchantId) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getAllProductMasterDataByMerchantService(merchantId);
            if (_isMounted.current && data && data.docs && Array.isArray(data.docs)) {
                setAllProductMasterData(data.docs.map((x) => ({ ...x, label: x.productDescription, value: x._id })));
            }
            if (data && (data.docs || []).length === 0) {
                toast.custom(
                    (t) => <CustomToast text="Product Master Data Not Found For Merchant" t={t} type="warning" />,
                    {
                        position: 'top-right',
                    }
                );
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const loadStockAdjustmentTransactions = () => {
        if (formData._id) {
            getCustomTransactionsByTypeAndDocumentsService(formData._id, 'STOCKS_ADJUSTMENT').then((res) => {
                if (res) {
                    const { data } = res;
                    data.docs && setAllStocksAdjustmentTransactions(data.docs);
                }
            });
        }
    };

    const loadAllOrdersByStatusDispatched = async () => {
        try {
            const { data } = await getAllOrdersByStatusService('dispatched');
            if (data.docs && _isMounted.current) {
                setDispatchedOrders(data.docs.map((x) => ({ ...x, label: x.referenceNumber, value: x._id })));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadWarehouseSites = async () => {
        try {
            const { data } = await getAllWarehouseSitesService();
            if (_isMounted.current && data && data.docs) {
                setWarehouseSites(
                    data.docs.map((x) => ({
                        ...x,
                        label: `${x.siteName}`,
                        key: x._id,
                    }))
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadStocksFrom = async () => {
        try {
            const { data } = await getAllSystemDataByTypeAndModuleService(
                SYSTEM_DATA_TYPES.STOCKS_RECEIVING.TYPES.STOCKS_FROM,
                SYSTEM_DATA_TYPES.STOCKS_RECEIVING.MODULE
            );
            if (_isMounted.current && data && Array.isArray(data)) {
                setStocksFromArray(data.map((x) => ({ ...x, label: x.name, value: x.name })));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadItems = (allRows) => {
        setAllItems(allRows || []);
    };

    // -------------  HANDLERS ---------

    const handleSubmit = async (event, errors, values) => {
        event.persist();

        if (formLoading) {
            return;
        }

        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }

        const { isFormValid, message } = validateForm(formData);

        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);

        try {
            let responseData;
            if (editingMode) {
                const { data } = await updateStocksAdjustmentService(formData);
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            } else {
                const { data } = await createStocksAdjustmentService(formData);
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            }
            setEditingMode(true);

            try {
                // Replace URL so relevant changes can be done
                if (responseData) {
                    history.replace({
                        pathname: `${PATHS.STOCK_ADJUSTMENT.FORM}`,
                        search: `?type=edit&id=${responseData._id}`,
                        state: {
                            stocksAdjustmentParentData: responseData,
                            prevCompState,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} Stocks Adjustment`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setIsFormSaved(true);
        } catch (e) {
            console.log(e);
            toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setFormLoading(false);
        setSubmitted(false);
    };

    const handleApproveAdjustment = async () => {
        setBackdropLoading((prevState) => prevState + 1);
        setBackdropMessage('Adjusting Stock...');
        try {
            const { data } = await updateConfirmStocksAdjustmentService({ ...formData });
            if (data) {
                setFormData((prevState) => ({ ...prevState, ...data }));
            }
            toast.custom((t) => <CustomToast text="Successfully Approved" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        toggleScootixModal();
        setBackdropMessage(null);
        setBackdropLoading((prevState) => prevState - 1);
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);

        if (event) {
            if (id === 'merchantId' && event) {
                const relevantMerchant = merchants.find((x) => x._id === event.value);
                if (relevantMerchant) {
                    setFormData({
                        ...formData,
                        merchantName: relevantMerchant.name || '',
                        [id]: event ? event.value : null,
                    });
                }
                return;
            }
            if (id === 'orderId' && event) {
                const relevantObject = dispatchedOrders.find((x) => x._id === event.value);
                if (relevantObject) {
                    setFormData({
                        ...formData,
                        orderReferenceNumber: relevantObject.referenceNumber || '',
                        [id]: event ? event.value : null,
                    });
                }
                return;
            }
            if (id === 'warehouseSiteId' && event) {
                const relevantObj = warehouseSites.find((x) => x._id === event.value);
                if (relevantObj) {
                    setFormData({
                        ...formData,
                        warehouseSiteName: relevantObj.siteName || '',
                        [id]: event ? event.value : null,
                    });
                }
                return;
            }
            if (id === 'stocksAdjustmentType' && event) {
                setFormData({
                    ...formData,
                    [id]: event ? event.value : null,
                    orderId: null,
                    orderReferenceNumber: null,
                    stocksTransferLocation: null,
                });
                return;
            }

            setFormData({
                ...formData,
                [id]: event ? event.value : null,
            });
        } else {
            if (id === 'merchantId') {
                setFormData({
                    ...formData,
                    merchantName: null,
                    [id]: null,
                });
                return;
            }
            setFormData({
                ...formData,
                [id]: null,
            });
        }
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    const onHandleApproveStockAdjustment = () => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            message:
                'Do you want to confirm the stocks Adjustment? Action can not be undone once you confirm the Stocks Adjustment',
            onConfirm: () => handleApproveAdjustment(),
            confirmMessage: 'Confirm',
        });
    };

    // Selects Handlers
    const getMerchantSelect = (_id) => {
        // eslint-disable-next-line no-console
        DEBUG_MODE() && console.log('select-func');
        if (_id) {
            const relevantObj = merchants.find((x) => x._id === _id);
            if (relevantObj) {
                return { label: relevantObj.name, value: relevantObj._id };
            }
        }
        return null;
    };

    const getWarehouseSiteSelect = (_id) => {
        // eslint-disable-next-line no-console
        DEBUG_MODE() && console.log('select-func');
        if (_id) {
            const relevantObj = warehouseSites.find((x) => x._id === _id);
            if (relevantObj) {
                return {
                    label: `${relevantObj.siteName}`,
                    value: relevantObj._id,
                };
            }
        }
        return null;
    };

    const getOrderSelect = (_id) => {
        // eslint-disable-next-line no-console
        DEBUG_MODE() && console.log('select-func');
        if (_id) {
            const relevantObj = dispatchedOrders.find((x) => x._id === _id);
            if (relevantObj) {
                return { label: relevantObj.referenceNumber, value: relevantObj._id };
            }
        }
        return null;
    };

    const getStocksTransferLocationSelect = (val) => {
        // eslint-disable-next-line no-console
        DEBUG_MODE() && console.log('select-func');
        if (val) {
            const relevantObj = stocksFromArray.find((x) => x.name === val);
            if (relevantObj) {
                return {
                    label: `${relevantObj.name}`,
                    value: relevantObj._id,
                };
            }
        }
        return null;
    };

    // ** Memos
    const selectedMerchantId = useMemo(() => getMerchantSelect(formData.merchantId), [formData.merchantId, merchants]);
    const selectedWarehouseSiteId = useMemo(
        () => getWarehouseSiteSelect(formData.warehouseSiteId),
        [formData.warehouseSiteId, warehouseSites]
    );
    const selectedStocksAdjustmentType = useMemo(
        () => getDefaultValueForSelect(formData.stocksAdjustmentType),
        [formData.stocksAdjustmentType]
    );
    const selectedStocksTransferLocation = useMemo(
        () => getStocksTransferLocationSelect(formData.stocksTransferLocation),
        [formData.stocksTransferLocation, stocksFromArray]
    );
    const selectedOrderId = useMemo(() => getOrderSelect(formData.orderId), [formData.orderId, merchants]);
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={
                            isViewMode
                                ? 'View Stock Adjustment'
                                : editingMode
                                ? 'Edit Stock Adjustment'
                                : 'New Stock Adjustment'
                        }
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Row style={{ justifyContent: 'space-between' }}>
                        {!editingMode && (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                onClick={() => {
                                    history.push({
                                        pathname: PATHS.STOCK_ADJUSTMENT.DEFAULT,
                                    });
                                }}
                            >
                                <i className="far fa-times-circle mr-2 fa-lg" />
                                Cancel
                            </motion.div>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FormStatusBtn isFormSaved={isFormSaved} />
                        </div>
                        {editingMode && (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                onClick={() => {
                                    history.push({
                                        pathname: PATHS.STOCK_ADJUSTMENT.DEFAULT,
                                    });
                                }}
                            >
                                <i className="fas fa-arrow-left mr-2 fa-lg" />
                                Back to Products
                            </motion.div>
                        )}
                    </Row>

                    {!editingMode && <ClientAlerts />}

                    {alertMessage && (
                        <Alert color="danger" className="">
                            {alertMessage}
                        </Alert>
                    )}

                    <Card
                        style={
                            !visitingClient || disableEntirePage
                                ? { pointerEvents: 'none', opacity: '0.6' }
                                : { borderRadius: 10 }
                        }
                    >
                        <CardBody>
                            <Row className="d-flex justify-content-between ml-3 mr-3 ml-n2 mb-2">
                                {/* <h4 className="card-title">Enter user details</h4> */}
                                <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                    STOCK ADJUSTMENT DETAILS
                                </SubTitle>
                            </Row>
                            <AvForm
                                autoComplete="off"
                                className="needs-validation default-form"
                                noValidate
                                onSubmit={handleSubmit}
                                id="productMasterDataForm"
                            >
                                <input type="hidden" value="something" />
                                <fieldset disabled={isViewMode || formLoading || formData.isConfirmed}>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="merchantId">Merchant</Label>
                                                <Select
                                                    value={selectedMerchantId}
                                                    isClearable
                                                    options={merchants}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                    submitted={submitted}
                                                    validated={validated}
                                                    required
                                                    isDisabled={allItems.length > 0}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="warehouseSiteId">Warehouse</Label>
                                                <Select
                                                    value={selectedWarehouseSiteId}
                                                    options={warehouseSites.map((x) => ({
                                                        label: x.label,
                                                        value: x.key,
                                                    }))}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) => handleSelectChange(event, 'warehouseSiteId')}
                                                    submitted={submitted}
                                                    validated={validated}
                                                    required
                                                    isClearable
                                                    isDisabled={allItems.length > 0}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="stocksAdjustmentType">Stocks Adjustment Type</Label>
                                                <Select
                                                    value={selectedStocksAdjustmentType}
                                                    options={stockAdjustmentTypes}
                                                    styles={reactSelectCustomStyles}
                                                    onChange={(event) =>
                                                        handleSelectChange(event, 'stocksAdjustmentType')
                                                    }
                                                    submitted={submitted}
                                                    validated={validated}
                                                    required
                                                    isClearable
                                                />
                                            </FormGroup>
                                        </Col>
                                        {formData.stocksAdjustmentType === 'Return Stocks to the merchant' && (
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="stocksTransferLocation">
                                                        Stocks Transfer Location
                                                    </Label>
                                                    <Select
                                                        value={selectedStocksTransferLocation}
                                                        options={stocksFromArray}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) =>
                                                            handleSelectChange(event, 'stocksTransferLocation')
                                                        }
                                                        submitted={submitted}
                                                        validated={validated}
                                                        required
                                                        isClearable
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}
                                        {formData.stocksAdjustmentType === 'Customer Returns' && (
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="orderId">Scootix Order Number</Label>
                                                    <Select
                                                        value={selectedOrderId}
                                                        options={dispatchedOrders}
                                                        styles={reactSelectCustomStyles}
                                                        onChange={(event) => handleSelectChange(event, 'orderId')}
                                                        submitted={submitted}
                                                        validated={validated}
                                                        required
                                                        isDisabled={IS_MERCHANT_VIEW()}
                                                        isClearable
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}

                                        <Col md="4">
                                            <Label htmlFor="note">Reference / Note</Label>
                                            <AvField
                                                name="note"
                                                placeholder="Reference / Note"
                                                type="text"
                                                className="form-control"
                                                validate={{ required: { value: true, errorMessage: 'Required' } }}
                                                id="note"
                                                onChange={handleInputChange}
                                                value={formData.note}
                                            />
                                        </Col>
                                    </Row>
                                    {!formData.isConfirmed && (
                                        <Row style={{ justifyContent: 'flex-start' }}>
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                type="submit"
                                                disabled={formLoading}
                                            >
                                                {formLoading ? (
                                                    <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                ) : (
                                                    <>
                                                        <i className="fas fa-save left-icon"></i>
                                                        <span className="h6">{editingMode ? 'Update' : 'Save'}</span>
                                                    </>
                                                )}
                                            </motion.button>
                                        </Row>
                                    )}
                                </fieldset>
                            </AvForm>
                            {formData._id && (
                                <>
                                    <Row className="d-flex justify-content-between ml-n1 mb-2 mt-4">
                                        <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: -2 }}>
                                            ITEMS
                                        </SubTitle>
                                    </Row>
                                    <DropdownItem divider className="mt-4" />
                                    <br />
                                    <ItemsSheet
                                        stocksAdjustmentData={formData}
                                        loadItems={loadItems}
                                        allProductMasterData={allProductMasterData}
                                        setIsItemsSaved={setIsItemsSaved}
                                        setBackdropLoading={setBackdropLoading}
                                    />
                                </>
                            )}
                        </CardBody>
                    </Card>
                    <br />

                    {editingMode && (
                        <>
                            <Row style={{ justifyContent: 'flex-end' }}>
                                {!formData.isConfirmed &&
                                    allItems.length > 0 &&
                                    isItemsSaved &&
                                    hasAccess(PERMISSIONS.APPROVE_STOCK_ADJUSTMENT, ['Approve']) && (
                                        <motion.div
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-btn-radius  m-2 mb-3 pr-4 pl-4 shadow-lg nw-md"
                                            onClick={() => onHandleApproveStockAdjustment()}
                                        >
                                            <i className="fas fa-warehouse left-icon mr-2" />
                                            <div style={{ marginTop: 1, marginLeft: 3 }}>Approve</div>
                                        </motion.div>
                                    )}
                            </Row>
                            {/* <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                                <CardBody>
                                    <div>
                                        <Row>
                                            <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}>
                                                APPROVALS
                                            </SubTitle>
                                        </Row>
                                        <br />
                                    </div>
                                </CardBody>
                            </Card> */}
                        </>
                    )}

                    {editingMode && (
                        <StockAdjustmentsTransactions
                            allStocksAdjustmentTransactions={allStocksAdjustmentTransactions}
                        />
                    )}
                </Container>
            </div>

            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
            <BackdropLoader show={backdropLoading} opacity={0.8} message={backdropMessage} />
        </>
    );
}

StockAdjustmentFormPage.propTypes = {
    location: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotStockAdjustmentFormPagePage = hot(StockAdjustmentFormPage);

export default connect(mapStateToProps, {})(HotStockAdjustmentFormPagePage);
