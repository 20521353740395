/* eslint-disable consistent-return */
/* eslint-disable no-restricted-properties */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Spinner,
    Modal,
    ModalBody,
    ModalHeader,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Form,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';

// #region components
import CustomToast from 'components/Toast/Custom';

// #region imports
import { getAttachmentByKey } from 'services/attachment.service';
import { centerElementContainerStyles } from 'styles';

function ImageUploadModal(props) {
    const {
        toggleModal,
        header = '',
        title = '',
        subTitle = '',
        loading = false,
        noOfFiles = 1,
        onUpload,
        multiple = false,
        fileTypes = '',
        existingFile = null,
        isOpen = false,
    } = props;

    // const [backdropLoading, setBackdropLoading] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [isViewMode, setIsViewMode] = useState(false);

    const [imageSrc, setImageSrc] = useState(null);
    const [customLoading, setCustomLoading] = useState(true);

    /**
     * Formats the size
     */
    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    const handleAcceptedFiles = (files) => {
        if (files.length === 0) {
            return toast.custom((t) => <CustomToast text="Please Select a Valid File Type" t={t} type="error" />, {
                position: 'top-right',
            });
        }
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        if (noOfFiles > 1) {
            setSelectedFiles(selectedFiles.concat(files));
        } else {
            setSelectedFiles([files[0]]);
        }
        setIsViewMode(false);
    };

    useEffect(() => {
        if (isViewMode) {
            renderImage();
        }
    }, [isViewMode]);

    const renderImage = () => {
        if (
            existingFile &&
            typeof existingFile === 'string' &&
            !existingFile.includes('Signature') &&
            !existingFile.includes('Algorithm') &&
            !isOpen
        ) {
            getAttachmentByKey(existingFile)
                .then((res) => {
                    if (res) {
                        setImageSrc(res);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    setCustomLoading(false);
                })
                .finally(() => {
                    setCustomLoading(false);
                });
        } else if (
            existingFile &&
            typeof existingFile === 'string' &&
            existingFile.includes('Signature') &&
            existingFile.includes('Algorithm') &&
            !isOpen
        ) {
            setCustomLoading(false);
            setImageSrc(existingFile);
        } else if (existingFile && typeof existingFile === 'string' && isOpen) {
            setCustomLoading(false);
            setImageSrc(existingFile);
        } else if (existingFile && typeof existingFile !== 'string') {
            // let convertedImage = URL.createObjectURL(existingFile);
        }
    };

    return (
        <>
            <Modal size="lg" isOpen toggle={toggleModal} center className="modal-dialog modal-dialog-centered">
                <ModalHeader toggle={toggleModal}>{header}</ModalHeader>
                <ModalBody>
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <CardTitle>{title}</CardTitle>
                                <CardSubtitle className="mb-3">{subTitle}</CardSubtitle>
                                {existingFile && (
                                    <>
                                        <Row>
                                            <div className="text-center mt-4">
                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                                    onClick={() => {
                                                        setIsViewMode(!isViewMode);
                                                    }}
                                                >
                                                    {isViewMode ? 'Upload View' : 'Image View'}
                                                </motion.button>
                                            </div>
                                        </Row>
                                        <br />
                                    </>
                                )}

                                {isViewMode && (
                                    <Col md={12} style={centerElementContainerStyles}>
                                        {imageSrc && !customLoading ? (
                                            <img
                                                className="rounded mr-2"
                                                alt={header}
                                                style={{ maxWidth: 500 }}
                                                src={imageSrc}
                                            />
                                        ) : (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        )}
                                    </Col>
                                )}
                                {!isViewMode && (
                                    <Form>
                                        <Dropzone
                                            accept={fileTypes}
                                            onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
                                            multiple={multiple}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="dropzone">
                                                    <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <div className="mb-3">
                                                            <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                        </div>
                                                        <h4>Drop files here or click to upload.</h4>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>

                                        <div className="dropzone-previews mt-3" id="file-previews">
                                            {selectedFiles.map((f, i) => (
                                                <Card
                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    // eslint-disable-next-line prefer-template
                                                    key={i + '-file'}
                                                >
                                                    <div className="p-2">
                                                        <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                                <img
                                                                    data-dz-thumbnail=""
                                                                    height="80"
                                                                    className="avatar-sm rounded bg-light"
                                                                    alt={f.name}
                                                                    src={f.preview}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Link to="#" className="text-muted font-weight-bold">
                                                                    {f.name}
                                                                </Link>
                                                                <p className="mb-0">
                                                                    <strong>{f.formattedSize}</strong>
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            ))}
                                        </div>
                                    </Form>
                                )}
                                {selectedFiles.length > 0 && (
                                    <div className="text-center mt-4">
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                            onClick={() => {
                                                if (onUpload) {
                                                    onUpload(selectedFiles);
                                                }
                                            }}
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <Spinner className="mr-2" color="secondary" size="sm" />
                                            ) : (
                                                ' Upload'
                                            )}
                                        </motion.button>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </ModalBody>
            </Modal>

            {/* <BackdropLoader show={backdropLoading} opacity={0.8} /> */}
        </>
    );
}

ImageUploadModal.propTypes = {
    toggleModal: PropTypes.func,
    onUpload: PropTypes.func,
    title: PropTypes.string,
    header: PropTypes.string,
    subTitle: PropTypes.string,
    loading: PropTypes.bool,
    multiple: PropTypes.bool,
    noOfFiles: PropTypes.number,
    fileTypes: PropTypes.any,
    existingFile: PropTypes.any,
    isOpen: PropTypes.bool,
};

export default ImageUploadModal;
