import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Row, Col, Card, CardBody, Spinner, Label, Input, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import './styles.css';

// #region assets | components
import { SaveAndUpdate } from 'components/Icons';
import { Select } from 'components';

// #region other imports
import { batchPrintSizes } from 'constants/options';

// #region utils
import { reactSelectCustomStyles } from 'utils/helpers';
import { hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

/**
 * Initial Form Body
 * @param {*} props
 * @returns {React.Component}
 */
export default function FormBody(props) {
    const {
        formData,
        merchants,
        handleSubmit,
        formLoading,
        handleSelectChange,
        submitted,
        validated,
        visitingMerchant,
        allPrinters,
        handleCheckChange,
        editingMode,
        selectedPrinter,
        handleInputChange,
        selectedMerchantId,
        selectedPrintSize,
        // handleStartPrintProcess,
        handleDownloadFile,
    } = props;

    return (
        <>
            <Card className="w-100" style={{ borderRadius: 10 }}>
                <CardBody>
                    <AvForm className="needs-validation default-form" onValidSubmit={handleSubmit}>
                        <fieldset disabled={formLoading}>
                            <Row className="mt-2">
                                <Col md={3}>
                                    <FormGroup className="batch-print-merchant-name">
                                        <Label htmlFor="merchantId">Merchant</Label>
                                        <Select
                                            value={selectedMerchantId}
                                            isClearable
                                            options={merchants}
                                            styles={reactSelectCustomStyles}
                                            onChange={(event) => handleSelectChange(event, 'merchantId')}
                                            submitted={submitted}
                                            validated={validated}
                                            isDisabled={visitingMerchant?._id || editingMode}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup className="batch_print_batch_print_name">
                                        <Label htmlFor="batchPrintName">Batch Print Name</Label>
                                        <AvField
                                            name="batchPrintName"
                                            placeholder=""
                                            type="string"
                                            errorMessage="Required"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="batchPrintName"
                                            value={String(formData.name)}
                                            onChange={handleCheckChange}
                                            disabled={formData.isUseDateTime || formData.isFinalized}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <div className="batch_print_date_time_use_div">
                                        <Input
                                            onChange={handleCheckChange}
                                            type="checkbox"
                                            defaultChecked
                                            disabled={formData.isFinalized}
                                            id="isUseDateTime"
                                        />
                                        <Label htmlFor="isUseDateTime" className="form-check-label">
                                            Use Date and Time
                                        </Label>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={3}>
                                    <FormGroup>
                                        <Label htmlFor="printer">Printer</Label>
                                        <Select
                                            value={selectedPrinter}
                                            isClearable
                                            options={allPrinters}
                                            styles={reactSelectCustomStyles}
                                            validate={{ required: { value: true } }}
                                            onChange={(event) => handleSelectChange(event, 'printer')}
                                            submitted={submitted}
                                            validated={validated}
                                            isDisabled={formData.isFinalized}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label htmlFor="printSize">Print Size</Label>
                                        <Select
                                            value={selectedPrintSize}
                                            isClearable
                                            options={batchPrintSizes
                                                .filter((x) => x.printerType === formData.printer)
                                                .map((val) => ({ ...val, value: val.key }))}
                                            styles={reactSelectCustomStyles}
                                            validate={{ required: { value: true } }}
                                            onChange={(event) => handleSelectChange(event, 'printSize')}
                                            submitted={submitted}
                                            validated={validated}
                                            isDisabled={formData.isFinalized}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label htmlFor="noOfCopies">Copies</Label>
                                        <AvField
                                            name="noOfCopies"
                                            placeholder=""
                                            type="number"
                                            errorMessage="No of copies should be between 1 and 20"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="noOfCopies"
                                            value={formData.noOfCopies}
                                            onChange={handleInputChange}
                                            disabled={formData.isFinalized}
                                            min={1}
                                            max={20}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br />
                            <Row className="form-button-row" style={{ justifyContent: 'flex-start' }}>
                                {!formData.isFinalized && (
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                        type="submit"
                                        disabled={formLoading}
                                        style={{ borderRadius: 30 }}
                                    >
                                        {formLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <SaveAndUpdate />
                                                <span className="h6">{editingMode ? 'Update' : 'Save'}</span>
                                            </>
                                        )}
                                    </motion.button>
                                )}
                            </Row>
                        </fieldset>
                    </AvForm>
                    {hasAccess(PERMISSIONS.LABEL_PRINT_SHIPPING_LABEL, ['Print']) && (
                        <>
                            {formData.isFinalized && formData.task?.resourceUrl && (
                                <Row className="mt-3" style={{ justifyContent: 'flex-start' }}>
                                    {/* <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                        disabled={formLoading}
                                        onClick={() => handleStartPrintProcess()}
                                        style={{ borderRadius: 30 }}
                                    >
                                        <Printer />
                                        <span className="h6">Print</span>
                                    </motion.button> */}
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn save-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                        disabled={formLoading}
                                        onClick={() => handleDownloadFile()}
                                        style={{ borderRadius: 30 }}
                                    >
                                        <SaveAndUpdate />
                                        <span className="h6">Download</span>
                                    </motion.button>
                                </Row>
                            )}
                        </>
                    )}
                </CardBody>
            </Card>
        </>
    );
}

FormBody.propTypes = {
    formData: PropTypes.object,
    handleSubmit: PropTypes.func,
    formLoading: PropTypes.bool,
    merchants: PropTypes.object,
    visitingMerchant: PropTypes.bool,
    handleSelectChange: PropTypes.func,
    submitted: PropTypes.bool,
    validated: PropTypes.bool,
    selectedPrinter: PropTypes.object,
    handleInputChange: PropTypes.func,
    handleCheckChange: PropTypes.func,
    selectedMerchantId: PropTypes.func,
    selectedPrintSize: PropTypes.object,
    allPrinters: PropTypes.array,
    editingMode: PropTypes.bool,
    // handleStartPrintProcess: PropTypes.func,
    handleDownloadFile: PropTypes.func,
};
