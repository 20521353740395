/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import classnames from 'classnames';
import { Row, Card, CardBody, Spinner, NavLink, NavItem, Nav } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import _ from 'lodash';
import moment from 'moment';

// #region components | assets
import { BackdropLoader, CustomToast } from 'components';

// #region utils
import { getVisitingObject } from 'utils/checkAuth';
import { MERCHANT_SETTING_DEFAULT } from 'constants/db/merchant-setting';
import { __DEV__ } from 'utils/checks';

// #region imports
import { SubTitle } from 'styles';

// #region services
import { getMerchantSettingByMerchantIdService } from 'services/merchant-setting.service';

// #endregion imports
import { createMerchantSettingService, updateMerchantSettingService } from './services';
import { validateForm } from './utils';
import DeliverySettingsSubModule from './modules/delivery-settings';
import DeliveryImportSubModule from './modules/delivery-import';

let initialSettings = {};

function MerchantSettingsDefaultPage(props) {
    const { merchantData = {}, componentRef, location } = props;

    const { visitingMerchant } = getVisitingObject();

    if (visitingMerchant) {
        initialSettings = {
            ...initialSettings,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    // component
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [formLoading, setFormLoading] = useState(false);

    const [activeTab, setActiveTab] = useState('1');
    const [tabStatus, setTabStatus] = useState('delivery');

    // form
    const [formData, setFormData] = useState(initialSettings);
    const [editingMode, setEditingMode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isFormSaved, setIsFormSaved] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [submitted, setSubmitted] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if (merchantData._id) {
            loadMerchantSettings(merchantData._id);
        }
    }, [merchantData._id]);

    const loadMerchantSettings = async (id) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getMerchantSettingByMerchantIdService(id);
            if (data) {
                setEditingMode(true);
                setFormData({
                    ...formData,
                    ...data,
                    settings: { ...MERCHANT_SETTING_DEFAULT, ...(data.settings || {}) },
                });
            } else {
                const payload = {
                    merchantId: id,
                    merchantName: merchantData.name || '',
                    settings: { ...MERCHANT_SETTING_DEFAULT },
                };
                try {
                    const response = await createMerchantSettingService(payload);
                    if (response && response.data) {
                        setFormData({ ...formData, ...response.data });
                        setEditingMode(true);
                        toast.custom((t) => <CustomToast text="Created New Merchant Settings" t={t} type="success" />, {
                            position: 'top-right',
                        });
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.persist();

        const { isFormValid, message } = validateForm(formData);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }

        setFormLoading(true);

        try {
            // eslint-disable-next-line no-unused-vars
            let responseData;
            if (editingMode) {
                const { data } = await updateMerchantSettingService(formData);
                if (data) {
                    responseData = { ...formData, ...data };
                    setFormData({ ...formData, ...data });
                }
            }
            setEditingMode(true);

            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} Settings`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setIsFormSaved(true);
        } catch (e) {
            console.log(e);
            toast.custom((t) => <CustomToast text="Something went wrong" t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setFormLoading(false);
        setSubmitted(false);
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        if (event?.value) {
            const { value } = event;
            const payload = _.set(formData, id, value);
            setFormData({ ...formData, ...payload });
        } else {
            const payload = _.set(formData, id, null);
            setFormData({ ...formData, ...payload });
        }
    };

    const handleInputChange = (event, id, inputType = 'string') => {
        const { value } = event.target;

        let modifiedValue = value;

        if (inputType === 'string') {
            modifiedValue = String(modifiedValue);
        }

        if (inputType === 'number') {
            modifiedValue = Number(modifiedValue);
        }

        const payload = _.set(formData, id, modifiedValue);
        setFormData({ ...formData, ...payload });
    };

    const handleCheckChange = (val, id) => {
        const payload = _.set(formData, id, val);
        setFormData({ ...formData, ...payload });
    };

    // ** Memos

    const commonTabModulesProps = {
        handleInputChange,
        handleSelectChange,
        settings: formData.settings,
        handleCheckChange,
    };

    return (
        <>
            <motion.div
                className="merchant-setting"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                id="merchant_setting_component"
                ref={componentRef}
            >
                <div>
                    <AvForm
                        autoComplete="off"
                        className="needs-validation default-form custom-form"
                        noValidate
                        onSubmit={handleSubmit}
                        id="merchantSettingsForm"
                    >
                        <fieldset disabled={false}>
                            <Card style={{ borderRadius: 15 }}>
                                <CardBody style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                    <div
                                        style={{
                                            backgroundColor: '#FFEEEE',
                                            borderTopRightRadius: 15,
                                            borderTopLeftRadius: 15,
                                            minHeight: 20,
                                        }}
                                    >
                                        {formData.updatedBy && (
                                            <div
                                                style={{ padding: 10, paddingLeft: 20, color: '#807F7D', fontSize: 16 }}
                                            >
                                                This setting is last updated by {formData.updatedBy.fullName || ''} at{' '}
                                                {moment(formData.updatedAt || formData.createdAt).format('YYYY-MM-DD')}{' '}
                                                on {moment(formData.updatedAt || formData.createdAt).format('hh.mm A')}
                                            </div>
                                        )}
                                    </div>
                                    <br />
                                    {formData.merchantId && formData.settings && (
                                        <div className="p-5">
                                            <Row className="d-flex justify-content-between pl-1">
                                                <SubTitle style={{ color: '#574b90', letterSpacing: 0.5 }}>
                                                    SETTINGS
                                                </SubTitle>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Nav tabs className="nav-tabs-custom">
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({
                                                                active: activeTab === '1',
                                                            })}
                                                            onClick={() => {
                                                                setTabStatus('delivery');
                                                                setActiveTab('1');
                                                            }}
                                                        >
                                                            <span className="d-none d-sm-block">Delivery</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({
                                                                active: activeTab === '2',
                                                            })}
                                                            onClick={() => {
                                                                setTabStatus('deliveryImport');
                                                                setActiveTab('2');
                                                            }}
                                                        >
                                                            <span className="d-none d-sm-block">Delivery Import</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </Row>
                                            <br />
                                            <br />

                                            {tabStatus === 'delivery' && (
                                                <DeliverySettingsSubModule {...commonTabModulesProps} />
                                            )}
                                            {tabStatus === 'deliveryImport' && (
                                                <DeliveryImportSubModule
                                                    {...commonTabModulesProps}
                                                    location={location}
                                                />
                                            )}
                                            <br />
                                            <Row style={{ justifyContent: 'flex-end' }}>
                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4 mr-5"
                                                    type="submit"
                                                    disabled={formLoading}
                                                >
                                                    {formLoading ? (
                                                        <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                                    ) : (
                                                        <>
                                                            <i className="fas fa-save left-icon"></i>
                                                            <span className="h6">
                                                                {editingMode ? 'Update' : 'Save'}
                                                            </span>
                                                        </>
                                                    )}
                                                </motion.button>
                                            </Row>
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </fieldset>
                    </AvForm>
                </div>
            </motion.div>

            {!__DEV__ && <BackdropLoader show={backdropLoading} opacity={0.8} />}
        </>
    );
}

MerchantSettingsDefaultPage.propTypes = {
    merchantData: PropTypes.object,
    componentRef: PropTypes.any,
    location: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotMerchantSettingsDefaultPage = hot(MerchantSettingsDefaultPage);

export default connect(mapStateToProps, {})(HotMerchantSettingsDefaultPage);
