/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

import {
    AUTH_CLIENT,
    AUTH_MERCHANT,
    AUTH_USER,
    CLIENT_MERCHANT_X_CLIENTS,
    CLIENT_MERCHANT_X_MERCHANTS,
    USER_INITIAL_WINDOW_HREF,
    TOKEN,
    USER,
} from 'constants/local-storage.constants';
import { getCurrentPageUrl } from './helpers';
import { encryptedLocalStorage } from './local-storage';

export const getUser = () => {
    try {
        const user = encryptedLocalStorage.getItem(USER);
        return user;
    } catch (e) {
        return {};
    }
};

export const getLoggedInUserRoles = () => {
    try {
        const user = getUser();
        return user?.rolePermission?.permissionArray || [];
    } catch (e) {
        return [];
    }
};

export const setAuthDetails = (authData) => {
    encryptedLocalStorage.setItem(TOKEN, authData.token || authData.authToken);
    encryptedLocalStorage.setItem(USER, JSON.stringify(authData.user));
    encryptedLocalStorage.setItem(AUTH_USER, JSON.stringify(authData.user));
    encryptedLocalStorage.setItem(AUTH_USER, JSON.stringify(authData.user));
    // encryptedLocalStorage.setItem('activeTime', authData.sessionTime);
    if (authData.user.client) {
        encryptedLocalStorage.setItem(AUTH_CLIENT, JSON.stringify(authData.user.client));
    }
    if (authData.user.merchant) {
        encryptedLocalStorage.setItem(AUTH_MERCHANT, JSON.stringify(authData.user.merchant));
    }
};

export const backToLogin = async (type = 'sessiontimeout') => {
    encryptedLocalStorage.removeItem(TOKEN);
    encryptedLocalStorage.removeItem(USER);
    encryptedLocalStorage.removeItem(AUTH_USER);
    encryptedLocalStorage.removeItem(AUTH_CLIENT);
    encryptedLocalStorage.removeItem(AUTH_MERCHANT);
    encryptedLocalStorage.removeItem(CLIENT_MERCHANT_X_CLIENTS);
    encryptedLocalStorage.removeItem(CLIENT_MERCHANT_X_MERCHANTS);
    if (type === 'sessiontimeout') {
        encryptedLocalStorage.setItem(USER_INITIAL_WINDOW_HREF, getCurrentPageUrl());
    } else {
        encryptedLocalStorage.setItem(USER_INITIAL_WINDOW_HREF, null);
    }
    window.location.href = `/login?type=${type}`;
};

export const removeStorageItems = async () => {
    encryptedLocalStorage.removeItem(TOKEN);
    encryptedLocalStorage.removeItem(USER);
    encryptedLocalStorage.removeItem(AUTH_CLIENT);
    encryptedLocalStorage.removeItem(AUTH_MERCHANT);
    encryptedLocalStorage.removeItem(AUTH_USER);
};

export const updateLocalUser = (user) => {
    encryptedLocalStorage.setItem(USER, JSON.stringify(user));
};

export const getAccessToken = () => encryptedLocalStorage.getItem(TOKEN);

export const getVisitingObject = () => {
    try {
        let visitingMerchant = null;
        let visitingClient = null;

        const merchant = encryptedLocalStorage.getItem(AUTH_MERCHANT);
        const client = encryptedLocalStorage.getItem(AUTH_CLIENT);
        if (merchant) {
            visitingMerchant = merchant;
        }
        if (client) {
            visitingClient = client;
        }
        return {
            visitingMerchant,
            visitingClient,
        };
    } catch (e) {
        console.log(e);
    }
    return {};
};

export const setVisitingClient = (visitingClient) => {
    encryptedLocalStorage.setItem(AUTH_CLIENT, JSON.stringify(visitingClient));
};

export const setVisitingMerchant = (visitingMerchant) => {
    encryptedLocalStorage.setItem(AUTH_MERCHANT, JSON.stringify(visitingMerchant));
};

export const removeVisitingClientAndMerchant = async () => {
    encryptedLocalStorage.removeItem(AUTH_CLIENT);
    encryptedLocalStorage.removeItem(AUTH_MERCHANT);
};

export const removeVisitingMerchant = async () => {
    encryptedLocalStorage.removeItem(AUTH_MERCHANT);
};

export const hasAccess = (feature, accessTypes) => {
    if (SUPER_AND_CLIENT_ADMINS()) return true;

    const features = getLoggedInUserRoles();
    if (features) {
        const selectedFeature = Object.values(features[feature] || {});
        const scopes = selectedFeature?.map((feature) => feature.value);
        return accessTypes.every((scope) => scopes?.includes(scope));
    }
    return false;
};

export const ONLY_ADMINS = () => {
    const user = getUser();
    if (user?.role === 'clientAdmin' || user?.role === 'merchantAdmin' || user?.role === 'superAdmin') {
        return true;
    }
    return false;
};

export const SUPER_AND_CLIENT_ADMINS = () => {
    const user = getUser();
    if (user?.role === 'clientAdmin' || user?.role === 'superAdmin') {
        return true;
    }
    return false;
};

export const IS_SUPER_ADMIN = () => {
    const user = getUser();
    if (user?.role === 'superAdmin') {
        return true;
    }
    return false;
};

export const IS_MERCHANT_VIEW = () => {
    const { visitingMerchant } = getVisitingObject();
    if (visitingMerchant && visitingMerchant._id) {
        return true;
    }
    return false;
};

/**
 * Clean User Object Before Appending To Request Header
 * @param {object} user
 * @returns
 */
export const cleanUserRequestHeader = (user) => {
    const modifiedUser = user;
    try {
        modifiedUser.attachmentObj && delete modifiedUser.attachmentObj;
        modifiedUser.addressMeta && delete modifiedUser.addressMeta;
        modifiedUser.updatedBy && delete modifiedUser.updatedBy;
        modifiedUser.permissionArray && delete modifiedUser.permissionArray;
        modifiedUser.rolePermission && delete modifiedUser.rolePermission;
        modifiedUser.merchant && delete modifiedUser.merchant;
    } catch (e) {
        console.log(e);
    }
    return modifiedUser;
};

export const hasRole = (roles = []) => {
    const { role = null } = getUser();
    if (role) {
        if (roles.includes(role)) {
            return true;
        }
    }
    return false;
};
