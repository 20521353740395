export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Reference',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
    {
        key: 'stocksFrom',
        value: 'Stocks From',
    },
    {
        key: 'deliveryNoteReference',
        value: 'Delivery Note Reference',
    },
    {
        key: 'additionalDetails',
        value: 'Additional Details',
    },
    {
        key: 'createdAt',
        value: 'Created At',
        type: 'dateTime',
    },
    {
        key: '_id',
        value: 'Restore',
        type: 'restoreButton',
    },
    {
        key: '_id',
        value: 'Permanent Delete',
        type: 'permanentDeleteButton',
    },
    {
        key: 'deletedAt',
        value: 'Deleted At',
        type: 'dateTime',
    },
    { key: 'deletedBy', value: 'Deleted By', type: 'userObject' },
];
