/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';

// #region constants
import { TRANSACTION_METHODS } from 'constants/enums';

/**
 * Modal for Transaction details
 */
function ProductMasterDataTransactionModal(props) {
    const { show, handleToggle, data } = props;
    const { sequence, method = '', meta } = data;

    return (
        <DeveloperModal
            header={`Transaction data for ${sequence}`}
            show={
                method === TRANSACTION_METHODS.INVENTORY.PRODUCT_MASTER_DATA.PRODUCT_CREATED ||
                (method === TRANSACTION_METHODS.INVENTORY.PRODUCT_MASTER_DATA.PRODUCT_UPDATED && show)
            }
            handleToggle={handleToggle}
        >
            <Container>
                <Card style={{ height: '200px', overflowY: 'scroll', overflowX: 'hidden', boxShadow: 'none' }}>
                    <Row className="mb-2">
                        <Col>
                            {method === TRANSACTION_METHODS.INVENTORY.PRODUCT_MASTER_DATA.PRODUCT_CREATED && (
                                <>
                                    <div className="ml-1">
                                        <h6>
                                            <b>Merchant</b>: {meta?.formData?.merchantName || ''}
                                        </h6>
                                        <h6>
                                            <b>Product Description</b>: {meta?.formData?.productDescription || ''}
                                        </h6>
                                        <h6>
                                            <b>Product Category</b>: {meta?.formData?.productCategory || ''}
                                        </h6>
                                        <h6>
                                            <b>Merchant Product Code</b>: {meta?.formData?.merchantProductCode || ''}
                                        </h6>
                                        <h6>
                                            <b>Cross Platform Reference</b>: {meta?.formData?.crossPlatformReference}
                                        </h6>
                                    </div>
                                    <hr />
                                    <div>
                                        <h6>
                                            <b>SKU Code</b>: {meta?.formData?.skuCode || ''}
                                        </h6>
                                        <h6>
                                            <b>UPC</b>: {meta?.formData?.upc || ''}
                                        </h6>
                                        <h6>
                                            <b>UAN</b>: {meta?.formData?.uan || ''}
                                        </h6>
                                        <h6>
                                            <b>Unit Of Measure</b>: {meta?.formData?.unitOfMeasure || ''}
                                        </h6>
                                        <h6>
                                            <b>Takeaway Method</b>: {meta?.formData?.takeawayMethod || ''}
                                        </h6>
                                        <h6>
                                            <b>Minimum Stock Level</b>: {meta?.formData?.minimumStockLevel || ''}
                                        </h6>
                                        <h6>
                                            <b>Minimum Shelf Life %</b>: {meta?.formData?.minimumShelfLife || ''}
                                        </h6>
                                    </div>
                                </>
                            )}
                            {method === TRANSACTION_METHODS.INVENTORY.PRODUCT_MASTER_DATA.PRODUCT_UPDATED && (
                                <>
                                    <div className="ml-1">
                                        <h6>
                                            <b>Merchant</b>: {meta?.previousData?.merchantName || ''}
                                        </h6>
                                        <h6>
                                            <b>Product Description</b>: {meta?.previousData?.productDescription || ''}
                                        </h6>
                                        <h6>
                                            <b>Product Category</b>: {meta?.previousData?.productCategory || ''}
                                        </h6>
                                        <h6>
                                            <b>Merchant Product Code</b>:{' '}
                                            {meta?.previousData?.merchantProductCode || ''}
                                        </h6>
                                        <h6>
                                            <b>Cross Platform Reference</b>:{' '}
                                            {meta?.previousData?.crossPlatformReference}
                                        </h6>
                                    </div>
                                    <hr />

                                    <div>
                                        <h6>
                                            <b>SKU Code</b>: {meta?.previousData?.skuCode || ''}
                                        </h6>
                                        <h6>
                                            <b>UPC</b>: {meta?.previousData?.upc || ''}
                                        </h6>
                                        <h6>
                                            <b>UAN</b>: {meta?.previousData?.uan || ''}
                                        </h6>
                                        <h6>
                                            <b>Unit Of Measure</b>: {meta?.previousData?.unitOfMeasure || ''}
                                        </h6>
                                        <h6>
                                            <b>Takeaway Method</b>: {meta?.previousData?.takeawayMethod || ''}
                                        </h6>
                                        <h6>
                                            <b>Minimum Stock Level</b>: {meta?.previousData?.minimumStockLevel || ''}
                                        </h6>
                                        <h6>
                                            <b>Minimum Shelf Life %</b>: {meta?.previousData?.minimumShelfLife || ''}
                                        </h6>
                                    </div>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card>
            </Container>
        </DeveloperModal>
    );
}

ProductMasterDataTransactionModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    data: PropTypes.object,
};

export default ProductMasterDataTransactionModal;
