import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

function Tabs(props) {
    const { tabData, activeTab: activeTabProp, onTabSwitch } = props;

    /** Keep track of the currently active tab */
    const [activeTab, setActiveTab] = useState(activeTabProp || '1');

    /**
     * Change the active tab
     * @param {string} tabId - ID of the tab as a string
     */
    const switchTabs = (tabId) => {
        if (activeTab !== tabId) {
            setActiveTab(tabId);
            if (onTabSwitch) {
                onTabSwitch(tabId);
            }
        }
    };

    /** Change the active tab if it was changed from the parent component */
    useEffect(() => {
        setActiveTab(activeTabProp);
    }, [activeTabProp]);

    return (
        <>
            <Nav tabs className="developer-portal-nav mt-4">
                {tabData.map((tab, index) => (
                    <NavItem key={tab.tabName} className={activeTab === `${index}` ? 'active' : ''}>
                        <NavLink
                            className={activeTab === `${index}` ? 'active' : ''}
                            onClick={() => switchTabs(`${index}`)}
                        >
                            {tab.tabName}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={activeTab} className="mt-4">
                {tabData.map((tab, index) => (
                    <>
                        {tab.tabContent && (
                            <TabPane key={tab.tabName} tabId={`${index}`}>
                                {tab.tabContent}
                            </TabPane>
                        )}
                    </>
                ))}
            </TabContent>
        </>
    );
}

Tabs.propTypes = {
    /** An array with objects in the form { tabName: string, tabContent: JSX } */
    tabData: PropTypes.array.isRequired,
    /** The index of the active tab as a string */
    activeTab: PropTypes.string,
    /** Optional callback function to call when tabs are switched */
    onTabSwitch: PropTypes.func,
};

export default Tabs;
