import React from 'react';

// #region components
import ClientMerchantX from 'components/CommonForBoth/TopbarDropdown/client-merchant-x';

function ClientMerchantXMobile() {
    return (
        <>
            <div className="client-merchant-x__header">
                <ClientMerchantX />
            </div>
        </>
    );
}

ClientMerchantXMobile.propTypes = {};

export default ClientMerchantXMobile;
