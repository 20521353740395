/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row, NavLink, NavItem, Nav } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';
import * as qs from 'query-string';
import moment from 'moment';

// #region assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { AlertModal, BackdropLoader, CustomToast, StatCard, TableCustom } from 'components';
import PATHS from 'routes/paths';

// #region utils
import { hasAccess, IS_SUPER_ADMIN } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';
import { apiErrorHandlerV2, isObject } from 'utils/helpers';
import { DEV_ENV } from 'utils/checks';

// #region services
import { getAllDeliveriesMetaService, getDeliveriesService } from 'services/deliveries.service';

// #endregion services
import { defaultTableHeaders, deliveryFilters } from '../../constants';
import { deleteDelivery } from '../../services';
import { getDeliveryStatus } from '../../utils';

import './index.css';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Delivery', link: '#' },
];

/**
 * Delivery Management Page
 * @param {*} props
 * @returns {React.Component}
 */
function DeliveryManagementPage(props) {
    const { location } = props;

    const history = useHistory();

    // component state
    const [loading, setLoading] = useState(0);
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [isCompletedList, setIsCompletedList] = useState(false);
    const [isDeletedList] = useState(false);

    const [urlParamsSearch, setUrlParamsSearch] = useState(null);
    const [searchParams, setSearchParams] = useState(null);

    const [activeTab, setActiveTab] = useState('1');

    const [deliveryListData, setDeliveryListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers:
            props.location.pathname === '/delivery/completed-list'
                ? [...defaultTableHeaders, { key: 'deliveredAt', value: 'Delivered At', type: 'dateTime' }]
                : [
                      ...defaultTableHeaders.slice(0, 7),
                      {
                          key: 'deliveryDate',
                          value: 'Delivery Date',
                          sortingEnabled: true,
                      },
                      ...defaultTableHeaders.slice(7),
                  ],
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    const [showDeleteModal, setShowDeleteModal] = useState({ state: false, data: null });

    const [deliveryMeta, setDeliveryMeta] = useState({
        allDeliveries: 0,
        generalDeliveries: 0,
        importedDeliveries: 0,
        deliveriesCompletedToday: 0,
        ongoingDeliveries: 0,
        pendingPickupDeliveries: 0,
        notAssignedDeliveries: 0,
    });

    /**
     * A callback function that is triggered when the user changes the number of results to display per page.
     * @param {number} limit - The new limit value (i.e. number of results per page).
     */
    const handleResultsPerPageChange = (limit) => {
        setDeliveryListData((prevTableData) => ({ ...prevTableData, limit: parseInt(limit, 10) }));
        replaceUrlParamsHandler({
            page: deliveryListData.page,
            limit,
            searchBy: searchParams?.searchBy,
            searchText: searchParams?.searchText,
            filterDate: searchParams?.filterDate,
        });
    };

    useEffect(() => {
        if (urlParamsSearch) {
            const { searchBy, searchText, type, page, sort, order, status, tab, filterDate } = urlParamsSearch;

            // !FIXME
            if (props.location.pathname === '/delivery/completed-list') {
                setIsCompletedList(true);
                loadDeliveriesListData({
                    showLoading: true,
                    ...deliveryListData,
                    searchBy: { searchBy, searchText },
                    type,
                    status: 'completed',
                    page,
                    sort,
                    order,
                });
            }
            if (props.location.pathname === '/delivery/list') {
                loadDeliveriesListData({
                    showLoading: true,
                    ...deliveryListData,
                    searchBy: { searchBy, searchText, filterDate },
                    type,
                    status,
                    tab,
                    page,
                    sort,
                    order,
                });
            }
            loadAllDeliveriesMeta(urlParamsSearch.tab);
        }
    }, [urlParamsSearch]);

    useEffect(() => {
        const urlSearch = qs.parse(location.search);
        if (urlSearch && urlSearch.tab) {
            const { tab, status, page, searchBy, searchText, sort, order, filterDate } = urlSearch;
            setActiveTab(tab);
            setUrlParamsSearch({ tab, status, page, searchBy, searchText, sort, order, filterDate });
            setSearchParams({ searchBy, searchText, order, sort, filterDate });
        } else {
            replaceUrlParamsHandler({ tab: 'all', status: 'all', page: '1' });
        }
    }, [location.search]);

    const reloadComponent = () => {
        loadDeliveriesListData({
            showLoading: true,
            ...deliveryListData,
            page: urlParamsSearch.page,
            searchBy: {
                searchBy: urlParamsSearch?.searchBy,
                searchText: urlParamsSearch?.searchText,
            },
            type: urlParamsSearch.type,
            status: urlParamsSearch.status,
        });
        loadAllDeliveriesMeta(urlParamsSearch.tab);
    };

    const loadAllDeliveriesMeta = async (tab) => {
        try {
            const { data } = await getAllDeliveriesMetaService(tab);
            if (data) {
                setDeliveryMeta((prevState) => ({ ...prevState, ...data }));
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    const loadDeliveriesListData = (args) => {
        if (args.showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getDeliveriesService({ ...args, populate: 'riderId,merchantId' })
            .then((res) => {
                const { data } = res;
                if (data && data.docs) {
                    const docs = mapDocs(data.docs);
                    setDeliveryListData({
                        ...deliveryListData,
                        ...data,
                        docs,
                    });
                }
            })
            .catch((e) => {
                const { message: exception } = apiErrorHandlerV2(e);
                toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                    position: 'top-right',
                });
            })
            .finally(() => {
                if (args.showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const mapDocs = (docs) => {
        let allDocs = [];
        allDocs = docs.map((x) => ({
            ...x,
            formattedStatus: getDeliveryStatus(x.status, x.riderId?._id),
            recipientNameFormatted: `${x.recipientName || 'No Recipient Given'}, ${x.deliveryAddress || ''}`,
            riderDetails: x.riderReference ? `${x.riderName} ${x.riderReference}` : `Rider Not Assigned`,
            merchant: x.merchantId && isObject(x.merchantId) ? x.merchantId : null,
            merchantName: x.merchantId && isObject(x.merchantId) ? x.merchantId.name : null,
            merchantPictureUrl: x.merchantId && isObject(x.merchantId) ? x.merchantId.merchantPictureUrl : null,
            merchantId: x.merchantId && isObject(x.merchantId) ? x.merchantId._id : x.merchantId,
            formattedRating: x.deliveryRating ? `${x.deliveryRating}/5` : 'Not Rated',
            orderReferenceNumber: x.orderReferenceNumber || '',

            deliveryDate:
                moment(x.deliveryDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')
                    ? `Today`
                    : `${moment(x.deliveryDate).format('YYYY-MM-DD')}`,
        }));
        return allDocs;
    };

    const replaceUrlParamsHandler = (
        params = {
            tab: null,
            status: null,
            page: '1',
            limit: '10',
            searchBy: null,
            searchText: null,
            sort: null,
            order: null,
            filterDate: null,
        }
    ) => {
        try {
            history.replace({
                search: `${
                    params.page || urlParamsSearch.page ? `page=${params.page || urlParamsSearch.page || '1'}&` : ''
                }${params.tab || urlParamsSearch.tab ? `tab=${params.tab || urlParamsSearch.tab || '1'}&` : ''}${
                    params.status || urlParamsSearch.status
                        ? `status=${params.status || urlParamsSearch.status || 'all'}&`
                        : ''
                }${params?.searchBy ? `searchBy=${params.searchBy}&` : ''}${
                    params?.searchText ? `searchText=${params.searchText}&` : ''
                }${params?.filterDate ? `filterDate=${params.filterDate}&` : ''}${
                    params?.limit ? `limit=${params.limit}&` : ''
                }${params?.sort ? `sort=${params.sort}&` : ''}${params?.order ? `order=${params.order}` : ''}`,
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleOnClickNewDelivery = () => {
        history.push({
            pathname: `${PATHS.DELIVERY.FORM}`,
            search: `?type=new`,
        });
    };

    const handleOnClickImportDeliveries = () => {
        history.push({
            pathname: `${PATHS.IMPORTS.DELIVERY_IMPORTS.DEFAULT}`,
        });
    };

    const handlePageChange = (pageNo) => {
        replaceUrlParamsHandler({
            page: pageNo,
            searchBy: searchParams?.searchBy,
            searchText: searchParams?.searchText,
            filterDate: searchParams?.filterDate,
            sort: searchParams?.sort,
            order: searchParams?.order,
        });
    };

    const handleFilter = (field, filterText) => {
        let modifiedFilterText = null;
        if (filterText && filterText !== '') {
            modifiedFilterText = filterText.replaceAll(' ', '$2');
        } else {
            modifiedFilterText = null;
        }
        replaceUrlParamsHandler({ searchBy: field || 'referenceNumber', searchText: modifiedFilterText, page: '1' });
    };

    // handling date filtering and updating URL parameters
    const handleFilterDate = (field, filterDate) => {
        let modifiedFilterDate = null;
        if (filterDate && filterDate !== '') {
            modifiedFilterDate = filterDate;
        } else {
            modifiedFilterDate = null;
        }
        replaceUrlParamsHandler({ searchBy: field, filterDate: modifiedFilterDate, page: '1' });
    };

    const handleEditRow = (e, row) => {
        history.push({
            pathname: `${PATHS.DELIVERY.FORM}`,
            search: `?type=edit&id=${row._id}`,
            state: { deliveryParentData: row },
        });
    };
    const handleViewRow = (e, row) => {
        history.push({
            pathname: `${PATHS.DELIVERY.FORM}`,
            search: `?type=deleted-view`,
            state: { deliveryParentData: row },
        });
    };

    const handleOnDelete = (e, row) => {
        setShowDeleteModal({ state: true, data: row });
    };

    const handleDeleteDelivery = async (data) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await deleteDelivery(data._id);
            reloadComponent();
            toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            console.log(e);
            if (e && e.data && e.data.errors && e.data.errors.userMessage) {
                toast.error(e.data.errors.userMessage);
            } else {
                toast.error('Something went wrong');
            }
        }
        loadAllDeliveriesMeta(urlParamsSearch.tab);
        setShowDeleteModal({ state: false, data: null });
        setBackdropLoading((prevState) => prevState - 1);
    };

    const allowDeliveryDelete = (delivery) => {
        if (IS_SUPER_ADMIN()) {
            return true;
        }

        if (delivery) {
            if (delivery.riderId) return false;
        }
        return true;
    };

    /**
     * Based on the passed column set url query params for column wise sorting
     * @param {boolean} isDescending - If true sorting is descending order else ascending order
     * @param {string} column - Which column need to be used
     */
    const handleColumnWiseSorting = (isDescending, column) => {
        replaceUrlParamsHandler({
            sort: column,
            order: isDescending ? -1 : 1,
            searchBy: urlParamsSearch.searchBy,
            searchText: urlParamsSearch.searchText,
        });
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />

                    {!isCompletedList && !isDeletedList && (
                        <>
                            <Row>
                                <div className="d-flex row ml-2">
                                    {hasAccess(PERMISSIONS.DELIVERY, ['Add']) && (
                                        <motion.div
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                            onClick={() => handleOnClickNewDelivery()}
                                            id="new-delivery-btn"
                                        >
                                            <i className="fas fa-cart-plus left-icon" />
                                            <span>New Delivery</span>
                                        </motion.div>
                                    )}
                                    {hasAccess(PERMISSIONS.DELIVERY_EXCEL_UPLOAD, ['View']) && (
                                        <motion.div
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                            onClick={() => handleOnClickImportDeliveries()}
                                            id="import-delivery-btn"
                                        >
                                            <i className="fas fa-file-import left-icon" />
                                            <span>Import Deliveries</span>
                                        </motion.div>
                                    )}
                                </div>
                            </Row>
                            <div className="delivery-list__card__scrollkit">
                                <Row
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'start',
                                        marginTop: 5,
                                        width: 'max-content',
                                    }}
                                >
                                    <motion.div
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="w3-animate-opacity pointer-cursor"
                                        onClick={() => {
                                            replaceUrlParamsHandler({ status: 'onGoing', page: '1' });
                                        }}
                                    >
                                        <StatCard
                                            title="ON-GOING"
                                            value={`${deliveryMeta.ongoingDeliveries} DELIVERIES`}
                                            tooltipTitle="Ongoing Deliveries"
                                            tooltipPlacement="bottom"
                                            icon="mdi mdi-clock-outline"
                                            active={urlParamsSearch?.status === 'onGoing'}
                                        />
                                    </motion.div>
                                    <motion.div
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="w3-animate-opacity pointer-cursor"
                                        onClick={() => {
                                            replaceUrlParamsHandler({ status: 'completedToday', page: '1' });
                                        }}
                                    >
                                        <StatCard
                                            title="COMPLETED TODAY"
                                            value={`${deliveryMeta.deliveriesCompletedToday} DELIVERIES`}
                                            icon="fas fa-check-circle"
                                            tooltipTitle="Deliveries Completed Today"
                                            tooltipPlacement="bottom"
                                            active={urlParamsSearch?.status === 'completedToday'}
                                        />
                                    </motion.div>
                                    <motion.div
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="w3-animate-opacity pointer-cursor"
                                        onClick={() => {
                                            replaceUrlParamsHandler({ status: 'pending', page: '1' });
                                        }}
                                    >
                                        <StatCard
                                            title="PENDING"
                                            value={`${deliveryMeta.pendingPickupDeliveries} PICKUPS`}
                                            tooltipTitle="Pickup Pending Count"
                                            tooltipPlacement="bottom"
                                            icon="fas fa-biking"
                                            active={urlParamsSearch?.status === 'pending'}
                                        />
                                    </motion.div>
                                    <motion.div
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="w3-animate-opacity pointer-cursor"
                                        onClick={() => {
                                            replaceUrlParamsHandler({ status: 'notAssigned', page: '1' });
                                        }}
                                    >
                                        <StatCard
                                            title="NOT ASSIGNED"
                                            value={`${deliveryMeta.notAssignedDeliveries} DELIVERIES`}
                                            tooltipTitle="Not Assigned Count"
                                            tooltipPlacement="bottom"
                                            icon="fas fa-biking"
                                            active={urlParamsSearch?.status === 'notAssigned'}
                                        />
                                    </motion.div>
                                </Row>
                            </div>
                            <Row className="m-2" style={{ justifyContent: 'space-between' }}>
                                <div>
                                    <Nav tabs className="nav-tabs-custom order-processing">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: activeTab === 'all',
                                                })}
                                                onClick={() => {
                                                    replaceUrlParamsHandler({ tab: 'all', page: '1' });
                                                }}
                                            >
                                                <span className="d-none d-sm-block h5">
                                                    {' '}
                                                    All ( {deliveryMeta.allDeliveries} ){' '}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: activeTab === 'general',
                                                })}
                                                onClick={() => {
                                                    replaceUrlParamsHandler({ tab: 'general', page: '1' });
                                                }}
                                            >
                                                <span className="d-none d-sm-block h5">
                                                    Created ( {deliveryMeta.generalDeliveries} ){' '}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: activeTab === 'imported',
                                                })}
                                                onClick={() => {
                                                    replaceUrlParamsHandler({ tab: 'imported', page: '1' });
                                                }}
                                            >
                                                <span className="d-none d-sm-block h5">
                                                    Imported ( {deliveryMeta.importedDeliveries} )
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                <div>
                                    <motion.button
                                        id="reload-comp-btn"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn circle shadow-lg nw-md pr-2 pl-3 pointer m3-3 mr-3"
                                        onClick={() => {
                                            replaceUrlParamsHandler({
                                                tab: 'all',
                                                status: 'all',
                                                page: '1',
                                                searchBy: null,
                                                searchText: null,
                                            });
                                        }}
                                    >
                                        <>Reset Filters</>
                                    </motion.button>
                                    <motion.button
                                        id="reload-comp-btn"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn circle shadow-lg nw-md pr-2 pl-3 pointer"
                                        onClick={() => {
                                            reloadComponent();
                                        }}
                                    >
                                        <>
                                            <i className="fas fa-redo-alt left-icon"></i>
                                        </>
                                    </motion.button>
                                </div>
                            </Row>
                        </>
                    )}
                    <br />

                    <Row>
                        <TableCustom
                            id="delivery-table-id"
                            tableData={deliveryListData}
                            onPageChange={handlePageChange}
                            onFilter={handleFilter}
                            handleDelete={handleOnDelete}
                            loading={loading}
                            isFullScreenShow
                            handleEdit={handleEditRow}
                            handleView={handleViewRow}
                            showView={isDeletedList}
                            showEdit={hasAccess(PERMISSIONS.DELIVERY, ['Edit']) && !isDeletedList}
                            showDelete={DEV_ENV() && !isDeletedList}
                            removeId={false}
                            isExport
                            type="delivery"
                            exportModalHeader="Export Delivery Data"
                            filters={deliveryFilters}
                            showStickyView
                            allowDelete={allowDeliveryDelete}
                            isManualSearch
                            searchProp={searchParams}
                            handleResultsPerPageChange={handleResultsPerPageChange}
                            showResultsPerPage
                            onDateFilter={handleFilterDate}
                            onSort={handleColumnWiseSorting}
                        />
                    </Row>
                </Container>
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.7} />

            <AlertModal
                show={showDeleteModal.state}
                onConfirm={handleDeleteDelivery}
                data={showDeleteModal.data}
                onHide={() =>
                    setShowDeleteModal({
                        state: false,
                        data: null,
                    })
                }
            />
        </>
    );
}

DeliveryManagementPage.propTypes = {
    location: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotDeliveryManagementPage = hot(DeliveryManagementPage);

export default connect(mapStateToProps, {})(HotDeliveryManagementPage);
