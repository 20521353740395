/* eslint-disable indent */
import {
    GET_PICKING_TASKS,
    GET_ONE_PICKING_TASK,
    GET_ALL_PICKING_TASKS,
    CREATE_PICKING_TASK,
    UPDATE_PICKING_TASK,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get PickingTasks Service
 * @returns {object}
 */
export const getPickingTasksService = function api(
    all = true,
    page = 1,
    limit = 10,
    filter = null,
    sort,
    order,
    searchBy,
    populate = 'orders'
) {
    return new Promise(async function promiseCall(resolve, reject) {
        const requestURL = `${GET_PICKING_TASKS}?page=${page}&${all ? `all=true&` : ''}limit=${limit}&${
            filter ? `filter=${filter}&` : ''
        }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : `order=-1`}${
            populate ? `populate=${populate}&` : ''
        }${
            searchBy
                ? `&${
                      searchBy.searchBy && searchBy.searchText
                          ? `searchBy=${searchBy.searchBy}&searchText=${searchBy.searchText}&`
                          : ''
                  }&`
                : ''
        }`;
        try {
            const url = UrlBuilder().setUrl(requestURL).build();
            resolve(instance.get(url));
        } catch (exception) {
            reject(exception);
        }
    });
};

/**
 * Get Picking Task By Id Service
 * @param {string} id
 * @returns {Promise}
 */

export const getPickingByIdService = (id) => {
    try {
        const url = UrlBuilder().setUrl(GET_ONE_PICKING_TASK).setPathParams({ id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get All Picking Tasks
 * @returns {Promise}
 */
export const getAllPicking = () => {
    try {
        const url = UrlBuilder().setUrl(GET_ALL_PICKING_TASKS).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Create Picking Tasks
 * @returns {Promise}
 */
export const createPicking = (data) => {
    try {
        const url = UrlBuilder().setUrl(CREATE_PICKING_TASK).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update PickingTask Service
 * @param {object} data
 * @returns {Promise}
 */
export const updatePickingTaskService = (data) => {
    try {
        const url = UrlBuilder().setUrl(UPDATE_PICKING_TASK).setPathParams({ id: data._id }).build();
        return instance.patch(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
