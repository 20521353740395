import {
    POST_INVENTORY_REPORT,
    POST_CYCLE_COUNT_REPORT,
    POST_TRANSACTION_LOG_REPORT,
    POST_STOCK_RECEIVING_REPORT,
    POST_STOCK_ISSUING_REPORT,
    POST_STOCK_ADJUSTMENT_REPORT,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Inventory Report
 * @param {object} data
 * @returns {Promise}
 */
export const getInventoryReport = (data) => {
    try {
        const url = UrlBuilder().setUrl(POST_INVENTORY_REPORT).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Cycle Count Report
 * @param {object} data
 * @returns {Promise}
 */
export const getCycleCountReport = (data) => {
    try {
        const url = UrlBuilder().setUrl(POST_CYCLE_COUNT_REPORT).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Transaction Log Report
 * @param {object} data
 * @returns {Promise}
 */
export const getTransactionLogReport = (data) => {
    try {
        const url = UrlBuilder().setUrl(POST_TRANSACTION_LOG_REPORT).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Stock Receiving Report
 * @param {object} data
 * @returns {Promise}
 */
export const getStockReceivingReport = (data) => {
    try {
        const url = UrlBuilder().setUrl(POST_STOCK_RECEIVING_REPORT).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Stock Issuing Report
 * @param {object} data
 * @returns {Promise}
 */
export const getStockIssuingReport = (data) => {
    try {
        const url = UrlBuilder().setUrl(POST_STOCK_ISSUING_REPORT).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Stock Adjustment Report
 * @param {object} data
 * @returns {Promise}
 */
export const getStockAdjustmentReport = (data) => {
    try {
        const url = UrlBuilder().setUrl(POST_STOCK_ADJUSTMENT_REPORT).build();
        return instance.post(url, data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
