import React from 'react';
import PropTypes from 'prop-types';
import ErrorFallback from './ErrorFallback';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        console.log(error, errorInfo);
        if (error?.message) {
            const { message } = error;
            if (message === 'Malformed UTF-8 data') {
                localStorage.clear();
                window.location.reload();
            }
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorFallback error={this.state.error} />;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.any,
};
