import React from 'react';
import { motion } from 'framer-motion';

// #region styles
import { THEME_COLOR_2 } from 'theme';

export default function BatchPrintResourceGenerated() {
    return (
        <>
            <div className="font-size-16 w-100 p-0 " style={{ borderRadius: 20 }}>
                <div
                    className="text-center text-white"
                    style={{
                        backgroundColor: THEME_COLOR_2,
                        padding: 10,
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                    }}
                >
                    Batch Print Document Alert!{' '}
                </div>
                <div
                    className="d-flex justify-content-center align-content-center align-items-center mt-4 mb-1"
                    style={{ flexDirection: 'column' }}
                >
                    <div style={{ fontSize: 14 }}>Batch Print Document Generated. </div>
                    <div style={{ fontSize: 10 }} className="mt-2">
                        Please click Reload to refresh the page. .{' '}
                    </div>
                    <div style={{ fontSize: 14 }} className="mt-2">
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-5 pl-5 shadow-lg "
                            onClick={() => {
                                window.location.reload();
                            }}
                            style={{ borderRadius: 10 }}
                        >
                            Reload
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
}

BatchPrintResourceGenerated.propTypes = {};
