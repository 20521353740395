import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';

// #region imports
import { centerElementContainerStyles, centerElementInnerStyles } from 'styles';

function ScootixModal(props) {
    const {
        toggleModal,
        show = false,
        title = null,
        message = 'Are you sure?',
        onConfirm = null,
        onCancel = null,
        confirmMessage = 'Yes',
        cancelMessage = 'No',
        data = null,
        removeBtns = false,
        phoneNo,
        loading = false,
        headerColor = null,
    } = props;

    return (
        <>
            <div className="custom-scootix-modal">
                {show && (
                    <Modal
                        size="lg"
                        isOpen
                        center
                        toggle={toggleModal}
                        style={{ borderRadius: 15 }}
                        className="modal-dialog modal-dialog-centered scootix-confirmation-modal"
                    >
                        <ModalHeader
                            className={!headerColor ? 'scootix-bg-radiant' : ''}
                            style={{
                                backgroundColor: headerColor || undefined,
                                marginTop: -1,
                                marginRight: -1,
                                marginLeft: -1,
                                color: '#FFF',
                                borderTopRightRadius: 15,
                                borderTopLeftRadius: 15,
                            }}
                        >
                            <h4
                                style={{
                                    color: '#FFF',
                                }}
                            >
                                {' '}
                                {title || 'Confirmation'}{' '}
                            </h4>
                        </ModalHeader>
                        <ModalBody
                            style={{
                                flexDirection: 'column',
                                borderRadius: 15,
                                backgroundColor: '#FAFAFA',
                                ...centerElementContainerStyles,
                            }}
                            className="p-3"
                        >
                            <br />
                            <div>
                                <h5 style={{ textAlign: 'center' }}>{message}</h5>
                            </div>
                            {phoneNo && (
                                <div>
                                    <a href={`tel:+${phoneNo}`}>{`+ ${phoneNo}`}</a>
                                </div>
                            )}
                            <br />
                            {!removeBtns && (
                                <div className="row">
                                    <motion.div
                                        className="btn"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        style={{
                                            backgroundColor: '#FFF9EA',
                                            minWidth: 120,
                                            padding: 17,
                                            paddingRight: 24,
                                            paddingLeft: 24,
                                            borderRadius: 20,
                                            marginLeft: 10,
                                            border: '1px solid #FDBA24',
                                            textAlign: 'center',
                                            ...centerElementInnerStyles,
                                        }}
                                        onClick={() => {
                                            if (onCancel) {
                                                onCancel(data);
                                            } else {
                                                // eslint-disable-next-line no-unused-expressions
                                                toggleModal && toggleModal();
                                            }
                                        }}
                                    >
                                        <span
                                            className="h6"
                                            style={{ color: '#FDBA24', fontWeight: 'bold', fontSize: 18 }}
                                        >
                                            {cancelMessage}
                                        </span>
                                    </motion.div>
                                    <motion.div
                                        className="scootix-bg-radiant ml-2 btn"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        style={{
                                            minWidth: 120,
                                            padding: 17,
                                            paddingRight: 24,
                                            paddingLeft: 24,
                                            borderRadius: 20,
                                            textAlign: 'center',
                                            ...centerElementInnerStyles,
                                        }}
                                        onClick={() => {
                                            if (onConfirm) {
                                                onConfirm(data);
                                            }
                                        }}
                                    >
                                        {loading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <span
                                                    className="h6"
                                                    style={{ color: '#FFF', fontWeight: 'bold', fontSize: 18 }}
                                                >
                                                    {confirmMessage}
                                                </span>
                                            </>
                                        )}
                                    </motion.div>
                                </div>
                            )}
                        </ModalBody>
                    </Modal>
                )}
            </div>
        </>
    );
}

ScootixModal.propTypes = {
    show: PropTypes.bool,
    removeBtns: PropTypes.bool,
    title: PropTypes.string,
    phoneNo: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    toggleModal: PropTypes.func,
    onCancel: PropTypes.func,
    confirmMessage: PropTypes.string,
    cancelMessage: PropTypes.string,
    headerColor: PropTypes.string,
    data: PropTypes.object,
    loading: PropTypes.bool,
};

export default ScootixModal;
