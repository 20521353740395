// @flow
import {
    RESTORE_USER_START,
    RESTORE_USER_END,
    PERMANENT_DELETE_USER_END,
    PERMANENT_DELETE_USER_START,
} from './actionTypes';

const INIT_STATE = {
    restoreUserData: null,
    permanentDeleteUserData: null,
};

const DeletedUsersReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESTORE_USER_START:
            return {
                ...state,
                restoreUserData: action.payload,
            };
        case RESTORE_USER_END:
            return {
                ...state,
                restoreUserData: null,
            };
        case PERMANENT_DELETE_USER_START:
            return {
                ...state,
                permanentDeleteUserData: action.payload,
            };
        case PERMANENT_DELETE_USER_END:
            return {
                ...state,
                permanentDeleteUserData: null,
            };
        default:
            return state;
    }
};

export default DeletedUsersReducer;
