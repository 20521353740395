export const defaultTableHeaders = [
    {
        key: 'sequenceId',
        value: 'Print Id',
    },
    {
        key: 'type',
        value: 'Type',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
    },
    {
        key: 'name',
        value: 'Print Name',
    },
    {
        key: 'createdByUserFullName',
        value: 'Created By',
    },
    {
        key: 'createdAt',
        value: 'Created Date and Time',
        type: 'dateTime',
    },
    {
        key: 'status',
        value: 'Status',
    },
];

export const filters = [
    {
        label: 'Print Id',
        key: 'sequenceId',
    },
    {
        label: 'Merchant',
        key: 'merchantName',
    },
    {
        label: 'Print Name',
        key: 'name',
    },
];

export const getStatus = (deliveryStatus, rider) => {
    if (deliveryStatus === 'inProgress') {
        return 'dispatchReady';
    }

    if (deliveryStatus === 'pickedUp') {
        return 'inProgress';
    }

    if (deliveryStatus === 'deliveryStarted') {
        return 'deliveryStarted';
    }

    if (deliveryStatus === 'completed') {
        return 'completed';
    }

    if (deliveryStatus === 'delivered') {
        return 'delivered';
    }

    if (rider) {
        return 'riderAssigned';
    }

    return 'delivering';
};

export const availableDateFilters = ['createdAt', 'updatedAt'];

export const defaultListData = {
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    headers: defaultTableHeaders,
    docs: [],
    all: false,
    filter: null,
    sort: null,
    search: undefined,
    order: -1,
};
