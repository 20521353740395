/* eslint-disable react/prop-types */
import { hot } from 'react-hot-loader/root';
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import UserAlert from 'pages/Modules/User-Management/components/UserAlert';
// import DeleteAccount from 'components/Icons/DeleteAccount';
import DeleteUserModel from 'pages/Modules/User-Management/components/DeleteUserModel';
// import RestoreUserModel from 'pages/Modules/User-Management/components/RestoreUserModel';
// import DeleteRiderModel from 'pages/Modules/Rider-Management/components/DeleteRiderModel';
// import RestoreRiderModal from 'pages/Modules/Rider-Management/components/RestoreRiderModal';

// #region services

function TestDataGrid(props) {
    const { show = true } = props;

    const generateBody = () => <></>;

    return (
        <>
            <Modal isOpen={show} center className="modal-dialog modal-dialog-centered">
                <ModalBody className="p-0">
                    {generateBody()}
                    <DeleteUserModel show={show} />
                    {/* <RestoreUserModel show={show} /> */}
                    {/* <DeleteRiderModel show={show} /> */}
                    {/* <RestoreRiderModal show={show} /> */}
                </ModalBody>
            </Modal>
            <div style={{ margin: '200px 20px 0 20px' }}>
                <UserAlert />
            </div>
        </>
    );
}

const HotApp = hot(TestDataGrid);

TestDataGrid.propTypes = {
    show: PropTypes.bool,
    // type: PropTypes.any,
};

export default HotApp;
