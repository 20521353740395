import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Modal, ModalBody } from 'reactstrap';
import { CrossCircleIcon } from 'components/Icons';

/**
 * A modal used in the API service related pages.
 * Content of the modal should be passed as children.
 * They will go immediately under the <ModalBody> without further styling.
 * @param {object} props
 */
function DeveloperModal(props) {
    const { show, handleToggle, header, children, mode = 'enable', size = 'md' } = props;

    return (
        <div className="custom-scootix-modal">
            <Modal
                size={size}
                isOpen={show}
                backdrop
                toggle={handleToggle}
                style={{ borderRadius: 15 }}
                className="modal-dialog modal-dialog-centered scootix-confirmation-modal"
            >
                {mode === 'disable' ? (
                    <CardHeader
                        className="d-flex justify-content-between align-items-center"
                        style={{
                            backgroundColor: '#FD4A4A',
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15,
                        }}
                    >
                        <span style={{ color: '#FFF' }}>{header}</span>
                        <div className="mr-1 cursor-pointer" onClick={handleToggle} role="button">
                            <CrossCircleIcon color="#FFF" />
                        </div>
                    </CardHeader>
                ) : (
                    <CardHeader
                        className="scootix-bg-radiant d-flex justify-content-between"
                        style={{
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15,
                        }}
                    >
                        <span style={{ color: '#FFF' }}>{header}</span>
                        <div className="ml-1 cursor-pointer" onClick={handleToggle} role="button">
                            <CrossCircleIcon color="#FFF" />
                        </div>
                    </CardHeader>
                )}
                <ModalBody>{children}</ModalBody>
            </Modal>
        </div>
    );
}

DeveloperModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    /** Text for the header */
    header: PropTypes.string,
    children: PropTypes.node,
    /** Either 'enable' (default) or 'disable', which is needed to set the styles. */
    mode: PropTypes.string,
    size: PropTypes.string,
};

export default DeveloperModal;
