import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import { Card, CardBody, Col, Row } from 'reactstrap';
import './styles/index.scss';

// #region assets | components
import { PhoneVolume } from 'components/Icons';
import { VEHICLE_IMAGES } from 'constants/constants';

// #region services
import { getAttachmentByKey } from 'services/attachment.service';

/**
 * A React component that displays rider details(online status, name, rider Id, vehicle type and vehicle).
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.riderDetails - The details of the rider to display.
 * @returns {JSX.Element} - The rendered component.
 */
function RiderDetailCard(props) {
    const { riderDetails } = props;

    const [images, setImages] = useState({
        profilePictureUrl: null,
    });

    useEffect(() => {
        if (riderDetails?.profilePictureUrl) {
            loadProfileImage(riderDetails?.profilePictureUrl);
        }
    }, [riderDetails]);

    /**
     * Load Profile Image
     * @param {string} profilePictureUrl
     */
    const loadProfileImage = (profilePictureUrl) => {
        getAttachmentByKey(profilePictureUrl)
            .then((res) => {
                if (res) {
                    setImages((prevState) => ({ ...prevState, profilePictureUrl: res }));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <React.Fragment>
            <Card className="shadow default-card route-planning-entry__card">
                <CardBody>
                    <Row>
                        <Col xs={4} className="align-items-center my-auto">
                            <Avatar
                                name={riderDetails?.fullName || ''}
                                src={images?.profilePictureUrl || ''}
                                size="5rem"
                                round
                                colors={['#1e6262', '#581b98', '#364f6b']}
                                className={
                                    riderDetails?.status === 'active' && riderDetails?.isActive ? 'avatar-online' : ''
                                }
                            />
                        </Col>
                        <Col xs={5} className="pl-0 pr-0">
                            <h5 className="mb-2" style={{ fontWeight: 'bold' }}>
                                Rider/Driver
                            </h5>
                            <h5 className="text-muted mb-0">{riderDetails?.fullName || 'Not Assigned'}</h5>
                            <p className="font-size-12 mb-0">{riderDetails?.referenceNumber}</p>
                            {riderDetails && (
                                <div className="d-flex">
                                    <PhoneVolume color="#FF9300" />
                                    <p className="font-size-12 ml-1">{riderDetails?.mobileNo}</p>
                                </div>
                            )}
                        </Col>
                        {riderDetails && (
                            <Col xs={3} className="d-flex justify-content-center align-items-center">
                                <img
                                    className="vehicle-type"
                                    src={VEHICLE_IMAGES[riderDetails?.vehicleType]}
                                    alt={riderDetails?.vehicleType || 'vehicle image'}
                                />
                            </Col>
                        )}
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

RiderDetailCard.propTypes = {
    riderDetails: PropTypes.any,
};

export default RiderDetailCard;
