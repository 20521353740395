import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Container, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';
import CheckCircleIcon from 'components/Icons/Check-Circle';
import CopyIcon from 'components/Icons/Copy';
import { CustomToast } from 'components';

/** This modals prompts the user for the password, in order to show the Secret Key */
function ViewSecretKey(props) {
    const { show, handleToggle, handleSubmit, apiKeyId } = props;

    /** State for showing whether the password check is in progress */
    const [loading, setLoading] = useState(false);
    /** State for storing entered form data */
    const [formData, setFormData] = useState({ password: '' });
    /** State for storing the secret key */
    const [secretKey, setSecretKey] = useState(null);

    const handleModalSubmit = async () => {
        setLoading(true);
        const response = await handleSubmit(apiKeyId, formData);
        if (!('error' in response)) {
            setSecretKey(response.secretKey);
        }
        setLoading(false);
    };

    const handleModalToggle = () => {
        resetForm();
        handleToggle();
        setSecretKey(null);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDoneButton = () => {
        handleModalToggle();
    };

    /**
     * Copy the secret key to the clipboard and display a toast.
     * Uses the Synchronous Clipboard API.
     * See: https://caniuse.com/?search=clipboard
     */
    const handleCopyButton = () => {
        navigator.clipboard.writeText(secretKey);
        toast.custom((t) => <CustomToast text="Copied to clipboard" t={t} type="success" />, {
            position: 'top-right',
        });
    };

    const resetForm = () => {
        setFormData({ password: '' });
        setLoading(false);
    };

    return (
        <DeveloperModal header="View Secret Key" show={show} handleToggle={handleModalToggle}>
            {secretKey ? (
                <Container>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="secretKey">Secret Key</Label>
                                <Input type="password" value={secretKey} disabled />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="auto">
                            <Button
                                style={{ borderRadius: 10 }}
                                className="scootix-btn-radius"
                                onClick={handleDoneButton}
                            >
                                <CheckCircleIcon color="#FFF" />
                                &nbsp;DONE
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                style={{ borderRadius: 10 }}
                                className="scootix-btn-radius"
                                onClick={handleCopyButton}
                            >
                                <CopyIcon color="#FFF" />
                                &nbsp;COPY
                            </Button>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Container>
                    <AvForm className="needs-validation default-form" noValidate onValidSubmit={handleModalSubmit}>
                        <Row>
                            <Col>
                                <Alert color="warning" style={{ borderRadius: 8 }}>
                                    Please enter your password to view the secret key.
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AvField
                                    name="password"
                                    type="password"
                                    value={formData.password}
                                    className="form-control"
                                    errorMessage="Required"
                                    onChange={handleInputChange}
                                    validate={{ required: { value: true } }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button disabled={loading} style={{ borderRadius: 10 }} className="scootix-btn-radius">
                                    {loading ? (
                                        <Spinner size="sm" color="warning" className="spinner-white mx-1" />
                                    ) : (
                                        <CheckCircleIcon color="#FFF" />
                                    )}
                                    &nbsp;DISPLAY
                                </Button>
                            </Col>
                        </Row>
                    </AvForm>
                </Container>
            )}
        </DeveloperModal>
    );
}

ViewSecretKey.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    apiKeyId: PropTypes.string.isRequired,
};

export default ViewSecretKey;
