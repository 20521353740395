import React from 'react';
import { Container } from 'reactstrap';
import APIRatesLimits from './Api-rates-limit';
import RequestSettings from './Request-settings';

const AdvancedSettings = () => (
    <Container fluid>
        <APIRatesLimits />
        <RequestSettings />
    </Container>
);

export default AdvancedSettings;
