export const defaultTableHeaders = [
    {
        key: 'referenceNumber',
        value: 'Location Id',
    },
    {
        key: 'merchantLocationName',
        value: 'Merchant Location Name',
    },
    {
        key: 'location',
        value: 'Location',
    },
    {
        key: 'city',
        value: 'City',
    },
    {
        key: 'state',
        value: 'State',
    },
    {
        key: 'merchantName',
        value: 'Merchant',
        isAvatar: true,
        isImage: true,
        imageProp: 'merchantPictureUrl',
    },
    {
        key: 'clientName',
        value: 'Client',
        isAvatar: true,
    },
];
