/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { CustomToast, ScootixModal, TableCustom } from 'components';

// #region imports
import PATHS from 'routes/paths';
import { PERMISSIONS } from 'utils/constants';

// #region services
import { getMerchantLocationsDeletedService } from 'services/merchant-location.service';

// #region utils
import { apiErrorHandler } from 'utils/helpers';
import { getUser, hasAccess } from 'utils/checkAuth';

// #endregion imports
import { defaultTableHeaders } from './constants';
import { deleteMerchantLocationPermanentService, restoreMerchantLocationService } from '../../services';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Merchant Locations', link: PATHS.MERCHANT.MERCHANT_LOCATION_LIST },
    { title: 'Deleted Merchant Locations', link: '#' },
];

function DeletedMerchantLocationsPage(props) {
    const { location } = props;

    const history = useHistory();

    // component state
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(0);

    const [merchantLocationListData, setMerchantLocationListData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    const [scootixModalState, setScootixModalState] = useState({
        show: false,
        title: null,
        message: 'Are you sure?',
        confirmMessage: 'Accept',
        cancelMessage: 'Cancel',
        onConfirm: null,
        onCancel: null,
        data: null,
    });

    const [searchBy, setSearchBy] = useState({ searchBy: '', searchText: null });

    const [isUserRestored, setIsUserRestored] = useState(false);
    const [isUserDeletePermanent, setIsUserDeletePermanent] = useState(false);

    useEffect(() => {
        loadMerchantLocationListData(
            true,
            merchantLocationListData.all,
            1,
            merchantLocationListData.limit,
            merchantLocationListData.filter,
            merchantLocationListData.sort,
            merchantLocationListData.order,
            searchBy,
            'deleted'
        );
    }, []);

    useEffect(() => {
        try {
            if (location && location.search) {
                if (location.search.includes('restoreid')) {
                    const regexParams = location.search.match(new RegExp('restoreid=' + '(.*)' + '&'));
                    if (regexParams && regexParams.length > 1) {
                        const checkForValidMongoDbID = new RegExp('^[0-9a-fA-F]{24}$');
                        if (checkForValidMongoDbID.test(regexParams[1])) {
                            handleOnRestore(regexParams[1]);
                            setIsUserRestored(true);
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
        try {
            if (location && location.search) {
                if (location.search.includes('deletepermenantid')) {
                    const regexParams = location.search.match(new RegExp('deletepermenantid=' + '(.*)' + '&'));
                    if (regexParams && regexParams.length > 1) {
                        const checkForValidMongoDbID = new RegExp('^[0-9a-fA-F]{24}$');
                        if (checkForValidMongoDbID.test(regexParams[1])) {
                            handleOnDeletePermanent(regexParams[1]);
                            setIsUserDeletePermanent(true);
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }, [location]);

    const loadMerchantLocationListData = (
        showLoading = true,
        all,
        page,
        limit,
        filter,
        sort,
        order,
        searchByProp,
        status
    ) => {
        if (showLoading) {
            setLoading((prevState) => prevState + 1);
        }
        getMerchantLocationsDeletedService(all, page, limit, filter, sort, order, searchByProp, null, status)
            .then((res) => {
                const { data } = res;
                setMerchantLocationListData({
                    ...merchantLocationListData,
                    ...data,
                    docs: data.docs.map((x) => ({
                        ...x,
                        name: `${x.firstName || ''} ${x.lastName || ''}`,
                    })),
                });
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (showLoading) {
                    setLoading((prevState) => prevState - 1);
                }
            });
    };

    const handlePageChange = (pageNo) => {
        loadMerchantLocationListData(
            true,
            merchantLocationListData.all,
            pageNo,
            merchantLocationListData.limit,
            merchantLocationListData.filter,
            merchantLocationListData.sort,
            merchantLocationListData.order,
            searchBy,
            'deleted'
        );
    };

    const handleFilter = (field, filterText) => {
        setSearchBy({ searchBy: field || 'fullName', searchText: filterText });
        loadMerchantLocationListData(
            false,
            merchantLocationListData.all,
            1,
            merchantLocationListData.limit,
            merchantLocationListData.filter,
            merchantLocationListData.sort,
            merchantLocationListData.order,
            { searchBy: field || 'fullName', searchText: filterText },
            'deleted'
        );
    };

    const handleOnRestore = (id) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: { _id: id },
            message: 'Are you sure you want to proceed?',
            onConfirm: (data) => handleRestore(data),
        });
    };

    const handleRestore = async (data) => {
        if (data && data._id) {
            try {
                await restoreMerchantLocationService(data._id);
                history.replace({
                    search: ``,
                });
                loadMerchantLocationListData(
                    true,
                    merchantLocationListData.all,
                    merchantLocationListData.page,
                    merchantLocationListData.limit,
                    merchantLocationListData.filter,
                    merchantLocationListData.sort,
                    merchantLocationListData.order,
                    searchBy,
                    'deleted'
                );
                toast.custom((t) => <CustomToast text="Successfully Restored" t={t} type="success" />, {
                    position: 'top-right',
                });
            } catch (e) {
                const message = apiErrorHandler(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        }

        toggleScootixModal();
    };

    const handleOnDeletePermanent = (id) => {
        setScootixModalState({
            ...scootixModalState,
            show: true,
            data: { _id: id },
            message: 'Are you sure you want to Permanently delete Merchant Location?',
            onConfirm: (data) => handleDeletePermanent(data),
        });
    };

    const toggleScootixModal = () => {
        setScootixModalState({ show: false, data: null });
        if (isUserRestored) {
            history.replace({
                search: ``,
            });
            setIsUserRestored(false);
        }
        if (isUserDeletePermanent) {
            history.replace({
                search: ``,
            });
            setIsUserDeletePermanent(false);
        }
    };

    const handleDeletePermanent = async (data) => {
        if (data && data._id) {
            try {
                await deleteMerchantLocationPermanentService(data._id);
                history.replace({
                    search: ``,
                });
                loadMerchantLocationListData(
                    true,
                    merchantLocationListData.all,
                    merchantLocationListData.page,
                    merchantLocationListData.limit,
                    merchantLocationListData.filter,
                    merchantLocationListData.sort,
                    merchantLocationListData.order,
                    searchBy,
                    'deleted'
                );
                toast.custom((t) => <CustomToast text="Successfully Deleted" t={t} type="success" />, {
                    position: 'top-right',
                });
            } catch (e) {
                const message = apiErrorHandler(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
        }

        toggleScootixModal();
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                    <br />
                    <Row>
                        <TableCustom
                            tableData={merchantLocationListData}
                            onFilter={handleFilter}
                            onPageChange={handlePageChange}
                            showDelete={false}
                            showEdit={false}
                            showView={false}
                            removeAction
                            showPermanentDelete={hasAccess(PERMISSIONS.DELETED_MERCHANT_LOCATION, ['Permanent Delete'])}
                            showRestore={hasAccess(PERMISSIONS.DELETED_MERCHANT_LOCATION, ['Restore'])}
                            filters={[
                                {
                                    label: 'Location Id',
                                    key: 'referenceNumber',
                                },
                                {
                                    label: 'Merchant Location Name',
                                    key: 'merchantLocationName',
                                },
                            ]}
                            disabledRows={{
                                role: { key: 'role', value: 'superAdmin' },
                                _id: {
                                    key: '_id',
                                    value: getUser()._id,
                                },
                            }}
                        />
                    </Row>
                </Container>
            </div>
            <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
        </>
    );
}

DeletedMerchantLocationsPage.propTypes = {
    location: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotDeletedMerchantLocationsPage = hot(DeletedMerchantLocationsPage);

export default connect(mapStateToProps, {})(HotDeletedMerchantLocationsPage);
