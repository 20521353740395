export const validateForm = (form) => {
    let isFormValid = false;
    const message = 'Fill all fields';

    const { reportType, warehouseSiteId } = form;

    if (!reportType || !warehouseSiteId) {
        return { isFormValid, message };
    }

    isFormValid = true;
    return { isFormValid, message };
};

export const validateReportOptions = (form) => {
    let isFormOptionsValid = false;
    const optionsMessage = 'Fill all fields';

    const { merchantId, reportType, warehouseSiteId, productMasterDataId } = form;

    if (reportType === 'Inventory Transaction Log' && !merchantId) {
        return { isFormOptionsValid, optionsMessage: 'Select Merchant to Continue' };
    }

    if (reportType === 'Inventory Report' && !warehouseSiteId) {
        return { isFormOptionsValid, optionsMessage: 'Select Warehouse to Continue' };
    }

    if (reportType === 'Inventory Transaction Log' && !productMasterDataId) {
        return { isFormOptionsValid, optionsMessage: 'Select Product to Continue' };
    }

    // if (reportType === 'Inventory Transaction Log' && (!fromDate || !toDate)) {
    //     return { isFormOptionsValid, optionsMessage: 'Select Dates to Continue' };
    // }

    // if (reportType === 'Stocks Receiving' && (!fromDate || !toDate)) {
    //     return { isFormOptionsValid, optionsMessage: 'Select Dates to Continue' };
    // }

    // if (reportType === 'Stocks Issuing' && (!fromDate || !toDate)) {
    //     return { isFormOptionsValid, optionsMessage: 'Select Dates to Continue' };
    // }

    // if (reportType === 'Stocks Adjustments' && (!fromDate || !toDate)) {
    //     return { isFormOptionsValid, optionsMessage: 'Select Dates to Continue' };
    // }

    isFormOptionsValid = true;
    return { isFormOptionsValid, optionsMessage };
};
