/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row } from 'reactstrap';

import excelExport from '../../../../../../assets/images/developer/exportfile.svg';
import ExcelExportButton from './CustomizableButton';
import ChipCard from './ChipCard';
/**
 * This is the card for graphs
 * @param {title: string, customClassName: string, cardStyle: styleObject, handleChipClick:func, hasPermission: bool, handleExportOnclick: func, children: React.Component} props
 * @returns GraphCard component
 */
function GraphCard(props) {
    const {
        title = '',
        customClassName = '',
        cardStyle = {},
        children,
        handleChipClick,
        hasPermission,
        handleExportOnclick,
    } = props;

    const cardId = `${title.toLowerCase().replace(/ /g, '')}-stat-card`;

    // This is styling for selected chip and not selected chips
    const selectedStyle = { borderWidth: '0px', color: 'white', backgroundColor: '#3A3937' };
    const notSelectedStyle = {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#3A3937',
        color: '#3A3937',
    };

    // This identifies the button click on success or error
    const [isSelectedSuccess, setIsSelectedSuccess] = useState(true);

    const handleOnClick = (type) => {
        handleChipClick(type);
    };

    return (
        <React.Fragment>
            <Card
                className={`m-1 pt-1 pb-1 pl-2 pr-2 shadow ${customClassName}`}
                id={cardId}
                style={{ ...cardStyle, backgroundColor: cardStyle.backgroundColor || '#FFF' }}
            >
                <CardBody style={{ marginLeft: '-10px' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {title ? (
                            <h6
                                className="ml-2 font-weight-bold"
                                style={{ fontFamily: 'Nunito', fontSize: '16px', alignItems: 'center' }}
                            >
                                {title}
                            </h6>
                        ) : (
                            <Row>
                                <ChipCard
                                    title="Success Requests Overview"
                                    handleChipClick={() => {
                                        handleOnClick('success');
                                        setIsSelectedSuccess(true);
                                    }}
                                    cardStyle={isSelectedSuccess ? selectedStyle : notSelectedStyle}
                                />
                                <ChipCard
                                    title="Error Requests Overview"
                                    handleChipClick={() => {
                                        handleOnClick('fail');
                                        setIsSelectedSuccess(false);
                                    }}
                                    cardStyle={!isSelectedSuccess ? selectedStyle : notSelectedStyle}
                                />
                            </Row>
                        )}
                        {/* if authorized client user or authorized merchant user */}
                        {hasPermission && (
                            <ExcelExportButton
                                buttonTitle="Export"
                                icon={excelExport}
                                customStyle={{ marginLeft: 20, marginTop: -5 }}
                                handleOnClick={handleExportOnclick}
                            />
                        )}
                    </div>
                    {children && children}
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

GraphCard.propTypes = {
    title: PropTypes.string,
    customClassName: PropTypes.string,
    cardStyle: PropTypes.any,
    children: PropTypes.element,
    handleChipClick: PropTypes.func,
    handleExportOnclick: PropTypes.func,
    hasPermission: PropTypes.bool,
};

export default GraphCard;
