import * as React from 'react';
import PropTypes from 'prop-types';

function SaveAndUpdate(props) {
    const { color, width, height } = props;
    return (
        <svg
            width={width || 24}
            height={height || 24}
            viewBox="1 2 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6 4H16L20 8V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4"
                stroke={color || '#fff'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 16C13.1046 16 14 15.1046 14 14C14 12.8954 13.1046 12 12 12C10.8954 12 10 12.8954 10 14C10 15.1046 10.8954 16 12 16Z"
                stroke={color || '#fff'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14 4V8H8V4"
                stroke={color || '#fff'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
SaveAndUpdate.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default SaveAndUpdate;
