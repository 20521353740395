import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import PATHS from 'routes/paths';
import { AUTH_USER, TOKEN, USER, USER_INITIAL_WINDOW_HREF } from 'constants/local-storage.constants';

// AUTH related imports
import { getApiErrorMessage } from 'constants/api';

import { backToLogin, setAuthDetails } from 'utils/checkAuth';
import { encryptedLocalStorage } from 'utils/local-storage';
import { post } from 'utils/httpclients/instances';
import { AUTH_LOGIN_USERNAME } from 'utils/apiEndpoints';

import { apiError, loginUserSuccessful } from './actions';
import { CHECK_LOGIN, LOGOUT_USER } from './actionTypes';

// If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history } }) {
    try {
        const { data } = yield call(post, AUTH_LOGIN_USERNAME, user);
        if (data) {
            /** If the user logging for the first time redirect to temporary-password-reset route */
            const { isFirstLoginAttempt, isAutoGeneratedPassword } = data.user;
            if (isFirstLoginAttempt && isAutoGeneratedPassword) {
                const userId = data.user._id;
                history.push(`${PATHS.AUTH.TEMPORARY_PASSWORD_RESET}/${userId}`);
            } else {
                setAuthDetails(data);
                yield put(loginUserSuccessful(data));

                let defaultPath = PATHS.HOME.DEFAULT;

                // Redirect User to URL he requested when he came in ( if user's session timed out or was not logged in )
                const prevUrlFromSessionTimeOut = encryptedLocalStorage.getItem(USER_INITIAL_WINDOW_HREF);
                if (prevUrlFromSessionTimeOut) {
                    defaultPath = String(prevUrlFromSessionTimeOut);
                }

                history.push(defaultPath);
            }
        }
        yield put(apiError('Something went wrong'));
    } catch (error) {
        console.log(error);
        if (error.message) {
            if (error.response && error.response.data && error.response.data.errors) {
                const message = getApiErrorMessage(
                    error.response.data.errors.msg,
                    error.response.status,
                    error.response.data.errors
                );
                yield put(apiError(message));
                return;
            }
        }
        yield put(apiError('Something went wrong'));
    }
}

function* logoutUser() {
    try {
        encryptedLocalStorage.removeItem(AUTH_USER);
        encryptedLocalStorage.removeItem(TOKEN);
        encryptedLocalStorage.removeItem(USER);
        backToLogin('userlogout');
    } catch (error) {
        yield put(apiError(error));
    }
}

export function* watchUserLogin() {
    yield takeEvery(CHECK_LOGIN, loginUser);
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser);
}

function* loginSaga() {
    yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default loginSaga;
