/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, Polyline, TileLayer } from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet-gesture-handling';
import '../styles/assignRider-modal-styles.scss';

// #region utils
import { getRandomColor } from 'utils/helpers';

// #endregion utils
import { buildRiderCoordinatesBasedOnDate } from 'pages/Modules/Delivery-Management/modules/Route-Planning/utils';
import { getMapCenterCoordinatesByClient } from 'utils/maps';

// #endregion components
import RiderMarker from '../../../../../../../components/RiderMarker';
import CustomMarker from '../../../../../../../components/CustomMarker';

// #endregion constants
import { ATTRIBUTION, GOOGLE_MAPS_URL } from '../../../../../../../constants';

/**
 * This modal contains the  map grid of the route planning module
 * @param {RoutePlanningMapGridPropTypes} props
 */
function AssignedRiderMapGrid(props) {
    const {
        selectedDeliveriesMapData,
        ridersOnMap,
        setRidersOnMap,
        formData,
        setFormData,
        setDeselectedDeliveries,
        setSelectedDeliveries,
        selectedDeliveries,
        optimizedDeliveryRoutePolyline,
        ridersRoutesPolylines,
    } = props;

    const _isMounted = useRef(true);

    // map reference
    const mapRef = useRef(null);

    /** the boundaries of the markers in the map */
    const [bound, setBound] = useState([]);

    /** the initial center coordinates of the the map */
    const [centerCoordinate, setCenterCoordinate] = useState(null);

    /** the polyline for the rider route path */
    const [riderRoutePolyline, setRiderRoutePolyline] = useState([]);

    /** once deliveries are changed set the center or the boundaries of the map dynamically */
    useEffect(() => {
        if (selectedDeliveries.length > 0) {
            const pickupMarkerCoordinates = selectedDeliveries.map((order) => [
                order.pickupLocationMeta.coordinates.lat,
                order.pickupLocationMeta.coordinates.lng,
            ]);
            const deliveryMarkerCoordinates = selectedDeliveries.map((order) => [
                order.deliveryAddressMeta.coordinates.lat,
                order.deliveryAddressMeta.coordinates.lng,
            ]);
            const coordinateArray = pickupMarkerCoordinates.concat(deliveryMarkerCoordinates);
            // if user select the rider in rider assign modal, this suppose to all delivery locations and rider location in one window
            if (formData.riderId) {
                const rider = ridersOnMap[0];
                const { riderCurrentCoordinate } = buildRiderCoordinatesBasedOnDate(
                    rider,
                    formData?.plannedDeliveryDate
                );
                coordinateArray.push(riderCurrentCoordinate);
            }
            // create a array of coordinates of the selectedDeliveries

            const bounds = L.latLngBounds(coordinateArray);
            setBound(bounds);
        }
    }, [selectedDeliveries, formData.riderId]);

    // once we set the initial bound based on the delivery, every time we did something on map it automatically reset back to the initial bound
    // this useEffect avoid it
    useEffect(() => {
        if (mapRef.current && bound) {
            mapRef.current.fitBounds(bound);
        }
    }, [bound]);

    // this GestureHandling to enables the zoom facility with using the cntrl + scroll
    useEffect(() => {
        L.Map.addInitHook('addHandler', 'gestureHandling', L.GestureHandling);
    }, []);

    /** this useEffect triggers only initial rendering, once this runs this set the center coordinates of the map
     * Based on the users current location */
    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (position && _isMounted.current) {
                        setCenterCoordinate([position.coords.latitude, position.coords.longitude]);
                    }
                },
                (error) => {
                    console.log(error);
                    updateCenterCoordinatesByClient();
                }
            );
        } else {
            updateCenterCoordinatesByClient();
            console.log('GEO Not Available');
        }
        return () => {
            _isMounted.current = false;
        };
    }, []);

    function updateCenterCoordinatesByClient() {
        const coords = getMapCenterCoordinatesByClient();
        if (coords) {
            setCenterCoordinate(coords);
        }
    }

    const customDestinationIcon = L.divIcon({
        className: 'destination-on-map',
        html: ReactDOMServer.renderToString(
            // Below conditionally apply scss stylings for different marker types
            <span className="destination-on-map__marker">
                <h7 className="text-center text-white">B</h7>
            </span>
        ),
    });

    return (
        <>
            <div className="assign-rider__modal__map-grid">
                {/* Main map component */}
                <MapContainer
                    center={centerCoordinate}
                    zoom={11}
                    className="assign-rider__modal__map-grid__map-container"
                    scrollWheelZoom={false}
                    whenCreated={(mapInstance) => {
                        mapRef.current = mapInstance;
                    }}
                    gestureHandling
                >
                    {selectedDeliveriesMapData?.pickupMappedObjectArray?.map((arrayElement) => (
                        <CustomMarker
                            key={arrayElement[0]._id}
                            mappedOrderArray={arrayElement}
                            isPickup
                            setSelectedOrders={setSelectedDeliveries}
                            setDeselectedOrders={setDeselectedDeliveries}
                            showRemove
                        />
                    ))}
                    {selectedDeliveriesMapData.deliveryMappedObjectArray.map((arrayElement) => (
                        <CustomMarker
                            key={arrayElement[0]._id}
                            mappedOrderArray={arrayElement}
                            isPickup={false}
                            setSelectedOrders={setSelectedDeliveries}
                            setDeselectedOrders={setDeselectedDeliveries}
                            showRemove
                        />
                    ))}
                    {ridersOnMap.map((arrayElement) => (
                        <RiderMarker
                            key={arrayElement._id}
                            formData={formData}
                            setFormData={setFormData}
                            setRidersOnMap={setRidersOnMap}
                            rider={arrayElement}
                            isTooltipOpen={formData.isShowRiderDetails}
                            deliveryDate={formData?.plannedDeliveryDate}
                            isConsiderDate
                            setRiderRoutePolyline={setRiderRoutePolyline}
                            isLimitedToAssignRiderModel
                        />
                    ))}
                    <TileLayer attribution={ATTRIBUTION} url={GOOGLE_MAPS_URL} />
                    {/* polyline for riders route paths */}
                    {ridersRoutesPolylines?.map((item) => (
                        <>
                            <Polyline positions={item} weight={3} color={getRandomColor([])} />
                            <Marker position={item[item.length - 1]} icon={customDestinationIcon}></Marker>
                        </>
                    ))}
                    {/* polyline for optimized delivery route */}
                    <Polyline positions={optimizedDeliveryRoutePolyline} weight={3} />
                    {/* polyline for the selected rider */}
                    {riderRoutePolyline?.map((item) => (
                        <>
                            <Polyline positions={item} weight={3} color="#013220" />
                            <Marker position={item[item.length - 1]} icon={customDestinationIcon}></Marker>
                        </>
                    ))}
                </MapContainer>
            </div>
        </>
    );
}

AssignedRiderMapGrid.propTypes = {
    selectedDeliveriesMapData: PropTypes.any,
    selectedDeliveries: PropTypes.array,
    ridersOnMap: PropTypes.array,
    setRidersOnMap: PropTypes.func,
    setDeselectedDeliveries: PropTypes.func,
    setSelectedDeliveries: PropTypes.func,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    optimizedDeliveryRoutePolyline: PropTypes.any,
    ridersRoutesPolylines: PropTypes.any,
};
export default AssignedRiderMapGrid;
