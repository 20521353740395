/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import moment from 'moment';
import { toast } from 'react-hot-toast';

// #region components | assets
import Breadcrumbs from 'components/Common/Breadcrumb';
import { BackdropLoader, CustomToast, Select, TableCustom } from 'components';

// #region imports
import PATHS from 'routes/paths';
import AccessDenied from 'pages/Utility/AccessDenied';

// #region services

// #region utils
import { getVisitingObject, hasAccess } from 'utils/checkAuth';
import { PERMISSIONS } from 'utils/constants';

// #endregion imports
import { SubTitle } from 'styles';
import {
    apiErrorHandler,
    formatter,
    getDateValue,
    getDefaultValueForSelect,
    reactSelectCustomStyles,
} from 'utils/helpers';
import { getMerchantsByClientIdService } from 'services/merchant.service';
import { defaultTableHeaders } from './constants';
import { generateReport } from '../services';
import { mapDocs } from './utils';

const breadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'User Management', link: PATHS.USER_PROFILE_MANAGEMENT.DEFAULT },
    { title: 'RiderCommission', link: '#' },
];

/**
 * RiderCommission Profile Page
 * @param {*} props
 * @returns {React.Component}
 */
function RiderCommissionPage() {
    const history = useHistory();
    const location = useLocation();

    // component state
    const { visitingClient } = getVisitingObject();

    const [loading] = useState(0);
    const [formLoading, setFormLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [riderCommissionMeta, setRiderCommissionMeta] = useState({
        totalDeliveries: 0,
        totalPaidAmount: 0,
        totalUnpaidAmount: 0,
        totalAmount: 0,
    });
    const [submitted] = useState(false);
    const [validated] = useState(false);
    const [merchants, setMerchants] = useState([]);

    const [backdropLoading] = useState(0);

    const [riderCommissionData, setRiderCommissionData] = useState({
        totalDocs: 0,
        totalPages: 0,
        limit: 10,
        page: 1,
        headers: defaultTableHeaders,
        docs: [],
        all: false,
        filter: null,
        sort: null,
        search: undefined,
        order: -1,
    });

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }

        // Retrieve filter values from URL params
        const urlParams = new URLSearchParams(location.search);

        if (urlParams.size > 0) {
            // get url params as an object
            const queryParams = Object.fromEntries(urlParams.entries());

            // extract the 'page', 'limit' parameter and remove it from the object
            const { page, limit, ...formParams } = queryParams;
            // populate form from query param data
            setFormData(formParams);
            setRiderCommissionData({ page, limit, ...riderCommissionData });

            const updatedRiderCommissionData = {
                ...riderCommissionData,
                page,
                limit,
            };
            const postData = {};
            postData.filters = formParams;
            fetchBillingRecords(updatedRiderCommissionData, postData);
        }
    }, []);

    /**
     * Fetch billing records according to filters
     * @param {object} filters - Billing record filters
     */
    const fetchBillingRecords = async (updatedRiderCommissionData = riderCommissionData, filters = {}) => {
        try {
            const { data } = await generateReport(updatedRiderCommissionData, filters);
            const docs = mapDocs(data, setRiderCommissionMeta);
            setRiderCommissionData({
                ...riderCommissionData,
                ...data,
                docs,
            });
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        } finally {
            setFormLoading(false);
        }
    };

    const handleGenerateReport = async () => {
        setFormLoading(true);
        const page = 1;
        const limit = 10;
        const updatedRiderCommissionData = { ...riderCommissionData, limit, page };
        setRiderCommissionData(updatedRiderCommissionData);
        const postData = {};
        postData.filters = formData;

        // Convert the tableFilterOptions object to a URL-encoded query string
        const queryString = new URLSearchParams(
            Object.entries({ page, limit, ...formData }).filter(([, value]) => value !== null) // remove pairs where value is null
        ).toString();
        // update url with filter values
        history.push({ search: queryString });

        // fetch billing records
        await fetchBillingRecords(updatedRiderCommissionData, postData);

        setFormLoading(false);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        if (id === 'fromDate' || id === 'toDate') {
            const updatedDate = moment(value).startOf('day').set({ hour: 23, minute: 59, second: 59 });
            setFormData({
                ...formData,
                [id]: updatedDate,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    };

    const handleSelectChange = (event, id) => {
        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    const handleOnClickImportRiderCommissions = () => {
        history.push({
            pathname: `${PATHS.IMPORTS.RIDER_COMMISSION_IMPORTS.DEFAULT}`,
        });
    };

    const handleEditRow = (e, row) => {
        // Open in same page
        history.push({
            pathname: `${PATHS.PAYMENTS.RIDER_COMMISSION_RECORD}`,
            search: `?type=edit&id=${row._id}`,
            state: { riderCommissionRecordParentData: row },
        });
    };

    const handleViewRow = (e, row) => {
        history.push({
            pathname: `${PATHS.PAYMENTS.RIDER_COMMISSION_RECORD}`,
            search: `?type=view`,
            state: { riderCommissionRecordParentData: row },
        });
    };

    /**
     * A callback function that is triggered when the user changes page number.
     * @param {number} pageNo - The new limit value (i.e. number of results per page).
     */
    const handlePageChange = async (pageNo) => {
        const updatedRiderCommissionData = { ...riderCommissionData, page: pageNo };
        const { page, limit } = updatedRiderCommissionData;
        setRiderCommissionData(updatedRiderCommissionData);

        // Convert the tableFilterOptions object to a URL-encoded query string
        const queryString = new URLSearchParams(
            Object.entries({ page, limit, ...formData }).filter(([, value]) => value !== null) // remove pairs where value is null
        ).toString();
        // update url with filter values
        history.push({ search: queryString });

        const postData = {};
        postData.filters = formData;
        await fetchBillingRecords(updatedRiderCommissionData, postData);
    };

    if (!hasAccess(PERMISSIONS.RIDER_COMMISSION, ['View'])) {
        return <AccessDenied pageName="Rider Commissions " />;
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />

                    <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                        <CardBody>
                            <AvForm
                                autoComplete="off"
                                className="needs-validation default-form"
                                noValidate
                                validated={validated.toString()}
                                id="riderCommissionForm"
                            >
                                <Row>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="merchantId">Merchant</Label>
                                            <Select
                                                value={getDefaultValueForSelect(getMerchantSelect(formData.merchantId))}
                                                options={merchants}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                submitted={submitted}
                                                validated={validated}
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="searchFilter">Search Filter</Label>
                                            <Select
                                                value={getDefaultValueForSelect(formData.searchFilter)}
                                                options={[
                                                    { label: 'Order Reference', value: 'Order Reference' },
                                                    { label: 'Merchant Order Number', value: 'Merchant Order Number' },
                                                    { label: 'Delivery ID', value: 'Delivery ID' },
                                                    { label: 'Recipient Name', value: 'Recipient Name' },
                                                    { label: 'Billing Reference', value: 'Billing Reference' },
                                                ]}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'searchFilter')}
                                                submitted={submitted}
                                                validated={validated}
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                    {formData.searchFilter && (
                                        <Col md="2">
                                            <FormGroup>
                                                <Label htmlFor="searcHbY">&nbsp;</Label>
                                                <AvField
                                                    name="searchBy"
                                                    placeholder="Search By"
                                                    type="text"
                                                    className="form-control"
                                                    id="searchBy"
                                                    value={formData.searchBy}
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    )}
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="fromDate">Delivery Date (FROM)</Label>
                                            <Input
                                                onChange={handleInputChange}
                                                type="datetime-local"
                                                className="form-control"
                                                value={getDateValue(formData.fromDate, 'datetime-local')}
                                                validate={{ required: { value: true } }}
                                                id="fromDate"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="toDate">Delivery Date (TO)</Label>
                                            <Input
                                                onChange={handleInputChange}
                                                type="datetime-local"
                                                className="form-control"
                                                value={getDateValue(formData.toDate, 'datetime-local')}
                                                validate={{ required: { value: true } }}
                                                id="toDate"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="riderCommissionMethod">Rider Commission Method</Label>
                                            <Select
                                                value={getDefaultValueForSelect(formData.riderCommissionMethod)}
                                                options={[
                                                    {
                                                        label: 'Fixed Per Delivery',
                                                        value: 'Fixed Per Delivery',
                                                    },
                                                    { label: 'Mileage-Based Fee', value: 'Mileage-Based Fee' },
                                                    { label: 'Commission', value: 'Commission' },
                                                ]}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'riderCommissionMethod')}
                                                submitted={submitted}
                                                validated={validated}
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <Label htmlFor="riderPaymentStatus">Payment Status</Label>
                                            <Select
                                                value={getDefaultValueForSelect(formData.riderPaymentStatus)}
                                                options={[
                                                    { label: 'Due', value: 'Due' },
                                                    { label: 'Paid', value: 'Paid' },
                                                ]}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'riderPaymentStatus')}
                                                submitted={submitted}
                                                validated={validated}
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ justifyContent: 'flex-start' }}>
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                        onClick={handleGenerateReport}
                                        disabled={formLoading}
                                    >
                                        {formLoading ? (
                                            <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                        ) : (
                                            <>
                                                <i className="fas fa-save left-icon"></i>
                                                <span className="h6">Generate</span>
                                            </>
                                        )}
                                    </motion.button>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>

                    <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                        <CardBody>
                            <Row className="justify-content-between">
                                <SubTitle
                                    style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}
                                    className="ml-3 mt-2"
                                >
                                    Summary of Rider Commissions
                                </SubTitle>
                            </Row>

                            <Row className="mt-4">
                                <Col md={2}>
                                    <h6>Total Amount</h6>
                                    <h5>
                                        <b>{`MYR ${formatter.format(riderCommissionMeta.totalAmount.toFixed(2))}`}</b>
                                    </h5>
                                </Col>
                                <Col md={2}>
                                    <h6>Total Deliveries</h6>
                                    <h5>
                                        <b>{`${riderCommissionMeta.totalDeliveries}`}</b>
                                    </h5>
                                </Col>
                                <Col md={2}>
                                    <h6>Paid Amount</h6>
                                    <h5>
                                        <b>{`MYR ${formatter.format(
                                            riderCommissionMeta.totalPaidAmount.toFixed(2)
                                        )}`}</b>
                                    </h5>
                                </Col>
                                <Col md={2}>
                                    <h6>Unpaid Amount</h6>
                                    <h5>
                                        <b>{`MYR ${formatter.format(
                                            riderCommissionMeta.totalUnpaidAmount.toFixed(2)
                                        )}`}</b>
                                    </h5>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Row>
                        {hasAccess(PERMISSIONS.MERCHANTS, ['Add']) && (
                            <div>
                                <motion.div
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="scootix-btn-radius  m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                    onClick={() => handleOnClickImportRiderCommissions()}
                                >
                                    <i className="fas fa-plus left-icon"></i>
                                    Upload Payment Details
                                </motion.div>
                            </div>
                        )}
                    </Row>

                    <Row>
                        <TableCustom
                            tableData={riderCommissionData}
                            onPageChange={handlePageChange}
                            // onFilter={handleFilter}
                            handleView={handleViewRow}
                            isExport
                            type="riderCommissionRecords"
                            exportModalHeader="Export Rider Commission Records"
                            loading={loading}
                            handleEdit={handleEditRow}
                            showEdit={hasAccess(PERMISSIONS.RIDER_COMMISSION, ['Edit'])}
                            showView={false}
                            isFilter={false}
                        />
                    </Row>
                </Container>
            </div>
            <BackdropLoader show={backdropLoading} opacity={0.7} />
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

const RiderCommissionManagementPage = hot(RiderCommissionPage);

export default connect(mapStateToProps, {})(RiderCommissionManagementPage);
