/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import '../styles/index.scss';

// #region assets | components
import CrossCircle from 'components/Icons/CrossCircle';
import CustomToast from 'components/Toast/Custom';
import { CheckCircle } from 'components/Icons';
import { BackdropLoader, InfoModal, ScootixModal, Select } from 'components';

// #region constants
import { initialScootixModalStateValues } from 'constants/modals/scootixModal.constant';

// #region services
import { getMerchantsByClientIdService } from 'services/merchant.service';

// #region imports
import PATHS from 'routes/paths';
import { DELIVERY_TYPES } from 'constants/constants';

// #region utils
import { apiErrorHandlerV2, getDefaultValueForSelect, reactSelectCustomStyles, capitalizeWords } from 'utils/helpers';
import { getVisitingObject } from 'utils/checkAuth';

// #endregion imports
import {
    getCitiesListService,
    getMerchantLocationService,
    getUnPlannedDeliveryService,
} from 'pages/Modules/Delivery-Management/modules/Route-Planning/modules/Form-View/containers/Form-Options/services';
import { buildDeliverySearchData } from 'pages/Modules/Delivery-Management/modules/Route-Planning/modules/Form-View/containers/Form-Options/utils';
import { addMoreDeliveriesToRoutePlanService } from 'services/routePlanning/routePlanning.service';
import ListTable from './containers/ListTable';
import { deliveryListInitialValues } from './containers/ListTable/constants';
import { buildAddedMoreDeliveriesData, validateRider, validateSelectedDeliveries } from './utils';

/**
 * Add unplanned deliveries modal for route planning
 * @param {func} setSelectedDeliveryTypes -  Set dropdown selected value arrays for deliveryTypes
 * @param {array} selectedUnPlannedDeliveryList - Selected values for selectedUnPlannedDeliveryList
 * @param {func} setSelectedUnPlannedDeliveryList - Set selected values for unPlannedDeliveryList
 * @param {func} toggleModal - Toggles the modal
 * @returns {JSX.Element} A React component that displays the add deliveries modal.
 */
function AddMoreDeliveriesModule(props) {
    const { routePlanData, toggleModal, reloads } = props;

    let didMount = false;

    // Get the visiting client
    const { visitingClient } = getVisitingObject();

    // Unplanned deliveries state
    const [unplannedDeliveryList, setUnplannedDeliveryList] = useState([]);
    // "selectedDeliveryData" stores checked records from the modal table
    const [selectedDeliveryData, setSelectedDeliveryData] = useState([]);
    // formatted payload for ListTable module
    const [tableData, setTableData] = useState({
        ...deliveryListInitialValues,
    });

    // search box text
    const [filterText, setFilterText] = useState('');
    // Used to store filtered records only such as searched records
    const [filteredRecords, setFilteredRecords] = useState(null);
    // Filter options
    const [filterOptions, setFilterOptions] = useState({
        merchantId: null,
        vehicleType: routePlanData?.rider?.vehicleType,
        merchantLocation: null,
        pickupCity: '',
        deliveryCity: '',
        isPickupReadyOnly: true,
    });

    /** info modal state - after assigning deliveries */
    const [infoModalData, setInfoModalData] = useState({
        title: null,
        body: '',
        type: 'routePlanningRiderAssignStatus',
        size: 'lg',
        show: false,
        data: null,
    });

    // Loading states
    const [loading, setLoading] = useState({
        merchantLoading: false,
        merchantLocationLoading: false,
        cityLoading: false,
        unplannedDeliveryLoading: false,
    });

    // Merchant state
    const [merchants, setMerchants] = useState([]);
    // Merchant location state
    const [merchantLocations, setMerchantLocations] = useState([]);
    // City state
    const [citiesList, setCitiesList] = useState([]);
    // Alert modal
    const [scootixModalState, setScootixModalState] = useState({ ...initialScootixModalStateValues });
    // Backdrop loading state
    const [backdropLoading, setBackdropLoading] = useState(0);

    /** Load Vising user */
    useEffect(() => {
        if (!didMount) {
            loadMerchants();
            loadPickupAndDeliveryCities();
            didMount = true;
        }
    }, []);

    /** This useEffect is responsible for fetching unplanned deliveries */
    useEffect(() => {
        loadUnplannedDeliveries();
    }, [filterOptions, merchants]);

    /** Set all the merchant Location to the dropdown */
    useEffect(() => {
        loadMerchantLocations();
    }, [filterOptions.merchantId]);

    /**
     * Generate payload for ListTable module by marking the selected delivery objects
     * does the same if filteredRecords if available
     */
    useEffect(() => {
        try {
            if (Array.isArray(selectedDeliveryData)) {
                const updatedSelectedDocs = tableData.docs.map((val) => {
                    const isSelectedType = selectedDeliveryData.find((delivery) => delivery._id === val._id);
                    return {
                        ...val,
                        isSelected: !!isSelectedType,
                    };
                });
                setTableData({ ...tableData, docs: updatedSelectedDocs });
                // if filteredRecords available show them in table
                if (filteredRecords) {
                    const updatedFilteredDocs = filteredRecords.map((val) => {
                        const isSelectedType = selectedDeliveryData.find((delivery) => delivery._id === val._id);
                        return {
                            ...val,
                            isSelected: !!isSelectedType,
                        };
                    });
                    setFilteredRecords(updatedFilteredDocs);
                }
            }
        } catch {
            toast.error('Something went wrong');
        }
    }, [selectedDeliveryData]);

    /** This useEffect is responsible for setting unplanned delivery data to the table */
    useEffect(() => {
        setFilteredRecords(unplannedDeliveryList);
    }, [unplannedDeliveryList]);

    /**
     * Handles the click event of the "Add Selected" button.
     * Adds the selected delivery types to the route planning form.
     * @returns {void}
     */
    const handleAddSelected = (deliveryData) => {
        if (deliveryData.length > 0) {
            // Get selected delivery array
            const isMerchantValidated = validateSelectedDeliveries(deliveryData, routePlanData);
            const isRiderValidated = validateRider(deliveryData, routePlanData);
            if (isMerchantValidated && isRiderValidated) {
                setScootixModalState({
                    ...scootixModalState,
                    show: true,
                    message: `Do you want to add deliveries?`,
                    confirmMessage: 'Yes',
                    cancelMessage: 'No',
                    onConfirm: () => {
                        handleAddMoreDeliveries(deliveryData);
                    },
                });
            } else {
                if (!isMerchantValidated) {
                    const validationFailText =
                        'Please check the current merchant or the selected merchant is Limited to their riders';
                    toast.custom((t) => <CustomToast text={validationFailText} t={t} type="error" />, {
                        position: 'top-right',
                    });
                }
                if (!isRiderValidated) {
                    const validationFailText =
                        'Current Rider is Limited to Existing Merchant, Please Add the Deliveries Relevent to the Same Merchant';
                    toast.custom((t) => <CustomToast text={validationFailText} t={t} type="error" />, {
                        position: 'top-right',
                    });
                }
            }
        } else {
            const deliveryEmptyMessage = 'Please select at least one delivery!';
            toast.custom((t) => <CustomToast text={deliveryEmptyMessage} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    const handleAddMoreDeliveries = async (deliveryData) => {
        setScootixModalState({
            show: false,
        });
        const selectedDeliveryIdArray = deliveryData.map((delivery) => delivery._id);
        const filteredArray = filteredRecords.filter((delivery) => {
            const isNotSelected = !selectedDeliveryIdArray.includes(delivery._id);
            if (isNotSelected) {
                return delivery;
            }
            return null;
        });
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const buildData = buildAddedMoreDeliveriesData(deliveryData, routePlanData);
            const { data } = await addMoreDeliveriesToRoutePlanService(routePlanData?._id, buildData);
            if (data.statusReport) {
                setInfoModalData({ ...infoModalData, show: true, data: data.statusReport });
            }
            setFilteredRecords(filteredArray);
            setSelectedDeliveryData([]);
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        setBackdropLoading((prevState) => prevState - 1);
        reloads();
        closeScootixModal();
    };

    /**
     * Handles search functionality
     * @param {*} event - Text box change event
     */
    const handleSearch = (event) => {
        const text = event.target.value;
        const searchText = text.toLowerCase();

        try {
            setFilterText(searchText);

            // filtered merchant data array of merchant objects
            const filteredDeliveryArray = unplannedDeliveryList.filter(
                (item) =>
                    item.referenceNumber.toLowerCase().indexOf(searchText) !== -1 ||
                    item.merchantOrderNo.toLowerCase().indexOf(searchText) !== -1
            );
            // if no match found for filter text return all table data
            setFilteredRecords(filteredDeliveryArray.length === 0 ? [] : filteredDeliveryArray);
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
    };

    /** Opens the delivery page in a new tab */
    const handleViewDelivery = (row) => {
        if (!row) return;
        window.open(`${PATHS.DELIVERY.FORM}?type=edit&id=${row._id}`, '_blank');
    };

    /**  Load unplanned deliveries based on filters */
    const loadUnplannedDeliveries = async () => {
        setLoading({ ...loading, unplannedDeliveryLoading: true });
        try {
            if (merchants.length > 0) {
                const deliveryTypes = [
                    { value: DELIVERY_TYPES.NEW_ORDER },
                    { value: DELIVERY_TYPES.RETURN_ORDER },
                    { value: DELIVERY_TYPES.ON_DEMAND_DELIVERY },
                ];

                // Build filter data
                // Here need to pass merchant id and merchant location id inside an array
                const queryOptions = buildDeliverySearchData(
                    filterOptions,
                    deliveryTypes,
                    filterOptions.merchantId ? [{ value: filterOptions.merchantId }] : [...merchants],
                    filterOptions.merchantLocation ? [{ value: filterOptions.merchantLocation }] : []
                );

                const { data } = await getUnPlannedDeliveryService(queryOptions);

                if (data) {
                    const mappedData = data.map((obj) => ({
                        ...obj,
                        merchantName: obj.merchant.name,
                    }));
                    setUnplannedDeliveryList(mappedData);
                    setTableData({
                        ...deliveryListInitialValues,
                        totalDocs: mappedData.length,
                        docs: mappedData,
                    });
                }
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setLoading({ ...loading, unplannedDeliveryLoading: false });
    };

    /** This function is responsible to get merchants to the filters */
    const loadMerchants = async () => {
        setLoading({ ...loading, merchantLoading: true });
        try {
            if (visitingClient && visitingClient._id) {
                const merchantList = await getMerchantsByClientIdService(visitingClient._id);
                const { data } = merchantList;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setLoading({ ...loading, merchantLoading: false });
    };

    /**  get all pickup and delivery cities by country iso code */
    const loadPickupAndDeliveryCities = async () => {
        setLoading({ ...loading, cityLoading: true });
        if (visitingClient?.countryIsoCode) {
            const { data } = await getCitiesListService(visitingClient.countryIsoCode);
            if (data) {
                const formattedCities = data.map((city) => {
                    const capitalized = capitalizeWords(city.name);
                    return { ...city, label: capitalized, value: city.name };
                });
                setCitiesList(formattedCities);
            }
        }
        setLoading({ ...loading, cityLoading: false });
    };

    /**  Get all merchant locations */
    const loadMerchantLocations = async () => {
        if (filterOptions.merchantId) {
            // To use the existing logic merchant id send as a array with one element
            const merchantId = [filterOptions.merchantId];
            const { data } = await getMerchantLocationService({ merchants: merchantId });
            if (data) {
                const updatedMerchantLocations = data.map((location) => ({
                    ...location,
                    label: `${location.merchantLocationName} - ${location.location}`,
                    value: location._id,
                }));
                setMerchantLocations(updatedMerchantLocations);
            }
        } else {
            const merchantIds = merchants.map((obj) => obj.value);
            const { data } = await getMerchantLocationService({ merchants: merchantIds });
            if (data) {
                const formattedData = data.map((x) => ({
                    ...x,
                    label: `${x.merchantLocationName} - ${x.location}`,
                    value: x._id,
                }));
                setMerchantLocations(formattedData);
            }
        }
    };

    /**  Checkbox state handler */
    const handleCheckChange = (event) => {
        const { id, checked } = event.target;
        if (checked) {
            setFilterOptions({
                ...filterOptions,
                [id]: true,
            });
        } else {
            setFilterOptions({
                ...filterOptions,
                [id]: false,
            });
        }
    };

    /**  Select change handler */
    const handleSelectChange = (event, id) => {
        if (event?.value) {
            setFilterOptions({
                ...filterOptions,
                [id]: event.value,
            });
        } else {
            setFilterOptions({
                ...filterOptions,
                [id]: null,
            });
        }
    };

    /** handle value selectors */
    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    const getMerchantLocationSelect = (_id) => {
        const relevantMerchantLocation = merchantLocations.find((x) => x._id === _id);
        if (relevantMerchantLocation) {
            return relevantMerchantLocation.label;
        }
        return null;
    };

    const getCitySelect = (cityName) => {
        const relevantCity = citiesList.find((x) => x.name === cityName);
        if (relevantCity) {
            const formattedName = capitalizeWords(relevantCity.name);
            return formattedName;
        }
        return null;
    };

    /** This is responsible for closing scootix modal */
    const closeScootixModal = () => {
        setScootixModalState({ show: false, data: null });
    };

    /** This is responsible for toggling scootix modal */
    const toggleScootixModal = () => {
        setScootixModalState({ show: !scootixModalState.show, data: null });
    };

    const toggleInfoModal = () => {
        setInfoModalData({ ...infoModalData, show: false });
    };

    return (
        <>
            <div className="font-size-14 w-100 p-0">
                <div className="modal__form-table">
                    <Row className="pl-4 pr-4 mt-4">
                        <Col sm={6} md={3}>
                            <FormGroup>
                                <Label htmlFor="merchantId">Merchant</Label>
                                <Select
                                    value={getDefaultValueForSelect(getMerchantSelect(filterOptions.merchantId))}
                                    options={merchants}
                                    styles={reactSelectCustomStyles}
                                    onChange={(event) => handleSelectChange(event, 'merchantId')}
                                    isLoading={loading.merchantLoading}
                                    isClearable
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6} md={3}>
                            <Label htmlFor="merchantLocation">Merchant Location</Label>
                            <FormGroup>
                                <Select
                                    value={getDefaultValueForSelect(
                                        getMerchantLocationSelect(filterOptions.merchantLocation)
                                    )}
                                    options={merchantLocations}
                                    styles={reactSelectCustomStyles}
                                    onChange={(event) => handleSelectChange(event, 'merchantLocation')}
                                    isClearable
                                    isLoading={loading.merchantLocationLoading}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6} md={3}>
                            <FormGroup>
                                <Label htmlFor="pickupCity">Pickup City</Label>
                                <Select
                                    value={getDefaultValueForSelect(getCitySelect(filterOptions.pickupCity))}
                                    options={citiesList}
                                    styles={reactSelectCustomStyles}
                                    onChange={(event) => handleSelectChange(event, 'pickupCity')}
                                    isClearable
                                    isLoading={loading.cityLoading}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6} md={3}>
                            <FormGroup>
                                <Label htmlFor="deliveryCity">Delivery City</Label>
                                <Select
                                    value={getDefaultValueForSelect(getCitySelect(filterOptions.deliveryCity))}
                                    options={citiesList}
                                    styles={reactSelectCustomStyles}
                                    onChange={(event) => handleSelectChange(event, 'deliveryCity')}
                                    isClearable
                                    isLoading={loading.cityLoading}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* Check boxes */}
                    <Row className="pl-4 pr-4 mt-4">
                        <Col sm={6} md={6}>
                            <FormGroup className="form-group">
                                <div className="form-check mb-3">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        onChange={handleCheckChange}
                                        checked={filterOptions.isPickupReadyOnly}
                                        id="isPickupReadyOnly"
                                    />
                                    <Label className="form-check-label" htmlFor="isPickupReadyOnly">
                                        Display Only Pick Up Ready Deliveries
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr className="divider" />
                    <Row>
                        <Col md={6}>
                            <Form className="table__custom__search-right d-lg-block p-0">
                                <div className="position-relative">
                                    <Input
                                        type="text"
                                        value={filterText}
                                        className="form-control"
                                        placeholder="Enter the Delivery ID or Merchant ID to Search"
                                        onChange={(event) => handleSearch(event)}
                                    />
                                    <span className="ri-search-line search-icon"></span>
                                </div>
                            </Form>
                        </Col>
                    </Row>

                    <ListTable
                        tableData={tableData}
                        isSearch={false}
                        selectedData={selectedDeliveryData}
                        setSelectedData={setSelectedDeliveryData}
                        allRecords={unplannedDeliveryList || null}
                        filteredRecords={filteredRecords}
                        onViewDelivery={handleViewDelivery}
                    />

                    <Row>
                        <ScootixModal toggleModal={toggleScootixModal} {...scootixModalState} />
                        {/* info model - delivery assigning details */}
                        <InfoModal toggleModal={toggleInfoModal} {...infoModalData} />
                        {/* backdrop  loading */}
                        <BackdropLoader show={backdropLoading} opacity={0.8} />

                        <Col xs="auto">
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-btn-radius secondary-btn shadow-lg "
                                style={{ borderRadius: 10 }}
                                onClick={() => handleAddSelected(selectedDeliveryData)}
                            >
                                <CheckCircle color="#fff" />
                                &nbsp;Add Selected
                            </motion.div>
                        </Col>
                        <Col xs="auto">
                            <motion.div
                                className="remove__btn form-delete-btn shadow-lg"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                style={{ borderRadius: 10 }}
                                onClick={toggleModal}
                            >
                                <CrossCircle color="#FFF" />
                                &nbsp;Close
                            </motion.div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

AddMoreDeliveriesModule.propTypes = {
    currentDeliveryDate: PropTypes.any,
    toggleModal: PropTypes.func,
    routePlanData: PropTypes.any,
    setSelectedUnPlannedDeliveryList: PropTypes.func,
    allUnPlannedDeliveryList: PropTypes.array,
    reloads: PropTypes.func,
};

export default AddMoreDeliveriesModule;
