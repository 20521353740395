import moment from 'moment';
import { privateKeyEndpoints } from 'pages/Modules/Developer/Api-Keys/constants';
import { getActionPathLabel } from 'pages/Modules/Developer/utils/apiUsage';
import { capitalizeFirstLetter } from 'utils/helpers';

/**
 *
 * @param {String} status - This is the status of the request
 * @param {*} data - This is the request data
 * @returns a series of request types
 */
export const mapDataSeriesForApiUsageOverview = (status, data) => {
    const { successRequestOverview = {}, failRequestOverview = {} } = data;

    let allOverview = {};

    if (status === 'success') {
        allOverview = successRequestOverview;
    }

    if (status === 'fail') {
        allOverview = failRequestOverview;
    }

    let series = [];

    const {
        cancelDelivery,
        createDelivery,
        getDeliveryByDeliveryId,
        getDeliveryCharges,
        // getDeliveryReadyStatusUpdate,
        getQuotation,
        getShippingLabel,
        trackDelivery,
        updateDelivery,
        bulkDeliveryCreate,
    } = privateKeyEndpoints;

    series = [
        allOverview[getQuotation.key] || 0,
        allOverview[createDelivery.key] || 0,
        allOverview[cancelDelivery.key] || 0,
        allOverview[updateDelivery.key] || 0,
        allOverview[getShippingLabel.key] || 0,
        allOverview[getDeliveryCharges.key] || 0,
        allOverview[trackDelivery.key] || 0,
        // allOverview[getDeliveryReadyStatusUpdate.key] || 0,
        allOverview[getDeliveryByDeliveryId.key] || 0,
        allOverview[bulkDeliveryCreate.key] || 0,
    ];

    return series;
};

/**
 * Following functions is responsible for returning month name for the month number
 * @param {Number} monthNumber - from 1 to 12
 * @returns the month name
 */
export const getMonthName = (monthNumber) => {
    switch (monthNumber) {
        case 1:
            return 'Jan';
        case 2:
            return 'Feb';
        case 3:
            return 'Mar';
        case 4:
            return 'Apr';
        case 5:
            return 'May';
        case 6:
            return 'Jun';
        case 7:
            return 'Jul';
        case 8:
            return 'Aug';
        case 9:
            return 'Sep';
        case 10:
            return 'Oct';
        case 11:
            return 'Nov';
        case 12:
            return 'Dec';
        default:
            return 'Inv';
    }
};

// TODO: ADD DOCS
export const mapDataSeriesForApiUsageOverviewTimely = (data) => {
    const { successData = [], failData = [], timelyGraphType = 'Hourly', fromDateTime, toDateTime } = data;
    const successSeriesArray = [];
    const failSeriesArray = [];

    const date1 = moment(fromDateTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    const date2 = moment(toDateTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ');

    if (timelyGraphType === 'Hourly') {
        const selectedHours = [];
        while (date1 <= date2) {
            selectedHours.push(date1.format('H'));
            date1.add(1, 'hours');
        }
        selectedHours.map((hour) => {
            const hourData = successData.find((x) => x.hour === Number(hour));
            if (hourData) {
                successSeriesArray.push(hourData.total);
            } else {
                successSeriesArray.push(0);
            }
            return hour;
        });

        selectedHours.map((hour) => {
            const hourData = failData.find((x) => x.hour === Number(hour));
            if (hourData) {
                failSeriesArray.push(hourData.total);
            } else {
                failSeriesArray.push(0);
            }
            return hour;
        });
    } else if (timelyGraphType === 'Daily') {
        const selectedDates = [];
        while (date1 <= date2) {
            selectedDates.push(date1.format('D'));
            date1.add(1, 'days');
        }
        selectedDates.map((day) => {
            const dayData = successData.find((x) => x.day === Number(day));
            if (dayData) {
                successSeriesArray.push(dayData.total);
            } else {
                successSeriesArray.push(0);
            }
            return day;
        });

        selectedDates.map((day) => {
            const dayData = failData.find((x) => x.day === Number(day));
            if (dayData) {
                failSeriesArray.push(dayData.total);
            } else {
                failSeriesArray.push(0);
            }
            return day;
        });
    } else if (timelyGraphType === 'Monthly') {
        const selectedMonths = [];
        while (date1 <= date2) {
            selectedMonths.push(date1.format('M'));
            date1.add(1, 'months');
        }
        selectedMonths.map((month) => {
            const monthData = successData.find((x) => x.month === Number(month));
            if (monthData) {
                successSeriesArray.push(monthData.total);
            } else {
                successSeriesArray.push(0);
            }
            return month;
        });

        selectedMonths.map((month) => {
            const monthData = failData.find((x) => x.month === Number(month));
            if (monthData) {
                failSeriesArray.push(monthData.total);
            } else {
                failSeriesArray.push(0);
            }
            return month;
        });
    }

    return { successSeriesArray, failSeriesArray };
};

export const mapApiUsageOverviewTableDocs = (docs) => {
    let allDocs = [];
    allDocs = docs.map((doc) => ({
        ...doc,
        actionPath: getActionPathLabel(doc.actionPath || ''),
        merchantOrderNo: doc.bodies?.merchantOrderNo,
        referenceNumber: doc.bodies?.referenceNumber,
        merchantReferenceNumber: doc.merchant?.referenceNumber,
        merchantName: doc.merchant?.name,
        merchantPictureUrl: doc.merchant?.merchantPictureUrl,
        status: doc.status === 'fail' ? `Failed` : capitalizeFirstLetter(doc.status),
    }));
    return allDocs;
};

export function buildRequestBody(language, data) {
    const { fullUrl, originalUrl, method, bodies } = data;

    switch (language) {
        case 'typescript':
            switch (method) {
                case 'GET':
                    return `fetch("${fullUrl || originalUrl}", {
    headers: new Headers({
        'x-api-key': '********************'
    })})
    .then(response => response.json())
        .then(data => {
            console.log(data)
        })
    .catch(error => console.error(error))
            `;

                case 'POST':
                    return `fetch("${fullUrl || originalUrl}", {
                        method: 'POST',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'x-api-key': '********************'
                        }),
                        body: ${JSON.stringify(bodies)}
                    })
                    .then(response => response.json())
                        .then(data => {
                            console.log(data)
                        })
                    .catch(error => console.error(error))`;

                case 'PATCH':
                    return `fetch("${fullUrl || originalUrl}", {
                        method: 'PATCH',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'x-api-key': '********************'
                        }),
                        body: ${JSON.stringify(bodies)}
                    })
                    .then(response => response.json())
                        .then(data => {
                            console.log(data)
                        })
                    .catch(error => console.error(error))`;

                case 'DELETE':
                    return `fetch("${fullUrl || originalUrl}", {
                        method: 'DELETE',
                        headers: new Headers({
                            'x-api-key': '********************'
                        })
                    })
                    .then(response => response.json())
                        .then(data => {
                            console.log(data)
                        })
                    .catch(error => console.error(error))`;

                case 'PUT':
                    return `fetch("${fullUrl || originalUrl}", {
                        method: 'PUT',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'x-api-key': '********************'
                        }),
                        body: ${JSON.stringify(bodies)}
                    })
                    .then(response => response.json())
                        .then(data => {
                            console.log(data)
                        })
                    .catch(error => console.error(error))`;

                default:
                    break;
            }
            break;
        default:
            break;
    }

    return '';
}

export function getSuccessPercentage(successReqCount, totalReqCount) {
    if (successReqCount && totalReqCount) {
        return `${((successReqCount / totalReqCount) * 100).toFixed(1)} %`;
    }
    return '0.0 %';
}

export function getErrorPercentage(errorReqCount, totalReqCount) {
    if (errorReqCount && totalReqCount) {
        return `${((errorReqCount / totalReqCount) * 100).toFixed(1)} %`;
    }
    return '0.0 %';
}
