/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Spinner, Label, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as qs from 'query-string';
import toast from 'react-hot-toast';
import BootstrapTable from 'react-bootstrap-table-next';
import { motion } from 'framer-motion';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

// #region imports
import Breadcrumbs from 'components/Common/Breadcrumb';
import PATHS from 'routes/paths';
import { apiErrorHandler, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { BackdropLoader, CustomToast, Select, TooltipDefault } from 'components';
import { getVisitingObject, hasAccess } from 'utils/checkAuth';
import Switch from 'react-switch';
import { PERMISSIONS } from 'utils/constants';

// #region imports
import { centerElementInnerStyles, SubTitle } from 'styles';

// #region services
import { completeOrderItemPackingService, getOrderItemsByOrderIdService } from 'services/order-items.service';
import { getMerchantsByClientIdService } from 'services/merchant.service';

// #endregion imports
import { updateOrderService } from '../services';
import { packingTaskOrderItems } from './constants';

const initialBreadcrumbItems = [
    { title: 'ScootiX', link: PATHS.HOME.DEFAULT },
    { title: 'Order List', link: PATHS.ORDER_PROCESSING.DEFAULT },
];

let initialFormValues = {
    taskStartDate: new Date(),
    merchantId: null,
    merchantName: null,
};

function PackagingFormPage(props) {
    const { visitingClient, visitingMerchant } = getVisitingObject();

    const packagingTypes = [
        {
            label: 'Irregular Package',
            value: 'Irregular Package',
        },
        {
            label: 'Box',
            value: 'Box',
        },
    ];

    const history = useHistory();

    if (visitingMerchant) {
        initialFormValues = {
            ...initialFormValues,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    // Component
    const [breadcrumbItems, setBreadCrumbItems] = useState(initialBreadcrumbItems);
    const [formLoading, setFormLoading] = useState(false);
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [orderItemsTableKey, setOrderItemsTableKey] = useState(uuid());

    // prev comp
    const [prevCompState, setPrevCompState] = useState({});

    // Form State
    const [editingMode, setEditingMode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isFormSaved, setIsFormSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);

    const [merchants, setMerchants] = useState([]);
    const [allOrderItems, setAllOrderItems] = useState([]);

    const [customOrderItemsColumns, setCustomOrderItemsColumns] = useState(packingTaskOrderItems.columns);

    useEffect(() => {
        if (!props.location) {
            history.push(PATHS.ORDER_PROCESSING.DEFAULT);
            return;
        }

        const formType = qs.parse(props.location.search);

        if (formType && formType.type && formType.type === 'new') {
            setEditingMode(false);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'New Task', link: '#' }]);
        }
        if (formType && formType.type && formType.type === 'edit') {
            const { orderParentData } = props.location.state;
            setFormData({ ...formData, ...orderParentData });
            setEditingMode(true);
            setIsFormSaved(true);
            setBreadCrumbItems([...initialBreadcrumbItems, { title: 'Packing', link: '#' }]);
        }

        const { prevCompState: prevComp = null } = props.location.state || {};
        if (prevComp) {
            setPrevCompState(prevComp);
        }
    }, [props.location]);

    // Merchant Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
    }, []);

    useEffect(() => {
        if (formData._id) {
            loadAllOrderItemsByOrder(formData._id);
        }
    }, [formData._id]);

    useEffect(() => {
        setCustomOrderItemsColumns([...customOrderItemsColumns, renderColumnButtons]);
    }, []);

    const loadAllOrderItemsByOrder = async (id) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getOrderItemsByOrderIdService(id, true);
            if (data && data.docs) {
                setAllOrderItems(data.docs);
            }
        } catch (e) {
            console.log(e);
        }
        setOrderItemsTableKey(uuid());
        setBackdropLoading((prevState) => prevState - 1);
    };

    const handleSubmit = async (event, errors) => {
        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.preventDefault();

        setFormLoading(true);
        const payload = formData;
        payload.isDispatchReady = payload.status === 'dispatchReady';
        payload.packagingCompletedAt = new Date();

        try {
            if (editingMode) {
                const { data } = await updateOrderService(payload);
                if (data) {
                    setFormData({
                        ...formData,
                        ...data,
                    });
                }
            } else {
                const { data } = await updateOrderService(payload);
                if (data) {
                    setFormData({
                        ...formData,
                        ...data,
                    });
                }
            }
            toast.custom(
                (t) => (
                    <CustomToast
                        text={`Successfully ${editingMode ? 'updated' : 'created'} Packing`}
                        t={t}
                        type="success"
                    />
                ),
                {
                    position: 'top-right',
                }
            );
            setIsFormSaved(true);
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setFormLoading(false);
        setSubmitted(false);
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        setIsFormSaved(false);

        if (id === 'merchantId') {
            const relevantMerchant = merchants.find((x) => x._id === event.value);
            if (relevantMerchant) {
                setFormData({
                    ...formData,
                    merchantName: relevantMerchant.name || '',
                    [id]: event ? event.value : null,
                });
            }
            return;
        }

        if (id === 'status') {
            const isAnyItemNotPacked = allOrderItems.some((x) => !x.isPackingCompleted);

            if (event && isAnyItemNotPacked) {
                toast.custom((t) => <CustomToast text="Complete Packing All Items" t={t} type="warning" />, {
                    position: 'top-right',
                });
                return;
            }

            setFormData({
                ...formData,
                [id]: event ? 'dispatchReady' : 'packaging',
            });
            return;
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleInputChange = (event) => {
        setIsFormSaved(false);
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleCompleteOrderItemPacking = async (row) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            await completeOrderItemPackingService({
                ...row,
                isPackingCompleted: true,
                packingCompletedAt: new Date(),
            });
            if (row.orderId) {
                await loadAllOrderItemsByOrder(row.orderId);
            }
            toast.custom((t) => <CustomToast text="Completed Packing Order Item" t={t} type="success" />, {
                position: 'top-right',
            });
        } catch (e) {
            const message = apiErrorHandler(e);
            toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                position: 'top-right',
            });
        }
        setOrderItemsTableKey(uuid());
        setBackdropLoading((prevState) => prevState - 1);
    };

    function renderCompleteOrderItem(cell, row) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {!row.isPackingCompleted && (
                    <>
                        <motion.div
                            whileHover={{ scale: 1.25 }}
                            whileTap={{ scale: 0.9 }}
                            className="w3-animate-opacity ml-3 pointer"
                            onClick={(e) => handleCompleteOrderItemPacking(row, cell)}
                        >
                            <i
                                id="complete-icon"
                                className="far fa-check-circle fa-lg"
                                aria-disabled
                                style={{ color: 'FFBF00' }}
                            />
                        </motion.div>
                        <TooltipDefault targetId="complete-icon" text="Complete Packing Order Item" />
                    </>
                )}
            </div>
        );
    }

    const renderColumnButtons = {
        dataField: 'edit',
        text: 'Complete',
        sort: false,
        formatter: renderCompleteOrderItem,
        headerAttrs: { width: 50 },
        attrs: { width: 50, className: 'picking-task-order-edit-column' },
        style: centerElementInnerStyles,
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs breadcrumbItems={breadcrumbItems} />
                    <Row style={{ justifyContent: 'flex-end', alignItems: 'center', margin: 2 }}>
                        {/* {hasAccess(PERMISSIONS.NEW_ORDER, ['View']) && ( */}
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                            onClick={() => {
                                history.push({
                                    pathname: `${PATHS.ORDER_PROCESSING.ORDER_FORM}`,
                                    search: `?type=edit`,
                                    state: { orderParentData: formData },
                                });
                            }}
                        >
                            <i className="ri-arrow-go-back-line left-icon"></i>
                            View Order
                        </motion.div>
                        {/* )} */}
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                            onClick={() => {
                                history.push({
                                    pathname: `${PATHS.ORDER_PROCESSING.DEFAULT}`,
                                    search: `?tab=${prevCompState.activeTab || '1'}`,
                                });
                            }}
                        >
                            <i className="ri-arrow-go-back-line left-icon"></i>
                            Back to Packing
                        </motion.div>
                    </Row>
                    <Card>
                        <CardBody>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="packaging-switch">
                                        <FormGroup
                                            className={`${
                                                formData.status === 'dispatchReady' ? 'switch-on' : 'switch-off'
                                            }`}
                                        >
                                            <Switch
                                                uncheckedIcon={
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: '100%',
                                                            fontSize: 12,
                                                            color: '#fff',
                                                            padding: 4,
                                                        }}
                                                    >
                                                        Pending
                                                    </div>
                                                }
                                                checkedIcon={
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: '100%',
                                                            fontSize: 12,
                                                            color: '#fff',
                                                            padding: 4,
                                                        }}
                                                    >
                                                        Completed
                                                    </div>
                                                }
                                                onColor="#FAA123"
                                                offColor="#FAA123"
                                                onChange={(event) => handleSelectChange(event, 'status')}
                                                checked={formData.status === 'dispatchReady'}
                                            />
                                        </FormGroup>
                                    </div>
                                </Row>
                                <Row style={{ justifyContent: 'space-between' }}>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label htmlFor="packagingType">Packing Type</Label>
                                            <Select
                                                value={getDefaultValueForSelect(formData.packagingType)}
                                                options={packagingTypes}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'packagingType')}
                                                submitted={submitted}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Label htmlFor="packagingType">Size of the Packages</Label>

                                        <Row>
                                            <FormGroup className="ml-3">
                                                <AvField
                                                    name="height"
                                                    placeholder="Height"
                                                    type="number"
                                                    className="form-control"
                                                    value={formData.height}
                                                    id="height"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                            <FormGroup className="ml-3">
                                                <AvField
                                                    name="width"
                                                    placeholder="Width"
                                                    type="number"
                                                    className="form-control"
                                                    value={formData.width}
                                                    id="width"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                            <FormGroup className="ml-3">
                                                <AvField
                                                    name="length"
                                                    placeholder="Length"
                                                    type="number"
                                                    errorMessage="Required"
                                                    className="form-control"
                                                    value={formData.length}
                                                    id="length"
                                                    onChange={handleInputChange}
                                                />
                                            </FormGroup>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="3">
                                        <FormGroup>
                                            <Label htmlFor="packagingType">Weight in KG</Label>
                                            <AvField
                                                name="Weight in KG"
                                                placeholder="Weight"
                                                type="number"
                                                className="form-control"
                                                value={formData.weight}
                                                id="weight"
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="3">
                                        <FormGroup>
                                            <Label htmlFor="packagingType">Remarks</Label>
                                            <AvField
                                                name="remarks"
                                                placeholder="Remarks"
                                                type="text"
                                                className="form-control"
                                                value={formData.remarks}
                                                id="remarks"
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ justifyContent: 'flex-start' }}>
                                    {hasAccess(PERMISSIONS.PACKING, ['View', 'Edit']) && (
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-4 pl-4"
                                            type="submit"
                                            disabled={formLoading}
                                        >
                                            {formLoading ? (
                                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                            ) : (
                                                <>
                                                    <i className="fas fa-save left-icon"></i>
                                                    <span className="h6">
                                                        {editingMode ? 'Update Packing' : 'Save'}
                                                    </span>
                                                </>
                                            )}
                                        </motion.button>
                                    )}
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="scootix-btn-radius secondary-btn p-2 m-2 mb-3 pr-3 pl-3 shadow-lg nw-md"
                                        onClick={() => {
                                            history.push({
                                                pathname: `${PATHS.ORDER_PROCESSING.DEFAULT}`,
                                                search: `?tab=${prevCompState.activeTab || '1'}`,
                                            });
                                        }}
                                    >
                                        <i className="far fa-times-circle mr-2 fa-lg" />
                                        Cancel
                                    </motion.div>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                    <br />

                    {editingMode && (
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <SubTitle
                                            style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}
                                            className="ml-3 mt-2"
                                        >
                                            ORDER ITEMS{' '}
                                        </SubTitle>
                                        <br />
                                        <br />
                                        <BootstrapTable
                                            keyField="_id"
                                            data={allOrderItems}
                                            columns={customOrderItemsColumns}
                                            key={orderItemsTableKey}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>

            <BackdropLoader show={backdropLoading} opacity={0.8} />
        </>
    );
}

PackagingFormPage.propTypes = {
    location: PropTypes.any,
};

const mapStateToProps = (state) => ({
    ...state,
});

const HotPackagingPage = hot(PackagingFormPage);

export default connect(mapStateToProps, {})(HotPackagingPage);
