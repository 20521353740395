/* eslint-disable react-hooks/exhaustive-deps */

import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import toast from 'react-hot-toast';

// #region components | assets
import { Select, ClientAlerts, MerchantAlerts, BackdropLoader } from 'components';

// #region imports
import paths from 'routes/paths';
import { TOAST_STYLES } from 'theme';
import { MERCHANT_SETTING_DEFAULT } from 'constants/db/merchant-setting';
import { createMerchantSettingService } from 'pages/Modules/Settings/services';

// #region services
import { getAllMerchantsService, getMerchantsByClientIdService } from 'services/merchant.service';

// #region utils
import { getDateValue, getDefaultValueForSelect, reactSelectCustomStyles } from 'utils/helpers';
import { getVisitingObject, IS_MERCHANT_VIEW } from 'utils/checkAuth';

// #region services
import { getMerchantSettingByMerchantIdService } from 'services/merchant-setting.service';

let initialSettings = {};

function ExternalSystemConnectionsForDeliveryPage() {
    const history = useHistory();

    const { visitingClient, visitingMerchant } = getVisitingObject();

    if (visitingMerchant) {
        initialSettings = {
            ...initialSettings,
            merchantId: visitingMerchant._id,
            merchantName: visitingMerchant.name,
        };
    }

    // component
    const [backdropLoading, setBackdropLoading] = useState(0);
    const [merchants, setMerchants] = useState([]);

    // form
    const [merchantSetting, setMerchantSetting] = useState(initialSettings);

    const [selectedMerchantId, setSelectedMerchantId] = useState(null);

    useEffect(() => {
        if (selectedMerchantId) {
            setSelectedMerchantId(selectedMerchantId);
            loadMerchantSettings(selectedMerchantId);
        }
    }, [selectedMerchantId]);

    // Merchant Load Handlers
    useEffect(() => {
        if (visitingClient && visitingClient._id) {
            getMerchantsByClientIdService(visitingClient._id).then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        } else {
            getAllMerchantsService().then((res) => {
                const { data } = res;
                if (data.docs) {
                    setMerchants(data.docs.map((x) => ({ ...x, label: x.name, value: x._id })));
                }
            });
        }
        if (visitingMerchant && visitingMerchant._id) {
            setSelectedMerchantId(visitingMerchant._id);
        }
    }, []);

    const loadMerchantSettings = async (id) => {
        setBackdropLoading((prevState) => prevState + 1);
        try {
            const { data } = await getMerchantSettingByMerchantIdService(id);
            if (data) {
                setMerchantSetting({ ...merchantSetting, ...data });
            } else {
                const payload = {
                    merchantId: id,
                    merchantName: merchantSetting.merchantName || '',
                    settings: { ...MERCHANT_SETTING_DEFAULT },
                };
                const response = await createMerchantSettingService(payload);
                if (response && response.data) {
                    setMerchantSetting({ ...merchantSetting, ...response.data });
                    toast.success(`Created New Merchant Settings`, TOAST_STYLES.SUCCESS);
                }
            }
        } catch (e) {
            console.log(e);
        }
        setBackdropLoading((prevState) => prevState - 1);
    };

    /**
     * Handle Select Change
     */
    const handleSelectChange = (event, id) => {
        if (id === 'merchantId') {
            const relevantMerchant = merchants.find((x) => x._id === event.value);
            if (relevantMerchant) {
                setSelectedMerchantId(event.value);
                setMerchantSetting({
                    ...merchantSetting,
                    merchantName: relevantMerchant.name || '',
                    [id]: event.value,
                });
            }
        }
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setMerchantSetting({
            ...merchantSetting,
            [id]: value,
        });
    };

    const getMerchantSelect = (_id) => {
        const relevantMerchant = merchants.find((x) => x._id === _id);
        if (relevantMerchant) {
            return relevantMerchant.name;
        }
        return null;
    };

    return (
        <>
            <div className={`page-content ${backdropLoading > 0 && 'hide-scroll'}`}>
                <AvForm className="needs-validation" noValidate id="orderForm">
                    <>
                        <br />
                        {!merchantSetting.merchantId && <MerchantAlerts />}
                        <ClientAlerts />
                    </>
                    <fieldset disabled={false}>
                        <Card style={{ borderRadius: 20 }}>
                            <CardBody style={{ padding: 40 }}>
                                <Row className="ml-2">
                                    <h4 className="card-title">Select Merchant</h4>
                                    <Col md="3" style={{ marginTop: -10 }}>
                                        <FormGroup>
                                            <Select
                                                value={getDefaultValueForSelect(getMerchantSelect(selectedMerchantId))}
                                                options={merchants}
                                                styles={reactSelectCustomStyles}
                                                onChange={(event) => handleSelectChange(event, 'merchantId')}
                                                required
                                                isDisabled={IS_MERCHANT_VIEW()}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <br />
                        <Card style={{ borderRadius: 15, marginTop: -10 }}>
                            <CardBody style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                <div
                                    style={{
                                        backgroundColor: '#FFEEEE',
                                        borderTopRightRadius: 15,
                                        borderTopLeftRadius: 15,
                                        minHeight: 20,
                                    }}
                                >
                                    {merchantSetting._id && merchantSetting.updatedBy && (
                                        <div style={{ padding: 10, paddingLeft: 20, color: '#807F7D', fontSize: 16 }}>
                                            {`This setting was created by ${
                                                merchantSetting.createdBy.fullName
                                            } at ${getDateValue(merchantSetting.createdAt, 'datetime')} and
                                                Last Updated by ${merchantSetting.createdBy.fullName} at ${getDateValue(
                                                merchantSetting.updatedAt,
                                                'datetime'
                                            )}`}
                                        </div>
                                    )}
                                </div>
                                <br />
                                {merchantSetting.merchantId && (
                                    <>
                                        <div className="form-card m-4 p-5">
                                            <Row
                                                style={{
                                                    padding: 2,

                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <h4 className="" style={{ fontWeight: 'bold', color: '#807F7D' }}>
                                                    Excel/CSV Import
                                                </h4>

                                                <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="scootix-btn-radius  shadow-lg nw-md pr-5 pl-5"
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: `${paths.SETTINGS.DELIVERY.EXCEL_CSV_IMPORT}`,
                                                            search: `?merchantId=${selectedMerchantId}&name=${
                                                                merchantSetting.merchantName || ''
                                                            }`,
                                                        });
                                                    }}
                                                >
                                                    <i className="fas fa-cog left-icon fa-2x"></i>
                                                    <span style={{ fontSize: 16 }}> Configure</span>
                                                </motion.div>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="availability">Availability</Label>
                                                        <AvField
                                                            name="availability"
                                                            placeholder="Availability"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            disabled
                                                            id="reference"
                                                            value={merchantSetting.availability}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="fileType">File Type</Label>
                                                        <AvField
                                                            name="fileType"
                                                            placeholder="File Type"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            disabled
                                                            id="fileType"
                                                            value={merchantSetting.fileType}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <br />
                                        <div className="form-card m-4 p-5">
                                            <Row
                                                style={{
                                                    padding: 2,

                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <h4 className="" style={{ fontWeight: 'bold', color: '#807F7D' }}>
                                                    Webhook API
                                                </h4>
                                                <div className="d-flex row">
                                                    <motion.div
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        className="scootix-btn-radius  shadow-lg nw-md pr-5 pl-5"
                                                        onClick={() => {
                                                            history.push({
                                                                pathname: `${paths.SETTINGS.DELIVERY.EXCEL_CSV_IMPORT}`,
                                                                search: `?merchantId=${selectedMerchantId}`,
                                                            });
                                                        }}
                                                    >
                                                        <i className="fas fa-shield-alt left-icon fa-2x" />
                                                        <span style={{ fontSize: 16 }}> API Keys</span>
                                                    </motion.div>
                                                    <motion.div
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        className="scootix-btn-radius ml-3 shadow-lg nw-md pr-5 pl-5"
                                                        onClick={() => {
                                                            history.push({
                                                                pathname: `${paths.SETTINGS.DELIVERY.EXCEL_CSV_IMPORT}`,
                                                                search: `?merchantId=${selectedMerchantId}`,
                                                            });
                                                        }}
                                                    >
                                                        <i className="fas fa-cog left-icon fa-2x"></i>
                                                        <span style={{ fontSize: 16 }}> Configure</span>
                                                    </motion.div>
                                                </div>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="availability">Availability</Label>
                                                        <AvField
                                                            name="availability"
                                                            placeholder="Availability"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            disabled
                                                            id="reference"
                                                            value={merchantSetting.availability}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <br />
                                        <div className="form-card m-4 p-5">
                                            <Row
                                                style={{
                                                    padding: 2,

                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <h4 className="" style={{ fontWeight: 'bold', color: '#807F7D' }}>
                                                    Sync to ScootiX
                                                </h4>
                                                <div className="d-flex row">
                                                    <motion.div
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        className="scootix-btn-radius  shadow-lg nw-md pr-5 pl-5"
                                                        onClick={() => {
                                                            history.push({
                                                                pathname: `${paths.SETTINGS.DELIVERY.EXCEL_CSV_IMPORT}`,
                                                                search: `?merchantId=${selectedMerchantId}`,
                                                            });
                                                        }}
                                                    >
                                                        <i className="fas fa-shield-alt left-icon fa-2x" />
                                                        <span style={{ fontSize: 16 }}> API Keys</span>
                                                    </motion.div>
                                                    <motion.div
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        className="scootix-btn-radius ml-3 shadow-lg nw-md pr-5 pl-5"
                                                        onClick={() => {
                                                            history.push({
                                                                pathname: `${paths.SETTINGS.DELIVERY.EXCEL_CSV_IMPORT}`,
                                                                search: `?merchantId=${selectedMerchantId}`,
                                                            });
                                                        }}
                                                    >
                                                        <i className="fas fa-cog left-icon fa-2x"></i>
                                                        <span style={{ fontSize: 16 }}> Configure</span>
                                                    </motion.div>
                                                </div>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="availability">Availability</Label>
                                                        <AvField
                                                            name="availability"
                                                            placeholder="Availability"
                                                            type="text"
                                                            errorMessage="Required"
                                                            className="form-control"
                                                            disabled
                                                            id="reference"
                                                            value={merchantSetting.availability}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3">
                                                    <FormGroup>
                                                        <Label htmlFor="interval">Interval</Label>
                                                        <Row style={{ marginLeft: 0 }}>
                                                            <AvField
                                                                name="interval"
                                                                placeholder="Interval"
                                                                type="text"
                                                                errorMessage="Required"
                                                                className="form-control"
                                                                disabled
                                                                id="interval"
                                                                value={merchantSetting.interval}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Label htmlFor="interval" className="mt-2 ml-2">
                                                                Interval
                                                            </Label>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                )}
                            </CardBody>
                        </Card>
                    </fieldset>
                </AvForm>

                <BackdropLoader show={backdropLoading} opacity={0.8} />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    ...state,
});

ExternalSystemConnectionsForDeliveryPage.propTypes = {};

const HotExternalSystemConnectionsForDeliveryPage = hot(ExternalSystemConnectionsForDeliveryPage);

export default connect(mapStateToProps, {})(HotExternalSystemConnectionsForDeliveryPage);
