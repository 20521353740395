import _ from 'lodash';
import {
    GET_ROUTE_PLANNING_IDS,
    GET_ROUTE_PLANNING_LIST_DATA,
    GET_ROUTE_PLANNING_DATA_BY_ID,
    CANCEL_ROUTE_PLANNING_ENTRY_BY_ID,
    UPDATE_DELIVERY_DATE_BY_ROUTE_PLANNING_ENTRY_ID,
    UPDATE_RIDER_BY_ROUTE_PLANNING_ENTRY_ID,
    ADD_DELIVERIES_TO_ROUTE_PLAN,
    ASSIGNING_ROUTE_PLAN,
    GENERATE_ROUTE_PLAN,
    GENERATE_RIDERS_ROUTES,
    DETACH_DELIVERY_FROM_ROUTE_PLAN,
    GENERATE_RIDER_ROUTES,
} from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Route Planning Ids
 * @returns {Array}
 */
export const getRoutePlanningIds = ({ page = 1, limit = 1, search, all, tableFilterOptions }) => {
    const buildQuery = {
        page,
        limit,
        search,
        all,
    };

    if (search) {
        buildQuery.searchText = search;
    }

    if (tableFilterOptions?.merchantId) {
        buildQuery.merchantId = tableFilterOptions?.merchantId;
    }

    const url = UrlBuilder().setUrl(GET_ROUTE_PLANNING_IDS).setQueryParams(buildQuery).build();
    return instance.get(url);
};

/**
 * Get Route Planning List Data
 * @returns {Array}
 */
export const getRoutePlanningListData = (params = {}) => {
    const {
        routePlanId,
        clientId,
        merchantId,
        riderId,
        deliveryDate,
        completedEntries,
        pendingEntries,
        cancelledEntries,
        page,
        limit,
        sort,
        order = -1,
    } = params;
    const queryParams = _.pickBy({
        id: routePlanId,
        clientId,
        merchantId,
        riderId,
        deliveryDate,
        completedEntries,
        pendingEntries,
        cancelledEntries,
        page,
        limit,
        sort: sort ? Object.keys(sort)[0] : 'createdAt',
        order,
    });
    const url = UrlBuilder()
        .setUrl(GET_ROUTE_PLANNING_LIST_DATA)
        .setQueryParams({ ...queryParams })
        .build();
    return instance.get(url);
};

/**
 * This builds url for getting Route Planning data by id
 * @returns {Array}
 */
export const getRoutePlanningDataById = (id) => {
    const url = UrlBuilder().setUrl(GET_ROUTE_PLANNING_DATA_BY_ID).setPathParams({ id }).build();
    return instance.get(url);
};

/**
 * This cancel the route planning entry by id
 */
export const cancelRoutePlanningEntryById = (id, data) => {
    const url = UrlBuilder().setUrl(CANCEL_ROUTE_PLANNING_ENTRY_BY_ID).setPathParams({ id }).build();
    return instance.patch(url, data);
};

/**
 * This builds url to update the delivery date of the route planning entry and relevant deliveries
 */
export const updateDeliveryDateByRoutePlanningEntryId = (id, data) => {
    const url = UrlBuilder().setUrl(UPDATE_DELIVERY_DATE_BY_ROUTE_PLANNING_ENTRY_ID).setPathParams({ id }).build();
    return instance.patch(url, data);
};

/**
 * This builds url to update the rider of the route planning entry and relevant deliveries
 */
export const updateRiderByRoutePlanningEntryId = (id, data) => {
    const url = UrlBuilder().setUrl(UPDATE_RIDER_BY_ROUTE_PLANNING_ENTRY_ID).setPathParams({ id }).build();
    return instance.patch(url, data);
};

/**
 * Update the route plan by adding more deliveries
 */
export const addMoreDeliveriesToRoutePlanService = (id, data) => {
    const url = UrlBuilder().setUrl(ADD_DELIVERIES_TO_ROUTE_PLAN).setPathParams({ id }).build();
    return instance.post(url, data);
};

/**
 * Assigning route plan to the rider
 */
export const assigningRoutPlanService = (data) => {
    const url = UrlBuilder().setUrl(ASSIGNING_ROUTE_PLAN).build();
    return instance.post(url, data);
};

/**
 * Generate route plan service based on riderId and deliveries
 */
export const generatesRoutPlanService = (data) => {
    const url = UrlBuilder().setUrl(GENERATE_ROUTE_PLAN).build();
    return instance.post(url, data);
};

/**
 * Generate riders route service
 */
export const generatesRidersPathService = (data) => {
    const url = UrlBuilder().setUrl(GENERATE_RIDERS_ROUTES).build();
    return instance.post(url, data);
};

/**
 * This builds url to detach delivery of the route planning entry
 * @param id - Route planning id
 * @param data - Delivery id and whether to unassign rider
 */
export const detachDeliveryFromRoutePlan = (id, data) => {
    const url = UrlBuilder().setUrl(DETACH_DELIVERY_FROM_ROUTE_PLAN).setPathParams({ id }).build();
    return instance.patch(url, data);
};

/**
 * Generate rider route path
 */
export const generatesRiderPathService = (data) => {
    const url = UrlBuilder().setUrl(GENERATE_RIDER_ROUTES).build();
    return instance.post(url, data);
};
