import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { motion } from 'framer-motion';

// #region imports
import { centerElementContainerStyles, centerElementInnerStyles } from 'styles';
import { reactSelectCustomStyles } from 'utils/helpers';
import { Select } from 'components';

function BinLocationModal(props) {
    const {
        toggleModal,
        show = false,
        title = null,
        message = 'Select Bin Location',
        onConfirm = null,
        confirmMessage = 'Ok',
        binLocations = [],
        activeRow,
    } = props;

    const [selectedValue, setSelectedValue] = useState(null);

    const getSelectValue = (_id) => {
        if (activeRow) {
            const relevantObj = binLocations.find((x) => x.value === _id);
            if (relevantObj) {
                return { label: relevantObj.label, value: relevantObj.value };
            }
            return null;
        }
        return null;
    };

    return (
        <>
            <div className="custom-scootix-modal">
                {show && (
                    <Modal
                        size="lg"
                        isOpen
                        center
                        toggle={toggleModal}
                        style={{ borderRadius: 15 }}
                        className="modal-dialog modal-dialog-centered scootix-confirmation-modal"
                    >
                        <ModalHeader
                            className="scootix-bg-radiant"
                            style={{
                                marginTop: -1,
                                marginRight: -1,
                                marginLeft: -1,
                                color: '#FFF',
                                borderTopRightRadius: 15,
                                borderTopLeftRadius: 15,
                            }}
                        >
                            <h4
                                style={{
                                    color: '#FFF',
                                }}
                            >
                                {' '}
                                {title || 'Confirmation'}{' '}
                            </h4>
                        </ModalHeader>
                        <ModalBody
                            style={{
                                flexDirection: 'column',
                                borderRadius: 15,
                                backgroundColor: '#FAFAFA',
                                ...centerElementContainerStyles,
                            }}
                            className="p-3"
                        >
                            <br />
                            <div>{binLocations.length > 0 && <h5>{message}</h5>}</div>
                            <br />
                            <Col md={6}>
                                {binLocations.length > 0 ? (
                                    <Select
                                        value={getSelectValue(selectedValue || activeRow.binLocationId)}
                                        options={binLocations}
                                        styles={reactSelectCustomStyles}
                                        onChange={(event) => setSelectedValue(event.value)}
                                        required
                                    />
                                ) : (
                                    <h4 style={{ textAlign: 'center' }}>No Bin Locations Found</h4>
                                )}
                            </Col>

                            <br />
                            <div className="row">
                                <motion.div
                                    className="scootix-bg-radiant ml-2 btn"
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    style={{
                                        minWidth: 120,
                                        padding: 17,
                                        paddingRight: 24,
                                        paddingLeft: 24,
                                        borderRadius: 20,
                                        textAlign: 'center',
                                        ...centerElementInnerStyles,
                                    }}
                                    // eslint-disable-next-line consistent-return
                                    onClick={() => {
                                        if (binLocations.length === 0) {
                                            return toggleModal();
                                        }
                                        if (onConfirm) {
                                            onConfirm(selectedValue);
                                        }
                                    }}
                                >
                                    <span className="h6" style={{ color: '#FFF', fontWeight: 'bold', fontSize: 18 }}>
                                        {confirmMessage}
                                    </span>
                                </motion.div>
                            </div>
                        </ModalBody>
                    </Modal>
                )}
            </div>
        </>
    );
}

BinLocationModal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    toggleModal: PropTypes.func,
    confirmMessage: PropTypes.string,
    binLocations: PropTypes.array,
    activeRow: PropTypes.any,
};

export default BinLocationModal;
