/* eslint-disable indent */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { motion } from 'framer-motion';

// #region components | assets
import { AttachmentAndNoteModal } from 'components';
import { NotesIcon, DeliveryAttachmentImageIcon, DeliveryAttachmentArchiveIcon } from 'components/Icons';

// #region utils
import { __BLOCKED_FOR_MERCHANTS__ } from 'utils/checks';

// #endregion imports
import AttachmentTable from '../../components/AttachmentTable';

/**
 * Add Delivery Attachment and Notes container.
 *
 * @type {React.FC<InferProps<import("./propTypes").DeliveryFormProps>>} <-- passing delivery From data
 * @returns {React.ReactElement} <-- delivery attachment table and button set
 */
function AttachmentsAndNotes(props) {
    const { data, merchantOrderNo, deliveryId, riderId, setIsViewMode, deliveryAttachments, loadAttachments } = props;

    // model
    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState('');

    const toggleModal = (id) => {
        setShowModal(!showModal);
        setType(id);
    };

    const attachmentTableCommonProps = {
        deliveryId,
        riderId,
        merchantOrderNo,
        toggleModal,
        setShowModal,
        setType,
        showModal,
        loadAttachments,
    };

    return (
        <div className="mt-2">
            <Row>
                {!__BLOCKED_FOR_MERCHANTS__ && (
                    <div style={{ display: 'flex', margin: '5px 12px 5px auto' }}>
                        <div>
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-2 pl-2 pt-2 pb-2"
                                style={{ borderRadius: 10 }}
                                onClick={() => {
                                    toggleModal('addNote');
                                }}
                            >
                                <NotesIcon /> <span style={{ fontSize: '12px', fontWeight: '700' }}>Add Notes</span>
                            </motion.button>
                        </div>
                        <div>
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-2 pl-2 pt-2 pb-2"
                                style={{ borderRadius: 10 }}
                                onClick={() => {
                                    toggleModal('addImage');
                                }}
                            >
                                <DeliveryAttachmentImageIcon />{' '}
                                <span style={{ fontSize: '12px', fontWeight: '700' }}>Add Image</span>
                            </motion.button>
                        </div>
                        <div>
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="scootix-form-btn m-2 mb-3 shadow-lg nw-md pr-2 pl-2 pt-2 pb-2"
                                style={{ borderRadius: 10, background: '#D9D9D9' }}
                                onClick={() => {
                                    toggleModal('archive');
                                }}
                            >
                                <DeliveryAttachmentArchiveIcon />{' '}
                            </motion.button>
                        </div>
                    </div>
                )}
            </Row>
            <Col>
                <AttachmentTable
                    deliveryAttachments={deliveryAttachments}
                    data={data}
                    {...attachmentTableCommonProps}
                />
            </Col>

            {/* Transaction modal form */}
            {showModal && (
                <AttachmentAndNoteModal
                    data={data}
                    toggleModal={toggleModal}
                    setShowModal={setShowModal}
                    type={type}
                    show={showModal}
                    merchantOrderNo={merchantOrderNo}
                    deliveryId={deliveryId}
                    riderId={riderId}
                    setIsViewMode={setIsViewMode}
                    deliveryAttachments={deliveryAttachments}
                    loadAttachments={loadAttachments}
                />
            )}
        </div>
    );
}

AttachmentsAndNotes.propTypes = {
    data: PropTypes.any,
    merchantOrderNo: PropTypes.any,
    deliveryId: PropTypes.any,
    riderId: PropTypes.any,
    setIsViewMode: PropTypes.func,
    deliveryAttachments: PropTypes.any,
    loadAttachments: PropTypes.func,
};

export default AttachmentsAndNotes;
