/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import { Col, Label, Row, Spinner } from 'reactstrap';
import { THEME_COLOR_2 } from 'theme';
import { AvField, AvForm } from 'availity-reactstrap-validation';

// #region components
import { Select, CustomToast, PlacesAutocompleteField } from 'components';
import { updateOtherInfoDeliveryService } from 'pages/Modules/Delivery-Management/services';

// #region other imports
import { TASKS, VEHICLE_TYPES } from 'constants/constants';

// #region services
import { getRiderListData } from 'services/rider.service';

// #region utils
import { apiErrorHandlerV2, getDefaultValueForSelect } from 'utils/helpers';

// #endregion imports
import { buildLocationDataForMoreOptions, confirmValidateForm } from '../utils';

function MoreOptions(props) {
    const { data: deliveryData, setShowModal, loadTransactions, setDeliveryFormData, updateHistoryWithData } = props;

    const { merchantOrderNo, referenceNumber, _id } = deliveryData;

    const initialFormValues = {
        task: '',
        vehicleType: '',
        riderId: null,
        reason: '',
    };

    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formData, setFormData] = useState(initialFormValues);

    const [riderListData, setRiderListData] = useState({ docs: [] });

    useEffect(() => {
        loadRiderListData({ ...formData, showLoading: true });
    }, [formData.vehicleType]);

    const handleSelectChange = (event, id) => {
        if (id === 'confirmation' && event) {
            setFormData({
                ...formData,
                [id]: event ? event.target.value : null,
            });
            return;
        }
        if (id === 'reason' && event) {
            setFormData({
                ...formData,
                [id]: event ? event.target.value : null,
            });
            return;
        }
        if (id === 'pickupLocation' && event) {
            setFormData({
                ...formData,
                [id]: event ? event.value : null,
            });
            return;
        }
        if (id === 'vehicleType' && event) {
            setFormData({
                ...formData,
                [id]: event ? event.value : null,
                riderId: null,
            });
            return;
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    /**
     * Load Delivery List Data
     * @param {*} args
     */
    const loadRiderListData = (args) => {
        getRiderListData({
            ...args,
            limit: 100,
        }).then((res) => {
            const { data } = res;
            delete data.sort;
            setRiderListData({ ...data });
        });
    };

    const handleSubmit = async (event, errors = []) => {
        let updatedPayloadData;

        setSubmitted(true);
        if (errors.length > 0) {
            setValidated(true);
            return;
        }
        event.persist();

        const { isFormValid, message } = confirmValidateForm(formData);
        if (!isFormValid) {
            toast.custom((t) => <CustomToast text={message} t={t} type="warning" />, {
                position: 'top-right',
            });
            return;
        }
        const payload = { ...formData, deliveryId: _id };

        setFormLoading(true);
        let isSuccess = false;

        try {
            const { data } = await updateOtherInfoDeliveryService(payload);
            if (data) {
                setFormData({ ...payload, ...data });
                updatedPayloadData = { ...deliveryData, ...data };
                setDeliveryFormData({ ...deliveryData, ...data });
                isSuccess = true;
                toast.custom(
                    (t) => <CustomToast text="Successfully Updated Other Information" t={t} type="success" />,
                    {
                        position: 'top-right',
                    }
                );
            }
        } catch (e) {
            const { message: exception } = apiErrorHandlerV2(e);
            toast.custom((t) => <CustomToast text={exception} t={t} type="error" />, {
                position: 'top-right',
            });
        }

        if (updatedPayloadData) {
            updateHistoryWithData({ ...deliveryData, ...updatedPayloadData });
        }

        loadTransactions();
        setFormLoading(false);
        setValidated(false);
        setSubmitted(false);

        if (isSuccess) {
            setShowModal(false);
        }
    };

    const returnLocationData = (id, idMeta, data) => {
        const builtData = buildLocationDataForMoreOptions(id, idMeta, data);
        setFormData({ ...formData, ...builtData });
    };

    const relevantRidersList = (vehicle) => {
        const ridersList = riderListData.docs.filter((x) => x.vehicleType === vehicle);

        const relevantRider = ridersList.map((riders) => {
            if (riders) {
                return { label: riders.fullName || '', value: riders._id };
            }
            return null;
        });
        return relevantRider;
    };

    const getRiderSelect = (id) => {
        // eslint-disable-next-line no-console
        if (id) {
            const relevantObj = riderListData.docs.find((x) => x._id === id);
            if (relevantObj) {
                return { label: relevantObj.fullName, value: relevantObj._id };
            }
        }
        return null;
    };

    const selectedRider = useMemo(() => getRiderSelect(formData.riderId), [formData.riderId, riderListData]);

    return (
        <div className="font-size-14 w-100 p-0">
            <div
                className="font-size-16 text-white"
                style={{ backgroundColor: THEME_COLOR_2, padding: 15, borderRadius: '5px 5px 0 0' }}
            >
                More Options - {merchantOrderNo} | {referenceNumber}{' '}
            </div>
            <div className="modal__form-table">
                <AvForm onSubmit={handleSubmit}>
                    <div className="modal_form-item">
                        <Label htmlFor="task">Task</Label>
                        <Select
                            value={getDefaultValueForSelect(formData.task)}
                            options={[
                                {
                                    label: TASKS.UPDATE_THE_PICKUP_LOCATION,
                                    value: TASKS.UPDATE_THE_PICKUP_LOCATION,
                                },
                                {
                                    label: TASKS.UPDATE_THE_DELIVERY_LOCATION,
                                    value: TASKS.UPDATE_THE_DELIVERY_LOCATION,
                                },
                                {
                                    label: TASKS.UPDATE_THE_VEHICLE_TYPE,
                                    value: TASKS.UPDATE_THE_VEHICLE_TYPE,
                                },
                            ]}
                            id="task"
                            onChange={(event) => handleSelectChange(event, 'task')}
                            submitted={submitted}
                            validated={validated}
                        />
                    </div>

                    {formData.task === 'Update the Pickup Location' && deliveryData.pickupLocationMeta && (
                        <div className="modal_form-item">
                            <Label htmlFor="Pickup Location">Pickup Location</Label>
                            <PlacesAutocompleteField
                                addressData={deliveryData.pickupLocationMeta || null}
                                id="pickupLocation"
                                idMeta="pickupLocationMeta"
                                submitted={submitted}
                                validated={validated}
                                isDisabled
                            />
                        </div>
                    )}

                    <Row>
                        <Col>
                            {formData.task === 'Update the Pickup Location' && (
                                <div className="modal_form-item">
                                    <Label htmlFor="pickupLocationPostalCode">Pickup Postal Code</Label>
                                    <AvField
                                        name="pickupLocationPostalCode"
                                        placeholder="Pickup Postal Code"
                                        className="form-control"
                                        id="pickupLocationPostalCode"
                                        errorMessage="Required"
                                        value={deliveryData.pickupLocationPostalCode}
                                        type="Number"
                                        minLength={5}
                                        maxLength={5}
                                        onChange={(event) => handleSelectChange(event, 'pickupLocationPostalCode')}
                                        disabled
                                    />
                                </div>
                            )}
                        </Col>
                        <Col>
                            {formData.task === 'Update the Pickup Location' && (
                                <div className="modal_form-item">
                                    <Label htmlFor="pickupLocationCity">Pickup City</Label>
                                    <AvField
                                        name="pickupLocationCity"
                                        placeholder="Pickup City"
                                        type="text"
                                        className="form-control"
                                        id="pickupLocationCity"
                                        value={deliveryData.pickupLocationCity}
                                        disabled
                                    />
                                </div>
                            )}
                        </Col>
                        <Col>
                            {formData.task === 'Update the Pickup Location' && (
                                <div className="modal_form-item">
                                    <Label htmlFor="pickupLocationState">Pickup State</Label>
                                    <AvField
                                        name="pickupLocationState"
                                        placeholder="Pickup State"
                                        type="text"
                                        className="form-control"
                                        id="pickupLocationState"
                                        value={deliveryData.pickupLocationState}
                                        disabled
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>

                    {formData.task === 'Update the Pickup Location' && (
                        <div className="modal_form-item">
                            <Label htmlFor="Delivery Location">New Pickup Location</Label>
                            <PlacesAutocompleteField
                                addressData={formData?.pickupLocationMeta || null}
                                id="pickupLocation"
                                idMeta="pickupLocationMeta"
                                returnLocationData={returnLocationData}
                                isRequired
                                // onChange={(event) => handleSelectChange(event, 'pickupLocation')}
                                submitted={submitted}
                                validated={validated}
                            />
                        </div>
                    )}

                    <Row>
                        <Col>
                            {formData.task === 'Update the Pickup Location' && (
                                <div className="modal_form-item">
                                    <Label htmlFor="pickupLocationPostalCode">Pickup Postal Code</Label>
                                    <AvField
                                        name="pickupLocationPostalCode"
                                        placeholder="Pickup Postal Code"
                                        errorMessage="Required"
                                        className="form-control"
                                        returnLocationData={returnLocationData}
                                        validate={{ required: { value: true } }}
                                        id="pickupLocationPostalCode"
                                        idMeta="pickupLocationMeta"
                                        value={formData?.pickupLocationValues?.pickupLocationPostalCode}
                                        type="Number"
                                        minLength={5}
                                        maxLength={5}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                        </Col>
                        <Col>
                            {formData.task === 'Update the Pickup Location' && (
                                <div className="modal_form-item">
                                    <Label htmlFor="pickupLocationCity">Pickup City</Label>
                                    <AvField
                                        name="pickupLocationCity"
                                        placeholder="Pickup City"
                                        type="text"
                                        errorMessage="Required"
                                        className="form-control"
                                        id="pickupLocationCity"
                                        value={formData?.pickupLocationValues?.pickupLocationCity}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                        </Col>
                        <Col>
                            {formData.task === 'Update the Pickup Location' && (
                                <div className="modal_form-item">
                                    <Label htmlFor="pickupLocationState">Pickup State</Label>
                                    <AvField
                                        name="pickupLocationState"
                                        placeholder="Pickup State"
                                        type="text"
                                        errorMessage="Required"
                                        className="form-control"
                                        id="pickupLocationState"
                                        value={formData?.pickupLocationValues?.pickupLocationState}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>

                    {formData.task === 'Update the Delivery Location' && (
                        <div className="modal_form-item">
                            <Label htmlFor="Delivery Location">Delivery Location</Label>
                            <PlacesAutocompleteField
                                addressData={deliveryData.deliveryAddressMeta || null}
                                id="deliveryAddress"
                                idMeta="pickupLocationMeta"
                                submitted={submitted}
                                validated={validated}
                                isDisabled
                            />
                        </div>
                    )}

                    <Row>
                        <Col>
                            {formData.task === 'Update the Delivery Location' && (
                                <div className="modal_form-item">
                                    <Label htmlFor="PostalCode">Postal Code</Label>
                                    <AvField
                                        name="PostalCode"
                                        placeholder="Postal Code"
                                        errorMessage="Required"
                                        className="form-control"
                                        // validate={{ required: { value: true } }}
                                        id="postalCode"
                                        value={deliveryData.postalCode}
                                        type="Number"
                                        minLength={5}
                                        maxLength={5}
                                        onChange={(event) => handleSelectChange(event, 'postalCode')}
                                        disabled
                                    />
                                </div>
                            )}
                        </Col>
                        <Col>
                            {formData.task === 'Update the Delivery Location' && (
                                <div className="modal_form-item">
                                    <Label htmlFor="City">Pickup City</Label>
                                    <AvField
                                        name="City"
                                        placeholder="City"
                                        type="text"
                                        className="form-control"
                                        id="city"
                                        value={deliveryData.city}
                                        disabled
                                    />
                                </div>
                            )}
                        </Col>
                        <Col>
                            {formData.task === 'Update the Delivery Location' && (
                                <div className="modal_form-item">
                                    <Label htmlFor="State">State</Label>
                                    <AvField
                                        name="State"
                                        placeholder="State"
                                        type="text"
                                        className="form-control"
                                        id="state"
                                        value={deliveryData.state}
                                        disabled
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>

                    {formData.task === 'Update the Delivery Location' && (
                        <div className="modal_form-item">
                            <Label htmlFor="New Delivery Location">New Delivery Location</Label>
                            <PlacesAutocompleteField
                                addressData={formData.deliveryAddressMeta || null}
                                returnLocationData={returnLocationData}
                                id="deliveryAddress"
                                idMeta="deliveryAddressMeta"
                                isRequired
                                submitted={submitted}
                                validated={validated}
                            />
                        </div>
                    )}

                    <Row>
                        <Col>
                            {formData.task === 'Update the Delivery Location' && (
                                <div className="modal_form-item">
                                    <Label htmlFor="PostalCode">Postal Code</Label>
                                    <AvField
                                        name="postalCode"
                                        placeholder="Postal Code"
                                        errorMessage="Required"
                                        className="form-control"
                                        returnLocationData={returnLocationData}
                                        validate={{ required: { value: true } }}
                                        id="postalCode"
                                        idMeta="deliveryAddressMeta"
                                        value={formData?.deliveryAddressValues?.postalCode}
                                        type="Number"
                                        minLength={5}
                                        maxLength={5}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                        </Col>
                        <Col>
                            {formData.task === 'Update the Delivery Location' && (
                                <div className="modal_form-item">
                                    <Label htmlFor="City">City</Label>
                                    <AvField
                                        name="city"
                                        placeholder="City"
                                        type="text"
                                        errorMessage="Required"
                                        className="form-control"
                                        // validate={{ required: { value: true } }}
                                        id="city"
                                        value={formData?.deliveryAddressValues?.city}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                        </Col>
                        <Col>
                            {formData.task === 'Update the Delivery Location' && (
                                <div className="modal_form-item">
                                    <Label htmlFor="State">State</Label>
                                    <AvField
                                        name="state"
                                        placeholder="State"
                                        type="text"
                                        errorMessage="Required"
                                        className="form-control"
                                        // validate={{ required: { value: true } }}
                                        id="state"
                                        value={formData?.deliveryAddressValues?.state}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>

                    {formData.task === 'Update the Vehicle Type' && (
                        <div className="modal_form-item">
                            <Label htmlFor="vehicleType">Vehicle Type</Label>
                            <Select
                                defaultValue={getDefaultValueForSelect(deliveryData.vehicleType)}
                                options={[
                                    { label: 'Motorbike', value: VEHICLE_TYPES.MOTOR_BIKE },
                                    { label: 'Car', value: VEHICLE_TYPES.CAR },
                                    { label: 'Van - 7FT', value: VEHICLE_TYPES.VAN_SEVEN_FEET },
                                    { label: 'Truck', value: VEHICLE_TYPES.TRUCK },
                                ]}
                                // onChange={(event) => handleSelectChange(event, 'vehicleType')}
                                submitted={submitted}
                                required
                                validated={validated}
                                isDisabled
                            />
                        </div>
                    )}
                    {formData.task === 'Update the Vehicle Type' && (
                        <div className="modal_form-item">
                            <Label htmlFor="vehicleType">New Vehicle Type</Label>
                            <Select
                                value={getDefaultValueForSelect(formData.vehicleType)}
                                options={[
                                    { label: 'Motorbike', value: VEHICLE_TYPES.MOTOR_BIKE },
                                    { label: 'Car', value: VEHICLE_TYPES.CAR },
                                    { label: 'Van - 7FT', value: VEHICLE_TYPES.VAN_SEVEN_FEET },
                                    { label: 'Truck', value: VEHICLE_TYPES.TRUCK },
                                ]}
                                onChange={(event) => handleSelectChange(event, 'vehicleType')}
                                submitted={submitted}
                                errorMessage="Required"
                                required
                                validated={validated}
                            />
                        </div>
                    )}
                    {formData.task === 'Update the Vehicle Type' && (
                        <div className="modal_form-item">
                            <Label>Rider/ Driver</Label>
                            <Select
                                options={relevantRidersList(formData.vehicleType)}
                                value={selectedRider}
                                id="riderId"
                                isClearable
                                onChange={(event) => handleSelectChange(event, 'riderId')}
                                submitted={submitted}
                                validated={validated}
                            />
                        </div>
                    )}

                    <div className="modal_form-item">
                        <Label htmlFor="reason">Reason</Label>
                        <AvField
                            value={formData.reason}
                            name="reason"
                            id="reason"
                            onChange={(event) => handleSelectChange(event, 'reason')}
                        />
                    </div>

                    <div style={{ width: '100%', padding: '10px', background: '#fffbf0', marginBottom: '15px' }}>
                        Type &apos;confirm&apos; to confirm the transaction and click confirm.
                    </div>

                    <div className="modal_form-item">
                        <AvField
                            value={formData.confirmation}
                            name="confirmation"
                            onChange={(event) => handleSelectChange(event, 'confirmation')}
                            errorMessage="Confirmation Required"
                            submitted={submitted}
                            required
                            id="confirmation"
                            validated={validated}
                            autoComplete="off"
                        />
                    </div>

                    <div style={{ fontSize: 14 }} className="mb-6">
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="scootix-btn-radius secondary-btn pt-1 pb-1 m-2 mb-3 pr-5 pl-5 shadow-lg "
                            style={{ borderRadius: 10 }}
                            type="submit"
                            disabled={formLoading}
                        >
                            {formLoading ? <Spinner className="mr-4 ml-4 0" color="info" size="sm" /> : <>CONFIRM</>}
                        </motion.button>
                    </div>
                </AvForm>
            </div>
        </div>
    );
}

MoreOptions.propTypes = {
    setShowModal: PropTypes.func,
    loadTransactions: PropTypes.func,
    setDeliveryFormData: PropTypes.func,
    data: PropTypes.any,
    updateHistoryWithData: PropTypes.func,
};

export default MoreOptions;
