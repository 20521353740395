export const deliveryStatusOptions = [
    {
        value: 'Pending',
        label: 'Pickup Pending',
    },
    {
        value: 'New',
        label: 'New Deliveries',
    },
];

export const mapDocs = (docs) => {
    let allDocs = [];
    allDocs = docs.map((x) => ({
        ...x,
    }));
    return allDocs;
};
