/* eslint-disable indent */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, Button, Alert, Container, Label, FormGroup, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { withRouter, Link } from 'react-router-dom';

import { IS_USER_PROFILE_PIC_IMAGE_CHECKED, LAST_LOGIN, USER_PROFILE_PIC } from 'constants/local-storage.constants';

// #region components | assets
import ScootixLogoLottie from 'assets/animations/scootix-logo-animated.json';

// #region imports
import { checkLogin, apiError } from 'store/actions';
import CONFIG from 'app-config';

import PackageJson from '../../../../package.json';

function LoginPage(props) {
    const [userData] = useState({});

    const [env, setEnv] = useState('');

    useEffect(() => {
        window.sessionStorage.removeItem(LAST_LOGIN);
        window.sessionStorage.removeItem(USER_PROFILE_PIC);
        window.sessionStorage.removeItem(IS_USER_PROFILE_PIC_IMAGE_CHECKED);
        if (window.ENV === 'test' || process.env.ENV === 'test' || process.env.NODE_ENV === 'test') {
            setEnv('test');
        }
        // console.log('process.env.REACT_APP_ENV', process.env.REACT_APP_ENV || '');
    }, []);

    const handleSubmit = (event, values) => {
        props.apiError('');
        props.checkLogin(values, props.history);
    };

    useEffect(() => {
        props.apiError('');
        document.body.classList.add('auth-body-bg');
        return () => {
            document.body.classList.remove('auth-body-bg');
        };
    }, []);

    const lottieOptions = (lottieName) => ({
        loop: false,
        autoplay: true,
        animationData: lottieName || '',
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    });

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={7}>
                            <div className="authentication-bg">
                                {/* <div className="bg-overlay"></div> */}
                                {/* <img src={APP_LOGO_WIDE_IMAGE} alt="icon" height={80} width={230} /> */}
                                <Lottie
                                    className="page-center-lottie"
                                    options={lottieOptions(ScootixLogoLottie)}
                                    height={90}
                                    width={230}
                                    speed={1}
                                />
                                <h3 className="font-size-18 mt-1">Delivery Management Console</h3>
                                <>
                                    {PackageJson ? (
                                        <h4 className="font-size-18" style={{ marginTop: -1 }}>
                                            {PackageJson.version || ''} - {CONFIG.subVersion}
                                        </h4>
                                    ) : (
                                        ''
                                    )}
                                </>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="auth-body w-100">
                                    <Row className="justify-content-center row-container">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <h5 className="text-muted font-weight-bold">Sign in to ScootiX</h5>
                                                </div>

                                                {props.loginError &&
                                                typeof props.loginError === 'string' &&
                                                props.loginError ? (
                                                    <>
                                                        <br />
                                                        <Alert color="danger">{props.loginError}</Alert>
                                                    </>
                                                ) : null}

                                                <div className="p-2 mt-4">
                                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="username">Username</Label>
                                                            <AvField
                                                                name="username"
                                                                value={userData.username}
                                                                type="text"
                                                                className="form-control"
                                                                id="username"
                                                                validate={{ required: true }}
                                                                placeholder="Enter Username"
                                                            />
                                                        </FormGroup>

                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="userpassword">Password</Label>
                                                            <AvField
                                                                name="password"
                                                                value={userData.password}
                                                                type="password"
                                                                className="form-control"
                                                                id="userpassword"
                                                                placeholder="Enter password"
                                                            />
                                                        </FormGroup>

                                                        <div className="custom-control custom-checkbox">
                                                            <Input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="customControlInline"
                                                            />
                                                            <Label
                                                                className="custom-control-label"
                                                                htmlFor="customControlInline"
                                                            >
                                                                Remember me
                                                            </Label>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Button
                                                                disabled={props.loading}
                                                                color="primary"
                                                                className="auth-login-btn nw-md "
                                                                type="submit"
                                                                id="auth-login-btn"
                                                            >
                                                                {props.loading ? (
                                                                    <Spinner
                                                                        className="mr-2"
                                                                        color="secondary"
                                                                        size="sm"
                                                                    />
                                                                ) : (
                                                                    'Sign In'
                                                                )}
                                                            </Button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Link to="/forgot-password" className="text-muted">
                                                                <i className="mdi mdi-lock mr-1"></i> Forgot your
                                                                password?
                                                            </Link>
                                                        </div>
                                                    </AvForm>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    {/* <p>
                                                        Don't have an account ?{' '}
                                                        <Link
                                                            to="/register"
                                                            className="font-weight-medium text-primary"
                                                        >
                                                            {' '}
                                                            Register{' '}
                                                        </Link>{' '}
                                                    </p> */}
                                                    <p>© 2023 ScootiX. Developed By TASA Global Solutions Sdn Bhd</p>
                                                    <p>{env}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

LoginPage.propTypes = {
    apiError: PropTypes.func,
    checkLogin: PropTypes.any,
    loginError: PropTypes.any,
    history: PropTypes.any,
    loading: PropTypes.any,
};

const mapStateToProps = (state) => {
    const { loginError, loading } = state.Login;
    return { loginError, loading };
};

export default withRouter(connect(mapStateToProps, { checkLogin, apiError })(LoginPage));
