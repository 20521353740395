/* eslint-disable indent */
/* eslint-disable arrow-body-style */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Spinner } from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';

// #region imports
import {
    RIDER_ICON_UPDATED_IMAGE,
    RECIPIENT_DELIVERY_ADDRESS_ICON_IMAGE,
    MERCHANT_LOCATION_ICON_IMAGE,
} from 'definitions/assets';
import { centerElementContainerStyles } from 'styles';
import { SOCKET_EVENTS } from 'constants/socket';
import { getSocketStorageSession } from 'utils/local-storage';

// #region services

// TODO:
// https://github.com/istarkov/google-map-clustering-example
// https://dev.to/zerquix18/let-s-play-with-google-maps-and-react-making-a-car-move-through-the-road-like-on-uber-part-1-4eo0

function TrackDeliveryModal(props) {
    const { riderData = null, toggleModal, pickupLocationMeta = {}, deliveryAddressMeta = {} } = props;

    const _isMounted = useRef(true);

    let isRiderInitialDataUpdated = false;

    const reduxState = useSelector((state) => state);

    const [loading] = useState(0);
    const [componentLoading, setComponentLoading] = useState(true);

    const [socketClient, setSocketClient] = useState(null);

    const [socketRiderData, setSocketRiderData] = useState(null);
    const [riderInitialSocketData, setRiderInitialSocketData] = useState(null);
    const [noRiderResponse, setNoRiderResponse] = useState(false);

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (reduxState?.Global) {
            const { socket } = reduxState.Global;
            if (socket && _isMounted.current) {
                setSocketClient(socket);
            }
        }
    }, [reduxState.Global]);

    useEffect(() => {
        const timer1 = setTimeout(() => {
            checkRiderResponse();
        }, 10000);
        return () => {
            clearTimeout(timer1);
        };
    }, [socketRiderData]);

    // effect to get geo data
    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position) {
                    // setCurrentGeo({
                    //     coordinates: {
                    //         lng: position.coords.longitude,
                    //         lat: position.coords.latitude,
                    //     },
                    // });
                }
            });
        } else {
            console.log('GEO Not Available');
        }
    }, []);

    useEffect(() => {
        if (socketClient && _isMounted.current) {
            socketClient.on(SOCKET_EVENTS.SERVER_CLIENT_SINGLE_RIDER_LOCATION_MAP, (res) => {
                console.log(res);
                if (_isMounted.current) {
                    setComponentLoading(false);
                    const { doc } = res;
                    if (doc.riderId === riderData._id) {
                        setSocketRiderData({ ...socketRiderData, ...doc });
                        if (!isRiderInitialDataUpdated) {
                            isRiderInitialDataUpdated = true;
                            setRiderInitialSocketData({ ...socketRiderData, ...doc });
                        }
                    }
                    setNoRiderResponse(false);
                }
            });
        }
    }, [socketClient, _isMounted.current]);

    useEffect(() => {
        const socketData = getSocketStorageSession();
        const interval = setInterval(() => {
            if (socketClient && _isMounted.current && socketData) {
                socketClient.emit(SOCKET_EVENTS.CLIENT_SERVER_LISTEN_SINGLE_RIDER_LOCATION, {
                    doc: {
                        riderId: riderData._id,
                        socketId: socketData.socketId,
                    },
                });
            }
        }, 2000); // 1 SEC Interval
        return () => clearInterval(interval);
    }, [socketClient, _isMounted.current]);

    function checkRiderResponse() {
        if (!socketRiderData && !riderInitialSocketData && _isMounted.current) {
            setNoRiderResponse(true);
        }
    }

    const PickUpLocationMarker = () => (
        <div>
            <img id="pickup-location-marker" src={MERCHANT_LOCATION_ICON_IMAGE} height={35} width={35} alt="" />
            <UncontrolledPopover
                className="custom-popover"
                trigger="hover"
                target="pickup-location-marker"
                placement="right"
                style={{ zIndex: 1000000 }}
            >
                <PopoverHeader className="custom-popover">Pickup Location</PopoverHeader>
                <PopoverBody>{pickupLocationMeta.address || ''}</PopoverBody>
            </UncontrolledPopover>
        </div>
    );

    const DeliveryAddressMarker = () => (
        <div>
            <img
                id="delivery-location-marker"
                src={RECIPIENT_DELIVERY_ADDRESS_ICON_IMAGE}
                height={35}
                width={35}
                alt=""
            />
            <UncontrolledPopover
                className="custom-popover"
                trigger="hover"
                target="delivery-location-marker"
                placement="right"
                style={{ zIndex: 1000000 }}
            >
                <PopoverHeader className="custom-popover">Delivery Location</PopoverHeader>
                <PopoverBody>{deliveryAddressMeta.address || ''}</PopoverBody>
            </UncontrolledPopover>
        </div>
    );

    const RiderMarker = () => (
        <div>
            <img id="rider-location-marker" src={RIDER_ICON_UPDATED_IMAGE} height={35} width={35} alt="" />
            <UncontrolledPopover
                className="custom-popover"
                trigger="hover"
                target="rider-location-marker"
                placement="right"
                style={{ zIndex: 1000000 }}
            >
                <PopoverHeader className="custom-popover">{riderData.fullName || ''}</PopoverHeader>
                <PopoverBody>{riderData.mobileNo || ''}</PopoverBody>
            </UncontrolledPopover>
        </div>
    );

    return (
        <>
            <div className="track-map-modal-container">
                <Modal
                    size="xl"
                    isOpen
                    center="true"
                    toggle={() => {
                        toggleModal();
                    }}
                    className="modal-dialog modal-dialog-centered"
                >
                    <ModalBody>
                        <div style={{ height: '70vh', width: '100%', ...centerElementContainerStyles }}>
                            {noRiderResponse ? (
                                <>
                                    <h3>No Response From Rider</h3>
                                </>
                            ) : (
                                <>
                                    {!(loading > 0 || componentLoading) &&
                                    riderInitialSocketData &&
                                    socketRiderData &&
                                    socketRiderData.coordinates ? (
                                        <GoogleMapReact
                                            defaultCenter={{
                                                lat: riderInitialSocketData.coordinates.latitude,
                                                lng: riderInitialSocketData.coordinates.longitude,
                                            }}
                                            defaultZoom={18}
                                        >
                                            {socketRiderData && socketRiderData.coordinates && (
                                                <RiderMarker
                                                    lat={socketRiderData.coordinates.latitude}
                                                    lng={socketRiderData.coordinates.longitude}
                                                />
                                            )}
                                            {deliveryAddressMeta && deliveryAddressMeta.coordinates && (
                                                <DeliveryAddressMarker
                                                    lat={deliveryAddressMeta.coordinates.lat}
                                                    lng={deliveryAddressMeta.coordinates.lng}
                                                />
                                            )}
                                            {pickupLocationMeta && pickupLocationMeta.coordinates && (
                                                <PickUpLocationMarker
                                                    lat={pickupLocationMeta.coordinates.lat}
                                                    lng={pickupLocationMeta.coordinates.lng}
                                                />
                                            )}
                                        </GoogleMapReact>
                                    ) : (
                                        <div>
                                            <Spinner size="50" />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
}
TrackDeliveryModal.propTypes = {
    riderData: PropTypes.object,
    toggleModal: PropTypes.func,
    pickupLocationMeta: PropTypes.any,
    deliveryAddressMeta: PropTypes.any,
};

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps)(withNamespaces()(TrackDeliveryModal));
