export const apiKeyListTableHeaders = [
    {
        key: 'name',
        value: 'NAME',
    },
    {
        key: 'isActiveString',
        value: 'STATUS',
        type: 'statusLabel',
        params: { shape: 'rectangle', activeLabel: 'ACTIVE', inactiveLabel: 'INACTIVE' },
    },
    {
        key: 'secretKey',
        value: 'SECRET KEY',
        type: 'customActionButton',
        params: { buttonLabel: 'View Key', customActionHandlerName: 'openSecretKey' },
    },
    {
        key: 'keyTypeString',
        value: 'KEY TYPE',
    },
    {
        key: 'createdAt',
        value: 'GENERATED AT',
        type: 'dateTime',
    },
    {
        key: 'createdByUserFullName',
        value: 'GENERATED BY',
    },
    {
        key: 'lastAccessedAt',
        value: 'SECRET KEY LAST ACCESSED AT',
        type: 'date',
    },
    {
        key: 'action',
        value: 'Action',
        type: 'customActionButton',
        params: { buttonLabel: 'Manage', customActionHandlerName: 'navigateToManagePage' },
    },
];
