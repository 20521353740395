import React from 'react';
import toast from 'react-hot-toast';

// #region imports
import { CustomToast } from 'components';
import { createSystemDataService } from 'services/system-data.service';
import { apiErrorHandler } from 'utils/helpers';
import paths from 'routes/paths';
import { SYSTEM_DATA_TYPES } from 'constants/enums';

import { updateProductMasterDataImagesService } from '../services';

export const handleCheckChange = (event, setIsFormSaved, setFormData, formData) => {
    setIsFormSaved(false);
    const { id, checked } = event.target;

    if (id === 'isChill') {
        if (formData.isFrozen) {
            setFormData({
                ...formData,
                isFrozen: false,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: checked,
            });
        }

        return;
    }

    if (id === 'isFrozen') {
        if (formData.isChill) {
            setFormData({
                ...formData,
                isChill: false,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: checked,
            });
        }

        return;
    }

    setFormData({
        ...formData,
        [id]: checked,
    });
};

/**
 * Handle Create Change
 */
export const handleCreateSelectChange = async (
    record,
    id,
    setIsFormSaved,
    setCustomLoading,
    setProductCategories,
    productCategories,
    setFormData,
    formData,
    setUnitOfMeasures,
    unitOfMeasures
) => {
    setIsFormSaved(false);
    if (record) {
        if (id === 'productCategory') {
            setCustomLoading((prev) => ({ ...prev, productCategory: true }));
            try {
                const { data } = await createSystemDataService({
                    type: SYSTEM_DATA_TYPES.PRODUCT_MASTER_DATA.TYPES.PRODUCT_CATEGORY,
                    module: SYSTEM_DATA_TYPES.PRODUCT_MASTER_DATA.MODULE,
                    name: record,
                });

                if (data) {
                    setProductCategories([...productCategories, { label: data.name, value: data.name }]);
                    setFormData({
                        ...formData,
                        [id]: record,
                    });
                }
            } catch (e) {
                const message = apiErrorHandler(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            setCustomLoading((prev) => ({ ...prev, productCategory: false }));
            return;
        }
        if (id === 'unitOfMeasure') {
            setCustomLoading((prev) => ({ ...prev, unitOfMeasure: true }));
            try {
                const { data } = await createSystemDataService({
                    type: SYSTEM_DATA_TYPES.PRODUCT_MASTER_DATA.TYPES.UNIT_OF_MEASURE,
                    module: SYSTEM_DATA_TYPES.PRODUCT_MASTER_DATA.MODULE,
                    name: record,
                });
                if (data) {
                    setUnitOfMeasures([...unitOfMeasures, { label: data.name, value: data.name }]);
                    setFormData({
                        ...formData,
                        [id]: record,
                    });
                }
            } catch (e) {
                const message = apiErrorHandler(e);
                toast.custom((t) => <CustomToast text={message} t={t} type="error" />, {
                    position: 'top-right',
                });
            }
            setCustomLoading((prev) => ({ ...prev, unitOfMeasure: false }));
            return;
        }

        setFormData({
            ...formData,
            [id]: record,
        });
    }
    setCustomLoading((prev) => ({ ...prev, productCategory: false }));
};

/**
 * Handle Select Change
 */
export const handleSelectChange = (event, id, setIsFormSaved, merchants, setFormData, formData) => {
    setIsFormSaved(false);
    if (event) {
        if (id === 'merchantId' && event) {
            const relevantMerchant = merchants.find((x) => x._id === event.value);
            if (relevantMerchant) {
                setFormData({
                    ...formData,
                    merchantName: relevantMerchant.name || '',
                    [id]: event ? event.value : null,
                });
            }
            return;
        }

        setFormData({
            ...formData,
            [id]: event ? event.value : null,
        });
    } else {
        if (id === 'merchantId') {
            setFormData({
                ...formData,
                merchantName: null,
                [id]: null,
            });
            return;
        }
        setFormData({
            ...formData,
            [id]: null,
        });
    }
};

export const getProductDescriptionSelect = (value, array) => {
    if (value) {
        const relevantObj = array.find((x) => x.value === value);
        if (relevantObj) {
            return relevantObj.name;
        }
    }
    return null;
};

export const handleImageUpdate = async (
    newAttachmentObj,
    initialAttachmentDb,
    setInitialAttachmentDb,
    formData,
    setFormData,
    history,
    prevCompState
) => {
    try {
        // Update User  ( Attachment Handling Occurs Within Service )
        const { data } = await updateProductMasterDataImagesService(formData, newAttachmentObj, initialAttachmentDb);
        if (data) {
            setFormData({ ...formData, ...data });
            setInitialAttachmentDb(data.attachmentObj);
            const updatedPayloadData = { ...formData, ...data };

            try {
                // Replace URL so relevant changes can be done
                if (updatedPayloadData) {
                    history.replace({
                        pathname: `${paths.PRODUCT_MASTER_DATA.FORM_VIEW}`,
                        search: `?type=edit&id=${updatedPayloadData._id}`,
                        state: {
                            productMasterDataParentData: updatedPayloadData,
                            prevCompState,
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }
        }
    } catch (e) {
        console.log(e);
    }
};
