import {
    CREATE_ATTACHMENT,
    CREATE_MULTI_ATTACHMENT,
    GET_ATTACHMENT_BY_KEY,
    UPDATE_ATTACHMENT,
    POST_DELIVERY_EXCEL_CSV_ATTACHMENT,
    CREATE_ATTACHMENT_OPEN,
    UPDATE_ATTACHMENT_OPEN,
    POST_BILLING_EXCEL_CSV_ATTACHMENT,
    POST_RIDER_COMMISSION_EXCEL_CSV_ATTACHMENT,
} from 'utils/apiEndpoints';
import { postAttachments, postFileAttachment } from 'utils/httpclients/instances';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';
import { encodeForwardSlashParam } from 'utils/helpers';

/**
 * Create Attachment Service
 * @returns {Promise}
 */
export const createAttachmentService = (data, objectData) => {
    try {
        return postAttachments(CREATE_ATTACHMENT, data, objectData);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
/**
 * Create Attachment Open Service
 * @returns {Promise}
 */
export const createAttachmentOpenService = (data, objectData) => {
    try {
        return postAttachments(CREATE_ATTACHMENT_OPEN, data, objectData);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Create Attachment Service
 * @returns {Promise}
 */
export const createMultiAttachmentService = (data, objectData) => {
    try {
        return postAttachments(CREATE_MULTI_ATTACHMENT, data, objectData);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Update Attachment Service
 * @returns {Promise}
 */
export const updateAttachmentService = async (key, payload, objectData) => {
    try {
        const modifiedKey = encodeForwardSlashParam(key);
        const url = UrlBuilder().setUrl(UPDATE_ATTACHMENT).setPathParams({ key: modifiedKey }).build();
        const res = await postAttachments(url, payload, objectData);
        return Promise.resolve(res);
    } catch (exception) {
        if (exception.response) {
            // IF ATTACHMENT NOT FOUND CREATE NEW ONE
            const { status, data } = exception.response;
            if (status === 500 && data && data.errors && data.errors.msg) {
                if (data.errors.msg === 'ATTACHMENT_NOT_FOUND') {
                    console.log('ATTACHMENT_NOT_FOUND');
                    console.log('Creating New Attachment');
                    try {
                        const res = await createAttachmentService(payload, objectData);
                        return Promise.resolve(res);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        }
        console.log(exception);
        return Promise.reject(exception);
    }
};

/**
 * Update Attachment Open Service
 * @returns {Promise}
 */
export const updateAttachmentOpenService = async (key, payload, objectData) => {
    try {
        const modifiedKey = encodeForwardSlashParam(key);
        const url = UrlBuilder().setUrl(UPDATE_ATTACHMENT_OPEN).setPathParams({ key: modifiedKey }).build();
        const res = await postAttachments(url, payload, objectData);
        return Promise.resolve(res);
    } catch (exception) {
        if (exception.response) {
            // IF ATTACHMENT NOT FOUND CREATE NEW ONE
            const { status, data } = exception.response;
            if (status === 500 && data && data.errors && data.errors.msg) {
                if (data.errors.msg === 'ATTACHMENT_NOT_FOUND') {
                    console.log('ATTACHMENT_NOT_FOUND');
                    console.log('Creating New Attachment');
                    try {
                        const res = await createAttachmentOpenService(payload, objectData);
                        return Promise.resolve(res);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        }
        console.log(exception);
        return Promise.reject(exception);
    }
};

/**
 * Get Attachment By Key Service
 * @returns {Promise}
 */
export const getAttachmentByKeyService = (key) => {
    // Encode URL -> route params doesn't support forward slash
    const modifiedKey = encodeForwardSlashParam(key);
    try {
        const url = UrlBuilder().setUrl(GET_ATTACHMENT_BY_KEY).setPathParams({ key: modifiedKey }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Get Attachment By Key
 * @returns {Promise}
 */
export const getAttachmentByKey = async (key) => {
    try {
        const { data } = await getAttachmentByKeyService(key);
        if (data && data.presignedUrl) {
            return data.presignedUrl;
        }
        return null;
    } catch (exception) {
        console.log(exception);
        return null;
    }
};

/**
 * Upload  Excel File
 * @param {object} data
 * @returns {Promise<attachment>}
 */
// eslint-disable-next-line consistent-return
export const uploadDeliveryImportXlCsvAttachment = (data) => {
    try {
        return postFileAttachment(
            POST_DELIVERY_EXCEL_CSV_ATTACHMENT,
            {
                file: data.uploadData,
            },
            JSON.stringify(data.meta)
        );
    } catch (error) {
        console.log(error);
    }
};

/**
 * Upload  Excel File
 * @param {object} data
 * @returns {Promise<attachment>}
 */
// eslint-disable-next-line consistent-return
export const uploadBillingImportXlCsvAttachment = (data) => {
    try {
        return postFileAttachment(
            POST_BILLING_EXCEL_CSV_ATTACHMENT,
            {
                file: data.uploadData,
            },
            JSON.stringify(data.meta)
        );
    } catch (error) {
        console.log(error);
    }
};

/**
 * Upload Rider Commission  Excel File
 * @param {object} data
 * @returns {Promise<attachment>}
 */
export const uploadRiderCommissionImportXlCsvAttachment = (data) =>
    postFileAttachment(
        POST_RIDER_COMMISSION_EXCEL_CSV_ATTACHMENT,
        {
            file: data.uploadData,
        },
        JSON.stringify(data.meta)
    );
