/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
import { isObject } from 'lodash';
import * as qs from 'query-string';

// #region other imports
import PATHS from 'routes/paths';

// #region utils
import { getDateValue, getGreetings } from 'utils/helpers';
import { getUser, getVisitingObject } from 'utils/checkAuth';

export const getHeaderTitle = (path, search, location) => {
    const parseSearch = qs.parse(search);
    let pathName = path;

    const { state } = location;
    const user = getUser() || {};

    if (path[0] === '/') {
        pathName = pathName.substring(1);
    }

    switch (pathName) {
        case 'home':
            return {
                heading: `Hello, ${user.fullName || `${user.firstName || ''} ${user.lastName || ''}` || ''}`,
                text: getGreetings(),
            };
        case 'user-management/list':
            return {
                heading: 'User Management',
                text: 'Manage users here ',
            };

        case 'user-management/form':
            const {
                isEditMode,
                data,
                createdBy = {},
                updatedBy = null,
                text,
            } = getUserRegistrationFormTitle(state, parseSearch);
            return {
                heading: `${isEditMode ? `Edit User Account ${text || ''} ` : 'Create a new User Account'}`,
                text: isEditMode
                    ? `Created By ${createdBy?.fullName || 'System'} on ${
                          data?.createdAt ? getDateValue(data.createdAt, 'datetime') : ''
                      }`
                    : 'Fill the following data and create a new User Account',
                subtitle:
                    isEditMode && updatedBy
                        ? `Updated By ${updatedBy?.fullName || 'System'} on ${
                              data?.updatedAt ? getDateValue(data.updatedAt, 'datetime') : ''
                          }`
                        : '',
            };

        case 'rider-profiles/list':
            return {
                heading: 'Rider / Driver Profile Management',
                text: 'Manage rider profiles here ',
            };
        case 'rider-profiles/form': {
            const {
                isEditMode,
                data,
                createdBy = {},
                updatedBy = null,
                text,
            } = getRiderRegistrationFormTitle(state, parseSearch);
            return {
                heading: `${isEditMode ? `Edit Rider Account ${text || ''} ` : 'Create a new Rider Account'}`,
                text: isEditMode
                    ? `Created By ${createdBy?.fullName || 'System'} on ${getDateValue(data.createdAt, 'datetime')}`
                    : 'Fill the following data and create a new Rider Account',
                subtitle:
                    isEditMode && updatedBy
                        ? `Updated By ${updatedBy?.fullName || 'System'} on ${getDateValue(data.updatedAt, 'datetime')}`
                        : '',
            };
        }
        case 'order-processing/list':
            return {
                heading: 'Your Order List',
                text: 'Select tabs to navigate',
            };

        case 'order-processing/processed-orders':
            return {
                heading: 'Your Processed Orders',
                text: 'Select tabs to navigate',
            };

        case 'order-processing/form': {
            const {
                isEditMode,
                data,
                createdBy = {},
                updatedBy = null,
                text,
            } = getOrderEditFormTitle(state, parseSearch);
            return {
                heading: `${isEditMode ? `Edit Order ${text || ''} ` : 'Create a new Order'}`,
                text:
                    isEditMode && createdBy
                        ? `Created By ${createdBy?.fullName || 'System'} on ${getDateValue(data.createdAt, 'datetime')}`
                        : 'Fill the following data and create a new Order',
                subtitle:
                    isEditMode && updatedBy
                        ? `Updated By ${updatedBy.fullName || 'System'} on ${getDateValue(data.updatedAt, 'datetime')}`
                        : '',
            };
        }

        case 'picking-task-list':
            return {
                heading: 'Picking Tasks List View',
                text: 'View the on going order processing here. ',
            };
        case 'picking-task/form': {
            const {
                isEditMode,
                data,
                createdBy = {},
                updatedBy = null,
                text,
            } = getPickingEditFormTitle(state, parseSearch);
            return {
                heading: `${isEditMode ? `Edit Picking Task ${text || ''} ` : 'Create a new Picking Task'}`,
                text: isEditMode
                    ? `Created By ${createdBy.fullName || 'System'} on ${getDateValue(data.createdAt, 'datetime')}`
                    : 'Fill the following data and create a new Picking Task',
                subtitle:
                    isEditMode && updatedBy
                        ? `Updated By ${updatedBy.fullName || 'System'} on ${getDateValue(data.updatedAt, 'datetime')}`
                        : '',
            };
        }
        case 'order-processing/packaging/form': {
            const { isEditMode, text = 'Packing', merchant, user } = getPackagingEditFormTitle(state, parseSearch);
            return {
                heading: `${isEditMode ? `Packing for ${text}` : 'Create a new Picking Task'}`,
                text: merchant || '',
                subtitle: `Created by ${user}`,
            };
        }
        case 'merchant/list':
            return {
                heading: 'Merchant Profile Management',
                text: 'Manage merchant profiles here. ',
            };
        case 'delivery/list': {
            return {
                heading: `Delivery List`,
                text: 'View deliveries here. ',
            };
        }

        case 'delivery/completed-list': {
            return {
                heading: `Completed Delivery List`,
                text: 'View completed deliveries here. ',
            };
        }
        case 'delivery/deleted-list': {
            return {
                heading: `Deleted Delivery List`,
                text: 'View deleted deliveries here. ',
            };
        }

        case 'delivery/form': {
            const {
                isEditMode,
                text = 'Delivery',
                data = {},
                createdBy = {},
                updatedBy = {},
                deliveryStatus,
                deliveryType = null,
                routePlanningId = null,
                routePlanningSequenceId = null,
                shopifyStoreName = null,
            } = getDeliveryEditFormTitle(state, parseSearch);

            let renderedText;

            if (isEditMode && data && !shopifyStoreName) {
                const createdByFullName = (createdBy || {}).fullName || 'System';
                const createdAtValue = data?.createdAt ? getDateValue(data.createdAt, 'datetime') : '';
                renderedText = `Created By ${createdByFullName} on ${createdAtValue}`;
            } else if (isEditMode && data && shopifyStoreName) {
                const createdAtValue = data?.createdAt ? getDateValue(data.createdAt, 'datetime') : '';
                renderedText = `Created Via Shopify on ${createdAtValue}`;
            } else {
                renderedText = 'Fill the following data and create a new Delivery';
            }

            return {
                heading: `${isEditMode ? `Edit Delivery ${text || ''} ` : 'Create a new Delivery'}`,
                text: renderedText,
                subtitle:
                    isEditMode && updatedBy && data
                        ? `Updated By ${updatedBy.fullName || 'System'} on ${
                              data?.updatedAt ? getDateValue(data.updatedAt, 'datetime') : ''
                          }`
                        : '',
                status: deliveryStatus || '',
                deliveryType,
                type: 'delivery_form',
                routePlanningId,
                routePlanningSequenceId,
            };
        }
        // eslint-disable-next-line no-duplicate-case
        case 'delivery/form': {
            let isDeliveryPendingModify = false;
            if (parseSearch && parseSearch.type && parseSearch.type === 'delivery-pending') {
                isDeliveryPendingModify = true;
            }
            const {
                isEditMode,
                text = 'Delivery',
                merchant,
                user,
                rider,
                deliveryStatus,
            } = getDeliveryEditFormTitle(state, parseSearch);
            return {
                heading: isDeliveryPendingModify
                    ? 'Modify Pending Delivery'
                    : `${isEditMode ? `Delivery ${text}` : 'Create a new Delivery'}`,
                text: isEditMode ? (rider ? `${merchant} Assigned to ${rider || ''}` || '' : 'Not Assigned Yet') : '',
                subtitle: isEditMode ? `Created by ${user}` : '',
                status: deliveryStatus || '',
            };
        }
        case 'merchant/form': {
            const {
                isEditMode,
                text = 'Fill the following data and create a new merchant',
                data = {},
                createdBy = {},
                updatedBy = {},
            } = getMerchantEditFormTitle(state, parseSearch);
            return {
                heading: `${isEditMode ? `Edit Merchant ${text || ''} ` : 'Create a new Merchant'}`,
                text:
                    isEditMode && data
                        ? `Created By ${(createdBy || {}).fullName || 'System'} on ${getDateValue(
                              data.createdAt,
                              'datetime'
                          )}`
                        : 'Fill the following data and create a new Merchant',
                subtitle:
                    isEditMode && updatedBy && data
                        ? `Updated By ${updatedBy.fullName || 'System'} on ${getDateValue(data.updatedAt, 'datetime')}`
                        : '',
            };
        }
        case 'merchant/locations-form': {
            const {
                isEditMode,
                text = 'Fill the following data and create a new merchant location',
                data = {},
                createdBy = {},
                updatedBy = {},
            } = getMerchantLocationEditFormTitle(state, parseSearch);
            return {
                heading: `${isEditMode ? `Edit Merchant Location ${text || ''} ` : 'Create a new Merchant Location'}`,
                text: isEditMode
                    ? `Created By ${(createdBy || {}).fullName || 'System'} on ${
                          data?.createdAt ? getDateValue(data.createdAt, 'datetime') : ''
                      }`
                    : 'Fill the following data and create a new Merchant Location',
                subtitle:
                    isEditMode && updatedBy
                        ? `Updated By ${updatedBy.fullName || 'System'} on ${
                              data?.updatedAt ? getDateValue(data.updatedAt, 'datetime') : ''
                          }`
                        : '',
            };
        }
        case 'user-profile': {
            return {
                heading: 'Edit Profile',
                text: '',
            };
        }
        case 'deleted-users': {
            return {
                heading: 'Deleted Users',
                text: '',
            };
        }
        case 'merchant/locations-list': {
            return {
                heading: 'Merchant Locations',
                text: '',
            };
        }
        case 'role-permission': {
            return {
                heading: 'Role Permissions',
                text: 'Create or Update Role Permission',
            };
        }
        case 'role/form': {
            return {
                heading: 'Create a new user role',
                text: 'Fill following data to create a user role',
            };
        }
        case 'shipping-label/view': {
            const { text = 'Delivery', merchant, user, rider } = getDeliveryEditFormTitle(state, parseSearch);
            return {
                heading: `Shipping Label for Delivery ${text}`,
                text: `${merchant} Assigned to ${rider || ''}`,
                subtitle: `Created by ${user}`,
            };
        }
        case 'riders/advanced-rider-tracking': {
            return {
                heading: 'Advanced Rider Tracking',
                // text: 'Fill following data to create a user role',
            };
        }
        case 'settings/delivery/external-connections': {
            return {
                heading: 'External System Connections for Deliveries',
                text: 'Configure methods to communicate with external systems  and method of importing data.',
            };
        }
        case 'settings/delivery/external-connections/excel-csv': {
            return {
                heading: `Configure Excel/ CSV Import for ${parseSearch.name || ''}`,
                text: 'Type the header name in the text box.  Fields are case sensitive.',
            };
        }
        case 'imports/delivery': {
            return {
                heading: `Import Deliveries`,
                text: 'View the on-going delivery progress.',
            };
        }
        case 'pending-data/delivery/form': {
            return {
                heading: `Manage Pending Deliveries`,
                text: 'You can manage each delivery which was imported.',
            };
        }
        case 'rider-profile-management/deleted':
            return {
                heading: 'Deleted Rider / Driver Profile Management',
                text: 'Manage Deleted rider profiles here ',
            };
        case 'merchant/list/deleted':
            return {
                heading: 'Deleted Merchant Profiles',
                text: 'Manage Deleted merchant profiles here ',
            };
        case 'merchant-locations/list/deleted':
            return {
                heading: 'Deleted Merchant Locations',
                text: 'Manage Deleted merchant locations here ',
            };
        case 'warehouse/warehouse-site/list':
            return {
                heading: 'Warehouses',
                text: 'Manage physical or virtual sites for warehouse management',
            };

        case 'warehouse/warehouse-site/form': {
            const {
                isEditMode,
                warehouse,
                createdBy = {},
                updatedBy = null,
                text,
            } = getWarehouseSiteEditFormTitle(state, parseSearch);
            return {
                heading: `${isEditMode ? `Edit Warehouse ${text || ''} ` : 'Create a new Warehouse'}`,
                text: isEditMode
                    ? `Created By ${createdBy.fullName || 'System'} on ${getDateValue(warehouse.createdAt, 'datetime')}`
                    : 'Fill the following data and create a new Warehouse',
                subtitle:
                    isEditMode && updatedBy
                        ? `Updated By ${updatedBy.fullName || 'System'} on ${getDateValue(
                              warehouse.updatedAt,
                              'datetime'
                          )}`
                        : '',
            };
        }
        case 'warehouse/warehouse-site/location/form': {
            const {
                isEditMode,
                formData,
                createdBy = {},
                updatedBy = null,
                text,
            } = getWarehouseZoneEditFormTitle(state, parseSearch);
            return {
                heading: `${isEditMode ? `Edit Warehouse Zone ${text || ''} ` : 'Create a new Warehouse Zone'}`,
                text: isEditMode
                    ? `Created By ${createdBy.fullName || 'System'} on ${getDateValue(formData.createdAt, 'datetime')}`
                    : 'Fill the following data and create a new Warehouse Zone',
                subtitle:
                    isEditMode && updatedBy
                        ? `Updated By ${updatedBy.fullName || 'System'} on ${getDateValue(
                              formData.updatedAt,
                              'datetime'
                          )}`
                        : '',
            };
        }
        case 'warehouse/warehouse-site/location/bin/form': {
            const {
                isEditMode,
                formData,
                createdBy = {},
                updatedBy = null,
                text,
            } = getWarehouseBinLocationEditFormTitle(state, parseSearch);
            return {
                heading: `${isEditMode ? `Edit Bin Location ${text || ''} ` : 'Create a new Bin Location'}`,
                text: isEditMode
                    ? `Created By ${createdBy.fullName || 'System'} on ${getDateValue(formData.createdAt, 'datetime')}`
                    : 'Fill the following data and create a new Bin Location',
                subtitle:
                    isEditMode && updatedBy
                        ? `Updated By ${updatedBy.fullName || 'System'} on ${getDateValue(
                              formData.updatedAt,
                              'datetime'
                          )}`
                        : '',
            };
        }

        case 'warehouse/product-master-data/list':
            return {
                heading: 'Product Management',
                text: 'Manage the products in your warehouse.',
            };

        case 'warehouse/product-master-data/form': {
            const {
                isEditMode,
                formData,
                createdBy = {},
                updatedBy = null,
                text,
            } = getProductMasterDataEditFormTitle(state, parseSearch);
            return {
                heading: `${
                    isEditMode ? `Edit Product Master Data ${text || ''} ` : 'Create a new Product Master Data'
                }`,
                text: isEditMode
                    ? `Created By ${createdBy.fullName || 'System'} on ${getDateValue(formData.createdAt, 'datetime')}`
                    : 'Fill the following data and create a new Product Master Data',
                subtitle:
                    isEditMode && updatedBy
                        ? `Updated By ${updatedBy.fullName || 'System'} on ${getDateValue(
                              formData.updatedAt,
                              'datetime'
                          )}`
                        : '',
            };
        }

        case 'warehouse/stocks-receiving/list':
            return {
                heading: 'Stocks Receiving Management',
                text: 'Manage the Stocks in your warehouse.',
            };

        case 'warehouse/stocks-receiving/form': {
            const {
                isEditMode,
                formData,
                createdBy = {},
                updatedBy = null,
                text,
            } = getStocksReceivingEditFormTitle(state, parseSearch);
            return {
                heading: `${isEditMode ? `Edit Stocks Receiving ${text || ''} ` : 'Create a new Stocks Receiving'}`,
                text: isEditMode
                    ? `Created By ${createdBy ? createdBy.fullName : 'System'} on ${
                          formData && formData.createdAt ? getDateValue(formData.createdAt, 'datetime') : ''
                      }`
                    : 'Fill the following data and create a new Stocks Receiving',
                subtitle:
                    isEditMode && updatedBy
                        ? `Updated By ${updatedBy ? updatedBy.fullName : 'System'} on ${
                              formData && formData.updatedAt ? getDateValue(formData.updatedAt, 'datetime') : ''
                          }`
                        : '',
            };
        }
        case 'delivery/unassigned-list': {
            return {
                heading: `Delivery Unassigned List`,
                text: 'View Unassigned deliveries here. ',
            };
        }

        case 'notifications': {
            return {
                heading: `Notifications`,
                text: 'View All Notifications Here',
            };
        }

        case 'warehouse/stock-adjustment/list': {
            return {
                heading: `Stock Adjustment`,
                text: 'View Stock Adjustments here. ',
            };
        }

        case 'billing/list': {
            return {
                heading: `Billing`,
                text: 'Select filters and click generate to generate a billing report.',
            };
        }

        case 'rider-commissions/list': {
            return {
                heading: `Rider Commissions`,
                text: 'Select filters and click generate to generate a rider comissions report.',
            };
        }

        case 'warehouse/stock-adjustment/form': {
            const {
                isEditMode,
                formData,
                createdBy = {},
                updatedBy = null,
                text,
                stockAdjustmentStatus,
            } = getStocksAdjustmentEditFormTitle(state, parseSearch);
            return {
                heading: `${isEditMode ? `Edit Stocks Adjustment ${text || ''} ` : 'Create a new Stocks Adjustment'}`,
                text: isEditMode
                    ? `Created By ${createdBy ? createdBy.fullName : 'System'} on ${
                          formData && formData.createdAt ? getDateValue(formData.createdAt, 'datetime') : ''
                      }`
                    : 'Fill the following data and create a new Stocks Adjustment',
                subtitle:
                    isEditMode && updatedBy
                        ? `Updated By ${updatedBy ? updatedBy.fullName : 'System'} on ${
                              formData && formData.updatedAt ? getDateValue(formData.updatedAt, 'datetime') : ''
                          }`
                        : '',
                stockAdjustmentStatus: stockAdjustmentStatus || '',
            };
        }

        case 'rider-commissions/form': {
            const {
                isEditMode,
                formData,
                createdBy = {},
                updatedBy = null,
                text,
            } = getRiderCommissionSettingEditFormTitle(state, parseSearch);
            return {
                heading: `${
                    isEditMode
                        ? `Edit Rider Commission Setting ${text || ''} `
                        : 'Create a new Rider Commission Setting'
                }`,
                text: isEditMode
                    ? `Created By ${createdBy.fullName || 'System'} on ${getDateValue(formData.createdAt, 'datetime')}`
                    : 'Fill the following data and create a new Rider Commission Setting',
                subtitle:
                    isEditMode && updatedBy
                        ? `Updated By ${updatedBy.fullName || 'System'} on ${getDateValue(
                              formData.updatedAt,
                              'datetime'
                          )}`
                        : '',
            };
        }

        case 'billing/form': {
            const {
                isEditMode,
                formData = {},
                createdBy = {},
                updatedBy = {},
                text,
            } = getBillingSettingEditFormTitle(state, parseSearch);

            const createdByUser = createdBy ? createdBy.fullName || 'System' : 'System';

            return {
                heading: `${isEditMode ? `Edit Billing Setting ${text || ''} ` : 'Create a new Billing Setting'}`,
                text: isEditMode
                    ? `Created By ${createdByUser} on ${
                          formData && formData.createdAt ? getDateValue(formData.createdAt, 'datetime') : ''
                      }`
                    : 'Fill the following data and create a new Stocks Receiving',
                subtitle:
                    isEditMode && updatedBy
                        ? `Updated By ${updatedBy ? updatedBy.fullName : 'System'} on ${
                              formData && formData.updatedAt ? getDateValue(formData.updatedAt, 'datetime') : ''
                          }`
                        : '',
            };
        }

        case 'billing/record/form': {
            const {
                isEditMode,
                formData = {},
                updatedBy = {},
                text,
                completedAt,
                merchantName,
                deliveryType,
            } = getBillingRecordEditFormTitle(state, parseSearch);
            return {
                heading: `${isEditMode ? `Payments for ${text || ''} ` : 'Create a new Billing Record'}`,
                text: `${merchantName} Delivery Completed at ${getDateValue(completedAt, 'datetime')}`,
                subtitle:
                    isEditMode && updatedBy
                        ? `Payment Updated By ${updatedBy?.fullName || 'System'} on ${getDateValue(
                              formData.updatedAt,
                              'datetime'
                          )}`
                        : '',
                type: 'billing_record_commission_form',
                deliveryType,
            };
        }
        case 'rider-commission/record/form': {
            const { isEditMode, text, completedAt, merchantName, deliveryType } = getRiderCommissionRecordEditFormTitle(
                state,
                parseSearch
            );

            return {
                heading: `${
                    isEditMode ? `Rider Commissions for ${text || ''} ` : 'Create a new Rider Commission Record'
                }`,
                text: `${merchantName} Delivery Completed at ${getDateValue(completedAt, 'datetime')}`,
                subtitle: 'Update or view rider commissions status for the delivery. ',
                type: 'rider_commission_form',
                deliveryType,
            };
        }
        case 'warehouse/stocks-receiving/deleted/list': {
            return {
                heading: `Deleted Stocks Receiving`,
                text: 'View Deleted Stocks Receiving Here.',
            };
        }

        case 'imports/rider-commissions':
            return {
                heading: 'Upload Payment Details',
                text: 'Update or view rider comissions status for the delivery. ',
            };

        case 'imports/billing':
            return {
                heading: 'Upload Payment Details',
                text: 'Update or view merchant payment status for the deliveries.',
            };
        case 'earning-summary':
            return {
                heading: 'Earning Summary',
                text: 'View Rider Earning Summaries here.',
            };
        case 'warehouse/reports/inventory/view':
            return {
                heading: 'Inventory Report',
                text: 'View Inventory Report Here.',
            };
        case 'delivery/report':
            return {
                heading: 'Delivery Report',
                text: 'Generate Delivery Reports Here.',
            };

        case 'order-processing/list/packing':
            return {
                heading: 'Your Order List',
                text: 'Select tabs to navigate',
            };
        case 'app/label-print/shipping-label/list':
            return {
                heading: 'Shipping Label Printing',
                text: 'Print Labels Here',
            };
        case 'app/label-print/shipping-label/batch-print':
            const { heading, subText, subtitleText, batchPrintStatus } = getBatchPrintFormTitle(state, parseSearch);
            return {
                heading: heading || 'New Batch Print',
                text: subText || 'Print Shipping Label Bulks',
                subtitle: subtitleText,
                status: batchPrintStatus,
            };

        case 'label-print/shipping-label/printer-settings':
            return {
                heading: 'Printer Settings',
                text: 'Set the default printer settings and page sizes',
            };
        case 'app/label-print/shipping-label/archive-print':
            return {
                heading: 'Archive Batch Prints',
                text: 'View and reprint already printed batch prints.',
            };
        case `${PATHS.DELIVERY.BATCH_ASSIGNMENT}`:
            return {
                heading: 'Batch Delivery Assignment',
                text: '',
            };
        case `${PATHS.DEVELOPER.API_SERVICE}`:
            return {
                heading: 'Scootix API Service',
                text: '',
                key: 'partner-api-service',
            };
        case `${PATHS.DEVELOPER.API_DASHBOARD}`:
            const { name } = getClientApiDashboardTitle(state);
            return {
                heading: `Api Dashboard - ${name || 'Current'}`,
                text: '',
            };
        case 'developer/api-portal':
            const { merchantName, merchantReferenceNumber, serviceStatusClient } = getMerchantNameForApiPortal(state);
            return {
                heading: `API Portal for ${merchantName || ''} - ${merchantReferenceNumber || ''}`,
                text: '',
                key: 'partner-api-portal',
                data: {
                    serviceStatusClient,
                },
            };
        case 'developer/api-key/manage':
            return {
                heading: 'Manage API Keys',
                text: '',
            };
        case 'developer/api-key/create':
            return {
                heading: 'Generate a New API Key',
                text: '',
            };
        case 'developer/webhook/create':
            return {
                heading: 'Create Webhook',
                text: '',
            };
        case 'developer/webhook/manage':
            return {
                heading: 'Manage Webhook',
                text: '',
            };
        case `${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.LIST}`:
            return {
                heading: `API Service - Sandbox Account Creation`,
                text: '',
            };
        case `${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.FORM}`:
            const { title, status = null } = getPartnerApiSandboxAccountFormTitle(state, parseSearch);

            return {
                heading: title || `API Service - Sandbox Account Request`,
                text: '',
                key: 'partner-api-sandbox-account-form',
                status,
            };
        case `${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_FORM}`: {
            return {
                heading: 'Route Planning',
                text: '',
            };
        }
        case `${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_ENTRIES}`: {
            return {
                heading: 'Route Planning Entries',
                text: '',
            };
        }
        /**
         * This case is responsible for showing header title for the route planning entry view
         */
        case `${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_ENTRY_VIEW}`: {
            const { routePlanningSequenceId, status, createdAt, createdBy } = getRoutePlanningDetails(state);
            const createdByUser = createdBy || 'System';
            return {
                heading: `Route Planning Entry - ${routePlanningSequenceId}`,
                text: `Created By ${createdByUser} on ${createdAt ? getDateValue(createdAt, 'datetime') : ''}`,
                status,
                key: 'route-planning-entry-view',
            };
        }
        default:
            break;
    }
};

export const getDocumentTitle = (tag) => {
    let tagModified = tag.toLowerCase();

    if (tagModified[0] === '/') {
        tagModified = tagModified.slice(0);
    }

    switch (tagModified) {
        case 'user-management/list':
            return 'User Profile';
        case 'delivery/form':
            return 'Delivery';
        case 'order-processing/list':
            return 'Order Processing';
        case 'order-processing/processed-orders':
            return 'Processed Orders';
        case 'packaging/list':
            return 'Packing';
        case 'order-processing/packaging/form':
            return 'Packing Form';
        case 'reset-password':
            return 'Reset Password';
        case 'order-processing/form':
            return 'Order Form';
        case 'picking-task/list':
            return 'Picking Task';
        case 'picking-task/form':
            return 'Picking Task Form';
        case 'merchant/list':
            return 'Merchant';
        case 'merchant/form':
            return 'Merchant Form';
        case 'user-management/form':
            return 'User Management';
        case 'rider-profiles/form':
            return 'Rider Form';
        case 'switch-profile':
            return 'Switch Profile';
        case 'delivery/list':
            return 'Delivery List';
        case 'user-profile':
            return 'User Profile';
        case 'merchant/locations-list':
            return 'Merchant Locations';
        case 'merchant/locations-form':
            return 'Merchant Location Form';
        case 'shipping-label/view':
            return 'Shipping Label';
        case 'riders/advanced-rider-tracking':
            return 'Advanced Rider Tracking';
        case 'settings/delivery/external-connections': {
            return 'External System Connections';
        }
        case 'settings/delivery/external-connections/excel-csv': {
            return 'Excel Csv Config';
        }
        case 'imports/delivery': {
            return 'Delivery Import';
        }
        case 'pending-data/delivery/form': {
            return 'Pending Data - Delivery';
        }
        case 'rider-profiles/list': {
            return 'Rider / Driver Management';
        }
        case 'rider-profile-management/deleted': {
            return 'Deleted Rider / Driver Management';
        }
        case 'role-permission': {
            return 'Role Permission';
        }
        case 'deleted-users': {
            return 'Deleted Users';
        }
        case 'merchant/list/deleted': {
            return 'Deleted Merchants';
        }
        case 'merchant-locations/list/deleted': {
            return 'Deleted Merchant Locations';
        }
        case 'warehouse/warehouse-site/list': {
            return 'Warehouses';
        }
        case 'warehouse/warehouse-site/form': {
            return 'Warehouse Form';
        }
        case 'warehouse/warehouse-site/location/form': {
            return 'Warehouse Zone Form';
        }
        case 'warehouse/warehouse-site/location/bin/form': {
            return 'Warehouse Bin Location Form';
        }
        case 'warehouse/product-master-data/list': {
            return 'Product Master Data';
        }
        case 'warehouse/product-master-data/form': {
            return 'Product Master Data Form';
        }
        case 'warehouse/stocks-receiving/list': {
            return 'Stocks Receiving';
        }
        case 'warehouse/stocks-receiving/form': {
            return 'Stocks Receiving Form';
        }
        case 'settings/merchant/view': {
            return 'Merchant Settings';
        }
        case 'delivery/unassigned-list': {
            return 'Deliveries Unassigned';
        }
        case 'warehouse/stock-adjustment/list': {
            return 'Stock Adjustments';
        }
        case 'warehouse/stocks-receiving/deleted/list': {
            return 'Deleted Stocks Receiving';
        }
        case 'warehouse/reports/inventory/view': {
            return 'Inventory Report    ';
        }
        case 'dp': {
            return 'ScootiX Delivery Update';
        }
        case 'delivery/report': {
            return 'Delivery Report';
        }
        case 'billing/list': {
            return 'Billing';
        }
        case 'rider-commissions/list': {
            return 'Rider Commissions';
        }
        case 'earning-summary': {
            return 'Earning Summery';
        }
        case 'terms-conditions/scootix-application/v1.0': {
            return 'Terms and Conditions v1.0 - Scootix Application';
        }
        case 'terms-conditions/scootix-application/v2.0': {
            return 'Terms and Conditions v2.0 - Scootix Application';
        }
        case 'billing/form': {
            return 'Billing Setting Form';
        }
        case 'billing/record/form': {
            return 'Billing Record Form';
        }
        case 'rider-commission/record/form': {
            return 'Rider Commission Form';
        }
        case 'delivery/deleted-list': {
            return 'Deleted Deliveries';
        }
        case PATHS.LABEL_PRINT.SHIPPING_LABEL.LIST_VIEW: {
            return 'Shipping Label Print';
        }
        case 'label-print/shipping-label/printer-settings': {
            return 'Printer Settings';
        }
        case PATHS.LABEL_PRINT.SHIPPING_LABEL.BATCH_PRINT_VIEW: {
            return 'Batch Print';
        }
        case PATHS.LABEL_PRINT.ARCHIVED_BATCH_PRINTS.DEFAULT: {
            return 'Archived Batch Print';
        }
        case PATHS.DELIVERY.BATCH_ASSIGNMENT: {
            return 'Batch Delivery Assignment';
        }
        case PATHS.DEVELOPER.API_DASHBOARD: {
            return 'Api Dashboard';
        }
        case 'developer/api-service/list': {
            return 'API Service';
        }
        case 'developer/api-portal': {
            return 'API Portal';
        }
        case 'developer/api-key/manage': {
            return 'Manage API Keys';
        }
        case 'developer/api-key/create': {
            return 'Generate a New API Key';
        }
        case 'developer/webhook/create': {
            return 'Create Webhook';
        }
        case 'developer/webhook/manage': {
            return 'Manage Webhook';
        }
        case 'imports/billing': {
            return 'Upload Payment';
        }
        case `${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.LIST}`:
            return 'Sandbox Accounts';
        case `${PATHS.PARTNER_API_DEVELOPER.SANDBOX_ACCOUNT.FORM}`:
            return 'Sandbox Accounts - Form';
        case `${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_FORM}`:
            return 'Route Planning';
        case `${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_ENTRIES}`:
            return 'Route Planning Entries';
        case `${PATHS.DELIVERY.ROUTE_PLANNING.ROUTE_PLANNING_ENTRY_VIEW}`:
            return 'Route Planning Entry View';
        default:
            break;
    }
};

const getEditMode = (parseSearch) => {
    let isEditMode = false;
    if (parseSearch && parseSearch.type) {
        if (parseSearch.type === 'new') {
            isEditMode = false;
        }
        if (parseSearch.type === 'edit') {
            isEditMode = true;
        }
    }

    return isEditMode;
};

const getOrderEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let data = null;
    let createdBy = null;
    let updatedBy = null;
    if (state) {
        const { orderParentData = null } = state;
        if (orderParentData) {
            data = orderParentData;
            createdBy = orderParentData.createdBy || {};
            updatedBy = orderParentData.updatedBy || null;
            text = orderParentData.referenceNumber || '';
        }
    }
    return { text, isEditMode, data, createdBy, updatedBy };
};

const getPickingEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let data = null;
    let createdBy = null;
    let updatedBy = null;
    if (state) {
        const { taskParentData = null } = state;
        if (taskParentData) {
            data = taskParentData;
            createdBy = taskParentData.createdBy || {};
            updatedBy = taskParentData.updatedBy || null;
            text = taskParentData.referenceNumber || '';
        }
    }
    return { text, isEditMode, data, createdBy, updatedBy };
};

const getPackagingEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let merchant = null;
    let user = null;

    if (state) {
        const { orderParentData = null } = state;
        if (orderParentData) {
            text = orderParentData.referenceNumber || '';
            merchant = orderParentData.merchantName || '';
            user =
                `${orderParentData.createdBy.firstName} ${orderParentData.createdBy.lastName} on ${getDateValue(
                    orderParentData.createdAt,
                    'datetime'
                )}` || '';
        }
    }
    return { text, isEditMode, merchant, user };
};

const getDeliveryEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let merchant = null;
    let user = null;
    let rider = null;
    let deliveryStatus = null;
    let createdBy = null;
    let updatedBy = null;
    let data = null;
    let deliveryType = null;
    let routePlanningId = null;
    let routePlanningSequenceId = null;
    let shopifyStoreName = null;

    if (state) {
        const { deliveryParentData = null } = state;
        if (deliveryParentData) {
            data = deliveryParentData;
            text = deliveryParentData.referenceNumber || '';
            deliveryType = deliveryParentData.deliveryType || '';
            merchant = deliveryParentData.merchantName || '';
            rider = deliveryParentData.riderName || '';
            deliveryStatus = deliveryParentData.isDeleted ? 'deleted' : deliveryParentData.status || '';
            routePlanningId = deliveryParentData.routePlanningId;
            routePlanningSequenceId = deliveryParentData.routePlan?.sequenceId || null;
            user =
                deliveryParentData.createdBy && deliveryParentData.createdAt
                    ? `${deliveryParentData.createdBy.firstName} ${
                          deliveryParentData.createdBy.lastName
                      } on ${getDateValue(deliveryParentData.createdAt, 'datetime')}` || ''
                    : `System on ${getDateValue(deliveryParentData.createdAt || new Date(), 'datetime')}`;
            createdBy = deliveryParentData.createdBy;
            updatedBy = deliveryParentData.updatedBy || null;
            shopifyStoreName = deliveryParentData?.shopifyStoreName;
        }
    }
    return {
        text,
        isEditMode,
        merchant,
        user,
        rider,
        deliveryStatus,
        data,
        createdBy,
        updatedBy,
        deliveryType,
        routePlanningId,
        routePlanningSequenceId,
        shopifyStoreName,
    };
};

const getMerchantEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let data = null;
    let createdBy = null;
    let updatedBy = null;

    if (state) {
        const { merchantParentData = null } = state;
        if (merchantParentData) {
            text = merchantParentData.referenceNumber || '';
            data = merchantParentData;
            createdBy = merchantParentData.createdBy;
            updatedBy = merchantParentData.updatedBy || null;
        }
    }
    return { text, isEditMode, data, createdBy, updatedBy };
};

const getMerchantLocationEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let data = null;
    let createdBy = null;
    let updatedBy = null;

    if (state) {
        const { merchantLocationParentData = null } = state;
        if (merchantLocationParentData) {
            text = merchantLocationParentData?.referenceNumber || '';
            data = merchantLocationParentData;
            createdBy = merchantLocationParentData?.createdBy;
            updatedBy = merchantLocationParentData?.updatedBy || null;
        }
    }
    return { text, isEditMode, data, createdBy, updatedBy };
};

const getUserRegistrationFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let data = null;
    let createdBy = null;
    let updatedBy = null;
    if (state) {
        const { userParentData = null } = state;
        if (userParentData) {
            data = userParentData;
            createdBy = userParentData.createdBy;
            updatedBy = userParentData.updatedBy || null;
            text = userParentData.username || '';
        }
    }
    return { text, isEditMode, data, createdBy, updatedBy };
};

const getRiderRegistrationFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let data = null;
    let createdBy = null;
    let updatedBy = null;

    if (state) {
        const { riderParentData = null } = state;
        if (riderParentData) {
            text = riderParentData.fullName || '';
            data = riderParentData;
            createdBy = riderParentData.createdBy;
            updatedBy = riderParentData.updatedBy || null;
            text = riderParentData.username || '';
        }
    }
    return { text, isEditMode, data, createdBy, updatedBy };
};

const getWarehouseSiteEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let warehouse = null;
    let createdBy = null;
    let updatedBy = null;
    if (state) {
        const { warehouseSiteParentData = null } = state;
        if (warehouseSiteParentData) {
            warehouse = warehouseSiteParentData;
            createdBy = warehouseSiteParentData.createdBy;
            updatedBy = warehouseSiteParentData.updatedBy || null;
            text = warehouseSiteParentData.referenceNumber || '';
        }
    }
    return { text, isEditMode, warehouse, createdBy, updatedBy };
};

const getWarehouseZoneEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let formData = null;
    let createdBy = null;
    let updatedBy = null;
    if (state) {
        const { warehouseZoneParentData = null } = state;
        if (warehouseZoneParentData) {
            formData = warehouseZoneParentData;
            createdBy = warehouseZoneParentData.createdBy;
            updatedBy = warehouseZoneParentData.updatedBy || null;
            text = warehouseZoneParentData.referenceNumber || '';
        }
    }
    return { text, isEditMode, formData, createdBy, updatedBy };
};

const getBillingSettingEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let formData = null;
    let createdBy = null;
    let updatedBy = null;
    if (state) {
        const { billingSettingParentData = null } = state;
        if (billingSettingParentData) {
            formData = billingSettingParentData;
            createdBy = billingSettingParentData.createdBy;
            updatedBy = billingSettingParentData.updatedBy || null;
            text = billingSettingParentData.referenceNumber || '';
        }
    }
    return { text, isEditMode, formData, createdBy, updatedBy };
};

const getBillingRecordEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let formData = null;
    let createdBy = null;
    let updatedBy = null;
    let merchantName = null;
    let completedAt = null;
    let deliveryType = null;
    if (state) {
        const { billingRecordParentData = null } = state;

        if (billingRecordParentData) {
            const { merchantId } = billingRecordParentData;
            const data = billingRecordParentData;
            deliveryType = data.deliveryId && isObject(data.deliveryId) ? data.deliveryId.deliveryType : null;
            merchantName = isObject(merchantId) ? merchantId.name : '';
            formData = billingRecordParentData;
            completedAt = billingRecordParentData.completedAt;
            createdBy = billingRecordParentData.createdBy;
            updatedBy = billingRecordParentData.updatedBy || null;
            // text for when there is only one delivery: `Delivery {Delivery ID} | Billing Reference : {Billing Reference)`
            text =
                `Delivery: ${billingRecordParentData.referenceNumber || ''} | Billing Reference: ${
                    billingRecordParentData.sequenceId || ''
                }` || 'Delivery';
            // if have multiple deliveries: `Billing Reference : {Billing Reference)`
            if (data.deliveries && data.deliveries?.length > 1) {
                text = `Billing Reference: ${billingRecordParentData.sequenceId || ''}` || 'Billing Reference ';
            }
        }
    }
    return { text, isEditMode, formData, createdBy, updatedBy, merchantName, completedAt, deliveryType };
};

const getRiderCommissionSettingEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let formData = null;
    let createdBy = null;
    let updatedBy = null;
    if (state) {
        const { riderCommissionSettingParentData = null } = state;
        if (riderCommissionSettingParentData) {
            formData = riderCommissionSettingParentData;
            createdBy = riderCommissionSettingParentData.createdBy;
            updatedBy = riderCommissionSettingParentData.updatedBy || null;
            text = riderCommissionSettingParentData.referenceNumber || '';
        }
    }
    return { text, isEditMode, formData, createdBy, updatedBy };
};

const getRiderCommissionRecordEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let formData = null;
    let createdBy = null;
    let updatedBy = null;
    let merchantName = null;
    let completedAt = null;
    let deliveryType = null;

    if (state) {
        const { riderCommissionRecordParentData = null } = state;
        if (riderCommissionRecordParentData) {
            const data = riderCommissionRecordParentData;
            deliveryType = data.deliveryId && isObject(data.deliveryId) ? data.deliveryId.deliveryType : null;
            formData = riderCommissionRecordParentData;
            merchantName = riderCommissionRecordParentData.merchantId.name || '';
            completedAt = riderCommissionRecordParentData.completedAt;
            createdBy = riderCommissionRecordParentData.createdBy;
            updatedBy = riderCommissionRecordParentData.updatedBy || null;
            // text for when there is only one delivery: `Delivery {Delivery ID} | Billing Reference : {Billing Reference)`
            text =
                `Delivery: ${riderCommissionRecordParentData.referenceNumber || ''} | Billing Reference: ${
                    riderCommissionRecordParentData.sequenceId || ''
                }` || 'Delivery';
            // if have multiple deliveries: `Billing Reference : {Billing Reference)`
            if (data.deliveries && data.deliveries?.length > 1) {
                text = `Billing Reference: ${riderCommissionRecordParentData.sequenceId || ''}` || 'Billing Reference ';
            }
        }
    }
    return { text, isEditMode, formData, createdBy, updatedBy, merchantName, completedAt, deliveryType };
};

const getWarehouseBinLocationEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let formData = null;
    let createdBy = null;
    let updatedBy = null;
    if (state) {
        const { binLocationParentData = null } = state;
        if (binLocationParentData) {
            formData = binLocationParentData;
            createdBy = binLocationParentData.createdBy;
            updatedBy = binLocationParentData.updatedBy || null;
            text = binLocationParentData.referenceNumber || '';
        }
    }
    return { text, isEditMode, formData, createdBy, updatedBy };
};

const getProductMasterDataEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let formData = null;
    let createdBy = null;
    let updatedBy = null;
    if (state) {
        const { productMasterDataParentData = null } = state;
        if (productMasterDataParentData) {
            formData = productMasterDataParentData;
            createdBy = productMasterDataParentData.createdBy;
            updatedBy = productMasterDataParentData.updatedBy || null;
            text = productMasterDataParentData.referenceNumber || '';
        }
    }
    return { text, isEditMode, formData, createdBy, updatedBy };
};

const getStocksReceivingEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let formData = null;
    let createdBy = null;
    let updatedBy = null;

    if (state) {
        const { stocksReceivingParentData = null } = state;
        if (stocksReceivingParentData) {
            formData = stocksReceivingParentData;
            createdBy = stocksReceivingParentData.createdBy;
            updatedBy = stocksReceivingParentData.updatedBy || null;
            text = stocksReceivingParentData.referenceNumber || '';
        }
    }
    return { text, isEditMode, formData, createdBy, updatedBy };
};

const getStocksAdjustmentEditFormTitle = (state, parseSearch) => {
    let text = null;
    let isEditMode = getEditMode(parseSearch);
    let formData = null;
    let createdBy = null;
    let updatedBy = null;
    let stockAdjustmentStatus = null;

    if (state) {
        const { stocksAdjustmentParentData = null } = state;
        if (stocksAdjustmentParentData) {
            formData = stocksAdjustmentParentData;
            createdBy = stocksAdjustmentParentData.createdBy;
            updatedBy = stocksAdjustmentParentData.updatedBy || null;
            text = stocksAdjustmentParentData.referenceNumber || '';
            stockAdjustmentStatus = stocksAdjustmentParentData.status || '';
        }
    }
    return { text, isEditMode, formData, createdBy, updatedBy, stockAdjustmentStatus };
};

const getBatchPrintFormTitle = (state, parseSearch) => {
    let heading = null;
    let batchPrintStatus = null;
    let subText = null;
    let subtitleText = null;
    if (state) {
        const { batchPrintParentData } = state;
        if (batchPrintParentData) {
            let sequenceId = batchPrintParentData.sequenceId || '';
            let user = batchPrintParentData.createdByUserFullName || batchPrintParentData.createdByUser?.fullName;
            let updatedUser =
                batchPrintParentData.updatedByUserFullName || batchPrintParentData.updatedByUser?.fullName;
            let createdAt = getDateValue(batchPrintParentData.createdAt, 'datetime');
            let updatedAt = getDateValue(batchPrintParentData.updatedAt, 'datetime');
            batchPrintStatus = batchPrintParentData.status || null;
            heading = sequenceId ? `Batch Print ${sequenceId}` : '';
            subText = user ? `Created by ${user} on ${createdAt}` : '';
            subtitleText = updatedUser ? `Updated by ${updatedUser} on ${updatedAt}` : null;
        }
    }
    return { heading, subText, batchPrintStatus, subtitleText };
};

// TODO add docs and add logic for object like this {serviceStatusClient, loaded:// if data loaded or not because initial value is false}
const getMerchantNameForApiPortal = (state) => {
    let merchantReferenceNumberFormatted = null;
    let merchantNameFormatted = null;
    let serviceStatusClientFormatted = false;

    if (state) {
        const { serviceStatusClient, merchantName, merchantReferenceNumber } = state;
        merchantNameFormatted = merchantName;
        merchantReferenceNumberFormatted = merchantReferenceNumber;
        serviceStatusClientFormatted = serviceStatusClient;
    }

    return {
        merchantName: merchantNameFormatted,
        merchantReferenceNumber: merchantReferenceNumberFormatted,
        serviceStatusClient: serviceStatusClientFormatted,
    };
};

const getClientApiDashboardTitle = (state, user) => {
    let name = null;

    const { visitingClient } = getVisitingObject();

    if (visitingClient?.name) {
        name = visitingClient.name;
    }

    return { name };
};

const getPartnerApiSandboxAccountFormTitle = (state = {}, parseSearch) => {
    let title = 'Create a Sandbox Account';
    let status = null;

    if (parseSearch.type) {
        const { type } = parseSearch;

        if (type === 'new') {
            title = `Create a Sandbox Account`;
        }

        if (type === 'edit') {
            const { sandboxData = {} } = state;
            title = `Sandbox Account Request - ${sandboxData?.sequenceId || ''}`;
            status = sandboxData.requestStatus;
        }
    }

    return { title, status };
};

/**
 * This function is responsible for returning data for header
 */
const getRoutePlanningDetails = (state) => {
    let routePlanningSequenceId = '';
    let createdAt = '';
    let createdBy = '';
    let status = '';

    if (state) {
        routePlanningSequenceId = state?.sequenceId;
        createdAt = state?.createdAt;
        createdBy = state?.createdByUserFullName ? state.createdByUserFullName : state.createdByUserId?.fullName;
        status = state?.status;
    }

    return {
        routePlanningSequenceId,
        createdAt,
        createdBy,
        status,
    };
};
