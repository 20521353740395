import { GET_MODEL_BY_TYPE_AND_REFERENCE_ID } from 'utils/apiEndpoints';
import instance from 'utils/httpclients';
import UrlBuilder from 'utils/httpclients/urlQueryBuilder';

/**
 * Get Vehicles By Type
 * @returns {Promise}
 */
export const getModelByTypeAndReferenceIdService = (type, id) => {
    try {
        const url = UrlBuilder().setUrl(GET_MODEL_BY_TYPE_AND_REFERENCE_ID).setPathParams({ type, id }).build();
        return instance.get(url);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
