const billingSettingModelKeys = {
    billingMethod: 'billingMethod',
    currency: 'currency',
    vehicleType: 'vehicleType',
    deliveryType: 'deliveryType',
    fixedDeliveryRate: 'fixedDeliveryRate',
    commission: 'commission',
    baseMileage: 'baseMileage',
    baseMileageRate: 'baseMileageRate',
    additionalMileageSlab1: 'additionalMileageSlab1',
    additionalMileageSlab1Limit: 'additionalMileageSlab1Limit',
    ratePerAdditionalMileage1: 'ratePerAdditionalMileage1',
    additionalMileageSlab2: 'additionalMileageSlab2',
    additionalMileageSlab2Limit: 'additionalMileageSlab2Limit',
    ratePerAdditionalMileage2: 'ratePerAdditionalMileage2',
    validFrom: 'validFrom',
    validTo: 'validTo',
    clientId: 'clientId',
    merchantId: 'merchantId',
    createdBy: 'createdBy',
    updatedBy: 'updatedBy',
    createdByUserId: 'createdByUserId',
    updatedByUserId: 'updatedByUserId',
};

export default billingSettingModelKeys;
