import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

function FormStatusBtn(props) {
    const { isFormSaved = false } = props;

    return (
        <motion.div
            className={`form-status-btn ${isFormSaved ? 'saved' : 'not-saved'}`}
            style={{
                backgroundColor: isFormSaved ? '#e1fad7' : '#FFF5F0',
                color: isFormSaved ? '#2cb978' : '#F98F82',
            }}
        >
            {isFormSaved ? (
                <i className="fas fa-check form-status-btn-icon w3-animate-opacity"></i>
            ) : (
                <i className="far fa-circle form-status-btn-icon w3-animate-opacity" />
            )}
            <span className="w3-animate-opacity"> {isFormSaved ? 'Saved' : 'Not Saved'}</span>
        </motion.div>
    );
}

FormStatusBtn.propTypes = {
    isFormSaved: PropTypes.bool,
};

export default FormStatusBtn;
