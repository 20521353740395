import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

// #region assets | components
import { CheckCircle } from 'components/Icons';
import ConfirmationModal from 'components/Modals/Developer/ConfirmationModal';

// #region utils
import { getDateValue } from 'utils/helpers';

/**
 * Update Multi-Stop billing setting
 * @returns {MultiStopBillingUpdateModal} - Showing Multi-Stop billing setting update modal for merchant user
 */
export default function MultiStopBillingUpdateModal(props) {
    const { show, onclose, handleMultiStopBillingUpdate, merchantSettings, formLoading } = props;

    /** Get the merchantName from merchant settings */
    const { merchantName } = merchantSettings;

    /** set modal input data */
    const [formData, setFormData] = useState(merchantSettings);

    /** handle Multi-Stop billing settings */
    const handleCheckChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.checked,
        });
    };

    /** generate last updated user and time */
    // eslint-disable-next-line consistent-return
    const generateMultiStopBillingSettingUpdate = () => {
        const { multiStopBillingSettingUpdated, updatedAt } = formData;
        const updatedBy = multiStopBillingSettingUpdated?.by?.fullName;

        const settingUpdatedAt = getDateValue(multiStopBillingSettingUpdated?.at || updatedAt, 'datetime');

        /** if updated name and date are true or undefine then only return the text
         * and if they undefine not return the text
         */
        if (updatedBy && settingUpdatedAt) {
            return `Last updated by ${updatedBy} at ${settingUpdatedAt}`;
        }
    };

    return (
        <ConfirmationModal header={`MULTI-STOP BILLING SETTINGS FOR ${merchantName}`} show={show} onclose={onclose}>
            <Container>
                <AvForm className="needs-validation default-form">
                    <Row>
                        <Col>
                            {generateMultiStopBillingSettingUpdate() && (
                                <Alert color="warning" style={{ borderRadius: 8 }}>
                                    <span>{generateMultiStopBillingSettingUpdate()}</span>
                                </Alert>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div
                                style={{
                                    backgroundColor: '#FCFCFC',
                                    border: '1px solid #F1F1F1',
                                    borderRadius: '8px',
                                    padding: '10px 35px',
                                    alignItems: 'center',
                                }}
                            >
                                <Input
                                    className="multi-stop-billing-check-input"
                                    type="checkbox"
                                    onChange={(event) => handleCheckChange(event)}
                                    checked={formData.isMultiStopBillingSettingEnabled}
                                    defaultChecked={formData.isMultiStopBillingSettingEnabled}
                                    id="isMultiStopBillingSettingEnabled"
                                />
                                <Label className="ml-1 mb-0">Enable Multi-Stop Billing</Label>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button
                                style={{ backgroundColor: '#FD4A4A', borderRadius: 8 }}
                                className="scootix-btn-radius"
                                onClick={() => handleMultiStopBillingUpdate(formData)}
                                disabled={formLoading}
                            >
                                {formLoading ? (
                                    <Spinner className="spinner-white mr-4 ml-4 0" color="info" size="sm" />
                                ) : (
                                    <CheckCircle color="#FFF" />
                                )}
                                &nbsp;UPDATE
                            </Button>
                        </Col>
                    </Row>
                </AvForm>
            </Container>
        </ConfirmationModal>
    );
}

MultiStopBillingUpdateModal.propTypes = {
    show: PropTypes.bool,
    onclose: PropTypes.func,
    handleMultiStopBillingUpdate: PropTypes.func,
    merchantSettings: PropTypes.object,
    formLoading: PropTypes.bool,
};
