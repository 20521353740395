/* eslint-disable indent */
import React from 'react';
import { Row, Card, CardBody, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

// #region imports
import { SubTitle } from 'styles';

/**
 * Stock Adjustments Transactions Component
 * @param {*} props
 * @returns
 */
function StockAdjustmentsTransactions(props) {
    const { allStocksAdjustmentTransactions } = props;

    return (
        <>
            {/* <style>{`
   table, th, td {
    border: 1px solid black;
  }
  `}</style> */}
            <Card className="shadow-lg" style={{ borderRadius: 20 }}>
                <CardBody>
                    <div>
                        <Row>
                            <SubTitle style={{ color: '#574b90', letterSpacing: 0.5, marginLeft: 10 }}>
                                SYSTEM TRANSACTIONS
                            </SubTitle>
                        </Row>
                        <br />

                        <Row>
                            <Col md={12}>
                                <table style={{ width: '100%' }}>
                                    <thead
                                        style={{
                                            color: '#184480',
                                        }}
                                    >
                                        <tr>
                                            <th style={{ minWidth: 150, border: '0.5px solid grey', padding: 10 }}>
                                                User
                                            </th>
                                            <th style={{ minWidth: 200, border: '0.5px solid grey', padding: 10 }}>
                                                Description
                                            </th>
                                            <th style={{ minWidth: 200, border: '0.5px solid grey', padding: 10 }}>
                                                Data
                                            </th>
                                            <th style={{ minWidth: 200, border: '0.5px solid grey', padding: 10 }}>
                                                Transaction Date and Time
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allStocksAdjustmentTransactions &&
                                            allStocksAdjustmentTransactions.map((x) => (
                                                <tr>
                                                    <td style={{ border: '0.5px solid grey', padding: 10 }}>
                                                        {x.createdBy
                                                            ? `${x.createdBy.firstName} ${x.createdBy.lastName}`
                                                            : ''}
                                                    </td>
                                                    <td style={{ border: '0.5px solid grey', padding: 10 }}>
                                                        {x.description || ''}
                                                    </td>
                                                    <td style={{ border: '0.5px solid grey', padding: 10 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <span>
                                                                Warehouse : {x.meta ? x.meta.warehouseSiteName : ''}
                                                            </span>
                                                            <span>
                                                                Stocks Adjustment Type :{' '}
                                                                {x.meta ? x.meta.stocksAdjustmentType : ''}
                                                            </span>
                                                            <span>
                                                                Stocks Transfer Location :{' '}
                                                                {x.meta ? x.meta.stocksTransferLocation : ''}
                                                            </span>
                                                            {x.meta &&
                                                                x.meta.stocksAdjustmentType &&
                                                                x.meta.stocksAdjustmentType === 'Customer Returns' && (
                                                                    <span>
                                                                        ScootiX Order Number :{' '}
                                                                        {x.meta ? x.meta.orderReferenceNumber : ''}
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </td>
                                                    <td style={{ border: '0.5px solid grey', padding: 10 }}>
                                                        {moment(x.createdAt).format('YYYY-MM-DD')} at{' '}
                                                        {moment(x.createdAt).format('HH.mm A')}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
            <br />
        </>
    );
}

StockAdjustmentsTransactions.propTypes = {
    allStocksAdjustmentTransactions: PropTypes.array,
};

export default StockAdjustmentsTransactions;
