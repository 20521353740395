/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';

// #region components
import DeveloperModal from 'components/Modals/Developer/DeveloperModal';

/**
 * Modal for enabling the API key
 */
function ApiKeyTransactionData(props) {
    const { show, handleToggle, transactionId, name, allowedEndpoints, previousApiKeyName } = props;

    return (
        <DeveloperModal header={`Transaction data for ${transactionId}`} show={show} handleToggle={handleToggle}>
            <Container>
                <Row className="mb-2">
                    <Col>
                        <Label htmlFor="name">Name</Label>
                        <Input name="name" className="form-control" value={name} readOnly />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Label htmlFor="name">Previous Api Key Name</Label>
                        <Input name="name" className="form-control" value={previousApiKeyName} readOnly />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Label htmlFor="allowedEndpoints">Key Permissions</Label>
                        <Input
                            name="allowedEndpoints"
                            type="textarea"
                            className="form-control"
                            style={{ borderRadius: 15, backgroundColor: '#F5F4F4', border: '#E7E4E4 1px solid' }}
                            value={allowedEndpoints}
                            readOnly
                        />
                    </Col>
                </Row>
            </Container>
        </DeveloperModal>
    );
}

ApiKeyTransactionData.propTypes = {
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    transactionId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    allowedEndpoints: PropTypes.string,
    previousApiKeyName: PropTypes.string,
};

export default ApiKeyTransactionData;
