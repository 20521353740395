/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, Card, CardBody, Spinner } from 'reactstrap';
import { getAttachmentByKey } from 'services/attachment.service';

function AttachmentPreviewModal(props) {
    const { toggleModal, show, type, image, header = 'Attachment Preview', isFormAttachmentModal = false } = props;

    const [imageSrc, setImageSrc] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [customLoading, setCustomLoading] = useState(true);

    useEffect(() => {
        renderImage();
    }, []);

    const renderImage = () => {
        if (image && typeof image === 'string' && !image.includes('Signature') && !image.includes('Algorithm')) {
            getAttachmentByKey(image)
                .then((res) => {
                    if (res) {
                        setImageSrc(res);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    setCustomLoading(false);
                })
                .finally(() => {
                    setCustomLoading(false);
                });
        } else if (image && typeof image === 'string' && image.includes('Signature') && image.includes('Algorithm')) {
            setCustomLoading(false);
            setImageSrc(image);
        } else if (image && typeof image === 'object') {
            try {
                const convertedImage = URL.createObjectURL(image);
                setImageSrc(convertedImage);
                setCustomLoading(false);
            } catch (e) {
                console.log(e);
                setCustomLoading(false);
            }
        }
    };

    // const renderImageUrlLocal = () => {
    //     console.log(typeof image);
    //     let convertedImage = null;
    //     try {
    //         convertedImage = URL.createObjectURL(image);
    //     } catch (e) {
    //         console.log(e);
    //     }

    //     if (!isDbSaved && convertedImage) {
    //         return (
    //             <Card className="shadow default-card">
    //                 <CardBody>
    //                     {image && !customLoading ? (
    //                         <img className="rounded mr-2" alt={header} style={{ maxWidth: 500 }} src={convertedImage} />
    //                     ) : (
    //                         <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
    //                     )}
    //                 </CardBody>
    //             </Card>
    //         );
    //     }
    //     return <div />;
    // };

    return (
        <>
            <Modal size="xl" isOpen={show} toggle={toggleModal} center className="modal-dialog modal-dialog-centered">
                <ModalHeader toggle={toggleModal}>{header}</ModalHeader>
                {isFormAttachmentModal && (
                    <ModalBody>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                            {type === 'default' && (
                                <>
                                    <Card className="shadow default-card">
                                        <CardBody>
                                            {imageSrc ? (
                                                <img
                                                    className="rounded mr-2"
                                                    alt={header}
                                                    style={{ maxWidth: 500 }}
                                                    src={imageSrc}
                                                />
                                            ) : (
                                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                            )}
                                        </CardBody>
                                    </Card>
                                    {/* {!isDbSaved && renderImageUrlLocal()} */}
                                </>
                            )}
                        </div>
                    </ModalBody>
                )}
                {!isFormAttachmentModal && (
                    <ModalBody>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                            {type === 'default' && (
                                <>
                                    <Card className="shadow default-card">
                                        <CardBody>
                                            {image ? (
                                                <img
                                                    className="rounded mr-2"
                                                    alt={header}
                                                    style={{ maxWidth: 500 }}
                                                    src={image}
                                                />
                                            ) : (
                                                <Spinner className="mr-4 ml-4 0" color="info" size="sm" />
                                            )}
                                        </CardBody>
                                    </Card>
                                </>
                            )}
                        </div>
                    </ModalBody>
                )}
            </Modal>
        </>
    );
}

AttachmentPreviewModal.propTypes = {
    toggleModal: PropTypes.func,
    show: PropTypes.bool,
    type: PropTypes.string,
    header: PropTypes.any,
    image: PropTypes.any,
    isFormAttachmentModal: PropTypes.bool,
};

export default AttachmentPreviewModal;
